import { BlueprintElements } from 'types/blueprint-config'

type BuildingBlock = {
  schema?: any
  required?: string[]
  uischema: BlueprintElements[]
}

type BlockValueProps = {
  name: string
  required?: boolean
}

// PersonalInfo

type PersonalInfoBlockValues = {
  salutation: BlockValueProps
  title: BlockValueProps
  first_name: BlockValueProps
  last_name: BlockValueProps
  user_email: BlockValueProps
  telephone: BlockValueProps
}

const defaultPersonalInfoValues: PersonalInfoBlockValues = {
  salutation: { name: 'salutation', required: true },
  title: { name: 'title', required: true },
  first_name: { name: 'first_name', required: true },
  last_name: { name: 'last_name', required: true },
  user_email: { name: 'user_email', required: true },
  telephone: { name: 'telephone', required: true },
}

export const buildPersonalInfoBlock = (
  personalInfoValues?: PersonalInfoBlockValues,
): BuildingBlock => {
  const values = personalInfoValues || defaultPersonalInfoValues

  const schema = {
    [values.salutation.name]: {
      type: 'string',
      enum: ['Herr', 'Frau', 'Andere'],
      errorMessage: 'Please select one of the options',
    },
    [values.title.name]: {
      type: 'string',
      enum: ['Dr.', 'Prof.', 'Prof.Dr.'],
      errorMessage: 'Please select one of the options',
    },
    [values.first_name.name]: {
      type: 'string',
      format: 'lettersMinimum_3',
      errorMessage: 'Please enter at least 3 letters',
    },
    [values.last_name.name]: {
      type: 'string',
      format: 'lettersMinimum_3',
      errorMessage: 'Please enter at least 3 letters',
    },
    [values.user_email.name]: {
      type: 'string',
      format: 'email',
      errorMessage: 'Please enter a valid email address',
      examples: ['user@example.com'],
    },
    [values.telephone.name]: {
      type: 'string',
      format: 'numbersTelephone_6',
      examples: ['+123456789'],
    },
  }

  const uischema: BlueprintElements[] = [
    {
      type: 'GridContainerLayout',
      options: {
        spacing: 4,
      },
      elements: [
        {
          type: 'GridItemLayout',
          options: {
            xs: 12,
            sm: 6,
          },
          elements: [
            {
              type: 'Control',
              scope: `#/properties/${values.salutation.name}`,
              options: {
                toggle: true,
              },
            },
          ],
        },
        {
          type: 'GridItemLayout',
          options: {
            xs: 12,
            sm: 6,
          },
          elements: [
            {
              type: 'Control',
              scope: `#/properties/${values.title.name}`,
              options: {
                toggle: true,
              },
            },
          ],
        },
      ],
    },
    {
      type: 'GridContainerLayout',
      options: {
        spacing: 4,
      },
      elements: [
        {
          type: 'GridItemLayout',
          options: {
            xs: 12,
            sm: 6,
          },
          elements: [
            {
              type: 'Control',
              label: 'First Name',
              scope: `#/properties/${values.first_name.name}`,
            },
          ],
        },
        {
          type: 'GridItemLayout',
          options: {
            xs: 12,
            sm: 6,
          },
          elements: [
            {
              type: 'Control',
              label: 'Last Name',
              scope: `#/properties/${values.last_name.name}`,
            },
          ],
        },
      ],
    },
    {
      type: 'GridContainerLayout',
      options: {
        spacing: 4,
      },
      elements: [
        {
          type: 'GridItemLayout',
          options: {
            xs: 12,
            sm: 6,
          },
          elements: [
            {
              type: 'Control',
              label: 'Email',
              scope: `#/properties/${values.user_email.name}`,
            },
          ],
        },
        {
          type: 'GridItemLayout',
          options: {
            xs: 12,
            sm: 6,
          },
          elements: [
            {
              type: 'Control',
              label: 'Telephone',
              scope: `#/properties/${values.telephone.name}`,
            },
          ],
        },
      ],
    },
  ]

  const required = []

  for (const value of Object.entries(values)) {
    if (value[1].required) {
      required.push(value[1].name)
    }
  }

  return {
    schema,
    uischema,
    required,
  }
}

// Text
export const buildTextBlock = (
  textContent: string,
  textType: any,
): BuildingBlock => {
  const uischema: BlueprintElements[] = [
    {
      type: 'GridContainerLayout',
      options: {
        spacing: 4,
      },
      elements: [
        {
          type: 'GridItemLayout',
          options: {
            xs: 12,
          },
          elements: [
            {
              type: textType,
              text: textContent,
            },
          ],
        },
      ],
    },
  ]

  return { uischema }
}

// Next Button
export const buildNextButtonBlock = (): BuildingBlock => {
  const uischema: BlueprintElements[] = [
    {
      type: 'GridContainerLayout',
      options: {
        spacing: 4,
      },
      elements: [
        {
          type: 'GridItemLayout',
          options: {
            xs: 12,
          },
          elements: [
            {
              type: 'ForwardSummaryTile',
            },
          ],
        },
      ],
    },
  ]

  return { uischema }
}

// Block Hider
export const blockHider = (
  scope: string,
  blocksToHide: BlueprintElements[],
): BuildingBlock => {
  const schema = {
    [scope]: {
      type: 'boolean',
    },
  }

  const uischema: BlueprintElements[] = [
    {
      type: 'Control',
      label: 'Abweichend von Eigentümer',
      scope: `#/properties/${scope}`,
    },
    {
      type: 'VerticalLayout',
      rule: {
        condition: {
          schema: {
            const: true,
          },
          scope: `#/properties/${scope}`,
        },
        effect: 'SHOW',
      },
      elements: [...blocksToHide],
    },
  ]

  return {
    schema,
    uischema,
  }
}
