import { datadogRum } from '@datadog/browser-rum'
import React from 'react'

import config from '../../config'

export const useDataDog = (shouldInit: boolean) => {
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    if (shouldInit && !initialized) {
      datadogRum.init({
        applicationId: '31821273-f2ec-4081-b46e-2623477def52',
        clientToken: config.DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: 'journey',
        env: config.STAGE,
        version: config.NODE_ENV.VERSION,
        sampleRate: 100,
        // TODO: Reenable when consent is set
        // trackInteractions: config.STAGE !== 'local',
        trackInteractions: true,
        trackViewsManually: true, // since we dont change the path, we need to manually track view changes, i.e. step changes
        useCrossSiteSessionCookie: true, // enables third party cookies
        // enrich event data with journeyId
        beforeSend: (event) => {
          const { url } = event.view // get current url
          const queryString = url.substring(url.lastIndexOf('?')) // find query string from url
          // retrieve params as [k: string]: string; from string
          const urlSearchParams = new URLSearchParams(queryString)
          const params = Object.fromEntries(urlSearchParams.entries())

          event.context = {
            ...event.context,
            journey_id: params.widgetid,
          }
        },
      })

      setInitialized(true)
    }
  }, [initialized, shouldInit])
}
