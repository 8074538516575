import { BlueprintElements } from 'types/blueprint-config'

type SummayTyleBlockValues = {
  stepLink: string
  title: string
  labels: string[]
  scope?: string
}

export const buildSummaryTileBlock = (
  values: SummayTyleBlockValues,
): BlueprintElements[] => {
  const elements: BlueprintElements[] = []

  for (const label of values.labels) {
    elements.push({
      type: 'DataLabel',
      text: label,
    })
  }

  const rule: any = values.scope
    ? {
        rule: {
          condition: {
            schema: {
              const: true,
            },
            scope: `#/properties/${values.scope}`,
          },
          effect: 'SHOW',
        },
      }
    : {}

  const uischema: BlueprintElements[] = [
    {
      type: 'GridItemLayout',
      options: {
        xs: 4,
      },
      elements: [
        {
          type: 'CardLayout',
          elements: [
            {
              type: 'GridContainerLayout',
              options: {
                spacing: 2,
              },
              elements: [
                {
                  type: 'SummaryTile',
                  options: {
                    title: values.title,
                    stepIdLink: values.stepLink,
                  },
                  elements: elements,
                },
              ],
            },
          ],
        },
      ],
      ...rule,
    },
  ]

  return uischema
}
