import { Grid } from '@epilot/base-elements'
import { PricingCard } from '@epilot/base-modules'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePrice } from 'components/hooks/usePrice'
import { SellingOption } from 'lib/api.types'
import { goNext } from 'modules/step/reducer'
import { getActiveStepNextButtonLabel } from 'modules/step/selectors'

type ProductDetailPricingCardProps = {
  price: SellingOption
  taxInformation?: string
  showAnnualBasePrice?: boolean
  showTotalAnnualPrice?: boolean
}

const ProductDetailPricingCard = ({
  price,
  taxInformation,
  showAnnualBasePrice = true,
  showTotalAnnualPrice = true,
}: ProductDetailPricingCardProps): JSX.Element => {
  const {
    estimatedPricePerMonth,
    estimatedPricePerYear,
    variablePrices,
    isMonthlyPrice,
  } = usePrice({
    price,
    showAnnualBasePrice,
    showTotalAnnualPrice,
  })
  const dispatch = useDispatch()
  const nextButtonLabel = useSelector(getActiveStepNextButtonLabel)
  const handleGoNext = () => {
    dispatch(goNext())
  }

  return (
    <Grid item xs={12}>
      <PricingCard
        buttonTitle={nextButtonLabel}
        footnote={taxInformation}
        onActionButtonClick={() => handleGoNext()}
        price={isMonthlyPrice ? estimatedPricePerMonth : estimatedPricePerYear}
        priceInformationLabel="Preisinformation"
        priceUnitInterval={isMonthlyPrice ? 'monatlich' : 'jährlich'}
        pricingItems={variablePrices}
      />
    </Grid>
  )
}

export default ProductDetailPricingCard
