import {
  functionalValidators,
  regExpStatments,
  schemaKeywords,
  validateSchemaKeyword,
} from '@epilot/validators'
import { createAjv } from '@jsonforms/core'
import { Ajv, ValidateFunction } from 'ajv'
import ajvErrors from 'ajv-errors'

// Error messages for default validation schema keywords
const localizedDefaultErrors: { [lang: string]: { [prop: string]: string } } = {
  en: {
    required: "'This field is required.'",
    minLength:
      '`This field cannot be shorter than ${v.params?.limit} characters.`',
    maxLength:
      '`This field cannot be longer than ${v.params?.limit} characters.`',
  },
  de: {
    required: "'Ist erforderlich.'",
    minLength:
      '`Dieses Feld darf nicht kürzer als ${v.params?.limit} Zeichen sein.`',
    maxLength:
      '`Dieses Feld darf nicht länger als ${v.params?.limit} Zeichen sein.`',
  },
}

// Generates inline function to override default errors from ajv on initialization
const generateCustomErrorMessages = (lang = 'en') => {
  let out = ' '

  out += 'vErrors = vErrors?.map((v) => {'
  out += `if (v.keyword === 'required') {v.message = ${localizedDefaultErrors[lang]?.required};} `
  out += `else if (v.keyword === 'minLength') {v.message = ${localizedDefaultErrors[lang]?.minLength};} `
  out += `else if (v.keyword === 'maxLength') {v.message = ${localizedDefaultErrors[lang]?.maxLength};} `
  out += 'return v;});'

  return out
}

// AJV custom format validations from @epilot/json-renderers library
export const initAjv = (lang = 'de'): Ajv => {
  const ajv = createAjv()

  Object.keys(regExpStatments).forEach((key: string) => {
    ajv.addFormat(regExpStatments[key].name, regExpStatments[key].regex)
  })
  Object.keys(functionalValidators).forEach((key: string) => {
    ajv.addFormat(
      functionalValidators[key].name,
      functionalValidators[key].callback,
    )
  })

  schemaKeywords.forEach((schemaKeyword) => {
    const { keyword } = schemaKeyword

    ajv.addKeyword(keyword, {
      type: 'string',
      validate: validateSchemaKeyword(schemaKeyword, lang) as ValidateFunction,
    })
  })

  // Custom schema keyword to override default error messages from ajv
  ajv.addKeyword('customErrorMessages', {
    inline: () => generateCustomErrorMessages(lang),
    statements: true,
    valid: true,
    errors: 'full',
  })

  ajvErrors(ajv, { singleError: ' ' })

  return ajv as Ajv
}
