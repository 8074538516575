import { styled } from '@epilot/components'

type AddonInfoTextStyledProps = {
  withDetails: boolean
}

const AddonInfoTextStyled = styled.span<AddonInfoTextStyledProps>`
  margin-top: ${({ withDetails }: AddonInfoTextStyledProps) =>
    withDetails ? '0' : '-20px'};
  margin-bottom: 15px;
  text-align: center;
  font-size: 10px;
  line-height: 10px;
  color: #849096;
`

export default AddonInfoTextStyled
