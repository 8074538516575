import { BodyText, StackSpacing } from '@epilot/base-elements'
import {
  EnumProductControl,
  EnumProductControlData,
  PricingItem,
} from '@epilot/base-modules'
import { ControlProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { priceTitles } from 'components/hooks/usePrice'
import config from 'config'
import { SellingOption } from 'lib/api.types'
import { addInjectedAddonsToCart } from 'lib/cart-utils'
import { formatPrice } from 'lib/format-currency'
import { getInitialConfig, getInjectedData } from 'modules/app/selectors'
import { cartAddProduct, cartRemoveProduct } from 'modules/shop/reducer'
import {
  getCart,
  getProductPriceInterval,
  getProductPricingBusiness,
  getProductsByCategory,
  getShop,
} from 'modules/shop/selectors'
import { Product, ProductCategory } from 'modules/shop/types'
import { PayloadResults } from 'modules/step/getResultsFromMapping/types'
import { goNext } from 'modules/step/reducer'
import { getAllResultData } from 'modules/step/selectors'
import { GlobalAppState } from 'modules/types'

import { Builder } from '../../../../src/types/widget-config'
import {
  generatePriceSheetData,
  hasPriceSheet,
} from '../../../lib/PriceSheet/generatePriceSheetPackage'

export type ProductSelectionRendererOptions = {
  previewMode?: boolean
  category: ProductCategory
  showBonusInformation?: boolean
  showFeatures?: boolean
  showFootnote?: boolean
}

const ProductSelectionRenderer = ({
  uischema,
  handleChange,
  path,
  data,
}: ControlProps): React.ReactElement | null => {
  const dispatch = useDispatch()
  const {
    category,
    previewMode,
    showBonusInformation,
    showFeatures,
    showFootnote,
  } = uischema.options as ProductSelectionRendererOptions
  let products = useSelector((state: GlobalAppState) =>
    getProductsByCategory(state, category),
  )
  const state = useSelector((state: GlobalAppState) => getShop(state))
  const pricingBusiness = useSelector(getProductPricingBusiness)
  const priceInterval = useSelector(getProductPriceInterval)
  const allResult = useSelector(getAllResultData) as PayloadResults
  const config = useSelector(getInitialConfig)
  const builder = config?.builder as Builder
  const injectedData = useSelector((state: GlobalAppState) =>
    getInjectedData(state),
  )

  const monitorIdString = state.monitorFieldsLastValue.product_ids as string

  if (monitorIdString !== undefined) {
    const monitorIds = monitorIdString.split(',')
    const sortedProducts: Product[] = []

    monitorIds.forEach((productId) => {
      const searchProduct = products.find((ele) => ele.id === productId)

      if (searchProduct !== undefined) sortedProducts.push(searchProduct)
    })

    products = sortedProducts
  }
  const productsInCart = useSelector(getCart)

  const resetCart = async () => {
    productsInCart.forEach(
      ({ id: productInCartId, productCategory: productInCartCategory }) => {
        if (category === productInCartCategory)
          dispatch(cartRemoveProduct(productInCartId))
      },
    )
  }

  const handleAddProduct = async (
    selectedProduct:
      | EnumProductControlData
      | EnumProductControlData[]
      | undefined,
  ) => {
    // Clean product with the same category from the cart
    await resetCart()
    if (!Array.isArray(selectedProduct)) {
      if (selectedProduct?.product?.id) {
        const selectedProductId = selectedProduct.product.id

        dispatch(cartAddProduct(selectedProductId, category))

        // if injectedData and product selection step not skipped,
        // then handle adding an addon on every product selection click
        addInjectedAddonsToCart(
          injectedData,
          products,
          selectedProductId,
          dispatch,
        )
      }

      await handleChange(path, selectedProduct?.product?.id)
    }
    if (!previewMode && selectedProduct) {
      await dispatch(goNext())
    }
  }

  const productTiles = products.map((product) => {
    const productTile = getProductTile({
      product,
      showFeatures,
      showBonusInformation,
      showFootnote,
      pricingBusiness,
      priceInterval,
      builder,
      allResult,
    })

    return { ...productTile }
  })

  return (
    <>
      <EnumProductControl
        actionButtonLabel="Jetzt bestellen"
        actionButtonRemoveLabel="Ausgewählt"
        onChange={handleAddProduct}
        options={productTiles}
        value={data}
      />
    </>
  )
}

function getProductTile({
  product,
  showFeatures = true,
  showBonusInformation = true,
  showFootnote = true,
  pricingBusiness = true,
  priceInterval = 'MONTHLY',
  builder,
  allResult,
}: {
  product: Product
  showFeatures?: boolean
  showBonusInformation?: boolean
  showFootnote?: boolean
  pricingBusiness?: boolean
  priceInterval?: string
  builder?: Builder
  allResult?: PayloadResults
}) {
  const { id, name, customFields, price, image, attachments } = product
  const {
    estimatedPricePerMonth,
    oneTimePrice,
    variablePrices,
  } = getProductPrice(price, pricingBusiness, priceInterval)
  const productFeatures = showFeatures
    ? getProductFeatures(customFields)
    : undefined
  const {
    'Bonus Titel': bonusTitle,
    'Bonus Detail Info': bonusDetailInfo,
    'Titel Konditionen und Zusatzinfos': additionalInformationTitle,
    'Beschreibung Konditionen und Zusatzinfos': conditions,
  } = customFields
  const productImage = image?.src ? config.IMAGE_HOST + image?.src : undefined
  const imageCaption =
    showBonusInformation && (bonusTitle || bonusDetailInfo)
      ? `${bonusTitle}\n${bonusDetailInfo}`
      : undefined
  let footnoteText = ''

  variablePrices?.forEach(({ title, price }) => {
    if (
      title === priceTitles.VARIABLE_PRICE ||
      title === priceTitles.BASE_MONTH_PRICE
    )
      footnoteText +=
        footnoteText === '' ? `${title} ${price}` : `\n ${title} ${price}`
  })

  const prices: PricingItem[] = []

  if (estimatedPricePerMonth)
    prices.push({
      price: estimatedPricePerMonth,
      priceCurrency: '€',
      title: 'monatlich',
      interval: 'monthly',
    })
  if (oneTimePrice)
    prices.push({
      price: oneTimePrice,
      title: 'einmalig',
      interval: 'onetime',
      priceCurrency: '€',
    })

  const collapsedDetails = (additionalInformationTitle || conditions) && (
    <StackSpacing scale={1}>
      <BodyText color="textPrimary">
        <b>{additionalInformationTitle}</b>
      </BodyText>
      <BodyText color="textSecondary">{conditions}</BodyText>
    </StackSpacing>
  )
  const includePriceSheet = hasPriceSheet(product, builder)
  const originalFields = attachments?.map(({ src, name }) => ({
    fileUrl: config.IMAGE_HOST + src,
    fileName: name,
    icon: 'description',
  }))
  const priceSheetFile = [
    {
      fileUrl: '',
      fileName: 'Preisblatt',
      icon: 'description',
    },
  ]
  const files = includePriceSheet
    ? originalFields
      ? originalFields.concat(priceSheetFile)
      : priceSheetFile
    : originalFields

  const priceSheetData = generatePriceSheetData(
    allResult as PayloadResults,
    product,
    builder as Builder,
  )

  // Parse product information to product tile component
  return {
    id,
    productImage,
    productName: name,
    footnote: showFootnote ? footnoteText : undefined,
    imageCaption,
    productFeatures,
    prices,
    collapsedDetails,
    attachments: files && files?.length > 0 ? files : undefined,
    priceSheetPackage: priceSheetData,
  }
}

function getProductFeatures(customFields: { [key: string]: string }) {
  const {
    'Tarifmerkmal 01': tariffDetails1,
    'Tarifmerkmal 02': tariffDetails2,
    'Tarifmerkmal 03': tariffDetails3,
    'Tarifmerkmal 04': tariffDetails4,
    'Tarifmerkmal 05': tariffDetails5,
    'Tarifmerkmal 06': tariffDetails6,
    'Tarifmerkmal 07': tariffDetails7,
  } = customFields

  return [
    tariffDetails1,
    tariffDetails2,
    tariffDetails3,
    tariffDetails4,
    tariffDetails5,
    tariffDetails6,
    tariffDetails7,
  ]
    .filter((value) => value && value !== '')
    .map((feature) => ({ checked: true, text: feature }))
}

function getProductPrice(
  price: SellingOption,
  pricingBusiness: boolean,
  priceInterval: string,
) {
  // Destructure price
  const {
    estimated_monthly_price: estimatedPricePerMonth,
    estimated_yearly_price: estimatedPricePerYear,
    calculated_consumption_price: variablePrice,
    recurring_price: basePrice,
    one_time_price: oneTimePrice,
  } = price
  const {
    monthly: basePricePerMonth,
    yearly: basePricePerYear,
    base_month_price: baseMonthPrice,
  } = basePrice || {}

  const variablePrices = []

  if (estimatedPricePerMonth)
    variablePrices.push({
      title: priceTitles.ESTIMATED_PRICE_PER_MONTH,
      price: formatPrice(estimatedPricePerMonth, pricingBusiness),
    })
  if (variablePrice)
    variablePrices.push({
      title: priceTitles.VARIABLE_PRICE,
      price: formatPrice(variablePrice, pricingBusiness, true),
    })
  if (baseMonthPrice)
    variablePrices.push({
      title: priceTitles.BASE_MONTH_PRICE,
      price: formatPrice(baseMonthPrice, pricingBusiness),
    })
  if (estimatedPricePerYear)
    variablePrices.push({
      title: priceTitles.ESTIMATED_PRICE_PER_YEAR,
      price: formatPrice(estimatedPricePerYear, pricingBusiness),
    })
  if (basePricePerYear)
    variablePrices.push({
      title: priceTitles.BASE_PRICE_PER_YEAR,
      price: formatPrice(basePricePerYear, pricingBusiness),
    })

  return {
    estimatedPricePerMonth:
      estimatedPricePerMonth &&
      (pricingBusiness
        ? estimatedPricePerMonth.amount_net
        : estimatedPricePerMonth.amount_gross),
    estimatedPricePerYear:
      estimatedPricePerYear &&
      (pricingBusiness
        ? estimatedPricePerYear.amount_net
        : estimatedPricePerYear.amount_gross),
    variablePrice:
      variablePrice &&
      (pricingBusiness ? variablePrice.amount_net : variablePrice.amount_gross),
    basePricePerMonth:
      basePricePerMonth &&
      (pricingBusiness
        ? basePricePerMonth.amount_net
        : basePricePerMonth.amount_gross),
    basePricePerYear:
      basePricePerYear &&
      (pricingBusiness
        ? basePricePerYear.amount_net
        : basePricePerYear.amount_gross),
    oneTimePrice:
      oneTimePrice &&
      (pricingBusiness ? oneTimePrice.amount_net : oneTimePrice.amount_gross),
    baseMonthPrice:
      baseMonthPrice &&
      (pricingBusiness
        ? baseMonthPrice.amount_net
        : baseMonthPrice.amount_gross),
    variablePrices,
    isMonthlyPrice: priceInterval === 'MONTHLY',
  }
}

export const productSelectionTester: RankedTester = rankWith(
  99,
  uiTypeIs('ProductSelection'),
)

export default withJsonFormsControlProps(ProductSelectionRenderer)
