import { Grid, Step, StepLabel, Stepper } from '@epilot/base-elements'
import {
  Layout,
  LayoutProps,
  RankedTester,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core'
import {
  ResolvedJsonFormsDispatch,
  withJsonFormsLayoutProps,
} from '@jsonforms/react'
import React from 'react'
import { useSelector } from 'react-redux'

import { getActiveStepId } from 'modules/step/selectors'

import { CustomConnector } from './stepper-navigation-custom-connector'
import { CustomIcon } from './stepper-navigation-custom-icon'

export type StepperNavigationRendererOptions = {
  steps: string[]
}

const StepperNavigationRenderer = ({
  uischema,
  enabled,
  path,
  renderers,
  schema,
  cells,
}: LayoutProps): React.ReactElement | null => {
  const layout = uischema as Layout
  const { steps } = (uischema?.options ||
    {}) as StepperNavigationRendererOptions
  const activeStepId = useSelector(getActiveStepId)
  const activeStepIndex = steps.indexOf(activeStepId)

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Stepper
          activeStep={activeStepIndex}
          connector={<CustomConnector />}
          style={{ background: 'transparent' }}
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                StepIconComponent={CustomIcon}
                className={index === activeStepIndex ? 'Mui-active' : ''}
              />
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        {layout.elements.map((child, index) => (
          <ResolvedJsonFormsDispatch
            cells={cells}
            enabled={enabled}
            key={index}
            path={path}
            renderers={renderers}
            schema={schema}
            uischema={child}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export const stepperNavigationTester: RankedTester = rankWith(
  1,
  uiTypeIs('StepperNavigation'),
)

export default withJsonFormsLayoutProps(StepperNavigationRenderer)
