import { BodyText, H2 } from '@epilot/components/dist'
import React from 'react'

import { SecondaryTitleDescriptionStyled } from './product-details-styled'

type ProductDetailsAdditionalInformationProps = {
  title?: string
  conditions?: string
}

const ProductDetailsAdditionalInformation = ({
  title,
  conditions,
}: ProductDetailsAdditionalInformationProps): JSX.Element => {
  return (
    <SecondaryTitleDescriptionStyled>
      {title && <H2 color="textPrimary">{title}</H2>}
      {conditions && <BodyText>{conditions}</BodyText>}
    </SecondaryTitleDescriptionStyled>
  )
}

export default ProductDetailsAdditionalInformation
