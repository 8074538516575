/* eslint-env node */
import { ErrorObject } from 'ajv'

/* eslint-disable @typescript-eslint/no-var-requires */
const localizeAjvErrorsDE = require('ajv-i18n/localize/de')
/* eslint-enable @typescript-eslint/no-var-requires */

// Caution, Error objects get muted -> needed for jsonforms
const localizeError = (errors: ErrorObject[]) => {
  localizeAjvErrorsDE(errors)

  errors.forEach((item) => {
    if (item.keyword === 'required' || item.dataPath === 'add_ons') {
      item.message = 'Ist erforderlich.'
    } else if (item.keyword === 'pattern') {
      item.message = 'Bitte Eingabe prüfen.'
    } else if (item.keyword === 'format' && item.schema === 'email') {
      item.message = 'Ungültige E-Mail Adresse.'
    } else if (item.schema === 'integer') {
      item.message = 'Ungültige Zahl.'
    } else if (item.parentSchema) {
      // Complex validation error messages
      const schema = item.parentSchema as { type: string; enum: string }

      if (
        schema['type'] === 'boolean' &&
        Array.isArray(schema['enum']) &&
        schema['enum'][0] === true
      ) {
        // type boolean with required enum:[true]
        item.message = 'Ist erforderlich.'
      }
    }
  })
}

export default localizeError
