import { ContractType } from 'lib/api/types'

export const contractTypeFind = (
  initialValue: string | undefined,
): ContractType => {
  let contractType: ContractType = ''

  if (initialValue) {
    if (initialValue.includes('Versorgerwechsel')) contractType = 'switching'
    if (initialValue.includes('Umzug')) contractType = 'new_contract'
  }

  return contractType
}
