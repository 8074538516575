import { Hidden, HiddenProps } from '@epilot/base-elements'
import {
  PricingItem,
  Product as ProductProps,
  ShoppingCartV2,
} from '@epilot/base-modules'
import { Layout, LayoutProps, rankWith, uiTypeIs } from '@jsonforms/core'
import {
  ResolvedJsonFormsDispatch,
  withJsonFormsLayoutProps,
} from '@jsonforms/react'
import React from 'react'
import { useSelector } from 'react-redux'

import { defaultConsumptionCalculatorSchemaProperties } from 'assets/power-and-gas/config/schemas/consumption-calculator.schema'
import { priceTitles } from 'components/hooks/usePrice'
import config from 'config'
import { formatCurrency } from 'lib/format-currency'
import {
  getCartTotalSummary,
  getCartWithDetails,
  getProductPriceIntervalLabel,
  getProductPricingBusiness,
} from 'modules/shop/selectors'
import { DisplayCartItem } from 'modules/shop/types'
import { getAllResultData } from 'modules/step/selectors'

type CartElementProps = {
  onCloseClick?: () => void
  footnote?: string
  bottomComponent?: React.ReactNode
}

export const CartElement = (props: CartElementProps) => {
  const cart = useSelector(getCartWithDetails)
  const pricingBusiness = useSelector(getProductPricingBusiness)
  const priceIntervalLabel = useSelector(getProductPriceIntervalLabel)
  const stepsData = useSelector(getAllResultData)

  const { onCloseClick, bottomComponent, footnote } = props

  const products: ProductProps[] = []

  const getProductPrices = (product: DisplayCartItem) => {
    const price =
      priceIntervalLabel === 'Monat'
        ? pricingBusiness
          ? product.price?.estimated_monthly_price?.amount_net || 0
          : product.price?.estimated_monthly_price?.amount_gross || 0
        : pricingBusiness
        ? product.price?.estimated_yearly_price?.amount_net || 0
        : product.price?.estimated_yearly_price?.amount_gross || 0

    const {
      monthly: basePricePerMonth,
      yearly: basePricePerYear,
      base_month_price: baseMonthPrice,
      base_yearly_price: baseYearPrice,
    } = product?.price?.recurring_price || {}

    const variablePrice = pricingBusiness
      ? product.price?.calculated_consumption_price?.amount_net || 0
      : product.price?.calculated_consumption_price?.amount_gross || 0

    const estimatedPricePerYear = pricingBusiness
      ? product?.price?.estimated_yearly_price?.amount_net
      : product?.price?.estimated_yearly_price?.amount_gross

    const oneTimePrice = pricingBusiness
      ? product.price?.one_time_price?.amount_net || 0
      : product.price?.one_time_price?.amount_gross || 0

    return {
      price,
      oneTimePrice,
      variablePrice,
      baseMonthPrice: pricingBusiness
        ? baseMonthPrice?.amount_net
        : baseMonthPrice?.amount_gross,
      baseYearPrice: pricingBusiness
        ? baseYearPrice?.amount_net
        : baseYearPrice?.amount_gross,
      basePricePerMonth: pricingBusiness
        ? basePricePerMonth?.amount_net
        : basePricePerMonth?.amount_gross,
      basePricePerYear: pricingBusiness
        ? basePricePerYear?.amount_net
        : basePricePerYear?.amount_gross,
      estimatedPricePerYear,
    }
  }

  const getAddonsPrices = (product: DisplayCartItem) => {
    if (product.addons && product.addons.length > 0) {
      product.addons.forEach((addonItem) => {
        const { name = '', price, image_src: addonThumbnail } = addonItem
        const oneTimePrice = pricingBusiness
          ? price?.one_time_price?.amount_net
          : price?.one_time_price?.amount_gross
        const recurringPrice = pricingBusiness
          ? priceIntervalLabel === 'Monat'
            ? price?.recurring_price?.monthly?.amount_net ||
              (price?.recurring_price?.yearly?.amount_net &&
                price?.recurring_price?.yearly?.amount_net / 12)
            : price?.recurring_price?.yearly?.amount_net
          : priceIntervalLabel === 'Monat'
          ? price?.recurring_price?.monthly?.amount_gross ||
            (price?.recurring_price?.yearly?.amount_gross &&
              price?.recurring_price?.yearly?.amount_gross / 12)
          : price?.recurring_price?.yearly?.amount_gross
        const durationNumber = addonItem.price?.duration?.slice(0, -1)
        const durationInterval = addonItem.price?.duration?.slice(-1)

        const prices: PricingItem[] = []
        const pricingItems: PricingItem[] = []

        if (recurringPrice)
          prices.push({
            price: recurringPrice,
            priceCurrency: '€',
            title: priceIntervalLabel === 'Monat' ? 'mtl.' : 'jährlich',
            interval: priceIntervalLabel === 'Monat' ? 'monthly' : 'monthly',
          })
        if (oneTimePrice)
          prices.push({
            price: oneTimePrice,
            priceCurrency: '€',
            title: 'Einmalig',
            interval: 'onetime',
          })
        if (durationNumber) {
          pricingItems.push({
            price: parseInt(durationNumber),
            priceDecimals: 0,
            priceCurrency: durationInterval === 'M' ? 'Monate' : 'Jahre',
            title: 'Zahlungslaufzeit',
          })
        }

        products.push({
          productName: name,
          prices,
          pricingItems,
          productImage: addonThumbnail
            ? config.IMAGE_HOST + addonThumbnail
            : undefined,
        })
      })
    }

    return null
  }

  cart.map((product: DisplayCartItem) => {
    const { image_src, custom_fields } = product

    const {
      price,
      oneTimePrice,
      variablePrice,
      baseMonthPrice,
      baseYearPrice,
    } = getProductPrices(product)

    const prices: PricingItem[] = []
    const pricingItems: PricingItem[] = []

    if (price)
      prices.push({
        price: price,
        priceCurrency: '€',
        title: priceIntervalLabel === 'Monat' ? 'mtl.' : 'jährlich',
        interval: priceIntervalLabel === 'Monat' ? 'monthly' : 'monthly',
      })
    if (oneTimePrice)
      prices.push({
        price: oneTimePrice,
        priceCurrency: '€',
        title: 'Einmalig',
        interval: 'onetime',
      })
    if (variablePrice)
      pricingItems.push({
        price: variablePrice * 100,
        priceCurrency: 'ct',
        title: priceTitles.VARIABLE_PRICE,
      })

    if (baseYearPrice) {
      pricingItems.push({
        price: baseYearPrice || 0,
        priceCurrency: '€',
        title: priceTitles.BASE_YEAR_PRICE,
      })
    } else if (baseMonthPrice) {
      pricingItems.push({
        price: baseMonthPrice ? baseMonthPrice * 12 : 0,
        priceCurrency: '€',
        title: priceTitles.BASE_YEAR_PRICE,
      })
    }

    const consumption =
      product.productCategory === 'POWER'
        ? stepsData[
            defaultConsumptionCalculatorSchemaProperties.power_consumption.name
          ]['amount']
        : product.productCategory === 'GAS'
        ? stepsData[
            defaultConsumptionCalculatorSchemaProperties.gas_consumption.name
          ]['amount']
        : 0

    pricingItems.push({
      price: consumption,
      priceCurrency: 'kWh',
      priceDecimals: 0,
      title: 'Verbrauchsangabe/Jahr',
    })

    if (product.price?.duration) {
      const durationNumber = parseInt(product.price.duration.slice(0, -1))
      const durationInterval = product.price.duration.slice(-1)
      const durationIntervalText =
        (durationInterval === 'M' ? 'Monat' : 'Jahr') +
        (durationNumber > 1 ? 'e' : '')

      pricingItems.push({
        price: durationNumber,
        priceDecimals: 0,
        priceCurrency: durationIntervalText,
        title: 'Mindestlaufzeit',
      })
    }

    if (custom_fields && custom_fields['Kündigungsfrist']) {
      const [monate, monateDetails] = custom_fields['Kündigungsfrist'].split(
        ' ',
      )

      pricingItems.push({
        price: parseInt(monate),
        priceDecimals: 0,
        priceCurrency:
          monateDetails || (parseInt(monate) === 1 ? 'Monat' : 'Monate'),
        title: 'Kündigungsfrist',
      })
    }
    products.push({
      productName: product.name,
      prices,
      pricingItems,
      productImage: image_src ? config.IMAGE_HOST + image_src : undefined,
    })
    getAddonsPrices(product)

    return null
  })
  const cartTotalValues = useSelector(getCartTotalSummary)
  const isEmpty = cart.length < 1

  const containsBroadband =
    cart.filter(
      (product) => product.productCategory.toLocaleUpperCase() === 'BROADBAND',
    ).length > 0

  const recurringTotal = pricingBusiness
    ? cartTotalValues.net
    : cartTotalValues.gross
  const oneTimeTotal = pricingBusiness
    ? cartTotalValues.oneTimeNet
    : cartTotalValues.oneTimeGross

  const summaryContent = [
    {
      label:
        priceIntervalLabel === 'Monat'
          ? 'Monatliche Zahlung'
          : 'Jährlich Zahlung',
      value: formatCurrency(recurringTotal),
    },
  ]

  if (!isEmpty && (containsBroadband || oneTimeTotal > 0)) {
    summaryContent.push({
      label: 'Einmalige Zahlung',
      value: formatCurrency(oneTimeTotal),
    })
  }

  return (
    <ShoppingCartV2
      bottomComponent={bottomComponent}
      footnote={footnote || 'Alle Preise inkl. MwSt.'}
      onCloseClickHandler={onCloseClick}
      products={products}
      showCloseButton={onCloseClick != null}
      title={`Produkte im Warenkorb (${products.length})`}
    />
  )
}

type CartRendererOptions = {
  hiddenProps: HiddenProps
}

const CartRenderer = ({
  uischema,
  visible,
  enabled,
  path,
  renderers,
  schema,
}: LayoutProps) => {
  const { hiddenProps = { smDown: true } } = (uischema?.options ||
    {}) as CartRendererOptions
  const elements = (uischema as Layout)?.elements
  const footnote = uischema.options?.footnote

  return (
    <Hidden xsUp={!visible} {...hiddenProps}>
      <CartElement
        bottomComponent={elements?.map((e, index) => (
          <ResolvedJsonFormsDispatch
            enabled={enabled}
            key={index}
            path={path}
            renderers={renderers}
            schema={schema}
            uischema={e}
          />
        ))}
        footnote={footnote}
      />
    </Hidden>
  )
}

export const cartRendererTester = rankWith(2, uiTypeIs('Cart'))

export default withJsonFormsLayoutProps(CartRenderer)
