import { DigitalSignature, DigitalSignatureProps } from '@epilot/base-modules'
import {
  ControlProps,
  optionIs,
  OwnPropsOfEnum,
  RankedTester,
  rankWith,
  JsonSchema7,
} from '@jsonforms/core'
import { withJsonFormsEnumProps } from '@jsonforms/react'
import html2canvas from 'html2canvas'
import React from 'react'
import { useSelector } from 'react-redux'

import { getActiveStepNextButtonLabel } from 'modules/step/selectors'
export type DigitalSignatureControlOptions = DigitalSignatureProps
const OverviewScreenshotControl = ({
  handleChange,
  path,
  errors,
  enabled,
  schema,
  uischema,
}: ControlProps & OwnPropsOfEnum & { schema: JsonSchema7 }) => {
  if (!uischema?.options) return null

  const { label = '' } = uischema || {}
  const nextButtonLabel = useSelector(getActiveStepNextButtonLabel)

  const { errorMessage } = schema

  const { ...props } = uischema.options as DigitalSignatureControlOptions

  const handleStoreSignature = () => {
    const screenshotElement = document.getElementById('signature')

    let area = screenshotElement
    // The deep is used for locating the DOM layers and calculate the height for the screenshot
    const deep = uischema.options?.deep
    let maxHeight = 0

    for (let i = 0; i < deep; i++) {
      const parent = area?.parentElement

      if (parent) {
        area = parent as HTMLElement
        const areaHeight = (area as HTMLElement).offsetHeight

        if (areaHeight > maxHeight) {
          maxHeight = areaHeight
        }
      } else {
        area = null
        console.log('Screenshot root not find!')
        break
      }
    }

    if (area) {
      const svgElements = area.querySelectorAll('svg')

      // the library can't support SVG element well
      // when render svg element on a canvas, here for
      // fixing SVG's width and height attribute missing
      if (svgElements) {
        for (let i = 0; i < svgElements.length; i++) {
          const curSvg = svgElements[i]
          const width = curSvg.width.baseVal.value
          const height = curSvg.height.baseVal.value

          width && curSvg.setAttribute('width', width.toString())
          height && curSvg.setAttribute('height', height.toString())
        }
      }

      screenshotElement && (screenshotElement.style.display = 'none')
      // Convert html element to an image
      html2canvas(area as HTMLElement, {
        useCORS: true,
        height: maxHeight,
        allowTaint: true,
        // Predicate function which removes the matching elements from the render
        ignoreElements: (e) => {
          // Remove the "Submit button" and "Editor button" element
          if (
            e.tagName == 'BUTTON' &&
            e.children[0] !== undefined &&
            e.children[0].textContent?.includes(nextButtonLabel)
          )
            return true
          else if (e.tagName === 'svg' && e.getAttribute('fill') !== null) {
            return true
          }

          return false
        },
      })
        .then((canvas) => {
          const dataURL = canvas.toDataURL('image/png')

          return dataURL
        })
        .then((dataURL) => {
          handleChange(path, dataURL)

          /* This part code is used for open the image in a new tab
          to let you preview the screenshot quickly and easily
           */
          // const w = window.open('')
          // const image = new Image()

          // image.src = dataURL
          // setTimeout(function () {
          //   w?.document.write(image.outerHTML)
          // }, 0)
        })

      screenshotElement && (screenshotElement.style.display = 'initial')
    }

    if (!uischema?.options) return null
  }
  const handleSaveScreenshotWithTimeDelay = () => {
    setTimeout(() => handleStoreSignature(), 10)
  }

  return (
    <div>
      <div id="signature"></div>
      <DigitalSignature
        disabled={!enabled}
        error={
          errors
            ? errorMessage && typeof errorMessage
              ? errorMessage
              : errors
            : undefined
        }
        onSave={handleSaveScreenshotWithTimeDelay}
        title={label as string}
        {...props}
      ></DigitalSignature>
    </div>
  )
}

export default withJsonFormsEnumProps(OverviewScreenshotControl as never)

export const OverviewScreenshotRendererTester: RankedTester = rankWith(
  10,
  optionIs('format', 'overviewScreenshot'),
)
