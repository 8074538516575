import React from 'react'
import { connect } from 'react-redux'

import config from 'config'
import { getClientLogo, getInitialConfig } from 'modules/app/selectors'
import { GlobalAppState } from 'modules/types'
import { WidgetConfig } from 'types/widget-config'

type LogoProps = {
  clientLogo: { url?: string; style?: React.CSSProperties }
  initialConfig: WidgetConfig | null
}

const Logo = ({ clientLogo, initialConfig }: LogoProps) => {
  const logoUrl = config.LOGO_PREVIEW || (clientLogo.url ? clientLogo.url : '')
  const { builder = {} } = initialConfig || {}
  const { useBaseElementsLibrary = false } = builder

  return logoUrl ? (
    <img
      alt="Logo"
      data-component="ui-wrapper-header-logo"
      src={logoUrl}
      style={
        useBaseElementsLibrary
          ? { maxHeight: '32px' }
          : { maxHeight: '32px', ...clientLogo.style }
      }
    />
  ) : (
    <div
      style={
        useBaseElementsLibrary
          ? { maxHeight: '32px' }
          : { maxHeight: '32px', ...clientLogo.style }
      }
    ></div>
  )
}

export default connect((state: GlobalAppState) => ({
  clientLogo: getClientLogo(state),
  initialConfig: getInitialConfig(state),
}))(Logo)
