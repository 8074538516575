import { SmallCheckIcon, CircleCheckIcon, Checkbox } from '@epilot/components'
import React from 'react'
import { useSelector } from 'react-redux'

import { getTheme } from 'modules/app/selectors'

import ProductButtonStyled from './product-button-styled'
import ProductDetailsStyled from './product-details-styled'

export type ProductDetailsProps = {
  description: string | undefined
  isActive: boolean
  open: boolean
  selectButtonLabel: string
}

const ProductDetails = ({
  description,
  isActive,
  open,
  selectButtonLabel,
}: ProductDetailsProps): JSX.Element => {
  const theme = useSelector(getTheme)

  return (
    <ProductDetailsStyled isActive={isActive} open={open}>
      <ProductButtonStyled className={isActive ? 'active' : ''}>
        <Checkbox
          checked={isActive}
          checkedIcon={
            <div className={'checkedIcon-background'}>
              <div className={'checkedIcon-icon'}>
                <SmallCheckIcon
                  color={theme?.palette?.primary?.main}
                  size={'smallest'}
                />
              </div>
            </div>
          }
          icon={<div className={'button-icon'}></div>}
          label={<span>{selectButtonLabel}</span>}
        />
      </ProductButtonStyled>
      {open &&
        description &&
        description?.split(/\r?\n/).map((text, i) => {
          return (
            <div className="text-container" key={i}>
              {text.startsWith('•') ? (
                <div className="circle-text-container">
                  <CircleCheckIcon
                    color={theme?.palette?.primary?.main}
                  ></CircleCheckIcon>{' '}
                  <p>{text.split('•')[1]}</p>
                </div>
              ) : (
                <p key={i}>{text}</p>
              )}
            </div>
          )
        })}
    </ProductDetailsStyled>
  )
}

export default ProductDetails
