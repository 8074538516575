import { styled } from '@epilot/components'

import { Theme } from '../../../modules/app/types'

type ProductBonusBoxStyledProps = {
  theme: Theme
}

const ProductBonusBoxStyled = styled.span<ProductBonusBoxStyledProps>`
  color: white;
  background-color: ${(props: ProductBonusBoxStyledProps) =>
    props.theme.palette.primary.main};
  position: absolute;
  margin-top: 15px;
  margin-left: 0;
  padding: 5px;
  padding-left: 15px;
  width: 40%;
  display: flex;
  text-align: left;
  align-items: center;
  font-weight: bold;
`

export default ProductBonusBoxStyled
