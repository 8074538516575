import { ObjectKeyString } from 'lib/types'
import { Addon, ShopState } from 'modules/shop/types'
import { GlobalResultData } from 'modules/step/types'

import { evaluateCondition } from './evaluateCondition'
import { getCartItems } from './getCartItems'
import { getCartTotalSummary } from './getCartTotalSummary'
import { getInputDataFromMapping } from './getInputDataFromMapping'

const logicRegExp = /^\((.+)\)\?\((.+)\):\((.+)\)$/

export const getConsumption = (
  consumption: { amount: number } | number | undefined,
) => {
  if (typeof consumption === 'undefined' || typeof consumption === 'number') {
    return consumption
  }

  return consumption.amount
}

export const getAddons = (shop: ShopState) => {
  const cart = shop.cart
  const products = shop.products

  if (!shop.cart || shop.cart.length === 0) return null

  const cartProductIds = cart.map((product) => product.id)
  const productsInCart = products.filter(({ id }) =>
    cartProductIds.includes(id),
  )
  const addons: Addon[] = []

  productsInCart.forEach((product) =>
    product.addons.forEach((addon) => addons.push(addon)),
  )
  const cartAddons: ObjectKeyString<number[]> = {}

  shop.cart.forEach((cartItem) => {
    cartItem.addons?.forEach((addonCartItem) => {
      const addonIndex = addons.findIndex(
        (addon) => addon.id.toString() === addonCartItem.id,
      )
      const pricesIds = addons[addonIndex]?.options?.map((option) => option.id)

      if (!cartAddons[addonCartItem.id]) cartAddons[addonCartItem.id] = []

      for (let i = 0; i < (addonCartItem.quantity || 1); i++)
        cartAddons[addonCartItem.id].push(...pricesIds)
    })
  })

  return cartAddons
}

export const getDataFromMapping = (
  mapping: string,
  data: GlobalResultData,
  meta: ObjectKeyString<string>,
  shop: ShopState,
): any => {
  const getFirstProduct = () =>
    shop.products.find((product) => product.id === shop.cart[0]?.id)

  const match = logicRegExp.exec(mapping)

  if (match) {
    const attribute = match[1].split('.')

    if (!(attribute[1] in data)) {
      return null
    }
    if (evaluateCondition(match[1], data))
      return getDataFromMapping(match[2], data, meta, shop)
    else return getDataFromMapping(match[3], data, meta, shop)
  }
  const split = mapping.split('.')

  // Global result data
  if (split[0] === 'data') {
    // step data
    return getInputDataFromMapping(mapping, data)
  } else if (split[0] === 'meta') {
    // Configuration Widget meta data
    return meta[split[1]]
  } else if (split[0] === 'shop') {
    if (split[1] === 'cart') {
      switch (split[2]) {
        case 'firstProductId':
          return shop.cart[0] ? +shop.cart[0].id : null
        case 'firstProductName':
          return getFirstProduct()?.name || null
        case 'firstProductCategory':
          return getFirstProduct()?.productCategory || null
        case 'firstProductCategoryId':
          return getFirstProduct()?.productCategoryId || null
        case 'firstSellingOptionId':
          return shop.cart[0]?.additional
            ? +(shop.cart[0]?.additional[0]?.sellingOptionId as string)
            : getFirstProduct()?.price?.id || null
        case 'firstConsumption':
          return (
            getConsumption(data['gas_consumption']) ||
            getConsumption(data['power_consumption'])
          )
        case 'firstBasePriceNet':
          return shop.productPriceInterval === 'MONTHLY'
            ? getFirstProduct()?.price?.estimated_monthly_price?.amount_net || 0
            : getFirstProduct()?.price?.estimated_yearly_price?.amount_net || 0
        case 'firstBasePriceVat':
          return shop.productPriceInterval === 'MONTHLY'
            ? getFirstProduct()?.price?.estimated_monthly_price?.amount_vat || 0
            : getFirstProduct()?.price?.estimated_yearly_price?.amount_vat || 0
        case 'firstBasePriceGross':
          return (
            getDataFromMapping(
              'shop.cart.firstBasePriceNet',
              data,
              meta,
              shop,
            ) +
            getDataFromMapping('shop.cart.firstBasePriceVat', data, meta, shop)
          )
        case 'firstUnitPriceNet':
          return (
            getFirstProduct()?.price?.calculated_consumption_price
              ?.amount_net || 0
          )
        case 'firstUnitPriceVat':
          return (
            getFirstProduct()?.price?.calculated_consumption_price
              ?.amount_vat || 0
          )
        case 'firstUnitPriceGross':
          return (
            getDataFromMapping(
              'shop.cart.firstUnitPriceNet',
              data,
              meta,
              shop,
            ) +
            getDataFromMapping('shop.cart.firstUnitPriceVat', data, meta, shop)
          )
        case 'pricingBusiness':
          return shop.productPricingBusiness
        case 'productPriceInterval':
          return shop.productPriceInterval
        case 'totalNet':
          return getCartTotalSummary(shop).net || 0
        case 'totalVat':
          return getCartTotalSummary(shop).vat || 0
        case 'totalGross':
          return getCartTotalSummary(shop).gross || 0
        case 'addons': {
          return getAddons(shop)
        }
        case 'items': {
          getCartItems(shop, { data })
        }
      }
    }
  }

  return mapping
}
