import { BlueprintResults } from '../../../types/blueprint-config'

export const results: BlueprintResults = {
  originalDataKey: 'fields',
  // describes how input data is being mapped to a format for RESULTS API
  payloadMapping: {
    widgetId: '',
    custom_fields: [],
    questions: [],
    widgetVersion: '',
    opportunity: {},
    customer: {},
  },
}
