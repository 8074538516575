import {
  OwnPropsOfRenderer,
  RankedTester,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

import OverviewStep from './overview-step'

// OVERVIEW-STEP
const OverviewStepRenderer = (props: OwnPropsOfRenderer) => {
  return <OverviewStep type="step" {...props} />
}

export default withJsonFormsLayoutProps(OverviewStepRenderer)

export const overviewStepTester: RankedTester = rankWith(
  99,
  uiTypeIs('OverviewStep'),
)
