import { H2, MuiTheme, WidgetWrapper, withMuiTheme } from '@epilot/components'
import React from 'react'
import { connect } from 'react-redux'

import { exitFullscreen } from 'modules/app/reducer'
import { getShowHeader, getContentMaxWidth } from 'modules/app/selectors'
import {
  getCartWithDetails,
  getProductPriceIntervalLabel,
  getProductPricingBusiness,
  isCartVisible,
} from 'modules/shop/selectors'
import { DisplayCartItem } from 'modules/shop/types'
import {
  goToStep,
  goNext,
  goPrevious,
  setInputValue,
} from 'modules/step/reducer'
import {
  getActiveStepNextButtonLabel,
  getActiveStepPreviousButtonLabel,
  getIsFirstStep,
  getIsLastStep,
  getIsLoading,
  getProgress,
  getHideFooter,
  getHideHeader,
  getPreviousSteps,
  getSteps,
} from 'modules/step/selectors'
import { GlobalAppState } from 'modules/types'

import config from '../../config'

import CartContent from './cart/cart-content'
import CartFooter from './cart/cart-footer'
import CartTotals from './cart/cart-totals'
import { ReactComponent as ArrowLeft } from './icons/arrow-left.svg'
import { ReactComponent as RemoveIcon } from './icons/remove.svg'
import Logo from './ui-wrapper-header-logo'

import './ui-wrapper.css'

type Props = {
  children: React.ReactElement
  goNext: typeof goNext
  goPrevious: typeof goPrevious
  exitFullscreen: () => void
  goToStep: (stepId: string) => void
  nextButtonLabel: string
  previousButtonLabel: string
  progress: number
  isFirstStep: boolean
  isLastStep: boolean
  isLoading: boolean
  cart: DisplayCartItem[]
  displayCart: boolean
  // cartTotalValue: number
  theme: MuiTheme
  productPriceIntervalLabel: string
  showHeader: boolean
  hideActiveStepFooter: boolean
  hideActiveStepHeader: boolean
  pricingBusiness: boolean
  contentMaxWidth?: string
  previousSteps?: string[]
  steps: any
  setInputValue: typeof setInputValue
}

const UIWrapper = ({
  children,
  goNext,
  goPrevious,
  goToStep,
  nextButtonLabel,
  previousButtonLabel,
  progress,
  isFirstStep,
  isLastStep,
  isLoading,
  cart,
  displayCart,
  exitFullscreen,
  productPriceIntervalLabel,
  showHeader,
  hideActiveStepFooter,
  hideActiveStepHeader,
  pricingBusiness,
  contentMaxWidth,
  previousSteps,
  steps,
  setInputValue,
}: Props) => {
  const handleClose = () => {
    Object.keys(steps).forEach((key) => {
      const { resultData, id } = steps[key] || {}
      const { upload_file, counter_relevant_upload_file, ...data } =
        resultData || {}

      if (upload_file || counter_relevant_upload_file) {
        setInputValue(data, undefined, id)
      }
    })

    exitFullscreen()

    // Return to first step when closing full screen
    if (previousSteps && previousSteps.length > 0) goToStep(previousSteps[0])
  }

  return (
    <WidgetWrapper
      cartContent={
        <CartContent
          cart={cart}
          priceIntervalLabel={productPriceIntervalLabel}
          pricingBusiness={pricingBusiness}
        />
      }
      cartFooterContent={
        <CartFooter
          priceIntervalLabel={productPriceIntervalLabel}
          pricingBusiness={pricingBusiness}
        />
      }
      cartTitleContent={<H2 gutterBottom>Warenkorb</H2>}
      closeButtonComponent={
        !config.HIDE_ITEMS.includes('closeButton') ? <RemoveIcon /> : <div />
      }
      disableNext={isLoading}
      footerContent={<CartTotals />}
      goNext={() => goNext()}
      goPrevious={goPrevious}
      hideCart={isLastStep || !displayCart}
      hideHeader={
        config.HIDE_ITEMS.includes('topBar')
          ? true
          : hideActiveStepHeader ?? !showHeader
      }
      hideNavigation={isLastStep || hideActiveStepFooter}
      hidePrevious={isFirstStep}
      logo={<Logo />}
      maxWidth={contentMaxWidth}
      nextButtonLabel={nextButtonLabel}
      onClose={handleClose}
      previousButtonLabel={previousButtonLabel}
      previousHeaderButtonComponent={
        previousSteps &&
        previousSteps.length > 0 &&
        !config.HIDE_ITEMS.includes('backButton') ? (
          <ArrowLeft />
        ) : (
          <div />
        )
      }
      progress={progress}
    >
      {[children]}
    </WidgetWrapper>
  )
}

export default connect(
  (state: GlobalAppState) => ({
    progress: getProgress(state),
    isFirstStep: getIsFirstStep(state),
    isLastStep: getIsLastStep(state),
    isLoading: getIsLoading(state),
    nextButtonLabel: getActiveStepNextButtonLabel(state),
    previousButtonLabel: getActiveStepPreviousButtonLabel(state),
    cart: getCartWithDetails(state),
    displayCart: isCartVisible(state),
    productPriceIntervalLabel: getProductPriceIntervalLabel(state),
    showHeader: getShowHeader(state),
    hideActiveStepFooter: getHideFooter(state),
    hideActiveStepHeader: getHideHeader(state),
    pricingBusiness: getProductPricingBusiness(state),
    contentMaxWidth: getContentMaxWidth(state),
    previousSteps: getPreviousSteps(state),
    steps: getSteps(state),
  }),
  {
    goNext,
    goPrevious,
    exitFullscreen,
    goToStep,
    setInputValue: setInputValue,
  },
)(withMuiTheme(UIWrapper))
