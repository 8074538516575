import { ConsentInput } from 'lib/api/types'

import { PayloadResults } from '../types'

export const consentsAdd = (results: PayloadResults): ConsentInput[] => {
  const consents: ConsentInput[] = []

  if (results.payment_agreement) {
    consents.push({
      value: results.payment_agreement ? 'Ja' : 'Nein',
      label: 'Einzugsermächtigung',
      type: 'sepa_consent',
    })
  }

  consents.push({
    value: results.accept_terms ? 'Ja' : 'Nein',
    label: 'AGB und Datenschutzerklärung gelesen und akzeptiert.',
    type: 'term_and_conditions',
  })

  consents.push({
    value: results.withdrawal ? 'Ja' : 'Nein',
    label: 'Die Widerrufsbelehrung habe ich zur Kenntnis genommen.',
    type: 'right_of_withdrawal',
  })

  consents.push({
    value: results.accept_promotions ? 'Ja' : 'Nein',
    label: 'Verarbeitung und Nutzung personenbezogener Daten akzeptiert.',
    type: 'marketing_consent',
  })

  consents.push({
    value: results.accept_assignment ? 'Ja' : 'Nein',
    label: 'Beauftragung',
    type: 'assignment_consent',
  })

  return consents
}
