import {
  CartItemReq,
  ContractType,
  MeterReading,
  OpportunityBody,
} from 'lib/api/types'

import { MetaData } from '../../../../modules/app/types'
import { ProductType } from '../../../../modules/shop/types'
import { isValidPayloadKey, PayloadResults } from '../types'

import { consentsAdd } from './consentsAdd'
import { contractTypeFind } from './contractTypeFind'
import { customDatesAdd } from './customDatesAdd'
import { customFieldsAdd } from './customFieldsAdd'
import { readingsAdd } from './readingsAdd'

export const dataOpportunitiesCreate = (
  cart: CartItemReq[],
  results: PayloadResults,
  meta: MetaData,
  builder: Record<string, any> | undefined,
  emailTemplate: string | undefined,
  fallbackProductType: string | undefined,
) => {
  const opportunities: OpportunityBody[] = cart.map((item) => {
    // safe type assertion
    const identifier = (item.product_type.toLowerCase() as unknown) as ProductType

    const keyPreviousProvider = `previous_provider_${identifier}` as const
    const keyDeliveryDate = `delivery_date_${identifier}` as const
    const keyTerminateContract = `terminate_contract_${identifier}` as const
    const keyTerminationDate = `termination_date_${identifier}` as const
    const keySituation = `situation_${identifier}` as const

    if (!isValidPayloadKey(results, keySituation)) {
      throw Error(`Invalid property in ${keySituation}`)
    }

    const contractTypeValue = results[keySituation]
    const contractType: ContractType = contractTypeFind(contractTypeValue)

    const readings: MeterReading[] = readingsAdd(results, identifier)

    return {
      product_type: item.product_type,
      ...(isValidPayloadKey(results, keyPreviousProvider) && {
        previous_provider: results[keyPreviousProvider]?.company_name,
      }),
      ...(isValidPayloadKey(results, keyPreviousProvider) && {
        previous_provider_id: results[keyPreviousProvider]?.bdew_code,
      }),
      ...(isValidPayloadKey(results, keyDeliveryDate) && {
        contract_date: results[keyDeliveryDate] as string,
      }),
      ...(isValidPayloadKey(results, keyTerminateContract) && {
        terminate_old_contract: results[keyTerminateContract] as string,
      }),
      ...(isValidPayloadKey(results, keyTerminationDate) && {
        termination_date: results[keyTerminationDate] as string,
      }),
      readings,
      brand_id: meta.brandId,
      sale_identifier_id: meta?.name,
      enable_send_email: results.send_email,
      email_template_id: emailTemplate,
      custom_fields: customFieldsAdd(results, identifier),
      custom_dates: customDatesAdd(results, identifier, builder),
      consents: consentsAdd(results),
      contract_type: contractType,
    }
  })

  if (!opportunities.length) {
    return [
      {
        product_type: fallbackProductType,
        brand_id: meta.brandId,
        sale_identifier_id: meta?.name,
        enable_send_email: results.send_email,
        email_template_id: emailTemplate,
        consents: consentsAdd(results),
      },
    ] as OpportunityBody[]
  }

  return opportunities
}
