import { clone } from 'modules/step/helper/utils'
import { GlobalResultData } from 'modules/step/types'

// now data clean for P&G bundle and new power journey
export const cleanBroadbandData = (
  data: GlobalResultData,
): GlobalResultData => {
  let dataCopy = clone<GlobalResultData>(data)

  if (dataCopy) {
    // number porting
    dataCopy = cleanNumberPortingStep(dataCopy)
  }

  return dataCopy
}

const cleanNumberPortingStep = (data: GlobalResultData): GlobalResultData => {
  // Step
  if (data['current_telphone_provider_number'] === '0') {
    if (data['terminate_current_connection'] !== 'yes') {
      delete data['terminate_contract_date']
      delete data['service_provider_name']
      delete data['other_service_provider_name']
      delete data['using_phone_number_type']
      delete data['prefix']
      delete data['primary_phone_number']
      delete data['old_subscriber_salutation']
      delete data['old_subscriber_lastname']
      delete data['old_subscriber_name']
      delete data['other_subscriber']
      delete data['other_subscriber_salutation_first']
      delete data['other_subscriber_lastname_first']
      delete data['other_subscriber_name_first']
      delete data['other_subscriber_1']
      delete data['other_subscriber_salutation_second']
      delete data['other_subscriber_lastname_second']
      delete data['other_subscriber_name_second']
      delete data['no_terminate_contract_tip']
    }

    delete data['number_portability_type']
    delete data['second_phone_number']
    delete data['third_phone_number']
    delete data['fourth_phone_number']
    delete data['fifth_phone_number']
    delete data['sixth_phone_number']
    delete data['seventh_phone_number']
    delete data['eighth_phone_number']
    delete data['ninth_phone_number']
    delete data['tenth_phone_number']
  } else {
    delete data['terminate_current_connection']
  }

  return data
}
