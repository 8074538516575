import { Dispatch } from 'redux'

import { cartAddAddon } from 'modules/shop/reducer'

import { JourneyInjectedData } from '../modules/app/types'
import { Product } from '../modules/shop/types'

export function addInjectedAddonsToCart(
  injectedData: JourneyInjectedData,
  products: Product[],
  selectedProductId: string,
  dispatch: Dispatch,
) {
  if (injectedData.productsData?.selectedAddonsIds) {
    const productData = products.find(
      (product) => product.id === selectedProductId,
    )

    if (productData) {
      productData.addons.forEach((addon) => {
        if (injectedData?.productsData?.selectedAddonsIds?.includes(addon.id)) {
          dispatch(cartAddAddon(addon.id.toString(), selectedProductId))
        }
      })
    }
  }
}
