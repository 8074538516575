import { makeStyles, createStyles, Drawer, Hidden } from '@epilot/base-elements'
import { ForwardSummaryTile } from '@epilot/base-modules'
import { LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCartWithDetails } from 'modules/shop/selectors'
import { goNext } from 'modules/step/reducer'
import { getActiveStepNextButtonLabel } from 'modules/step/selectors'

import { CartElement } from '../cart'

export type ForwardSummaryTileRendererOptions = {
  title?: string
  mobileOnly?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    paperAnchorBottom: {
      maxHeight: '100%',
      '& .MuiCard-root': {
        overflow: 'scroll',
      },
    },
    container: {
      position: 'relative',
      width: '100vw',
    },
    placeholder: {
      height: '100px',
    },
    tile: {
      position: 'fixed',
      bottom: '0',
      width: '100%',
      maxWidth: '1128px',
      zIndex: 2,
    },
  }),
)

const ForwardSummaryTileRenderer = ({
  uischema,
}: LayoutProps): React.ReactElement | null => {
  const [open, setOpen] = React.useState(false)
  // Destructure props from config
  const { title = '', mobileOnly } = (uischema?.options ||
    {}) as ForwardSummaryTileRendererOptions
  const dispatch = useDispatch()
  const nextButtonLabel = useSelector(getActiveStepNextButtonLabel)

  const handleButtonClick = () => {
    dispatch(goNext())
  }
  const cart = useSelector(getCartWithDetails)

  const classes = useStyles()

  return (
    <Hidden smUp={mobileOnly}>
      <div className={classes.container}>
        <div className={classes.placeholder} />
        <div className={classes.tile}>
          <ForwardSummaryTile
            buttonLabel={nextButtonLabel}
            mobileLinkText={`Warenkorb ansehen (${cart.length} Artikel)`}
            onButtonClick={handleButtonClick}
            onMobileLinkClick={() => {
              setOpen(true)
            }}
            spacing={1}
            tableContent={[]}
            titleLabel={title}
            {...uischema?.options}
          />
        </div>
        <Drawer
          anchor="bottom"
          aria-describedby="dialog-description"
          aria-labelledby="dialog-title"
          classes={classes}
          elevation={10}
          onClose={() => {
            setOpen(false)
          }}
          open={open}
        >
          <CartElement
            onCloseClick={() => {
              setOpen(false)
            }}
          />
        </Drawer>
      </div>
    </Hidden>
  )
}

export const forwardSummaryTileTester: RankedTester = rankWith(
  1,
  uiTypeIs('ForwardSummaryTile'),
)

export default withJsonFormsLayoutProps(ForwardSummaryTileRenderer)
