import { defaultPersonalInformationSchemaProperties } from './personal-information.schema'

export const defaultPaymentSchemaProperties = {
  payment_method: { name: 'payment_method' },
  account_owner: { name: 'account_owner' },
  sepa: { name: 'sepa' },
  payment_agreement: { name: 'payment_agreement' },
}

export const paymentSchemaProperties = {
  [defaultPaymentSchemaProperties.payment_method.name]: {
    type: 'string',
    enum: ['sepa', 'invoice'],
  },
  [defaultPaymentSchemaProperties.account_owner.name]: {
    type: 'string',
    examples: ['Vorname Nachname'],
    effect: {
      type: 'PREFILL',
      scopes: [
        defaultPersonalInformationSchemaProperties.first_name.name,
        defaultPersonalInformationSchemaProperties.last_name.name,
      ],
      separator: ' ',
    },
  },
  [defaultPaymentSchemaProperties.sepa.name]: {
    type: 'object',
    errorMessage: 'Bitte geben Sie eine gültige IBAN ein.',
    minProperties: 3,
  },
  [defaultPaymentSchemaProperties.payment_agreement.name]: {
    type: 'boolean',
  },
}

export const paymentSchemaRequired = {
  required: [defaultPaymentSchemaProperties.payment_method.name],
  if: {
    properties: {
      [defaultPaymentSchemaProperties.payment_method.name]: {
        enum: ['sepa'],
      },
    },
  },
  then: {
    required: [
      defaultPaymentSchemaProperties.account_owner.name,
      defaultPaymentSchemaProperties.sepa.name,
      defaultPaymentSchemaProperties.payment_agreement.name,
    ],
  },
}
