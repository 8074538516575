export const formatIsoDateString = (
  value: string,
  isSimplifyFormat = false,
  locale = 'de-DE',
  errorString = '',
) => {
  const formatOptions = isSimplifyFormat
    ? {
        year: 'numeric' as const,
        month: 'numeric' as const,
        day: 'numeric' as const,
      }
    : {
        weekday: 'long' as const,
        year: 'numeric' as const,
        month: 'long' as const,
        day: 'numeric' as const,
      }
  const formatter = new Intl.DateTimeFormat(locale, formatOptions)
  const date = new Date(value)

  try {
    return formatter.format(date)
  } catch {
    return errorString
  }
}
