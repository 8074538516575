import { ObjectKeyString } from 'lib/types'
import { Results } from 'modules/app/types'
import { ShopState } from 'modules/shop/types'

import { DataTemplate, GlobalResultData } from '../types'

import { cleanBroadbandData } from './cleanBroadbandData'
import { clone, getPayloadFromMapping } from './utils'

export const parseCreditCheck = (
  template: ObjectKeyString<string | number>,
) => ({
  ...template,
  gender:
    template?.gender === 'MR' ? 'm' : template?.gender === 'MRS' ? 'f' : 'd',
  postal_code: template?.postal_code && +template?.postal_code,
})
export const isNetworkError = (error: Error) =>
  error.message === 'Network Error'

export const generateResultPayload = (
  resultMapping: Results,
  dataInitial: GlobalResultData,
  meta: ObjectKeyString<string>,
  shop: ShopState,
  builder: Record<string, any> | undefined,
) => {
  const originalDataKey = resultMapping.originalDataKey
  const payloadMapping = resultMapping.payloadMapping
  const data = cleanBroadbandData(dataInitial)
  let payload = {} as ObjectKeyString<any>

  if (originalDataKey) {
    //// fix the wrong consumption key for old widget which created by JB
    const productCategory = shop?.productSettings?.categories[0]?.id.toLowerCase()
    const scopeOfConsumption = `${productCategory}_consumption`
    const consumptionKey = productCategory && scopeOfConsumption
    const clonedData = clone(data)

    if (clonedData['consumption']) {
      const consumptionValue = clonedData['consumption']

      delete clonedData['consumption']
      clonedData[consumptionKey] = consumptionValue
    }
    payload[originalDataKey] = clonedData
  }

  // Building payload based on defined mapping fields and extracting
  // data from the Widget into the payload
  if (payloadMapping) {
    const reducedPayload = getPayloadFromMapping(
      payloadMapping,
      data,
      meta,
      shop,
    )

    if (data) {
      payload = {
        ...payload,
        ...reducedPayload,
      }
    }
  }

  if (builder && builder.callEnetInfo)
    payload['callEnetInfo'] = builder.callEnetInfo

  if (meta['name']) payload['name'] = meta['name']

  return payload
}

export const queryTemplateToQuery = (
  template: DataTemplate,
  data: GlobalResultData,
) => {
  const query = {} as ObjectKeyString<string>

  Object.keys(template).forEach((templateKey) => {
    const templateValue = template[templateKey]

    if (Array.isArray(templateValue)) {
      templateValue.forEach((value) => {
        // result 'POWER, GAS, HOUSE"
        if (typeof query[templateKey] !== 'undefined') {
          query[templateKey] += ', ' + value
        } else {
          query[templateKey] = value
        }
      })
    } else {
      const templateSplit = templateValue.split('.')

      if (templateSplit.length > 1) {
        const objectData = data[templateSplit[0]]

        query[templateKey] = objectData && objectData[templateSplit[1]]
      } else {
        // templateValue is a property name
        query[templateKey] = data[templateValue]
      }
    }
  })

  return query
}
