import React from 'react'

import { Addon as AddonType } from 'modules/shop/types'

import Addon from './addon'
import AddonsSelectionStyled from './addons-selection-styled'

type AddonsSelectionProps = {
  selectedIds: string
  addons: AddonType[]
  handleAdd: (id: number, quantity?: number) => void
  handleRemove: (id: number) => void
  singleSelect?: boolean
  withDetails?: boolean
}

const AddonsSelection = ({
  selectedIds,
  addons,
  handleAdd,
  handleRemove,
  singleSelect,
  withDetails = true,
}: AddonsSelectionProps): JSX.Element => {
  const addonsCount = addons.length

  return (
    <AddonsSelectionStyled addonsCount={addonsCount}>
      {addons.map((addon) => {
        const active = selectedIds?.split(';').includes(addon.id.toString())

        return (
          <Addon
            active={active}
            addon={addon}
            handleAdd={handleAdd}
            handleRemove={handleRemove}
            key={addon.id}
            singleSelect={singleSelect}
            withDetails={withDetails}
          />
        )
      })}
    </AddonsSelectionStyled>
  )
}

export default AddonsSelection
