import { BodyText, RadioElement, Radios } from '@epilot/components'
import {
  and,
  ControlProps,
  isStringControl,
  optionIs,
  OwnPropsOfEnum,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsEnumProps } from '@jsonforms/react'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { cartAddProduct } from 'modules/shop/reducer'
import { getProducts } from 'modules/shop/selectors'
import { getShowErrors } from 'modules/step/selectors'
import { ControlElementEpilot } from 'modules/step/types'
import { GlobalAppState } from 'modules/types'

import Radio from './radio'
import RadioDescription from './radio-description'

const RadiosControl = ({
  path,
  handleChange,
  options: enumOptions,
  data,
  uischema,
  enabled,
  errors,
  id,
}: ControlProps & OwnPropsOfEnum) => {
  const handleChangeLocal = (id: string): void => {
    handleChange(path, id)
  }

  // TODO: add rule logic for "DISABLED"
  if (!enumOptions) throw Error('RadiosControl is expecting options/enums')

  // jsonforms changed options type from any[] to EnumOption[] in version 2.5.0
  // instead of having an array of values now we have an array with an object: { value: string, label: string }
  const options = enumOptions?.map(({ value }) => value)

  const radioElement = uischema as ControlElementEpilot
  const radioLabel = radioElement?.label as string
  const radioLabels = radioElement?.labels
  const radioOptions = radioElement?.options
  const radioLayout = radioOptions?.layout
  const radioEqualSize = radioOptions?.equalSize
  const radioPlacement = radioOptions?.placement
  const radioSpace = radioOptions?.space
  const radioColumnWidth = radioOptions?.columnWidth
  const isIconOnRight = radioOptions?.isIconOnRight
  const style = radioOptions?.style
  const boxed = radioOptions?.boxed
  const optionAsProductSelection = radioOptions?.optionAsProductSelection
  const showErrors = useSelector(getShowErrors)
  const products = useSelector((state: GlobalAppState) => getProducts(state))
  const dispatch = useDispatch()

  //set selected product based on selected option
  if (optionAsProductSelection) {
    if (products && products.length > 0) {
      dispatch(
        cartAddProduct(
          products[0].id,
          uischema?.options?.productCategory,
          true,
        ),
      )
    }
  }
  const radioElements = options
    .map((option) => {
      const radioItem = {
        value: option,
        children: <Radio label={option} />,
      } as RadioElement

      if (radioLabels) {
        /**
         * Label
         */
        if (!radioLabels[option]) {
          radioItem.children = undefined

          return radioItem
        }
        // throw Error(
        //   `'uischema' error - enum option '${option}' does not have configured label`,
        // )
        let label = radioLabels[option].label as unknown

        if (typeof label !== 'string') {
          const labelOptions = label as {
            style: React.CSSProperties
            label: string
          }

          label = <div style={labelOptions?.style}>{labelOptions?.label}</div>
        }

        /**
         * SubLabel
         */
        let subLabel = radioLabels[option].subLabel as unknown

        if (
          subLabel &&
          typeof subLabel !== 'string' &&
          typeof subLabel !== 'number'
        ) {
          const labelOptions = subLabel as {
            style: React.CSSProperties
            label: string
          }

          subLabel = <BodyText>{labelOptions.label}</BodyText>
        }

        /**
         * Disabled
         */
        const disabled = radioLabels[option].disabled

        if (disabled) {
          radioItem.disabled = true
        }

        radioItem.children = (
          <Radio label={label as string} subLabel={subLabel as string} />
        )

        // Lower Product selection
        const shortDescription = radioLabels[option].shortDescription
        const description = radioLabels[option].description

        if (shortDescription) {
          radioItem.outerChildren = (
            <RadioDescription
              description={description}
              summary={shortDescription}
            />
          )
        }
        const tip = radioLabels[option].tip
        const descriptionWithTip = radioLabels[option].descriptionWithTip

        if (descriptionWithTip || tip) {
          radioItem.outerChildren = (
            <div>
              {descriptionWithTip && (
                <div
                  dangerouslySetInnerHTML={{ __html: descriptionWithTip }}
                  style={{ fontSize: '12px', margin: '0px 0px 0px 26px' }}
                />
              )}
              {data === option && tip && (
                <div
                  dangerouslySetInnerHTML={{ __html: tip }}
                  style={{
                    margin: '8px 0px 0px 26px',
                    fontSize: '12px',
                  }}
                />
              )}
            </div>
          )
        }
      }

      return radioItem
    })
    .filter((ele) => {
      if (ele.children === undefined) return false
      else return true
    })

  if (!enabled) return <></>

  return (
    <Radios
      boxed={typeof boxed === 'boolean' ? boxed : true}
      columnWidth={radioColumnWidth}
      disabled={!enabled}
      elements={radioElements}
      equalSize={radioEqualSize}
      error={showErrors ? errors : undefined}
      handleClick={handleChangeLocal}
      id={id}
      isIconOnRight={isIconOnRight}
      label={radioLabel}
      layout={radioLayout}
      placement={radioPlacement}
      selected={data}
      space={radioSpace}
      style={style}
    />
  )
}

export default withJsonFormsEnumProps(RadiosControl)

export const radiosControlTester: RankedTester = rankWith(
  99,
  and(isStringControl, optionIs('format', 'radio')),
)
