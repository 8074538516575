export const getSalutationFromLabel = (
  label: string,
): 'MR' | 'MRS' | 'OTHERS' | '' => {
  switch (label) {
    case 'MR':
    case 'Herr':
      return 'MR' as const

    case 'MRS':
    case 'Frau':
      return 'MRS' as const

    case 'Divers':
      return 'OTHERS' as const

    default:
      return '' as const
  }
}

export const getTitleFromLabel = (
  label: string,
): 'Dr.' | 'Prof.' | 'Prof.Dr.' | '' => {
  switch (label) {
    case 'Prof. Dr.':
      return 'Prof.Dr.'

    case 'Prof.':
      return 'Prof.'

    case 'Dr.':
      return 'Dr.'

    default:
      return ''
  }
}
