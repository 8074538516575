import DateFnsAdapter from '@date-io/date-fns'
import { format } from 'date-fns'

export const getAsapDate = (daysDelay: number, dateFormat: string) => {
  const dateFns = new DateFnsAdapter()
  const today = dateFns.date()
  const asapDate = format(dateFns.addDays(today, daysDelay), dateFormat)

  return asapDate
}
