import { SmallText } from '@epilot/components/dist'
import React from 'react'

import { SecondaryTitleDescriptionStyled } from './product-details-styled'

type ProductDetailsFootnoteProps = {
  footnote: string
}

const ProductDetailsFootnote = ({
  footnote,
}: ProductDetailsFootnoteProps): JSX.Element => {
  return (
    <SecondaryTitleDescriptionStyled>
      <SmallText>{footnote}</SmallText>
    </SecondaryTitleDescriptionStyled>
  )
}

export default ProductDetailsFootnote
