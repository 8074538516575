import {
  Grid,
  Hidden,
  Typography,
  TypographyProps,
} from '@epilot/base-elements'
import { LayoutProps, rankWith, uiTypeIs, and } from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'
import { useSelector } from 'react-redux'

import { getAllResultData } from 'modules/step/selectors'

export type DynamicDataLabelRendererOptions = TypographyProps & {
  labels: { [property: string]: { [value: string]: string } }
  targetScope: string
}

const DynamicDataLabelRenderer = ({ uischema, visible }: LayoutProps) => {
  const stepData = useSelector(getAllResultData)
  const allStepsData = { ...stepData }
  const { labels, targetScope, ...rest } = (uischema?.options ||
    {}) as DynamicDataLabelRendererOptions
  const AMOUNTLABEL = 'Zähler'
  const targetValues =
    targetScope && allStepsData[targetScope] ? allStepsData[targetScope] : []
  const targetValueLength = targetValues.length
  const keyOfLabels = labels && Object.keys(labels)
  const allEleGroup = targetValues.map((item: any, index: number) => {
    const additionalLabel = AMOUNTLABEL.concat(' ', (index + 1).toString())
    const auxIndexString = targetValueLength > 1 ? additionalLabel : ''
    let eachEleInGroup: any = []

    if (keyOfLabels && targetValueLength > 0) {
      eachEleInGroup = keyOfLabels?.map((ele, eleIndex) => {
        const label = (labels && labels[ele].value) || ''
        const eachValue = item[ele] || ''

        return (
          <Typography {...rest} key={eleIndex}>
            {eachValue && label + ' ' + auxIndexString + ': ' + eachValue}
          </Typography>
        )
      })
    }

    return <Grid key={index}>{eachEleInGroup ? eachEleInGroup : ''}</Grid>
  })

  return <Hidden xsUp={!visible}>{allEleGroup}</Hidden>
}

export const dynamicDataLabelRendererTester = rankWith(
  4,
  and(uiTypeIs('DynamicDataLabel')),
)

export default withJsonFormsLayoutProps(DynamicDataLabelRenderer)
