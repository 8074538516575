// URL params
const searchParams = new URLSearchParams(window.location.search)
const urlParamShadow = searchParams.get('shadow')
const urlParamDebug = searchParams.get('debug')
const urlParamWidgetId = searchParams.get('widgetid')
const urlParamEndpointConfiguration = searchParams.get('endpointconfiguration')
const urlParamEndpointResults = searchParams.get('endpointresults')
const urlEnableWhyDidYouRender = searchParams.get('whydidyourender')
const urlInitialStepId = searchParams.get('initialStepId')
const previewMode = searchParams.get('previewMode')
const urlParamBlueprintConfiguration = searchParams.get('blueprint')
const urlParamTheme = searchParams.get('theme')
const urlParamLogo = searchParams.get('logo')
const MANUAL_INITIALIZATION = searchParams.get('manual_init')
const HIDE_ITEMS =
  (searchParams.get('hide') && searchParams.get('hide')?.split(',')) || []
const AUTO_RESIZE = searchParams.get('autoresize') === 'true' ? true : false

const LIVE_IN_SHAWDOW =
  urlParamShadow === 'true' || urlParamShadow === '1' || false
const DEBUG = urlParamDebug === 'true' || urlParamDebug === '1' || false
const WIDGET_ID = urlParamWidgetId
const FORCE_INITIAL_STEP_ID = urlInitialStepId
const PREVIEW_MODE = previewMode === 'true'
const BLUEPRINT_CONFIGURATION = urlParamBlueprintConfiguration
const THEME_PREVIEW = urlParamTheme && atob('' + urlParamTheme)
const LOGO_PREVIEW = urlParamLogo

// NODE_ENV
const IS_DEV = process.env.NODE_ENV !== 'production'
const WHYDIDYOURENDER = !!urlEnableWhyDidYouRender

// API ENDPOINT - CONFIGURATION
// order 1. url param, 2. process env or 3. fallback string
const API_CONFIGURATION = urlParamEndpointConfiguration
  ? urlParamEndpointConfiguration
  : process.env.REACT_APP_EPILOT_API_CONFIGURATION ||
    'https://journey-config.dev.sls.epilot.io/v1/widget/configuration'

// API ENDPOINT - RESULTS
// order 1. url param, 2. process env or 3. fallback string
const API_RESULTS = urlParamEndpointResults
  ? urlParamEndpointResults
  : process.env.REACT_APP_EPILOT_API_RESULTS ||
    'https://result.dev.sls.epilot.io/v1/result'

const API_RESULTS_V3 = urlParamEndpointResults
  ? urlParamEndpointResults
  : process.env.REACT_APP_EPILOT_API_RESULTS_V3 ||
    'https://ochfkewmp6.execute-api.eu-central-1.amazonaws.com/dev/v3/results'

const API_RESULTS_V4 = urlParamEndpointResults
  ? urlParamEndpointResults
  : process.env.REACT_APP_EPILOT_API_RESULTS_V4 ||
    'https://ochfkewmp6.execute-api.eu-central-1.amazonaws.com/dev/v4/results'

const API_RESULTS_PRESIGNED_URLS_V4 =
  process.env.REACT_APP_RESULTS_PRESIGNED_URLS_V4 ||
  'https://ochfkewmp6.execute-api.eu-central-1.amazonaws.com/dev/v4/presignedUrls'

const API_RESULTS_DEFAULT_VERSION =
  process.env.REACT_APP_EPILOT_API_RESULTS_DEFAULT_VERSION || 'v3'

const API_PREVIOUS_PROVIDER =
  process.env.REACT_APP_EPILOT_API_PREVIOUS_PROVIDER ||
  'https://56eqhfwg08.execute-api.eu-central-1.amazonaws.com/dev/v1/integration/power/providers?name=%value%'

const API_IBAN_VALIDATION =
  process.env.REACT_APP_EPILOT_API_IBAN_VALIDATION ||
  'https://m1ijr3w9q8.execute-api.eu-central-1.amazonaws.com/dev/v1/integration/iban?iban='

const API_POSTALCODE_ADDRESS =
  process.env.REACT_APP_EPILOT_API_POSTALCODE_ADDRESS ||
  'https://jdk4pkdou2.execute-api.eu-central-1.amazonaws.com/dev'

export const API_PRODUCTS =
  process.env.REACT_APP_API_PRODUCTS ||
  'https://67n47bvgwa.execute-api.eu-central-1.amazonaws.com/review-dev-4jxwt5'

const IMAGE_HOST = IS_DEV ? 'https://widget.dev.epilot.io' : ''

const SUBMISSION_API_V1 =
  process.env.REACT_APP_SUBMISSION_API_V1 ||
  'https://unz1npfzhb.execute-api.eu-central-1.amazonaws.com/v1/submission/submissions'

const PREVIEW_ORGANIZATION =
  process.env.REACT_APP_PREVIEW_ORGANIZATION || '7321549'

const DATADOG_CLIENT_TOKEN =
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN ||
  'pubcbfbb4b2a92ed722ad8c1e40b2880553'

const isInIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const IS_IN_IFRAME = isInIframe()

if (AUTO_RESIZE) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('iframe-resizer').iframeResizerContentWindow
}

const config = {
  NODE_ENV: process.env,
  IS_DEV,
  LIVE_IN_SHAWDOW,
  DEBUG,
  WIDGET_ID,
  API_CONFIGURATION,
  API_RESULTS,
  API_RESULTS_V3,
  API_RESULTS_V4,
  API_RESULTS_PRESIGNED_URLS_V4,
  API_RESULTS_DEFAULT_VERSION,
  API_PREVIOUS_PROVIDER,
  API_IBAN_VALIDATION,
  API_POSTALCODE_ADDRESS,
  API_PRODUCTS,
  WHYDIDYOURENDER,
  FORCE_INITIAL_STEP_ID,
  IMAGE_HOST,
  PREVIEW_MODE,
  BLUEPRINT_CONFIGURATION,
  SUBMISSION_API_V1,
  STAGE: process.env.REACT_APP_STAGE || 'local',
  THEME_PREVIEW,
  LOGO_PREVIEW,
  PREVIEW_ORGANIZATION,
  DATADOG_CLIENT_TOKEN,
  IS_IN_IFRAME,
  MANUAL_INITIALIZATION,
  AUTO_RESIZE,
  HIDE_ITEMS,
}

export default config
