export type QuestionType = {
  question: string
  answer: string | number
}
export type CustomFieldsType = {
  label: string
  type: string
  value: string | number
}
const fixedQuestions = (
  questions: Array<QuestionType>,
): Array<QuestionType> => {
  if (questions) {
    let fixedQuestions: Array<QuestionType> = []

    fixedQuestions = questions.map((item: QuestionType) => {
      if (item.answer && typeof item.question === 'undefined') {
        item.question = 'Sollen wir Ihren bestehenden Vertrag für Sie kündigen?'
      }

      if (typeof item.answer === 'number') {
        item.answer = String(item.answer)
      }

      return item
    })

    return fixedQuestions
  } else {
    return []
  }
}

const fixedCustomFields = (
  customFields: Array<CustomFieldsType>,
): Array<CustomFieldsType> => {
  if (customFields) {
    const fixedCustomFields = customFields.map((item: CustomFieldsType) => {
      if (item.value && typeof item.label === 'undefined') {
        item.label = 'Sollen wir Ihren bestehenden Vertrag für Sie kündigen?'
      }
      if (typeof item.value === 'number') {
        item.value = String(item.value)
      }

      return item
    })

    return fixedCustomFields
  } else {
    return []
  }
}

export { fixedQuestions, fixedCustomFields }
