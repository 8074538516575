import { GlobalResultData } from 'modules/step/types'

export const addMissingDeliveryAddressSuffix = (
  category: string,
  data: GlobalResultData,
) => {
  switch (category) {
    case 'commissioning_gas':
    case 'commissioning_power':
    case 'registration_of_micro_power_plants':
      return data?.fields?.additional_address
    case 'contact_form':
      return data?.fields?.address_delivery_addition
    default:
      return ''
  }
}
export const addMissingBillingAddressSuffix = (
  category: string,
  data: GlobalResultData,
) => {
  switch (category) {
    case 'commissioning_gas':
    case 'commissioning_power':
    case 'registration_of_micro_power_plants':
      return data?.fields?.additional_match_address
    case 'contact_form':
      return data?.fields?.address_billing_addition
    default:
      return ''
  }
}
