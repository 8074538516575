import { CustomField } from 'lib/api/types'
import { ProductType } from 'modules/shop/types'

import { PayloadResults } from '../types'

import { customFieldsCommon } from './customFieldsCommon'
import { customFieldsGas } from './customFieldsGas'
import { customFieldsPower } from './customFieldsPower'

export const customFieldsAdd = (
  results: PayloadResults,
  identifier: ProductType,
): CustomField[] => {
  let customFields: CustomField[] = []

  switch (identifier) {
    case 'power':
      customFields = customFieldsPower(results)
      break
    case 'gas':
      customFields = customFieldsGas(results)
      break
    default:
      customFields = []
      break
  }

  return [...customFields, ...customFieldsCommon(results)]
}
