import { Box } from '@epilot/base-elements'
import {
  InputControl as InputControlComponent,
  MuiHidden,
} from '@epilot/components'
import {
  and,
  ControlProps,
  or,
  RankedTester,
  rankWith,
  schemaTypeIs,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'
import { useSelector } from 'react-redux'

import { isRequiredScope } from 'lib'
import { generateStringLabel } from 'lib/is-required-scope'
import { limitString } from 'lib/limit-string'
import { getShowErrors } from 'modules/step/selectors'

import { useOverwritePlaceholderStyles } from '../styles'

const MAX_CHARS = 1500

const TextInputControl = ({
  id,
  data,
  enabled,
  label,
  visible,
  errors,
  handleChange,
  path,
  schema,
  uischema,
  rootSchema,
}: ControlProps) => {
  const classes = useOverwritePlaceholderStyles()
  const showErrors = useSelector(getShowErrors)

  const type =
    schema.format === 'email'
      ? 'email'
      : uischema?.options?.type === 'phone'
      ? 'tel'
      : 'text'

  const isRequired = isRequiredScope(rootSchema, uischema.scope)
  const stringLabel = generateStringLabel(label as string, isRequired)
  const rows = uischema?.options?.rows
  const multiline = uischema?.options?.multiline
  const handleChangeLocal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (value.length === 0) {
      handleChange(path, undefined)
    } else if (multiline && value.length >= MAX_CHARS) {
      handleChange(path, limitString(event.target.value, MAX_CHARS))
    } else {
      handleChange(path, event.target.value)
    }
  }

  const formatControl = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.trim()

    handleChangeLocal(event)
  }

  return (
    <MuiHidden xsUp={!visible}>
      <Box className={classes.inputOverwrite} position="relative">
        {multiline && typeof data === 'string' && (
          <div style={{ position: 'absolute', right: '0', top: '-17px' }}>
            <span style={{ fontSize: '9px' }}>
              {/* TODO: Add i18n to this */}
              Maximale Buchstaben {MAX_CHARS} ({MAX_CHARS - data.length} übrig)
            </span>
          </div>
        )}
        <InputControlComponent
          disabled={!enabled}
          error={showErrors ? errors : ''}
          fullWidth
          id={id}
          label={stringLabel}
          multiline={multiline}
          onBlur={formatControl}
          onChange={handleChangeLocal}
          placeholder={uischema?.options?.placeholder}
          rows={rows}
          type={type}
          value={data ?? ''}
        />
      </Box>
    </MuiHidden>
  )
}

export default withJsonFormsControlProps(TextInputControl)

export const textControlTester: RankedTester = rankWith(
  9,
  and(uiTypeIs('Control'), or(schemaTypeIs('string'))),
)
