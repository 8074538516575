export const INSTALLATIONTYPE = {
  INVERTER: 'inverter',
  SOLAR: 'solar',
  COMMISSIONING: 'commissioning',
  GENERATOR: 'generator',
  STORAGE: 'storage',
}

export const INSTALLATIONUNIT = {
  [INSTALLATIONTYPE.INVERTER]: 'kVA',
  [INSTALLATIONTYPE.COMMISSIONING]: 'kWh',
  [INSTALLATIONTYPE.SOLAR]: 'Wp',
  [INSTALLATIONTYPE.GENERATOR]: 'kVA',
  [INSTALLATIONTYPE.STORAGE]: 'kWh',
}
export const ERRORMESSAGE = 'Ist erforderlich.'
