import { ConfigurationIcon } from '@epilot/components'
import React from 'react'
import { connect } from 'react-redux'

import { exitFullscreen } from '../../modules/app/reducer'

import ErrorContainer from './error-container-styled'

type Props = {
  exitFullscreen: () => void
}

const Error = ({ exitFullscreen }: Props) => {
  return (
    <ErrorContainer>
      <ConfigurationIcon color="#849096" />
      <h1>Etwas ist schief gelaufen</h1>
      <p>
        Ein Fehler ist aufgetreten. Wir arbeiten bereits an der Behebung des
        Problems. Wir sind in Kürze startklar.
      </p>
      <p>
        Wenn du sofort Hilfe benötigst, besuche bitte unser{' '}
        <a
          href="https://e-pilot.atlassian.net/servicedesk/customer/portal/2"
          rel="noopener noreferrer"
          target="_blank"
        >
          Support Center
        </a>
        . Vielen Dank für deine Geduld!
      </p>
      <button onClick={exitFullscreen}>Schließen</button>
    </ErrorContainer>
  )
}

export default connect(null, {
  exitFullscreen,
})(Error)
