import { styled } from '@epilot/components'

import { Theme } from '../../../modules/app/types'

type ProductFootnoteStyledProps = {
  withDetails: boolean
  theme: Theme
}

const ProductFootnoteStyled = styled.span<ProductFootnoteStyledProps>`
  margin-top: ${({ withDetails }: ProductFootnoteStyledProps) =>
    withDetails ? '0' : '-20px'};
  margin-left: 15px;
  margin-right: auto;
  margin-bottom: 15px;
  text-align: left;
  font-size: 10px;
  line-height: 10px;
  color: ${({ theme }: ProductFootnoteStyledProps) => theme.typography.color};
`

export default ProductFootnoteStyled
