import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useOverwritePlaceholderStyles = makeStyles(() =>
  createStyles({
    inputOverwrite: {
      '& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:not(:focus)::-webkit-input-placeholder, label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input:not(:focus)::placeholder': {
        opacity: '0.42 !important',
      },
    },
  }),
)

export const useErrorMessageStyles = makeStyles(() =>
  createStyles({
    errorBox: {
      marginTop: '5px',
      color: '#f44336',
      fontSize: '0.75rem',
    },
  }),
)
