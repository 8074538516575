import { Grid, TextField, Autocomplete } from '@epilot/base-elements'
import { styled } from '@epilot/components'
import {
  and,
  ControlProps,
  isObjectControl,
  optionIs,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { generateStringLabel } from 'lib/is-required-scope'
import { getShowErrors } from 'modules/step/selectors'

import { isRequiredScope } from '../../../lib'
import postalCodes from '../../../lib/postalcode-mapping/postalcode-city-mappings'

const StyledGrid = styled(Grid)`
  & .MuiAutocomplete-inputRoot {
    padding-top: 7px !important;
  }

  & .MuiAutocomplete-input {
    padding-top: 0px !important;
  }
`

const checkFullAddress = (address: {
  postalcode?: string
  city?: string
  street?: string
  number?: string
}) => {
  return address.postalcode && address.city && address.street && address.number
}

const ExpandedPostalcodeControl = ({
  data,
  label,
  handleChange,
  path,
  uischema,
  errors,
  rootSchema,
}: ControlProps) => {
  const showErrors = useSelector(getShowErrors)
  const isRequired = isRequiredScope(rootSchema, uischema.scope)
  const stringLabel = generateStringLabel(label as string, isRequired)
  const error = showErrors && errors.length > 0 ? errors : undefined
  const postalCodeOptions = postalCodes.map((postalCode) => {
    return `${postalCode.postalcode} ${postalCode.city}`
  })
  const [showStreetAndNumber, setShowStreetAndNumber] = useState(false)
  const [localData, setLocalData] = useState({
    postalcode: '',
    city: '',
    street: '',
    number: '',
  })
  const isEnet = uischema?.options?.isEnet

  useEffect(() => {
    if (data) {
      setLocalData(data)
    }
    if (data && checkFullAddress(data)) {
      setShowStreetAndNumber(true)
    }
  }, [data])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkEnet = (_postalCode: any) => {
    setShowStreetAndNumber(true)
  }

  const onPostalCodeChange = (_: any, value: any) => {
    const newData = {
      ...localData,
      postalcode: value.split(' ')[0],
      city: value.split(' ')[1],
    }

    setLocalData(newData)

    if (isEnet) {
      checkEnet(newData)
    } else {
      handleChange(path, newData)
    }
  }

  const onStreetChange = (event: any) => {
    const newData = {
      ...localData,
      street: event.target.value,
    }

    setLocalData(newData)

    if (checkFullAddress(newData)) {
      handleChange(path, newData)
    } else {
      handleChange(path, undefined)
    }
  }

  const onNumberChange = (event: any) => {
    const newData = {
      ...localData,
      number: event.target.value,
    }

    setLocalData(newData)

    if (checkFullAddress(newData)) {
      handleChange(path, newData)
    } else {
      handleChange(path, undefined)
    }
  }

  return (
    <Grid container spacing={2} style={{ width: 'calc(100% + 16px)' }}>
      <StyledGrid item sm={6} xs={12}>
        <Autocomplete
          error={error && !localData.postalcode ? error : ''}
          label={stringLabel}
          multiple={false}
          noOptionsText={uischema?.options?.noOptionsText}
          onChange={onPostalCodeChange}
          options={postalCodeOptions}
          placeholder={uischema?.options?.placeholder}
          value={
            localData ? `${localData.postalcode} ${localData.city}` : undefined
          }
        />
      </StyledGrid>
      {showStreetAndNumber && (
        <>
          <Grid item sm={4} xs={8}>
            <TextField
              error={error && !localData.street ? true : false}
              label="Strasse*"
              onChange={onStreetChange}
              style={{ marginTop: 0 }}
              value={localData.street}
            />
          </Grid>
          <Grid item sm={2} xs={4}>
            <TextField
              error={error && !localData.number ? true : false}
              label="Hausnummer*"
              onChange={onNumberChange}
              style={{ marginTop: 0 }}
              value={localData.number}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default withJsonFormsControlProps(ExpandedPostalcodeControl)
export const expandedPostalcodeTester: RankedTester = rankWith(
  99,
  and(isObjectControl, optionIs('format', 'expanded-postalcode')),
)
