import { datadogRum } from '@datadog/browser-rum'
import { EventChannel, eventChannel } from 'redux-saga'
import { all, take, takeLatest } from 'redux-saga/effects'
import { call, put, SagaGenerator, select } from 'typed-redux-saga'

import { ContactForm } from 'assets/contact-form'
import { NewGGNV } from 'assets/new-ggnv'
import { PowerAndGas } from 'assets/power-and-gas'
import config from 'config'
import { gaTracker, fbTracker } from 'lib/analytics'
import { FB_PLUGIN_NAME, GA_PLUGIN_NAME } from 'lib/analytics/plugins/utils'
import { apiCall, getWidgetConfiguration } from 'lib/api/epilot.api'
import {
  mapEpilotTheme,
  parseThemeTemplate,
} from 'lib/theme-mapping/theme_mapping'
import {
  previewStep,
  ENTER_FULLSCREEN,
  EXIT_FULLSCREEN,
  HANDLE_PARAMS,
  handleParams,
  IFRAME_MESSAGE,
  iframeMessage,
  INIT_APP,
  INIT_APP_SUCCESS,
  initApp,
  INIT_SUBSCRIPTIONS_IFRAME,
  initAppFailure,
  initAppSuccess,
  LOAD_CONFIGURATION,
  LOAD_CONFIGURATION_SUCCESS,
  loadConfiguration,
  loadConfigurationFailure,
  loadConfigurationSuccess,
  RESET_GLOBAL,
  resetGlobal,
  START_SUBSCRIPTION_IFRAME_MESSAGES,
  startSubscriptionIframeMessage,
  SUBMIT_SUCCESS,
  SUBMIT_ERROR,
  handleCookiesConsentParams,
  HANDLE_COOKIES_CONSENT_PARAMS,
  handleJourneyDataInjection,
  initSubscription,
} from 'modules/app/reducer'
import {
  cartAddAddon,
  cartAddProduct,
  loadProducts,
  loadProductsSuccess,
} from 'modules/shop/reducer'
import { getProductLoadWithoutCondition } from 'modules/shop/selectors'
import { prefillStepData } from 'modules/step/reducer'
import { getCurrentStepId } from 'modules/step/selectors'
import { Step } from 'modules/step/types'
import { TypedAction } from 'modules/types'

import { dispatchUserEvent } from '../../lib/message-utils'
import {
  getCookiesConsentItemKey,
  getLocalStorageItem,
  updateLocalStorageItem,
} from '../../lib/storage'
import { WidgetConfig } from '../../types/widget-config'
import { ProductCategory } from '../shop/types'

import {
  getInitialConfig,
  getInjectedData,
  getInjectedSettings,
  getWidgetId,
} from './selectors'
import {
  WidgetCookiesConsent,
  WidgetCookiesConsentParams,
  WidgetParams,
} from './types'
import { isSkipStep, isSkipStepById } from './utils'
// TODO: Make the feature available to all orgs
const ORG_TEST_ANALYTICS_CONSENT = [
  '728', // epilot staging
  '66', // epilot prod
  '290535', // Dortmunder Energie- und Wasserversorgung GmbH
  '6288298', // 2nd DEW Account
] // org ids the cookies analytics consent feature is available to

function* initAppFlow() {
  try {
    // Do auth or other checks, or load external date
    //yield* put(startSubscriptionIframeMessage())

    // Load config
    yield put(loadConfiguration())
    // TODO: check how saga can wait for all actions to call success before calling appInitSuccess

    // yield put(initAppSuccess()) // Trigged by loadConfigurationSuccess
  } catch (error) {
    console.error('Error when trying to initialize the App', error)
    yield put(initAppFailure(error))
  }
}

function* initSubscriptionIframeMessageFlow() {
  try {
    yield* put(startSubscriptionIframeMessage())
  } catch (error) {
    console.error(
      'Error when trying to initialize App iframe subscriptions',
      error,
    )
    yield put(initAppFailure(error))
  }
}

function* initAppSuccessFlow() {
  const productLoadWithoutCondition = yield* select(
    getProductLoadWithoutCondition,
  )

  if (productLoadWithoutCondition) {
    yield* put(loadProducts())
  }
}

const findStepByNextStepId = (
  steps: Step[],
  id: string | undefined,
  builder: any,
  skipSteps: Step[],
): any => {
  const step = steps?.find((step: Step) => step.id === id)

  if (step && isSkipStepById(builder, step, skipSteps)) {
    return findStepByNextStepId(
      steps,
      step?.logic?.nextStepId,
      builder,
      skipSteps,
    )
  } else return step?.id
}

function* loadConfigurationFlow() {
  try {
    let widgetConfig: WidgetConfig
    const injectedSettings = yield* select(getInjectedSettings)
    const {
      stepsData: injectedSteps,
      productsData: injectedProducts,
    } = yield* select(getInjectedData)

    if (config.WIDGET_ID) {
      // Use Url param Widget ID
      widgetConfig = yield* call(getWidgetConfiguration, config.WIDGET_ID)
      if (!config.PREVIEW_MODE) {
        const { steps, builder, shop, ...rest } = widgetConfig
        const skipSteps = injectedSettings.skipSteps?.split(',') || []

        let { initialStepId } = widgetConfig

        let filteredSteps = steps.filter(
          (step, index) => !isSkipStep(builder, step, skipSteps, index),
        )

        const skippedSteps = steps.filter((step, index) =>
          isSkipStep(builder, step, skipSteps, index),
        )

        // proceed only if there are hidden steps
        if (filteredSteps.length !== steps.length) {
          // go through each non hidden step, and modify the next step
          filteredSteps?.forEach((step, index) => {
            // find the next step based on nextStepId
            const stepNext = steps.find(
              (item) => item.id === step?.logic.nextStepId,
            )

            // if next step found and next step should be hidden and next step logic contains rules -> replace current step rules with next step rules
            // else check if no rules defined and there is a next step, then replace nextstepid
            if (
              stepNext &&
              isSkipStepById(builder, stepNext, skippedSteps) &&
              !stepNext.logic.nextStepId &&
              stepNext.logic.rules
            ) {
              step.logic.nextStepId = undefined
              step.logic.rules = stepNext.logic.rules
            } else if (filteredSteps[index + 1] && !step.logic.rules) {
              step.logic.nextStepId = findStepByNextStepId(
                steps,
                step?.logic?.nextStepId,
                builder,
                skippedSteps,
              )
            } else if (injectedSteps) {
              // find next step in filteredSteps array, which is not skipped or hidden
              const stepId = filteredSteps[index + 1]

              if (stepId) step.logic.nextStepId = stepId.id
            }
          })
          initialStepId = filteredSteps[0]?.id || rest.initialStepId
        }

        filteredSteps = [...filteredSteps, ...skippedSteps]

        widgetConfig = ({
          steps: filteredSteps.length !== steps.length ? filteredSteps : steps,
          builder,
          shop,
          ...rest,
          initialStepId,
        } as unknown) as WidgetConfig
      }
    } else {
      // Use Widget Showcase demo if not Widget ID provided
      // Configuration will be automatically copied to public folder at build time
      let result = yield* call(apiCall, 'GET', '/configs/default.config.json')

      let widgetBlueprint

      if (config.BLUEPRINT_CONFIGURATION) {
        switch (config.BLUEPRINT_CONFIGURATION) {
          case 'power-and-gas':
            widgetBlueprint = PowerAndGas as unknown
            break
          case 'contact-form':
            widgetBlueprint = ContactForm as unknown
            break
          case 'ggnv':
            widgetBlueprint = NewGGNV as unknown
            break
          // Old blueprint call method if blueprint is not defined
          default:
            result = yield* call(
              apiCall,
              'GET',
              `/blueprints/${config.BLUEPRINT_CONFIGURATION}.config.json`,
            )
            break
        }
      }

      if (!widgetBlueprint) {
        widgetConfig = (result as unknown) as WidgetConfig
      } else {
        widgetConfig = widgetBlueprint as WidgetConfig
      }
    }
    if (!widgetConfig) {
      throw Error(
        'No WidgetConfig provided. Add .env or setup API call for configuration',
      )
    }

    // ThemeProvider from new elements library (@epilot/base-elements)
    // does not use a mui theme but a custom theme (EpilotTheme)
    // Then if useBaseElementsLibrary property is set in the styling of the configuration,
    // map theme_options to be compatible with @epilot/base-elements's ThemeProvider
    const styling = widgetConfig.styling?.theming_options
      ? widgetConfig.builder?.useBaseElementsLibrary
        ? mapEpilotTheme(widgetConfig.styling?.theming_options)
        : parseThemeTemplate(widgetConfig.styling?.theming_options)
      : widgetConfig.styling // If theme_options is not defined, theme property will be used as a mui theme

    widgetConfig = {
      ...widgetConfig,
      styling,
    } as WidgetConfig

    // Overview initial step id when provided by url param
    if (config.FORCE_INITIAL_STEP_ID) {
      if (widgetConfig.meta)
        widgetConfig.meta.initialStepId = config.FORCE_INITIAL_STEP_ID
      else widgetConfig.initialStepId = config.FORCE_INITIAL_STEP_ID
    }

    initWidgetAnalyticsTracking(widgetConfig)

    // Log Data to DataDog
    datadogRum.startView(widgetConfig.initialStepId)

    yield put(loadConfigurationSuccess(widgetConfig, injectedSteps))

    /** INJECTION STEPS/PRODUCTS DATA */

    // Inject steps data
    if (injectedSteps) {
      yield* put(prefillStepData(injectedSteps))
    }

    // Inject products
    if (
      injectedProducts &&
      injectedProducts.data &&
      injectedProducts.data.length > 0
    ) {
      yield* put(loadProductsSuccess(injectedProducts.data))
    }

    // Add pre selection for products if available
    if (injectedProducts?.selectedProductsIds) {
      const addDataToCartActions = [] as SagaGenerator<any>[]

      injectedProducts.selectedProductsIds.forEach((productId) => {
        const productData = injectedProducts.data.find(
          (p) => p.id.toString() === productId.toString(),
        )

        if (!productData) {
          console.error(`Cannot find products based on injected product ids`)

          return
        }

        const productCategory = productData.type.toUpperCase()

        if (!productCategory) {
          console.error(
            `Injected product ${productData?.id} with invalid category ${productCategory}`,
          )

          return
        }

        addDataToCartActions.push(
          put(
            cartAddProduct(
              productId.toString(),
              productCategory as ProductCategory,
            ),
          ),
        )

        if (injectedProducts.selectedAddonsIds) {
          productData.addons.forEach((addon) => {
            if (injectedProducts.selectedAddonsIds?.includes(addon.id)) {
              addDataToCartActions.push(
                put(cartAddAddon(addon.id.toString(), productId.toString())),
              )
            }
          })
        }
      })

      yield all(addDataToCartActions)
    }
  } catch (error) {
    yield put(loadConfigurationFailure(error))

    // Notify error monitoring
    error.message =
      'Widget configuration could not be loaded | ' + error.message
    // Will be logged to DataDog
    console.error(error)
  }
}

function* loadConfigurationSuccessFlow() {
  yield put(initAppSuccess())
}

function* startSubscribeIframeMessagesFlow() {
  // TODO Add handle for END / cancel function > stopSubscriptionIframeMessage
  const channel: EventChannel<string> = yield call(iframeMessageChannel)

  try {
    while (true) {
      const message: { data: { type: string } } | undefined = yield take(
        channel,
      )

      if (
        message?.data?.type === 'EPILOT/PARAMS' ||
        message?.data?.type === 'EPILOT/PREVIEW_STEP' ||
        message?.data?.type === 'EPILOT/COOKIES_CONSENT_PARAMS' ||
        message?.data?.type === 'EPILOT/INJECT_JOURNEY_DATA' ||
        message?.data?.type === 'EPILOT/INIT_APP'
      ) {
        yield put(iframeMessage(message))
      }
    }
  } finally {
    console.log(
      'AUTH :: eventChannel listener for Iframe messsage subscription terminated',
    )
  }
}

function* iframeMessageFlow({
  payload,
}: TypedAction<{
  message: {
    data: { type: string; params: Record<string, unknown> }
  }
}>): Generator {
  const {
    message: { data },
  } = payload

  if (data.type === 'EPILOT/INIT_APP') {
    yield* put(initApp(window.location.search))
  } else if (data.type === 'EPILOT/PARAMS' && data.params) {
    yield* put(handleParams(data.params))
  } else if (data.type === 'EPILOT/PREVIEW_STEP' && data.params) {
    yield* put(handleParams(data.params))
    yield* put(previewStep(data.params))
  } else if (data.type === 'EPILOT/COOKIES_CONSENT_PARAMS') {
    yield* put(handleCookiesConsentParams(data.params))
  } else if (data.type === 'EPILOT/INJECT_JOURNEY_DATA') {
    yield* put(handleJourneyDataInjection(data.params))
  }
}

/**
 * Handler for cookies consent message events
 * @param payload
 * @returns
 */
function* handleCookiesConsentParamsFlow({
  payload,
}: TypedAction<WidgetCookiesConsent>): Generator {
  const initialConfig = yield* select(getInitialConfig)

  const currentStepId = yield* select(getCurrentStepId)
  const brandId = initialConfig?.builder?.brand_id
  const orgId = initialConfig?.organizationId

  if (!brandId || !orgId) {
    // Will be logged to DataDog
    console.error(
      'Brand id / Organization Id not defined when handling widget cookies consent event - skipping...',
    )

    return
  }

  if (payload.params) {
    // update local storage with the received cookies consent
    updateLocalStorageItem<WidgetCookiesConsentParams>(
      getCookiesConsentItemKey(brandId),
      payload.params,
    )

    if (ORG_TEST_ANALYTICS_CONSENT.includes(orgId)) {
      // Enable/disable FB Pixel
      fbTracker.enablePlugin(
        FB_PLUGIN_NAME,
        payload.params.analytics,
        currentStepId,
      )

      // Enable/disable GA
      gaTracker.enablePlugin(
        GA_PLUGIN_NAME,
        payload.params.analytics,
        currentStepId,
      )
    }
  }
}

function* handleParamsFlow({ payload }: TypedAction<WidgetParams>): Generator {
  const params = payload.params
  const reset = params.reset
  const cart = params.cart
  const fields = params.fields
  const previewModule = params.previewModule

  if (reset) {
    yield* put(resetGlobal())
  }

  if (cart) {
    // TODO: make array possible
    const item = cart[0]
    // TODO: make array possible
    const productId = item.id
    const productCategory = item.productCategory

    yield* put(cartAddProduct(productId, productCategory, previewModule))

    // TODO - check if frist the products should be loaded and then the product to the cart - or indifferent

    // TODO: Call Product/Pricing API
    // releveanat
    // - productCategory
    // - consumption
  }

  if (fields) {
    // inject result data in to store
    yield* put(prefillStepData(fields))
  }
}

function* enterFullscreenFlow() {
  const widgetId = yield* select(getWidgetId)

  window.parent.postMessage({ type: 'EPILOT/ENTER_FULLSCREEN', widgetId }, '*')
}

function* exitFullscreenFlow() {
  const widgetId = yield* select(getWidgetId)

  window.parent.postMessage({ type: 'EPILOT/EXIT_FULLSCREEN', widgetId }, '*')
}

function* submitSuccessFlow() {
  const widgetId = yield* select(getWidgetId)

  window.parent.postMessage({ type: 'EPILOT/SUBMIT_SUCCESS', widgetId }, '*')
}

function* submitErrorFlow() {
  const widgetId = yield* select(getWidgetId)

  window.parent.postMessage({ type: 'EPILOT/SUBMIT_ERROR', widgetId }, '*')
}

function* resetGlobalFlow() {
  const config = yield* select(getInitialConfig)

  if (config) {
    yield* put(loadConfigurationSuccess(config))
  }
}

/**
 * Helper
 */
const iframeMessageChannel = () => {
  return eventChannel<boolean>((emitter) => {
    const messageEmitter = (message: any) => {
      emitter(message)
    }

    window.addEventListener('message', messageEmitter, false)
    const widgetId = config.WIDGET_ID

    window.parent.postMessage({ type: 'EPILOT/INIT_READY', widgetId }, '*')

    return () => {
      window.removeEventListener('message', messageEmitter)
    }
  })
}

/**
 * Init Widget Analytics tracking
 * @param widgetConfig widget configuration
 * @param currentStepId current step id (optional)
 */
function initWidgetAnalyticsTracking(
  widgetConfig: WidgetConfig,
  customStepId?: string, // if not passed, the initial step id will be considered
) {
  // track only if not in preview mode (journey builder) or when in development
  if (!config.PREVIEW_MODE || config.NODE_ENV.NODE_ENV === 'development') {
    const { builder, meta, widgetId, initialStepId, organizationId } =
      widgetConfig || {}
    let cookiesConsent: WidgetCookiesConsentParams = { analytics: true }

    if (organizationId && ORG_TEST_ANALYTICS_CONSENT.includes(organizationId)) {
      cookiesConsent = getLocalStorageItem<WidgetCookiesConsentParams>(
        getCookiesConsentItemKey(builder?.brand_id),
      )
    }

    // connect tracker with tracking id if given
    gaTracker.connect({
      trackingId:
        (builder &&
          builder.google_analytics &&
          builder.google_analytics.active &&
          builder.google_analytics.tracking_id) ||
        '', // activate only if google_analytics set, active and trackingId set - needs to be backwards compatible for clients not having the data in widgetConfig
      widgetId: (meta && meta.widgetId) || widgetId,
      initialStepId:
        customStepId || (meta && meta.initialStepId) || initialStepId,
      toEnable: cookiesConsent?.analytics,
    })

    if (builder && builder.facebook_pixel && builder.facebook_pixel.active) {
      fbTracker.initialize({
        trackingId: builder.facebook_pixel.tracking_id || '',
        widgetId: (meta && meta.widgetId) || widgetId,
        initialStepId:
          customStepId || (meta && meta.initialStepId) || initialStepId,
        toEnable: cookiesConsent?.analytics,
      })
    }
    dispatchUserEvent(
      (meta && meta.widgetId) || widgetId,
      customStepId || (meta && meta.initialStepId) || initialStepId,
    )
  }
}

/**
 * SAGA action trigger
 */
export default function* saga() {
  yield all([
    takeLatest(INIT_APP, initAppFlow),
    takeLatest(INIT_SUBSCRIPTIONS_IFRAME, initSubscriptionIframeMessageFlow),
    takeLatest(INIT_APP_SUCCESS, initAppSuccessFlow),
    takeLatest(LOAD_CONFIGURATION, loadConfigurationFlow),
    takeLatest(LOAD_CONFIGURATION_SUCCESS, loadConfigurationSuccessFlow),
    takeLatest(
      START_SUBSCRIPTION_IFRAME_MESSAGES,
      startSubscribeIframeMessagesFlow,
    ),
    takeLatest(IFRAME_MESSAGE, iframeMessageFlow),
    takeLatest(HANDLE_PARAMS, handleParamsFlow),
    takeLatest(HANDLE_COOKIES_CONSENT_PARAMS, handleCookiesConsentParamsFlow),
    takeLatest(ENTER_FULLSCREEN, enterFullscreenFlow),
    takeLatest(EXIT_FULLSCREEN, exitFullscreenFlow),
    takeLatest(SUBMIT_SUCCESS, submitSuccessFlow),
    takeLatest(SUBMIT_ERROR, submitErrorFlow),
    takeLatest(RESET_GLOBAL, resetGlobalFlow),
  ])

  // Initialize app based on conditions
  yield put(initSubscription())

  if (!config.MANUAL_INITIALIZATION) {
    yield put(initApp(window.location.search))
  }
}
