import { AddressModuleProps } from '@epilot/base-modules'

import config from 'config'
import { BlueprintElements } from 'types/blueprint-config'

import {
  buildGridContainerBlock,
  buildInputControlBlock,
  buildGridItemBlock,
  buildBooleanControlBlock,
  includeShowEffect,
  includeEffectMultipleConditions,
  buildEnumControlBlock,
  buildAddressControl,
} from '../block-builders'
import { defaultAddressSchemaProperties } from '../schemas/address.schema'
import { defaultConsumptionCalculatorSchemaProperties } from '../schemas/consumption-calculator.schema'

const addressInputLabels = {
  STREET_NAME_LABEL: 'Straße',
  STREET_NAME_PLACEHOLDER: 'Gustav-Stresemann-Allee',
  HOUSE_NUMBER_LABEL: 'Hausnummer',
  HOUSE_NUMBER_PLACEHOLDER: 'z.B. 49',
  EXTENTION_LABEL: 'Adresszusatz',
  EXTENTION_PLACEHOLDER: 'z.B. Hinterhaus',
  POSTALCODE_CITY_LABEL: 'Postleitzahl/Ort',
  POSTALCODE_CITY_PLACEHOLDER: '50672 Köln',
  DIFFERENT_BILLING_ADDRESS: 'Abweichend von Lieferadresse',
  DIFFERENT_INVOICE_RECIPIENT: 'Abweichender Rechnungsempfänger',
  INVOICE_RECIPIENT_FIRST_NAME: 'Vorname',
  INVOICE_RECIPIENT_LAST_NAME: 'Nachname',
}

// Build Delivery Address
export const buildDeliveryAddressBlock = (
  disableStreet?: boolean,
  disableHouse?: boolean,
): BlueprintElements => {
  // Build Delivery Address
  const deliveryAddressOptions: AddressModuleProps = {
    labels: {
      streetLabel: addressInputLabels.STREET_NAME_LABEL,
      housenumberLabel: addressInputLabels.HOUSE_NUMBER_LABEL,
      extentionLabel: addressInputLabels.EXTENTION_LABEL,
      zipCityLabel: addressInputLabels.POSTALCODE_CITY_LABEL,
    },
    placeholders: {
      streetPlaceholder: addressInputLabels.STREET_NAME_PLACEHOLDER,
      housenumberPlaceholder: addressInputLabels.HOUSE_NUMBER_PLACEHOLDER,
      extentionPlaceholder: addressInputLabels.EXTENTION_PLACEHOLDER,
      zipCityPlaceholder: addressInputLabels.POSTALCODE_CITY_PLACEHOLDER,
    },
    fieldsOrder: [
      {
        name: 'ZIP_CITY',
        gridItemProps: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
      {
        name: 'STREET',
        gridItemProps: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
      {
        name: 'HOUSE',
        gridItemProps: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
      {
        name: 'EXTENTION',
        gridItemProps: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
    ],
    disabledFields: {
      zipCity: true,
      street: disableStreet,
      housenumber: disableHouse,
    },
    requiredFields: {
      zipCity: true,
      street: true,
      housenumber: true,
    },
    streetAddressAPI:
      config.API_POSTALCODE_ADDRESS +
      '/v1/integration/location/%country%/streets?postal_code=%zip%&city=%city%&street=%street%',
    cityZipAPI:
      config.API_POSTALCODE_ADDRESS +
      '/v1/integration/location/%country%/postalcode-cities?postalcode_city=%postalcode_city%',
    zipCitySplitString: ' ',
    containerProps: {
      spacing: 3,
    },
    acceptOnlySuggestedAddresses: true,
  }
  const deliveryAddressControl = buildAddressControl(
    defaultConsumptionCalculatorSchemaProperties.postalcode_city.name,
    deliveryAddressOptions,
  )

  return deliveryAddressControl
}

// Build Billing Address
export const buildBillingAddressBlock = (): BlueprintElements => {
  const gridContainerElements = []

  // Build Different Billing Address
  const differentBillingAddressControl = buildBooleanControlBlock(
    defaultAddressSchemaProperties.different_billing_address.name,
    addressInputLabels.DIFFERENT_BILLING_ADDRESS,
    {
      clearPathOnChange: [
        defaultAddressSchemaProperties.billing_address.name,
        defaultAddressSchemaProperties.invoice_recipient_first_name.name,
        defaultAddressSchemaProperties.invoice_recipient_last_name.name,
        defaultAddressSchemaProperties.invoice_recipient_salutation.name,
        defaultAddressSchemaProperties.invoice_recipient_title.name,
      ],
    },
  )
  const differentBillingAddressControlGridItem = buildGridItemBlock(
    [differentBillingAddressControl],
    {
      xs: 12,
    },
  )

  gridContainerElements.push(differentBillingAddressControlGridItem)

  const differentBillingAddressGridContainerElements = []

  // Build Billing Address
  const billingAddressOptions: AddressModuleProps = {
    labels: {
      streetLabel: addressInputLabels.STREET_NAME_LABEL,
      housenumberLabel: addressInputLabels.HOUSE_NUMBER_LABEL,
      extentionLabel: addressInputLabels.EXTENTION_LABEL,
      zipCityLabel: addressInputLabels.POSTALCODE_CITY_LABEL,
    },
    placeholders: {
      streetPlaceholder: addressInputLabels.STREET_NAME_PLACEHOLDER,
      housenumberPlaceholder: addressInputLabels.HOUSE_NUMBER_PLACEHOLDER,
      extentionPlaceholder: addressInputLabels.EXTENTION_PLACEHOLDER,
      zipCityPlaceholder: addressInputLabels.POSTALCODE_CITY_PLACEHOLDER,
    },
    fieldsOrder: [
      {
        name: 'ZIP_CITY',
        gridItemProps: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
      {
        name: 'STREET',
        gridItemProps: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
      {
        name: 'HOUSE',
        gridItemProps: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
      {
        name: 'EXTENTION',
        gridItemProps: { xs: 12, sm: 12, md: 6, lg: 6 },
      },
    ],
    streetAddressAPI:
      config.API_POSTALCODE_ADDRESS +
      '/v1/integration/location/%country%/streets?postal_code=%zip%&city=%city%&street=%street%',
    cityZipAPI:
      config.API_POSTALCODE_ADDRESS +
      '/v1/integration/location/%country%/postalcode-cities?postalcode_city=%postalcode_city%',
    zipCitySplitString: ' ',
    containerProps: {
      spacing: 3,
    },
    requiredFields: {
      zipCity: true,
      street: true,
      housenumber: true,
    },
    acceptOnlySuggestedAddresses: true,
  }
  const billingAddressControl = buildAddressControl(
    defaultAddressSchemaProperties.billing_address.name,
    billingAddressOptions as AddressModuleProps,
  )

  differentBillingAddressGridContainerElements.push(billingAddressControl)

  // Build Billing Different Invoice Recipient
  const differentInvoiceRecipientControl = buildBooleanControlBlock(
    defaultAddressSchemaProperties.different_invoice_recipient.name,
    addressInputLabels.DIFFERENT_INVOICE_RECIPIENT,
    {
      clearPathOnChange: [
        defaultAddressSchemaProperties.invoice_recipient_first_name.name,
        defaultAddressSchemaProperties.invoice_recipient_last_name.name,
        defaultAddressSchemaProperties.invoice_recipient_salutation.name,
        defaultAddressSchemaProperties.invoice_recipient_title.name,
      ],
    },
  )
  const differentInvoiceRecipientControlGridItem = buildGridItemBlock(
    [differentInvoiceRecipientControl],
    {
      xs: 12,
    },
  )

  const differentInvoiceRecipientControlGridItemContainer = buildGridContainerBlock(
    [differentInvoiceRecipientControlGridItem],
    {
      spacing: 3,
    },
  )

  differentBillingAddressGridContainerElements.push(
    differentInvoiceRecipientControlGridItemContainer,
  )

  const differentInvoiceRecipientGridContainerElements = []

  // Build Billing Invoice Recipient Salutation
  const invoiceRecipientSalutationControl = buildEnumControlBlock(
    defaultAddressSchemaProperties.invoice_recipient_salutation.name,
    true,
  )
  const invoiceRecipientSalutationControlGridItem = buildGridItemBlock(
    [invoiceRecipientSalutationControl],
    {
      xs: 12,
      md: 6,
    },
  )

  differentInvoiceRecipientGridContainerElements.push(
    invoiceRecipientSalutationControlGridItem,
  )

  // Build Billing Invoice Recipient Title
  const invoiceRecipientTitleControl = buildEnumControlBlock(
    defaultAddressSchemaProperties.invoice_recipient_title.name,
    true,
  )
  const invoiceRecipientTitleControlGridItem = buildGridItemBlock(
    [invoiceRecipientTitleControl],
    {
      xs: 12,
      md: 6,
    },
  )

  differentInvoiceRecipientGridContainerElements.push(
    invoiceRecipientTitleControlGridItem,
  )

  // Build Billing Invoice Recipient First Name
  const invoiceRecipientFirstNameControl = buildInputControlBlock(
    defaultAddressSchemaProperties.invoice_recipient_first_name.name,
    addressInputLabels.INVOICE_RECIPIENT_FIRST_NAME,
  )
  const invoiceRecipientFirstNameControlGridItem = buildGridItemBlock(
    [invoiceRecipientFirstNameControl],
    {
      xs: 12,
      md: 6,
    },
  )

  differentInvoiceRecipientGridContainerElements.push(
    invoiceRecipientFirstNameControlGridItem,
  )

  // Build Billing Invoice Recipient Last Name
  const invoiceRecipientLastNameControl = buildInputControlBlock(
    defaultAddressSchemaProperties.invoice_recipient_last_name.name,
    addressInputLabels.INVOICE_RECIPIENT_LAST_NAME,
  )
  const invoiceRecipientLastNameControlGridItem = buildGridItemBlock(
    [invoiceRecipientLastNameControl],
    {
      xs: 12,
      md: 6,
    },
  )

  differentInvoiceRecipientGridContainerElements.push(
    invoiceRecipientLastNameControlGridItem,
  )

  // Show/Hide Billing Address
  const differentBillingAddressGridContainer = includeShowEffect(
    buildGridItemBlock(differentBillingAddressGridContainerElements, {
      spacing: 3,
    }),
    defaultAddressSchemaProperties.different_billing_address.name,
    true,
  )

  gridContainerElements.push(
    buildGridItemBlock([differentBillingAddressGridContainer], {
      xs: 12,
    }),
  )

  // Show/Hide Invoice Recipient
  const differentInvoiceRecipientGridContainer = includeEffectMultipleConditions(
    buildGridContainerBlock(differentInvoiceRecipientGridContainerElements, {
      spacing: 3,
    }),
    {
      [defaultAddressSchemaProperties.different_invoice_recipient.name]: true,
      [defaultAddressSchemaProperties.different_billing_address.name]: true,
    },
    'SHOW',
  )

  gridContainerElements.push(
    buildGridItemBlock([differentInvoiceRecipientGridContainer], { xs: 12 }),
  )

  // Build Container
  const uischema = buildGridContainerBlock(gridContainerElements, {
    spacing: 3,
  })

  return uischema
}
