import { Grid } from '@epilot/base-elements'
import { styled } from '@epilot/components'
import React from 'react'

import { LayoutItemProps } from './types'

const ResponsivedGrid = styled(Grid)`
  & div {
    width: 100%;
  }
  & button {
    width: 100%;
  }
`

const ResponsiveLayoutItem = ({ gridProps, children }: LayoutItemProps) => {
  return (
    <ResponsivedGrid item {...gridProps}>
      {children}
    </ResponsivedGrid>
  )
}

export default ResponsiveLayoutItem
