import { Icon, Grid } from '@epilot/base-elements'
import { styled } from '@epilot/components'
import {
  and,
  ControlProps,
  ControlState,
  isStringControl,
  optionIs,
  OwnPropsOfEnum,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { Control, withJsonFormsEnumProps } from '@jsonforms/react'
import React from 'react'
import { connect } from 'react-redux'

import { getShowErrors } from 'modules/step/selectors'
import { ControlElementEpilot } from 'modules/step/types'
import { GlobalAppState } from 'modules/types'

// This was necessary because old component library also uses an important on the font, that overrides the icons
const StyledIcon = styled(Icon)`
  font-family: 'Material Icons' !important;
  font-size: 30px !important;
`

const StyledGrid = styled(Grid)`
  cursor: pointer;
  flex-grow: 0 !important;
`

const StyledDiv = styled.div`
  width: 100%;
`

const StyledLabel = styled.label`
  font-size: 12px;
  font-weight: 600;
  top: 10px;
  text-align: left;
`

class IconRadioControl extends Control<
  ControlProps & OwnPropsOfEnum & { showErrors?: typeof getShowErrors },
  ControlState
> {
  handleChangeLocal = (id: string): void => {
    const { handleChange, path } = this.props

    handleChange(path, id)
  }

  render() {
    const { options: enumOptions, data, uischema } = this.props

    // TODO: add rule logic for "DISABLED"
    if (!enumOptions) throw Error('RadiosControl is expecting options/enums')

    // jsonforms changed options type from any[] to EnumOption[] in version 2.5.0
    // instead of having an array of values now we have an array with an object: { value: string, label: string }
    const options = enumOptions?.map(({ value }) => value)

    const radioElement = uischema as ControlElementEpilot
    const radioLabel = radioElement.label

    return (
      <StyledDiv>
        <StyledLabel>{radioLabel}</StyledLabel>
        <Grid container justify="space-between" wrap="nowrap">
          {options.map((option) => {
            const iconName =
              data && data >= option ? 'person' : 'person_outline'

            return (
              <StyledGrid
                item
                key={option}
                onClick={() => this.handleChangeLocal(option)}
                xs
              >
                <StyledIcon color="primary" name={iconName} />
              </StyledGrid>
            )
          })}
        </Grid>
      </StyledDiv>
    )
  }
}

export default connect((state: GlobalAppState) => ({
  showErrors: getShowErrors(state),
}))(withJsonFormsEnumProps(IconRadioControl))

export const iconRadioControlTester: RankedTester = rankWith(
  99,
  and(isStringControl, optionIs('format', 'icon-radio')),
)
