import {
  InputControl as InputControlComponent,
  MuiHidden,
} from '@epilot/components'
import {
  and,
  ControlProps,
  isStringControl,
  optionIs,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { ChangeEvent, useState } from 'react'
import InputMask from 'react-input-mask'
import { useSelector } from 'react-redux'

import { generateStringLabel } from 'lib/is-required-scope'
import { getShowErrors } from 'modules/step/selectors'

import { validateIBAN, isRequiredScope } from '../../../lib'

const IbanControl = ({
  id,
  data,
  label,
  visible,
  errors,
  handleChange,
  path,
  rootSchema,
  uischema,
}: ControlProps) => {
  const isRequired = isRequiredScope(rootSchema, uischema.scope)
  const stringLabel = generateStringLabel(label as string, isRequired)

  const [localError, setLocalError] = useState('')
  const showErrors = useSelector(getShowErrors)

  const handleChangeLocal = (event: ChangeEvent<HTMLInputElement>): void => {
    event.stopPropagation()

    const value = event.target.value
    const error = validateIBAN(value)

    if (error) {
      if (!localError) {
        handleChange(path, undefined)
      }

      setLocalError(error)
    } else {
      setLocalError('')
      handleChange(path, value)
    }
  }

  return (
    <MuiHidden xsUp={!visible}>
      <InputMask
        mask="DE99 9999 9999 9999 9999 99"
        onChange={handleChangeLocal}
        value={data}
      >
        {() => (
          <InputControlComponent
            error={localError || (showErrors ? errors : '')}
            fullWidth
            id={id}
            label={stringLabel}
            type="text"
          />
        )}
      </InputMask>
    </MuiHidden>
  )
}

export default withJsonFormsControlProps(IbanControl)

export const ibanControlTester: RankedTester = rankWith(
  99,
  and(isStringControl, optionIs('format', 'iban')),
)
