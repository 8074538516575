import { styled } from '@epilot/components'

const IconStyled = styled.div`
  svg {
    border: 1px solid #d2dee4;
    border-radius: 50%;
    box-sizing: content-box;
    color: #849096;
    height: 45px;
    margin-bottom: 24px;
    overflow: visible;
    padding: 25px;
    width: 45px;
  }
`

export default IconStyled
