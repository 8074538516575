import { SmallText } from '@epilot/components/dist'
import React from 'react'

import { Currency } from 'lib/api.types'

import PriceStyled from './price-styled'

export type PriceProps = {
  currency?: Currency
  description: string
  value: number
  highlighted?: boolean
  small?: boolean
  variant?: 'bigger' | 'default'
}

const Price = ({
  description,
  value,
  currency = 'EUR',
  highlighted = false,
  small = false,
  variant = 'default',
}: PriceProps): JSX.Element => {
  let currencySymbol = ''

  switch (currency) {
    case 'EUR':
      currencySymbol = '€'
      break
    case 'CHF':
      currencySymbol = 'Fr.'
      break
    default:
      break
  }

  const [integerPrice, decimalPrice] = value.toFixed(2).split('.')

  return (
    <PriceStyled highlighted={highlighted} small={small} variant={variant}>
      <div className="prices-container">
        <span className="small">{currencySymbol}</span>
        <span className="big">{integerPrice || 0}</span>
        <div className="small-container">
          <span className="small">
            {!highlighted && small
              ? '.' + (decimalPrice || '00')
              : decimalPrice || '00'}
          </span>
          <SmallText
            className={!highlighted ? 'price-description ' : 'small-price-text'}
          >
            {description}
          </SmallText>
        </div>
      </div>
    </PriceStyled>
  )
}

export default Price
