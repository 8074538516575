import { ContractType } from 'lib/api/types'
import { ProductCategory } from 'modules/shop/types'

import { Step } from '../types'

type ResultsConsumptionCalculator = {
  customer_type: 'PRIVATE'
  power_consumption?: { amount: number; numberOfItems: number }
  gas_consumption?: { amount: number; numberOfItems: number }
}

type ResultsProductOverviewPower = {
  product_ids: string
  previewMode: boolean
  power_product_selected: string
}

type ResultsProductOverviewGas = {
  product_ids: string
  previewMode: boolean
  gas_product_selected: string
}

type ResultsPersonalInformation = {
  consumption_calculator: {
    selected_product_category: ProductCategory
  }
  power_icon_value: number
  postalcode: string
  city: string
  postalcode_city: {
    zipCode: string
    city: string
    streetName?: string
    houseNumber?: string
    addressExtention?: string
    countryCode?: string
  }
  power_consumption: number
  salutation: string
  title?: string
  first_name: string
  last_name: string
  birth_date?: string
  email: string
  telephone?: string
  agreement?: boolean
  company_name?: string
  billing_company_name?: string
  disable_postalcode?: boolean
  different_invoice_recipient?: boolean
  address_delivery_street_name: string
  address_delivery_street_number: string
  address_delivery_additional_address?: string
  invoice_recipient_salutation?: string
  invoice_recipient_title?: string
  invoice_recipient_company_name?: string
  invoice_recipient_first_name?: string
  invoice_recipient_last_name?: string
} & (
  | { different_billing_address?: never; billing_address?: never }
  | {
      different_billing_address: true
      billing_address: {
        zipCode: string
        city: string
        streetName?: string
        houseNumber?: string
        addressExtention?: string
        countryCode?: string
      }
    }
)

type ResultsCancellation = {
  situation_power: string
  situation_gas: string
  terminate_contract_power?: string
  terminate_contract_gas?: string
  previous_provider_power?: { bdew_code: string; company_name: string }
  previous_provider_gas?: { bdew_code: string; company_name: string }
  delivery_date_power?: string
  delivery_date_asap_power?: boolean
  delivery_date_gas?: string
  delivery_date_asap_gas?: boolean
  termination_date_asap_power?: boolean
  termination_date_power?: string
  termination_date_gas?: string
  termination_date_asap_gas?: boolean

  first_time_occupancy_power?: string
  first_time_occupancy_gas?: string
  moving_date_power?: string
  moving_date_gas?: string

  power_readings?: {
    meterNumber: string
    marketLocationId: string
    meterReading: string
  }[]
  gas_readings?: {
    meterNumber: string
    marketLocationId: string
    meterReading: string
  }[]
}

type ResultsPaymentInformation = {
  payment_method: 'sepa' | 'invoice'
  account_owner?: string
  sepa?: {
    iban: string
    bic_number: string
    bank_name: string
  }
  payment_agreement?: boolean
}

type ResultsSummary = {
  signature?: string
  accept_terms: boolean
  withdrawal: boolean
  accept_promotions?: boolean
  accept_assignment?: boolean
}

type ResultsThankYouNew = {
  send_email?: boolean
}

export type TypedStep = Step & {
  // TODO: we somehow need to replace this. The property is only being used to determine the type inside a typeguard, for nothing more..
  isTypedStep: true
  resultData: {
    previewMode?: boolean
  } & (
    | ResultsConsumptionCalculator
    | ResultsProductOverviewPower
    | ResultsProductOverviewGas
    | ResultsPersonalInformation
    | ResultsPaymentInformation
    | ResultsCancellation
    | ResultsSummary
  )
}

export type PayloadResults = ResultsConsumptionCalculator &
  ResultsProductOverviewPower &
  ResultsProductOverviewGas &
  ResultsPersonalInformation &
  ResultsCancellation &
  ResultsPaymentInformation &
  ResultsSummary &
  ResultsThankYouNew

export const isValidPayloadKey = (
  obj: any,
  property: string,
): property is keyof PayloadResults => {
  return obj && obj[property]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTypedStep(arg: any): arg is TypedStep {
  return arg && arg.isTypedStep === true
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isContractType = (arg: any): arg is ContractType => {
  if (typeof arg === 'string') {
    if (arg === '') return true
    if (arg === 'switching') return true
    if (arg === 'new_contract') return true
  }

  return false
}
