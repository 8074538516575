import { BlueprintElements } from 'types/blueprint-config'

type AlignmentType = 'baseline' | 'center' | 'flex-end'

export const buildTextBlock = (
  textContent: string,
  textVariant: any,
  alignment?: AlignmentType,
): BlueprintElements[] => {
  const uischema: BlueprintElements[] = [
    {
      type: 'GridItemLayout',
      options: {
        xs: 12,
      },
      elements: [
        {
          type: 'StackSpacingLayout',
          options: {
            scale: 6,
            alignItems: alignment,
          },
          elements: [
            {
              type: 'Label',
              text: textContent,
              options: {
                variant: textVariant,
              },
            },
          ],
        },
      ],
    },
  ]

  return uischema
}
