import { MuiFormHelperText } from '@epilot/components/dist'
import {
  and,
  ControlProps,
  OwnPropsOfEnum,
  RankedTester,
  rankWith,
  schemaTypeIs,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsEnumProps } from '@jsonforms/react'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { addInjectedAddonsToCart } from 'lib/cart-utils'
import { getInjectedData } from 'modules/app/selectors'
import { cartAddProduct } from 'modules/shop/reducer'
import { getProductsByCategory, getShop } from 'modules/shop/selectors'
import { Product } from 'modules/shop/types'
import { getShowErrors } from 'modules/step/selectors'
import { GlobalAppState } from 'modules/types'

import ProductsSelection from './products-selection'

const ProductsControl = ({
  data,
  handleChange,
  path,
  uischema,
  errors,
}: ControlProps & OwnPropsOfEnum) => {
  const productCategory = uischema?.options?.productCategory
  let products = useSelector((state: GlobalAppState) =>
    getProductsByCategory(state, productCategory),
  )
  const state = useSelector((state: GlobalAppState) => getShop(state))
  const injectedData = useSelector((state: GlobalAppState) =>
    getInjectedData(state),
  )
  const monitorIdString = state.monitorFieldsLastValue.product_ids as string

  if (monitorIdString !== undefined) {
    const monitorIds = monitorIdString.split(',')
    const sortedProducts: Product[] = []

    monitorIds.forEach((productId) => {
      const searchProduct = products.find((ele) => ele.id === productId)

      if (searchProduct !== undefined) sortedProducts.push(searchProduct)
    })

    products = sortedProducts
  }

  const showErrors = useSelector(getShowErrors)
  const dispatch = useDispatch()

  const error =
    errors === 'Ist erforderlich.'
      ? 'Bitte wählen Sie zuerst ein Produkt aus'
      : errors

  const handleChangeLocal = (id: string): void => {
    handleChange(path, id)
    dispatch(cartAddProduct(id, uischema?.options?.productCategory, true))

    // if injectedData and product selection step not skipped,
    // then handle adding an addon on every product selection click
    addInjectedAddonsToCart(injectedData, products, id, dispatch)
  }

  if (!productCategory)
    throw Error('ProductsControl is expecting uischema.options.productcategory')

  const withDetails = uischema?.options?.withDetails
  const tariffDetails = uischema?.options?.tariffDetails
  const showBonus = uischema?.options?.showBonus
  const showFootnote = uischema?.options?.showFootnote

  return (
    <>
      <ProductsSelection
        onSelectedChange={(value: string) => handleChangeLocal(value)}
        open={true}
        products={products}
        selectedIds={data}
        showBonus={showBonus}
        showFootnote={showFootnote}
        tariffDetails={tariffDetails}
        withDetails={withDetails}
      />
      {showErrors && <MuiFormHelperText error>{error}</MuiFormHelperText>}
    </>
  )
}

export default withJsonFormsEnumProps(ProductsControl)

export const productsControlTester: RankedTester = rankWith(
  99,
  and(uiTypeIs('Products'), schemaTypeIs('string')),
)
