import { BlueprintStep } from 'types/blueprint-config'

import {
  addressSchemaProperties,
  defaultAddressSchemaProperties,
} from '../schemas/address.schema'
import { defaultConsumptionCalculatorSchemaProperties } from '../schemas/consumption-calculator.schema'
import {
  personalInformationSchemaProperties,
  personalInformationSchemaRequired,
} from '../schemas/personal-information.schema'
import {
  buildDeliveryAddressBlock,
  buildBillingAddressBlock,
} from '../uischemas/address.uischema'
import { buildPersonalInformationBlock } from '../uischemas/personal-information.uischema'

export const personalInformation: BlueprintStep = {
  id: 'personal_information',
  title: 'Personal information',
  nextButtonLabel: 'Weiter',
  schema: {
    type: 'object',
    properties: {
      ...personalInformationSchemaProperties,
      ...addressSchemaProperties,
      power: {
        type: 'object',
        properties: {
          showAdditionalFields: {
            type: 'boolean',
          },
        },
      },
    },
    ...personalInformationSchemaRequired,
    allOf: [
      {
        if: {
          properties: {
            [defaultAddressSchemaProperties.different_billing_address.name]: {
              const: true,
            },
          },
        },
        then: {
          properties: {
            [defaultAddressSchemaProperties.billing_address.name]: {
              minProperties: 5,
              errorMessage: 'Ist erforderlich.',
              required: [
                'countryCode',
                'zipCode',
                'city',
                'streetName',
                'houseNumber',
              ],
            },
          },
          required: [
            defaultConsumptionCalculatorSchemaProperties.postalcode_city.name,
            defaultAddressSchemaProperties.billing_address.name,
          ],
        },
        else: {
          properties: {
            [defaultConsumptionCalculatorSchemaProperties.postalcode_city
              .name]: {
              minProperties: 5,
              errorMessage: 'Ist erforderlich.',
              required: [
                'countryCode',
                'zipCode',
                'city',
                'streetName',
                'houseNumber',
              ],
            },
          },
          required: [
            defaultConsumptionCalculatorSchemaProperties.postalcode_city.name,
          ],
        },
      },
      {
        if: {
          allOf: [
            {
              properties: {
                [defaultAddressSchemaProperties.different_billing_address
                  .name]: {
                  const: true,
                },
              },
            },
            {
              properties: {
                [defaultAddressSchemaProperties.different_invoice_recipient
                  .name]: {
                  const: true,
                },
              },
            },
          ],
        },
        then: {
          required: [
            defaultAddressSchemaProperties.invoice_recipient_salutation.name,
            defaultAddressSchemaProperties.invoice_recipient_first_name.name,
            defaultAddressSchemaProperties.invoice_recipient_last_name.name,
          ],
        },
      },
    ],
  },
  uischema: {
    type: 'StepperNavigation',
    options: {
      steps: [
        'personal_information',
        'contract_cancellation',
        'payment_information',
        'summary',
      ],
    },
    elements: [
      {
        type: 'GridContainerLayout',
        options: {
          spacing: 6,
        },
        elements: [
          {
            type: 'GridItemLayout',
            options: {
              xs: 12,
            },
            elements: [
              {
                type: 'Label',
                text: 'Persönliche Informationen',
                options: {
                  variant: 'h1',
                },
              },
            ],
          },
          {
            type: 'GridItemLayout',
            options: { xs: 12, md: 7 },
            elements: [
              {
                type: 'GridContainerLayout',
                options: {
                  spacing: 6,
                },
                elements: [
                  {
                    type: 'GridItemLayout',
                    options: {
                      xs: 12,
                    },
                    elements: [
                      {
                        type: 'CardLayout',
                        elements: [
                          {
                            type: 'StepContent',
                            options: {
                              headline: 'Kontaktangaben',
                              headlineCaption: '* Benötigte Angaben',
                            },
                            elements: [
                              {
                                ...buildPersonalInformationBlock({
                                  showSalutation: true,
                                  showTitle: true,
                                  showBirthdate: true,
                                  showTelephone: true,
                                  showAgreement: false,
                                }),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'GridItemLayout',
                    options: {
                      xs: 12,
                    },
                    elements: [
                      {
                        type: 'CardLayout',
                        elements: [
                          {
                            type: 'StepContent',
                            options: {
                              headline: 'Lieferadresse',
                            },
                            elements: [
                              {
                                ...buildDeliveryAddressBlock(true, true),
                                rule: {
                                  effect: 'SHOW',
                                  condition: {
                                    scope:
                                      '#/properties/power/properties/showAdditionalFields',
                                    schema: {
                                      const: true,
                                    },
                                  },
                                },
                              },
                              {
                                ...buildDeliveryAddressBlock(),
                                rule: {
                                  effect: 'HIDE',
                                  condition: {
                                    scope:
                                      '#/properties/power/properties/showAdditionalFields',
                                    schema: {
                                      const: true,
                                    },
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'GridItemLayout',
                    options: {
                      xs: 12,
                    },
                    elements: [
                      {
                        type: 'CardLayout',
                        elements: [
                          {
                            type: 'StepContent',
                            options: {
                              headline: 'Rechnungsadresse',
                              showNextButtonInDesktop: true,
                            },
                            elements: [
                              {
                                ...buildBillingAddressBlock(),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'GridItemLayout',
            options: { xs: 12, md: 5 },
            elements: [
              {
                type: 'Cart',
              },
            ],
          },
          {
            type: 'GridItemLayout',
            options: {
              xs: 12,
            },
            elements: [
              {
                type: 'ForwardSummaryTile',
                options: {
                  mobileOnly: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  resultData: {
    [defaultAddressSchemaProperties.disable_postalcode.name]: true,
    [defaultAddressSchemaProperties.different_billing_address.name]: false,
    [defaultAddressSchemaProperties.different_invoice_recipient.name]: false,
  },
  logic: {
    rules: [
      {
        effect: 'NEXT_STEP',
        condition: {
          schema: 'Strom',
          scope: `${defaultConsumptionCalculatorSchemaProperties.selected_tab.name}`,
          action: {
            nextSteps: 'contract_cancellation_power',
          },
        },
      },
      {
        effect: 'NEXT_STEP',
        condition: {
          schema: 'Gas',
          scope: `${defaultConsumptionCalculatorSchemaProperties.selected_tab.name}`,
          action: {
            nextSteps: 'contract_cancellation_gas',
          },
        },
      },
      {
        effect: 'NEXT_STEP',
        condition: {
          schema: 'Strom & Gas',
          scope: `${defaultConsumptionCalculatorSchemaProperties.selected_tab.name}`,
          action: {
            nextSteps: 'contract_cancellation',
          },
        },
      },
    ],
  },
}
