import {
  BodyText,
  Box,
  Button,
  Grid,
  H2,
  SmallText,
} from '@epilot/base-elements'
import { MuiHidden } from '@epilot/components/dist'
import {
  Layout,
  LayoutProps,
  RankedTester,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core'
import {
  ResolvedJsonFormsDispatch,
  withJsonFormsLayoutProps,
} from '@jsonforms/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { goNext } from 'modules/step/reducer'
import { getActiveStepNextButtonLabel } from 'modules/step/selectors'

export type StepContentRendererOptions = {
  headline?: string
  headlineCaption?: string
  description?: string
  subheadline?: string
  showNextButtonInDesktop?: boolean
}

const StepContentRenderer = ({
  uischema,
  path,
  enabled,
  renderers,
  schema,
  cells,
}: LayoutProps): React.ReactElement | null => {
  const layout = uischema as Layout
  const {
    headline,
    headlineCaption,
    description,
    subheadline,
    showNextButtonInDesktop,
  } = (uischema?.options || {}) as StepContentRendererOptions
  const nextButtonLabel = useSelector(getActiveStepNextButtonLabel)
  const dispatch = useDispatch()

  return (
    <>
      {(headline || description || subheadline || headlineCaption) && (
        <Grid container spacing={8}>
          {(headline || headlineCaption) && (
            <Grid item xs={12}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
              >
                <H2 color="textPrimary">{headline}</H2>
                <SmallText align="right">{headlineCaption}</SmallText>
              </Box>
            </Grid>
          )}
          {description && (
            <Grid item xs={12}>
              <BodyText>{description}</BodyText>
            </Grid>
          )}
          {subheadline && (
            <Grid item xs={12}>
              <H2 color="textPrimary">{subheadline}</H2>
            </Grid>
          )}
        </Grid>
      )}
      <Box mb={4} mt={4}>
        {layout.elements.map((child, index) => (
          <ResolvedJsonFormsDispatch
            cells={cells}
            enabled={enabled}
            key={index}
            path={path}
            renderers={renderers}
            schema={schema}
            uischema={child}
          />
        ))}
      </Box>
      <MuiHidden smDown={true} smUp={!showNextButtonInDesktop}>
        <Grid container>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Button
              color="primary"
              fullWidth
              onClick={() => dispatch(goNext())}
              size="large"
              variant="contained"
            >
              {nextButtonLabel}
            </Button>
          </Grid>
        </Grid>
      </MuiHidden>
    </>
  )
}

export const stepContentTester: RankedTester = rankWith(
  1,
  uiTypeIs('StepContent'),
)

export default withJsonFormsLayoutProps(StepContentRenderer)
