import { Box } from '@epilot/base-elements'
import {
  Autocomplete,
  MuiHidden,
  InputControl as InputControlComponent,
} from '@epilot/components'
import {
  and,
  ControlProps,
  optionIs,
  RankedTester,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { generateStringLabel } from 'lib/is-required-scope'
import { getOrganizationId } from 'modules/app/selectors'
import { searchStreet } from 'modules/step/reducer'
import {
  getShowErrors,
  getAllResultData,
  getActiveStep,
} from 'modules/step/selectors'

import { getHtmlId, isRequiredScope } from '../../../lib'
import { useOverwritePlaceholderStyles } from '../styles'

const StreetControl = ({
  id,
  data,
  label,
  visible,
  handleChange,
  path,
  uischema,
  errors,
  rootSchema,
}: ControlProps) => {
  const classes = useOverwritePlaceholderStyles()
  const htmlId = getHtmlId(id)
  const isRequired = isRequiredScope(rootSchema, uischema.scope)
  const stringLabel = generateStringLabel(label as string, isRequired)

  const organizationid = useSelector(getOrganizationId)
  const showErrors = useSelector(getShowErrors)
  const allResultData = useSelector(getAllResultData)
  const postalcodeCityScope = uischema?.options?.postalcodeCityScope
  const streetListScope = uischema?.options?.streetListScope
  const apiEndpoint = uischema?.options?.endpoint
  const [postalcodeCity, setPostalcodeCity] = useState({
    city: '',
    postalcode: '',
  })
  const dispatch = useDispatch()
  const handleSearch = useCallback(
    (city, postalcode) =>
      dispatch(
        searchStreet(
          city,
          postalcode,
          organizationid || '',
          apiEndpoint,
          streetListScope,
        ),
      ),
    [dispatch, apiEndpoint, organizationid, streetListScope],
  )

  if (allResultData[postalcodeCityScope]) {
    const city = allResultData[postalcodeCityScope]['city']
    const postalcode = allResultData[postalcodeCityScope]['postalcode']

    if (
      postalcode !== postalcodeCity.postalcode ||
      city !== postalcodeCity.city
    ) {
      setPostalcodeCity({ city, postalcode })
      handleSearch(city, postalcode)
    }
  }
  const stepData = useSelector(getActiveStep).schema.properties
  const streetListStr = stepData?.[streetListScope]?.description
  let streetList = []

  if (streetListStr) {
    streetList = JSON.parse(streetListStr)
  } else {
    streetList = []
  }

  const handleChangeLocal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (value.length === 0) {
      handleChange(path, undefined)
    } else {
      handleChange(path, value)
    }
  }

  if (streetList.length === 0) {
    return (
      <MuiHidden xsUp={!visible}>
        <Box className={classes.inputOverwrite}>
          <InputControlComponent
            error={showErrors ? errors : ''}
            fullWidth
            id={id}
            label={stringLabel}
            onChange={handleChangeLocal}
            value={data ?? ''}
          />
        </Box>
      </MuiHidden>
    )
  } else {
    return (
      <MuiHidden xsUp={!visible}>
        <Box className={classes.inputOverwrite}>
          <Autocomplete
            disableOpenOnFocus
            error={showErrors && errors.length > 0}
            filterOption={(streets, value) => {
              const lowstreets = streets.toLocaleLowerCase()

              if (!value) return false

              lowstreets.startsWith(value.toLocaleLowerCase())

              return true
            }}
            getOptionLabel={(streetName) => {
              return streetName
            }}
            helperText={showErrors ? errors : ''}
            id={htmlId}
            label={label as string}
            name={htmlId}
            noOptionsText={uischema?.options?.noOptionsText}
            onChange={(_, value) => handleChange(path, value)}
            options={streetList}
            placeholder={uischema?.options?.placeholder}
            value={data ?? ''}
          />
        </Box>
      </MuiHidden>
    )
  }
}

export default withJsonFormsControlProps(StreetControl)
export const StreetControlTester: RankedTester = rankWith(
  99,
  and(uiTypeIs('Control'), optionIs('format', 'street')),
)
