import './cart-totals.css'

import { BodyText, MuiTheme, withMuiTheme, SmallText } from '@epilot/components'
import React from 'react'
import { useSelector } from 'react-redux'

import { formatCurrency } from 'lib/format-currency'
import {
  getCart,
  getCartTotalSummary,
  getProductPriceIntervalLabel,
  getProductPricingBusiness,
} from 'modules/shop/selectors'

const CartTotals = ({ theme }: { theme: MuiTheme }) => {
  const cartTotalValues = useSelector(getCartTotalSummary)
  const cart = useSelector(getCart)
  const priceIntervalLabel = useSelector(getProductPriceIntervalLabel)
  const pricingBusiness = useSelector(getProductPricingBusiness)
  const isEmpty = cart.length < 1

  const containsBroadband =
    cart.filter(
      (product) => product.productCategory.toLocaleUpperCase() === 'BROADBAND',
    ).length > 0

  const recurringTotal = pricingBusiness
    ? cartTotalValues.net
    : cartTotalValues.gross
  const oneTimeTotal = pricingBusiness
    ? cartTotalValues.oneTimeNet
    : cartTotalValues.oneTimeGross

  return (
    <div className={'cart-totals--container'}>
      <div
        className={'cart-total'}
        style={{
          color: theme.palette.primary.main,
        }}
      >
        {isEmpty ? 'Warenkorb ist leer' : `${cart.length} Artikel`}
      </div>
      <div className={'cart-price'}>
        <div>
          {isEmpty ? '' : <BodyText>{formatCurrency(recurringTotal)}</BodyText>}
          {isEmpty ? '' : <BodyText>{'/' + priceIntervalLabel}</BodyText>}
        </div>
        {!isEmpty && (containsBroadband || oneTimeTotal > 0) && (
          <SmallText>{`+${formatCurrency(oneTimeTotal)} einmalig`}</SmallText>
        )}
      </div>
    </div>
  )
}

export default withMuiTheme(CartTotals)
