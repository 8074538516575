import React from 'react'
import { useSelector } from 'react-redux'

import { AddonSellingOption } from 'lib/api.types'
import {
  getProductPricingBusiness,
  getProductPriceIntervalLabel,
} from 'modules/shop/selectors'

import Price from '../price/price'

import AddonPricesStyled from './addon-prices-styled'

export type AddonPricesProps = {
  prices: AddonSellingOption
  variant?: 'bigger' | 'default'
}

const AddonPrices = ({
  prices,
  variant = 'default',
}: AddonPricesProps): JSX.Element => {
  const pricingBusiness = useSelector(getProductPricingBusiness)
  const priceIntervalLabel = useSelector(getProductPriceIntervalLabel)
  const oneTimePrice = pricingBusiness
    ? prices.one_time_price?.amount_net
    : prices.one_time_price?.amount_gross
  const recurringPrice = pricingBusiness
    ? priceIntervalLabel === 'Monat'
      ? prices.recurring_price?.monthly?.amount_net ||
        (prices?.recurring_price?.yearly?.amount_net &&
          prices?.recurring_price?.yearly?.amount_net / 12)
      : prices.recurring_price?.yearly?.amount_net
    : priceIntervalLabel === 'Monat'
    ? prices.recurring_price?.monthly?.amount_gross ||
      (prices?.recurring_price?.yearly?.amount_gross &&
        prices?.recurring_price?.yearly?.amount_gross / 12)
    : prices.recurring_price?.yearly?.amount_gross

  return (
    <AddonPricesStyled variant={variant}>
      {recurringPrice && (
        <Price
          currency={
            priceIntervalLabel === 'Monat'
              ? prices.recurring_price?.monthly?.currency_id
              : prices.recurring_price?.yearly?.currency_id
          }
          description={
            priceIntervalLabel === 'Monat' ? 'monatlich' : 'jährlich'
          }
          highlighted={true}
          value={recurringPrice}
        />
      )}
      {oneTimePrice && (
        <Price
          currency={prices.one_time_price?.currency_id}
          description={'einmalig'}
          highlighted={true}
          value={oneTimePrice}
        />
      )}
      {!recurringPrice && !oneTimePrice && (
        <Price
          currency={prices.one_time_price?.currency_id}
          description={''}
          value={0}
        />
      )}
    </AddonPricesStyled>
  )
}

export default AddonPrices
