import { format } from 'date-fns'
import {
  all,
  call,
  put,
  SagaGenerator,
  select,
  takeLatest,
} from 'typed-redux-saga'

import config from 'config'
import {
  ProductAPI,
  ProductAPIResponse,
  ProductAvailabilityAPI,
} from 'lib/api.types'
import { apiCall, getWidgetConfiguration } from 'lib/api/epilot.api'
import { addQueryToURL } from 'lib/api/utils'
import { ObjectKeyString } from 'lib/types'
import {
  getOrganizationId,
  getFilterLabel,
  getInitialConfig,
} from 'modules/app/selectors'
import { generateEnetFlag } from 'modules/generateEnetFlag'
import { queryTemplateToQuery } from 'modules/step/helper'
import { ERROR_STEP } from 'modules/step/saga'
import { getAllResultData, getActiveStep } from 'modules/step/selectors'
import { TypedAction } from 'modules/types'

import { enterFullscreen } from '../app/reducer'
import {
  getScopedProductData,
  hasChangedMonitorFields,
  justUppercaseAtFirstCharacter,
} from '../shop/helper'
import {
  goNext,
  goToStep,
  loadGridOperatorSuccess,
  LOAD_GRID_OPERATOR,
  replaceNextStepId,
  replaceStepSchemaProperty,
} from '../step/reducer'

import {
  CHECK_PRODUCT_AVAILABILITY,
  CHECK_PRODUCT_AVAILABILITY_SUCCESS,
  CHECK_PRODUCT_AVAILABILITY_FAILURE,
  checkProductAvailability,
  checkProductAvailabilitySuccess,
  checkProductAvailabilityFailure,
  LOAD_PRODUCTS,
  loadProducts,
  loadProductsSuccess,
  setMonitorFieldValue,
  SHOP_CHECK_PROPERTY_CHANGES,
  cartAddProduct,
  cartEmpty,
} from './reducer'
import {
  getMonitorFieldsLastValue,
  getProductApi,
  getProductSettings,
  getProductAvailabilityApi,
  getEnetApi,
} from './selectors'

function* shopCheckPropertyChangesFlow(): Generator {
  // The shop-configuration has one or more product categories defined, these
  // categories can have `loadProductsScope` or `loadProductsWithPriceScope`
  // as array to define the scoped properties which are required to make an
  // API call to load the products (with calculated prices)

  const globalData = yield* select(getAllResultData)
  // Check if field values have changed
  const monitorFieldsLastValue = yield* select(getMonitorFieldsLastValue)

  if (!hasChangedMonitorFields(monitorFieldsLastValue, globalData)) {
    return
  }

  const productSettings = yield* select(getProductSettings)
  const loadProductActions = [] as SagaGenerator<any>[]
  const currentConfig = yield* select(getActiveStep)
  const productCategory =
    productSettings?.categories[0]?.id &&
    productSettings?.categories[0]?.id.toLowerCase()
  const scopeOfConsumption = `${productCategory}_consumption`
  const consumptionKey = productCategory && scopeOfConsumption

  if (globalData['consumption']) {
    const consumptionValue = globalData['consumption']

    delete globalData['consumption']
    globalData[consumptionKey] = consumptionValue
  }

  productSettings.categories.forEach((category) => {
    // LOAD PRODUCTS
    let productsScope =
      category.loadProductsScope?.map((scope) => {
        if (scope === 'consumption') {
          return `${productCategory}_consumption`
        } else {
          return scope
        }
      }) || []

    //set load product scope for preview module in widget builder
    if (currentConfig.resultData?.previewMode) {
      productsScope = ['product_ids']
    }

    if (productsScope && productsScope.length) {
      // Check if all scoped properties exists in global data
      const matchedValues = getScopedProductData(productsScope, globalData)

      if (matchedValues) {
        // Save changed fields
        loadProductActions.push(put(setMonitorFieldValue(matchedValues)))
        loadProductActions.push(put(checkProductAvailability()))
        loadProductActions.push(put(loadProducts()))
      }
    }
  })

  yield all(loadProductActions)
}

function* checkProductAvailabilityFlow(): Generator {
  try {
    const productAvailabilityApi = yield* select(getProductAvailabilityApi)
    const activeStep = yield* select(getActiveStep)
    const initialConfig = yield* select(getInitialConfig)

    if (
      productAvailabilityApi &&
      activeStep.id === productAvailabilityApi.checkingStep
    ) {
      const globalData = yield* select(getAllResultData)
      const organizationId = yield* select(getOrganizationId)
      const filterLabel = yield* select(getFilterLabel)

      const endpoint = productAvailabilityApi.endpoint
      const queryTemplate = productAvailabilityApi.queryTemplate
      const queryStatic = productAvailabilityApi.queryStatic
      const brandId = initialConfig?.builder?.brand_id
      let query = {
        ...(brandId && { brand_id: brandId }),
        organization_id: organizationId,
        ...(filterLabel ? { label: filterLabel } : undefined),
      } as Record<string, unknown>

      query = { ...query, ...queryTemplateToQuery(queryTemplate, globalData) }

      const urlWithQuery = addQueryToURL(endpoint, { ...query, ...queryStatic })

      const results = yield* call(apiCall, 'GET', urlWithQuery)

      yield put(
        checkProductAvailabilitySuccess(results as ProductAvailabilityAPI),
      )
    }
  } catch (error) {
    yield put(checkProductAvailabilityFailure(error))
  }
}

function* checkProductAvailabilitySuccessFlow({
  payload,
}: TypedAction<{
  productAvailability: ProductAvailabilityAPI
}>): Generator {
  try {
    const { items } = payload.productAvailability
    const productAvailabilityApi = yield* select(getProductAvailabilityApi)

    if (items && items.length > 0 && items[0].available) {
      const nextStepId = items[0].has_gnv
        ? productAvailabilityApi?.nextStepId.available.gnv
        : productAvailabilityApi?.nextStepId.available.noGnv

      if (nextStepId)
        yield put(
          replaceNextStepId(
            productAvailabilityApi?.checkingStep || '',
            nextStepId,
          ),
        )
    } else if (
      items &&
      items.length > 0 &&
      !items[0].available &&
      items[0].available_from
    ) {
      const nextStepId = productAvailabilityApi?.nextStepId.available.from

      if (nextStepId) {
        const year =
          items[0].available_from.split('-')[0] ||
          new Date(items[0].available_from).getFullYear().toString()

        yield put(
          replaceStepSchemaProperty(nextStepId, 'available_from', {
            type: 'string',
            default: parseInt(year, 10),
          }),
        )
        yield put(
          replaceNextStepId(
            productAvailabilityApi?.checkingStep || '',
            nextStepId,
          ),
        )
      }
    } else {
      yield put(checkProductAvailabilityFailure(Error('Product not available')))
    }
  } catch (error) {
    yield put(checkProductAvailabilityFailure(error))
  }
}

function* checkProductAvailabilityFailureFlow(): Generator {
  try {
    const productAvailabilityApi = yield* select(getProductAvailabilityApi)

    yield put(
      replaceNextStepId(
        productAvailabilityApi?.checkingStep || '',
        productAvailabilityApi?.nextStepId.notAvailable,
      ),
    )
  } catch (error) {
    console.error(error)
  }
}

function* loadProductsFlow(): Generator {
  try {
    const productApi = yield* select(getProductApi)
    const globalData = yield* select(getAllResultData)
    const organizationId = yield* select(getOrganizationId)
    const filterLabel = yield* select(getFilterLabel)
    const initialConfig = yield* select(getInitialConfig)
    const currentDate = format(new Date(), 'yyyy-MM-dd')
    const previewModule = globalData?.previewMode || false
    const enetCallInfo = initialConfig?.builder?.callEnetInfo
    // Product bundling:
    // If more than one product category then call product API each time
    // with the corresponding product consumption

    let categories

    if (typeof productApi.queryStatic.category === 'string')
      categories = [productApi.queryStatic.category]
    else {
      categories = [...productApi.queryStatic.category]
    }

    let items: ProductAPI[] = []

    for (let i = 0; i < categories.length; i++) {
      const category = categories[i]
      const currentEnetCallInfo = generateEnetFlag(category, enetCallInfo)

      const productCategory = category.toLowerCase() || ''

      const queryTemplate: ObjectKeyString<string> = {}
      const queryStatic = { ...productApi.queryStatic, category }
      const consumptionScope = `${productCategory}_consumption`

      Object.keys(productApi.queryTemplate).forEach((key: string) => {
        queryTemplate[key] = productApi.queryTemplate[key]
        if (queryTemplate[key] === 'consumption')
          queryTemplate[key] = consumptionScope
      })

      let query = {
        enet_call: previewModule
          ? 'false'
          : currentEnetCallInfo &&
            currentEnetCallInfo[0] &&
            currentEnetCallInfo[0].enetCall
          ? currentEnetCallInfo[0].enetCall.toString()
          : 'false',
        organization_id: organizationId,
        brand_id: initialConfig?.builder?.brand_id,
        delivery_date: currentDate,
        ...(filterLabel ? { label: filterLabel } : undefined),
      } as Record<string, unknown>

      query = { ...queryTemplateToQuery(queryTemplate, globalData), ...query }
      const urlWithQuery = addQueryToURL(productApi.endpoint, {
        ...query,
        ...queryStatic,
      })

      const results = yield* call(apiCall, 'GET', urlWithQuery)

      const categoryItems:
        | ProductAPI[]
        | undefined = (results as ProductAPIResponse).items

      if (categoryItems)
        items = [
          ...items,
          ...sortItemsBySelection(
            categoryItems,
            query['product_ids'] as string,
          ),
        ]
    }

    //Select first product if product selection step is hidden
    if (config.WIDGET_ID) {
      const widgetConfig = yield* call(getWidgetConfiguration, config.WIDGET_ID)
      const { builder, shop } = widgetConfig
      const productSelectionStep =
        builder?.steps && builder?.steps['product_selection']

      if (productSelectionStep && !productSelectionStep.toggle_has_visibility) {
        const firstProduct = items[0]

        // Empty the cart
        yield* put(cartEmpty())
        // Preselect first product
        yield* put(
          cartAddProduct(
            firstProduct.id.toString(),
            shop?.productApi.queryStatic.category,
          ),
        )
      }
    }
    // End of select first product

    yield* put(loadProductsSuccess(items))
  } catch (error) {
    // Will be logged to DataDog
    console.error(error)
    yield* put(enterFullscreen())
    yield* put(goToStep(ERROR_STEP))
  }
}

function* loadGridAmountAPI(
  selectedProductName: string,
  productCategory: string,
): Generator {
  const initialConfig = yield* select(getInitialConfig)
  const gridEnetAPI = yield* select(getEnetApi)

  const queryTemplate: ObjectKeyString<string> = {}

  const currentDate = format(new Date(), 'yyyy-MM-dd')

  Object.keys(gridEnetAPI.queryTemplate).forEach((key: string) => {
    queryTemplate[key] = gridEnetAPI.queryTemplate[key]
  })

  let productName = (selectedProductName
    ? selectedProductName
    : productCategory
  ).toLowerCase()

  if (selectedProductName === 'power & gas') {
    productName = 'power'
  }
  queryTemplate['consumption'] = queryTemplate['consumption'].replace(
    '{product}',
    productName,
  )
  const globalData = yield* select(getAllResultData)

  const query = {
    ...queryTemplateToQuery(queryTemplate, globalData),
  }

  if (
    query['consumption'] === undefined ||
    (query['consumption'] !== undefined && query['consumption'].length === 0)
  ) {
    query['consumption'] = '0'
  }

  const getGridOperatorAmountEndpoint =
    gridEnetAPI.endPoint + gridEnetAPI.gridOperatorAmount
  const urlWithQuery = addQueryToURL(getGridOperatorAmountEndpoint, {
    brand_id: initialConfig?.builder?.brand_id,
    product_type: justUppercaseAtFirstCharacter(productName),
    delivery_date: currentDate,
    ...query,
  })

  const results = yield* call(apiCall, 'GET', urlWithQuery)

  const gridAmountAPIResponse = (results as any).amount_grid_providers

  yield* put(loadGridOperatorSuccess(gridAmountAPIResponse, productName))

  return gridAmountAPIResponse
}

function* loadGridOperatorFlow(): Generator {
  let selectedProductName
  let productCategory

  try {
    const allResultData = yield* select(getAllResultData)

    const productSettings = yield* select(getProductSettings)

    productCategory = productSettings.categories[0].id.toLowerCase()

    // for bundle selection tab
    selectedProductName = allResultData && allResultData?.selected_tab
    selectedProductName =
      selectedProductName &&
      selectedProductName.replace('Strom', 'Power').toLowerCase()

    if (selectedProductName === 'power & gas') selectedProductName = 'power'

    const gridOperation =
      allResultData[selectedProductName ? selectedProductName : productCategory]

    if (
      gridOperation === undefined ||
      gridOperation.amount === undefined ||
      gridOperation.amount === -1
    ) {
      // load Amount api
      const amount = yield* loadGridAmountAPI(
        selectedProductName,
        productCategory,
      )

      // api return amount ===1 => load product api  -> go next
      if (amount === 1) {
        yield* put(loadProducts())
        yield* put(goNext())
      }
    } else {
      // has Amount
      yield* put(loadProducts())
      yield* put(goNext())
    }
  } catch (error) {
    // Will be logged to DataDog
    console.error(error)
    yield* put(enterFullscreen())
    yield* put(goToStep(ERROR_STEP))
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(SHOP_CHECK_PROPERTY_CHANGES, shopCheckPropertyChangesFlow),
    takeLatest(CHECK_PRODUCT_AVAILABILITY, checkProductAvailabilityFlow),
    takeLatest(
      CHECK_PRODUCT_AVAILABILITY_SUCCESS,
      checkProductAvailabilitySuccessFlow,
    ),
    takeLatest(LOAD_GRID_OPERATOR, loadGridOperatorFlow),
    takeLatest(
      CHECK_PRODUCT_AVAILABILITY_FAILURE,
      checkProductAvailabilityFailureFlow,
    ),
    takeLatest(LOAD_PRODUCTS, loadProductsFlow),
  ])
}

function sortItemsBySelection(categoryItems: ProductAPI[], orderIds?: string) {
  const selectedIds = orderIds && orderIds.split(',')

  if (selectedIds && selectedIds.length > 0) {
    return categoryItems.sort((a, b) => {
      const aIndex = selectedIds.indexOf(a.id.toString())
      const bIndex = selectedIds.indexOf(b.id.toString())

      if (aIndex < bIndex) return -1
      if (aIndex > bIndex) return 1

      return 0
    })
  }

  return categoryItems
}
