import { Components } from '@epilot/submission-client'
import jp from 'jsonpath'

import { BodyResults, ConsentInput } from 'lib/api/types'

import { StepsType } from '../types'

const DEFAULT_MARKETING_CONSENT =
  'Ich erkläre mich einverstanden mit der Verarbeitung und Nutzung der von mir im Rahmen dieses Vertrags erhobenen persönlichen Daten für an mich per Telefon und E-Mail gerichtete Werbung (Angebote und Informationen im Zusammenhang mit der Energiebelieferung). Meine Einwilligung kann ich jederzeit widerrufen.'

export const convertToSubmission = ({
  results,
  marketingConsentTitle,
}: {
  results: BodyResults
  marketingConsentTitle?: string
}): Components.Schemas.SubmissionPayload => {
  return {
    organization_id: results.organization_id,
    source_type: 'journey',
    source_id: results.widget_id,
    opt_ins: getOptIns({ results, marketingConsentTitle }),
    entities: [{ ...results, _schema: 'submission' }],
    journey_submit_id: results.journeySubmitId,
  }
}

/**
 * Tries to find marketing consents title.
 * Searches for nodes: '#/properties/accept_promotions' and decode base64 title.
 */
export const tryGetMarketingConsentTitleFromSteps = (steps: StepsType) => {
  const query = '$..elements[?(@.scope=="#/properties/accept_promotions")]'
  const nodes = jp.nodes(steps, query)

  try {
    return decodeBase64(nodes[0].value.label)
  } catch (err) {
    console.warn('Could not decode marketing consent title', { nodes, err })
  }
}

const getOptIns = ({
  results,
  marketingConsentTitle,
}: {
  results: BodyResults
  marketingConsentTitle?: string
}) => {
  if (!results.billing_contact?.email) return

  const optins: Components.Schemas.OptIn[] = []

  results.opportunities?.[0]?.consents?.forEach((consent) => {
    includeOptins(consent, optins, results, marketingConsentTitle)
  })

  // if no optins until now, check old v3 structure (consents data goes in additional_user_input)
  if (optins.length === 0) {
    results.additional_user_input?.consents?.forEach(
      (consent: ConsentInput) => {
        includeOptins(consent, optins, results, marketingConsentTitle)
      },
    )
  }

  return optins.length === 0 ? undefined : optins
}

function includeOptins(
  consent: ConsentInput,
  optins: Components.Schemas.OptIn[],
  results: BodyResults,
  marketingConsentTitle: string | undefined,
) {
  if (consent.type === 'marketing_consent' && consent.value === 'Ja') {
    optins.push({
      identifier: results.billing_contact?.email,
      topic: 'EMAIL_MARKETING',
      meta: {
        label: marketingConsentTitle || DEFAULT_MARKETING_CONSENT,
      },
    })
  }
}

function decodeBase64(encoded: string) {
  const binary = atob(encoded)
  const bytes = new Uint8Array(binary.length)

  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i)
  }

  return String.fromCharCode(...new Uint16Array(bytes.buffer))
}
