import { createSelector } from 'reselect'

import { GlobalAppState } from '../types'

import { AppState } from './types'

const getState = (state: GlobalAppState): AppState => state.app

// Reducer states
const isInitialized = (state: GlobalAppState) => getState(state).isInitialized
const isLoading = (state: GlobalAppState) => getState(state).isLoading
const error = (state: GlobalAppState) => getState(state).error
const meta = (state: GlobalAppState) => getState(state).meta
const styling = (state: GlobalAppState) => getState(state).styling
const initialConfig = (state: GlobalAppState) => getState(state).initialConfig
const resultMapping = (state: GlobalAppState) => getState(state).results
const injectedSettings = (state: GlobalAppState) =>
  getState(state).injectedSettings
const injectedData = (state: GlobalAppState) => getState(state).injectedData

// Getter
export const getIsInitialized = (state: GlobalAppState) => isInitialized(state)
export const getIsLoading = (state: GlobalAppState) => isLoading(state)
export const getError = (state: GlobalAppState) => error(state)
export const getMeta = (state: GlobalAppState) => meta(state)
export const getWidgetId = (state: GlobalAppState) => meta(state)?.widgetId
export const getWidgetVersion = (state: GlobalAppState) => meta(state)?.version
export const getOrganizationId = (state: GlobalAppState) =>
  meta(state)?.organizationId
export const getFilterLabel = (state: GlobalAppState) => state.app.filter_label

export const getTheme = (state: GlobalAppState) => styling(state).theme
export const getClientLogoUrl = (state: GlobalAppState) =>
  styling(state).clientLogoUrl
export const getClientLogoStyle = (state: GlobalAppState) =>
  styling(state).clientLogoStyle
export const getContentMaxWidth = (state: GlobalAppState) =>
  styling(state).contentMaxWidth

export const getInitialConfig = (state: GlobalAppState) => initialConfig(state)
export const getResultMapping = (state: GlobalAppState) => resultMapping(state)
export const getInjectedSettings = (state: GlobalAppState) =>
  injectedSettings(state)
export const getInjectedData = (state: GlobalAppState) => injectedData(state)

export const getClientLogo = createSelector(
  [getClientLogoUrl, getClientLogoStyle],
  (url, style) => ({
    url,
    style,
  }),
)

export const getShowHeader = (state: GlobalAppState) =>
  !styling(state).hideHeader
