import { Contact, DeliveryContact } from 'lib/api/types'
import {
  getSalutationFromLabel,
  getTitleFromLabel,
} from 'modules/step/helper/utils/getDataFromMapping/getMappedTitleAndSautation'

import { PayloadResults } from '../types'

export const dataContactsCreate = (results: PayloadResults) => {
  const deliveryContact: DeliveryContact = {
    salutation: getSalutationFromLabel(results.salutation),
    title: results.title && getTitleFromLabel(results.title),
    company_name: results.company_name || '',
    first_name: results.first_name.trim(),
    last_name: results.last_name.trim(),
    birthday: results.birth_date,
    street_name: results.postalcode_city?.streetName || '',
    street_number: results.postalcode_city?.houseNumber || '',
    address_suffix: results.postalcode_city?.addressExtention || '',
    country_code: results.postalcode_city?.countryCode || 'DE',
    city: results.postalcode_city.city,
    postal_code: results.postalcode_city.zipCode,
    email: results.email,
    phone: results.telephone,
  }

  const billingContact: Contact = results.different_billing_address
    ? {
        salutation: results.different_invoice_recipient
          ? results.invoice_recipient_salutation
            ? getSalutationFromLabel(results.invoice_recipient_salutation)
            : undefined
          : getSalutationFromLabel(results.salutation),
        title: results.different_invoice_recipient
          ? results.invoice_recipient_title
            ? getTitleFromLabel(results.invoice_recipient_title)
            : undefined
          : results.title
          ? getTitleFromLabel(results.title)
          : undefined,
        company_name:
          results.different_billing_address && results.billing_company_name
            ? results.billing_company_name
            : results.company_name || '',
        first_name: results.different_invoice_recipient
          ? results.invoice_recipient_first_name?.trim()
          : results.first_name.trim(),
        last_name: results.different_invoice_recipient
          ? results.invoice_recipient_last_name?.trim()
          : results.last_name.trim(),
        street_name: results.billing_address?.streetName || '',
        street_number: results.billing_address?.houseNumber || '',
        address_suffix: results.billing_address?.addressExtention || '',
        city: results.billing_address?.city,
        postal_code: results.billing_address?.zipCode,
        country_code: results.billing_address?.countryCode || 'DE',
        email: results.email,
        phone: results.telephone,
      }
    : deliveryContact

  return { deliveryContact, billingContact, additionalContacts: [] }
}

export const checkCustomerType = (results: PayloadResults) => {
  let customerType = results.customer_type.toUpperCase() as CustomerType

  const companyName = results.company_name

  if (companyName) {
    customerType = 'BUSINESS'
  }

  return customerType
}

type CustomerType = 'PRIVATE' | 'BUSINESS'
