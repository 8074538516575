import {
  Box,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
} from '@epilot/base-elements'
import {
  and,
  isEnumControl,
  optionIs,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsEnumProps } from '@jsonforms/react'
import React, { ReactElement } from 'react'

import { useErrorMessageStyles, useOverwritePlaceholderStyles } from '../styles'

import { useSelectBox } from './hooks'
import { useSelectBoxStyles } from './styles'
import { SelectBoxControlProps } from './types'

/**
 * This component is used as a JSONForms Renderer. The Schema defines the enum values. The UISchema defines the corresponding labels.
 * The component combines both, the enum values and the UISchema labels to the type {value: string; label: string}[]
 * If only enum values are provided by the Schema, the respective label for the option is equal to its value
 */
export const SelectBoxControl = (
  props: SelectBoxControlProps,
): ReactElement | null => {
  const {
    options: enumOptions = [],
    errors,
    rootSchema,
    uischema,
    label: originalLabel,
    handleChange,
    path,
    data,
  } = props

  const placeholderClasses = useOverwritePlaceholderStyles()
  const classes = useSelectBoxStyles()
  const errorMessageClasses = useErrorMessageStyles()

  const { label, options, showErrors } = useSelectBox({
    rootSchema,
    data,
    uischema,
    label: originalLabel,
    // jsonforms changed options type from any[] to EnumOption[] in version 2.5.0
    // instead of having an array of values now we have an array with an object: { value: string, label: string }
    enumOptions: enumOptions?.map(({ value }) => value),
  })

  // Todo: Auto switch to autocomplete if showAutoComplete === true
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const showAutoComplete = options.length > MAX_AMOUNT_SELECT_BOX

  const handleSelect = (_event: React.MouseEvent, value: string) => {
    handleChange(path, value === null ? undefined : value)
  }

  return (
    <Box className={placeholderClasses.inputOverwrite} mb={2}>
      <InputLabel className={classes.selectBoxLabel}>{label}</InputLabel>
      <ToggleButtonGroup
        className={classes.selectBoxContainer}
        exclusive
        onChange={handleSelect}
        value={data}
      >
        {options.map((option) => {
          return (
            <ToggleButton key={option.value} value={option.value}>
              {option.label}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
      {showErrors && (
        <Box className={errorMessageClasses.errorBox}>{errors}</Box>
      )}
    </Box>
  )
}

export default withJsonFormsEnumProps(SelectBoxControl)

export const selectBoxControlTester: RankedTester = rankWith(
  9,
  and(isEnumControl, optionIs('format', 'select-box')),
)
