import { styled } from '@epilot/components'

import { Theme } from '../../../modules/app/types'

const ProductButtonStyled = styled.button`
  width: 100%;
  padding: 6px;
  border: 1px solid;
  border-color: ${(props: { theme: Theme }) =>
    props.theme?.palette?.primary?.main};
  border-radius: 4px;
  text-align: center;
  background-color: transparent;

  &:focus {
    outline-color: ${(props: { theme: Theme }) =>
      props.theme?.palette?.primary?.main};
  }

  &.active {
    background-color: ${(props: { theme: Theme }) =>
      props.theme?.palette?.primary?.main};
  }

  &.active .MuiTypography-root {
    color: #ffff;
  }
`

export default ProductButtonStyled
