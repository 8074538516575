import {
  Box,
  Icon,
  LargeText,
  StepIconProps,
  useTheme,
} from '@epilot/base-elements'
import React from 'react'

export const CustomIcon = (props: StepIconProps): JSX.Element => {
  const { active, completed, icon } = props
  const theme = useTheme()

  return (
    <Box
      alignItems="center"
      bgcolor={active ? 'primary.main' : 'white'}
      border={2}
      borderColor={completed ? 'primary.main' : 'primary.main'}
      borderRadius={theme.shape.borderRadius}
      color={active ? 'white' : 'primary.main'}
      display="flex"
      height={40}
      justifyContent="center"
      width={40}
    >
      {completed ? (
        <Icon color="primary" name="check" />
      ) : (
        <LargeText>{icon}</LargeText>
      )}
    </Box>
  )
}
