import { BodyText } from '@epilot/components'
import {
  OwnPropsOfRenderer,
  RankedTester,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

import LabelControl from './label-control'

/**
 * Default tester for a label.
 * @type {RankedTester}
 */
export const labelRendererTester: RankedTester = rankWith(2, uiTypeIs('Label'))

/**
 * Default renderer for a label.
 */
export const LabelRenderer = (
  props: OwnPropsOfRenderer,
): React.ReactElement | null => {
  return <LabelControl component={BodyText} {...props} />
}

export default withJsonFormsLayoutProps(LabelRenderer)
