import { BlueprintElements } from 'types/blueprint-config'

export const boxWrapper = (
  elements: BlueprintElements[],
): BlueprintElements[] => {
  const uischema: BlueprintElements[] = [
    {
      type: 'GridItemLayout',
      options: {
        xs: 12,
      },
      elements: [
        {
          type: 'CardLayout',
          elements: [
            {
              type: 'GridContainerLayout',
              options: {
                spacing: 2,
              },
              elements: [...elements],
            },
          ],
        },
      ],
    },
  ]

  return uischema
}
