import { JsonSchema7, UISchemaElement } from '@jsonforms/core'
import { ErrorObject } from 'ajv'
import { produce } from 'immer'

import { ProductCategory } from 'modules/shop/types'

import { WidgetConfig } from '../../types/widget-config'
import {
  LOAD_CONFIGURATION_FAILURE,
  LOAD_CONFIGURATION_SUCCESS,
  RESET_GLOBAL,
  PREVIEW_STEP,
} from '../app/reducer'
import { Action, ActionHandlers, ErrorAction, TypedAction } from '../types'

import { Step, StepId, StepIdPayload, StepState, StepsType } from './types'

/**
 * Action Constants
 */
export const GO_NEXT = 'STEP/GO_NEXT'
export const GO_NEXT_SUCCESS = 'STEP/GO_NEXT_SUCCESS'
export const GO_NEXT_FAILURE = 'STEP/GO_NEXT_FAILURE'

export const GO_PREVIOUS = 'STEP/GO_PREVIOUS'
export const GO_PREVIOUS_SUCCESS = 'STEP/GO_PREVIOUS_SUCCESS'
export const GO_PREVIOUS_FAILURE = 'STEP/GO_PREVIOUS_FAILURE'

export const GO_TO_STEP = 'STEP/GO_TO_STEP'
export const GO_TO_STEP_SUCCESS = 'STEP/GO_TO_STEP_SUCCESS'
export const GO_TO_STEP_FAILURE = 'STEP/GO_TO_STEP_FAILURE'

export const RESET_PREVOIUS_STEPS = 'STEP/RESET_PREVOIUS_STEPS'

export const SET_ACTIVE_STEP = 'STEP/SET_ACTIVE_STEP'
export const REPLACE_STEP_SCHEMA_PROPERTY = 'STEP/REPLACE_STEP_SCHEMA_PROPERTY'
export const REPLACE_STEP_UI_SCHEMA_ELEMENT =
  'STEP/REPLACE_STEP_UI_SCHEMA_ELEMENT'
export const REPLACE_NEXT_STEP_ID = 'STEP/REPLACE_NEXT_STEP_ID'

export const PREFILL_FROM_GLOBAL_DATA = 'STEP/PREFILL_FROM_GLOBAL_DATA'
export const PREFILL_STEP_DATA = 'STEP/PREFILL_STEP_DATA'

export const DO_FULL_SCREEN_CHECK = 'STEP/DO_FULL_SCREEN_CHECK'

export const DO_CHECK_SEND_RESULTS = 'STEP/DO_CHECK_SEND_RESULTS'
export const DO_CHECK_SEND_RESULTS_FAILURE =
  'STEP/DO_CHECK_SEND_RESULTS_FAILURE'

export const DO_CHECK_TOGGLE_CART = 'STEP/DO_CHECK_TOGGLE_CART'
export const DO_CHECK_SHOW_SHOPPING_CART = 'STEP/DO_CHECK_SHOW_SHOPPING_CART'
export const LOAD_GRID_OPERATOR = 'STEP_LOAD_GRID_OPERATOR'
export const LOAD_GRID_OPERATOR_SUCCESS = 'STEP_LOAD_GRID_OPERATOR_SUCCESS'
export const LOAD_GRID_OPERATOR_FAILURE = 'STEP_LOAD_GRID_OPERATOR_FAILURE'
export const DO_PREREQUISITES = 'STEP/DO_PREREQUISITES'
export const DO_PREREQUISITES_SUCCESS = 'STEP/DO_PREREQUISITES_SUCCESS'
export const DO_PREREQUISITES_FAILURE = 'STEP/DO_PREREQUISITES_FAILURE'

export const FIND_NEXT = 'STEP/FIND_NEXT'
export const FIND_NEXT_SUCCESS = 'STEP/FIND_NEXT_SUCCESS'
export const FIND_NEXT_FAILURE = 'STEP/FIND_NEXT_FAILURE'

export const VALIDATE_INPUT = 'STEP/VALIDATE_INPUT'
export const VALIDATE_INPUT_SUCCESS = 'STEP/VALIDATE_INPUT_SUCCESS'
export const VALIDATE_INPUT_FAILURE = 'STEP/VALIDATE_INPUT_FAILURE'

export const SET_INPUT_VALUE = 'STEP/SET_INPUT_VALUE'
export const SET_INPUT_VALUE_SUCCESS = 'STEP/SET_INPUT_VALUE_SUCCESS'
export const SET_INPUT_VALUE_FAILURE = 'STEP/SET_INPUT_VALUE_FAILURE'
export const SET_ACTIVE_STEP_ERRORS = 'STEP/SET_ACTIVE_STEP_ERRORS'

export const ACTIVATE_LIMBO = 'STEP/ACTIVATE_LIMBO'
export const DEACTIVATE_LIMBO = 'STEP/DEACTIVATE_LIMBO'
export const NEXT_STEP_FROM_LIMBO = 'STEP/NEXT_STEP_FROM_LIMBO'
export const CHECK_STEP_LIMBO = 'STEP/CHECK_STEP_LIMBO'
export const SHOW_ERRORS = 'STEP/SHOW_ERRORS'

export const SEARCH_STREET = 'STEP/SEARCH_STREET'

const initialState: StepState = {
  steps: {},
  limboSteps: [],
  activeStepId: undefined,
  previousSteps: [],
  isLoading: true,
  error: false,
  activeStepErrors: [],
  showErrors: false,
  gridOperations: {},
}

// Reducer default & handler
export default function reducer(
  state: StepState = initialState,
  action: TypedAction<any>,
): StepState {
  const handler = action && ACTION_HANDLERS[action.type]

  return handler
    ? produce(state, (draft) => handler(draft, action.payload))
    : state
}

/**
 * Main Reducer
 */
const ACTION_HANDLERS: ActionHandlers<StepState, any> = {
  [GO_NEXT]: (draft: StepState, payload = true) => {
    if (payload) draft.isLoading = true
  },
  [GO_NEXT_SUCCESS]: (draft: StepState) => {
    draft.showErrors = false
  },
  [GO_NEXT_FAILURE]: (draft: StepState) => {
    // TODO: get error massage and show user?
    draft.isLoading = false
  },
  [GO_PREVIOUS]: (draft: StepState) => {
    draft.isLoading = true
  },
  [GO_PREVIOUS_SUCCESS]: (draft: StepState) => {
    draft.isLoading = false
    // TODO add previous list pop() here
  },
  [GO_PREVIOUS_FAILURE]: (draft: StepState) => {
    // TODO: get error massage and show user?
    draft.isLoading = false
  },

  /**
   */
  [SET_ACTIVE_STEP]: (
    draft: StepState,
    {
      stepId,
      popPreviousSteps,
    }: {
      stepId: StepId
      popPreviousSteps?: boolean
    },
  ) => {
    const oldStepId = draft.activeStepId

    draft.activeStepId = stepId

    if (popPreviousSteps) {
      // remove latest previousStep
      draft.previousSteps.pop()
    } else if (
      draft.previousSteps[draft.previousSteps.length - 1] !== oldStepId
    ) {
      // add stepId to previousStep
      oldStepId && draft.previousSteps.push(oldStepId)
    }

    draft.isLoading = false
  },
  [RESET_PREVOIUS_STEPS]: (
    draft: StepState,
    { stepId }: { stepId: StepId },
  ) => {
    const indexStep = draft.previousSteps.indexOf(stepId)

    // Remove all steps after the target step, including the step itself
    draft.previousSteps.splice(indexStep + 1)
  },
  [REPLACE_STEP_SCHEMA_PROPERTY]: (
    draft: StepState,
    {
      stepId,
      targetProperty,
      schema,
    }: { stepId: StepId; targetProperty: string; schema: JsonSchema7 },
  ) => {
    const stepSchema = draft.steps[stepId].schema

    if (stepSchema.properties) stepSchema.properties[targetProperty] = schema
  },
  [REPLACE_STEP_UI_SCHEMA_ELEMENT]: (
    draft: StepState,
    {
      stepId,
      elementMatcher,
      targetProperty,
      schema,
    }: {
      stepId: StepId
      elementMatcher: { key: string; value: string }
      targetProperty: string
      schema: UISchemaElement
    },
  ) => {
    const stepUISchema = draft.steps[stepId].uischema
    const element = stepUISchema.elements.find((element) => {
      // eslint-disable-next-line
      // @ts-ignore - Workaround index object by key string
      return element[elementMatcher.key] === elementMatcher.value
    })

    if (!element)
      throw Error(
        `Reducer Error [STEP/REPLACE_STEP_UI_SCHEMA_PROPERTY]: step '${stepId}' missing needed 'uischema.elements'`,
      )

    if (typeof targetProperty === 'string') {
      // eslint-disable-next-line
      // @ts-ignore - Workaround index object by key string
      element[targetProperty] = schema
    }
  },
  [REPLACE_NEXT_STEP_ID]: (
    draft: StepState,
    {
      stepId,
      nextStepId,
    }: { stepId: StepId; targetProperty: string; nextStepId: string },
  ) => {
    const stepLogic = draft.steps[stepId].logic

    if (stepLogic) stepLogic.nextStepId = nextStepId
  },
  [SET_INPUT_VALUE]: (
    draft: StepState,

    {
      errors,
      stepId,
    }: {
      errors: ErrorObject[]
      stepId: StepId
      resultData: Record<string, unknown>
    },
  ) => {
    // Set errors only when making changes to the active step
    if (stepId !== draft.activeStepId && errors) {
      draft.activeStepErrors = errors
    }
  },
  [SET_INPUT_VALUE_SUCCESS]: (
    draft: StepState,
    {
      stepId,
      resultData,
    }: { stepId: StepId; resultData: Record<string, unknown> },
  ) => {
    draft.steps[stepId].resultData = resultData
  },
  [SET_ACTIVE_STEP_ERRORS]: (
    draft: StepState,
    {
      errors,
    }: {
      errors: ErrorObject[]
    },
  ) => {
    draft.activeStepErrors = errors
  },

  /**
   *  Get steps from configuration
   */
  [LOAD_CONFIGURATION_SUCCESS]: (
    draft: StepState,
    {
      config,
      injectedResultsData,
    }: { config: WidgetConfig } & { injectedResultsData?: Record<string, any> },
  ) => {
    const reducedSteps = reduceSteps(config.steps)
    const activeStepId = config.meta?.initialStepId || config.initialStepId

    // Steps

    // merge injectedResultsData if available
    if (injectedResultsData) {
      reducedSteps[activeStepId].resultData = {
        ...reducedSteps[activeStepId].resultData,
        ...injectedResultsData,
      }
    }

    draft.steps = { ...draft.steps, ...reducedSteps }

    draft.activeStepId = activeStepId

    // Loading
    draft.isLoading = false
  },
  [LOAD_CONFIGURATION_FAILURE]: (draft: StepState) => {
    draft.isLoading = false
  },
  [PREVIEW_STEP]: (draft: StepState, { step }: { step: Step }) => {
    draft.steps = { ...reduceSteps([step]) }
    draft.activeStepId = step.id
    draft.isLoading = false
  },
  [ACTIVATE_LIMBO]: (
    draft: StepState,
    { limboSteps }: { limboSteps: StepId[] },
  ) => {
    draft.limboSteps = limboSteps
  },
  [DEACTIVATE_LIMBO]: (draft: StepState) => {
    draft.limboSteps = []
  },
  [NEXT_STEP_FROM_LIMBO]: (draft: StepState) => {
    if (draft.limboSteps.length < 2) {
      draft.limboSteps = []
    } else {
      draft.limboSteps.splice(0, 1)
    }
  },
  [SHOW_ERRORS]: (draft: StepState) => {
    draft.showErrors = true
  },
  [RESET_GLOBAL]: () => {
    return initialState
  },
  [DO_CHECK_SEND_RESULTS_FAILURE]: (draft: StepState) => {
    draft.isLoading = false
  },
  [FIND_NEXT_FAILURE]: (draft: StepState) => {
    draft.isLoading = false
  },
  [DO_PREREQUISITES_FAILURE]: (draft: StepState) => {
    draft.isLoading = false
  },
  [GO_TO_STEP_FAILURE]: (draft: StepState) => {
    draft.isLoading = false
  },

  [LOAD_GRID_OPERATOR]: (
    draft: StepState,
    { productCategory }: { productCategory: ProductCategory },
  ) => {
    if (draft.gridOperations === undefined) draft.gridOperations = {}

    if (draft.gridOperations[productCategory] === undefined)
      draft.gridOperations[productCategory] = {
        ...draft.gridOperations[productCategory],
        isLoading: true,
        amount: -1,
        showAdditionalFields: false,
      }
  },
  [LOAD_GRID_OPERATOR_SUCCESS]: (
    draft: StepState,
    {
      amountGridOperator,
      productCategory,
    }: { amountGridOperator: number; productCategory: string },
  ) => {
    draft.gridOperations[productCategory] = {
      ...draft.gridOperations[productCategory],
      isLoading: false,
      amount: amountGridOperator,
      showAdditionalFields: amountGridOperator > 1,
    }
  },

  [LOAD_GRID_OPERATOR_FAILURE]: (
    draft: StepState,
    { error, productCategory },
  ) => {
    draft.gridOperations[productCategory] = {
      ...draft.gridOperations[productCategory],
      isLoading: false,
      amount: -1,
      showAdditionalFields: false,
    }
    console.error(error)
  },
}

/**
 * ACTIONS
 */
export const goNext = (payload = true): TypedAction<boolean> => ({
  type: GO_NEXT,
  payload,
})
export const goNextSuccess = (stepId: StepId): TypedAction<StepIdPayload> => ({
  type: GO_NEXT_SUCCESS,
  payload: { stepId },
})
export const goNextFailure = (error: Error): ErrorAction => ({
  type: GO_NEXT_FAILURE,
  payload: { error },
})

export const goPrevious = (): Action => ({ type: GO_PREVIOUS })
export const goPreviousSuccess = (
  stepId: StepId,
): TypedAction<StepIdPayload> => ({
  type: GO_PREVIOUS_SUCCESS,
  payload: { stepId },
})
export const goPreviousFailure = (error: Error): ErrorAction => ({
  type: GO_PREVIOUS_FAILURE,
  payload: { error },
})

export const goToStep = (stepId: StepId): TypedAction<StepIdPayload> => ({
  type: GO_TO_STEP,
  payload: { stepId },
})
export const goToStepFailure = (error: Error): ErrorAction => ({
  type: GO_TO_STEP_FAILURE,
  payload: { error },
})

export const setActiveStep = (
  stepId: StepId,
  popPreviousSteps?: boolean,
): TypedAction<StepIdPayload & { popPreviousSteps?: boolean }> => ({
  type: SET_ACTIVE_STEP,
  payload: {
    stepId,
    popPreviousSteps,
  },
})

export const replaceNextStepId = (
  stepId: StepId,
  nextStepId?: string,
): TypedAction<StepIdPayload & { nextStepId?: string }> => ({
  type: REPLACE_NEXT_STEP_ID,
  payload: {
    stepId,
    nextStepId,
  },
})

export const resetPreviousSteps = (
  stepId: StepId,
): TypedAction<StepIdPayload> => ({
  type: RESET_PREVOIUS_STEPS,
  payload: {
    stepId,
  },
})

export const doFullScreenCheck = (
  stepId: StepId,
): TypedAction<StepIdPayload> => ({
  type: DO_FULL_SCREEN_CHECK,
  payload: { stepId },
})
export const doCheckSendResults = (): Action => ({
  type: DO_CHECK_SEND_RESULTS,
})
export const doCheckSendResultsFailure = (error: Error): ErrorAction => ({
  type: DO_CHECK_SEND_RESULTS_FAILURE,
  payload: { error },
})
export const doCheckToggleCart = (
  stepId: StepId,
): TypedAction<StepIdPayload> => ({
  type: DO_CHECK_TOGGLE_CART,
  payload: { stepId },
})
export const doCheckShowShoppingCart = (
  stepId: StepId,
): TypedAction<StepIdPayload> => ({
  type: DO_CHECK_SHOW_SHOPPING_CART,
  payload: { stepId },
})
export const doStepPrerequisites = (
  stepId: StepId,
): TypedAction<StepIdPayload> => ({
  type: DO_PREREQUISITES,
  payload: { stepId },
})

export const doStepPrerequisitesSuccess = (
  stepId: StepId,
): TypedAction<StepIdPayload> => ({
  type: DO_PREREQUISITES_SUCCESS,
  payload: { stepId },
})
export const doStepPrerequisitesFailure = (error: Error): ErrorAction => ({
  type: DO_PREREQUISITES_FAILURE,
  payload: { error },
})

export const replaceStepSchemaProperty = (
  stepId: StepId,
  targetProperty: string,
  schema: JsonSchema7,
): TypedAction<
  StepIdPayload & {
    targetProperty: string
    schema: JsonSchema7
  }
> => ({
  type: REPLACE_STEP_SCHEMA_PROPERTY,
  payload: { stepId, targetProperty, schema },
})
export const replaceStepUISchemaElement = (
  stepId: StepId,
  elementMatcher: { key: string; value: string },
  targetProperty: string,
  schema: JsonSchema7,
): TypedAction<
  StepIdPayload & {
    elementMatcher: { key: string; value: string }
    targetProperty: string
    schema: JsonSchema7
  }
> => ({
  type: REPLACE_STEP_UI_SCHEMA_ELEMENT,
  payload: { stepId, elementMatcher, targetProperty, schema },
})

export const prefillFromGlobalData = (
  stepId: StepId,
): TypedAction<StepIdPayload> => ({
  type: PREFILL_FROM_GLOBAL_DATA,
  payload: { stepId },
})

export const findNextStep = (): Action => ({ type: FIND_NEXT })
export const findNextStepSuccess = (
  stepId: StepId,
): TypedAction<StepIdPayload> => ({
  type: FIND_NEXT_SUCCESS,
  payload: {
    stepId,
  },
})
export const findNextStepFailure = (error: Error): ErrorAction => ({
  type: FIND_NEXT_FAILURE,
  payload: { error },
})
export const loadGridOperator = (productCategory: ProductCategory) => ({
  type: LOAD_GRID_OPERATOR,
  payload: { productCategory },
})
export const loadGridOperatorSuccess = (
  amountGridOperator: number,
  productCategory: string,
) => ({
  type: LOAD_GRID_OPERATOR_SUCCESS,
  payload: { amountGridOperator, productCategory },
})
export const loadGridOperatorFailure = (
  error: Error,
  productCategory: string,
) => ({
  type: LOAD_GRID_OPERATOR_FAILURE,
  payload: { error, productCategory },
})
export const setInputValue = (
  data: Record<string, unknown>,
  errors: ErrorObject[] | undefined,
  stepId?: StepId,
): TypedAction<{
  data: Record<string, unknown>
  errors: ErrorObject[] | undefined
  stepId?: StepId
}> => ({
  type: SET_INPUT_VALUE,
  payload: { data, errors, stepId },
})
export const setInputValueSuccess = (
  stepId: StepId,
  resultData: Record<string, unknown>,
): TypedAction<StepIdPayload & { resultData: Record<string, unknown> }> => ({
  type: SET_INPUT_VALUE_SUCCESS,
  payload: { stepId, resultData },
})
export const setInputValueFailure = (error: Error): ErrorAction => ({
  type: SET_INPUT_VALUE_FAILURE,
  payload: { error },
})
export const validateInput = (): Action => ({ type: VALIDATE_INPUT })
export const validateInputSuccess = (): Action => ({
  type: VALIDATE_INPUT_SUCCESS,
})
export const validateInputFailure = (error: Error): ErrorAction => ({
  type: VALIDATE_INPUT_FAILURE,
  payload: { error },
})
export const setActiveStepErrors = (
  errors: ErrorObject[] | undefined,
): TypedAction<{ errors: ErrorObject[] | undefined }> => ({
  type: SET_ACTIVE_STEP_ERRORS,
  payload: { errors },
})

export const activateStepLimbo = (
  limboSteps: StepId[],
): TypedAction<{ limboSteps: StepId[] }> => ({
  type: ACTIVATE_LIMBO,
  payload: { limboSteps },
})
export const deactivateStepLimbo = (): Action => ({
  type: DEACTIVATE_LIMBO,
})
export const nextStepFromLimbo = (): Action => ({
  type: NEXT_STEP_FROM_LIMBO,
})
export const checkStepLimbo = (stepId: StepId): TypedAction<StepIdPayload> => ({
  type: CHECK_STEP_LIMBO,
  payload: { stepId },
})

export const prefillStepData = (
  data: Record<string, unknown>,
): TypedAction<{ data: Record<string, unknown> }> => ({
  type: PREFILL_STEP_DATA,
  payload: { data },
})
export const showErrors = (): Action => ({
  type: SHOW_ERRORS,
})

export const searchStreet = (
  city: string,
  postalcode: number,
  organizationId: string,
  apiEndpoint: string,
  streetListScope: string,
): TypedAction<{
  city: string
  postalcode: number
  organizationId: string
  apiEndpoint: string
  streetListScope: string
}> => ({
  type: SEARCH_STREET,
  payload: {
    city,
    postalcode,
    organizationId,
    apiEndpoint,
    streetListScope,
  },
})

/**
 * Helper Data Reducer
 */
const reduceSteps = (stepsArray: Step[]) => {
  const steps: StepsType = {}

  stepsArray.forEach((step) => {
    const newStep = { ...step }

    /**
     * Temporary workaround for getting the configurations changed without a breaking change!
     */
    // eslint-disable-next-line
    // @ts-ignore
    if (step.Schema || step.UISchema) {
      console.warn(
        `'Schema' and 'UISchema' is depreciated, use small caps names`,
      )
      // eslint-disable-next-line
      // @ts-ignore
      newStep.schema = newStep.Schema
      // eslint-disable-next-line
      // @ts-ignore
      delete newStep.Schema
      // eslint-disable-next-line
      // @ts-ignore
      newStep.uischema = newStep.UISchema
      // eslint-disable-next-line
      // @ts-ignore
      delete newStep.UISchema
    }

    steps[step.id] = newStep
  })

  return steps
}
