import { MeterReading } from 'lib/api/types'
import { ProductType } from 'modules/shop/types'

import { isValidPayloadKey, PayloadResults } from '../types'

export const readingsAdd = (
  results: PayloadResults,
  identifier: ProductType,
): MeterReading[] => {
  const keyReadings = `${identifier}_readings` as const
  let readings: MeterReading[] = []

  if (isValidPayloadKey(results, keyReadings)) {
    const items = results[keyReadings]

    if (items && items.length) {
      readings = items.map((item) => {
        return {
          meterId: item.meterNumber,
          marketLocationId: item.marketLocationId,
          meterReading: item.meterReading,
          readingUnit: 'kWh',
        }
      })
    }
  }

  return readings
}
