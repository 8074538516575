import { Product } from 'modules/shop/types'

import { PayloadResults } from '../../modules/step/getResultsFromMapping/types'
import { Builder } from '../../types/widget-config'

import { PriceSheetPackage, ProductPackage } from './type'
export const generatePriceSheetData = (
  allResult: PayloadResults,
  product: Product,
  builder: Builder,
): PriceSheetPackage => {
  const dateOfPrice = builder?.price_sheet_setting?.price_status || ''
  const textDisclaimer = builder?.price_sheet_setting?.disclaimer_text || ''
  const imageUrl = builder?.price_sheet_setting?.image_link || ''
  const date = new Date()
  const formatDate = `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()} um ${date.getHours()}:${date.getMinutes()} Uhr`

  /*
    About consumptionPrice and workPrice: both of them is for dynamic component.
    The difference is that the detail component price information of consumptionPrice
    is different with workPrice. e.g.
     consumptionPrice=  {
                "name": "DEW Arbeitspreis Komfort",
                "id": 7283339,
                "from": null,
                "to": null,
                "currency_id": "EUR",
                "amount_net": 0.0598,
                "amount_vat": 0.011362,
                "amount_gross": 0.071162,
                "vat_pct": 19
              }
     workPrice= {
              "id": 7283339,
              "component_name": "DEW Arbeitspreis Komfort",
              "is_enet_component": false,
              "net_retail_price": 5.98,
              "price_type": 1,
              "payment_frequency": 1
            }
    All the price in consumptionPrice is based on Euro currency. But the price in workPrice
    might be Cent or Euro (we can't decide which unit is used for the price in workPrice).
    Hence in order to keep consistent price with EURO currency, we will use the net price
    in consumptionPrice.
  */

  // all product component with consumption price (based on Euro currency)
  const consumptionPrice = product.price?.consumption_price?.items

  // all product components with work price, but use the price with Euro currency, then convert to Cent
  const workPriceComponents = product.price.price_components

    .filter(
      (priceComponent: Record<string, any>) => priceComponent.price_type === 1,
    )
    .map((priceComponent: Record<string, any>) => {
      const componentId = priceComponent.id
      const netWorkPriceBaseEuroUnit =
        consumptionPrice &&
        consumptionPrice.find((item) => item.id === componentId)
      const netPriceForWorkComponent =
        netWorkPriceBaseEuroUnit?.amount_net || priceComponent.net_retail_price

      return {
        componentName: priceComponent.component_name || '',
        netPrice: priceStringFromPricingItem(netPriceForWorkComponent * 100),
      }
    })
  const basicPriceComponents = product.price.price_components
    .filter(
      (priceComponent: Record<string, any>) => priceComponent.price_type === 0,
    )
    .map((priceComponent: Record<string, any>) => {
      const netPriceForBasicComponent =
        priceComponent?.payment_frequency == 1
          ? priceComponent.net_retail_price * 12
          : priceComponent.net_retail_price || 0

      return {
        componentName: priceComponent.component_name || '',
        netPrice: priceStringFromPricingItem(netPriceForBasicComponent, 2),
      }
    })

  return {
    hasPriceSheet: hasPriceSheet(product, builder),
    packageName: product.name,
    postCode: `${allResult?.postalcode_city?.zipCode || ''} ${
      allResult?.postalcode_city?.city || ''
    }`,
    streetInfo: `${allResult?.postalcode_city?.streetName || ''} ${
      allResult?.postalcode_city?.houseNumber || ''
    }`,
    currentDateTime: formatDate,
    dateOfPrice: dateOfPrice,
    consumption: `${allResult?.power_consumption?.amount} kWh` || '',
    imageUrl: imageUrl,
    textDisclaimer: textDisclaimer || '',
    productComponentsPrice: {
      workProductComponentPrice: {
        productComponentType: 'static',
        calculatedTotalPrice: {
          netPriceForTotal: priceStringFromPricingItem(
            calculateFinalBasicPrice(product.price.price_components)
              .totalNetPrice || 0.0,
            2,
          ),
          tax: priceStringFromPricingItem(
            calculateFinalBasicPrice(product.price.price_components)
              .amountVat || 0.0,
            2,
          ),
          grossPriceForTotal: priceStringFromPricingItem(
            calculateFinalBasicPrice(product.price.price_components)
              .totalGrossPrice || 0.0,
            2,
          ),
        },
        productPackagesPrice: enetBasicComponentMapping(basicPriceComponents),
      },
      basicProductComponentPrice: {
        productComponentType: 'dynamic',
        calculatedTotalPrice: {
          netPriceForTotal: priceStringFromPricingItem(
            (product.price.calculated_consumption_price?.amount_net || 0) * 100,
            2,
          ),
          tax: priceStringFromPricingItem(
            (product.price.calculated_consumption_price?.amount_vat || 0.0) *
              100,
            2,
          ),
          grossPriceForTotal: priceStringFromPricingItem(
            (product.price.calculated_consumption_price?.amount_gross || 0.0) *
              100,
            2,
          ),
        },
        productPackagesPrice: enetWorkComponentMapping(workPriceComponents),
      },
    },
  }
}
export const calculateFinalBasicPrice = (
  priceComponent: Record<string, any>,
) => {
  let totalNetWorkPrice = 0
  let totalGrossWorkPrice = 0

  priceComponent
    .filter(
      (priceComponent: Record<string, any>) => priceComponent.price_type === 0,
    )
    .map((priceComponent: Record<string, any>) => {
      const netPriceForBasicComponent =
        priceComponent?.payment_frequency == 1
          ? priceComponent.net_retail_price * 12
          : priceComponent.net_retail_price || 0

      totalNetWorkPrice += netPriceForBasicComponent
      totalGrossWorkPrice =
        totalNetWorkPrice + totalNetWorkPrice * (priceComponent.tax / 100)
    })

  return {
    totalNetPrice: totalNetWorkPrice,
    totalGrossPrice: totalGrossWorkPrice,
    amountVat: totalGrossWorkPrice - totalNetWorkPrice,
  }
}
export const enetWorkComponentMapping = (
  priceComponent: ProductPackage[],
): ProductPackage[] => {
  const mappedPriceComponent: ProductPackage[] = []

  if (priceComponent) {
    priceComponent.forEach((onePriceComponent) => {
      switch (onePriceComponent.componentName) {
        case 'Strom- oder Erdgassteuer Power':
          onePriceComponent.componentName = 'Stromsteuer'
          mappedPriceComponent.push(onePriceComponent)
          break
        case 'Wirkarbeit Power':
          onePriceComponent.componentName = 'Netznutzungsentgelt'

          mappedPriceComponent.push(onePriceComponent)
          break
        case 'Konzessionsabgabe Power':
          onePriceComponent.componentName = 'Konzessionsabgabe'
          mappedPriceComponent.push(onePriceComponent)
          break
        case 'KWK-Umlage':
          onePriceComponent.componentName =
            'Umlage nach Kraft-Wärme-Kopplungsgesetz (KWK-Umlage)'
          mappedPriceComponent.push(onePriceComponent)
          break
        case '§19 StromNEV Umlage':
          onePriceComponent.componentName =
            'Umlage nach § 19 Stromnetzentgeltverordnung (§ 19 StromNEW-Umlage)'
          mappedPriceComponent.push(onePriceComponent)
          break
        case 'Offshore-Haftungsumlage':
          onePriceComponent.componentName =
            'Umlage nach § 17 f Energiewirtschaftsgesetz (Offshore-Netzumlage)'
          mappedPriceComponent.push(onePriceComponent)
          break
        case 'Umlage für abschaltbare Lasten':
          onePriceComponent.componentName =
            'Umlage nach § 18 der Verordnung über Vereinbarungen zu abschaltbaren Lasten (AbLaV-Umlage)'
          mappedPriceComponent.push(onePriceComponent)
          break
        case 'EEG-Umlage':
          onePriceComponent.componentName =
            'Umlage nach Erneuerbare-Energien-Gesetz (EEG-Umlage)'
          mappedPriceComponent.push(onePriceComponent)
          break
        default:
          onePriceComponent.componentName = 'Basisarbeitspreis'
          mappedPriceComponent.push(onePriceComponent)
      }
    })
  }

  return mappedPriceComponent
}
export const enetBasicComponentMapping = (
  priceComponent: ProductPackage[],
): ProductPackage[] => {
  const mappedPriceComponent: ProductPackage[] = []

  if (priceComponent) {
    priceComponent.forEach((onePriceComponent) => {
      switch (onePriceComponent.componentName) {
        case 'Grundpreis Power':
          onePriceComponent.componentName = 'Grundpreis Netznutzung'
          mappedPriceComponent.push(onePriceComponent)
          break
        case 'Messstellenbetrieb und Messung':
          onePriceComponent.componentName = 'Messstellenbetrieb inkl. Messung'
          mappedPriceComponent.push(onePriceComponent)
          break
        default:
          onePriceComponent.componentName = 'Basisgrundpreis'
          mappedPriceComponent.push(onePriceComponent)
      }
    })
  }

  return mappedPriceComponent
}

export const productEnetInfo = (product: Product | undefined): boolean => {
  let enetEnabledProduct = false

  if (product) {
    enetEnabledProduct = product?.price?.price_components.some(
      (item: Record<string, any>) => item.is_enet_component,
    )
  }

  return enetEnabledProduct
}
export const hasPriceSheet = (product: Product, builder?: Builder) => {
  const isEnablePriceSheet =
    builder?.price_sheet_setting?.enable_price_sheet || false
  const isEnetProduct = productEnetInfo(product)

  return (isEnablePriceSheet && isEnetProduct) || false
}

export function priceStringFromPricingItem(item: number, maxDecimals?: number) {
  return (
    item.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: maxDecimals ? maxDecimals : 3,
    }) || ''
  )
}
