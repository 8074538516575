import { deepEqual } from 'fast-equals'
import { useEffect, useState } from 'react'

import { usePrevious } from './usePrevious'

export const useIsDirty = (
  currentValues: Record<string, unknown>[],
): boolean => {
  const [initialValues] = useState(currentValues)

  const isDirty = !deepEqual(initialValues, currentValues)

  const isDirtyPrev = usePrevious(isDirty)

  useEffect(() => {
    if (!deepEqual(isDirtyPrev, isDirty)) {
      window.parent.postMessage(
        {
          type: 'EPILOT/FORM_EVENT',
          payload: { isDirty },
        },
        '*',
      )
    }
  }, [isDirtyPrev, isDirty])

  return isDirty
}
