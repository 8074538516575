export const defaultPersonalInformationSchemaProperties = {
  title: { name: 'title' },
  salutation: { name: 'salutation' },
  first_name: { name: 'first_name' },
  last_name: { name: 'last_name' },
  birth_date: { name: 'birth_date' },
  email: { name: 'email' },
  telephone: { name: 'telephone' },
  agreement: { name: 'agreement' },
}

export const personalInformationSchemaProperties = {
  [defaultPersonalInformationSchemaProperties.salutation.name]: {
    type: 'string',
    enum: ['Herr', 'Frau', 'Divers'],
  },
  [defaultPersonalInformationSchemaProperties.title.name]: {
    type: 'string',
    enum: ['Dr.', 'Prof.', 'Prof. Dr.'],
  },
  [defaultPersonalInformationSchemaProperties.first_name.name]: {
    type: 'string',
  },
  [defaultPersonalInformationSchemaProperties.last_name.name]: {
    type: 'string',
  },
  [defaultPersonalInformationSchemaProperties.birth_date.name]: {
    type: 'string',
    dateDE: true,
    birthdateDE: true,
    examples: ['TT.MM.JJJJ'],
  },
  [defaultPersonalInformationSchemaProperties.email.name]: {
    type: 'string',
    email: true,
  },
  [defaultPersonalInformationSchemaProperties.telephone.name]: {
    type: 'string',
    format: 'telephone_de',
    errorMessage:
      'Die angegebene Telefonnummer ist nicht gültig. Bitte Format überprüfen.',
  },
  [defaultPersonalInformationSchemaProperties.agreement.name]: {
    type: 'boolean',
  },
}

export const personalInformationSchemaRequired = {
  required: [
    defaultPersonalInformationSchemaProperties.first_name.name,
    defaultPersonalInformationSchemaProperties.last_name.name,
    defaultPersonalInformationSchemaProperties.email.name,
  ],
}
