export const defaultConsumptionCalculatorSchemaProperties = {
  selected_tab: { name: 'selected_tab' },
  postalcode_city: { name: 'postalcode_city' },
  power_consumption: { name: 'power_consumption' },
  gas_consumption: { name: 'gas_consumption' },
  grid_operator_button: { name: 'grid_operator_button' },
}

export const consumptionCalculatorSchemaProperties = {
  [defaultConsumptionCalculatorSchemaProperties.selected_tab.name]: {
    type: 'string',
    enum: ['Strom', 'Gas', 'Strom & Gas'],
    default: 'Strom',
  },
  [defaultConsumptionCalculatorSchemaProperties.grid_operator_button.name]: {
    type: 'object',
  },
  [defaultConsumptionCalculatorSchemaProperties.power_consumption.name]: {
    type: 'object',
    errorMessage: 'Ist erforderlich.',
  },
  [defaultConsumptionCalculatorSchemaProperties.gas_consumption.name]: {
    type: 'object',
    errorMessage: 'Ist erforderlich.',
  },
  [defaultConsumptionCalculatorSchemaProperties.postalcode_city.name]: {
    type: 'object',
    errorMessage: 'Ist erforderlich.',
    required: ['zipCode', 'city'],
  },
}
