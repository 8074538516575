import { deepEqual } from 'fast-equals'
import { createSelector } from 'reselect'

import { ObjectKeyString } from 'lib/types'

import { GlobalAppState } from '../types'

import { TypedStep } from './getResultsFromMapping/types'
import {
  GridOperation,
  NextSteps,
  Step,
  StepData,
  StepId,
  StepLogic,
  StepLogicPrerequisite,
  StepLogicRule,
  StepState,
  StepsType,
} from './types'

const getState = (state: GlobalAppState): StepState => state.step

// Reducer states
const isLoading = (state: GlobalAppState) => getState(state).isLoading
const error = (state: GlobalAppState) => getState(state).error
const steps = (state: GlobalAppState) => getState(state).steps
const limboSteps = (state: GlobalAppState) => getState(state).limboSteps
const activeStepId = (state: GlobalAppState) => getState(state).activeStepId
const previousSteps = (state: GlobalAppState) => getState(state).previousSteps
const activeStepErrors = (state: GlobalAppState) =>
  getState(state).activeStepErrors
const showErrors = (state: GlobalAppState) => getState(state).showErrors

// Getter
export const getIsLoading = (state: GlobalAppState): boolean => isLoading(state)
export const getError = (state: GlobalAppState): boolean => error(state)
export const getSteps = (state: GlobalAppState): StepsType => steps(state)

export const getStepList = (state: GlobalAppState): (Step | TypedStep)[] => {
  const steps = getSteps(state)

  return Object.keys(steps).map((stepKey) => steps[stepKey])
}
export const getStepById = (
  state: GlobalAppState,
  id: StepId,
): Step | TypedStep => getSteps(state)[id]

export const getStepDataById = (
  state: GlobalAppState,
  id: StepId,
): StepData => {
  return getSteps(state)[id].resultData
}
export const getLimboSteps = (state: GlobalAppState): StepId[] =>
  limboSteps(state)
export const getActiveStepErrors = (state: GlobalAppState) =>
  activeStepErrors(state)
export const getShowErrors = (state: GlobalAppState) => showErrors(state)

export const getActiveStepId = (state: GlobalAppState): StepId => {
  const stepId = activeStepId(state)

  if (!stepId) throw Error('No active step. Maybe initialStep was not set?')

  return stepId
}

export const getCurrentStepId = (state: GlobalAppState): StepId | undefined => {
  return activeStepId(state)
}

export const getActiveStep = createSelector(
  [getActiveStepId, getSteps],
  (activeStepId, steps) => {
    const step = steps[activeStepId]

    if (!step) throw Error('activeStep is undefined')

    return step
  },
)
export const getActiveStepData = (state: GlobalAppState): StepData =>
  getActiveStep(state).resultData
export const getActiveStepLogic = (state: GlobalAppState): StepLogic =>
  getActiveStep(state).logic
export const getActiveStepLogicNextStepId = (
  state: GlobalAppState,
): StepId | undefined => getActiveStepLogic(state).nextStepId
export const isActiveStepValid = (state: GlobalAppState): boolean => {
  const activeStepErrors = getActiveStepErrors(state)

  return activeStepErrors.length === 0
}
export const getStepLogicShoppingCartById = (
  state: GlobalAppState,
  stepId: StepId,
): boolean | undefined => {
  const isShowShoppingCart = getStepById(state, stepId)?.logic?.showCart

  return isShowShoppingCart
}
export const getActiveStepNextButtonLabel = (state: GlobalAppState): string =>
  getActiveStep(state).nextButtonLabel
export const getActiveStepPreviousButtonLabel = (
  state: GlobalAppState,
): string => getActiveStep(state).previousButtonLabel

export const getStepLogicPrerequisitesById = (
  state: GlobalAppState,
  stepId: StepId,
): StepLogicPrerequisite[] | undefined =>
  getStepById(state, stepId)?.logic?.prerequisites
export const getStepLogicToggleCartById = (
  state: GlobalAppState,
  stepId: StepId,
): boolean | undefined => getStepById(state, stepId)?.logic?.toggleCart

export const getStepSchemaPropertiesAsArray = (
  state: GlobalAppState,
  stepId: StepId,
): string[] => {
  const step = getStepById(state, stepId)

  if (!step.schema.properties) {
    if (step.schema.oneOf && step.schema.oneOf.length > 0) {
      return step.schema.oneOf
        .map((item) => Object.keys(item.properties || {}))
        .flat()
      // eslint-disable-next-line no-dupe-else-if
    } else if (step.schema.oneOf && step.schema.oneOf.length > 0) {
      return step.schema.oneOf
        .map((item) => Object.keys(item.properties || {}))
        .flat()
    }

    throw Error('getStepSchemaPropertiesAsArray step.schema.properties empty')
  }

  return Object.keys(step.schema.properties)
}

// TODO: move to plain TS function
export const getActiveStepLogicNextStepsByRules = (
  state: GlobalAppState,
): NextSteps => {
  const { logic: stepLogic } = getActiveStep(state)
  let nextSteps: NextSteps | undefined = undefined

  if (!stepLogic.rules) throw Error('Step does not have "rules"')

  const CompareCondition = (rule: StepLogicRule): boolean => {
    if (rule.effect === 'NEXT_STEP') {
      const condition = rule.condition
      const dataScoped = getAllResultData(state)[condition.scope]
      const schema = condition.schema
      const action = condition.action
      const mode = condition.mode

      let compareSchema = schema
      let compareData = dataScoped

      if (mode === 'true-only') {
        // {"power": false", "gas":true } === { "gas":true }
        compareSchema = {} as ObjectKeyString<unknown>
        compareData = {} as ObjectKeyString<unknown>

        Object.keys(schema).forEach((key) => {
          if (schema[key] === true) compareSchema[key] = true
        })

        Object.keys(dataScoped).forEach((key) => {
          if (dataScoped[key] === true) compareData[key] = true
        })
      }
      // Check for if rule matches
      let compare = deepEqual(compareSchema, compareData)

      if (compare && mode !== 'not-equal') {
        compare = true
      } else if (!compare && mode === 'not-equal') {
        compare = true
      } else {
        compare = false
      }
      if (action.nextSteps && action.nextCondition) {
        throw Error(
          'Next step logic and next condition can not be used in same level',
        )
      }
      if (action.nextSteps && compare) {
        nextSteps = action.nextSteps

        return true
      }

      if (action.nextCondition && compare) {
        return CompareCondition(action.nextCondition as StepLogicRule)
      }

      // Check if scope is an object
      const splitScope = condition.scope.split('.')

      if (splitScope.length > 0 && getAllResultData(state)[splitScope[0]]) {
        const scopeToObject = {
          [splitScope[0]]: {
            [splitScope[1]]: getAllResultData(state)[splitScope[0]][
              splitScope[1]
            ],
          },
        }

        if (JSON.stringify(scopeToObject) === JSON.stringify(condition.schema))
          nextSteps = action.nextSteps
      }

      return false
    }

    return false
  }

  stepLogic.rules.forEach(CompareCondition)

  if (nextSteps) return nextSteps

  throw Error('Step logic rules could not find nextStepId')
}

export const getNextStep = (
  state: GlobalAppState,
  nextStepId: StepId,
): Step | TypedStep => {
  const allSteps = getSteps(state)
  const nextStep = allSteps[nextStepId]

  return nextStep
}

// Complex selectors
// export const getActiveStepValidation = (
//   state: GlobalAppState,
// ): undefined | Step => getActiveStep(state) && getActiveStep(state).validation

export const getPreviousSteps = (state: GlobalAppState): string[] =>
  previousSteps(state)
export const getPreviousStepId = (state: GlobalAppState): StepId => {
  const steps = getPreviousSteps(state)

  if (steps.length === 0) throw Error('No previous step')

  return steps[steps.length - 1]
}

export const getAllResultData = createSelector([getSteps], (steps) => {
  const results = {} as { [key: string]: any }

  Object.keys(steps).forEach((stepId) => {
    Object.keys(steps[stepId].resultData).map((keyData) => {
      const step = steps[stepId]
      const data = step.resultData

      if (keyData === 'postalcode_city') {
        return (results[keyData] = data[keyData])
      }

      if (
        ['delivery_date_asap_power', 'delivery_date_asap_gas'].includes(keyData)
      ) {
        return (results[keyData] = !results[keyData]
          ? data[keyData]
          : results[keyData])
      }

      return (results[keyData] =
        results[keyData] === undefined ? data[keyData] : results[keyData])
    })
  })

  return results
})

// Progress
export const getProgress = (state: GlobalAppState): number => {
  const steps = getSteps(state)

  if (!steps) throw Error('steps are undefined')

  const totalStepLength = Object.keys(steps).length
  const previousStepsLength = getPreviousSteps(state).length

  const stepSize = 100 / totalStepLength
  const progress = stepSize * (previousStepsLength + 1)

  return progress
}
export const getIsFirstStep = (state: GlobalAppState): boolean => {
  const id = getActiveStepId(state)
  const configurationMeta = state.app.meta

  return id === configurationMeta?.initialStepId
}
export const getIsLastStep = (state: GlobalAppState): boolean => {
  const activeStep = getActiveStep(state)

  return !activeStep.logic.nextStepId && !activeStep.logic.rules
}

export const getHideFooter = (state: GlobalAppState): boolean => {
  const activeStep = getActiveStep(state)

  return activeStep.hideFooter || false
}

export const getHideHeader = (state: GlobalAppState): boolean => {
  const activeStep = getActiveStep(state)

  return activeStep.hideHeader || false
}

export const getIsEmbedded = (state: GlobalAppState): boolean => {
  const activeStep = getActiveStep(state)

  return activeStep.isEmbedded || false
}
export const getGridOperations = (
  state: GlobalAppState,
): Record<string, GridOperation> | undefined => {
  const states = getState(state)

  return states.gridOperations
}
