import { S3File } from '../../../../lib/api/types'
import { PayloadResults } from '../types'
export const setSignatureNaming = (
  signature: S3File | undefined,
  results: PayloadResults,
) => {
  const firstName = results.first_name
  const lastName = results.last_name
  const signatureName = `${firstName}_${lastName}.png`
  let newSignature = signature

  if (signature) {
    newSignature = { ...signature, original_name: signatureName }
  }

  return newSignature
}
