const parseTemplate = (template: string, params: { [key: string]: string }) => {
  let result = template || ''

  for (const key of Object.keys(params || {})) {
    result = result.split(`{{${key}}}`).join(params[key])
  }

  return result
}

export default parseTemplate
