import { Button, ButtonProps } from '@epilot/base-elements'
import { Spinner } from '@epilot/elements'
import {
  ControlProps,
  RankedTester,
  rankWith,
  uiTypeIs,
  and,
  optionIs,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { exitFullscreen } from 'modules/app/reducer'
import { getInitialConfig } from 'modules/app/selectors'
import { loadProducts } from 'modules/shop/reducer'
import {
  goNext,
  goPrevious,
  loadGridOperator,
  showErrors,
} from 'modules/step/reducer'
import {
  getActiveStepErrors,
  getAllResultData,
  getGridOperations,
} from 'modules/step/selectors'

import { EnetCallInfo } from './type'

const ButtonControl = ({ data, handleChange, uischema }: ControlProps) => {
  const dispatch = useDispatch()
  const gridOperations = useSelector(getGridOperations)
  const configuration = useSelector(getInitialConfig)
  const enetFlagInfo = configuration?.builder?.callEnetInfo || []

  // If street and house number are already visible in the first step, enet call should not be done
  // This flag will be used to check that
  const streetAndHouseNumberFlag =
    configuration?.builder?.steps?.consumption_calculator
      ?.toggle_street_and_house_number

  const activeStepErrors = useSelector(getActiveStepErrors)

  const allResultData = useSelector(getAllResultData)
  const { label, options } = uischema
  const { style, variant, fullWidth, color } = (uischema?.options ||
    {}) as ButtonProps

  let selectedTab = allResultData?.selected_tab

  selectedTab =
    selectedTab && selectedTab.replace('Strom', 'Power').toLowerCase()
  if (selectedTab === 'power & gas') selectedTab = 'power'
  // store the newest grid amount info to result data when grid amount info state is changed, we will show or hide street info based on the info
  const initialGridInfo = {
    [selectedTab]: {
      isLoading: false,
      amount: -1,
      showAdditionalFields: false,
    },
  }
  const hasEnetFlag =
    enetFlagInfo.length > 0 &&
    enetFlagInfo.filter(
      (item: EnetCallInfo) =>
        item.category.toLowerCase() === 'power' && item.enetCall === true,
    ).length > 0
  const zipCode =
    typeof allResultData?.postalcode_city === 'object'
      ? (allResultData?.postalcode_city as any)?.zipCode
      : undefined
  const operatorInfo = zipCode ? gridOperations : initialGridInfo

  useEffect(() => {
    if (gridOperations && gridOperations[selectedTab] === undefined) {
      handleChange('', {
        ...data,
        ...initialGridInfo,
      })
    } else {
      handleChange('', {
        ...data,
        ...operatorInfo,
      })
    }
  }, [gridOperations])

  const hanleClick = () => {
    const href = options?.href

    switch (options?.action) {
      case 'loadAmountGridOperator':
        {
          if (activeStepErrors?.length > 0) {
            dispatch(showErrors())

            return
          }
          if (selectedTab === 'gas') {
            dispatch(loadProducts())
            dispatch(goNext())
          } else if (selectedTab === 'power') {
            if (hasEnetFlag && !streetAndHouseNumberFlag) {
              dispatch(loadGridOperator(selectedTab.toLowerCase()))
            } else {
              dispatch(loadProducts())
              dispatch(goNext())
            }
          }
        }
        break
      case 'next':
        if (activeStepErrors?.length > 0) {
          dispatch(showErrors())

          return
        }
        dispatch(goNext())
        break
      case 'previous':
        dispatch(goPrevious())
        break
      case 'close':
        dispatch(exitFullscreen())
        break
      case 'link':
        if (href) {
          const newTabLink = window.open(href, '_blank')

          newTabLink?.focus()
        }
        break
      default:
        new Error('ButtonControl is expecting an action')
    }
  }

  return (
    <Button
      color={color || 'primary'}
      data-component="button"
      data-key="next"
      fullWidth={fullWidth}
      onClick={hanleClick}
      size="large"
      style={style}
      variant={variant}
    >
      {gridOperations && gridOperations[selectedTab]?.isLoading === true ? (
        <Spinner />
      ) : (
        label
      )}
    </Button>
  )
}

export default withJsonFormsControlProps(ButtonControl)

export const buttonRendererTester: RankedTester = rankWith(
  10,
  and(uiTypeIs('Button'), optionIs('control', true)),
)
