import googleTagManager from '@analytics/google-tag-manager'
import Analytics, { AnalyticsInstance } from 'analytics'

import config from 'config'

import customGoogleAnalytics from './plugins/customGoogleAnalytics'
import { GA_PLUGIN_NAME } from './plugins/utils'
import { AnalyticsInit } from './types'

/**
 * After initialized, offers some methods to track data to Google Analytics
 * @method getPageData collects host information + widgetId + given stepId and returns page path
 * @method page mimics analytics.page() function call, but calls own instance and if given clients instance
 * @method init initializes clients analytics if set in journey builder. Instance is then being used for other track methods
 * @returns methods to interact with analytics
 *
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const trackerInit = function () {
  // GA client id which is mimicking the _ga cookie
  let clientId = ''
  // clients analytics tracker if initialized
  let clientsAnalytics: undefined | AnalyticsInstance
  // can either be a GTM containerId or the GA TrackingId
  let gaTrackingId = ''
  // id of widget used to generate virtual page path
  let widgetId = ''
  // initialStepId to track pageview once connected and initialized
  let initialStepId = ''
  // Maximum time in milliseconds to poll the parent frame for ready signal
  let maxTime = 5000
  // Intervall used for polling
  const pollInterval = 200
  // window.intervall
  let poll = 0
  // indicates if parent is ready for communication
  let parentReady = false
  // Enable or disable tracker from loading until `analytics.plugins.enable` / `analytics.plugins.disable` called
  let initEnabled = false

  /**
   * Initializer for clientsAnalytics tracker
   */
  const init = () => {
    if (gaTrackingId && clientId && !clientsAnalytics) {
      clientsAnalytics = Analytics({
        app: 'e-pilot-widget',
        plugins: [
          gaTrackingId.startsWith('GTM')
            ? Object.assign(
                {},
                googleTagManager({
                  containerId: gaTrackingId,
                }),
                {
                  name: GA_PLUGIN_NAME,
                  enabled: initEnabled,
                },
              )
            : customGoogleAnalytics(
                {
                  trackingId: gaTrackingId,
                  storage: 'none',
                  clientId: clientId,
                },
                GA_PLUGIN_NAME,
                initEnabled,
              ),
        ],
        debug: config.NODE_ENV.NODE_ENV === 'development',
      })
      // initial pageview once initialized
      clientsAnalytics.page({ path: getPageData(initialStepId) })
    } else {
      console.error('Called init while having missing values')
    }
  }

  const getPageData = (stepId: string): string => {
    // only slash if widgetId given
    return `epilot-widget/${widgetId ? widgetId + '/' : ''}${stepId}`
  }

  /**
   * The child frame polls the parent page with the childReady message to signal it’s ready for the bilateral communication to start.
   */
  const pollCallback = () => {
    // If maximum time is reached, stop polling
    maxTime -= pollInterval
    if (maxTime <= 0) window.clearInterval(poll)
    // Send message to parent that iframe is ready to retrieve Client ID
    window.top.postMessage('childReady', '*')
  }

  /**
   * waits for two things:
   * The parent to signal parentReady, so that the <iframe> can start forwarding its dataLayer messages to the parent.
   * The parent to return a Client ID string, so that the <iframe> can use this in Google Analytics tags.
   */
  const postCallback = (event: any) => {
    if (event.data.event !== 'clientId' && event.data !== 'parentReady') return

    if (event.data.event === 'clientId') {
      clientId = event.data.clientId
      init()
    }

    if (event.data === 'parentReady' && !parentReady) {
      window.clearInterval(poll)
      parentReady = true
    }
  }

  return {
    /**
     * Aims to connect iframe with parent website.
     * Sets intervall to poll for Analytics container
     * Listens for message from parent website to know that parent is ready and to receive clientId
     */
    connect: function ({
      trackingId: ga,
      widgetId: id,
      initialStepId: stepId,
      toEnable = false,
    }: AnalyticsInit) {
      // If not in iframe, do nothing
      try {
        if (window.top === window.self) return
      } catch (e) {
        console.error(e)
      }

      gaTrackingId = ga || ''
      widgetId = id
      initialStepId = stepId
      initEnabled = toEnable

      // connect only if tracking id exists
      if (gaTrackingId) {
        // Start polling the parent page with "childReady" message
        poll = window.setInterval(pollCallback, pollInterval)

        // Start listening for messages from the parent page
        window.addEventListener('message', postCallback)
      }
    },

    /**
     * Analytics functions
     */
    page: function (stepId: string) {
      if (clientsAnalytics) {
        const path = getPageData(stepId)

        clientsAnalytics.page({ path })
      }
    },
    /**
     * Enable/Disable a plugin
     * @param plugin the plugin identifier / name
     * @param enable used to enable or disable the plugin
     * @param currentStepId current step id (optional, only needed when enabling plugin)
     */
    enablePlugin: (
      plugin: string,
      toEnable = false,
      currentStepId?: string,
    ) => {
      if (clientsAnalytics) {
        if (toEnable) {
          const pluginState = clientsAnalytics.getState('plugins')
          const alreadyEnabled = pluginState[plugin].enabled || false // to be used as a protection to avoid multiple calls to page tracking

          clientsAnalytics.plugins.enable(plugin, () => {
            if (clientsAnalytics && !alreadyEnabled) {
              const stepToTrack = currentStepId || initialStepId

              clientsAnalytics.page({ path: getPageData(stepToTrack) })
            }
          })
        } else {
          clientsAnalytics.plugins.disable(plugin, () => {
            /** Do something after disabling if needed */
          })
        }
      }
    },
  }
}

export const gaTracker = trackerInit()
