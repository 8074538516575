import { ArrowDownIcon, ArrowUpIcon, Button, MuiGrid } from '@epilot/components'
import React, { useState, useEffect } from 'react'

import { InstallationsPerformance } from '../installation-module-performance'
import { InstallationPerformanceDetails } from '../installation-module-sum-detail'
import { generateInfoForAllConsumption } from '../installationUnitLabelInfo'
import {
  Installation,
  CommissioningInstallation,
  InstallationsPerformanceModuleType,
  SolarModuleInstallation,
  StorageInstallation,
  InverterInstallation,
  GeneratorInstallation,
} from '../types'

import { TotalAnnualConsumptionStyled } from './installations-performance-module-styled'

const InstallationsPerformanceModule = ({
  commissioningInstallationsLabel,
  commissioningInstallationsOptions,
  handleConsumptionChange,
  selectedInstallations = undefined,
  type = 'commissioning',
  showErrors,
  buttonText,
}: InstallationsPerformanceModuleType): JSX.Element => {
  const installationInitialValues = {
    name: undefined,
    status: undefined,
    ratedCapacity: undefined,
    annualConsumption: undefined,
    manufacturer: undefined,
    type: undefined,
    performance: undefined,
    quantity: undefined,
    powerConsumption: undefined,
    powerOutput: undefined,
    storageCapacity: undefined,
    apparentPerformance: undefined,
    activePerformance: undefined,
    constructionType: undefined,
    installationType: type,
    inverterPerformance: undefined,
  }
  const [installations, setInstallations] = useState<Installation[]>(
    selectedInstallations && selectedInstallations?.length > 0
      ? selectedInstallations
      : [installationInitialValues],
  )
  const [totalAnnualConsumption, setTotalAnnualConsumption] = useState(0)
  const [expanded, setExpanded] = useState(false)

  const infoForAllConsumption = generateInfoForAllConsumption(type)

  useEffect(() => {
    let total = 0

    switch (type) {
      case 'solar':
        total =
          installations?.length > 0
            ? (installations as SolarModuleInstallation[])
                .map(
                  ({ quantity = 0, solarPerformance = 0 }) =>
                    (solarPerformance / 1000) * quantity,
                )
                .reduce(
                  (total, consumption) =>
                    parseFloat(total.toString()) +
                    parseFloat(consumption.toString()),
                )
            : 0
        break
      case 'storage':
        total =
          installations?.length > 0
            ? (installations as StorageInstallation[])
                .map(
                  ({ quantity = 0, storageCapacity = 0 }) =>
                    storageCapacity * quantity,
                )
                .reduce(
                  (total, consumption) =>
                    parseFloat(total.toString()) +
                    parseFloat(consumption.toString()),
                )
            : 0
        break
      case 'inverter':
        total =
          installations?.length > 0
            ? (installations as InverterInstallation[])
                .map(
                  ({ quantity = 0, inverterPerformance = 0 }) =>
                    inverterPerformance * quantity,
                )
                .reduce(
                  (total, consumption) =>
                    parseFloat(total.toString()) +
                    parseFloat(consumption.toString()),
                )
            : 0
        break
      case 'generator':
        total =
          installations?.length > 0
            ? (installations as GeneratorInstallation[])
                .map(
                  ({ quantity = 0, apparentPerformance = 0 }) =>
                    apparentPerformance * quantity,
                )
                .reduce(
                  (total, consumption) =>
                    parseFloat(total.toString()) +
                    parseFloat(consumption.toString()),
                )
            : 0
        break
      case 'commissioning':
      default:
        total =
          installations?.length > 0
            ? (installations as CommissioningInstallation[])
                .map(({ annualConsumption = 0 }) => annualConsumption)
                .reduce(
                  (total, consumption) =>
                    parseFloat(total.toString()) +
                    parseFloat(consumption.toString()),
                )
            : 0
    }
    if (typeof total === 'string') {
      total = parseFloat(total)
    }
    setTotalAnnualConsumption(parseFloat(total.toFixed(2)))
    handleConsumptionChange(parseFloat(total.toFixed(2)), installations)
  }, [handleConsumptionChange, installations, type])

  const handleAddInstallation = () => {
    setInstallations((prevInstallations) => [
      ...prevInstallations,
      installationInitialValues,
    ])
  }

  const handleRemoveInstallation = (indexToRemove: number) => {
    setInstallations((prevInstallations) =>
      prevInstallations.filter((_, index) => indexToRemove !== index),
    )
  }

  const handleChangeInstallation = (index: number, newValue: Installation) => {
    setInstallations((prevInstallations) => {
      const newInstallations = [...prevInstallations]

      newInstallations[index] = { ...newInstallations[index], ...newValue }

      return [...newInstallations]
    })
  }

  return (
    <MuiGrid container direction="column">
      <TotalAnnualConsumptionStyled>
        <div className="annual-consumption">
          <h2>
            {infoForAllConsumption.sumConsumptionText}:{' '}
            {(Math.round(totalAnnualConsumption * 100) / 100).toFixed(2)}{' '}
            {infoForAllConsumption.sumConsumptionUnit}
          </h2>
          <span onClick={() => setExpanded((prev) => !prev)}>
            Details
            {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </span>
        </div>
        <InstallationPerformanceDetails
          commissioningInstallationsLabel={commissioningInstallationsLabel}
          expanded={expanded}
          installations={installations}
          type={type}
        />
      </TotalAnnualConsumptionStyled>
      <InstallationsPerformance
        commissioningInstallationsLabel={commissioningInstallationsLabel}
        commissioningInstallationsOptions={commissioningInstallationsOptions}
        handleChangeInstallation={handleChangeInstallation}
        handleRemoveInstallation={handleRemoveInstallation}
        installations={installations}
        showErrors={showErrors}
        type={type}
      />
      <MuiGrid container direction="row" spacing={2}>
        <MuiGrid item xs>
          <Button
            color="primary"
            data-component="button"
            onClick={handleAddInstallation}
            style={{ borderWidth: '2px' }}
            variant="outlined"
          >
            {buttonText}
          </Button>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  )
}

export default InstallationsPerformanceModule
