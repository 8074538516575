import { BlueprintElements } from 'types/blueprint-config'

import {
  buildGridContainerBlock,
  buildLayoutBlock,
  buildEnumControlBlock,
  buildInputControlBlock,
  buildGridItemBlock,
  buildBooleanControlBlock,
} from '../block-builders'
import { defaultPersonalInformationSchemaProperties } from '../schemas/personal-information.schema'

const personalInformationInputLabels = {
  FIRST_NAME: 'Vorname',
  LAST_NAME: 'Nachname',
  BIRTH_DATE: 'Geburtsdatum',
  EMAIL: 'E-Mail Adresse',
  TELEPHONE: 'Telefon',
}

type PersonalInformationBuilderProps = {
  showSalutation?: boolean
  showTitle?: boolean
  showBirthdate?: boolean
  showTelephone?: boolean
  showAgreement?: boolean
  agreementText?: string
}

// Build Personal Information
export const buildPersonalInformationBlock = ({
  showSalutation = false,
  showTitle = false,
  showBirthdate = false,
  showTelephone = false,
  showAgreement = false,
  agreementText = '',
}: PersonalInformationBuilderProps): BlueprintElements => {
  const gridContainerElements = []

  // Build Salutation
  if (showSalutation) {
    const salutationControl = buildEnumControlBlock(
      defaultPersonalInformationSchemaProperties.salutation.name,
      true,
    )
    const salutationControlGridItem = buildGridItemBlock([salutationControl], {
      xs: 12,
      md: showTitle ? 6 : 12,
    })

    gridContainerElements.push(salutationControlGridItem)
  }

  // Builder Title
  if (showTitle) {
    const titleControl = buildEnumControlBlock(
      defaultPersonalInformationSchemaProperties.title.name,
      true,
    )
    const titleControlGridItem = buildGridItemBlock([titleControl], {
      xs: 12,
      md: showSalutation ? 6 : 12,
    })

    gridContainerElements.push(titleControlGridItem)
  }

  // Builder First Name
  const firstNameControl = buildInputControlBlock(
    defaultPersonalInformationSchemaProperties.first_name.name,
    personalInformationInputLabels.FIRST_NAME,
  )
  const firstNameControlGridItem = buildGridItemBlock([firstNameControl], {
    xs: 12,
    md: 6,
  })

  gridContainerElements.push(firstNameControlGridItem)

  // Builder Last Name
  const lastNameControl = buildInputControlBlock(
    defaultPersonalInformationSchemaProperties.last_name.name,
    personalInformationInputLabels.LAST_NAME,
  )
  const lastNameControlGridItem = buildGridItemBlock([lastNameControl], {
    xs: 12,
    md: 6,
  })

  gridContainerElements.push(lastNameControlGridItem)

  // Builder Birth Date
  if (showBirthdate) {
    const birthDateControl = buildInputControlBlock(
      defaultPersonalInformationSchemaProperties.birth_date.name,
      personalInformationInputLabels.BIRTH_DATE,
    )
    const birthDateControlGridItem = buildGridItemBlock([birthDateControl], {
      xs: 12,
      md: 6,
    })

    gridContainerElements.push(birthDateControlGridItem)
  }

  // Builder Email
  const emailControl = buildInputControlBlock(
    defaultPersonalInformationSchemaProperties.email.name,
    personalInformationInputLabels.EMAIL,
  )
  const emailControlGridItem = buildGridItemBlock([emailControl], {
    xs: 12,
    md: 6,
  })

  gridContainerElements.push(emailControlGridItem)

  // Builder Telephone
  if (showTelephone) {
    const telephoneControl = buildInputControlBlock(
      defaultPersonalInformationSchemaProperties.telephone.name,
      personalInformationInputLabels.TELEPHONE,
    )
    const telephoneControlGridItem = buildGridItemBlock([telephoneControl], {
      xs: 12,
      md: 6,
    })

    gridContainerElements.push(telephoneControlGridItem)
  }

  // Builder Email
  if (showAgreement) {
    const agreementControl = buildBooleanControlBlock(
      defaultPersonalInformationSchemaProperties.agreement.name,
      agreementText,
    )
    const agreementControlGridItem = buildGridItemBlock([agreementControl], {
      xs: 12,
    })

    gridContainerElements.push(agreementControlGridItem)
  }

  // Builder Containers
  const gridContainer = buildGridContainerBlock(gridContainerElements, {
    spacing: 3,
  })

  const uischema = buildLayoutBlock('VerticalLayout', [gridContainer])

  return uischema
}
