import { ObjectKeyString } from 'lib/types'

export const URLhasQuery = (url: string): boolean => {
  // URL already a query if '?' is in URL
  return url.indexOf('?') !== -1
}

export const addQueryToURL = (
  url: string,
  queryParams: ObjectKeyString<string>,
): string => {
  let URLwithQuery = url

  Object.keys(queryParams).forEach((key) => {
    // Add query initial '?', if already present add '&'
    URLwithQuery += URLhasQuery(URLwithQuery) ? '&' : '?'
    // Add query param [key=value] to url
    URLwithQuery += `${key}=${queryParams[key] ? queryParams[key] : ''}`
  })

  return URLwithQuery
}
