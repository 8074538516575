import { BlueprintStep } from 'types/blueprint-config'

import { defaultConsumptionCalculatorSchemaProperties } from '../schemas/consumption-calculator.schema'

export const productDetailPower: BlueprintStep = {
  id: 'product_detail_power',
  title: 'Product details (Power)',
  nextButtonLabel: 'in den Warenkorb',
  schema: {
    type: 'object',
    properties: {
      power_addons_selected: {
        type: 'array',
      },
      power_product_selected: {
        type: 'string',
      },
    },
  },
  uischema: {
    type: 'GridContainerLayout',
    options: {
      spacing: 6,
    },
    elements: [
      {
        type: 'GridItemLayout',
        options: {
          xs: 12,
        },
        elements: [
          {
            type: 'CardLayout',
            options: {},
            elements: [
              {
                type: 'GridContainerLayout',
                options: {},
                elements: [
                  {
                    type: 'GridItemLayout',
                    options: {
                      xs: 12,
                      md: 2,
                    },
                    elements: [
                      {
                        type: 'Label',
                        options: {
                          color: 'textSecondary',
                          variant: 'body2',
                          style: {
                            fontWeight: 600,
                          },
                        },
                        text: 'Angaben:',
                      },
                    ],
                  },
                  {
                    type: 'GridItemLayout',
                    options: {
                      xs: 12,
                      md: 10,
                    },
                    elements: [
                      {
                        type: 'InlineSpacingLayout',
                        elements: [
                          {
                            type: 'DataLabel',
                            options: {
                              color: 'textSecondary',
                            },
                            text: `PLZ {{${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.zipCode}} |`,
                          },
                          {
                            type: 'Label',
                            options: {
                              color: 'textSecondary',
                            },
                            text: 'Strom',
                          },
                          // TO-DO: Hide message when no icon/option selected
                          // {
                          //   type: 'Label',
                          //   options: {
                          //     color: 'primary',
                          //   },
                          //   text: '|',
                          // },
                          // {
                          //   type: 'DataLabel',
                          //   options: {
                          //     color: 'primary',
                          //   },
                          //   text: `{{${defaultConsumptionCalculatorSchemaProperties.power_consumption.name}.numberOfItems}} Personen`,
                          // },
                          {
                            type: 'Label',
                            options: {
                              color: 'textSecondary',
                            },
                            text: '|',
                          },
                          {
                            type: 'DataLabel',
                            options: {
                              color: 'textSecondary',
                              formatDataNumber: true,
                            },
                            text: `{{${defaultConsumptionCalculatorSchemaProperties.power_consumption.name}.amount}} kWh`,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'ProductDetail',
        scope: '#/properties/power_addons_selected',
        options: {
          category: 'POWER',
        },
      },
    ],
  },
  resultData: {},
  logic: {
    rules: [
      {
        effect: 'NEXT_STEP',
        condition: {
          schema: 'Strom',
          scope: `${defaultConsumptionCalculatorSchemaProperties.selected_tab.name}`,
          action: {
            nextSteps: 'personal_information',
          },
        },
      },
      {
        effect: 'NEXT_STEP',
        condition: {
          schema: 'Strom & Gas',
          scope: `${defaultConsumptionCalculatorSchemaProperties.selected_tab.name}`,
          action: {
            nextSteps: 'product_unavailability_gas',
          },
        },
      },
    ],
  },
}
