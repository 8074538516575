import { AttachIcon, LargeText, styled } from '@epilot/components'
import React from 'react'

import config from 'config'
import { Attachment } from 'lib/api.types'

import { SecondaryTitleDescriptionStyled } from './product-details-styled'

type ProductDetailsAttachmentsProps = {
  attachments: Attachment[]
}

const AttachmentsStyled = styled(SecondaryTitleDescriptionStyled)`
  display: flex;
  flex-wrap: wrap;

  > div {
    align-items: center;
    display: flex;
    flex-basis: 25%;
    padding: 8px;

    > svg {
      margin-right: 8px;
      height: 16px;
      width: 16px;
    }
    > a {
      margin-right: 8px;
      text-decoration: none;
    }
  }

  @media (max-width: 751px) {
    > div {
      flex-basis: 50%;
    }
  }
`

const ProductDetailsAttachments = ({
  attachments,
}: ProductDetailsAttachmentsProps): JSX.Element => {
  function formatFileSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

    if (bytes === 0) return '0 Byte'
    const i = Math.floor(Math.log(bytes) / Math.log(1024))

    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
  }

  return (
    <AttachmentsStyled>
      {attachments.map((attachment) => (
        <div key={attachment.filename}>
          <AttachIcon />
          <a
            href={config.IMAGE_HOST + attachment.src}
            rel="noopener noreferrer"
            target="_blank"
          >
            <LargeText color="primary">{attachment.name}</LargeText>
          </a>
          <span>{formatFileSize(attachment.filesize)}</span>
        </div>
      ))}
    </AttachmentsStyled>
  )
}

export default ProductDetailsAttachments
