import { BlueprintStep } from 'types/blueprint-config'

import { API_PRODUCTS } from '../../../../config'
import { defaultConsumptionCalculatorSchemaProperties } from '../schemas/consumption-calculator.schema'
import {
  personalInformationSchemaProperties,
  defaultPersonalInformationSchemaProperties,
} from '../schemas/personal-information.schema'
import { buildPersonalInformationBlock } from '../uischemas/personal-information.uischema'
export const productUnavailabilityGas: BlueprintStep = {
  id: 'product_unavailability_gas',
  title: 'Product Unavailability',
  nextButtonLabel: 'abschicken',
  schema: {
    type: 'object',
    properties: {
      ...personalInformationSchemaProperties,
    },
    required: [
      defaultPersonalInformationSchemaProperties.first_name.name,
      defaultPersonalInformationSchemaProperties.last_name.name,
      defaultPersonalInformationSchemaProperties.email.name,
    ],
  },
  uischema: {
    type: 'GridContainerLayout',
    options: {
      spacing: 6,
    },
    elements: [
      {
        type: 'GridItemLayout',
        options: { xs: 12 },
        elements: [
          {
            type: 'GridContainerLayout',
            options: {
              spacing: 6,
            },
            elements: [
              {
                type: 'GridItemLayout',
                options: {
                  xs: 12,
                },
                elements: [
                  {
                    type: 'CardLayout',
                    elements: [
                      {
                        type: 'StepContent',
                        options: {
                          headline: 'Wir entschuldigen uns!',
                          headlineCaption: '* Benötigte Angaben',
                          description:
                            'Leider können wir unsere Produkte in Ihrer Nähe nicht anbieten. Sie können eine Anfrage stellen und wir werden Sie innerhalb weniger Tage informieren.',
                          subheadline: 'Ihre Kontaktdaten',
                          showNextButtonInDesktop: true,
                        },
                        elements: [
                          {
                            ...buildPersonalInformationBlock({
                              showTitle: true,
                              showSalutation: true,
                              showTelephone: true,
                              showAgreement: true,
                              agreementText:
                                'Ich erkläre mich einverstanden mit der Verarbeitung und Nutzung der von mir im Rahmen dieses Vertrags erhobenen persönlichen Daten für an mich per Telefon und E-Mail gerichtete Werbung (Angebote und Informationen im Zusammenhang mit der Energiebelieferung) durch die XXX GmbH. Meine Einwilligung kann ich jederzeit widerrufen.',
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'GridItemLayout',
        options: {
          xs: 12,
        },
        elements: [
          {
            type: 'ForwardSummaryTile',
            options: {
              mobileOnly: true,
            },
          },
        ],
      },
    ],
  },
  resultData: {},
  logic: {
    nextStepId: 'thank_you',
    sendResults: true,
    prerequisites: [
      {
        queryTemplate: {
          postal_code: `${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.zipCode`,
          city: `${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.city`,
          street_name: `${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.streetName`,
          street_number: `${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.houseNumber`,
          gas_consumption: `${defaultConsumptionCalculatorSchemaProperties.gas_consumption.name}.amount`,
          product_ids: 'product_ids',
        },
        queryStatic: {
          category: 'GAS',
        },
        responseStepId: {
          productsAvailable: 'product_overview_gas',
        },
        responseType: 'PRODUCT_AVAILABILITY',
        type: 'API',
        method: 'GET',
        url: `${API_PRODUCTS}/v2/product`,
        targetProperty: '',
        EXPERIMENTAL_skipOnProperty: '',
        responseErrorStepId: '',
      },
    ],
  },
}
