import { MuiFormHelperText } from '@epilot/components/dist'
import {
  RankedTester,
  rankWith,
  uiTypeIs,
  ControlProps,
  OwnPropsOfEnum,
} from '@jsonforms/core'
import { withJsonFormsEnumProps } from '@jsonforms/react'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { AddonAPI } from 'lib/api.types'
import { cartAddAddon, cartRemoveAddon } from 'modules/shop/reducer'
import {
  getAddonsFromProductsInCart,
  getProducts,
} from 'modules/shop/selectors'
import { getActiveStepData, getShowErrors } from 'modules/step/selectors'

import AddonsSelection from './addons-selection'

const Addons = ({
  data,
  handleChange,
  path,
  uischema,
  errors,
}: ControlProps & OwnPropsOfEnum): JSX.Element => {
  const singleSelection = uischema?.options?.singleSelection
  const filter = uischema?.options?.filter
  const exclude = uischema?.options?.exclude
  const withDetails = uischema?.options?.withDetails
  const productAddons = useSelector(getAddonsFromProductsInCart)
  const dispatch = useDispatch()
  const activeStepData = useSelector(getActiveStepData)
  const showErrors = useSelector(getShowErrors)
  const error =
    errors === 'Ist erforderlich.'
      ? 'Bitte wählen Sie zuerst ein Produkt aus'
      : errors

  const allProducts = useSelector(getProducts)
  const adddOnsIds = activeStepData['addon_ids']
  let addons: AddonAPI[] = []

  if (activeStepData['previewMode']) {
    let allAddons: AddonAPI[] = []
    const addonIds: number[] = []

    allProducts.forEach((product) => {
      allAddons = [...allAddons, ...product.addons]
    })

    for (let i = 0; i < allAddons.length; i++) {
      const addon = allAddons[i]

      if (!addonIds.includes(addon.id)) {
        addons.push(addon)
        addonIds.push(addon.id)
      }
    }
    if (adddOnsIds) {
      const addonIds = (adddOnsIds instanceof Array
        ? adddOnsIds.join(',')
        : adddOnsIds
      ).split(',')
      const sortedAddons: AddonAPI[] = []

      addonIds.forEach((id: string) => {
        const searchAddon = addons.find((ele) => ele.id.toString() === id)

        if (searchAddon !== undefined) sortedAddons.push(searchAddon)
      })
      addons = sortedAddons
    }
    if (filter) addons = addons.filter((addon) => addon.labels.includes(filter))

    if (exclude)
      addons = addons.filter((addon) => !exclude.includes(addon.labels[0]))

    return (
      <>
        <AddonsSelection
          addons={addons}
          handleAdd={() => {
            return null
          }}
          handleRemove={() => {
            return null
          }}
          selectedIds={data}
          singleSelect={singleSelection}
          withDetails={withDetails && withDetails !== 'false'}
        />
        {showErrors && <MuiFormHelperText error>{error}</MuiFormHelperText>}
      </>
    )
  } else {
    if (!productAddons[0]) return <></>
    addons = productAddons[0].addons

    if (adddOnsIds) {
      const addonIds = (Array.isArray(adddOnsIds)
        ? adddOnsIds.join(',')
        : adddOnsIds
      ).split(',')

      const sortedAddons: AddonAPI[] = []

      addonIds.forEach((id: string) => {
        const searchAddon = addons.find((ele) => ele.id.toString() === id)

        if (searchAddon !== undefined) sortedAddons.push(searchAddon)
      })
      addons = sortedAddons
    }

    const { productId } = productAddons[0]

    if (filter) addons = addons.filter((addon) => addon.labels.includes(filter))

    if (exclude)
      addons = addons.filter((addon) => !exclude.includes(addon.labels[0]))

    const handleAdd = (id: number, quantity = 1) => {
      if (singleSelection) {
        handleChange(path, id.toString())

        dispatch(cartAddAddon(id.toString(), productId, quantity, true))
      } else {
        if (!data) {
          handleChange(path, id.toString())
        } else if (!data.split(';').includes(id.toString())) {
          handleChange(path, data + ';' + id.toString())
        }
        dispatch(cartAddAddon(id.toString(), productId, quantity))
      }
    }

    const handleRemove = (id: number) => {
      if (singleSelection) {
        handleChange(path, null)
        dispatch(cartRemoveAddon(id.toString(), productId))
      } else {
        handleChange(
          path,
          data
            .split(';')
            .filter((cartId: string) => {
              if (cartId === id.toString())
                dispatch(cartRemoveAddon(id.toString(), productId))

              return cartId !== id.toString()
            })
            .join(';'),
        )
      }
    }

    return (
      <>
        <AddonsSelection
          addons={addons}
          handleAdd={(id: number, quantity = 1) => handleAdd(id, quantity)}
          handleRemove={(id: number) => handleRemove(id)}
          selectedIds={data}
          singleSelect={singleSelection}
          withDetails={withDetails}
        />
        {showErrors && <MuiFormHelperText error>{error}</MuiFormHelperText>}
      </>
    )
  }
}

export const AddonsRenderer = withJsonFormsEnumProps(Addons)

export const addonsRendererTester: RankedTester = rankWith(
  1,
  uiTypeIs('Addons'),
)
