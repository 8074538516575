import { GlobalResultData } from 'modules/step/types'

import { defaultConfirmationSchemaProperties } from '../../../../../../src/assets/power-and-gas/config/schemas/confirmation.schema'
import { defaultContractCancellationSchemaProperties } from '../../../../../../src/assets/power-and-gas/config/schemas/contract-cancellation.schema'

export const evaluateCondition = (mapping: string, data: GlobalResultData) => {
  if (mapping === 'data.owner') {
    return data['owner'] === 'yes'
  }
  if (mapping === 'data.termination_gas') {
    return data['termination_gas'] === 'yes'
  }

  if (mapping === 'data.termination_power') {
    return data['termination_power'] === 'yes'
  }
  if (mapping === 'data.termination_broadband') {
    return data['termination_broadband'] === 'yes'
  }
  if (mapping === 'data.contract_termination') {
    return data['contract_termination'] === 'yes'
  }
  if (mapping === 'data.contract_type') {
    return data['contract_type'] === 'new_contract'
  }
  if (mapping === 'data.checkout_payment') {
    return data['checkout_payment'] === 'sepa'
  }
  if (mapping === 'data.additional_numbers') {
    return data['additional_numbers']
  }

  if (mapping === 'data.individual_connection_record') {
    return data['individual_connection_record'] === 'yes'
  }
  if (mapping === 'data.terminate_current_connection') {
    return data['terminate_current_connection'] === 'yes'
  }
  if (mapping === 'data.individual_connection_record_type') {
    return data['individual_connection_record_type'] === 'gekürzt'
  }
  if (mapping === 'data.accept_terms') {
    return data['accept_terms']
  }
  if (mapping === 'data.different_invoice_name') {
    return data['different_invoice_name']
  }
  if (mapping === 'data.withdrawal') {
    return data['withdrawal']
  }
  if (mapping === 'data.accept_promotions') {
    return data['accept_promotions']
  }
  if (mapping === 'data.providers_relationship_type') {
    return data['providers_relationship_type'] === 'same'
  }
  if (mapping === 'data.checkout_direct_debit_mandate') {
    return data['checkout_direct_debit_mandate']
  }
  if (mapping === 'data.next_delivery_date') {
    return data['next_delivery_date']
  }
  if (mapping === 'data.phonebook_entry') {
    return data['phonebook_entry'] === 'yes'
  }

  if (mapping === 'data.terminate_current_connection') {
    return data['terminate_current_connection'] === 'yes'
  }
  if (mapping === 'data.terminate_contract_tip') {
    return data['terminate_contract_tip']
  }
  if (mapping === 'data.no_terminate_contract_tip') {
    return data['no_terminate_contract_tip']
  }
  if (mapping === 'data.number_block') {
    return data['number_block']
  }
  if (mapping === 'data.not_connection_owner') {
    return data['not_connection_owner']
  }
  if (mapping === 'data.switch_old_contract_gift') {
    return data['switch_old_contract_gift']
  }
  if (mapping === 'data.differ_meter_with_network_operator') {
    return data['differ_meter_with_network_operator'] === 'yes'
  }
  if (mapping === 'data.exist_storage_system') {
    return data['exist_storage_system'] === 'yes'
  }
  if (mapping === 'data.meter_available') {
    return data['meter_available'] === 'yes'
  }
  if (mapping === 'data.owner_of_property') {
    return data['owner_of_property']
  }
  if (mapping === 'data.locate_of_photovoltaics_system') {
    return data['locate_of_photovoltaics_system'] === 'building'
  }
  if (mapping === 'data.exist_other_generating_system') {
    return data['exist_other_generating_system'] === 'yes'
  }
  if (mapping === 'data.isolated_operation') {
    return data['isolated_operation'] === 'yes'
  }
  if (mapping === 'data.payment_agreement') {
    return data['payment_agreement']
  }
  if (mapping === 'data.accept_assignment') {
    return data['accept_assignment']
  }

  const splitMapping = mapping.split('.')
  const fieldsToEvaluate = [
    defaultContractCancellationSchemaProperties.delivery_date_asap_power.name,
    defaultContractCancellationSchemaProperties.delivery_date_asap_gas.name,
    defaultConfirmationSchemaProperties.accept_terms.name,
    defaultConfirmationSchemaProperties.withdrawal.name,
    defaultConfirmationSchemaProperties.accept_promotions.name,
  ]

  if (
    splitMapping &&
    splitMapping?.length > 0 &&
    splitMapping[0] === 'data' &&
    fieldsToEvaluate.includes(splitMapping[1]) &&
    data[splitMapping[1]]
  ) {
    return data[splitMapping[1]]
  }

  return false
}
