import { BlueprintStep } from 'types/blueprint-config'

export const thankYou: BlueprintStep = {
  id: 'thank_you',
  title: 'Last step',
  nextButtonLabel: 'Next',
  schema: {
    type: 'object',
    properties: {},
  },
  uischema: {
    type: 'GridContainerLayout',
    options: {},
    elements: [
      {
        type: 'GridItemLayout',
        options: {
          xs: 12,
        },
        elements: [
          {
            type: 'LabelH1',
            text: 'Thank you screen goes here',
          },
        ],
      },
    ],
  },
  resultData: {},
  logic: {},
}
