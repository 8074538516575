export type InstallationType =
  | 'commissioning'
  | 'solar'
  | 'storage'
  | 'inverter'
  | 'generator'

export type Installation = { installationType?: InstallationType } & (
  | CommissioningInstallation
  | SolarModuleInstallation
  | InverterInstallation
  | StorageInstallation
  | GeneratorInstallation
)

export enum InstallationStatus {
  Existing = 'Bestehend',
  New = 'Neu',
}

export enum InstallationConstructionType {
  synchronous = 'Synchron',
  asynchronous = 'Asynchron',
}
export enum InstallationName {
  Kochen = 'Kochen',
  Heizen = 'Heizen',
  HeizenUndWarmwasser = 'Heizen & Warmwasser',
  Warmwasser = 'Warmwasser',
  BHKW = 'BHKW',
}

export type CommissioningInstallation = {
  name?: string
  status?: InstallationStatus
  ratedCapacity?: number
  annualConsumption?: number
}

type MicroPowerPlantInstallation = {
  manufacturer?: string
  type?: string
  quantity?: number
}

export type SolarModuleInstallation = MicroPowerPlantInstallation & {
  solarPerformance?: number
}

export type InverterInstallation = MicroPowerPlantInstallation & {
  inverterPerformance?: number
}

export type StorageInstallation = MicroPowerPlantInstallation & {
  powerConsumption?: number
  powerOutput?: number
  storageCapacity?: number
}

export type GeneratorInstallation = MicroPowerPlantInstallation & {
  apparentPerformance?: number
  activePerformance?: number
  constructionType?: 'Synchron' | 'Asynchron'
}

export type InstallationsPerformanceModuleType = {
  commissioningInstallationsLabel?: string
  commissioningInstallationsOptions?: string[]
  showErrors?: boolean
  buttonText?: string
  handleConsumptionChange: (
    consumption: number,
    installations: Installation[],
  ) => void
  minInstallations?: number
  selectedInstallations?: Installation[]
  type?: InstallationType
}

export type InstallationPerformanceDetailsType = {
  type: InstallationType
  commissioningInstallationsLabel?: string
  installations: Installation[]
  expanded?: boolean
}

type InstallationPerformanceType = {
  handleChangeInstallation: (key: number, newValue: Installation) => void
  handleRemoveInstallation: (key: number) => void
  showRemoveIcon?: boolean
}

export type CommissioningInstallationPerformanceType = InstallationPerformanceType & {
  installations: CommissioningInstallation[]
  label: string
  options: string[]
}

export type SolarModuleInstallationPerformanceType = InstallationPerformanceType & {
  installations: SolarModuleInstallation[]
}

export type StorageInstallationPerformanceType = InstallationPerformanceType & {
  installations: StorageInstallation[]
}

export type InverterInstallationPerformanceType = InstallationPerformanceType & {
  installations: InverterInstallation[]
}

export type GeneratorInstallationPerformanceType = InstallationPerformanceType & {
  installations: GeneratorInstallation[]
}
export type ModuleInstallationPerformanceType = InstallationPerformanceType
