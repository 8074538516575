import { Addon as ShopAddon, ShopState } from 'modules/shop/types'

import { Addon } from '../../../../lib/api/types'

export const dataAddonsCreate = (shop: ShopState): Addon[] => {
  const cart = shop.cart
  const products = shop.products
  const addons: Addon[] = []

  if (!shop.cart || shop.cart.length === 0) return []

  const productIds = cart.map((product) => product.id)
  const productsInCart = products.filter(({ id }) => productIds.includes(id))
  const addonsAll: ShopAddon[] = []

  productsInCart.forEach((product) =>
    product.addons.forEach((addon) => addonsAll.push(addon)),
  )

  cart.forEach((cartItem) => {
    const { productCategory, addons: addonsInProduct, id: productId } = cartItem

    if (
      addonsInProduct &&
      addonsInProduct.length &&
      addonsInProduct.length > 0
    ) {
      addonsInProduct.forEach((addonInProduct) => {
        const { id } = addonInProduct
        const addon = addonsAll.find((addon) => String(addon.id) === id)

        if (addon) {
          if (addon.options && addon.options.length > 1)
            // Will be logged to DataDog
            console.error('Multiple Selling Option Ids detected')

          const sellingOptionId = addon.options[0].id

          addons.push({
            id: String(addon.id),
            product_id: productId,
            product_type: productCategory,
            selling_option_id: String(sellingOptionId),
          })
        } else {
          throw Error('Invalid Addon Selected')
        }
      })
    }
  })

  return addons
}
