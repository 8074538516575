import { StepsUnified } from '../../modules/step/constants'

import { MessagePayload, MessageSettings, MessageType } from './types'

export const dispatchUserEvent = (
  widgetId: string | undefined,
  stepId: string,
  value?: number,
) => {
  let journeyStepEvent = ''

  if (StepsUnified.STEP_PRODUCT_SELECTION.includes(stepId)) {
    journeyStepEvent = 'InitiateProductSelection'
  } else if (StepsUnified.STEP_INIT_CHECKOUT.includes(stepId)) {
    journeyStepEvent = 'InitiateCheckout'
  } else if (StepsUnified.STEP_THANK_YOU.includes(stepId)) {
    journeyStepEvent = 'Purchase'
  }

  const messagePayload = {
    journeyAction: 'pageview' as const,
    journeyPagePath: `epilot-widget/${widgetId}/${stepId}`,
    journeyStepEvent: journeyStepEvent as MessagePayload['journeyStepEvent'], // as const not possible here
    journeyEventData:
      stepId === 'thank_you' || stepId === 'thank_you_new' ? value : undefined,
  }

  dispatchPostMessage('EPILOT/USER_EVENT', messagePayload, { widgetId })
}

export const dispatchPostMessage = (
  type: MessageType,
  payload: MessagePayload,
  settings: MessageSettings,
) => {
  const { widgetId } = settings

  window.parent.postMessage(
    {
      type,
      widgetId,
      params: payload,
    },
    '*',
  )
}
