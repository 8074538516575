import { datadogRum } from '@datadog/browser-rum'
import { rankWith, uiTypeIs, or, LayoutProps } from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import { useSelector } from 'react-redux'

import { getAllResultData, getPreviousSteps } from 'modules/step/selectors'

const DataLoggerRenderer = ({ data }: LayoutProps) => {
  // local state from step handler
  const jsonFormsData = data
  // global redux state
  const globalData = useSelector(getAllResultData)
  const previousSteps = useSelector(getPreviousSteps)

  // prevent logging if unavailability was the last seen step
  // Ref: https://e-pilot.atlassian.net/browse/ER-2186
  // In some rare occasions the product unavailability screen switches directly to the error screen.
  const disallowedPrevSteps = [
    'product_unavailability_gas',
    'product_unavailability_power',
    'product_unavailability',
  ]

  const prevent =
    previousSteps?.length > 0 &&
    (disallowedPrevSteps.includes(previousSteps[previousSteps.length - 1]) ||
      previousSteps[previousSteps.length - 1] === 'error')

  if (!globalData.previewMode && !prevent)
    datadogRum.addAction('error-page-shown', {
      jsonFormsData,
      globalData,
    })

  return null
}

export const dataLoggerRendererTester = rankWith(2, or(uiTypeIs('DataLogger')))

export default withJsonFormsLayoutProps(DataLoggerRenderer)
