import { datadogRum } from '@datadog/browser-rum'
import { JsonSchema7 } from '@jsonforms/core'
import html2canvas from 'html2canvas'
import { all, takeLatest } from 'redux-saga/effects'
import { call, put, select } from 'typed-redux-saga'
import { v4 as uuid } from 'uuid'

import config from 'config'
import { gaTracker, fbTracker } from 'lib/analytics'
import {
  apiCall,
  apiCallErrorHandling,
  postPresignedPutUrls,
  putUploadPresignedUrl,
  postResultsV4,
  postResultsV3,
  postSubmissionApiV1,
} from 'lib/api/epilot.api'
import {
  BodyResults,
  PresignedUrlReq,
  PresignedUrlResp,
  S3File,
} from 'lib/api/types'
import { addQueryToURL } from 'lib/api/utils'
import { ObjectKeyString } from 'lib/types'
import { generateEnetFlag } from 'modules/generateEnetFlag'
import {
  shopCheckPropertyChanges,
  showCart,
  toggleCart,
} from 'modules/shop/reducer'
import {
  getShop,
  getCartTotalSummary,
  getProductSettings,
} from 'modules/shop/selectors'

import { dispatchUserEvent } from '../../lib/message-utils'
import {
  enterFullscreen,
  exitFullscreen,
  setSubmitError,
  setSubmitting,
  submitError,
  submitSuccess,
} from '../app/reducer'
import {
  getWidgetId,
  getMeta,
  getResultMapping,
  getOrganizationId,
  getFilterLabel,
  getInitialConfig,
} from '../app/selectors'
import { TypedAction } from '../types'

import { StepsUnified } from './constants'
import { getResultsFromMapping } from './getResultsFromMapping'
import {
  convertToSubmission,
  tryGetMarketingConsentTitleFromSteps,
} from './getResultsFromMapping/submissionConverter'
import { PayloadResults } from './getResultsFromMapping/types'
import {
  generateResultPayload,
  queryTemplateToQuery,
  parseCreditCheck,
  isNetworkError,
} from './helper'
import { getResponseFromMapping } from './helper/payload-mapper/result_mapper'
import { getResponseFromMappingV4 } from './helper/payload-mapper/result_mapper_v4'
import {
  activateStepLimbo,
  CHECK_STEP_LIMBO,
  checkStepLimbo,
  DO_CHECK_SEND_RESULTS,
  DO_CHECK_TOGGLE_CART,
  DO_FULL_SCREEN_CHECK,
  DO_PREREQUISITES,
  DO_PREREQUISITES_SUCCESS,
  doCheckSendResults,
  doCheckSendResultsFailure,
  doCheckToggleCart,
  doCheckShowShoppingCart,
  doFullScreenCheck,
  doStepPrerequisites,
  doStepPrerequisitesFailure,
  doStepPrerequisitesSuccess,
  FIND_NEXT,
  FIND_NEXT_SUCCESS,
  findNextStep,
  findNextStepFailure,
  findNextStepSuccess,
  GO_NEXT,
  GO_NEXT_SUCCESS,
  GO_PREVIOUS,
  GO_PREVIOUS_SUCCESS,
  GO_TO_STEP,
  goNextFailure,
  goNextSuccess,
  goPreviousFailure,
  goPreviousSuccess,
  goToStep,
  goToStepFailure,
  nextStepFromLimbo,
  PREFILL_FROM_GLOBAL_DATA,
  PREFILL_STEP_DATA,
  prefillFromGlobalData,
  resetPreviousSteps,
  SET_INPUT_VALUE,
  SET_INPUT_VALUE_SUCCESS,
  setActiveStep,
  setInputValue,
  setInputValueFailure,
  setInputValueSuccess,
  showErrors,
  VALIDATE_INPUT,
  validateInput,
  SEARCH_STREET,
  replaceStepSchemaProperty,
  DO_CHECK_SHOW_SHOPPING_CART,
} from './reducer'
import {
  getActiveStep,
  getActiveStepErrors,
  getActiveStepLogic,
  getActiveStepLogicNextStepId,
  getActiveStepLogicNextStepsByRules,
  getAllResultData,
  getLimboSteps,
  getPreviousStepId,
  getStepById,
  getStepDataById,
  getStepList,
  getStepLogicPrerequisitesById,
  getStepLogicToggleCartById,
  getStepSchemaPropertiesAsArray,
  getStepLogicShoppingCartById,
  getGridOperations,
  getSteps,
} from './selectors'
import {
  GlobalResultData,
  JsonSchemaWithEffect,
  StepId,
  StepIdPayload,
} from './types'

export const ERROR_STEP = 'error'

function* goNextFlow(): Generator {
  yield put(validateInput())
}

function* validateInputFlow(): Generator {
  try {
    const errors = yield* select(getActiveStepErrors)

    if (errors.length === 0) {
      // Continue
      yield* put(doCheckSendResults())
    } else {
      // Show errors and stop goNext flow
      yield* put(showErrors())
      yield* put(goNextFailure(Error('step has invalid fields')))
    }
  } catch (e) {
    console.error(e)
  }
}

function* doCheckSendResultsFlow(): Generator {
  try {
    const logic = yield* select(getActiveStepLogic)
    const shouldSendResults = logic.sendResults
    const resultMapping = yield* select(getResultMapping)
    const initialConfigInfo = yield* select(getInitialConfig)
    const builder = initialConfigInfo?.builder
    const resultApiVersion =
      logic.resultApiVersion || config.API_RESULTS_DEFAULT_VERSION

    if (shouldSendResults && resultMapping) {
      yield put(setSubmitting(true))
      const meta = yield* select(getMeta)
      const shop = yield* select(getShop)
      const activeStep = yield* select(getActiveStep)
      let emailTemplate = meta?.email_template_id
      const fallbackProductType =
        activeStep.id === 'product_unavailability_gas' ? 'GAS' : 'POWER'

      if (
        meta &&
        ['product_unavailability_power', 'product_unavailability_gas'].includes(
          activeStep.id,
        )
      ) {
        emailTemplate = meta.unavailability_email_template_id
      } else if (meta && activeStep.id === 'error') {
        emailTemplate = meta.error_email_template_id
      }

      // if v4, generate payload in new style and send to v4 Results API
      if (resultApiVersion === 'v4') {
        const results = ((yield* select(
          getAllResultData,
        )) as unknown) as PayloadResults

        if (!results.email) {
          throw Error('Email not provided, aborting v4 submission')
        }

        if (!meta) throw Error('Meta Data is not defined')

        // get screenshot
        let screenshot: any = undefined

        if (results.signature) {
          const s = results.signature as string
          const base64data = s.split(',')[1]

          screenshot = Buffer.from(base64data, 'base64')
        } else if (builder?.summarySnapshot) {
          const area =
            document.getElementById('content-wrapper') || document.body
          const areaHeight = area.offsetHeight

          const svgElements = area.querySelectorAll('svg')

          // the library can't support SVG element well
          // when render svg element on a canvas, here for
          // fixing SVG's width and height attribute missing
          if (svgElements) {
            for (let i = 0; i < svgElements.length; i++) {
              const curSvg = svgElements[i]
              const width = curSvg.width.baseVal.value
              const height = curSvg.height.baseVal.value

              width && curSvg.setAttribute('width', width.toString())
              height && curSvg.setAttribute('height', height.toString())
            }
          }

          yield html2canvas(area, {
            height: areaHeight,
            useCORS: true,
            allowTaint: true,
          })
            .then((canvas) => {
              const dataUrl = canvas.toDataURL('image/png')

              return dataUrl
            })
            .then((dataUrl) => {
              screenshot = Buffer.from(dataUrl.split(',')[1], 'base64')
            })
        }

        // get files which we need to upoload
        let signatureS3File: S3File | undefined = undefined

        if (screenshot) {
          const presignedUrlReq: PresignedUrlReq = {
            fileTypes: ['png'],
          }

          const presignedUrls = (yield call(
            postPresignedPutUrls,
            presignedUrlReq,
          )) as PresignedUrlResp

          signatureS3File = {
            file_name: presignedUrls[0].key,
            file_size: 0,
            original_name: 'signature.png',
            file_type: 'png',
          }

          // upload all files to s3
          yield all(
            presignedUrls.map((presigned) => {
              return call(
                putUploadPresignedUrl,
                screenshot,
                presigned.url,
                'image/png',
              )
            }),
          )

          // 4. modify payload for results api
        }

        const journeySubmitId = uuid()

        const resultData = getResultsFromMapping(
          results,
          meta,
          shop,
          signatureS3File,
          builder,
          journeySubmitId,
          emailTemplate,
          fallbackProductType,
        )

        yield all([postResultsV4(resultData), sendSubmission(resultData)])
      } else {
        // Generate Payload in old fashion
        const globalData = yield* select(getAllResultData)
        const resultPayload = generateResultPayload(
          resultMapping,
          globalData,
          (meta as unknown) as ObjectKeyString<string>,
          shop,
          builder,
        )

        // if v3Tov4 flag set, map payload to v4 Results API
        if (resultApiVersion === 'v3Tov4') {
          try {
            const journeySubmitId = uuid()
            const resultData = getResponseFromMappingV4(
              resultPayload,
              journeySubmitId,
            )

            yield all([postResultsV4(resultData), sendSubmission(resultData)])
          } catch (e) {
            // Will be logged to DataDog
            console.error(e)
            if (isNetworkError(e)) {
              yield* put(setSubmitError('NetworkError'))
            } else {
              const step = yield* select(getStepById, ERROR_STEP)

              if (step) {
                yield* put(goToStep(ERROR_STEP))
              } else {
                yield* put(doCheckSendResultsFailure(e))
              }
            }
          }
          // else, map payload to v3 Results API
        } else {
          const journeySubmitId = uuid()
          const resultData = getResponseFromMapping(
            resultPayload,
            journeySubmitId,
          )

          const resultsDataSubmission = {
            ...resultData,
            user_screenshot:
              (resultData?.user_screenshot &&
                typeof resultData.user_screenshot === 'object' &&
                Object.keys(resultData.user_screenshot)?.length > 0 && {
                  ...resultData.user_screenshot,
                  base64: 'check ivy opportunity',
                }) ||
              resultData?.user_screenshot,
            user_uploaded_files: Array.isArray(resultData?.user_uploaded_files)
              ? resultData?.user_uploaded_files.map((uploadFile: any) => {
                  return {
                    ...uploadFile,
                    base64: 'check ivy opportunity',
                  }
                })
              : resultData?.user_uploaded_files,
          }

          yield all([
            postResultsV3(resultData),
            sendSubmission(resultsDataSubmission),
          ])
        }
      }
      yield put(setSubmitting(false))
      yield* put(submitSuccess())
    }

    // Start process to find next flow
    yield* put(findNextStep())
  } catch (error) {
    // Will be logged to DataDog
    console.error(error)
    if (isNetworkError(error)) {
      yield* put(setSubmitError('NetworkError'))
    } else {
      const step = yield* select(getStepById, ERROR_STEP)

      if (step) {
        yield* put(goToStep(ERROR_STEP))
      } else {
        yield* put(doCheckSendResultsFailure(error))
      }
    }

    yield* put(submitError())
  }

  function* sendSubmission(resultData: BodyResults) {
    try {
      const steps = yield* select(getSteps)
      const marketingConsentTitle = tryGetMarketingConsentTitleFromSteps(steps)
      const submissionPayload = convertToSubmission({
        results: resultData,
        marketingConsentTitle,
      })

      yield call(postSubmissionApiV1, submissionPayload)
    } catch (error) {
      // Will be logged to DataDog
      console.error(error)
    }
  }
}

// Find the nextStepId based on the active step
function* findNextStepFlow(): Generator<unknown, void, unknown> {
  try {
    // Simple next step
    let nextStepId = yield* select(getActiveStepLogicNextStepId)

    // Advanced next step
    if (!nextStepId) {
      const stepLogicNextStepOrSteps = yield* select(
        getActiveStepLogicNextStepsByRules,
      )

      // Check for limboMode with multiple steps
      if (Array.isArray(stepLogicNextStepOrSteps)) {
        nextStepId = stepLogicNextStepOrSteps[0]
        const limboSteps = [...stepLogicNextStepOrSteps]

        limboSteps.shift()
        yield put(activateStepLimbo(limboSteps))
      } else {
        nextStepId = stepLogicNextStepOrSteps
      }
    }

    // TODO: Step available at backend/API?
    // Call API endpoint to see if the step(flow) is available to load
    // if yes: save step and stepflow
    // If no: put findNextStepFailure()

    yield put(findNextStepSuccess(nextStepId))
  } catch (error) {
    // Will be logged to DataDog
    console.error(error)
    const step = yield* select(getStepById, ERROR_STEP)

    if (step) {
      yield* put(goToStep(ERROR_STEP))
    } else {
      yield put(findNextStepFailure(error))
    }
  }
}

function* findNextStepSuccessFlow({
  payload,
}: TypedAction<StepIdPayload>): Generator {
  const { stepId } = payload

  yield put(checkStepLimbo(stepId))
}

function* checkStepLimboFlow({
  payload,
}: TypedAction<StepIdPayload>): Generator {
  const { stepId } = payload
  let nextStepId = stepId

  const limboSteps = yield* select(getLimboSteps)

  // Check if in limbo mode
  if (limboSteps?.length > 0) {
    // limbomode is activate
    const step = yield* select(getStepById, stepId)

    // Check if nextStep has stopLimbo=true
    if (step.logic.stopLimbo) {
      // Then get change possible limboStep
      yield put(nextStepFromLimbo())
      nextStepId = limboSteps[0]
    }
  }
  yield put(doFullScreenCheck(nextStepId))
}

function* doFullScreenCheckFlow({
  payload: { stepId },
}: TypedAction<StepIdPayload>): Generator {
  const stepData = yield* select(getStepById, stepId)

  if (stepData?.isEmbedded && !StepsUnified.STEP_THANK_YOU.includes(stepId)) {
    yield* put(exitFullscreen())
  } else if (!config.AUTO_RESIZE) {
    yield* put(enterFullscreen())
  }

  yield put(doCheckToggleCart(stepId))
  yield put(doCheckShowShoppingCart(stepId))
}
function* doCheckShowShoppingCartFlow({
  payload,
}: TypedAction<StepIdPayload>): Generator {
  try {
    const { stepId: id } = payload
    const stepId = id
    const showShoppingCart = yield* select(getStepLogicShoppingCartById, stepId)

    yield* put(showCart(showShoppingCart))
  } catch (error) {
    // Will be logged to DataDog
    console.error(error)
    const step = yield* select(getStepById, ERROR_STEP)

    if (step) {
      yield* put(goToStep(ERROR_STEP))
    } else {
      yield put(goToStepFailure(error))
    }
  }
}

function* doCheckToggleCartFlow({
  payload,
}: TypedAction<StepIdPayload>): Generator {
  try {
    const { stepId: id } = payload
    const stepId = id
    const shouldToggleCart = yield* select(getStepLogicToggleCartById, stepId)

    if (shouldToggleCart) {
      yield* put(toggleCart())
    }

    yield* put(doStepPrerequisites(stepId))
  } catch (error) {
    // Will be logged to DataDog
    console.error(error)
    const step = yield* select(getStepById, ERROR_STEP)

    if (step) {
      yield* put(goToStep(ERROR_STEP))
    } else {
      yield put(goToStepFailure(error))
    }
  }
}
function* doStepPrerequisitesFlow({
  payload,
}: TypedAction<StepIdPayload>): Generator<any, any, any> {
  try {
    const { stepId: id } = payload
    const stepId = id

    // Check if next step has prerequisites that need to be completed before continuing
    const prerequisites = yield* select(getStepLogicPrerequisitesById, stepId)
    const globalData = yield* select(getAllResultData)
    const organizationId = yield* select(getOrganizationId)
    const filterLabel = yield* select(getFilterLabel)
    const initialConfig = yield* select(getInitialConfig)
    const enetCallInfo = initialConfig?.builder?.callEnetInfo
    const previewModule = globalData?.previewMode || false

    /**
     *  Skip if no prerequisites defined
     */
    if (!prerequisites) return yield* put(doStepPrerequisitesSuccess(stepId))

    // Could be an API call, wait for results, provide results for the next
    // Example is the API call with the POSTAL CODE to determine of the available Gas/Power products
    // Set prefilled data into the next step in the redux store, so when the next gets active it does
    // can render based on the API result - Only Gas or both Gas/POwer
    let prerequisitesEarlySuccess = false
    const sagaGenerators = prerequisites.map((prerequisite) => {
      if (prerequisitesEarlySuccess) return null
      /**
       * API
       */
      if (prerequisite.type === 'API') {
        /**
         * Skip if 'EXPERIMENTAL_skipOnProperty' is set in 'globalData'
         */
        if (
          prerequisite.EXPERIMENTAL_skipOnProperty &&
          globalData[prerequisite.EXPERIMENTAL_skipOnProperty]
        ) {
          prerequisitesEarlySuccess = true

          return null
        }

        const { method, url, queryTemplate, queryStatic = {} } = prerequisite
        const currentEnetCallInfo = generateEnetFlag(
          queryStatic?.category,
          enetCallInfo,
        )

        /**
         * API Method: GET
         */
        if (method === 'GET') {
          const query = queryTemplateToQuery(queryTemplate, globalData)

          const urlWithQuery = addQueryToURL(url, {
            ...query,
            ...queryStatic,
            organization_id: organizationId || '',
            brand_id: initialConfig?.builder?.brand_id,
            enet_call: previewModule
              ? 'false'
              : currentEnetCallInfo &&
                currentEnetCallInfo[0] &&
                currentEnetCallInfo[0].enetCall
              ? currentEnetCallInfo[0].enetCall.toString()
              : 'false',
            ...(filterLabel ? { label: filterLabel } : undefined),
          })

          return call(apiCallErrorHandling, method, urlWithQuery)
        } else if (method === 'POST') {
          let data = queryTemplateToQuery(
            queryTemplate,
            globalData,
          ) as ObjectKeyString<string | number>

          if (prerequisite.responseType === 'CREDIT_CHECK')
            data = {
              organization_id: organizationId || '',
              ...parseCreditCheck(data),
            }

          return call(apiCallErrorHandling, method, url, data, {
            ...prerequisite?.options,
          })
        }
        /**
         * ASSIGN
         */
      } else if (prerequisite.type === 'ASSIGN') {
        const data = { [prerequisite.targetProperty]: prerequisite.value }

        return put(setInputValue(data, undefined, stepId))
      }

      return null
    })

    /**
     * Return on prerequisitesEarlySuccess
     */
    if (prerequisitesEarlySuccess)
      return yield* put(doStepPrerequisitesSuccess(stepId))

    const results = yield all(sagaGenerators)

    let errorStep
    let nextStepId
    let availableFrom
    let creditCheckNote

    const editSteps = results?.map(
      (
        result: {
          data?: any
          flag?: string
          items?: Array<ObjectKeyString<boolean | string>>
        },
        index: number,
      ) => {
        const originalPrerequisite = prerequisites[index]

        // TODO: multiple prerequisites could have different errorSteps
        // allow only one prerequisites OR document that order of prerequisites
        // is important - check which one overrides the one before
        if (
          originalPrerequisite.type === 'API' &&
          originalPrerequisite.responseType === 'PRODUCTS'
        ) {
          if (!result.data || result.data.length < 1) {
            nextStepId = originalPrerequisite.responseErrorStepId
          }
        } else if (
          originalPrerequisite.type === 'API' &&
          originalPrerequisite.responseType === 'CREDIT_CHECK'
        ) {
          // Check prerequisite condition matching global widget data
          if (
            originalPrerequisite.condition &&
            originalPrerequisite.condition.scope &&
            originalPrerequisite.condition.scope in globalData &&
            globalData[originalPrerequisite.condition.scope] !==
              originalPrerequisite.condition.value
          ) {
            creditCheckNote = {
              [originalPrerequisite.targetProperty]: '',
            }
          } else {
            creditCheckNote =
              result && result.flag === 'green'
                ? {
                    [originalPrerequisite.targetProperty]:
                      originalPrerequisite.responsePositiveNote,
                  }
                : {
                    [originalPrerequisite.targetProperty]:
                      originalPrerequisite.responseNegativeNote,
                  }
          }

          if (!result || result.flag !== 'green')
            errorStep = originalPrerequisite.responseErrorStepId
        } else if (
          originalPrerequisite.type === 'API' &&
          originalPrerequisite.responseType === 'PRODUCT_AVAILABILITY'
        ) {
          if (result?.items && result?.items?.length > 0) {
            const { available, has_gnv, available_from } = result.items[0]

            nextStepId = available
              ? has_gnv
                ? originalPrerequisite?.responseStepId?.gnv
                : originalPrerequisite?.responseStepId?.noGnv
              : available_from
              ? originalPrerequisite?.responseStepId?.availableFrom
              : originalPrerequisite?.responseStepId?.productsAvailable ||
                originalPrerequisite?.responseErrorStepId
            if (available_from) {
              const year =
                (available_from as string).split('-')[0] ||
                new Date(available_from as string).getFullYear().toString()

              availableFrom = {
                [originalPrerequisite.targetProperty]: parseInt(year, 10),
              }
            }
          } else {
            nextStepId = originalPrerequisite?.responseErrorStepId
          }
        }

        return null
      },
    )

    if (creditCheckNote)
      yield* put(setInputValue(creditCheckNote, undefined, stepId))

    if (errorStep) return yield* put(findNextStepSuccess(errorStep))

    if (nextStepId && nextStepId !== stepId) {
      if (availableFrom)
        yield* put(setInputValue(availableFrom, undefined, nextStepId))

      return yield* put(findNextStepSuccess(nextStepId))
    }

    yield all(editSteps)
    yield* put(doStepPrerequisitesSuccess(stepId))
  } catch (error) {
    // Will be logged to DataDog
    console.error(error)
    const step = yield* select(getStepById, ERROR_STEP)

    if (step) {
      yield* put(goToStep(ERROR_STEP))
    } else {
      yield* put(doStepPrerequisitesFailure(error))
    }
  }
}
function* doStepPrerequisitesSuccessFlow({
  payload,
}: TypedAction<StepIdPayload>): Generator {
  yield put(prefillFromGlobalData(payload.stepId))
}
function* prefillFromGlobalDataFlow({
  payload,
}: TypedAction<StepIdPayload>): Generator {
  try {
    const { stepId } = payload
    // Check if globalData has properties which are used in this step,
    // if yes - use the globalData to edit the next target steps 'prefillData' aka resultData
    const globalData = yield* select(getAllResultData)
    const stepData = yield* select(getStepDataById, stepId)
    const stepSchemaProperties = yield* select(
      getStepSchemaPropertiesAsArray,
      stepId,
    )
    const stepDataModified = { ...stepData }
    let modifyTargetStep = false

    const step = stepId
      ? yield* select(getStepById, stepId)
      : yield* select(getActiveStep)

    stepSchemaProperties.forEach((stepProperty) => {
      if (globalData[stepProperty]) {
        stepDataModified[stepProperty] = globalData[stepProperty]
        modifyTargetStep = true
      }

      // Check for PREFILL effect in schema properties
      const schemaProperties = step.schema.properties
      const schemaProperty =
        schemaProperties &&
        (schemaProperties[stepProperty] as JsonSchemaWithEffect)
      const schemaPropertyEffect = schemaProperty && schemaProperty.effect

      if (schemaPropertyEffect && schemaPropertyEffect.type === 'PREFILL') {
        let prefilledValue = ''
        const templates = schemaPropertyEffect.scopeTemplates
        const scopes = schemaPropertyEffect.scopes

        // If there are templates, the order is relevant -> the first to match wins
        if (templates) {
          const regexTemplate = /{{\s?([^{}\s]*)\s?}}/g

          for (const template of templates) {
            const templateValue = template.value

            let parsedTemplate = templateValue
            let match
            let matched = false

            if (template.if && !globalData[template.if]) {
              continue
            }

            while ((match = regexTemplate.exec(templateValue)) !== null) {
              if (globalData[match[1]]) {
                parsedTemplate = parsedTemplate.replace(
                  `${match[0]}`,
                  globalData[match[1]],
                )
                matched = true
              } else {
                regexTemplate.lastIndex = 0
                break
              }
            }

            if (matched) {
              prefilledValue = parsedTemplate
              break
            }
          }
        } else {
          // Schema property is prefilled with properties from globalData
          scopes?.forEach((scope, index) => {
            prefilledValue += globalData[scope] || ''
            prefilledValue +=
              index !== scopes.length - 1
                ? schemaPropertyEffect.separator || ''
                : ''
          })
        }

        stepDataModified[stepProperty] = prefilledValue?.trim() || ''
        modifyTargetStep = true
      }
    })

    if (modifyTargetStep) {
      yield put(setInputValue(stepDataModified, undefined, stepId))
    }

    yield put(goNextSuccess(payload.stepId))
  } catch (error) {
    // Will be logged to DataDog
    console.error(error)
    const step = yield* select(getStepById, ERROR_STEP)

    if (step) {
      yield* put(goToStep(ERROR_STEP))
    } else {
      yield put(goNextFailure(error))
    }
  }
}

function* goNextSuccessFlow({
  payload,
}: TypedAction<StepIdPayload>): Generator {
  const { stepId } = payload
  const widgetId = yield* select(getWidgetId)
  let value

  // track data in analytics
  if (!config.PREVIEW_MODE || config.NODE_ENV.NODE_ENV === 'development') {
    gaTracker.page(stepId)
    fbTracker.track('ViewContent', { content_name: stepId })

    // Specific step events
    if (stepId === 'product_selection') {
      fbTracker.track('InitiateProductSelection', undefined, true)
    }
    if (stepId === 'payment') {
      fbTracker.track('InitiateCheckout')
    }
    if (stepId === 'thank_you') {
      const totalSummary = yield* select(getCartTotalSummary)

      value = totalSummary.gross + totalSummary.oneTimeGross

      fbTracker.track('Purchase', { value: value, currency: 'EUR' })
    }
    dispatchUserEvent(widgetId, stepId, value)
  }

  // Log Data to DataDog
  datadogRum.startView(stepId)

  yield put(setActiveStep(stepId))
}

function* goPreviousSuccessFlow({
  payload,
}: TypedAction<StepIdPayload>): Generator {
  const stepData = yield* select(getStepById, payload.stepId)

  yield put(doCheckShowShoppingCart(payload.stepId))
  if (stepData?.isEmbedded) {
    yield* put(exitFullscreen())
  }

  const widgetId = yield* select(getWidgetId)

  // track data in analytics
  if (!config.PREVIEW_MODE || config.NODE_ENV.NODE_ENV === 'development') {
    gaTracker.page(payload.stepId)
    fbTracker.track('ViewContent', { content_name: payload.stepId })
    dispatchUserEvent(widgetId, payload.stepId)
  }
  // Log Data to DataDog
  datadogRum.startView(payload.stepId)
  yield put(setActiveStep(payload.stepId, true))
}

function* goPreviousFlow(): Generator {
  try {
    const activeStep = yield* select(getActiveStep)
    const { id: activeStepId } = activeStep
    const previousStepId = yield* select(getPreviousStepId)

    const showShoppingCart = yield* select(
      getStepLogicShoppingCartById,
      previousStepId,
    )
    const shouldToggleCart = yield* select(
      getStepLogicToggleCartById,
      activeStepId,
    )

    if (shouldToggleCart) {
      yield* put(toggleCart())
    }
    yield* put(showCart(showShoppingCart))
    yield put(goPreviousSuccess(previousStepId))
  } catch (error) {
    // Will be logged to DataDog
    console.error(error)
    const step = yield* select(getStepById, ERROR_STEP)

    if (step) {
      yield* put(goToStep(ERROR_STEP))
    } else {
      yield put(goPreviousFailure(error))
    }
  }
}

function* goToStepFlow({ payload }: TypedAction<StepIdPayload>): Generator {
  const { stepId } = payload

  try {
    // When jumping to a random step of the Widget steps the history of the
    // previous steps needs to be handled. The history after the step which will
    // be jumped to will be resetted and user have to go through the normal
    // step flow again
    yield put(resetPreviousSteps(stepId))

    // Reuse go previous action
    yield put(goPreviousSuccess(stepId))
  } catch (error) {
    // Will be logged to DataDog
    console.error(error)
    const step = yield* select(getStepById, ERROR_STEP)

    if (step) {
      yield* put(goToStep(ERROR_STEP))
    } else {
      yield put(goToStepFailure(error))
    }
  }
}

// TODO: Create ActionTypes
function* setInputValueFlow({
  payload,
}: TypedAction<{
  data: any
  stepId?: StepId
}>): Generator {
  try {
    const { data: changedData, stepId } = payload
    const step = stepId
      ? yield* select(getStepById, stepId)
      : yield* select(getActiveStep)
    const schemaProperties = step.schema.properties

    /**
     * Check the changed data for triggering additional changes to other fields
     * EFFECTS: "ASSIGN" | "COPY"
     */
    Object.keys(changedData).forEach((propertyName) => {
      const value = changedData[propertyName]

      const schemaProperty =
        schemaProperties &&
        (schemaProperties[propertyName] as JsonSchemaWithEffect)
      const schemaPropertyEffect = schemaProperty && schemaProperty.effect

      // Check if the linked step schema of the data has an EFFECT set
      if (
        schemaPropertyEffect &&
        schemaPropertyEffect.type === 'ASSIGN' &&
        value !== step.resultData[propertyName]
      ) {
        changedData[schemaPropertyEffect.target] = schemaPropertyEffect.values[
          value
        ] as string
      } else if (schemaPropertyEffect && schemaPropertyEffect.type === 'COPY') {
        if (typeof value !== 'string') {
          return console.error(
            "'schema' property of with effect type COPY needs to be of type 'string'",
          )
        }

        const valueParts = value.split(schemaPropertyEffect.separator)

        valueParts.forEach((part, index) => {
          const propertyTarget = schemaPropertyEffect.targets[index]

          changedData[propertyTarget] = part
        })
      }

      /**
       * - Shopping/Cart logic
       * Does read the steps cart logic, it includes the properties with should be monitored,
       * for changes (its value is a productId), and the productCategory which the added product
       * belongs to.
       */
      // const cart = step.logic.cart
      // const addToCartProperties = cart?.monitorProperties
      // const productCategory = cart?.productCategory

      // if (addToCartProperties && productCategory) {
      //   addToCartSagas = addToCartProperties.map(cartProperty => {
      //     if (cartProperty === propertyName) {
      //       return put(
      //         cartAddProduct(productCategory, value as ProductCategory), // TODO check if better way for typing
      //       )
      //     }
      //   })
      // }
    })

    // Execute the rule actions
    // if (addToCartSagas) {
    //   yield all(addToCartSagas)
    // }
    if (step.id === 'consumption_calculator') {
      const data = { ...changedData }

      const isPostCodeChange = yield* checkPostalCodeChangingForAmountGridAPI(
        data,
      )

      // when post code is changed, we need to set the operator amount info to be a initial value. So the street will be hide when the postcode is changed.
      // Also because enet API calling will check street number and street name based on the grid operator amount for postcode.
      // So when we change post code is changed, we need to reset the street number and street name.
      if (
        isPostCodeChange &&
        isPostCodeChange.isPostalCodeChange &&
        isPostCodeChange.gridOperations
      ) {
        if (isPostCodeChange.selectedProduct) {
          data[isPostCodeChange.selectedProduct] = {
            isLoading: false,
            amount: -1,
            showAdditionalFields: false,
          }
          if (data['postalcode_city']) {
            data['postalcode_city'] = Object.fromEntries(
              Object.entries(data['postalcode_city']).filter(
                ([key]) => key !== 'streetName' && key !== 'houseNumber',
              ),
            )
          }
        }
      }
      yield put(setInputValueSuccess(step.id, data))
    } else {
      yield put(setInputValueSuccess(step.id, changedData))
    }

    // TODO: Decide if input value validity check is needed
  } catch (error) {
    yield put(setInputValueFailure(error))

    return { error }
  }
}

function* setInputValueSuccessFlow(): Generator {
  // Call all action which need to be triggert after a new user input is
  // successfully in the redux store

  yield* put(shopCheckPropertyChanges())
}
function* checkPostalCodeChangingForAmountGridAPI(
  data: ObjectKeyString<string>,
): Generator {
  const resultData = yield* select(getAllResultData)

  const gridOperations = yield* select(getGridOperations)
  let selectedProductName = resultData && resultData?.selected_tab
  const productSettings = yield* select(getProductSettings)
  const productCategory =
    productSettings &&
    productSettings?.categories[0]?.id &&
    productSettings?.categories[0]?.id.toLowerCase()

  selectedProductName =
    selectedProductName &&
    selectedProductName.replace('Strom', 'Power').toLowerCase()

  if (!selectedProductName) {
    selectedProductName = productCategory && productCategory.toLowerCase()
  }

  selectedProductName =
    selectedProductName === 'power & gas' ? 'power' : selectedProductName

  const getZipCode = (allData: ObjectKeyString<string>) => {
    if (allData?.postalcode_city) {
      const zipCode =
        typeof allData?.postalcode_city === 'object'
          ? (allData?.postalcode_city as any)?.zipCode
          : undefined

      return zipCode
    } else if (allData?.postalcode_city === null) {
      return null
    }
  }

  const oldZipCode = getZipCode(resultData)

  const newZipCode = getZipCode(data)

  return {
    gridOperations: gridOperations,
    isPostalCodeChange: oldZipCode
      ? oldZipCode === newZipCode || (oldZipCode && newZipCode === null)
        ? false
        : true
      : false,
    selectedProduct: selectedProductName,
  }
}

// function* saveProductsFlow({
//   payload,
// }: TypedAction<{
//   stepId: StepId
//   targetProperty: string
// }>) {
//   const { stepId, targetProperty } = payload

//   // yield* put(saveProductsSuccess(stepId, targetProperty))
//   yield console.error('saveProductsFlow - old saveProductsSuccess()')
// }

function* prefillStepDataFlow({
  payload,
}: TypedAction<{
  data: GlobalResultData
}>) {
  const { data: prefillData } = payload
  const prefillDataKeys = Object.keys(prefillData) as string[]
  const steps = yield* select(getStepList)

  const prefillSteps = {} as ObjectKeyString<ObjectKeyString<unknown>>

  // Go through all steps
  steps.forEach((step) => {
    if (step.schema.properties) {
      // Go through the steps properties
      Object.keys(step.schema.properties).forEach((propertyName) => {
        // Check the property is in the prefillData
        if (prefillDataKeys.includes(propertyName)) {
          // Initialize new stepData with existing globalData
          if (!prefillSteps[step.id]) {
            prefillSteps[step.id] = { ...step.resultData }
          }

          prefillSteps[step.id][propertyName] = prefillData[propertyName]
          prefillDataKeys.splice(prefillDataKeys.indexOf(propertyName), 1)
        }
      })
    }
  })

  const sagas = Object.keys(prefillSteps).map((stepId) =>
    put(setInputValue(prefillSteps[stepId], undefined, stepId)),
  )

  yield all(sagas)
}

function* searchStreetFlow({
  payload,
}: TypedAction<{
  city: string
  postalcode: number
  organizationId: string
  apiEndpoint: string
  streetListScope: string
}>): Generator {
  try {
    const dat = {
      city: payload.city,

      organization_id: payload.organizationId,

      postal_code: payload.postalcode,
    }

    const streetList = yield* call(apiCall, 'POST', payload.apiEndpoint, dat)
    const step = yield* select(getActiveStep)
    const schemaDat: JsonSchema7 = {
      type: 'string',
      description: JSON.stringify(streetList),
    }

    yield* put(
      replaceStepSchemaProperty(step.id, payload.streetListScope, schemaDat),
    )
  } catch (error) {
    console.error(error)
  }
}

export default function* saga(): Generator {
  yield all([
    takeLatest(GO_NEXT, goNextFlow),
    takeLatest(GO_PREVIOUS, goPreviousFlow),
    takeLatest(GO_TO_STEP, goToStepFlow),
    takeLatest(VALIDATE_INPUT, validateInputFlow),
    takeLatest(FIND_NEXT, findNextStepFlow),
    takeLatest(FIND_NEXT_SUCCESS, findNextStepSuccessFlow),
    takeLatest(CHECK_STEP_LIMBO, checkStepLimboFlow),
    takeLatest(DO_FULL_SCREEN_CHECK, doFullScreenCheckFlow),
    takeLatest(DO_PREREQUISITES, doStepPrerequisitesFlow),
    takeLatest(DO_CHECK_SEND_RESULTS, doCheckSendResultsFlow),
    takeLatest(DO_CHECK_TOGGLE_CART, doCheckToggleCartFlow),
    takeLatest(DO_CHECK_SHOW_SHOPPING_CART, doCheckShowShoppingCartFlow),

    takeLatest(DO_PREREQUISITES_SUCCESS, doStepPrerequisitesSuccessFlow),
    takeLatest(PREFILL_FROM_GLOBAL_DATA, prefillFromGlobalDataFlow),
    takeLatest(GO_NEXT_SUCCESS, goNextSuccessFlow),
    takeLatest(GO_PREVIOUS_SUCCESS, goPreviousSuccessFlow),
    takeLatest(SET_INPUT_VALUE, setInputValueFlow),
    takeLatest(SET_INPUT_VALUE_SUCCESS, setInputValueSuccessFlow),
    takeLatest(PREFILL_STEP_DATA, prefillStepDataFlow),
    takeLatest(SEARCH_STREET, searchStreetFlow),
  ])
}
