import { CardProps, GridProps, SpacingProps } from '@epilot/base-elements'
import {
  ConsumptionCalculatorProps,
  AddressModuleProps,
} from '@epilot/base-modules'
import { TypographyVariant } from '@material-ui/core'

import { MeterReadingRendererOptions } from 'components/renderer-v2/meter-reading'
import {
  BlueprintControlElement,
  BlueprintElements,
  BlueprintCardElement,
  BlueprintGridElement,
  BlueprintLabelElement,
  BlueprintLayoutElement,
  BlueprintCustomLayoutElement,
  BlueprintCustomControlElement,
  BlueprintTabControlElement,
} from 'types/blueprint-config'

export const buildLayoutBlock = (
  type: 'VerticalLayout' | 'HorizontalLayout',
  elements: BlueprintElements[],
): BlueprintLayoutElement => ({
  type,
  elements,
})

const buildGridBlock = (
  type: 'GridLayout' | 'GridItemLayout' | 'GridContainerLayout',
  elements: BlueprintElements[],
  options: GridProps & {
    background?: 'none' | 'primary' | 'secondary' | 'contrast'
  },
): BlueprintGridElement => ({
  type,
  elements,
  options,
})

export const buildGridContainerBlock = (
  elements: BlueprintElements[],
  options: GridProps & {
    background?: 'none' | 'primary' | 'secondary' | 'contrast'
  },
): BlueprintGridElement =>
  buildGridBlock('GridContainerLayout', elements, options)

export const buildGridItemBlock = (
  elements: BlueprintElements[],
  options: GridProps & {
    background?: 'none' | 'primary' | 'secondary' | 'contrast'
  },
): BlueprintGridElement => buildGridBlock('GridItemLayout', elements, options)

export const buildCardBlock = (
  elements: BlueprintElements[],
  options: CardProps,
): BlueprintCardElement => ({
  type: 'CardLayout',
  elements,
  options,
})

export const buildStackSpacingBlock = (
  elements: BlueprintElements[],
  options: SpacingProps,
): BlueprintCustomLayoutElement => ({
  type: 'StackSpacingLayout',
  elements,
  options,
})
export const buildTabBlock = (
  labels: { label: string; icons: string[] }[],
  scope: string,
): BlueprintTabControlElement => ({
  type: 'Control',
  labels: labels?.map(({ label }) => label),
  scope: `#/properties/${scope}`,
  options: {
    tabs: true,
    values: labels?.map(({ label, icons }) => ({
      value: label,
      icons: icons.map((icon) => ({
        icon,
        fontSize: 'medium',
        orientation: 'leading-right',
      })),
    })),
  },
})
export const buildInputControlBlock = (
  scope: string,
  label: string,
): BlueprintControlElement => ({
  type: 'Control',
  scope: `#/properties/${scope}`,
  label: label || ' ',
})

export const buildProductTileControl = (
  scope: string,
  label: string,
): BlueprintControlElement => ({
  type: 'Control',
  scope: `#/properties/${scope}`,
  label,
  options: {
    product: true,
    uiType: 'ProductTile',
    actionButtonLabel: 'in der Auswählen',
  },
})

export const buildEnumControlBlock = (
  scope: string,
  toggle = false,
): BlueprintControlElement => ({
  type: 'Control',
  scope: `#/properties/${scope}`,
  options: {
    toggle,
  },
})

export const buildBooleanControlBlock = (
  scope: string,
  label: string,
  options?: {
    clearPathOnChange?: string[]
    typographyVariant?: TypographyVariant
  },
): BlueprintControlElement => ({
  type: 'Control',
  scope: `#/properties/${scope}`,
  label,
  options,
})

export const buildRadioControlBlock = (
  scope: string,
  label: string,
  options?: {
    clearPathOnChange?: string[]
    optionsLabels?: string[]
  },
): BlueprintControlElement => ({
  type: 'Control',
  scope: `#/properties/${scope}`,
  label,
  options: {
    radio: true,
    ...options,
  },
})

export const buildSignatureControlBlock = (
  scope: string,
  label: string,
  penColor?: string,
  scale?: number,
): BlueprintControlElement => ({
  type: 'Control',
  scope: `#/properties/${scope}`,
  label,
  options: {
    digital_signature: true,
    penColor: penColor || 'black',
    scale: scale || 2,
  },
})
export const buildOverviewScreenshotControlBlock = (
  scope: string,
  label: string,
  layersForOverviewScreenshot: number,
  penColor?: string,
  scale?: number,
): BlueprintControlElement => ({
  type: 'Control',
  scope: `#/properties/${scope}`,
  label,
  options: {
    format: 'overviewScreenshot',
    penColor: penColor || 'black',
    scale: scale || 2,
    deep: layersForOverviewScreenshot,
  },
})

export const buildPreviousProviderControlBlock = (
  scope: string,
  label: string,
  previousProviderEndpoint: string,
): BlueprintControlElement => ({
  type: 'Control',
  scope: `#/properties/${scope}`,
  label,
  options: {
    previous_provider: true,
    acceptOnlySuggestedProviders: true,
    previousProviderEndpoint,
  },
})

export const buildIbanControlBlock = (
  scope: string,
  label: string,
  ibanValidationEndpoint: string,
  bicLabel?: string,
  bankNameLabel?: string,
): BlueprintControlElement => ({
  type: 'Control',
  scope: `#/properties/${scope}`,
  label,
  options: {
    iban_complex: true,
    bicLabel,
    bankNameLabel,
    apiBaseUrl: ibanValidationEndpoint,
  },
})

export const buildMeterReadingControlBlock = (
  scope: string,
  options: MeterReadingRendererOptions,
): BlueprintCustomControlElement => {
  const {
    title = '',
    showMeterNumber = true,
    showMarketLocationId = true,
    showMeterReading = true,
    showAddButton = true,
    showAsSummary = false,
  } = options

  return {
    type: 'MeterReading',
    scope: `#/properties/${scope}`,
    options: {
      title,
      showMeterNumber,
      showMarketLocationId,
      showMeterReading,
      showAddButton,
      showAsSummary,
    },
  }
}

export const buildConsumptionCalculatorControl = (
  scope: string,
  options: Omit<ConsumptionCalculatorProps, 'options' | 'iconComponent'> & {
    icon?: string
  },
): BlueprintCustomControlElement => ({
  type: 'ConsumptionCalculatorControl',
  scope: `#/properties/${scope}`,
  options,
})

export const buildAddressControl = (
  scope: string,
  options?: AddressModuleProps,
): BlueprintCustomControlElement => {
  return {
    type: 'AddressControl',
    scope: `#/properties/${scope}`,
    options,
  }
}

export const buildLabelBlock = (
  text: string,
  options?: { [key: string]: any },
): BlueprintLabelElement => ({
  type: 'Label',
  text,
  options,
})

export const buildH1Block = (
  text: string,
  options?: { [key: string]: any },
): BlueprintLabelElement => buildLabelBlock(text, { ...options, variant: 'h1' })

export const buildH2Block = (
  text: string,
  options?: { [key: string]: any },
): BlueprintLabelElement => buildLabelBlock(text, { ...options, variant: 'h2' })

export const buildH3Block = (
  text: string,
  options?: { [key: string]: any },
): BlueprintLabelElement => buildLabelBlock(text, { ...options, variant: 'h3' })

export const includeEffect = (
  control: BlueprintElements | BlueprintControlElement,
  effectScope: string,
  effect: 'HIDE' | 'SHOW' | 'ENABLE' | 'DISABLE',
  effectScopeConditionValue?: any,
  effectScopeConditionEnumValue?: Array<string>,
): BlueprintElements => {
  return {
    ...control,
    rule: {
      condition: {
        schema: {
          ...(effectScopeConditionValue !== undefined && {
            const: effectScopeConditionValue,
          }),
          ...(effectScopeConditionEnumValue && {
            enum: effectScopeConditionEnumValue,
          }),
        },
        scope: `#/properties/${effectScope}`,
      },
      effect,
    },
  }
}

export const includeEffectMultipleConditions = (
  control: BlueprintElements | BlueprintControlElement,
  scopesConditions: { [key: string]: boolean | string },
  effect: 'HIDE' | 'SHOW' | 'ENABLE' | 'DISABLE',
): BlueprintElements => {
  const conditions: any[] = []

  Object.keys(scopesConditions).forEach((scope) => {
    const condition = {
      type: 'object',
      properties: {
        [scope]: {
          const: scopesConditions[scope],
        },
      },
      required: [scope],
    }

    conditions.push(condition)
  })

  return {
    ...control,
    rule: {
      condition: {
        scope: `#`,
        schema: {
          allOf: conditions,
        },
      },
      effect,
    },
  }
}

export const includeDisableEffect = (
  control: BlueprintElements,
  disableScope: string,
  disableScopeConditionValue: boolean | string,
): BlueprintElements =>
  includeEffect(control, disableScope, 'DISABLE', disableScopeConditionValue)

export const includeEnableEffect = (
  control: BlueprintElements,
  enableScope: string,
  effectScopeConditionValue: boolean | string,
): BlueprintElements =>
  includeEffect(control, enableScope, 'ENABLE', effectScopeConditionValue)

export const includeHideEffect = (
  control: BlueprintElements,
  hideScope: string,
  hideScopeConditionValue: boolean | string,
): BlueprintElements =>
  includeEffect(control, hideScope, 'HIDE', hideScopeConditionValue)

export const includeShowEffect = (
  control: BlueprintElements,
  showScope: string,
  showScopeConditionValue?: boolean | string,
  showEffectScopeConditionEnumValue?: Array<string>,
): BlueprintElements =>
  includeEffect(
    control,
    showScope,
    'SHOW',
    showScopeConditionValue,
    showEffectScopeConditionEnumValue,
  )

export const buildNextButton = (
  label: string,
  options?: { [key: string]: any },
  scope?: string,
): BlueprintElements => ({
  type: 'Button',
  scope,
  label,
  options,
})
