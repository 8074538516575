import { LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

import ButtonLayout from './button-layout'

const ButtonLayoutRenderer = (props: LayoutProps) => {
  return <ButtonLayout {...props} />
}

export default withJsonFormsLayoutProps(ButtonLayoutRenderer)

export const buttonLayoutRendererTester: RankedTester = rankWith(
  9,
  uiTypeIs('Button'),
)
