import { BlueprintStep } from 'types/blueprint-config'

import {
  paymentSchemaProperties,
  paymentSchemaRequired,
} from '../schemas/payment.schema'
import { buildPaymentInformationBlock } from '../uischemas/payment.uischema'

export const paymentInformation: BlueprintStep = {
  id: 'payment_information',
  title: 'Payment information',
  nextButtonLabel: 'Weiter',
  schema: {
    type: 'object',
    properties: {
      ...paymentSchemaProperties,
    },
    ...paymentSchemaRequired,
  },
  uischema: {
    type: 'StepperNavigation',
    options: {
      steps: [
        'personal_information',
        'contract_cancellation',
        'payment_information',
        'summary',
      ],
    },
    elements: [
      {
        type: 'GridContainerLayout',
        options: {
          spacing: 6,
        },
        elements: [
          {
            type: 'GridItemLayout',
            options: {
              xs: 12,
            },
            elements: [
              {
                type: 'Label',
                text: 'Zahlungsart',
                options: {
                  variant: 'h1',
                },
              },
            ],
          },
          {
            type: 'GridItemLayout',
            options: { xs: 12, md: 7 },
            elements: [
              {
                type: 'GridContainerLayout',
                options: {
                  spacing: 6,
                },
                elements: [
                  {
                    type: 'GridItemLayout',
                    options: {
                      xs: 12,
                    },
                    elements: [
                      {
                        type: 'CardLayout',
                        elements: [
                          {
                            type: 'StepContent',
                            options: {
                              headline: 'Zahlungsdetails',
                              headlineCaption: '* Benötigte Angaben',
                              showNextButtonInDesktop: true,
                            },
                            elements: [
                              {
                                ...buildPaymentInformationBlock(
                                  'Hiermit ermächtige ich die epilot GmbH, Im Mediapark 8a, 50670 Köln (Gläubiger-Identifikationsnummer: DEXXX), fällige Beträge von meinem Konto wiederkehrend mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der epilot GmbH auf mein Konto gezogenen Lastschriften einzulösen. Den Nachweis des SEPA-Lastschriftmandates gegenüber der Bank erbringt die epilot GmbH. Dieses Mandat gilt für alle bestehenden und zukünftigen Verträge, die unter der für diesen Vertrag bereits existierenden oder neu zu vergebenden Vertragskontonummer geführt werden. Hinweis: Innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, kann die Erstattung des Lastschriftbetrages vom Kontoinhaber verlangt werden. Es gelten dabei die mit dem Kreditinstitut vereinbarten Bedingungen. Zugleich weise ich mein Kreditinstitut an, die von der epilot GmbH auf mein Konto gezogenen Lastschriften einzulösen.',
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'GridItemLayout',
            options: { xs: 12, md: 5 },
            elements: [
              {
                type: 'Cart',
              },
            ],
          },
          {
            type: 'GridItemLayout',
            options: {
              xs: 12,
            },
            elements: [
              {
                type: 'ForwardSummaryTile',
                options: {
                  mobileOnly: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  resultData: {
    payment_method: 'sepa',
  },
  logic: {
    nextStepId: 'summary',
  },
}
