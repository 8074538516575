import { Components } from '@epilot/submission-client'
import axios, { AxiosResponse } from 'axios'
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry'

import { WidgetConfig, WidgetId } from 'types/widget-config'

import config from '../../config'

import { BodyResults, PresignedUrlReq } from './types'

// check if Network Error, else use axios-retry default mechanism
// required as isNetworkOrIdempotentRequestError does not detect "Network Error"
function simpleNetworkErrorCheck(error: Error) {
  if (!!(error as any).isAxiosError && error.message === 'Network Error') {
    return true
  } else {
    return isNetworkOrIdempotentRequestError(error)
  }
}

// intercepts axios calls
// retries failed calls like Network Errors, etc.. Will not retry if no need to retry such as with a 400 response
axiosRetry(axios, {
  retryCondition: simpleNetworkErrorCheck,
  retries: 5,
  retryDelay: () => 1000,
})

export const apiCall = (
  method: 'GET' | 'POST' | 'PUT',
  url: string,
  data?: Record<string, unknown>,
  headers: Record<string, unknown> = {},
): Promise<AxiosResponse<any>> => {
  return axios({
    method,
    url,
    data,
    headers: headers,
  })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
      console.error(data)
      throw error
    })
}

export const apiCallErrorHandling = async (
  method: 'GET' | 'POST' | 'PUT',
  url: string,
  data?: Record<string, unknown>,
  options: Record<string, unknown> = {},
) => {
  let result

  try {
    const response = await axios({
      method,
      url,
      data,
      ...options,
    })

    result = response.data
  } catch (error) {
    result = error.response || error
  }

  return result
}

export const postResults = (data: Record<string, unknown>) => {
  return apiCall('POST', config.API_RESULTS, data, {
    'Content-Type': 'application/x-www-form-urlencoded',
  })
}

export const postResultsV3 = (data: Record<string, unknown>) => {
  return apiCall('POST', config.API_RESULTS_V3, data, {
    'Content-Type': 'application/json',
  })
}

export const postResultsV4 = (data: BodyResults) => {
  return apiCall('POST', config.API_RESULTS_V4, data, {
    'Content-Type': 'application/json',
  })
}

export const postSubmissionApiV1 = (
  data: Components.Schemas.SubmissionPayload,
) => {
  return apiCall('POST', config.SUBMISSION_API_V1, data as any, {
    'Content-Type': 'application/json',
  })
}

export const postPresignedPutUrls = (data: PresignedUrlReq) => {
  return apiCall('POST', config.API_RESULTS_PRESIGNED_URLS_V4, data, {
    'Content-Type': 'application/json',
  })
}

export const putUploadPresignedUrl = (
  data: Buffer,
  presignedUrl: string,
  contentType: string,
) => {
  return apiCall('PUT', presignedUrl, data as any, {
    'Content-Type': contentType,
    'Content-Encoding': 'base64',
  })
}

export const getWidgetConfiguration = (
  widgetId: WidgetId,
): Promise<WidgetConfig> => {
  return axios
    .get(`${config.API_CONFIGURATION}/${widgetId}`)
    .then((response) =>
      response.data && response.data.data
        ? response.data.data.widgetConfiguration
        : response.data,
    )
}
