import { TypographyProps } from '@epilot/components'
import { LabelElement, OwnPropsOfRenderer } from '@jsonforms/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getAllResultData } from 'modules/step/selectors'

import { parseTemplate } from '../../../lib'

type LabelComponent = {
  component: React.FC<TypographyProps>
}

const useStyles = makeStyles(() =>
  createStyles({
    labelRoot: {
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      hyphens: 'auto',
    },
  }),
)

/**
 * Label HOC provider.
 */
const Label = ({
  component,
  ...props
}: OwnPropsOfRenderer & LabelComponent): React.ReactElement => {
  const classes = useStyles()
  const { visible, uischema } = props
  const labelElement: LabelElement = uischema as LabelElement
  const TypographyComponent = component
  const options = labelElement?.options
  const gutterBottom = options?.gutterBottom || true
  const style = options?.style
  const color = options?.color
  const data = useSelector(getAllResultData)
  const [text, setText] = useState(labelElement?.text || '')

  useEffect(() => {
    const scope = uischema?.options?.scope

    if (data[scope])
      setText(
        parseTemplate(text, {
          [scope]: data[scope],
        }),
      )
  }, [data, text, uischema])

  if (!visible) return <></>

  if (uischema?.options?.template) {
    const text = parseTemplate(labelElement?.text, uischema.options.template)

    return (
      <TypographyComponent
        customColor={color}
        dangerouslySetInnerHTML={{ __html: text }}
        gutterBottom={gutterBottom}
        style={style}
      />
    )
  }

  return (
    <TypographyComponent
      className={classes.labelRoot}
      customColor={color}
      gutterBottom={gutterBottom}
      style={style}
    >
      {text}
    </TypographyComponent>
  )
}

export default Label
