import { BodyText, LargeText } from '@epilot/components/dist'
import React from 'react'

import { ReactComponent as BroadbandIcon } from 'assets/icons/broadband.svg'
import { ReactComponent as GasIcon } from 'assets/icons/gas.svg'
import { ReactComponent as PowerIcon } from 'assets/icons/power.svg'
import { SellingOption } from 'lib/api.types'
import { ProductCategory } from 'modules/shop/types'

import { CircleCheckIconStyled } from '../product-details/product-details-styled'

import ProductFootnoteStyled from './product-footnote-styled'
import ProductHeaderStyled, {
  TariffDetailsBulletStyled,
  TariffDetailsStyled,
} from './product-header-styled'
import ProductPrices from './product-prices'

export type ProductHeaderProps = {
  prices: SellingOption
  title: string
  category: ProductCategory
  withDetails?: boolean
  withIcon?: boolean
  tariffDetails?: boolean
  tariffDetailsTitle?: string
  tariffDetailsText?: string[]
  showFootnote?: boolean
  footnote?: string
}

const ProductHeader = ({
  title,
  prices,
  category,
  tariffDetails = false,
  tariffDetailsTitle = '',
  tariffDetailsText = [],
  withDetails = true,
  withIcon = true,
  showFootnote,
  footnote,
}: ProductHeaderProps): JSX.Element => {
  let icon = <></>

  switch (category) {
    case 'POWER':
      icon = <PowerIcon color="primary" />
      break
    case 'GAS':
      icon = <GasIcon color="primary" />
      break
    case 'BROADBAND':
      icon = <BroadbandIcon color="primary" />
      break
    default:
      break
  }

  const tariffDetailsWithBullets = tariffDetailsText
    ?.map((text, index) => {
      return text ? (
        <TariffDetailsBulletStyled key={index}>
          <CircleCheckIconStyled /> <p>{text}</p>
        </TariffDetailsBulletStyled>
      ) : null
    })
    .filter((element) => element !== null)

  return (
    <>
      <ProductHeaderStyled
        tariffDetails={tariffDetails}
        withDetails={withDetails}
      >
        {withDetails && withIcon && icon}
        {tariffDetails ? <LargeText>{title}</LargeText> : <p>{title}</p>}
        {tariffDetails && (
          <TariffDetailsStyled>
            {tariffDetailsTitle && <BodyText>{tariffDetailsTitle}:</BodyText>}
            {tariffDetailsWithBullets}
          </TariffDetailsStyled>
        )}
        <ProductPrices
          prices={prices}
          variant={withDetails ? 'default' : 'bigger'}
        />
      </ProductHeaderStyled>
      {showFootnote && footnote && (
        <ProductFootnoteStyled withDetails={withDetails}>
          {footnote}
        </ProductFootnoteStyled>
      )}
    </>
  )
}

export default ProductHeader
