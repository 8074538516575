import {
  UploadPanelControl,
  UploadPanelControlTester,
} from '@epilot/json-renderers'
import { JsonFormsCore } from '@jsonforms/core'
import { JsonForms } from '@jsonforms/react'
import React from 'react'

import createRenderer from 'components/renderer'
import { Step } from 'modules/step/types'

const epilotRenderer = [
  ...createRenderer(),
  {
    tester: UploadPanelControlTester,
    renderer: UploadPanelControl,
  },
]

type StepHandlerJsonProps = {
  step: Step
  handleChange: ({
    data,
    errors,
  }: Pick<JsonFormsCore, 'data' | 'errors'>) => void
}

const StepHandlerJson = ({ step, handleChange }: StepHandlerJsonProps) => {
  return (
    <JsonForms
      data={step.resultData}
      key={step.id}
      onChange={handleChange}
      renderers={epilotRenderer}
      schema={step.schema}
      uischema={step.uischema}
    />
  )
}

export default StepHandlerJson
