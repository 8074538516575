import { datadogRum } from '@datadog/browser-rum'
import { produce } from 'immer'

import { Action, ActionHandlers, ErrorAction, TypedAction } from 'modules/types'

import { WidgetConfig } from '../../types/widget-config'
import { Step } from '../step/types'

import {
  AppState,
  JourneyDataInjectionParams,
  SubmitError,
  WidgetCookiesConsentParams,
} from './types'

/**
 * Action Constants
 */
export const INIT_APP = 'APP/INIT_APP'
export const INIT_APP_SUCCESS = 'APP/INIT_APP_SUCCESS'
export const INIT_APP_FAILURE = 'APP/INIT_APP_FAILURE'
export const INIT_SUBSCRIPTIONS_IFRAME = 'APP/INIT_SUBSCRIPTIONS_IFRAME'

export const LOAD_CONFIGURATION = 'APP/LOAD_CONFIGURATION'
export const LOAD_CONFIGURATION_SUCCESS = 'APP/LOAD_CONFIGURATION_SUCCESS'
export const LOAD_CONFIGURATION_FAILURE = 'APP/LOAD_CONFIGURATION_FAILURE'

export const SET_SUBMITTING = 'APP/SET_SUBMITTING'
export const SET_SUBMIT_ERROR = 'APP/SET_SUBMIT_ERROR'

export const START_SUBSCRIPTION_IFRAME_MESSAGES =
  'APP/START_SUBSCRIBE_IFRAME_MESSAGES'
export const STOP_SUBSCRIPTION_IFRAME_MESSAGES =
  'APP/STOP_SUBSCRIBE_IFRAME_MESSAGES'
export const IFRAME_MESSAGE = 'APP/IFRAME_MESSAGE'
export const HANDLE_PARAMS = 'APP/HANDLE_PARAMS'
export const HANDLE_COOKIES_CONSENT_PARAMS = 'APP/HANDLE_COOKIES_CONSENT_PARAMS'
export const HANDLE_JOURNEY_DATA_INJECTION = 'APP/HANDLE_JOURNEY_DATA_INJECTION'

export const ENTER_FULLSCREEN = 'APP/ENTER_FULLSCREEN'
export const EXIT_FULLSCREEN = 'APP/EXIT_FULLSCREEN'

export const RESET_GLOBAL = 'APP/RESET_GLOBAL'
export const SUBMIT_SUCCESS = 'APP/SUBMIT_SUCCESS'
export const SUBMIT_ERROR = 'APP/SUBMIT_ERROR'

export const PREVIEW_STEP = 'APP/PREVIEW_STEP'

const initialState: AppState = {
  isInitialized: false,
  isLoading: true,
  error: false,
  styling: {},
  meta: null,
  results: {
    originalDataKey: 'fields',
    payloadMapping: {},
  },
  isFullscreen: false,
  initialConfig: null,
  isSubmitting: false,
  submitError: '',
  injectedSettings: {},
  injectedData: {},
}

// Reducer default & handler
export default function reducer(
  state = initialState,
  action: TypedAction<unknown>,
): AppState {
  const handler = action && ACTION_HANDLERS[action.type]

  return handler
    ? produce(state, (draft: AppState) => handler(draft, action.payload))
    : state
}

/**
 * Main Reducer
 */
const ACTION_HANDLERS: ActionHandlers<AppState, any> = {
  [INIT_APP]: (
    draft: AppState,
    { locationSearch }: { locationSearch: string },
  ) => {
    if (locationSearch) {
      const searchParams = new URLSearchParams(locationSearch)

      draft.filter_label = searchParams.get('filter_label') || undefined
    }
  },
  [HANDLE_PARAMS]: (draft: AppState, params: Record<string, unknown>) => {
    if (params['filter_label'])
      draft.filter_label = params['filter_label'] as string
  },
  [HANDLE_JOURNEY_DATA_INJECTION]: (
    draft: AppState,
    payload: { params: JourneyDataInjectionParams },
  ) => {
    const params = payload.params

    datadogRum.addAction('Injected Data received', {
      params,
    })
    if (params['settings']) draft.injectedSettings = params['settings']
    if (params['data']) draft.injectedData = params['data']
  },
  [INIT_APP_SUCCESS]: (draft: AppState) => {
    draft.isInitialized = true
  },
  [LOAD_CONFIGURATION]: (draft: AppState) => {
    draft.isLoading = true
  },
  [SET_SUBMITTING]: (draft: AppState, payload = false) => {
    draft.isSubmitting = payload
    if (payload) draft.submitError = ''
  },
  [SET_SUBMIT_ERROR]: (draft: AppState, payload: SubmitError = '') => {
    draft.isSubmitting = false
    draft.submitError = payload
  },
  [LOAD_CONFIGURATION_SUCCESS]: (
    draft: AppState,
    { config }: { config: WidgetConfig },
  ) => {
    const {
      version,
      initialStepId,
      widgetId,
      organizationId,
      name,
      builder,
    } = config
    const brandId = builder?.brand_id
    const isNumberTypeBrandId = typeof brandId === 'number'

    draft.meta = config.meta
      ? config.meta
      : {
          version,
          initialStepId,
          widgetId,
          organizationId,
          name,
          brandId: isNumberTypeBrandId ? brandId.toString() : brandId,
          email_template_id: builder?.email_template_id,
          unavailability_email_template_id:
            builder?.unavailability_email_template_id,
          error_email_template_id: builder?.error_email_template_id,
          enable_send_email: builder?.enable_send_email,
        }

    if (config.styling) {
      draft.styling = config.styling
    }

    if (config.results) {
      draft.results = config.results
    }

    // backup config
    draft.initialConfig = config

    draft.isLoading = false
  },
  [LOAD_CONFIGURATION_FAILURE]: (draft: AppState) => {
    draft.error = true
    draft.isLoading = false
  },
  [ENTER_FULLSCREEN]: (draft: AppState) => {
    draft.isFullscreen = true
  },
  [EXIT_FULLSCREEN]: (draft: AppState) => {
    draft.isFullscreen = false
  },
  [RESET_GLOBAL]: (draft: AppState) => {
    const config = draft.initialConfig

    return { ...initialState, initialConfig: config }
  },
  [PREVIEW_STEP]: (draft: AppState, { step }: { step: Step }) => {
    if (draft.meta) draft.meta.initialStepId = step.id
    if (draft.initialConfig) draft.initialConfig.steps = [step]
  },
}

/**
 * ACTIONS
 */
export const initApp = (
  locationSearch: string,
): TypedAction<{ locationSearch: string }> => ({
  type: INIT_APP,
  payload: { locationSearch },
})
export const initSubscription = (): Action => ({
  type: INIT_SUBSCRIPTIONS_IFRAME,
})
export const initAppSuccess = (): Action => ({ type: INIT_APP_SUCCESS })
export const initAppFailure = (error: Error): ErrorAction => ({
  type: INIT_APP_FAILURE,
  payload: { error },
})

export const loadConfiguration = (): Action => ({ type: LOAD_CONFIGURATION })
export const loadConfigurationSuccess = (
  config: WidgetConfig,
  injectedResultsData?: Record<string, any>,
): TypedAction<
  { config: WidgetConfig } & { injectedResultsData?: Record<string, any> }
> => ({
  type: LOAD_CONFIGURATION_SUCCESS,
  payload: { config, injectedResultsData },
})
export const loadConfigurationFailure = (error: Error): ErrorAction => ({
  type: LOAD_CONFIGURATION_FAILURE,
  payload: { error },
})

export const setSubmitting = (payload = false): TypedAction<boolean> => ({
  type: SET_SUBMITTING,
  payload,
})

export const setSubmitError = (
  payload: SubmitError = '',
): TypedAction<SubmitError> => ({
  type: SET_SUBMIT_ERROR,
  payload,
})

export const startSubscriptionIframeMessage = (): Action => ({
  type: START_SUBSCRIPTION_IFRAME_MESSAGES,
})
export const stopSubscriptionIframeMessage = (): Action => ({
  type: STOP_SUBSCRIPTION_IFRAME_MESSAGES,
})
export const iframeMessage = (
  message: Record<string, unknown>,
): TypedAction<{ message: Record<string, unknown> }> => ({
  type: IFRAME_MESSAGE,
  payload: { message },
})
export const handleParams = (params: Record<string, unknown>) => ({
  type: HANDLE_PARAMS,
  payload: { params },
})

export const handleCookiesConsentParams = (
  params: WidgetCookiesConsentParams,
) => ({
  type: HANDLE_COOKIES_CONSENT_PARAMS,
  payload: { params },
})

export const handleJourneyDataInjection = (
  params: JourneyDataInjectionParams,
) => ({
  type: HANDLE_JOURNEY_DATA_INJECTION,
  payload: { params },
})

export const enterFullscreen = (): Action => ({
  type: ENTER_FULLSCREEN,
})

export const exitFullscreen = (): Action => ({
  type: EXIT_FULLSCREEN,
})

export const submitSuccess = (): Action => ({
  type: SUBMIT_SUCCESS,
})

export const submitError = (): Action => ({
  type: SUBMIT_ERROR,
})

export const resetGlobal = (): Action => ({
  type: RESET_GLOBAL,
})

export const previewStep = (step: unknown) => ({
  type: PREVIEW_STEP,
  payload: { step },
})
