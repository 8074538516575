import { BlueprintStep } from 'types/blueprint-config'

import {
  contractCancellationSchemaGasProperties,
  contractCancellationSchemaRequiredGas,
} from '../schemas/contract-cancellation.schema'
import { buildContractCancellationBlock } from '../uischemas/contract-cancellation-uischema'

export const contractCancellationGas: BlueprintStep = {
  id: 'contract_cancellation_gas',
  title: 'Contract cancellation',
  nextButtonLabel: 'Weiter',
  schema: {
    type: 'object',
    properties: {
      ...contractCancellationSchemaGasProperties,
    },
    ...contractCancellationSchemaRequiredGas(),
  },
  uischema: {
    type: 'StepperNavigation',
    options: {
      steps: [
        'personal_information',
        'contract_cancellation_gas',
        'payment_information',
        'summary',
      ],
    },
    elements: [
      {
        type: 'GridContainerLayout',
        options: {
          spacing: 6,
        },
        elements: [
          {
            type: 'GridItemLayout',
            options: {
              xs: 12,
            },
            elements: [
              {
                type: 'Label',
                text: 'Vertragsinformationen',
                options: {
                  variant: 'h1',
                },
              },
            ],
          },
          {
            type: 'GridItemLayout',
            options: { xs: 12, md: 7 },
            elements: [
              {
                type: 'GridContainerLayout',
                options: {
                  spacing: 6,
                },
                elements: [
                  {
                    type: 'GridItemLayout',
                    options: {
                      xs: 12,
                    },
                    elements: [
                      {
                        type: 'CardLayout',
                        elements: [
                          {
                            type: 'StepContent',
                            options: {
                              headline: 'Vertragsdetails Gas',
                              headlineCaption: '* Benötigte Angaben',
                              showNextButtonInDesktop: true,
                            },
                            elements: [
                              {
                                ...buildContractCancellationBlock({
                                  category: 'GAS',
                                }),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'GridItemLayout',
            options: { xs: 12, md: 5 },
            elements: [
              {
                type: 'Cart',
              },
            ],
          },
          {
            type: 'GridItemLayout',
            options: {
              xs: 12,
            },
            elements: [
              {
                type: 'ForwardSummaryTile',
                options: {
                  mobileOnly: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  resultData: { delivery_date_asap_gas: false },
  logic: {
    nextStepId: 'payment_information',
  },
}
