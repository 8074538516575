const invalidDateMessage = 'Ungültiges Datum.'

export const validateDate = (
  day: string | number,
  month: string | number,
  year: string | number,
): string => {
  if (!day && !month && !year) return ''

  const date = new Date(+year, +month - 1, +day)

  const isValid = Boolean(+date) && date.getDate() === +day

  return !isValid ? invalidDateMessage : ''
}
