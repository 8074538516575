import { Box } from '@epilot/base-elements'
import { Autocomplete, MuiHidden, styled } from '@epilot/components'
import {
  and,
  ControlProps,
  isEnumControl,
  optionIs,
  OwnPropsOfEnum,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsEnumProps } from '@jsonforms/react'
import React from 'react'
import { useSelector } from 'react-redux'

import { generateStringLabel } from 'lib/is-required-scope'
import { getShowErrors } from 'modules/step/selectors'

import { getHtmlId, isRequiredScope } from '../../../lib'
import { useOverwritePlaceholderStyles } from '../styles'

const AutocompleteHelper = styled.div`
  min-width: 150px;
  margin: -12px 0px 8px 0px;
  color: #f44336;
  font-size: 0.75rem;
`

const AutocompleteControl = ({
  id,
  data,
  label,
  visible,
  handleChange,
  path,
  uischema,
  options: enumOptions,
  errors,
  rootSchema,
}: ControlProps & OwnPropsOfEnum & { showErrors?: typeof getShowErrors }) => {
  // jsonforms changed options type from any[] to EnumOption[] in version 2.5.0
  // instead of having an array of values now we have an array with an object: { value: string, label: string }
  const options = enumOptions?.map(({ value }) => value)
  const classes = useOverwritePlaceholderStyles()
  const htmlId = getHtmlId(id)
  const isRequired = isRequiredScope(rootSchema, uischema.scope)
  const stringLabel = generateStringLabel(label as string, isRequired)

  const showErrors = useSelector(getShowErrors)

  const defaultOption = uischema?.options?.defaultOption
  const defaultValue =
    defaultOption > 0 && options ? options[defaultOption - 1] : ''

  if (!options) return null

  return (
    <MuiHidden xsUp={!visible}>
      <Box className={classes.inputOverwrite}>
        <Autocomplete
          error={showErrors && errors.length > 0}
          getOptionLabel={(option) => option}
          id={htmlId}
          label={stringLabel}
          name={htmlId}
          noOptionsText={uischema?.options?.noOptionsText}
          onChange={(_, value) => handleChange(path, value)}
          options={options?.map((value) => value || '')}
          placeholder={uischema?.options?.placeholder}
          value={data || defaultValue || ''}
        />
        {showErrors && <AutocompleteHelper>{errors}</AutocompleteHelper>}
      </Box>
    </MuiHidden>
  )
}

export default withJsonFormsEnumProps(AutocompleteControl)

export const autocompleteTester: RankedTester = rankWith(
  99,
  and(isEnumControl, optionIs('format', 'autocomplete')),
)
