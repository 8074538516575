import { MuiHidden, Radio } from '@epilot/components'
import {
  CombinatorProps,
  createCombinatorRenderInfos,
  isOneOfControl,
  JsonSchema,
  RankedTester,
  rankWith,
  resolveSubSchemas,
  UISchemaElement,
} from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsOneOfProps } from '@jsonforms/react'
import React, { useState } from 'react'

import ItemWrapper from './item-wrapper-styled'
import Wrapper from './wrapper-styled'

const OneOfRenderer = ({
  schema,
  rootSchema,
  indexOfFittingSchema,
  visible,
  path,
  renderers,
  uischema,
  uischemas,
  handleChange,
}: CombinatorProps) => {
  const [selectedOneOf, setSelectedOneOf] = useState(indexOfFittingSchema || 0)

  if (!schema) throw new Error('No schema in Props')
  if (!uischema) throw new Error('No uischema in Props')
  const _schema = resolveSubSchemas(schema, rootSchema, 'oneOf') as JsonSchema

  if (!_schema || !_schema.oneOf) throw new Error('Not a oneOf schema')

  const oneOfRenderInfos = createCombinatorRenderInfos(
    _schema.oneOf,
    rootSchema,
    'oneOf',
    uischema,
    path,
    uischemas,
  )

  const realPath = path.substr(0, path.length - 5)
  const inputName = uischema.options?.name ?? 'oneOf'

  const options = Object.entries(
    ((uischema as unknown) as { labels: Record<string, unknown> }).labels,
  ).map(([key, value]) => ({
    value: key,
    label: (value as Record<string, unknown>).label,
    uischema: (value as Record<string, unknown>).uischema,
  }))

  const selectedOption = options[selectedOneOf]

  return (
    <MuiHidden xsUp={!visible}>
      <Wrapper>
        {options.map((option, index) => (
          <ItemWrapper
            key={option.value}
            selected={selectedOption.value === option.value}
          >
            <Radio
              checked={selectedOption.value === option.value}
              label={option.label as string}
              name={inputName}
              onChange={() => {
                setSelectedOneOf(index)
                handleChange(inputName, option.value)
              }}
              selected={selectedOption.value}
              value={option.value}
            />
            {selectedOption.value === option.value && (
              <div className="sub-form">
                <JsonFormsDispatch
                  path={realPath}
                  renderers={renderers}
                  schema={oneOfRenderInfos[index].schema}
                  uischema={option.uischema as UISchemaElement | undefined}
                />
              </div>
            )}
          </ItemWrapper>
        ))}
      </Wrapper>
    </MuiHidden>
  )
}

export const oneOfControlTester: RankedTester = rankWith(99, isOneOfControl)
export default withJsonFormsOneOfProps(OneOfRenderer)
