import React from 'react'

import config from 'config'
import { Product as ProductType } from 'modules/shop/types'

import ProductBonusBoxStyled from './product-bonusbox-styled'
import ProductBonusTextStyled from './product-bonustext-styled'
import ProductDetails from './product-details'
import ProductHeader from './product-header'
import ProductStyled from './product-styled'

export type ProductProps = {
  product: ProductType
  selectedIds: string
  onSelectedChange: (value: string) => void
  open: boolean
  withDetails?: boolean
  tariffDetails?: boolean
  showBonus?: boolean
  showFootnote?: boolean
}

const Product = ({
  product,
  open,
  selectedIds,
  onSelectedChange,
  withDetails = true,
  tariffDetails = false,
  showBonus = false,
  showFootnote = false,
}: ProductProps): JSX.Element => {
  const active = selectedIds?.split(';').includes(product.id)
  const {
    'Titel Tarifmerkmal': tariffDetailsTitle,
    'Tarifmerkmal 01': tariffDetails1,
    'Tarifmerkmal 02': tariffDetails2,
    'Tarifmerkmal 03': tariffDetails3,
    'Tarifmerkmal 04': tariffDetails4,
    'Tarifmerkmal 05': tariffDetails5,
    'Tarifmerkmal 06': tariffDetails6,
    'Tarifmerkmal 07': tariffDetails7,
  } = product.customFields
  const productImage = product.image?.src
  const tariffDetailsText = [
    tariffDetails1,
    tariffDetails2,
    tariffDetails3,
    tariffDetails4,
    tariffDetails5,
    tariffDetails6,
    tariffDetails7,
  ]

  const bonusData = {
    title: product.customFields['Bonus Titel'],
    description: product.customFields['Bonus Detail Info'],
  }

  const footnote = product.customFields['Fußnote']

  return (
    <ProductStyled
      isActive={active}
      onClick={() => onSelectedChange(product.id)}
      withDetails={withDetails}
    >
      <div className="img-container">
        {showBonus && bonusData && bonusData.title && (
          <ProductBonusBoxStyled>{bonusData.title}</ProductBonusBoxStyled>
        )}
        {showBonus && bonusData && bonusData.title && bonusData.description && (
          <ProductBonusTextStyled>
            {bonusData.description}
          </ProductBonusTextStyled>
        )}
        {productImage && (
          <img alt={product.name} src={config.IMAGE_HOST + productImage} />
        )}
      </div>
      <ProductHeader
        category={product.productCategory}
        footnote={footnote}
        prices={product.price}
        showFootnote={showFootnote}
        tariffDetails={tariffDetails}
        tariffDetailsText={tariffDetailsText}
        tariffDetailsTitle={tariffDetailsTitle}
        title={product.name}
        withDetails={withDetails}
        withIcon={!productImage}
      />
      <ProductDetails
        description={product.description}
        isActive={active || false}
        open={open && withDetails}
        selectButtonLabel={active ? 'Ausgewählt' : 'Auswählen'}
      />
    </ProductStyled>
  )
}

export default Product
