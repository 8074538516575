import { DigitalSignature } from '@epilot/components'
import {
  ControlProps,
  optionIs,
  OwnPropsOfEnum,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsEnumProps } from '@jsonforms/react'
import html2canvas from 'html2canvas'
import React from 'react'

const SignatureControl = ({
  handleChange,
  path,
  uischema,
}: ControlProps & OwnPropsOfEnum) => {
  if (!uischema?.options) return null
  const clearText = uischema?.options?.clearText
  const closeText = uischema?.options?.closeText
  const saveText = uischema?.options?.saveText
  const penColor = uischema?.options?.penColor

  const handleStoreSignature = () => {
    const screenshotElement = document.getElementById('signature')

    let area = screenshotElement
    const deep = uischema.options?.deep
    let maxHeight = 0
    let screenshotElementCopy = screenshotElement

    for (let i = 0; i < deep; i++) {
      const parent = area?.parentElement

      if (parent) {
        area = parent as HTMLElement
        const areaHeight = (area as HTMLElement).offsetHeight

        if (areaHeight > maxHeight) {
          maxHeight = areaHeight
        }
      } else {
        area = null
        console.log('Screenshot root not find!')
        break
      }
    }

    while (screenshotElementCopy != null) {
      screenshotElementCopy.scrollTop = 0
      screenshotElementCopy = screenshotElementCopy.parentElement
    }

    if (area) {
      const svgElements = area.querySelectorAll('svg')
      const pElements = area.querySelectorAll('p')

      if (svgElements) {
        for (let i = 0; i < svgElements.length; i++) {
          const curSvg = svgElements[i]
          const width = curSvg.width.baseVal.value
          const height = curSvg.height.baseVal.value

          width && curSvg.setAttribute('width', width.toString())
          height && curSvg.setAttribute('height', height.toString())
        }
      }

      if (pElements) {
        for (let i = 0; i < pElements.length; i++) {
          const curP = pElements[i]
          const width = curP.offsetWidth
          const height = curP.offsetHeight + 5

          curP.setAttribute(
            'style',
            'width:' +
              (width.toString() + 'px;height:' + height.toString() + 'px'),
          )
        }
      }

      screenshotElement && (screenshotElement.style.display = 'none')

      html2canvas(area as HTMLElement, {
        allowTaint: true,
        height: maxHeight,
      })
        .then((canvas) => {
          const dataURL = canvas.toDataURL('image/png')

          return dataURL
        })
        .then((dataURL) => {
          let screenshotPack = {}
          const screenshotStringLength =
            dataURL?.length - 'data:image/png;base64,'.length

          const screenshotSizeInBytes =
            4 * Math.ceil(screenshotStringLength / 3) * 0.5624896334383812

          screenshotPack = {
            file_type: 'png',
            file_size: screenshotSizeInBytes,
            file_name: 'screenshot.png',
            base64: dataURL,
          }

          handleChange(path, screenshotPack)
        })

      screenshotElement && (screenshotElement.style.display = 'initial')
    }

    if (!uischema?.options) return null
  }

  return (
    <div>
      <div id="signature"></div>
      <DigitalSignature
        clearText={clearText}
        closeText={closeText}
        handleStoreData={() => {
          setTimeout(() => handleStoreSignature(), 10)
        }}
        penColor={penColor}
        saveText={saveText}
      ></DigitalSignature>
    </div>
  )
}

export default withJsonFormsEnumProps(SignatureControl)

export const signatureTester: RankedTester = rankWith(
  10,
  optionIs('format', 'signature'),
)
