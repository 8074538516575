import {
  Addon,
  BodyResults as RequestBody,
  CartItemReq,
  S3File,
  VariableModel,
} from 'lib/api/types'
import { dataCleanBaseCondition } from 'lib/dataCleanBaseCondition'
import { MetaData } from 'modules/app/types'
import { ShopState } from 'modules/shop/types'
import { PayloadResults } from 'modules/step/getResultsFromMapping/types'
import { getCartItems } from 'modules/step/helper/utils/getDataFromMapping/getCartItems'

import { dataAddonsCreate } from './dataAddonsCreate'
import { checkCustomerType, dataContactsCreate } from './dataContactsCreate'
import { dataOpportunitiesCreate } from './dataOpportunitiesCreate'
import { setSignatureNaming } from './dataOpportunitiesCreate/setSignatureName'
import { variableModelsAdd } from './dataOpportunitiesCreate/variableModelsAdd'

export const getResultsFromMapping = (
  data: PayloadResults,
  meta: MetaData,

  shop: ShopState,
  signature: S3File | undefined,
  builder: Record<string, any> | undefined,
  journeySubmitId: string,
  emailTemplate: string | undefined,
  fallbackProductType: string | undefined,
): RequestBody => {
  const results = dataCleanBaseCondition(data) as PayloadResults
  const { organizationId, widgetId } = meta
  const { deliveryContact, billingContact } = dataContactsCreate(results)
  const consumptions = []
  const variableModels: VariableModel = variableModelsAdd(results)

  if (results.power_consumption) {
    consumptions.push({
      category: 'POWER' as const,
      value: results.power_consumption.amount,
    })
  }

  if (results.gas_consumption) {
    consumptions.push({
      category: 'GAS' as const,
      value: results.gas_consumption.amount,
    })
  }

  const cart: CartItemReq[] = getCartItems(shop, { consumptions })

  const addons: Addon[] = dataAddonsCreate(shop)

  const opportunities = dataOpportunitiesCreate(
    cart,
    results,
    meta,
    builder,
    emailTemplate,
    fallbackProductType,
  )

  // add signature to all opportunities
  opportunities.map((o) => {
    o.user_screenshot = setSignatureNaming(signature, results)
  })

  const requestBody: RequestBody = {
    organization_id: organizationId,
    description: 'Created by a Widget request',
    widget_id: widgetId,
    customer_type: checkCustomerType(results),
    delivery_contact: deliveryContact,
    billing_contact: billingContact,
    cart,
    addons,
    different_billing_address: !!results.different_billing_address,
    variableModels,
    opportunities,
    ...(results.payment_method && {
      payment_details: {
        payment_type: results.payment_method,
        bank_account_owner: results?.account_owner,
        iban: results.sepa?.iban,
        bic: results.sepa?.bic_number,
        bank_name: results.sepa?.bank_name,
      },
    }),
    journeySubmitId,
  }

  return requestBody
}
