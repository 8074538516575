import { styled } from '@epilot/components'

import { Theme } from '../../../modules/app/types'

type ProductBonusTextStyledProps = {
  theme: Theme
}

const ProductBonusTextStyled = styled.span`
  color: white;
  font-size: 11px;
  background-color: ${(props: ProductBonusTextStyledProps) =>
    props.theme.palette.primary.main};
  position: absolute;
  opacity: 0.8;
  bottom: 0;
  padding: 5px;
  padding-left: 15px;
  width: 100%;
  display: flex;
  text-align: left;
  align-items: center;
`

export default ProductBonusTextStyled
