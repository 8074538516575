import { BodyText, SmallText } from '@epilot/components/dist'
import React from 'react'
import { useSelector } from 'react-redux'

import { SellingOption } from 'lib/api.types'
import { formatAsCent, formatCurrency } from 'lib/format-currency'
import { getProductPricingBusiness } from 'modules/shop/selectors'

import { ProductDetailsPricesStyled } from './product-details-styled'

type ProductDetailsPricesProps = {
  price: SellingOption
  showTotalAnnualPrice: boolean
  showAnnualBasePrice: boolean
  taxInformation?: string
}

const ProductDetailsPrices = ({
  price,
  showTotalAnnualPrice,
  showAnnualBasePrice,
  taxInformation,
}: ProductDetailsPricesProps): JSX.Element => {
  const pricingBusiness = useSelector(getProductPricingBusiness)
  const recurringDuration = price.duration?.split('M')[0] || '12'
  const estimatedPricePerMonth = pricingBusiness
    ? price?.estimated_monthly_price?.amount_net
      ? formatCurrency(price.estimated_monthly_price.amount_net)
      : undefined
    : price?.estimated_monthly_price?.amount_gross
    ? formatCurrency(price.estimated_monthly_price.amount_gross)
    : undefined
  const estimatedPricePerYear = pricingBusiness
    ? price?.estimated_yearly_price?.amount_net
      ? formatCurrency(price.estimated_yearly_price.amount_net)
      : undefined
    : price?.estimated_yearly_price?.amount_gross
    ? formatCurrency(price.estimated_yearly_price.amount_gross)
    : undefined
  const variablePrice = pricingBusiness
    ? price?.calculated_consumption_price?.amount_net
      ? formatAsCent(price.calculated_consumption_price.amount_net)
      : undefined
    : price?.calculated_consumption_price?.amount_gross
    ? formatAsCent(price.calculated_consumption_price.amount_gross)
    : undefined
  const basePricePerMonth = pricingBusiness
    ? price?.recurring_price?.monthly?.amount_net
      ? formatCurrency(price.recurring_price.monthly.amount_net)
      : price?.recurring_price?.yearly?.amount_net
      ? formatCurrency(
          price.recurring_price.yearly.amount_net / parseInt(recurringDuration),
        )
      : undefined
    : price?.recurring_price?.monthly?.amount_gross
    ? formatCurrency(price.recurring_price.monthly.amount_gross)
    : price?.recurring_price?.yearly?.amount_gross
    ? formatCurrency(
        price.recurring_price.yearly.amount_gross / parseInt(recurringDuration),
      )
    : undefined
  const basePricePerYear = pricingBusiness
    ? price?.recurring_price?.yearly?.amount_net
      ? formatCurrency(price.recurring_price.yearly.amount_net)
      : undefined
    : price?.recurring_price?.yearly?.amount_gross
    ? formatCurrency(price.recurring_price.yearly.amount_gross)
    : undefined

  return (
    <ProductDetailsPricesStyled>
      {taxInformation && (
        <div>
          <SmallText>{taxInformation}</SmallText>
        </div>
      )}
      <div>
        <div className="prices-table">
          {estimatedPricePerMonth && (
            <div style={{ alignItems: 'center' }}>
              <BodyText style={{ fontWeight: 600, fontSize: '18px' }}>
                Voraussichtlicher Abschlag pro Monat
              </BodyText>
              <BodyText style={{ fontWeight: 600, fontSize: '30px' }}>
                {estimatedPricePerMonth}
              </BodyText>
            </div>
          )}
          {variablePrice && (
            <div>
              <BodyText>Arbeitspreis je kWh</BodyText>
              <span>{variablePrice}</span>
            </div>
          )}
          {basePricePerMonth && (
            <div>
              <BodyText>Grundpreis pro Monat</BodyText>
              <span>{basePricePerMonth}</span>
            </div>
          )}
          {showTotalAnnualPrice && estimatedPricePerYear && (
            <div>
              <BodyText>Gesamtpreis pro Jahr</BodyText>
              <span>{estimatedPricePerYear}</span>
            </div>
          )}
          {showAnnualBasePrice && basePricePerYear && (
            <div>
              <BodyText>Gesamtgrundpreis pro Jahr</BodyText>
              <span>{basePricePerYear}</span>
            </div>
          )}
        </div>
      </div>
    </ProductDetailsPricesStyled>
  )
}

export default ProductDetailsPrices
