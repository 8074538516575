import {
  BodyText,
  Card,
  CardContent,
  Grid,
  H2,
  Icon,
  InlineSpacing,
  StackSpacing,
} from '@epilot/base-elements'
import React from 'react'

type ProductDetailFeaturesProps = {
  title?: string
  features?: string[]
}

const ProductDetailFeatures = ({
  title,
  features,
}: ProductDetailFeaturesProps): JSX.Element => {
  const mapBulletText = (text: string, index: number) => {
    return text ? (
      <InlineSpacing key={index} scale={2}>
        <Icon color="primary" name="check" />
        <BodyText>{text}</BodyText>
      </InlineSpacing>
    ) : null
  }
  const firstPart = features
    ?.filter((_, i) => i <= Math.floor(features.length / 2))
    .map(mapBulletText)
    .filter((element) => element !== null)
  const secondPart = features
    ?.filter((_, i) => i > Math.floor(features.length / 2))
    .map(mapBulletText)
    .filter((element) => element !== null)

  return firstPart && firstPart.concat(secondPart || []).length > 0 ? (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <StackSpacing scale={4}>
            {title && <H2 color="textPrimary">{title}</H2>}
            {features && features?.length > 0 && (
              <Grid container>
                {firstPart && firstPart?.length > 0 && (
                  <Grid item md={6} xs={12}>
                    {firstPart}
                  </Grid>
                )}
                {secondPart && secondPart?.length > 0 && (
                  <Grid item md={6} xs={12}>
                    {secondPart}
                  </Grid>
                )}
              </Grid>
            )}
          </StackSpacing>
        </CardContent>
      </Card>
    </Grid>
  ) : (
    <></>
  )
}

export default ProductDetailFeatures
