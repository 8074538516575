import { variableModelsAdd } from 'modules/step/getResultsFromMapping/dataOpportunitiesCreate/variableModelsAdd'

import { BodyResults } from '../../../../lib/api/types'
import { ProductCategory, ProductType } from '../../../shop/types'
import { contractTypeFind } from '../../getResultsFromMapping/dataOpportunitiesCreate/contractTypeFind'
import { GlobalResultData } from '../../types'
import {
  getSalutationFromLabel,
  getTitleFromLabel,
} from '../utils/getDataFromMapping/getMappedTitleAndSautation'

import {
  addMissingBillingAddressSuffix,
  addMissingDeliveryAddressSuffix,
} from './fixMissingAddressSuffix'
import { fixedCustomFields, fixedQuestions } from './fixMissingLabel'
import {
  checkMissingInstallerCustomFieldsForCOM,
  checkMissingInstallerQuestionsForCOM,
  generateListCustomFields,
} from './installations_mapper'
import { createAddonsSchema, createCartItemsSchema } from './product_mapper'

export const getResponseFromMappingV4 = (
  data: GlobalResultData,
  journeySubmitId: string,
): BodyResults => {
  const product_type = (typeof data?.fields?.product_category === 'string'
    ? data?.fields?.product_category.toLowerCase()
    : (undefined as unknown)) as ProductType | undefined

  if (!product_type) {
    throw Error(
      'mapping to Results API v4 failed because the product_type could not be determined',
    )
  }

  const upload_files = []

  if (data.opportunity.additionalFields?.upload_files) {
    upload_files.push(...data.opportunity.additionalFields.upload_files)
  }

  if (data.opportunity.additionalFields?.counter_relevant_upload_files) {
    upload_files.push(
      ...data.opportunity.additionalFields.counter_relevant_upload_files,
    )
  }

  const requestBody: BodyResults = {
    organization_id: data.customer.organizationId || '728',
    widget_id: data.widgetId,
    widget_version: data.widgetVersion,
    description: 'Created by a Widget request',
    title_in_opportunity: 'Opportunity from Widget',
    customer_type: data.customer.customerType || '',
    journey_type: data.journey_type || '',
    delivery_contact: {
      salutation: getSalutationFromLabel(data.customer.salutation),
      company_name: data.customer.companyName || '',
      first_name: data.customer.firstName,
      last_name: data.customer.lastName,
      ...(data.customer.birthdate && { birthday: data.customer.birthdate }),
      street_name: data.customer.deliveryAddress?.streetName || '',
      street_number: data.customer.deliveryAddress?.streetNumber || '',
      address_suffix:
        data.customer.deliveryAddress?.address_suffix ||
        addMissingDeliveryAddressSuffix(product_type, data) ||
        '',
      city: data.customer.deliveryAddress.city,
      postal_code: data.customer.deliveryAddress.postalCode,
      email: data.customer.email,
      phone: data.customer.phone,
      title: getTitleFromLabel(data.customer.title),
      country_code:
        data.fields.address_delivery_postalcode_city?.countrycode || 'DE',
    },
    billing_contact: {
      salutation:
        (data.customer.differentInvoiceName &&
          getSalutationFromLabel(data.customer.billingAddress.salutation)) ||
        getSalutationFromLabel(data.customer.salutation),
      company_name: data.customer.companyName || '',
      first_name:
        (data.customer.differentInvoiceName &&
          data.customer.billingAddress.firstName) ||
        data.customer.firstName,
      last_name:
        (data.customer.differentInvoiceName &&
          data.customer.billingAddress.lastName) ||
        data.customer.lastName,
      title:
        (data.customer.differentInvoiceName &&
          getTitleFromLabel(data.customer.billingAddress.title)) ||
        getTitleFromLabel(data.customer.title),
      ...(data.customer.birthdate && { birthday: data.customer.birthdate }),
      street_name: data.customer.billingAddress?.streetName || '',
      street_number: data.customer.billingAddress?.streetNumber || '',
      city: data.customer.billingAddress?.city,
      postal_code: data.customer.billingAddress?.postalCode,
      address_suffix:
        data.customer.billingAddress?.address_suffix ||
        addMissingBillingAddressSuffix(product_type, data) ||
        '',
      email: data.customer.email,
      phone: data.customer.phone,
      country_code:
        data.fields.address_billing_postalcode_city?.countrycode ||
        data.fields.address_delivery_postalcode_city?.countrycode ||
        'DE',
    },
    company_details: {
      register_court: data.customer.registerCourt || '',
      register_number: data.customer.registerNumber || '',
    },
    additional_contacts: data.customer.additional_contacts || {},
    ...(data.opportunity.addOns && {
      addons:
        createAddonsSchema(
          data.opportunity.addOns,
          product_type.toUpperCase() as ProductCategory,
        ) || [],
    }),
    ...(data.opportunity.additionalFields?.paymentType && {
      payment_details: {
        payment_type: data.opportunity.additionalFields?.paymentType || 'sepa',
        iban:
          data.fields.checkout_payment_sepa_iban ||
          data.fields.sepa?.iban ||
          '',
        bic:
          data.fields.checkout_payment_sepa_bic ||
          data.fields.sepa?.bic_number ||
          '',
        bank_name:
          data.fields.checkout_payment_sepa_bankname ||
          data.fields.sepa?.bank_name ||
          '',
        bank_account_owner:
          data.fields.checkout_payment_sepa_account_owner ||
          data.fields.account_owner ||
          '',
      },
    }),
    different_billing_address: data?.customer?.differentBillingAddress || false,
    variableModels: variableModelsAdd(data.fields),
    opportunities: [
      {
        product_type: product_type,
        brand_id:
          typeof data.opportunity.brand_id === 'number'
            ? data.opportunity.brand_id.toString()
            : data.opportunity.brand_id,
        delivery_date: data.opportunity.additionalFields?.deliveryData || '',
        consents: data.consents || [],
        contract_type:
          data.opportunity.additionalFields?.preExisitingContract ||
          contractTypeFind(
            data.opportunity.additionalFields?.contract_type_power,
          ),
        custom_fields: fixedCustomFields(
          checkMissingInstallerCustomFieldsForCOM(
            data,
            generateListCustomFields(data),
          ),
        ),
        email_template_id: data.opportunity?.email_template_id || '0',
        enable_send_email:
          typeof data.opportunity.enable_send_email === 'boolean'
            ? data.opportunity.enable_send_email
            : true,
        preExisitingContract:
          data.opportunity.additionalFields?.preExisitingContract,
        questions_in_opportunity: fixedQuestions(
          checkMissingInstallerQuestionsForCOM(data),
        ),
        sale_identifier_id: data.opportunity.identifier_id || data?.name,
        terminateOldContract:
          data.opportunity.additionalFields?.terminateOldContract || 'none',
        terminate_old_contract:
          data.opportunity.additionalFields?.terminateOldContract || 'none',
        // TODO: Add logic in https://e-pilot.atlassian.net/browse/ER-1355
        user_uploaded_files: upload_files,
        // user_screenshot: generateScreenshotPack(data),
      },
    ],
    cart: createCartItemsSchema(
      data.cart?.items,
      data.opportunity?.selling_option_id,
      data.cart?.summary?.interval,
      product_type.toUpperCase() as ProductCategory,
    ),
    journeySubmitId,
  }

  return requestBody
}
