import config from 'config'
import { BlueprintElements } from 'types/blueprint-config'

import {
  buildGridContainerBlock,
  buildLayoutBlock,
  buildGridItemBlock,
  buildRadioControlBlock,
  buildInputControlBlock,
  buildBooleanControlBlock,
  includeShowEffect,
  buildIbanControlBlock,
  buildStackSpacingBlock,
} from '../block-builders'
import { defaultPaymentSchemaProperties } from '../schemas/payment.schema'

const paymentInputLabels = {
  PAYMENT_METHOD_OPTIONS: ['SEPA Lastschrift', 'Überweisung'],
  ACCOUNT_OWNER: 'Kontoinhaber',
  IBAN: 'IBAN',
  BIC: 'BIC',
  BANK_NAME: 'Bankname',
}

// Build Payment Information
export const buildPaymentInformationBlock = (
  sepaConsent: string,
): BlueprintElements => {
  const gridContainerElements = []

  // Build Payment Method
  const paymentMethodControl = buildRadioControlBlock(
    defaultPaymentSchemaProperties.payment_method.name,
    '',
    {
      optionsLabels: paymentInputLabels.PAYMENT_METHOD_OPTIONS,
      clearPathOnChange: [
        defaultPaymentSchemaProperties.account_owner.name,
        defaultPaymentSchemaProperties.sepa.name,
        defaultPaymentSchemaProperties.payment_agreement.name,
      ],
    },
  )
  const paymentMethodControlGridItem = buildGridItemBlock(
    [paymentMethodControl],
    {
      xs: 12,
    },
  )

  gridContainerElements.push(paymentMethodControlGridItem)

  // Build SEPA Fields
  const sepaGridContainerElements = []

  // Build Account Owner
  const accountOwnerControl = buildInputControlBlock(
    defaultPaymentSchemaProperties.account_owner.name,
    paymentInputLabels.ACCOUNT_OWNER,
  )
  const accountOwnerControlGridItem = buildGridItemBlock(
    [accountOwnerControl],
    {
      xs: 12,
    },
  )

  sepaGridContainerElements.push(accountOwnerControlGridItem)

  // Build IBAN
  const ibanControl = buildIbanControlBlock(
    defaultPaymentSchemaProperties.sepa.name,
    paymentInputLabels.IBAN,
    config.API_IBAN_VALIDATION,
    paymentInputLabels.BIC,
    paymentInputLabels.BANK_NAME,
  )
  const ibanControlStackSpacing = buildStackSpacingBlock([ibanControl], {
    scale: 4,
  })
  const ibanControlGridItem = buildGridItemBlock([ibanControlStackSpacing], {
    xs: 12,
  })

  sepaGridContainerElements.push(ibanControlGridItem)

  // Build Payment Agreement
  const paymentAgreementControl = buildBooleanControlBlock(
    defaultPaymentSchemaProperties.payment_agreement.name,
    sepaConsent,
    { typographyVariant: 'caption' },
  )
  const paymentAgreementControlGridItem = buildGridItemBlock(
    [paymentAgreementControl],
    {
      xs: 12,
    },
  )

  sepaGridContainerElements.push(paymentAgreementControlGridItem)

  // Show/hide SEPA Fields
  const sepaGridContainer = includeShowEffect(
    buildGridContainerBlock(sepaGridContainerElements, {
      spacing: 3,
    }),
    defaultPaymentSchemaProperties.payment_method.name,
    'sepa',
  )

  gridContainerElements.push(
    buildGridItemBlock([sepaGridContainer], { xs: 12 }),
  )

  // Build Container
  const gridContainer = buildGridContainerBlock(gridContainerElements, {
    spacing: 3,
    alignItems: 'flex-end',
  })

  const uischema = buildLayoutBlock('VerticalLayout', [gridContainer])

  return uischema
}
