import { Blueprint } from '../../types/blueprint-config'

import { results } from './config/results'
import { addressInformation, thankYou } from './config/steps'
import { epilotStyling } from './config/styling'

export const ContactForm: Blueprint = {
  initialStepId: addressInformation.id,
  styling: epilotStyling,
  steps: [addressInformation, thankYou],
  results,
}
