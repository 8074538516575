import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useSelectBoxStyles = makeStyles(() => {
  return createStyles({
    selectBoxContainer: {
      display: 'flex',
      '& .MuiToggleButton-root': {
        flex: 1,
        padding: '8px', // TODO: use theme spacing,
        textTransform: 'none',
        border: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
    selectBoxLabel: {
      marginBottom: '4px',
    },
  })
})
