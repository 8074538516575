/**
 * Generate cookies consent local storage item key
 */
export const getCookiesConsentItemKey = (id: string) =>
  `epilot-cookies-consent_${id}`

/**
 * Update or create (if not exists) a local storage item
 * @param key item key
 * @param value item value to store
 * @returns persisted item
 */
export const updateLocalStorageItem = <T>(key: string, value: T) => {
  try {
    // Save to local storage
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    // Will be logged to DataDog
    console.error(error)
  }
}

/**
 * Get the local storage item value for the given key
 * @param key item key
 * @returns persisted item for the given key
 */
export const getLocalStorageItem = <T>(key: string): T => {
  let item

  try {
    // Get item from local storage
    item = window.localStorage.getItem(key)
  } catch (error) {
    // Will be logged to DataDog
    console.error(error)
  }

  return item ? JSON.parse(item) : null
}
