import { BlueprintStep } from 'types/blueprint-config'

import { defaultAddressSchemaProperties } from '../schemas/address.schema'
import {
  confirmationSchemaProperties,
  confirmationSchemaRequired,
} from '../schemas/confirmation.schema'
import { defaultConsumptionCalculatorSchemaProperties } from '../schemas/consumption-calculator.schema'
import {
  defaultContractCancellationSchemaProperties,
  SITUATION_GAS_ENUM_VALUES,
  SITUATION_POWER_ENUM_VALUES,
} from '../schemas/contract-cancellation.schema'
import { defaultPaymentSchemaProperties } from '../schemas/payment.schema'
import { defaultPersonalInformationSchemaProperties } from '../schemas/personal-information.schema'
import { buildConfirmationBlock } from '../uischemas/confirmation.uischema'
import { buildContractCancellationSummaryBlock } from '../uischemas/contract-cancellation-uischema'

export const summary: BlueprintStep = {
  id: 'summary',
  title: 'Summary',
  nextButtonLabel: 'Jetzt bestellen',
  schema: {
    type: 'object',
    properties: {
      ...confirmationSchemaProperties,
    },
    ...confirmationSchemaRequired,
  },
  uischema: {
    type: 'StepperNavigation',
    options: {
      steps: [
        'personal_information',
        'contract_cancellation',
        'payment_information',
        'summary',
      ],
    },
    elements: [
      {
        type: 'GridContainerLayout',
        options: {
          spacing: 6,
        },
        elements: [
          {
            type: 'GridItemLayout',
            options: {
              xs: 12,
            },
            elements: [
              {
                type: 'Label',
                text: 'Zusammenfassung',
                options: {
                  variant: 'h1',
                  color: 'primary',
                },
              },
            ],
          },
          {
            type: 'GridItemLayout',
            options: { xs: 12, md: 7 },
            elements: [
              {
                type: 'GridContainerLayout',
                options: {
                  spacing: 6,
                },
                elements: [
                  {
                    type: 'GridItemLayout',
                    options: { xs: 12 },
                    elements: [
                      {
                        type: 'CardLayout',
                        elements: [
                          {
                            type: 'SummaryTile',
                            options: {
                              title: 'Verbrauchsangaben',
                              stepIdLink: 'consumption_calculator',
                            },
                            elements: [
                              {
                                type: 'DataLabel',
                                text: `Strom: {{${defaultConsumptionCalculatorSchemaProperties.power_consumption.name}.amount}} kWh`,
                                rule: {
                                  condition: {
                                    schema: {
                                      const: 'Gas',
                                    },
                                    scope: `#/properties/${defaultConsumptionCalculatorSchemaProperties.selected_tab.name}`,
                                  },
                                  effect: 'HIDE',
                                },
                              },
                              {
                                type: 'DataLabel',
                                text: `Gas: {{${defaultConsumptionCalculatorSchemaProperties.gas_consumption.name}.amount}} kWh`,
                                rule: {
                                  condition: {
                                    schema: {
                                      const: 'Strom',
                                    },
                                    scope: `#/properties/${defaultConsumptionCalculatorSchemaProperties.selected_tab.name}`,
                                  },
                                  effect: 'HIDE',
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'GridItemLayout',
                    options: { xs: 12 },
                    elements: [
                      {
                        type: 'CardLayout',
                        elements: [
                          {
                            type: 'SummaryTile',
                            options: {
                              title: 'Persönliche Informationen',
                              stepIdLink: 'personal_information',
                            },
                            elements: [
                              {
                                type: 'DataLabel',
                                text: `{{${defaultPersonalInformationSchemaProperties.salutation.name}}} {{${defaultPersonalInformationSchemaProperties.title.name}}} {{${defaultPersonalInformationSchemaProperties.first_name.name}}} {{${defaultPersonalInformationSchemaProperties.last_name.name}}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `{{${defaultPersonalInformationSchemaProperties.birth_date.name}}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `{{${defaultPersonalInformationSchemaProperties.email.name}}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `{{${defaultPersonalInformationSchemaProperties.telephone.name}}}`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'GridItemLayout',
                    options: { xs: 12 },
                    elements: [
                      {
                        type: 'CardLayout',
                        elements: [
                          {
                            type: 'SummaryTile',
                            options: {
                              title: 'Lieferadresse',
                              stepIdLink: 'personal_information',
                            },
                            elements: [
                              {
                                type: 'DataLabel',
                                text: `{{${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.streetName}} {{${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.houseNumber}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `{{${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.extention}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `{{${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.zipCode}} {{${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.city}}`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'GridItemLayout',
                    options: { xs: 12 },
                    rule: {
                      condition: {
                        schema: {
                          const: true,
                        },
                        scope: `#/properties/${defaultAddressSchemaProperties.different_billing_address.name}`,
                      },
                      effect: 'SHOW',
                    },
                    elements: [
                      {
                        type: 'CardLayout',
                        elements: [
                          {
                            type: 'SummaryTile',
                            options: {
                              title: 'Rechnungsadresse',
                              stepIdLink: 'personal_information',
                            },
                            elements: [
                              {
                                type: 'DataLabel',
                                text: `{{${defaultAddressSchemaProperties.billing_address.name}.streetName}} {{${defaultAddressSchemaProperties.billing_address.name}.houseNumber}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `{{${defaultAddressSchemaProperties.billing_address.name}.extention}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `{{${defaultAddressSchemaProperties.billing_address.name}.zipCode}} {{${defaultAddressSchemaProperties.billing_address.name}.city}}`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'GridItemLayout',
                    rule: {
                      condition: {
                        schema: {
                          const: 'Gas',
                        },
                        scope: `#/properties/${defaultConsumptionCalculatorSchemaProperties.selected_tab.name}`,
                      },
                      effect: 'HIDE',
                    },
                    options: { xs: 12 },
                    elements: [
                      {
                        type: 'CardLayout',
                        elements: [
                          {
                            type: 'SummaryTile',
                            options: {
                              title: 'Vertragsdetails Strom',
                              stepIdLink: 'contract_cancellation_power',
                            },
                            elements: [
                              {
                                type: 'DataLabel',
                                text: `Situation: Versorgerwechsel`,
                                rule: {
                                  effect: 'SHOW',
                                  condition: {
                                    scope: '#/properties/situation_power',
                                    schema: {
                                      const: SITUATION_POWER_ENUM_VALUES[0],
                                    },
                                  },
                                },
                              },
                              {
                                type: 'DataLabel',
                                text: `Situation: Umzug`,
                                rule: {
                                  effect: 'SHOW',
                                  condition: {
                                    scope: '#/properties/situation_power',
                                    schema: {
                                      const: SITUATION_POWER_ENUM_VALUES[1],
                                    },
                                  },
                                },
                              },
                              {
                                type: 'DataLabel',
                                text: `Vertragskündigung: {{${defaultContractCancellationSchemaProperties.terminate_contract_power.name}}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `Gewünschter Lieferbeginn: {{${defaultContractCancellationSchemaProperties.delivery_date_power.name}}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `Gewünschter Lieferbeginn: Schnellstmöglich`,
                                rule: {
                                  effect: 'SHOW',
                                  condition: {
                                    scope:
                                      '#/properties/delivery_date_asap_power',
                                    schema: {
                                      const: true,
                                    },
                                  },
                                },
                              },
                              {
                                type: 'DataLabel',
                                text: `Bisheriger Anbieter: {{${defaultContractCancellationSchemaProperties.previous_provider_power.name}.company_name}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `Kündigungstermin: {{${defaultContractCancellationSchemaProperties.termination_date_power.name}}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `Einzugsdatum: {{${defaultContractCancellationSchemaProperties.moving_date_power.name}}}`,
                              },
                              {
                                ...buildContractCancellationSummaryBlock({
                                  category: 'POWER',
                                  showAsSummary: true,
                                }),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'GridItemLayout',
                    options: { xs: 12 },
                    rule: {
                      condition: {
                        schema: {
                          const: 'Strom',
                        },
                        scope: `#/properties/${defaultConsumptionCalculatorSchemaProperties.selected_tab.name}`,
                      },
                      effect: 'HIDE',
                    },
                    elements: [
                      {
                        type: 'CardLayout',
                        elements: [
                          {
                            type: 'SummaryTile',
                            options: {
                              title: 'Vertragsdetails Gas',
                              stepIdLink: 'contract_cancellation_gas',
                            },
                            elements: [
                              {
                                type: 'DataLabel',
                                text: `Situation: Versorgerwechsel`,
                                rule: {
                                  effect: 'SHOW',
                                  condition: {
                                    scope: '#/properties/situation_gas',
                                    schema: {
                                      const: SITUATION_GAS_ENUM_VALUES[0],
                                    },
                                  },
                                },
                              },
                              {
                                type: 'DataLabel',
                                text: `Situation: Umzug`,
                                rule: {
                                  effect: 'SHOW',
                                  condition: {
                                    scope: '#/properties/situation_gas',
                                    schema: {
                                      const: SITUATION_GAS_ENUM_VALUES[1],
                                    },
                                  },
                                },
                              },
                              {
                                type: 'DataLabel',
                                text: `Vertragskündigung: {{${defaultContractCancellationSchemaProperties.terminate_contract_gas.name}}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `Gewünschter Lieferbeginn: {{${defaultContractCancellationSchemaProperties.delivery_date_gas.name}}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `Gewünschter Lieferbeginn: Schnellstmöglich`,
                                rule: {
                                  effect: 'SHOW',
                                  condition: {
                                    scope:
                                      '#/properties/delivery_date_asap_gas',
                                    schema: {
                                      const: true,
                                    },
                                  },
                                },
                              },
                              {
                                type: 'DataLabel',
                                text: `Bisheriger Anbieter: {{${defaultContractCancellationSchemaProperties.previous_provider_gas.name}.company_name}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `Kündigungstermin: {{${defaultContractCancellationSchemaProperties.termination_date_gas.name}}}`,
                              },
                              {
                                type: 'DataLabel',
                                text: `Einzugsdatum: {{${defaultContractCancellationSchemaProperties.moving_date_gas.name}}}`,
                              },
                              {
                                ...buildContractCancellationSummaryBlock({
                                  category: 'GAS',
                                  showAsSummary: true,
                                }),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'GridItemLayout',
                    options: { xs: 12 },
                    elements: [
                      {
                        type: 'CardLayout',
                        elements: [
                          {
                            type: 'SummaryTile',
                            options: {
                              title: 'Zahlungsart',
                              stepIdLink: 'payment_information',
                            },
                            elements: [
                              {
                                type: 'DataLabel',
                                text: `{{${defaultPaymentSchemaProperties.payment_method.name}}}`,
                                options: {
                                  labels: {
                                    [defaultPaymentSchemaProperties
                                      .payment_method.name]: {
                                      sepa: 'SEPA Lastschrift',
                                      invoice: 'Überweisung',
                                    },
                                  },
                                },
                              },
                              {
                                type: 'DataLabel',
                                text: `{{${defaultPaymentSchemaProperties.account_owner.name}}}`,
                                rule: {
                                  condition: {
                                    schema: {
                                      const: 'sepa',
                                    },
                                    scope: `#/properties/${defaultPaymentSchemaProperties.payment_method.name}`,
                                  },
                                  effect: 'SHOW',
                                },
                              },
                              {
                                type: 'DataLabel',
                                text: `IBAN: {{${defaultPaymentSchemaProperties.sepa.name}.iban}}`,
                                rule: {
                                  condition: {
                                    schema: {
                                      const: 'sepa',
                                    },
                                    scope: `#/properties/${defaultPaymentSchemaProperties.payment_method.name}`,
                                  },
                                  effect: 'SHOW',
                                },
                              },
                              {
                                type: 'DataLabel',
                                text: `BIC: {{${defaultPaymentSchemaProperties.sepa.name}.bic_number}}`,
                                rule: {
                                  condition: {
                                    schema: {
                                      const: 'sepa',
                                    },
                                    scope: `#/properties/${defaultPaymentSchemaProperties.payment_method.name}`,
                                  },
                                  effect: 'SHOW',
                                },
                              },
                              {
                                type: 'DataLabel',
                                text: `Bankname: {{${defaultPaymentSchemaProperties.sepa.name}.bank_name}}`,
                                rule: {
                                  condition: {
                                    schema: {
                                      const: 'sepa',
                                    },
                                    scope: `#/properties/${defaultPaymentSchemaProperties.payment_method.name}`,
                                  },
                                  effect: 'SHOW',
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'GridItemLayout',
            options: { xs: 12, md: 5 },
            elements: [
              {
                type: 'Cart',
                options: {
                  hiddenProps: {
                    smDown: false,
                  },
                },
                elements: [
                  {
                    ...buildConfirmationBlock({ showSignature: true }),
                  },
                  {
                    type: 'Button',
                    label: 'Jetzt bestellen',
                    options: {
                      action: 'next',
                      variant: 'contained',
                      style: {
                        marginTop: '16px',
                        width: '100%',
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  resultData: {
    accept_promotions: false,
  },
  logic: {
    nextStepId: 'thank_you',
    sendResults: true,
    resultApiVersion: 'v4',
  },
}
