import React from 'react'
import { useSelector } from 'react-redux'

import { SellingOption } from 'lib/api.types'
import {
  getProductPricingBusiness,
  getProductPriceIntervalLabel,
} from 'modules/shop/selectors'

import Price from '../price/price'

import ProductPricesStyled from './product-prices-styled'

export type ProductPricesProps = {
  prices: SellingOption
  variant?: 'bigger' | 'default'
}

const ProductPrices = ({
  prices,
  variant = 'default',
}: ProductPricesProps): JSX.Element => {
  const pricingBusiness = useSelector(getProductPricingBusiness)
  const priceIntervalLabel = useSelector(getProductPriceIntervalLabel)
  const oneTimePrice = pricingBusiness
    ? prices.one_time_price?.amount_net
    : prices.one_time_price?.amount_gross
  const recurringPrice = pricingBusiness
    ? priceIntervalLabel === 'Monat'
      ? prices.estimated_monthly_price?.amount_net
      : prices.estimated_yearly_price?.amount_net
    : priceIntervalLabel === 'Monat'
    ? prices.estimated_monthly_price?.amount_gross
    : prices.estimated_yearly_price?.amount_gross

  return (
    <ProductPricesStyled variant={variant}>
      {recurringPrice && (
        <Price
          currency={
            priceIntervalLabel === 'Monat'
              ? prices.estimated_monthly_price?.currency_id
              : prices.estimated_yearly_price?.currency_id
          }
          description={
            priceIntervalLabel === 'Monat' ? 'monatlich' : 'jährlich'
          }
          highlighted={true}
          value={recurringPrice}
          variant={variant}
        />
      )}
      {oneTimePrice && (
        <Price
          currency={prices.one_time_price?.currency_id}
          description={'+einmalig'}
          small={true}
          value={oneTimePrice}
        />
      )}
    </ProductPricesStyled>
  )
}

export default ProductPrices
