import { styled } from '@epilot/components'

import { Theme } from 'modules/app/types'

const CartFooterStyled = styled.div`
  padding: 0px 8px 8px 8px;
  width: 100%;
  > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .cart-summary {
    font-weight: 600;
    color: ${(props: { theme: Theme }) => props.theme.palette.primary.main};
    line-height: 24px;
  }
  .cart-price {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: 4px;
  }
  .cart-price > p {
    font-size: 18px;
  }
  .cart-price > p > span {
    font-size: 14px;
    font-weight: 400;
  }
  .cart-price > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .cart-price > div > svg {
    margin-left: 4px;
    stroke: none;
    fill: ${(props: { theme: Theme }) => props.theme.palette.grey['400']};
  }
  .tooltip {
    margin-top: 8px;
    position: relative;
  }
  .tooltip > .tooltip-text {
    visibility: hidden;
    width: 250px;
    text-align: center;
    border-radius: 6px;
    padding: 16px 16px;
    border-radius: 4px;
    background-color: ${(props: { theme: Theme }) =>
      props.theme.palette.grey['800']};
    color: #fff;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 50%;
    margin-left: -155px;
    font-size: 12px;
  }
  .tooltip > .tooltip-text::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${(props: { theme: Theme }) =>
        props.theme.palette.grey['800']}
      transparent transparent transparent;
  }
  .tooltip:hover > .tooltip-text {
    visibility: visible;
  }
  .bonus-item-container {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    text-align: right;
    color: ${(props: { theme: Theme }) => props.theme.palette.primary.main};
    > div:nth-child(1) {
      font-weight: 600;
      font-size: 18px;
    }
    > div:nth-child(2) {
      font-weight: 400;
      font-size: 14px;
    }
  }
  @media (max-width: 935px) {
    cursor: pointer;
    .cart-total {
      font-weight: 600;
    }
  }
`

export default CartFooterStyled
