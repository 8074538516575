import { Box, H2, Hidden, StackSpacing, useTheme } from '@epilot/base-elements'
import { Pencil2 } from '@epilot/icons'
import {
  Layout,
  LayoutProps,
  RankedTester,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core'
import {
  ResolvedJsonFormsDispatch,
  withJsonFormsLayoutProps,
} from '@jsonforms/react'
import React from 'react'
import { useDispatch } from 'react-redux'

import { goToStep } from 'modules/step/reducer'

export type SummaryTileRendererOptions = {
  title?: string
  stepIdLink?: string
}

const SummaryTileRenderer = ({
  schema,
  uischema,
  enabled,
  path,
  renderers,
  visible,
}: LayoutProps): React.ReactElement | null => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const layout = uischema as Layout
  const { title, stepIdLink } = (uischema?.options ||
    {}) as SummaryTileRendererOptions
  const handleEditButtonClick = () => {
    if (stepIdLink) dispatch(goToStep(stepIdLink))
  }

  return (
    <Hidden xsUp={!visible}>
      <StackSpacing scale={4}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          {title && <H2 color="textPrimary">{title}</H2>}
          {stepIdLink && (
            <Pencil2
              fill={theme?.palette?.primary?.main}
              fontSize={theme?.typography?.h2?.fontSize || '24px'}
              onClick={handleEditButtonClick}
              style={{ cursor: 'pointer' }}
            />
          )}
        </Box>
        <StackSpacing scale={0}>
          {layout.elements.map((child, index) => (
            <ResolvedJsonFormsDispatch
              enabled={enabled}
              key={index}
              path={path}
              renderers={renderers}
              schema={schema}
              uischema={child}
            />
          ))}
        </StackSpacing>
      </StackSpacing>
    </Hidden>
  )
}

export const summaryTileTester: RankedTester = rankWith(
  1,
  uiTypeIs('SummaryTile'),
)

export default withJsonFormsLayoutProps(SummaryTileRenderer)
