import { JsonSchema } from '@jsonforms/core'

export const isRequiredScope = (
  rootSchema: JsonSchema,
  scope: string,
): boolean => {
  if (!scope) return false
  if (!rootSchema.required) return false

  const field = scope.replace('#/properties/', '')

  return rootSchema.required.includes(field)
}
export const generateStringLabel = (
  label: string,
  isRequired: boolean,
): string => {
  const isLabelToBeSpace = label !== ' '
  const stringLabel = isLabelToBeSpace ? label + (isRequired ? '*' : '') : ''

  return stringLabel
}
