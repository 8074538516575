import { styled } from '@epilot/components'

import { Theme } from '../../../modules/app/types'
type ProductDetailsStyledProps = {
  isActive: boolean
  open: boolean
  theme: Theme
}

const ProductsDetailsStyled = styled.div<ProductDetailsStyledProps>`
  align-items: center;
  border-top: 1px solid #d2dee4;
  display: flex;
  flex-direction: column;
  padding: ${({ open }: ProductDetailsStyledProps) =>
    open ? '16px 24px 24px 24px' : '24px'};

  .select-product-button {
    width: 100%;
    padding: 6px;
    border: 1px solid
      ${({ theme }: ProductDetailsStyledProps) => theme?.palette?.primary?.main} !important;
  }
  .select-product-button .MuiButton-label span {
    color: ${({ isActive, theme }: ProductDetailsStyledProps) =>
      isActive
        ? theme?.typography?.primaryColor
        : theme?.palette?.primary?.main};
  }
  .select-product-button:hover .MuiButton-label span {
    color: ${({ theme }: ProductDetailsStyledProps) =>
      theme?.typography?.primaryColor};
  }
  .select-product-button .MuiButton-label {
    height: 16px;
  }
  .MuiFormControlLabel-root .MuiTypography-root {
    font-weight: bold;
    font-size: 14px;
    font-style: normal;
    line-height: 24px;
  }
  .select-product-button:hover .MuiSvgIcon-root {
    fill: white;
  }
  .MuiSvgIcon-root {
    fill: ${({ isActive, theme }: ProductDetailsStyledProps) =>
      isActive ? 'white' : theme?.palette?.disabled?.light};
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: white;
  }
  p {
    align-self: flex-start;
    margin-top: 8px;
    margin-bottom: 0px;
  }
  .circle-text-container {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
  }
  .circle-text-container p {
    height: 24px;
    margin: 8px 8px 0px;
  }
  .circle-text-container svg {
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-top: 10px;
  }
  .text-container {
    width: 100%;
    color: ${({ theme }: ProductDetailsStyledProps) =>
      theme?.typography?.color};
  }
  .text-container > p {
    margin-top: 16px;
  }
  .button-icon {
    width: 16px;
    height: 16px;
    border: 1px solid
      ${({ isActive, theme }: ProductDetailsStyledProps) =>
        isActive ? 'white' : theme?.palette?.disabled?.light};
    border-radius: 50%;
  }
  .checkedIcon-background {
    position: relative;
    width: 16px;
    height: 16px;
    background: ${({ theme }: ProductDetailsStyledProps) =>
      theme?.palette?.background?.default || 'white'};
    border-radius: 50%;
  }
  .checkedIcon-icon {
    position: absolute;
    top: -1px;
  }
`

export default ProductsDetailsStyled
