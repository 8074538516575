import { MuiGrid, MuiHidden } from '@epilot/components'
import {
  LayoutProps,
  rankWith,
  uiTypeIs,
  VerticalLayout,
} from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'
import { useSelector } from 'react-redux'

import { getAllResultData } from 'modules/step/selectors'
import { GlobalAppState } from 'modules/types'

import { displayLogics } from './displayLogic'
const VerticalLayoutRenderer = ({
  uischema,
  renderers,
  schema,
  path,
  enabled,
  visible,
}: LayoutProps) => {
  const layout = uischema as VerticalLayout
  const style = uischema?.options?.style

  const options = uischema?.options
  const state = useSelector((state: GlobalAppState) => getAllResultData(state))
  const displayLogic = displayLogics(options, state)

  if (!displayLogic) {
    return <></>
  }

  return (
    <MuiHidden xsUp={!visible}>
      <MuiGrid container direction="column" style={style}>
        {layout.elements.map((child, index) => (
          <MuiGrid
            item
            key={`${path}-${index}`}
            style={child?.options?.elementsStyle}
            xs
          >
            <JsonFormsDispatch
              enabled={enabled}
              path={path}
              renderers={renderers}
              schema={schema}
              uischema={child}
            />
          </MuiGrid>
        ))}
      </MuiGrid>
    </MuiHidden>
  )
}

export const verticalLayoutTester = rankWith(2, uiTypeIs('VerticalLayout'))

export default withJsonFormsLayoutProps(VerticalLayoutRenderer)
