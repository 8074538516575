import { DatePicker } from '@epilot/components'
import {
  and,
  ControlProps,
  isDateControl,
  RankedTester,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'
import { useSelector } from 'react-redux'

import { generateStringLabel } from 'lib/is-required-scope'
import { getShowErrors } from 'modules/step/selectors'

import { isRequiredScope } from '../../../lib'

function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(d.getTime())
}

const DatePickerControl = ({
  label,
  data,
  uischema,
  handleChange,
  errors,
  path,
  rootSchema,
}: ControlProps) => {
  const showOnlyFutureDates = uischema?.options?.showOnlyFutureDates
  const daysInFuture = uischema?.options?.daysInFuture
  const minAbsoluteDate = uischema?.options?.minAbsoluteDate
  let maxFutureDate = uischema?.options?.maxFutureDate
  const showErrors = useSelector(getShowErrors)

  const isRequired = isRequiredScope(rootSchema, uischema.scope)
  const stringLabel = generateStringLabel(label as string, isRequired)

  let minDate = undefined

  if (minAbsoluteDate) {
    minDate = new Date(minAbsoluteDate)
  } else if (daysInFuture) {
    minDate = new Date()
    minDate.setDate(minDate.getDate() + daysInFuture)
  } else if (showOnlyFutureDates) {
    minDate = new Date()
  }
  let maxDate = undefined

  const currentDate = new Date()

  if (maxFutureDate) {
    maxFutureDate = maxFutureDate.toLowerCase()
    if (maxFutureDate.includes('m')) {
      const res = parseInt(maxFutureDate.split('m')[0])

      maxDate = new Date(currentDate.setMonth(currentDate.getMonth() + res))
    } else if (maxFutureDate.includes('d')) {
      const res = parseInt(maxFutureDate.split('d')[0])

      maxDate = new Date(currentDate.setDate(currentDate.getDate() + res))
    }
  }

  const additionalErrorProps: { error?: boolean; helperText?: string } = {}

  if (showErrors) {
    additionalErrorProps.error = showErrors
    additionalErrorProps.helperText = errors
  }

  return (
    <DatePicker
      cancelLabel="Schließen"
      emptyLabel="__.__.____"
      error={showErrors && errors.length > 0}
      format="dd.MM.yyyy"
      helperText={showErrors && errors}
      invalidDateMessage="Ungültiges Datumsformat"
      label={stringLabel}
      maxDate={maxDate}
      maxDateMessage="Das Datum sollte nicht nach dem Höchstdatum liegen"
      minDate={minDate}
      minDateMessage="Das Datum sollte nicht vor dem Mindestdatum liegen"
      onChange={(value, textValue) => {
        if (!isValidDate(value as any)) {
          handleChange(path, textValue || undefined)
        } else if (!value) {
          handleChange(path, '')
        } else if (typeof value.getMonth === 'function') {
          // is date
          try {
            const date = value as Date

            // adjust UTC offset
            date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
            handleChange(path, date.toISOString().substring(0, 10))
          } catch {
            handleChange(path, value)
          }
        } else {
          handleChange(path, value)
        }
      }}
      value={typeof data === 'undefined' ? null : data}
    />
  )
}

export default withJsonFormsControlProps(DatePickerControl)

export const datepickerControlTester: RankedTester = rankWith(
  999,
  and(uiTypeIs('Control'), isDateControl),
)
