import { Button } from '@epilot/components'
import {
  LayoutProps,
  RankedTester,
  rankWith,
  UISchemaElement,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'
import { useDispatch } from 'react-redux'

import { exitFullscreen } from 'modules/app/reducer'
import { goNext, goPrevious } from 'modules/step/reducer'

type ButtonControlProps = {
  goNext?: typeof goNext
  goPrevious?: typeof goPrevious
  exitFullscreen?: typeof exitFullscreen
  uischema: UISchemaElement & {
    label?: string
  }
}

const ButtonControl = ({ uischema }: LayoutProps & ButtonControlProps) => {
  const dispatch = useDispatch()

  const { label, options } = uischema

  const hanleClick = () => {
    const href = options?.href

    switch (options?.action) {
      case 'next':
        dispatch(goNext())
        break
      case 'previous':
        dispatch(goPrevious())
        break
      case 'close':
        dispatch(exitFullscreen())
        break
      case 'link':
        if (href) {
          const newTabLink = window.open(href, '_blank')

          newTabLink?.focus()
        }
        break
      default:
        new Error('ButtonControl is expecting an action')
    }
  }

  return (
    <Button
      color="primary"
      data-component="button"
      data-key="next"
      onClick={hanleClick}
      size="large"
      style={options?.style}
      variant={options?.variant}
    >
      {label as any}
    </Button>
  )
}

export default withJsonFormsLayoutProps(ButtonControl)

export const buttonRendererTester: RankedTester = rankWith(
  9,
  uiTypeIs('Button'),
)
