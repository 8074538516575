import { CircleUpIcon, CircleDownIcon } from '@epilot/components'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import config from 'config'
import { getTheme } from 'modules/app/selectors'
import { getCartWithDetails } from 'modules/shop/selectors'
import { Addon as AddonType } from 'modules/shop/types'

import AddonDetails from './addon-details'
import AddonHeader from './addon-header'
import AddonStyled from './addon-styled'

export type AddonProps = {
  addon: AddonType
  active: boolean
  handleAdd: (id: number, quantity?: number) => void
  handleRemove: (id: number) => void
  singleSelect?: boolean
  withDetails?: boolean
}

const Addon = ({
  addon,
  active,
  handleAdd,
  handleRemove,
  singleSelect,
  withDetails = true,
}: AddonProps): JSX.Element => {
  const theme = useSelector(getTheme)
  const cartItems = useSelector(getCartWithDetails)
  const [open, setOpen] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const minQuantity = addon.start_range || 0
  const maxQuantity = addon.end_range || 0
  const addonImage = addon.images?.[0]?.src

  useEffect(() => {
    const firstProductAddonsInCart = cartItems[0]?.addons

    if (firstProductAddonsInCart) {
      const addonInCart = firstProductAddonsInCart?.find(
        (addonInCart) => addonInCart.id === addon.id.toString(),
      )

      if (addonInCart) {
        setQuantity(addonInCart.quantity || 1)
      }
    }
  }, [cartItems, addon.id])

  const handleChangeQuantity = (
    e: React.MouseEvent<HTMLButtonElement>,
    value: number,
  ) => {
    e.stopPropagation()
    setQuantity((previousQuantity) => {
      const nextQuantity = previousQuantity + value

      if (nextQuantity === 0 || nextQuantity === minQuantity - 1) {
        handleRemove(addon.id)

        return previousQuantity
      }

      if (maxQuantity === 0 || nextQuantity <= maxQuantity) {
        handleAdd(addon.id, nextQuantity)

        return nextQuantity
      } else {
        return previousQuantity
      }
    })
  }

  return (
    <AddonStyled
      isActive={active}
      isOpened={open}
      onClick={($event: React.MouseEvent<HTMLElement>) => {
        $event.preventDefault()
        if (active) {
          handleRemove(addon.id)
        } else {
          handleAdd(addon.id, quantity)
        }
      }}
      withDetails={withDetails}
    >
      {addonImage && (
        <img alt={addon.name} src={config.IMAGE_HOST + addonImage} />
      )}
      <AddonHeader
        prices={addon.options[0]}
        title={addon.name}
        withDetails={withDetails}
        withIcon={!addonImage}
      />
      {withDetails && (
        <div className="circle-icon">
          {open ? (
            <CircleDownIcon
              color={theme?.palette?.primary?.main}
              onClick={(e) => {
                e.stopPropagation()
                setOpen(!open)
              }}
            />
          ) : (
            <CircleUpIcon
              color={theme?.palette?.primary?.main}
              onClick={(e) => {
                e.stopPropagation()
                setOpen(!open)
              }}
            />
          )}
        </div>
      )}
      <AddonDetails
        description={addon.description}
        handleChangeQuantity={handleChangeQuantity}
        isActive={active || false}
        open={open && withDetails}
        quantity={quantity}
        selectButtonLabel={active ? 'Ausgewählt' : 'Auswählen'}
        singleSelect={singleSelect}
        withQuantity={typeof addon.start_range === 'number'}
      />
    </AddonStyled>
  )
}

export default Addon
