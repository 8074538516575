import { Hidden, Typography } from '@epilot/base-elements'
import {
  MeterReading,
  MeterReadingItemProps,
  Reading,
} from '@epilot/base-modules'
import { ControlProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { getShowErrors } from 'modules/step/selectors'

export type MeterReadingRendererOptions = {
  title?: string
  showMeterNumber?: boolean
  meterNumberRequired?: boolean
  showMarketLocationId?: boolean
  marketLocationIdRequired?: boolean
  showMeterReading?: boolean
  meterReadingRequired?: boolean
  showAddButton?: boolean
  showAsSummary?: boolean
}

type ReadingType = {
  meterNumber?: string
  marketLocationId?: string
  meterReading?: string
}

const MeterReadingRenderer = ({
  uischema,
  data = [],
  handleChange,
  path,
  errors,
  required,
  visible,
}: ControlProps): React.ReactElement | null => {
  const showErrors = useSelector(getShowErrors)
  // Destructure props from config
  const {
    title = '',
    showMarketLocationId = true,
    marketLocationIdRequired = false,
    showMeterNumber = true,
    meterNumberRequired = false,
    showMeterReading = true,
    meterReadingRequired = false,
    showAddButton = true,
    showAsSummary = false,
    ...rest
  } = (uischema?.options || {}) as MeterReadingRendererOptions

  const [lastReadings, setLastReadings] = useState([...data])

  const handleUpdateMeterReading = (reading: ReadingType, index: number) => {
    const newReadings = [...lastReadings]

    newReadings[index] = { ...reading }
    setLastReadings(newReadings)

    // If any meter number is missing, set as undefined
    if (required) {
      for (const r of newReadings) {
        if (meterNumberRequired && r && !r.meterNumber) {
          handleChange(path, undefined)

          return
        }
        if (meterReadingRequired && r && !r.meterReading) {
          handleChange(path, undefined)

          return
        }
        if (marketLocationIdRequired && r && !r.marketLocationId) {
          handleChange(path, undefined)

          return
        }
      }
    }

    handleChange(path, newReadings)
  }

  const handleDeleteMeterReading = (
    _: MeterReadingItemProps,
    index: number,
  ) => {
    const currentReadings = [...data]

    if (data[index]) {
      handleChange(
        path,
        currentReadings.filter((_, i) => i !== index),
      )
    }
  }

  if (showAsSummary) {
    return (
      <Hidden xsUp={!visible}>
        {data.map((read: Reading, index: number) => {
          const auxIndex = index + 1

          return (
            <React.Fragment key={index}>
              <Typography variant="body1" {...rest}>
                {'Zählernummer ' + auxIndex + ': ' + read.meterNumber}
              </Typography>
              <Typography variant="body1" {...rest}>
                {'Zählerstand ' + auxIndex + ': ' + read.meterReading + ' kWh'}
              </Typography>
            </React.Fragment>
          )
        })}
      </Hidden>
    )
  } else {
    return (
      <Hidden xsUp={!visible}>
        <MeterReading
          headingTitle={title}
          isMaLoIdRequired={marketLocationIdRequired}
          isMeterNumberRequired={meterNumberRequired}
          isMeterReadingRequired={meterReadingRequired}
          meterNumberError={showErrors && errors.length > 0}
          onDeleteMeterReading={handleDeleteMeterReading}
          onUpdateMeterReading={handleUpdateMeterReading}
          readings={data}
          showAddButton={showAddButton}
          showMarketLocationId={showMarketLocationId}
          showMeterNumber={showMeterNumber}
          showMeterReading={showMeterReading}
        />
      </Hidden>
    )
  }
}

export const meterReadingTester: RankedTester = rankWith(
  2,
  uiTypeIs('MeterReading'),
)

export default withJsonFormsControlProps(MeterReadingRenderer)
