import { CircleCheckIcon, styled, ThemeProps } from '@epilot/components/dist'

import { Theme } from 'modules/app/types'

type ProductDetailsTitleStyledProps = {
  theme: Theme
  withImage: boolean
}

export const ProductDetailsTitleStyled = styled.div`
  display: flex;
  padding-bottom: ${({ theme }: ProductDetailsTitleStyledProps) =>
    theme.spacing(5)}px;

  > div {
    flex-grow: 1;
  }
  > div:first-child {
    ${({ theme, withImage }: ProductDetailsTitleStyledProps) =>
      withImage && `padding-right: ${theme.spacing(2)}px`}
  }
  > div:last-child {
    ${({ theme, withImage }: ProductDetailsTitleStyledProps) =>
      withImage && `padding-left: ${theme.spacing(2)}px;`}
  }
  > div > img {
    border-radius: ${({ theme }: ProductDetailsTitleStyledProps) =>
      theme.shape.borderRadius};
    max-width: 100%;
  }
  .MuiTypography-h1 {
    font-size: 40px;
    line-height: 40px;
  }
  .MuiTypography-h2 {
    margin-top: ${({ theme }: ProductDetailsTitleStyledProps) =>
      theme.spacing(3)}px;
  }
  .short-description {
    margin-top: ${({ theme }: ProductDetailsTitleStyledProps) =>
      theme.spacing(1)}px;
  }
  .tariff-details {
    display: flex;
  }
  .tariff-details > div {
    margin-top: ${({ theme }: ProductDetailsTitleStyledProps) =>
      theme.spacing(1)}px;
  }
  @media (max-width: 751px) {
    flex-direction: column;

    > div {
      flex-basis: 100%;
    }
    > div:first-child {
      ${({ withImage }: ProductDetailsTitleStyledProps) =>
        withImage && `padding-right: 0`}
    }
    > div:last-child {
      ${({ theme, withImage }: ProductDetailsTitleStyledProps) =>
        withImage && `padding: 0; padding-top: ${theme.spacing(3)}px;`}
    }
  }
`

export const CircleCheckIconStyled = styled(CircleCheckIcon).attrs(
  ({ theme }: { theme: ThemeProps }) => ({
    color: theme.palette.primary.main,
  }),
)``

export const BulletTextStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }: { theme: ThemeProps }) => theme.spacing(1)}px;

  p {
    margin: 0 ${({ theme }: { theme: ThemeProps }) => theme.spacing(1)}px;
  }
  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
`

export const SecondaryTitleDescriptionStyled = styled.div`
  border-top: 1px solid
    ${({ theme }: { theme: ThemeProps }) => theme.palette.disabled.light};
  padding: ${({ theme }: { theme: ThemeProps }) => theme.spacing(5)}px 0;

  & .MuiTypography-h2 {
    margin-bottom: ${({ theme }: { theme: ThemeProps }) => theme.spacing(3)}px;
  }
  > div {
    display: flex;

    > div {
      flex-basis: 50%;
    }
  }
  @media (max-width: 751px) {
    > div {
      flex-direction: column;

      > div {
        flex-basis: 100%;
      }
    }
  }
`

export const ProductDetailsPricesStyled = styled.div`
  border-top: 1px solid
    ${({ theme }: { theme: ThemeProps }) => theme.palette.disabled.light};
  display: flex;
  padding: ${({ theme }: { theme: ThemeProps }) => theme.spacing(5)}px 0;

  .prices-table {
    display: flex;
    border-bottom: 0;
    border-radius: ${({ theme }: { theme: ThemeProps }) =>
      theme.shape.borderRadius};
    flex-direction: column;
  }
  .prices-table > div {
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }: { theme: ThemeProps }) => theme.spacing(1)}px
      ${({ theme }: { theme: ThemeProps }) => theme.spacing(1)}px;
  }
  .prices-table div *:nth-child(1) {
    flex-basis: 70%;
  }
  .prices-table div *:nth-child(2) {
    flex-basis: 30%;
    text-align: right;
  }
  > div {
    flex-basis: 50%;
    padding: 0 ${({ theme }: { theme: ThemeProps }) => theme.spacing(1)}px;
  }
  @media (max-width: 751px) {
    flex-direction: column-reverse;
    > div {
      flex-basis: 100%;
    }

    .prices-table {
      margin-bottom: ${({ theme }: { theme: ThemeProps }) =>
        theme.spacing(3)}px;
    }
  }
`
