import { JsonFormsRendererRegistryEntry } from '@jsonforms/core'

import * as addons from './addons'
import * as autocomplete from './autocomplete'
import * as button from './button'
import * as checkbox from './checkbox'
import * as datepicker from './datepicker'
import * as expandedPostalCode from './expanded-postalcode'
import * as iban from './iban'
import * as icon from './icon'
import * as iconRadio from './icon-radio'
import * as input from './input'
import * as installationsPerformance from './installations-module'
import * as labels from './label'
import * as layout from './layout'
import * as oneOf from './one-of'
import * as overview from './overview'
import * as productDetails from './product-details'
import * as products from './products'
import * as radios from './radios'
import * as selectbox from './selectbox'
import * as signature from './signature'
import * as upload from './upload'

export default (): JsonFormsRendererRegistryEntry[] => {
  const renderer: JsonFormsRendererRegistryEntry[] = []

  // Labels
  renderer.push(
    { tester: labels.labelRendererTester, renderer: labels.LabelRenderer },
    { tester: labels.labelH1RendererTester, renderer: labels.LabelH1Renderer },
    { tester: labels.labelH2RendererTester, renderer: labels.LabelH2Renderer },
    { tester: labels.labelH3RendererTester, renderer: labels.LabelH3Renderer },
  )

  // Radios
  renderer.push({
    tester: radios.radiosControlTester,
    renderer: radios.RadiosControl,
  })

  // Icon Radio
  renderer.push({
    tester: iconRadio.iconRadioControlTester,
    renderer: iconRadio.IconRadioControl,
  })

  // Checkbox
  renderer.push({
    tester: checkbox.checkboxControlTester,
    renderer: checkbox.CheckboxControl,
  })
  renderer.push({
    tester: checkbox.checkboxCartControlTester,
    renderer: checkbox.CheckboxCartControl,
  })

  // Datepicker
  renderer.push({
    tester: datepicker.datepickerControlTester,
    renderer: datepicker.DatePickerControl,
  })

  // Autocomplete: basic
  renderer.push({
    tester: autocomplete.autocompleteTester,
    renderer: autocomplete.AutocompleteControl,
  })

  // Autocomplete.: PostalcodeCity
  renderer.push({
    tester: autocomplete.postalcodeCityTester,
    renderer: autocomplete.PostalcodeCityControl,
  })
  renderer.push({
    tester: autocomplete.StreetControlTester,
    renderer: autocomplete.StreetControl,
  })

  // Selectbox: basic
  renderer.push({
    tester: selectbox.selectBoxControlTester,
    renderer: selectbox.SelectBoxControl,
  })

  // Input
  renderer.push({
    tester: input.textControlTester,
    renderer: input.TextInputControl,
  })
  renderer.push({
    tester: input.numberInputControlTester,
    renderer: input.NumberInputControl,
  })
  renderer.push({
    tester: input.birthdateInputControlTester,
    renderer: input.BirthdateInputControl,
  })

  // Button
  renderer.push({
    tester: button.buttonRendererTester,
    renderer: button.ButtonRenderer,
  })

  // Icon
  renderer.push({
    tester: icon.iconRendererTester,
    renderer: icon.IconRenderer,
  })

  // Products
  renderer.push({
    tester: products.productsControlTester,
    renderer: products.ProductsControl,
  })
  // Product details
  renderer.push({
    tester: productDetails.productDetailsRendererTester,
    renderer: productDetails.ProductDetailsRenderer,
  })

  // Add-ons
  renderer.push({
    tester: addons.addonsRendererTester,
    renderer: addons.AddonsRenderer,
  })

  // OverviewStep
  renderer.push({
    tester: overview.overviewStepTester,
    renderer: overview.OverviewStepRenderer,
  })

  // OverviewElement
  renderer.push({
    tester: overview.overviewElementTester,
    renderer: overview.OverviewElementRenderer,
  })

  // IBAN
  renderer.push({
    tester: iban.ibanControlTester,
    renderer: iban.IbanControl,
  })

  // One-Of
  renderer.push({
    tester: oneOf.oneOfControlTester,
    renderer: oneOf.OneOfControl,
  })

  // Layout
  renderer.push({
    tester: layout.horizontalLayoutTester,
    renderer: layout.HorizontalLayoutControl,
  })
  renderer.push({
    tester: layout.verticalLayoutTester,
    renderer: layout.VerticalLayoutControl,
  })
  // Uplooad
  renderer.push({
    tester: upload.uploadTester,
    renderer: upload.UploadControl,
  })

  // Signature
  renderer.push({
    tester: signature.signatureTester,
    renderer: signature.SignatureControl,
  })

  // Consumption Calculation
  renderer.push({
    tester: installationsPerformance.installationsPerformanceControlTester,
    renderer: installationsPerformance.InstallationsPerformanceControl,
  })

  // Expanded Postalcode
  renderer.push({
    tester: expandedPostalCode.expandedPostalcodeTester,
    renderer: expandedPostalCode.ExpandedPostalCodeControl,
  })

  return renderer
}
