import { BlueprintControlElement } from 'types/blueprint-config'

export const isBlueprintControlElement = (
  item: BlueprintControlElement | Required<BlueprintControlElement>,
): item is Required<BlueprintControlElement> => {
  return (
    typeof item.label === 'string' &&
    item.type === 'Control' &&
    typeof item.scope === 'string' &&
    typeof item.text === 'undefined' &&
    (typeof item.labels === 'undefined' ||
      (typeof item.labels === 'object' && item.labels !== null))
  )
}
