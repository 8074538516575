import { defaultAddressSchemaProperties } from './address.schema'
import { defaultConsumptionCalculatorSchemaProperties } from './consumption-calculator.schema'

export const defaultConfirmationSchemaProperties = {
  accept_terms: { name: 'accept_terms' },
  withdrawal: {
    name: 'withdrawal',
    required: true,
  },
  accept_promotions: { name: 'accept_promotions' },
  signature: { name: 'signature' },
}

export const confirmationSchemaProperties = {
  [defaultConfirmationSchemaProperties.accept_terms.name]: {
    type: 'boolean',
  },
  [defaultConfirmationSchemaProperties.withdrawal.name]: {
    type: 'boolean',
  },
  [defaultConfirmationSchemaProperties.accept_promotions.name]: {
    type: 'boolean',
  },
  [defaultConfirmationSchemaProperties.signature.name]: {
    type: 'string',
  },
  [defaultConsumptionCalculatorSchemaProperties.selected_tab.name]: {
    type: 'string',
  },
  [defaultAddressSchemaProperties.different_billing_address.name]: {
    type: 'boolean',
  },
}

export const confirmationSchemaRequired = {
  required: [
    defaultConfirmationSchemaProperties.accept_terms.name,
    defaultConfirmationSchemaProperties.withdrawal.name,
  ],
}
