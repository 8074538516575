import { Hidden, Typography, TypographyProps } from '@epilot/base-elements'
import {
  LayoutProps,
  rankWith,
  uiTypeIs,
  or,
  LabelElement,
} from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'
import { useSelector } from 'react-redux'

import replaceTemplate from 'lib/replace-template'
import { getAllResultData } from 'modules/step/selectors'

import { dataCleanBaseCondition } from '../../../lib/dataCleanBaseCondition'
export type DataLabelRendererOptions = TypographyProps & {
  labels?: { [property: string]: { [value: string]: string } }
  formatDataNumber?: boolean
}

const DataLabelRenderer = ({ uischema, visible }: LayoutProps) => {
  const stepData = useSelector(getAllResultData)
  const results = dataCleanBaseCondition(stepData)
  const element = uischema as LabelElement
  const { labels, formatDataNumber = false, ...rest } = (uischema?.options ||
    {}) as DataLabelRendererOptions

  return (
    <Hidden xsUp={!visible}>
      <Typography {...rest}>
        {element?.text
          ? replaceTemplate(element.text, results, labels, formatDataNumber)
          : ''}
      </Typography>
    </Hidden>
  )
}

export const dataLabelRendererTester = rankWith(2, or(uiTypeIs('DataLabel')))

export default withJsonFormsLayoutProps(DataLabelRenderer)
