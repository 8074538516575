import { styled } from '@epilot/components'

import { Theme } from 'modules/app/types'

export const TotalAnnualConsumptionStyled = styled.div`
  background-color: ${({ theme }: { theme: Theme }) =>
    theme?.palette?.grey[200]};
  margin-bottom: ${({ theme }: { theme: Theme }) => `${theme?.spacing(2)}px`};
  padding: ${({ theme }: { theme: Theme }) =>
    `${theme?.spacing(3)}px ${theme?.spacing(2)}px`};

  .annual-consumption {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > h2,
    > p {
      margin: 0;
    }
    span {
      align-items: center;
      cursor: pointer;
      display: flex;

      svg {
        height: 10px;
        margin-left: ${({ theme }: { theme: Theme }) => theme?.spacing(1)}px;
        width: 10px;

        g {
          stroke-width: 3;
        }
      }
    }
  }
  .installation-details-title {
    align-items: center;
    border-bottom: 1px solid
      ${({ theme }: { theme: Theme }) => theme.palette?.disabled.light};
    display: flex;
    height: 30px;
    justify-content: space-between;
    margin-top: ${({ theme }: { theme: Theme }) => theme.spacing(4)}px;

    p {
      flex-basis: calc(25%);
      margin-right: 0 ${({ theme }: { theme: Theme }) => theme.spacing(1)}px;
      padding-bottom: ${({ theme }: { theme: Theme }) => theme.spacing(1)}px;
    }
  }
  .installation-details {
    align-items: center;
    display: flex;
    justify-content: space-between;

    p {
      flex-basis: calc(25%);
      margin-right: ${({ theme }: { theme: Theme }) => theme.spacing(1)}px;
      margin-top: ${({ theme }: { theme: Theme }) => theme.spacing(2)}px;
      word-break: break-all;
    }
  }
`

export const InstallationsStyled = styled.div`
  > div {
    border-top: 1px solid
      ${({ theme }: { theme: Theme }) => theme.palette?.grey[200]};
    margin-top: ${({ theme }: { theme: Theme }) => theme.spacing(2)}px;
    padding-top: ${({ theme }: { theme: Theme }) => theme.spacing(2)}px;
  }
  > div:first-child {
    border-top: 0;
    padding-top: 0;
  }
`

export const CollapsibleContainerStyled = styled.div`
  max-height: ${({ expanded }: { expanded: boolean }) =>
    expanded ? '1000px' : '0px'};
  overflow: hidden;
  transition: ${({ expanded }: { expanded: boolean }) =>
    expanded ? 'max-height 300ms ease-in' : 'max-height 200ms ease-out'};
`
