import { styled } from '@epilot/components'

type AddonHeaderStyledProps = {
  withDetails: boolean
}

const AddonsHeaderStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: ${({ withDetails }: AddonHeaderStyledProps) =>
    withDetails ? 'column' : 'column-reverse'};
  max-height: 100%;
  flex-grow: 1;
  justify-content: center;
  padding: '24px 24px 16px 24px';
  padding: ${({ withDetails }: AddonHeaderStyledProps) =>
    withDetails ? '24px 24px 16px 24px' : '40px 24px'};

  p {
    font-size: ${({ withDetails }: AddonHeaderStyledProps) =>
      withDetails ? '14px' : '18px'};
    font-style: normal;
    font-weight: ${({ withDetails }: AddonHeaderStyledProps) =>
      withDetails ? '600' : '400'};
    line-height: 24px;
    margin-bottom: 0px;
    margin-top: ${({ withDetails }: AddonHeaderStyledProps) =>
      withDetails ? '16px' : '32px'};
    text-align: center;
    min-height: ${({ withDetails }: AddonHeaderStyledProps) =>
      withDetails ? '72px' : 'auto'};
  }
`

export default AddonsHeaderStyled
