import { BlueprintStep } from 'types/blueprint-config'

import {
  consumptionCalculatorSchemaProperties,
  defaultConsumptionCalculatorSchemaProperties,
} from '../schemas/consumption-calculator.schema'
import { buildConsumptionCalculatorBlock } from '../uischemas/calculator-consumption.uischema'

export const consumptionCalculator: BlueprintStep = {
  id: 'consumption_calculator',
  title: 'Consumption Calculator',
  nextButtonLabel: 'Berechnen',
  schema: {
    type: 'object',
    properties: {
      ...consumptionCalculatorSchemaProperties,
    },
    allOf: [
      {
        if: {
          properties: {
            [defaultConsumptionCalculatorSchemaProperties.selected_tab.name]: {
              enum: ['Strom'],
            },
          },
        },
        then: {
          required: [
            defaultConsumptionCalculatorSchemaProperties.postalcode_city.name,
            defaultConsumptionCalculatorSchemaProperties.power_consumption.name,
          ],
        },
        else: {
          if: {
            properties: {
              [defaultConsumptionCalculatorSchemaProperties.selected_tab
                .name]: {
                enum: ['Gas'],
              },
            },
          },
          then: {
            required: [
              defaultConsumptionCalculatorSchemaProperties.postalcode_city.name,
              defaultConsumptionCalculatorSchemaProperties.gas_consumption.name,
            ],
          },
          else: {
            required: [
              defaultConsumptionCalculatorSchemaProperties.postalcode_city.name,
              defaultConsumptionCalculatorSchemaProperties.power_consumption
                .name,
              defaultConsumptionCalculatorSchemaProperties.gas_consumption.name,
            ],
          },
        },
      },
      {
        if: {
          properties: {
            power: {
              properties: {
                showAdditionalFields: {
                  const: true,
                },
              },
            },
          },
        },
        then: {
          properties: {
            [defaultConsumptionCalculatorSchemaProperties.postalcode_city
              .name]: {
              required: [
                'countryCode',
                'zipCode',
                'city',
                'streetName',
                'houseNumber',
              ],
              minProperties: 5,
            },
          },
        },
        else: {
          properties: {
            [defaultConsumptionCalculatorSchemaProperties.postalcode_city
              .name]: {
              required: ['countryCode', 'zipCode', 'city'],
              minProperties: 3,
            },
          },
        },
      },
    ],
  },
  uischema: {
    ...buildConsumptionCalculatorBlock('bundle_power_gas' as any),
  },
  resultData: {
    [defaultConsumptionCalculatorSchemaProperties.selected_tab.name]: 'Strom',
    customer_type: 'PRIVATE',
    power: {
      showAdditionalFields: false,
    },
  },
  logic: {
    rules: [
      {
        effect: 'NEXT_STEP',
        condition: {
          schema: 'Strom',
          scope: 'selected_tab',
          action: {
            nextSteps: 'product_unavailability_power',
          },
        },
      },
      {
        effect: 'NEXT_STEP',
        condition: {
          schema: 'Gas',
          scope: `${defaultConsumptionCalculatorSchemaProperties.selected_tab.name}`,
          action: {
            nextSteps: 'product_unavailability_gas',
          },
        },
      },
      {
        effect: 'NEXT_STEP',
        condition: {
          schema: 'Strom & Gas',
          scope: `${defaultConsumptionCalculatorSchemaProperties.selected_tab.name}`,
          action: {
            nextSteps: 'product_unavailability_power',
          },
        },
      },
    ],
  },
  isEmbedded: true,
}
