import {
  EpilotTheme,
  EpilotPalette,
  EpilotTypography,
  EpilotOverrides,
  DefaultEpilotTheme,
  EpilotCustomFontSource,
} from '@epilot/base-elements'

import { AppStyling } from 'modules/app/types'
import { ThemingOptions } from 'types/widget-config'

const allowedFontFormatEnum = {
  WOFF2: 'woff2',
  WOFF: 'woff',
  TTF: 'truetype',
  EOT: 'embedded-opentype',
}

const loadCustomFontConfig = (typography: any): EpilotCustomFontSource[] => {
  const fonts: EpilotCustomFontSource[] = []

  typography?.font_url?.map(({ url, type }: Record<string, string>) => {
    fonts.push({
      fontFamily:
        typography?.font_family || DefaultEpilotTheme.typography?.fontFamily,
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: typography?.font_weight_regular || 400,
      src: `url('${url}') format('${
        allowedFontFormatEnum[type as keyof typeof allowedFontFormatEnum]
      }')`,
    })
  })

  return fonts
}

export const parseThemeTemplate = (params: any) => {
  const theme = {
    contentMaxWidth: `${params.props.content_max_width}px`,
    clientLogoUrl: params.props.client_logo_url,
    clientLogoStyle: {
      height: '32px',
    },
    theme: {
      typography: {
        fontWeightRegular: `${params.typography.font_weight_regular}`,
        fontWeightMedium: `${params.typography.font_weight_medium}`,
        fontWeightBold: `${params.typography.font_weight_bold}`,
        fontSize: `${params.typography.main_size}`,
        fontFamily: `${params.typography.font_family} !important`,
        color: `${params.typography.main_color}`,
        fontUrls: params?.typography?.font_url,
        primaryColor: params?.typography?.primary_color || 'white',
      },
      palette: {
        primary: {
          contrastText: '#FFFFFF',
          main: `${params.palette.primary}`,
        },
        secondary: {
          contrastText: '#FFFFFF',
          main: `${params.palette.secondary}`,
        },
        disabled: {
          light: `${params.palette.grey_light}`,
          dark: `${params.palette.grey_dark}`,
        },
        background: {
          default: `${params.background.default}`,
        },
      },
      overrides: {
        wrapperHeader: {
          backgroundColor: `${
            params.props.header_background_with_primary_color
              ? params.palette.primary
              : params.background.default
          }`,
          color: `${
            params.props.header_background_with_primary_color
              ? params?.typography?.primary_color
              : '#222222'
          }`,
        },
        MuiInputLabel: {
          formControl: {
            transform: 'scale(1)',
            position: 'relative',
            top: '10px',
            textAlign: 'left',
            display: 'contents',
          },
          filled: {
            color: `${params.palette.grey_dark} !important`,
            transform: 'translate(12px, 24px) scale(1.2)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(12px, 12px) scale(0.75)',
            },
          },
          shrink: {
            transform: 'scale(1)',
          },
          root: {
            border: 'none',
            transform: 'scale(1)',
            backgroundColor: 'transparent',
            fontSize: `${
              params.custom?.label_size || params.typography.caption_size
            }px`,
            color: `${params.typography.main_color} !important`,
            fontWeight: `${params.typography.font_weight_bold}`,
          },
        },
        MuiFormControl: {
          root: {
            width: '100%',
            marginBottom: `${params.spacing.default}px`,
          },
        },
        MuiButton: {
          containedPrimary: {
            boxShadow: 'none',
            transition: 'opacity .15s ease-in-out',
            color: `${params?.typography?.primary_color || '#FFFFFF'}`,
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: `${params.palette.primary}`,
              opacity: '.7',
            },
            '&:active': {
              backgroundColor: `${params.palette.primary}`,
              opacity: '.7',
            },
          },
          outlinedPrimary: {
            border: `2px solid ${params.palette.primary}`,
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: `${params.palette.primary}`,
              color: '#FFFFFF',
            },
            '&:active': {
              backgroundColor: `${params.palette.primary}`,
              color: '#FFFFFF',
            },
          },
          outlinedSecondary: {
            color: `${params.typography.main_color}`,
            border: `1px solid ${params.palette.grey_light}`,
            backgroundColor: `${params.background.default || '#FFFFFF'}`,
            '&:hover': {
              backgroundColor: `${params.palette.disable}`,
              borderColor: `${params.palette.grey_light}`,
            },
            '&:active': {
              backgroundColor: `${params.palette.grey_light}`,
              borderColor: `${params.palette.grey_light}`,
            },
          },
          outlined: {
            padding: '2px 16px 6px',
            '&:hover': {
              backgroundColor: `${params.palette.primary}`,
            },
            '&:active': {
              backgroundColor: `${params.palette.primary}`,
            },
          },
          contained: {
            color: `${params?.typography?.primary_color || '#FFFFFF'}`,
            backgroundColor: `${params.palette.primary}`,
            '&:hover': {
              backgroundColor: `${params.palette.primary}`,
            },
            '&:active': {
              backgroundColor: `${params.palette.primary}`,
            },
          },

          root: {
            padding: '3px 16px 6px',
            fontFamily: `${params.typography.font_family}`,
            '&.Mui-disabled': {
              border: `1px solid ${params.palette.grey_light} !important`,
              backgroundColor: `${
                params.background.default || params.palette.disable
              }`,
            },
            fontSize: `${params.typography.main_size}px`,
            fontVariant: 'tabular-nums',
            fontWeight: `${params.typography.font_weight_bold}`,
            textTransform: 'none',
          },
          textPrimary: {
            color: `${params.palette.secondary}`,
            backgroundColor: '#ffffff',
            '&:hover': {
              backgroundColor: `${params.palette.disable}`,
            },
            '&:active': {
              backgroundColor: `${params.palette.disable}`,
            },
          },
          text: {
            padding: '1px 16px 0',
            fontSize: `${params.typography.main_size}px`,
            fontVariant: 'tabular-nums',
            fontWeight: `${params.typography.font_weight_bold}`,
          },
          sizeSmall: {
            padding: '0 12px',
            fontSize: `${params.typography.caption}`,
            height: '24px',
          },
          sizeLarge: {
            padding: '0 24px',
            fontSize: `${params.typography.large_size}px`,
            height: '48px',
          },
        },

        MuiOutlinedInput: {
          input: {
            padding: 0,
          },
          adornedStart: {
            paddingLeft: '7px',
          },
          notchedOutline: {
            display: 'none',
          },
          adornedEnd: {
            paddingRight: '7px',
          },
        },
        MuiRadio: {
          root: {
            padding: '0px 8px 0px 0px',
          },
        },
        MuiFilledInput: {
          input: {
            top: '6px',
            padding: '12px 10px',
            position: 'relative',
          },
          root: {
            padding: '0',
            backgroundColor: `${params.custom?.input_background || '#ffffff'}`,
            '&:focus': {
              backgroundColor: `${
                params.custom?.input_background || '#ffffff'
              }`,
            },
            '&:hover': {
              backgroundColor: `${
                params.custom?.input_background || '#ffffff'
              }`,
            },
            '&:focus-within': {
              backgroundColor: `${
                params.custom?.input_background || '#ffffff'
              }`,
            },
            '&:active': {
              backgroundColor: `${
                params.custom?.input_background || '#ffffff'
              }`,
            },
            height: '56px',
          },
        },
        PrivateRadioButtonIcon: {
          checked: {
            color: `${params.palette.secondary}`,
          },
          root: {
            color: `${params.palette.grey_light}`,
          },
        },
        PrivateSwitchBase: {
          root: {
            padding: '0px 8px 0px 0px',
            height: '23px',
          },
        },
        MuiLinearProgress: {
          colorPrimary: {
            backgroundColor: `${params.palette.grey_light}`,
          },
          bar: {
            borderBottomRightRadius: '2px',
            borderTopRightRadius: '2px',
            top: '-1px',
            height: '2px',
          },
          root: {
            height: 0,
            overflow: 'visible',
          },
        },
        MuiDialogContent: {
          root: {
            padding: '8px',
          },
        },
        MuiSvgIcon: {
          root: {
            width: `${params.custom?.radio_button_size || 19}px`,
          },
        },
        MuiTypography: {
          body1: {
            fontSize: `${params.typography.main_size}px`,
            lineHeight: '24px',
            fontStyle: 'normal',
            fontWeight: `${params.typography.font_weight_regular}`,
          },
          root: {
            color: `${params.typography.main_color}`,
            '& a': {
              color: `${params.palette.primary}`,
            },
          },
          body2: {
            fontSize: `${params.typography.main_size}px`,
            lineHeight: '24px',
            fontStyle: 'normal',
            fontWeight: `${params.typography.font_weight_regular}`,
          },
          h1: {
            letterSpacing: '0',
            fontSize: `${params.typography.title_size}px`,
            lineHeight: '32px',
            fontStyle: 'normal',
            fontWeight: `${params.typography.font_weight_regular}`,
          },
          caption: {
            letterSpacing: '0.5px',
            fontSize: `${params.typography.caption_size}px`,
            lineHeight: '16px',
            fontStyle: 'normal',
            color: `${params.palette.grey_dark}`,
            fontWeight: `${
              params.custom?.caption_weight ||
              params.typography.font_weight_bold
            }`,
          },
          h2: {
            fontSize: `${params.typography.subtitle_size}px`,
            lineHeight: `${params.custom?.subtitle_line_height || 24}px`,
            fontStyle: 'normal',
            fontWeight: `${
              params.custom?.subtitle_weight ||
              params.typography.font_weight_bold
            }`,
          },
          h3: {
            fontWeight: `${params.typography.font_weight_medium}`,
            fontSize: `${params.typography.title_size}px`,
            lineHeight: '24px',
          },
          h4: {
            fontWeight: `${params.typography.font_weight_medium}`,
            fontSize: `${params.typography.title_size}px`,
            lineHeight: '24px',
          },
        },
        MuiMenuItem: {
          selected: {
            backgroundColor: `${params.palette.disable} !important`,
            '&:hover': {
              backgroundColor: `${params.palette.disable} !important`,
            },
            color: `${params.typography.main_color}`,
            fontWeight: `${params.typography.font_weight_medium}`,
          },
          root: {
            fontFamily: `${params.typography.font_family}`,
            paddingBottom: '8px',
            paddingRight: '16px',
            '&.Mui-disabled': {
              color: `${params.palette.grey_dark}`,
              backgroundColor: `${params.palette.disable}`,
            },
            fontSize: `${params.typography.main_size}px`,
            lineHeight: '24px',
            paddingTop: '8px',
            '&:hover': {
              backgroundColor: `${params.palette.disable}`,
            },
            paddingLeft: '16px',
            fontWeight: `${params.typography.font_weight_medium}`,
          },
        },
        MuiGrid: {
          root: {
            '& #checkbox--helper-text': {
              paddingLeft: '29px',
            },
          },
        },
        MuiFormControlLabel: {
          root: {
            marginRight: '0',
            alignItems: 'flex-start',
            borderRadius: `${params.shape.border_radius}px`,
            marginLeft: '0',
          },
          label: {
            fontSize: `${
              params.custom?.input_size || params.typography.main_size
            }px`,
          },
        },
        MuiInputAdornment: {
          positionEnd: {
            marginLeft: '8px',
          },
          filled: {
            marginTop: '0 !important',
          },
          positionStart: {
            marginRight: '8px',
          },
        },
        MuiDrawer: {
          paperAnchorTop: {
            borderBottomRightRadius: `${params.shape.border_radius}px`,
            maxHeight: '80%',
            borderBottomLeftRadius: `${params.shape.border_radius}px`,
          },
          paperAnchorBottom: {
            maxHeight: '80%',
            borderTopRightRadius: `${params.shape.border_radius}px`,
            borderTopLeftRadius: `${params.shape.border_radius}px`,
          },
        },
        MuiAutocomplete: {
          input: {
            fontWeight: `${params.typography.font_weight_regular}`,
          },
          noOptions: {
            fontStyle: 'italic',
          },
          listbox: {
            padding: 0,
            fontFamily: `${params.typography.font_family}`,
          },
          endAdornment: {
            top: 0,
            height: '100%',
            display: 'flex',
          },
          popupIndicator: {
            paddingRight: '16px',
          },
          popupIndicatorOpen: {
            transform: 'none',
          },
          option: {
            fontSize: '14px',
            paddingTop: '8px',
            paddingBottom: '8px',
            '&[aria-selected="true"]': {
              color: `${params.palette.primary}`,
              backgroundColor: '#FFFFFF',
            },
            '&[aria-disabled="true"]': {
              color: `${params.palette.grey_dark}`,
              backgroundColor: `${params.palette.disable}`,
            },
          },
        },
        MuiAvatar: {
          colorDefault: {
            color: `${params.palette.disable}`,
            backgroundColor: `${params.palette.primary}`,
          },
          root: {
            width: '30px',
            border: `1px solid ${params.palette.grey_light}`,
            '&.MuiAvatar-small': {
              width: '22px',
              height: '22px',
            },
            height: '30px',
          },
        },
        MuiTooltip: {
          tooltip: {
            padding: '8px 16px',
            backgroundColor: 'rgba(34, 34, 34, 0.85)',
            '&$tooltipPlacementTop, &$tooltipPlacementBottom': {
              margin: '16px 0',
            },
            fontSize: `${params.typography.caption_size}px`,
            lineHeight: '16px',
            '&$tooltipPlacementLeft, &$tooltipPlacementRight': {
              margin: '0 16px',
            },
            maxWidth: '256px',
          },
          popperArrow: {
            '&[x-placement*="left"] $arrow': {
              marginRight: '-1em',
            },
            '&[x-placement*="bottom"] $arrow': {
              marginTop: '-1em',
            },
            '&[x-placement*="right"] $arrow': {
              marginLeft: '-1em',
            },
            '&[x-placement*="top"] $arrow': {
              marginBottom: '-1em',
            },
          },
          arrow: {
            color: 'rgba(34, 34, 34, 0.85)',
            fontSize: '4px',
          },
        },
        MuiButtonGroup: {
          groupedContainedPrimary: {
            '&:not(:last-child)': {
              borderColor: DefaultEpilotTheme.palette?.background.default,
            },
          },
          groupedTextPrimary: {
            border: `1px solid ${DefaultEpilotTheme.palette?.grey[70]} !important`,

            '&:not(:last-child)': {
              marginRight: -1,
            },
          },
        },
        MuiFormLabel: {
          root: {
            transform: 'scale(1)',
            fontFamily: `${params.typography.font_family}`,
          },
        },
        MuiInputBase: {
          input: {
            '&.Mui-disabled': {
              color: `${params.palette.grey_dark}`,
            },
            fontSize: `${params.typography.main_size}px !important`,
          },
          disabled: {
            border: 'none',
            backgroundColor: `${params.palette.disable}`,
            '&:hover': {
              border: 'none',
            },
            '&:focus': {
              border: 'none',
            },
            '&:focus-within': {
              border: 'none',
            },
            '&:active': {
              border: 'none',
            },
          },
          error: {
            border: `1px solid ${params.palette.error}`,
          },
          root: {
            backgroundColor:
              `${params.custom?.input_background}` || 'transparent',
            border: `1px solid ${
              params.custom?.input_background || params.palette.grey_light
            }`,
            padding: '0 14px',
            color: `${params.typography.main_color}`,
            fontVariant: 'tabular-nums',
            fontFamily: `${params.typography.font_family}`,
            '&:focus': {
              border: `1px solid ${
                params.custom?.input_background || params.palette.secondary
              }`,
            },
            borderRadius: '4px',
            fontSize: '14px',
            '&:hover': {
              border: `1px solid ${
                params.custom?.input_background || params.palette.secondary
              }`,
            },
            '&:focus-within': {
              border: `1px solid ${
                params.custom?.input_background || params.palette.secondary
              }`,
            },
            fontWeight: `${
              params.custom?.input_weight ||
              params.typography.font_weight_regular
            }`,
            height: '32px',
            textTransform: 'none',
            '&:active': {
              border: `1px solid ${params.palette.secondary}`,
            },
          },
          multiline: {
            padding: '4px 16px',
            fontSize: '14px',
            height: 'initial',
          },
        },
        MuiPickersCalendarHeader: {
          dayLabel: {
            color: `${params.palette.grey_dark}`,
            textTransform: 'uppercase',
          },
        },
        MuiListItem: {
          button: {
            '&:hover': {
              backgroundColor: `${params.palette.disable}`,
            },
          },
        },
        MuiCheckbox: {
          colorPrimary: {
            color: `${params.palette.secondary}`,
            '&.Mui-checked': {
              color: `${params.palette.secondary}`,
            },
          },
        },
        MuiListItemAvatar: {
          root: {
            minWidth: 'unset',
            display: 'flex',
          },
        },
        MuiDialogTitle: {
          root: {
            padding: '8px',
          },
        },
        MuiPickersDay: {
          daySelected: {
            backgroundColor: `${params.palette.secondary} !important`,
            '&:hover': {
              backgroundColor: `${params.palette.secondary} !important`,
            },
          },
        },
        MuiIconButton: {
          disabled: {
            '& svg:nth-of-type(2)': {
              fill: `${params.palette.grey_dark}`,
              transform: `scale(${params.custom?.icon_button_transform || 2})`,
            },
            '& svg *': {
              color: `${params.palette.grey_light}`,
            },
          },
          root: {
            '&:hover': {
              backgroundColor: 'initial !important',
            },
          },
        },
        MuiSelect: {
          iconOpen: {
            transform: 'none',
          },
          select: {
            padding: '0 24px 0 16px',
            '&:focus': {
              backgroundColor: 'transparent',
            },
            alignItems: 'center',
            display: 'flex',
          },
          selectMenu: {
            height: '100%',
          },
        },
        MuiInput: {
          formControl: {
            'label + &': {
              marginTop: '0',
            },
          },
        },
        MuiListItemText: {
          secondary: {
            color: `${params.palette.grey_dark}`,
            lineHeight: '14px',
            display: 'block',
          },
          primary: {
            lineHeight: '18px',
          },
        },
        MuiMenu: {
          list: {
            borderRadius: `${params.shape.border_radius}px`,
            padding: 0,
          },
          paper: {
            margin: '4px 0',
          },
        },
        MuiFormHelperText: {
          root: {
            border: 'none',
            marginTop: '4px',
            marginLeft: '0px !important',
          },
        },
      },
      shape: {
        borderRadius: `${params.shape.border_radius}px`,
      },
      props: {
        MuiButtonGroup: {
          disableRipple: !params.props.enable_ripple,
        },
        MuiInput: {
          disableUnderline: true,
        },
        MuiListItem: {
          disableGutters: true,
        },
        MuiPickersDay: {
          daySelected: {
            '&:hover': {
              backgroundColor: `${params.background.default} !important`,
            },
          },
        },
        theme: 'epilot',
        MuiAutocomplete: {
          noOptionsText: 'Nicht gefunden',
          disableClearable: true,
          popupIcon: null,
        },
        MuiButtonBase: {
          disableRipple: !params.props.enable_ripple,
        },
        MuiRadio: {
          disableRipple: !params.props.enable_ripple,
        },
        MuiFilledInput: {
          disableUnderline: true,
        },
        MuiSelect: {
          MenuProps: {
            elevation: 3,
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
            getContentAnchorEl: null,
          },
        },
        MuiList: {
          disablePadding: true,
        },
        MuiTooltip: {
          arrow: true,
        },
      },
    },
  }

  return theme as unknown
}

export const mapEpilotTheme = ({
  background,
  palette,
  typography,
  spacing,
  shape,
  props,
}: ThemingOptions): AppStyling => {
  const theme: EpilotTheme = {
    palette: {
      ...DefaultEpilotTheme.palette,
      primary: {
        main: palette?.primary || DefaultEpilotTheme.palette?.primary.main,
        contrastText: '#FFFFFF',
        '900': '#152B42',
        '800': '#1B3855',
        '700': '#28527D',
        '600': '#0F5B99',
        '500': '#039BE5',
        '400': '#65C8F7',
        '300': '#ADDFF7',
        '200': '#D9F1FC',
        '100': '#EDF9FF',
        '50': '#F7FBFD',
      },
      secondary: {
        main: palette?.primary || DefaultEpilotTheme.palette?.primary.main,
        contrastText: '#FFFFFF',
        '600': '#F44719',
        '500': '#FF6A3F',
        '300': '#FF8461',
        '200': '#FFA78E',
        '100': '#FFCABA',
        '50': '#FBE8E6',
      },
      success: {
        main: '#13BB89',
        contrastText: '#FFFFFF',
        '500': '#13BB89',
        '200': '#7AD3B2',
        '100': '#B1E4D0',
        '50': '#DFF4EC',
      },
      info: {
        main: '#26BCFC',
        contrastText: '#172B4D',
        '500': '#26BCFC',
        '200': '#70CFFF',
        '100': '#ACE2FF',
        '50': '#DEF4FF',
      },
      warning: {
        main: '#FFBF44',
        contrastText: '#172B4D',
        '500': '#FFBF44',
        '200': '#FFD17C',
        '100': '#FFE3B0',
        '50': '#FFF4DF',
      },
      error: {
        main: '#FF3A3F',
        contrastText: '#FFFFFF',
        '500': '#FF3A3F',
        '300': '#F46668',
        '200': '#FB9393',
        '100': '#FFC9CF',
      },
      focus: {
        main: '#5B4CEC',
        dark: '#5B4CEC',
        contrastText: '#FFFFFF',

        '500': '#5B4CEC',
        '300': '#806CEF',
        '200': '#A797F2',
        '100': '#CBC0F7',
      },
      accent1: {
        main: '#FF6A3F',
        contrastText: '#FFFFFF',
        '600': '#F44719',
        '500': '#FF6A3F',
        '300': '#FF8461',
        '200': '#FFA78E',
        '100': '#FFCABA',
        '50': '#FBE8E6',
      },
      accent2: {
        main: '#99E0EF',
        contrastText: '#172B4D',
        '500': '#59C3DC',
        '400': '#62CBE2',
        '300': '#77D4E7',
        '200': '#99E0EF',
        '100': '#BEECF6',
        '50': '#E5F7FB',
      },
      tertiary: {
        main: '#99E0EF',
        contrastText: '#172B4D',
        '500': '#59C3DC',
        '400': '#62CBE2',
        '300': '#77D4E7',
        '200': '#99E0EF',
        '100': '#BEECF6',
        '50': '#E5F7FB',
      },
      grey: {
        '10': '#FCFEFF',
        '20': '#F5F7FA',
        '30': '#EDF2F7',
        '40': '#E6EEF7',
        '50': '#DEEAF7',
        '60': '#D5E1ED',
        '70': '#C5D0DB',
        '80': '#AFBCC9',
        '90': '#9DADBD',
        '100': '#8C9DAD',
        '200': '#7E8D9C',
        '300': '#6F7C8A',
        '400': '#596775',
        '500': '#505E6B',
        '600': '#44525E',
        '700': '#35434F',
        '800': '#27333D',
        '900': '#263847',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
      action: {
        active: '#324C66',
        hover: '#EFF5FA',
        selected: '#324C66',
        selectedText: '#ffffff',
        disabled: '#E6EEF7',
        focus: '#324C66',
      },
      background: {
        default:
          background?.default || DefaultEpilotTheme.palette?.background.default,
      },
      text: {
        primary: typography?.main_color || '#172B4D',
        secondary: '#8C9EB0',
      },
      divider: '#DEEAF7',
    } as EpilotPalette,
    typography: {
      ...DefaultEpilotTheme.typography,
      fontWeightRegular: 400,
      fontWeightBold: 600,
      fontSize: 14,
      fontFamily:
        typography?.font_family || DefaultEpilotTheme.typography?.fontFamily,
      fontSource: loadCustomFontConfig(typography),
      h1: {
        fontSize: typography?.title_size || '32px',
        '@media (max-width:600px)': {
          fontSize: '24px',
          fontWeight: 600,
          marginLeft: '24px',
        },
      },
      h2: {
        fontSize: typography?.subtitle_size || '24px',
        '@media (max-width:600px)': {
          fontSize: '20px',
          fontWeight: 600,
        },
      },
      h3: {
        '@media (max-width:600px)': {
          fontSize: '40px',
        },
      },
      body2: {
        fontSize: typography?.main_size || '14px',
        lineHeight: '157%',
      },
      caption: {
        fontSize: typography?.caption_size || '12px',
        lineHeight: '150%',
      },
      button: {
        fontSize: DefaultEpilotTheme.typography?.button.fontSize,
        textTransform: 'none',
        height: '48px',
      },
    } as EpilotTypography,
    spacing: spacing?.default || 4,
    shape: {
      borderRadius:
        shape?.border_radius || DefaultEpilotTheme.shape?.borderRadius || 0,
    },
    muiProps: {
      ...DefaultEpilotTheme.muiProps,
      MuiCard: {
        elevation: 1,
      },
      MuiAppBar: {
        elevation: 0,
        position: 'static',
        square: true,
      },
    },
    muiOverrides: {
      ...DefaultEpilotTheme.muiOverrides,
      MuiTabs: {
        root: {
          minHeight: 40,
          overflow: 'visible',
        },
        flexContainer: {
          marginTop: '8px',
          '& .Mui-selected path': {
            fill: palette?.primary || DefaultEpilotTheme.palette?.primary.main,
          },
        },
      },
      MuiTabPanel: {
        root: {
          padding: 0,
          '& .MuiPaper-root': {
            borderRadius: `0px ${
              shape?.border_radius ||
              DefaultEpilotTheme.shape?.borderRadius ||
              0
            }px ${
              shape?.border_radius ||
              DefaultEpilotTheme.shape?.borderRadius ||
              0
            }px ${
              shape?.border_radius ||
              DefaultEpilotTheme.shape?.borderRadius ||
              0
            }px`,
          },
        },
      },
      MuiToggleButton: {
        root: {
          fontWeight: 600,
          backgroundColor:
            background?.default ||
            DefaultEpilotTheme.palette?.background.default,
          color:
            typography?.main_color || DefaultEpilotTheme.palette?.text?.primary,
          borderColor: `${DefaultEpilotTheme.palette?.grey[70]} !important`,
          height: '32px',

          '&:hover': {
            backgroundColor:
              background?.default ||
              DefaultEpilotTheme.palette?.background?.default,
          },

          '&$selected': {
            backgroundColor: `${
              palette?.primary || DefaultEpilotTheme.palette?.primary.main
            } !important`,
            borderColor: `${
              palette?.primary || DefaultEpilotTheme.palette?.primary.main
            } !important`,
            borderRight: `1px solid ${
              palette?.primary || DefaultEpilotTheme.palette?.primary.main
            } !important`,

            '&:hover': {
              backgroundColor:
                palette?.primary || DefaultEpilotTheme.palette?.primary.main,
            },

            '& .MuiToggleButton-label': {
              color:
                background?.default ||
                DefaultEpilotTheme.palette?.background.default,
            },
          },

          '&$disabled': {
            backgroundColor: DefaultEpilotTheme.palette?.grey[40],

            '& .MuiToggleButton-label': {
              color: DefaultEpilotTheme.palette?.grey[100],
            },
          },
        },
      },
      MuiAutocomplete: {
        listbox: {
          '& li[data-focus="true"]': {
            backgroundColor: DefaultEpilotTheme.palette?.action.hover,
          },
        },
        inputRoot: {
          '&[class*="MuiFilledInput-root"]': {
            padding: '0 !important',
          },
        },
      },
      MuiCardContent: {
        root: {
          padding: '24px',
          '&:last-child': {
            paddingBottom: '24px',
          },
        },
      },
      MuiInputBase: {
        input: {
          boxSizing: 'border-box',
          padding: '22px 12px 6px !important',
          height: '48px',
        },
      },
      MuiCheckbox: {
        root: {
          paddingLeft: 0,
        },
      },
      MuiFormLabel: {
        root: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    } as EpilotOverrides,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1920,
      },
    },
  }

  return {
    theme,
    clientLogoUrl: props?.client_logo_url,
    clientLogoStyle: {
      height: '32px',
    },
    contentMaxWidth: props?.content_max_width
      ? `${props.content_max_width}px`
      : '100%',
  }
}
