import { Spinner } from '@epilot/elements'
import { RankedTester, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'

const SpinnerControl = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        //backgroundColor: 'rgba(255,255,0,0.5)',
        position: 'absolute',
        zIndex: 1,
      }}
    >
      <Spinner></Spinner>
    </div>
  )
}

export default withJsonFormsControlProps(SpinnerControl)

export const spinnerRendererTester: RankedTester = rankWith(
  9,
  uiTypeIs('Spinner'),
)
