import { MuiTheme, styled } from '@epilot/components'

type ItemWrapper = {
  selected: boolean
}

type PropsType = { theme: MuiTheme } & ItemWrapper

const ItemWrapper = styled.div<ItemWrapper>`
  display: flex;
  flex-direction: column;
  border: ${(props: PropsType) =>
    props.selected
      ? '1px solid' + props.theme.palette.primary.main
      : '1px solid #d2dee4'};
  border-radius: ${(props: PropsType) => props.theme.shape.borderRadius}px;
  padding: 11px 16px 11px 24px;

  .sub-form > * {
    padding-left: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
`

export default ItemWrapper
