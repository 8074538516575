import { BodyText, SmallText } from '@epilot/components'
import React from 'react'

import { ReactComponent as Broadband } from 'assets/icons/broadband.svg'
import { ReactComponent as Gas } from 'assets/icons/gas.svg'
import { ReactComponent as Power } from 'assets/icons/power.svg'
import config from 'config'
import { formatAsCent, formatCurrency } from 'lib/format-currency'
import { DisplayCartItem } from 'modules/shop/types'

import CartItemInfoTextStyled from './cart-item-infotext-styled'
import CartItemStyled from './cart-item-styled'

type CartItemProps = {
  cartItem: DisplayCartItem
  priceIntervalLabel: string
  pricingBusiness: boolean
}

const CartItem = ({
  cartItem,
  priceIntervalLabel,
  pricingBusiness,
}: CartItemProps): JSX.Element => {
  const price =
    priceIntervalLabel === 'Monat'
      ? pricingBusiness
        ? cartItem.price?.estimated_monthly_price?.amount_net || 0
        : cartItem.price?.estimated_monthly_price?.amount_gross || 0
      : pricingBusiness
      ? cartItem.price?.estimated_yearly_price?.amount_net || 0
      : cartItem.price?.estimated_yearly_price?.amount_gross || 0

  const basePrice =
    priceIntervalLabel === 'Monat'
      ? pricingBusiness
        ? cartItem.price?.recurring_price?.monthly?.amount_net ||
          (cartItem.price?.recurring_price?.yearly?.amount_net &&
            cartItem.price?.recurring_price?.yearly?.amount_net / 12) ||
          0
        : cartItem.price?.recurring_price?.monthly?.amount_gross ||
          (cartItem.price?.recurring_price?.yearly?.amount_gross &&
            cartItem.price?.recurring_price?.yearly?.amount_gross / 12) ||
          0
      : pricingBusiness
      ? cartItem.price?.recurring_price?.yearly?.amount_net || 0
      : cartItem.price?.recurring_price?.yearly?.amount_gross || 0

  const variablePrice = pricingBusiness
    ? cartItem.price?.calculated_consumption_price?.amount_net || 0
    : cartItem.price?.calculated_consumption_price?.amount_gross || 0
  const variablePriceUnit = cartItem.price?.consumption_price?.unit

  const oneTimePrice = pricingBusiness
    ? cartItem.price?.one_time_price?.amount_net || 0
    : cartItem.price?.one_time_price?.amount_gross || 0

  const totalPrice = `${formatCurrency(price)}`
  let basePriceLabel = basePrice
    ? `Grundpreis: ${formatCurrency(basePrice)}/${priceIntervalLabel}`
    : null

  const variablePriceLabel = variablePrice
    ? `Arbeitspreis: ${formatAsCent(variablePrice)}/${variablePriceUnit}`
    : null

  let icon = <></>
  let priceContainer = (
    <BodyText fontWeight={600}>
      {totalPrice}
      <span>/{priceIntervalLabel}</span>
    </BodyText>
  )

  switch (cartItem.productCategory.toUpperCase()) {
    case 'POWER':
      icon = <Power />
      break
    case 'GAS':
      icon = <Gas />
      break
    case 'BROADBAND':
      icon = <Broadband />
      basePriceLabel = null
      priceContainer = (
        <>
          <BodyText fontWeight={600}>
            {totalPrice}
            <span>/{priceIntervalLabel}</span>
          </BodyText>
          <SmallText fontWeight={600}>
            {`+${formatCurrency(oneTimePrice)} `}
            <span>einmalig</span>
          </SmallText>
        </>
      )
      break
    default:
      break
  }

  return (
    <>
      <CartItemStyled className="cart-item">
        {cartItem.image_src ? (
          <img
            alt={cartItem.name}
            src={config.IMAGE_HOST + cartItem.image_src}
          />
        ) : (
          <div className="icon">{icon}</div>
        )}
        <div className="title">
          <BodyText fontWeight={600}>{cartItem.name}</BodyText>
          <SmallText customColor="#849096">{variablePriceLabel}</SmallText>
          <SmallText customColor="#849096">{basePriceLabel}</SmallText>
        </div>
        <div className="price">{priceContainer}</div>
      </CartItemStyled>
      {cartItem.addons &&
        cartItem.addons.length > 0 &&
        cartItem.addons.map((addonItem) => {
          const {
            id,
            name,
            price,
            quantity,
            image_src: addonThumbnail,
          } = addonItem
          const oneTimePrice = pricingBusiness
            ? price?.one_time_price?.amount_net
            : price?.one_time_price?.amount_gross
          const recurringPrice = pricingBusiness
            ? priceIntervalLabel === 'Monat'
              ? price?.recurring_price?.monthly?.amount_net ||
                (price?.recurring_price?.yearly?.amount_net &&
                  price?.recurring_price?.yearly?.amount_net / 12)
              : price?.recurring_price?.yearly?.amount_net
            : priceIntervalLabel === 'Monat'
            ? price?.recurring_price?.monthly?.amount_gross ||
              (price?.recurring_price?.yearly?.amount_gross &&
                price?.recurring_price?.yearly?.amount_gross / 12)
            : price?.recurring_price?.yearly?.amount_gross
          const durationNumber = addonItem.price?.duration?.slice(0, -1)
          const durationInterval = addonItem.price?.duration?.slice(-1)
          const durationText = `Zahlungslaufzeit: ${durationNumber} ${
            durationInterval === 'M' ? 'Monate' : 'Jahre'
          }.`

          return (
            <CartItemStyled className="cart-item" key={id}>
              {addonThumbnail ? (
                <img
                  alt={cartItem.name}
                  src={config.IMAGE_HOST + addonThumbnail}
                />
              ) : (
                <div className="icon addon-icon"></div>
              )}
              <div className="title">
                <BodyText>
                  {quantity && quantity > 1 ? `${name} (x ${quantity})` : name}
                </BodyText>
              </div>
              <div className="price">
                {recurringPrice && (
                  <BodyText fontWeight={600}>
                    {formatCurrency(recurringPrice * (quantity || 1))}
                    <span>/{priceIntervalLabel}</span>
                  </BodyText>
                )}
                {oneTimePrice && (
                  <BodyText fontWeight={600}>
                    {`+${formatCurrency(
                      oneTimePrice * (quantity || 1),
                    )} einmalig`}
                  </BodyText>
                )}
                {!recurringPrice && !oneTimePrice && (
                  <BodyText fontWeight={600}>{`${formatCurrency(0)}`}</BodyText>
                )}
                {recurringPrice && (
                  <CartItemInfoTextStyled>
                    {durationText}
                  </CartItemInfoTextStyled>
                )}
              </div>
            </CartItemStyled>
          )
        })}
    </>
  )
}

export default CartItem
