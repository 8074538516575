import { Upload } from '@epilot/components'
import {
  ControlProps,
  optionIs,
  OwnPropsOfEnum,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsEnumProps } from '@jsonforms/react'
import React from 'react'

import UploadStyled from './upload-styled'

export interface FilesPack {
  file_type: string
  file_size: number
  file_name: string
  base64: string
  original_name: string
}

const UploadControl = ({
  data,
  uischema,
  handleChange,
  path,
}: ControlProps & OwnPropsOfEnum) => {
  const _maxSize = uischema?.options?.maxSize
  const _restrictType = uischema?.options?.restrictType
  const _errTypeText = uischema?.options?.errTypeText
  const _deleteDialogContent = uischema?.options?.deleteDialogContent
  const _errSizeText = uischema?.options?.errSizeText
  const _errFilesNumber = uischema?.options?.errFilesNumber
  const _maxFilesNumber = uischema?.options?.maxFilesNumber
  const _maxSizeText = uischema?.options?.maxSizeText

  const existedFiles = Array<string>()

  const getBase64 = (file: File) =>
    new Promise((resolve) => {
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = (error) => {
        console.log('Error: ', error)
      }
    })

  if (data) {
    for (let i = 0; i < data.length; i++) {
      existedFiles.push(data[i].file_name)
    }
  } else {
    data = []
  }
  const uploadFile = async (uploadedFiles: Array<File>) => {
    const addedFiles: FilesPack[] = []

    for (let i = 0; i < uploadedFiles.length; i++) {
      const fileName = uploadedFiles[i].name
      const fileSize = uploadedFiles[i].size
      const fileType = uploadedFiles[i].type.split('/')[1]

      if (uploadedFiles[i].size === 0) {
        for (let k = 0; k < data.length; k++) {
          if (data[k].file_name === fileName) {
            addedFiles.push(data[k])
          }
        }
        continue
      }

      const fileData = (await getBase64(uploadedFiles[i])) as string

      const filePack: FilesPack = {
        file_type: fileType,

        file_size: fileSize,

        file_name: fileName,
        base64: fileData,

        original_name: fileName,
      }

      addedFiles.push(filePack)
    }
    if (addedFiles.length > 0) handleChange(path, addedFiles)
  }

  return (
    <UploadStyled>
      <Upload
        deleteDialogContent={_deleteDialogContent}
        errFilesNumber={_errFilesNumber}
        errSizeText={_errSizeText}
        errTypeText={_errTypeText}
        existedFiles={existedFiles}
        handleUploadFile={uploadFile}
        maxFilesNumber={_maxFilesNumber}
        maxSize={_maxSize}
        maxSizeText={_maxSizeText}
        restrictType={_restrictType}
      ></Upload>
    </UploadStyled>
  )
}

export default withJsonFormsEnumProps(UploadControl)

export const uploadTester: RankedTester = rankWith(
  10,
  optionIs('format', 'upload'),
)
