export const generateInfoForAllConsumption = (type: string) => {
  switch (type) {
    case 'storage':
      return {
        sumConsumptionText: 'Gesamtspeicherkapazität',
        sumConsumptionUnit: 'kWh',
      }
    case 'commissioning':
      return {
        sumConsumptionText: 'Jährlicher Verbrauch',
        sumConsumptionUnit: 'kWh',
      }
    case 'inverter':
    case 'generator':
      return {
        sumConsumptionText: 'Gesamtleistung',
        sumConsumptionUnit: 'kVA',
      }
    case 'solar':
      return {
        sumConsumptionText: 'Gesamtleistung',
        sumConsumptionUnit: 'kWp',
      }
    default:
      return {
        sumConsumptionText: 'Gesamtleistung',
        sumConsumptionUnit: 'kWh',
      }
  }
}
