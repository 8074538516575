import { produce } from 'immer'

import { ProductAPI, ProductAvailabilityAPI } from 'lib/api.types'
import { ObjectKeyString } from 'lib/types'
import {
  LOAD_CONFIGURATION_FAILURE,
  LOAD_CONFIGURATION_SUCCESS,
  PREVIEW_STEP,
  RESET_GLOBAL,
} from 'modules/app/reducer'
import { Step, StepId } from 'modules/step/types'
import { Action, ActionHandlers, TypedAction } from 'modules/types'
import { WidgetConfig } from 'types/widget-config'

import { Product, ProductCategory, ShopState } from './types'

/**
 * Action Constants
 */
export const CART_ADD_PRODUCT = 'SHOP/CART_ADD_PRODUCT'
export const CART_REMOVE_PRODUCT = 'SHOP/CART_REMOVE_PRODUCT'
export const CART_EMPTY = 'SHOP/CART_EMPTY'

export const CART_ADD_ADDON = 'SHOP/CART_ADD_ADDON'
export const CART_REMOVE_ADDON = 'SHOP/CART_REMOVE_ADDON'

export const CHECK_PRODUCT_AVAILABILITY = 'SHOP/CHECK_PRODUCT_AVAILABILITY'
export const CHECK_PRODUCT_AVAILABILITY_SUCCESS =
  'SHOP/CHECK_PRODUCT_AVAILABILITY_SUCCESS'
export const CHECK_PRODUCT_AVAILABILITY_FAILURE =
  'SHOP/CHECK_PRODUCT_AVAILABILITY_FAILURE'

export const LOAD_PRODUCTS = 'SHOP/LOAD_PRODUCTS'
export const LOAD_PRODUCTS_SUCCESS = 'SHOP/LOAD_PRODUCTS_SUCCESS'
export const LOAD_PRODUCTS_FAILURE = 'SHOP/LOAD_PRODUCTS_FAILURE'

export const TOGGLE_CART = 'SHOP/TOGGLE_CART'
export const SHOW_CART = 'SHOP/SHOW_CART'
export const SHOP_CHECK_PROPERTY_CHANGES = 'SHOP/SHOP_CHECK_PROPERTY_CHANGES'

export const SET_MONITOR_FIELD_VALUE = 'SHOP/SET_MONITOR_FIELD_VALUE'

// OLD
export const SAVE_PRODUCTS = 'STEP/SAVE_PRODUCTS'
export const SAVE_PRODUCTS_SUCCESS = 'STEP/SAVE_PRODUCTS_SUCCESS'
export const ADD_CART_CATEGORY_PRODUCT = 'STEP/ADD_CART_CATEGORY_PRODUCT'
export const CHECK_CART_VARIABLE_PRICE_CHANGES =
  'STEP/CHECK_CART_VARIABLE_PRICE_CHANGES'

const initialState: ShopState = {
  isLoading: true,
  error: null,
  // Data & lists
  cart: [],
  products: [],
  productsById: {},

  // Settings
  showCart: false,
  showCartWithItems: true,
  productSettings: { categories: [] },
  productPricingBusiness: false,
  productApi: {
    endpoint: '',
    queryTemplate: {},
    queryStatic: {},
  },
  productApiV2: {
    endpoint: '',
    queryTemplate: {},
    queryStatic: {},
  },
  enetApi: {
    queryTemplate: {},
    gridOperatorAmount: '',
    endPoint: '',
  },
  productPriceInterval: 'YEARLY',
  items: {},
  productPriceByProductId: {},
  monitorFieldsLastValue: {},
}

// Reducer default & handler
export default function reducer(
  state: ShopState = initialState,
  action: TypedAction<any>,
): ShopState {
  const handler = action && ACTION_HANDLERS[action.type]

  return handler
    ? produce(state, (draft) => handler(draft, action.payload))
    : state
}
/**
 * Main Reducer
 */
const ACTION_HANDLERS: ActionHandlers<ShopState, any> = {
  /**
   *  Get steps from configuration
   */
  [LOAD_CONFIGURATION_SUCCESS]: (
    draft: ShopState,
    { config }: { config: WidgetConfig },
  ) => {
    // Loading
    // draft.isLoading = false

    if (config.cart) {
      console.warn("Configuration 'cart' deprecated, rename to 'shop'")
    }
    const shop = config.shop || config.cart

    // Cart
    return { ...draft, ...shop, isLoading: false }
  },
  [LOAD_CONFIGURATION_FAILURE]: (draft: ShopState) => {
    draft.isLoading = false
  },
  // CART
  [CART_ADD_PRODUCT]: (
    draft: ShopState,
    { productId, productCategory, reset = false, quantity = 1 },
  ) => {
    if (reset) draft.cart = []
    const cart = draft.cart
    const foundIndex = cart.findIndex((item) => item.id === productId)

    // If cart has already an item with the same id
    if (cart[foundIndex]) {
      // Just increase quantity
      cart[foundIndex].quantity = cart[foundIndex].quantity + quantity
    } else {
      // Add to cart
      cart.push({ id: productId, productCategory, quantity })
    }
  },
  [CART_REMOVE_PRODUCT]: (draft: ShopState, { productId }) => {
    const cart = draft.cart
    const foundIndex = cart.findIndex((item) => item.id === productId)

    if (cart[foundIndex]) {
      cart.splice(foundIndex)
    } else {
      console.error('Tried to remove an cart item by id which does not exist')
    }
  },
  [CART_EMPTY]: (draft: ShopState) => {
    const cart = draft.cart

    cart.splice(0, cart.length)
  },
  [CART_ADD_ADDON]: (
    draft: ShopState,
    { addonId, productId, quantity = 1, reset },
  ) => {
    const cart = draft.cart
    const productIndex = cart.findIndex((item) => item.id === productId)
    const cartProduct = cart[productIndex]

    if (!cartProduct?.addons || reset) {
      cartProduct.addons = []
      cartProduct?.addons?.push({ id: addonId, quantity })
    } else {
      let addonIndex = cartProduct.addons?.findIndex(
        (addon) => addon.id === addonId,
      )

      if (!cartProduct.addons[addonIndex]) {
        cartProduct?.addons?.push({ id: addonId, quantity })
        addonIndex = cartProduct?.addons.length - 1
      }

      cartProduct.addons[addonIndex].quantity = quantity
    }
  },
  [CART_REMOVE_ADDON]: (draft: ShopState, { addonId, productId }) => {
    const cart = draft.cart
    const productIndex = cart.findIndex((item) => item.id === productId)

    cart[productIndex].addons = cart[productIndex]?.addons?.filter(
      (item) => item.id !== addonId,
    )
  },
  [LOAD_PRODUCTS]: (draft: ShopState) => {
    draft.isLoading = true
  },
  [LOAD_PRODUCTS_SUCCESS]: (
    draft: ShopState,
    { products }: { products: ProductAPI[] },
  ) => {
    const { products: productList, productsById } = reduceProductsData(products)

    draft.products = productList
    draft.productsById = productsById
    draft.isLoading = false
  },
  [LOAD_PRODUCTS_FAILURE]: (draft: ShopState, { error }) => {
    draft.isLoading = false
    console.error(error)
  },
  [ADD_CART_CATEGORY_PRODUCT]: (
    draft: ShopState,
    {
      productCategory,
      productId,
    }: { productCategory: ProductCategory; productId: string },
  ) => {
    // TODO: Workaround problems
    // 1. SET_INPUT_VALUE action is called twice with the same values
    // 2. product selection by radio does only return the new value - but the product selected before should be deselected and removed from the cart

    // 2: Override existing selected products of the category (only one product per category possible in the moment)
    draft.items = { ...draft.items, [productCategory]: [productId] }
  },
  [SET_MONITOR_FIELD_VALUE]: (
    draft: ShopState,
    { data }: { data: ObjectKeyString<unknown> },
  ) => {
    // filter returns only the cart products which do not match the one to be removed
    Object.keys(data).forEach(
      (propertyName) =>
        (draft.monitorFieldsLastValue[propertyName] = data[propertyName]),
    )
  },
  [TOGGLE_CART]: (draft: ShopState) => {
    draft.showCart = !draft.showCart
  },
  [SHOW_CART]: (
    draft: ShopState,
    { showShoppingCart }: { showShoppingCart: boolean },
  ) => {
    if (showShoppingCart !== undefined) {
      draft.showCart = showShoppingCart || false
    }
  },
  [PREVIEW_STEP]: (draft: ShopState, { step }: { step: Step }) => {
    draft.showCart = step.logic.showCart || false
  },
  [RESET_GLOBAL]: () => {
    return initialState
  },
}

/**
 * ACTIONS
 */
// PRODUCTS
export const checkProductAvailability = () => ({
  type: CHECK_PRODUCT_AVAILABILITY,
})
export const checkProductAvailabilitySuccess = (
  productAvailability: ProductAvailabilityAPI,
) => ({
  type: CHECK_PRODUCT_AVAILABILITY_SUCCESS,
  payload: { productAvailability },
})
export const checkProductAvailabilityFailure = (error: Error) => ({
  type: CHECK_PRODUCT_AVAILABILITY_FAILURE,
  payload: { error },
})
export const loadProducts = () => ({
  type: LOAD_PRODUCTS,
})
export const loadProductsSuccess = (products: ProductAPI[] | undefined) => ({
  type: LOAD_PRODUCTS_SUCCESS,
  payload: { products },
})
export const loadProductsFailure = (error: Error) => ({
  type: LOAD_PRODUCTS_FAILURE,
  payload: { error },
})

export const shopCheckPropertyChanges = (): Action => ({
  type: SHOP_CHECK_PROPERTY_CHANGES,
})

// CART
export const cartAddProduct = (
  productId: string,
  productCategory: ProductCategory,
  reset?: boolean,
  quantity?: number,
) => ({
  type: CART_ADD_PRODUCT,
  payload: { productId, productCategory, reset, quantity },
})
export const cartRemoveProduct = (productId: string) => ({
  type: CART_REMOVE_PRODUCT,
  payload: { productId },
})
export const cartEmpty = () => ({
  type: CART_EMPTY,
})
export const cartAddAddon = (
  addonId: string,
  productId: string,
  quantity?: number,
  reset?: boolean,
) => ({
  type: CART_ADD_ADDON,
  payload: { addonId, productId, quantity, reset },
})
export const cartRemoveAddon = (addonId: string, productId: string) => ({
  type: CART_REMOVE_ADDON,
  payload: { addonId, productId },
})

// OLD
export const saveProducts = (
  productsData: Product[],
  stepId: StepId,
  targetProperty: string,
): TypedAction<unknown> => ({
  type: SAVE_PRODUCTS,
  payload: { productsData, stepId, targetProperty },
})
export const saveProductsSuccess = (
  stepId: StepId,
  targetProperty: string,
): TypedAction<unknown> => ({
  type: SAVE_PRODUCTS_SUCCESS,
  payload: { stepId, targetProperty },
})

export const addCartCategoryProduct = (
  productCategory: ProductCategory,
  productId: string,
): TypedAction<unknown> => ({
  type: ADD_CART_CATEGORY_PRODUCT,
  payload: { productCategory, productId },
})

export const setMonitorFieldValue = (
  data: ObjectKeyString<unknown>,
): TypedAction<{
  data: ObjectKeyString<unknown>
}> => ({
  type: SET_MONITOR_FIELD_VALUE,
  payload: { data },
})

export const toggleCart = (): Action => ({
  type: TOGGLE_CART,
})

export const showCart = (
  showShoppingCart?: boolean,
): TypedAction<{
  showShoppingCart?: boolean
}> => ({
  type: SHOW_CART,
  payload: { showShoppingCart },
})

/**
 * Helper Data Reducer
 */
const reduceProductsData = (productsData: ProductAPI[]) => {
  const productsById = {} as ObjectKeyString<Product>
  const products = productsData.map((product) => {
    const localProduct: Product = {
      id: product.id.toString(),
      productCategoryId: product.category_id,
      productCategory: product.type.toUpperCase() as ProductCategory,
      name: product.name,
      description: product.description,
      provider: product.provider,
      customFields: product.custom_fields,
      labels: product.labels,
      price: product.options && product.options[0],
      addons: product.addons,
      image: {
        src: product?.images?.[0]?.src,
        thumbnail_src: product?.images?.[0]?.thumbnail_src,
      },
      attachments: product.attachments,
    }

    productsById[localProduct.id] = localProduct

    return localProduct
  })

  return { products, productsById }
}
