import { ShopState } from 'modules/shop/types'

export const getCartTotalSummary = (shop: ShopState) => {
  const cart = shop.cart

  return cart.reduce(
    (summery, current) => {
      const currentProduct = shop.products.find(
        (product) => product.id === current.id,
      )
      const addonPrices = currentProduct?.addons?.reduce(
        (addonsSummery, currentAddon) => {
          return {
            addonsNet:
              addonsSummery.addonsNet +
              (currentAddon.options[0]?.recurring_price?.monthly?.amount_net ||
                0),
            addonsVat:
              addonsSummery.addonsVat +
              (currentAddon.options[0]?.recurring_price?.monthly?.amount_vat ||
                0),
            addonsGross:
              addonsSummery.addonsGross +
              (currentAddon.options[0]?.recurring_price?.monthly
                ?.amount_gross || 0),
            addonsOneTimeNet:
              addonsSummery.addonsOneTimeNet +
              (currentAddon.options[0]?.one_time_price?.amount_net || 0),
            addonsOneTimeGross:
              addonsSummery.addonsOneTimeGross +
              (currentAddon.options[0]?.one_time_price?.amount_gross || 0),
          }
        },
        {
          addonsNet: 0,
          addonsVat: 0,
          addonsGross: 0,
          addonsOneTimeNet: 0,
          addonsOneTimeGross: 0,
        },
      )

      return {
        net:
          summery.net +
          (currentProduct?.price?.estimated_monthly_price?.amount_net || 0) +
          (addonPrices?.addonsNet || 0),
        gross:
          summery.gross +
          (currentProduct?.price?.estimated_monthly_price?.amount_gross || 0) +
          (addonPrices?.addonsGross || 0),
        vat:
          summery.vat +
          (currentProduct?.price?.estimated_monthly_price?.amount_gross || 0) -
          (currentProduct?.price?.estimated_monthly_price?.amount_net || 0) +
          (addonPrices?.addonsVat || 0),
        oneTimeNet:
          summery.oneTimeNet +
          (currentProduct?.price?.one_time_price?.amount_net || 0) +
          (addonPrices?.addonsOneTimeNet || 0),
        oneTimeGross:
          summery.oneTimeGross +
          (currentProduct?.price?.one_time_price?.amount_gross || 0) +
          (addonPrices?.addonsOneTimeGross || 0),
      }
    },
    { net: 0, vat: 0, gross: 0, oneTimeNet: 0, oneTimeGross: 0 },
  )
}
