import { BlueprintElements } from 'types/blueprint-config'

import { BuildingBlock } from './types'

type CheckBoxBlockValues = {
  scope: string
  label: string
  required?: boolean
}

export const buildCheckboxBlock = (
  values: CheckBoxBlockValues,
): BuildingBlock => {
  const schema = {
    [values.scope]: {
      type: 'boolean',
    },
  }

  const uischema: BlueprintElements[] = [
    {
      type: 'GridItemLayout',
      options: {
        xs: 12,
      },
      elements: [
        {
          type: 'Control',
          label: values.label,
          scope: `#/properties/${values.scope}`,
        },
      ],
    },
  ]

  const required = values.required ? [values.scope] : []

  return {
    schema: schema,
    uischema: uischema,
    required: required,
  }
}
