import { Grid } from '@epilot/base-elements'
import { MuiHidden } from '@epilot/components'
import { LayoutProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

import DefaultLayoutItem from './default-layout-item'
import ResponsiveLayoutItem from './responsive-layout-item'
import { ExtendedHorizontalLayout } from './types'

const HorizontalLayoutRenderer = ({
  uischema,
  renderers,
  schema,
  path,
  enabled,
  visible,
}: LayoutProps) => {
  const layout = uischema as ExtendedHorizontalLayout
  const style = uischema?.options?.style

  const direction = layout.reverse ? 'row-reverse' : 'row'

  const LayoutItem = layout.responsive
    ? ResponsiveLayoutItem
    : DefaultLayoutItem

  return (
    <MuiHidden xsUp={!visible}>
      <Grid container direction={direction} spacing={2} style={style}>
        {layout.elements.map((child, index) => (
          <LayoutItem gridProps={layout.gridProps} key={index}>
            <JsonFormsDispatch
              enabled={enabled}
              path={path}
              renderers={renderers}
              schema={schema}
              uischema={child}
            />
          </LayoutItem>
        ))}
      </Grid>
    </MuiHidden>
  )
}

export const horizontalLayoutTester = rankWith(2, uiTypeIs('HorizontalLayout'))

export default withJsonFormsLayoutProps(HorizontalLayoutRenderer)
