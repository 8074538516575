// @flow

import { all } from 'redux-saga/effects'

import appSaga from './app/saga'
import shopSaga from './shop/saga'
import stepSaga from './step/saga'

export default function* saga(): Generator {
  yield all([
    stepSaga(),
    shopSaga(),
    /* needs to be after all other sagas */
    appSaga(),
  ])
}
