import { defaultConsumptionCalculatorSchemaProperties } from '../../../../../assets/power-and-gas/config/schemas/consumption-calculator.schema'
import { CartItemReq } from '../../../../../lib/api/types'
import { ProductCategory, ShopState } from '../../../../../modules/shop/types'
import { GlobalResultData } from '../../../../../modules/step/types'

export const getCartItems = (
  shop: ShopState,
  options: {
    data?: GlobalResultData
    consumptions?: { category: ProductCategory; value: number }[]
  },
): CartItemReq[] => {
  const cartItems: CartItemReq[] = []

  shop.cart.forEach(({ id, productCategory }) => {
    const product = shop.products.find((product) => product.id === id)
    let consumption = 0

    if (!product)
      throw Error('Product in cart does not equal to available products')

    if (options.consumptions) {
      const foundConsumption = options.consumptions.find(
        (consumption) => consumption.category === productCategory,
      )

      if (!foundConsumption)
        throw Error('No Consumption found for product category')
      consumption = foundConsumption.value
    } else if (options.data) {
      switch (productCategory) {
        case 'POWER':
          consumption =
            options.data[
              defaultConsumptionCalculatorSchemaProperties.power_consumption
                .name
            ]['amount'] || 0
          break
        case 'GAS':
          consumption =
            options.data[
              defaultConsumptionCalculatorSchemaProperties.gas_consumption.name
            ]['amount'] || 0
          break
      }
    }

    if (!product.price || !product.price.id)
      throw Error('The selling option id must be defined')

    cartItems.push({
      product_id: id,
      selling_option_id: product.price.id.toString(),
      product_type: product.productCategory,
      consumption:
        typeof consumption === 'string' ? Number(consumption) : consumption,
      interval: shop.productPriceInterval,
    })
  })

  return cartItems
}
