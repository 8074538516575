import {
  Box,
  Card,
  CardContent,
  Grid,
  H1,
  H2,
  Typography,
} from '@epilot/base-elements'
import {
  Product as ProductTile,
  DownloadItem,
  DownloadsTable,
  EnumProductControl,
  EnumProductControlData,
  ExpandableText,
  ProductDetailCard,
  PricingItem,
} from '@epilot/base-modules'
import { ControlProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { priceTitles } from 'components/hooks/usePrice'
import config from 'config'
import { SellingOption } from 'lib/api.types'
import {
  generatePriceSheetData,
  hasPriceSheet,
} from 'lib/PriceSheet/generatePriceSheetPackage'
import { getInitialConfig } from 'modules/app/selectors'
import { cartAddAddon, cartRemoveAddon } from 'modules/shop/reducer'
import {
  getCart,
  getProductById,
  getProductPriceInterval,
  getProductPricingBusiness,
  getProductsByCategory,
} from 'modules/shop/selectors'
import { Addon, ProductCategory } from 'modules/shop/types'
import { PayloadResults } from 'modules/step/getResultsFromMapping/types'
import { getAllResultData } from 'modules/step/selectors'
import { GlobalAppState } from 'modules/types'

import { Builder } from '../../../../src/types/widget-config'

import ProductDetailFeatures from './product-detail-features'
import ProductDetailPricingCard from './product-detail-pricing-card'

export type ProductDetailRendererOptions = {
  category: ProductCategory
  showTotalAnnualPrice?: boolean
  showAnnualBasePrice?: boolean
  showAddons?: boolean
  multipleAddonsSelection?: boolean
  showDescription?: boolean
}

const ProductDetailRenderer = ({
  uischema,
  handleChange,
  path,
  data,
}: ControlProps): React.ReactElement | null => {
  const dispatch = useDispatch()
  const allResult = useSelector(getAllResultData)
  const configuration = useSelector(getInitialConfig)
  const builder = configuration?.builder

  const {
    category,
    showTotalAnnualPrice = true,
    showAnnualBasePrice = true,
    showAddons = true,
    multipleAddonsSelection = true,
    showDescription = true,
  } = (uischema?.options || {}) as ProductDetailRendererOptions
  const resultData = useSelector(getAllResultData)
  const pricingBusiness = useSelector(getProductPricingBusiness)
  const priceInterval = useSelector(getProductPriceInterval)

  const { previewMode, addon_ids } = resultData

  let productId = ''
  // preselect first product matching productCategory when in JB mode
  const products = useSelector((state: GlobalAppState) =>
    getProductsByCategory(state, category),
  )
  const cart = useSelector(getCart)

  // define productId. previewMode is set when journey is seen inside the JB preview.
  if (previewMode) {
    const productFirstInCategory = products.find(
      (product) => product.productCategory === category,
    )

    if (productFirstInCategory) {
      productId = productFirstInCategory.id
    }
  } else {
    const cartItems = cart.filter((item) => item.productCategory === category)

    if (cartItems.length > 1)
      throw Error('Multiple products for detail view detected')

    productId = cartItems[0] && cartItems[0].id
  }

  const product = useSelector((state: GlobalAppState) =>
    getProductById(state, productId),
  )

  // bail early if no productId determined
  if (!productId) {
    return (
      <Box mt={6} textAlign="center" width="100%">
        <H1 color="textPrimary">
          Bitte wählen Sie im Produktauswahl Schritt mindestens ein Produkt aus.
        </H1>
      </Box>
    )
  }

  // Destructure properties from product
  const {
    name,
    customFields,
    addons,
    description,
    price,
    image,
    attachments,
  } = product

  const {
    Claim: subtitle,
    'Beschreibung Konditionen und Zusatzinfos': conditions,
    'Titel Tarifmerkmal': tariffDetailsTitle,
    'Tarifmerkmal 01': tariffDetails1,
    'Tarifmerkmal 02': tariffDetails2,
    'Tarifmerkmal 03': tariffDetails3,
    'Tarifmerkmal 04': tariffDetails4,
    'Tarifmerkmal 05': tariffDetails5,
    'Tarifmerkmal 06': tariffDetails6,
    'Tarifmerkmal 07': tariffDetails7,
    'Titel Zusatzoptionen': addOnsTitle,
    Steuerinformationen: taxInformation,
    'Titel Konditionen und Zusatzinfos': additionalInformationTitle,
    Fußnote: footnote,
  } = customFields
  const tariffDetails = [
    tariffDetails1,
    tariffDetails2,
    tariffDetails3,
    tariffDetails4,
    tariffDetails5,
    tariffDetails6,
    tariffDetails7,
  ]
  const includePriceSheet = hasPriceSheet(product, builder as Builder)
  const originalFields = attachments?.map(({ src, name }) => ({
    fileUrl: config.IMAGE_HOST + src,
    fileName: name,
    icon: 'description',
  }))
  const priceSheetFile = [
    {
      fileUrl: '',
      fileName: 'Preisblatt',
      icon: 'description',
    },
  ]
  const filesWithPriceSheet = includePriceSheet
    ? originalFields
      ? originalFields.concat(priceSheetFile)
      : priceSheetFile
    : originalFields
  const priceSheetData = generatePriceSheetData(
    allResult as PayloadResults,
    product,
    builder as Builder,
  )
  const productImage = image?.src ? config.IMAGE_HOST + image.src : undefined

  const filteredAddons: Addon[] = []

  if (typeof addon_ids === 'string') {
    const selectedAddonIds = addon_ids.split(',')

    if (selectedAddonIds) {
      selectedAddonIds.forEach((id) => {
        const addon = addons.find((addon) => addon.id.toString() === id)

        if (addon) {
          filteredAddons.push(addon)
        }
      })
    }
  } else if (Array.isArray(addon_ids)) {
    addon_ids.forEach((item) => {
      const addon = addons.find(
        (addon) => addon.id === item.id && item.category === category,
      )

      if (addon) {
        filteredAddons.push(addon)
      }
    })
  }

  const resetCartAddons = () => {
    const cartItems = cart.filter((item) => item.productCategory === category)

    if (cartItems[0] && cartItems[0].addons) {
      cartItems[0].addons.forEach((addon) => {
        dispatch(cartRemoveAddon(addon.id, cartItems[0].id))
      })
    }
  }

  const handleChangeAddonSelection = async (
    selectedAddons:
      | EnumProductControlData
      | EnumProductControlData[]
      | undefined,
  ) => {
    if (
      (Array.isArray(selectedAddons) && selectedAddons.length === 0) ||
      !selectedAddons
    ) {
      resetCartAddons()
    }
    if (multipleAddonsSelection && Array.isArray(selectedAddons)) {
      selectedAddons?.forEach(
        (
          { product: addon, quantity }: EnumProductControlData,
          index: number,
        ) => {
          if (addon.id)
            dispatch(
              cartAddAddon(
                addon.id.toString(),
                productId,
                quantity,
                index === 0,
              ),
            )
        },
      )
    } else if (selectedAddons && !Array.isArray(selectedAddons)) {
      const { product: addon, quantity } = selectedAddons

      if (addon.id)
        dispatch(cartAddAddon(addon.id.toString(), productId, quantity, true))
    }

    handleChange(
      path,
      !selectedAddons ||
        (Array.isArray(selectedAddons) && selectedAddons.length === 0)
        ? undefined
        : multipleAddonsSelection
        ? selectedAddons
        : [selectedAddons],
    )
  }

  let addonTiles: ProductTile[] = []

  if (showAddons && filteredAddons)
    addonTiles = filteredAddons.map((addon) => {
      return getAddonTile({
        addon,
        pricingBusiness,
        priceInterval,
      })
    })

  // Clean data (selected addons) if product selection changed
  if (data && data.length > 0) {
    const addonId = data[0].product.id
    const cartItems = cart.filter((item) => item.productCategory === category)

    if (
      cartItems[0] &&
      cartItems[0].addons?.filter(
        (addonCartitem) => addonCartitem.id === addonId.toString(),
      ) === undefined
    ) {
      handleChange(path, undefined)
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <ProductDetailCard
          product={{
            productName: name,
            productDescription: showDescription ? description : undefined,
            productImage,
            productSubTitle: subtitle,
            prices: [],
          }}
        />
      </Grid>
      {tariffDetails?.length > 0 && (
        <ProductDetailFeatures
          features={tariffDetails}
          title={tariffDetailsTitle}
        />
      )}
      {showAddons && filteredAddons && filteredAddons.length > 0 && (
        <React.Fragment>
          <Grid item xs={12}>
            <H2 color="textPrimary">{addOnsTitle}</H2>
            <EnumProductControl
              actionButtonLabel="Auswählen"
              actionButtonRemoveLabel="Ausgewählt"
              balanceProductFeatures={false}
              highlightSelectedProduct={true}
              itemContainerProps={{ md: 3 }}
              onChange={handleChangeAddonSelection}
              options={addonTiles}
              selectionType={multipleAddonsSelection ? 'many' : 'single'}
              value={
                data && data.length > 0
                  ? multipleAddonsSelection
                    ? data
                    : data[0]
                  : undefined
              }
            />
          </Grid>
        </React.Fragment>
      )}
      <ProductDetailPricingCard
        price={price}
        showAnnualBasePrice={!!showAnnualBasePrice}
        showTotalAnnualPrice={!!showTotalAnnualPrice}
        taxInformation={taxInformation}
      />
      {(additionalInformationTitle || conditions) && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ExpandableText
                text={conditions}
                title={additionalInformationTitle}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
      {filesWithPriceSheet && filesWithPriceSheet?.length > 0 && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <DownloadsTable
                headingTitle={'Dokumente'}
                items={filesWithPriceSheet as DownloadItem[]}
                priceSheetData={priceSheetData}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
      {footnote && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="caption">{footnote}</Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  )
}

function getAddonTile({
  addon,
  pricingBusiness = true,
  priceInterval = 'MONTHLY',
}: {
  addon: Addon
  pricingBusiness?: boolean
  priceInterval?: string
}) {
  const { id, name, options, images } = addon
  const price = options[0] as SellingOption
  const { oneTimePrice, basePricePerMonth, basePricePerYear } = getAddonPrice(
    price,
    pricingBusiness,
  )
  const addonImage =
    images && images?.length > 0 && images[0]?.src
      ? config.IMAGE_HOST + images[0]?.src
      : undefined
  const durationNumber = price.duration?.slice(0, -1)
  const durationInterval = price.duration?.slice(-1)
  const durationText = `Zahlungslaufzeit: ${durationNumber} ${
    durationInterval === 'M' ? 'Monate' : 'Jahre'
  }.`

  const prices: PricingItem[] = []
  const basePrice =
    priceInterval === 'MONTHLY' ? basePricePerMonth : basePricePerYear
  const basePriceInterval =
    priceInterval === 'MONTHLY' ? 'monatlich' : 'jährlich'

  if (basePrice)
    prices.push({
      price: basePrice,
      title: basePriceInterval,
      priceCurrency: '€',
    })
  if (oneTimePrice)
    prices.push({ price: oneTimePrice, title: 'einmalig', priceCurrency: '€' })

  // Parse addon information to addon tile component
  return {
    id: id?.toString(),
    productImage: addonImage,
    productName: name,
    footnote: durationNumber ? durationText : undefined,
    prices,
  }
}

function getAddonPrice(price: SellingOption, pricingBusiness: boolean) {
  // Destructure price
  const { recurring_price: basePrice, one_time_price: oneTimePrice } = price
  const {
    monthly: basePricePerMonth,
    yearly: basePricePerYear,
    base_month_price: baseMonthPrice,
  } = basePrice || {}

  const variablePrices = []

  if (baseMonthPrice)
    variablePrices.push({
      title: priceTitles.BASE_MONTH_PRICE,
      price: pricingBusiness
        ? baseMonthPrice.amount_net
        : baseMonthPrice.amount_gross,
      priceCurrency: '€',
    })
  if (basePricePerYear || baseMonthPrice)
    variablePrices.push({
      title: priceTitles.BASE_PRICE_PER_YEAR,
      price: pricingBusiness
        ? (baseMonthPrice?.amount_net ? baseMonthPrice.amount_net * 12 : 0) +
          (basePricePerYear?.amount_net || 0)
        : (baseMonthPrice?.amount_gross
            ? baseMonthPrice.amount_gross * 12
            : 0) + (basePricePerYear?.amount_gross || 0),
      priceCurrency: '€',
    })

  return {
    basePricePerMonth:
      basePricePerMonth &&
      (pricingBusiness
        ? basePricePerMonth?.amount_net
        : basePricePerMonth?.amount_gross),
    basePricePerYear:
      basePricePerYear &&
      (pricingBusiness
        ? basePricePerYear?.amount_net
        : basePricePerYear?.amount_gross),
    oneTimePrice:
      oneTimePrice &&
      (pricingBusiness ? oneTimePrice.amount_net : oneTimePrice?.amount_gross),
  }
}

export const productDetailTester: RankedTester = rankWith(
  3,
  uiTypeIs('ProductDetail'),
)

export default withJsonFormsControlProps(ProductDetailRenderer)
