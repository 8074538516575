import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import config from './config'

/* ----------------------------------------------------------------------------
 * Development tools
 * ------------------------------------------------------------------------- */
if ((config.IS_DEV as any) === 'never') {
  // reactive if you want to monitor rerender issues
  // Rerender
  /* eslint-disable */
  const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js')
  /* eslint-enable */

  whyDidYouRender(React, {
    trackAllPureComponents: true,
    collapseGroups: true,
    // trackExtraHooks: [[ReactRedux, 'useSelector']],
  })
}

/* ----------------------------------------------------------------------------
 * React app injection
 * ------------------------------------------------------------------------- */
const RenderApp = <App />
const reactEntryPoint = document.getElementById('react-root') as HTMLElement

if (config.AUTO_RESIZE) {
  reactEntryPoint.classList.add('static') // removes position absolute if autoresize flag passed
  reactEntryPoint.setAttribute('data-iframe-height', '')
}
ReactDOM.render(RenderApp, reactEntryPoint)
