import { ThemingOptions } from 'types/widget-config'

export const epilotStyling = {
  theming_options: {
    palette: {
      primary: '#0F5B99',
    },
    background: {
      default: '#E0EDEF',
    },
    props: {
      client_logo_url: '',
      content_max_width: 1200,
    },
  } as ThemingOptions,
  useBaseElementsLibrary: true,
}

export const dew21Styling = {
  theming_options: {
    palette: {
      primary: '#0c68b0',
    },
    background: {
      default: '#EDF2F7',
    },
    props: {
      client_logo_url:
        'https://staging.epilot.cloud/styles/728/7066103/logo/27e64fd0-33e3-11eb-b9a0-f9420bcdfcf8_DEW21-Logo-white.png',
      content_max_width: 1200,
    },
  } as ThemingOptions,
  useBaseElementsLibrary: true,
}
