import {
  BodyText,
  H1,
  Icon,
  StackSpacing,
  IconName,
  Button,
} from '@epilot/base-elements'
import { LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'
import { useDispatch } from 'react-redux'

import { exitFullscreen } from 'modules/app/reducer'

export type ConfirmationMessageRendererOptions = {
  icon?: IconName
  title?: string
  text?: string
  closeButtonText?: string
  showCloseButton?: boolean
}

const ConfirmationMessageRenderer = ({
  uischema,
}: LayoutProps): React.ReactElement | null => {
  const dispatch = useDispatch()
  const {
    title = '',
    text = '',
    icon = 'check',
    closeButtonText = 'schließen',
    showCloseButton = true,
  } = (uischema?.options || {}) as ConfirmationMessageRendererOptions

  const handleCloseButtonClick = () => {
    dispatch(exitFullscreen())
  }

  return (
    <StackSpacing alignItems="center" scale={2}>
      <Icon color="primary" fontSize="large" name={icon} />
      <H1 color="textPrimary">{title}</H1>
      <BodyText>{text}</BodyText>
      {showCloseButton && (
        <Button
          color="primary"
          onClick={handleCloseButtonClick}
          size="large"
          variant="contained"
        >
          {closeButtonText}
        </Button>
      )}
    </StackSpacing>
  )
}

export const confirmationMessageTester: RankedTester = rankWith(
  1,
  uiTypeIs('ConfirmationMessage'),
)

export default withJsonFormsLayoutProps(ConfirmationMessageRenderer)
