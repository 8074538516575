import {
  CommissioningInstallation,
  GeneratorInstallation,
  Installation,
  InverterInstallation,
  SolarModuleInstallation,
  StorageInstallation,
} from 'components/renderer/installations-module/types'
import { GlobalResultData } from 'modules/step/types'

import { installationsScope } from './constant'
import { CustomFieldsType, QuestionType } from './fixMissingLabel'
const INSTALLER_LABELS = {
  PHONE_NUMBER_LABEL: 'Telefonnummer (Vertragsinstallateur)',
  IDENTIFIER_NUMBER_LABEL: 'Ausweisnummer (Vertragsinstallateur)',
  EMAIL_ADDRESS_LABEL: 'E-Mail-Addresse (Vertragsinstallateur)',
  COMPANY_NAME_LABEL: 'Firmenname (Vertragsinstallateur)',
}

export const generateListCustomFields = (
  data: GlobalResultData,
): Array<CustomFieldsType> => {
  let installationsCustomerFields: Array<CustomFieldsType> = []

  installationsScope.forEach((installationScope: string) => {
    installationsCustomerFields = installationsCustomerFields.concat(
      customFieldsWithInstallation(data, installationScope),
    )
  })

  if (installationsCustomerFields.length === 0) {
    if (data.organizationId && data.organizationId === '6288298') {
      return [...data.custom_fields, ...addPowerReading(data)]
    }

    return [...data.custom_fields, ...addPowerReading(data)]
  } else
    return [
      ...data.custom_fields,
      ...removeEmptyInstallationValue(installationsCustomerFields),
    ]
}

const removeEmptyInstallationValue = (
  installationsCustomFields: Array<CustomFieldsType>,
) => {
  const filteredInstallationCustomFields = installationsCustomFields.filter(
    (installationCustomField) => !!installationCustomField.value,
  )

  return filteredInstallationCustomFields
}

const addPowerReading = (data: GlobalResultData) => {
  const values: any = []

  if (
    data.fields.power_readings &&
    data.fields.power_readings.length &&
    data.fields.power_readings.length > 0
  ) {
    data.fields.power_readings.forEach((reading: any, index: number) => {
      values.push(
        ...[
          {
            value: reading.meterNumber,
            label: `Zählernummer Strom ${index + 1}`,
            source: 'journey',
            readOnly: true,
          },
          {
            value: reading.meterReading,
            label: `Zählerwert Strom ${index + 1}`,
            source: 'journey',
            readOnly: true,
          },
          {
            value: reading.marketLocationId,
            label: `MaLo-ID Strom ${index + 1}`,
            source: 'journey',
            readOnly: true,
          },
        ],
      )
    })
  }

  return values
}

const customFieldsWithInstallation = (
  data: GlobalResultData,
  installationScope: string,
): Array<CustomFieldsType> => {
  const installationsPerformance = data.fields[installationScope]

  if (installationsPerformance?.selected_installations?.length > 0) {
    const installationsCustomFields = []

    const selectedInstallations = installationsPerformance?.selected_installations as Installation[]
    const annualConsumption = installationsPerformance?.annual_consumption || 0

    for (let i = 0; i < selectedInstallations.length; i++) {
      switch (selectedInstallations[i]?.installationType) {
        case 'solar':
          installationsCustomFields.push(
            {
              type: 'Text',
              value: (selectedInstallations[i] as SolarModuleInstallation)
                .manufacturer,
              label: `Solarmodul ${i + 1} Hersteller`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as SolarModuleInstallation).type,
              label: `Solarmodul ${i + 1} Typ`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as SolarModuleInstallation)
                .solarPerformance,
              label: `Solarmodul ${i + 1}: Leistung`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as SolarModuleInstallation)
                .quantity,
              label: `Solarmodul ${i + 1}: Anzahl`,
            },
            {
              type: 'Text',
              value: annualConsumption,
              label: `Gesamtleistung Solarmodule (kWp)`,
            },
          )
          break
        case 'storage':
          installationsCustomFields.push(
            {
              type: 'Text',
              value: (selectedInstallations[i] as StorageInstallation)
                .manufacturer,
              label: `Speicher ${i + 1} Hersteller`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as StorageInstallation).type,
              label: `Speicher ${i + 1}: Typ`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as StorageInstallation)
                .powerConsumption,
              label: `Speicher ${i + 1}: Leistungsaufnahme`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as StorageInstallation)
                .powerOutput,
              label: `Speicher ${i + 1}: Leistungsabgabe`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as StorageInstallation)
                .storageCapacity,
              label: `Speicher ${i + 1}: Speicherkapazität`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as StorageInstallation).quantity,
              label: `Speicher ${i + 1}: Anzahl`,
            },
            {
              type: 'Text',
              value: annualConsumption,
              label: `Gesamtspeicherkapazität Speicher (kVA)`,
            },
          )
          break
        case 'inverter':
          installationsCustomFields.push(
            {
              type: 'Text',
              value: (selectedInstallations[i] as InverterInstallation)
                .manufacturer,
              label: `Wechselrichter ${i + 1} Hersteller`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as InverterInstallation).type,
              label: `Wechselrichter ${i + 1}: Typ`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as InverterInstallation)
                .inverterPerformance,
              label: `Wechselrichter ${i + 1}: Leistung`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as SolarModuleInstallation)
                .quantity,
              label: `Wechselrichter ${i + 1}: Anzahl`,
            },
            {
              type: 'Text',
              value: annualConsumption,
              label: `Gesamtleistung Wechselrichter (kVA)`,
            },
          )
          break
        case 'generator':
          installationsCustomFields.push(
            {
              type: 'Text',
              value: (selectedInstallations[i] as GeneratorInstallation)
                .manufacturer,
              label: `Generator ${i + 1} Hersteller`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as GeneratorInstallation).type,
              label: `Generator ${i + 1}: Typ`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as GeneratorInstallation)
                .apparentPerformance,
              label: `Generator ${i + 1}: Scheinleistung`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as GeneratorInstallation)
                .activePerformance,
              label: `Generator ${i + 1}: Wirkleistung`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as GeneratorInstallation)
                .constructionType,
              label: `Generator ${i + 1}: Bauart`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as GeneratorInstallation)
                .quantity,
              label: `Generator ${i + 1}: Anzahl`,
            },
            {
              type: 'Text',
              value: annualConsumption,
              label: `Gesamtleistung Generator (kVA)`,
            },
          )
          break
        case 'commissioning':
        default:
          installationsCustomFields.push(
            {
              type: 'Text',
              value: (selectedInstallations[i] as CommissioningInstallation)
                .name,
              label: `Gerät ${i + 1}`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as CommissioningInstallation)
                .status,
              label: `Gerät ${i + 1}: Status`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as CommissioningInstallation)
                .ratedCapacity,
              label: `Gerät ${i + 1}: Nennleistung`,
            },
            {
              type: 'Text',
              value: (selectedInstallations[i] as CommissioningInstallation)
                .annualConsumption,
              label: `Gerät ${i + 1}: Jährlicher Verbrauch(kWh)`,
            },
          )
      }
    }

    return installationsCustomFields
  }

  return []
}

export const checkMissingInstallerCustomFieldsForCOM = (
  data: GlobalResultData,
  customFields: CustomFieldsType[],
): CustomFieldsType[] => {
  const isCustomFieldMissing = (label: string) => {
    return customFields.find(
      (customField: CustomFieldsType) => customField.label === label,
    )
  }
  const hasPhoneNumber = isCustomFieldMissing(
    INSTALLER_LABELS.PHONE_NUMBER_LABEL,
  )
  const hasIdentifierNumber = isCustomFieldMissing(
    INSTALLER_LABELS.IDENTIFIER_NUMBER_LABEL,
  )
  const hasEmailAddress = isCustomFieldMissing(
    INSTALLER_LABELS.EMAIL_ADDRESS_LABEL,
  )
  const hasCompanyName = isCustomFieldMissing(
    INSTALLER_LABELS.COMPANY_NAME_LABEL,
  )

  if (!hasIdentifierNumber && data?.fields?.identification_number) {
    customFields.push({
      type: 'Text',
      value: data?.fields?.identification_number,
      label: INSTALLER_LABELS.IDENTIFIER_NUMBER_LABEL,
    })
  }

  if (!hasPhoneNumber && data?.fields?.installer_phone_number) {
    customFields.push({
      type: 'Text',
      value: data?.fields?.installer_phone_number,
      label: INSTALLER_LABELS.PHONE_NUMBER_LABEL,
    })
  }

  if (!hasEmailAddress && data?.fields?.installer_email) {
    customFields.push({
      type: 'Text',
      value: data?.fields?.installer_email,
      label: INSTALLER_LABELS.EMAIL_ADDRESS_LABEL,
    })
  }

  if (!hasCompanyName && data?.fields?.installer_company_name) {
    customFields.push({
      type: 'Text',
      value: data?.fields?.installer_company_name,
      label: INSTALLER_LABELS.COMPANY_NAME_LABEL,
    })
  }

  return customFields
}

export const checkMissingInstallerQuestionsForCOM = (
  data: GlobalResultData,
): Array<QuestionType> => {
  const questions = data.questions || []

  const isQuestionMissing = (label: string) => {
    return questions.find((k: any) => k.question === label)
  }
  const hasPhoneNumber = isQuestionMissing(INSTALLER_LABELS.PHONE_NUMBER_LABEL)
  const hasIdentifierNumber = isQuestionMissing(
    INSTALLER_LABELS.IDENTIFIER_NUMBER_LABEL,
  )
  const hasEmailAddress = isQuestionMissing(
    INSTALLER_LABELS.EMAIL_ADDRESS_LABEL,
  )
  const hasCompanyName = isQuestionMissing(INSTALLER_LABELS.COMPANY_NAME_LABEL)

  if (!hasIdentifierNumber && data?.fields?.identification_number) {
    questions.push({
      answer: data?.fields?.identification_number,
      question: INSTALLER_LABELS.IDENTIFIER_NUMBER_LABEL,
    })
  }

  if (!hasPhoneNumber && data?.fields?.installer_phone_number) {
    questions.push({
      answer: data?.fields?.installer_phone_number,
      question: INSTALLER_LABELS.PHONE_NUMBER_LABEL,
    })
  }

  if (!hasEmailAddress && data?.fields?.installer_email) {
    questions.push({
      answer: data?.fields?.installer_email,
      question: INSTALLER_LABELS.EMAIL_ADDRESS_LABEL,
    })
  }

  if (!hasCompanyName && data?.fields?.installer_company_name) {
    questions.push({
      answer: data?.fields?.installer_company_name,
      question: INSTALLER_LABELS.COMPANY_NAME_LABEL,
    })
  }

  return questions
}
