import {
  Button,
  ButtonProps,
  CircularProgress,
  SmallText,
} from '@epilot/base-elements'
import { LayoutProps, UISchemaElement } from '@jsonforms/core'
import React from 'react'
import { connect, useSelector } from 'react-redux'

import { exitFullscreen } from 'modules/app/reducer'
import { goNext, goPrevious } from 'modules/step/reducer'

import { AppState } from '../../../modules/app/types'
import { GlobalAppState } from '../../../modules/types'
type ButtonControlProps = {
  goNext?: typeof goNext
  goPrevious?: typeof goPrevious
  exitFullscreen?: typeof exitFullscreen
  uischema: UISchemaElement & {
    label?: string
  }
}
const ButtonControl = (props: LayoutProps & ButtonControlProps) => {
  const { uischema, goNext, goPrevious, exitFullscreen } = props
  const { label, options } = uischema
  const { style, variant, fullWidth, color } = (uischema?.options ||
    {}) as ButtonProps

  const { isSubmitting, submitError } = useSelector<GlobalAppState, AppState>(
    (state) => state.app,
  )

  const hanleClick = () => {
    const href = options?.href

    switch (options?.action) {
      case 'next':
        goNext && goNext()
        break
      case 'submit':
        goNext && goNext(false)
        break
      case 'previous':
        goPrevious && goPrevious()
        break
      case 'close':
        exitFullscreen && exitFullscreen()
        break
      case 'link':
        if (href) {
          const newTabLink = window.open(href, '_blank')

          newTabLink?.focus()
        }
        break
      default:
        new Error('ButtonControl is expecting an action')
    }
  }

  return (
    <>
      <Button
        color={color || 'primary'}
        data-component="button"
        data-key="next"
        disabled={isSubmitting}
        endIcon={
          isSubmitting ? <CircularProgress color="inherit" size={20} /> : null
        }
        fullWidth={fullWidth}
        onClick={hanleClick}
        size="large"
        style={style}
        variant={variant}
      >
        {label}
      </Button>
      {submitError === 'NetworkError' && (
        <SmallText color="error">
          Keine Internetverbindung. Bitte erneut versuchen.
        </SmallText>
      )}
    </>
  )
}

export default connect(null, { goNext, goPrevious, exitFullscreen })(
  ButtonControl,
)
