export default [
  {
    postalcode: '01067',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01069',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01097',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01099',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01108',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01109',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01127',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01129',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01139',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01156',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01157',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01159',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01169',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01187',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01189',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01217',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01219',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01237',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01239',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01257',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01259',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01277',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01279',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01307',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01309',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01324',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01326',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01328',
    city: 'Dresden',
    countrycode: 'DE',
  },
  {
    postalcode: '01445',
    city: 'Radebeul',
    countrycode: 'DE',
  },
  {
    postalcode: '01454',
    city: 'Großerkmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01454',
    city: 'Radeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '01454',
    city: 'Ullersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01454',
    city: 'Wachau',
    countrycode: 'DE',
  },
  {
    postalcode: '01458',
    city: 'Ottendorf-Okrilla',
    countrycode: 'DE',
  },
  {
    postalcode: '01462',
    city: 'Cossebaude',
    countrycode: 'DE',
  },
  {
    postalcode: '01462',
    city: 'Gohlis',
    countrycode: 'DE',
  },
  {
    postalcode: '01462',
    city: 'Niederwartha',
    countrycode: 'DE',
  },
  {
    postalcode: '01465',
    city: 'Langebrück',
    countrycode: 'DE',
  },
  {
    postalcode: '01465',
    city: 'Liegau-Augustusbad',
    countrycode: 'DE',
  },
  {
    postalcode: '01465',
    city: 'Schönborn',
    countrycode: 'DE',
  },
  {
    postalcode: '01468',
    city: 'Moritzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '01468',
    city: 'Reichenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '01468',
    city: 'Volkersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01471',
    city: 'Berbisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01471',
    city: 'Bärnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01471',
    city: 'Großdittmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01471',
    city: 'Radeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '01471',
    city: 'Steinbach bei Moritzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '01477',
    city: 'Arnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01477',
    city: 'Fischbach',
    countrycode: 'DE',
  },
  {
    postalcode: '01477',
    city: 'Kleinwolmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01477',
    city: 'Wallroda',
    countrycode: 'DE',
  },
  {
    postalcode: '01558',
    city: 'Großenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Adelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Bauda',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Blochwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Brockwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Brößnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Böhla',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Dobra',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Dobra Bad',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Ebersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Fasanerie',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Görzig',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Kleinnaundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Kleinthiemig',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Krauschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Kraußnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Lampertswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Liega',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Nasseböhla',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Niegeroda',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Oelsnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Priestewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Quersa',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Schönborn',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Schönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Skaup',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Skäßchen',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Strauch',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Stroga',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Tauscha',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Thiendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Treugeböhla',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Uebigau',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Walda',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Weißig am Raschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Wildenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Würschnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Zabeltitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01561',
    city: 'Zschorna',
    countrycode: 'DE',
  },
  {
    postalcode: '01587',
    city: 'Riesa',
    countrycode: 'DE',
  },
  {
    postalcode: '01589',
    city: 'Oelsitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01589',
    city: 'Riesa',
    countrycode: 'DE',
  },
  {
    postalcode: '01591',
    city: 'Riesa',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Böhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Dobernitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Dösitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Gleina',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Gostewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Groptitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Hirschstein',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Ibanitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Jahnishausen',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Kalbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Leutewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Mautitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Nickritz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Panitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Plotitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Prositz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Pöhsig',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Seerhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Staucha',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Stauchitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Steudten',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Stösitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01594',
    city: 'Treben',
    countrycode: 'DE',
  },
  {
    postalcode: '01609',
    city: 'Gröditz',
    countrycode: 'DE',
  },
  {
    postalcode: '01609',
    city: 'Heidehäuser',
    countrycode: 'DE',
  },
  {
    postalcode: '01609',
    city: 'Lichtensee',
    countrycode: 'DE',
  },
  {
    postalcode: '01609',
    city: 'Nauwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '01609',
    city: 'Peritz',
    countrycode: 'DE',
  },
  {
    postalcode: '01609',
    city: 'Röderaue',
    countrycode: 'DE',
  },
  {
    postalcode: '01609',
    city: 'Streumen',
    countrycode: 'DE',
  },
  {
    postalcode: '01609',
    city: 'Tiefenau',
    countrycode: 'DE',
  },
  {
    postalcode: '01609',
    city: 'Wülknitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01612',
    city: 'Colmnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01612',
    city: 'Diesbar-Seußlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01612',
    city: 'Glaubitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01612',
    city: 'Nünchritz',
    countrycode: 'DE',
  },
  {
    postalcode: '01616',
    city: 'Strehla',
    countrycode: 'DE',
  },
  {
    postalcode: '01619',
    city: 'Gohlis',
    countrycode: 'DE',
  },
  {
    postalcode: '01619',
    city: 'Röderau-Bobersen',
    countrycode: 'DE',
  },
  {
    postalcode: '01619',
    city: 'Zeithain',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Abend',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Höfgen',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Klessig',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Kreißa',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Leuben-Schleinitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Lommatzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Mutzschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Noßlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Oberstößwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Pinnewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Priesen',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Raußlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Rüsseina',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Stahna',
    countrycode: 'DE',
  },
  {
    postalcode: '01623',
    city: 'Ziegenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '01640',
    city: 'Coswig',
    countrycode: 'DE',
  },
  {
    postalcode: '01662',
    city: 'Meißen',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Diera',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Gallschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Gauernitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Klipphausen',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Käbschütztal',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Leippen',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Lindigt',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Lösten',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Perne',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Proschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Rottewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Sachsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Scharfenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Schrebitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Schänitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Taubenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Triebischtal',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Winkwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Zehren',
    countrycode: 'DE',
  },
  {
    postalcode: '01665',
    city: 'Zetta',
    countrycode: 'DE',
  },
  {
    postalcode: '01683',
    city: 'Bodenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '01683',
    city: 'Gruna',
    countrycode: 'DE',
  },
  {
    postalcode: '01683',
    city: 'Heynitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01683',
    city: 'Karcha',
    countrycode: 'DE',
  },
  {
    postalcode: '01683',
    city: 'Neubodenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '01683',
    city: 'Nossen',
    countrycode: 'DE',
  },
  {
    postalcode: '01683',
    city: 'Rhäsa',
    countrycode: 'DE',
  },
  {
    postalcode: '01683',
    city: 'Rothschönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '01683',
    city: 'Saultitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01683',
    city: 'Starbach',
    countrycode: 'DE',
  },
  {
    postalcode: '01683',
    city: 'Tanneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '01683',
    city: 'Wolkau',
    countrycode: 'DE',
  },
  {
    postalcode: '01689',
    city: 'Niederau',
    countrycode: 'DE',
  },
  {
    postalcode: '01689',
    city: 'Weinböhla',
    countrycode: 'DE',
  },
  {
    postalcode: '01705',
    city: 'Freital',
    countrycode: 'DE',
  },
  {
    postalcode: '01705',
    city: 'Pesterwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01723',
    city: 'Grumbach',
    countrycode: 'DE',
  },
  {
    postalcode: '01723',
    city: 'Helbigsdorf-Blankenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '01723',
    city: 'Kesselsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01723',
    city: 'Mohorn',
    countrycode: 'DE',
  },
  {
    postalcode: '01723',
    city: 'Wilsdruff',
    countrycode: 'DE',
  },
  {
    postalcode: '01728',
    city: 'Bannewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01728',
    city: 'Cunnersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01728',
    city: 'Goppeln',
    countrycode: 'DE',
  },
  {
    postalcode: '01728',
    city: 'Hänichen',
    countrycode: 'DE',
  },
  {
    postalcode: '01728',
    city: 'Possendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01728',
    city: 'Rippien',
    countrycode: 'DE',
  },
  {
    postalcode: '01731',
    city: 'Hornschänke',
    countrycode: 'DE',
  },
  {
    postalcode: '01731',
    city: 'Kreischa',
    countrycode: 'DE',
  },
  {
    postalcode: '01734',
    city: 'Rabenau',
    countrycode: 'DE',
  },
  {
    postalcode: '01737',
    city: 'Braunsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01737',
    city: 'Hartha',
    countrycode: 'DE',
  },
  {
    postalcode: '01737',
    city: 'Kleinopitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01737',
    city: 'Oberhermsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01737',
    city: 'Pohrsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01737',
    city: 'Tharandt',
    countrycode: 'DE',
  },
  {
    postalcode: '01738',
    city: 'Colmnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01738',
    city: 'Dorfhain',
    countrycode: 'DE',
  },
  {
    postalcode: '01738',
    city: 'Klingenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '01744',
    city: 'Dippoldiswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '01744',
    city: 'Friedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01744',
    city: 'Malter',
    countrycode: 'DE',
  },
  {
    postalcode: '01744',
    city: 'Oberhäslich',
    countrycode: 'DE',
  },
  {
    postalcode: '01744',
    city: 'Reichstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '01744',
    city: 'Reinholdshain',
    countrycode: 'DE',
  },
  {
    postalcode: '01744',
    city: 'Röthenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '01762',
    city: 'Hartmannsdorf-Reichenau',
    countrycode: 'DE',
  },
  {
    postalcode: '01762',
    city: 'Obercarsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01762',
    city: 'Schmiedeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '01768',
    city: 'Bärenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '01768',
    city: 'Glashütte',
    countrycode: 'DE',
  },
  {
    postalcode: '01768',
    city: 'Glashütte-Dittersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01768',
    city: 'Glashütte-Johnsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '01768',
    city: 'Glashütte-Luchau',
    countrycode: 'DE',
  },
  {
    postalcode: '01768',
    city: 'Glashütte-Schlottwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01768',
    city: 'Reinhardtsgrimma',
    countrycode: 'DE',
  },
  {
    postalcode: '01773',
    city: 'Altenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '01774',
    city: 'Höckendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01774',
    city: 'Pretzschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01776',
    city: 'Hermsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01776',
    city: 'Hermsdorf-Seyde',
    countrycode: 'DE',
  },
  {
    postalcode: '01778',
    city: 'Fürstenau',
    countrycode: 'DE',
  },
  {
    postalcode: '01778',
    city: 'Fürstenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '01778',
    city: 'Geising',
    countrycode: 'DE',
  },
  {
    postalcode: '01778',
    city: 'Lauenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '01778',
    city: 'Liebenau',
    countrycode: 'DE',
  },
  {
    postalcode: '01778',
    city: 'Müglitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01796',
    city: 'Dohma',
    countrycode: 'DE',
  },
  {
    postalcode: '01796',
    city: 'Pirna',
    countrycode: 'DE',
  },
  {
    postalcode: '01796',
    city: 'Struppen',
    countrycode: 'DE',
  },
  {
    postalcode: '01809',
    city: 'Dohna',
    countrycode: 'DE',
  },
  {
    postalcode: '01809',
    city: 'Heidenau',
    countrycode: 'DE',
  },
  {
    postalcode: '01809',
    city: 'Meusegast',
    countrycode: 'DE',
  },
  {
    postalcode: '01809',
    city: 'Müglitztal',
    countrycode: 'DE',
  },
  {
    postalcode: '01809',
    city: 'Röhrsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01814',
    city: 'Bad Schandau',
    countrycode: 'DE',
  },
  {
    postalcode: '01814',
    city: 'Kleingießhübel',
    countrycode: 'DE',
  },
  {
    postalcode: '01814',
    city: 'Porschdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01814',
    city: 'Rathmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01814',
    city: 'Reinhardtsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01814',
    city: 'Schöna',
    countrycode: 'DE',
  },
  {
    postalcode: '01816',
    city: 'Bad Gottleuba',
    countrycode: 'DE',
  },
  {
    postalcode: '01816',
    city: 'Bahratal',
    countrycode: 'DE',
  },
  {
    postalcode: '01816',
    city: 'Oelsen',
    countrycode: 'DE',
  },
  {
    postalcode: '01819',
    city: 'Bahretal',
    countrycode: 'DE',
  },
  {
    postalcode: '01819',
    city: 'Berggießhübel',
    countrycode: 'DE',
  },
  {
    postalcode: '01819',
    city: 'Langenhennersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01824',
    city: 'Gohrisch',
    countrycode: 'DE',
  },
  {
    postalcode: '01824',
    city: 'Königstein',
    countrycode: 'DE',
  },
  {
    postalcode: '01824',
    city: 'Rathen',
    countrycode: 'DE',
  },
  {
    postalcode: '01824',
    city: 'Rosenthal-Bielatal',
    countrycode: 'DE',
  },
  {
    postalcode: '01825',
    city: 'Börnersdorf-Breitenau',
    countrycode: 'DE',
  },
  {
    postalcode: '01825',
    city: 'Liebstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '01829',
    city: 'Stadt Wehlen',
    countrycode: 'DE',
  },
  {
    postalcode: '01833',
    city: 'Dürrröhrsdorf-Dittersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '01833',
    city: 'Stolpen',
    countrycode: 'DE',
  },
  {
    postalcode: '01844',
    city: 'Hohwald',
    countrycode: 'DE',
  },
  {
    postalcode: '01844',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '01847',
    city: 'Lohmen',
    countrycode: 'DE',
  },
  {
    postalcode: '01848',
    city: 'Hohnstein',
    countrycode: 'DE',
  },
  {
    postalcode: '01855',
    city: 'Altendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01855',
    city: 'Hinterhermsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01855',
    city: 'Lichtenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '01855',
    city: 'Mittelndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01855',
    city: 'Ottendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01855',
    city: 'Saupsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01855',
    city: 'Sebnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01855',
    city: 'Zeughaus',
    countrycode: 'DE',
  },
  {
    postalcode: '01877',
    city: 'Bischofswerda',
    countrycode: 'DE',
  },
  {
    postalcode: '01877',
    city: 'Demitz-Thumitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01877',
    city: 'Naundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01877',
    city: 'Putzkau',
    countrycode: 'DE',
  },
  {
    postalcode: '01877',
    city: 'Rammenau',
    countrycode: 'DE',
  },
  {
    postalcode: '01877',
    city: 'Schmölln',
    countrycode: 'DE',
  },
  {
    postalcode: '01877',
    city: 'Tröbigau',
    countrycode: 'DE',
  },
  {
    postalcode: '01896',
    city: 'Lichtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '01896',
    city: 'Ohorn',
    countrycode: 'DE',
  },
  {
    postalcode: '01896',
    city: 'Pulsnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01896',
    city: 'Röderhäuser',
    countrycode: 'DE',
  },
  {
    postalcode: '01900',
    city: 'Bretnig-Hauswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '01900',
    city: 'Großröhrsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01904',
    city: 'Neukirch',
    countrycode: 'DE',
  },
  {
    postalcode: '01904',
    city: 'Ringenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '01904',
    city: 'Steinigtwolmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01904',
    city: 'Weifa',
    countrycode: 'DE',
  },
  {
    postalcode: '01906',
    city: 'Burkau',
    countrycode: 'DE',
  },
  {
    postalcode: '01909',
    city: 'Frankenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '01909',
    city: 'Großharthau',
    countrycode: 'DE',
  },
  {
    postalcode: '01909',
    city: 'Großharthau-Bühlau',
    countrycode: 'DE',
  },
  {
    postalcode: '01909',
    city: 'Großharthau-Schmiedefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '01909',
    city: 'Großharthau-Seeligstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '01917',
    city: 'Kamenz',
    countrycode: 'DE',
  },
  {
    postalcode: '01920',
    city: 'Bischheim-Häslich',
    countrycode: 'DE',
  },
  {
    postalcode: '01920',
    city: 'Crostwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01920',
    city: 'Elstra',
    countrycode: 'DE',
  },
  {
    postalcode: '01920',
    city: 'Gersdorf-Möhrsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01920',
    city: 'Nebelschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '01920',
    city: 'Oßling',
    countrycode: 'DE',
  },
  {
    postalcode: '01920',
    city: 'Panschwitz-Kuckau',
    countrycode: 'DE',
  },
  {
    postalcode: '01920',
    city: 'Ralbitz-Rosenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '01920',
    city: 'Räckelwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01920',
    city: 'Schönteichen',
    countrycode: 'DE',
  },
  {
    postalcode: '01920',
    city: 'Steina',
    countrycode: 'DE',
  },
  {
    postalcode: '01936',
    city: 'Gottschdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01936',
    city: 'Großnaundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01936',
    city: 'Koitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '01936',
    city: 'Königsbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '01936',
    city: 'Laußnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01936',
    city: 'Neukirch',
    countrycode: 'DE',
  },
  {
    postalcode: '01936',
    city: 'Oberlichtenau',
    countrycode: 'DE',
  },
  {
    postalcode: '01936',
    city: 'Reichenbach-Reichenau',
    countrycode: 'DE',
  },
  {
    postalcode: '01936',
    city: 'Schmorkau',
    countrycode: 'DE',
  },
  {
    postalcode: '01936',
    city: 'Schwepnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01936',
    city: 'Straßgräbchen',
    countrycode: 'DE',
  },
  {
    postalcode: '01936',
    city: 'Weißbach bei Königsbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Biehlen',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Frauendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Grünewald',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Guteborn',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Hermsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Hohenbocka',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Jannowitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Kroppen',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Lindenau',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Lipsa',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Peickwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Ruhland',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Schwarzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '01945',
    city: 'Tettau',
    countrycode: 'DE',
  },
  {
    postalcode: '01968',
    city: 'Brieske',
    countrycode: 'DE',
  },
  {
    postalcode: '01968',
    city: 'Großkoschen',
    countrycode: 'DE',
  },
  {
    postalcode: '01968',
    city: 'Hörlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01968',
    city: 'Niemtsch',
    countrycode: 'DE',
  },
  {
    postalcode: '01968',
    city: 'Sedlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '01968',
    city: 'Senftenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '01979',
    city: 'Grünewalde',
    countrycode: 'DE',
  },
  {
    postalcode: '01979',
    city: 'Kostebrau',
    countrycode: 'DE',
  },
  {
    postalcode: '01979',
    city: 'Lauchhammer',
    countrycode: 'DE',
  },
  {
    postalcode: '01983',
    city: 'Allmosen',
    countrycode: 'DE',
  },
  {
    postalcode: '01983',
    city: 'Bahnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '01983',
    city: 'Dörrwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '01983',
    city: 'Freienhufen',
    countrycode: 'DE',
  },
  {
    postalcode: '01983',
    city: 'Großräschen',
    countrycode: 'DE',
  },
  {
    postalcode: '01983',
    city: 'Woschkow',
    countrycode: 'DE',
  },
  {
    postalcode: '01987',
    city: 'Schwarzheide',
    countrycode: 'DE',
  },
  {
    postalcode: '01990',
    city: 'Frauwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '01990',
    city: 'Großkmehlen',
    countrycode: 'DE',
  },
  {
    postalcode: '01990',
    city: 'Kleinkmehlen',
    countrycode: 'DE',
  },
  {
    postalcode: '01990',
    city: 'Ortrand',
    countrycode: 'DE',
  },
  {
    postalcode: '01993',
    city: 'Schipkau',
    countrycode: 'DE',
  },
  {
    postalcode: '01994',
    city: 'Annahütte',
    countrycode: 'DE',
  },
  {
    postalcode: '01994',
    city: 'Barzig',
    countrycode: 'DE',
  },
  {
    postalcode: '01994',
    city: 'Drochow',
    countrycode: 'DE',
  },
  {
    postalcode: '01994',
    city: 'Meuro',
    countrycode: 'DE',
  },
  {
    postalcode: '01994',
    city: 'Saalhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '01994',
    city: 'Wormlage',
    countrycode: 'DE',
  },
  {
    postalcode: '01996',
    city: 'Hosena',
    countrycode: 'DE',
  },
  {
    postalcode: '01998',
    city: 'Klettwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02625',
    city: 'Bautzen',
    countrycode: 'DE',
  },
  {
    postalcode: '02627',
    city: 'Hochkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '02627',
    city: 'Kubschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '02627',
    city: 'Radibor',
    countrycode: 'DE',
  },
  {
    postalcode: '02627',
    city: 'Weißenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '02633',
    city: 'Gaußig',
    countrycode: 'DE',
  },
  {
    postalcode: '02633',
    city: 'Göda',
    countrycode: 'DE',
  },
  {
    postalcode: '02633',
    city: 'Weißnaußlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02681',
    city: 'Crostau',
    countrycode: 'DE',
  },
  {
    postalcode: '02681',
    city: 'Kirschau',
    countrycode: 'DE',
  },
  {
    postalcode: '02681',
    city: 'Schirgiswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '02681',
    city: 'Wilthen',
    countrycode: 'DE',
  },
  {
    postalcode: '02689',
    city: 'Sohland',
    countrycode: 'DE',
  },
  {
    postalcode: '02692',
    city: 'Doberschau',
    countrycode: 'DE',
  },
  {
    postalcode: '02692',
    city: 'Eulowitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02692',
    city: 'Gnaschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02692',
    city: 'Großpostwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02692',
    city: 'Obergurig',
    countrycode: 'DE',
  },
  {
    postalcode: '02694',
    city: 'Großdubrau',
    countrycode: 'DE',
  },
  {
    postalcode: '02694',
    city: 'Guttau',
    countrycode: 'DE',
  },
  {
    postalcode: '02694',
    city: 'Malschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02699',
    city: 'Königswartha',
    countrycode: 'DE',
  },
  {
    postalcode: '02699',
    city: 'Neschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02699',
    city: 'Puschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02708',
    city: 'Dürrhennersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02708',
    city: 'Großschweidnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02708',
    city: 'Kittlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02708',
    city: 'Lawalde',
    countrycode: 'DE',
  },
  {
    postalcode: '02708',
    city: 'Lawalde-Kleindehsa',
    countrycode: 'DE',
  },
  {
    postalcode: '02708',
    city: 'Lawalde-Lauba',
    countrycode: 'DE',
  },
  {
    postalcode: '02708',
    city: 'Löbau',
    countrycode: 'DE',
  },
  {
    postalcode: '02708',
    city: 'Niedercunnersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02708',
    city: 'Obercunnersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02708',
    city: 'Rosenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '02708',
    city: 'Schönbach',
    countrycode: 'DE',
  },
  {
    postalcode: '02727',
    city: 'Neugersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02730',
    city: 'Ebersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '02733',
    city: 'Cunewalde',
    countrycode: 'DE',
  },
  {
    postalcode: '02733',
    city: 'Czorneboh',
    countrycode: 'DE',
  },
  {
    postalcode: '02736',
    city: 'Beiersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02736',
    city: 'Oppach',
    countrycode: 'DE',
  },
  {
    postalcode: '02739',
    city: 'Eibau',
    countrycode: 'DE',
  },
  {
    postalcode: '02739',
    city: 'Eibau-Neueibau',
    countrycode: 'DE',
  },
  {
    postalcode: '02739',
    city: 'Eibau-Walddorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02742',
    city: 'Friedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02742',
    city: 'Neusalza-Spremberg',
    countrycode: 'DE',
  },
  {
    postalcode: '02747',
    city: 'Berthelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02747',
    city: 'Großhennersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02747',
    city: 'Herrnhut',
    countrycode: 'DE',
  },
  {
    postalcode: '02747',
    city: 'Rennersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02747',
    city: 'Strahwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '02748',
    city: 'Bernstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '02763',
    city: 'Bertsdorf-Hörnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02763',
    city: 'Eckartsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '02763',
    city: 'Mittelherwigsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02763',
    city: 'Oberseifersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02763',
    city: 'Radgendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02763',
    city: 'Zittau',
    countrycode: 'DE',
  },
  {
    postalcode: '02779',
    city: 'Großschönau',
    countrycode: 'DE',
  },
  {
    postalcode: '02779',
    city: 'Hainewalde',
    countrycode: 'DE',
  },
  {
    postalcode: '02782',
    city: 'Seifhennersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02785',
    city: 'Olbersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02788',
    city: 'Dittelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02788',
    city: 'Hirschfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '02788',
    city: 'Schlegel',
    countrycode: 'DE',
  },
  {
    postalcode: '02791',
    city: 'Oderwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02794',
    city: 'Leutersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02794',
    city: 'Spitzkunnersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02796',
    city: 'Jonsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02797',
    city: 'Lückendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02797',
    city: 'Oybin',
    countrycode: 'DE',
  },
  {
    postalcode: '02799',
    city: 'Waltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02826',
    city: 'Görlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02827',
    city: 'Görlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02828',
    city: 'Görlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02829',
    city: 'Königshain',
    countrycode: 'DE',
  },
  {
    postalcode: '02829',
    city: 'Markersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02829',
    city: 'Neißeaue',
    countrycode: 'DE',
  },
  {
    postalcode: '02829',
    city: 'Schöpstal',
    countrycode: 'DE',
  },
  {
    postalcode: '02894',
    city: 'Reichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '02894',
    city: 'Sohland',
    countrycode: 'DE',
  },
  {
    postalcode: '02894',
    city: 'Vierkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '02899',
    city: 'Ostritz',
    countrycode: 'DE',
  },
  {
    postalcode: '02899',
    city: 'Schönau-Berzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02906',
    city: 'Hohendubrau',
    countrycode: 'DE',
  },
  {
    postalcode: '02906',
    city: 'Klitten',
    countrycode: 'DE',
  },
  {
    postalcode: '02906',
    city: 'Kreba-Neudorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02906',
    city: 'Mücka',
    countrycode: 'DE',
  },
  {
    postalcode: '02906',
    city: 'Niesky',
    countrycode: 'DE',
  },
  {
    postalcode: '02906',
    city: 'Quitzdorf am See',
    countrycode: 'DE',
  },
  {
    postalcode: '02906',
    city: 'Waldhufen',
    countrycode: 'DE',
  },
  {
    postalcode: '02923',
    city: 'Horka',
    countrycode: 'DE',
  },
  {
    postalcode: '02923',
    city: 'Hähnichen',
    countrycode: 'DE',
  },
  {
    postalcode: '02923',
    city: 'Kodersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02923',
    city: 'Kosel',
    countrycode: 'DE',
  },
  {
    postalcode: '02923',
    city: 'Quolsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02923',
    city: 'Sandschenke',
    countrycode: 'DE',
  },
  {
    postalcode: '02923',
    city: 'Spree',
    countrycode: 'DE',
  },
  {
    postalcode: '02923',
    city: 'Stannewisch',
    countrycode: 'DE',
  },
  {
    postalcode: '02923',
    city: 'Trebus',
    countrycode: 'DE',
  },
  {
    postalcode: '02929',
    city: 'Rothenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '02943',
    city: 'Boxberg',
    countrycode: 'DE',
  },
  {
    postalcode: '02943',
    city: 'Weißwasser',
    countrycode: 'DE',
  },
  {
    postalcode: '02953',
    city: 'Bad Muskau',
    countrycode: 'DE',
  },
  {
    postalcode: '02953',
    city: 'Gablenz',
    countrycode: 'DE',
  },
  {
    postalcode: '02953',
    city: 'Gablenz-Kromlau',
    countrycode: 'DE',
  },
  {
    postalcode: '02953',
    city: 'Halbendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02956',
    city: 'Rietschen',
    countrycode: 'DE',
  },
  {
    postalcode: '02957',
    city: 'Krauschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02957',
    city: 'Weißkeißel',
    countrycode: 'DE',
  },
  {
    postalcode: '02959',
    city: 'Groß Düben',
    countrycode: 'DE',
  },
  {
    postalcode: '02959',
    city: 'Schleife',
    countrycode: 'DE',
  },
  {
    postalcode: '02959',
    city: 'Schleife-Mulkwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02959',
    city: 'Schleife-Rohne',
    countrycode: 'DE',
  },
  {
    postalcode: '02959',
    city: 'Trebendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02959',
    city: 'Trebendorf-Mühlrose',
    countrycode: 'DE',
  },
  {
    postalcode: '02977',
    city: 'Hoyerswerda',
    countrycode: 'DE',
  },
  {
    postalcode: '02979',
    city: 'Burg',
    countrycode: 'DE',
  },
  {
    postalcode: '02979',
    city: 'Burghammer',
    countrycode: 'DE',
  },
  {
    postalcode: '02979',
    city: 'Burgneudorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02979',
    city: 'Elsterheide',
    countrycode: 'DE',
  },
  {
    postalcode: '02979',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '02979',
    city: 'Spreetal',
    countrycode: 'DE',
  },
  {
    postalcode: '02979',
    city: 'Spreewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02979',
    city: 'Zerre',
    countrycode: 'DE',
  },
  {
    postalcode: '02991',
    city: 'Laubusch',
    countrycode: 'DE',
  },
  {
    postalcode: '02991',
    city: 'Lauta',
    countrycode: 'DE',
  },
  {
    postalcode: '02991',
    city: 'Leippe-Torno',
    countrycode: 'DE',
  },
  {
    postalcode: '02994',
    city: 'Bernsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '02994',
    city: 'Wiednitz',
    countrycode: 'DE',
  },
  {
    postalcode: '02997',
    city: 'Wittichenau',
    countrycode: 'DE',
  },
  {
    postalcode: '02999',
    city: 'Knappensee-Groß Särchen',
    countrycode: 'DE',
  },
  {
    postalcode: '02999',
    city: 'Knappensee-Koblenz',
    countrycode: 'DE',
  },
  {
    postalcode: '02999',
    city: 'Knappensee-Wartha',
    countrycode: 'DE',
  },
  {
    postalcode: '02999',
    city: 'Lohsa',
    countrycode: 'DE',
  },
  {
    postalcode: '02999',
    city: 'Uhyst',
    countrycode: 'DE',
  },
  {
    postalcode: '03042',
    city: 'Cottbus',
    countrycode: 'DE',
  },
  {
    postalcode: '03044',
    city: 'Cottbus',
    countrycode: 'DE',
  },
  {
    postalcode: '03046',
    city: 'Cottbus',
    countrycode: 'DE',
  },
  {
    postalcode: '03048',
    city: 'Cottbus',
    countrycode: 'DE',
  },
  {
    postalcode: '03050',
    city: 'Cottbus',
    countrycode: 'DE',
  },
  {
    postalcode: '03051',
    city: 'Cottbus',
    countrycode: 'DE',
  },
  {
    postalcode: '03052',
    city: 'Cottbus',
    countrycode: 'DE',
  },
  {
    postalcode: '03053',
    city: 'Cottbus',
    countrycode: 'DE',
  },
  {
    postalcode: '03054',
    city: 'Cottbus',
    countrycode: 'DE',
  },
  {
    postalcode: '03055',
    city: 'Cottbus',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Frauendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Gablenz',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Gallinchen',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Groß Döbbern',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Groß Gaglow',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Groß Oßnig',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Haasow',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Kathlow',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Kiekebusch',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Klein Döbbern',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Klein Oßnig',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Komptendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Koppatz',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Laubsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Leuthen',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Neuhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Roggosen',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Schorbus',
    countrycode: 'DE',
  },
  {
    postalcode: '03058',
    city: 'Sergen',
    countrycode: 'DE',
  },
  {
    postalcode: '03096',
    city: 'Brahmow',
    countrycode: 'DE',
  },
  {
    postalcode: '03096',
    city: 'Briesen',
    countrycode: 'DE',
  },
  {
    postalcode: '03096',
    city: 'Burg',
    countrycode: 'DE',
  },
  {
    postalcode: '03096',
    city: 'Dissen',
    countrycode: 'DE',
  },
  {
    postalcode: '03096',
    city: 'Fehrow',
    countrycode: 'DE',
  },
  {
    postalcode: '03096',
    city: 'Guhrow',
    countrycode: 'DE',
  },
  {
    postalcode: '03096',
    city: 'Müschen',
    countrycode: 'DE',
  },
  {
    postalcode: '03096',
    city: 'Saccassne',
    countrycode: 'DE',
  },
  {
    postalcode: '03096',
    city: 'Schmogrow',
    countrycode: 'DE',
  },
  {
    postalcode: '03096',
    city: 'Striesow',
    countrycode: 'DE',
  },
  {
    postalcode: '03096',
    city: 'Werben',
    countrycode: 'DE',
  },
  {
    postalcode: '03099',
    city: 'Kolkwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03103',
    city: 'Lindchen',
    countrycode: 'DE',
  },
  {
    postalcode: '03103',
    city: 'Lubochow',
    countrycode: 'DE',
  },
  {
    postalcode: '03103',
    city: 'Neupetershain',
    countrycode: 'DE',
  },
  {
    postalcode: '03103',
    city: 'Ressen',
    countrycode: 'DE',
  },
  {
    postalcode: '03116',
    city: 'Domsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03116',
    city: 'Drebkau',
    countrycode: 'DE',
  },
  {
    postalcode: '03116',
    city: 'Golschow',
    countrycode: 'DE',
  },
  {
    postalcode: '03116',
    city: 'Greifenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '03116',
    city: 'Illmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03116',
    city: 'Jehserig',
    countrycode: 'DE',
  },
  {
    postalcode: '03116',
    city: 'Laubst',
    countrycode: 'DE',
  },
  {
    postalcode: '03116',
    city: 'Siewisch',
    countrycode: 'DE',
  },
  {
    postalcode: '03119',
    city: 'Welzow',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Bagenz',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Bloischdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Bohsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Drieschnitz-Kahsel',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Friedrichshain',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Graustein',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Groß Luja',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Haidemühl',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Hornow',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Jämlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Kausche',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Klein Düben',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Klein Loitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Lieskau',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Proschim',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Reuthen',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Sellessen',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Spremberg',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Tschernitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Türkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Wadelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03130',
    city: 'Wolfshain',
    countrycode: 'DE',
  },
  {
    postalcode: '03139',
    city: 'Schwarze Pumpe',
    countrycode: 'DE',
  },
  {
    postalcode: '03139',
    city: 'Terpe',
    countrycode: 'DE',
  },
  {
    postalcode: '03149',
    city: 'Dubrau',
    countrycode: 'DE',
  },
  {
    postalcode: '03149',
    city: 'Forst',
    countrycode: 'DE',
  },
  {
    postalcode: '03149',
    city: 'Gahry',
    countrycode: 'DE',
  },
  {
    postalcode: '03149',
    city: 'Gosda',
    countrycode: 'DE',
  },
  {
    postalcode: '03149',
    city: 'Groß Schacksdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03149',
    city: 'Jethe',
    countrycode: 'DE',
  },
  {
    postalcode: '03149',
    city: 'Jocksdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03149',
    city: 'Simmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03149',
    city: 'Trebendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03159',
    city: 'Döbern',
    countrycode: 'DE',
  },
  {
    postalcode: '03159',
    city: 'Groß Kölzig',
    countrycode: 'DE',
  },
  {
    postalcode: '03159',
    city: 'Jerischke',
    countrycode: 'DE',
  },
  {
    postalcode: '03159',
    city: 'Klein Kölzig',
    countrycode: 'DE',
  },
  {
    postalcode: '03159',
    city: 'Mattendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03159',
    city: 'Preschen',
    countrycode: 'DE',
  },
  {
    postalcode: '03172',
    city: 'Atterwasch',
    countrycode: 'DE',
  },
  {
    postalcode: '03172',
    city: 'Bärenklau',
    countrycode: 'DE',
  },
  {
    postalcode: '03172',
    city: 'Gastrose-Kerkwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03172',
    city: 'Grabko',
    countrycode: 'DE',
  },
  {
    postalcode: '03172',
    city: 'Grießen',
    countrycode: 'DE',
  },
  {
    postalcode: '03172',
    city: 'Guben',
    countrycode: 'DE',
  },
  {
    postalcode: '03172',
    city: 'Horno',
    countrycode: 'DE',
  },
  {
    postalcode: '03172',
    city: 'Lutzketal',
    countrycode: 'DE',
  },
  {
    postalcode: '03172',
    city: 'Pinnow-Heideland',
    countrycode: 'DE',
  },
  {
    postalcode: '03185',
    city: 'Drachhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '03185',
    city: 'Drehnow',
    countrycode: 'DE',
  },
  {
    postalcode: '03185',
    city: 'Grötsch',
    countrycode: 'DE',
  },
  {
    postalcode: '03185',
    city: 'Heinersbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '03185',
    city: 'Peitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03185',
    city: 'Tauer',
    countrycode: 'DE',
  },
  {
    postalcode: '03185',
    city: 'Teichland',
    countrycode: 'DE',
  },
  {
    postalcode: '03185',
    city: 'Turnow-Preilack',
    countrycode: 'DE',
  },
  {
    postalcode: '03197',
    city: 'Drewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03197',
    city: 'Jänschwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '03197',
    city: 'Jänschwalde Ost',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Bischdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Bolschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Bronkow',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Buchwäldchen Ziegelei',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Buckow',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Calau',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Craupe',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Dubrau',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Gollmitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Gosda',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Groß Jehser',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Groß-Mehßow',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Kemmen',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Laasow',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Lipten',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Lug',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Missen',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Mlode',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Ogrosen',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Repten',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Saßleben',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Werchow',
    countrycode: 'DE',
  },
  {
    postalcode: '03205',
    city: 'Zinnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03222',
    city: 'Boblitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03222',
    city: 'Groß Beuchow',
    countrycode: 'DE',
  },
  {
    postalcode: '03222',
    city: 'Groß-Klessow',
    countrycode: 'DE',
  },
  {
    postalcode: '03222',
    city: 'Hindenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '03222',
    city: 'Kittlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03222',
    city: 'Klein Radden',
    countrycode: 'DE',
  },
  {
    postalcode: '03222',
    city: 'Lübbenau',
    countrycode: 'DE',
  },
  {
    postalcode: '03222',
    city: 'Ragow',
    countrycode: 'DE',
  },
  {
    postalcode: '03226',
    city: 'Groß Lübbenau',
    countrycode: 'DE',
  },
  {
    postalcode: '03226',
    city: 'Göritz',
    countrycode: 'DE',
  },
  {
    postalcode: '03226',
    city: 'Koßwig',
    countrycode: 'DE',
  },
  {
    postalcode: '03226',
    city: 'Leipe',
    countrycode: 'DE',
  },
  {
    postalcode: '03226',
    city: 'Naundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03226',
    city: 'Raddusch',
    countrycode: 'DE',
  },
  {
    postalcode: '03226',
    city: 'Stradow',
    countrycode: 'DE',
  },
  {
    postalcode: '03226',
    city: 'Suschow',
    countrycode: 'DE',
  },
  {
    postalcode: '03226',
    city: 'Vetschau',
    countrycode: 'DE',
  },
  {
    postalcode: '03229',
    city: 'Altdöbern',
    countrycode: 'DE',
  },
  {
    postalcode: '03229',
    city: 'Buchwäldchen',
    countrycode: 'DE',
  },
  {
    postalcode: '03229',
    city: 'Casel',
    countrycode: 'DE',
  },
  {
    postalcode: '03229',
    city: 'Muckwar',
    countrycode: 'DE',
  },
  {
    postalcode: '03229',
    city: 'Ranzow',
    countrycode: 'DE',
  },
  {
    postalcode: '03229',
    city: 'Reddern',
    countrycode: 'DE',
  },
  {
    postalcode: '03229',
    city: 'Schöllnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Betten',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Dollenchen',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Eichholz-Drößig',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Finsterwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Fischwasser',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Gorden-Staupitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Gröbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Göllnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Lichterfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Lieskau',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Lindthal',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Lugau',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Massen',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Münchhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Oppelhain',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Ponnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Rückersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Sallgast',
    countrycode: 'DE',
  },
  {
    postalcode: '03238',
    city: 'Schacksdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03246',
    city: 'Babben',
    countrycode: 'DE',
  },
  {
    postalcode: '03246',
    city: 'Bergen',
    countrycode: 'DE',
  },
  {
    postalcode: '03246',
    city: 'Crinitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03246',
    city: 'Fürstlich Drehna',
    countrycode: 'DE',
  },
  {
    postalcode: '03246',
    city: 'Gahro',
    countrycode: 'DE',
  },
  {
    postalcode: '03249',
    city: 'Bahren',
    countrycode: 'DE',
  },
  {
    postalcode: '03249',
    city: 'Breitenau',
    countrycode: 'DE',
  },
  {
    postalcode: '03249',
    city: 'Goßmar',
    countrycode: 'DE',
  },
  {
    postalcode: '03249',
    city: 'Großkrausnik',
    countrycode: 'DE',
  },
  {
    postalcode: '03249',
    city: 'Kleinkrausnik',
    countrycode: 'DE',
  },
  {
    postalcode: '03249',
    city: 'Pahlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03249',
    city: 'Sonnewalde',
    countrycode: 'DE',
  },
  {
    postalcode: '03249',
    city: 'Zeckerin',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Arenzhain',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Brenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Buchhain',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Doberlug-Kirchhain',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Dübrichen',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Frankena',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Friedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Hennersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Nexdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Prießen',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Schilda',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Schönborn',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Trebbus',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Tröbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '03253',
    city: 'Werenzhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04103',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04105',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04107',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04109',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04129',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04155',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04157',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04158',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04159',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04177',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04178',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04179',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04205',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04207',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04209',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04229',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04249',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04275',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04277',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04279',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04288',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04289',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04299',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04315',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04316',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04317',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04318',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04319',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04328',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04329',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04347',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04349',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04356',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04357',
    city: 'Leipzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04416',
    city: 'Markkleeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04420',
    city: 'Großlehna',
    countrycode: 'DE',
  },
  {
    postalcode: '04420',
    city: 'Kulkwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04420',
    city: 'Markranstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '04425',
    city: 'Taucha',
    countrycode: 'DE',
  },
  {
    postalcode: '04435',
    city: 'Schkeuditz',
    countrycode: 'DE',
  },
  {
    postalcode: '04442',
    city: 'Zwenkau',
    countrycode: 'DE',
  },
  {
    postalcode: '04451',
    city: 'Borsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04451',
    city: 'Panitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '04460',
    city: 'Kitzen',
    countrycode: 'DE',
  },
  {
    postalcode: '04463',
    city: 'Großpösna',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Brodau',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Delitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Döbernitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Krostitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Löbnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Neukyhna',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Priester',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Schönwölkau',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Selben',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Wiedemar',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Zschepen',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Zschortau',
    countrycode: 'DE',
  },
  {
    postalcode: '04509',
    city: 'Zwochau',
    countrycode: 'DE',
  },
  {
    postalcode: '04519',
    city: 'Kletzen-Zschölkau',
    countrycode: 'DE',
  },
  {
    postalcode: '04519',
    city: 'Rackwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04523',
    city: 'Elstertrebnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04523',
    city: 'Pegau',
    countrycode: 'DE',
  },
  {
    postalcode: '04539',
    city: 'Groitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '04552',
    city: 'Borna',
    countrycode: 'DE',
  },
  {
    postalcode: '04552',
    city: 'Lobstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '04552',
    city: 'Wyhratal',
    countrycode: 'DE',
  },
  {
    postalcode: '04564',
    city: 'Böhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '04565',
    city: 'Regis-Breitingen',
    countrycode: 'DE',
  },
  {
    postalcode: '04567',
    city: 'Kitzscher',
    countrycode: 'DE',
  },
  {
    postalcode: '04571',
    city: 'Rötha',
    countrycode: 'DE',
  },
  {
    postalcode: '04574',
    city: 'Deutzen',
    countrycode: 'DE',
  },
  {
    postalcode: '04574',
    city: 'Heuersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04575',
    city: 'Neukieritzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '04579',
    city: 'Espenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04579',
    city: 'Mölbis',
    countrycode: 'DE',
  },
  {
    postalcode: '04600',
    city: 'Altenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '04603',
    city: 'Göhren',
    countrycode: 'DE',
  },
  {
    postalcode: '04603',
    city: 'Nobitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04603',
    city: 'Saara',
    countrycode: 'DE',
  },
  {
    postalcode: '04603',
    city: 'Windischleuba',
    countrycode: 'DE',
  },
  {
    postalcode: '04610',
    city: 'Meuselwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04610',
    city: 'Wintersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04613',
    city: 'Lucka',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Fockendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Gerstenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Großröda',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Haselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Kriebitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Lehma',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Lödla',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Monstab',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Naundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Neubraunshain',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Rositz',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Starkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Tegkwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Treben',
    countrycode: 'DE',
  },
  {
    postalcode: '04617',
    city: 'Waltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04618',
    city: 'Frohnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04618',
    city: 'Göpfersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04618',
    city: 'Jückelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04618',
    city: 'Langenleuba-Niederhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04618',
    city: 'Ziegelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Altkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Dobitschen',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Drogen',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Göllnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Heukewalde',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Heyersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Jonaswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Lumpzig',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Löbichau',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Mehna',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Nöbdenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Posterstein',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Schmölln',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Thonhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Vollmershain',
    countrycode: 'DE',
  },
  {
    postalcode: '04626',
    city: 'Wildenbörten',
    countrycode: 'DE',
  },
  {
    postalcode: '04639',
    city: 'Gößnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04639',
    city: 'Ponitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04643',
    city: 'Altottenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04643',
    city: 'Bruchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '04643',
    city: 'Frankenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04643',
    city: 'Geithain',
    countrycode: 'DE',
  },
  {
    postalcode: '04643',
    city: 'Kolka',
    countrycode: 'DE',
  },
  {
    postalcode: '04643',
    city: 'Niederpickenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04643',
    city: 'Ossa',
    countrycode: 'DE',
  },
  {
    postalcode: '04643',
    city: 'Ottenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04643',
    city: 'Tautenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04643',
    city: 'Wenigossa',
    countrycode: 'DE',
  },
  {
    postalcode: '04651',
    city: 'Bad Lausick',
    countrycode: 'DE',
  },
  {
    postalcode: '04651',
    city: 'Elbisbach',
    countrycode: 'DE',
  },
  {
    postalcode: '04651',
    city: 'Flößberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04651',
    city: 'Hopfgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '04651',
    city: 'Prießnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04651',
    city: 'Trebishain',
    countrycode: 'DE',
  },
  {
    postalcode: '04654',
    city: 'Frohburg',
    countrycode: 'DE',
  },
  {
    postalcode: '04655',
    city: 'Kohren-Sahlis',
    countrycode: 'DE',
  },
  {
    postalcode: '04657',
    city: 'Langensteinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '04657',
    city: 'Narsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04657',
    city: 'Rathendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Bröhsen',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Böhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Draschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Dürrweitzschen',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Frauendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Grimma',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Großbardau',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Großbothen',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Haubitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Keiselwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Kuckeland',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Leipnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Motterwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Muschau',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Nauberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Ostrau',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Otterwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Papsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Parthenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Poischwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Pöhsig',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Ragewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Seidewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Zaschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Zeunitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04668',
    city: 'Zschoppach',
    countrycode: 'DE',
  },
  {
    postalcode: '04680',
    city: 'Bockwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04680',
    city: 'Colditz',
    countrycode: 'DE',
  },
  {
    postalcode: '04680',
    city: 'Collmen',
    countrycode: 'DE',
  },
  {
    postalcode: '04680',
    city: 'Commichau',
    countrycode: 'DE',
  },
  {
    postalcode: '04680',
    city: 'Meuselwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04680',
    city: 'Skoplau',
    countrycode: 'DE',
  },
  {
    postalcode: '04680',
    city: 'Tanndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04680',
    city: 'Zschadraß',
    countrycode: 'DE',
  },
  {
    postalcode: '04683',
    city: 'Belgershain',
    countrycode: 'DE',
  },
  {
    postalcode: '04683',
    city: 'Fuchshain',
    countrycode: 'DE',
  },
  {
    postalcode: '04683',
    city: 'Naunhof',
    countrycode: 'DE',
  },
  {
    postalcode: '04685',
    city: 'Nerchau',
    countrycode: 'DE',
  },
  {
    postalcode: '04687',
    city: 'Trebsen',
    countrycode: 'DE',
  },
  {
    postalcode: '04688',
    city: 'Mutzschen',
    countrycode: 'DE',
  },
  {
    postalcode: '04703',
    city: 'Bockelwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04703',
    city: 'Gersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04703',
    city: 'Leisnig',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Beicha',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Choren',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Dreißig',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Döbeln',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Ebersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Geleitshäuser',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Großweitzschen',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Juchhöh',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Kleinmockritz',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Leschen',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Lüttewitz bei Döbeln',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Maltitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Markritz',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Mochau',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Petersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Prüfern',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Theeschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Ziegra-Knobelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04720',
    city: 'Zschaitz-Ottewig',
    countrycode: 'DE',
  },
  {
    postalcode: '04736',
    city: 'Waldheim',
    countrycode: 'DE',
  },
  {
    postalcode: '04741',
    city: 'Gertitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '04741',
    city: 'Niederstriegis',
    countrycode: 'DE',
  },
  {
    postalcode: '04741',
    city: 'Roßwein',
    countrycode: 'DE',
  },
  {
    postalcode: '04746',
    city: 'Hartha',
    countrycode: 'DE',
  },
  {
    postalcode: '04749',
    city: 'Ostrau',
    countrycode: 'DE',
  },
  {
    postalcode: '04758',
    city: 'Cavertitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04758',
    city: 'Hof',
    countrycode: 'DE',
  },
  {
    postalcode: '04758',
    city: 'Liebschützberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04758',
    city: 'Oschatz',
    countrycode: 'DE',
  },
  {
    postalcode: '04769',
    city: 'Mügeln',
    countrycode: 'DE',
  },
  {
    postalcode: '04769',
    city: 'Naundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04769',
    city: 'Salbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04769',
    city: 'Sornzig-Ablaß',
    countrycode: 'DE',
  },
  {
    postalcode: '04774',
    city: 'Dahlen',
    countrycode: 'DE',
  },
  {
    postalcode: '04779',
    city: 'Wermsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04808',
    city: 'Dornreichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '04808',
    city: 'Falkenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04808',
    city: 'Hohburg',
    countrycode: 'DE',
  },
  {
    postalcode: '04808',
    city: 'Kühnitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '04808',
    city: 'Kühren-Burkartshain',
    countrycode: 'DE',
  },
  {
    postalcode: '04808',
    city: 'Meltewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04808',
    city: 'Thallwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04808',
    city: 'Thammenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04808',
    city: 'Voigtshain',
    countrycode: 'DE',
  },
  {
    postalcode: '04808',
    city: 'Wurzen',
    countrycode: 'DE',
  },
  {
    postalcode: '04821',
    city: 'Brandis',
    countrycode: 'DE',
  },
  {
    postalcode: '04821',
    city: 'Polenz',
    countrycode: 'DE',
  },
  {
    postalcode: '04824',
    city: 'Beucha',
    countrycode: 'DE',
  },
  {
    postalcode: '04827',
    city: 'Gerichshain',
    countrycode: 'DE',
  },
  {
    postalcode: '04827',
    city: 'Lübschützer Teiche',
    countrycode: 'DE',
  },
  {
    postalcode: '04827',
    city: 'Machern',
    countrycode: 'DE',
  },
  {
    postalcode: '04828',
    city: 'Altenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '04828',
    city: 'Bach',
    countrycode: 'DE',
  },
  {
    postalcode: '04828',
    city: 'Bennewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04828',
    city: 'Deuben',
    countrycode: 'DE',
  },
  {
    postalcode: '04828',
    city: 'Grubnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04828',
    city: 'Nepperwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04828',
    city: 'Pausitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04828',
    city: 'Püchau',
    countrycode: 'DE',
  },
  {
    postalcode: '04828',
    city: 'Rothersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04828',
    city: 'Schmölen',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Audenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Battaune',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Doberschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Eilenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Jesewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Laußig',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Mockrehna',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Mörtitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Paschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Schöna',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Sprotta',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Strelln',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Wildenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Wildschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Wöllnau',
    countrycode: 'DE',
  },
  {
    postalcode: '04838',
    city: 'Zschepplin',
    countrycode: 'DE',
  },
  {
    postalcode: '04849',
    city: 'Authausen',
    countrycode: 'DE',
  },
  {
    postalcode: '04849',
    city: 'Bad Düben',
    countrycode: 'DE',
  },
  {
    postalcode: '04849',
    city: 'Kossa',
    countrycode: 'DE',
  },
  {
    postalcode: '04849',
    city: 'Pressel',
    countrycode: 'DE',
  },
  {
    postalcode: '04849',
    city: 'Schnaditz',
    countrycode: 'DE',
  },
  {
    postalcode: '04849',
    city: 'Tiefensee',
    countrycode: 'DE',
  },
  {
    postalcode: '04860',
    city: 'Großwig',
    countrycode: 'DE',
  },
  {
    postalcode: '04860',
    city: 'Klitzschen',
    countrycode: 'DE',
  },
  {
    postalcode: '04860',
    city: 'Süptitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04860',
    city: 'Torgau',
    countrycode: 'DE',
  },
  {
    postalcode: '04860',
    city: 'Weidenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04860',
    city: 'Zinna',
    countrycode: 'DE',
  },
  {
    postalcode: '04874',
    city: 'Belgern',
    countrycode: 'DE',
  },
  {
    postalcode: '04880',
    city: 'Dommitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '04880',
    city: 'Elsnig',
    countrycode: 'DE',
  },
  {
    postalcode: '04880',
    city: 'Trossin',
    countrycode: 'DE',
  },
  {
    postalcode: '04880',
    city: 'Wörblitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04886',
    city: 'Arzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04886',
    city: 'Beilrode',
    countrycode: 'DE',
  },
  {
    postalcode: '04886',
    city: 'Döbrichau',
    countrycode: 'DE',
  },
  {
    postalcode: '04886',
    city: 'Großtreben-Zwethau',
    countrycode: 'DE',
  },
  {
    postalcode: '04889',
    city: 'Langenreichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '04889',
    city: 'Pflückuff',
    countrycode: 'DE',
  },
  {
    postalcode: '04889',
    city: 'Schildau',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Bahnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Beyern',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Brottewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Buckau',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Burxdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Falkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Fermerswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Großrössen',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Kleinrössen',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Koßdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Kölsa',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Löhsten',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Martinskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Neudeck',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Rehfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Saxdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Schmerkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04895',
    city: 'Züllsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04910',
    city: 'Elsterwerda',
    countrycode: 'DE',
  },
  {
    postalcode: '04910',
    city: 'Haida',
    countrycode: 'DE',
  },
  {
    postalcode: '04910',
    city: 'Kraupa',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Ahlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Arnsnesta',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Bernsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Borken',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Brandis',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Dubro',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Friedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Grassau',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Gräfendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Herzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Jeßnigk',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Mahdel',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Osteroda',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Polzen',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Rahnisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Schönewalde',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Stolzenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Werchau',
    countrycode: 'DE',
  },
  {
    postalcode: '04916',
    city: 'Wildberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Bad Liebenwerda',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Beiersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Beutersitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Bönitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Dobra',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Domsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Kauxdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Kröbeln',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Lausitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Maasdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Marxdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Oschätzchen',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Prestewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Prieschka',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Rothstein',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Thalberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Theisa',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Wahrenbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Wildgrube',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Winkel',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Zeischa',
    countrycode: 'DE',
  },
  {
    postalcode: '04924',
    city: 'Zobersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04928',
    city: 'Döllingen',
    countrycode: 'DE',
  },
  {
    postalcode: '04928',
    city: 'Kahla',
    countrycode: 'DE',
  },
  {
    postalcode: '04928',
    city: 'Plessa',
    countrycode: 'DE',
  },
  {
    postalcode: '04928',
    city: 'Schraden',
    countrycode: 'DE',
  },
  {
    postalcode: '04931',
    city: 'Altenau',
    countrycode: 'DE',
  },
  {
    postalcode: '04931',
    city: 'Fichtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04931',
    city: 'Kosilenzien',
    countrycode: 'DE',
  },
  {
    postalcode: '04931',
    city: 'Langenrieth',
    countrycode: 'DE',
  },
  {
    postalcode: '04931',
    city: 'Möglenz',
    countrycode: 'DE',
  },
  {
    postalcode: '04931',
    city: 'Mühlberg',
    countrycode: 'DE',
  },
  {
    postalcode: '04931',
    city: 'Neuburxdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04932',
    city: 'Großthiemig',
    countrycode: 'DE',
  },
  {
    postalcode: '04932',
    city: 'Gröden',
    countrycode: 'DE',
  },
  {
    postalcode: '04932',
    city: 'Hirschfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '04932',
    city: 'Merzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04932',
    city: 'Prösen',
    countrycode: 'DE',
  },
  {
    postalcode: '04932',
    city: 'Reichenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04932',
    city: 'Saathain',
    countrycode: 'DE',
  },
  {
    postalcode: '04932',
    city: 'Stolzenhain an d Röder',
    countrycode: 'DE',
  },
  {
    postalcode: '04932',
    city: 'Wainsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04934',
    city: 'Hohenleipisch',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Frankenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Freileben',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Hillmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Hohenbucko',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Jagsal',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Kolochau',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Körba',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Lebusa',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Malitschkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Naundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Oelsig',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Proßmarke',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Schlieben',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Schöna-Kolpien',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Stechau',
    countrycode: 'DE',
  },
  {
    postalcode: '04936',
    city: 'Wehrhain',
    countrycode: 'DE',
  },
  {
    postalcode: '04938',
    city: 'Drasdo',
    countrycode: 'DE',
  },
  {
    postalcode: '04938',
    city: 'Langennaundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '04938',
    city: 'Uebigau',
    countrycode: 'DE',
  },
  {
    postalcode: '04938',
    city: 'Wiederau',
    countrycode: 'DE',
  },
  {
    postalcode: '06108',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06110',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06112',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06114',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06116',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06118',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06120',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06120',
    city: 'Lieskau',
    countrycode: 'DE',
  },
  {
    postalcode: '06122',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06124',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06126',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06128',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06130',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06132',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Angersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Benkendorf bei Salzmünde',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Bennstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Dornstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Hohenweiden',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Holleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Höhnstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Langenbogen',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Quillschina',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Schochwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Steuden',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Teutschenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Zappendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06179',
    city: 'Zscherben',
    countrycode: 'DE',
  },
  {
    postalcode: '06184',
    city: 'Burgliebenau',
    countrycode: 'DE',
  },
  {
    postalcode: '06184',
    city: 'Dieskau',
    countrycode: 'DE',
  },
  {
    postalcode: '06184',
    city: 'Dölbau',
    countrycode: 'DE',
  },
  {
    postalcode: '06184',
    city: 'Döllnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06184',
    city: 'Ermlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06184',
    city: 'Großkugel',
    countrycode: 'DE',
  },
  {
    postalcode: '06184',
    city: 'Gröbers',
    countrycode: 'DE',
  },
  {
    postalcode: '06184',
    city: 'Lochau',
    countrycode: 'DE',
  },
  {
    postalcode: '06184',
    city: 'Raßnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06184',
    city: 'Röglitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06188',
    city: 'Brachstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06188',
    city: 'Braschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06188',
    city: 'Hohenthurm',
    countrycode: 'DE',
  },
  {
    postalcode: '06188',
    city: 'Landsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06188',
    city: 'Niemberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06188',
    city: 'Oppin',
    countrycode: 'DE',
  },
  {
    postalcode: '06188',
    city: 'Peißen',
    countrycode: 'DE',
  },
  {
    postalcode: '06188',
    city: 'Queis',
    countrycode: 'DE',
  },
  {
    postalcode: '06188',
    city: 'Reußen',
    countrycode: 'DE',
  },
  {
    postalcode: '06188',
    city: 'Schwerz',
    countrycode: 'DE',
  },
  {
    postalcode: '06188',
    city: 'Sietzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '06188',
    city: 'Spickendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Gutenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Krosigk',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Kütten',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Löbejün',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Morl',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Mösthinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Nauendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Nehlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Ostrau',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Petersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Plötz',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Sennewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Teicha',
    countrycode: 'DE',
  },
  {
    postalcode: '06193',
    city: 'Wallwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06198',
    city: 'Beesenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06198',
    city: 'Brachwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06198',
    city: 'Döblitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06198',
    city: 'Dößel',
    countrycode: 'DE',
  },
  {
    postalcode: '06198',
    city: 'Fienstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06198',
    city: 'Gimritz',
    countrycode: 'DE',
  },
  {
    postalcode: '06198',
    city: 'Kloschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06198',
    city: 'Neutz-Lettewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06198',
    city: 'Salzmünde',
    countrycode: 'DE',
  },
  {
    postalcode: '06198',
    city: 'Wettin',
    countrycode: 'DE',
  },
  {
    postalcode: '06217',
    city: 'Beuna',
    countrycode: 'DE',
  },
  {
    postalcode: '06217',
    city: 'Geusa',
    countrycode: 'DE',
  },
  {
    postalcode: '06217',
    city: 'Gut Werder',
    countrycode: 'DE',
  },
  {
    postalcode: '06217',
    city: 'Merseburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06217',
    city: 'Meuschau',
    countrycode: 'DE',
  },
  {
    postalcode: '06217',
    city: 'Trebnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06231',
    city: 'Bad Dürrenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06231',
    city: 'Kreypau',
    countrycode: 'DE',
  },
  {
    postalcode: '06231',
    city: 'Kötzschau',
    countrycode: 'DE',
  },
  {
    postalcode: '06231',
    city: 'Nempitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06231',
    city: 'Oebles-Schlechtewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06231',
    city: 'Rodden',
    countrycode: 'DE',
  },
  {
    postalcode: '06231',
    city: 'Tollwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06237',
    city: 'Leuna',
    countrycode: 'DE',
  },
  {
    postalcode: '06237',
    city: 'Spergau',
    countrycode: 'DE',
  },
  {
    postalcode: '06242',
    city: 'Braunsbedra',
    countrycode: 'DE',
  },
  {
    postalcode: '06242',
    city: 'Großkayna',
    countrycode: 'DE',
  },
  {
    postalcode: '06242',
    city: 'Krumpa',
    countrycode: 'DE',
  },
  {
    postalcode: '06242',
    city: 'Roßbach',
    countrycode: 'DE',
  },
  {
    postalcode: '06246',
    city: 'Bad Lauchstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '06246',
    city: 'Delitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06246',
    city: 'Klobikau',
    countrycode: 'DE',
  },
  {
    postalcode: '06246',
    city: 'Knapendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06246',
    city: 'Milzau',
    countrycode: 'DE',
  },
  {
    postalcode: '06249',
    city: 'Mücheln',
    countrycode: 'DE',
  },
  {
    postalcode: '06254',
    city: 'Friedensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06254',
    city: 'Günthersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06254',
    city: 'Horburg-Maßlau',
    countrycode: 'DE',
  },
  {
    postalcode: '06254',
    city: 'Kötschlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06254',
    city: 'Luppenau',
    countrycode: 'DE',
  },
  {
    postalcode: '06254',
    city: 'Wallendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06254',
    city: 'Zweimen',
    countrycode: 'DE',
  },
  {
    postalcode: '06254',
    city: 'Zöschen',
    countrycode: 'DE',
  },
  {
    postalcode: '06255',
    city: 'Schafstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '06255',
    city: 'Wünsch',
    countrycode: 'DE',
  },
  {
    postalcode: '06258',
    city: 'Korbetha',
    countrycode: 'DE',
  },
  {
    postalcode: '06258',
    city: 'Schkopau',
    countrycode: 'DE',
  },
  {
    postalcode: '06259',
    city: 'Frankleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Albersroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Barnstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Gatterstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Grockstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Kuckenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Langeneichstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Leimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Nemsdorf-Göhrendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Obhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Oechlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Querfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Schmon',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Steigra',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Vitzenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Weißenschirmbach',
    countrycode: 'DE',
  },
  {
    postalcode: '06268',
    city: 'Ziegelroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06279',
    city: 'Alberstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06279',
    city: 'Esperstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06279',
    city: 'Farnstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '06279',
    city: 'Schraplau',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Bischofrode',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Bornstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Burgsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Dederstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Hedersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Hornburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Lutherstadt Eisleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Neehausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Osterhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Polleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Rothenschirmbach',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Rottelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Schmalzerode',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Schwittersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Unterrißdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Volkstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06295',
    city: 'Wolferode',
    countrycode: 'DE',
  },
  {
    postalcode: '06308',
    city: 'Annarode',
    countrycode: 'DE',
  },
  {
    postalcode: '06308',
    city: 'Augsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06308',
    city: 'Benndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06308',
    city: 'Hübitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06308',
    city: 'Klostermansfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '06308',
    city: 'Siebigerode',
    countrycode: 'DE',
  },
  {
    postalcode: '06308',
    city: 'Siersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06311',
    city: 'Helbra',
    countrycode: 'DE',
  },
  {
    postalcode: '06313',
    city: 'Ahlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06313',
    city: 'Hergisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06313',
    city: 'Wimmelburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06317',
    city: 'Amsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06317',
    city: 'Aseleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06317',
    city: 'Erdeborn',
    countrycode: 'DE',
  },
  {
    postalcode: '06317',
    city: 'Lüttchendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06317',
    city: 'Röblingen am See',
    countrycode: 'DE',
  },
  {
    postalcode: '06317',
    city: 'Seeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06317',
    city: 'Stedten',
    countrycode: 'DE',
  },
  {
    postalcode: '06318',
    city: 'Wansleben am See',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Arnstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Bräunrode',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Endorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Greifenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Hettstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Neuplatendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Quenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Ritterode',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Saurasen',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Sylda',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Walbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Welbsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Welfesholz',
    countrycode: 'DE',
  },
  {
    postalcode: '06333',
    city: 'Wiederstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06343',
    city: 'Biesenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '06343',
    city: 'Gorenzen',
    countrycode: 'DE',
  },
  {
    postalcode: '06343',
    city: 'Mansfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '06343',
    city: 'Möllendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06343',
    city: 'Piskaborn',
    countrycode: 'DE',
  },
  {
    postalcode: '06343',
    city: 'Rödgen',
    countrycode: 'DE',
  },
  {
    postalcode: '06343',
    city: 'Vatterode',
    countrycode: 'DE',
  },
  {
    postalcode: '06347',
    city: 'Freist',
    countrycode: 'DE',
  },
  {
    postalcode: '06347',
    city: 'Friedeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06347',
    city: 'Friedeburgerhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '06347',
    city: 'Gerbstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06347',
    city: 'Heiligenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '06347',
    city: 'Ihlewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06347',
    city: 'Zabenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06348',
    city: 'Großörner',
    countrycode: 'DE',
  },
  {
    postalcode: '06366',
    city: 'Köthen',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Arensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Cosa',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Cösitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Diebzig',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Dohndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Dornbock',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Drosa',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Elsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Glauzig',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Gnetsch',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Großbadegast',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Großpaschleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Görzig',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Kleinpaschleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Libehna',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Löbnitz an der Linde',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Merzien',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Porst',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Prosigk',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Radegast',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Reupzig',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Riesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Schortewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Trebbichau',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Trinum',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Weißandt-Gölzau',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Wulfen',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Wörbzig',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Wülknitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Zabitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06369',
    city: 'Zehbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06385',
    city: 'Aken',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Chörau',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Elsnigk',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Fraßdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Friedrichsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Hinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Kleinzerbst',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Libbesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Meilendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Micheln',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Osternienburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Quellendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Reppichau',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Scheuder',
    countrycode: 'DE',
  },
  {
    postalcode: '06386',
    city: 'Storkau',
    countrycode: 'DE',
  },
  {
    postalcode: '06388',
    city: 'Baasdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06388',
    city: 'Edderitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06388',
    city: 'Gröbzig',
    countrycode: 'DE',
  },
  {
    postalcode: '06388',
    city: 'Maasdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06388',
    city: 'Piethen',
    countrycode: 'DE',
  },
  {
    postalcode: '06388',
    city: 'Wieskau',
    countrycode: 'DE',
  },
  {
    postalcode: '06406',
    city: 'Bernburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06408',
    city: 'Aderstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06408',
    city: 'Baalberge',
    countrycode: 'DE',
  },
  {
    postalcode: '06408',
    city: 'Biendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06408',
    city: 'Cörmigk',
    countrycode: 'DE',
  },
  {
    postalcode: '06408',
    city: 'Gröna',
    countrycode: 'DE',
  },
  {
    postalcode: '06408',
    city: 'Ilberstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06408',
    city: 'Latdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06408',
    city: 'Peißen',
    countrycode: 'DE',
  },
  {
    postalcode: '06408',
    city: 'Poley',
    countrycode: 'DE',
  },
  {
    postalcode: '06408',
    city: 'Preußlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06408',
    city: 'Wohlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06408',
    city: 'Zepzig',
    countrycode: 'DE',
  },
  {
    postalcode: '06420',
    city: 'Domnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06420',
    city: 'Edlau',
    countrycode: 'DE',
  },
  {
    postalcode: '06420',
    city: 'Gerlebogk',
    countrycode: 'DE',
  },
  {
    postalcode: '06420',
    city: 'Gnölbzig',
    countrycode: 'DE',
  },
  {
    postalcode: '06420',
    city: 'Golbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06420',
    city: 'Könnern',
    countrycode: 'DE',
  },
  {
    postalcode: '06420',
    city: 'Lebendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06420',
    city: 'Rothenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06420',
    city: 'Trebnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06420',
    city: 'Wiendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06420',
    city: 'Zickeritz',
    countrycode: 'DE',
  },
  {
    postalcode: '06425',
    city: 'Alsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06425',
    city: 'Beesenlaublingen',
    countrycode: 'DE',
  },
  {
    postalcode: '06425',
    city: 'Belleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06425',
    city: 'Plötzkau',
    countrycode: 'DE',
  },
  {
    postalcode: '06425',
    city: 'Schackstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06425',
    city: 'Strenznaundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06429',
    city: 'Gerbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06429',
    city: 'Neugattersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06429',
    city: 'Nienburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06429',
    city: 'Pobzig',
    countrycode: 'DE',
  },
  {
    postalcode: '06429',
    city: 'Wedlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06449',
    city: 'Aschersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06449',
    city: 'Cochstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06449',
    city: 'Friedrichsaue',
    countrycode: 'DE',
  },
  {
    postalcode: '06449',
    city: 'Giersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06449',
    city: 'Groß Schierstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06449',
    city: 'Klein Schierstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06449',
    city: 'Neu Königsaue',
    countrycode: 'DE',
  },
  {
    postalcode: '06449',
    city: 'Schackenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '06449',
    city: 'Schadeleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06449',
    city: 'Westdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06449',
    city: 'Wilsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06449',
    city: 'Winningen',
    countrycode: 'DE',
  },
  {
    postalcode: '06456',
    city: 'Drohndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06456',
    city: 'Freckleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06456',
    city: 'Mehringen',
    countrycode: 'DE',
  },
  {
    postalcode: '06456',
    city: 'Sandersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06458',
    city: 'Hausneindorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06458',
    city: 'Hedersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06458',
    city: 'Heteborn',
    countrycode: 'DE',
  },
  {
    postalcode: '06458',
    city: 'Wedderstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06463',
    city: 'Ermsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06463',
    city: 'Meisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06463',
    city: 'Radisleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06463',
    city: 'Reinstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06464',
    city: 'Frose',
    countrycode: 'DE',
  },
  {
    postalcode: '06466',
    city: 'Gatersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06467',
    city: 'Hoym',
    countrycode: 'DE',
  },
  {
    postalcode: '06469',
    city: 'Nachterstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06484',
    city: 'Ditfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '06484',
    city: 'Quedlinburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06484',
    city: 'Westerhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06493',
    city: 'Badeborn',
    countrycode: 'DE',
  },
  {
    postalcode: '06493',
    city: 'Ballenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06493',
    city: 'Dankerode',
    countrycode: 'DE',
  },
  {
    postalcode: '06493',
    city: 'Harzgerode',
    countrycode: 'DE',
  },
  {
    postalcode: '06493',
    city: 'Königerode',
    countrycode: 'DE',
  },
  {
    postalcode: '06493',
    city: 'Neudorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06493',
    city: 'Schielo',
    countrycode: 'DE',
  },
  {
    postalcode: '06493',
    city: 'Straßberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06502',
    city: 'Neinstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06502',
    city: 'Thale',
    countrycode: 'DE',
  },
  {
    postalcode: '06502',
    city: 'Timmenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '06502',
    city: 'Warnstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06502',
    city: 'Weddersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06507',
    city: 'Allrode',
    countrycode: 'DE',
  },
  {
    postalcode: '06507',
    city: 'Bad Suderode',
    countrycode: 'DE',
  },
  {
    postalcode: '06507',
    city: 'Friedrichsbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '06507',
    city: 'Gernrode',
    countrycode: 'DE',
  },
  {
    postalcode: '06507',
    city: 'Güntersberge',
    countrycode: 'DE',
  },
  {
    postalcode: '06507',
    city: 'Rieder',
    countrycode: 'DE',
  },
  {
    postalcode: '06507',
    city: 'Siptenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '06507',
    city: 'Stecklenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06526',
    city: 'Sangerhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Beyernaumburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Birkenschäferei',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Blankenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Breitenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Brücken',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Drebsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Edersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Emseloh',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Gonna',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Grillenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Großleinungen',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Hackpfüffel',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Hainrode',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Holdenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Horla',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Kleinleinungen',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Landgemeinde',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Lengefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Liedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Martinsrieth',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Morungen',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Neuhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Oberröblingen',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Obersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Pölsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Riestedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Riethnordhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Rotha',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Sotterhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Wallhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06528',
    city: 'Wettelrode',
    countrycode: 'DE',
  },
  {
    postalcode: '06536',
    city: 'Bennungen',
    countrycode: 'DE',
  },
  {
    postalcode: '06536',
    city: 'Berga',
    countrycode: 'DE',
  },
  {
    postalcode: '06536',
    city: 'Breitungen',
    countrycode: 'DE',
  },
  {
    postalcode: '06536',
    city: 'Dietersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06536',
    city: 'Hayn',
    countrycode: 'DE',
  },
  {
    postalcode: '06536',
    city: 'Questenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06536',
    city: 'Roßla',
    countrycode: 'DE',
  },
  {
    postalcode: '06536',
    city: 'Wickerode',
    countrycode: 'DE',
  },
  {
    postalcode: '06536',
    city: 'Wolfsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06537',
    city: 'Kelbra',
    countrycode: 'DE',
  },
  {
    postalcode: '06537',
    city: 'Tilleda',
    countrycode: 'DE',
  },
  {
    postalcode: '06542',
    city: 'Allstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06542',
    city: 'Katharinenrieth',
    countrycode: 'DE',
  },
  {
    postalcode: '06542',
    city: 'Mittelhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06542',
    city: 'Niederröblingen',
    countrycode: 'DE',
  },
  {
    postalcode: '06542',
    city: 'Nienstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06542',
    city: 'Winkel',
    countrycode: 'DE',
  },
  {
    postalcode: '06542',
    city: 'Wolferstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Abberode',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Alterode',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Braunschwende',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Friesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Harkerode',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Hermerode',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Molmerswende',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Pansfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Ritzgerode',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Stangerode',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Ulzigerode',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Wieserode',
    countrycode: 'DE',
  },
  {
    postalcode: '06543',
    city: 'Wippra',
    countrycode: 'DE',
  },
  {
    postalcode: '06547',
    city: 'Breitenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '06547',
    city: 'Schwenda',
    countrycode: 'DE',
  },
  {
    postalcode: '06547',
    city: 'Stolberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06548',
    city: 'Rottleberode',
    countrycode: 'DE',
  },
  {
    postalcode: '06548',
    city: 'Uftrungen',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Artern',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Borxleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Bretleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Heygendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Ichstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Kachstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Kalbsrieth',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Mönchpfiffel-Nikolausrieth',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Reinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Ringleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Ritteburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Schönewerda',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Schönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Udersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06556',
    city: 'Voigtstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06567',
    city: 'Bad Frankenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06567',
    city: 'Esperstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06567',
    city: 'Göllingen',
    countrycode: 'DE',
  },
  {
    postalcode: '06567',
    city: 'Günserode',
    countrycode: 'DE',
  },
  {
    postalcode: '06567',
    city: 'Rottleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06567',
    city: 'Seega',
    countrycode: 'DE',
  },
  {
    postalcode: '06567',
    city: 'Seehausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06567',
    city: 'Steinthaleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06571',
    city: 'Bottendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06571',
    city: 'Donndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06571',
    city: 'Garnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '06571',
    city: 'Gehofen',
    countrycode: 'DE',
  },
  {
    postalcode: '06571',
    city: 'Hechendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06571',
    city: 'Langenroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06571',
    city: 'Nausitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06571',
    city: 'Roßleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06571',
    city: 'Wiehe',
    countrycode: 'DE',
  },
  {
    postalcode: '06577',
    city: 'Braunsroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06577',
    city: 'Etzleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06577',
    city: 'Gorsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06577',
    city: 'Hauteroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06577',
    city: 'Heldrungen',
    countrycode: 'DE',
  },
  {
    postalcode: '06577',
    city: 'Hemleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06577',
    city: 'Oberheldrungen',
    countrycode: 'DE',
  },
  {
    postalcode: '06578',
    city: 'Bilzingsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06578',
    city: 'Kannawurf',
    countrycode: 'DE',
  },
  {
    postalcode: '06578',
    city: 'Oldisleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06578',
    city: 'Sachsenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Casekirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Eulau',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Gieckau',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Görschen',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Heiligenkreuz',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Henne',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Janisroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Kleinjena',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Leislau',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Löbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Mertendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Molau',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Naumburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Prießnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Pödelist',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Schönburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Utenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '06618',
    city: 'Wethau',
    countrycode: 'DE',
  },
  {
    postalcode: '06628',
    city: 'Abtlöbnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06628',
    city: 'Bad Kösen',
    countrycode: 'DE',
  },
  {
    postalcode: '06628',
    city: 'Crölpa-Löbschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '06628',
    city: 'Möllern',
    countrycode: 'DE',
  },
  {
    postalcode: '06628',
    city: 'Spielberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06628',
    city: 'Taugwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06632',
    city: 'Balgstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '06632',
    city: 'Baumersroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06632',
    city: 'Branderoda',
    countrycode: 'DE',
  },
  {
    postalcode: '06632',
    city: 'Ebersroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06632',
    city: 'Freyburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06632',
    city: 'Gleina',
    countrycode: 'DE',
  },
  {
    postalcode: '06632',
    city: 'Gröst',
    countrycode: 'DE',
  },
  {
    postalcode: '06632',
    city: 'Größnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06632',
    city: 'Schleberoda',
    countrycode: 'DE',
  },
  {
    postalcode: '06632',
    city: 'Zeuchfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '06636',
    city: 'Burgscheidungen',
    countrycode: 'DE',
  },
  {
    postalcode: '06636',
    city: 'Golzen',
    countrycode: 'DE',
  },
  {
    postalcode: '06636',
    city: 'Hirschroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06636',
    city: 'Kirchscheidungen',
    countrycode: 'DE',
  },
  {
    postalcode: '06636',
    city: 'Laucha',
    countrycode: 'DE',
  },
  {
    postalcode: '06636',
    city: 'Weischütz',
    countrycode: 'DE',
  },
  {
    postalcode: '06638',
    city: 'Karsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06642',
    city: 'Altenroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06642',
    city: 'Bucha',
    countrycode: 'DE',
  },
  {
    postalcode: '06642',
    city: 'Memleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06642',
    city: 'Nebra',
    countrycode: 'DE',
  },
  {
    postalcode: '06642',
    city: 'Reinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06642',
    city: 'Wangen',
    countrycode: 'DE',
  },
  {
    postalcode: '06642',
    city: 'Wohlmirstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06642',
    city: 'Zuckerfabrik Vitzenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06647',
    city: 'Bad Bibra',
    countrycode: 'DE',
  },
  {
    postalcode: '06647',
    city: 'Billroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06647',
    city: 'Burkersroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06647',
    city: 'Hohndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06647',
    city: 'Kahlwinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '06647',
    city: 'Klosterhäseler',
    countrycode: 'DE',
  },
  {
    postalcode: '06647',
    city: 'Lindenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06647',
    city: 'Lossa',
    countrycode: 'DE',
  },
  {
    postalcode: '06647',
    city: 'Saubach',
    countrycode: 'DE',
  },
  {
    postalcode: '06647',
    city: 'Steinburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06647',
    city: 'Thalwinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '06647',
    city: 'Wischroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06648',
    city: 'Braunsroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06648',
    city: 'Burgholzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06648',
    city: 'Eckartsberga',
    countrycode: 'DE',
  },
  {
    postalcode: '06648',
    city: 'Gernstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06648',
    city: 'Herrengosserstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06648',
    city: 'Tromsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Burgwerben',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Goseck',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Gröbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Langendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Leißling',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Markwerben',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Pretzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Prittitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Reichardtswerben',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Schmerdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Storkau',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Stößen',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Tagewerben',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Uichteritz',
    countrycode: 'DE',
  },
  {
    postalcode: '06667',
    city: 'Weißenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '06679',
    city: 'Granschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '06679',
    city: 'Großgrimma',
    countrycode: 'DE',
  },
  {
    postalcode: '06679',
    city: 'Hohenmölsen',
    countrycode: 'DE',
  },
  {
    postalcode: '06679',
    city: 'Muschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06679',
    city: 'Taucha',
    countrycode: 'DE',
  },
  {
    postalcode: '06679',
    city: 'Webau',
    countrycode: 'DE',
  },
  {
    postalcode: '06679',
    city: 'Zembschen',
    countrycode: 'DE',
  },
  {
    postalcode: '06679',
    city: 'Zorbau',
    countrycode: 'DE',
  },
  {
    postalcode: '06682',
    city: 'Deuben',
    countrycode: 'DE',
  },
  {
    postalcode: '06682',
    city: 'Gröben',
    countrycode: 'DE',
  },
  {
    postalcode: '06682',
    city: 'Krauschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06682',
    city: 'Nessa',
    countrycode: 'DE',
  },
  {
    postalcode: '06682',
    city: 'Schelkau',
    countrycode: 'DE',
  },
  {
    postalcode: '06682',
    city: 'Teuchern',
    countrycode: 'DE',
  },
  {
    postalcode: '06682',
    city: 'Trebnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06682',
    city: 'Werschen',
    countrycode: 'DE',
  },
  {
    postalcode: '06686',
    city: 'Dehlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06686',
    city: 'Großgörschen',
    countrycode: 'DE',
  },
  {
    postalcode: '06686',
    city: 'Lützen',
    countrycode: 'DE',
  },
  {
    postalcode: '06686',
    city: 'Poserna',
    countrycode: 'DE',
  },
  {
    postalcode: '06686',
    city: 'Rippach',
    countrycode: 'DE',
  },
  {
    postalcode: '06686',
    city: 'Röcken',
    countrycode: 'DE',
  },
  {
    postalcode: '06686',
    city: 'Starsiedel',
    countrycode: 'DE',
  },
  {
    postalcode: '06686',
    city: 'Sössen',
    countrycode: 'DE',
  },
  {
    postalcode: '06688',
    city: 'Großkorbetha',
    countrycode: 'DE',
  },
  {
    postalcode: '06688',
    city: 'Schkortleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06688',
    city: 'Wengelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Bergisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Bornitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Breitenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Draschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Droßdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Döschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Geußnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Grana',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Göbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Haynsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Heuckewalde',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Kretzschau',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Könderitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Schellbach',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Schkauditz',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Wittgendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Würchwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Zangenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06712',
    city: 'Zeitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06721',
    city: 'Goldschau',
    countrycode: 'DE',
  },
  {
    postalcode: '06721',
    city: 'Meineweh',
    countrycode: 'DE',
  },
  {
    postalcode: '06721',
    city: 'Osterfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '06721',
    city: 'Unterkaka',
    countrycode: 'DE',
  },
  {
    postalcode: '06721',
    city: 'Waldau',
    countrycode: 'DE',
  },
  {
    postalcode: '06722',
    city: 'Droyßig',
    countrycode: 'DE',
  },
  {
    postalcode: '06722',
    city: 'Kleinhelmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06722',
    city: 'Weickelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06722',
    city: 'Weißenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '06722',
    city: 'Wetterzeube',
    countrycode: 'DE',
  },
  {
    postalcode: '06724',
    city: 'Bröckau',
    countrycode: 'DE',
  },
  {
    postalcode: '06724',
    city: 'Kayna',
    countrycode: 'DE',
  },
  {
    postalcode: '06724',
    city: 'Spora',
    countrycode: 'DE',
  },
  {
    postalcode: '06725',
    city: 'Profen',
    countrycode: 'DE',
  },
  {
    postalcode: '06725',
    city: 'Reuden',
    countrycode: 'DE',
  },
  {
    postalcode: '06727',
    city: 'Döbris',
    countrycode: 'DE',
  },
  {
    postalcode: '06727',
    city: 'Luckenau',
    countrycode: 'DE',
  },
  {
    postalcode: '06727',
    city: 'Nonnewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06727',
    city: 'Schleinitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06727',
    city: 'Theißen',
    countrycode: 'DE',
  },
  {
    postalcode: '06729',
    city: 'Etzoldshain',
    countrycode: 'DE',
  },
  {
    postalcode: '06729',
    city: 'Langendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06729',
    city: 'Minkwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06729',
    city: 'Rehmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06729',
    city: 'Torna',
    countrycode: 'DE',
  },
  {
    postalcode: '06729',
    city: 'Traupitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06729',
    city: 'Tröglitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06729',
    city: 'Wadewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06749',
    city: 'Bitterfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '06749',
    city: 'Friedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06766',
    city: 'Bobbau',
    countrycode: 'DE',
  },
  {
    postalcode: '06766',
    city: 'Reuden an der Fuhne',
    countrycode: 'DE',
  },
  {
    postalcode: '06766',
    city: 'Rödgen',
    countrycode: 'DE',
  },
  {
    postalcode: '06766',
    city: 'Thalheim',
    countrycode: 'DE',
  },
  {
    postalcode: '06766',
    city: 'Wolfen',
    countrycode: 'DE',
  },
  {
    postalcode: '06773',
    city: 'Bergwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06773',
    city: 'Gossa',
    countrycode: 'DE',
  },
  {
    postalcode: '06773',
    city: 'Gräfenhainichen',
    countrycode: 'DE',
  },
  {
    postalcode: '06773',
    city: 'Gröbern',
    countrycode: 'DE',
  },
  {
    postalcode: '06773',
    city: 'Jüdenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06773',
    city: 'Radis',
    countrycode: 'DE',
  },
  {
    postalcode: '06773',
    city: 'Rotta',
    countrycode: 'DE',
  },
  {
    postalcode: '06773',
    city: 'Schköna',
    countrycode: 'DE',
  },
  {
    postalcode: '06773',
    city: 'Selbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06773',
    city: 'Uthausen',
    countrycode: 'DE',
  },
  {
    postalcode: '06774',
    city: 'Krina',
    countrycode: 'DE',
  },
  {
    postalcode: '06774',
    city: 'Mühlbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '06774',
    city: 'Plodda',
    countrycode: 'DE',
  },
  {
    postalcode: '06774',
    city: 'Pouch',
    countrycode: 'DE',
  },
  {
    postalcode: '06774',
    city: 'Rösa',
    countrycode: 'DE',
  },
  {
    postalcode: '06774',
    city: 'Schlaitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06774',
    city: 'Schwemsal',
    countrycode: 'DE',
  },
  {
    postalcode: '06774',
    city: 'Söllichau',
    countrycode: 'DE',
  },
  {
    postalcode: '06774',
    city: 'Tornau',
    countrycode: 'DE',
  },
  {
    postalcode: '06779',
    city: 'Marke',
    countrycode: 'DE',
  },
  {
    postalcode: '06779',
    city: 'Raguhn',
    countrycode: 'DE',
  },
  {
    postalcode: '06779',
    city: 'Retzau',
    countrycode: 'DE',
  },
  {
    postalcode: '06779',
    city: 'Salzfurtkapelle',
    countrycode: 'DE',
  },
  {
    postalcode: '06779',
    city: 'Schierau',
    countrycode: 'DE',
  },
  {
    postalcode: '06779',
    city: 'Thurland',
    countrycode: 'DE',
  },
  {
    postalcode: '06779',
    city: 'Tornau von der Heide',
    countrycode: 'DE',
  },
  {
    postalcode: '06780',
    city: 'Großzöberitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06780',
    city: 'Göttnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06780',
    city: 'Heideloh',
    countrycode: 'DE',
  },
  {
    postalcode: '06780',
    city: 'Löberitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06780',
    city: 'Quetzdölsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06780',
    city: 'Schrenz',
    countrycode: 'DE',
  },
  {
    postalcode: '06780',
    city: 'Spören',
    countrycode: 'DE',
  },
  {
    postalcode: '06780',
    city: 'Stumsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06780',
    city: 'Zörbig',
    countrycode: 'DE',
  },
  {
    postalcode: '06785',
    city: 'Brandhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '06785',
    city: 'Horstdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06785',
    city: 'Kakau',
    countrycode: 'DE',
  },
  {
    postalcode: '06785',
    city: 'Oranienbaum',
    countrycode: 'DE',
  },
  {
    postalcode: '06785',
    city: 'Schleesen',
    countrycode: 'DE',
  },
  {
    postalcode: '06786',
    city: 'Gohrau',
    countrycode: 'DE',
  },
  {
    postalcode: '06786',
    city: 'Griesen',
    countrycode: 'DE',
  },
  {
    postalcode: '06786',
    city: 'Rehsen',
    countrycode: 'DE',
  },
  {
    postalcode: '06786',
    city: 'Riesigk',
    countrycode: 'DE',
  },
  {
    postalcode: '06786',
    city: 'Vockerode',
    countrycode: 'DE',
  },
  {
    postalcode: '06786',
    city: 'Wörlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06791',
    city: 'Möhlau',
    countrycode: 'DE',
  },
  {
    postalcode: '06791',
    city: 'Zschornewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06792',
    city: 'Sandersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06794',
    city: 'Glebitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '06794',
    city: 'Ramsin',
    countrycode: 'DE',
  },
  {
    postalcode: '06794',
    city: 'Renneritz',
    countrycode: 'DE',
  },
  {
    postalcode: '06794',
    city: 'Zscherndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06796',
    city: 'Brehna',
    countrycode: 'DE',
  },
  {
    postalcode: '06800',
    city: 'Altjeßnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06800',
    city: 'Jeßnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06803',
    city: 'Greppin',
    countrycode: 'DE',
  },
  {
    postalcode: '06804',
    city: 'Burgkemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06804',
    city: 'Muldenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '06808',
    city: 'Holzweißig',
    countrycode: 'DE',
  },
  {
    postalcode: '06809',
    city: 'Petersroda',
    countrycode: 'DE',
  },
  {
    postalcode: '06809',
    city: 'Roitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '06842',
    city: 'Dessau',
    countrycode: 'DE',
  },
  {
    postalcode: '06844',
    city: 'Dessau',
    countrycode: 'DE',
  },
  {
    postalcode: '06846',
    city: 'Dessau',
    countrycode: 'DE',
  },
  {
    postalcode: '06847',
    city: 'Dessau',
    countrycode: 'DE',
  },
  {
    postalcode: '06849',
    city: 'Dessau',
    countrycode: 'DE',
  },
  {
    postalcode: '06862',
    city: 'Brambach',
    countrycode: 'DE',
  },
  {
    postalcode: '06862',
    city: 'Bräsen',
    countrycode: 'DE',
  },
  {
    postalcode: '06862',
    city: 'Hundeluft',
    countrycode: 'DE',
  },
  {
    postalcode: '06862',
    city: 'Jeber-Bergfrieden',
    countrycode: 'DE',
  },
  {
    postalcode: '06862',
    city: 'Mühlstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '06862',
    city: 'Ragösen',
    countrycode: 'DE',
  },
  {
    postalcode: '06862',
    city: 'Rodleben',
    countrycode: 'DE',
  },
  {
    postalcode: '06862',
    city: 'Roßlau',
    countrycode: 'DE',
  },
  {
    postalcode: '06862',
    city: 'Serno',
    countrycode: 'DE',
  },
  {
    postalcode: '06862',
    city: 'Stackelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06862',
    city: 'Streetz',
    countrycode: 'DE',
  },
  {
    postalcode: '06862',
    city: 'Thießen',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Buko',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Cobbelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Coswig',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Düben',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Griebo',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Göritz',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Klieken',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Köselitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Luko',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Möllensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Senst',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Wörpen',
    countrycode: 'DE',
  },
  {
    postalcode: '06869',
    city: 'Zieko',
    countrycode: 'DE',
  },
  {
    postalcode: '06886',
    city: 'Lutherstadt Wittenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06888',
    city: 'Abtsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06888',
    city: 'Dabrun',
    countrycode: 'DE',
  },
  {
    postalcode: '06888',
    city: 'Dietrichsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06888',
    city: 'Eutzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '06888',
    city: 'Mochau',
    countrycode: 'DE',
  },
  {
    postalcode: '06888',
    city: 'Mühlanger',
    countrycode: 'DE',
  },
  {
    postalcode: '06888',
    city: 'Pratau',
    countrycode: 'DE',
  },
  {
    postalcode: '06888',
    city: 'Seegrehna',
    countrycode: 'DE',
  },
  {
    postalcode: '06888',
    city: 'Zörnigall',
    countrycode: 'DE',
  },
  {
    postalcode: '06895',
    city: 'Boßdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06895',
    city: 'Bülzig',
    countrycode: 'DE',
  },
  {
    postalcode: '06895',
    city: 'Kropstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '06895',
    city: 'Leetza',
    countrycode: 'DE',
  },
  {
    postalcode: '06895',
    city: 'Rahnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06895',
    city: 'Zahna',
    countrycode: 'DE',
  },
  {
    postalcode: '06896',
    city: 'Nudersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06896',
    city: 'Reinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06896',
    city: 'Schmilkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06896',
    city: 'Straach',
    countrycode: 'DE',
  },
  {
    postalcode: '06901',
    city: 'Ateritz',
    countrycode: 'DE',
  },
  {
    postalcode: '06901',
    city: 'Dorna',
    countrycode: 'DE',
  },
  {
    postalcode: '06901',
    city: 'Globig-Bleddin',
    countrycode: 'DE',
  },
  {
    postalcode: '06901',
    city: 'Kemberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06901',
    city: 'Rackith',
    countrycode: 'DE',
  },
  {
    postalcode: '06901',
    city: 'Schnellin',
    countrycode: 'DE',
  },
  {
    postalcode: '06901',
    city: 'Wartenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06905',
    city: 'Bad Schmiedeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06905',
    city: 'Korgau',
    countrycode: 'DE',
  },
  {
    postalcode: '06905',
    city: 'Meuro',
    countrycode: 'DE',
  },
  {
    postalcode: '06909',
    city: 'Pretzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '06909',
    city: 'Priesitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06909',
    city: 'Trebitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06917',
    city: 'Arnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06917',
    city: 'Battin',
    countrycode: 'DE',
  },
  {
    postalcode: '06917',
    city: 'Düßnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06917',
    city: 'Gorsdorf-Hemsendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06917',
    city: 'Jessen',
    countrycode: 'DE',
  },
  {
    postalcode: '06917',
    city: 'Kleindröben',
    countrycode: 'DE',
  },
  {
    postalcode: '06917',
    city: 'Klöden',
    countrycode: 'DE',
  },
  {
    postalcode: '06917',
    city: 'Leipa',
    countrycode: 'DE',
  },
  {
    postalcode: '06917',
    city: 'Rade',
    countrycode: 'DE',
  },
  {
    postalcode: '06917',
    city: 'Ruhlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06917',
    city: 'Schützberg',
    countrycode: 'DE',
  },
  {
    postalcode: '06918',
    city: 'Elster',
    countrycode: 'DE',
  },
  {
    postalcode: '06918',
    city: 'Gadegast',
    countrycode: 'DE',
  },
  {
    postalcode: '06918',
    city: 'Gentha',
    countrycode: 'DE',
  },
  {
    postalcode: '06918',
    city: 'Listerfehrda',
    countrycode: 'DE',
  },
  {
    postalcode: '06918',
    city: 'Mellnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06918',
    city: 'Morxdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06918',
    city: 'Naundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06918',
    city: 'Seyda',
    countrycode: 'DE',
  },
  {
    postalcode: '06918',
    city: 'Zemnick',
    countrycode: 'DE',
  },
  {
    postalcode: '06922',
    city: 'Axien',
    countrycode: 'DE',
  },
  {
    postalcode: '06922',
    city: 'Gerbisbach',
    countrycode: 'DE',
  },
  {
    postalcode: '06922',
    city: 'Labrun',
    countrycode: 'DE',
  },
  {
    postalcode: '06922',
    city: 'Lebien',
    countrycode: 'DE',
  },
  {
    postalcode: '06922',
    city: 'Plossig',
    countrycode: 'DE',
  },
  {
    postalcode: '06922',
    city: 'Prettin',
    countrycode: 'DE',
  },
  {
    postalcode: '06925',
    city: 'Annaburg',
    countrycode: 'DE',
  },
  {
    postalcode: '06925',
    city: 'Bethau',
    countrycode: 'DE',
  },
  {
    postalcode: '06925',
    city: 'Groß Naundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06925',
    city: 'Löben',
    countrycode: 'DE',
  },
  {
    postalcode: '06925',
    city: 'Purzien',
    countrycode: 'DE',
  },
  {
    postalcode: '06926',
    city: 'Buschkuhnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06926',
    city: 'Großkorga',
    countrycode: 'DE',
  },
  {
    postalcode: '06926',
    city: 'Holzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06926',
    city: 'Kleinkorga',
    countrycode: 'DE',
  },
  {
    postalcode: '06926',
    city: 'Mönchenhöfe',
    countrycode: 'DE',
  },
  {
    postalcode: '06926',
    city: 'Neuerstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '06926',
    city: 'Premsendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '06926',
    city: 'Reicho',
    countrycode: 'DE',
  },
  {
    postalcode: '06928',
    city: 'Dixförda',
    countrycode: 'DE',
  },
  {
    postalcode: '06928',
    city: 'Klossa',
    countrycode: 'DE',
  },
  {
    postalcode: '06928',
    city: 'Linda',
    countrycode: 'DE',
  },
  {
    postalcode: '06928',
    city: 'Lindwerder',
    countrycode: 'DE',
  },
  {
    postalcode: '06928',
    city: 'Mügeln',
    countrycode: 'DE',
  },
  {
    postalcode: '06928',
    city: 'Schweinitz',
    countrycode: 'DE',
  },
  {
    postalcode: '06928',
    city: 'Steinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Arnsgereuth',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Aue am Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Bernsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Beulwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Crösten',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Eyba',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Kleingeschwenda',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Kämmeritze',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Lositz-Jehmichen',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Reschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Saalfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Volkmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Wickersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Wittgendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Wittmannsgereuth',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Witzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07318',
    city: 'Wöhlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07330',
    city: 'Arnsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07330',
    city: 'Döhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '07330',
    city: 'Laasen',
    countrycode: 'DE',
  },
  {
    postalcode: '07330',
    city: 'Marktgölitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07330',
    city: 'Oberloquitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07330',
    city: 'Probstzella',
    countrycode: 'DE',
  },
  {
    postalcode: '07330',
    city: 'Reichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07330',
    city: 'Schaderthal',
    countrycode: 'DE',
  },
  {
    postalcode: '07330',
    city: 'Unterloquitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07333',
    city: 'Unterwellenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '07334',
    city: 'Goßwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07334',
    city: 'Kamsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07336',
    city: 'Birkigt',
    countrycode: 'DE',
  },
  {
    postalcode: '07336',
    city: 'Könitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Altenbeuthen',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Blaues Glück',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Dorfilm',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Drognitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Großgeschwenda',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Grünau',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Hirzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Hohenwarte',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Kaulsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Landsendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Leutenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Lichtentanne',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Munschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Neidenberga',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Neue Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Neuenbeuthen',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Reitzengeschwenda',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Rosenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Schweinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Steinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07338',
    city: 'Wickendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07343',
    city: 'Granitwerk Sormitztal',
    countrycode: 'DE',
  },
  {
    postalcode: '07343',
    city: 'Grubersmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '07343',
    city: 'Neumühle',
    countrycode: 'DE',
  },
  {
    postalcode: '07343',
    city: 'Wurzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07343',
    city: 'Zschachenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '07349',
    city: 'Lehesten',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Altengesees',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Burglemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Eliasbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Gahma',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Gleima',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Helmsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Kühler Morgen',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Lobenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Lothra',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Neundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Oberlemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Rauschengesees',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Ruppersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Saaldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Thierbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Thimmendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Unterlemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07356',
    city: 'Weisbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07366',
    city: 'Birkenhügel',
    countrycode: 'DE',
  },
  {
    postalcode: '07366',
    city: 'Blankenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07366',
    city: 'Blankenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '07366',
    city: 'Harra',
    countrycode: 'DE',
  },
  {
    postalcode: '07366',
    city: 'Lichtenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '07366',
    city: 'Pottiga',
    countrycode: 'DE',
  },
  {
    postalcode: '07366',
    city: 'Schlegel',
    countrycode: 'DE',
  },
  {
    postalcode: '07366',
    city: 'Seibis',
    countrycode: 'DE',
  },
  {
    postalcode: '07368',
    city: 'Ebersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07368',
    city: 'Liebengrün',
    countrycode: 'DE',
  },
  {
    postalcode: '07368',
    city: 'Liebschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '07368',
    city: 'Remptendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Bodelwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Döbritz',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Friedebach',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Herschdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Langenorla',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Mariengrund',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Moxa',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Nimritz',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Oberoppurg',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Oppurg',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Paska',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Pößneck',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Solkwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Trannroda',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Wernburg',
    countrycode: 'DE',
  },
  {
    postalcode: '07381',
    city: 'Zella',
    countrycode: 'DE',
  },
  {
    postalcode: '07387',
    city: 'Gräfendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07387',
    city: 'Krölpa',
    countrycode: 'DE',
  },
  {
    postalcode: '07387',
    city: 'Lausnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07387',
    city: 'Rockendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Bucha',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Dobian',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Gertewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Grobengereuth',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Gössitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Keila',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Knau',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Peuschen',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Quaschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Ranis',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Schmorda',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Seisla',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Wilhelmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07389',
    city: 'Wöhlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Ammelstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Beutelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Breitenheerda',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Clöswitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Dorndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Engerda',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Eschdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Geitersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Großkochberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Haufeld',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Heilingen',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Heilsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Kirchhasel',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Kleinkochberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Milbitz bei Teichel',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Niederkrossen',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Remda',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Rudolstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Rödelwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Schloßkulm',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Schmieden',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Sundremda',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Teichel',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Teichröda',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Teichweiden',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Treppendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Tännich',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Uhlstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '07407',
    city: 'Zeutsch',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Bad Blankenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Burkersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Dittersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Dittrichshütte',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Hengelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Kossau',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Leutnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Milbitz bei Rottenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Paulinzella',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Quittelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Rottenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Solsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Thälendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07422',
    city: 'Unterwirbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07426',
    city: 'Allendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07426',
    city: 'Aschau',
    countrycode: 'DE',
  },
  {
    postalcode: '07426',
    city: 'Bechstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '07426',
    city: 'Dröbischau',
    countrycode: 'DE',
  },
  {
    postalcode: '07426',
    city: 'Köditzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07426',
    city: 'Königsee',
    countrycode: 'DE',
  },
  {
    postalcode: '07426',
    city: 'Oberhain',
    countrycode: 'DE',
  },
  {
    postalcode: '07426',
    city: 'Unterhain',
    countrycode: 'DE',
  },
  {
    postalcode: '07427',
    city: 'Schwarzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '07429',
    city: 'Döschnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07429',
    city: 'Rohrbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07429',
    city: 'Sitzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07545',
    city: 'Gera',
    countrycode: 'DE',
  },
  {
    postalcode: '07546',
    city: 'Gera',
    countrycode: 'DE',
  },
  {
    postalcode: '07548',
    city: 'Gera',
    countrycode: 'DE',
  },
  {
    postalcode: '07549',
    city: 'Gera',
    countrycode: 'DE',
  },
  {
    postalcode: '07551',
    city: 'Gera',
    countrycode: 'DE',
  },
  {
    postalcode: '07552',
    city: 'Gera',
    countrycode: 'DE',
  },
  {
    postalcode: '07554',
    city: 'Bethenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '07554',
    city: 'Brahmenau',
    countrycode: 'DE',
  },
  {
    postalcode: '07554',
    city: 'Gera',
    countrycode: 'DE',
  },
  {
    postalcode: '07554',
    city: 'Hirschfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '07554',
    city: 'Kauern',
    countrycode: 'DE',
  },
  {
    postalcode: '07554',
    city: 'Korbußen',
    countrycode: 'DE',
  },
  {
    postalcode: '07554',
    city: 'Pölzig',
    countrycode: 'DE',
  },
  {
    postalcode: '07554',
    city: 'Schwaara',
    countrycode: 'DE',
  },
  {
    postalcode: '07557',
    city: 'Crimla',
    countrycode: 'DE',
  },
  {
    postalcode: '07557',
    city: 'Gera',
    countrycode: 'DE',
  },
  {
    postalcode: '07557',
    city: 'Hundhaupten',
    countrycode: 'DE',
  },
  {
    postalcode: '07557',
    city: 'Köfeln',
    countrycode: 'DE',
  },
  {
    postalcode: '07557',
    city: 'Zedlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Burkersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Endschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Forstwolfersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Frießnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Hohenölsen',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Neundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Niederpöllnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Rohna',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Schömberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Steinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Teichwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Weida',
    countrycode: 'DE',
  },
  {
    postalcode: '07570',
    city: 'Wünschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07580',
    city: 'Braunichswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '07580',
    city: 'Gauern',
    countrycode: 'DE',
  },
  {
    postalcode: '07580',
    city: 'Großenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '07580',
    city: 'Hilbersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07580',
    city: 'Linda',
    countrycode: 'DE',
  },
  {
    postalcode: '07580',
    city: 'Paitzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07580',
    city: 'Reichstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '07580',
    city: 'Ronneburg',
    countrycode: 'DE',
  },
  {
    postalcode: '07580',
    city: 'Rückersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07580',
    city: 'Seelingstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '07586',
    city: 'Bad Köstritz',
    countrycode: 'DE',
  },
  {
    postalcode: '07586',
    city: 'Caaschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07586',
    city: 'Hartmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07586',
    city: 'Kraftsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07589',
    city: 'Bocka',
    countrycode: 'DE',
  },
  {
    postalcode: '07589',
    city: 'Großebersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07589',
    city: 'Lederhose',
    countrycode: 'DE',
  },
  {
    postalcode: '07589',
    city: 'Lindenkreuz',
    countrycode: 'DE',
  },
  {
    postalcode: '07589',
    city: 'Münchenbernsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07589',
    city: 'Saara',
    countrycode: 'DE',
  },
  {
    postalcode: '07589',
    city: 'Schwarzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07607',
    city: 'Eisenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07607',
    city: 'Gösen',
    countrycode: 'DE',
  },
  {
    postalcode: '07607',
    city: 'Hainspitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07613',
    city: 'Crossen',
    countrycode: 'DE',
  },
  {
    postalcode: '07613',
    city: 'Hartmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07613',
    city: 'Heideland',
    countrycode: 'DE',
  },
  {
    postalcode: '07613',
    city: 'Rauda',
    countrycode: 'DE',
  },
  {
    postalcode: '07613',
    city: 'Seifartsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07613',
    city: 'Silbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07613',
    city: 'Walpernhain',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Beulbar-Ilmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Bürgel',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Droschka',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Graitschen bei Bürgel',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Hetzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Hohendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Langethalsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Lochmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Nausnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Petersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Poxdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Rauschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Rodigast-Lucka',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Serba',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Taupadel',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Thalbürgel',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Wilhelmshöhe',
    countrycode: 'DE',
  },
  {
    postalcode: '07616',
    city: 'Ölmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '07619',
    city: 'Mertendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07619',
    city: 'Schkölen',
    countrycode: 'DE',
  },
  {
    postalcode: '07619',
    city: 'Stünzmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '07629',
    city: 'Hermsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07629',
    city: 'Reichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07629',
    city: 'Schleifreisen',
    countrycode: 'DE',
  },
  {
    postalcode: '07629',
    city: 'St Gangloff',
    countrycode: 'DE',
  },
  {
    postalcode: '07639',
    city: 'Bad Klosterlausnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07639',
    city: 'Tautenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '07639',
    city: 'Weißenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Albersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Bobeck',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Bollberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Bremsnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Eineborn',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Geisenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Gneus',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Großbockedra',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Karlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Kleinbockedra',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Kleinebersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Laasdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Lippersdorf-Erdmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Meusebach',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Möckern',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Mörsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Oberbodnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Ottendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Quirla',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Rattelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Rausdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Renthendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Ruttersdorf-Lotschen',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Scheiditz',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Schlöben',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Schöngleina',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Stadtroda',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Tautendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Tissa',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Trockenborn-Wolfersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Tröbnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Unterbodnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Waldeck',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Waltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07646',
    city: 'Weißbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07743',
    city: 'Jena',
    countrycode: 'DE',
  },
  {
    postalcode: '07745',
    city: 'Jena',
    countrycode: 'DE',
  },
  {
    postalcode: '07747',
    city: 'Jena',
    countrycode: 'DE',
  },
  {
    postalcode: '07749',
    city: 'Jena',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Bucha',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Cospeda',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Drackendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Golmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Großlöbichau',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Großpürschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Isserstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Jenalöbnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Jenaprießnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Krippendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Kunitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Löberschütz',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Maua',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Milda',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Münchenroda',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Rothenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Sulza',
    countrycode: 'DE',
  },
  {
    postalcode: '07751',
    city: 'Zöllnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Altenberga',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Bibra',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Eichenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Freienorla',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Großeutersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Gumperda',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Hummelshain',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Kahla',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Kleineutersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Lindig',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Orlamünde',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Reinstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Schöps',
    countrycode: 'DE',
  },
  {
    postalcode: '07768',
    city: 'Seitenroda',
    countrycode: 'DE',
  },
  {
    postalcode: '07774',
    city: 'Camburg',
    countrycode: 'DE',
  },
  {
    postalcode: '07774',
    city: 'Frauenprießnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07774',
    city: 'Thierschneck',
    countrycode: 'DE',
  },
  {
    postalcode: '07774',
    city: 'Wichmar',
    countrycode: 'DE',
  },
  {
    postalcode: '07778',
    city: 'Dornburg',
    countrycode: 'DE',
  },
  {
    postalcode: '07778',
    city: 'Dorndorf-Steudnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07778',
    city: 'Hainichen',
    countrycode: 'DE',
  },
  {
    postalcode: '07778',
    city: 'Lehesten',
    countrycode: 'DE',
  },
  {
    postalcode: '07778',
    city: 'Neuengönna',
    countrycode: 'DE',
  },
  {
    postalcode: '07778',
    city: 'Tautenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '07778',
    city: 'Zimmern',
    countrycode: 'DE',
  },
  {
    postalcode: '07806',
    city: 'Breitenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '07806',
    city: 'Burgwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07806',
    city: 'Dreba',
    countrycode: 'DE',
  },
  {
    postalcode: '07806',
    city: 'Eisenhammer',
    countrycode: 'DE',
  },
  {
    postalcode: '07806',
    city: 'Harrasmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '07806',
    city: 'Kospoda',
    countrycode: 'DE',
  },
  {
    postalcode: '07806',
    city: 'Lausnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07806',
    city: 'Meilitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07806',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '07806',
    city: 'Papiermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '07806',
    city: 'Stanau',
    countrycode: 'DE',
  },
  {
    postalcode: '07806',
    city: 'Weira',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Burkersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Dreitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Geheege',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Geroda',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Kopitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Lemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Leubsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Linda',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Miesitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Mittelpöllnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Pillingsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Rosendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Schmieritz',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Traun',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Triptis',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Tömmelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Weltwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Wittchenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Wüstenwetzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07819',
    city: 'Zwackau',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Burgk',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Chursdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Dittersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Dragensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Görkwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Göschitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Löhma',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Moßbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Oettersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Plothen',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Pörmitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Schleiz',
    countrycode: 'DE',
  },
  {
    postalcode: '07907',
    city: 'Tegau',
    countrycode: 'DE',
  },
  {
    postalcode: '07919',
    city: 'Kirschkau',
    countrycode: 'DE',
  },
  {
    postalcode: '07919',
    city: 'Langenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07919',
    city: 'Mühltroff',
    countrycode: 'DE',
  },
  {
    postalcode: '07922',
    city: 'Tanna',
    countrycode: 'DE',
  },
  {
    postalcode: '07924',
    city: 'Crispendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07924',
    city: 'Eßbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07924',
    city: 'Neundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07924',
    city: 'Schöndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07924',
    city: 'Volkmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07924',
    city: 'Ziegenrück',
    countrycode: 'DE',
  },
  {
    postalcode: '07926',
    city: 'Gefell',
    countrycode: 'DE',
  },
  {
    postalcode: '07927',
    city: 'Hirschberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07929',
    city: 'Saalburg',
    countrycode: 'DE',
  },
  {
    postalcode: '07937',
    city: 'Förthen',
    countrycode: 'DE',
  },
  {
    postalcode: '07937',
    city: 'Langenwolschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07937',
    city: 'Leitlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07937',
    city: 'Läwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07937',
    city: 'Pahren',
    countrycode: 'DE',
  },
  {
    postalcode: '07937',
    city: 'Pöllwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07937',
    city: 'Silberfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '07937',
    city: 'Weckersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07937',
    city: 'Zadelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07937',
    city: 'Zeulenroda',
    countrycode: 'DE',
  },
  {
    postalcode: '07950',
    city: 'Göhren-Döhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '07950',
    city: 'Merkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07950',
    city: 'Niederböhmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07950',
    city: 'Staitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07950',
    city: 'Triebes',
    countrycode: 'DE',
  },
  {
    postalcode: '07950',
    city: 'Weißendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07950',
    city: 'Wiebelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07952',
    city: 'Arnsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '07952',
    city: 'Bernsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '07952',
    city: 'Pausa',
    countrycode: 'DE',
  },
  {
    postalcode: '07955',
    city: 'Auma',
    countrycode: 'DE',
  },
  {
    postalcode: '07955',
    city: 'Braunsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07957',
    city: 'Hain',
    countrycode: 'DE',
  },
  {
    postalcode: '07957',
    city: 'Langenwetzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07958',
    city: 'Hohenleuben',
    countrycode: 'DE',
  },
  {
    postalcode: '07973',
    city: 'Greiz',
    countrycode: 'DE',
  },
  {
    postalcode: '07973',
    city: 'Krebsbach Talsperre',
    countrycode: 'DE',
  },
  {
    postalcode: '07980',
    city: 'Berga',
    countrycode: 'DE',
  },
  {
    postalcode: '07980',
    city: 'Großkundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07980',
    city: 'Kühdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07980',
    city: 'Lunzig',
    countrycode: 'DE',
  },
  {
    postalcode: '07980',
    city: 'Neugernsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07980',
    city: 'Neumühle',
    countrycode: 'DE',
  },
  {
    postalcode: '07980',
    city: 'Waltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07980',
    city: 'Wildetaube',
    countrycode: 'DE',
  },
  {
    postalcode: '07985',
    city: 'Cossengrün',
    countrycode: 'DE',
  },
  {
    postalcode: '07985',
    city: 'Elsterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '07985',
    city: 'Hohndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07985',
    city: 'Leiningen',
    countrycode: 'DE',
  },
  {
    postalcode: '07985',
    city: 'Rentzschmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '07985',
    city: 'Schönbach',
    countrycode: 'DE',
  },
  {
    postalcode: '07985',
    city: 'Tremnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '07987',
    city: 'Mohlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07989',
    city: 'Kleinreinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '07989',
    city: 'Teichwolframsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '08056',
    city: 'Zwickau',
    countrycode: 'DE',
  },
  {
    postalcode: '08058',
    city: 'Zwickau',
    countrycode: 'DE',
  },
  {
    postalcode: '08060',
    city: 'Zwickau',
    countrycode: 'DE',
  },
  {
    postalcode: '08062',
    city: 'Zwickau',
    countrycode: 'DE',
  },
  {
    postalcode: '08064',
    city: 'Zwickau',
    countrycode: 'DE',
  },
  {
    postalcode: '08066',
    city: 'Zwickau',
    countrycode: 'DE',
  },
  {
    postalcode: '08107',
    city: 'Hartmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '08107',
    city: 'Kirchberg',
    countrycode: 'DE',
  },
  {
    postalcode: '08107',
    city: 'Niedercrinitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08112',
    city: 'Wilkau-Haßlau',
    countrycode: 'DE',
  },
  {
    postalcode: '08115',
    city: 'Lichtentanne',
    countrycode: 'DE',
  },
  {
    postalcode: '08115',
    city: 'Schönfels',
    countrycode: 'DE',
  },
  {
    postalcode: '08118',
    city: 'Hartenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '08121',
    city: 'Silberstraße',
    countrycode: 'DE',
  },
  {
    postalcode: '08132',
    city: 'Mülsen',
    countrycode: 'DE',
  },
  {
    postalcode: '08134',
    city: 'Langenweißbach',
    countrycode: 'DE',
  },
  {
    postalcode: '08134',
    city: 'Wildenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '08141',
    city: 'Reinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '08144',
    city: 'Ebersbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '08144',
    city: 'Hirschfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '08144',
    city: 'Stenn',
    countrycode: 'DE',
  },
  {
    postalcode: '08144',
    city: 'Waldhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '08147',
    city: 'Crinitzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '08209',
    city: 'Auerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '08209',
    city: 'Rebesgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08223',
    city: 'Falkenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '08223',
    city: 'Grünbach',
    countrycode: 'DE',
  },
  {
    postalcode: '08223',
    city: 'Grünbach-Muldenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '08223',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '08223',
    city: 'Werda',
    countrycode: 'DE',
  },
  {
    postalcode: '08228',
    city: 'Rodewisch',
    countrycode: 'DE',
  },
  {
    postalcode: '08233',
    city: 'Eich',
    countrycode: 'DE',
  },
  {
    postalcode: '08233',
    city: 'Hartmannsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08233',
    city: 'Treuen',
    countrycode: 'DE',
  },
  {
    postalcode: '08236',
    city: 'Ellefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '08237',
    city: 'Steinberg',
    countrycode: 'DE',
  },
  {
    postalcode: '08239',
    city: 'Bergen',
    countrycode: 'DE',
  },
  {
    postalcode: '08239',
    city: 'Oberlauterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '08239',
    city: 'Trieb',
    countrycode: 'DE',
  },
  {
    postalcode: '08248',
    city: 'Klingenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '08258',
    city: 'Bethanien',
    countrycode: 'DE',
  },
  {
    postalcode: '08258',
    city: 'Breitenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '08258',
    city: 'Landwüst',
    countrycode: 'DE',
  },
  {
    postalcode: '08258',
    city: 'Markneukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '08258',
    city: 'Wernitzgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08261',
    city: 'Schöneck',
    countrycode: 'DE',
  },
  {
    postalcode: '08262',
    city: 'Morgenröthe-Rautenkranz',
    countrycode: 'DE',
  },
  {
    postalcode: '08262',
    city: 'Tannenbergsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '08265',
    city: 'Erlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '08267',
    city: 'Zwota',
    countrycode: 'DE',
  },
  {
    postalcode: '08269',
    city: 'Hammerbrücke',
    countrycode: 'DE',
  },
  {
    postalcode: '08280',
    city: 'Aue',
    countrycode: 'DE',
  },
  {
    postalcode: '08289',
    city: 'Schneeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '08294',
    city: 'Lößnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08297',
    city: 'Zwönitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08301',
    city: 'Schlema',
    countrycode: 'DE',
  },
  {
    postalcode: '08304',
    city: 'Schönheide',
    countrycode: 'DE',
  },
  {
    postalcode: '08309',
    city: 'Blechhammer',
    countrycode: 'DE',
  },
  {
    postalcode: '08309',
    city: 'Eibenstock',
    countrycode: 'DE',
  },
  {
    postalcode: '08309',
    city: 'Neues Wiesenhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '08309',
    city: 'Stabhammer',
    countrycode: 'DE',
  },
  {
    postalcode: '08309',
    city: 'Weitersglashütte',
    countrycode: 'DE',
  },
  {
    postalcode: '08309',
    city: 'Wildenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '08309',
    city: 'Wilzschmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '08312',
    city: 'Lauter',
    countrycode: 'DE',
  },
  {
    postalcode: '08315',
    city: 'Bernsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '08318',
    city: 'Blauenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '08318',
    city: 'Hundshübel',
    countrycode: 'DE',
  },
  {
    postalcode: '08318',
    city: 'Lichtenau',
    countrycode: 'DE',
  },
  {
    postalcode: '08318',
    city: 'Neidhardtsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '08318',
    city: 'Torfstich',
    countrycode: 'DE',
  },
  {
    postalcode: '08318',
    city: 'Wolfsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08321',
    city: 'Zschorlau',
    countrycode: 'DE',
  },
  {
    postalcode: '08324',
    city: 'Bockau',
    countrycode: 'DE',
  },
  {
    postalcode: '08325',
    city: 'Carlsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '08326',
    city: 'Sosa',
    countrycode: 'DE',
  },
  {
    postalcode: '08328',
    city: 'Stützengrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08340',
    city: 'Beierfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '08340',
    city: 'Schwarzenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '08349',
    city: 'Erlabrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '08349',
    city: 'Johanngeorgenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '08352',
    city: 'Markersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '08352',
    city: 'Pöhla',
    countrycode: 'DE',
  },
  {
    postalcode: '08352',
    city: 'Raschau',
    countrycode: 'DE',
  },
  {
    postalcode: '08352',
    city: 'Wolfner Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '08355',
    city: 'Rittersgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08358',
    city: 'Grünhain',
    countrycode: 'DE',
  },
  {
    postalcode: '08359',
    city: 'Breitenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '08371',
    city: 'Glauchau',
    countrycode: 'DE',
  },
  {
    postalcode: '08373',
    city: 'Remse',
    countrycode: 'DE',
  },
  {
    postalcode: '08393',
    city: 'Dennheritz',
    countrycode: 'DE',
  },
  {
    postalcode: '08393',
    city: 'Meerane',
    countrycode: 'DE',
  },
  {
    postalcode: '08393',
    city: 'Schönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '08396',
    city: 'Dürrenuhlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '08396',
    city: 'Oberwiera',
    countrycode: 'DE',
  },
  {
    postalcode: '08396',
    city: 'Waldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '08399',
    city: 'Wolkenburg-Kaufungen',
    countrycode: 'DE',
  },
  {
    postalcode: '08412',
    city: 'Königswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '08412',
    city: 'Leubnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08412',
    city: 'Werdau',
    countrycode: 'DE',
  },
  {
    postalcode: '08412',
    city: 'Steinpleis',
    countrycode: 'DE',
  },
  {
    postalcode: '08412',
    city: 'Langenhessen',
    countrycode: 'DE',
  },
  {
    postalcode: '08427',
    city: 'Fraureuth',
    countrycode: 'DE',
  },
  {
    postalcode: '08428',
    city: 'Langenbernsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '08439',
    city: 'Langenhessen',
    countrycode: 'DE',
  },
  {
    postalcode: '08451',
    city: 'Crimmitschau',
    countrycode: 'DE',
  },
  {
    postalcode: '08459',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '08468',
    city: 'Heinsdorfergrund',
    countrycode: 'DE',
  },
  {
    postalcode: '08468',
    city: 'Mühlwand',
    countrycode: 'DE',
  },
  {
    postalcode: '08468',
    city: 'Reichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '08468',
    city: 'Schneidenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '08485',
    city: 'Lengenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '08485',
    city: 'Schönbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '08485',
    city: 'Waldkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '08491',
    city: 'Brockau',
    countrycode: 'DE',
  },
  {
    postalcode: '08491',
    city: 'Jägerhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '08491',
    city: 'Lauschgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08491',
    city: 'Limbach',
    countrycode: 'DE',
  },
  {
    postalcode: '08491',
    city: 'Netzschkau',
    countrycode: 'DE',
  },
  {
    postalcode: '08491',
    city: 'Reimersgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08496',
    city: 'Neumark',
    countrycode: 'DE',
  },
  {
    postalcode: '08499',
    city: 'Mylau',
    countrycode: 'DE',
  },
  {
    postalcode: '08523',
    city: 'Plauen',
    countrycode: 'DE',
  },
  {
    postalcode: '08525',
    city: 'Kauschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08525',
    city: 'Plauen',
    countrycode: 'DE',
  },
  {
    postalcode: '08527',
    city: 'Neundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '08527',
    city: 'Plauen',
    countrycode: 'DE',
  },
  {
    postalcode: '08527',
    city: 'Rößnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08527',
    city: 'Schneckengrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08527',
    city: 'Straßberg',
    countrycode: 'DE',
  },
  {
    postalcode: '08529',
    city: 'Plauen',
    countrycode: 'DE',
  },
  {
    postalcode: '08538',
    city: 'Burgstein',
    countrycode: 'DE',
  },
  {
    postalcode: '08538',
    city: 'Reuth',
    countrycode: 'DE',
  },
  {
    postalcode: '08538',
    city: 'Weischlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08539',
    city: 'Kornbach',
    countrycode: 'DE',
  },
  {
    postalcode: '08539',
    city: 'Leubnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08539',
    city: 'Mehltheuer',
    countrycode: 'DE',
  },
  {
    postalcode: '08539',
    city: 'Rodau',
    countrycode: 'DE',
  },
  {
    postalcode: '08539',
    city: 'Schönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '08541',
    city: 'Großfriesen',
    countrycode: 'DE',
  },
  {
    postalcode: '08541',
    city: 'Mechelgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08541',
    city: 'Neuensalz',
    countrycode: 'DE',
  },
  {
    postalcode: '08541',
    city: 'Theuma',
    countrycode: 'DE',
  },
  {
    postalcode: '08541',
    city: 'Thoßfell',
    countrycode: 'DE',
  },
  {
    postalcode: '08541',
    city: 'Zobes',
    countrycode: 'DE',
  },
  {
    postalcode: '08543',
    city: 'Helmsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08543',
    city: 'Herlasgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08543',
    city: 'Jocketa',
    countrycode: 'DE',
  },
  {
    postalcode: '08543',
    city: 'Möschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08543',
    city: 'Ruppertsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08547',
    city: 'Jößnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08548',
    city: 'Syrau',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Bobenneukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Bösenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Droßdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Lottengrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Oelsnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Ottengrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Planschwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Posseck',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Sachsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Schönbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Tirpersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Tirschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '08606',
    city: 'Triebel',
    countrycode: 'DE',
  },
  {
    postalcode: '08626',
    city: 'Adorf',
    countrycode: 'DE',
  },
  {
    postalcode: '08626',
    city: 'Eichigt',
    countrycode: 'DE',
  },
  {
    postalcode: '08626',
    city: 'Gettengrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08626',
    city: 'Hermsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '08626',
    city: 'Leubetha',
    countrycode: 'DE',
  },
  {
    postalcode: '08626',
    city: 'Marieney',
    countrycode: 'DE',
  },
  {
    postalcode: '08626',
    city: 'Rebersreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '08626',
    city: 'Unterwürschnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '08626',
    city: 'Wohlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '08645',
    city: 'Bad Elster',
    countrycode: 'DE',
  },
  {
    postalcode: '08648',
    city: 'Bad Brambach',
    countrycode: 'DE',
  },
  {
    postalcode: '09111',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09112',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09113',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09114',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09116',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09117',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09119',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09120',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09122',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09123',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09125',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09126',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09127',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09128',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09130',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09131',
    city: 'Chemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09212',
    city: 'Bräunsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09212',
    city: 'Limbach-Oberfrohna',
    countrycode: 'DE',
  },
  {
    postalcode: '09217',
    city: 'Burgstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '09221',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '09221',
    city: 'Neukirchen-Adorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09224',
    city: 'Grüna',
    countrycode: 'DE',
  },
  {
    postalcode: '09224',
    city: 'Mittelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09228',
    city: 'Wittgensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09232',
    city: 'Hartmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09235',
    city: 'Burkhardtsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09236',
    city: 'Claußnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09241',
    city: 'Mühlau',
    countrycode: 'DE',
  },
  {
    postalcode: '09243',
    city: 'Niederfrohna',
    countrycode: 'DE',
  },
  {
    postalcode: '09244',
    city: 'Lichtenau',
    countrycode: 'DE',
  },
  {
    postalcode: '09246',
    city: 'Pleißa',
    countrycode: 'DE',
  },
  {
    postalcode: '09247',
    city: 'Kändler',
    countrycode: 'DE',
  },
  {
    postalcode: '09247',
    city: 'Röhrsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09249',
    city: 'Taura',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Doberenz',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Erlau',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Hausdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Kaltenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Königsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Königshain-Wiederau',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Köttwitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Leupahn',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Leutenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Rochlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Schwarzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Seelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Seupahn',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Stollsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Terpitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Wechselburg',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Weiditz',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Weißbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Zettlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Zollwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09306',
    city: 'Zschirla',
    countrycode: 'DE',
  },
  {
    postalcode: '09322',
    city: 'Chursdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09322',
    city: 'Penig',
    countrycode: 'DE',
  },
  {
    postalcode: '09322',
    city: 'Tauscha',
    countrycode: 'DE',
  },
  {
    postalcode: '09322',
    city: 'Thierbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09326',
    city: 'Aitzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09326',
    city: 'Arras',
    countrycode: 'DE',
  },
  {
    postalcode: '09326',
    city: 'Dittmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09326',
    city: 'Erlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09326',
    city: 'Geringswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '09326',
    city: 'Holzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '09326',
    city: 'Koltzschen',
    countrycode: 'DE',
  },
  {
    postalcode: '09328',
    city: 'Lunzenau',
    countrycode: 'DE',
  },
  {
    postalcode: '09337',
    city: 'Bernsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09337',
    city: 'Callenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '09337',
    city: 'Hohenstein-Ernstthal',
    countrycode: 'DE',
  },
  {
    postalcode: '09350',
    city: 'Lichtenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '09353',
    city: 'Oberlungwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09355',
    city: 'Gersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09356',
    city: 'St. Egidien',
    countrycode: 'DE',
  },
  {
    postalcode: '09366',
    city: 'Niederdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09366',
    city: 'Stollberg',
    countrycode: 'DE',
  },
  {
    postalcode: '09376',
    city: 'Oelsnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09380',
    city: 'Thalheim',
    countrycode: 'DE',
  },
  {
    postalcode: '09385',
    city: 'Erlbach-Kirchberg',
    countrycode: 'DE',
  },
  {
    postalcode: '09385',
    city: 'Lugau',
    countrycode: 'DE',
  },
  {
    postalcode: '09387',
    city: 'Jahnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09390',
    city: 'Gornsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09392',
    city: 'Auerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09394',
    city: 'Hohndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09395',
    city: 'Hormersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09399',
    city: 'Niederwürschnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09405',
    city: 'Gornau',
    countrycode: 'DE',
  },
  {
    postalcode: '09405',
    city: 'Zschopau',
    countrycode: 'DE',
  },
  {
    postalcode: '09419',
    city: 'Thum',
    countrycode: 'DE',
  },
  {
    postalcode: '09419',
    city: 'Thum-Herold',
    countrycode: 'DE',
  },
  {
    postalcode: '09419',
    city: 'Thum-Jahnsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09419',
    city: 'Venusberg Spinnerei',
    countrycode: 'DE',
  },
  {
    postalcode: '09423',
    city: 'Gelenau',
    countrycode: 'DE',
  },
  {
    postalcode: '09423',
    city: 'Wiltzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '09427',
    city: 'Ehrenfriedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09429',
    city: 'Falkenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09429',
    city: 'Gehringswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '09429',
    city: 'Hilmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09429',
    city: 'Hopfgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '09429',
    city: 'Schönbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '09429',
    city: 'Wolkenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '09430',
    city: 'Drebach',
    countrycode: 'DE',
  },
  {
    postalcode: '09430',
    city: 'Venusberg',
    countrycode: 'DE',
  },
  {
    postalcode: '09432',
    city: 'Großolbersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09434',
    city: 'Hohndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09434',
    city: 'Krumhermersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09435',
    city: 'Grießbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09435',
    city: 'Scharfenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '09437',
    city: 'Börnichen',
    countrycode: 'DE',
  },
  {
    postalcode: '09437',
    city: 'Waldkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '09437',
    city: 'Witzschdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09439',
    city: 'Amtsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '09439',
    city: 'Wilischthal',
    countrycode: 'DE',
  },
  {
    postalcode: '09456',
    city: 'Annaberg-Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '09456',
    city: 'Mildenau',
    countrycode: 'DE',
  },
  {
    postalcode: '09465',
    city: 'Sehmatal-Cranzahl',
    countrycode: 'DE',
  },
  {
    postalcode: '09465',
    city: 'Sehmatal-Neudorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09465',
    city: 'Sehmatal-Sehma',
    countrycode: 'DE',
  },
  {
    postalcode: '09468',
    city: 'Geyer',
    countrycode: 'DE',
  },
  {
    postalcode: '09468',
    city: 'Jugendherberge Hormersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09468',
    city: 'Tannenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '09471',
    city: 'Bärenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '09474',
    city: 'Crottendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09474',
    city: 'Walthersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09477',
    city: 'Grumbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09477',
    city: 'Jöhstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '09477',
    city: 'Steinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09481',
    city: 'Elterlein',
    countrycode: 'DE',
  },
  {
    postalcode: '09481',
    city: 'Scheibenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '09484',
    city: 'Oberwiesenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '09487',
    city: 'Schlettau',
    countrycode: 'DE',
  },
  {
    postalcode: '09488',
    city: 'Neundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09488',
    city: 'Plattenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '09488',
    city: 'Schönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '09488',
    city: 'Thermalbad Wiesenbad',
    countrycode: 'DE',
  },
  {
    postalcode: '09488',
    city: 'Wiesa',
    countrycode: 'DE',
  },
  {
    postalcode: '09496',
    city: 'Kühnhaide',
    countrycode: 'DE',
  },
  {
    postalcode: '09496',
    city: 'Marienberg',
    countrycode: 'DE',
  },
  {
    postalcode: '09496',
    city: 'Pobershau',
    countrycode: 'DE',
  },
  {
    postalcode: '09496',
    city: 'Reitzenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '09496',
    city: 'Satzung',
    countrycode: 'DE',
  },
  {
    postalcode: '09509',
    city: 'Pockau',
    countrycode: 'DE',
  },
  {
    postalcode: '09514',
    city: 'Lengefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '09517',
    city: 'Zöblitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09518',
    city: 'Großrückerswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '09526',
    city: 'Heidersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09526',
    city: 'Olbernhau',
    countrycode: 'DE',
  },
  {
    postalcode: '09526',
    city: 'Pfaffroda',
    countrycode: 'DE',
  },
  {
    postalcode: '09526',
    city: 'Pfaffroda-Dittmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09526',
    city: 'Pfaffroda-Dörnthal',
    countrycode: 'DE',
  },
  {
    postalcode: '09526',
    city: 'Pfaffroda-Hallbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09526',
    city: 'Pfaffroda-Haselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09526',
    city: 'Pfaffroda-Hutha',
    countrycode: 'DE',
  },
  {
    postalcode: '09526',
    city: 'Pfaffroda-Schönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '09526',
    city: 'Rübenau',
    countrycode: 'DE',
  },
  {
    postalcode: '09544',
    city: 'Neuhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '09548',
    city: 'Deutschneudorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09548',
    city: 'Seiffen',
    countrycode: 'DE',
  },
  {
    postalcode: '09557',
    city: 'Flöha',
    countrycode: 'DE',
  },
  {
    postalcode: '09569',
    city: 'Falkenau',
    countrycode: 'DE',
  },
  {
    postalcode: '09569',
    city: 'Frankenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '09569',
    city: 'Gahlenz',
    countrycode: 'DE',
  },
  {
    postalcode: '09569',
    city: 'Oederan',
    countrycode: 'DE',
  },
  {
    postalcode: '09573',
    city: 'Augustusburg',
    countrycode: 'DE',
  },
  {
    postalcode: '09573',
    city: 'Dittmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09573',
    city: 'Leubsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09575',
    city: 'Eppendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09577',
    city: 'Niederwiesa',
    countrycode: 'DE',
  },
  {
    postalcode: '09579',
    city: 'Borstendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09579',
    city: 'Grünhainichen',
    countrycode: 'DE',
  },
  {
    postalcode: '09599',
    city: 'Freiberg',
    countrycode: 'DE',
  },
  {
    postalcode: '09600',
    city: 'Niederschöna',
    countrycode: 'DE',
  },
  {
    postalcode: '09600',
    city: 'Oberschöna',
    countrycode: 'DE',
  },
  {
    postalcode: '09600',
    city: 'Wegefarth',
    countrycode: 'DE',
  },
  {
    postalcode: '09600',
    city: 'Weißenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '09603',
    city: 'Bräunsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09603',
    city: 'Großschirma',
    countrycode: 'DE',
  },
  {
    postalcode: '09603',
    city: 'Langhennersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09618',
    city: 'Brand-Erbisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09618',
    city: 'Großhartmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09619',
    city: 'Dorfchemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '09619',
    city: 'Helbigsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09619',
    city: 'Mulda',
    countrycode: 'DE',
  },
  {
    postalcode: '09619',
    city: 'Sayda',
    countrycode: 'DE',
  },
  {
    postalcode: '09619',
    city: 'Voigtsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09619',
    city: 'Wolfsgrund',
    countrycode: 'DE',
  },
  {
    postalcode: '09619',
    city: 'Zethau',
    countrycode: 'DE',
  },
  {
    postalcode: '09623',
    city: 'Frauenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '09623',
    city: 'Rechenberg-Bienenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '09627',
    city: 'Bobritzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '09627',
    city: 'Hilbersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09629',
    city: 'Bieberstein',
    countrycode: 'DE',
  },
  {
    postalcode: '09629',
    city: 'Burkersdorf, Mulde',
    countrycode: 'DE',
  },
  {
    postalcode: '09629',
    city: 'Dittmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '09629',
    city: 'Drehfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '09629',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '09629',
    city: 'Reinsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '09629',
    city: 'Steinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09629',
    city: 'Wolfsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '09633',
    city: 'Halsbrücke',
    countrycode: 'DE',
  },
  {
    postalcode: '09634',
    city: 'Friedland',
    countrycode: 'DE',
  },
  {
    postalcode: '09634',
    city: 'Hirschfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '09634',
    city: 'Siebenlehn',
    countrycode: 'DE',
  },
  {
    postalcode: '09636',
    city: 'Langenau',
    countrycode: 'DE',
  },
  {
    postalcode: '09638',
    city: 'Lichtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '09648',
    city: 'Altmittweida',
    countrycode: 'DE',
  },
  {
    postalcode: '09648',
    city: 'Kriebstein',
    countrycode: 'DE',
  },
  {
    postalcode: '09648',
    city: 'Mittweida',
    countrycode: 'DE',
  },
  {
    postalcode: '09661',
    city: 'Hainichen',
    countrycode: 'DE',
  },
  {
    postalcode: '09661',
    city: 'Rossau',
    countrycode: 'DE',
  },
  {
    postalcode: '09661',
    city: 'Schlegel',
    countrycode: 'DE',
  },
  {
    postalcode: '09661',
    city: 'Striegistal',
    countrycode: 'DE',
  },
  {
    postalcode: '09661',
    city: 'Tiefenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '09669',
    city: 'Frankenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '10115',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10117',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10119',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10178',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10179',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10243',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10245',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10247',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10249',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10315',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10317',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10318',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10319',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10365',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10367',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10369',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10405',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10407',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10409',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10435',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10437',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10439',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10551',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10553',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10555',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10557',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10559',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10585',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10587',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10589',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10623',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10625',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10627',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10629',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10707',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10709',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10711',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10713',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10715',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10717',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10719',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10777',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10779',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10781',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10783',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10785',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10787',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10789',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10823',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10825',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10827',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10829',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10961',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10963',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10965',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10967',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10969',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10997',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '10999',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12043',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12045',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12047',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12049',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12051',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12053',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12055',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12057',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12059',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12099',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12101',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12103',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12105',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12107',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12109',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12157',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12159',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12161',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12163',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12165',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12167',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12169',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12203',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12205',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12207',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12209',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12247',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12249',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12277',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12279',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12305',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12307',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12309',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12347',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12349',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12351',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12353',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12355',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12357',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12359',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12435',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12437',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12439',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12459',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12487',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12489',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12524',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12526',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12527',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12529',
    city: 'Schönefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '12555',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12557',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12559',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12587',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12589',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12619',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12621',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12623',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12625',
    city: 'Waldesruh',
    countrycode: 'DE',
  },
  {
    postalcode: '12627',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12629',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12679',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12681',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12683',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12685',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12687',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '12689',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13051',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13053',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13055',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13057',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13059',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13086',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13088',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13089',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13125',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13127',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13129',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13156',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13158',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13159',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13187',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13189',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13347',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13349',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13351',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13353',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13355',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13357',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13359',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13403',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13405',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13407',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13409',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13435',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13437',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13439',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13465',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13467',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13469',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13503',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13505',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13507',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13509',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13581',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13583',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13585',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13587',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13589',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13591',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13593',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13595',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13597',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13599',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13627',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '13629',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14050',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14052',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14053',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14055',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14057',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14059',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14089',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14109',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14129',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14163',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14165',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14167',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14169',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14193',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14195',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14197',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14199',
    city: 'Berlin',
    countrycode: 'DE',
  },
  {
    postalcode: '14467',
    city: 'Potsdam',
    countrycode: 'DE',
  },
  {
    postalcode: '14469',
    city: 'Potsdam',
    countrycode: 'DE',
  },
  {
    postalcode: '14471',
    city: 'Potsdam',
    countrycode: 'DE',
  },
  {
    postalcode: '14473',
    city: 'Potsdam',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Fahrland',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Golm',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Groß Glienicke',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Göttin',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Kartzow',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Krampnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Leest',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Marquardt',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Neu Fahrland',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Paaren',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Satzkorn',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Seeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Töplitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14476',
    city: 'Uetz',
    countrycode: 'DE',
  },
  {
    postalcode: '14478',
    city: 'Potsdam',
    countrycode: 'DE',
  },
  {
    postalcode: '14480',
    city: 'Potsdam',
    countrycode: 'DE',
  },
  {
    postalcode: '14482',
    city: 'Potsdam',
    countrycode: 'DE',
  },
  {
    postalcode: '14513',
    city: 'Teltow',
    countrycode: 'DE',
  },
  {
    postalcode: '14532',
    city: 'Fahlhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '14532',
    city: 'Güterfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '14532',
    city: 'Kienwerder',
    countrycode: 'DE',
  },
  {
    postalcode: '14532',
    city: 'Kleinmachnow',
    countrycode: 'DE',
  },
  {
    postalcode: '14532',
    city: 'Marggraffshof',
    countrycode: 'DE',
  },
  {
    postalcode: '14532',
    city: 'Nudow',
    countrycode: 'DE',
  },
  {
    postalcode: '14532',
    city: 'Philippsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '14532',
    city: 'Schenkenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '14532',
    city: 'Sputendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14532',
    city: 'Stahnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Bliesendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Elisabethhöhe',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Geltow',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Glindow',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Göhlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Kemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Neu Plötzin',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Petzow',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Phöben',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Plessow',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Plötzin',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Werder',
    countrycode: 'DE',
  },
  {
    postalcode: '14542',
    city: 'Wildpark West',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Beelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Birkhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Busendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Elsholz',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Fichtenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Körzin',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Reesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Rieben',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Salzbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Schlunkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Schäpe',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Schönefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Stücken',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Wittbrietzen',
    countrycode: 'DE',
  },
  {
    postalcode: '14547',
    city: 'Zauchwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14548',
    city: 'Caputh',
    countrycode: 'DE',
  },
  {
    postalcode: '14548',
    city: 'Ferch',
    countrycode: 'DE',
  },
  {
    postalcode: '14550',
    city: 'Bochow',
    countrycode: 'DE',
  },
  {
    postalcode: '14550',
    city: 'Deetz',
    countrycode: 'DE',
  },
  {
    postalcode: '14550',
    city: 'Derwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14550',
    city: 'Groß Kreutz',
    countrycode: 'DE',
  },
  {
    postalcode: '14550',
    city: 'Krielow',
    countrycode: 'DE',
  },
  {
    postalcode: '14550',
    city: 'Neu Bochow',
    countrycode: 'DE',
  },
  {
    postalcode: '14550',
    city: 'Schmergow',
    countrycode: 'DE',
  },
  {
    postalcode: '14552',
    city: 'Fresdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14552',
    city: 'Langerwisch Süd',
    countrycode: 'DE',
  },
  {
    postalcode: '14552',
    city: 'Michendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14552',
    city: 'Saarmund',
    countrycode: 'DE',
  },
  {
    postalcode: '14552',
    city: 'Siedlung Bergheide',
    countrycode: 'DE',
  },
  {
    postalcode: '14552',
    city: 'Tremsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14552',
    city: 'Wildenbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '14554',
    city: 'Seddiner See',
    countrycode: 'DE',
  },
  {
    postalcode: '14557',
    city: 'Langerwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '14557',
    city: 'Wilhelmshorst',
    countrycode: 'DE',
  },
  {
    postalcode: '14558',
    city: 'Bergholz-Rehbrücke',
    countrycode: 'DE',
  },
  {
    postalcode: '14612',
    city: 'Falkensee',
    countrycode: 'DE',
  },
  {
    postalcode: '14612',
    city: 'Waldheim',
    countrycode: 'DE',
  },
  {
    postalcode: '14621',
    city: 'Schönwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '14624',
    city: 'Dallgow',
    countrycode: 'DE',
  },
  {
    postalcode: '14624',
    city: 'Rohrbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '14627',
    city: 'Elstal',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Berge',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Bergerdamm',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Bredow',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Brädikow',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Buchow-Karpzow',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Börnicke',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Dyrotz',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Ebereschenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Etzin',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Falkenrehde',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Gohlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Groß Behnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Grünefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Hanffabrik',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Hertefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Hoppenrade',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Kienberg',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Klein Behnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Lietzow',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Markau',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Markee',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Nauen',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Neu Falkenrehde',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Niebede',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Paaren im Glien',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Paulinenaue',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Pausin',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Perwenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Pessin',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Priort',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Quermathen',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Retzow',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Ribbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Selbelang',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Teufelshof',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Tietzow',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Tremmen',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Utershorst',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Wachow',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Wagenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Wansdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Wernitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Wustermark',
    countrycode: 'DE',
  },
  {
    postalcode: '14641',
    city: 'Zeestow',
    countrycode: 'DE',
  },
  {
    postalcode: '14656',
    city: 'Alt Brieselang',
    countrycode: 'DE',
  },
  {
    postalcode: '14656',
    city: 'Brieselang',
    countrycode: 'DE',
  },
  {
    postalcode: '14662',
    city: 'Friesack',
    countrycode: 'DE',
  },
  {
    postalcode: '14662',
    city: 'Haage',
    countrycode: 'DE',
  },
  {
    postalcode: '14662',
    city: 'Klessener Zootzen',
    countrycode: 'DE',
  },
  {
    postalcode: '14662',
    city: 'Senzke',
    countrycode: 'DE',
  },
  {
    postalcode: '14662',
    city: 'Vietznitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14662',
    city: 'Warsow',
    countrycode: 'DE',
  },
  {
    postalcode: '14662',
    city: 'Wutzetz',
    countrycode: 'DE',
  },
  {
    postalcode: '14662',
    city: 'Zootzen Damm',
    countrycode: 'DE',
  },
  {
    postalcode: '14669',
    city: 'Gutenpaaren',
    countrycode: 'DE',
  },
  {
    postalcode: '14669',
    city: 'Ketzin',
    countrycode: 'DE',
  },
  {
    postalcode: '14669',
    city: 'Paretz',
    countrycode: 'DE',
  },
  {
    postalcode: '14669',
    city: 'Zachow',
    countrycode: 'DE',
  },
  {
    postalcode: '14712',
    city: 'Grünaue',
    countrycode: 'DE',
  },
  {
    postalcode: '14712',
    city: 'Rathenow',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Bahnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Bamme',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Barnewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Buckow bei Großwudicke',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Buckow bei Nennhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Buschow',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Bünsche',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Bützer',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Damme',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Elslaake',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Ferchels',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Ferchesar',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Galm',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Garlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Glien',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Großwudicke',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Gräningen',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Gülpe',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Hohennauen',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Hohenwinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Jerchel',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Knoblauch',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Kohlhof',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Kotzen',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Kriele',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Königshütte',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Landin',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Liepe',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Mahlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Marquede',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Milow',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Molkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Mögelin',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Möthlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Möthlow',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Mützlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Nennhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Neu Dessau',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Neumolkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Neuschollene',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Neuwartensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Nierow',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Nitzahn',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Parey',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Prietzen',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Schleuse Grütz',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Schmetzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Schollene',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Spaatz',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Stechow',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Vieritz',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Wassersuppe',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Wendeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Witzke',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Wolsier',
    countrycode: 'DE',
  },
  {
    postalcode: '14715',
    city: 'Zollchow',
    countrycode: 'DE',
  },
  {
    postalcode: '14727',
    city: 'Döberitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14727',
    city: 'Gapel',
    countrycode: 'DE',
  },
  {
    postalcode: '14727',
    city: 'Premnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14728',
    city: 'Dickte',
    countrycode: 'DE',
  },
  {
    postalcode: '14728',
    city: 'Görne',
    countrycode: 'DE',
  },
  {
    postalcode: '14728',
    city: 'Kietz',
    countrycode: 'DE',
  },
  {
    postalcode: '14728',
    city: 'Kleßen',
    countrycode: 'DE',
  },
  {
    postalcode: '14728',
    city: 'Neuwerder',
    countrycode: 'DE',
  },
  {
    postalcode: '14728',
    city: 'Ohnewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14728',
    city: 'Rhinow',
    countrycode: 'DE',
  },
  {
    postalcode: '14728',
    city: 'Schönholz',
    countrycode: 'DE',
  },
  {
    postalcode: '14728',
    city: 'Strodehne',
    countrycode: 'DE',
  },
  {
    postalcode: '14728',
    city: 'Stölln',
    countrycode: 'DE',
  },
  {
    postalcode: '14770',
    city: 'Brandenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '14772',
    city: 'Brandenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '14774',
    city: 'Brandenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '14776',
    city: 'Brandenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '14776',
    city: 'Paterdamm',
    countrycode: 'DE',
  },
  {
    postalcode: '14776',
    city: 'Rotscherlinde',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Bagow',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Bollmannsruh',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Brielow',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Briest',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Brückermark',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Butzow',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Böcke',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Gollwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Golzow',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Gortz',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Grabow',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Grüneiche',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Grüningen',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Götz',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Jeserig',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Ketzür',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Klein Kreutz',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Krahne',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Lucksfleiß',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Lünow',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Marzahne',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Meßdunk',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Mötzow',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Oberjünne',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Pernitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Päwesin',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Radewege',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Reckahn',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Riewend',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Roskow',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Saaringen',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Schenkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Trechwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Wenzlow',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Weseram',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Wollin',
    countrycode: 'DE',
  },
  {
    postalcode: '14778',
    city: 'Wust',
    countrycode: 'DE',
  },
  {
    postalcode: '14789',
    city: 'Bensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14789',
    city: 'Herrenhölzer',
    countrycode: 'DE',
  },
  {
    postalcode: '14789',
    city: 'Mahlenzien',
    countrycode: 'DE',
  },
  {
    postalcode: '14789',
    city: 'Neubensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14789',
    city: 'Rosenau',
    countrycode: 'DE',
  },
  {
    postalcode: '14789',
    city: 'Vehlen',
    countrycode: 'DE',
  },
  {
    postalcode: '14789',
    city: 'Woltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14789',
    city: 'Wusterwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14793',
    city: 'Buckau',
    countrycode: 'DE',
  },
  {
    postalcode: '14793',
    city: 'Bücknitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14793',
    city: 'Dahlen',
    countrycode: 'DE',
  },
  {
    postalcode: '14793',
    city: 'Dretzen',
    countrycode: 'DE',
  },
  {
    postalcode: '14793',
    city: 'Glienecke',
    countrycode: 'DE',
  },
  {
    postalcode: '14793',
    city: 'Grebs',
    countrycode: 'DE',
  },
  {
    postalcode: '14793',
    city: 'Gräben',
    countrycode: 'DE',
  },
  {
    postalcode: '14793',
    city: 'Köpernitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14793',
    city: 'Pramsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14793',
    city: 'Rottstock',
    countrycode: 'DE',
  },
  {
    postalcode: '14793',
    city: 'Steinberg',
    countrycode: 'DE',
  },
  {
    postalcode: '14793',
    city: 'Ziesar',
    countrycode: 'DE',
  },
  {
    postalcode: '14797',
    city: 'Damsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14797',
    city: 'Emstal',
    countrycode: 'DE',
  },
  {
    postalcode: '14797',
    city: 'Grebs',
    countrycode: 'DE',
  },
  {
    postalcode: '14797',
    city: 'Lehnin',
    countrycode: 'DE',
  },
  {
    postalcode: '14797',
    city: 'Michelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14797',
    city: 'Nahmitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14797',
    city: 'Netzen',
    countrycode: 'DE',
  },
  {
    postalcode: '14797',
    city: 'Prützke',
    countrycode: 'DE',
  },
  {
    postalcode: '14797',
    city: 'Rietz',
    countrycode: 'DE',
  },
  {
    postalcode: '14797',
    city: 'Rädel',
    countrycode: 'DE',
  },
  {
    postalcode: '14798',
    city: 'Fohrde',
    countrycode: 'DE',
  },
  {
    postalcode: '14798',
    city: 'Hohenferchesar',
    countrycode: 'DE',
  },
  {
    postalcode: '14798',
    city: 'Krahnepuhl',
    countrycode: 'DE',
  },
  {
    postalcode: '14798',
    city: 'Kützkow',
    countrycode: 'DE',
  },
  {
    postalcode: '14798',
    city: 'Pritzerbe',
    countrycode: 'DE',
  },
  {
    postalcode: '14798',
    city: 'Seelensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14798',
    city: 'Tieckow',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Baitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Belzig',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Benken',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Bergholz',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Borne',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Dahnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Dippmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Egelinde',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Fredersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Groß Briesen',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Hagelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Hohenspringe',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Klein Briesen',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Kranepuhl',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Kuhlowitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Locktow',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Lübnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Lüsse',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Lütte',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Mörz',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Neschholz',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Preußnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Ragösen',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Rothebach',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Schwanebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Verlorenwasser',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Weitzgrund',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Werbig',
    countrycode: 'DE',
  },
  {
    postalcode: '14806',
    city: 'Ziezow',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Alt Bork',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Borkheide',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Borkwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Brachwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Brück',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Cammer',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Damelang',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Deutsch Bork',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Freienthal',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Gömnigk',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Jeserig',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Linthe',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Neuendorf bei Brück',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Nichel',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Niederwerbig',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Schlalach',
    countrycode: 'DE',
  },
  {
    postalcode: '14822',
    city: 'Trebitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Garrey',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Grabow',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Groß Marzehns',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Grubo',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Haseloff',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Hohenwerbig',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Klein Marzehns',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Klepzig',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Lehnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Lühnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Mützdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Neuendorf bei Niemegk',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Niemegk',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Raben',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Rädigke',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Welsigke',
    countrycode: 'DE',
  },
  {
    postalcode: '14823',
    city: 'Zixdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Arensnest',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Glashütte',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Jeserig',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Jeserigerhütten',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Mahlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Medewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Medewitzerhütten',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Neuehütten',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Reetz',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Reetzerhütten',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Schlamau',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Schmerwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Setzsteig',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Spring',
    countrycode: 'DE',
  },
  {
    postalcode: '14827',
    city: 'Wiesenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '14828',
    city: 'Börnecke',
    countrycode: 'DE',
  },
  {
    postalcode: '14828',
    city: 'Dangelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14828',
    city: 'Görzke',
    countrycode: 'DE',
  },
  {
    postalcode: '14828',
    city: 'Hohenlobbese',
    countrycode: 'DE',
  },
  {
    postalcode: '14828',
    city: 'Reppinichen',
    countrycode: 'DE',
  },
  {
    postalcode: '14828',
    city: 'Wutzow',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Altes Lager',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Bardenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Blönsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Bochow',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Borgisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Buckow',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Bärwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Dalichow',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Danna',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Dennewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Dietersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Eckmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Feldheim',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Fröhden',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Gräfendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Grüna',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Gölsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Heinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Herbersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Hohenahlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Hohengörsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Hohenseefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Höfgen',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Ihlow',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Illmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Jüterbog',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Kaltenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Kloster Zinna',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Kossin',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Kurzlipsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Körbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Langenlipsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Lichterfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Liepe',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Ließen',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Lindow',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Lobbese',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Lüdendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Malterhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Markendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Marzahna',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Meinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Mellnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Merzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Neuheim',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Neuhof bei Zinna',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Niebendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Niedergörsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Nonnendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Oehna',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Pechüle',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Petkus',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Pflügkuff',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Reinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Riesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Rinow',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Rohrbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Schlenzer',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Schmögelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Schwabeck',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Schönefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Seehausen',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Sernow',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Wahlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Waltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Weidmannsruh',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Weißen',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Welsickendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Werbig',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Werder',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Wergzahna',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Wiepersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Wölmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Zellendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14913',
    city: 'Zeuden',
    countrycode: 'DE',
  },
  {
    postalcode: '14929',
    city: 'Frohnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14929',
    city: 'Klausdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14929',
    city: 'Niebel',
    countrycode: 'DE',
  },
  {
    postalcode: '14929',
    city: 'Niebelhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '14929',
    city: 'Rietz',
    countrycode: 'DE',
  },
  {
    postalcode: '14929',
    city: 'Tiefenbrunnen',
    countrycode: 'DE',
  },
  {
    postalcode: '14929',
    city: 'Treuenbrietzen',
    countrycode: 'DE',
  },
  {
    postalcode: '14943',
    city: 'Luckenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '14943',
    city: 'Lüdersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14943',
    city: 'Lühsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14943',
    city: 'Wiesenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '14947',
    city: 'Nuthe-Urstromtal',
    countrycode: 'DE',
  },
  {
    postalcode: '14959',
    city: 'Blankensee',
    countrycode: 'DE',
  },
  {
    postalcode: '14959',
    city: 'Glau',
    countrycode: 'DE',
  },
  {
    postalcode: '14959',
    city: 'Klein Schulzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14959',
    city: 'Kliestow',
    countrycode: 'DE',
  },
  {
    postalcode: '14959',
    city: 'Schönhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '14959',
    city: 'Stangenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '14959',
    city: 'Trebbin',
    countrycode: 'DE',
  },
  {
    postalcode: '14974',
    city: 'Ahrensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14974',
    city: 'Genshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '14974',
    city: 'Großbeuthen',
    countrycode: 'DE',
  },
  {
    postalcode: '14974',
    city: 'Gröben',
    countrycode: 'DE',
  },
  {
    postalcode: '14974',
    city: 'Kerzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14974',
    city: 'Ludwigsfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '14974',
    city: 'Löwenbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '14974',
    city: 'Märkisch Wilmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14974',
    city: 'Siethen',
    countrycode: 'DE',
  },
  {
    postalcode: '14974',
    city: 'Thyrow',
    countrycode: 'DE',
  },
  {
    postalcode: '14974',
    city: 'Wietstock',
    countrycode: 'DE',
  },
  {
    postalcode: '14979',
    city: 'Birkenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '14979',
    city: 'Friderikenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '14979',
    city: 'Großbeeren',
    countrycode: 'DE',
  },
  {
    postalcode: '14979',
    city: 'Heinersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '14979',
    city: 'Osdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15230',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '15232',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '15234',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '15236',
    city: 'Biegen',
    countrycode: 'DE',
  },
  {
    postalcode: '15236',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '15236',
    city: 'Jacobsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15236',
    city: 'Petersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15236',
    city: 'Pillgram',
    countrycode: 'DE',
  },
  {
    postalcode: '15236',
    city: 'Sieversdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15236',
    city: 'Treplin',
    countrycode: 'DE',
  },
  {
    postalcode: '15295',
    city: 'Brieskow-Finkenheerd',
    countrycode: 'DE',
  },
  {
    postalcode: '15295',
    city: 'Groß Lindow',
    countrycode: 'DE',
  },
  {
    postalcode: '15295',
    city: 'Wiesenau',
    countrycode: 'DE',
  },
  {
    postalcode: '15295',
    city: 'Ziltendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15299',
    city: 'Dammendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15299',
    city: 'Grunow',
    countrycode: 'DE',
  },
  {
    postalcode: '15299',
    city: 'Mixdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15299',
    city: 'Müllrose',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Alt Mahlisch',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Diedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Dolgelin',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Falkenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Friedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Gusow',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Libbenichen',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Lietzen',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Marxdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Neu Mahlisch',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Niederjesar',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Platkow',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Sachsendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Seelow',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Werbig',
    countrycode: 'DE',
  },
  {
    postalcode: '15306',
    city: 'Worin',
    countrycode: 'DE',
  },
  {
    postalcode: '15320',
    city: 'Altbarnim',
    countrycode: 'DE',
  },
  {
    postalcode: '15320',
    city: 'Altfriedland',
    countrycode: 'DE',
  },
  {
    postalcode: '15320',
    city: 'Jahnsfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '15320',
    city: 'Neuhardenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15320',
    city: 'Neutrebbin',
    countrycode: 'DE',
  },
  {
    postalcode: '15320',
    city: 'Quappendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15320',
    city: 'Trebnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15320',
    city: 'Wulkow',
    countrycode: 'DE',
  },
  {
    postalcode: '15324',
    city: 'Gieshof-Zelliner Loose',
    countrycode: 'DE',
  },
  {
    postalcode: '15324',
    city: 'Groß Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15324',
    city: 'Kiehnwerder',
    countrycode: 'DE',
  },
  {
    postalcode: '15324',
    city: 'Kienitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15324',
    city: 'Letschin',
    countrycode: 'DE',
  },
  {
    postalcode: '15324',
    city: 'Neubarnim',
    countrycode: 'DE',
  },
  {
    postalcode: '15324',
    city: 'Ortwig',
    countrycode: 'DE',
  },
  {
    postalcode: '15324',
    city: 'Sietzing',
    countrycode: 'DE',
  },
  {
    postalcode: '15324',
    city: 'Solikante',
    countrycode: 'DE',
  },
  {
    postalcode: '15324',
    city: 'Sophienthal',
    countrycode: 'DE',
  },
  {
    postalcode: '15324',
    city: 'Steintoch',
    countrycode: 'DE',
  },
  {
    postalcode: '15324',
    city: 'Wilhelmsaue',
    countrycode: 'DE',
  },
  {
    postalcode: '15326',
    city: 'Carzig',
    countrycode: 'DE',
  },
  {
    postalcode: '15326',
    city: 'Lebus',
    countrycode: 'DE',
  },
  {
    postalcode: '15326',
    city: 'Mallnow',
    countrycode: 'DE',
  },
  {
    postalcode: '15326',
    city: 'Podelzig',
    countrycode: 'DE',
  },
  {
    postalcode: '15326',
    city: 'Schönfließ',
    countrycode: 'DE',
  },
  {
    postalcode: '15326',
    city: 'Wulkow',
    countrycode: 'DE',
  },
  {
    postalcode: '15326',
    city: 'Zeschdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Alt Tucheband',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Bleyen',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Buschdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Friedrichsaue',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Genschmar',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Golzow',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Gorgast',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Hathenow',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Küstrin-Kietz',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Manschnow',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Rathstock',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Reitwein',
    countrycode: 'DE',
  },
  {
    postalcode: '15328',
    city: 'Zechin',
    countrycode: 'DE',
  },
  {
    postalcode: '15344',
    city: 'Strausberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Altlandsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Eggersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Garzau',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Garzin',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Gielsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Hohenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Hoppegarten bei Müncheberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Kagel',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Kienbaum',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Klosterdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Lichtenow',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Möllensee',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Prädikow',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Prötzel',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Rehfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Reichenow-Möglin',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Ruhlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Werder',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Wesendahl',
    countrycode: 'DE',
  },
  {
    postalcode: '15345',
    city: 'Zinndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15366',
    city: 'Birkenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '15366',
    city: 'Dahlwitz-Hoppegarten',
    countrycode: 'DE',
  },
  {
    postalcode: '15366',
    city: 'Hönow',
    countrycode: 'DE',
  },
  {
    postalcode: '15366',
    city: 'Münchehofe, Mark',
    countrycode: 'DE',
  },
  {
    postalcode: '15366',
    city: 'Neuenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '15370',
    city: 'Bruchmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '15370',
    city: 'Fredersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15370',
    city: 'Petershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '15370',
    city: 'Vogelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15374',
    city: 'Eggersdorf Siedlung',
    countrycode: 'DE',
  },
  {
    postalcode: '15374',
    city: 'Hermersdorf/Obersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15374',
    city: 'Müncheberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15377',
    city: 'Batzlow',
    countrycode: 'DE',
  },
  {
    postalcode: '15377',
    city: 'Bergschäferei',
    countrycode: 'DE',
  },
  {
    postalcode: '15377',
    city: 'Bollersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15377',
    city: 'Buckow',
    countrycode: 'DE',
  },
  {
    postalcode: '15377',
    city: 'Grunow',
    countrycode: 'DE',
  },
  {
    postalcode: '15377',
    city: 'Ihlow',
    countrycode: 'DE',
  },
  {
    postalcode: '15377',
    city: 'Liebenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '15377',
    city: 'Reichenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15377',
    city: 'Ringenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '15377',
    city: 'Waldsieversdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15378',
    city: 'Hennickendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15378',
    city: 'Herzfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '15517',
    city: 'Fürstenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Alt Madlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Arensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Beerfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Berkenbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Braunsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Briesen',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Demnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Eggersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Falkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Hangelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Hasenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Heinersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Jänickendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Kolpin',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Langewahl',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Neuendorf im Sande',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Petersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Rauen',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Schönfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Steinhöfel',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Tempelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15518',
    city: 'Wilmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15526',
    city: 'Alt Golm',
    countrycode: 'DE',
  },
  {
    postalcode: '15526',
    city: 'Bad Saarow-Pieskow',
    countrycode: 'DE',
  },
  {
    postalcode: '15526',
    city: 'Neu Golm',
    countrycode: 'DE',
  },
  {
    postalcode: '15526',
    city: 'Reichenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '15528',
    city: 'Hartmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15528',
    city: 'Markgrafpieske',
    countrycode: 'DE',
  },
  {
    postalcode: '15528',
    city: 'Mönchwinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '15528',
    city: 'Spreeau',
    countrycode: 'DE',
  },
  {
    postalcode: '15528',
    city: 'Spreenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '15537',
    city: 'Burig',
    countrycode: 'DE',
  },
  {
    postalcode: '15537',
    city: 'Erkner',
    countrycode: 'DE',
  },
  {
    postalcode: '15537',
    city: 'Gosen',
    countrycode: 'DE',
  },
  {
    postalcode: '15537',
    city: 'Grünheide',
    countrycode: 'DE',
  },
  {
    postalcode: '15537',
    city: 'Neu Zittau',
    countrycode: 'DE',
  },
  {
    postalcode: '15537',
    city: 'Wernsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15562',
    city: 'Rüdersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15566',
    city: 'Schöneiche',
    countrycode: 'DE',
  },
  {
    postalcode: '15569',
    city: 'Woltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15711',
    city: 'Krummensee',
    countrycode: 'DE',
  },
  {
    postalcode: '15711',
    city: 'Königs Wusterhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '15711',
    city: 'Schenkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15711',
    city: 'Zeesen',
    countrycode: 'DE',
  },
  {
    postalcode: '15732',
    city: 'Eichwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '15732',
    city: 'Schulzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15732',
    city: 'Waltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15738',
    city: 'Zeuthen',
    countrycode: 'DE',
  },
  {
    postalcode: '15741',
    city: 'Bestensee',
    countrycode: 'DE',
  },
  {
    postalcode: '15741',
    city: 'Gräbendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15741',
    city: 'Motzen',
    countrycode: 'DE',
  },
  {
    postalcode: '15741',
    city: 'Pätz',
    countrycode: 'DE',
  },
  {
    postalcode: '15745',
    city: 'Wildau',
    countrycode: 'DE',
  },
  {
    postalcode: '15746',
    city: 'Groß Köris',
    countrycode: 'DE',
  },
  {
    postalcode: '15748',
    city: 'Birkholz',
    countrycode: 'DE',
  },
  {
    postalcode: '15748',
    city: 'Hermsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15748',
    city: 'Kleinwasserburg',
    countrycode: 'DE',
  },
  {
    postalcode: '15748',
    city: 'Märkisch Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '15748',
    city: 'Münchehofe',
    countrycode: 'DE',
  },
  {
    postalcode: '15749',
    city: 'Brusendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15749',
    city: 'Gallun',
    countrycode: 'DE',
  },
  {
    postalcode: '15749',
    city: 'Kiekebusch',
    countrycode: 'DE',
  },
  {
    postalcode: '15749',
    city: 'Mittenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '15749',
    city: 'Ragow',
    countrycode: 'DE',
  },
  {
    postalcode: '15749',
    city: 'Schöneicher Plan',
    countrycode: 'DE',
  },
  {
    postalcode: '15751',
    city: 'Miersdorfer Werder',
    countrycode: 'DE',
  },
  {
    postalcode: '15751',
    city: 'Niederlehme',
    countrycode: 'DE',
  },
  {
    postalcode: '15752',
    city: 'Kolberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15752',
    city: 'Prieros',
    countrycode: 'DE',
  },
  {
    postalcode: '15752',
    city: 'Streganz',
    countrycode: 'DE',
  },
  {
    postalcode: '15754',
    city: 'Bindow',
    countrycode: 'DE',
  },
  {
    postalcode: '15754',
    city: 'Blossin',
    countrycode: 'DE',
  },
  {
    postalcode: '15754',
    city: 'Dannenreich',
    countrycode: 'DE',
  },
  {
    postalcode: '15754',
    city: 'Dolgenbrodt',
    countrycode: 'DE',
  },
  {
    postalcode: '15754',
    city: 'Friedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15754',
    city: 'Friedrichshof',
    countrycode: 'DE',
  },
  {
    postalcode: '15754',
    city: 'Gussow',
    countrycode: 'DE',
  },
  {
    postalcode: '15754',
    city: 'Senzig',
    countrycode: 'DE',
  },
  {
    postalcode: '15754',
    city: 'Wolzig',
    countrycode: 'DE',
  },
  {
    postalcode: '15755',
    city: 'Egsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15755',
    city: 'Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15755',
    city: 'Schwerin',
    countrycode: 'DE',
  },
  {
    postalcode: '15755',
    city: 'Teupitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15755',
    city: 'Tornow',
    countrycode: 'DE',
  },
  {
    postalcode: '15755',
    city: 'Töpchin',
    countrycode: 'DE',
  },
  {
    postalcode: '15757',
    city: 'Briesen',
    countrycode: 'DE',
  },
  {
    postalcode: '15757',
    city: 'Freidorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15757',
    city: 'Halbe',
    countrycode: 'DE',
  },
  {
    postalcode: '15757',
    city: 'Löpten',
    countrycode: 'DE',
  },
  {
    postalcode: '15757',
    city: 'Oderin',
    countrycode: 'DE',
  },
  {
    postalcode: '15758',
    city: 'Kablow',
    countrycode: 'DE',
  },
  {
    postalcode: '15758',
    city: 'Kablow Ziegelei',
    countrycode: 'DE',
  },
  {
    postalcode: '15758',
    city: 'Zernsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Christinendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Dabendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Gadsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Glienick',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Groß Machnow',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Groß Schulzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Horstfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Kallinchen',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Kummersdorf-Alexanderdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Mellensee',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Nunsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Nächst Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Rehagen',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Saalow',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Schöneiche',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Schünow',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Telz',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Werben',
    countrycode: 'DE',
  },
  {
    postalcode: '15806',
    city: 'Zossen',
    countrycode: 'DE',
  },
  {
    postalcode: '15827',
    city: 'Blankenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '15827',
    city: 'Dahlewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15831',
    city: 'Birkholz',
    countrycode: 'DE',
  },
  {
    postalcode: '15831',
    city: 'Diedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15831',
    city: 'Diepensee',
    countrycode: 'DE',
  },
  {
    postalcode: '15831',
    city: 'Groß Kienitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15831',
    city: 'Großziethen',
    countrycode: 'DE',
  },
  {
    postalcode: '15831',
    city: 'Jühnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15831',
    city: 'Kleinziethen',
    countrycode: 'DE',
  },
  {
    postalcode: '15831',
    city: 'Mahlow',
    countrycode: 'DE',
  },
  {
    postalcode: '15831',
    city: 'Selchow',
    countrycode: 'DE',
  },
  {
    postalcode: '15831',
    city: 'Waßmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15834',
    city: 'Pramsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15834',
    city: 'Rangsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15834',
    city: 'Theresenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '15837',
    city: 'Baruth',
    countrycode: 'DE',
  },
  {
    postalcode: '15837',
    city: 'Dornswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '15837',
    city: 'Groß Ziescht',
    countrycode: 'DE',
  },
  {
    postalcode: '15837',
    city: 'Horstwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '15837',
    city: 'Klasdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15837',
    city: 'Mückendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15837',
    city: 'Paplitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15837',
    city: 'Radeland',
    countrycode: 'DE',
  },
  {
    postalcode: '15837',
    city: 'Schöbendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15838',
    city: 'Klausdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15838',
    city: 'Kummersdorf Gut',
    countrycode: 'DE',
  },
  {
    postalcode: '15838',
    city: 'Lindenbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '15838',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '15838',
    city: 'Sperenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15838',
    city: 'Waldstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '15838',
    city: 'Wünsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15838',
    city: 'Zesch am See',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Beeskow',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Birkholz',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Bornow',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Bornower Ausbau',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Briescht',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Buckow',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Drahendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Falkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Friedland',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Giesensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Groß Rietz',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Görsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Görzig',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Kadelhof',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Kohlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Kossenblatt',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Krügersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Kunersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Merz',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Mittweide',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Neubrück',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Oegeln',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Pfaffendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Plattkow',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Radinkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Ragow',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Ranzig',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Raßmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Reudnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Rietz-Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Sauen',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Schneeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Speichrow',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Stremmen',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Tauche',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Trebatsch',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Werder',
    countrycode: 'DE',
  },
  {
    postalcode: '15848',
    city: 'Wilmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Alt Stahnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Bugk',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Dickdamm',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Groß Eichholz',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Groß Schauen',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Görsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Kehrigk',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Kummersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Philadelphia',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Rieplos',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Schwerin',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Selchow',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Storkow',
    countrycode: 'DE',
  },
  {
    postalcode: '15859',
    city: 'Wochowsee',
    countrycode: 'DE',
  },
  {
    postalcode: '15864',
    city: 'Ahrensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15864',
    city: 'Behrensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15864',
    city: 'Dahmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15864',
    city: 'Diensdorf-Radlow',
    countrycode: 'DE',
  },
  {
    postalcode: '15864',
    city: 'Glienicke',
    countrycode: 'DE',
  },
  {
    postalcode: '15864',
    city: 'Hartensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15864',
    city: 'Herzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15864',
    city: 'Krachtsheide',
    countrycode: 'DE',
  },
  {
    postalcode: '15864',
    city: 'Limsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15864',
    city: 'Lindenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15864',
    city: 'Wendisch Rietz',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Behlow',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Blasdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Doberburg',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Goschen',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Groß Muckrow',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Hollbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Jamlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Leeskow',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Lieberose',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Münchhofe',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Trebitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Ullersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15868',
    city: 'Wirchensee Försterei',
    countrycode: 'DE',
  },
  {
    postalcode: '15890',
    city: 'Bremsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15890',
    city: 'Eisenhüttenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '15890',
    city: 'Fünfeichen',
    countrycode: 'DE',
  },
  {
    postalcode: '15890',
    city: 'Kieselwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15890',
    city: 'Kobbeln',
    countrycode: 'DE',
  },
  {
    postalcode: '15890',
    city: 'Möbiskruge',
    countrycode: 'DE',
  },
  {
    postalcode: '15890',
    city: 'Pohlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15890',
    city: 'Rießen',
    countrycode: 'DE',
  },
  {
    postalcode: '15890',
    city: 'Schernsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15890',
    city: 'Vogelsang',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Bahro',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Bomsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Breslack',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Coschen',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Göhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Henzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Lawitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Neuzelle',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Ossendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Ratzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Schwerzko',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Steinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Streichwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Treppeln',
    countrycode: 'DE',
  },
  {
    postalcode: '15898',
    city: 'Wellmitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15907',
    city: 'Lübben',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Alt-Schadow',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Dürrenhofe',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Freiwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Groß Wasserburg',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Gröditsch',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Hohenbrück-Neu Schadow',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Krausnick',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Kuschkow',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Leibsch',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Neu Lübbenau',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Neuendorf am See',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Niewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Pretschen',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Rietzneuendorf-Friedrichshof',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Schlepzig',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Schuhlen-Wiese',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Schönwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Staakow',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Waldow',
    countrycode: 'DE',
  },
  {
    postalcode: '15910',
    city: 'Wittmannsdorf-Bückchen',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Alt Zauche',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Biebersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Briesensee',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Butzen',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Byhleguhre',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Byhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Caminchen',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Dollgen',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Glietz',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Goyatz',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Groß Leine',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Groß Leuthen',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Guhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Jessern',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Klein Leine',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Krugau',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Laasow',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Lamsfeld-Groß Liebitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Leibchel',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Mochow',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Neu Zauche',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Ressen',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Sacrow',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Siegadel',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Straupitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Waldow-Sacrow',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Wußwerk',
    countrycode: 'DE',
  },
  {
    postalcode: '15913',
    city: 'Zaue',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Beesdau',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Bornsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Cahnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Duben',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Egsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Falkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Freesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Gehren',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Gießmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Goßmar',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Görlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Karche-Zaacko',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Kemlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Kreblitz',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Langengrassau',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Luckau',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Pitschen-Pickel',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Schlabendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Terpt',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Uckro',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Walddrehna',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Waltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Weißack',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Willmersdorf-Stöbritz',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Wüstermarke',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Zieckau',
    countrycode: 'DE',
  },
  {
    postalcode: '15926',
    city: 'Zöllmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15936',
    city: 'Bollensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15936',
    city: 'Dahme',
    countrycode: 'DE',
  },
  {
    postalcode: '15936',
    city: 'Gebersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15936',
    city: 'Glienig',
    countrycode: 'DE',
  },
  {
    postalcode: '15936',
    city: 'Görsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15936',
    city: 'Mehlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15936',
    city: 'Niendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15936',
    city: 'Prensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15936',
    city: 'Rietdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15936',
    city: 'Rosenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '15938',
    city: 'Drahnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15938',
    city: 'Falkenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '15938',
    city: 'Golßen',
    countrycode: 'DE',
  },
  {
    postalcode: '15938',
    city: 'Jetsch',
    countrycode: 'DE',
  },
  {
    postalcode: '15938',
    city: 'Kasel-Golzig',
    countrycode: 'DE',
  },
  {
    postalcode: '15938',
    city: 'Kümmritz',
    countrycode: 'DE',
  },
  {
    postalcode: '15938',
    city: 'Reichwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '15938',
    city: 'Schiebsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15938',
    city: 'Sellendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15938',
    city: 'Wildau-Wentdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '15938',
    city: 'Zützen',
    countrycode: 'DE',
  },
  {
    postalcode: '16225',
    city: 'Eberswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '16227',
    city: 'Eberswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Blütenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Breydin',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Britz',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Brodowin',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Buckow',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Chorin',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Golzow',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Kahlenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Lichterfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Melchow',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Neuehütte',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Sandkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Senftenhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Serwest',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Sydower Fließ',
    countrycode: 'DE',
  },
  {
    postalcode: '16230',
    city: 'Teerofen',
    countrycode: 'DE',
  },
  {
    postalcode: '16244',
    city: 'Altenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '16244',
    city: 'Eichhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '16244',
    city: 'Finowfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '16244',
    city: 'Werbellin',
    countrycode: 'DE',
  },
  {
    postalcode: '16244',
    city: 'Üdersee bei Werbellin',
    countrycode: 'DE',
  },
  {
    postalcode: '16247',
    city: 'Althüttendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16247',
    city: 'Friedrichswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '16247',
    city: 'Groß-Ziethen',
    countrycode: 'DE',
  },
  {
    postalcode: '16247',
    city: 'Joachimsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '16247',
    city: 'Klein Ziethen',
    countrycode: 'DE',
  },
  {
    postalcode: '16247',
    city: 'Neugrimnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16247',
    city: 'Parlow-Glambeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16247',
    city: 'Wucker',
    countrycode: 'DE',
  },
  {
    postalcode: '16248',
    city: 'Bölkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16248',
    city: 'Hohenfinow',
    countrycode: 'DE',
  },
  {
    postalcode: '16248',
    city: 'Hohensaaten',
    countrycode: 'DE',
  },
  {
    postalcode: '16248',
    city: 'Klosterbrücke',
    countrycode: 'DE',
  },
  {
    postalcode: '16248',
    city: 'Liepe',
    countrycode: 'DE',
  },
  {
    postalcode: '16248',
    city: 'Lunow',
    countrycode: 'DE',
  },
  {
    postalcode: '16248',
    city: 'Lüdersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16248',
    city: 'Niederfinow',
    countrycode: 'DE',
  },
  {
    postalcode: '16248',
    city: 'Oderberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16248',
    city: 'Parstein',
    countrycode: 'DE',
  },
  {
    postalcode: '16248',
    city: 'Stecherschleuse',
    countrycode: 'DE',
  },
  {
    postalcode: '16248',
    city: 'Stolzenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Ackermannshof',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Altgersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Altglietzen',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Altlewin',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Altmädewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Altreetz',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Alttrebbin',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Altwustrow',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Bad Freienwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Beerbaum',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Beiersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Bralitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Broichsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Brunow',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Cöthen',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Dannenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Falkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Freudenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Friedrichshof',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Gratze',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Güstebieser Loose',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Heckelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Hohenwutzen',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Kruge',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Leuenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Neuenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Neugersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Neukietz bei Wriezen',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Neuküstrinchen',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Neulewin',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Neulietzegöricke',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Neumädewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Neureetz',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Neurüdnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Neuwustrow',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Schiffmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Steinbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Tiefensee',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Wölsickendorf-Wollenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16259',
    city: 'Zäckericker Loose',
    countrycode: 'DE',
  },
  {
    postalcode: '16269',
    city: 'Bliesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16269',
    city: 'Harnekop',
    countrycode: 'DE',
  },
  {
    postalcode: '16269',
    city: 'Königshof',
    countrycode: 'DE',
  },
  {
    postalcode: '16269',
    city: 'Sternebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16269',
    city: 'Thöringswerder',
    countrycode: 'DE',
  },
  {
    postalcode: '16269',
    city: 'Wriezen',
    countrycode: 'DE',
  },
  {
    postalcode: '16269',
    city: 'Wriezener Höhe',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Alt-Galow',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Altkünkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Angermünde',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Biesenbrow',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Bruchhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Crussow',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Felchow',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Frauenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Galow',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Gellmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Greiffenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Grünow',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Görlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Günterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Herzsprung',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Kerkow',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Landin',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Mürow',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Neukünkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Pinnow',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Polßen',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Schmargendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Schmiedeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Schöneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Schönermark',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Steinhöfel',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Stolpe',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Welsow',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Wilmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16278',
    city: 'Wolletz',
    countrycode: 'DE',
  },
  {
    postalcode: '16303',
    city: 'Schwedt',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Berkholz-Meyenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Biesendahlshof',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Blumberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Blumenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Casekow',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Criewen',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Flemsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Fredersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Friedrichsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Gatow',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Golm',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Groß Pinnow',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Hohenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Hohenselchow',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Kummerow',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Kunow',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Luckow-Petershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Mürowsche Kavelheide',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Niederfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Schönow',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Stendell',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Vierraden',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Wartin',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Welsebruch',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Woltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Zichow',
    countrycode: 'DE',
  },
  {
    postalcode: '16306',
    city: 'Zützen',
    countrycode: 'DE',
  },
  {
    postalcode: '16307',
    city: 'Gartz',
    countrycode: 'DE',
  },
  {
    postalcode: '16307',
    city: 'Geesow',
    countrycode: 'DE',
  },
  {
    postalcode: '16307',
    city: 'Hohenreinkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16307',
    city: 'Mescherin',
    countrycode: 'DE',
  },
  {
    postalcode: '16307',
    city: 'Neurochlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16307',
    city: 'Radekow',
    countrycode: 'DE',
  },
  {
    postalcode: '16307',
    city: 'Rosow',
    countrycode: 'DE',
  },
  {
    postalcode: '16307',
    city: 'Schönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '16307',
    city: 'Tantow',
    countrycode: 'DE',
  },
  {
    postalcode: '16321',
    city: 'Bernau',
    countrycode: 'DE',
  },
  {
    postalcode: '16321',
    city: 'Börnicke',
    countrycode: 'DE',
  },
  {
    postalcode: '16321',
    city: 'Danewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16321',
    city: 'Ladeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '16321',
    city: 'Lindenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16321',
    city: 'Lobetal',
    countrycode: 'DE',
  },
  {
    postalcode: '16321',
    city: 'Rüdnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16321',
    city: 'Schönow',
    countrycode: 'DE',
  },
  {
    postalcode: '16341',
    city: 'Schwanebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16341',
    city: 'Zepernick',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Groß Schönebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Klosterfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Lottschesee',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Marienwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Marienwerder',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Neudörfchen',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Prenden',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Ruhlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Ruhlsdorf Ausbau',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Sophienstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Stolzenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Wandlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16348',
    city: 'Zerpenschleuse',
    countrycode: 'DE',
  },
  {
    postalcode: '16352',
    city: 'Basdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16352',
    city: 'Schönerlinde',
    countrycode: 'DE',
  },
  {
    postalcode: '16352',
    city: 'Schönwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '16356',
    city: 'Ahrensfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '16356',
    city: 'Blumberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16356',
    city: 'Eiche',
    countrycode: 'DE',
  },
  {
    postalcode: '16356',
    city: 'Hirschfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '16356',
    city: 'Krummensee',
    countrycode: 'DE',
  },
  {
    postalcode: '16356',
    city: 'Löhme',
    countrycode: 'DE',
  },
  {
    postalcode: '16356',
    city: 'Mehrow',
    countrycode: 'DE',
  },
  {
    postalcode: '16356',
    city: 'Schönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '16356',
    city: 'Seefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '16356',
    city: 'Weesow',
    countrycode: 'DE',
  },
  {
    postalcode: '16356',
    city: 'Werneuchen',
    countrycode: 'DE',
  },
  {
    postalcode: '16356',
    city: 'Willmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16359',
    city: 'Biesenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '16359',
    city: 'Lanke',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Bernöwe',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Freienhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Friedrichsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Hertefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Hohenbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Malz',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Nassenheide',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Neuholland',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Oranienburg',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Schmachtenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Teschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Wensickendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Zehlendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16515',
    city: 'Zühlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16540',
    city: 'Hohen Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16540',
    city: 'Stolpe',
    countrycode: 'DE',
  },
  {
    postalcode: '16547',
    city: 'Birkenwerder',
    countrycode: 'DE',
  },
  {
    postalcode: '16548',
    city: 'Glienicke',
    countrycode: 'DE',
  },
  {
    postalcode: '16552',
    city: 'Schildow',
    countrycode: 'DE',
  },
  {
    postalcode: '16556',
    city: 'Borgsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16559',
    city: 'Hammer',
    countrycode: 'DE',
  },
  {
    postalcode: '16559',
    city: 'Kreuzbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '16559',
    city: 'Liebenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '16559',
    city: 'Liebenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '16562',
    city: 'Bergfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '16565',
    city: 'Lehnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16567',
    city: 'Mühlenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16567',
    city: 'Schönfließ',
    countrycode: 'DE',
  },
  {
    postalcode: '16727',
    city: 'Bärenklau',
    countrycode: 'DE',
  },
  {
    postalcode: '16727',
    city: 'Bötzow',
    countrycode: 'DE',
  },
  {
    postalcode: '16727',
    city: 'Eichstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '16727',
    city: 'Marwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16727',
    city: 'Schwante',
    countrycode: 'DE',
  },
  {
    postalcode: '16727',
    city: 'Vehlefanz',
    countrycode: 'DE',
  },
  {
    postalcode: '16727',
    city: 'Velten',
    countrycode: 'DE',
  },
  {
    postalcode: '16761',
    city: 'Hennigsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16761',
    city: 'Stolpe-Süd',
    countrycode: 'DE',
  },
  {
    postalcode: '16766',
    city: 'Amalienfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '16766',
    city: 'Beetz',
    countrycode: 'DE',
  },
  {
    postalcode: '16766',
    city: 'Dorotheenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '16766',
    city: 'Flatow',
    countrycode: 'DE',
  },
  {
    postalcode: '16766',
    city: 'Groß-Ziethen',
    countrycode: 'DE',
  },
  {
    postalcode: '16766',
    city: 'Kremmen',
    countrycode: 'DE',
  },
  {
    postalcode: '16766',
    city: 'Linumhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '16766',
    city: 'Neu-Vehlefanz',
    countrycode: 'DE',
  },
  {
    postalcode: '16766',
    city: 'Sommerfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '16766',
    city: 'Staffelde',
    countrycode: 'DE',
  },
  {
    postalcode: '16767',
    city: 'Germendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16767',
    city: 'Leegebruch',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Altlüdersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Badingen',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Barsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Baumgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Bergsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Blumenow',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Bredereiche',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Burgwall',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Dannenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Dollgow',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Falkenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Glambeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Gramzow',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Gransee',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Grieben',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Großmutz',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Großwoltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Grüneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Gutengermendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Häsen',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Kappe',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Keller',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Klein-Mutz',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Kraatz-Buberow',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Krewelin',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Löwenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Marienthal',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Menz',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Meseberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Mildenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Neuglobsow',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Neulögow',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Neulöwenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Pian',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Ribbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Rönnebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Schulzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Schönermark',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Seilershof',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Sonnenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Tornow',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Wolfsruh',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Zabelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16775',
    city: 'Zernikow',
    countrycode: 'DE',
  },
  {
    postalcode: '16792',
    city: 'Kurtschlag',
    countrycode: 'DE',
  },
  {
    postalcode: '16792',
    city: 'Vogelsang',
    countrycode: 'DE',
  },
  {
    postalcode: '16792',
    city: 'Wesendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16792',
    city: 'Zehdenick',
    countrycode: 'DE',
  },
  {
    postalcode: '16798',
    city: 'Altthymen',
    countrycode: 'DE',
  },
  {
    postalcode: '16798',
    city: 'Fürstenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16798',
    city: 'Himmelpfort',
    countrycode: 'DE',
  },
  {
    postalcode: '16798',
    city: 'Schönhorn Forsthaus',
    countrycode: 'DE',
  },
  {
    postalcode: '16798',
    city: 'Steinförde',
    countrycode: 'DE',
  },
  {
    postalcode: '16798',
    city: 'Zootzen',
    countrycode: 'DE',
  },
  {
    postalcode: '16816',
    city: 'Buskow',
    countrycode: 'DE',
  },
  {
    postalcode: '16816',
    city: 'Neuruppin',
    countrycode: 'DE',
  },
  {
    postalcode: '16816',
    city: 'Nietwerder',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Albertinenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Altfriesack',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Basdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Braunsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Buchenhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Dabergotz',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Darritz',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Darsikow',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Deutschhof',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Frankendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Gnewikow',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Gühlen Glienicke',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Karwe',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Katerbow',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Kränzlin',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Kuhhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Langen',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Lichtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Netzeband',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Neu Glienicke',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Pfalzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Radensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Rheinsberg Glienicke',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Ribbeckshorst',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Rägelin',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Storbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Tornow',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Wahlendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Wall',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Walsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Werder',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Wustrau',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Wuthenow',
    countrycode: 'DE',
  },
  {
    postalcode: '16818',
    city: 'Ziethenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '16827',
    city: 'Alt Ruppin',
    countrycode: 'DE',
  },
  {
    postalcode: '16827',
    city: 'Krangen',
    countrycode: 'DE',
  },
  {
    postalcode: '16827',
    city: 'Molchow',
    countrycode: 'DE',
  },
  {
    postalcode: '16827',
    city: 'Zermützel',
    countrycode: 'DE',
  },
  {
    postalcode: '16827',
    city: 'Zippelsförde',
    countrycode: 'DE',
  },
  {
    postalcode: '16831',
    city: 'Großzerlang',
    countrycode: 'DE',
  },
  {
    postalcode: '16831',
    city: 'Heinrichsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16831',
    city: 'Kleinzerlang',
    countrycode: 'DE',
  },
  {
    postalcode: '16831',
    city: 'Linow',
    countrycode: 'DE',
  },
  {
    postalcode: '16831',
    city: 'Rheinsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16831',
    city: 'Schwanow',
    countrycode: 'DE',
  },
  {
    postalcode: '16831',
    city: 'Zechlinerhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '16831',
    city: 'Zechow',
    countrycode: 'DE',
  },
  {
    postalcode: '16831',
    city: 'Zühlen',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Betzin',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Brunne',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Dechtow',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Fehrbellin',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Hakenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Jahnberge',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Karwesee',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Königshorst',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Lentzke',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Linum',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Protzen',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Stöffin',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Tarmow',
    countrycode: 'DE',
  },
  {
    postalcode: '16833',
    city: 'Walchow',
    countrycode: 'DE',
  },
  {
    postalcode: '16835',
    city: 'Banzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16835',
    city: 'Dierberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16835',
    city: 'Herzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16835',
    city: 'Hindenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16835',
    city: 'Klosterheide',
    countrycode: 'DE',
  },
  {
    postalcode: '16835',
    city: 'Lindow',
    countrycode: 'DE',
  },
  {
    postalcode: '16835',
    city: 'Rüthnick',
    countrycode: 'DE',
  },
  {
    postalcode: '16835',
    city: 'Schönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16835',
    city: 'Seebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16835',
    city: 'Strubensee',
    countrycode: 'DE',
  },
  {
    postalcode: '16835',
    city: 'Vielitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16835',
    city: 'Wulkow',
    countrycode: 'DE',
  },
  {
    postalcode: '16837',
    city: 'Alt Lutterow',
    countrycode: 'DE',
  },
  {
    postalcode: '16837',
    city: 'Dorf Zechlin',
    countrycode: 'DE',
  },
  {
    postalcode: '16837',
    city: 'Flecken Zechlin',
    countrycode: 'DE',
  },
  {
    postalcode: '16837',
    city: 'Kagar',
    countrycode: 'DE',
  },
  {
    postalcode: '16837',
    city: 'Luhme',
    countrycode: 'DE',
  },
  {
    postalcode: '16837',
    city: 'Neu Lutterow',
    countrycode: 'DE',
  },
  {
    postalcode: '16837',
    city: 'Wallitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16837',
    city: 'Zempow',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Barsikow',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Blankenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Blumenaue',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Breddin',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Brunn',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Bückwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Dessow',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Dreetz',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Ganzer',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Gartow',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Garz',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Giesenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Goldbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Gottberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Großderschau',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Hohenofen',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Holzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Kantow',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Kerzlin',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Koppenbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Küdow',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Leddin',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Lohm',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Lögow',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Lüchfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Manker',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Nackel',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Plänitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Roddahn',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Rohrlack',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Schönermark',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Segeletz',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Segeletz Bahnhof',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Sieversdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Stüdenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Trieplatz',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Vichel',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Wildberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16845',
    city: 'Zernitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Barenthin',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Bork-Lellichow',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Dannenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Demerthin',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Drewen',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Döllen',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Granzow',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Groß Welle',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Gumtow',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Görike',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Kolrep',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Kunow',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Kyritz',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Kötzlin',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Rehfeld-Berlitt',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Schrepkow',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Schönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Schönhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Sechzehneichen',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Teetz',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Tornow',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Vehlin',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Vehlow',
    countrycode: 'DE',
  },
  {
    postalcode: '16866',
    city: 'Wutike',
    countrycode: 'DE',
  },
  {
    postalcode: '16868',
    city: 'Bantikow',
    countrycode: 'DE',
  },
  {
    postalcode: '16868',
    city: 'Wusterhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Berlinchen',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Blandikow',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Blesendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Christdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Dossow',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Dranse',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Fretzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Gadow',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Glienicke',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Goldbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Grabow',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Groß Haßlow',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Heiligengrabe',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Herzsprung',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Jabel',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Klein Haßlow',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Königsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Liebenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Maulbeerwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Niemerlang',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Papenbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Rossow',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Schweinrich',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Sewekow',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Wernikow',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Wittstock',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Wulfersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Zaatzke',
    countrycode: 'DE',
  },
  {
    postalcode: '16909',
    city: 'Zootzen',
    countrycode: 'DE',
  },
  {
    postalcode: '16918',
    city: 'Freyenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Alt Krüssow',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Beveringen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Biesterholz',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Birkenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Blumenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Boddin',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Brügge',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Brünkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Buchhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Bullendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Bölzke',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Dahlhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Ellershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Falkenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Felsenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Gerdshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Giesenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Giesensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Groß Langerwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Groß Pankow',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Groß Woltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Hasenwinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Heidelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Helle',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Holzländerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Hoppenrade',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Horst',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Kammermark',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Kehrberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Kemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Kiebitzberg, Gem Beveringen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Klein Langerwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Klein Woltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Klenzenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Kuckuck',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Kuhbier',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Kuhsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Könkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Langnow',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Lindenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Luggendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Mesendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Neu Kemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Neu Krüssow',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Neu Redlin',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Neudorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Neuhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Preddöhl',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Pritzwalk',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Rapshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Reckenthin',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Rohlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Rosenwinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Sadenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Sarnow',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Schönebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Schönhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Schönhagener Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Seefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Steffenshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Streckenthin',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Tüchen',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Vettin',
    countrycode: 'DE',
  },
  {
    postalcode: '16928',
    city: 'Wilmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16945',
    city: 'Buckow',
    countrycode: 'DE',
  },
  {
    postalcode: '16945',
    city: 'Frehne',
    countrycode: 'DE',
  },
  {
    postalcode: '16945',
    city: 'Grabow',
    countrycode: 'DE',
  },
  {
    postalcode: '16945',
    city: 'Halenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16945',
    city: 'Krempendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16945',
    city: 'Meyenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '16945',
    city: 'Schmolde',
    countrycode: 'DE',
  },
  {
    postalcode: '16945',
    city: 'Stepenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16945',
    city: 'Warnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16949',
    city: 'Hülsebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '16949',
    city: 'Jännersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '16949',
    city: 'Putlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '16949',
    city: 'Triglitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17033',
    city: 'Neubrandenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '17034',
    city: 'Neubrandenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '17036',
    city: 'Neubrandenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Beseritz',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Blankenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Brunn',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Neddemin',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Neuenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Neverin',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Sponholz',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Staven',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Trollenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Warlin',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Woggersin',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Wulkenzin',
    countrycode: 'DE',
  },
  {
    postalcode: '17039',
    city: 'Zirzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17087',
    city: 'Altentreptow',
    countrycode: 'DE',
  },
  {
    postalcode: '17089',
    city: 'Bartow',
    countrycode: 'DE',
  },
  {
    postalcode: '17089',
    city: 'Breest',
    countrycode: 'DE',
  },
  {
    postalcode: '17089',
    city: 'Burow',
    countrycode: 'DE',
  },
  {
    postalcode: '17089',
    city: 'Gnevkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17089',
    city: 'Golchen',
    countrycode: 'DE',
  },
  {
    postalcode: '17089',
    city: 'Grapzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17089',
    city: 'Grischow',
    countrycode: 'DE',
  },
  {
    postalcode: '17089',
    city: 'Gültz',
    countrycode: 'DE',
  },
  {
    postalcode: '17089',
    city: 'Siedenbollentin',
    countrycode: 'DE',
  },
  {
    postalcode: '17089',
    city: 'Werder',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Altenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Breesen',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Groß Teetzleben',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Knorrendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Kriesow',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Mölln',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Pripsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Rosenow',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Röckwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Tützpatz',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Wildberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Wolde',
    countrycode: 'DE',
  },
  {
    postalcode: '17091',
    city: 'Wrodow',
    countrycode: 'DE',
  },
  {
    postalcode: '17094',
    city: 'Burg Stargard',
    countrycode: 'DE',
  },
  {
    postalcode: '17094',
    city: 'Cammin',
    countrycode: 'DE',
  },
  {
    postalcode: '17094',
    city: 'Cölpin',
    countrycode: 'DE',
  },
  {
    postalcode: '17094',
    city: 'Groß Nemerow',
    countrycode: 'DE',
  },
  {
    postalcode: '17094',
    city: 'Holldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17094',
    city: 'Pragsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17094',
    city: 'Teschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17098',
    city: 'Friedland',
    countrycode: 'DE',
  },
  {
    postalcode: '17099',
    city: 'Brohm',
    countrycode: 'DE',
  },
  {
    postalcode: '17099',
    city: 'Eichhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '17099',
    city: 'Fleethof',
    countrycode: 'DE',
  },
  {
    postalcode: '17099',
    city: 'Genzkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17099',
    city: 'Glienke',
    countrycode: 'DE',
  },
  {
    postalcode: '17099',
    city: 'Jatzke',
    countrycode: 'DE',
  },
  {
    postalcode: '17099',
    city: 'Kotelow',
    countrycode: 'DE',
  },
  {
    postalcode: '17099',
    city: 'Sadelkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17099',
    city: 'Salow',
    countrycode: 'DE',
  },
  {
    postalcode: '17099',
    city: 'Schwanbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '17099',
    city: 'Schwichtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17109',
    city: 'Demmin',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Beestland',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Beggerow',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Borrentin',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Gnevezow',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Hohenbollentin',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Hohenbrünzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Hohenmocker',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Kletzin',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Lindenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Meesiger',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Metschow',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Neu-Kentzlin',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Nossendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Quitzerow',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Sanzkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Sarow',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Schönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Siedenbrünzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Sommersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Teusin',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Upost',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Utzedel',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Verchen',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Warrenzin',
    countrycode: 'DE',
  },
  {
    postalcode: '17111',
    city: 'Wotenick',
    countrycode: 'DE',
  },
  {
    postalcode: '17121',
    city: 'Damerow',
    countrycode: 'DE',
  },
  {
    postalcode: '17121',
    city: 'Düvier',
    countrycode: 'DE',
  },
  {
    postalcode: '17121',
    city: 'Görmin',
    countrycode: 'DE',
  },
  {
    postalcode: '17121',
    city: 'Loitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17121',
    city: 'Sassen',
    countrycode: 'DE',
  },
  {
    postalcode: '17121',
    city: 'Trantow',
    countrycode: 'DE',
  },
  {
    postalcode: '17121',
    city: 'Vorbein',
    countrycode: 'DE',
  },
  {
    postalcode: '17121',
    city: 'Wüstenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '17126',
    city: 'Jarmen',
    countrycode: 'DE',
  },
  {
    postalcode: '17129',
    city: 'Alt Tellin',
    countrycode: 'DE',
  },
  {
    postalcode: '17129',
    city: 'Bentzin',
    countrycode: 'DE',
  },
  {
    postalcode: '17129',
    city: 'Daberkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17129',
    city: 'Kruckow',
    countrycode: 'DE',
  },
  {
    postalcode: '17129',
    city: 'Plötz',
    countrycode: 'DE',
  },
  {
    postalcode: '17129',
    city: 'Schmarsow',
    countrycode: 'DE',
  },
  {
    postalcode: '17129',
    city: 'Tutow',
    countrycode: 'DE',
  },
  {
    postalcode: '17129',
    city: 'Völschow',
    countrycode: 'DE',
  },
  {
    postalcode: '17139',
    city: 'Basedow',
    countrycode: 'DE',
  },
  {
    postalcode: '17139',
    city: 'Duckow',
    countrycode: 'DE',
  },
  {
    postalcode: '17139',
    city: 'Faulenrost',
    countrycode: 'DE',
  },
  {
    postalcode: '17139',
    city: 'Gielow',
    countrycode: 'DE',
  },
  {
    postalcode: '17139',
    city: 'Gorschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17139',
    city: 'Kummerow',
    countrycode: 'DE',
  },
  {
    postalcode: '17139',
    city: 'Langwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17139',
    city: 'Malchin',
    countrycode: 'DE',
  },
  {
    postalcode: '17139',
    city: 'Remplin',
    countrycode: 'DE',
  },
  {
    postalcode: '17139',
    city: 'Schwinkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17153',
    city: 'Bredenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '17153',
    city: 'Briggow',
    countrycode: 'DE',
  },
  {
    postalcode: '17153',
    city: 'Galenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '17153',
    city: 'Grammentin',
    countrycode: 'DE',
  },
  {
    postalcode: '17153',
    city: 'Gülzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17153',
    city: 'Ivenack',
    countrycode: 'DE',
  },
  {
    postalcode: '17153',
    city: 'Jürgenstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17153',
    city: 'Kittendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17153',
    city: 'Ritzerow',
    countrycode: 'DE',
  },
  {
    postalcode: '17153',
    city: 'Stavenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17153',
    city: 'Wackerow',
    countrycode: 'DE',
  },
  {
    postalcode: '17153',
    city: 'Zettemin',
    countrycode: 'DE',
  },
  {
    postalcode: '17154',
    city: 'Neukalen',
    countrycode: 'DE',
  },
  {
    postalcode: '17159',
    city: 'Brudersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17159',
    city: 'Dargun',
    countrycode: 'DE',
  },
  {
    postalcode: '17159',
    city: 'Stubbendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17159',
    city: 'Wagun',
    countrycode: 'DE',
  },
  {
    postalcode: '17159',
    city: 'Zarnekow',
    countrycode: 'DE',
  },
  {
    postalcode: '17166',
    city: 'Alt-Sührkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17166',
    city: 'Bristow',
    countrycode: 'DE',
  },
  {
    postalcode: '17166',
    city: 'Bülow',
    countrycode: 'DE',
  },
  {
    postalcode: '17166',
    city: 'Dahmen',
    countrycode: 'DE',
  },
  {
    postalcode: '17166',
    city: 'Dalkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17166',
    city: 'Groß Roge',
    countrycode: 'DE',
  },
  {
    postalcode: '17166',
    city: 'Groß-Wokern',
    countrycode: 'DE',
  },
  {
    postalcode: '17166',
    city: 'Großen-Luckow',
    countrycode: 'DE',
  },
  {
    postalcode: '17166',
    city: 'Hohen-Demzin',
    countrycode: 'DE',
  },
  {
    postalcode: '17166',
    city: 'Neu-Ziddorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17166',
    city: 'Teterow',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Groß Wüstenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Jördenstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Lelkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Levitzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Matgendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Neu Heinde',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Poggelow',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Prebberede',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Remlin',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Rey',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Sukow-Marienhof',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Thürkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17168',
    city: 'Warnkenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17179',
    city: 'Altkalen',
    countrycode: 'DE',
  },
  {
    postalcode: '17179',
    city: 'Behren-Lübchin',
    countrycode: 'DE',
  },
  {
    postalcode: '17179',
    city: 'Boddin',
    countrycode: 'DE',
  },
  {
    postalcode: '17179',
    city: 'Finkenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '17179',
    city: 'Gnoien',
    countrycode: 'DE',
  },
  {
    postalcode: '17179',
    city: 'Groß Nieköhr',
    countrycode: 'DE',
  },
  {
    postalcode: '17179',
    city: 'Kleverhof',
    countrycode: 'DE',
  },
  {
    postalcode: '17179',
    city: 'Lühburg',
    countrycode: 'DE',
  },
  {
    postalcode: '17179',
    city: 'Walkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17179',
    city: 'Wasdow',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Alt Schönau',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Groß Dratow',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Groß Gievitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Groß Plasten',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Kargow',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Klink',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Lansen',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Levenstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Neu Schloen',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Schloen',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Schwarzenhof bei Groß Gievitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Torgelow',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Varchentin',
    countrycode: 'DE',
  },
  {
    postalcode: '17192',
    city: 'Waren',
    countrycode: 'DE',
  },
  {
    postalcode: '17194',
    city: 'Beckenkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '17194',
    city: 'Grabowhöfe',
    countrycode: 'DE',
  },
  {
    postalcode: '17194',
    city: 'Hinrichshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17194',
    city: 'Hohen Wangelin',
    countrycode: 'DE',
  },
  {
    postalcode: '17194',
    city: 'Jabel',
    countrycode: 'DE',
  },
  {
    postalcode: '17194',
    city: 'Klocksin',
    countrycode: 'DE',
  },
  {
    postalcode: '17194',
    city: 'Lupendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17194',
    city: 'Moltzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17194',
    city: 'Neu Gaarz',
    countrycode: 'DE',
  },
  {
    postalcode: '17194',
    city: 'Vielist',
    countrycode: 'DE',
  },
  {
    postalcode: '17194',
    city: 'Vollrathsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '17207',
    city: 'Bollewick',
    countrycode: 'DE',
  },
  {
    postalcode: '17207',
    city: 'Gotthun',
    countrycode: 'DE',
  },
  {
    postalcode: '17207',
    city: 'Groß Kelle',
    countrycode: 'DE',
  },
  {
    postalcode: '17207',
    city: 'Kambs',
    countrycode: 'DE',
  },
  {
    postalcode: '17207',
    city: 'Ludorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17207',
    city: 'Röbel',
    countrycode: 'DE',
  },
  {
    postalcode: '17207',
    city: 'Zierzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Altenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Below',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Bütow',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Fincken',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Grabow',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Jaebetz',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Kieve',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Leizen',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Massow',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Melz',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Minzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Priborn',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Rogeez',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Satow',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Sietow',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Stuer',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Vipperow',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Walow',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Wildkuhl',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Wredenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Zepkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17209',
    city: 'Zislow',
    countrycode: 'DE',
  },
  {
    postalcode: '17213',
    city: 'Adamshoffnung',
    countrycode: 'DE',
  },
  {
    postalcode: '17213',
    city: 'Grüssow',
    countrycode: 'DE',
  },
  {
    postalcode: '17213',
    city: 'Göhren-Lebbin',
    countrycode: 'DE',
  },
  {
    postalcode: '17213',
    city: 'Kogel',
    countrycode: 'DE',
  },
  {
    postalcode: '17213',
    city: 'Lexow',
    countrycode: 'DE',
  },
  {
    postalcode: '17213',
    city: 'Malchow',
    countrycode: 'DE',
  },
  {
    postalcode: '17213',
    city: 'Penkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17214',
    city: 'Alt Schwerin',
    countrycode: 'DE',
  },
  {
    postalcode: '17214',
    city: 'Nossentiner Hütte',
    countrycode: 'DE',
  },
  {
    postalcode: '17214',
    city: 'Silz',
    countrycode: 'DE',
  },
  {
    postalcode: '17217',
    city: 'Alt Rehse',
    countrycode: 'DE',
  },
  {
    postalcode: '17217',
    city: 'Groß Vielen',
    countrycode: 'DE',
  },
  {
    postalcode: '17217',
    city: 'Klein Lukow',
    countrycode: 'DE',
  },
  {
    postalcode: '17217',
    city: 'Krukow',
    countrycode: 'DE',
  },
  {
    postalcode: '17217',
    city: 'Lapitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17217',
    city: 'Mallin',
    countrycode: 'DE',
  },
  {
    postalcode: '17217',
    city: 'Mollenstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17217',
    city: 'Penzlin',
    countrycode: 'DE',
  },
  {
    postalcode: '17217',
    city: 'Puchow',
    countrycode: 'DE',
  },
  {
    postalcode: '17219',
    city: 'Ankershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17219',
    city: 'Carolinenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '17219',
    city: 'Groß Flotow',
    countrycode: 'DE',
  },
  {
    postalcode: '17219',
    city: 'Klockow',
    countrycode: 'DE',
  },
  {
    postalcode: '17219',
    city: 'Marihn',
    countrycode: 'DE',
  },
  {
    postalcode: '17219',
    city: 'Möllenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17235',
    city: 'Neustrelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Babke',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Blankenförde',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Blankensee',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Blumenholz',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Carpin',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Godendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Grünow',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Hohenzieritz',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Klein Vielen',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Koldenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Kratzeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Möllenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Rödlin-Thurow',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Userin',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Watzkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Wokuhl-Dabelow',
    countrycode: 'DE',
  },
  {
    postalcode: '17237',
    city: 'Zartwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17248',
    city: 'Lärz',
    countrycode: 'DE',
  },
  {
    postalcode: '17248',
    city: 'Rechlin',
    countrycode: 'DE',
  },
  {
    postalcode: '17252',
    city: 'Diemitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17252',
    city: 'Mirow',
    countrycode: 'DE',
  },
  {
    postalcode: '17252',
    city: 'Roggentin',
    countrycode: 'DE',
  },
  {
    postalcode: '17252',
    city: 'Schwarz',
    countrycode: 'DE',
  },
  {
    postalcode: '17252',
    city: 'Siedenheide',
    countrycode: 'DE',
  },
  {
    postalcode: '17252',
    city: 'Zirtow',
    countrycode: 'DE',
  },
  {
    postalcode: '17255',
    city: 'Priepert',
    countrycode: 'DE',
  },
  {
    postalcode: '17255',
    city: 'Strasen',
    countrycode: 'DE',
  },
  {
    postalcode: '17255',
    city: 'Wesenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17255',
    city: 'Wustrow',
    countrycode: 'DE',
  },
  {
    postalcode: '17258',
    city: 'Beenz',
    countrycode: 'DE',
  },
  {
    postalcode: '17258',
    city: 'Conow',
    countrycode: 'DE',
  },
  {
    postalcode: '17258',
    city: 'Dolgen',
    countrycode: 'DE',
  },
  {
    postalcode: '17258',
    city: 'Feldberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17258',
    city: 'Lüttenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17259',
    city: 'Lichtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17259',
    city: 'Tornowhof',
    countrycode: 'DE',
  },
  {
    postalcode: '17259',
    city: 'Triepkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Beutel',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Boitzenburger Land',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Densow',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Flieth',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Friedenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Gandenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Gerswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Gollin',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Groß Dölln',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Groß Fredenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Groß Kölpin',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Grunewald',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Hammelspring',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Herzfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Kaakstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Klosterwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Krohnhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Milmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Mittenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Petznick',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Ringenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Röddelin',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Stegelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Storkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Tackmannshof',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Temmen',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Templin',
    countrycode: 'DE',
  },
  {
    postalcode: '17268',
    city: 'Vietmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17279',
    city: 'Lychen',
    countrycode: 'DE',
  },
  {
    postalcode: '17279',
    city: 'Retzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17279',
    city: 'Rutenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Albrechtshof',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Arendsee',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Bertikow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Bietikow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Blankenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Blindow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Carmzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Damme',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Dauer',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Dedelow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Drense',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Eickstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Falkenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Falkenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Ferdinandshorst',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Fürstenwerder',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Gollmitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Gramzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Grenz',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Grünow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Göritz',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Güstow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Hohengüstow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Holzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Kleinow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Klinkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Kraatz',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Ludwigsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Lützlow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Meichow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Naugarten',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Parmen-Weggun',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Potzlow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Prenzlau',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Röpersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Schapow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Schenkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Schmölln',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Schwaneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Schönermark',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Schönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Schönwerder',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Seehausen',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Sternhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Wallmow',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Warnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Weselitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Wollin',
    countrycode: 'DE',
  },
  {
    postalcode: '17291',
    city: 'Ziemkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Belling',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Brietzig',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Damerow',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Fahrenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Jatznick',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Koblentz',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Krugsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Marienthal',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Nieden',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Papendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Pasewalk',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Polzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Rollwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Schönwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Viereck',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Zerrenthin',
    countrycode: 'DE',
  },
  {
    postalcode: '17309',
    city: 'Züsedom',
    countrycode: 'DE',
  },
  {
    postalcode: '17321',
    city: 'Bergholz',
    countrycode: 'DE',
  },
  {
    postalcode: '17321',
    city: 'Löcknitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17321',
    city: 'Plöwen',
    countrycode: 'DE',
  },
  {
    postalcode: '17321',
    city: 'Ramin',
    countrycode: 'DE',
  },
  {
    postalcode: '17321',
    city: 'Rothenklempenow',
    countrycode: 'DE',
  },
  {
    postalcode: '17322',
    city: 'Bismark',
    countrycode: 'DE',
  },
  {
    postalcode: '17322',
    city: 'Blankensee',
    countrycode: 'DE',
  },
  {
    postalcode: '17322',
    city: 'Boock',
    countrycode: 'DE',
  },
  {
    postalcode: '17322',
    city: 'Glasow',
    countrycode: 'DE',
  },
  {
    postalcode: '17322',
    city: 'Grambow',
    countrycode: 'DE',
  },
  {
    postalcode: '17322',
    city: 'Lebehn',
    countrycode: 'DE',
  },
  {
    postalcode: '17322',
    city: 'Mewegen',
    countrycode: 'DE',
  },
  {
    postalcode: '17322',
    city: 'Pampow',
    countrycode: 'DE',
  },
  {
    postalcode: '17322',
    city: 'Rossow',
    countrycode: 'DE',
  },
  {
    postalcode: '17326',
    city: 'Bagemühl',
    countrycode: 'DE',
  },
  {
    postalcode: '17326',
    city: 'Brüssow',
    countrycode: 'DE',
  },
  {
    postalcode: '17326',
    city: 'Grünberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17326',
    city: 'Menkin',
    countrycode: 'DE',
  },
  {
    postalcode: '17326',
    city: 'Woddow',
    countrycode: 'DE',
  },
  {
    postalcode: '17326',
    city: 'Wollschow',
    countrycode: 'DE',
  },
  {
    postalcode: '17328',
    city: 'Battinsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '17328',
    city: 'Grünz',
    countrycode: 'DE',
  },
  {
    postalcode: '17328',
    city: 'Penkun',
    countrycode: 'DE',
  },
  {
    postalcode: '17328',
    city: 'Schuckmannshöhe',
    countrycode: 'DE',
  },
  {
    postalcode: '17328',
    city: 'Sommersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17328',
    city: 'Storkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17328',
    city: 'Wollin',
    countrycode: 'DE',
  },
  {
    postalcode: '17329',
    city: 'Krackow',
    countrycode: 'DE',
  },
  {
    postalcode: '17329',
    city: 'Ladenthin',
    countrycode: 'DE',
  },
  {
    postalcode: '17329',
    city: 'Nadrensee',
    countrycode: 'DE',
  },
  {
    postalcode: '17335',
    city: 'Rohrkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '17335',
    city: 'Strasburg',
    countrycode: 'DE',
  },
  {
    postalcode: '17337',
    city: 'Blumenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17337',
    city: 'Friedrichshof',
    countrycode: 'DE',
  },
  {
    postalcode: '17337',
    city: 'Galenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '17337',
    city: 'Groß Luckow',
    countrycode: 'DE',
  },
  {
    postalcode: '17337',
    city: 'Groß Spiegelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17337',
    city: 'Johannisberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17337',
    city: 'Klein Luckow',
    countrycode: 'DE',
  },
  {
    postalcode: '17337',
    city: 'Schönhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '17337',
    city: 'Uckerland',
    countrycode: 'DE',
  },
  {
    postalcode: '17337',
    city: 'Wittenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '17348',
    city: 'Groß Daberkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17348',
    city: 'Göhren',
    countrycode: 'DE',
  },
  {
    postalcode: '17348',
    city: 'Mildenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17348',
    city: 'Neu Käbelich',
    countrycode: 'DE',
  },
  {
    postalcode: '17348',
    city: 'Petersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17348',
    city: 'Woldegk',
    countrycode: 'DE',
  },
  {
    postalcode: '17349',
    city: 'Groß Miltzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17349',
    city: 'Helpt',
    countrycode: 'DE',
  },
  {
    postalcode: '17349',
    city: 'Kublank',
    countrycode: 'DE',
  },
  {
    postalcode: '17349',
    city: 'Lindetal',
    countrycode: 'DE',
  },
  {
    postalcode: '17349',
    city: 'Neetzka',
    countrycode: 'DE',
  },
  {
    postalcode: '17349',
    city: 'Schönbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '17349',
    city: 'Voigtsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17358',
    city: 'Hammer',
    countrycode: 'DE',
  },
  {
    postalcode: '17358',
    city: 'Torgelow',
    countrycode: 'DE',
  },
  {
    postalcode: '17358',
    city: 'Torgelow-Holländerei',
    countrycode: 'DE',
  },
  {
    postalcode: '17367',
    city: 'Eggesin',
    countrycode: 'DE',
  },
  {
    postalcode: '17373',
    city: 'Ueckermünde',
    countrycode: 'DE',
  },
  {
    postalcode: '17375',
    city: 'Ahlbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '17375',
    city: 'Altwarp',
    countrycode: 'DE',
  },
  {
    postalcode: '17375',
    city: 'Grambin',
    countrycode: 'DE',
  },
  {
    postalcode: '17375',
    city: 'Hintersee',
    countrycode: 'DE',
  },
  {
    postalcode: '17375',
    city: 'Hoppenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '17375',
    city: 'Leopoldshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17375',
    city: 'Liepgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '17375',
    city: 'Luckow',
    countrycode: 'DE',
  },
  {
    postalcode: '17375',
    city: 'Meiersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '17375',
    city: 'Mönkebude',
    countrycode: 'DE',
  },
  {
    postalcode: '17375',
    city: 'Rieth',
    countrycode: 'DE',
  },
  {
    postalcode: '17375',
    city: 'Vogelsang-Warsin',
    countrycode: 'DE',
  },
  {
    postalcode: '17379',
    city: 'Altwigshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17379',
    city: 'Ferdinandshof',
    countrycode: 'DE',
  },
  {
    postalcode: '17379',
    city: 'Heinrichsruh',
    countrycode: 'DE',
  },
  {
    postalcode: '17379',
    city: 'Heinrichswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '17379',
    city: 'Lübs',
    countrycode: 'DE',
  },
  {
    postalcode: '17379',
    city: 'Neuendorf A',
    countrycode: 'DE',
  },
  {
    postalcode: '17379',
    city: 'Rothemühl',
    countrycode: 'DE',
  },
  {
    postalcode: '17379',
    city: 'Wietstock',
    countrycode: 'DE',
  },
  {
    postalcode: '17379',
    city: 'Wilhelmsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '17389',
    city: 'Anklam',
    countrycode: 'DE',
  },
  {
    postalcode: '17390',
    city: 'Groß Polzin',
    countrycode: 'DE',
  },
  {
    postalcode: '17390',
    city: 'Klein Bünzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17390',
    city: 'Murchin',
    countrycode: 'DE',
  },
  {
    postalcode: '17390',
    city: 'Rubkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17390',
    city: 'Schmatzin',
    countrycode: 'DE',
  },
  {
    postalcode: '17390',
    city: 'Ziethen',
    countrycode: 'DE',
  },
  {
    postalcode: '17391',
    city: 'Iven',
    countrycode: 'DE',
  },
  {
    postalcode: '17391',
    city: 'Krien',
    countrycode: 'DE',
  },
  {
    postalcode: '17391',
    city: 'Krusenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '17391',
    city: 'Liepen',
    countrycode: 'DE',
  },
  {
    postalcode: '17391',
    city: 'Medow',
    countrycode: 'DE',
  },
  {
    postalcode: '17391',
    city: 'Neetzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17391',
    city: 'Nerdin',
    countrycode: 'DE',
  },
  {
    postalcode: '17391',
    city: 'Neuendorf B',
    countrycode: 'DE',
  },
  {
    postalcode: '17391',
    city: 'Postlow',
    countrycode: 'DE',
  },
  {
    postalcode: '17391',
    city: 'Stolpe',
    countrycode: 'DE',
  },
  {
    postalcode: '17392',
    city: 'Blesewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17392',
    city: 'Boldekow',
    countrycode: 'DE',
  },
  {
    postalcode: '17392',
    city: 'Butzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17392',
    city: 'Drewelow',
    countrycode: 'DE',
  },
  {
    postalcode: '17392',
    city: 'Japenzin',
    countrycode: 'DE',
  },
  {
    postalcode: '17392',
    city: 'Neuenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '17392',
    city: 'Pelsin',
    countrycode: 'DE',
  },
  {
    postalcode: '17392',
    city: 'Putzar',
    countrycode: 'DE',
  },
  {
    postalcode: '17392',
    city: 'Sarnow',
    countrycode: 'DE',
  },
  {
    postalcode: '17392',
    city: 'Spantekow',
    countrycode: 'DE',
  },
  {
    postalcode: '17398',
    city: 'Bargischow',
    countrycode: 'DE',
  },
  {
    postalcode: '17398',
    city: 'Bugewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17398',
    city: 'Ducherow',
    countrycode: 'DE',
  },
  {
    postalcode: '17398',
    city: 'Löwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17398',
    city: 'Neu Kosenow',
    countrycode: 'DE',
  },
  {
    postalcode: '17398',
    city: 'Rathebur',
    countrycode: 'DE',
  },
  {
    postalcode: '17398',
    city: 'Rossin',
    countrycode: 'DE',
  },
  {
    postalcode: '17398',
    city: 'Schwerinsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '17406',
    city: 'Morgenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17406',
    city: 'Rankwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17406',
    city: 'Stolpe',
    countrycode: 'DE',
  },
  {
    postalcode: '17406',
    city: 'Usedom',
    countrycode: 'DE',
  },
  {
    postalcode: '17419',
    city: 'Dargen',
    countrycode: 'DE',
  },
  {
    postalcode: '17419',
    city: 'Garz',
    countrycode: 'DE',
  },
  {
    postalcode: '17419',
    city: 'Kamminke',
    countrycode: 'DE',
  },
  {
    postalcode: '17419',
    city: 'Korswandt',
    countrycode: 'DE',
  },
  {
    postalcode: '17419',
    city: 'Seebad Ahlbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '17419',
    city: 'Zirchow',
    countrycode: 'DE',
  },
  {
    postalcode: '17424',
    city: 'Seebad Heringsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17429',
    city: 'Benz',
    countrycode: 'DE',
  },
  {
    postalcode: '17429',
    city: 'Katschow',
    countrycode: 'DE',
  },
  {
    postalcode: '17429',
    city: 'Mellenthin',
    countrycode: 'DE',
  },
  {
    postalcode: '17429',
    city: 'Neppermin',
    countrycode: 'DE',
  },
  {
    postalcode: '17429',
    city: 'Pudagla',
    countrycode: 'DE',
  },
  {
    postalcode: '17429',
    city: 'Seebad Bansin',
    countrycode: 'DE',
  },
  {
    postalcode: '17438',
    city: 'Wolgast',
    countrycode: 'DE',
  },
  {
    postalcode: '17440',
    city: 'Buddenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17440',
    city: 'Buggenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17440',
    city: 'Groß Ernsthof',
    countrycode: 'DE',
  },
  {
    postalcode: '17440',
    city: 'Hohendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17440',
    city: 'Krummin',
    countrycode: 'DE',
  },
  {
    postalcode: '17440',
    city: 'Kröslin',
    countrycode: 'DE',
  },
  {
    postalcode: '17440',
    city: 'Lassan',
    countrycode: 'DE',
  },
  {
    postalcode: '17440',
    city: 'Lütow',
    countrycode: 'DE',
  },
  {
    postalcode: '17440',
    city: 'Pulow',
    countrycode: 'DE',
  },
  {
    postalcode: '17440',
    city: 'Sauzin',
    countrycode: 'DE',
  },
  {
    postalcode: '17440',
    city: 'Zemitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17449',
    city: 'Karlshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17449',
    city: 'Mölschow',
    countrycode: 'DE',
  },
  {
    postalcode: '17449',
    city: 'Peenemünde',
    countrycode: 'DE',
  },
  {
    postalcode: '17449',
    city: 'Trassenheide',
    countrycode: 'DE',
  },
  {
    postalcode: '17454',
    city: 'Zinnowitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17459',
    city: 'Koserow',
    countrycode: 'DE',
  },
  {
    postalcode: '17459',
    city: 'Loddin',
    countrycode: 'DE',
  },
  {
    postalcode: '17459',
    city: 'Zempin',
    countrycode: 'DE',
  },
  {
    postalcode: '17459',
    city: 'Ückeritz',
    countrycode: 'DE',
  },
  {
    postalcode: '17489',
    city: 'Greifswald',
    countrycode: 'DE',
  },
  {
    postalcode: '17491',
    city: 'Greifswald',
    countrycode: 'DE',
  },
  {
    postalcode: '17493',
    city: 'Greifswald',
    countrycode: 'DE',
  },
  {
    postalcode: '17493',
    city: 'Insel Koos',
    countrycode: 'DE',
  },
  {
    postalcode: '17495',
    city: 'Groß Kiesow',
    countrycode: 'DE',
  },
  {
    postalcode: '17495',
    city: 'Karlsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '17495',
    city: 'Lühmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '17495',
    city: 'Ranzin',
    countrycode: 'DE',
  },
  {
    postalcode: '17495',
    city: 'Wrangelsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '17495',
    city: 'Züssow',
    countrycode: 'DE',
  },
  {
    postalcode: '17498',
    city: 'Behrenhoff',
    countrycode: 'DE',
  },
  {
    postalcode: '17498',
    city: 'Dargelin',
    countrycode: 'DE',
  },
  {
    postalcode: '17498',
    city: 'Dersekow',
    countrycode: 'DE',
  },
  {
    postalcode: '17498',
    city: 'Diedrichshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17498',
    city: 'Hinrichshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17498',
    city: 'Insel Riems',
    countrycode: 'DE',
  },
  {
    postalcode: '17498',
    city: 'Levenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17498',
    city: 'Mesekenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17498',
    city: 'Neuenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '17498',
    city: 'Riemserort',
    countrycode: 'DE',
  },
  {
    postalcode: '17498',
    city: 'Wackerow',
    countrycode: 'DE',
  },
  {
    postalcode: '17498',
    city: 'Weitenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17506',
    city: 'Bandelin',
    countrycode: 'DE',
  },
  {
    postalcode: '17506',
    city: 'Breechen',
    countrycode: 'DE',
  },
  {
    postalcode: '17506',
    city: 'Gribow',
    countrycode: 'DE',
  },
  {
    postalcode: '17506',
    city: 'Gützkow',
    countrycode: 'DE',
  },
  {
    postalcode: '17506',
    city: 'Kammin',
    countrycode: 'DE',
  },
  {
    postalcode: '17506',
    city: 'Kölzin',
    countrycode: 'DE',
  },
  {
    postalcode: '17506',
    city: 'Lüssow',
    countrycode: 'DE',
  },
  {
    postalcode: '17509',
    city: 'Brünzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17509',
    city: 'Hanshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17509',
    city: 'Katzow',
    countrycode: 'DE',
  },
  {
    postalcode: '17509',
    city: 'Kemnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '17509',
    city: 'Loissin',
    countrycode: 'DE',
  },
  {
    postalcode: '17509',
    city: 'Lubmin',
    countrycode: 'DE',
  },
  {
    postalcode: '17509',
    city: 'Neu Boltenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '17509',
    city: 'Rubenow',
    countrycode: 'DE',
  },
  {
    postalcode: '17509',
    city: 'Wusterhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '18055',
    city: 'Rostock',
    countrycode: 'DE',
  },
  {
    postalcode: '18057',
    city: 'Rostock',
    countrycode: 'DE',
  },
  {
    postalcode: '18059',
    city: 'Papendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18059',
    city: 'Pölchow',
    countrycode: 'DE',
  },
  {
    postalcode: '18059',
    city: 'Rostock',
    countrycode: 'DE',
  },
  {
    postalcode: '18059',
    city: 'Ziesendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18069',
    city: 'Lambrechtshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18069',
    city: 'Rostock',
    countrycode: 'DE',
  },
  {
    postalcode: '18106',
    city: 'Rostock',
    countrycode: 'DE',
  },
  {
    postalcode: '18107',
    city: 'Elmenhorst/Lichtenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18107',
    city: 'Rostock',
    countrycode: 'DE',
  },
  {
    postalcode: '18109',
    city: 'Rostock',
    countrycode: 'DE',
  },
  {
    postalcode: '18119',
    city: 'Rostock',
    countrycode: 'DE',
  },
  {
    postalcode: '18146',
    city: 'Niederhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18146',
    city: 'Rostock',
    countrycode: 'DE',
  },
  {
    postalcode: '18147',
    city: 'Rostock',
    countrycode: 'DE',
  },
  {
    postalcode: '18181',
    city: 'Graal-Müritz',
    countrycode: 'DE',
  },
  {
    postalcode: '18181',
    city: 'Torfbrücke',
    countrycode: 'DE',
  },
  {
    postalcode: '18182',
    city: 'Bentwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '18182',
    city: 'Blankenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18182',
    city: 'Gelbensande',
    countrycode: 'DE',
  },
  {
    postalcode: '18182',
    city: 'Mönchhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18182',
    city: 'Rostock',
    countrycode: 'DE',
  },
  {
    postalcode: '18182',
    city: 'Rövershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18182',
    city: 'Wiethagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18184',
    city: 'Broderstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18184',
    city: 'Klein Kussewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18184',
    city: 'Mandelshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18184',
    city: 'Poppendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18184',
    city: 'Roggentin',
    countrycode: 'DE',
  },
  {
    postalcode: '18184',
    city: 'Steinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '18184',
    city: 'Thulendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18190',
    city: 'Groß Lüsewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18190',
    city: 'Gubkow',
    countrycode: 'DE',
  },
  {
    postalcode: '18190',
    city: 'Niekrenz',
    countrycode: 'DE',
  },
  {
    postalcode: '18190',
    city: 'Reppelin',
    countrycode: 'DE',
  },
  {
    postalcode: '18190',
    city: 'Sanitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18195',
    city: 'Cammin',
    countrycode: 'DE',
  },
  {
    postalcode: '18195',
    city: 'Gnewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18195',
    city: 'Grammow',
    countrycode: 'DE',
  },
  {
    postalcode: '18195',
    city: 'Nustrow',
    countrycode: 'DE',
  },
  {
    postalcode: '18195',
    city: 'Selpin',
    countrycode: 'DE',
  },
  {
    postalcode: '18195',
    city: 'Stubbendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18195',
    city: 'Tessin',
    countrycode: 'DE',
  },
  {
    postalcode: '18195',
    city: 'Thelkow',
    countrycode: 'DE',
  },
  {
    postalcode: '18195',
    city: 'Zarnewanz',
    countrycode: 'DE',
  },
  {
    postalcode: '18196',
    city: 'Damm',
    countrycode: 'DE',
  },
  {
    postalcode: '18196',
    city: 'Dummerstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18196',
    city: 'Kavelstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18196',
    city: 'Kessin',
    countrycode: 'DE',
  },
  {
    postalcode: '18196',
    city: 'Lieblingshof',
    countrycode: 'DE',
  },
  {
    postalcode: '18196',
    city: 'Prisannewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18198',
    city: 'Kritzmow',
    countrycode: 'DE',
  },
  {
    postalcode: '18198',
    city: 'Stäbelow',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Bad Doberan',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Badenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Bartenshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Bollbrücke',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Brodhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Fulgenkoppel',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Glashagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Hohenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Hütten',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Neu Hohenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Parkentin',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Reddelich',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Steffenshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Stülow',
    countrycode: 'DE',
  },
  {
    postalcode: '18209',
    city: 'Wittenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '18211',
    city: 'Admannshagen-Bargeshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18211',
    city: 'Bahrenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '18211',
    city: 'Börgerende',
    countrycode: 'DE',
  },
  {
    postalcode: '18211',
    city: 'Ehbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '18211',
    city: 'Forsthaus Ivendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18211',
    city: 'Ivendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18211',
    city: 'Neu Rethwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '18211',
    city: 'Ostseebad Nienhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18211',
    city: 'Rethwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '18211',
    city: 'Retschow',
    countrycode: 'DE',
  },
  {
    postalcode: '18211',
    city: 'Retschow Waldsiedlung',
    countrycode: 'DE',
  },
  {
    postalcode: '18225',
    city: 'Kühlungsborn',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Bastorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Biendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Blengow',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Büttelkow',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Gaarzerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Garvsmühlen',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Gersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Hohen Niendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Jennewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Kägsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Körchow',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Mechelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Meschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Meschendorf Zeltplatz',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Neu Gaarz',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Ostseebad Rerik',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Ostseebad Rerik Zeltplatz',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Roggow',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Wendelstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Westhof',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Wischuer',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Wischuer Abbau',
    countrycode: 'DE',
  },
  {
    postalcode: '18230',
    city: 'Zweedorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Alt Bukow',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Bolland',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Clausdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Garvensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Jörnstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Kamin',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Kirch Mulsow',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Krempin',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Neubukow',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Parchow',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Pepelow',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Rakow',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Ravensberg',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Sandhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Sandhagen Abbau',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Steinhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Teßmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Uhlenbrook',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Westenbrügge',
    countrycode: 'DE',
  },
  {
    postalcode: '18233',
    city: 'Zarfzow',
    countrycode: 'DE',
  },
  {
    postalcode: '18236',
    city: 'Alt Karin',
    countrycode: 'DE',
  },
  {
    postalcode: '18236',
    city: 'Altenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18236',
    city: 'Danneborth',
    countrycode: 'DE',
  },
  {
    postalcode: '18236',
    city: 'Einhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '18236',
    city: 'Groß Siemen',
    countrycode: 'DE',
  },
  {
    postalcode: '18236',
    city: 'Kröpelin',
    countrycode: 'DE',
  },
  {
    postalcode: '18236',
    city: 'Neu Karin',
    countrycode: 'DE',
  },
  {
    postalcode: '18236',
    city: 'Schmadebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Anna Luisenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Berendshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Clausdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Dolglas',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Forsthof Satow',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Gerdshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Gorow',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Groß Bölkow',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Groß Nienhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Hanstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Hastorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Heiligenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Hohen Luckow',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Horst',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Klein Bölkow',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Konow',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Lüningshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Matersen',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Miekenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Pustohl',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Püschow',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Radegast',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Rederank',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Reinshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Rosenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Satow',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Sophienholz',
    countrycode: 'DE',
  },
  {
    postalcode: '18239',
    city: 'Steinhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Baumgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Bützow',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Friedrichshof',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Glambeck',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Gnemern',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Groß Belitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Göllin',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Jabelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Jürgenshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Klein Belitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Klein Gischow',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Klein Gnemern',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Klein Sien',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Krugland',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Kurzen Trechow',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Käterhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Langen Trechow',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Moisall',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Moltenow',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Neu Käterhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Oettelin',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Reinstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Rühn',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Selow',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Steinhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Ulrikenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Wokrent',
    countrycode: 'DE',
  },
  {
    postalcode: '18246',
    city: 'Zepelin',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Bernitt',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Boitin',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Buchenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Dreetz',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Eickelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Eickhof',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Katelbogen',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Klein Raden',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Laase',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Lübzin-Rosenow',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Penzin',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Qualitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Schlemmin',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Schlockow',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Tarnow',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Viezen',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Warnow',
    countrycode: 'DE',
  },
  {
    postalcode: '18249',
    city: 'Zernin',
    countrycode: 'DE',
  },
  {
    postalcode: '18258',
    city: 'Bandow',
    countrycode: 'DE',
  },
  {
    postalcode: '18258',
    city: 'Benitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18258',
    city: 'Brookhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '18258',
    city: 'Bröbberow',
    countrycode: 'DE',
  },
  {
    postalcode: '18258',
    city: 'Göldenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18258',
    city: 'Kambs',
    countrycode: 'DE',
  },
  {
    postalcode: '18258',
    city: 'Kassow',
    countrycode: 'DE',
  },
  {
    postalcode: '18258',
    city: 'Rukieten',
    countrycode: 'DE',
  },
  {
    postalcode: '18258',
    city: 'Schwaan',
    countrycode: 'DE',
  },
  {
    postalcode: '18258',
    city: 'Vorbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '18258',
    city: 'Wiendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18273',
    city: 'Güstrow',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Bülow',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Glasewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Groß Schwiesow',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Gutow',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Gülzow',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Klein Upahl',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Knegendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Kuhs',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Lohmen',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Lüssow',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Mistorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Mühl Rosin',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Plaaz',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Prüzen',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Recknitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Reimershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Rossewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Sarmstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18276',
    city: 'Zehna',
    countrycode: 'DE',
  },
  {
    postalcode: '18279',
    city: 'Lalendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18279',
    city: 'Langhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18279',
    city: 'Mamerow',
    countrycode: 'DE',
  },
  {
    postalcode: '18279',
    city: 'Vietgest',
    countrycode: 'DE',
  },
  {
    postalcode: '18279',
    city: 'Wattmannshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18292',
    city: 'Bellin',
    countrycode: 'DE',
  },
  {
    postalcode: '18292',
    city: 'Charlottenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '18292',
    city: 'Dobbin',
    countrycode: 'DE',
  },
  {
    postalcode: '18292',
    city: 'Hoppenrade',
    countrycode: 'DE',
  },
  {
    postalcode: '18292',
    city: 'Klein Tessin',
    countrycode: 'DE',
  },
  {
    postalcode: '18292',
    city: 'Krakow',
    countrycode: 'DE',
  },
  {
    postalcode: '18292',
    city: 'Kuchelmiß',
    countrycode: 'DE',
  },
  {
    postalcode: '18292',
    city: 'Linstow',
    countrycode: 'DE',
  },
  {
    postalcode: '18299',
    city: 'Alt Kätwin',
    countrycode: 'DE',
  },
  {
    postalcode: '18299',
    city: 'Diekhof',
    countrycode: 'DE',
  },
  {
    postalcode: '18299',
    city: 'Groß Ridsenow',
    countrycode: 'DE',
  },
  {
    postalcode: '18299',
    city: 'Hohen Sprenz',
    countrycode: 'DE',
  },
  {
    postalcode: '18299',
    city: 'Korleput',
    countrycode: 'DE',
  },
  {
    postalcode: '18299',
    city: 'Laage',
    countrycode: 'DE',
  },
  {
    postalcode: '18299',
    city: 'Liessow',
    countrycode: 'DE',
  },
  {
    postalcode: '18299',
    city: 'Pölitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18299',
    city: 'Sabel',
    countrycode: 'DE',
  },
  {
    postalcode: '18299',
    city: 'Striesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18299',
    city: 'Wardow',
    countrycode: 'DE',
  },
  {
    postalcode: '18299',
    city: 'Weitendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18311',
    city: 'Ribnitz-Damgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '18314',
    city: 'Bartelshagen II',
    countrycode: 'DE',
  },
  {
    postalcode: '18314',
    city: 'Beughorst',
    countrycode: 'DE',
  },
  {
    postalcode: '18314',
    city: 'Divitz-Spoldershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18314',
    city: 'Kenz-Küstrow',
    countrycode: 'DE',
  },
  {
    postalcode: '18314',
    city: 'Kindshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18314',
    city: 'Löbnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18314',
    city: 'Lüdershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18314',
    city: 'Redebas',
    countrycode: 'DE',
  },
  {
    postalcode: '18314',
    city: 'Saatel',
    countrycode: 'DE',
  },
  {
    postalcode: '18317',
    city: 'Hermannshagen Dorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18317',
    city: 'Hermannshagen Hof',
    countrycode: 'DE',
  },
  {
    postalcode: '18317',
    city: 'Hessenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '18317',
    city: 'Kuhlenbusch',
    countrycode: 'DE',
  },
  {
    postalcode: '18317',
    city: 'Kückenshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18317',
    city: 'Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18317',
    city: 'Neuendorf Heide',
    countrycode: 'DE',
  },
  {
    postalcode: '18317',
    city: 'Saal',
    countrycode: 'DE',
  },
  {
    postalcode: '18317',
    city: 'Staben',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Ahrenshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Balkenkoppel',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Buchenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Daskow',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Eickhof',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Gruel',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Langenhanshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Langenhanshagen Heide',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Neuenlübke',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Neuenrost',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Prusdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Schlemmin',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Todenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Tribohm',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Trinwillershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18320',
    city: 'Wiepkenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18334',
    city: 'Bad Sülze',
    countrycode: 'DE',
  },
  {
    postalcode: '18334',
    city: 'Breesen',
    countrycode: 'DE',
  },
  {
    postalcode: '18334',
    city: 'Böhlendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18334',
    city: 'Dettmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18334',
    city: 'Eixen',
    countrycode: 'DE',
  },
  {
    postalcode: '18334',
    city: 'Langsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18334',
    city: 'Schulenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '18334',
    city: 'Semlow',
    countrycode: 'DE',
  },
  {
    postalcode: '18337',
    city: 'Marlow',
    countrycode: 'DE',
  },
  {
    postalcode: '18347',
    city: 'Dierhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18347',
    city: 'Ostseebad Ahrenshoop',
    countrycode: 'DE',
  },
  {
    postalcode: '18347',
    city: 'Ostseebad Wustrow',
    countrycode: 'DE',
  },
  {
    postalcode: '18356',
    city: 'Barth',
    countrycode: 'DE',
  },
  {
    postalcode: '18356',
    city: 'Bodstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '18356',
    city: 'Bresewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18356',
    city: 'Fahrenkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '18356',
    city: 'Fuhlendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18356',
    city: 'Glöwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18356',
    city: 'Gutglück',
    countrycode: 'DE',
  },
  {
    postalcode: '18356',
    city: 'Michaelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18356',
    city: 'Planitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18356',
    city: 'Pruchten',
    countrycode: 'DE',
  },
  {
    postalcode: '18374',
    city: 'Zingst',
    countrycode: 'DE',
  },
  {
    postalcode: '18375',
    city: 'Born',
    countrycode: 'DE',
  },
  {
    postalcode: '18375',
    city: 'Ostseebad Prerow',
    countrycode: 'DE',
  },
  {
    postalcode: '18375',
    city: 'Wieck amDarß',
    countrycode: 'DE',
  },
  {
    postalcode: '18435',
    city: 'Stralsund',
    countrycode: 'DE',
  },
  {
    postalcode: '18437',
    city: 'Stralsund',
    countrycode: 'DE',
  },
  {
    postalcode: '18439',
    city: 'Stralsund',
    countrycode: 'DE',
  },
  {
    postalcode: '18442',
    city: 'Groß Kordshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18442',
    city: 'Jakobsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18442',
    city: 'Kummerow',
    countrycode: 'DE',
  },
  {
    postalcode: '18442',
    city: 'Lüssow',
    countrycode: 'DE',
  },
  {
    postalcode: '18442',
    city: 'Neu Bartelshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18442',
    city: 'Niepars',
    countrycode: 'DE',
  },
  {
    postalcode: '18442',
    city: 'Pantelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18442',
    city: 'Steinhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18442',
    city: 'Wendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18445',
    city: 'Altenpleen',
    countrycode: 'DE',
  },
  {
    postalcode: '18445',
    city: 'Groß Mohrdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18445',
    city: 'Klausdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18445',
    city: 'Kramerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '18445',
    city: 'Preetz',
    countrycode: 'DE',
  },
  {
    postalcode: '18445',
    city: 'Prohn',
    countrycode: 'DE',
  },
  {
    postalcode: '18461',
    city: 'Franzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '18461',
    city: 'Gremersdorf-Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '18461',
    city: 'Millienhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18461',
    city: 'Oebelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18461',
    city: 'Richtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '18461',
    city: 'Weitenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18465',
    city: 'Drechow',
    countrycode: 'DE',
  },
  {
    postalcode: '18465',
    city: 'Hugoldsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18465',
    city: 'Tribsees',
    countrycode: 'DE',
  },
  {
    postalcode: '18469',
    city: 'Karnin',
    countrycode: 'DE',
  },
  {
    postalcode: '18469',
    city: 'Velgast',
    countrycode: 'DE',
  },
  {
    postalcode: '18507',
    city: 'Grimmen',
    countrycode: 'DE',
  },
  {
    postalcode: '18510',
    city: 'Behnkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18510',
    city: 'Elmenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '18510',
    city: 'Heidebrink',
    countrycode: 'DE',
  },
  {
    postalcode: '18510',
    city: 'Papenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18510',
    city: 'Stoltenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18510',
    city: 'Wittenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18510',
    city: 'Zarrendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18513',
    city: 'Deyelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18513',
    city: 'Glewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18513',
    city: 'Grammendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18513',
    city: 'Gransebieth',
    countrycode: 'DE',
  },
  {
    postalcode: '18513',
    city: 'Splietsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18513',
    city: 'Wendisch Baggendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18516',
    city: 'Süderholz',
    countrycode: 'DE',
  },
  {
    postalcode: '18519',
    city: 'Brandshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18519',
    city: 'Horst',
    countrycode: 'DE',
  },
  {
    postalcode: '18519',
    city: 'Kirchdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '18519',
    city: 'Miltzow',
    countrycode: 'DE',
  },
  {
    postalcode: '18519',
    city: 'Reinberg',
    countrycode: 'DE',
  },
  {
    postalcode: '18519',
    city: 'Wilmshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18528',
    city: 'Bergen',
    countrycode: 'DE',
  },
  {
    postalcode: '18528',
    city: 'Buschvitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18528',
    city: 'Lietzow',
    countrycode: 'DE',
  },
  {
    postalcode: '18528',
    city: 'Parchtitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18528',
    city: 'Patzig',
    countrycode: 'DE',
  },
  {
    postalcode: '18528',
    city: 'Ralswiek',
    countrycode: 'DE',
  },
  {
    postalcode: '18528',
    city: 'Rappin',
    countrycode: 'DE',
  },
  {
    postalcode: '18528',
    city: 'Sehlen',
    countrycode: 'DE',
  },
  {
    postalcode: '18528',
    city: 'Thesenvitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18528',
    city: 'Zirkow',
    countrycode: 'DE',
  },
  {
    postalcode: '18546',
    city: 'Sassnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18551',
    city: 'Glowe',
    countrycode: 'DE',
  },
  {
    postalcode: '18551',
    city: 'Lohme',
    countrycode: 'DE',
  },
  {
    postalcode: '18551',
    city: 'Sagard',
    countrycode: 'DE',
  },
  {
    postalcode: '18556',
    city: 'Altenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '18556',
    city: 'Breege',
    countrycode: 'DE',
  },
  {
    postalcode: '18556',
    city: 'Dranske',
    countrycode: 'DE',
  },
  {
    postalcode: '18556',
    city: 'Putgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '18556',
    city: 'Wiek',
    countrycode: 'DE',
  },
  {
    postalcode: '18565',
    city: 'Grieben',
    countrycode: 'DE',
  },
  {
    postalcode: '18565',
    city: 'Kloster',
    countrycode: 'DE',
  },
  {
    postalcode: '18565',
    city: 'Neuendorf, Hiddensee',
    countrycode: 'DE',
  },
  {
    postalcode: '18565',
    city: 'Vitte',
    countrycode: 'DE',
  },
  {
    postalcode: '18569',
    city: 'Gingst',
    countrycode: 'DE',
  },
  {
    postalcode: '18569',
    city: 'Kluis',
    countrycode: 'DE',
  },
  {
    postalcode: '18569',
    city: 'Neuenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '18569',
    city: 'Schaprode',
    countrycode: 'DE',
  },
  {
    postalcode: '18569',
    city: 'Trent',
    countrycode: 'DE',
  },
  {
    postalcode: '18569',
    city: 'Ummanz',
    countrycode: 'DE',
  },
  {
    postalcode: '18573',
    city: 'Altefähr',
    countrycode: 'DE',
  },
  {
    postalcode: '18573',
    city: 'Dreschvitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18573',
    city: 'Rambin',
    countrycode: 'DE',
  },
  {
    postalcode: '18573',
    city: 'Samtens',
    countrycode: 'DE',
  },
  {
    postalcode: '18574',
    city: 'Garz',
    countrycode: 'DE',
  },
  {
    postalcode: '18574',
    city: 'Groß Schoritz',
    countrycode: 'DE',
  },
  {
    postalcode: '18574',
    city: 'Gustow',
    countrycode: 'DE',
  },
  {
    postalcode: '18574',
    city: 'Karnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18574',
    city: 'Poseritz',
    countrycode: 'DE',
  },
  {
    postalcode: '18574',
    city: 'Zudar',
    countrycode: 'DE',
  },
  {
    postalcode: '18581',
    city: 'Alt Lanschvitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18581',
    city: 'Beuchow',
    countrycode: 'DE',
  },
  {
    postalcode: '18581',
    city: 'Darsband',
    countrycode: 'DE',
  },
  {
    postalcode: '18581',
    city: 'Insel Vilm',
    countrycode: 'DE',
  },
  {
    postalcode: '18581',
    city: 'Putbus',
    countrycode: 'DE',
  },
  {
    postalcode: '18581',
    city: 'Strachtitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18586',
    city: 'Baabe',
    countrycode: 'DE',
  },
  {
    postalcode: '18586',
    city: 'Gager',
    countrycode: 'DE',
  },
  {
    postalcode: '18586',
    city: 'Göhren',
    countrycode: 'DE',
  },
  {
    postalcode: '18586',
    city: 'Lancken-Granitz',
    countrycode: 'DE',
  },
  {
    postalcode: '18586',
    city: 'Middelhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '18586',
    city: 'Sellin',
    countrycode: 'DE',
  },
  {
    postalcode: '18586',
    city: 'Thiessow',
    countrycode: 'DE',
  },
  {
    postalcode: '18609',
    city: 'Ostseebad Binz',
    countrycode: 'DE',
  },
  {
    postalcode: '19053',
    city: 'Schwerin',
    countrycode: 'DE',
  },
  {
    postalcode: '19055',
    city: 'Schwerin',
    countrycode: 'DE',
  },
  {
    postalcode: '19057',
    city: 'Schwerin',
    countrycode: 'DE',
  },
  {
    postalcode: '19059',
    city: 'Schwerin',
    countrycode: 'DE',
  },
  {
    postalcode: '19061',
    city: 'Schwerin',
    countrycode: 'DE',
  },
  {
    postalcode: '19063',
    city: 'Schwerin',
    countrycode: 'DE',
  },
  {
    postalcode: '19065',
    city: 'Gneven',
    countrycode: 'DE',
  },
  {
    postalcode: '19065',
    city: 'Godern',
    countrycode: 'DE',
  },
  {
    postalcode: '19065',
    city: 'Görslow',
    countrycode: 'DE',
  },
  {
    postalcode: '19065',
    city: 'Kritzow',
    countrycode: 'DE',
  },
  {
    postalcode: '19065',
    city: 'Pinnow',
    countrycode: 'DE',
  },
  {
    postalcode: '19065',
    city: 'Raben Steinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '19067',
    city: 'Cambs',
    countrycode: 'DE',
  },
  {
    postalcode: '19067',
    city: 'Langen Brütz',
    countrycode: 'DE',
  },
  {
    postalcode: '19067',
    city: 'Leezen',
    countrycode: 'DE',
  },
  {
    postalcode: '19067',
    city: 'Neu Schlagsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19067',
    city: 'Rampe',
    countrycode: 'DE',
  },
  {
    postalcode: '19067',
    city: 'Retgendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19067',
    city: 'Rubow',
    countrycode: 'DE',
  },
  {
    postalcode: '19067',
    city: 'Zittow',
    countrycode: 'DE',
  },
  {
    postalcode: '19069',
    city: 'Alt Meteln',
    countrycode: 'DE',
  },
  {
    postalcode: '19069',
    city: 'Böken',
    countrycode: 'DE',
  },
  {
    postalcode: '19069',
    city: 'Drispeth',
    countrycode: 'DE',
  },
  {
    postalcode: '19069',
    city: 'Klein Trebbow',
    countrycode: 'DE',
  },
  {
    postalcode: '19069',
    city: 'Lübstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19069',
    city: 'Moltenow',
    countrycode: 'DE',
  },
  {
    postalcode: '19069',
    city: 'Pingelshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '19069',
    city: 'Rugensee',
    countrycode: 'DE',
  },
  {
    postalcode: '19069',
    city: 'Seehof',
    countrycode: 'DE',
  },
  {
    postalcode: '19069',
    city: 'Zickhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '19071',
    city: 'Brüsewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19071',
    city: 'Cramonshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '19071',
    city: 'Dalberg-Wendelstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19071',
    city: 'Grambow',
    countrycode: 'DE',
  },
  {
    postalcode: '19071',
    city: 'Groß Brütz',
    countrycode: 'DE',
  },
  {
    postalcode: '19071',
    city: 'Herren Steinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '19073',
    city: 'Dümmer',
    countrycode: 'DE',
  },
  {
    postalcode: '19073',
    city: 'Klein Rogahn',
    countrycode: 'DE',
  },
  {
    postalcode: '19073',
    city: 'Schossin',
    countrycode: 'DE',
  },
  {
    postalcode: '19073',
    city: 'Stralendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19073',
    city: 'Wittenförden',
    countrycode: 'DE',
  },
  {
    postalcode: '19073',
    city: 'Wodenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19073',
    city: 'Zülow',
    countrycode: 'DE',
  },
  {
    postalcode: '19075',
    city: 'Holthusen',
    countrycode: 'DE',
  },
  {
    postalcode: '19075',
    city: 'Mühlenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '19075',
    city: 'Pampow',
    countrycode: 'DE',
  },
  {
    postalcode: '19075',
    city: 'Warsow',
    countrycode: 'DE',
  },
  {
    postalcode: '19077',
    city: 'Kraak',
    countrycode: 'DE',
  },
  {
    postalcode: '19077',
    city: 'Lübesse',
    countrycode: 'DE',
  },
  {
    postalcode: '19077',
    city: 'Rastow',
    countrycode: 'DE',
  },
  {
    postalcode: '19077',
    city: 'Sülstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19077',
    city: 'Uelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19079',
    city: 'Banzkow',
    countrycode: 'DE',
  },
  {
    postalcode: '19079',
    city: 'Goldenstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '19079',
    city: 'Sukow',
    countrycode: 'DE',
  },
  {
    postalcode: '19086',
    city: 'Plate',
    countrycode: 'DE',
  },
  {
    postalcode: '19089',
    city: 'Bahlenhüschen',
    countrycode: 'DE',
  },
  {
    postalcode: '19089',
    city: 'Barnin',
    countrycode: 'DE',
  },
  {
    postalcode: '19089',
    city: 'Bülow',
    countrycode: 'DE',
  },
  {
    postalcode: '19089',
    city: 'Crivitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19089',
    city: 'Demen',
    countrycode: 'DE',
  },
  {
    postalcode: '19089',
    city: 'Gädebehn',
    countrycode: 'DE',
  },
  {
    postalcode: '19089',
    city: 'Göhren',
    countrycode: 'DE',
  },
  {
    postalcode: '19089',
    city: 'Ruthenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '19089',
    city: 'Settin',
    countrycode: 'DE',
  },
  {
    postalcode: '19089',
    city: 'Tramm',
    countrycode: 'DE',
  },
  {
    postalcode: '19089',
    city: 'Wessin',
    countrycode: 'DE',
  },
  {
    postalcode: '19089',
    city: 'Zapel',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Bendhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Botelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Breesen',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Dragun',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Dutzow',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Gadebusch',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Goddin',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Groß Eichsen',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Groß Salitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Groß Thurow',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Hindenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Kaeselow',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Klein Salitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Klein Thurow',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Kneese',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Krembz',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Marienthal',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Meetzen',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Mühlen Eichsen',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Neu Thurow',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Passow',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Pokrent',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Radegast',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Rambeel',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Roggendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Rosenow',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Rögnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Schönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Schönwolde',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Veelböken',
    countrycode: 'DE',
  },
  {
    postalcode: '19205',
    city: 'Webelsfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '19209',
    city: 'Badow',
    countrycode: 'DE',
  },
  {
    postalcode: '19209',
    city: 'Gottesgabe',
    countrycode: 'DE',
  },
  {
    postalcode: '19209',
    city: 'Groß Welzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19209',
    city: 'Klein Welzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19209',
    city: 'Lützow',
    countrycode: 'DE',
  },
  {
    postalcode: '19209',
    city: 'Perlin',
    countrycode: 'DE',
  },
  {
    postalcode: '19209',
    city: 'Renzow',
    countrycode: 'DE',
  },
  {
    postalcode: '19209',
    city: 'Rosenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Benzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Bestenrade',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Brützkow',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Bülow',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Campow',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Carlow',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Dechow',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Demern',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Gletzow',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Groß Hundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Groß Molzahn',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Groß Rünz',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Holdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Kasendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Klein Molzahn',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Kuhlrade',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Köchelstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Löwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Neschow',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Nesow',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Neu Vitense',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Othenstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Parber',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Pogez',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Raddingsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Rehna',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Rieps',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Samkow',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Schaddingsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Schlagsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Stove',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Thandorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Törber',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Utecht',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Vitense',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Wedendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19217',
    city: 'Woitendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Alt Zachun',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Bandenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Belsch',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Bobzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Bresegard',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Gammelin',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Goldenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Gramnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Granzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Groß Krams',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Grünhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Hagenow',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Hagenow Heide',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Hoort',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Hülseburg',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Jasnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Kirch Jesar',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Kuhstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Moraas',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Neu Zachun',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Neuenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Picher',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Pritzier',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Pätow',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Redefin',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Ruhethal',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Scharbow',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Schwaberow',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Schwechow',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Setzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Steegen',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Strohkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Sudenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Toddin',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Viez',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Warlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19230',
    city: 'Zapel',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Boddin',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Dodow',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Dreilützow',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Drönnewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Döbbersen',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Harst',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Karft',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Körchow',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Kützin',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Lehsen',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Luckwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Parum',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Perdöhl',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Pogreß',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Püttelkow',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Raguth',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Tessin',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Waschow',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Wittenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Woez',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Wulfskuhl',
    countrycode: 'DE',
  },
  {
    postalcode: '19243',
    city: 'Zühr',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Bantin',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Bernstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Boissow',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Boize',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Camin',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Hakendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Kogel',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Kölzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Lassahn',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Lüttow',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Neuenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Pamprin',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Stintenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Stintenburger Hütte',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Techin',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Testorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Valluhn',
    countrycode: 'DE',
  },
  {
    postalcode: '19246',
    city: 'Zarrentin',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Bandekow',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Benz',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Briest',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Brömsenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Garlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Gudow',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Gößlow',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Jessenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Jessenitz-Werk',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Lank',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Lübbendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Lübtheen',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Neu Lübtheen',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Probst Jesar',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Quassel',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Trebs',
    countrycode: 'DE',
  },
  {
    postalcode: '19249',
    city: 'Volzrade',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Badekow',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Besitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Bickhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Boizenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Gallin',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Granzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Gresse',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Greven',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Groß Bengerstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Heide',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Heidekrug',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Horst',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Klein Bengerstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Kuhlenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Leisterförde',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Lüttenmark',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Neu Gülze',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Nieklitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Nostorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Rensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Schwanheide',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Tessin',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Wiebendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19258',
    city: 'Zahrensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19260',
    city: 'Albertinenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19260',
    city: 'Banzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19260',
    city: 'Bennin',
    countrycode: 'DE',
  },
  {
    postalcode: '19260',
    city: 'Dersenow',
    countrycode: 'DE',
  },
  {
    postalcode: '19260',
    city: 'Goldenbow',
    countrycode: 'DE',
  },
  {
    postalcode: '19260',
    city: 'Kloddram',
    countrycode: 'DE',
  },
  {
    postalcode: '19260',
    city: 'Marsow',
    countrycode: 'DE',
  },
  {
    postalcode: '19260',
    city: 'Rodenwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '19260',
    city: 'Schildfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '19260',
    city: 'Tüschow',
    countrycode: 'DE',
  },
  {
    postalcode: '19260',
    city: 'Vellahn',
    countrycode: 'DE',
  },
  {
    postalcode: '19260',
    city: 'Vietow',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Bandekow',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Bitter',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Bleckederwerder',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Bohnenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Brahlstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Dammereez',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Darchau',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Dellien',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Groß Kühren',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Groß Timkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Gülstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Gülze',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Haar',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Herrenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Hinterhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Kaarßen',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Konau',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Krusendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Laake',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Laave',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Langenheide',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Melkof',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Neu Bleckede',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Neu Garge',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Neu Wendischthun',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Neuhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Niendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Pinnau',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Popelau',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Preten',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Privelack',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Rassau',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Rosien',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Schleusenow',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Soltow',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Stapel',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Stiepelse',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Stixe',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Strachau',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Sumte',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Sückau',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Teldau',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Tripkau',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Viehle',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Vockfey',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Vorderhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Wehningen',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Wilkenstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19273',
    city: 'Zeetze',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Alt Krenzlin',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Fahrbinde',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Glaisin',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Groß Laasch',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Göhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Klein Krams',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Krenzliner Hütte',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Kummer',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Leussow',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Ludwigslust',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Lüblow',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Neu Lüblow',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Warlow',
    countrycode: 'DE',
  },
  {
    postalcode: '19288',
    city: 'Wöbbelin',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Altona',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Bresegard',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Dadow',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Eldena',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Gorlosen',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Grebs',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Göhren',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Görnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Güritz Försterei',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Karenz',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Karstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Krinitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Liepe',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Malk',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Malliß',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Neu Göhren',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Neu Kaliß',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Niendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Strassen',
    countrycode: 'DE',
  },
  {
    postalcode: '19294',
    city: 'Stuck',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Balow',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Beckentin',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Deibow',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Fresenbrügge',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Grabow',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Kastorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Kremmin',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Marienhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Milow',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Muchow',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Möllenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Neese',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Neese Ausbau',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Prislich',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Semmerin',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Steesow',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Wanzlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Werle',
    countrycode: 'DE',
  },
  {
    postalcode: '19300',
    city: 'Zierzow',
    countrycode: 'DE',
  },
  {
    postalcode: '19303',
    city: 'Alt Jabel',
    countrycode: 'DE',
  },
  {
    postalcode: '19303',
    city: 'Dömitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19303',
    city: 'Heidhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19303',
    city: 'Neu Jabel',
    countrycode: 'DE',
  },
  {
    postalcode: '19303',
    city: 'Polz',
    countrycode: 'DE',
  },
  {
    postalcode: '19303',
    city: 'Rüterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19303',
    city: 'Tewswoos',
    countrycode: 'DE',
  },
  {
    postalcode: '19303',
    city: 'Vielank',
    countrycode: 'DE',
  },
  {
    postalcode: '19303',
    city: 'Woosmer',
    countrycode: 'DE',
  },
  {
    postalcode: '19306',
    city: 'Blievenstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19306',
    city: 'Brenz',
    countrycode: 'DE',
  },
  {
    postalcode: '19306',
    city: 'Neustadt-Glewe',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Baarz',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Bernheide',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Besandten',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Eldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Ferbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Gaarz',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Jagel',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Lanz',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Lenzen',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Mellen',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Rambow amSee',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Unbesandten',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Wootz',
    countrycode: 'DE',
  },
  {
    postalcode: '19309',
    city: 'Wustrow',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Abbendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Bentwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Breese',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Bälow',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Cumlosen',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Garsedow',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Gnevsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Groß Breese',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Hermannshof',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Hinzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Klein Leppin',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Klein Lüben',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Kuhberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Kuhblank',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Lindenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Lütjenheide',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Motrich',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Müggendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Plattenburg Forsthaus',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Rühstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Sandkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Schadebeuster',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Schilde',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Weisen',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Wentdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Wittenberge',
    countrycode: 'DE',
  },
  {
    postalcode: '19322',
    city: 'Zwischendeich',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Bad Wilsnack',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Groß Lüben',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Groß Werzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Grube',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Haaren',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Karthan',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Kletzke',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Legde',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Lennewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Plattenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Quitzöbel',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Rambow',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Roddan',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Sigrön',
    countrycode: 'DE',
  },
  {
    postalcode: '19336',
    city: 'Viesecke',
    countrycode: 'DE',
  },
  {
    postalcode: '19339',
    city: 'Bendelin',
    countrycode: 'DE',
  },
  {
    postalcode: '19339',
    city: 'Friedrichswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '19339',
    city: 'Glöwen',
    countrycode: 'DE',
  },
  {
    postalcode: '19339',
    city: 'Groß Leppin',
    countrycode: 'DE',
  },
  {
    postalcode: '19339',
    city: 'Hermshof',
    countrycode: 'DE',
  },
  {
    postalcode: '19339',
    city: 'Netzow',
    countrycode: 'DE',
  },
  {
    postalcode: '19339',
    city: 'Scharleuk',
    countrycode: 'DE',
  },
  {
    postalcode: '19339',
    city: 'Schwanensee',
    countrycode: 'DE',
  },
  {
    postalcode: '19339',
    city: 'Storbeckshof',
    countrycode: 'DE',
  },
  {
    postalcode: '19339',
    city: 'Söllenthin',
    countrycode: 'DE',
  },
  {
    postalcode: '19339',
    city: 'Zernikow',
    countrycode: 'DE',
  },
  {
    postalcode: '19339',
    city: 'Zichtow',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Baek',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Berge',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Bresch',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Burghagen',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Burow',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Dannhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Dergenthin',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Düpow',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Gramzow',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Grenzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Groß Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Groß Gottschow',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Groß Linde',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Guhlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Gulow',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Gülitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Hellburg',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Hohenvier',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Horst',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Kaltenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Karlshorst',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Kleeste',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Klein Gottschow',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Klein Linde',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Kleinow',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Knorrenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Krampfer',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Kreuzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Laaslich',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Lenzersilge',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Lübzow',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Lübzow Ausbau',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Margarethenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Mollnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Muggerkuhl',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Nebelin',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Neu Hohenvier',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Neuhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Perleberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Pirow',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Platenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Ponitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Quitzow',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Reetz',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Retzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Rohlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Rosenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Schönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Schönholz',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Seddin',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Simonshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Spiegelhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Steinberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Strigleben',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Sükow',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Tacken',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Tangendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Tiefenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Uenze',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Waldhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Wolfshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '19348',
    city: 'Wüsten-Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Birkholz',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Blüthen',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Boberow',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Bootz',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Dallmin',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Dambeck',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Dargardt',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Garlin',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Glövzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Gosedahl',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Groß Warnow',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Karstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Karwe',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Klein Warnow',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Klockow',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Klüß',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Kribbe',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Mankmuß',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Mesekow',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Neu Pinnow',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Neu Premslin',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Pinnow',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Postlin',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Premslin',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Pröttlin',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Reckenzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Sargleben',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Seetz',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Simonshof',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Stavenow',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Streesow',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Strehlen',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Waterloo',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Wittmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '19357',
    city: 'Zapel',
    countrycode: 'DE',
  },
  {
    postalcode: '19370',
    city: 'Parchim',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Bauerkuhl',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Brunow',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Darze',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Drefahl',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Dütschow',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Groß Godems',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Herzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Karrenzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Lancken',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Löcknitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Matzlow-Garwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Meierstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Pampin',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Platschow',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Primank',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Rom',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Spornitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Steinbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Stolpe',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Stralendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Stresendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19372',
    city: 'Ziegendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Bergrade Dorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Damm',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Domsühl',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Frauenmark',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Friedrichsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Goldenbow',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Grebbin',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Groß Niendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Herzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Hof Grabow',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Kadow',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Kladrum',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Klinken',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Lenschow',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Mestlin',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Neu Ruthenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Raduhn',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Ruest',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Severin',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Vimfow',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Zieslübbe',
    countrycode: 'DE',
  },
  {
    postalcode: '19374',
    city: 'Zölkow',
    countrycode: 'DE',
  },
  {
    postalcode: '19376',
    city: 'Drenkow',
    countrycode: 'DE',
  },
  {
    postalcode: '19376',
    city: 'Jarchow',
    countrycode: 'DE',
  },
  {
    postalcode: '19376',
    city: 'Leppin',
    countrycode: 'DE',
  },
  {
    postalcode: '19376',
    city: 'Marnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19376',
    city: 'Mentin',
    countrycode: 'DE',
  },
  {
    postalcode: '19376',
    city: 'Poltnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '19376',
    city: 'Siggelkow',
    countrycode: 'DE',
  },
  {
    postalcode: '19376',
    city: 'Suckow',
    countrycode: 'DE',
  },
  {
    postalcode: '19376',
    city: 'Tessenow',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Broock',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Gallin',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Gischow',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Granzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Karbow-Vietlübbe',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Kreien',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Kritzow',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Kuhwalk',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Kuppentin',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Lutheran',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Lübz',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Passow',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Quaßliner Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Sandkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Wahlstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19386',
    city: 'Werder',
    countrycode: 'DE',
  },
  {
    postalcode: '19395',
    city: 'Barkow',
    countrycode: 'DE',
  },
  {
    postalcode: '19395',
    city: 'Ganzlin',
    countrycode: 'DE',
  },
  {
    postalcode: '19395',
    city: 'Gnevsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19395',
    city: 'Hahnenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '19395',
    city: 'Karow',
    countrycode: 'DE',
  },
  {
    postalcode: '19395',
    city: 'Plau',
    countrycode: 'DE',
  },
  {
    postalcode: '19395',
    city: 'Plauerhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '19395',
    city: 'Redewisch',
    countrycode: 'DE',
  },
  {
    postalcode: '19395',
    city: 'Retzow',
    countrycode: 'DE',
  },
  {
    postalcode: '19395',
    city: 'Wendisch Priborn',
    countrycode: 'DE',
  },
  {
    postalcode: '19395',
    city: 'Wunderfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '19399',
    city: 'Diestelow',
    countrycode: 'DE',
  },
  {
    postalcode: '19399',
    city: 'Dobbertin',
    countrycode: 'DE',
  },
  {
    postalcode: '19399',
    city: 'Goldberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19399',
    city: 'Jellen bei Dobbertin',
    countrycode: 'DE',
  },
  {
    postalcode: '19399',
    city: 'Jellen bei Goldberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19399',
    city: 'Kleesten',
    countrycode: 'DE',
  },
  {
    postalcode: '19399',
    city: 'Langenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '19399',
    city: 'Neu Poserin',
    countrycode: 'DE',
  },
  {
    postalcode: '19399',
    city: 'Suckwitz Abbau',
    countrycode: 'DE',
  },
  {
    postalcode: '19399',
    city: 'Techentin',
    countrycode: 'DE',
  },
  {
    postalcode: '19399',
    city: 'Unterbrüz',
    countrycode: 'DE',
  },
  {
    postalcode: '19399',
    city: 'Wendisch Waren',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Bolz',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Borkow',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Dabel',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Dessin',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Dinnies',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Groß Görnow',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Groß Raden',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Gägelow',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Hohen Pritz',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Hohenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Holzendorf bei Sternberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Klein Görnow',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Klein Pritz',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Kobrow',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Lenzen',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Loiz',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Mustin',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Neu Pastin',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Neu Woserin',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Pastin',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Rothen',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Ruchow',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Sagsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Schlowe',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Schönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Sternberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Sternberger Burg',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Stieten',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Turloff',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Wamckow',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Witzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Woland',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Woserin',
    countrycode: 'DE',
  },
  {
    postalcode: '19406',
    city: 'Zülow',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Alt Necheln',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Blankenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Brüel',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Friedrichswalde',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Golchen',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Gustävel',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Holdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Holzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Häven',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Jülchendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Kaarz',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Keez',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Klein Jarchow',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Kuhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Langen Jarchow',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Müsselmow',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Neu Necheln',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Nutteln',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Penzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Schönlage',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Sülten',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Tempzin',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Tessin',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Thurow',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Weberin',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Weitendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Wendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Wipersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Zahrensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19412',
    city: 'Zaschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Bibow',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Büschow',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Dämelow',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Groß Labenz',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Hasenwinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Jesendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Kleekamp',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Klein Labenz',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Lindenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Mankmoos',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Neperstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Neu Pennewitt',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Nisbill',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Pennewitt',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Trams',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Ventschow',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Warin',
    countrycode: 'DE',
  },
  {
    postalcode: '19417',
    city: 'Weiße Krug',
    countrycode: 'DE',
  },
  {
    postalcode: '20095',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20097',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20099',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20144',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20146',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20148',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20149',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20249',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20251',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20253',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20255',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20257',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20259',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20354',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20355',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20357',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20359',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20457',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20459',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20535',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20537',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '20539',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21029',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21031',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21033',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21035',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21037',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21039',
    city: 'Börnsen',
    countrycode: 'DE',
  },
  {
    postalcode: '21039',
    city: 'Escheburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21039',
    city: 'Fahrendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21039',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21073',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21075',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21077',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21079',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21107',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21109',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21129',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21147',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21149',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21217',
    city: 'Seevetal',
    countrycode: 'DE',
  },
  {
    postalcode: '21218',
    city: 'Seevetal',
    countrycode: 'DE',
  },
  {
    postalcode: '21220',
    city: 'Seevetal',
    countrycode: 'DE',
  },
  {
    postalcode: '21224',
    city: 'Rosengarten',
    countrycode: 'DE',
  },
  {
    postalcode: '21227',
    city: 'Am Jägerberg',
    countrycode: 'DE',
  },
  {
    postalcode: '21227',
    city: 'Bendestorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21228',
    city: 'Harmstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21244',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '21255',
    city: 'Dohren',
    countrycode: 'DE',
  },
  {
    postalcode: '21255',
    city: 'Drestedt-Valzik',
    countrycode: 'DE',
  },
  {
    postalcode: '21255',
    city: 'Kakenstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21255',
    city: 'Königsmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '21255',
    city: 'Riepshof',
    countrycode: 'DE',
  },
  {
    postalcode: '21255',
    city: 'Tostedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21255',
    city: 'Wistedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21256',
    city: 'Handeloh',
    countrycode: 'DE',
  },
  {
    postalcode: '21258',
    city: 'Heidenau',
    countrycode: 'DE',
  },
  {
    postalcode: '21259',
    city: 'Otter',
    countrycode: 'DE',
  },
  {
    postalcode: '21261',
    city: 'Welle',
    countrycode: 'DE',
  },
  {
    postalcode: '21266',
    city: 'Jesteburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21271',
    city: 'Asendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21271',
    city: 'Hanstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21272',
    city: 'Egestorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21274',
    city: 'Undeloh',
    countrycode: 'DE',
  },
  {
    postalcode: '21279',
    city: 'Appel',
    countrycode: 'DE',
  },
  {
    postalcode: '21279',
    city: 'Appelbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '21279',
    city: 'Drestedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21279',
    city: 'Hollenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21279',
    city: 'Wenzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21335',
    city: 'Lüneburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21337',
    city: 'Lüneburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21339',
    city: 'Lüneburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21354',
    city: 'Bleckede',
    countrycode: 'DE',
  },
  {
    postalcode: '21357',
    city: 'Bardowick',
    countrycode: 'DE',
  },
  {
    postalcode: '21357',
    city: 'Barum',
    countrycode: 'DE',
  },
  {
    postalcode: '21357',
    city: 'Wittorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21358',
    city: 'Mechtersen',
    countrycode: 'DE',
  },
  {
    postalcode: '21360',
    city: 'Vögelsen',
    countrycode: 'DE',
  },
  {
    postalcode: '21365',
    city: 'Adendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21368',
    city: 'Boitze',
    countrycode: 'DE',
  },
  {
    postalcode: '21368',
    city: 'Dahlem',
    countrycode: 'DE',
  },
  {
    postalcode: '21368',
    city: 'Dahlenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21369',
    city: 'Nahrendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21371',
    city: 'Tosterglope',
    countrycode: 'DE',
  },
  {
    postalcode: '21376',
    city: 'Eyendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21376',
    city: 'Garlstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21376',
    city: 'Gödenstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21376',
    city: 'Salzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '21379',
    city: 'Echem',
    countrycode: 'DE',
  },
  {
    postalcode: '21379',
    city: 'Lüdersburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21379',
    city: 'Neumühlen',
    countrycode: 'DE',
  },
  {
    postalcode: '21379',
    city: 'Rullstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21379',
    city: 'Scharnebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '21380',
    city: 'Artlenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21382',
    city: 'Brietlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '21385',
    city: 'Amelinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '21385',
    city: 'Oldendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21385',
    city: 'Rehlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '21386',
    city: 'Betzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21388',
    city: 'Aspelhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '21388',
    city: 'Soderstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21391',
    city: 'Lüneburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21391',
    city: 'Reppenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21394',
    city: 'Kirchgellersen',
    countrycode: 'DE',
  },
  {
    postalcode: '21394',
    city: 'Südergellersen',
    countrycode: 'DE',
  },
  {
    postalcode: '21394',
    city: 'Westergellersen',
    countrycode: 'DE',
  },
  {
    postalcode: '21395',
    city: 'Tespe',
    countrycode: 'DE',
  },
  {
    postalcode: '21397',
    city: 'Barendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21397',
    city: 'Vastorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21398',
    city: 'Neetze',
    countrycode: 'DE',
  },
  {
    postalcode: '21400',
    city: 'Reinstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21401',
    city: 'Thomasburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21403',
    city: 'Wendisch Evern',
    countrycode: 'DE',
  },
  {
    postalcode: '21406',
    city: 'Barnstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21406',
    city: 'Melbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '21407',
    city: 'Deutsch Evern',
    countrycode: 'DE',
  },
  {
    postalcode: '21409',
    city: 'Embsen',
    countrycode: 'DE',
  },
  {
    postalcode: '21423',
    city: 'Drage',
    countrycode: 'DE',
  },
  {
    postalcode: '21423',
    city: 'Winsen',
    countrycode: 'DE',
  },
  {
    postalcode: '21435',
    city: 'Stelle',
    countrycode: 'DE',
  },
  {
    postalcode: '21436',
    city: 'Marschacht',
    countrycode: 'DE',
  },
  {
    postalcode: '21438',
    city: 'Brackel',
    countrycode: 'DE',
  },
  {
    postalcode: '21439',
    city: 'Marxen',
    countrycode: 'DE',
  },
  {
    postalcode: '21441',
    city: 'Garstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21442',
    city: 'Toppenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21444',
    city: 'Einemhof',
    countrycode: 'DE',
  },
  {
    postalcode: '21444',
    city: 'Vierhöfen',
    countrycode: 'DE',
  },
  {
    postalcode: '21445',
    city: 'Wulfsen',
    countrycode: 'DE',
  },
  {
    postalcode: '21447',
    city: 'Handorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21449',
    city: 'Radbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '21465',
    city: 'Reinbek',
    countrycode: 'DE',
  },
  {
    postalcode: '21465',
    city: 'Wentorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21481',
    city: 'Buchhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '21481',
    city: 'Lauenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21481',
    city: 'Schnakenbek',
    countrycode: 'DE',
  },
  {
    postalcode: '21483',
    city: 'Basedow',
    countrycode: 'DE',
  },
  {
    postalcode: '21483',
    city: 'Dalldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21483',
    city: 'Gülzow',
    countrycode: 'DE',
  },
  {
    postalcode: '21483',
    city: 'Juliusburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21483',
    city: 'Krukow',
    countrycode: 'DE',
  },
  {
    postalcode: '21483',
    city: 'Krüzen',
    countrycode: 'DE',
  },
  {
    postalcode: '21483',
    city: 'Lanze',
    countrycode: 'DE',
  },
  {
    postalcode: '21483',
    city: 'Lütau',
    countrycode: 'DE',
  },
  {
    postalcode: '21483',
    city: 'Wangelau',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Basthorst',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Elmenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Fuhlenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Grabau',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Grove',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Groß Pampau',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Havekost',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Kollow, Kurheim',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Louisenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Melusinental',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Möhnsen',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Mühlenrade',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Sahms',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Schretstaken',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Schwarzenbek',
    countrycode: 'DE',
  },
  {
    postalcode: '21493',
    city: 'Talkau',
    countrycode: 'DE',
  },
  {
    postalcode: '21502',
    city: 'Geesthacht',
    countrycode: 'DE',
  },
  {
    postalcode: '21502',
    city: 'Hamwarde',
    countrycode: 'DE',
  },
  {
    postalcode: '21502',
    city: 'Heidkaten',
    countrycode: 'DE',
  },
  {
    postalcode: '21502',
    city: 'Wiershop',
    countrycode: 'DE',
  },
  {
    postalcode: '21502',
    city: 'Worth',
    countrycode: 'DE',
  },
  {
    postalcode: '21509',
    city: 'Glinde',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Bröthen',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Büchen',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Fitzen',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Göttin',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Güster',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Hornbek',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Kankelau',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Klein Pampau',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Langenlehsten',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Neue Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Neugüster',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Roseburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Siebeneichen',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Witzeeze',
    countrycode: 'DE',
  },
  {
    postalcode: '21514',
    city: 'Wotersen',
    countrycode: 'DE',
  },
  {
    postalcode: '21516',
    city: 'Müssen',
    countrycode: 'DE',
  },
  {
    postalcode: '21516',
    city: 'Schulendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21516',
    city: 'Tramm',
    countrycode: 'DE',
  },
  {
    postalcode: '21516',
    city: 'Woltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21521',
    city: 'Aumühle',
    countrycode: 'DE',
  },
  {
    postalcode: '21521',
    city: 'Bismarckquelle',
    countrycode: 'DE',
  },
  {
    postalcode: '21521',
    city: 'Dassendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21521',
    city: 'Friedrichsruh',
    countrycode: 'DE',
  },
  {
    postalcode: '21521',
    city: 'Wohltorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21522',
    city: 'Hittbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '21522',
    city: 'Hohnstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21524',
    city: 'Brunstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21526',
    city: 'Hohenhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '21527',
    city: 'Kollow',
    countrycode: 'DE',
  },
  {
    postalcode: '21529',
    city: 'Kröppelshagen-Fahrendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21614',
    city: 'Buxtehude',
    countrycode: 'DE',
  },
  {
    postalcode: '21629',
    city: 'Neu Wulmstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21635',
    city: 'Hinterdeich',
    countrycode: 'DE',
  },
  {
    postalcode: '21635',
    city: 'Jork',
    countrycode: 'DE',
  },
  {
    postalcode: '21640',
    city: 'Bliedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21640',
    city: 'Horneburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21640',
    city: 'Neuenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '21640',
    city: 'Nottensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21641',
    city: 'Apensen',
    countrycode: 'DE',
  },
  {
    postalcode: '21643',
    city: 'Beckdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21644',
    city: 'Sauensiek',
    countrycode: 'DE',
  },
  {
    postalcode: '21646',
    city: 'Halvesbostel',
    countrycode: 'DE',
  },
  {
    postalcode: '21647',
    city: 'Moisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21649',
    city: 'Regesbostel',
    countrycode: 'DE',
  },
  {
    postalcode: '21680',
    city: 'Stade',
    countrycode: 'DE',
  },
  {
    postalcode: '21682',
    city: 'Stade',
    countrycode: 'DE',
  },
  {
    postalcode: '21683',
    city: 'Stade',
    countrycode: 'DE',
  },
  {
    postalcode: '21684',
    city: 'Agathenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21684',
    city: 'Stade',
    countrycode: 'DE',
  },
  {
    postalcode: '21698',
    city: 'Bargstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21698',
    city: 'Brest',
    countrycode: 'DE',
  },
  {
    postalcode: '21698',
    city: 'Harsefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '21702',
    city: 'Ahlerstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21706',
    city: 'Am Rönndeich',
    countrycode: 'DE',
  },
  {
    postalcode: '21706',
    city: 'Drochtersen',
    countrycode: 'DE',
  },
  {
    postalcode: '21709',
    city: 'Burweg',
    countrycode: 'DE',
  },
  {
    postalcode: '21709',
    city: 'Düdenbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '21709',
    city: 'Himmelpforten',
    countrycode: 'DE',
  },
  {
    postalcode: '21710',
    city: 'Engelschoff',
    countrycode: 'DE',
  },
  {
    postalcode: '21712',
    city: 'Großenwörden',
    countrycode: 'DE',
  },
  {
    postalcode: '21714',
    city: 'Hammah',
    countrycode: 'DE',
  },
  {
    postalcode: '21717',
    city: 'Deinste',
    countrycode: 'DE',
  },
  {
    postalcode: '21717',
    city: 'Fredenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '21720',
    city: 'Grünendeich',
    countrycode: 'DE',
  },
  {
    postalcode: '21720',
    city: 'Guderhandviertel',
    countrycode: 'DE',
  },
  {
    postalcode: '21720',
    city: 'Mittelnkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '21720',
    city: 'Steinkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '21723',
    city: 'Hollern-Twielenfleth',
    countrycode: 'DE',
  },
  {
    postalcode: '21726',
    city: 'Heinbockel',
    countrycode: 'DE',
  },
  {
    postalcode: '21726',
    city: 'Kranenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21726',
    city: 'Oldendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21727',
    city: 'Estorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21729',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '21730',
    city: 'Balje',
    countrycode: 'DE',
  },
  {
    postalcode: '21732',
    city: 'Krummendeich',
    countrycode: 'DE',
  },
  {
    postalcode: '21734',
    city: 'Breitendeich',
    countrycode: 'DE',
  },
  {
    postalcode: '21734',
    city: 'Eggerkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '21734',
    city: 'Hollerdeich',
    countrycode: 'DE',
  },
  {
    postalcode: '21734',
    city: 'Oederquart',
    countrycode: 'DE',
  },
  {
    postalcode: '21734',
    city: 'Zehntweg',
    countrycode: 'DE',
  },
  {
    postalcode: '21737',
    city: 'Wischhafen',
    countrycode: 'DE',
  },
  {
    postalcode: '21739',
    city: 'Dollern',
    countrycode: 'DE',
  },
  {
    postalcode: '21745',
    city: 'Hemmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '21755',
    city: 'Hechthausen',
    countrycode: 'DE',
  },
  {
    postalcode: '21756',
    city: 'Osten',
    countrycode: 'DE',
  },
  {
    postalcode: '21762',
    city: 'Osterbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '21762',
    city: 'Otterndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21763',
    city: 'Neuenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '21765',
    city: 'Nordleda',
    countrycode: 'DE',
  },
  {
    postalcode: '21769',
    city: 'Armstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21769',
    city: 'Hollnseth',
    countrycode: 'DE',
  },
  {
    postalcode: '21769',
    city: 'Lamstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21770',
    city: 'Mittelstenahe',
    countrycode: 'DE',
  },
  {
    postalcode: '21772',
    city: 'Stinstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '21775',
    city: 'Ihlienworth',
    countrycode: 'DE',
  },
  {
    postalcode: '21775',
    city: 'Odisheim',
    countrycode: 'DE',
  },
  {
    postalcode: '21775',
    city: 'Steinau',
    countrycode: 'DE',
  },
  {
    postalcode: '21776',
    city: 'Wanna',
    countrycode: 'DE',
  },
  {
    postalcode: '21781',
    city: 'Cadenberge',
    countrycode: 'DE',
  },
  {
    postalcode: '21782',
    city: 'Bülkau',
    countrycode: 'DE',
  },
  {
    postalcode: '21784',
    city: 'Geversdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21785',
    city: 'Belum',
    countrycode: 'DE',
  },
  {
    postalcode: '21785',
    city: 'Neuhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '21787',
    city: 'Oberndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '21789',
    city: 'Wingst',
    countrycode: 'DE',
  },
  {
    postalcode: '22041',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22043',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22045',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22047',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22049',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22081',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22083',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22085',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22087',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22089',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22111',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22113',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22113',
    city: 'Oststeinbek',
    countrycode: 'DE',
  },
  {
    postalcode: '22115',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22117',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22119',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22143',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22145',
    city: 'Braak',
    countrycode: 'DE',
  },
  {
    postalcode: '22145',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22145',
    city: 'Stapelfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '22147',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22149',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22159',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22175',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22177',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22179',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22297',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22299',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22301',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22303',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22305',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22307',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22309',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22335',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22337',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22339',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22359',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22391',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22393',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22395',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22397',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22399',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22415',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22417',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22419',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22453',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22455',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22457',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22459',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22523',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22525',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22527',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22529',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22547',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22549',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22559',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22587',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22589',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22605',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22607',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22609',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22761',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22763',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22765',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22767',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22769',
    city: 'Hamburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22844',
    city: 'Norderstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '22846',
    city: 'Norderstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '22848',
    city: 'Norderstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '22850',
    city: 'Norderstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '22851',
    city: 'Norderstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '22869',
    city: 'Schenefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '22880',
    city: 'Wedel',
    countrycode: 'DE',
  },
  {
    postalcode: '22885',
    city: 'Barsbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '22889',
    city: 'Tangstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '22926',
    city: 'Ahrensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22927',
    city: 'Großhansdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '22929',
    city: 'Hamfelde, Kr Hzgt Lauenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22929',
    city: 'Hamfelde, Kr Stormarn',
    countrycode: 'DE',
  },
  {
    postalcode: '22929',
    city: 'Kasseburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22929',
    city: 'Köthel, Kr Hzgt Lauenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22929',
    city: 'Köthel, Kr Stormarn',
    countrycode: 'DE',
  },
  {
    postalcode: '22929',
    city: 'Rausdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '22929',
    city: 'Schönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '22941',
    city: 'Bargteheide',
    countrycode: 'DE',
  },
  {
    postalcode: '22941',
    city: 'Delingsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '22941',
    city: 'Hammoor',
    countrycode: 'DE',
  },
  {
    postalcode: '22941',
    city: 'Jersbek',
    countrycode: 'DE',
  },
  {
    postalcode: '22946',
    city: 'Brunsbek',
    countrycode: 'DE',
  },
  {
    postalcode: '22946',
    city: 'Dahmker',
    countrycode: 'DE',
  },
  {
    postalcode: '22946',
    city: 'Grande',
    countrycode: 'DE',
  },
  {
    postalcode: '22946',
    city: 'Großensee',
    countrycode: 'DE',
  },
  {
    postalcode: '22946',
    city: 'Hohenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '22946',
    city: 'Trittau',
    countrycode: 'DE',
  },
  {
    postalcode: '22949',
    city: 'Ammersbek',
    countrycode: 'DE',
  },
  {
    postalcode: '22952',
    city: 'Lütjensee',
    countrycode: 'DE',
  },
  {
    postalcode: '22955',
    city: 'Hoisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '22956',
    city: 'Grönwohld',
    countrycode: 'DE',
  },
  {
    postalcode: '22958',
    city: 'Kuddewörde',
    countrycode: 'DE',
  },
  {
    postalcode: '22959',
    city: 'Linau',
    countrycode: 'DE',
  },
  {
    postalcode: '22961',
    city: 'Oetjendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '22962',
    city: 'Siek',
    countrycode: 'DE',
  },
  {
    postalcode: '22964',
    city: 'Steinburg',
    countrycode: 'DE',
  },
  {
    postalcode: '22965',
    city: 'Todendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '22967',
    city: 'Tremsbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '22969',
    city: 'Witzhave',
    countrycode: 'DE',
  },
  {
    postalcode: '23552',
    city: 'Lübeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23554',
    city: 'Lübeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23556',
    city: 'Lübeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23558',
    city: 'Lübeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23560',
    city: 'Lübeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23562',
    city: 'Lübeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23564',
    city: 'Lübeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23566',
    city: 'Lübeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23568',
    city: 'Lübeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23569',
    city: 'Lübeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23570',
    city: 'Lübeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23611',
    city: 'Bad Schwartau',
    countrycode: 'DE',
  },
  {
    postalcode: '23611',
    city: 'Sereetz',
    countrycode: 'DE',
  },
  {
    postalcode: '23611',
    city: 'Sereetzerfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '23611',
    city: 'Sielbek',
    countrycode: 'DE',
  },
  {
    postalcode: '23617',
    city: 'Stockelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23619',
    city: 'Badendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23619',
    city: 'Goldenerhahn',
    countrycode: 'DE',
  },
  {
    postalcode: '23619',
    city: 'Hamberge',
    countrycode: 'DE',
  },
  {
    postalcode: '23619',
    city: 'Heilshoop',
    countrycode: 'DE',
  },
  {
    postalcode: '23619',
    city: 'Herrenbranden',
    countrycode: 'DE',
  },
  {
    postalcode: '23619',
    city: 'Mönkhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23619',
    city: 'Rehhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '23619',
    city: 'Zarpen',
    countrycode: 'DE',
  },
  {
    postalcode: '23623',
    city: 'Ahrensbök',
    countrycode: 'DE',
  },
  {
    postalcode: '23623',
    city: 'Jeshop',
    countrycode: 'DE',
  },
  {
    postalcode: '23626',
    city: 'Ratekau',
    countrycode: 'DE',
  },
  {
    postalcode: '23627',
    city: 'Groß Grönau',
    countrycode: 'DE',
  },
  {
    postalcode: '23627',
    city: 'Groß Sarau',
    countrycode: 'DE',
  },
  {
    postalcode: '23627',
    city: 'Klein Grönau',
    countrycode: 'DE',
  },
  {
    postalcode: '23628',
    city: 'Klempau',
    countrycode: 'DE',
  },
  {
    postalcode: '23628',
    city: 'Krummesse',
    countrycode: 'DE',
  },
  {
    postalcode: '23629',
    city: 'Sarkwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '23669',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '23669',
    city: 'Oeverdiek',
    countrycode: 'DE',
  },
  {
    postalcode: '23669',
    city: 'Timmendorfer Strand',
    countrycode: 'DE',
  },
  {
    postalcode: '23683',
    city: 'Scharbeutz',
    countrycode: 'DE',
  },
  {
    postalcode: '23684',
    city: 'Scharbeutz',
    countrycode: 'DE',
  },
  {
    postalcode: '23689',
    city: 'Hobbersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23689',
    city: 'Luschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23689',
    city: 'Luschendorferhof',
    countrycode: 'DE',
  },
  {
    postalcode: '23689',
    city: 'Pansdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23689',
    city: 'Rohlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23689',
    city: 'Rohlsdorferbeek',
    countrycode: 'DE',
  },
  {
    postalcode: '23689',
    city: 'Techau',
    countrycode: 'DE',
  },
  {
    postalcode: '23701',
    city: 'Eutin',
    countrycode: 'DE',
  },
  {
    postalcode: '23701',
    city: 'Süsel',
    countrycode: 'DE',
  },
  {
    postalcode: '23714',
    city: 'Kirchnüchel',
    countrycode: 'DE',
  },
  {
    postalcode: '23714',
    city: 'Malente',
    countrycode: 'DE',
  },
  {
    postalcode: '23715',
    city: 'Bosau',
    countrycode: 'DE',
  },
  {
    postalcode: '23717',
    city: 'Bökensberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23717',
    city: 'Kasseedorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23719',
    city: 'Glasau',
    countrycode: 'DE',
  },
  {
    postalcode: '23730',
    city: 'Altenkrempe',
    countrycode: 'DE',
  },
  {
    postalcode: '23730',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '23730',
    city: 'Schashagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23730',
    city: 'Sierksdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23738',
    city: 'Beschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23738',
    city: 'Damlos',
    countrycode: 'DE',
  },
  {
    postalcode: '23738',
    city: 'Harmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23738',
    city: 'Kabelhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '23738',
    city: 'Lensahn',
    countrycode: 'DE',
  },
  {
    postalcode: '23738',
    city: 'Manhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23738',
    city: 'Riepsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23743',
    city: 'Grömitz',
    countrycode: 'DE',
  },
  {
    postalcode: '23743',
    city: 'Morest',
    countrycode: 'DE',
  },
  {
    postalcode: '23744',
    city: 'Schönwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '23746',
    city: 'Kellenhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '23746',
    city: 'Rittbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '23747',
    city: 'Dahme',
    countrycode: 'DE',
  },
  {
    postalcode: '23747',
    city: 'Gruberhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23749',
    city: 'Grube',
    countrycode: 'DE',
  },
  {
    postalcode: '23758',
    city: 'Gremersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23758',
    city: 'Göhl',
    countrycode: 'DE',
  },
  {
    postalcode: '23758',
    city: 'Oldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '23758',
    city: 'Wangels',
    countrycode: 'DE',
  },
  {
    postalcode: '23769',
    city: 'Bannesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23769',
    city: 'Burg',
    countrycode: 'DE',
  },
  {
    postalcode: '23769',
    city: 'Landkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '23769',
    city: 'Puttgarden',
    countrycode: 'DE',
  },
  {
    postalcode: '23769',
    city: 'Westfehmarn',
    countrycode: 'DE',
  },
  {
    postalcode: '23774',
    city: 'Heiligenhafen',
    countrycode: 'DE',
  },
  {
    postalcode: '23775',
    city: 'Großenbrode',
    countrycode: 'DE',
  },
  {
    postalcode: '23777',
    city: 'Heringsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23777',
    city: 'Wulfshof',
    countrycode: 'DE',
  },
  {
    postalcode: '23779',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '23779',
    city: 'Neuseegalendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Bad Segeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Fahrenkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Groß Rönnau',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Högersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Klein Gladebrügge',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Klein Rönnau',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Mözen',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Negernbötel',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Schackendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Schieren',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Schwissel',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Stipsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Traventhal',
    countrycode: 'DE',
  },
  {
    postalcode: '23795',
    city: 'Weede',
    countrycode: 'DE',
  },
  {
    postalcode: '23812',
    city: 'Glashütte',
    countrycode: 'DE',
  },
  {
    postalcode: '23812',
    city: 'Wahlstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '23813',
    city: 'Blunk',
    countrycode: 'DE',
  },
  {
    postalcode: '23813',
    city: 'Nehms',
    countrycode: 'DE',
  },
  {
    postalcode: '23815',
    city: 'Geschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23815',
    city: 'Strukdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23815',
    city: 'Westerrade',
    countrycode: 'DE',
  },
  {
    postalcode: '23816',
    city: 'Bebensee',
    countrycode: 'DE',
  },
  {
    postalcode: '23816',
    city: 'Groß Niendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23816',
    city: 'Leezen',
    countrycode: 'DE',
  },
  {
    postalcode: '23816',
    city: 'Neversdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23818',
    city: 'Neuengörs',
    countrycode: 'DE',
  },
  {
    postalcode: '23820',
    city: 'Pronstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23821',
    city: 'Rohlstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23823',
    city: 'Seedorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23824',
    city: 'Damsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23824',
    city: 'Tensfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '23826',
    city: 'Bark',
    countrycode: 'DE',
  },
  {
    postalcode: '23826',
    city: 'Fredesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23826',
    city: 'Todesfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '23827',
    city: 'Krems II',
    countrycode: 'DE',
  },
  {
    postalcode: '23827',
    city: 'Travenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '23827',
    city: 'Wensin',
    countrycode: 'DE',
  },
  {
    postalcode: '23829',
    city: 'Kükels',
    countrycode: 'DE',
  },
  {
    postalcode: '23829',
    city: 'Wittenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '23843',
    city: 'Bad Oldesloe',
    countrycode: 'DE',
  },
  {
    postalcode: '23843',
    city: 'Neritz',
    countrycode: 'DE',
  },
  {
    postalcode: '23843',
    city: 'Rümpel',
    countrycode: 'DE',
  },
  {
    postalcode: '23843',
    city: 'Travenbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '23845',
    city: 'Bahrenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '23845',
    city: 'Borstel',
    countrycode: 'DE',
  },
  {
    postalcode: '23845',
    city: 'Bühnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23845',
    city: 'Dreggers',
    countrycode: 'DE',
  },
  {
    postalcode: '23845',
    city: 'Grabau',
    countrycode: 'DE',
  },
  {
    postalcode: '23845',
    city: 'Itzstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '23845',
    city: 'Oering',
    countrycode: 'DE',
  },
  {
    postalcode: '23845',
    city: 'Seth',
    countrycode: 'DE',
  },
  {
    postalcode: '23845',
    city: 'Wakendorf I',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Bliestorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Düchelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Grinau',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Groß Boden',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Kastorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Lasbek',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Meddewade',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Pölitz',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Rethwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Schiphorst',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Schürensöhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Siebenbäumen',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Sierksrade',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Steinhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Stubben',
    countrycode: 'DE',
  },
  {
    postalcode: '23847',
    city: 'Westerau',
    countrycode: 'DE',
  },
  {
    postalcode: '23858',
    city: 'Barnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '23858',
    city: 'Feldhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '23858',
    city: 'Heidekamp',
    countrycode: 'DE',
  },
  {
    postalcode: '23858',
    city: 'Reinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '23858',
    city: 'Wesenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23860',
    city: 'Groß Schenkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23860',
    city: 'Klein Wesenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23863',
    city: 'Bargfeld-Stegen',
    countrycode: 'DE',
  },
  {
    postalcode: '23863',
    city: 'Kayhude',
    countrycode: 'DE',
  },
  {
    postalcode: '23863',
    city: 'Nienwohld',
    countrycode: 'DE',
  },
  {
    postalcode: '23866',
    city: 'Nahe',
    countrycode: 'DE',
  },
  {
    postalcode: '23867',
    city: 'Sülfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '23869',
    city: 'Elmenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '23879',
    city: 'Drüsen',
    countrycode: 'DE',
  },
  {
    postalcode: '23879',
    city: 'Mölln',
    countrycode: 'DE',
  },
  {
    postalcode: '23881',
    city: 'Alt Mölln',
    countrycode: 'DE',
  },
  {
    postalcode: '23881',
    city: 'Borstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23881',
    city: 'Breitenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '23881',
    city: 'Bälau',
    countrycode: 'DE',
  },
  {
    postalcode: '23881',
    city: 'Koberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23881',
    city: 'Lankau',
    countrycode: 'DE',
  },
  {
    postalcode: '23881',
    city: 'Niendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Bresahn',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Brunsmark',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Dargow',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Grambek',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Hollenbek',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Horst',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Klein Zecher',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Lehmrade',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Marienstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Seedorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Sterley',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Sterleyerheide',
    countrycode: 'DE',
  },
  {
    postalcode: '23883',
    city: 'Torfkaten',
    countrycode: 'DE',
  },
  {
    postalcode: '23896',
    city: 'Nusse',
    countrycode: 'DE',
  },
  {
    postalcode: '23896',
    city: 'Panten',
    countrycode: 'DE',
  },
  {
    postalcode: '23896',
    city: 'Poggensee',
    countrycode: 'DE',
  },
  {
    postalcode: '23896',
    city: 'Ritzerau',
    countrycode: 'DE',
  },
  {
    postalcode: '23896',
    city: 'Walksfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '23898',
    city: 'Duvensee',
    countrycode: 'DE',
  },
  {
    postalcode: '23898',
    city: 'Klinkrade',
    countrycode: 'DE',
  },
  {
    postalcode: '23898',
    city: 'Kühsen',
    countrycode: 'DE',
  },
  {
    postalcode: '23898',
    city: 'Labenz',
    countrycode: 'DE',
  },
  {
    postalcode: '23898',
    city: 'Lüchow',
    countrycode: 'DE',
  },
  {
    postalcode: '23898',
    city: 'Sandesneben',
    countrycode: 'DE',
  },
  {
    postalcode: '23898',
    city: 'Sirksfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '23898',
    city: 'Wentorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23899',
    city: 'Besenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '23899',
    city: 'Gudow',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Albsfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Bäk',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Farchau',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Farchauermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Fredeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Giesensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Hundebusch',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Mechow',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Ratzeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Römnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Sande',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Söhren',
    countrycode: 'DE',
  },
  {
    postalcode: '23909',
    city: 'Weißer Hirsch',
    countrycode: 'DE',
  },
  {
    postalcode: '23911',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '23911',
    city: 'Einhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '23911',
    city: 'Groß Disnack',
    countrycode: 'DE',
  },
  {
    postalcode: '23911',
    city: 'Harmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23911',
    city: 'Kittlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '23911',
    city: 'Kulpin',
    countrycode: 'DE',
  },
  {
    postalcode: '23911',
    city: 'Mustin',
    countrycode: 'DE',
  },
  {
    postalcode: '23911',
    city: 'Pogeez',
    countrycode: 'DE',
  },
  {
    postalcode: '23911',
    city: 'Salem',
    countrycode: 'DE',
  },
  {
    postalcode: '23911',
    city: 'Schmilau',
    countrycode: 'DE',
  },
  {
    postalcode: '23911',
    city: 'Ziethen',
    countrycode: 'DE',
  },
  {
    postalcode: '23919',
    city: 'Behlendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23919',
    city: 'Berkenthin',
    countrycode: 'DE',
  },
  {
    postalcode: '23919',
    city: 'Fliegenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23919',
    city: 'Göldenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '23919',
    city: 'Niendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23919',
    city: 'Rondeshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Bechelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Blüssen',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Boitin-Resdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Dorf Wahrsow',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Duvennest',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Eulenkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Groß Bünsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Groß Neuleben',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Groß Siemz',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Herrnburg',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Hof Selmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Hof Wahrsow',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Klein Bünsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Klein Neuleben',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Klein Siemz',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Kleinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Lauen',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Lindow',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Lockwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Lüdersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Malzow',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Menzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Niendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Ollndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Palingen',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Petersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Retelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Roduchelstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Rupensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Sabow',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Schattin',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Schönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Selmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Sülsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Teschow',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Torisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Törpt',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Wahrsow',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Westerbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23923',
    city: 'Zarnewenz',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Barendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Bernstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Blieschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Boienhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Bonnhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Börzow',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Bössow',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Büttlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Diedrichshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Everstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Friedrichshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Fräulein-Steinfort',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Gostorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Grenzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Greschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Grevenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Grevesmühlen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Grieben',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Groß Pravtshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Hamberge',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Hanshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Hanstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Harmshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Hilgendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Hof Mummendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Hoikendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Jeese',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Kastahn',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Kirch-Mummendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Mallentin',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Meierstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Naschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Neu Degtow',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Papenhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Pieverstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Plüschow',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Pohnstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Questin',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Rankendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Rodenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Roggenstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Roxin',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Rüting',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Rüting Steinfort',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Santow',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Schildberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Schmachthagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Schönhof',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Seefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Sievershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Strohkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Teschow',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Testorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Testorf-Steinfort',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Thorstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Tramm',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Upahl',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Volkenshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Waldeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Warnow',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Wendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Wilkenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Wotenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Wölschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Wüstenmark',
    countrycode: 'DE',
  },
  {
    postalcode: '23936',
    city: 'Zehmen',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Benckendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Dassow',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Dönkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Feldhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Groß Schwansee',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Harkensee',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Johannstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Kalkhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Klein Schwansee',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Neuenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Pötenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Rosenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23942',
    city: 'Volkstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23946',
    city: 'Ostseebad Boltenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23946',
    city: 'Ostseebad Boltenhagen Zeltplatz',
    countrycode: 'DE',
  },
  {
    postalcode: '23946',
    city: 'Redewisch',
    countrycode: 'DE',
  },
  {
    postalcode: '23946',
    city: 'Redewisch Fischerhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '23946',
    city: 'Tarnewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '23946',
    city: 'Tarnewitzerhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23946',
    city: 'Wichmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Arpshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Brook',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Christinenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Damshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Dorf Gutow',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Dorf Reppenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Elmenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Eulenkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Gantenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Goldbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Groß Walmstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Großenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Grundshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Hof Gutow',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Hof Reppenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Hofzumfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Hohen Schönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Klein Pravtshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Klütz',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Kussow',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Kühlenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Moor',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Nedderhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Niederklütz',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Niendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Oberhof',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Oberklütz',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Parin',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Rolofshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Steinbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Stellshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Wahrstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Warnkenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Welzin',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Wohlenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23948',
    city: 'Wohlenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Groß Krankow',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Karow',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Kletzin',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Moidentin',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Neu Stieten',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Olgashof',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Petersdorf bei Wismar, Meckl',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Rambow',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Rosenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Steffin',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Triwalk',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Wietow',
    countrycode: 'DE',
  },
  {
    postalcode: '23966',
    city: 'Wismar',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Barnekow',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Gramkow',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Gressow',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Gägelow',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Jamel',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Neu Weitendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Proseken',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Stofferstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Voßkuhl',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Weitendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Wismar',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Wolde',
    countrycode: 'DE',
  },
  {
    postalcode: '23968',
    city: 'Zierow',
    countrycode: 'DE',
  },
  {
    postalcode: '23970',
    city: 'Benz',
    countrycode: 'DE',
  },
  {
    postalcode: '23970',
    city: 'Greese',
    countrycode: 'DE',
  },
  {
    postalcode: '23970',
    city: 'Kritzow',
    countrycode: 'DE',
  },
  {
    postalcode: '23970',
    city: 'Levetzow',
    countrycode: 'DE',
  },
  {
    postalcode: '23970',
    city: 'Rüggow',
    countrycode: 'DE',
  },
  {
    postalcode: '23970',
    city: 'Wismar',
    countrycode: 'DE',
  },
  {
    postalcode: '23972',
    city: 'Dorf Mecklenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '23972',
    city: 'Groß Stieten',
    countrycode: 'DE',
  },
  {
    postalcode: '23972',
    city: 'Klein Stieten',
    countrycode: 'DE',
  },
  {
    postalcode: '23972',
    city: 'Klüssendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23972',
    city: 'Lübow',
    countrycode: 'DE',
  },
  {
    postalcode: '23972',
    city: 'Martensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23972',
    city: 'Maßlow',
    countrycode: 'DE',
  },
  {
    postalcode: '23972',
    city: 'Metelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23972',
    city: 'Schimm',
    countrycode: 'DE',
  },
  {
    postalcode: '23972',
    city: 'Schulenbrook',
    countrycode: 'DE',
  },
  {
    postalcode: '23972',
    city: 'Tarzow',
    countrycode: 'DE',
  },
  {
    postalcode: '23974',
    city: 'Blowatz',
    countrycode: 'DE',
  },
  {
    postalcode: '23974',
    city: 'Boiensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23974',
    city: 'Gagzow',
    countrycode: 'DE',
  },
  {
    postalcode: '23974',
    city: 'Hagebök',
    countrycode: 'DE',
  },
  {
    postalcode: '23974',
    city: 'Hof Redentin',
    countrycode: 'DE',
  },
  {
    postalcode: '23974',
    city: 'Hornstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23974',
    city: 'Krusenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23974',
    city: 'Mückenfang',
    countrycode: 'DE',
  },
  {
    postalcode: '23974',
    city: 'Neuburg-Steinhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '23974',
    city: 'Rohlstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Alt Poorstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Babst',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Bäbelin',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Charlottenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Fahren',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Glasin',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Goldberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Groß Tessin',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Höltingsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Kahlenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Klein Warin',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Klein Warin Lager',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Krassow',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Langenstück',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Lübberstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Lüdersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Nakenstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Neu Babst',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Neu Poorstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Neukloster',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Neumühle',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Nevern',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Passee',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Perniek',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Pinnowhof',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Poischendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Poischendorf Molkerei',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Ravensruh',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Reinstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Rügkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Schmakentin',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Sellin',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Strameuß',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Teplitz',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Tollow',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Tüzen',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Wakendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Warnkenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Zurow',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Zweihausen',
    countrycode: 'DE',
  },
  {
    postalcode: '23992',
    city: 'Züsow',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Bad Kleinen',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Beidendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Bobitz',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Dalliendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Dambeck',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Fichtenhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Gallentin',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Glashagen',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Grapen-Stieten',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Hohen Viecheln',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Hoppenrade',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Hädchenshof',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Losten',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Lutterstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Moltow',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Naudin',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Neu Saunstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Neu Viecheln',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Niendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Rastorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Saunstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Scharfstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '23996',
    city: 'Wendisch Rambow',
    countrycode: 'DE',
  },
  {
    postalcode: '23999',
    city: 'Insel Poel',
    countrycode: 'DE',
  },
  {
    postalcode: '24103',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24105',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24106',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24107',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24107',
    city: 'Ottendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24107',
    city: 'Quarnbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24109',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24109',
    city: 'Melsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24111',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24113',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24113',
    city: 'Molfsee',
    countrycode: 'DE',
  },
  {
    postalcode: '24114',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24116',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24118',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24119',
    city: 'Kronshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '24143',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24145',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24146',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24147',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24147',
    city: 'Klausdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24148',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24149',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24159',
    city: 'Kiel',
    countrycode: 'DE',
  },
  {
    postalcode: '24161',
    city: 'Altenholz',
    countrycode: 'DE',
  },
  {
    postalcode: '24161',
    city: 'Levensau',
    countrycode: 'DE',
  },
  {
    postalcode: '24161',
    city: 'Rathmannsdorf, Gut',
    countrycode: 'DE',
  },
  {
    postalcode: '24161',
    city: 'Rathmannsdorf, Hof',
    countrycode: 'DE',
  },
  {
    postalcode: '24161',
    city: 'Rathmannsdorferschleuse',
    countrycode: 'DE',
  },
  {
    postalcode: '24211',
    city: 'Honigsee',
    countrycode: 'DE',
  },
  {
    postalcode: '24211',
    city: 'Kahlkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '24211',
    city: 'Kühren',
    countrycode: 'DE',
  },
  {
    postalcode: '24211',
    city: 'Lehmkuhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '24211',
    city: 'Pohnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24211',
    city: 'Postfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24211',
    city: 'Preetz',
    countrycode: 'DE',
  },
  {
    postalcode: '24211',
    city: 'Rastorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24211',
    city: 'Schellhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '24211',
    city: 'Wahlstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Altwittenbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Fahrenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Gettorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Großkönigsförde',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Großkönigsförderwohld',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Langenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Lindau',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Neudorf-Bornstein',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Neuwittenbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Noer',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Scharfenholz',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Schinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Tüttendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24214',
    city: 'Waterdiek',
    countrycode: 'DE',
  },
  {
    postalcode: '24217',
    city: 'Barsbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24217',
    city: 'Bendfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24217',
    city: 'Fiefbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '24217',
    city: 'Höhndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24217',
    city: 'Krokau',
    countrycode: 'DE',
  },
  {
    postalcode: '24217',
    city: 'Krummbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24217',
    city: 'Rögen',
    countrycode: 'DE',
  },
  {
    postalcode: '24217',
    city: 'Schönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24217',
    city: 'Stakendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24217',
    city: 'Wisch',
    countrycode: 'DE',
  },
  {
    postalcode: '24220',
    city: 'Boksee',
    countrycode: 'DE',
  },
  {
    postalcode: '24220',
    city: 'Böhnhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '24220',
    city: 'Flintbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24220',
    city: 'Schönhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '24220',
    city: 'Techelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24223',
    city: 'Neuwühren',
    countrycode: 'DE',
  },
  {
    postalcode: '24223',
    city: 'Raisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24226',
    city: 'Heikendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24229',
    city: 'Dänischenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '24229',
    city: 'Schwedeneck',
    countrycode: 'DE',
  },
  {
    postalcode: '24229',
    city: 'Strande',
    countrycode: 'DE',
  },
  {
    postalcode: '24232',
    city: 'Dobersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24232',
    city: 'Kählen',
    countrycode: 'DE',
  },
  {
    postalcode: '24232',
    city: 'Schönkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '24235',
    city: 'Brodersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24235',
    city: 'Laboe',
    countrycode: 'DE',
  },
  {
    postalcode: '24235',
    city: 'Lutterbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24235',
    city: 'Stein',
    countrycode: 'DE',
  },
  {
    postalcode: '24235',
    city: 'Wendtorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24238',
    city: 'Lammershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '24238',
    city: 'Martensrade',
    countrycode: 'DE',
  },
  {
    postalcode: '24238',
    city: 'Mucheln',
    countrycode: 'DE',
  },
  {
    postalcode: '24238',
    city: 'Selent',
    countrycode: 'DE',
  },
  {
    postalcode: '24239',
    city: 'Achterwehr',
    countrycode: 'DE',
  },
  {
    postalcode: '24241',
    city: 'Blumenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '24241',
    city: 'Grevenkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '24241',
    city: 'Reesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24241',
    city: 'Schierensee',
    countrycode: 'DE',
  },
  {
    postalcode: '24241',
    city: 'Schmalstede',
    countrycode: 'DE',
  },
  {
    postalcode: '24241',
    city: 'Sören',
    countrycode: 'DE',
  },
  {
    postalcode: '24242',
    city: 'Felde',
    countrycode: 'DE',
  },
  {
    postalcode: '24244',
    city: 'Felm',
    countrycode: 'DE',
  },
  {
    postalcode: '24245',
    city: 'Barmissen',
    countrycode: 'DE',
  },
  {
    postalcode: '24245',
    city: 'Großbarkau',
    countrycode: 'DE',
  },
  {
    postalcode: '24245',
    city: 'Kirchbarkau',
    countrycode: 'DE',
  },
  {
    postalcode: '24245',
    city: 'Klein Barkau',
    countrycode: 'DE',
  },
  {
    postalcode: '24247',
    city: 'Mielkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24247',
    city: 'Rodenbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24248',
    city: 'Mönkeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24250',
    city: 'Bothkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '24250',
    city: 'Löptin',
    countrycode: 'DE',
  },
  {
    postalcode: '24250',
    city: 'Nettelsee',
    countrycode: 'DE',
  },
  {
    postalcode: '24250',
    city: 'Warnau',
    countrycode: 'DE',
  },
  {
    postalcode: '24251',
    city: 'Osdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24253',
    city: 'Fahren',
    countrycode: 'DE',
  },
  {
    postalcode: '24253',
    city: 'Passade',
    countrycode: 'DE',
  },
  {
    postalcode: '24253',
    city: 'Prasdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24253',
    city: 'Probsteierhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '24254',
    city: 'Rumohr',
    countrycode: 'DE',
  },
  {
    postalcode: '24256',
    city: 'Fargau-Pratjau',
    countrycode: 'DE',
  },
  {
    postalcode: '24256',
    city: 'Schlesen',
    countrycode: 'DE',
  },
  {
    postalcode: '24256',
    city: 'Stoltenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24257',
    city: 'Hohenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '24257',
    city: 'Köhn',
    countrycode: 'DE',
  },
  {
    postalcode: '24257',
    city: 'Köhnerbrücke',
    countrycode: 'DE',
  },
  {
    postalcode: '24257',
    city: 'Schwartbuck',
    countrycode: 'DE',
  },
  {
    postalcode: '24259',
    city: 'Westensee',
    countrycode: 'DE',
  },
  {
    postalcode: '24306',
    city: 'Bösdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24306',
    city: 'Hinterste Wache',
    countrycode: 'DE',
  },
  {
    postalcode: '24306',
    city: 'Lebrade',
    countrycode: 'DE',
  },
  {
    postalcode: '24306',
    city: 'Plön',
    countrycode: 'DE',
  },
  {
    postalcode: '24306',
    city: 'Rathjensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24306',
    city: 'Waldshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '24306',
    city: 'Wittmoldt',
    countrycode: 'DE',
  },
  {
    postalcode: '24321',
    city: 'Behrensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24321',
    city: 'Giekau',
    countrycode: 'DE',
  },
  {
    postalcode: '24321',
    city: 'Helmstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24321',
    city: 'Hohwacht',
    countrycode: 'DE',
  },
  {
    postalcode: '24321',
    city: 'Klamp',
    countrycode: 'DE',
  },
  {
    postalcode: '24321',
    city: 'Lütjenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '24321',
    city: 'Panker',
    countrycode: 'DE',
  },
  {
    postalcode: '24321',
    city: 'Rantzauer Papiermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '24321',
    city: 'Tivoli',
    countrycode: 'DE',
  },
  {
    postalcode: '24321',
    city: 'Tröndel',
    countrycode: 'DE',
  },
  {
    postalcode: '24326',
    city: 'Ascheberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24326',
    city: 'Dersau',
    countrycode: 'DE',
  },
  {
    postalcode: '24326',
    city: 'Dörnick',
    countrycode: 'DE',
  },
  {
    postalcode: '24326',
    city: 'Kalübbe',
    countrycode: 'DE',
  },
  {
    postalcode: '24326',
    city: 'Nehmten',
    countrycode: 'DE',
  },
  {
    postalcode: '24326',
    city: 'Stadtbek, Gem Bosau',
    countrycode: 'DE',
  },
  {
    postalcode: '24326',
    city: 'Stadtbek, Gem Seedorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24326',
    city: 'Stadtbekermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '24326',
    city: 'Stocksee',
    countrycode: 'DE',
  },
  {
    postalcode: '24327',
    city: 'Blekendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24327',
    city: 'Grimmelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24327',
    city: 'Högsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24327',
    city: 'Kletkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '24329',
    city: 'Dannau',
    countrycode: 'DE',
  },
  {
    postalcode: '24329',
    city: 'Grebin',
    countrycode: 'DE',
  },
  {
    postalcode: '24329',
    city: 'Rantzau',
    countrycode: 'DE',
  },
  {
    postalcode: '24340',
    city: 'Altenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '24340',
    city: 'Eckernförde',
    countrycode: 'DE',
  },
  {
    postalcode: '24340',
    city: 'Gammelby',
    countrycode: 'DE',
  },
  {
    postalcode: '24340',
    city: 'Goosefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24340',
    city: 'Louisenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24340',
    city: 'Windeby',
    countrycode: 'DE',
  },
  {
    postalcode: '24351',
    city: 'Damp',
    countrycode: 'DE',
  },
  {
    postalcode: '24351',
    city: 'Thumby',
    countrycode: 'DE',
  },
  {
    postalcode: '24354',
    city: 'Bösby',
    countrycode: 'DE',
  },
  {
    postalcode: '24354',
    city: 'Bösbyfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24354',
    city: 'Kosel',
    countrycode: 'DE',
  },
  {
    postalcode: '24354',
    city: 'Pukholt',
    countrycode: 'DE',
  },
  {
    postalcode: '24354',
    city: 'Rieseby',
    countrycode: 'DE',
  },
  {
    postalcode: '24357',
    city: 'Fleckeby',
    countrycode: 'DE',
  },
  {
    postalcode: '24357',
    city: 'Güby',
    countrycode: 'DE',
  },
  {
    postalcode: '24357',
    city: 'Hummelfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24357',
    city: 'Krummland',
    countrycode: 'DE',
  },
  {
    postalcode: '24358',
    city: 'Ascheffel',
    countrycode: 'DE',
  },
  {
    postalcode: '24358',
    city: 'Bistensee',
    countrycode: 'DE',
  },
  {
    postalcode: '24358',
    city: 'Fresenboje',
    countrycode: 'DE',
  },
  {
    postalcode: '24358',
    city: 'Hütten',
    countrycode: 'DE',
  },
  {
    postalcode: '24360',
    city: 'Barkelsby',
    countrycode: 'DE',
  },
  {
    postalcode: '24360',
    city: 'Rögen, Gut',
    countrycode: 'DE',
  },
  {
    postalcode: '24361',
    city: 'Damendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24361',
    city: 'Groß Wittensee',
    countrycode: 'DE',
  },
  {
    postalcode: '24361',
    city: 'Haby',
    countrycode: 'DE',
  },
  {
    postalcode: '24361',
    city: 'Holzbunge',
    countrycode: 'DE',
  },
  {
    postalcode: '24361',
    city: 'Klein Wittensee',
    countrycode: 'DE',
  },
  {
    postalcode: '24361',
    city: 'Lehmsiek',
    countrycode: 'DE',
  },
  {
    postalcode: '24361',
    city: 'Profit',
    countrycode: 'DE',
  },
  {
    postalcode: '24361',
    city: 'Sande',
    countrycode: 'DE',
  },
  {
    postalcode: '24363',
    city: 'Holtsee',
    countrycode: 'DE',
  },
  {
    postalcode: '24363',
    city: 'Ropahl',
    countrycode: 'DE',
  },
  {
    postalcode: '24364',
    city: 'Holzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24366',
    city: 'Loose',
    countrycode: 'DE',
  },
  {
    postalcode: '24367',
    city: 'Osterby',
    countrycode: 'DE',
  },
  {
    postalcode: '24367',
    city: 'Suhrbrook',
    countrycode: 'DE',
  },
  {
    postalcode: '24369',
    city: 'Waabs',
    countrycode: 'DE',
  },
  {
    postalcode: '24376',
    city: 'Grödersby',
    countrycode: 'DE',
  },
  {
    postalcode: '24376',
    city: 'Hasselberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24376',
    city: 'Hauheck',
    countrycode: 'DE',
  },
  {
    postalcode: '24376',
    city: 'Kappeln',
    countrycode: 'DE',
  },
  {
    postalcode: '24376',
    city: 'Rabel',
    countrycode: 'DE',
  },
  {
    postalcode: '24376',
    city: 'Wormshöft',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Bicken',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Blick',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Boren',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Brebel',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Dollrottfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Ekenis',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Flarupholz',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Hye',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Kiesby',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Norderbrarup',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Nottfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Saustrup',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Scheggerott',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Süderbrarup',
    countrycode: 'DE',
  },
  {
    postalcode: '24392',
    city: 'Wagersrott',
    countrycode: 'DE',
  },
  {
    postalcode: '24395',
    city: 'Gelting',
    countrycode: 'DE',
  },
  {
    postalcode: '24395',
    city: 'Holzkoppel',
    countrycode: 'DE',
  },
  {
    postalcode: '24395',
    city: 'Kronsgaard',
    countrycode: 'DE',
  },
  {
    postalcode: '24395',
    city: 'Lüchtoft',
    countrycode: 'DE',
  },
  {
    postalcode: '24395',
    city: 'Nieby',
    countrycode: 'DE',
  },
  {
    postalcode: '24395',
    city: 'Niesgrau',
    countrycode: 'DE',
  },
  {
    postalcode: '24395',
    city: 'Pommerby',
    countrycode: 'DE',
  },
  {
    postalcode: '24395',
    city: 'Rabenholz',
    countrycode: 'DE',
  },
  {
    postalcode: '24395',
    city: 'Röhrmoos',
    countrycode: 'DE',
  },
  {
    postalcode: '24395',
    city: 'Stangheck',
    countrycode: 'DE',
  },
  {
    postalcode: '24398',
    city: 'Brodersby',
    countrycode: 'DE',
  },
  {
    postalcode: '24398',
    city: 'Büßholz',
    countrycode: 'DE',
  },
  {
    postalcode: '24398',
    city: 'Dörphof',
    countrycode: 'DE',
  },
  {
    postalcode: '24398',
    city: 'Karby',
    countrycode: 'DE',
  },
  {
    postalcode: '24398',
    city: 'Karlsburgerholz',
    countrycode: 'DE',
  },
  {
    postalcode: '24398',
    city: 'Kniepenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24398',
    city: 'Steinerholz',
    countrycode: 'DE',
  },
  {
    postalcode: '24398',
    city: 'Winnemark',
    countrycode: 'DE',
  },
  {
    postalcode: '24399',
    city: 'Arnis',
    countrycode: 'DE',
  },
  {
    postalcode: '24399',
    city: 'Marienhof',
    countrycode: 'DE',
  },
  {
    postalcode: '24401',
    city: 'Böel',
    countrycode: 'DE',
  },
  {
    postalcode: '24402',
    city: 'Esgrus',
    countrycode: 'DE',
  },
  {
    postalcode: '24402',
    city: 'Schrepperie',
    countrycode: 'DE',
  },
  {
    postalcode: '24404',
    city: 'Maasholm',
    countrycode: 'DE',
  },
  {
    postalcode: '24404',
    city: 'Schleimünde',
    countrycode: 'DE',
  },
  {
    postalcode: '24405',
    city: 'Borrishaag',
    countrycode: 'DE',
  },
  {
    postalcode: '24405',
    city: 'Böelnorderfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24405',
    city: 'Fraulund',
    countrycode: 'DE',
  },
  {
    postalcode: '24405',
    city: 'Mohrkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '24405',
    city: 'Rügge',
    countrycode: 'DE',
  },
  {
    postalcode: '24405',
    city: 'Stoltoft',
    countrycode: 'DE',
  },
  {
    postalcode: '24405',
    city: 'Thiesholz',
    countrycode: 'DE',
  },
  {
    postalcode: '24407',
    city: 'Karschau',
    countrycode: 'DE',
  },
  {
    postalcode: '24407',
    city: 'Kragelund',
    countrycode: 'DE',
  },
  {
    postalcode: '24407',
    city: 'Oersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24407',
    city: 'Rabenkirchen-Faulück',
    countrycode: 'DE',
  },
  {
    postalcode: '24407',
    city: 'Schweltholm',
    countrycode: 'DE',
  },
  {
    postalcode: '24409',
    city: 'Stoltebüll',
    countrycode: 'DE',
  },
  {
    postalcode: '24534',
    city: 'Neumünster',
    countrycode: 'DE',
  },
  {
    postalcode: '24536',
    city: 'Neumünster',
    countrycode: 'DE',
  },
  {
    postalcode: '24536',
    city: 'Tasdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24537',
    city: 'Neumünster',
    countrycode: 'DE',
  },
  {
    postalcode: '24539',
    city: 'Neumünster',
    countrycode: 'DE',
  },
  {
    postalcode: '24558',
    city: 'Henstedt-Ulzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '24558',
    city: 'Wakendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24568',
    city: 'Kaltenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '24568',
    city: 'Kattendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24568',
    city: 'Nützen',
    countrycode: 'DE',
  },
  {
    postalcode: '24568',
    city: 'Oersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24568',
    city: 'Winsen',
    countrycode: 'DE',
  },
  {
    postalcode: '24576',
    city: 'Bad Bramstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24576',
    city: 'Bimöhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '24576',
    city: 'Hagen',
    countrycode: 'DE',
  },
  {
    postalcode: '24576',
    city: 'Hegebuchenbusch',
    countrycode: 'DE',
  },
  {
    postalcode: '24576',
    city: 'Hitzhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '24576',
    city: 'Mönkloh',
    countrycode: 'DE',
  },
  {
    postalcode: '24576',
    city: 'Reesmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '24576',
    city: 'Weddelbrook',
    countrycode: 'DE',
  },
  {
    postalcode: '24582',
    city: 'Bissee',
    countrycode: 'DE',
  },
  {
    postalcode: '24582',
    city: 'Bordesholm',
    countrycode: 'DE',
  },
  {
    postalcode: '24582',
    city: 'Brügge',
    countrycode: 'DE',
  },
  {
    postalcode: '24582',
    city: 'Groß Buchwald',
    countrycode: 'DE',
  },
  {
    postalcode: '24582',
    city: 'Hoffeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24582',
    city: 'Mühbrook',
    countrycode: 'DE',
  },
  {
    postalcode: '24582',
    city: 'Schönbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24582',
    city: 'Wattenbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24589',
    city: 'Borgdorf-Seedorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24589',
    city: 'Dätgen',
    countrycode: 'DE',
  },
  {
    postalcode: '24589',
    city: 'Eisendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24589',
    city: 'Ellerdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24589',
    city: 'Nortorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24589',
    city: 'Schülp',
    countrycode: 'DE',
  },
  {
    postalcode: '24594',
    city: 'Grauel',
    countrycode: 'DE',
  },
  {
    postalcode: '24594',
    city: 'Heinkenborstel',
    countrycode: 'DE',
  },
  {
    postalcode: '24594',
    city: 'Hohenwestedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24594',
    city: 'Jahrsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24594',
    city: 'Meezen',
    countrycode: 'DE',
  },
  {
    postalcode: '24594',
    city: 'Mörel',
    countrycode: 'DE',
  },
  {
    postalcode: '24594',
    city: 'Nindorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24594',
    city: 'Rade',
    countrycode: 'DE',
  },
  {
    postalcode: '24594',
    city: 'Remmels',
    countrycode: 'DE',
  },
  {
    postalcode: '24594',
    city: 'Tappendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24594',
    city: 'Wapelfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24598',
    city: 'Boostedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24598',
    city: 'Heidmühlen',
    countrycode: 'DE',
  },
  {
    postalcode: '24598',
    city: 'Latendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24601',
    city: 'Belau',
    countrycode: 'DE',
  },
  {
    postalcode: '24601',
    city: 'Ruhwinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '24601',
    city: 'Stolpe',
    countrycode: 'DE',
  },
  {
    postalcode: '24601',
    city: 'Wankendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24610',
    city: 'Gönnebek',
    countrycode: 'DE',
  },
  {
    postalcode: '24610',
    city: 'Trappenkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '24613',
    city: 'Aukrug',
    countrycode: 'DE',
  },
  {
    postalcode: '24613',
    city: 'Wiedenborstel',
    countrycode: 'DE',
  },
  {
    postalcode: '24616',
    city: 'Armstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24616',
    city: 'Borstel',
    countrycode: 'DE',
  },
  {
    postalcode: '24616',
    city: 'Brokstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24616',
    city: 'Hardebek',
    countrycode: 'DE',
  },
  {
    postalcode: '24616',
    city: 'Hasenkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '24616',
    city: 'Sarlhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '24616',
    city: 'Willenscharen',
    countrycode: 'DE',
  },
  {
    postalcode: '24619',
    city: 'Bornhöved',
    countrycode: 'DE',
  },
  {
    postalcode: '24619',
    city: 'Rendswühren',
    countrycode: 'DE',
  },
  {
    postalcode: '24619',
    city: 'Tarbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24620',
    city: 'Bönebüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '24622',
    city: 'Gnutz',
    countrycode: 'DE',
  },
  {
    postalcode: '24623',
    city: 'Großenaspe',
    countrycode: 'DE',
  },
  {
    postalcode: '24625',
    city: 'Großharrie',
    countrycode: 'DE',
  },
  {
    postalcode: '24625',
    city: 'Negenharrie',
    countrycode: 'DE',
  },
  {
    postalcode: '24626',
    city: 'Groß Kummerfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24628',
    city: 'Hartenholm',
    countrycode: 'DE',
  },
  {
    postalcode: '24629',
    city: 'Kisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24631',
    city: 'Langwedel',
    countrycode: 'DE',
  },
  {
    postalcode: '24632',
    city: 'Heidmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '24632',
    city: 'Lentföhrden',
    countrycode: 'DE',
  },
  {
    postalcode: '24634',
    city: 'Arpsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24634',
    city: 'Padenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24635',
    city: 'Daldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24635',
    city: 'Rickling',
    countrycode: 'DE',
  },
  {
    postalcode: '24637',
    city: 'Ovendorferredder',
    countrycode: 'DE',
  },
  {
    postalcode: '24637',
    city: 'Schillsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24638',
    city: 'Schmalensee',
    countrycode: 'DE',
  },
  {
    postalcode: '24640',
    city: 'Bollweg',
    countrycode: 'DE',
  },
  {
    postalcode: '24640',
    city: 'Hasenmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '24640',
    city: 'Schmalfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24640',
    city: 'Wierenkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '24641',
    city: 'Hüttblek',
    countrycode: 'DE',
  },
  {
    postalcode: '24641',
    city: 'Sievershütten',
    countrycode: 'DE',
  },
  {
    postalcode: '24641',
    city: 'Stuvenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '24643',
    city: 'Struvenhütten',
    countrycode: 'DE',
  },
  {
    postalcode: '24644',
    city: 'Krogaspe',
    countrycode: 'DE',
  },
  {
    postalcode: '24644',
    city: 'Loop',
    countrycode: 'DE',
  },
  {
    postalcode: '24644',
    city: 'Timmaspe',
    countrycode: 'DE',
  },
  {
    postalcode: '24646',
    city: 'Warder',
    countrycode: 'DE',
  },
  {
    postalcode: '24647',
    city: 'Ehndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24647',
    city: 'Wasbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24649',
    city: 'Fuhlendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24649',
    city: 'Wiemersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24768',
    city: 'Nübbel',
    countrycode: 'DE',
  },
  {
    postalcode: '24768',
    city: 'Rendsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '24782',
    city: 'Büdelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24782',
    city: 'Duten',
    countrycode: 'DE',
  },
  {
    postalcode: '24782',
    city: 'Rickert',
    countrycode: 'DE',
  },
  {
    postalcode: '24783',
    city: 'Osterrönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24784',
    city: 'Westerrönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24787',
    city: 'Ahrenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24787',
    city: 'Fockbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24790',
    city: 'Haßmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '24790',
    city: 'Heidkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '24790',
    city: 'Ostenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24790',
    city: 'Rade',
    countrycode: 'DE',
  },
  {
    postalcode: '24790',
    city: 'Schacht-Audorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24790',
    city: 'Schülldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24791',
    city: 'Alt Duvenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24791',
    city: 'Neu Duvenstedt-Nord',
    countrycode: 'DE',
  },
  {
    postalcode: '24793',
    city: 'Bargstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24793',
    city: 'Brammer',
    countrycode: 'DE',
  },
  {
    postalcode: '24793',
    city: 'Oldenhütten',
    countrycode: 'DE',
  },
  {
    postalcode: '24794',
    city: 'Borgstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24794',
    city: 'Bünsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24794',
    city: 'Inselhof',
    countrycode: 'DE',
  },
  {
    postalcode: '24794',
    city: 'Neu Duvenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24794',
    city: 'Rader Insel',
    countrycode: 'DE',
  },
  {
    postalcode: '24796',
    city: 'Bovenau',
    countrycode: 'DE',
  },
  {
    postalcode: '24796',
    city: 'Bredenbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24796',
    city: 'Krummwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '24797',
    city: 'Bokelhoop',
    countrycode: 'DE',
  },
  {
    postalcode: '24797',
    city: 'Breiholz',
    countrycode: 'DE',
  },
  {
    postalcode: '24797',
    city: 'Gieselauschleuse',
    countrycode: 'DE',
  },
  {
    postalcode: '24797',
    city: 'Hörsten',
    countrycode: 'DE',
  },
  {
    postalcode: '24797',
    city: 'Jevenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24797',
    city: 'Moltkestein',
    countrycode: 'DE',
  },
  {
    postalcode: '24797',
    city: 'Tackesdorf-Nord',
    countrycode: 'DE',
  },
  {
    postalcode: '24799',
    city: 'Christiansholm',
    countrycode: 'DE',
  },
  {
    postalcode: '24799',
    city: 'Friedrichsgraben',
    countrycode: 'DE',
  },
  {
    postalcode: '24799',
    city: 'Friedrichsholm',
    countrycode: 'DE',
  },
  {
    postalcode: '24799',
    city: 'Königshügel',
    countrycode: 'DE',
  },
  {
    postalcode: '24799',
    city: 'Meggerdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24799',
    city: 'Umleitungsdeich',
    countrycode: 'DE',
  },
  {
    postalcode: '24800',
    city: 'Elsdorf-Westermühlen',
    countrycode: 'DE',
  },
  {
    postalcode: '24802',
    city: 'Bokel',
    countrycode: 'DE',
  },
  {
    postalcode: '24802',
    city: 'Emkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24802',
    city: 'Groß Vollstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24803',
    city: 'Erfde',
    countrycode: 'DE',
  },
  {
    postalcode: '24803',
    city: 'Tielen',
    countrycode: 'DE',
  },
  {
    postalcode: '24805',
    city: 'Hamdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24805',
    city: 'Prinzenmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '24806',
    city: 'Bargstall',
    countrycode: 'DE',
  },
  {
    postalcode: '24806',
    city: 'Hohn',
    countrycode: 'DE',
  },
  {
    postalcode: '24806',
    city: 'Lohe-Föhrden',
    countrycode: 'DE',
  },
  {
    postalcode: '24806',
    city: 'Sophienhamm',
    countrycode: 'DE',
  },
  {
    postalcode: '24806',
    city: 'Sorgbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '24808',
    city: 'Jevenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24808',
    city: 'Plirup',
    countrycode: 'DE',
  },
  {
    postalcode: '24809',
    city: 'Nübbel',
    countrycode: 'DE',
  },
  {
    postalcode: '24811',
    city: 'Ahlefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24811',
    city: 'Brekendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24811',
    city: 'Owschlag',
    countrycode: 'DE',
  },
  {
    postalcode: '24811',
    city: 'Stenten',
    countrycode: 'DE',
  },
  {
    postalcode: '24813',
    city: 'Schülp',
    countrycode: 'DE',
  },
  {
    postalcode: '24814',
    city: 'Sehestedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24816',
    city: 'Brinjahe',
    countrycode: 'DE',
  },
  {
    postalcode: '24816',
    city: 'Freudenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24816',
    city: 'Hamweddel',
    countrycode: 'DE',
  },
  {
    postalcode: '24816',
    city: 'Luhnstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24816',
    city: 'Luhnvie',
    countrycode: 'DE',
  },
  {
    postalcode: '24816',
    city: 'Stafstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24817',
    city: 'Tetenhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '24819',
    city: 'Embühren',
    countrycode: 'DE',
  },
  {
    postalcode: '24819',
    city: 'Grotenknöll',
    countrycode: 'DE',
  },
  {
    postalcode: '24819',
    city: 'Haale',
    countrycode: 'DE',
  },
  {
    postalcode: '24819',
    city: 'Nienborstel',
    countrycode: 'DE',
  },
  {
    postalcode: '24819',
    city: 'Todenbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '24837',
    city: 'Königswill',
    countrycode: 'DE',
  },
  {
    postalcode: '24837',
    city: 'Schleswig',
    countrycode: 'DE',
  },
  {
    postalcode: '24848',
    city: 'Alt Bennebek',
    countrycode: 'DE',
  },
  {
    postalcode: '24848',
    city: 'Boklund',
    countrycode: 'DE',
  },
  {
    postalcode: '24848',
    city: 'Großrheider Ausbau',
    countrycode: 'DE',
  },
  {
    postalcode: '24848',
    city: 'Großrheiderfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24848',
    city: 'Klein Bennebek',
    countrycode: 'DE',
  },
  {
    postalcode: '24848',
    city: 'Klein Rheide',
    countrycode: 'DE',
  },
  {
    postalcode: '24848',
    city: 'Kropp',
    countrycode: 'DE',
  },
  {
    postalcode: '24848',
    city: 'Mielberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24850',
    city: 'Hüsby',
    countrycode: 'DE',
  },
  {
    postalcode: '24850',
    city: 'Lürschau',
    countrycode: 'DE',
  },
  {
    postalcode: '24850',
    city: 'Schuby',
    countrycode: 'DE',
  },
  {
    postalcode: '24852',
    city: 'Eggebek',
    countrycode: 'DE',
  },
  {
    postalcode: '24852',
    city: 'Langstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24852',
    city: 'Sollerup',
    countrycode: 'DE',
  },
  {
    postalcode: '24852',
    city: 'Süderhackstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24855',
    city: 'Bollingstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24855',
    city: 'Jübek',
    countrycode: 'DE',
  },
  {
    postalcode: '24857',
    city: 'Borgwedel',
    countrycode: 'DE',
  },
  {
    postalcode: '24857',
    city: 'Fahrdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24860',
    city: 'Böklund',
    countrycode: 'DE',
  },
  {
    postalcode: '24860',
    city: 'Klappholz',
    countrycode: 'DE',
  },
  {
    postalcode: '24860',
    city: 'Uelsby',
    countrycode: 'DE',
  },
  {
    postalcode: '24860',
    city: 'Wellspang',
    countrycode: 'DE',
  },
  {
    postalcode: '24861',
    city: 'Bergenhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '24861',
    city: 'Fünfmühlen',
    countrycode: 'DE',
  },
  {
    postalcode: '24861',
    city: 'Papenbrook',
    countrycode: 'DE',
  },
  {
    postalcode: '24861',
    city: 'Reppel',
    countrycode: 'DE',
  },
  {
    postalcode: '24861',
    city: 'Wassermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '24863',
    city: 'Barkhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '24863',
    city: 'Börm',
    countrycode: 'DE',
  },
  {
    postalcode: '24864',
    city: 'Brodersby',
    countrycode: 'DE',
  },
  {
    postalcode: '24864',
    city: 'Goltoft',
    countrycode: 'DE',
  },
  {
    postalcode: '24866',
    city: 'Busdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24867',
    city: 'Dannewerk',
    countrycode: 'DE',
  },
  {
    postalcode: '24869',
    city: 'Dörpstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24870',
    city: 'Ellingstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24872',
    city: 'Groß Rheide',
    countrycode: 'DE',
  },
  {
    postalcode: '24873',
    city: 'Havetoft',
    countrycode: 'DE',
  },
  {
    postalcode: '24875',
    city: 'Havetoftloit',
    countrycode: 'DE',
  },
  {
    postalcode: '24876',
    city: 'Hollingstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24878',
    city: 'Jagel',
    countrycode: 'DE',
  },
  {
    postalcode: '24878',
    city: 'Lottorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24879',
    city: 'Idstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24879',
    city: 'Neuberend',
    countrycode: 'DE',
  },
  {
    postalcode: '24881',
    city: 'Nübel',
    countrycode: 'DE',
  },
  {
    postalcode: '24882',
    city: 'Geelbek',
    countrycode: 'DE',
  },
  {
    postalcode: '24882',
    city: 'Schaalby',
    countrycode: 'DE',
  },
  {
    postalcode: '24884',
    city: 'Geltorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24884',
    city: 'Hahnenkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '24884',
    city: 'Selk',
    countrycode: 'DE',
  },
  {
    postalcode: '24885',
    city: 'Sieverstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24887',
    city: 'Schwittschau',
    countrycode: 'DE',
  },
  {
    postalcode: '24887',
    city: 'Silberstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24888',
    city: 'Brebelmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '24888',
    city: 'Loit',
    countrycode: 'DE',
  },
  {
    postalcode: '24888',
    city: 'Schmedeland',
    countrycode: 'DE',
  },
  {
    postalcode: '24888',
    city: 'Steinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24890',
    city: 'Stolk',
    countrycode: 'DE',
  },
  {
    postalcode: '24890',
    city: 'Süderfahrenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24891',
    city: 'Dingwatt',
    countrycode: 'DE',
  },
  {
    postalcode: '24891',
    city: 'Ekebergkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '24891',
    city: 'Eslingholz',
    countrycode: 'DE',
  },
  {
    postalcode: '24891',
    city: 'Hollmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '24891',
    city: 'Lüttholm',
    countrycode: 'DE',
  },
  {
    postalcode: '24891',
    city: 'Rabenholzlück',
    countrycode: 'DE',
  },
  {
    postalcode: '24891',
    city: 'Schnarup-Thumby',
    countrycode: 'DE',
  },
  {
    postalcode: '24891',
    city: 'Struxdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '24893',
    city: 'Taarstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24894',
    city: 'Hoffnungstal',
    countrycode: 'DE',
  },
  {
    postalcode: '24894',
    city: 'Tolk',
    countrycode: 'DE',
  },
  {
    postalcode: '24894',
    city: 'Twedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24896',
    city: 'Nordergeilwang',
    countrycode: 'DE',
  },
  {
    postalcode: '24896',
    city: 'Treia',
    countrycode: 'DE',
  },
  {
    postalcode: '24897',
    city: 'Ulsnis',
    countrycode: 'DE',
  },
  {
    postalcode: '24899',
    city: 'Wohlde',
    countrycode: 'DE',
  },
  {
    postalcode: '24937',
    city: 'Flensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '24939',
    city: 'Flensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '24941',
    city: 'Flensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '24941',
    city: 'Jarplund-Weding',
    countrycode: 'DE',
  },
  {
    postalcode: '24943',
    city: 'Flensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '24943',
    city: 'Tastrup',
    countrycode: 'DE',
  },
  {
    postalcode: '24944',
    city: 'Flensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '24955',
    city: 'Harrislee',
    countrycode: 'DE',
  },
  {
    postalcode: '24960',
    city: 'Glücksburg',
    countrycode: 'DE',
  },
  {
    postalcode: '24960',
    city: 'Munkbrarup',
    countrycode: 'DE',
  },
  {
    postalcode: '24963',
    city: 'Jerrishoe',
    countrycode: 'DE',
  },
  {
    postalcode: '24963',
    city: 'Tarp',
    countrycode: 'DE',
  },
  {
    postalcode: '24966',
    city: 'Sörup',
    countrycode: 'DE',
  },
  {
    postalcode: '24969',
    city: 'Großenwiehe',
    countrycode: 'DE',
  },
  {
    postalcode: '24969',
    city: 'Lindewitt',
    countrycode: 'DE',
  },
  {
    postalcode: '24972',
    city: 'Quern',
    countrycode: 'DE',
  },
  {
    postalcode: '24972',
    city: 'Steinberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24972',
    city: 'Steinbergkirche',
    countrycode: 'DE',
  },
  {
    postalcode: '24975',
    city: 'Ausacker',
    countrycode: 'DE',
  },
  {
    postalcode: '24975',
    city: 'Husby',
    countrycode: 'DE',
  },
  {
    postalcode: '24975',
    city: 'Hürup',
    countrycode: 'DE',
  },
  {
    postalcode: '24975',
    city: 'Maasbüll',
    countrycode: 'DE',
  },
  {
    postalcode: '24977',
    city: 'Grundhof',
    countrycode: 'DE',
  },
  {
    postalcode: '24977',
    city: 'Langballig',
    countrycode: 'DE',
  },
  {
    postalcode: '24977',
    city: 'Ringsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '24977',
    city: 'Westerholz',
    countrycode: 'DE',
  },
  {
    postalcode: '24980',
    city: 'Hörup',
    countrycode: 'DE',
  },
  {
    postalcode: '24980',
    city: 'Meyn',
    countrycode: 'DE',
  },
  {
    postalcode: '24980',
    city: 'Nordhackstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '24980',
    city: 'Schafflund',
    countrycode: 'DE',
  },
  {
    postalcode: '24980',
    city: 'Wallsbüll',
    countrycode: 'DE',
  },
  {
    postalcode: '24983',
    city: 'Handewitt',
    countrycode: 'DE',
  },
  {
    postalcode: '24986',
    city: 'Rüde',
    countrycode: 'DE',
  },
  {
    postalcode: '24986',
    city: 'Satrup',
    countrycode: 'DE',
  },
  {
    postalcode: '24988',
    city: 'Oeversee',
    countrycode: 'DE',
  },
  {
    postalcode: '24988',
    city: 'Sankelmark',
    countrycode: 'DE',
  },
  {
    postalcode: '24989',
    city: 'Dollerup',
    countrycode: 'DE',
  },
  {
    postalcode: '24989',
    city: 'Tiefengruft',
    countrycode: 'DE',
  },
  {
    postalcode: '24991',
    city: 'Freienwill',
    countrycode: 'DE',
  },
  {
    postalcode: '24991',
    city: 'Großsolt',
    countrycode: 'DE',
  },
  {
    postalcode: '24992',
    city: 'Janneby',
    countrycode: 'DE',
  },
  {
    postalcode: '24992',
    city: 'Jerrisbekfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24992',
    city: 'Jörl',
    countrycode: 'DE',
  },
  {
    postalcode: '24992',
    city: 'Süderhackstedtfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '24994',
    city: 'Böxlund',
    countrycode: 'DE',
  },
  {
    postalcode: '24994',
    city: 'Holt',
    countrycode: 'DE',
  },
  {
    postalcode: '24994',
    city: 'Jardelund',
    countrycode: 'DE',
  },
  {
    postalcode: '24994',
    city: 'Medelby',
    countrycode: 'DE',
  },
  {
    postalcode: '24994',
    city: 'Osterby',
    countrycode: 'DE',
  },
  {
    postalcode: '24994',
    city: 'Weesby',
    countrycode: 'DE',
  },
  {
    postalcode: '24996',
    city: 'Ahneby',
    countrycode: 'DE',
  },
  {
    postalcode: '24996',
    city: 'Sterup',
    countrycode: 'DE',
  },
  {
    postalcode: '24997',
    city: 'Wanderup',
    countrycode: 'DE',
  },
  {
    postalcode: '24999',
    city: 'Wees',
    countrycode: 'DE',
  },
  {
    postalcode: '25335',
    city: 'Altenmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '25335',
    city: 'Bokholt-Hanredder',
    countrycode: 'DE',
  },
  {
    postalcode: '25335',
    city: 'Elmshorn',
    countrycode: 'DE',
  },
  {
    postalcode: '25335',
    city: 'Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25335',
    city: 'Raa-Besenbek',
    countrycode: 'DE',
  },
  {
    postalcode: '25336',
    city: 'Elmshorn',
    countrycode: 'DE',
  },
  {
    postalcode: '25336',
    city: 'Klein Nordende',
    countrycode: 'DE',
  },
  {
    postalcode: '25337',
    city: 'Elmshorn',
    countrycode: 'DE',
  },
  {
    postalcode: '25337',
    city: 'Kölln-Reisiek',
    countrycode: 'DE',
  },
  {
    postalcode: '25337',
    city: 'Seeth-Ekholt',
    countrycode: 'DE',
  },
  {
    postalcode: '25348',
    city: 'Blomesche Wildnis',
    countrycode: 'DE',
  },
  {
    postalcode: '25348',
    city: 'Engelbrechtsche Wildnis',
    countrycode: 'DE',
  },
  {
    postalcode: '25348',
    city: 'Glückstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '25355',
    city: 'Barmstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25355',
    city: 'Bevern',
    countrycode: 'DE',
  },
  {
    postalcode: '25355',
    city: 'Bullenkuhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '25355',
    city: 'Groß Offenseth-Aspern',
    countrycode: 'DE',
  },
  {
    postalcode: '25355',
    city: 'Heede',
    countrycode: 'DE',
  },
  {
    postalcode: '25355',
    city: 'Lutzhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '25358',
    city: 'Hohenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '25358',
    city: 'Horst',
    countrycode: 'DE',
  },
  {
    postalcode: '25358',
    city: 'Sommerland',
    countrycode: 'DE',
  },
  {
    postalcode: '25361',
    city: 'Elskop',
    countrycode: 'DE',
  },
  {
    postalcode: '25361',
    city: 'Gehrhof',
    countrycode: 'DE',
  },
  {
    postalcode: '25361',
    city: 'Grevenkop',
    countrycode: 'DE',
  },
  {
    postalcode: '25361',
    city: 'Krempe',
    countrycode: 'DE',
  },
  {
    postalcode: '25361',
    city: 'Muchelndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25361',
    city: 'Süderau',
    countrycode: 'DE',
  },
  {
    postalcode: '25364',
    city: 'Bokel',
    countrycode: 'DE',
  },
  {
    postalcode: '25364',
    city: 'Brande-Hörnerkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '25364',
    city: 'Osterhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '25364',
    city: 'Westerhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '25365',
    city: 'Klein Offenseth-Sparrieshoop',
    countrycode: 'DE',
  },
  {
    postalcode: '25368',
    city: 'Kiebitzreihe',
    countrycode: 'DE',
  },
  {
    postalcode: '25370',
    city: 'Seester',
    countrycode: 'DE',
  },
  {
    postalcode: '25371',
    city: 'Seestermühe',
    countrycode: 'DE',
  },
  {
    postalcode: '25373',
    city: 'Ellerhoop',
    countrycode: 'DE',
  },
  {
    postalcode: '25376',
    city: 'Borsfleth',
    countrycode: 'DE',
  },
  {
    postalcode: '25376',
    city: 'Krempdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25377',
    city: 'Kollmar',
    countrycode: 'DE',
  },
  {
    postalcode: '25377',
    city: 'Pagensand',
    countrycode: 'DE',
  },
  {
    postalcode: '25379',
    city: 'Herzhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '25379',
    city: 'Kamerlanderdeich',
    countrycode: 'DE',
  },
  {
    postalcode: '25421',
    city: 'Pinneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '25436',
    city: 'Groß Nordende',
    countrycode: 'DE',
  },
  {
    postalcode: '25436',
    city: 'Heidgraben',
    countrycode: 'DE',
  },
  {
    postalcode: '25436',
    city: 'Moorrege',
    countrycode: 'DE',
  },
  {
    postalcode: '25436',
    city: 'Neuendeich',
    countrycode: 'DE',
  },
  {
    postalcode: '25436',
    city: 'Tornesch',
    countrycode: 'DE',
  },
  {
    postalcode: '25436',
    city: 'Uetersen',
    countrycode: 'DE',
  },
  {
    postalcode: '25451',
    city: 'Quickborn',
    countrycode: 'DE',
  },
  {
    postalcode: '25462',
    city: 'Rellingen',
    countrycode: 'DE',
  },
  {
    postalcode: '25469',
    city: 'Halstenbek',
    countrycode: 'DE',
  },
  {
    postalcode: '25474',
    city: 'Bönningstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25474',
    city: 'Ellerbek',
    countrycode: 'DE',
  },
  {
    postalcode: '25474',
    city: 'Hasloh',
    countrycode: 'DE',
  },
  {
    postalcode: '25479',
    city: 'Ellerau',
    countrycode: 'DE',
  },
  {
    postalcode: '25482',
    city: 'Appen',
    countrycode: 'DE',
  },
  {
    postalcode: '25485',
    city: 'Bilsen',
    countrycode: 'DE',
  },
  {
    postalcode: '25485',
    city: 'Hemdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '25485',
    city: 'Langeln',
    countrycode: 'DE',
  },
  {
    postalcode: '25486',
    city: 'Alveslohe',
    countrycode: 'DE',
  },
  {
    postalcode: '25488',
    city: 'Holm',
    countrycode: 'DE',
  },
  {
    postalcode: '25489',
    city: 'Haselau',
    countrycode: 'DE',
  },
  {
    postalcode: '25489',
    city: 'Haseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25491',
    city: 'Hetlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '25492',
    city: 'Heist',
    countrycode: 'DE',
  },
  {
    postalcode: '25492',
    city: 'Hetlinger Neuerkoog',
    countrycode: 'DE',
  },
  {
    postalcode: '25494',
    city: 'Borstel-Hohenraden',
    countrycode: 'DE',
  },
  {
    postalcode: '25495',
    city: 'Kummerfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25497',
    city: 'Prisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25499',
    city: 'Tangstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25524',
    city: 'Bekmünde',
    countrycode: 'DE',
  },
  {
    postalcode: '25524',
    city: 'Breitenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '25524',
    city: 'Heiligenstedten',
    countrycode: 'DE',
  },
  {
    postalcode: '25524',
    city: 'Heiligenstedtenerkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '25524',
    city: 'Itzehoe',
    countrycode: 'DE',
  },
  {
    postalcode: '25524',
    city: 'Kollmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '25524',
    city: 'Oelixdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25541',
    city: 'Brunsbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25548',
    city: 'Auufer',
    countrycode: 'DE',
  },
  {
    postalcode: '25548',
    city: 'Kellinghusen',
    countrycode: 'DE',
  },
  {
    postalcode: '25548',
    city: 'Mühlenbarbek',
    countrycode: 'DE',
  },
  {
    postalcode: '25548',
    city: 'Oeschebüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25548',
    city: 'Rosdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25548',
    city: 'Störkathen',
    countrycode: 'DE',
  },
  {
    postalcode: '25548',
    city: 'Wittenbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '25551',
    city: 'Hohenlockstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25551',
    city: 'Lockstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25551',
    city: 'Lohbarbek',
    countrycode: 'DE',
  },
  {
    postalcode: '25551',
    city: 'Peissen',
    countrycode: 'DE',
  },
  {
    postalcode: '25551',
    city: 'Schlotfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25551',
    city: 'Silzen',
    countrycode: 'DE',
  },
  {
    postalcode: '25551',
    city: 'Springhoe',
    countrycode: 'DE',
  },
  {
    postalcode: '25551',
    city: 'Winseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25554',
    city: 'Bekdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25554',
    city: 'Brokdorferlandscheide',
    countrycode: 'DE',
  },
  {
    postalcode: '25554',
    city: 'Dammfleth',
    countrycode: 'DE',
  },
  {
    postalcode: '25554',
    city: 'Kleve',
    countrycode: 'DE',
  },
  {
    postalcode: '25554',
    city: 'Krummendiek',
    countrycode: 'DE',
  },
  {
    postalcode: '25554',
    city: 'Landrecht',
    countrycode: 'DE',
  },
  {
    postalcode: '25554',
    city: 'Moorhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '25554',
    city: 'Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25554',
    city: 'Nortorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25554',
    city: 'Sachsenbande',
    countrycode: 'DE',
  },
  {
    postalcode: '25554',
    city: 'Stördorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25554',
    city: 'Wilster',
    countrycode: 'DE',
  },
  {
    postalcode: '25557',
    city: 'Beldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25557',
    city: 'Bendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25557',
    city: 'Bornholt',
    countrycode: 'DE',
  },
  {
    postalcode: '25557',
    city: 'Gokels',
    countrycode: 'DE',
  },
  {
    postalcode: '25557',
    city: 'Hanerau-Hademarschen',
    countrycode: 'DE',
  },
  {
    postalcode: '25557',
    city: 'Keller',
    countrycode: 'DE',
  },
  {
    postalcode: '25557',
    city: 'Oldenbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25557',
    city: 'Seefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25557',
    city: 'Steenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25557',
    city: 'Thaden',
    countrycode: 'DE',
  },
  {
    postalcode: '25560',
    city: 'Aasbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25560',
    city: 'Agethorst',
    countrycode: 'DE',
  },
  {
    postalcode: '25560',
    city: 'Bokhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '25560',
    city: 'Hadenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25560',
    city: 'Kaisborstel',
    countrycode: 'DE',
  },
  {
    postalcode: '25560',
    city: 'Oldenborstel',
    countrycode: 'DE',
  },
  {
    postalcode: '25560',
    city: 'Puls',
    countrycode: 'DE',
  },
  {
    postalcode: '25560',
    city: 'Pöschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25560',
    city: 'Schenefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25560',
    city: 'Siezbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25560',
    city: 'Warringholz',
    countrycode: 'DE',
  },
  {
    postalcode: '25563',
    city: 'Bargholz',
    countrycode: 'DE',
  },
  {
    postalcode: '25563',
    city: 'Föhrden-Barl',
    countrycode: 'DE',
  },
  {
    postalcode: '25563',
    city: 'Hingstheide',
    countrycode: 'DE',
  },
  {
    postalcode: '25563',
    city: 'Quarnstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25563',
    city: 'Wrist',
    countrycode: 'DE',
  },
  {
    postalcode: '25563',
    city: 'Wulfsmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '25566',
    city: 'Lägerdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25566',
    city: 'Rethwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '25569',
    city: 'Bahrenfleth',
    countrycode: 'DE',
  },
  {
    postalcode: '25569',
    city: 'Hodorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25569',
    city: 'Kremperheide',
    countrycode: 'DE',
  },
  {
    postalcode: '25569',
    city: 'Krempermoor',
    countrycode: 'DE',
  },
  {
    postalcode: '25572',
    city: 'Aebtissinwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '25572',
    city: 'Büttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25572',
    city: 'Ecklak',
    countrycode: 'DE',
  },
  {
    postalcode: '25572',
    city: 'Kudensee',
    countrycode: 'DE',
  },
  {
    postalcode: '25572',
    city: 'Landscheide',
    countrycode: 'DE',
  },
  {
    postalcode: '25572',
    city: 'Oberstenwehr',
    countrycode: 'DE',
  },
  {
    postalcode: '25572',
    city: 'Sankt Margarethen',
    countrycode: 'DE',
  },
  {
    postalcode: '25572',
    city: 'Vaalerfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25573',
    city: 'Beidenfleth',
    countrycode: 'DE',
  },
  {
    postalcode: '25573',
    city: 'Klein Kampen',
    countrycode: 'DE',
  },
  {
    postalcode: '25575',
    city: 'Beringstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25576',
    city: 'Brokdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25578',
    city: 'Dägeling',
    countrycode: 'DE',
  },
  {
    postalcode: '25578',
    city: 'Neuenbrook',
    countrycode: 'DE',
  },
  {
    postalcode: '25579',
    city: 'Fitzbek',
    countrycode: 'DE',
  },
  {
    postalcode: '25579',
    city: 'Rade',
    countrycode: 'DE',
  },
  {
    postalcode: '25581',
    city: 'Hennstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25581',
    city: 'Poyenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '25582',
    city: 'Drage',
    countrycode: 'DE',
  },
  {
    postalcode: '25582',
    city: 'Hohenaspe',
    countrycode: 'DE',
  },
  {
    postalcode: '25582',
    city: 'Kaaks',
    countrycode: 'DE',
  },
  {
    postalcode: '25582',
    city: 'Looft',
    countrycode: 'DE',
  },
  {
    postalcode: '25584',
    city: 'Besdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25584',
    city: 'Holstenniendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25585',
    city: 'Lütjenwestedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25585',
    city: 'Tackesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25587',
    city: 'Münsterdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25588',
    city: 'Huje',
    countrycode: 'DE',
  },
  {
    postalcode: '25588',
    city: 'Mehlbek',
    countrycode: 'DE',
  },
  {
    postalcode: '25588',
    city: 'Oldendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25590',
    city: 'Osterstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25591',
    city: 'Ottenbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25593',
    city: 'Christinenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '25593',
    city: 'Reher',
    countrycode: 'DE',
  },
  {
    postalcode: '25594',
    city: 'Nutteln',
    countrycode: 'DE',
  },
  {
    postalcode: '25594',
    city: 'Vaale',
    countrycode: 'DE',
  },
  {
    postalcode: '25594',
    city: 'Vaalermoor',
    countrycode: 'DE',
  },
  {
    postalcode: '25596',
    city: 'Bokelrehm',
    countrycode: 'DE',
  },
  {
    postalcode: '25596',
    city: 'Gribbohm',
    countrycode: 'DE',
  },
  {
    postalcode: '25596',
    city: 'Nienbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25596',
    city: 'Wacken',
    countrycode: 'DE',
  },
  {
    postalcode: '25597',
    city: 'Breitenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '25597',
    city: 'Kronsmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '25597',
    city: 'Moordiek',
    countrycode: 'DE',
  },
  {
    postalcode: '25597',
    city: 'Moordorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25597',
    city: 'Westermoor',
    countrycode: 'DE',
  },
  {
    postalcode: '25599',
    city: 'Wewelsfleth',
    countrycode: 'DE',
  },
  {
    postalcode: '25693',
    city: 'Christianslust',
    countrycode: 'DE',
  },
  {
    postalcode: '25693',
    city: 'Gudendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25693',
    city: 'St. Michaelisdonn',
    countrycode: 'DE',
  },
  {
    postalcode: '25693',
    city: 'Trennewurth',
    countrycode: 'DE',
  },
  {
    postalcode: '25693',
    city: 'Volsemenhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '25704',
    city: 'Bargenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25704',
    city: 'Elpersbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25704',
    city: 'Epenwöhrden',
    countrycode: 'DE',
  },
  {
    postalcode: '25704',
    city: 'Meldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25704',
    city: 'Nindorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25704',
    city: 'Nordermeldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25704',
    city: 'Wolmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25709',
    city: 'Diekhusen-Fahrstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25709',
    city: 'Helse',
    countrycode: 'DE',
  },
  {
    postalcode: '25709',
    city: 'Kaiser-Wilhelm-Koog',
    countrycode: 'DE',
  },
  {
    postalcode: '25709',
    city: 'Kronprinzenkoog',
    countrycode: 'DE',
  },
  {
    postalcode: '25709',
    city: 'Marne',
    countrycode: 'DE',
  },
  {
    postalcode: '25709',
    city: 'Marnerdeich',
    countrycode: 'DE',
  },
  {
    postalcode: '25712',
    city: 'Brickeln',
    countrycode: 'DE',
  },
  {
    postalcode: '25712',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '25712',
    city: 'Burg',
    countrycode: 'DE',
  },
  {
    postalcode: '25712',
    city: 'Burgerfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25712',
    city: 'Großenrade',
    countrycode: 'DE',
  },
  {
    postalcode: '25712',
    city: 'Hochdonn',
    countrycode: 'DE',
  },
  {
    postalcode: '25712',
    city: 'Kuden',
    countrycode: 'DE',
  },
  {
    postalcode: '25712',
    city: 'Quickborn',
    countrycode: 'DE',
  },
  {
    postalcode: '25715',
    city: 'Averlak',
    countrycode: 'DE',
  },
  {
    postalcode: '25715',
    city: 'Blangenmoorfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25715',
    city: 'Dingen',
    countrycode: 'DE',
  },
  {
    postalcode: '25715',
    city: 'Eddelak',
    countrycode: 'DE',
  },
  {
    postalcode: '25715',
    city: 'Ramhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '25718',
    city: 'Friedrichskoog',
    countrycode: 'DE',
  },
  {
    postalcode: '25719',
    city: 'Barlt',
    countrycode: 'DE',
  },
  {
    postalcode: '25719',
    city: 'Busenwurth',
    countrycode: 'DE',
  },
  {
    postalcode: '25721',
    city: 'Eggstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25724',
    city: 'Neufeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25724',
    city: 'Neufelderkoog',
    countrycode: 'DE',
  },
  {
    postalcode: '25724',
    city: 'Schmedeswurth',
    countrycode: 'DE',
  },
  {
    postalcode: '25725',
    city: 'Bornholt',
    countrycode: 'DE',
  },
  {
    postalcode: '25725',
    city: 'Schafstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25725',
    city: 'Weidenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '25727',
    city: 'Frestedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25727',
    city: 'Krumstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25727',
    city: 'Süderhastedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25729',
    city: 'Windbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '25746',
    city: 'Heide',
    countrycode: 'DE',
  },
  {
    postalcode: '25746',
    city: 'Lohe-Rickelshof',
    countrycode: 'DE',
  },
  {
    postalcode: '25746',
    city: 'Norderwöhrden',
    countrycode: 'DE',
  },
  {
    postalcode: '25746',
    city: 'Ostrohe',
    countrycode: 'DE',
  },
  {
    postalcode: '25746',
    city: 'Wesseln',
    countrycode: 'DE',
  },
  {
    postalcode: '25761',
    city: 'Büsum',
    countrycode: 'DE',
  },
  {
    postalcode: '25761',
    city: 'Büsumer Deichhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '25761',
    city: 'Hedwigenkoog',
    countrycode: 'DE',
  },
  {
    postalcode: '25761',
    city: 'Oesterdeichstrich',
    countrycode: 'DE',
  },
  {
    postalcode: '25761',
    city: 'Warwerort',
    countrycode: 'DE',
  },
  {
    postalcode: '25761',
    city: 'Westerdeichstrich',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Friedrichsgabekoog',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Hellschen-Heringsand-Unterschaar',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Hillgroven',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Norddeich',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Oesterwurth',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Reinsbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Schülp',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Schülperneuensiel',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Süderdeich',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Wesselburen',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Wesselburener Deichhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Wesselburenerkoog',
    countrycode: 'DE',
  },
  {
    postalcode: '25764',
    city: 'Wulfenhusen',
    countrycode: 'DE',
  },
  {
    postalcode: '25767',
    city: 'Albersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25767',
    city: 'Arkebek',
    countrycode: 'DE',
  },
  {
    postalcode: '25767',
    city: 'Bunsoh',
    countrycode: 'DE',
  },
  {
    postalcode: '25767',
    city: 'Grünental',
    countrycode: 'DE',
  },
  {
    postalcode: '25767',
    city: 'Immenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25767',
    city: 'Offenbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25767',
    city: 'Osterrade',
    countrycode: 'DE',
  },
  {
    postalcode: '25767',
    city: 'Riesewohld',
    countrycode: 'DE',
  },
  {
    postalcode: '25767',
    city: 'Tensbüttel-Röst',
    countrycode: 'DE',
  },
  {
    postalcode: '25767',
    city: 'Wennbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25770',
    city: 'Hemmingstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25770',
    city: 'Lieth',
    countrycode: 'DE',
  },
  {
    postalcode: '25774',
    city: 'Groven',
    countrycode: 'DE',
  },
  {
    postalcode: '25774',
    city: 'Hemme',
    countrycode: 'DE',
  },
  {
    postalcode: '25774',
    city: 'Karolinenkoog',
    countrycode: 'DE',
  },
  {
    postalcode: '25774',
    city: 'Krempel',
    countrycode: 'DE',
  },
  {
    postalcode: '25774',
    city: 'Lehe',
    countrycode: 'DE',
  },
  {
    postalcode: '25774',
    city: 'Lunden',
    countrycode: 'DE',
  },
  {
    postalcode: '25776',
    city: 'Dammsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25776',
    city: 'Hehm',
    countrycode: 'DE',
  },
  {
    postalcode: '25776',
    city: 'Leherfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25776',
    city: 'Nordfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25776',
    city: 'Rehm-Flehde-Bargen',
    countrycode: 'DE',
  },
  {
    postalcode: '25776',
    city: 'St. Annen',
    countrycode: 'DE',
  },
  {
    postalcode: '25779',
    city: 'Bergewöhrden',
    countrycode: 'DE',
  },
  {
    postalcode: '25779',
    city: 'Fedderingen',
    countrycode: 'DE',
  },
  {
    postalcode: '25779',
    city: 'Glüsing',
    countrycode: 'DE',
  },
  {
    postalcode: '25779',
    city: 'Hennstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25779',
    city: 'Hägen',
    countrycode: 'DE',
  },
  {
    postalcode: '25779',
    city: 'Kleve',
    countrycode: 'DE',
  },
  {
    postalcode: '25779',
    city: 'Norderheistedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25779',
    city: 'Schlichting',
    countrycode: 'DE',
  },
  {
    postalcode: '25779',
    city: 'Süderheistedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25779',
    city: 'Westermoor',
    countrycode: 'DE',
  },
  {
    postalcode: '25779',
    city: 'Wiemerstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25782',
    city: 'Gaushorn',
    countrycode: 'DE',
  },
  {
    postalcode: '25782',
    city: 'Hövede',
    countrycode: 'DE',
  },
  {
    postalcode: '25782',
    city: 'Schalkholz',
    countrycode: 'DE',
  },
  {
    postalcode: '25782',
    city: 'Schrum',
    countrycode: 'DE',
  },
  {
    postalcode: '25782',
    city: 'Süderdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25782',
    city: 'Tellingstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25782',
    city: 'Welmbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25782',
    city: 'Westerborstel',
    countrycode: 'DE',
  },
  {
    postalcode: '25785',
    city: 'Nordhastedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25785',
    city: 'Odderade',
    countrycode: 'DE',
  },
  {
    postalcode: '25785',
    city: 'Sarzbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25786',
    city: 'Dellstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25788',
    city: 'Delve',
    countrycode: 'DE',
  },
  {
    postalcode: '25788',
    city: 'Hollingstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25788',
    city: 'Wallen',
    countrycode: 'DE',
  },
  {
    postalcode: '25791',
    city: 'Barkenholm',
    countrycode: 'DE',
  },
  {
    postalcode: '25791',
    city: 'Brandmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '25791',
    city: 'Linden',
    countrycode: 'DE',
  },
  {
    postalcode: '25792',
    city: 'Neuenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '25792',
    city: 'Strübbel',
    countrycode: 'DE',
  },
  {
    postalcode: '25794',
    city: 'Dörpling',
    countrycode: 'DE',
  },
  {
    postalcode: '25794',
    city: 'Pahlen',
    countrycode: 'DE',
  },
  {
    postalcode: '25794',
    city: 'Tielenhemme',
    countrycode: 'DE',
  },
  {
    postalcode: '25795',
    city: 'Stelle-Wittenwurth',
    countrycode: 'DE',
  },
  {
    postalcode: '25795',
    city: 'Weddingstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25797',
    city: 'Wöhrden',
    countrycode: 'DE',
  },
  {
    postalcode: '25799',
    city: 'Wrohm',
    countrycode: 'DE',
  },
  {
    postalcode: '25813',
    city: 'Husum',
    countrycode: 'DE',
  },
  {
    postalcode: '25813',
    city: 'Schwesing',
    countrycode: 'DE',
  },
  {
    postalcode: '25813',
    city: 'Simonsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '25813',
    city: 'Spaltenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '25813',
    city: 'Südermarsch',
    countrycode: 'DE',
  },
  {
    postalcode: '25821',
    city: 'Almdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25821',
    city: 'Bordelumsiel',
    countrycode: 'DE',
  },
  {
    postalcode: '25821',
    city: 'Bredstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25821',
    city: 'Breklum',
    countrycode: 'DE',
  },
  {
    postalcode: '25821',
    city: 'Brommelund',
    countrycode: 'DE',
  },
  {
    postalcode: '25821',
    city: 'Dörpum',
    countrycode: 'DE',
  },
  {
    postalcode: '25821',
    city: 'Dörpumfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25821',
    city: 'Glücksburg',
    countrycode: 'DE',
  },
  {
    postalcode: '25821',
    city: 'Reußenköge',
    countrycode: 'DE',
  },
  {
    postalcode: '25821',
    city: 'Struckum',
    countrycode: 'DE',
  },
  {
    postalcode: '25821',
    city: 'Sönnebüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25821',
    city: 'Vollstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25826',
    city: 'Sankt Peter-Ording',
    countrycode: 'DE',
  },
  {
    postalcode: '25832',
    city: 'Kotzenbüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25832',
    city: 'Tönning',
    countrycode: 'DE',
  },
  {
    postalcode: '25836',
    city: 'Garding',
    countrycode: 'DE',
  },
  {
    postalcode: '25836',
    city: 'Garding, Kirchspiel',
    countrycode: 'DE',
  },
  {
    postalcode: '25836',
    city: 'Grothusenkoog',
    countrycode: 'DE',
  },
  {
    postalcode: '25836',
    city: 'Katharinenheerd',
    countrycode: 'DE',
  },
  {
    postalcode: '25836',
    city: 'Osterhever',
    countrycode: 'DE',
  },
  {
    postalcode: '25836',
    city: 'Poppenbüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25836',
    city: 'Vollerwiek',
    countrycode: 'DE',
  },
  {
    postalcode: '25836',
    city: 'Welt',
    countrycode: 'DE',
  },
  {
    postalcode: '25840',
    city: 'An der Chaussee',
    countrycode: 'DE',
  },
  {
    postalcode: '25840',
    city: 'Feddersdeich',
    countrycode: 'DE',
  },
  {
    postalcode: '25840',
    city: 'Friedrichstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '25840',
    city: 'Hakenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '25840',
    city: 'Koldenbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '25840',
    city: 'Mildterhof',
    countrycode: 'DE',
  },
  {
    postalcode: '25840',
    city: 'Peterskoogsdeich',
    countrycode: 'DE',
  },
  {
    postalcode: '25840',
    city: 'Rantrumdeich',
    countrycode: 'DE',
  },
  {
    postalcode: '25840',
    city: 'Ziegelhof',
    countrycode: 'DE',
  },
  {
    postalcode: '25842',
    city: 'Addebüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25842',
    city: 'Bargum',
    countrycode: 'DE',
  },
  {
    postalcode: '25842',
    city: 'Langenhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '25842',
    city: 'Lütjenholm',
    countrycode: 'DE',
  },
  {
    postalcode: '25842',
    city: 'Ockholm',
    countrycode: 'DE',
  },
  {
    postalcode: '25845',
    city: 'Elisabeth-Sophien-Koog',
    countrycode: 'DE',
  },
  {
    postalcode: '25845',
    city: 'Nordstrand',
    countrycode: 'DE',
  },
  {
    postalcode: '25845',
    city: 'Nordstrandischmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '25845',
    city: 'Südfall',
    countrycode: 'DE',
  },
  {
    postalcode: '25849',
    city: 'Pellworm',
    countrycode: 'DE',
  },
  {
    postalcode: '25849',
    city: 'Süderoog',
    countrycode: 'DE',
  },
  {
    postalcode: '25850',
    city: 'Behrendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25850',
    city: 'Bondelum',
    countrycode: 'DE',
  },
  {
    postalcode: '25852',
    city: 'Bordelum',
    countrycode: 'DE',
  },
  {
    postalcode: '25853',
    city: 'Ahrenshöft',
    countrycode: 'DE',
  },
  {
    postalcode: '25853',
    city: 'Bohmstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25853',
    city: 'Drelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25855',
    city: 'Haselund',
    countrycode: 'DE',
  },
  {
    postalcode: '25856',
    city: 'Hattstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25856',
    city: 'Hattstedtermarsch',
    countrycode: 'DE',
  },
  {
    postalcode: '25856',
    city: 'Wobbenbüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25858',
    city: 'Högel',
    countrycode: 'DE',
  },
  {
    postalcode: '25859',
    city: 'Hooge',
    countrycode: 'DE',
  },
  {
    postalcode: '25860',
    city: 'Arlewatt',
    countrycode: 'DE',
  },
  {
    postalcode: '25860',
    city: 'Horstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25860',
    city: 'Olderup',
    countrycode: 'DE',
  },
  {
    postalcode: '25862',
    city: 'Goldebek',
    countrycode: 'DE',
  },
  {
    postalcode: '25862',
    city: 'Goldelund',
    countrycode: 'DE',
  },
  {
    postalcode: '25862',
    city: 'Joldelund',
    countrycode: 'DE',
  },
  {
    postalcode: '25862',
    city: 'Kolkerheide',
    countrycode: 'DE',
  },
  {
    postalcode: '25863',
    city: 'Langeneß',
    countrycode: 'DE',
  },
  {
    postalcode: '25864',
    city: 'Löwenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25866',
    city: 'Mildstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25868',
    city: 'Erfderdamm',
    countrycode: 'DE',
  },
  {
    postalcode: '25868',
    city: 'Norderstapel',
    countrycode: 'DE',
  },
  {
    postalcode: '25868',
    city: 'Steinschleuse',
    countrycode: 'DE',
  },
  {
    postalcode: '25869',
    city: 'Gröde',
    countrycode: 'DE',
  },
  {
    postalcode: '25869',
    city: 'Habel',
    countrycode: 'DE',
  },
  {
    postalcode: '25869',
    city: 'Oland',
    countrycode: 'DE',
  },
  {
    postalcode: '25870',
    city: 'Norderfriedrichskoog',
    countrycode: 'DE',
  },
  {
    postalcode: '25870',
    city: 'Oldenswort',
    countrycode: 'DE',
  },
  {
    postalcode: '25872',
    city: 'Ostenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25872',
    city: 'Wittbek',
    countrycode: 'DE',
  },
  {
    postalcode: '25873',
    city: 'Oldersbek',
    countrycode: 'DE',
  },
  {
    postalcode: '25873',
    city: 'Rantrum',
    countrycode: 'DE',
  },
  {
    postalcode: '25875',
    city: 'Schobüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25876',
    city: 'Fresendelf',
    countrycode: 'DE',
  },
  {
    postalcode: '25876',
    city: 'Hude',
    countrycode: 'DE',
  },
  {
    postalcode: '25876',
    city: 'Huderfähre',
    countrycode: 'DE',
  },
  {
    postalcode: '25876',
    city: 'Ramstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25876',
    city: 'Schwabstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25876',
    city: 'Süderhöft',
    countrycode: 'DE',
  },
  {
    postalcode: '25876',
    city: 'Wisch',
    countrycode: 'DE',
  },
  {
    postalcode: '25878',
    city: 'Drage',
    countrycode: 'DE',
  },
  {
    postalcode: '25878',
    city: 'Jeppern',
    countrycode: 'DE',
  },
  {
    postalcode: '25878',
    city: 'Krelau',
    countrycode: 'DE',
  },
  {
    postalcode: '25878',
    city: 'Seeth',
    countrycode: 'DE',
  },
  {
    postalcode: '25879',
    city: 'Süderstapel',
    countrycode: 'DE',
  },
  {
    postalcode: '25881',
    city: 'Tating',
    countrycode: 'DE',
  },
  {
    postalcode: '25881',
    city: 'Tümlauer Koog',
    countrycode: 'DE',
  },
  {
    postalcode: '25881',
    city: 'Westerhever',
    countrycode: 'DE',
  },
  {
    postalcode: '25882',
    city: 'Tetenbüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25884',
    city: 'Norstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25884',
    city: 'Sollwitt',
    countrycode: 'DE',
  },
  {
    postalcode: '25884',
    city: 'Viöl',
    countrycode: 'DE',
  },
  {
    postalcode: '25885',
    city: 'Ahrenviöl',
    countrycode: 'DE',
  },
  {
    postalcode: '25885',
    city: 'Ahrenviölfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '25885',
    city: 'Immenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25885',
    city: 'Oster-Ohrstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25885',
    city: 'Wester-Ohrstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25887',
    city: 'Winnert',
    countrycode: 'DE',
  },
  {
    postalcode: '25889',
    city: 'Uelvesbüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25889',
    city: 'Witzwort',
    countrycode: 'DE',
  },
  {
    postalcode: '25899',
    city: 'Bosbüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25899',
    city: 'Dagebüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25899',
    city: 'Galmsbüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25899',
    city: 'Gasthafen',
    countrycode: 'DE',
  },
  {
    postalcode: '25899',
    city: 'Gotteskoogdeich',
    countrycode: 'DE',
  },
  {
    postalcode: '25899',
    city: 'Katzhörn',
    countrycode: 'DE',
  },
  {
    postalcode: '25899',
    city: 'Kleinkoogsdeich',
    countrycode: 'DE',
  },
  {
    postalcode: '25899',
    city: 'Klixbüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25899',
    city: 'Niebüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25899',
    city: 'Osterdeich',
    countrycode: 'DE',
  },
  {
    postalcode: '25917',
    city: 'Achtrup',
    countrycode: 'DE',
  },
  {
    postalcode: '25917',
    city: 'Enge-Sande',
    countrycode: 'DE',
  },
  {
    postalcode: '25917',
    city: 'Leck',
    countrycode: 'DE',
  },
  {
    postalcode: '25917',
    city: 'Sprakebüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25917',
    city: 'Stadum',
    countrycode: 'DE',
  },
  {
    postalcode: '25917',
    city: 'Tinningstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25920',
    city: 'Risum-Lindholm',
    countrycode: 'DE',
  },
  {
    postalcode: '25920',
    city: 'Stedesand',
    countrycode: 'DE',
  },
  {
    postalcode: '25923',
    city: 'Braderup',
    countrycode: 'DE',
  },
  {
    postalcode: '25923',
    city: 'Ellhöft',
    countrycode: 'DE',
  },
  {
    postalcode: '25923',
    city: 'Hattersbüllhallig',
    countrycode: 'DE',
  },
  {
    postalcode: '25923',
    city: 'Holm',
    countrycode: 'DE',
  },
  {
    postalcode: '25923',
    city: 'Humptrup',
    countrycode: 'DE',
  },
  {
    postalcode: '25923',
    city: 'Lexgaard',
    countrycode: 'DE',
  },
  {
    postalcode: '25923',
    city: 'Süderlügum',
    countrycode: 'DE',
  },
  {
    postalcode: '25923',
    city: 'Uphusum',
    countrycode: 'DE',
  },
  {
    postalcode: '25924',
    city: 'Emmelsbüll-Horsbüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25924',
    city: 'Friedrich-Wilhelm-Lübke-Koog',
    countrycode: 'DE',
  },
  {
    postalcode: '25924',
    city: 'Klanxbüll',
    countrycode: 'DE',
  },
  {
    postalcode: '25924',
    city: 'Rodenäs',
    countrycode: 'DE',
  },
  {
    postalcode: '25926',
    city: 'Bramstedtlund',
    countrycode: 'DE',
  },
  {
    postalcode: '25926',
    city: 'Karlum',
    countrycode: 'DE',
  },
  {
    postalcode: '25926',
    city: 'Ladelund',
    countrycode: 'DE',
  },
  {
    postalcode: '25926',
    city: 'Westre',
    countrycode: 'DE',
  },
  {
    postalcode: '25927',
    city: 'Aventoft',
    countrycode: 'DE',
  },
  {
    postalcode: '25927',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '25938',
    city: 'Alkersum',
    countrycode: 'DE',
  },
  {
    postalcode: '25938',
    city: 'Borgsum',
    countrycode: 'DE',
  },
  {
    postalcode: '25938',
    city: 'Dunsum',
    countrycode: 'DE',
  },
  {
    postalcode: '25938',
    city: 'Midlum',
    countrycode: 'DE',
  },
  {
    postalcode: '25938',
    city: 'Nieblum',
    countrycode: 'DE',
  },
  {
    postalcode: '25938',
    city: 'Oevenum',
    countrycode: 'DE',
  },
  {
    postalcode: '25938',
    city: 'Oldsum',
    countrycode: 'DE',
  },
  {
    postalcode: '25938',
    city: 'Süderende',
    countrycode: 'DE',
  },
  {
    postalcode: '25938',
    city: 'Utersum',
    countrycode: 'DE',
  },
  {
    postalcode: '25938',
    city: 'Witsum',
    countrycode: 'DE',
  },
  {
    postalcode: '25938',
    city: 'Wrixum',
    countrycode: 'DE',
  },
  {
    postalcode: '25938',
    city: 'Wyk',
    countrycode: 'DE',
  },
  {
    postalcode: '25946',
    city: 'Nebel',
    countrycode: 'DE',
  },
  {
    postalcode: '25946',
    city: 'Norddorf',
    countrycode: 'DE',
  },
  {
    postalcode: '25946',
    city: 'Wittdün',
    countrycode: 'DE',
  },
  {
    postalcode: '25980',
    city: 'Rantum',
    countrycode: 'DE',
  },
  {
    postalcode: '25980',
    city: 'Sylt-Ost',
    countrycode: 'DE',
  },
  {
    postalcode: '25980',
    city: 'Westerland',
    countrycode: 'DE',
  },
  {
    postalcode: '25992',
    city: 'Jugendseeheim Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '25992',
    city: 'List',
    countrycode: 'DE',
  },
  {
    postalcode: '25992',
    city: 'Vogelkoje',
    countrycode: 'DE',
  },
  {
    postalcode: '25996',
    city: 'Wenningstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '25997',
    city: 'Hörnum',
    countrycode: 'DE',
  },
  {
    postalcode: '25999',
    city: 'Kampen',
    countrycode: 'DE',
  },
  {
    postalcode: '26121',
    city: 'Oldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26122',
    city: 'Oldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26123',
    city: 'Oldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26125',
    city: 'Oldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26127',
    city: 'Oldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26129',
    city: 'Oldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26131',
    city: 'Oldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26133',
    city: 'Oldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26135',
    city: 'Oldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26160',
    city: 'Bad Zwischenahn',
    countrycode: 'DE',
  },
  {
    postalcode: '26169',
    city: 'Friesoythe',
    countrycode: 'DE',
  },
  {
    postalcode: '26180',
    city: 'Rastede',
    countrycode: 'DE',
  },
  {
    postalcode: '26188',
    city: 'Edewecht',
    countrycode: 'DE',
  },
  {
    postalcode: '26197',
    city: 'Großenkneten',
    countrycode: 'DE',
  },
  {
    postalcode: '26203',
    city: 'Wardenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26209',
    city: 'Hatten',
    countrycode: 'DE',
  },
  {
    postalcode: '26215',
    city: 'Wiefelstede',
    countrycode: 'DE',
  },
  {
    postalcode: '26219',
    city: 'Bösel',
    countrycode: 'DE',
  },
  {
    postalcode: '26316',
    city: 'Varel',
    countrycode: 'DE',
  },
  {
    postalcode: '26340',
    city: 'Zetel',
    countrycode: 'DE',
  },
  {
    postalcode: '26345',
    city: 'Bockhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '26349',
    city: 'Jade',
    countrycode: 'DE',
  },
  {
    postalcode: '26382',
    city: 'Wilhelmshaven',
    countrycode: 'DE',
  },
  {
    postalcode: '26384',
    city: 'Wilhelmshaven',
    countrycode: 'DE',
  },
  {
    postalcode: '26386',
    city: 'Wilhelmshaven',
    countrycode: 'DE',
  },
  {
    postalcode: '26388',
    city: 'Wilhelmshaven',
    countrycode: 'DE',
  },
  {
    postalcode: '26389',
    city: 'Wilhelmshaven',
    countrycode: 'DE',
  },
  {
    postalcode: '26409',
    city: 'Knyphauserwald',
    countrycode: 'DE',
  },
  {
    postalcode: '26409',
    city: 'Pfahlhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '26409',
    city: 'Wittmund',
    countrycode: 'DE',
  },
  {
    postalcode: '26419',
    city: 'Schortens',
    countrycode: 'DE',
  },
  {
    postalcode: '26427',
    city: 'Dunum',
    countrycode: 'DE',
  },
  {
    postalcode: '26427',
    city: 'Esens',
    countrycode: 'DE',
  },
  {
    postalcode: '26427',
    city: 'Holtgast',
    countrycode: 'DE',
  },
  {
    postalcode: '26427',
    city: 'Moorweg',
    countrycode: 'DE',
  },
  {
    postalcode: '26427',
    city: 'Neuharlingersiel',
    countrycode: 'DE',
  },
  {
    postalcode: '26427',
    city: 'Stedesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '26427',
    city: 'Werdum',
    countrycode: 'DE',
  },
  {
    postalcode: '26427',
    city: 'Westerburer Polder',
    countrycode: 'DE',
  },
  {
    postalcode: '26434',
    city: 'Wangerland',
    countrycode: 'DE',
  },
  {
    postalcode: '26441',
    city: 'Groß Hauskreuz',
    countrycode: 'DE',
  },
  {
    postalcode: '26441',
    city: 'Jever',
    countrycode: 'DE',
  },
  {
    postalcode: '26441',
    city: 'Streitfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '26446',
    city: 'Friedeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26452',
    city: 'Sande',
    countrycode: 'DE',
  },
  {
    postalcode: '26465',
    city: 'Langeoog',
    countrycode: 'DE',
  },
  {
    postalcode: '26474',
    city: 'Spiekeroog',
    countrycode: 'DE',
  },
  {
    postalcode: '26486',
    city: 'Wangerooge',
    countrycode: 'DE',
  },
  {
    postalcode: '26487',
    city: 'Blomberg',
    countrycode: 'DE',
  },
  {
    postalcode: '26487',
    city: 'Neuschoo',
    countrycode: 'DE',
  },
  {
    postalcode: '26489',
    city: 'Ochtersum',
    countrycode: 'DE',
  },
  {
    postalcode: '26506',
    city: 'Norden',
    countrycode: 'DE',
  },
  {
    postalcode: '26524',
    city: 'Berumbur',
    countrycode: 'DE',
  },
  {
    postalcode: '26524',
    city: 'Hage',
    countrycode: 'DE',
  },
  {
    postalcode: '26524',
    city: 'Hagermarsch',
    countrycode: 'DE',
  },
  {
    postalcode: '26524',
    city: 'Halbemond',
    countrycode: 'DE',
  },
  {
    postalcode: '26524',
    city: 'Lütetsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26529',
    city: 'Leezdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '26529',
    city: 'Marienhafe',
    countrycode: 'DE',
  },
  {
    postalcode: '26529',
    city: 'Osteel',
    countrycode: 'DE',
  },
  {
    postalcode: '26529',
    city: 'Rechtsupweg',
    countrycode: 'DE',
  },
  {
    postalcode: '26529',
    city: 'Upgant-Schott',
    countrycode: 'DE',
  },
  {
    postalcode: '26529',
    city: 'Wirdum',
    countrycode: 'DE',
  },
  {
    postalcode: '26532',
    city: 'Großheide',
    countrycode: 'DE',
  },
  {
    postalcode: '26548',
    city: 'Norderney',
    countrycode: 'DE',
  },
  {
    postalcode: '26553',
    city: 'Dornum',
    countrycode: 'DE',
  },
  {
    postalcode: '26556',
    city: 'Eversmeer',
    countrycode: 'DE',
  },
  {
    postalcode: '26556',
    city: 'Nenndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '26556',
    city: 'Schweindorf',
    countrycode: 'DE',
  },
  {
    postalcode: '26556',
    city: 'Utarp',
    countrycode: 'DE',
  },
  {
    postalcode: '26556',
    city: 'Westerholt',
    countrycode: 'DE',
  },
  {
    postalcode: '26571',
    city: 'Juist',
    countrycode: 'DE',
  },
  {
    postalcode: '26571',
    city: 'Nordseeinsel Memmert',
    countrycode: 'DE',
  },
  {
    postalcode: '26579',
    city: 'Baltrum',
    countrycode: 'DE',
  },
  {
    postalcode: '26603',
    city: 'Aurich',
    countrycode: 'DE',
  },
  {
    postalcode: '26605',
    city: 'Aurich',
    countrycode: 'DE',
  },
  {
    postalcode: '26607',
    city: 'Aurich',
    countrycode: 'DE',
  },
  {
    postalcode: '26624',
    city: 'Südbrookmerland',
    countrycode: 'DE',
  },
  {
    postalcode: '26629',
    city: 'Großefehn',
    countrycode: 'DE',
  },
  {
    postalcode: '26632',
    city: 'Ihlow',
    countrycode: 'DE',
  },
  {
    postalcode: '26639',
    city: 'Wiesmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '26655',
    city: 'Westerstede',
    countrycode: 'DE',
  },
  {
    postalcode: '26670',
    city: 'Uplengen',
    countrycode: 'DE',
  },
  {
    postalcode: '26676',
    city: 'Barßel',
    countrycode: 'DE',
  },
  {
    postalcode: '26683',
    city: 'Saterland',
    countrycode: 'DE',
  },
  {
    postalcode: '26689',
    city: 'Apen',
    countrycode: 'DE',
  },
  {
    postalcode: '26721',
    city: 'Emden',
    countrycode: 'DE',
  },
  {
    postalcode: '26723',
    city: 'Emden',
    countrycode: 'DE',
  },
  {
    postalcode: '26725',
    city: 'Emden',
    countrycode: 'DE',
  },
  {
    postalcode: '26736',
    city: 'Krummhörn',
    countrycode: 'DE',
  },
  {
    postalcode: '26757',
    city: 'Borkum',
    countrycode: 'DE',
  },
  {
    postalcode: '26759',
    city: 'Hinte',
    countrycode: 'DE',
  },
  {
    postalcode: '26759',
    city: 'Klein Heikeland',
    countrycode: 'DE',
  },
  {
    postalcode: '26789',
    city: 'Leer',
    countrycode: 'DE',
  },
  {
    postalcode: '26802',
    city: 'Grovehörn',
    countrycode: 'DE',
  },
  {
    postalcode: '26802',
    city: 'Moormerland',
    countrycode: 'DE',
  },
  {
    postalcode: '26810',
    city: 'Westoverledingen',
    countrycode: 'DE',
  },
  {
    postalcode: '26817',
    city: 'Rhauderfehn',
    countrycode: 'DE',
  },
  {
    postalcode: '26826',
    city: 'Weener',
    countrycode: 'DE',
  },
  {
    postalcode: '26831',
    city: 'Boen',
    countrycode: 'DE',
  },
  {
    postalcode: '26831',
    city: 'Bunde',
    countrycode: 'DE',
  },
  {
    postalcode: '26831',
    city: 'Bunderhee',
    countrycode: 'DE',
  },
  {
    postalcode: '26831',
    city: 'Dollart',
    countrycode: 'DE',
  },
  {
    postalcode: '26831',
    city: 'Wymeer',
    countrycode: 'DE',
  },
  {
    postalcode: '26835',
    city: 'Brinkum',
    countrycode: 'DE',
  },
  {
    postalcode: '26835',
    city: 'Firrel',
    countrycode: 'DE',
  },
  {
    postalcode: '26835',
    city: 'Hesel',
    countrycode: 'DE',
  },
  {
    postalcode: '26835',
    city: 'Holtland',
    countrycode: 'DE',
  },
  {
    postalcode: '26835',
    city: 'Neukamperfehn',
    countrycode: 'DE',
  },
  {
    postalcode: '26835',
    city: 'Schwerinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '26842',
    city: 'Ostrhauderfehn',
    countrycode: 'DE',
  },
  {
    postalcode: '26844',
    city: 'Jemgum',
    countrycode: 'DE',
  },
  {
    postalcode: '26845',
    city: 'Nortmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '26847',
    city: 'Detern',
    countrycode: 'DE',
  },
  {
    postalcode: '26849',
    city: 'Filsum',
    countrycode: 'DE',
  },
  {
    postalcode: '26871',
    city: 'Aschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '26871',
    city: 'Papenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '26892',
    city: 'Dörpen',
    countrycode: 'DE',
  },
  {
    postalcode: '26892',
    city: 'Heede',
    countrycode: 'DE',
  },
  {
    postalcode: '26892',
    city: 'Kluse',
    countrycode: 'DE',
  },
  {
    postalcode: '26892',
    city: 'Lehe',
    countrycode: 'DE',
  },
  {
    postalcode: '26892',
    city: 'Wippingen',
    countrycode: 'DE',
  },
  {
    postalcode: '26897',
    city: 'Bockhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '26897',
    city: 'Breddenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '26897',
    city: 'Esterwegen',
    countrycode: 'DE',
  },
  {
    postalcode: '26897',
    city: 'Hilkenbrook',
    countrycode: 'DE',
  },
  {
    postalcode: '26899',
    city: 'Rhede',
    countrycode: 'DE',
  },
  {
    postalcode: '26901',
    city: 'Lorup',
    countrycode: 'DE',
  },
  {
    postalcode: '26901',
    city: 'Rastdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '26903',
    city: 'Surwold',
    countrycode: 'DE',
  },
  {
    postalcode: '26904',
    city: 'Börger',
    countrycode: 'DE',
  },
  {
    postalcode: '26906',
    city: 'Dersum',
    countrycode: 'DE',
  },
  {
    postalcode: '26907',
    city: 'Walchum',
    countrycode: 'DE',
  },
  {
    postalcode: '26909',
    city: 'Neubörger',
    countrycode: 'DE',
  },
  {
    postalcode: '26909',
    city: 'Neulehe',
    countrycode: 'DE',
  },
  {
    postalcode: '26919',
    city: 'Brake',
    countrycode: 'DE',
  },
  {
    postalcode: '26931',
    city: 'Elsfleth',
    countrycode: 'DE',
  },
  {
    postalcode: '26935',
    city: 'Stadland',
    countrycode: 'DE',
  },
  {
    postalcode: '26936',
    city: 'Stadland',
    countrycode: 'DE',
  },
  {
    postalcode: '26937',
    city: 'Stadland',
    countrycode: 'DE',
  },
  {
    postalcode: '26939',
    city: 'Ovelgönne',
    countrycode: 'DE',
  },
  {
    postalcode: '26954',
    city: 'Nordenham',
    countrycode: 'DE',
  },
  {
    postalcode: '26969',
    city: 'Butjadingen',
    countrycode: 'DE',
  },
  {
    postalcode: '27211',
    city: 'Bassum',
    countrycode: 'DE',
  },
  {
    postalcode: '27232',
    city: 'Sulingen',
    countrycode: 'DE',
  },
  {
    postalcode: '27239',
    city: 'Twistringen',
    countrycode: 'DE',
  },
  {
    postalcode: '27243',
    city: 'Beckeln',
    countrycode: 'DE',
  },
  {
    postalcode: '27243',
    city: 'Colnrade',
    countrycode: 'DE',
  },
  {
    postalcode: '27243',
    city: 'Dünsen',
    countrycode: 'DE',
  },
  {
    postalcode: '27243',
    city: 'Groß Ippener',
    countrycode: 'DE',
  },
  {
    postalcode: '27243',
    city: 'Harpstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27243',
    city: 'Kirchseelte',
    countrycode: 'DE',
  },
  {
    postalcode: '27243',
    city: 'Prinzhöfte',
    countrycode: 'DE',
  },
  {
    postalcode: '27243',
    city: 'Winkelsett',
    countrycode: 'DE',
  },
  {
    postalcode: '27245',
    city: 'Bahrenborstel',
    countrycode: 'DE',
  },
  {
    postalcode: '27245',
    city: 'Barenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '27245',
    city: 'Kirchdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '27246',
    city: 'Borstel',
    countrycode: 'DE',
  },
  {
    postalcode: '27248',
    city: 'Ehrenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '27249',
    city: 'Maasen',
    countrycode: 'DE',
  },
  {
    postalcode: '27249',
    city: 'Mellinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '27251',
    city: 'Neuenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '27251',
    city: 'Scholen',
    countrycode: 'DE',
  },
  {
    postalcode: '27252',
    city: 'Schwaförden',
    countrycode: 'DE',
  },
  {
    postalcode: '27254',
    city: 'Siedenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '27254',
    city: 'Staffhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '27257',
    city: 'Affinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '27257',
    city: 'Sudwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '27259',
    city: 'Freistatt',
    countrycode: 'DE',
  },
  {
    postalcode: '27259',
    city: 'Varrel',
    countrycode: 'DE',
  },
  {
    postalcode: '27259',
    city: 'Wehrbleck',
    countrycode: 'DE',
  },
  {
    postalcode: '27283',
    city: 'Verden',
    countrycode: 'DE',
  },
  {
    postalcode: '27299',
    city: 'Langwedel',
    countrycode: 'DE',
  },
  {
    postalcode: '27305',
    city: 'Bruchhausen-Vilsen',
    countrycode: 'DE',
  },
  {
    postalcode: '27305',
    city: 'Engeln',
    countrycode: 'DE',
  },
  {
    postalcode: '27305',
    city: 'Süstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27308',
    city: 'Kirchlinteln',
    countrycode: 'DE',
  },
  {
    postalcode: '27313',
    city: 'Dörverden',
    countrycode: 'DE',
  },
  {
    postalcode: '27318',
    city: 'Hilgermissen',
    countrycode: 'DE',
  },
  {
    postalcode: '27318',
    city: 'Hoya',
    countrycode: 'DE',
  },
  {
    postalcode: '27318',
    city: 'Hoyerhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '27321',
    city: 'Emtinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '27321',
    city: 'Morsum',
    countrycode: 'DE',
  },
  {
    postalcode: '27321',
    city: 'Thedinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '27324',
    city: 'Eystrup',
    countrycode: 'DE',
  },
  {
    postalcode: '27324',
    city: 'Gandesbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '27324',
    city: 'Hassel',
    countrycode: 'DE',
  },
  {
    postalcode: '27324',
    city: 'Hämelhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '27324',
    city: 'Schweringen, Ziegelei',
    countrycode: 'DE',
  },
  {
    postalcode: '27327',
    city: 'Martfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '27327',
    city: 'Schwarme',
    countrycode: 'DE',
  },
  {
    postalcode: '27330',
    city: 'Asendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '27333',
    city: 'Bücken',
    countrycode: 'DE',
  },
  {
    postalcode: '27333',
    city: 'Schweringen',
    countrycode: 'DE',
  },
  {
    postalcode: '27333',
    city: 'Warpe',
    countrycode: 'DE',
  },
  {
    postalcode: '27336',
    city: 'Frankenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '27336',
    city: 'Häuslingen',
    countrycode: 'DE',
  },
  {
    postalcode: '27336',
    city: 'Rethem',
    countrycode: 'DE',
  },
  {
    postalcode: '27336',
    city: 'Rethemer Fähre',
    countrycode: 'DE',
  },
  {
    postalcode: '27337',
    city: 'Blender',
    countrycode: 'DE',
  },
  {
    postalcode: '27339',
    city: 'Riede',
    countrycode: 'DE',
  },
  {
    postalcode: '27356',
    city: 'Rotenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '27367',
    city: 'Ahausen',
    countrycode: 'DE',
  },
  {
    postalcode: '27367',
    city: 'Bötersen',
    countrycode: 'DE',
  },
  {
    postalcode: '27367',
    city: 'Hassendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '27367',
    city: 'Hellwege',
    countrycode: 'DE',
  },
  {
    postalcode: '27367',
    city: 'Horstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27367',
    city: 'Reeßum',
    countrycode: 'DE',
  },
  {
    postalcode: '27367',
    city: 'Sottrum',
    countrycode: 'DE',
  },
  {
    postalcode: '27374',
    city: 'Visselhövede',
    countrycode: 'DE',
  },
  {
    postalcode: '27383',
    city: 'Scheeßel',
    countrycode: 'DE',
  },
  {
    postalcode: '27386',
    city: 'Bothel',
    countrycode: 'DE',
  },
  {
    postalcode: '27386',
    city: 'Brockel',
    countrycode: 'DE',
  },
  {
    postalcode: '27386',
    city: 'Hemsbünde',
    countrycode: 'DE',
  },
  {
    postalcode: '27386',
    city: 'Hemslingen',
    countrycode: 'DE',
  },
  {
    postalcode: '27386',
    city: 'Kirchwalsede',
    countrycode: 'DE',
  },
  {
    postalcode: '27386',
    city: 'Westerwalsede',
    countrycode: 'DE',
  },
  {
    postalcode: '27389',
    city: 'Fintel',
    countrycode: 'DE',
  },
  {
    postalcode: '27389',
    city: 'Helvesiek',
    countrycode: 'DE',
  },
  {
    postalcode: '27389',
    city: 'Lauenbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '27389',
    city: 'Stemmen',
    countrycode: 'DE',
  },
  {
    postalcode: '27389',
    city: 'Vahlde',
    countrycode: 'DE',
  },
  {
    postalcode: '27404',
    city: 'Elsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '27404',
    city: 'Gyhum',
    countrycode: 'DE',
  },
  {
    postalcode: '27404',
    city: 'Heeslingen',
    countrycode: 'DE',
  },
  {
    postalcode: '27404',
    city: 'Ostereistedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27404',
    city: 'Rhade',
    countrycode: 'DE',
  },
  {
    postalcode: '27404',
    city: 'Seedorf',
    countrycode: 'DE',
  },
  {
    postalcode: '27404',
    city: 'Zeven',
    countrycode: 'DE',
  },
  {
    postalcode: '27412',
    city: 'Breddorf',
    countrycode: 'DE',
  },
  {
    postalcode: '27412',
    city: 'Bülstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27412',
    city: 'Hepstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27412',
    city: 'Kirchtimke',
    countrycode: 'DE',
  },
  {
    postalcode: '27412',
    city: 'Tarmstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27412',
    city: 'Vorwerk',
    countrycode: 'DE',
  },
  {
    postalcode: '27412',
    city: 'Westertimke',
    countrycode: 'DE',
  },
  {
    postalcode: '27412',
    city: 'Wilstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27419',
    city: 'Groß Meckelsen',
    countrycode: 'DE',
  },
  {
    postalcode: '27419',
    city: 'Hamersen',
    countrycode: 'DE',
  },
  {
    postalcode: '27419',
    city: 'Kalbe',
    countrycode: 'DE',
  },
  {
    postalcode: '27419',
    city: 'Klein Meckelsen',
    countrycode: 'DE',
  },
  {
    postalcode: '27419',
    city: 'Lengenbostel',
    countrycode: 'DE',
  },
  {
    postalcode: '27419',
    city: 'Sittensen',
    countrycode: 'DE',
  },
  {
    postalcode: '27419',
    city: 'Tiste',
    countrycode: 'DE',
  },
  {
    postalcode: '27419',
    city: 'Vierden',
    countrycode: 'DE',
  },
  {
    postalcode: '27419',
    city: 'Wohnste',
    countrycode: 'DE',
  },
  {
    postalcode: '27432',
    city: 'Alfstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27432',
    city: 'Basdahl',
    countrycode: 'DE',
  },
  {
    postalcode: '27432',
    city: 'Bremervörde',
    countrycode: 'DE',
  },
  {
    postalcode: '27432',
    city: 'Ebersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '27432',
    city: 'Hipstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27432',
    city: 'Malse',
    countrycode: 'DE',
  },
  {
    postalcode: '27432',
    city: 'Oerel',
    countrycode: 'DE',
  },
  {
    postalcode: '27442',
    city: 'Gnarrenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '27446',
    city: 'Anderlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '27446',
    city: 'Deinstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27446',
    city: 'Farven',
    countrycode: 'DE',
  },
  {
    postalcode: '27446',
    city: 'Sandbostel',
    countrycode: 'DE',
  },
  {
    postalcode: '27446',
    city: 'Selsingen',
    countrycode: 'DE',
  },
  {
    postalcode: '27449',
    city: 'Kutenholz',
    countrycode: 'DE',
  },
  {
    postalcode: '27472',
    city: 'Cuxhaven',
    countrycode: 'DE',
  },
  {
    postalcode: '27474',
    city: 'Cuxhaven',
    countrycode: 'DE',
  },
  {
    postalcode: '27476',
    city: 'Cuxhaven',
    countrycode: 'DE',
  },
  {
    postalcode: '27478',
    city: 'Cuxhaven',
    countrycode: 'DE',
  },
  {
    postalcode: '27498',
    city: 'Helgoland',
    countrycode: 'DE',
  },
  {
    postalcode: '27499',
    city: 'Hamburg-Insel Neuwerk',
    countrycode: 'DE',
  },
  {
    postalcode: '27568',
    city: 'Bremerhaven',
    countrycode: 'DE',
  },
  {
    postalcode: '27570',
    city: 'Bremerhaven',
    countrycode: 'DE',
  },
  {
    postalcode: '27572',
    city: 'Bremerhaven',
    countrycode: 'DE',
  },
  {
    postalcode: '27574',
    city: 'Bremerhaven',
    countrycode: 'DE',
  },
  {
    postalcode: '27576',
    city: 'Bremerhaven',
    countrycode: 'DE',
  },
  {
    postalcode: '27578',
    city: 'Bremerhaven',
    countrycode: 'DE',
  },
  {
    postalcode: '27580',
    city: 'Bremerhaven',
    countrycode: 'DE',
  },
  {
    postalcode: '27607',
    city: 'Langen',
    countrycode: 'DE',
  },
  {
    postalcode: '27612',
    city: 'Loxstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27616',
    city: 'Appeln',
    countrycode: 'DE',
  },
  {
    postalcode: '27616',
    city: 'Beverstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27616',
    city: 'Bokel',
    countrycode: 'DE',
  },
  {
    postalcode: '27616',
    city: 'Frelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '27616',
    city: 'Heerstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27616',
    city: 'Hollen',
    countrycode: 'DE',
  },
  {
    postalcode: '27616',
    city: 'Kirchwistedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27616',
    city: 'Lunestedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27616',
    city: 'Stubben',
    countrycode: 'DE',
  },
  {
    postalcode: '27619',
    city: 'Schiffdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '27624',
    city: 'Bad Bederkesa',
    countrycode: 'DE',
  },
  {
    postalcode: '27624',
    city: 'Drangstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27624',
    city: 'Elmlohe',
    countrycode: 'DE',
  },
  {
    postalcode: '27624',
    city: 'Flögeln',
    countrycode: 'DE',
  },
  {
    postalcode: '27624',
    city: 'Köhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '27624',
    city: 'Kührstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27624',
    city: 'Lintig',
    countrycode: 'DE',
  },
  {
    postalcode: '27624',
    city: 'Ringstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27628',
    city: 'Bramstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27628',
    city: 'Driftsethe',
    countrycode: 'DE',
  },
  {
    postalcode: '27628',
    city: 'Hagen',
    countrycode: 'DE',
  },
  {
    postalcode: '27628',
    city: 'Sandstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27628',
    city: 'Uthlede',
    countrycode: 'DE',
  },
  {
    postalcode: '27628',
    city: 'Wulsbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '27632',
    city: 'Cappel',
    countrycode: 'DE',
  },
  {
    postalcode: '27632',
    city: 'Dorum',
    countrycode: 'DE',
  },
  {
    postalcode: '27632',
    city: 'Midlum',
    countrycode: 'DE',
  },
  {
    postalcode: '27632',
    city: 'Misselwarden',
    countrycode: 'DE',
  },
  {
    postalcode: '27632',
    city: 'Mulsum',
    countrycode: 'DE',
  },
  {
    postalcode: '27632',
    city: 'Padingbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '27637',
    city: 'Nordholz',
    countrycode: 'DE',
  },
  {
    postalcode: '27638',
    city: 'Wremen',
    countrycode: 'DE',
  },
  {
    postalcode: '27711',
    city: 'Osterholz-Scharmbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '27721',
    city: 'Ritterhude',
    countrycode: 'DE',
  },
  {
    postalcode: '27726',
    city: 'Breddorfermoor',
    countrycode: 'DE',
  },
  {
    postalcode: '27726',
    city: 'Worpswede',
    countrycode: 'DE',
  },
  {
    postalcode: '27729',
    city: 'Axstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27729',
    city: 'Hambergen',
    countrycode: 'DE',
  },
  {
    postalcode: '27729',
    city: 'Holste',
    countrycode: 'DE',
  },
  {
    postalcode: '27729',
    city: 'Lübberstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '27729',
    city: 'Vollersode',
    countrycode: 'DE',
  },
  {
    postalcode: '27749',
    city: 'Delmenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '27751',
    city: 'Delmenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '27753',
    city: 'Delmenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '27755',
    city: 'Delmenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '27777',
    city: 'Ganderkesee',
    countrycode: 'DE',
  },
  {
    postalcode: '27793',
    city: 'Wildeshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '27798',
    city: 'Hude',
    countrycode: 'DE',
  },
  {
    postalcode: '27801',
    city: 'Dötlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '27804',
    city: 'Berne',
    countrycode: 'DE',
  },
  {
    postalcode: '27809',
    city: 'Lemwerder',
    countrycode: 'DE',
  },
  {
    postalcode: '28195',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28197',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28199',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28201',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28203',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28205',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28207',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28209',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28211',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28213',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28215',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28217',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28219',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28237',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28239',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28259',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28277',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28279',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28307',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28309',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28325',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28327',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28329',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28355',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28357',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28359',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28717',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28719',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28755',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28757',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28759',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28777',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28779',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '28790',
    city: 'Schwanewede',
    countrycode: 'DE',
  },
  {
    postalcode: '28816',
    city: 'Stuhr',
    countrycode: 'DE',
  },
  {
    postalcode: '28832',
    city: 'Achim',
    countrycode: 'DE',
  },
  {
    postalcode: '28844',
    city: 'Weyhe',
    countrycode: 'DE',
  },
  {
    postalcode: '28857',
    city: 'Syke',
    countrycode: 'DE',
  },
  {
    postalcode: '28865',
    city: 'Lilienthal',
    countrycode: 'DE',
  },
  {
    postalcode: '28870',
    city: 'Ottersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '28876',
    city: 'Oyten',
    countrycode: 'DE',
  },
  {
    postalcode: '28879',
    city: 'Grasberg',
    countrycode: 'DE',
  },
  {
    postalcode: '29221',
    city: 'Celle',
    countrycode: 'DE',
  },
  {
    postalcode: '29223',
    city: 'Celle',
    countrycode: 'DE',
  },
  {
    postalcode: '29225',
    city: 'Celle',
    countrycode: 'DE',
  },
  {
    postalcode: '29227',
    city: 'Celle',
    countrycode: 'DE',
  },
  {
    postalcode: '29229',
    city: 'Celle',
    countrycode: 'DE',
  },
  {
    postalcode: '29229',
    city: 'Wittbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '29303',
    city: 'Bergen',
    countrycode: 'DE',
  },
  {
    postalcode: '29303',
    city: 'Lohheide, gemfr. Bezirk',
    countrycode: 'DE',
  },
  {
    postalcode: '29303',
    city: 'Miele',
    countrycode: 'DE',
  },
  {
    postalcode: '29303',
    city: 'Rehwinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '29308',
    city: 'Winsen',
    countrycode: 'DE',
  },
  {
    postalcode: '29313',
    city: 'Hambühren',
    countrycode: 'DE',
  },
  {
    postalcode: '29320',
    city: 'Hermannsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '29323',
    city: 'Wietze',
    countrycode: 'DE',
  },
  {
    postalcode: '29328',
    city: 'Faßberg',
    countrycode: 'DE',
  },
  {
    postalcode: '29331',
    city: 'Lachendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29336',
    city: 'Nienhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '29339',
    city: 'Wathlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '29342',
    city: 'Wienhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '29345',
    city: 'Unterlüß',
    countrycode: 'DE',
  },
  {
    postalcode: '29348',
    city: 'Eschede',
    countrycode: 'DE',
  },
  {
    postalcode: '29348',
    city: 'Scharnhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '29351',
    city: 'Eldingen',
    countrycode: 'DE',
  },
  {
    postalcode: '29352',
    city: 'Adelheidsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29353',
    city: 'Ahnsbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '29355',
    city: 'Beedenbostel',
    countrycode: 'DE',
  },
  {
    postalcode: '29356',
    city: 'Bröckel',
    countrycode: 'DE',
  },
  {
    postalcode: '29358',
    city: 'Eicklingen',
    countrycode: 'DE',
  },
  {
    postalcode: '29359',
    city: 'Habighorst',
    countrycode: 'DE',
  },
  {
    postalcode: '29361',
    city: 'Höfer',
    countrycode: 'DE',
  },
  {
    postalcode: '29362',
    city: 'Hohne',
    countrycode: 'DE',
  },
  {
    postalcode: '29364',
    city: 'Langlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '29365',
    city: 'Sprakensehl',
    countrycode: 'DE',
  },
  {
    postalcode: '29367',
    city: 'Steinhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '29369',
    city: 'Ummern',
    countrycode: 'DE',
  },
  {
    postalcode: '29378',
    city: 'Wittingen',
    countrycode: 'DE',
  },
  {
    postalcode: '29379',
    city: 'Wittingen',
    countrycode: 'DE',
  },
  {
    postalcode: '29386',
    city: 'Dedelstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29386',
    city: 'Hankensbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '29386',
    city: 'Obernholz',
    countrycode: 'DE',
  },
  {
    postalcode: '29389',
    city: 'Bad Bodenteich',
    countrycode: 'DE',
  },
  {
    postalcode: '29392',
    city: 'Wesendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29393',
    city: 'Groß Oesingen',
    countrycode: 'DE',
  },
  {
    postalcode: '29394',
    city: 'Lüder',
    countrycode: 'DE',
  },
  {
    postalcode: '29396',
    city: 'Schönewörde',
    countrycode: 'DE',
  },
  {
    postalcode: '29399',
    city: 'Wahrenholz',
    countrycode: 'DE',
  },
  {
    postalcode: '29410',
    city: 'Klein Chüden',
    countrycode: 'DE',
  },
  {
    postalcode: '29410',
    city: 'Ritze',
    countrycode: 'DE',
  },
  {
    postalcode: '29410',
    city: 'Salzwedel',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Bonese',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Bornsen',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Brietz',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Diesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Dähre',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Ellenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Gieseritz',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Groß Wieblitz',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Henningen',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Klein Wieblitz',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Lagendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Langenapel',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Mehmke',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Neuekrug',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Osterwohle',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Seebenau',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Tylsen',
    countrycode: 'DE',
  },
  {
    postalcode: '29413',
    city: 'Wallstawe',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Abbau Ader',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Altensalzwedel',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Benkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Bierstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Binde',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Dambeck',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Eversdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Fleetmark',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Gischau',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Groß Chüden',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Jeggeleben',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Kaulitz',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Kerkau',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Klein Gartz',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Kuhfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Liesten',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Mahlsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Mechau',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Pretzier',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Püggen',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Rademin',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Riebau',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Siedenlangenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Stappenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Steinitz',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Valfitz',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Vissum',
    countrycode: 'DE',
  },
  {
    postalcode: '29416',
    city: 'Winterfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '29439',
    city: 'Lüchow',
    countrycode: 'DE',
  },
  {
    postalcode: '29451',
    city: 'Dannenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '29456',
    city: 'Hitzacker',
    countrycode: 'DE',
  },
  {
    postalcode: '29459',
    city: 'Clenze',
    countrycode: 'DE',
  },
  {
    postalcode: '29462',
    city: 'Wustrow',
    countrycode: 'DE',
  },
  {
    postalcode: '29465',
    city: 'Schnega',
    countrycode: 'DE',
  },
  {
    postalcode: '29468',
    city: 'Bergen',
    countrycode: 'DE',
  },
  {
    postalcode: '29471',
    city: 'Gartow',
    countrycode: 'DE',
  },
  {
    postalcode: '29472',
    city: 'Damnatz',
    countrycode: 'DE',
  },
  {
    postalcode: '29473',
    city: 'Göhrde',
    countrycode: 'DE',
  },
  {
    postalcode: '29475',
    city: 'Gorleben',
    countrycode: 'DE',
  },
  {
    postalcode: '29476',
    city: 'Gusborn',
    countrycode: 'DE',
  },
  {
    postalcode: '29478',
    city: 'Höhbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '29479',
    city: 'Jameln',
    countrycode: 'DE',
  },
  {
    postalcode: '29481',
    city: 'Karwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '29482',
    city: 'Küsten',
    countrycode: 'DE',
  },
  {
    postalcode: '29484',
    city: 'Langendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29485',
    city: 'Lemgow',
    countrycode: 'DE',
  },
  {
    postalcode: '29487',
    city: 'Luckau',
    countrycode: 'DE',
  },
  {
    postalcode: '29488',
    city: 'Lübbow',
    countrycode: 'DE',
  },
  {
    postalcode: '29490',
    city: 'Neu Darchau',
    countrycode: 'DE',
  },
  {
    postalcode: '29491',
    city: 'Prezelle',
    countrycode: 'DE',
  },
  {
    postalcode: '29493',
    city: 'Schnackenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '29494',
    city: 'Trebel',
    countrycode: 'DE',
  },
  {
    postalcode: '29496',
    city: 'Waddeweitz',
    countrycode: 'DE',
  },
  {
    postalcode: '29497',
    city: 'Woltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29499',
    city: 'Zernien',
    countrycode: 'DE',
  },
  {
    postalcode: '29525',
    city: 'Uelzen',
    countrycode: 'DE',
  },
  {
    postalcode: '29549',
    city: 'Bad Bevensen',
    countrycode: 'DE',
  },
  {
    postalcode: '29553',
    city: 'Bienenbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '29556',
    city: 'Suderburg',
    countrycode: 'DE',
  },
  {
    postalcode: '29559',
    city: 'Wrestedt',
    countrycode: 'DE',
  },
  {
    postalcode: '29562',
    city: 'Suhlendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29565',
    city: 'Wriedel',
    countrycode: 'DE',
  },
  {
    postalcode: '29568',
    city: 'Wieren',
    countrycode: 'DE',
  },
  {
    postalcode: '29571',
    city: 'Rosche',
    countrycode: 'DE',
  },
  {
    postalcode: '29574',
    city: 'Ebstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29575',
    city: 'Altenmedingen',
    countrycode: 'DE',
  },
  {
    postalcode: '29576',
    city: 'Barum',
    countrycode: 'DE',
  },
  {
    postalcode: '29578',
    city: 'Eimke',
    countrycode: 'DE',
  },
  {
    postalcode: '29579',
    city: 'Emmendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29581',
    city: 'Gerdau',
    countrycode: 'DE',
  },
  {
    postalcode: '29582',
    city: 'Hanstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '29584',
    city: 'Himbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '29585',
    city: 'Jelmstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29587',
    city: 'Natendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29588',
    city: 'Oetzen',
    countrycode: 'DE',
  },
  {
    postalcode: '29590',
    city: 'Rätzlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '29591',
    city: 'Römstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '29593',
    city: 'Schwienau',
    countrycode: 'DE',
  },
  {
    postalcode: '29594',
    city: 'Soltendieck',
    countrycode: 'DE',
  },
  {
    postalcode: '29596',
    city: 'Stadensen',
    countrycode: 'DE',
  },
  {
    postalcode: '29597',
    city: 'Stoetze',
    countrycode: 'DE',
  },
  {
    postalcode: '29599',
    city: 'Weste',
    countrycode: 'DE',
  },
  {
    postalcode: '29614',
    city: 'Soltau',
    countrycode: 'DE',
  },
  {
    postalcode: '29633',
    city: 'Munster',
    countrycode: 'DE',
  },
  {
    postalcode: '29640',
    city: 'Heimbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '29640',
    city: 'Schneverdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '29643',
    city: 'Neuenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '29646',
    city: 'Bispingen',
    countrycode: 'DE',
  },
  {
    postalcode: '29649',
    city: 'Wietzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29664',
    city: 'Ostenholz',
    countrycode: 'DE',
  },
  {
    postalcode: '29664',
    city: 'Walsrode',
    countrycode: 'DE',
  },
  {
    postalcode: '29683',
    city: 'Fallingbostel',
    countrycode: 'DE',
  },
  {
    postalcode: '29683',
    city: 'Oerbke',
    countrycode: 'DE',
  },
  {
    postalcode: '29683',
    city: 'Wense',
    countrycode: 'DE',
  },
  {
    postalcode: '29690',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '29690',
    city: 'Essel',
    countrycode: 'DE',
  },
  {
    postalcode: '29690',
    city: 'Gilten',
    countrycode: 'DE',
  },
  {
    postalcode: '29690',
    city: 'Grethem',
    countrycode: 'DE',
  },
  {
    postalcode: '29690',
    city: 'Lindwedel',
    countrycode: 'DE',
  },
  {
    postalcode: '29690',
    city: 'Schwarmstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '29693',
    city: 'Ahlden',
    countrycode: 'DE',
  },
  {
    postalcode: '29693',
    city: 'Böhme',
    countrycode: 'DE',
  },
  {
    postalcode: '29693',
    city: 'Eickeloh',
    countrycode: 'DE',
  },
  {
    postalcode: '29693',
    city: 'Hademstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '29693',
    city: 'Hodenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '29693',
    city: 'Krelinger Bruch',
    countrycode: 'DE',
  },
  {
    postalcode: '29699',
    city: 'Bomlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '30159',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30161',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30163',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30165',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30167',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30169',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30171',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30173',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30175',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30177',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30179',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30419',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30449',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30451',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30453',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30455',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30457',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30459',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30519',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30521',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30539',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30559',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30625',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30627',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30629',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30655',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30657',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30659',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30669',
    city: 'Hannover',
    countrycode: 'DE',
  },
  {
    postalcode: '30823',
    city: 'Garbsen',
    countrycode: 'DE',
  },
  {
    postalcode: '30826',
    city: 'Garbsen',
    countrycode: 'DE',
  },
  {
    postalcode: '30827',
    city: 'Garbsen',
    countrycode: 'DE',
  },
  {
    postalcode: '30851',
    city: 'Langenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '30853',
    city: 'Langenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '30855',
    city: 'Langenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '30880',
    city: 'Laatzen',
    countrycode: 'DE',
  },
  {
    postalcode: '30890',
    city: 'Barsinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '30900',
    city: 'Wedemark',
    countrycode: 'DE',
  },
  {
    postalcode: '30916',
    city: 'Isernhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '30926',
    city: 'Seelze',
    countrycode: 'DE',
  },
  {
    postalcode: '30938',
    city: 'Burgwedel',
    countrycode: 'DE',
  },
  {
    postalcode: '30952',
    city: 'Ronnenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '30966',
    city: 'Hemmingen',
    countrycode: 'DE',
  },
  {
    postalcode: '30974',
    city: 'Wennigsen',
    countrycode: 'DE',
  },
  {
    postalcode: '30982',
    city: 'Pattensen',
    countrycode: 'DE',
  },
  {
    postalcode: '30989',
    city: 'Gehrden',
    countrycode: 'DE',
  },
  {
    postalcode: '31008',
    city: 'Elze',
    countrycode: 'DE',
  },
  {
    postalcode: '31020',
    city: 'Salzhemmendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '31028',
    city: 'Gronau',
    countrycode: 'DE',
  },
  {
    postalcode: '31029',
    city: 'Banteln',
    countrycode: 'DE',
  },
  {
    postalcode: '31032',
    city: 'Betheln',
    countrycode: 'DE',
  },
  {
    postalcode: '31033',
    city: 'Brüggen',
    countrycode: 'DE',
  },
  {
    postalcode: '31035',
    city: 'Despetal',
    countrycode: 'DE',
  },
  {
    postalcode: '31036',
    city: 'Eime',
    countrycode: 'DE',
  },
  {
    postalcode: '31039',
    city: 'Rheden',
    countrycode: 'DE',
  },
  {
    postalcode: '31061',
    city: 'Alfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '31073',
    city: 'Delligsen',
    countrycode: 'DE',
  },
  {
    postalcode: '31073',
    city: 'Grünenplan',
    countrycode: 'DE',
  },
  {
    postalcode: '31079',
    city: 'Adenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '31079',
    city: 'Almstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '31079',
    city: 'Eberholzen',
    countrycode: 'DE',
  },
  {
    postalcode: '31079',
    city: 'Sibbesse',
    countrycode: 'DE',
  },
  {
    postalcode: '31079',
    city: 'Westfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '31084',
    city: 'Freden',
    countrycode: 'DE',
  },
  {
    postalcode: '31085',
    city: 'Everode',
    countrycode: 'DE',
  },
  {
    postalcode: '31087',
    city: 'Landwehr',
    countrycode: 'DE',
  },
  {
    postalcode: '31088',
    city: 'Winzenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '31089',
    city: 'Duingen',
    countrycode: 'DE',
  },
  {
    postalcode: '31091',
    city: 'Coppengrave',
    countrycode: 'DE',
  },
  {
    postalcode: '31093',
    city: 'Hoyershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '31094',
    city: 'Marienhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '31096',
    city: 'Weenzen',
    countrycode: 'DE',
  },
  {
    postalcode: '31097',
    city: 'Harbarnsen',
    countrycode: 'DE',
  },
  {
    postalcode: '31099',
    city: 'Woltershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '31134',
    city: 'Hildesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '31135',
    city: 'Hildesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '31137',
    city: 'Hildesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '31139',
    city: 'Hildesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '31141',
    city: 'Hildesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '31157',
    city: 'Sarstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '31162',
    city: 'Bad Salzdetfurth',
    countrycode: 'DE',
  },
  {
    postalcode: '31167',
    city: 'Bockenem',
    countrycode: 'DE',
  },
  {
    postalcode: '31171',
    city: 'Nordstemmen',
    countrycode: 'DE',
  },
  {
    postalcode: '31174',
    city: 'Schellerten',
    countrycode: 'DE',
  },
  {
    postalcode: '31177',
    city: 'Harsum',
    countrycode: 'DE',
  },
  {
    postalcode: '31180',
    city: 'Giesen',
    countrycode: 'DE',
  },
  {
    postalcode: '31185',
    city: 'Söhlde',
    countrycode: 'DE',
  },
  {
    postalcode: '31188',
    city: 'Holle',
    countrycode: 'DE',
  },
  {
    postalcode: '31191',
    city: 'Algermissen',
    countrycode: 'DE',
  },
  {
    postalcode: '31195',
    city: 'Lamspringe',
    countrycode: 'DE',
  },
  {
    postalcode: '31195',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '31195',
    city: 'Wöllersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '31196',
    city: 'Sehlem',
    countrycode: 'DE',
  },
  {
    postalcode: '31199',
    city: 'Diekholzen',
    countrycode: 'DE',
  },
  {
    postalcode: '31224',
    city: 'Peine',
    countrycode: 'DE',
  },
  {
    postalcode: '31226',
    city: 'Peine',
    countrycode: 'DE',
  },
  {
    postalcode: '31228',
    city: 'Peine',
    countrycode: 'DE',
  },
  {
    postalcode: '31234',
    city: 'Edemissen',
    countrycode: 'DE',
  },
  {
    postalcode: '31241',
    city: 'Ilsede',
    countrycode: 'DE',
  },
  {
    postalcode: '31246',
    city: 'Lahstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '31249',
    city: 'Hohenhameln',
    countrycode: 'DE',
  },
  {
    postalcode: '31275',
    city: 'Lehrte',
    countrycode: 'DE',
  },
  {
    postalcode: '31303',
    city: 'Burgdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '31311',
    city: 'Uetze',
    countrycode: 'DE',
  },
  {
    postalcode: '31319',
    city: 'Sehnde',
    countrycode: 'DE',
  },
  {
    postalcode: '31515',
    city: 'Wunstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '31535',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '31542',
    city: 'Bad Nenndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '31547',
    city: 'Rehburg-Loccum',
    countrycode: 'DE',
  },
  {
    postalcode: '31552',
    city: 'Apelern',
    countrycode: 'DE',
  },
  {
    postalcode: '31552',
    city: 'Rodenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '31553',
    city: 'Auhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '31553',
    city: 'Sachsenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '31555',
    city: 'Suthfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '31556',
    city: 'Wölpinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '31558',
    city: 'Hagenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '31559',
    city: 'Haste',
    countrycode: 'DE',
  },
  {
    postalcode: '31559',
    city: 'Hohnhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '31582',
    city: 'Nienburg',
    countrycode: 'DE',
  },
  {
    postalcode: '31592',
    city: 'Stolzenau',
    countrycode: 'DE',
  },
  {
    postalcode: '31595',
    city: 'Steyerberg',
    countrycode: 'DE',
  },
  {
    postalcode: '31600',
    city: 'Uchte',
    countrycode: 'DE',
  },
  {
    postalcode: '31603',
    city: 'Diepenau',
    countrycode: 'DE',
  },
  {
    postalcode: '31604',
    city: 'Raddestorf',
    countrycode: 'DE',
  },
  {
    postalcode: '31606',
    city: 'Warmsen',
    countrycode: 'DE',
  },
  {
    postalcode: '31608',
    city: 'Marklohe',
    countrycode: 'DE',
  },
  {
    postalcode: '31609',
    city: 'Balge',
    countrycode: 'DE',
  },
  {
    postalcode: '31613',
    city: 'Wietzen',
    countrycode: 'DE',
  },
  {
    postalcode: '31618',
    city: 'Liebenau',
    countrycode: 'DE',
  },
  {
    postalcode: '31619',
    city: 'Binnen',
    countrycode: 'DE',
  },
  {
    postalcode: '31621',
    city: 'Pennigsehl',
    countrycode: 'DE',
  },
  {
    postalcode: '31622',
    city: 'Heemsen',
    countrycode: 'DE',
  },
  {
    postalcode: '31623',
    city: 'Drakenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '31626',
    city: 'Haßbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '31627',
    city: 'Rohrsen',
    countrycode: 'DE',
  },
  {
    postalcode: '31628',
    city: 'Landesbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '31629',
    city: 'Estorf',
    countrycode: 'DE',
  },
  {
    postalcode: '31632',
    city: 'Husum',
    countrycode: 'DE',
  },
  {
    postalcode: '31633',
    city: 'Leese',
    countrycode: 'DE',
  },
  {
    postalcode: '31634',
    city: 'Steimbke',
    countrycode: 'DE',
  },
  {
    postalcode: '31636',
    city: 'Linsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '31637',
    city: 'Rodewald',
    countrycode: 'DE',
  },
  {
    postalcode: '31638',
    city: 'Stöckse',
    countrycode: 'DE',
  },
  {
    postalcode: '31655',
    city: 'Stadthagen',
    countrycode: 'DE',
  },
  {
    postalcode: '31675',
    city: 'Bückeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '31683',
    city: 'Obernkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '31688',
    city: 'Nienstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '31691',
    city: 'Helpsen',
    countrycode: 'DE',
  },
  {
    postalcode: '31691',
    city: 'Seggebruch',
    countrycode: 'DE',
  },
  {
    postalcode: '31693',
    city: 'Hespe',
    countrycode: 'DE',
  },
  {
    postalcode: '31698',
    city: 'Beckedorfer Schacht',
    countrycode: 'DE',
  },
  {
    postalcode: '31698',
    city: 'Lindhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '31699',
    city: 'Beckedorf',
    countrycode: 'DE',
  },
  {
    postalcode: '31700',
    city: 'Heuerßen',
    countrycode: 'DE',
  },
  {
    postalcode: '31702',
    city: 'Lüdersfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '31707',
    city: 'Bad Eilsen',
    countrycode: 'DE',
  },
  {
    postalcode: '31707',
    city: 'Heeßen',
    countrycode: 'DE',
  },
  {
    postalcode: '31708',
    city: 'Ahnsen',
    countrycode: 'DE',
  },
  {
    postalcode: '31710',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '31711',
    city: 'Luhden',
    countrycode: 'DE',
  },
  {
    postalcode: '31712',
    city: 'Niedernwöhren',
    countrycode: 'DE',
  },
  {
    postalcode: '31714',
    city: 'Lauenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '31715',
    city: 'Meerbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '31717',
    city: 'Nordsehl',
    countrycode: 'DE',
  },
  {
    postalcode: '31718',
    city: 'Pollhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '31719',
    city: 'Wiedensahl',
    countrycode: 'DE',
  },
  {
    postalcode: '31737',
    city: 'Rinteln',
    countrycode: 'DE',
  },
  {
    postalcode: '31749',
    city: 'Auetal',
    countrycode: 'DE',
  },
  {
    postalcode: '31785',
    city: 'Hameln',
    countrycode: 'DE',
  },
  {
    postalcode: '31787',
    city: 'Hameln',
    countrycode: 'DE',
  },
  {
    postalcode: '31789',
    city: 'Hameln',
    countrycode: 'DE',
  },
  {
    postalcode: '31812',
    city: 'Bad Pyrmont',
    countrycode: 'DE',
  },
  {
    postalcode: '31832',
    city: 'Springe',
    countrycode: 'DE',
  },
  {
    postalcode: '31840',
    city: 'Hessisch Oldendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '31848',
    city: 'Bad Münder',
    countrycode: 'DE',
  },
  {
    postalcode: '31855',
    city: 'Aerzen',
    countrycode: 'DE',
  },
  {
    postalcode: '31860',
    city: 'Emmerthal',
    countrycode: 'DE',
  },
  {
    postalcode: '31863',
    city: 'Coppenbrügge',
    countrycode: 'DE',
  },
  {
    postalcode: '31867',
    city: 'Hülsede',
    countrycode: 'DE',
  },
  {
    postalcode: '31867',
    city: 'Lauenau',
    countrycode: 'DE',
  },
  {
    postalcode: '31867',
    city: 'Messenkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '31867',
    city: 'Pohle',
    countrycode: 'DE',
  },
  {
    postalcode: '31868',
    city: 'Ottenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '32049',
    city: 'Herford',
    countrycode: 'DE',
  },
  {
    postalcode: '32051',
    city: 'Herford',
    countrycode: 'DE',
  },
  {
    postalcode: '32052',
    city: 'Herford',
    countrycode: 'DE',
  },
  {
    postalcode: '32105',
    city: 'Bad Salzuflen',
    countrycode: 'DE',
  },
  {
    postalcode: '32107',
    city: 'Bad Salzuflen',
    countrycode: 'DE',
  },
  {
    postalcode: '32108',
    city: 'Bad Salzuflen',
    countrycode: 'DE',
  },
  {
    postalcode: '32120',
    city: 'Hiddenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '32130',
    city: 'Enger',
    countrycode: 'DE',
  },
  {
    postalcode: '32139',
    city: 'Spenge',
    countrycode: 'DE',
  },
  {
    postalcode: '32257',
    city: 'Bünde',
    countrycode: 'DE',
  },
  {
    postalcode: '32278',
    city: 'Kirchlengern',
    countrycode: 'DE',
  },
  {
    postalcode: '32289',
    city: 'Rödinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '32312',
    city: 'Lübbecke',
    countrycode: 'DE',
  },
  {
    postalcode: '32339',
    city: 'Espelkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '32351',
    city: 'Stemwede',
    countrycode: 'DE',
  },
  {
    postalcode: '32361',
    city: 'Preußisch Oldendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '32369',
    city: 'Rahden',
    countrycode: 'DE',
  },
  {
    postalcode: '32423',
    city: 'Minden',
    countrycode: 'DE',
  },
  {
    postalcode: '32425',
    city: 'Minden',
    countrycode: 'DE',
  },
  {
    postalcode: '32427',
    city: 'Minden',
    countrycode: 'DE',
  },
  {
    postalcode: '32429',
    city: 'Minden',
    countrycode: 'DE',
  },
  {
    postalcode: '32457',
    city: 'Porta Westfalica',
    countrycode: 'DE',
  },
  {
    postalcode: '32469',
    city: 'Petershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '32479',
    city: 'Hille',
    countrycode: 'DE',
  },
  {
    postalcode: '32545',
    city: 'Bad Oeynhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '32547',
    city: 'Bad Oeynhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '32549',
    city: 'Bad Oeynhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '32584',
    city: 'Löhne',
    countrycode: 'DE',
  },
  {
    postalcode: '32602',
    city: 'Vlotho',
    countrycode: 'DE',
  },
  {
    postalcode: '32609',
    city: 'Hüllhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '32657',
    city: 'Lemgo',
    countrycode: 'DE',
  },
  {
    postalcode: '32676',
    city: 'Lügde',
    countrycode: 'DE',
  },
  {
    postalcode: '32683',
    city: 'Barntrup',
    countrycode: 'DE',
  },
  {
    postalcode: '32689',
    city: 'Kalletal',
    countrycode: 'DE',
  },
  {
    postalcode: '32694',
    city: 'Dörentrup',
    countrycode: 'DE',
  },
  {
    postalcode: '32699',
    city: 'Extertal',
    countrycode: 'DE',
  },
  {
    postalcode: '32756',
    city: 'Detmold',
    countrycode: 'DE',
  },
  {
    postalcode: '32758',
    city: 'Detmold',
    countrycode: 'DE',
  },
  {
    postalcode: '32760',
    city: 'Detmold',
    countrycode: 'DE',
  },
  {
    postalcode: '32791',
    city: 'Lage',
    countrycode: 'DE',
  },
  {
    postalcode: '32805',
    city: 'Horn-Bad Meinberg',
    countrycode: 'DE',
  },
  {
    postalcode: '32816',
    city: 'Schieder-Schwalenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '32825',
    city: 'Blomberg',
    countrycode: 'DE',
  },
  {
    postalcode: '32832',
    city: 'Augustdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '32839',
    city: 'Steinheim',
    countrycode: 'DE',
  },
  {
    postalcode: '33014',
    city: 'Bad Driburg',
    countrycode: 'DE',
  },
  {
    postalcode: '33034',
    city: 'Brakel',
    countrycode: 'DE',
  },
  {
    postalcode: '33039',
    city: 'Nieheim',
    countrycode: 'DE',
  },
  {
    postalcode: '33098',
    city: 'Paderborn',
    countrycode: 'DE',
  },
  {
    postalcode: '33100',
    city: 'Paderborn',
    countrycode: 'DE',
  },
  {
    postalcode: '33102',
    city: 'Paderborn',
    countrycode: 'DE',
  },
  {
    postalcode: '33104',
    city: 'Paderborn',
    countrycode: 'DE',
  },
  {
    postalcode: '33106',
    city: 'Paderborn',
    countrycode: 'DE',
  },
  {
    postalcode: '33129',
    city: 'Delbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '33142',
    city: 'Büren',
    countrycode: 'DE',
  },
  {
    postalcode: '33154',
    city: 'Paderborn',
    countrycode: 'DE',
  },
  {
    postalcode: '33154',
    city: 'Salzkotten',
    countrycode: 'DE',
  },
  {
    postalcode: '33161',
    city: 'Hövelhof',
    countrycode: 'DE',
  },
  {
    postalcode: '33161',
    city: 'Paderborn',
    countrycode: 'DE',
  },
  {
    postalcode: '33165',
    city: 'Lichtenau',
    countrycode: 'DE',
  },
  {
    postalcode: '33175',
    city: 'Bad Lippspringe',
    countrycode: 'DE',
  },
  {
    postalcode: '33178',
    city: 'Borchen',
    countrycode: 'DE',
  },
  {
    postalcode: '33181',
    city: 'Bad Wünnenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '33184',
    city: 'Altenbeken',
    countrycode: 'DE',
  },
  {
    postalcode: '33189',
    city: 'Schlangen',
    countrycode: 'DE',
  },
  {
    postalcode: '33330',
    city: 'Gütersloh',
    countrycode: 'DE',
  },
  {
    postalcode: '33332',
    city: 'Gütersloh',
    countrycode: 'DE',
  },
  {
    postalcode: '33334',
    city: 'Gütersloh',
    countrycode: 'DE',
  },
  {
    postalcode: '33335',
    city: 'Gütersloh',
    countrycode: 'DE',
  },
  {
    postalcode: '33378',
    city: 'Rheda-Wiedenbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '33397',
    city: 'Rietberg',
    countrycode: 'DE',
  },
  {
    postalcode: '33415',
    city: 'Verl',
    countrycode: 'DE',
  },
  {
    postalcode: '33428',
    city: 'Harsewinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '33428',
    city: 'Marienfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33442',
    city: 'Herzebrock-Clarholz',
    countrycode: 'DE',
  },
  {
    postalcode: '33449',
    city: 'Langenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '33602',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33604',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33605',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33607',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33609',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33611',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33613',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33615',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33617',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33619',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33647',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33649',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33659',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33689',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33699',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33719',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33729',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33739',
    city: 'Bielefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '33758',
    city: 'Schloß Holte-Stukenbrock',
    countrycode: 'DE',
  },
  {
    postalcode: '33775',
    city: 'Versmold',
    countrycode: 'DE',
  },
  {
    postalcode: '33790',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '33803',
    city: 'Steinhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '33813',
    city: 'Oerlinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '33818',
    city: 'Leopoldshöhe',
    countrycode: 'DE',
  },
  {
    postalcode: '33824',
    city: 'Werther',
    countrycode: 'DE',
  },
  {
    postalcode: '33829',
    city: 'Borgholzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '34117',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34119',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34121',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34123',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34125',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34127',
    city: 'Am Sandkopf',
    countrycode: 'DE',
  },
  {
    postalcode: '34127',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34128',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34130',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34131',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34132',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34134',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34212',
    city: 'Melsungen',
    countrycode: 'DE',
  },
  {
    postalcode: '34225',
    city: 'Baunatal',
    countrycode: 'DE',
  },
  {
    postalcode: '34225',
    city: 'Kahler Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '34233',
    city: 'Fuldatal',
    countrycode: 'DE',
  },
  {
    postalcode: '34233',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34246',
    city: 'Hof Mondschirm',
    countrycode: 'DE',
  },
  {
    postalcode: '34246',
    city: 'Vellmar',
    countrycode: 'DE',
  },
  {
    postalcode: '34253',
    city: 'Lohfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '34260',
    city: 'Kaufungen',
    countrycode: 'DE',
  },
  {
    postalcode: '34266',
    city: 'Niestetal',
    countrycode: 'DE',
  },
  {
    postalcode: '34270',
    city: 'Schauenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '34277',
    city: 'Fuldabrück',
    countrycode: 'DE',
  },
  {
    postalcode: '34281',
    city: 'Gudensberg',
    countrycode: 'DE',
  },
  {
    postalcode: '34286',
    city: 'Spangenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '34289',
    city: 'Zierenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '34292',
    city: 'Ahnatal',
    countrycode: 'DE',
  },
  {
    postalcode: '34295',
    city: 'Edermünde',
    countrycode: 'DE',
  },
  {
    postalcode: '34298',
    city: 'Helsa',
    countrycode: 'DE',
  },
  {
    postalcode: '34302',
    city: 'Guxhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '34305',
    city: 'Gestecke',
    countrycode: 'DE',
  },
  {
    postalcode: '34305',
    city: 'Niedenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '34308',
    city: 'Bad Emstal',
    countrycode: 'DE',
  },
  {
    postalcode: '34311',
    city: 'Naumburg',
    countrycode: 'DE',
  },
  {
    postalcode: '34314',
    city: 'Espenau',
    countrycode: 'DE',
  },
  {
    postalcode: '34317',
    city: 'Habichtswald',
    countrycode: 'DE',
  },
  {
    postalcode: '34320',
    city: 'Söhrewald',
    countrycode: 'DE',
  },
  {
    postalcode: '34323',
    city: 'Malsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '34326',
    city: 'Morschen',
    countrycode: 'DE',
  },
  {
    postalcode: '34327',
    city: 'Körle',
    countrycode: 'DE',
  },
  {
    postalcode: '34329',
    city: 'Nieste',
    countrycode: 'DE',
  },
  {
    postalcode: '34346',
    city: 'Hann. Münden',
    countrycode: 'DE',
  },
  {
    postalcode: '34355',
    city: 'Kassel',
    countrycode: 'DE',
  },
  {
    postalcode: '34355',
    city: 'Staufenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '34359',
    city: 'Reinhardshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '34369',
    city: 'Hofgeismar',
    countrycode: 'DE',
  },
  {
    postalcode: '34376',
    city: 'Immenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '34379',
    city: 'Calden',
    countrycode: 'DE',
  },
  {
    postalcode: '34385',
    city: 'Bad Karlshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '34388',
    city: 'Trendelburg',
    countrycode: 'DE',
  },
  {
    postalcode: '34393',
    city: 'Grebenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '34396',
    city: 'Liebenau',
    countrycode: 'DE',
  },
  {
    postalcode: '34399',
    city: 'Oberweser',
    countrycode: 'DE',
  },
  {
    postalcode: '34414',
    city: 'Warburg',
    countrycode: 'DE',
  },
  {
    postalcode: '34431',
    city: 'Marsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '34434',
    city: 'Borgentreich',
    countrycode: 'DE',
  },
  {
    postalcode: '34439',
    city: 'Willebadessen',
    countrycode: 'DE',
  },
  {
    postalcode: '34454',
    city: 'Bad Arolsen',
    countrycode: 'DE',
  },
  {
    postalcode: '34466',
    city: 'Wolfhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '34471',
    city: 'Volkmarsen',
    countrycode: 'DE',
  },
  {
    postalcode: '34474',
    city: 'Diemelstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '34477',
    city: 'Twistetal',
    countrycode: 'DE',
  },
  {
    postalcode: '34479',
    city: 'Breuna',
    countrycode: 'DE',
  },
  {
    postalcode: '34497',
    city: 'Am Rainberge',
    countrycode: 'DE',
  },
  {
    postalcode: '34497',
    city: 'Korbach',
    countrycode: 'DE',
  },
  {
    postalcode: '34508',
    city: 'Willingen',
    countrycode: 'DE',
  },
  {
    postalcode: '34513',
    city: 'Klippmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '34513',
    city: 'Waldeck',
    countrycode: 'DE',
  },
  {
    postalcode: '34516',
    city: 'Fürstental',
    countrycode: 'DE',
  },
  {
    postalcode: '34516',
    city: 'Vöhl',
    countrycode: 'DE',
  },
  {
    postalcode: '34519',
    city: 'Diemelsee',
    countrycode: 'DE',
  },
  {
    postalcode: '34537',
    city: 'Bad Wildungen',
    countrycode: 'DE',
  },
  {
    postalcode: '34549',
    city: 'Edertal',
    countrycode: 'DE',
  },
  {
    postalcode: '34560',
    city: 'Fritzlar',
    countrycode: 'DE',
  },
  {
    postalcode: '34576',
    city: 'Grünhof',
    countrycode: 'DE',
  },
  {
    postalcode: '34576',
    city: 'Homberg',
    countrycode: 'DE',
  },
  {
    postalcode: '34582',
    city: 'Borken',
    countrycode: 'DE',
  },
  {
    postalcode: '34587',
    city: 'Felsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '34590',
    city: 'Wabern',
    countrycode: 'DE',
  },
  {
    postalcode: '34593',
    city: 'Knüllwald',
    countrycode: 'DE',
  },
  {
    postalcode: '34596',
    city: 'Bad Zwesten',
    countrycode: 'DE',
  },
  {
    postalcode: '34599',
    city: 'Neuental',
    countrycode: 'DE',
  },
  {
    postalcode: '34613',
    city: 'Schwalmstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '34621',
    city: 'Frielendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '34626',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '34628',
    city: 'Willingshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '34630',
    city: 'Gilserberg',
    countrycode: 'DE',
  },
  {
    postalcode: '34632',
    city: 'Jesberg',
    countrycode: 'DE',
  },
  {
    postalcode: '34633',
    city: 'Ottrau',
    countrycode: 'DE',
  },
  {
    postalcode: '34637',
    city: 'Schrecksbach',
    countrycode: 'DE',
  },
  {
    postalcode: '34639',
    city: 'Schwarzenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '35037',
    city: 'Marburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35039',
    city: 'Marburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35041',
    city: 'Marburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35043',
    city: 'Capelle',
    countrycode: 'DE',
  },
  {
    postalcode: '35043',
    city: 'Marburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35066',
    city: 'Frankenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '35075',
    city: 'Gladenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '35080',
    city: 'Bad Endbach',
    countrycode: 'DE',
  },
  {
    postalcode: '35083',
    city: 'Wetter',
    countrycode: 'DE',
  },
  {
    postalcode: '35085',
    city: 'Ebsdorfergrund',
    countrycode: 'DE',
  },
  {
    postalcode: '35088',
    city: 'Battenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '35091',
    city: 'Cölbe',
    countrycode: 'DE',
  },
  {
    postalcode: '35094',
    city: 'Lahntal',
    countrycode: 'DE',
  },
  {
    postalcode: '35094',
    city: 'Michelbacher Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '35096',
    city: 'Weimar',
    countrycode: 'DE',
  },
  {
    postalcode: '35099',
    city: 'Burgwald',
    countrycode: 'DE',
  },
  {
    postalcode: '35102',
    city: 'Lohra',
    countrycode: 'DE',
  },
  {
    postalcode: '35104',
    city: 'Lichtenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '35108',
    city: 'Allendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '35110',
    city: 'Frankenau',
    countrycode: 'DE',
  },
  {
    postalcode: '35112',
    city: 'Fronhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '35114',
    city: 'Haina',
    countrycode: 'DE',
  },
  {
    postalcode: '35116',
    city: 'Hatzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '35117',
    city: 'Münchhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '35119',
    city: 'Rosenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '35216',
    city: 'Biedenkopf',
    countrycode: 'DE',
  },
  {
    postalcode: '35216',
    city: 'Waldhaus Grebe',
    countrycode: 'DE',
  },
  {
    postalcode: '35232',
    city: 'Dautphetal',
    countrycode: 'DE',
  },
  {
    postalcode: '35236',
    city: 'Breidenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '35239',
    city: 'Steffenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '35260',
    city: 'Stadtallendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '35274',
    city: 'Kirchhain',
    countrycode: 'DE',
  },
  {
    postalcode: '35279',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '35282',
    city: 'Rauschenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '35285',
    city: 'Gemünden',
    countrycode: 'DE',
  },
  {
    postalcode: '35287',
    city: 'Amöneburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35288',
    city: 'Wohratal',
    countrycode: 'DE',
  },
  {
    postalcode: '35305',
    city: 'Grünberg',
    countrycode: 'DE',
  },
  {
    postalcode: '35315',
    city: 'Homberg',
    countrycode: 'DE',
  },
  {
    postalcode: '35321',
    city: 'Laubach',
    countrycode: 'DE',
  },
  {
    postalcode: '35325',
    city: 'Mücke',
    countrycode: 'DE',
  },
  {
    postalcode: '35327',
    city: 'Ulrichstein',
    countrycode: 'DE',
  },
  {
    postalcode: '35329',
    city: 'Gemünden',
    countrycode: 'DE',
  },
  {
    postalcode: '35390',
    city: 'Gießen',
    countrycode: 'DE',
  },
  {
    postalcode: '35392',
    city: 'Gießen',
    countrycode: 'DE',
  },
  {
    postalcode: '35394',
    city: 'Gießen',
    countrycode: 'DE',
  },
  {
    postalcode: '35396',
    city: 'Gießen',
    countrycode: 'DE',
  },
  {
    postalcode: '35398',
    city: 'Gießen',
    countrycode: 'DE',
  },
  {
    postalcode: '35410',
    city: 'Hungen',
    countrycode: 'DE',
  },
  {
    postalcode: '35415',
    city: 'Pohlheim',
    countrycode: 'DE',
  },
  {
    postalcode: '35418',
    city: 'Buseck',
    countrycode: 'DE',
  },
  {
    postalcode: '35423',
    city: 'Lich',
    countrycode: 'DE',
  },
  {
    postalcode: '35428',
    city: 'Langgöns',
    countrycode: 'DE',
  },
  {
    postalcode: '35435',
    city: 'Wettenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '35440',
    city: 'Linden',
    countrycode: 'DE',
  },
  {
    postalcode: '35444',
    city: 'Biebertal',
    countrycode: 'DE',
  },
  {
    postalcode: '35447',
    city: 'Reiskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '35452',
    city: 'Heuchelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '35457',
    city: 'Lollar',
    countrycode: 'DE',
  },
  {
    postalcode: '35460',
    city: 'Staufenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '35463',
    city: 'Fernwald',
    countrycode: 'DE',
  },
  {
    postalcode: '35466',
    city: 'Rabenau',
    countrycode: 'DE',
  },
  {
    postalcode: '35469',
    city: 'Allendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '35510',
    city: 'Butzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '35516',
    city: 'Münzenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '35519',
    city: 'Rockenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '35576',
    city: 'Wetzlar',
    countrycode: 'DE',
  },
  {
    postalcode: '35578',
    city: 'Wetzlar',
    countrycode: 'DE',
  },
  {
    postalcode: '35579',
    city: 'Wetzlar',
    countrycode: 'DE',
  },
  {
    postalcode: '35580',
    city: 'Wetzlar',
    countrycode: 'DE',
  },
  {
    postalcode: '35581',
    city: 'Wetzlar',
    countrycode: 'DE',
  },
  {
    postalcode: '35582',
    city: 'Wetzlar',
    countrycode: 'DE',
  },
  {
    postalcode: '35583',
    city: 'Wetzlar',
    countrycode: 'DE',
  },
  {
    postalcode: '35584',
    city: 'Wetzlar',
    countrycode: 'DE',
  },
  {
    postalcode: '35585',
    city: 'Wetzlar',
    countrycode: 'DE',
  },
  {
    postalcode: '35586',
    city: 'Wetzlar',
    countrycode: 'DE',
  },
  {
    postalcode: '35606',
    city: 'Solms',
    countrycode: 'DE',
  },
  {
    postalcode: '35614',
    city: 'Aßlar',
    countrycode: 'DE',
  },
  {
    postalcode: '35619',
    city: 'Braunfels',
    countrycode: 'DE',
  },
  {
    postalcode: '35625',
    city: 'Hüttenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '35630',
    city: 'Ehringshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '35630',
    city: 'Grundmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '35630',
    city: 'Heinrichsegen',
    countrycode: 'DE',
  },
  {
    postalcode: '35633',
    city: 'Lahnau',
    countrycode: 'DE',
  },
  {
    postalcode: '35638',
    city: 'Leun',
    countrycode: 'DE',
  },
  {
    postalcode: '35641',
    city: 'Schöffengrund',
    countrycode: 'DE',
  },
  {
    postalcode: '35644',
    city: 'Hohenahr',
    countrycode: 'DE',
  },
  {
    postalcode: '35647',
    city: 'Waldsolms',
    countrycode: 'DE',
  },
  {
    postalcode: '35649',
    city: 'Bischoffen',
    countrycode: 'DE',
  },
  {
    postalcode: '35683',
    city: 'Dillenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35684',
    city: 'Dillenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35685',
    city: 'Dillenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35686',
    city: 'Dillenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35687',
    city: 'Dillenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35688',
    city: 'Dillenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35689',
    city: 'Dillenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35690',
    city: 'Dillenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35708',
    city: 'Haiger',
    countrycode: 'DE',
  },
  {
    postalcode: '35713',
    city: 'Eschenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35716',
    city: 'Dietzhölztal',
    countrycode: 'DE',
  },
  {
    postalcode: '35719',
    city: 'Angelburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35745',
    city: 'Herborn',
    countrycode: 'DE',
  },
  {
    postalcode: '35753',
    city: 'Greifenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '35756',
    city: 'Mittenaar',
    countrycode: 'DE',
  },
  {
    postalcode: '35759',
    city: 'Driedorf',
    countrycode: 'DE',
  },
  {
    postalcode: '35764',
    city: 'Sinn',
    countrycode: 'DE',
  },
  {
    postalcode: '35767',
    city: 'Breitscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '35768',
    city: 'Siegbach',
    countrycode: 'DE',
  },
  {
    postalcode: '35781',
    city: 'Weilburg',
    countrycode: 'DE',
  },
  {
    postalcode: '35789',
    city: 'Weilmünster',
    countrycode: 'DE',
  },
  {
    postalcode: '35792',
    city: 'Löhnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '35794',
    city: 'Mengerskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '35796',
    city: 'Weinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '35799',
    city: 'Merenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '36037',
    city: 'Fulda',
    countrycode: 'DE',
  },
  {
    postalcode: '36039',
    city: 'Fulda',
    countrycode: 'DE',
  },
  {
    postalcode: '36041',
    city: 'Fulda',
    countrycode: 'DE',
  },
  {
    postalcode: '36043',
    city: 'Fulda',
    countrycode: 'DE',
  },
  {
    postalcode: '36088',
    city: 'Hünfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '36093',
    city: 'Künzell',
    countrycode: 'DE',
  },
  {
    postalcode: '36100',
    city: 'Petersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '36103',
    city: 'Flieden',
    countrycode: 'DE',
  },
  {
    postalcode: '36110',
    city: 'Schlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '36115',
    city: 'Ehrenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '36115',
    city: 'Hilders',
    countrycode: 'DE',
  },
  {
    postalcode: '36119',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '36124',
    city: 'Eichenzell',
    countrycode: 'DE',
  },
  {
    postalcode: '36129',
    city: 'Gersfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '36129',
    city: 'Wachtküppel',
    countrycode: 'DE',
  },
  {
    postalcode: '36132',
    city: 'Eiterfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '36137',
    city: 'Großenlüder',
    countrycode: 'DE',
  },
  {
    postalcode: '36142',
    city: 'Tann',
    countrycode: 'DE',
  },
  {
    postalcode: '36145',
    city: 'Hofbieber',
    countrycode: 'DE',
  },
  {
    postalcode: '36148',
    city: 'Kalbach',
    countrycode: 'DE',
  },
  {
    postalcode: '36151',
    city: 'Burghaun',
    countrycode: 'DE',
  },
  {
    postalcode: '36154',
    city: 'Hosenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '36154',
    city: 'Zwickmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '36157',
    city: 'Ebersburg',
    countrycode: 'DE',
  },
  {
    postalcode: '36160',
    city: 'Dipperz',
    countrycode: 'DE',
  },
  {
    postalcode: '36163',
    city: 'Poppenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '36166',
    city: 'Haunetal',
    countrycode: 'DE',
  },
  {
    postalcode: '36166',
    city: 'Sennhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '36167',
    city: 'Nüsttal',
    countrycode: 'DE',
  },
  {
    postalcode: '36169',
    city: 'Rasdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '36179',
    city: 'Bebra',
    countrycode: 'DE',
  },
  {
    postalcode: '36179',
    city: 'Gunkelrode',
    countrycode: 'DE',
  },
  {
    postalcode: '36199',
    city: 'Rotenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '36205',
    city: 'Sontra',
    countrycode: 'DE',
  },
  {
    postalcode: '36208',
    city: 'Bellers',
    countrycode: 'DE',
  },
  {
    postalcode: '36208',
    city: 'Wildeck',
    countrycode: 'DE',
  },
  {
    postalcode: '36211',
    city: 'Alheim',
    countrycode: 'DE',
  },
  {
    postalcode: '36214',
    city: 'Nentershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '36217',
    city: 'Ronshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '36219',
    city: 'Cornberg',
    countrycode: 'DE',
  },
  {
    postalcode: '36219',
    city: 'Menglers',
    countrycode: 'DE',
  },
  {
    postalcode: '36251',
    city: 'Bad Hersfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '36251',
    city: 'Ludwigsau',
    countrycode: 'DE',
  },
  {
    postalcode: '36266',
    city: 'Heringen',
    countrycode: 'DE',
  },
  {
    postalcode: '36269',
    city: 'Philippsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '36272',
    city: 'Niederaula',
    countrycode: 'DE',
  },
  {
    postalcode: '36275',
    city: 'Kirchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '36277',
    city: 'Schenklengsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '36280',
    city: 'Oberaula',
    countrycode: 'DE',
  },
  {
    postalcode: '36282',
    city: 'Hauneck',
    countrycode: 'DE',
  },
  {
    postalcode: '36284',
    city: 'Hohenroda',
    countrycode: 'DE',
  },
  {
    postalcode: '36286',
    city: 'Neuenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '36287',
    city: 'Breitenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '36289',
    city: 'Friedewald',
    countrycode: 'DE',
  },
  {
    postalcode: '36304',
    city: 'Alsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '36304',
    city: 'Hardtmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '36318',
    city: 'Schwalmtal',
    countrycode: 'DE',
  },
  {
    postalcode: '36320',
    city: 'Kirtorf',
    countrycode: 'DE',
  },
  {
    postalcode: '36323',
    city: 'Grebenau',
    countrycode: 'DE',
  },
  {
    postalcode: '36325',
    city: 'Feldatal',
    countrycode: 'DE',
  },
  {
    postalcode: '36326',
    city: 'Antrifttal',
    countrycode: 'DE',
  },
  {
    postalcode: '36326',
    city: 'Dammeshof',
    countrycode: 'DE',
  },
  {
    postalcode: '36326',
    city: 'Dammesmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '36329',
    city: 'Romrod',
    countrycode: 'DE',
  },
  {
    postalcode: '36341',
    city: 'Lauterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '36355',
    city: 'Grebenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '36358',
    city: 'Herbstein',
    countrycode: 'DE',
  },
  {
    postalcode: '36364',
    city: 'Bad Salzschlirf',
    countrycode: 'DE',
  },
  {
    postalcode: '36367',
    city: 'Wartenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '36369',
    city: 'Lautertal',
    countrycode: 'DE',
  },
  {
    postalcode: '36381',
    city: 'Schlüchtern',
    countrycode: 'DE',
  },
  {
    postalcode: '36391',
    city: 'Sinntal',
    countrycode: 'DE',
  },
  {
    postalcode: '36396',
    city: 'Steinau',
    countrycode: 'DE',
  },
  {
    postalcode: '36399',
    city: 'Freiensteinau',
    countrycode: 'DE',
  },
  {
    postalcode: '36404',
    city: 'Gehaus',
    countrycode: 'DE',
  },
  {
    postalcode: '36404',
    city: 'Martinroda',
    countrycode: 'DE',
  },
  {
    postalcode: '36404',
    city: 'Mieswarz',
    countrycode: 'DE',
  },
  {
    postalcode: '36404',
    city: 'Oechsen',
    countrycode: 'DE',
  },
  {
    postalcode: '36404',
    city: 'Otzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '36404',
    city: 'Sünna',
    countrycode: 'DE',
  },
  {
    postalcode: '36404',
    city: 'Vacha',
    countrycode: 'DE',
  },
  {
    postalcode: '36404',
    city: 'Völkershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '36404',
    city: 'Wölferbütt',
    countrycode: 'DE',
  },
  {
    postalcode: '36414',
    city: 'Pferdsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '36414',
    city: 'Unterbreizbach',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Bermbach',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Borsch',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Bremen',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Buttlar',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Geisa',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Geismar',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Gerstengrund',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Ketten',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Kranlucken',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Motzlar',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Schleid',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Spahl',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Wenigentaft',
    countrycode: 'DE',
  },
  {
    postalcode: '36419',
    city: 'Zitters',
    countrycode: 'DE',
  },
  {
    postalcode: '36433',
    city: 'Bad Salzungen',
    countrycode: 'DE',
  },
  {
    postalcode: '36433',
    city: 'Immelborn',
    countrycode: 'DE',
  },
  {
    postalcode: '36433',
    city: 'Leimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '36433',
    city: 'Moorgrund',
    countrycode: 'DE',
  },
  {
    postalcode: '36448',
    city: 'Bad Liebenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '36448',
    city: 'Schweina',
    countrycode: 'DE',
  },
  {
    postalcode: '36448',
    city: 'Steinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '36452',
    city: 'Andenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '36452',
    city: 'Brunnhartshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '36452',
    city: 'Diedorf',
    countrycode: 'DE',
  },
  {
    postalcode: '36452',
    city: 'Empfertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '36452',
    city: 'Fischbach',
    countrycode: 'DE',
  },
  {
    postalcode: '36452',
    city: 'Kaltenlengsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '36452',
    city: 'Kaltennordheim',
    countrycode: 'DE',
  },
  {
    postalcode: '36452',
    city: 'Klings',
    countrycode: 'DE',
  },
  {
    postalcode: '36452',
    city: 'Neidhartshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '36452',
    city: 'Zella',
    countrycode: 'DE',
  },
  {
    postalcode: '36456',
    city: 'Barchfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '36457',
    city: 'Stadtlengsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '36457',
    city: 'Urnshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '36457',
    city: 'Weilar',
    countrycode: 'DE',
  },
  {
    postalcode: '36460',
    city: 'Dietlas',
    countrycode: 'DE',
  },
  {
    postalcode: '36460',
    city: 'Dorndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '36460',
    city: 'Dönges',
    countrycode: 'DE',
  },
  {
    postalcode: '36460',
    city: 'Frauensee',
    countrycode: 'DE',
  },
  {
    postalcode: '36460',
    city: 'Kieselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '36460',
    city: 'Merkers',
    countrycode: 'DE',
  },
  {
    postalcode: '36460',
    city: 'Weißendiez',
    countrycode: 'DE',
  },
  {
    postalcode: '36466',
    city: 'Dermbach',
    countrycode: 'DE',
  },
  {
    postalcode: '36466',
    city: 'Wiesenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '36469',
    city: 'Oberrohn',
    countrycode: 'DE',
  },
  {
    postalcode: '36469',
    city: 'Tiefenort',
    countrycode: 'DE',
  },
  {
    postalcode: '37073',
    city: 'Göttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '37075',
    city: 'Göttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '37077',
    city: 'Göttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '37079',
    city: 'Göttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '37081',
    city: 'Göttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '37083',
    city: 'Göttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '37085',
    city: 'Göttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '37115',
    city: 'Duderstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '37120',
    city: 'Bovenden',
    countrycode: 'DE',
  },
  {
    postalcode: '37124',
    city: 'Rosdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '37127',
    city: 'Brackenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '37127',
    city: 'Bühren',
    countrycode: 'DE',
  },
  {
    postalcode: '37127',
    city: 'Dransfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '37127',
    city: 'Jühnde',
    countrycode: 'DE',
  },
  {
    postalcode: '37127',
    city: 'Niemetal',
    countrycode: 'DE',
  },
  {
    postalcode: '37127',
    city: 'Scheden',
    countrycode: 'DE',
  },
  {
    postalcode: '37130',
    city: 'Gleichen',
    countrycode: 'DE',
  },
  {
    postalcode: '37133',
    city: 'Friedland',
    countrycode: 'DE',
  },
  {
    postalcode: '37136',
    city: 'Ebergötzen',
    countrycode: 'DE',
  },
  {
    postalcode: '37136',
    city: 'Landolfshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37136',
    city: 'Seeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '37136',
    city: 'Seulingen',
    countrycode: 'DE',
  },
  {
    postalcode: '37136',
    city: 'Waake',
    countrycode: 'DE',
  },
  {
    postalcode: '37139',
    city: 'Adelebsen',
    countrycode: 'DE',
  },
  {
    postalcode: '37154',
    city: 'Northeim',
    countrycode: 'DE',
  },
  {
    postalcode: '37170',
    city: 'Uslar',
    countrycode: 'DE',
  },
  {
    postalcode: '37176',
    city: 'Nörten-Hardenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '37181',
    city: 'Hardegsen',
    countrycode: 'DE',
  },
  {
    postalcode: '37186',
    city: 'Moringen',
    countrycode: 'DE',
  },
  {
    postalcode: '37191',
    city: 'Katlenburg-Lindau',
    countrycode: 'DE',
  },
  {
    postalcode: '37194',
    city: 'Bodenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '37194',
    city: 'Wahlsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '37197',
    city: 'Hattorf',
    countrycode: 'DE',
  },
  {
    postalcode: '37199',
    city: 'Wulften',
    countrycode: 'DE',
  },
  {
    postalcode: '37213',
    city: 'Witzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37214',
    city: 'Witzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37215',
    city: 'Witzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37216',
    city: 'Witzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37217',
    city: 'Nonnenholz',
    countrycode: 'DE',
  },
  {
    postalcode: '37217',
    city: 'Witzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37218',
    city: 'Witzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37235',
    city: 'Hessisch Lichtenau',
    countrycode: 'DE',
  },
  {
    postalcode: '37242',
    city: 'Bad Sooden-Allendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '37247',
    city: 'Großalmerode',
    countrycode: 'DE',
  },
  {
    postalcode: '37249',
    city: 'Neu-Eichenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '37269',
    city: 'Eschwege',
    countrycode: 'DE',
  },
  {
    postalcode: '37276',
    city: 'Meinhard',
    countrycode: 'DE',
  },
  {
    postalcode: '37281',
    city: 'Wanfried',
    countrycode: 'DE',
  },
  {
    postalcode: '37284',
    city: 'Waldkappel',
    countrycode: 'DE',
  },
  {
    postalcode: '37287',
    city: 'Wehretal',
    countrycode: 'DE',
  },
  {
    postalcode: '37290',
    city: 'Meißner',
    countrycode: 'DE',
  },
  {
    postalcode: '37293',
    city: 'Herleshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37296',
    city: 'Ringgau',
    countrycode: 'DE',
  },
  {
    postalcode: '37297',
    city: 'Berkatal',
    countrycode: 'DE',
  },
  {
    postalcode: '37299',
    city: 'Weißenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Ankermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Bebendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Bernterode',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Bischhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Bodenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Döringsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Geisleden',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Geismar',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Glasehausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Heilbad Heiligenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Heuthen',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Kella',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Krombach',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Mengelrode',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Pfaffschwende',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Reinholterode',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Schimberg',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Sickerode',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Siemerode',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Steinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Streitholz',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Volkerode',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Westhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37308',
    city: 'Wiesenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Arenshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Asbach-Sickenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Birkenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Bornhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Burgwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Dieterode',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Dietzenrode/Vatterode',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Eichstruth',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Freienhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Fretterode',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Gerbershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Hohengandern',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Kirchgandern',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Lenterode',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Lindewerra',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Lutter',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Mackenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Marth',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Rohrberg',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Rustenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Röhrig',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Schachtebich',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Schwobfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Schönhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Steinheuterode',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Thalwenden',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Uder',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Wahlhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37318',
    city: 'Wüstheuterode',
    countrycode: 'DE',
  },
  {
    postalcode: '37327',
    city: 'Beuren',
    countrycode: 'DE',
  },
  {
    postalcode: '37327',
    city: 'Birkungen',
    countrycode: 'DE',
  },
  {
    postalcode: '37327',
    city: 'Breitenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '37327',
    city: 'Hausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37327',
    city: 'Kallmerode',
    countrycode: 'DE',
  },
  {
    postalcode: '37327',
    city: 'Leinefelde',
    countrycode: 'DE',
  },
  {
    postalcode: '37327',
    city: 'Wingerode',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Berlingerode',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Bleckenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Brehme',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Breitenworbis',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Buchmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Buhla',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Böseckendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Ecklingerode',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Ferna',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Gernrode',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Haynrode',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Hundeshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Kaltohmfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Kirchohmfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Kirchworbis',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Tastungen',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Teistungen',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Wehnde',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Wintzingerode',
    countrycode: 'DE',
  },
  {
    postalcode: '37339',
    city: 'Worbis',
    countrycode: 'DE',
  },
  {
    postalcode: '37345',
    city: 'Bischofferode',
    countrycode: 'DE',
  },
  {
    postalcode: '37345',
    city: 'Bockelnhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '37345',
    city: 'Großbodungen',
    countrycode: 'DE',
  },
  {
    postalcode: '37345',
    city: 'Holungen',
    countrycode: 'DE',
  },
  {
    postalcode: '37345',
    city: 'Jützenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '37345',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '37345',
    city: 'Silkerode',
    countrycode: 'DE',
  },
  {
    postalcode: '37345',
    city: 'Steinrode',
    countrycode: 'DE',
  },
  {
    postalcode: '37345',
    city: 'Stöckey',
    countrycode: 'DE',
  },
  {
    postalcode: '37345',
    city: 'Weißenborn-Lüderode',
    countrycode: 'DE',
  },
  {
    postalcode: '37345',
    city: 'Zwinge',
    countrycode: 'DE',
  },
  {
    postalcode: '37351',
    city: 'Dingelstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '37351',
    city: 'Helmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '37351',
    city: 'Kefferhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37351',
    city: 'Kreuzebra',
    countrycode: 'DE',
  },
  {
    postalcode: '37351',
    city: 'Silberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37355',
    city: 'Bernterode',
    countrycode: 'DE',
  },
  {
    postalcode: '37355',
    city: 'Deuna',
    countrycode: 'DE',
  },
  {
    postalcode: '37355',
    city: 'Gerterode',
    countrycode: 'DE',
  },
  {
    postalcode: '37355',
    city: 'Kleinbartloff',
    countrycode: 'DE',
  },
  {
    postalcode: '37355',
    city: 'Niederorschel',
    countrycode: 'DE',
  },
  {
    postalcode: '37355',
    city: 'Vollenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '37359',
    city: 'Büttstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '37359',
    city: 'Effelder',
    countrycode: 'DE',
  },
  {
    postalcode: '37359',
    city: 'Großbartloff',
    countrycode: 'DE',
  },
  {
    postalcode: '37359',
    city: 'Küllstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '37359',
    city: 'Wachstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '37412',
    city: 'Aschenhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '37412',
    city: 'Elbingerode',
    countrycode: 'DE',
  },
  {
    postalcode: '37412',
    city: 'Herzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '37412',
    city: 'Hörden',
    countrycode: 'DE',
  },
  {
    postalcode: '37431',
    city: 'Bad Lauterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '37434',
    city: 'An der Rhumequelle',
    countrycode: 'DE',
  },
  {
    postalcode: '37434',
    city: 'Bilshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37434',
    city: 'Bodensee',
    countrycode: 'DE',
  },
  {
    postalcode: '37434',
    city: 'Gieboldehausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37434',
    city: 'Krebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '37434',
    city: 'Obernfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '37434',
    city: 'Rhumspringe',
    countrycode: 'DE',
  },
  {
    postalcode: '37434',
    city: 'Rollshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37434',
    city: 'Rotenberg, Jagdhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '37434',
    city: 'Rüdershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37434',
    city: 'Wollbrandshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37434',
    city: 'Wollershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37441',
    city: 'Bad Sachsa',
    countrycode: 'DE',
  },
  {
    postalcode: '37444',
    city: 'St. Andreasberg',
    countrycode: 'DE',
  },
  {
    postalcode: '37445',
    city: 'Walkenried',
    countrycode: 'DE',
  },
  {
    postalcode: '37447',
    city: 'Wieda',
    countrycode: 'DE',
  },
  {
    postalcode: '37449',
    city: 'Zorge',
    countrycode: 'DE',
  },
  {
    postalcode: '37520',
    city: 'Osterode',
    countrycode: 'DE',
  },
  {
    postalcode: '37534',
    city: 'Badenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37534',
    city: 'Eisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '37534',
    city: 'Gittelde',
    countrycode: 'DE',
  },
  {
    postalcode: '37539',
    city: 'Bad Grund',
    countrycode: 'DE',
  },
  {
    postalcode: '37539',
    city: 'Windhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37547',
    city: 'Kreiensen',
    countrycode: 'DE',
  },
  {
    postalcode: '37574',
    city: 'Einbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '37581',
    city: 'Bad Gandersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '37586',
    city: 'Dassel',
    countrycode: 'DE',
  },
  {
    postalcode: '37589',
    city: 'Kalefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '37603',
    city: 'Holzminden',
    countrycode: 'DE',
  },
  {
    postalcode: '37619',
    city: 'Bodenwerder',
    countrycode: 'DE',
  },
  {
    postalcode: '37619',
    city: 'Hehlen',
    countrycode: 'DE',
  },
  {
    postalcode: '37619',
    city: 'Heyen',
    countrycode: 'DE',
  },
  {
    postalcode: '37619',
    city: 'Kirchbrak',
    countrycode: 'DE',
  },
  {
    postalcode: '37619',
    city: 'Pegestorf',
    countrycode: 'DE',
  },
  {
    postalcode: '37620',
    city: 'Bremke',
    countrycode: 'DE',
  },
  {
    postalcode: '37620',
    city: 'Dohnsen',
    countrycode: 'DE',
  },
  {
    postalcode: '37620',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '37620',
    city: 'Hunzen',
    countrycode: 'DE',
  },
  {
    postalcode: '37620',
    city: 'Tuchtfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '37620',
    city: 'Wegensen',
    countrycode: 'DE',
  },
  {
    postalcode: '37627',
    city: 'Arholzen',
    countrycode: 'DE',
  },
  {
    postalcode: '37627',
    city: 'Deensen',
    countrycode: 'DE',
  },
  {
    postalcode: '37627',
    city: 'Heinade',
    countrycode: 'DE',
  },
  {
    postalcode: '37627',
    city: 'Lenne',
    countrycode: 'DE',
  },
  {
    postalcode: '37627',
    city: 'Stadtoldendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '37627',
    city: 'Wangelnstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '37632',
    city: 'Eimen',
    countrycode: 'DE',
  },
  {
    postalcode: '37632',
    city: 'Eschershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '37632',
    city: 'Holzen',
    countrycode: 'DE',
  },
  {
    postalcode: '37633',
    city: 'Dielmissen',
    countrycode: 'DE',
  },
  {
    postalcode: '37635',
    city: 'Lüerdissen',
    countrycode: 'DE',
  },
  {
    postalcode: '37639',
    city: 'Bevern',
    countrycode: 'DE',
  },
  {
    postalcode: '37639',
    city: 'Brille',
    countrycode: 'DE',
  },
  {
    postalcode: '37640',
    city: 'Golmbach',
    countrycode: 'DE',
  },
  {
    postalcode: '37642',
    city: 'Holenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '37643',
    city: 'Negenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '37647',
    city: 'Brevörde',
    countrycode: 'DE',
  },
  {
    postalcode: '37647',
    city: 'Polle',
    countrycode: 'DE',
  },
  {
    postalcode: '37647',
    city: 'Vahlbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '37647',
    city: 'Wilmeröderberg',
    countrycode: 'DE',
  },
  {
    postalcode: '37649',
    city: 'Heinsen',
    countrycode: 'DE',
  },
  {
    postalcode: '37671',
    city: 'Höxter',
    countrycode: 'DE',
  },
  {
    postalcode: '37688',
    city: 'Beverungen',
    countrycode: 'DE',
  },
  {
    postalcode: '37691',
    city: 'Boffzen',
    countrycode: 'DE',
  },
  {
    postalcode: '37691',
    city: 'Derental',
    countrycode: 'DE',
  },
  {
    postalcode: '37696',
    city: 'Marienmünster',
    countrycode: 'DE',
  },
  {
    postalcode: '37697',
    city: 'Lauenförde',
    countrycode: 'DE',
  },
  {
    postalcode: '37699',
    city: 'Fürstenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '38100',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38102',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38104',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38106',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38108',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38110',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38112',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38114',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38116',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38118',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38120',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38122',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38124',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38126',
    city: 'Braunschweig',
    countrycode: 'DE',
  },
  {
    postalcode: '38154',
    city: 'Brunsleberfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '38154',
    city: 'Groß Rode',
    countrycode: 'DE',
  },
  {
    postalcode: '38154',
    city: 'Königslutter',
    countrycode: 'DE',
  },
  {
    postalcode: '38154',
    city: 'Tetzelstein',
    countrycode: 'DE',
  },
  {
    postalcode: '38154',
    city: 'Watzumer Häuschen',
    countrycode: 'DE',
  },
  {
    postalcode: '38154',
    city: 'Zur Schunterquelle',
    countrycode: 'DE',
  },
  {
    postalcode: '38159',
    city: 'Vechelde',
    countrycode: 'DE',
  },
  {
    postalcode: '38162',
    city: 'Cremlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '38165',
    city: 'Lehre',
    countrycode: 'DE',
  },
  {
    postalcode: '38170',
    city: 'Dahlum',
    countrycode: 'DE',
  },
  {
    postalcode: '38170',
    city: 'Kneitlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '38170',
    city: 'Schöppenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38170',
    city: 'Uehrde',
    countrycode: 'DE',
  },
  {
    postalcode: '38170',
    city: 'Vahlberg',
    countrycode: 'DE',
  },
  {
    postalcode: '38170',
    city: 'Winnigstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38173',
    city: 'Dettum',
    countrycode: 'DE',
  },
  {
    postalcode: '38173',
    city: 'Erkerode',
    countrycode: 'DE',
  },
  {
    postalcode: '38173',
    city: 'Evessen',
    countrycode: 'DE',
  },
  {
    postalcode: '38173',
    city: 'Reitling',
    countrycode: 'DE',
  },
  {
    postalcode: '38173',
    city: 'Sickte',
    countrycode: 'DE',
  },
  {
    postalcode: '38173',
    city: 'Veltheim',
    countrycode: 'DE',
  },
  {
    postalcode: '38176',
    city: 'Wendeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38179',
    city: 'Schwülper',
    countrycode: 'DE',
  },
  {
    postalcode: '38226',
    city: 'Salzgitter',
    countrycode: 'DE',
  },
  {
    postalcode: '38228',
    city: 'Salzgitter',
    countrycode: 'DE',
  },
  {
    postalcode: '38229',
    city: 'Salzgitter',
    countrycode: 'DE',
  },
  {
    postalcode: '38239',
    city: 'Salzgitter',
    countrycode: 'DE',
  },
  {
    postalcode: '38259',
    city: 'Salzgitter',
    countrycode: 'DE',
  },
  {
    postalcode: '38268',
    city: 'Lengede',
    countrycode: 'DE',
  },
  {
    postalcode: '38271',
    city: 'Baddeckenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38272',
    city: 'Burgdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38274',
    city: 'Elbe',
    countrycode: 'DE',
  },
  {
    postalcode: '38275',
    city: 'Haverlah',
    countrycode: 'DE',
  },
  {
    postalcode: '38277',
    city: 'Heere',
    countrycode: 'DE',
  },
  {
    postalcode: '38279',
    city: 'Sehlde',
    countrycode: 'DE',
  },
  {
    postalcode: '38300',
    city: 'Wolfenbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '38302',
    city: 'Wolfenbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '38304',
    city: 'Wolfenbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '38312',
    city: 'Achim',
    countrycode: 'DE',
  },
  {
    postalcode: '38312',
    city: 'Börßum',
    countrycode: 'DE',
  },
  {
    postalcode: '38312',
    city: 'Cramme',
    countrycode: 'DE',
  },
  {
    postalcode: '38312',
    city: 'Dorstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '38312',
    city: 'Flöthe',
    countrycode: 'DE',
  },
  {
    postalcode: '38312',
    city: 'Heiningen',
    countrycode: 'DE',
  },
  {
    postalcode: '38312',
    city: 'Ohrum',
    countrycode: 'DE',
  },
  {
    postalcode: '38315',
    city: 'Gielde',
    countrycode: 'DE',
  },
  {
    postalcode: '38315',
    city: 'Hornburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38315',
    city: 'Schladen',
    countrycode: 'DE',
  },
  {
    postalcode: '38315',
    city: 'Werlaburgdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38319',
    city: 'Remlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '38321',
    city: 'Denkte',
    countrycode: 'DE',
  },
  {
    postalcode: '38322',
    city: 'Hedeper',
    countrycode: 'DE',
  },
  {
    postalcode: '38324',
    city: 'Kissenbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '38325',
    city: 'Roklum',
    countrycode: 'DE',
  },
  {
    postalcode: '38327',
    city: 'Semmenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38329',
    city: 'Wittmar',
    countrycode: 'DE',
  },
  {
    postalcode: '38350',
    city: 'Am Tekenberge',
    countrycode: 'DE',
  },
  {
    postalcode: '38350',
    city: 'Buschhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '38350',
    city: 'Helmstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38350',
    city: 'Kißleberfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '38350',
    city: 'Südschacht',
    countrycode: 'DE',
  },
  {
    postalcode: '38364',
    city: 'Schöningen',
    countrycode: 'DE',
  },
  {
    postalcode: '38368',
    city: 'Grasleben',
    countrycode: 'DE',
  },
  {
    postalcode: '38368',
    city: 'Mariental',
    countrycode: 'DE',
  },
  {
    postalcode: '38368',
    city: 'Querenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '38368',
    city: 'Rennau',
    countrycode: 'DE',
  },
  {
    postalcode: '38372',
    city: 'Büddenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38373',
    city: 'Frellstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38373',
    city: 'Süpplingen',
    countrycode: 'DE',
  },
  {
    postalcode: '38375',
    city: 'Räbke',
    countrycode: 'DE',
  },
  {
    postalcode: '38376',
    city: 'Süpplingenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38378',
    city: 'Warberg',
    countrycode: 'DE',
  },
  {
    postalcode: '38379',
    city: 'Wolsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38381',
    city: 'Jerxheim',
    countrycode: 'DE',
  },
  {
    postalcode: '38382',
    city: 'Beierstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38384',
    city: 'Gevensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '38385',
    city: 'Ingeleben',
    countrycode: 'DE',
  },
  {
    postalcode: '38387',
    city: 'Söllingen',
    countrycode: 'DE',
  },
  {
    postalcode: '38388',
    city: 'Twieflingen',
    countrycode: 'DE',
  },
  {
    postalcode: '38440',
    city: 'Wolfsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38442',
    city: 'Wolfsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38444',
    city: 'Wolfsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38446',
    city: 'Wolfsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38448',
    city: 'Wolfsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38458',
    city: 'Velpke',
    countrycode: 'DE',
  },
  {
    postalcode: '38459',
    city: 'Bahrdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38461',
    city: 'Danndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38462',
    city: 'Grafhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '38464',
    city: 'Groß Twülpstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38465',
    city: 'Brome',
    countrycode: 'DE',
  },
  {
    postalcode: '38467',
    city: 'Bergfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '38468',
    city: 'Ehra-Lessien',
    countrycode: 'DE',
  },
  {
    postalcode: '38470',
    city: 'Parsau',
    countrycode: 'DE',
  },
  {
    postalcode: '38471',
    city: 'Rühen',
    countrycode: 'DE',
  },
  {
    postalcode: '38473',
    city: 'Tiddische',
    countrycode: 'DE',
  },
  {
    postalcode: '38474',
    city: 'Tülau',
    countrycode: 'DE',
  },
  {
    postalcode: '38476',
    city: 'Barwedel',
    countrycode: 'DE',
  },
  {
    postalcode: '38477',
    city: 'Jembke',
    countrycode: 'DE',
  },
  {
    postalcode: '38479',
    city: 'Tappenbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Apenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Bandau',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Dönitz',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Hohenhenningen',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Immekath',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Jahrstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Klötze',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Kunrau',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Kusey',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Neuferchau',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Ristedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Röwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Steimke',
    countrycode: 'DE',
  },
  {
    postalcode: '38486',
    city: 'Wenze',
    countrycode: 'DE',
  },
  {
    postalcode: '38489',
    city: 'Ahlum',
    countrycode: 'DE',
  },
  {
    postalcode: '38489',
    city: 'Beetzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38489',
    city: 'Hanum',
    countrycode: 'DE',
  },
  {
    postalcode: '38489',
    city: 'Hohentramm',
    countrycode: 'DE',
  },
  {
    postalcode: '38489',
    city: 'Jeeben',
    countrycode: 'DE',
  },
  {
    postalcode: '38489',
    city: 'Jübar',
    countrycode: 'DE',
  },
  {
    postalcode: '38489',
    city: 'Lüdelsen',
    countrycode: 'DE',
  },
  {
    postalcode: '38489',
    city: 'Mellin',
    countrycode: 'DE',
  },
  {
    postalcode: '38489',
    city: 'Nettgau',
    countrycode: 'DE',
  },
  {
    postalcode: '38489',
    city: 'Rohrberg',
    countrycode: 'DE',
  },
  {
    postalcode: '38489',
    city: 'Tangeln',
    countrycode: 'DE',
  },
  {
    postalcode: '38518',
    city: 'Gifhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '38524',
    city: 'Sassenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38527',
    city: 'Meine',
    countrycode: 'DE',
  },
  {
    postalcode: '38528',
    city: 'Adenbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '38530',
    city: 'Didderse',
    countrycode: 'DE',
  },
  {
    postalcode: '38531',
    city: 'Rötgesbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '38533',
    city: 'Vordorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38536',
    city: 'Meinersen',
    countrycode: 'DE',
  },
  {
    postalcode: '38539',
    city: 'Müden',
    countrycode: 'DE',
  },
  {
    postalcode: '38542',
    city: 'Leiferde',
    countrycode: 'DE',
  },
  {
    postalcode: '38543',
    city: 'Hillerse',
    countrycode: 'DE',
  },
  {
    postalcode: '38547',
    city: 'Calberlah',
    countrycode: 'DE',
  },
  {
    postalcode: '38550',
    city: 'Isenbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '38551',
    city: 'Algesbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '38551',
    city: 'Ribbesbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '38553',
    city: 'Wasbüttel',
    countrycode: 'DE',
  },
  {
    postalcode: '38554',
    city: 'Weyhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '38556',
    city: 'Bokensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38557',
    city: 'Osloß',
    countrycode: 'DE',
  },
  {
    postalcode: '38559',
    city: 'Ringelah',
    countrycode: 'DE',
  },
  {
    postalcode: '38559',
    city: 'Wagenhoff',
    countrycode: 'DE',
  },
  {
    postalcode: '38640',
    city: 'Goslar',
    countrycode: 'DE',
  },
  {
    postalcode: '38642',
    city: 'Goslar',
    countrycode: 'DE',
  },
  {
    postalcode: '38644',
    city: 'Goslar',
    countrycode: 'DE',
  },
  {
    postalcode: '38667',
    city: 'Bad Harzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38667',
    city: 'Torfhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '38678',
    city: 'Clausthal-Zellerfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '38678',
    city: 'Oberschulenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '38685',
    city: 'Langelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '38690',
    city: 'Vienenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38700',
    city: 'Braunlage',
    countrycode: 'DE',
  },
  {
    postalcode: '38704',
    city: 'Liebenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38707',
    city: 'Altenau',
    countrycode: 'DE',
  },
  {
    postalcode: '38707',
    city: 'Schulenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '38709',
    city: 'Wildemann',
    countrycode: 'DE',
  },
  {
    postalcode: '38723',
    city: 'Seesen',
    countrycode: 'DE',
  },
  {
    postalcode: '38729',
    city: 'Hahausen',
    countrycode: 'DE',
  },
  {
    postalcode: '38729',
    city: 'Lutter',
    countrycode: 'DE',
  },
  {
    postalcode: '38729',
    city: 'Wallmoden',
    countrycode: 'DE',
  },
  {
    postalcode: '38820',
    city: 'Halberstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '38822',
    city: 'Aspenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38822',
    city: 'Athenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38822',
    city: 'Emersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '38822',
    city: 'Groß Quenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38822',
    city: 'Klein Quenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38822',
    city: 'Mahndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38822',
    city: 'Neu Runstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38822',
    city: 'Sargstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38822',
    city: 'Schachdorf Ströbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '38822',
    city: 'Veltensmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '38828',
    city: 'Rodersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38828',
    city: 'Wegeleben',
    countrycode: 'DE',
  },
  {
    postalcode: '38829',
    city: 'Harsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '38835',
    city: 'Berßel',
    countrycode: 'DE',
  },
  {
    postalcode: '38835',
    city: 'Bühne',
    countrycode: 'DE',
  },
  {
    postalcode: '38835',
    city: 'Deersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '38835',
    city: 'Hessen',
    countrycode: 'DE',
  },
  {
    postalcode: '38835',
    city: 'Lüttgenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '38835',
    city: 'Osterode',
    countrycode: 'DE',
  },
  {
    postalcode: '38835',
    city: 'Osterwieck',
    countrycode: 'DE',
  },
  {
    postalcode: '38835',
    city: 'Rhoden',
    countrycode: 'DE',
  },
  {
    postalcode: '38835',
    city: 'Schauen',
    countrycode: 'DE',
  },
  {
    postalcode: '38835',
    city: 'Veltheim',
    countrycode: 'DE',
  },
  {
    postalcode: '38835',
    city: 'Wülperode',
    countrycode: 'DE',
  },
  {
    postalcode: '38835',
    city: 'Zilly',
    countrycode: 'DE',
  },
  {
    postalcode: '38836',
    city: 'Anderbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '38836',
    city: 'Badersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '38836',
    city: 'Dardesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '38836',
    city: 'Dedeleben',
    countrycode: 'DE',
  },
  {
    postalcode: '38836',
    city: 'Huy-Neinstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38836',
    city: 'Pabstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38836',
    city: 'Rohrsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '38836',
    city: 'Vogelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38838',
    city: 'Aderstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38838',
    city: 'Dingelstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38838',
    city: 'Eilenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38838',
    city: 'Eilsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '38838',
    city: 'Schlanstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38855',
    city: 'Danstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38855',
    city: 'Heudeber',
    countrycode: 'DE',
  },
  {
    postalcode: '38855',
    city: 'Reddeber',
    countrycode: 'DE',
  },
  {
    postalcode: '38855',
    city: 'Schmatzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '38855',
    city: 'Wernigerode',
    countrycode: 'DE',
  },
  {
    postalcode: '38871',
    city: 'Abbenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '38871',
    city: 'Darlingerode',
    countrycode: 'DE',
  },
  {
    postalcode: '38871',
    city: 'Drübeck',
    countrycode: 'DE',
  },
  {
    postalcode: '38871',
    city: 'Ilsenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38871',
    city: 'Langeln',
    countrycode: 'DE',
  },
  {
    postalcode: '38871',
    city: 'Stapelburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38871',
    city: 'Veckenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38871',
    city: 'Wasserleben',
    countrycode: 'DE',
  },
  {
    postalcode: '38875',
    city: 'Drei Annen Hohne',
    countrycode: 'DE',
  },
  {
    postalcode: '38875',
    city: 'Elbingerode',
    countrycode: 'DE',
  },
  {
    postalcode: '38875',
    city: 'Elend',
    countrycode: 'DE',
  },
  {
    postalcode: '38875',
    city: 'Königshütte',
    countrycode: 'DE',
  },
  {
    postalcode: '38875',
    city: 'Sorge',
    countrycode: 'DE',
  },
  {
    postalcode: '38875',
    city: 'Tanne',
    countrycode: 'DE',
  },
  {
    postalcode: '38877',
    city: 'Benneckenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '38879',
    city: 'Schierke',
    countrycode: 'DE',
  },
  {
    postalcode: '38889',
    city: 'Altenbrak',
    countrycode: 'DE',
  },
  {
    postalcode: '38889',
    city: 'Blankenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38889',
    city: 'Cattenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '38889',
    city: 'Heimburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38889',
    city: 'Hüttenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '38889',
    city: 'Rübeland',
    countrycode: 'DE',
  },
  {
    postalcode: '38889',
    city: 'Treseburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38889',
    city: 'Wienrode',
    countrycode: 'DE',
  },
  {
    postalcode: '38895',
    city: 'Derenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '38895',
    city: 'Langenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '38899',
    city: 'Hasselfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '38899',
    city: 'Stiege',
    countrycode: 'DE',
  },
  {
    postalcode: '38899',
    city: 'Trautenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '39104',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39106',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39108',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39110',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39112',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39114',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39116',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39118',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39120',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39122',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39124',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39126',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39128',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39130',
    city: 'Magdeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39164',
    city: 'Bottmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39164',
    city: 'Domersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39164',
    city: 'Klein Wanzleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39164',
    city: 'Remkersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39164',
    city: 'Wanzleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39167',
    city: 'Eichenbarleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39167',
    city: 'Groß Rodensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39167',
    city: 'Hohendodeleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39167',
    city: 'Irxleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39167',
    city: 'Klein Rodensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39167',
    city: 'Niederndodeleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39167',
    city: 'Ochtmersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39167',
    city: 'Wellen',
    countrycode: 'DE',
  },
  {
    postalcode: '39171',
    city: 'Altenweddingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39171',
    city: 'Bahrendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39171',
    city: 'Dodendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39171',
    city: 'Langenweddingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39171',
    city: 'Osterweddingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39171',
    city: 'Schwaneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39171',
    city: 'Sülldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39175',
    city: 'Biederitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39175',
    city: 'Gerwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '39175',
    city: 'Gübs',
    countrycode: 'DE',
  },
  {
    postalcode: '39175',
    city: 'Königsborn',
    countrycode: 'DE',
  },
  {
    postalcode: '39175',
    city: 'Körbelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39175',
    city: 'Menz',
    countrycode: 'DE',
  },
  {
    postalcode: '39175',
    city: 'Wahlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39175',
    city: 'Woltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39179',
    city: 'Barleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39179',
    city: 'Ebendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39218',
    city: 'Schönebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '39221',
    city: 'Biere',
    countrycode: 'DE',
  },
  {
    postalcode: '39221',
    city: 'Eggersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39221',
    city: 'Eickendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39221',
    city: 'Großmühlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39221',
    city: 'Kleinmühlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39221',
    city: 'Ranies',
    countrycode: 'DE',
  },
  {
    postalcode: '39221',
    city: 'Welsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39221',
    city: 'Zens',
    countrycode: 'DE',
  },
  {
    postalcode: '39240',
    city: 'Breitenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '39240',
    city: 'Brumby',
    countrycode: 'DE',
  },
  {
    postalcode: '39240',
    city: 'Calbe',
    countrycode: 'DE',
  },
  {
    postalcode: '39240',
    city: 'Glöthe',
    countrycode: 'DE',
  },
  {
    postalcode: '39240',
    city: 'Groß Rosenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39240',
    city: 'Grube Alfred',
    countrycode: 'DE',
  },
  {
    postalcode: '39240',
    city: 'Lödderitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39240',
    city: 'Sachsendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39240',
    city: 'Schwarz',
    countrycode: 'DE',
  },
  {
    postalcode: '39240',
    city: 'Trabitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39240',
    city: 'Zuchau',
    countrycode: 'DE',
  },
  {
    postalcode: '39245',
    city: 'Dannigkow',
    countrycode: 'DE',
  },
  {
    postalcode: '39245',
    city: 'Gommern',
    countrycode: 'DE',
  },
  {
    postalcode: '39245',
    city: 'Plötzky',
    countrycode: 'DE',
  },
  {
    postalcode: '39245',
    city: 'Pretzien',
    countrycode: 'DE',
  },
  {
    postalcode: '39249',
    city: 'Barby',
    countrycode: 'DE',
  },
  {
    postalcode: '39249',
    city: 'Glinde',
    countrycode: 'DE',
  },
  {
    postalcode: '39249',
    city: 'Gnadau',
    countrycode: 'DE',
  },
  {
    postalcode: '39249',
    city: 'Pömmelte',
    countrycode: 'DE',
  },
  {
    postalcode: '39249',
    city: 'Tornitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39249',
    city: 'Wespen',
    countrycode: 'DE',
  },
  {
    postalcode: '39261',
    city: 'Zerbst',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Bias',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Bornum',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Buhlendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Deetz',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Dobritz',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Dornburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Gehrden',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Grimme',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Gödnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Güterglück',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Hohenlepte',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Jütrichau',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Leps',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Lindau',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Luso',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Lübs',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Moritz',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Nedlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Nutha',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Polenzko',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Prödel',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Pulspforde',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Reuden',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Steutz',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Straguth',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Walternienburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39264',
    city: 'Zernitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39279',
    city: 'Hobeck',
    countrycode: 'DE',
  },
  {
    postalcode: '39279',
    city: 'Ladeburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39279',
    city: 'Leitzkau',
    countrycode: 'DE',
  },
  {
    postalcode: '39279',
    city: 'Loburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39279',
    city: 'Rosian',
    countrycode: 'DE',
  },
  {
    postalcode: '39279',
    city: 'Schweinitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39279',
    city: 'Zeppernick',
    countrycode: 'DE',
  },
  {
    postalcode: '39288',
    city: 'Burg',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Büden',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Detershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Drewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Dörnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Friedensau',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Grabow',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Hohenwarthe',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Hohenziatz',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Ihleburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Karith',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Krüssau',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Küsel',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Lostau',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Lübars',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Magdeburgerforth',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Möckern',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Möser',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Nedlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Niegripp',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Parchau',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Pietzpuhl',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Reesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Reesen',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Rietzel',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Ringelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Schartau',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Schermen',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Schopsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Stegelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Stresow',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Theeßen',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Tryppehna',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Vehlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Wallwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Wörmlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Wüstenjerichow',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Zeddenick',
    countrycode: 'DE',
  },
  {
    postalcode: '39291',
    city: 'Ziepel',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Bergzow',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Brandenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Brettin',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Demsin',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Genthin',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Gladau',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Hohenseeden',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Kade',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Karow',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Klitsche',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Mützel',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Paplitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Parchen',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Roßdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Schlagenthin',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Tucheim',
    countrycode: 'DE',
  },
  {
    postalcode: '39307',
    city: 'Zabakuck',
    countrycode: 'DE',
  },
  {
    postalcode: '39317',
    city: 'Derben',
    countrycode: 'DE',
  },
  {
    postalcode: '39317',
    city: 'Ferchland',
    countrycode: 'DE',
  },
  {
    postalcode: '39317',
    city: 'Güsen',
    countrycode: 'DE',
  },
  {
    postalcode: '39317',
    city: 'Parey',
    countrycode: 'DE',
  },
  {
    postalcode: '39317',
    city: 'Zerben',
    countrycode: 'DE',
  },
  {
    postalcode: '39319',
    city: 'Jerichow',
    countrycode: 'DE',
  },
  {
    postalcode: '39319',
    city: 'Nielebock',
    countrycode: 'DE',
  },
  {
    postalcode: '39319',
    city: 'Redekin',
    countrycode: 'DE',
  },
  {
    postalcode: '39319',
    city: 'Wulkow',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Angern',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Colbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Dahlenwarsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Farsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Glindenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Groß Ammensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Gutenswegen',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Heinrichsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Hermsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Hohenwarsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Jersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Klein Ammensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Loitsche',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Meitzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Meseberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Mose',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Rogätz',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Samswegen',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Wolmirstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39326',
    city: 'Zielitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39340',
    city: 'Haldensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39340',
    city: 'Maschenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '39340',
    city: 'Zillbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Ackendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Alleringersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Altenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Bartensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Bebertal',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Beendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Bornstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Bregenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Eimersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Emden',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Erxleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Groß Santersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Hakenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Hillersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Hundisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Ivenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Morsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Nordgermersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Ostingersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Rottmersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Schackensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Schwanefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Süplingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39343',
    city: 'Uhrsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Born',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Bülstringen',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Detzel Gut',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Detzel Schloß',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Flechtingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Hütten',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Keindorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Neuenhofe',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Planken',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Satuelle',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Uthmöden',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Vahldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Wedringen',
    countrycode: 'DE',
  },
  {
    postalcode: '39345',
    city: 'Wieglitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39356',
    city: 'Behnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39356',
    city: 'Belsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39356',
    city: 'Döhren',
    countrycode: 'DE',
  },
  {
    postalcode: '39356',
    city: 'Eschenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '39356',
    city: 'Hödingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39356',
    city: 'Hörsingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39356',
    city: 'Seggerde',
    countrycode: 'DE',
  },
  {
    postalcode: '39356',
    city: 'Siestedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39356',
    city: 'Walbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '39356',
    city: 'Weferlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Böddensell',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Bösdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Calvörde',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Eickendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Etingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Everingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Gehrendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Grauingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Kathendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Lockstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Mannhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Rätzlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Velsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39359',
    city: 'Wegenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Drackenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Dreileben',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Druxberge',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Eggenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Eilsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Harbke',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Marienborn',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Ovelgünne',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Seehausen',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Sommersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Ummendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Wefensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39365',
    city: 'Wormsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39387',
    city: 'Altbrandsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39387',
    city: 'Ampfurth',
    countrycode: 'DE',
  },
  {
    postalcode: '39387',
    city: 'Großalsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39387',
    city: 'Hordorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39387',
    city: 'Hornhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '39387',
    city: 'Krottorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39387',
    city: 'Neindorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39387',
    city: 'Neuwegersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39387',
    city: 'Oschersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39387',
    city: 'Schermcke',
    countrycode: 'DE',
  },
  {
    postalcode: '39387',
    city: 'Wulferstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39393',
    city: 'Ausleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39393',
    city: 'Barneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39393',
    city: 'Beckendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39393',
    city: 'Gunsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39393',
    city: 'Hamersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39393',
    city: 'Hötensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39393',
    city: 'Ohrsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39393',
    city: 'Völpke',
    countrycode: 'DE',
  },
  {
    postalcode: '39393',
    city: 'Wackersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39397',
    city: 'Gröningen',
    countrycode: 'DE',
  },
  {
    postalcode: '39397',
    city: 'Kroppenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39397',
    city: 'Nienhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '39397',
    city: 'Schwanebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '39398',
    city: 'Alikendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39398',
    city: 'Groß Germersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39398',
    city: 'Hadmersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39398',
    city: 'Klein Oschersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39398',
    city: 'Kleinalsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39398',
    city: 'Peseckendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39418',
    city: 'Lust',
    countrycode: 'DE',
  },
  {
    postalcode: '39418',
    city: 'Neu Staßfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '39418',
    city: 'Neundorf (Anhalt)',
    countrycode: 'DE',
  },
  {
    postalcode: '39418',
    city: 'Rathmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39418',
    city: 'Staßfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '39435',
    city: 'Borne',
    countrycode: 'DE',
  },
  {
    postalcode: '39435',
    city: 'Egeln',
    countrycode: 'DE',
  },
  {
    postalcode: '39435',
    city: 'Groß Börnecke',
    countrycode: 'DE',
  },
  {
    postalcode: '39435',
    city: 'Schneidlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39435',
    city: 'Tarthun',
    countrycode: 'DE',
  },
  {
    postalcode: '39435',
    city: 'Unseburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39435',
    city: 'Wolmirsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39439',
    city: 'Amesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39439',
    city: 'Güsten',
    countrycode: 'DE',
  },
  {
    postalcode: '39439',
    city: 'Rathmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39443',
    city: 'Atzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39443',
    city: 'Brumby',
    countrycode: 'DE',
  },
  {
    postalcode: '39443',
    city: 'Förderstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39443',
    city: 'Glöthe',
    countrycode: 'DE',
  },
  {
    postalcode: '39443',
    city: 'Hohenerxleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39443',
    city: 'Löbnitz (Bode)',
    countrycode: 'DE',
  },
  {
    postalcode: '39443',
    city: 'Staßfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '39443',
    city: 'Üllnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39444',
    city: 'Hecklingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39446',
    city: 'Athensleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39446',
    city: 'Löderburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39446',
    city: 'Rothenförde',
    countrycode: 'DE',
  },
  {
    postalcode: '39446',
    city: 'Staßfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '39448',
    city: 'Etgersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39448',
    city: 'Hakeborn',
    countrycode: 'DE',
  },
  {
    postalcode: '39448',
    city: 'Westeregeln',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Bertingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Birkholz',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Bittkau',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Brunkau',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Buch',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Burgstall',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Bölsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Cobbel',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Cröchern',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Dolle',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Grieben',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Jerchel',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Kehnert',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Klein Schwarzlosen',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Lüderitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Mahlwinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Ringfurth',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Sandbeiendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Schelldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Schernebeck',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Schönwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Tangerhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Uchtdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Uetz',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Weißewarte',
    countrycode: 'DE',
  },
  {
    postalcode: '39517',
    city: 'Wenddorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Fischbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Garz',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Hohengöhren',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Jederitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Kamern',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Klietz',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Kuhlhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Mangelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Neuermark-Lübars',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Sandau',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Schönfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Schönhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Warnau',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Wulkau',
    countrycode: 'DE',
  },
  {
    postalcode: '39524',
    city: 'Wust',
    countrycode: 'DE',
  },
  {
    postalcode: '39539',
    city: 'Damerow',
    countrycode: 'DE',
  },
  {
    postalcode: '39539',
    city: 'Havelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39539',
    city: 'Kümmernitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39539',
    city: 'Neuwerben',
    countrycode: 'DE',
  },
  {
    postalcode: '39539',
    city: 'Nitzow',
    countrycode: 'DE',
  },
  {
    postalcode: '39539',
    city: 'Vehlgast',
    countrycode: 'DE',
  },
  {
    postalcode: '39539',
    city: 'Waldfrieden',
    countrycode: 'DE',
  },
  {
    postalcode: '39576',
    city: 'Stendal',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Bellingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Dahlen',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Demker',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Garlipp',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Grassau',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Grobleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Groß Schwechten',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Hüselitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Klein Schwechten',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Kläden',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Möllendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Petersmark',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Querstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Rochau',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Schernikau',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Schinne',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Schäplitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Uenglingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Windberge',
    countrycode: 'DE',
  },
  {
    postalcode: '39579',
    city: 'Wittenmoor',
    countrycode: 'DE',
  },
  {
    postalcode: '39590',
    city: 'Bindfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '39590',
    city: 'Heeren',
    countrycode: 'DE',
  },
  {
    postalcode: '39590',
    city: 'Hämerten',
    countrycode: 'DE',
  },
  {
    postalcode: '39590',
    city: 'Langensalzwedel',
    countrycode: 'DE',
  },
  {
    postalcode: '39590',
    city: 'Miltern',
    countrycode: 'DE',
  },
  {
    postalcode: '39590',
    city: 'Storkau',
    countrycode: 'DE',
  },
  {
    postalcode: '39590',
    city: 'Tangermünde',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Altenzaun',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Arneburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Baben',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Beelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Bertkow',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Eichstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Goldbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Hassel',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Hindenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Hohenberg-Krusemark',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Jarchau',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Lindtorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Sanne',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Schwarzholz',
    countrycode: 'DE',
  },
  {
    postalcode: '39596',
    city: 'Staffelde',
    countrycode: 'DE',
  },
  {
    postalcode: '39599',
    city: 'Deetz',
    countrycode: 'DE',
  },
  {
    postalcode: '39599',
    city: 'Insel',
    countrycode: 'DE',
  },
  {
    postalcode: '39599',
    city: 'Klinke',
    countrycode: 'DE',
  },
  {
    postalcode: '39599',
    city: 'Käthen',
    countrycode: 'DE',
  },
  {
    postalcode: '39599',
    city: 'Möringen',
    countrycode: 'DE',
  },
  {
    postalcode: '39599',
    city: 'Nahrstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39599',
    city: 'Staats',
    countrycode: 'DE',
  },
  {
    postalcode: '39599',
    city: 'Steinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '39599',
    city: 'Uchtspringe',
    countrycode: 'DE',
  },
  {
    postalcode: '39599',
    city: 'Vinzelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39599',
    city: 'Volgfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Ballerstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Behrendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Boock',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Bretsch',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Dobberkau',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Dobbrun',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Düsedau',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Erxleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Flessau',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Gagel',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Gladigau',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Heiligenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Hohenwulsch',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Iden',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Kleinau',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Kossebau',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Krevese',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Königsmark',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Lückstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Meseberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Osterburg',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Rossau',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Sandauerholz',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Sanne-Kerkuhn',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Schorstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39606',
    city: 'Walsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Aulosen',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Beuster',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Drüsedau',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Falkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Geestgottberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Gollensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Groß Garz',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Höwisch',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Krüden',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Leppin',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Lichterfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Losenrade',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Losse',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Neulingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Pollitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Schönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Seehausen',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Wahrenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Wanzer',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Wendemark',
    countrycode: 'DE',
  },
  {
    postalcode: '39615',
    city: 'Werben',
    countrycode: 'DE',
  },
  {
    postalcode: '39619',
    city: 'Arendsee',
    countrycode: 'DE',
  },
  {
    postalcode: '39619',
    city: 'Kläden',
    countrycode: 'DE',
  },
  {
    postalcode: '39619',
    city: 'Schrampe',
    countrycode: 'DE',
  },
  {
    postalcode: '39619',
    city: 'Thielbeer',
    countrycode: 'DE',
  },
  {
    postalcode: '39619',
    city: 'Ziemendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Altmersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Badel',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Berkau',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Brunau',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Büste',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Güssefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Jeetze',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Kahrstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Kakerbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Kalbe',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Kremkau',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Meßdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Neuendorf am Damm',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Packebusch',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Vienau',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Wernstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Winkelstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39624',
    city: 'Zethlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39629',
    city: 'Bismark',
    countrycode: 'DE',
  },
  {
    postalcode: '39629',
    city: 'Holzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '39629',
    city: 'Könnigde',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Algenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Berenbrock',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Berge',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Breitenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Dorst',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Engersen',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Estedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Gardelegen',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Hemstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Hottendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Jerchel',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Jeseritz',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Jävenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Kassieck',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Kloster Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Klüden',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Letzlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Lindstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Potzehne',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Roxförde',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Schenkenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Schwiesau',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Seethen',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Solpke',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Wannefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Wiepke',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Zichtau',
    countrycode: 'DE',
  },
  {
    postalcode: '39638',
    city: 'Zobbenitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39646',
    city: 'Kahnstieg',
    countrycode: 'DE',
  },
  {
    postalcode: '39646',
    city: 'Oebisfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '39646',
    city: 'Taterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '39649',
    city: 'Dannefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '39649',
    city: 'Jeggau',
    countrycode: 'DE',
  },
  {
    postalcode: '39649',
    city: 'Köckte',
    countrycode: 'DE',
  },
  {
    postalcode: '39649',
    city: 'Mieste',
    countrycode: 'DE',
  },
  {
    postalcode: '39649',
    city: 'Miesterhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '39649',
    city: 'Peckfitz',
    countrycode: 'DE',
  },
  {
    postalcode: '39649',
    city: 'Sachau',
    countrycode: 'DE',
  },
  {
    postalcode: '39649',
    city: 'Sichau',
    countrycode: 'DE',
  },
  {
    postalcode: '39649',
    city: 'Trippigleben',
    countrycode: 'DE',
  },
  {
    postalcode: '40210',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40211',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40212',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40213',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40215',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40217',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40219',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40221',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40223',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40225',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40227',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40229',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40231',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40233',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40235',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40237',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40239',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40468',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40470',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40472',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40472',
    city: 'Ratingen',
    countrycode: 'DE',
  },
  {
    postalcode: '40474',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40476',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40477',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40479',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40489',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40545',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40547',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40549',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40589',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40591',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40593',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40595',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40597',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40599',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40625',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40627',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40629',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40667',
    city: 'Meerbusch',
    countrycode: 'DE',
  },
  {
    postalcode: '40668',
    city: 'Meerbusch',
    countrycode: 'DE',
  },
  {
    postalcode: '40670',
    city: 'Meerbusch',
    countrycode: 'DE',
  },
  {
    postalcode: '40699',
    city: 'Erkrath',
    countrycode: 'DE',
  },
  {
    postalcode: '40721',
    city: 'Düsseldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '40721',
    city: 'Hilden',
    countrycode: 'DE',
  },
  {
    postalcode: '40723',
    city: 'Hilden',
    countrycode: 'DE',
  },
  {
    postalcode: '40724',
    city: 'Hilden',
    countrycode: 'DE',
  },
  {
    postalcode: '40764',
    city: 'Langenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '40789',
    city: 'Monheim',
    countrycode: 'DE',
  },
  {
    postalcode: '40822',
    city: 'Mettmann',
    countrycode: 'DE',
  },
  {
    postalcode: '40878',
    city: 'Ratingen',
    countrycode: 'DE',
  },
  {
    postalcode: '40880',
    city: 'Ratingen',
    countrycode: 'DE',
  },
  {
    postalcode: '40882',
    city: 'Ratingen',
    countrycode: 'DE',
  },
  {
    postalcode: '40883',
    city: 'Ratingen',
    countrycode: 'DE',
  },
  {
    postalcode: '40885',
    city: 'Ratingen',
    countrycode: 'DE',
  },
  {
    postalcode: '41061',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41063',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41065',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41066',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41068',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41069',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41169',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41179',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41189',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41199',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41236',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41238',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41239',
    city: 'Mönchengladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '41334',
    city: 'Nettetal',
    countrycode: 'DE',
  },
  {
    postalcode: '41352',
    city: 'Korschenbroich',
    countrycode: 'DE',
  },
  {
    postalcode: '41363',
    city: 'Jüchen',
    countrycode: 'DE',
  },
  {
    postalcode: '41366',
    city: 'Schwalmtal',
    countrycode: 'DE',
  },
  {
    postalcode: '41372',
    city: 'Niederkrüchten',
    countrycode: 'DE',
  },
  {
    postalcode: '41379',
    city: 'Brüggen',
    countrycode: 'DE',
  },
  {
    postalcode: '41460',
    city: 'Neuss',
    countrycode: 'DE',
  },
  {
    postalcode: '41462',
    city: 'Neuss',
    countrycode: 'DE',
  },
  {
    postalcode: '41464',
    city: 'Neuss',
    countrycode: 'DE',
  },
  {
    postalcode: '41466',
    city: 'Neuss',
    countrycode: 'DE',
  },
  {
    postalcode: '41468',
    city: 'Neuss',
    countrycode: 'DE',
  },
  {
    postalcode: '41469',
    city: 'Neuss',
    countrycode: 'DE',
  },
  {
    postalcode: '41470',
    city: 'Neuss',
    countrycode: 'DE',
  },
  {
    postalcode: '41472',
    city: 'Neuss',
    countrycode: 'DE',
  },
  {
    postalcode: '41515',
    city: 'Grevenbroich',
    countrycode: 'DE',
  },
  {
    postalcode: '41516',
    city: 'Grevenbroich',
    countrycode: 'DE',
  },
  {
    postalcode: '41517',
    city: 'Grevenbroich',
    countrycode: 'DE',
  },
  {
    postalcode: '41539',
    city: 'Dormagen',
    countrycode: 'DE',
  },
  {
    postalcode: '41540',
    city: 'Dormagen',
    countrycode: 'DE',
  },
  {
    postalcode: '41541',
    city: 'Dormagen',
    countrycode: 'DE',
  },
  {
    postalcode: '41542',
    city: 'Dormagen',
    countrycode: 'DE',
  },
  {
    postalcode: '41564',
    city: 'Kaarst',
    countrycode: 'DE',
  },
  {
    postalcode: '41569',
    city: 'Rommerskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '41747',
    city: 'Viersen',
    countrycode: 'DE',
  },
  {
    postalcode: '41748',
    city: 'Viersen',
    countrycode: 'DE',
  },
  {
    postalcode: '41749',
    city: 'Viersen',
    countrycode: 'DE',
  },
  {
    postalcode: '41751',
    city: 'Viersen',
    countrycode: 'DE',
  },
  {
    postalcode: '41812',
    city: 'Erkelenz',
    countrycode: 'DE',
  },
  {
    postalcode: '41836',
    city: 'Hückelhoven',
    countrycode: 'DE',
  },
  {
    postalcode: '41844',
    city: 'Wegberg',
    countrycode: 'DE',
  },
  {
    postalcode: '41849',
    city: 'Wassenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '42103',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42105',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42107',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42109',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42111',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42113',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42115',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42117',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42119',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42275',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42277',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42279',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42281',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42283',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42285',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42287',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42289',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42327',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42329',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42349',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42369',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42389',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42399',
    city: 'Wuppertal',
    countrycode: 'DE',
  },
  {
    postalcode: '42477',
    city: 'Radevormwald',
    countrycode: 'DE',
  },
  {
    postalcode: '42489',
    city: 'Wülfrath',
    countrycode: 'DE',
  },
  {
    postalcode: '42499',
    city: 'Hückeswagen',
    countrycode: 'DE',
  },
  {
    postalcode: '42549',
    city: 'Velbert',
    countrycode: 'DE',
  },
  {
    postalcode: '42551',
    city: 'Velbert',
    countrycode: 'DE',
  },
  {
    postalcode: '42553',
    city: 'Velbert',
    countrycode: 'DE',
  },
  {
    postalcode: '42555',
    city: 'Velbert',
    countrycode: 'DE',
  },
  {
    postalcode: '42579',
    city: 'Heiligenhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '42651',
    city: 'Solingen',
    countrycode: 'DE',
  },
  {
    postalcode: '42653',
    city: 'Solingen',
    countrycode: 'DE',
  },
  {
    postalcode: '42655',
    city: 'Solingen',
    countrycode: 'DE',
  },
  {
    postalcode: '42657',
    city: 'Solingen',
    countrycode: 'DE',
  },
  {
    postalcode: '42659',
    city: 'Solingen',
    countrycode: 'DE',
  },
  {
    postalcode: '42697',
    city: 'Solingen',
    countrycode: 'DE',
  },
  {
    postalcode: '42699',
    city: 'Solingen',
    countrycode: 'DE',
  },
  {
    postalcode: '42719',
    city: 'Solingen',
    countrycode: 'DE',
  },
  {
    postalcode: '42781',
    city: 'Haan',
    countrycode: 'DE',
  },
  {
    postalcode: '42799',
    city: 'Leichlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '42853',
    city: 'Remscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '42855',
    city: 'Remscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '42857',
    city: 'Remscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '42859',
    city: 'Remscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '42897',
    city: 'Remscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '42899',
    city: 'Remscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '42929',
    city: 'Wermelskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '44135',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44137',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44139',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44141',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44143',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44145',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44147',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44149',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44225',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44227',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44229',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44263',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44265',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44267',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44269',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44287',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44289',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44309',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44319',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44328',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44329',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44339',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44357',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44359',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44369',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44379',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44388',
    city: 'Dortmund',
    countrycode: 'DE',
  },
  {
    postalcode: '44532',
    city: 'Lünen',
    countrycode: 'DE',
  },
  {
    postalcode: '44534',
    city: 'Lünen',
    countrycode: 'DE',
  },
  {
    postalcode: '44536',
    city: 'Lünen',
    countrycode: 'DE',
  },
  {
    postalcode: '44575',
    city: 'Castrop-Rauxel',
    countrycode: 'DE',
  },
  {
    postalcode: '44577',
    city: 'Castrop-Rauxel',
    countrycode: 'DE',
  },
  {
    postalcode: '44579',
    city: 'Castrop-Rauxel',
    countrycode: 'DE',
  },
  {
    postalcode: '44581',
    city: 'Castrop-Rauxel',
    countrycode: 'DE',
  },
  {
    postalcode: '44623',
    city: 'Herne',
    countrycode: 'DE',
  },
  {
    postalcode: '44625',
    city: 'Herne',
    countrycode: 'DE',
  },
  {
    postalcode: '44627',
    city: 'Herne',
    countrycode: 'DE',
  },
  {
    postalcode: '44628',
    city: 'Herne',
    countrycode: 'DE',
  },
  {
    postalcode: '44629',
    city: 'Herne',
    countrycode: 'DE',
  },
  {
    postalcode: '44649',
    city: 'Herne',
    countrycode: 'DE',
  },
  {
    postalcode: '44651',
    city: 'Herne',
    countrycode: 'DE',
  },
  {
    postalcode: '44652',
    city: 'Herne',
    countrycode: 'DE',
  },
  {
    postalcode: '44653',
    city: 'Herne',
    countrycode: 'DE',
  },
  {
    postalcode: '44787',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44789',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44791',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44793',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44795',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44797',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44799',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44801',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44803',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44805',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44807',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44809',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44866',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44867',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44869',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44879',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44892',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '44894',
    city: 'Bochum',
    countrycode: 'DE',
  },
  {
    postalcode: '45127',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45128',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45130',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45131',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45133',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45134',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45136',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45138',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45139',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45141',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45143',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45144',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45145',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45147',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45149',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45219',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45239',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45257',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45259',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45276',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45277',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45279',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45289',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45307',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45309',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45326',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45327',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45329',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45355',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45356',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45357',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45359',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '45468',
    city: 'Mülheim',
    countrycode: 'DE',
  },
  {
    postalcode: '45470',
    city: 'Mülheim',
    countrycode: 'DE',
  },
  {
    postalcode: '45472',
    city: 'Mülheim',
    countrycode: 'DE',
  },
  {
    postalcode: '45473',
    city: 'Mülheim',
    countrycode: 'DE',
  },
  {
    postalcode: '45475',
    city: 'Mülheim',
    countrycode: 'DE',
  },
  {
    postalcode: '45476',
    city: 'Mülheim',
    countrycode: 'DE',
  },
  {
    postalcode: '45478',
    city: 'Mülheim',
    countrycode: 'DE',
  },
  {
    postalcode: '45479',
    city: 'Mülheim',
    countrycode: 'DE',
  },
  {
    postalcode: '45481',
    city: 'Mülheim',
    countrycode: 'DE',
  },
  {
    postalcode: '45525',
    city: 'Hattingen',
    countrycode: 'DE',
  },
  {
    postalcode: '45527',
    city: 'Hattingen',
    countrycode: 'DE',
  },
  {
    postalcode: '45529',
    city: 'Hattingen',
    countrycode: 'DE',
  },
  {
    postalcode: '45549',
    city: 'Sprockhövel',
    countrycode: 'DE',
  },
  {
    postalcode: '45657',
    city: 'Recklinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '45659',
    city: 'Recklinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '45661',
    city: 'Recklinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '45663',
    city: 'Recklinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '45665',
    city: 'Recklinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '45699',
    city: 'Herten',
    countrycode: 'DE',
  },
  {
    postalcode: '45701',
    city: 'Herten',
    countrycode: 'DE',
  },
  {
    postalcode: '45711',
    city: 'Datteln',
    countrycode: 'DE',
  },
  {
    postalcode: '45721',
    city: 'Haltern am See',
    countrycode: 'DE',
  },
  {
    postalcode: '45731',
    city: 'Waltrop',
    countrycode: 'DE',
  },
  {
    postalcode: '45739',
    city: 'Oer-Erkenschwick',
    countrycode: 'DE',
  },
  {
    postalcode: '45768',
    city: 'Marl',
    countrycode: 'DE',
  },
  {
    postalcode: '45770',
    city: 'Marl',
    countrycode: 'DE',
  },
  {
    postalcode: '45772',
    city: 'Marl',
    countrycode: 'DE',
  },
  {
    postalcode: '45879',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45881',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45883',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45884',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45886',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45888',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45889',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45891',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45892',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45894',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45896',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45897',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45899',
    city: 'Gelsenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '45964',
    city: 'Gladbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '45966',
    city: 'Gladbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '45968',
    city: 'Gladbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '46045',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '46047',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '46049',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '46117',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '46119',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '46145',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '46147',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '46149',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '46236',
    city: 'Bottrop',
    countrycode: 'DE',
  },
  {
    postalcode: '46238',
    city: 'Bottrop',
    countrycode: 'DE',
  },
  {
    postalcode: '46240',
    city: 'Bottrop',
    countrycode: 'DE',
  },
  {
    postalcode: '46242',
    city: 'Bottrop',
    countrycode: 'DE',
  },
  {
    postalcode: '46244',
    city: 'Bottrop',
    countrycode: 'DE',
  },
  {
    postalcode: '46282',
    city: 'Dorsten',
    countrycode: 'DE',
  },
  {
    postalcode: '46284',
    city: 'Dorsten',
    countrycode: 'DE',
  },
  {
    postalcode: '46286',
    city: 'Dorsten',
    countrycode: 'DE',
  },
  {
    postalcode: '46325',
    city: 'Borken',
    countrycode: 'DE',
  },
  {
    postalcode: '46342',
    city: 'Velen',
    countrycode: 'DE',
  },
  {
    postalcode: '46348',
    city: 'Raesfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '46354',
    city: 'Südlohn',
    countrycode: 'DE',
  },
  {
    postalcode: '46359',
    city: 'Heiden',
    countrycode: 'DE',
  },
  {
    postalcode: '46395',
    city: 'Bocholt',
    countrycode: 'DE',
  },
  {
    postalcode: '46397',
    city: 'Bocholt',
    countrycode: 'DE',
  },
  {
    postalcode: '46399',
    city: 'Bocholt',
    countrycode: 'DE',
  },
  {
    postalcode: '46414',
    city: 'Rhede',
    countrycode: 'DE',
  },
  {
    postalcode: '46419',
    city: 'Isselburg',
    countrycode: 'DE',
  },
  {
    postalcode: '46446',
    city: 'Emmerich',
    countrycode: 'DE',
  },
  {
    postalcode: '46459',
    city: 'Rees',
    countrycode: 'DE',
  },
  {
    postalcode: '46483',
    city: 'Wesel',
    countrycode: 'DE',
  },
  {
    postalcode: '46485',
    city: 'Wesel',
    countrycode: 'DE',
  },
  {
    postalcode: '46487',
    city: 'Wesel',
    countrycode: 'DE',
  },
  {
    postalcode: '46499',
    city: 'Hamminkeln',
    countrycode: 'DE',
  },
  {
    postalcode: '46509',
    city: 'Xanten',
    countrycode: 'DE',
  },
  {
    postalcode: '46514',
    city: 'Schermbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '46519',
    city: 'Alpen',
    countrycode: 'DE',
  },
  {
    postalcode: '46535',
    city: 'Dinslaken',
    countrycode: 'DE',
  },
  {
    postalcode: '46537',
    city: 'Dinslaken',
    countrycode: 'DE',
  },
  {
    postalcode: '46539',
    city: 'Dinslaken',
    countrycode: 'DE',
  },
  {
    postalcode: '46562',
    city: 'Voerde',
    countrycode: 'DE',
  },
  {
    postalcode: '46569',
    city: 'Hünxe',
    countrycode: 'DE',
  },
  {
    postalcode: '47051',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47053',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47055',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47057',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47058',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47059',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47119',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47137',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47138',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47139',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47166',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47167',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47169',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47178',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47179',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47198',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47199',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47226',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47228',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47229',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47239',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47249',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47259',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47269',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47279',
    city: 'Duisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47441',
    city: 'Moers',
    countrycode: 'DE',
  },
  {
    postalcode: '47443',
    city: 'Moers',
    countrycode: 'DE',
  },
  {
    postalcode: '47445',
    city: 'Moers',
    countrycode: 'DE',
  },
  {
    postalcode: '47447',
    city: 'Moers',
    countrycode: 'DE',
  },
  {
    postalcode: '47475',
    city: 'Kamp-Lintfort',
    countrycode: 'DE',
  },
  {
    postalcode: '47495',
    city: 'Rheinberg',
    countrycode: 'DE',
  },
  {
    postalcode: '47506',
    city: 'Neukirchen-Vluyn',
    countrycode: 'DE',
  },
  {
    postalcode: '47509',
    city: 'Rheurdt',
    countrycode: 'DE',
  },
  {
    postalcode: '47533',
    city: 'Kleve',
    countrycode: 'DE',
  },
  {
    postalcode: '47546',
    city: 'Kalkar',
    countrycode: 'DE',
  },
  {
    postalcode: '47551',
    city: 'Bedburg-Hau',
    countrycode: 'DE',
  },
  {
    postalcode: '47559',
    city: 'Kranenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '47574',
    city: 'Goch',
    countrycode: 'DE',
  },
  {
    postalcode: '47589',
    city: 'Uedem',
    countrycode: 'DE',
  },
  {
    postalcode: '47608',
    city: 'Geldern',
    countrycode: 'DE',
  },
  {
    postalcode: '47623',
    city: 'Kevelaer',
    countrycode: 'DE',
  },
  {
    postalcode: '47624',
    city: 'Kevelaer',
    countrycode: 'DE',
  },
  {
    postalcode: '47625',
    city: 'Kevelaer',
    countrycode: 'DE',
  },
  {
    postalcode: '47626',
    city: 'Kevelaer',
    countrycode: 'DE',
  },
  {
    postalcode: '47627',
    city: 'Kevelaer',
    countrycode: 'DE',
  },
  {
    postalcode: '47638',
    city: 'Straelen',
    countrycode: 'DE',
  },
  {
    postalcode: '47647',
    city: 'Kerken',
    countrycode: 'DE',
  },
  {
    postalcode: '47652',
    city: 'Weeze',
    countrycode: 'DE',
  },
  {
    postalcode: '47661',
    city: 'Issum',
    countrycode: 'DE',
  },
  {
    postalcode: '47665',
    city: 'Sonsbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '47669',
    city: 'Wachtendonk',
    countrycode: 'DE',
  },
  {
    postalcode: '47798',
    city: 'Krefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '47799',
    city: 'Krefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '47800',
    city: 'Krefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '47802',
    city: 'Krefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '47803',
    city: 'Krefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '47804',
    city: 'Krefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '47805',
    city: 'Krefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '47807',
    city: 'Krefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '47809',
    city: 'Krefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '47829',
    city: 'Krefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '47839',
    city: 'Krefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '47877',
    city: 'Willich',
    countrycode: 'DE',
  },
  {
    postalcode: '47906',
    city: 'Kempen',
    countrycode: 'DE',
  },
  {
    postalcode: '47918',
    city: 'Tönisvorst',
    countrycode: 'DE',
  },
  {
    postalcode: '47929',
    city: 'Grefrath',
    countrycode: 'DE',
  },
  {
    postalcode: '48143',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48145',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48147',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48149',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48151',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48153',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48155',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48157',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48159',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48161',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48163',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48165',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48167',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '48231',
    city: 'Warendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '48249',
    city: 'Dülmen',
    countrycode: 'DE',
  },
  {
    postalcode: '48268',
    city: 'Greven',
    countrycode: 'DE',
  },
  {
    postalcode: '48282',
    city: 'Emsdetten',
    countrycode: 'DE',
  },
  {
    postalcode: '48291',
    city: 'Telgte',
    countrycode: 'DE',
  },
  {
    postalcode: '48301',
    city: 'Nottuln',
    countrycode: 'DE',
  },
  {
    postalcode: '48308',
    city: 'Senden',
    countrycode: 'DE',
  },
  {
    postalcode: '48317',
    city: 'Drensteinfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '48324',
    city: 'Sendenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '48329',
    city: 'Havixbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '48336',
    city: 'Sassenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '48341',
    city: 'Altenberge',
    countrycode: 'DE',
  },
  {
    postalcode: '48346',
    city: 'Ostbevern',
    countrycode: 'DE',
  },
  {
    postalcode: '48351',
    city: 'Everswinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '48356',
    city: 'Nordwalde',
    countrycode: 'DE',
  },
  {
    postalcode: '48361',
    city: 'Beelen',
    countrycode: 'DE',
  },
  {
    postalcode: '48366',
    city: 'Laer',
    countrycode: 'DE',
  },
  {
    postalcode: '48369',
    city: 'Saerbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '48429',
    city: 'Rheine',
    countrycode: 'DE',
  },
  {
    postalcode: '48431',
    city: 'Rheine',
    countrycode: 'DE',
  },
  {
    postalcode: '48432',
    city: 'Rheine',
    countrycode: 'DE',
  },
  {
    postalcode: '48455',
    city: 'Bad Bentheim',
    countrycode: 'DE',
  },
  {
    postalcode: '48465',
    city: 'Engden',
    countrycode: 'DE',
  },
  {
    postalcode: '48465',
    city: 'Isterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '48465',
    city: 'Ohne',
    countrycode: 'DE',
  },
  {
    postalcode: '48465',
    city: 'Quendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '48465',
    city: 'Samern',
    countrycode: 'DE',
  },
  {
    postalcode: '48465',
    city: 'Schüttorf',
    countrycode: 'DE',
  },
  {
    postalcode: '48465',
    city: 'Suddendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '48477',
    city: 'Hörstel',
    countrycode: 'DE',
  },
  {
    postalcode: '48480',
    city: 'Lünne',
    countrycode: 'DE',
  },
  {
    postalcode: '48480',
    city: 'Schapen',
    countrycode: 'DE',
  },
  {
    postalcode: '48480',
    city: 'Spelle',
    countrycode: 'DE',
  },
  {
    postalcode: '48485',
    city: 'Neuenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '48488',
    city: 'Emsbüren',
    countrycode: 'DE',
  },
  {
    postalcode: '48493',
    city: 'Wettringen',
    countrycode: 'DE',
  },
  {
    postalcode: '48496',
    city: 'Hopsten',
    countrycode: 'DE',
  },
  {
    postalcode: '48499',
    city: 'Salzbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '48527',
    city: 'Nordhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '48529',
    city: 'Nordhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '48531',
    city: 'Nordhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '48565',
    city: 'Steinfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '48599',
    city: 'Gronau',
    countrycode: 'DE',
  },
  {
    postalcode: '48607',
    city: 'Ochtrup',
    countrycode: 'DE',
  },
  {
    postalcode: '48612',
    city: 'Horstmar',
    countrycode: 'DE',
  },
  {
    postalcode: '48619',
    city: 'Heek',
    countrycode: 'DE',
  },
  {
    postalcode: '48624',
    city: 'Schöppingen',
    countrycode: 'DE',
  },
  {
    postalcode: '48629',
    city: 'Metelen',
    countrycode: 'DE',
  },
  {
    postalcode: '48653',
    city: 'Coesfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '48683',
    city: 'Ahaus',
    countrycode: 'DE',
  },
  {
    postalcode: '48691',
    city: 'Vreden',
    countrycode: 'DE',
  },
  {
    postalcode: '48703',
    city: 'Stadtlohn',
    countrycode: 'DE',
  },
  {
    postalcode: '48712',
    city: 'Gescher',
    countrycode: 'DE',
  },
  {
    postalcode: '48720',
    city: 'Rosendahl',
    countrycode: 'DE',
  },
  {
    postalcode: '48727',
    city: 'Billerbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '48734',
    city: 'Reken',
    countrycode: 'DE',
  },
  {
    postalcode: '48739',
    city: 'Legden',
    countrycode: 'DE',
  },
  {
    postalcode: '49074',
    city: 'Osnabrück',
    countrycode: 'DE',
  },
  {
    postalcode: '49076',
    city: 'Osnabrück',
    countrycode: 'DE',
  },
  {
    postalcode: '49078',
    city: 'Osnabrück',
    countrycode: 'DE',
  },
  {
    postalcode: '49080',
    city: 'Osnabrück',
    countrycode: 'DE',
  },
  {
    postalcode: '49082',
    city: 'Osnabrück',
    countrycode: 'DE',
  },
  {
    postalcode: '49084',
    city: 'Osnabrück',
    countrycode: 'DE',
  },
  {
    postalcode: '49086',
    city: 'Osnabrück',
    countrycode: 'DE',
  },
  {
    postalcode: '49088',
    city: 'Osnabrück',
    countrycode: 'DE',
  },
  {
    postalcode: '49090',
    city: 'Osnabrück',
    countrycode: 'DE',
  },
  {
    postalcode: '49124',
    city: 'Georgsmarienhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '49134',
    city: 'Wallenhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '49143',
    city: 'Bissendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '49152',
    city: 'Bad Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '49163',
    city: 'Bohmte',
    countrycode: 'DE',
  },
  {
    postalcode: '49170',
    city: 'Hagen',
    countrycode: 'DE',
  },
  {
    postalcode: '49176',
    city: 'Hilter',
    countrycode: 'DE',
  },
  {
    postalcode: '49179',
    city: 'Ostercappeln',
    countrycode: 'DE',
  },
  {
    postalcode: '49186',
    city: 'Bad Iburg',
    countrycode: 'DE',
  },
  {
    postalcode: '49191',
    city: 'Belm',
    countrycode: 'DE',
  },
  {
    postalcode: '49196',
    city: 'Bad Laer',
    countrycode: 'DE',
  },
  {
    postalcode: '49201',
    city: 'Dissen',
    countrycode: 'DE',
  },
  {
    postalcode: '49205',
    city: 'Hasbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '49214',
    city: 'Bad Rothenfelde',
    countrycode: 'DE',
  },
  {
    postalcode: '49219',
    city: 'Glandorf',
    countrycode: 'DE',
  },
  {
    postalcode: '49324',
    city: 'Melle',
    countrycode: 'DE',
  },
  {
    postalcode: '49326',
    city: 'Melle',
    countrycode: 'DE',
  },
  {
    postalcode: '49328',
    city: 'Melle',
    countrycode: 'DE',
  },
  {
    postalcode: '49356',
    city: 'Diepholz',
    countrycode: 'DE',
  },
  {
    postalcode: '49377',
    city: 'Vechta',
    countrycode: 'DE',
  },
  {
    postalcode: '49393',
    city: 'Lohne',
    countrycode: 'DE',
  },
  {
    postalcode: '49401',
    city: 'Damme',
    countrycode: 'DE',
  },
  {
    postalcode: '49406',
    city: 'Barnstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '49406',
    city: 'Drentwede',
    countrycode: 'DE',
  },
  {
    postalcode: '49406',
    city: 'Eydelstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '49413',
    city: 'Dinklage',
    countrycode: 'DE',
  },
  {
    postalcode: '49419',
    city: 'Wagenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '49424',
    city: 'Goldenstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '49429',
    city: 'Visbek',
    countrycode: 'DE',
  },
  {
    postalcode: '49434',
    city: 'Neuenkirchen-Vörden',
    countrycode: 'DE',
  },
  {
    postalcode: '49439',
    city: 'Steinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '49448',
    city: 'Brockum',
    countrycode: 'DE',
  },
  {
    postalcode: '49448',
    city: 'Hüde',
    countrycode: 'DE',
  },
  {
    postalcode: '49448',
    city: 'Lemförde',
    countrycode: 'DE',
  },
  {
    postalcode: '49448',
    city: 'Marl',
    countrycode: 'DE',
  },
  {
    postalcode: '49448',
    city: 'Quernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '49448',
    city: 'Stemshorn',
    countrycode: 'DE',
  },
  {
    postalcode: '49451',
    city: 'Holdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '49453',
    city: 'Barver',
    countrycode: 'DE',
  },
  {
    postalcode: '49453',
    city: 'Dickel',
    countrycode: 'DE',
  },
  {
    postalcode: '49453',
    city: 'Hemsloh',
    countrycode: 'DE',
  },
  {
    postalcode: '49453',
    city: 'Rehden',
    countrycode: 'DE',
  },
  {
    postalcode: '49453',
    city: 'Wetschen',
    countrycode: 'DE',
  },
  {
    postalcode: '49456',
    city: 'Bakum',
    countrycode: 'DE',
  },
  {
    postalcode: '49457',
    city: 'Drebber',
    countrycode: 'DE',
  },
  {
    postalcode: '49457',
    city: 'Dreeker Fladder',
    countrycode: 'DE',
  },
  {
    postalcode: '49459',
    city: 'Berglage',
    countrycode: 'DE',
  },
  {
    postalcode: '49459',
    city: 'Burlage',
    countrycode: 'DE',
  },
  {
    postalcode: '49459',
    city: 'Evershorst',
    countrycode: 'DE',
  },
  {
    postalcode: '49459',
    city: 'Kuhlhorst',
    countrycode: 'DE',
  },
  {
    postalcode: '49459',
    city: 'Lembruch',
    countrycode: 'DE',
  },
  {
    postalcode: '49459',
    city: 'Mecklinge',
    countrycode: 'DE',
  },
  {
    postalcode: '49459',
    city: 'Quernheimerbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '49477',
    city: 'Ibbenbüren',
    countrycode: 'DE',
  },
  {
    postalcode: '49479',
    city: 'Ibbenbüren',
    countrycode: 'DE',
  },
  {
    postalcode: '49492',
    city: 'Westerkappeln',
    countrycode: 'DE',
  },
  {
    postalcode: '49497',
    city: 'Mettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '49504',
    city: 'Lotte',
    countrycode: 'DE',
  },
  {
    postalcode: '49509',
    city: 'Recke',
    countrycode: 'DE',
  },
  {
    postalcode: '49525',
    city: 'Lengerich',
    countrycode: 'DE',
  },
  {
    postalcode: '49536',
    city: 'Lienen',
    countrycode: 'DE',
  },
  {
    postalcode: '49545',
    city: 'Tecklenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '49549',
    city: 'Ladbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '49565',
    city: 'Bramsche',
    countrycode: 'DE',
  },
  {
    postalcode: '49577',
    city: 'Ankum',
    countrycode: 'DE',
  },
  {
    postalcode: '49577',
    city: 'Eggermühlen',
    countrycode: 'DE',
  },
  {
    postalcode: '49577',
    city: 'Kettenkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '49584',
    city: 'Fürstenau',
    countrycode: 'DE',
  },
  {
    postalcode: '49586',
    city: 'Merzen',
    countrycode: 'DE',
  },
  {
    postalcode: '49586',
    city: 'Neuenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '49593',
    city: 'Bersenbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '49594',
    city: 'Alfhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '49596',
    city: 'Gehrde',
    countrycode: 'DE',
  },
  {
    postalcode: '49597',
    city: 'Rieste',
    countrycode: 'DE',
  },
  {
    postalcode: '49599',
    city: 'Voltlage',
    countrycode: 'DE',
  },
  {
    postalcode: '49610',
    city: 'Quakenbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '49624',
    city: 'Löningen',
    countrycode: 'DE',
  },
  {
    postalcode: '49626',
    city: 'Berge',
    countrycode: 'DE',
  },
  {
    postalcode: '49626',
    city: 'Bippen',
    countrycode: 'DE',
  },
  {
    postalcode: '49632',
    city: 'Essen',
    countrycode: 'DE',
  },
  {
    postalcode: '49635',
    city: 'Badbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '49637',
    city: 'Menslage',
    countrycode: 'DE',
  },
  {
    postalcode: '49638',
    city: 'Nortrup',
    countrycode: 'DE',
  },
  {
    postalcode: '49661',
    city: 'Cloppenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '49681',
    city: 'Garrel',
    countrycode: 'DE',
  },
  {
    postalcode: '49685',
    city: 'Bühren',
    countrycode: 'DE',
  },
  {
    postalcode: '49685',
    city: 'Drantum',
    countrycode: 'DE',
  },
  {
    postalcode: '49685',
    city: 'Emstek',
    countrycode: 'DE',
  },
  {
    postalcode: '49685',
    city: 'Garthe',
    countrycode: 'DE',
  },
  {
    postalcode: '49685',
    city: 'Halen',
    countrycode: 'DE',
  },
  {
    postalcode: '49685',
    city: 'Hoheging',
    countrycode: 'DE',
  },
  {
    postalcode: '49685',
    city: 'Höltinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '49685',
    city: 'Schneiderkrug',
    countrycode: 'DE',
  },
  {
    postalcode: '49688',
    city: 'Lastrup',
    countrycode: 'DE',
  },
  {
    postalcode: '49692',
    city: 'Cappeln',
    countrycode: 'DE',
  },
  {
    postalcode: '49696',
    city: 'Dwergte',
    countrycode: 'DE',
  },
  {
    postalcode: '49696',
    city: 'Ermke',
    countrycode: 'DE',
  },
  {
    postalcode: '49696',
    city: 'Grönheim',
    countrycode: 'DE',
  },
  {
    postalcode: '49696',
    city: 'Molbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '49696',
    city: 'Peheim',
    countrycode: 'DE',
  },
  {
    postalcode: '49696',
    city: 'Resthausen',
    countrycode: 'DE',
  },
  {
    postalcode: '49696',
    city: 'Stalförden',
    countrycode: 'DE',
  },
  {
    postalcode: '49699',
    city: 'Lindern',
    countrycode: 'DE',
  },
  {
    postalcode: '49716',
    city: 'Meppen',
    countrycode: 'DE',
  },
  {
    postalcode: '49733',
    city: 'Haren',
    countrycode: 'DE',
  },
  {
    postalcode: '49740',
    city: 'Haselünne',
    countrycode: 'DE',
  },
  {
    postalcode: '49744',
    city: 'Geeste',
    countrycode: 'DE',
  },
  {
    postalcode: '49751',
    city: 'Hüven',
    countrycode: 'DE',
  },
  {
    postalcode: '49751',
    city: 'Spahnharrenstätte',
    countrycode: 'DE',
  },
  {
    postalcode: '49751',
    city: 'Sögel',
    countrycode: 'DE',
  },
  {
    postalcode: '49751',
    city: 'Werpeloh',
    countrycode: 'DE',
  },
  {
    postalcode: '49757',
    city: 'Lahn',
    countrycode: 'DE',
  },
  {
    postalcode: '49757',
    city: 'Vrees',
    countrycode: 'DE',
  },
  {
    postalcode: '49757',
    city: 'Werlte',
    countrycode: 'DE',
  },
  {
    postalcode: '49762',
    city: 'Fresenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '49762',
    city: 'Lathen',
    countrycode: 'DE',
  },
  {
    postalcode: '49762',
    city: 'Renkenberge',
    countrycode: 'DE',
  },
  {
    postalcode: '49762',
    city: 'Sustrum',
    countrycode: 'DE',
  },
  {
    postalcode: '49767',
    city: 'Twist',
    countrycode: 'DE',
  },
  {
    postalcode: '49770',
    city: 'Dohren',
    countrycode: 'DE',
  },
  {
    postalcode: '49770',
    city: 'Herzlake',
    countrycode: 'DE',
  },
  {
    postalcode: '49774',
    city: 'Lähden',
    countrycode: 'DE',
  },
  {
    postalcode: '49777',
    city: 'Groß Berßen',
    countrycode: 'DE',
  },
  {
    postalcode: '49777',
    city: 'Klein Berßen',
    countrycode: 'DE',
  },
  {
    postalcode: '49777',
    city: 'Stavern',
    countrycode: 'DE',
  },
  {
    postalcode: '49779',
    city: 'Niederlangen',
    countrycode: 'DE',
  },
  {
    postalcode: '49779',
    city: 'Oberlangen',
    countrycode: 'DE',
  },
  {
    postalcode: '49808',
    city: 'Lingen',
    countrycode: 'DE',
  },
  {
    postalcode: '49809',
    city: 'Lingen',
    countrycode: 'DE',
  },
  {
    postalcode: '49811',
    city: 'Lingen',
    countrycode: 'DE',
  },
  {
    postalcode: '49824',
    city: 'Emlichheim',
    countrycode: 'DE',
  },
  {
    postalcode: '49824',
    city: 'Laar',
    countrycode: 'DE',
  },
  {
    postalcode: '49824',
    city: 'Ringe',
    countrycode: 'DE',
  },
  {
    postalcode: '49828',
    city: 'Esche',
    countrycode: 'DE',
  },
  {
    postalcode: '49828',
    city: 'Georgsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '49828',
    city: 'Lage',
    countrycode: 'DE',
  },
  {
    postalcode: '49828',
    city: 'Neuenhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '49828',
    city: 'Osterwald',
    countrycode: 'DE',
  },
  {
    postalcode: '49832',
    city: 'Andervenne',
    countrycode: 'DE',
  },
  {
    postalcode: '49832',
    city: 'Beesten',
    countrycode: 'DE',
  },
  {
    postalcode: '49832',
    city: 'Freren',
    countrycode: 'DE',
  },
  {
    postalcode: '49832',
    city: 'Messingen',
    countrycode: 'DE',
  },
  {
    postalcode: '49832',
    city: 'Thuine',
    countrycode: 'DE',
  },
  {
    postalcode: '49835',
    city: 'Wietmarschen',
    countrycode: 'DE',
  },
  {
    postalcode: '49838',
    city: 'Gersten',
    countrycode: 'DE',
  },
  {
    postalcode: '49838',
    city: 'Handrup',
    countrycode: 'DE',
  },
  {
    postalcode: '49838',
    city: 'Langen',
    countrycode: 'DE',
  },
  {
    postalcode: '49838',
    city: 'Lengerich',
    countrycode: 'DE',
  },
  {
    postalcode: '49838',
    city: 'Wettrup',
    countrycode: 'DE',
  },
  {
    postalcode: '49843',
    city: 'Getelo',
    countrycode: 'DE',
  },
  {
    postalcode: '49843',
    city: 'Gölenkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '49843',
    city: 'Halle',
    countrycode: 'DE',
  },
  {
    postalcode: '49843',
    city: 'Uelsen',
    countrycode: 'DE',
  },
  {
    postalcode: '49844',
    city: 'Bawinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '49846',
    city: 'Hoogstede',
    countrycode: 'DE',
  },
  {
    postalcode: '49847',
    city: 'Itterbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '49847',
    city: 'Wielen',
    countrycode: 'DE',
  },
  {
    postalcode: '49849',
    city: 'Wilsum',
    countrycode: 'DE',
  },
  {
    postalcode: '50126',
    city: 'Bergheim',
    countrycode: 'DE',
  },
  {
    postalcode: '50127',
    city: 'Bergheim',
    countrycode: 'DE',
  },
  {
    postalcode: '50129',
    city: 'Bergheim',
    countrycode: 'DE',
  },
  {
    postalcode: '50169',
    city: 'Kerpen',
    countrycode: 'DE',
  },
  {
    postalcode: '50170',
    city: 'Kerpen',
    countrycode: 'DE',
  },
  {
    postalcode: '50171',
    city: 'Kerpen',
    countrycode: 'DE',
  },
  {
    postalcode: '50181',
    city: 'Bedburg',
    countrycode: 'DE',
  },
  {
    postalcode: '50189',
    city: 'Elsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '50226',
    city: 'Frechen',
    countrycode: 'DE',
  },
  {
    postalcode: '50259',
    city: 'Pulheim',
    countrycode: 'DE',
  },
  {
    postalcode: '50321',
    city: 'Brühl',
    countrycode: 'DE',
  },
  {
    postalcode: '50354',
    city: 'Hürth',
    countrycode: 'DE',
  },
  {
    postalcode: '50374',
    city: 'Erftstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '50389',
    city: 'Wesseling',
    countrycode: 'DE',
  },
  {
    postalcode: '50667',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50668',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50670',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50672',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50674',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50676',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50677',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50678',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50679',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50733',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50735',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50737',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50739',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50765',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50767',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50769',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50823',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50825',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50827',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50829',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50858',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50859',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50931',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50933',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50935',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50937',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50939',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50968',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50969',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50996',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50997',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '50999',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51061',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51063',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51065',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51067',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51069',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51103',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51105',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51107',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51109',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51143',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51145',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51147',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51149',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51371',
    city: 'Leverkusen',
    countrycode: 'DE',
  },
  {
    postalcode: '51373',
    city: 'Leverkusen',
    countrycode: 'DE',
  },
  {
    postalcode: '51375',
    city: 'Leverkusen',
    countrycode: 'DE',
  },
  {
    postalcode: '51377',
    city: 'Leverkusen',
    countrycode: 'DE',
  },
  {
    postalcode: '51379',
    city: 'Leverkusen',
    countrycode: 'DE',
  },
  {
    postalcode: '51381',
    city: 'Leverkusen',
    countrycode: 'DE',
  },
  {
    postalcode: '51399',
    city: 'Burscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '51427',
    city: 'Bergisch Gladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '51429',
    city: 'Bergisch Gladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '51465',
    city: 'Bergisch Gladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '51467',
    city: 'Bergisch Gladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '51467',
    city: 'Köln',
    countrycode: 'DE',
  },
  {
    postalcode: '51469',
    city: 'Bergisch Gladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '51491',
    city: 'Overath',
    countrycode: 'DE',
  },
  {
    postalcode: '51503',
    city: 'Rösrath',
    countrycode: 'DE',
  },
  {
    postalcode: '51515',
    city: 'Kürten',
    countrycode: 'DE',
  },
  {
    postalcode: '51519',
    city: 'Odenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '51545',
    city: 'Waldbröl',
    countrycode: 'DE',
  },
  {
    postalcode: '51570',
    city: 'Windeck',
    countrycode: 'DE',
  },
  {
    postalcode: '51580',
    city: 'Reichshof',
    countrycode: 'DE',
  },
  {
    postalcode: '51588',
    city: 'Nümbrecht',
    countrycode: 'DE',
  },
  {
    postalcode: '51597',
    city: 'Morsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '51598',
    city: 'Friesenhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '51643',
    city: 'Gummersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '51645',
    city: 'Gummersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '51647',
    city: 'Gummersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '51674',
    city: 'Wiehl',
    countrycode: 'DE',
  },
  {
    postalcode: '51688',
    city: 'Wipperfürth',
    countrycode: 'DE',
  },
  {
    postalcode: '51702',
    city: 'Bergneustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '51709',
    city: 'Marienheide',
    countrycode: 'DE',
  },
  {
    postalcode: '51766',
    city: 'Engelskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '51789',
    city: 'Lindlar',
    countrycode: 'DE',
  },
  {
    postalcode: '52062',
    city: 'Aachen',
    countrycode: 'DE',
  },
  {
    postalcode: '52064',
    city: 'Aachen',
    countrycode: 'DE',
  },
  {
    postalcode: '52066',
    city: 'Aachen',
    countrycode: 'DE',
  },
  {
    postalcode: '52068',
    city: 'Aachen',
    countrycode: 'DE',
  },
  {
    postalcode: '52070',
    city: 'Aachen',
    countrycode: 'DE',
  },
  {
    postalcode: '52072',
    city: 'Aachen',
    countrycode: 'DE',
  },
  {
    postalcode: '52074',
    city: 'Aachen',
    countrycode: 'DE',
  },
  {
    postalcode: '52076',
    city: 'Aachen',
    countrycode: 'DE',
  },
  {
    postalcode: '52078',
    city: 'Aachen',
    countrycode: 'DE',
  },
  {
    postalcode: '52080',
    city: 'Aachen',
    countrycode: 'DE',
  },
  {
    postalcode: '52134',
    city: 'Herzogenrath',
    countrycode: 'DE',
  },
  {
    postalcode: '52146',
    city: 'Würselen',
    countrycode: 'DE',
  },
  {
    postalcode: '52152',
    city: 'Simmerath',
    countrycode: 'DE',
  },
  {
    postalcode: '52156',
    city: 'Monschau',
    countrycode: 'DE',
  },
  {
    postalcode: '52159',
    city: 'Roetgen',
    countrycode: 'DE',
  },
  {
    postalcode: '52222',
    city: 'Stolberg',
    countrycode: 'DE',
  },
  {
    postalcode: '52223',
    city: 'Stolberg',
    countrycode: 'DE',
  },
  {
    postalcode: '52224',
    city: 'Stolberg',
    countrycode: 'DE',
  },
  {
    postalcode: '52249',
    city: 'Eschweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '52349',
    city: 'Düren',
    countrycode: 'DE',
  },
  {
    postalcode: '52351',
    city: 'Düren',
    countrycode: 'DE',
  },
  {
    postalcode: '52353',
    city: 'Düren',
    countrycode: 'DE',
  },
  {
    postalcode: '52355',
    city: 'Düren',
    countrycode: 'DE',
  },
  {
    postalcode: '52372',
    city: 'Kreuzau',
    countrycode: 'DE',
  },
  {
    postalcode: '52379',
    city: 'Langerwehe',
    countrycode: 'DE',
  },
  {
    postalcode: '52382',
    city: 'Niederzier',
    countrycode: 'DE',
  },
  {
    postalcode: '52385',
    city: 'Nideggen',
    countrycode: 'DE',
  },
  {
    postalcode: '52388',
    city: 'Nörvenich',
    countrycode: 'DE',
  },
  {
    postalcode: '52391',
    city: 'Vettweiß',
    countrycode: 'DE',
  },
  {
    postalcode: '52393',
    city: 'Hürtgenwald',
    countrycode: 'DE',
  },
  {
    postalcode: '52396',
    city: 'Heimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '52399',
    city: 'Merzenich',
    countrycode: 'DE',
  },
  {
    postalcode: '52428',
    city: 'Jülich',
    countrycode: 'DE',
  },
  {
    postalcode: '52441',
    city: 'Linnich',
    countrycode: 'DE',
  },
  {
    postalcode: '52445',
    city: 'Titz',
    countrycode: 'DE',
  },
  {
    postalcode: '52457',
    city: 'Aldenhoven',
    countrycode: 'DE',
  },
  {
    postalcode: '52459',
    city: 'Inden',
    countrycode: 'DE',
  },
  {
    postalcode: '52477',
    city: 'Alsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '52499',
    city: 'Baesweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '52511',
    city: 'Geilenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '52525',
    city: 'Heinsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '52525',
    city: 'Waldfeucht',
    countrycode: 'DE',
  },
  {
    postalcode: '52531',
    city: 'Übach-Palenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '52538',
    city: 'Gangelt',
    countrycode: 'DE',
  },
  {
    postalcode: '52538',
    city: 'Selfkant',
    countrycode: 'DE',
  },
  {
    postalcode: '53111',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53113',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53115',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53117',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53119',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53121',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53123',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53125',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53127',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53129',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53173',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53175',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53177',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53179',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53225',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53227',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53229',
    city: 'Bonn',
    countrycode: 'DE',
  },
  {
    postalcode: '53332',
    city: 'Bornheim',
    countrycode: 'DE',
  },
  {
    postalcode: '53340',
    city: 'Meckenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '53343',
    city: 'Wachtberg',
    countrycode: 'DE',
  },
  {
    postalcode: '53347',
    city: 'Alfter',
    countrycode: 'DE',
  },
  {
    postalcode: '53359',
    city: 'Rheinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53424',
    city: 'Calmuth',
    countrycode: 'DE',
  },
  {
    postalcode: '53424',
    city: 'Remagen',
    countrycode: 'DE',
  },
  {
    postalcode: '53426',
    city: 'Dedenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53426',
    city: 'Königsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '53426',
    city: 'Schalkenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53474',
    city: 'Bad Neuenahr-Ahrweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '53489',
    city: 'Sinzig',
    countrycode: 'DE',
  },
  {
    postalcode: '53498',
    city: 'Bad Breisig',
    countrycode: 'DE',
  },
  {
    postalcode: '53498',
    city: 'Gönnersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '53498',
    city: 'Waldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '53501',
    city: 'Grafschaft',
    countrycode: 'DE',
  },
  {
    postalcode: '53505',
    city: 'Altenahr',
    countrycode: 'DE',
  },
  {
    postalcode: '53505',
    city: 'Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '53505',
    city: 'Kalenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '53505',
    city: 'Kirchsahr',
    countrycode: 'DE',
  },
  {
    postalcode: '53506',
    city: 'Ahrbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '53506',
    city: 'Heckenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53506',
    city: 'Hönningen',
    countrycode: 'DE',
  },
  {
    postalcode: '53506',
    city: 'Kesseling',
    countrycode: 'DE',
  },
  {
    postalcode: '53506',
    city: 'Lind',
    countrycode: 'DE',
  },
  {
    postalcode: '53506',
    city: 'Rech',
    countrycode: 'DE',
  },
  {
    postalcode: '53507',
    city: 'Dernau',
    countrycode: 'DE',
  },
  {
    postalcode: '53507',
    city: 'Marienthal',
    countrycode: 'DE',
  },
  {
    postalcode: '53508',
    city: 'Mayschoß',
    countrycode: 'DE',
  },
  {
    postalcode: '53518',
    city: 'Adenau',
    countrycode: 'DE',
  },
  {
    postalcode: '53518',
    city: 'Herschbroich',
    countrycode: 'DE',
  },
  {
    postalcode: '53518',
    city: 'Honerath',
    countrycode: 'DE',
  },
  {
    postalcode: '53518',
    city: 'Kottenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '53518',
    city: 'Kottenbornermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '53518',
    city: 'Leimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53518',
    city: 'Quiddelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53518',
    city: 'Wimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Bierschbacher Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Dankerath',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Daubiansmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Drees',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Döttinger Höhe',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Dümpelfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Harscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Hümmel',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Insul',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Kaltenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Meuspath',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Müllenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Nürburg',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Ohlenhard',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Reifferscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Rodder',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Schuld',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Senscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Sierscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Trierscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Wershofen',
    countrycode: 'DE',
  },
  {
    postalcode: '53520',
    city: 'Winnerath',
    countrycode: 'DE',
  },
  {
    postalcode: '53533',
    city: 'Antweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '53533',
    city: 'Aremberg',
    countrycode: 'DE',
  },
  {
    postalcode: '53533',
    city: 'Dorsel',
    countrycode: 'DE',
  },
  {
    postalcode: '53533',
    city: 'Eichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53533',
    city: 'Fuchshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '53533',
    city: 'Laufenbacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '53533',
    city: 'Müsch',
    countrycode: 'DE',
  },
  {
    postalcode: '53533',
    city: 'Stahlhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '53534',
    city: 'Barweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '53534',
    city: 'Bauler',
    countrycode: 'DE',
  },
  {
    postalcode: '53534',
    city: 'Hoffeld',
    countrycode: 'DE',
  },
  {
    postalcode: '53534',
    city: 'Kirmutscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '53534',
    city: 'Pomster',
    countrycode: 'DE',
  },
  {
    postalcode: '53534',
    city: 'Wiesemscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '53534',
    city: 'Wirft',
    countrycode: 'DE',
  },
  {
    postalcode: '53539',
    city: 'Bodenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53539',
    city: 'Bongard',
    countrycode: 'DE',
  },
  {
    postalcode: '53539',
    city: 'Borler',
    countrycode: 'DE',
  },
  {
    postalcode: '53539',
    city: 'Brücktal',
    countrycode: 'DE',
  },
  {
    postalcode: '53539',
    city: 'Gelenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '53539',
    city: 'Kelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '53539',
    city: 'Kirsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53539',
    city: 'Reimerath',
    countrycode: 'DE',
  },
  {
    postalcode: '53539',
    city: 'Welcherath',
    countrycode: 'DE',
  },
  {
    postalcode: '53545',
    city: 'Linz',
    countrycode: 'DE',
  },
  {
    postalcode: '53545',
    city: 'Ockenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Alsau',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Arnsau',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Breitscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Dattenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Girgenrath',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Grübelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Hausen',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Heeg',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Hümmerich',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Kasbach-Ohlenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Krumscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Leubsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Reidenbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '53547',
    city: 'Roßbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53557',
    city: 'Bad Hönningen',
    countrycode: 'DE',
  },
  {
    postalcode: '53560',
    city: 'Kretzhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '53560',
    city: 'Vettelschoß',
    countrycode: 'DE',
  },
  {
    postalcode: '53562',
    city: 'Hähnen',
    countrycode: 'DE',
  },
  {
    postalcode: '53562',
    city: 'Rothekreuz',
    countrycode: 'DE',
  },
  {
    postalcode: '53562',
    city: 'St Katharinen',
    countrycode: 'DE',
  },
  {
    postalcode: '53567',
    city: 'Asbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53567',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '53572',
    city: 'Bruchhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '53572',
    city: 'Hohenunkel',
    countrycode: 'DE',
  },
  {
    postalcode: '53572',
    city: 'Unkel',
    countrycode: 'DE',
  },
  {
    postalcode: '53577',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '53578',
    city: 'Windhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '53579',
    city: 'Erpel',
    countrycode: 'DE',
  },
  {
    postalcode: '53604',
    city: 'Bad Honnef',
    countrycode: 'DE',
  },
  {
    postalcode: '53619',
    city: 'Rheinbreitbach',
    countrycode: 'DE',
  },
  {
    postalcode: '53639',
    city: 'Königswinter',
    countrycode: 'DE',
  },
  {
    postalcode: '53721',
    city: 'Siegburg',
    countrycode: 'DE',
  },
  {
    postalcode: '53757',
    city: 'Sankt Augustin',
    countrycode: 'DE',
  },
  {
    postalcode: '53773',
    city: 'Hennef',
    countrycode: 'DE',
  },
  {
    postalcode: '53783',
    city: 'Eitorf',
    countrycode: 'DE',
  },
  {
    postalcode: '53797',
    city: 'Lohmar',
    countrycode: 'DE',
  },
  {
    postalcode: '53804',
    city: 'Much',
    countrycode: 'DE',
  },
  {
    postalcode: '53809',
    city: 'Ruppichteroth',
    countrycode: 'DE',
  },
  {
    postalcode: '53819',
    city: 'Neunkirchen-Seelscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '53840',
    city: 'Troisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '53842',
    city: 'Troisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '53844',
    city: 'Troisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '53859',
    city: 'Niederkassel',
    countrycode: 'DE',
  },
  {
    postalcode: '53879',
    city: 'Euskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '53881',
    city: 'Euskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '53894',
    city: 'Mechernich',
    countrycode: 'DE',
  },
  {
    postalcode: '53902',
    city: 'Bad Münstereifel',
    countrycode: 'DE',
  },
  {
    postalcode: '53909',
    city: 'Zülpich',
    countrycode: 'DE',
  },
  {
    postalcode: '53913',
    city: 'Swisttal',
    countrycode: 'DE',
  },
  {
    postalcode: '53919',
    city: 'Weilerswist',
    countrycode: 'DE',
  },
  {
    postalcode: '53925',
    city: 'Kall',
    countrycode: 'DE',
  },
  {
    postalcode: '53937',
    city: 'Schleiden',
    countrycode: 'DE',
  },
  {
    postalcode: '53940',
    city: 'Hellenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '53945',
    city: 'Blankenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '53947',
    city: 'Nettersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '53949',
    city: 'Dahlem',
    countrycode: 'DE',
  },
  {
    postalcode: '54290',
    city: 'Trier',
    countrycode: 'DE',
  },
  {
    postalcode: '54292',
    city: 'Trier',
    countrycode: 'DE',
  },
  {
    postalcode: '54293',
    city: 'Trier',
    countrycode: 'DE',
  },
  {
    postalcode: '54294',
    city: 'Trier',
    countrycode: 'DE',
  },
  {
    postalcode: '54295',
    city: 'Trier',
    countrycode: 'DE',
  },
  {
    postalcode: '54296',
    city: 'Trier',
    countrycode: 'DE',
  },
  {
    postalcode: '54298',
    city: 'Aach',
    countrycode: 'DE',
  },
  {
    postalcode: '54298',
    city: 'Eisenach',
    countrycode: 'DE',
  },
  {
    postalcode: '54298',
    city: 'Gilzem',
    countrycode: 'DE',
  },
  {
    postalcode: '54298',
    city: 'Höhjunk',
    countrycode: 'DE',
  },
  {
    postalcode: '54298',
    city: 'Igel',
    countrycode: 'DE',
  },
  {
    postalcode: '54298',
    city: 'Orenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '54298',
    city: 'Welschbillig',
    countrycode: 'DE',
  },
  {
    postalcode: '54306',
    city: 'Kordel',
    countrycode: 'DE',
  },
  {
    postalcode: '54308',
    city: 'Langsur',
    countrycode: 'DE',
  },
  {
    postalcode: '54309',
    city: 'Newel',
    countrycode: 'DE',
  },
  {
    postalcode: '54310',
    city: 'Menningen',
    countrycode: 'DE',
  },
  {
    postalcode: '54310',
    city: 'Minden',
    countrycode: 'DE',
  },
  {
    postalcode: '54310',
    city: 'Ralingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54311',
    city: 'Trierweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54313',
    city: 'Zemmer',
    countrycode: 'DE',
  },
  {
    postalcode: '54314',
    city: 'Baldringen',
    countrycode: 'DE',
  },
  {
    postalcode: '54314',
    city: 'Benratherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '54314',
    city: 'Greimerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54314',
    city: 'Hentern',
    countrycode: 'DE',
  },
  {
    postalcode: '54314',
    city: 'Neunhäuser',
    countrycode: 'DE',
  },
  {
    postalcode: '54314',
    city: 'Paschel',
    countrycode: 'DE',
  },
  {
    postalcode: '54314',
    city: 'Schömerich',
    countrycode: 'DE',
  },
  {
    postalcode: '54314',
    city: 'Vierherrenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '54314',
    city: 'Zerf',
    countrycode: 'DE',
  },
  {
    postalcode: '54316',
    city: 'Bonerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54316',
    city: 'Franzenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54316',
    city: 'Hinzenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54316',
    city: 'Hockweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54316',
    city: 'Holzerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54316',
    city: 'Lampaden',
    countrycode: 'DE',
  },
  {
    postalcode: '54316',
    city: 'Ollmuth',
    countrycode: 'DE',
  },
  {
    postalcode: '54316',
    city: 'Pluwig',
    countrycode: 'DE',
  },
  {
    postalcode: '54316',
    city: 'Schöndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54317',
    city: 'Farschweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54317',
    city: 'Gusterath',
    countrycode: 'DE',
  },
  {
    postalcode: '54317',
    city: 'Gutweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54317',
    city: 'Herl',
    countrycode: 'DE',
  },
  {
    postalcode: '54317',
    city: 'Kasel',
    countrycode: 'DE',
  },
  {
    postalcode: '54317',
    city: 'Korlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54317',
    city: 'Lorscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54317',
    city: 'Morscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54317',
    city: 'Osburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54317',
    city: 'Riveris',
    countrycode: 'DE',
  },
  {
    postalcode: '54317',
    city: 'Sommerau',
    countrycode: 'DE',
  },
  {
    postalcode: '54317',
    city: 'Thomm',
    countrycode: 'DE',
  },
  {
    postalcode: '54318',
    city: 'Mertesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54320',
    city: 'Waldrach',
    countrycode: 'DE',
  },
  {
    postalcode: '54329',
    city: 'Konz',
    countrycode: 'DE',
  },
  {
    postalcode: '54331',
    city: 'Oberbillig',
    countrycode: 'DE',
  },
  {
    postalcode: '54331',
    city: 'Pellingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54332',
    city: 'Wasserliesch',
    countrycode: 'DE',
  },
  {
    postalcode: '54338',
    city: 'Longen',
    countrycode: 'DE',
  },
  {
    postalcode: '54338',
    city: 'Schweich',
    countrycode: 'DE',
  },
  {
    postalcode: '54340',
    city: 'Bekond',
    countrycode: 'DE',
  },
  {
    postalcode: '54340',
    city: 'Detzem',
    countrycode: 'DE',
  },
  {
    postalcode: '54340',
    city: 'Ensch',
    countrycode: 'DE',
  },
  {
    postalcode: '54340',
    city: 'Klüsserath',
    countrycode: 'DE',
  },
  {
    postalcode: '54340',
    city: 'Köwerich',
    countrycode: 'DE',
  },
  {
    postalcode: '54340',
    city: 'Leiwen',
    countrycode: 'DE',
  },
  {
    postalcode: '54340',
    city: 'Longuich',
    countrycode: 'DE',
  },
  {
    postalcode: '54340',
    city: 'Naurath',
    countrycode: 'DE',
  },
  {
    postalcode: '54340',
    city: 'Pölich',
    countrycode: 'DE',
  },
  {
    postalcode: '54340',
    city: 'Riol',
    countrycode: 'DE',
  },
  {
    postalcode: '54340',
    city: 'Schleich',
    countrycode: 'DE',
  },
  {
    postalcode: '54340',
    city: 'Thörnich',
    countrycode: 'DE',
  },
  {
    postalcode: '54341',
    city: 'Fell',
    countrycode: 'DE',
  },
  {
    postalcode: '54343',
    city: 'Föhren',
    countrycode: 'DE',
  },
  {
    postalcode: '54344',
    city: 'Kenn',
    countrycode: 'DE',
  },
  {
    postalcode: '54346',
    city: 'Mehring',
    countrycode: 'DE',
  },
  {
    postalcode: '54347',
    city: 'Neumagen-Dhron',
    countrycode: 'DE',
  },
  {
    postalcode: '54349',
    city: 'Trittenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54411',
    city: 'Deuselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54411',
    city: 'Hermeskeil',
    countrycode: 'DE',
  },
  {
    postalcode: '54411',
    city: 'Rorodt',
    countrycode: 'DE',
  },
  {
    postalcode: '54413',
    city: 'Bescheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54413',
    city: 'Beuren',
    countrycode: 'DE',
  },
  {
    postalcode: '54413',
    city: 'Damflos',
    countrycode: 'DE',
  },
  {
    postalcode: '54413',
    city: 'Geisfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54413',
    city: 'Grimburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54413',
    city: 'Gusenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54413',
    city: 'Rascheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54421',
    city: 'Hinzert-Pölert',
    countrycode: 'DE',
  },
  {
    postalcode: '54421',
    city: 'Reinsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54422',
    city: 'Börfink',
    countrycode: 'DE',
  },
  {
    postalcode: '54422',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '54422',
    city: 'Neuhütten',
    countrycode: 'DE',
  },
  {
    postalcode: '54422',
    city: 'Züsch',
    countrycode: 'DE',
  },
  {
    postalcode: '54424',
    city: 'Burtscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54424',
    city: 'Etgert',
    countrycode: 'DE',
  },
  {
    postalcode: '54424',
    city: 'Gielert',
    countrycode: 'DE',
  },
  {
    postalcode: '54424',
    city: 'Lückenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54424',
    city: 'Thalfang',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Berglicht',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Breit',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Büdlich',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Dhronecken',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Gräfendhron',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Heidenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Hilscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Immert',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Malborn',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Merschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Naurath',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Neunkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Schönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '54426',
    city: 'Talling',
    countrycode: 'DE',
  },
  {
    postalcode: '54427',
    city: 'Kell',
    countrycode: 'DE',
  },
  {
    postalcode: '54427',
    city: 'Mühlscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54429',
    city: 'Heddert',
    countrycode: 'DE',
  },
  {
    postalcode: '54429',
    city: 'Mandern',
    countrycode: 'DE',
  },
  {
    postalcode: '54429',
    city: 'Schillingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54429',
    city: 'Waldweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54439',
    city: 'Fisch',
    countrycode: 'DE',
  },
  {
    postalcode: '54439',
    city: 'Merzkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '54439',
    city: 'Palzem',
    countrycode: 'DE',
  },
  {
    postalcode: '54439',
    city: 'Saarburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54441',
    city: 'Ayl',
    countrycode: 'DE',
  },
  {
    postalcode: '54441',
    city: 'Kanzem',
    countrycode: 'DE',
  },
  {
    postalcode: '54441',
    city: 'Kastel-Staadt',
    countrycode: 'DE',
  },
  {
    postalcode: '54441',
    city: 'Kirf',
    countrycode: 'DE',
  },
  {
    postalcode: '54441',
    city: 'Mannebach',
    countrycode: 'DE',
  },
  {
    postalcode: '54441',
    city: 'Ockfen',
    countrycode: 'DE',
  },
  {
    postalcode: '54441',
    city: 'Schoden',
    countrycode: 'DE',
  },
  {
    postalcode: '54441',
    city: 'Taben-Rodt',
    countrycode: 'DE',
  },
  {
    postalcode: '54441',
    city: 'Temmels',
    countrycode: 'DE',
  },
  {
    postalcode: '54441',
    city: 'Trassem',
    countrycode: 'DE',
  },
  {
    postalcode: '54441',
    city: 'Wawern',
    countrycode: 'DE',
  },
  {
    postalcode: '54441',
    city: 'Wellen',
    countrycode: 'DE',
  },
  {
    postalcode: '54450',
    city: 'Freudenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54451',
    city: 'Irsch',
    countrycode: 'DE',
  },
  {
    postalcode: '54453',
    city: 'Nittel',
    countrycode: 'DE',
  },
  {
    postalcode: '54455',
    city: 'Serrig',
    countrycode: 'DE',
  },
  {
    postalcode: '54456',
    city: 'Onsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54456',
    city: 'Tawern',
    countrycode: 'DE',
  },
  {
    postalcode: '54457',
    city: 'Wincheringen',
    countrycode: 'DE',
  },
  {
    postalcode: '54459',
    city: 'Wiltingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54470',
    city: 'Bernkastel-Kues',
    countrycode: 'DE',
  },
  {
    postalcode: '54470',
    city: 'Graach',
    countrycode: 'DE',
  },
  {
    postalcode: '54470',
    city: 'Hirzlei',
    countrycode: 'DE',
  },
  {
    postalcode: '54470',
    city: 'Lieser',
    countrycode: 'DE',
  },
  {
    postalcode: '54472',
    city: 'Brauneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '54472',
    city: 'Burgen',
    countrycode: 'DE',
  },
  {
    postalcode: '54472',
    city: 'Gornhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54472',
    city: 'Hochscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54472',
    city: 'Kommen',
    countrycode: 'DE',
  },
  {
    postalcode: '54472',
    city: 'Longkamp',
    countrycode: 'DE',
  },
  {
    postalcode: '54472',
    city: 'Monzelfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54472',
    city: 'Veldenz',
    countrycode: 'DE',
  },
  {
    postalcode: '54483',
    city: 'Kleinich',
    countrycode: 'DE',
  },
  {
    postalcode: '54484',
    city: 'Maring-Noviand',
    countrycode: 'DE',
  },
  {
    postalcode: '54486',
    city: 'Mülheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54487',
    city: 'Wintrich',
    countrycode: 'DE',
  },
  {
    postalcode: '54492',
    city: 'Altmachern',
    countrycode: 'DE',
  },
  {
    postalcode: '54492',
    city: 'Erden',
    countrycode: 'DE',
  },
  {
    postalcode: '54492',
    city: 'Klosterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '54492',
    city: 'Lösnich',
    countrycode: 'DE',
  },
  {
    postalcode: '54492',
    city: 'Machern',
    countrycode: 'DE',
  },
  {
    postalcode: '54492',
    city: 'Zeltingen-Rachtig',
    countrycode: 'DE',
  },
  {
    postalcode: '54497',
    city: 'Horath',
    countrycode: 'DE',
  },
  {
    postalcode: '54497',
    city: 'Morbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54498',
    city: 'Piesport',
    countrycode: 'DE',
  },
  {
    postalcode: '54516',
    city: 'Flußbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54516',
    city: 'Wittlich',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Altrich',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Arenrath',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Bergweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Binsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Bruch',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Dodenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Dreis',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Esch',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Gladbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Heckenmünster',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Heidweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Hupperath',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Kaisermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Kesten',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Minderlittgen',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Minheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Niersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Osann-Monzel',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Platten',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Plein',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Rivenich',
    countrycode: 'DE',
  },
  {
    postalcode: '54518',
    city: 'Sehlem',
    countrycode: 'DE',
  },
  {
    postalcode: '54523',
    city: 'Dierscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54523',
    city: 'Hetzerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54523',
    city: 'Kalbergerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '54524',
    city: 'Klausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54526',
    city: 'Landscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54528',
    city: 'Salmtal',
    countrycode: 'DE',
  },
  {
    postalcode: '54529',
    city: 'Spangdahlem',
    countrycode: 'DE',
  },
  {
    postalcode: '54531',
    city: 'Buchholz, Gem Eckfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54531',
    city: 'Buchholz, Gem Pantenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54531',
    city: 'Eckfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54531',
    city: 'Manderscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54531',
    city: 'Meerfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54531',
    city: 'Pantenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54531',
    city: 'Wallscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Bettenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Biermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Dierfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Eisenschmitt',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Gipperath',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Gransdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Greimerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Hasborn',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Laufeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Niederscheidweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Niederöfflingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Oberkail',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Oberscheidweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Oberöfflingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Salmberg',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Schwarzenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '54533',
    city: 'Willwerscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54534',
    city: 'Großlittgen',
    countrycode: 'DE',
  },
  {
    postalcode: '54534',
    city: 'Karl',
    countrycode: 'DE',
  },
  {
    postalcode: '54534',
    city: 'Musweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54534',
    city: 'Schladt',
    countrycode: 'DE',
  },
  {
    postalcode: '54536',
    city: 'Kröv',
    countrycode: 'DE',
  },
  {
    postalcode: '54538',
    city: 'Bausendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54538',
    city: 'Bengel',
    countrycode: 'DE',
  },
  {
    postalcode: '54538',
    city: 'Diefenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54538',
    city: 'Engelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '54538',
    city: 'Hontheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54538',
    city: 'Kinderbeuern',
    countrycode: 'DE',
  },
  {
    postalcode: '54538',
    city: 'Kinheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54538',
    city: 'Melchhof',
    countrycode: 'DE',
  },
  {
    postalcode: '54538',
    city: 'Neidhof',
    countrycode: 'DE',
  },
  {
    postalcode: '54539',
    city: 'Zeltingen, Forsthaus',
    countrycode: 'DE',
  },
  {
    postalcode: '54539',
    city: 'Ürzig',
    countrycode: 'DE',
  },
  {
    postalcode: '54550',
    city: 'Daun',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Beinhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Boxberg',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Brockscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Darscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Demerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Dockweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Dreis-Brück',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Ellscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Gefell',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Hörscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Hörschhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Immerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Katzwinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Kradenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Mehren',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Neichen',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Nerdlen',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Sarmersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Schalkenmehren',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Schönbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Steineberg',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Steiningen',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Strotzbüsch',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Udler',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Utzerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54552',
    city: 'Üdersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54558',
    city: 'Gillenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54558',
    city: 'Mückeln',
    countrycode: 'DE',
  },
  {
    postalcode: '54558',
    city: 'Saxler',
    countrycode: 'DE',
  },
  {
    postalcode: '54558',
    city: 'Sprink',
    countrycode: 'DE',
  },
  {
    postalcode: '54558',
    city: 'Strohn',
    countrycode: 'DE',
  },
  {
    postalcode: '54558',
    city: 'Winkel',
    countrycode: 'DE',
  },
  {
    postalcode: '54568',
    city: 'Gerolstein',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Berlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Betteldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Bleckhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Bleckhausener Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Densborn',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Deudesfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Hinterweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Hohenfels-Essingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Kalenborn-Scheuern',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Kirchweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Meisburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Mürlenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Neroth',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Niederstadtfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Oberstadtfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Pelm',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Rockeskyll',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Salm',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Sauerseifen',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Schutz',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Wallenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '54570',
    city: 'Weidenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54574',
    city: 'Birresborn',
    countrycode: 'DE',
  },
  {
    postalcode: '54574',
    city: 'Kopp',
    countrycode: 'DE',
  },
  {
    postalcode: '54574',
    city: 'Rom',
    countrycode: 'DE',
  },
  {
    postalcode: '54576',
    city: 'Dohm-Lammersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54576',
    city: 'Hillesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54578',
    city: 'Basberg',
    countrycode: 'DE',
  },
  {
    postalcode: '54578',
    city: 'Berndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54578',
    city: 'Kerpen',
    countrycode: 'DE',
  },
  {
    postalcode: '54578',
    city: 'Nohn',
    countrycode: 'DE',
  },
  {
    postalcode: '54578',
    city: 'Oberbettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54578',
    city: 'Oberehe-Stroheich',
    countrycode: 'DE',
  },
  {
    postalcode: '54578',
    city: 'Walsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54578',
    city: 'Wiesbaum',
    countrycode: 'DE',
  },
  {
    postalcode: '54579',
    city: 'Üxheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54584',
    city: 'Feusdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54584',
    city: 'Gönnersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54584',
    city: 'Jünkerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54585',
    city: 'Esch',
    countrycode: 'DE',
  },
  {
    postalcode: '54586',
    city: 'Schüller',
    countrycode: 'DE',
  },
  {
    postalcode: '54587',
    city: 'Birgel',
    countrycode: 'DE',
  },
  {
    postalcode: '54587',
    city: 'Lissendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54589',
    city: 'Kerschenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54589',
    city: 'Stadtkyll',
    countrycode: 'DE',
  },
  {
    postalcode: '54595',
    city: 'Gondenbrett',
    countrycode: 'DE',
  },
  {
    postalcode: '54595',
    city: 'Orlenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54595',
    city: 'Pittenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54595',
    city: 'Prüm',
    countrycode: 'DE',
  },
  {
    postalcode: '54595',
    city: 'Watzerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54595',
    city: 'Weinsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Auw',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Balesfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Burbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Denterhof',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Duppach',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Euscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Feuerscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Fleringen',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Gesotz, Gem Feuerscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Gesotz, Gem Hargarten',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Gesotz, Gem Plütscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Habscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Hersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Hofswald',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Kinzenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Kleinlangenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Lascheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Lierfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Lünebach',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Masthorn',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Matzerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Merlscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Neuheilenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Olzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Ormont',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Plütscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Pronsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Reuth',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Rommersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Roth',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Schwarzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Schwirzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Seiwerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Steffeln',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Strickscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Wallersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Weißenseifen, Gem Mürlenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54597',
    city: 'Weißenseifen, Gem Wallersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54608',
    city: 'Bleialf',
    countrycode: 'DE',
  },
  {
    postalcode: '54608',
    city: 'Brandscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54608',
    city: 'Buchet',
    countrycode: 'DE',
  },
  {
    postalcode: '54608',
    city: 'Großlangenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54608',
    city: 'Mützenich',
    countrycode: 'DE',
  },
  {
    postalcode: '54608',
    city: 'Oberlascheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54608',
    city: 'Schweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54608',
    city: 'Sellerich',
    countrycode: 'DE',
  },
  {
    postalcode: '54608',
    city: 'Winterscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54610',
    city: 'Büdesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54611',
    city: 'Hallschlag',
    countrycode: 'DE',
  },
  {
    postalcode: '54611',
    city: 'Scheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54612',
    city: 'Lasel',
    countrycode: 'DE',
  },
  {
    postalcode: '54612',
    city: 'Nimshuscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54612',
    city: 'Wawern',
    countrycode: 'DE',
  },
  {
    postalcode: '54614',
    city: 'Dingdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54614',
    city: 'Giesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54614',
    city: 'Heisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54614',
    city: 'Niederlauch',
    countrycode: 'DE',
  },
  {
    postalcode: '54614',
    city: 'Nimsreuland',
    countrycode: 'DE',
  },
  {
    postalcode: '54614',
    city: 'Oberlauch',
    countrycode: 'DE',
  },
  {
    postalcode: '54614',
    city: 'Schönecken',
    countrycode: 'DE',
  },
  {
    postalcode: '54614',
    city: 'Winringen',
    countrycode: 'DE',
  },
  {
    postalcode: '54616',
    city: 'Winterspelt',
    countrycode: 'DE',
  },
  {
    postalcode: '54617',
    city: 'Harspelt',
    countrycode: 'DE',
  },
  {
    postalcode: '54617',
    city: 'Lützkampen',
    countrycode: 'DE',
  },
  {
    postalcode: '54617',
    city: 'Sevenig',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Banzenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Dackscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Dromigt',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Eschfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Großkampenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Heckhuscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Herzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Kesfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Leidenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Lichtenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Reiff',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Roscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Sengerich',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Spielmannsholz',
    countrycode: 'DE',
  },
  {
    postalcode: '54619',
    city: 'Üttfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54634',
    city: 'Birtlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54634',
    city: 'Bitburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54634',
    city: 'Metterich',
    countrycode: 'DE',
  },
  {
    postalcode: '54634',
    city: 'Niederstedem',
    countrycode: 'DE',
  },
  {
    postalcode: '54634',
    city: 'Oberstedem',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Altenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Altscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Baustert',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Bickendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Biersdorf am See',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Brecht',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Dahlem',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Dockendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Echtershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Ehlenz',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Eßlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Feilsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Fließem',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Hamm',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Heilenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Hütterscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Hüttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Idenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Idesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Ingendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Ließem',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Meckel',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Meilbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Merkeshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Messerich',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Mülbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Nattenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Neumühle',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Niederweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Oberweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Oberweis',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Rittermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Rittersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Röhl',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Scharfbillig',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Schleid',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Seffern',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Sefferweich',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Sülm',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Trimport',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Weidingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Wiersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Wißmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54636',
    city: 'Wolsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54646',
    city: 'Bettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54646',
    city: 'Brimingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54646',
    city: 'Burg',
    countrycode: 'DE',
  },
  {
    postalcode: '54646',
    city: 'Enzen',
    countrycode: 'DE',
  },
  {
    postalcode: '54646',
    city: 'Halsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54646',
    city: 'Hisel',
    countrycode: 'DE',
  },
  {
    postalcode: '54646',
    city: 'Niehl',
    countrycode: 'DE',
  },
  {
    postalcode: '54646',
    city: 'Olsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54646',
    city: 'Stockem',
    countrycode: 'DE',
  },
  {
    postalcode: '54646',
    city: 'Wettlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54647',
    city: 'Dudeldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54647',
    city: 'Gondorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54647',
    city: 'Pickließem',
    countrycode: 'DE',
  },
  {
    postalcode: '54649',
    city: 'Dackscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54649',
    city: 'Eilscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54649',
    city: 'Hargarten',
    countrycode: 'DE',
  },
  {
    postalcode: '54649',
    city: 'Lambertsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '54649',
    city: 'Lauperath',
    countrycode: 'DE',
  },
  {
    postalcode: '54649',
    city: 'Luppertsseifen, Gem Burscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54649',
    city: 'Manderscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54649',
    city: 'Mauel',
    countrycode: 'DE',
  },
  {
    postalcode: '54649',
    city: 'Niederpierscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54649',
    city: 'Oberpierscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54649',
    city: 'Pintesfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54649',
    city: 'Waxweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Altenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Etteldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Friedbüsch',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Kyllburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Kyllburgweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Malberg',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Malbergweich',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Orsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Sankt Thomas',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Seinsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Steinborn',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Usch',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Wilsecker',
    countrycode: 'DE',
  },
  {
    postalcode: '54655',
    city: 'Zendscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54657',
    city: 'Badem',
    countrycode: 'DE',
  },
  {
    postalcode: '54657',
    city: 'Gindorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54657',
    city: 'Neidenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54662',
    city: 'Beilingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54662',
    city: 'Herforst',
    countrycode: 'DE',
  },
  {
    postalcode: '54662',
    city: 'Pfalzkyll',
    countrycode: 'DE',
  },
  {
    postalcode: '54662',
    city: 'Philippsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '54662',
    city: 'Speicher',
    countrycode: 'DE',
  },
  {
    postalcode: '54664',
    city: 'Auw',
    countrycode: 'DE',
  },
  {
    postalcode: '54664',
    city: 'Hosten',
    countrycode: 'DE',
  },
  {
    postalcode: '54664',
    city: 'Preist',
    countrycode: 'DE',
  },
  {
    postalcode: '54666',
    city: 'Irrel',
    countrycode: 'DE',
  },
  {
    postalcode: '54668',
    city: 'Alsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54668',
    city: 'Diesburgerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '54668',
    city: 'Echternacherbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '54668',
    city: 'Ernzen',
    countrycode: 'DE',
  },
  {
    postalcode: '54668',
    city: 'Ferschweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54668',
    city: 'Holsthum',
    countrycode: 'DE',
  },
  {
    postalcode: '54668',
    city: 'Kaschenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54668',
    city: 'Neu-Diesburgerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '54668',
    city: 'Niederweis',
    countrycode: 'DE',
  },
  {
    postalcode: '54668',
    city: 'Peffingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54668',
    city: 'Prümzurlay',
    countrycode: 'DE',
  },
  {
    postalcode: '54668',
    city: 'Schankweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '54669',
    city: 'Bollendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Ammeldingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Bauler',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Berkoth',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Berscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Burscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Dauwelshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Emmelbaum',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Falkenauel',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Gaymühle, Gem Bauler',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Gaymühle, Gem Berscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Gemünd',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Heilbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Herbstmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Hütten',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Karlshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Keppeshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Koxhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Krautscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Leimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Machtemesmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Muxerath',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Nasingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Neuerburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Plascheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Preischeiderley',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Rodershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Sauerwies',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Scheitenkorb',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Scheuern',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Schwabert',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Sevenig',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Uppershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Waldhof-Falkenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '54673',
    city: 'Zweifelscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Ammeldingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Biesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Fischbach-Oberraden',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Gaymühle, Gem Biesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Gaymühle, Gem Wallendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Geichlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Gentingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Hommerdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Hüttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Kruchten',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Körperich',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Lahr',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Mettendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Niedergeckler',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Niederraden',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Nusbaum',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Obergeckler',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Roth',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Sinspelt',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Utscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54675',
    city: 'Wallendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '54687',
    city: 'Arzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '54689',
    city: 'Affler',
    countrycode: 'DE',
  },
  {
    postalcode: '54689',
    city: 'Dahnen',
    countrycode: 'DE',
  },
  {
    postalcode: '54689',
    city: 'Daleiden',
    countrycode: 'DE',
  },
  {
    postalcode: '54689',
    city: 'Dasburg',
    countrycode: 'DE',
  },
  {
    postalcode: '54689',
    city: 'Irrhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54689',
    city: 'Jucken',
    countrycode: 'DE',
  },
  {
    postalcode: '54689',
    city: 'Kickeshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '54689',
    city: 'Olmscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '54689',
    city: 'Preischeid',
    countrycode: 'DE',
  },
  {
    postalcode: '54689',
    city: 'Reipeldingen',
    countrycode: 'DE',
  },
  {
    postalcode: '54689',
    city: 'Steinrausch',
    countrycode: 'DE',
  },
  {
    postalcode: '54689',
    city: 'Übereisenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55116',
    city: 'Mainz',
    countrycode: 'DE',
  },
  {
    postalcode: '55118',
    city: 'Mainz',
    countrycode: 'DE',
  },
  {
    postalcode: '55120',
    city: 'Mainz',
    countrycode: 'DE',
  },
  {
    postalcode: '55122',
    city: 'Mainz',
    countrycode: 'DE',
  },
  {
    postalcode: '55124',
    city: 'Mainz',
    countrycode: 'DE',
  },
  {
    postalcode: '55126',
    city: 'Mainz',
    countrycode: 'DE',
  },
  {
    postalcode: '55127',
    city: 'Mainz',
    countrycode: 'DE',
  },
  {
    postalcode: '55128',
    city: 'Mainz',
    countrycode: 'DE',
  },
  {
    postalcode: '55129',
    city: 'Mainz',
    countrycode: 'DE',
  },
  {
    postalcode: '55130',
    city: 'Mainz',
    countrycode: 'DE',
  },
  {
    postalcode: '55131',
    city: 'Mainz',
    countrycode: 'DE',
  },
  {
    postalcode: '55218',
    city: 'Ingelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55232',
    city: 'Alzey',
    countrycode: 'DE',
  },
  {
    postalcode: '55232',
    city: 'Ensheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Albig',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Bechenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Bechtolsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Bermersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Biebelnheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Dintesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Eppelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Erbes-Büdesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Esselborn',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Flomborn',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Framersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Freimersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Gau-Heppenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Hangen-Weisheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Hochborn',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Kettenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Monzernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Nack',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Nieder-Wiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Ober-Flörsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Offenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Wahlheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55234',
    city: 'Wendelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55237',
    city: 'Bornheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55237',
    city: 'Flonheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55237',
    city: 'Lonsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55239',
    city: 'Gau-Odernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55246',
    city: 'Mainz-Kostheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55252',
    city: 'Mainz-Kastel',
    countrycode: 'DE',
  },
  {
    postalcode: '55257',
    city: 'Budenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55262',
    city: 'Heidesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55263',
    city: 'Wackernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55268',
    city: 'Nieder-Olm',
    countrycode: 'DE',
  },
  {
    postalcode: '55270',
    city: 'Bubenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55270',
    city: 'Engelstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '55270',
    city: 'Essenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55270',
    city: 'Jugenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55270',
    city: 'Klein-Winternheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55270',
    city: 'Ober-Olm',
    countrycode: 'DE',
  },
  {
    postalcode: '55270',
    city: 'Schwabenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55270',
    city: 'Sörgenloch',
    countrycode: 'DE',
  },
  {
    postalcode: '55270',
    city: 'Zornheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55271',
    city: 'Stadecken-Elsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55276',
    city: 'Dienheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55276',
    city: 'Oppenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Dalheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Dexheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Dolgesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Eimsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Friesenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Hahnheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Köngernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Ludwigshöhe',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Mommenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Selzen',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Uelversheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Undenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55278',
    city: 'Weinolsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55283',
    city: 'Nierstein',
    countrycode: 'DE',
  },
  {
    postalcode: '55286',
    city: 'Sulzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55286',
    city: 'Wörrstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '55288',
    city: 'Armsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55288',
    city: 'Gabsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55288',
    city: 'Partenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55288',
    city: 'Schornsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55288',
    city: 'Spiesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55288',
    city: 'Udenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55291',
    city: 'Saulheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55294',
    city: 'Bodenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55296',
    city: 'Gau-Bischofsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55296',
    city: 'Harxheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55296',
    city: 'Lörzweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55299',
    city: 'Nackenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55411',
    city: 'Bingen',
    countrycode: 'DE',
  },
  {
    postalcode: '55413',
    city: 'Manubach',
    countrycode: 'DE',
  },
  {
    postalcode: '55413',
    city: 'Niederheimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55413',
    city: 'Oberdiebach',
    countrycode: 'DE',
  },
  {
    postalcode: '55413',
    city: 'Oberheimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55413',
    city: 'Trechtingshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55413',
    city: 'Weiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55422',
    city: 'Bacharach',
    countrycode: 'DE',
  },
  {
    postalcode: '55422',
    city: 'Breitscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '55424',
    city: 'Münster-Sarmsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55425',
    city: 'Waldalgesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55430',
    city: 'Oberwesel',
    countrycode: 'DE',
  },
  {
    postalcode: '55430',
    city: 'Perscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '55430',
    city: 'Urbar',
    countrycode: 'DE',
  },
  {
    postalcode: '55432',
    city: 'Damscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '55432',
    city: 'Niederburg',
    countrycode: 'DE',
  },
  {
    postalcode: '55435',
    city: 'Gau-Algesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55437',
    city: 'Appenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55437',
    city: 'Nieder-Hilbersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55437',
    city: 'Ober-Hilbersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55437',
    city: 'Ockenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55442',
    city: 'Daxweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55442',
    city: 'Roth',
    countrycode: 'DE',
  },
  {
    postalcode: '55442',
    city: 'Stromberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55442',
    city: 'Warmsroth',
    countrycode: 'DE',
  },
  {
    postalcode: '55444',
    city: 'Dörrebach',
    countrycode: 'DE',
  },
  {
    postalcode: '55444',
    city: 'Eckenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '55444',
    city: 'Schweppenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55444',
    city: 'Schöneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55444',
    city: 'Seibersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55444',
    city: 'Waldlaubersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55450',
    city: 'Langenlonsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55452',
    city: 'Dorsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55452',
    city: 'Guldental',
    countrycode: 'DE',
  },
  {
    postalcode: '55452',
    city: 'Hergenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '55452',
    city: 'Laubenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55452',
    city: 'Rümmelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55452',
    city: 'Windesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55457',
    city: 'Gensingen',
    countrycode: 'DE',
  },
  {
    postalcode: '55457',
    city: 'Horrweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55459',
    city: 'Aspisheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55459',
    city: 'Grolsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Altweidelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Belgweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Bergenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Birkenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Budenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Heidehof',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Holzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Horn',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Klosterkumbd',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Mutterschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Nannhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Niederkumbd',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Ohlweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Oppertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Pleizenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Rayerschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Riegenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Schönborn',
    countrycode: 'DE',
  },
  {
    postalcode: '55469',
    city: 'Simmern',
    countrycode: 'DE',
  },
  {
    postalcode: '55471',
    city: 'Biebern',
    countrycode: 'DE',
  },
  {
    postalcode: '55471',
    city: 'Fronhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '55471',
    city: 'Keidelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55471',
    city: 'Külz',
    countrycode: 'DE',
  },
  {
    postalcode: '55471',
    city: 'Kümbdchen',
    countrycode: 'DE',
  },
  {
    postalcode: '55471',
    city: 'Neuerkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '55471',
    city: 'Ravengiersburg',
    countrycode: 'DE',
  },
  {
    postalcode: '55471',
    city: 'Reich',
    countrycode: 'DE',
  },
  {
    postalcode: '55471',
    city: 'Sargenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '55471',
    city: 'Tiefenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55471',
    city: 'Wüschheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Dillendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Hecken',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Kirchberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Kludenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Lindenschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Maitzborn',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Metzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Nieder Kostenz',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Ober Kostenz',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Reckershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Rödern',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Schwarzen',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Todenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '55481',
    city: 'Womrath',
    countrycode: 'DE',
  },
  {
    postalcode: '55483',
    city: 'Bärenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55483',
    city: 'Dickenschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55483',
    city: 'Hahn-Flughafen',
    countrycode: 'DE',
  },
  {
    postalcode: '55483',
    city: 'Heinzenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55483',
    city: 'Hirschfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '55483',
    city: 'Horbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '55483',
    city: 'Kappel',
    countrycode: 'DE',
  },
  {
    postalcode: '55483',
    city: 'Krummenau',
    countrycode: 'DE',
  },
  {
    postalcode: '55483',
    city: 'Lautzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55483',
    city: 'Schlierschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55483',
    city: 'Unzenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55487',
    city: 'Dill',
    countrycode: 'DE',
  },
  {
    postalcode: '55487',
    city: 'Laufersweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55487',
    city: 'Niedersohren',
    countrycode: 'DE',
  },
  {
    postalcode: '55487',
    city: 'Sohren',
    countrycode: 'DE',
  },
  {
    postalcode: '55487',
    city: 'Sohrschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55490',
    city: 'Gehlweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55490',
    city: 'Gemünden',
    countrycode: 'DE',
  },
  {
    postalcode: '55490',
    city: 'Henau',
    countrycode: 'DE',
  },
  {
    postalcode: '55490',
    city: 'Mengerschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55490',
    city: 'Rohrbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55490',
    city: 'Wildburg',
    countrycode: 'DE',
  },
  {
    postalcode: '55490',
    city: 'Woppenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '55491',
    city: 'Büchenbeuren',
    countrycode: 'DE',
  },
  {
    postalcode: '55491',
    city: 'Niederweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55491',
    city: 'Wahlenau',
    countrycode: 'DE',
  },
  {
    postalcode: '55494',
    city: 'Benzweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55494',
    city: 'Dichtelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55494',
    city: 'Erbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55494',
    city: 'Liebshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55494',
    city: 'Mörschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55494',
    city: 'Rheinböllen',
    countrycode: 'DE',
  },
  {
    postalcode: '55494',
    city: 'Wahlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55496',
    city: 'Argenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '55497',
    city: 'Ellern',
    countrycode: 'DE',
  },
  {
    postalcode: '55497',
    city: 'Schnorbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55499',
    city: 'Riesweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55543',
    city: 'Bad Kreuznach',
    countrycode: 'DE',
  },
  {
    postalcode: '55543',
    city: 'Rosenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '55545',
    city: 'Bad Kreuznach',
    countrycode: 'DE',
  },
  {
    postalcode: '55546',
    city: 'Biebelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55546',
    city: 'Frei-Laubersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55546',
    city: 'Fürfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '55546',
    city: 'Hackenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55546',
    city: 'Neu-Bamberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55546',
    city: 'Pfaffen-Schwabenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55546',
    city: 'Tiefenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '55546',
    city: 'Volxheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55559',
    city: 'Bretzenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55566',
    city: 'Bad Sobernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55566',
    city: 'Daubach',
    countrycode: 'DE',
  },
  {
    postalcode: '55566',
    city: 'Ippenschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55566',
    city: 'Kirschroth',
    countrycode: 'DE',
  },
  {
    postalcode: '55566',
    city: 'Meddersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55566',
    city: 'Rehbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55568',
    city: 'Abtweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55568',
    city: 'Lauschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55568',
    city: 'Staudernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55569',
    city: 'Auen',
    countrycode: 'DE',
  },
  {
    postalcode: '55569',
    city: 'Langenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '55569',
    city: 'Monzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '55569',
    city: 'Nußbaum',
    countrycode: 'DE',
  },
  {
    postalcode: '55571',
    city: 'Odernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55576',
    city: 'Badenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55576',
    city: 'Pleitersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55576',
    city: 'Sprendlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '55576',
    city: 'Welgesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55576',
    city: 'Zotzenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55578',
    city: 'Gau-Weinheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55578',
    city: 'St Johann',
    countrycode: 'DE',
  },
  {
    postalcode: '55578',
    city: 'Vendersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55578',
    city: 'Wallertheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55578',
    city: 'Wolfsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55583',
    city: 'Bad Münster-Ebernburg',
    countrycode: 'DE',
  },
  {
    postalcode: '55585',
    city: 'Altenbamberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55585',
    city: 'Duchroth',
    countrycode: 'DE',
  },
  {
    postalcode: '55585',
    city: 'Hochstätten',
    countrycode: 'DE',
  },
  {
    postalcode: '55585',
    city: 'Niederhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55585',
    city: 'Norheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55585',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55590',
    city: 'Meisenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55592',
    city: 'Breitenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55592',
    city: 'Desloch',
    countrycode: 'DE',
  },
  {
    postalcode: '55592',
    city: 'Jeckenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55592',
    city: 'Raumbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55592',
    city: 'Rehborn',
    countrycode: 'DE',
  },
  {
    postalcode: '55593',
    city: 'Rüdesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Allenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Argenschwang',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Bockenau',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Boos',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Braunweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Burgsponheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Dalberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Gebroth',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Gutenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Hargesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Hüffelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Mandel',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Münchwald',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Roxheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Sankt Katharinen',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Sommerloch',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Spabrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Spall',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Sponheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Traisen',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Wallhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Weinsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Winterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55595',
    city: 'Winterburg',
    countrycode: 'DE',
  },
  {
    postalcode: '55596',
    city: 'Oberstreit',
    countrycode: 'DE',
  },
  {
    postalcode: '55596',
    city: 'Schloßböckelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55596',
    city: 'Waldböckelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55597',
    city: 'Gumbsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55597',
    city: 'Wöllstein',
    countrycode: 'DE',
  },
  {
    postalcode: '55599',
    city: 'Eckelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55599',
    city: 'Gau-Bickelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55599',
    city: 'Siefersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55599',
    city: 'Stein-Bockenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55599',
    city: 'Wonsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Brauweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Bruschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Bärweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Hahnenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Heimweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Heinzenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Heinzenberger-Gesellschaftsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Hochstetten-Dhaun',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Horbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Hottenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Kellenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Kirn',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Königsau',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Limbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Lochmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Lohmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Lützelsoon',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Meckenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Otzweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Untere Horbachsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Wartenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '55606',
    city: 'Welschrötherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '55608',
    city: 'Becherbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55608',
    city: 'Bergen',
    countrycode: 'DE',
  },
  {
    postalcode: '55608',
    city: 'Berschweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55608',
    city: 'Griebelschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55608',
    city: 'Hausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55608',
    city: 'Schneppenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55618',
    city: 'Simmertal',
    countrycode: 'DE',
  },
  {
    postalcode: '55619',
    city: 'Hennweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55621',
    city: 'Hundsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55624',
    city: 'Bollenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55624',
    city: 'Gösenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '55624',
    city: 'Oberkirn',
    countrycode: 'DE',
  },
  {
    postalcode: '55624',
    city: 'Rhaunen',
    countrycode: 'DE',
  },
  {
    postalcode: '55624',
    city: 'Schwerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55624',
    city: 'Weitersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55626',
    city: 'Bundenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55627',
    city: 'Martinstein',
    countrycode: 'DE',
  },
  {
    postalcode: '55627',
    city: 'Merxheim',
    countrycode: 'DE',
  },
  {
    postalcode: '55627',
    city: 'Weiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55629',
    city: 'Schwarzerden',
    countrycode: 'DE',
  },
  {
    postalcode: '55629',
    city: 'Seesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55629',
    city: 'Weitersborn',
    countrycode: 'DE',
  },
  {
    postalcode: '55743',
    city: 'Fischbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55743',
    city: 'Gerach',
    countrycode: 'DE',
  },
  {
    postalcode: '55743',
    city: 'Gerichtsmannsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '55743',
    city: 'Heidehof',
    countrycode: 'DE',
  },
  {
    postalcode: '55743',
    city: 'Hintertiefenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55743',
    city: 'Hüttgeswasen',
    countrycode: 'DE',
  },
  {
    postalcode: '55743',
    city: 'Idar-Oberstein',
    countrycode: 'DE',
  },
  {
    postalcode: '55743',
    city: 'Kirschweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55756',
    city: 'Herrstein',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Allenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Asbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Asbacherhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Breitenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Bruchweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Bärenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Dickesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Hammerbirkenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Harfenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Hellertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Herborn',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Hettenrodt',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Hottenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Kempfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Langweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Mackenrodt',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Mittelreidenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Mörschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Niederhosenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Niederwörresbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Oberhosenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Oberreidenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Oberwörresbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Schauren',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Schmidthachenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Sensweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Sien',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Sienhachenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Sonnschied',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Stipshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Sulzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Veitsrodt',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Vollmersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Weiden',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Wickenrodt',
    countrycode: 'DE',
  },
  {
    postalcode: '55758',
    city: 'Wirschweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55765',
    city: 'Birkenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '55765',
    city: 'Dambach',
    countrycode: 'DE',
  },
  {
    postalcode: '55765',
    city: 'Dienstweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55765',
    city: 'Eborn',
    countrycode: 'DE',
  },
  {
    postalcode: '55765',
    city: 'Elchweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55765',
    city: 'Ellenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55765',
    city: 'Ellweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55765',
    city: 'Oberhambach',
    countrycode: 'DE',
  },
  {
    postalcode: '55765',
    city: 'Rimsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55765',
    city: 'Sauerbrunnen',
    countrycode: 'DE',
  },
  {
    postalcode: '55765',
    city: 'Schmißberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55765',
    city: 'Waldfriede',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Abentheuer',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Achtelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Brücken',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Buhlenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Gimbweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Gollenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Hattgenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Kronweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Leisel',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Meckenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Niederbrombach',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Niederhambach',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Nohen',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Oberbrombach',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Rinzenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Rötsweiler-Nockenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Schwollen',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Siesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Sonnenberg-Winnenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55767',
    city: 'Wilzenberg-Hußweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55768',
    city: 'Hoppstädten-Weiersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55774',
    city: 'Baumholder',
    countrycode: 'DE',
  },
  {
    postalcode: '55776',
    city: 'Berglangenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55776',
    city: 'Frauenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55776',
    city: 'Hahnweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55776',
    city: 'Reichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55776',
    city: 'Rohrbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55776',
    city: 'Ruschberg',
    countrycode: 'DE',
  },
  {
    postalcode: '55776',
    city: 'Rückweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55776',
    city: 'Zinkweilerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '55777',
    city: 'Berschweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55777',
    city: 'Eckersweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55777',
    city: 'Fohren-Linden',
    countrycode: 'DE',
  },
  {
    postalcode: '55777',
    city: 'Mettweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '55779',
    city: 'Heimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '55779',
    city: 'Leitzweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '56068',
    city: 'Koblenz',
    countrycode: 'DE',
  },
  {
    postalcode: '56070',
    city: 'Koblenz',
    countrycode: 'DE',
  },
  {
    postalcode: '56072',
    city: 'Koblenz',
    countrycode: 'DE',
  },
  {
    postalcode: '56073',
    city: 'Koblenz',
    countrycode: 'DE',
  },
  {
    postalcode: '56075',
    city: 'Koblenz',
    countrycode: 'DE',
  },
  {
    postalcode: '56076',
    city: 'Koblenz',
    countrycode: 'DE',
  },
  {
    postalcode: '56077',
    city: 'Koblenz',
    countrycode: 'DE',
  },
  {
    postalcode: '56112',
    city: 'Lahnstein',
    countrycode: 'DE',
  },
  {
    postalcode: '56130',
    city: 'Altes Forsthaus',
    countrycode: 'DE',
  },
  {
    postalcode: '56130',
    city: 'Bad Ems',
    countrycode: 'DE',
  },
  {
    postalcode: '56130',
    city: 'Buchenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56130',
    city: 'Grenzloch',
    countrycode: 'DE',
  },
  {
    postalcode: '56130',
    city: 'Heinrichshof',
    countrycode: 'DE',
  },
  {
    postalcode: '56130',
    city: 'Lindenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56130',
    city: 'Schleuse Dausenau',
    countrycode: 'DE',
  },
  {
    postalcode: '56130',
    city: 'Schleuse Ems',
    countrycode: 'DE',
  },
  {
    postalcode: '56132',
    city: 'Becheln',
    countrycode: 'DE',
  },
  {
    postalcode: '56132',
    city: 'Dausenau',
    countrycode: 'DE',
  },
  {
    postalcode: '56132',
    city: 'Frücht',
    countrycode: 'DE',
  },
  {
    postalcode: '56132',
    city: 'Kemmenau',
    countrycode: 'DE',
  },
  {
    postalcode: '56132',
    city: 'Miellen',
    countrycode: 'DE',
  },
  {
    postalcode: '56132',
    city: 'Nievern',
    countrycode: 'DE',
  },
  {
    postalcode: '56132',
    city: 'Schöne Aussicht',
    countrycode: 'DE',
  },
  {
    postalcode: '56132',
    city: 'Waldfrieden-Schweizertal',
    countrycode: 'DE',
  },
  {
    postalcode: '56133',
    city: 'Fachbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56154',
    city: 'Boppard',
    countrycode: 'DE',
  },
  {
    postalcode: '56170',
    city: 'Bembsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56170',
    city: 'Bendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56170',
    city: 'Lachnitzmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56179',
    city: 'Niederwerth',
    countrycode: 'DE',
  },
  {
    postalcode: '56179',
    city: 'Vallendar',
    countrycode: 'DE',
  },
  {
    postalcode: '56182',
    city: 'Urbar',
    countrycode: 'DE',
  },
  {
    postalcode: '56191',
    city: 'Weitersburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56203',
    city: 'Bembermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56203',
    city: 'Höhr-Grenzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56203',
    city: 'Waldfriede',
    countrycode: 'DE',
  },
  {
    postalcode: '56204',
    city: 'Hillscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56206',
    city: 'Hilgert',
    countrycode: 'DE',
  },
  {
    postalcode: '56206',
    city: 'Kammerforst',
    countrycode: 'DE',
  },
  {
    postalcode: '56218',
    city: 'Mülheim-Kärlich',
    countrycode: 'DE',
  },
  {
    postalcode: '56220',
    city: 'Bassenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56220',
    city: 'Kaltenengers',
    countrycode: 'DE',
  },
  {
    postalcode: '56220',
    city: 'Kettig',
    countrycode: 'DE',
  },
  {
    postalcode: '56220',
    city: 'Sankt Sebastian',
    countrycode: 'DE',
  },
  {
    postalcode: '56220',
    city: 'Urmitz',
    countrycode: 'DE',
  },
  {
    postalcode: '56235',
    city: 'Faulbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56235',
    city: 'Hundsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56235',
    city: 'Ransbach-Baumbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56237',
    city: 'Alsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56237',
    city: 'Breitenau',
    countrycode: 'DE',
  },
  {
    postalcode: '56237',
    city: 'Caan',
    countrycode: 'DE',
  },
  {
    postalcode: '56237',
    city: 'Deesen',
    countrycode: 'DE',
  },
  {
    postalcode: '56237',
    city: 'Nauort',
    countrycode: 'DE',
  },
  {
    postalcode: '56237',
    city: 'Oberhaid',
    countrycode: 'DE',
  },
  {
    postalcode: '56237',
    city: 'Sessenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56237',
    city: 'Wirscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56237',
    city: 'Wittgert',
    countrycode: 'DE',
  },
  {
    postalcode: '56242',
    city: 'Ellenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56242',
    city: 'Hammermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56242',
    city: 'Marienrachdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56242',
    city: 'Nordhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '56242',
    city: 'Quirnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56242',
    city: 'Selters',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Arnshöfen',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Ettinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Ewighausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Freilingen',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Freirachdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Goddert',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Hahn',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Hartenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Helferskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Krümmel',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Kuhnhöfen',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Leuterod',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Maxsain',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Niedersayn',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Rückeroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Schenkelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Sessenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Steinen',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Vielbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Weidenhahn',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Wölferlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '56244',
    city: 'Ötzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '56249',
    city: 'Herschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56253',
    city: 'Treis-Karden',
    countrycode: 'DE',
  },
  {
    postalcode: '56254',
    city: 'Moselkern',
    countrycode: 'DE',
  },
  {
    postalcode: '56254',
    city: 'Müden',
    countrycode: 'DE',
  },
  {
    postalcode: '56269',
    city: 'Dierdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56269',
    city: 'Marienhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56271',
    city: 'Isenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56271',
    city: 'Kettemers Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56271',
    city: 'Kleinmaischeid',
    countrycode: 'DE',
  },
  {
    postalcode: '56271',
    city: 'Maroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56271',
    city: 'Mündersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56271',
    city: 'Roßbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56271',
    city: 'Wiedischhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56276',
    city: 'Großmaischeid',
    countrycode: 'DE',
  },
  {
    postalcode: '56276',
    city: 'Stebach',
    countrycode: 'DE',
  },
  {
    postalcode: '56281',
    city: 'Dörth',
    countrycode: 'DE',
  },
  {
    postalcode: '56281',
    city: 'Emmelshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56281',
    city: 'Hungenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56281',
    city: 'Karbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56281',
    city: 'Schwall',
    countrycode: 'DE',
  },
  {
    postalcode: '56283',
    city: 'Beulich',
    countrycode: 'DE',
  },
  {
    postalcode: '56283',
    city: 'Dieler',
    countrycode: 'DE',
  },
  {
    postalcode: '56283',
    city: 'Gondershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56283',
    city: 'Halsenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56283',
    city: 'Kratzenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56283',
    city: 'Mermuth',
    countrycode: 'DE',
  },
  {
    postalcode: '56283',
    city: 'Morshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56283',
    city: 'Ney',
    countrycode: 'DE',
  },
  {
    postalcode: '56283',
    city: 'Nörtershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56283',
    city: 'Wildenbungert',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Alterkülz',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Bell',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Braunshorn',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Bubach',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Dudenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Hasselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Hollnich',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Hundheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Kastellaun',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Korweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Krastel',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Lahr',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Laubach',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Michelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Roth',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Rothenbergerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Spesenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Steffenshof',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Völkenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Wohnroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56288',
    city: 'Zilshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56290',
    city: 'Beltheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56290',
    city: 'Buch',
    countrycode: 'DE',
  },
  {
    postalcode: '56290',
    city: 'Dommershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56290',
    city: 'Gödenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56290',
    city: 'Lieg',
    countrycode: 'DE',
  },
  {
    postalcode: '56290',
    city: 'Lütz',
    countrycode: 'DE',
  },
  {
    postalcode: '56290',
    city: 'Macken',
    countrycode: 'DE',
  },
  {
    postalcode: '56290',
    city: 'Mörsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56290',
    city: 'Uhler',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Badenhard',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Bickenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Birkheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Hausbay',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Kehlenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Kisselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Laudert',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Leiningen',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Lingerhahn',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Maisborn',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Mühlpfad',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Niedert',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Norath',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Ostersmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Pfalzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Steinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Thörlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Utzenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '56291',
    city: 'Wiebelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56294',
    city: 'Gappenach',
    countrycode: 'DE',
  },
  {
    postalcode: '56294',
    city: 'Gierschnach',
    countrycode: 'DE',
  },
  {
    postalcode: '56294',
    city: 'Kalt',
    countrycode: 'DE',
  },
  {
    postalcode: '56294',
    city: 'Münstermaifeld',
    countrycode: 'DE',
  },
  {
    postalcode: '56294',
    city: 'Wierschem',
    countrycode: 'DE',
  },
  {
    postalcode: '56295',
    city: 'Kerben',
    countrycode: 'DE',
  },
  {
    postalcode: '56295',
    city: 'Lonnig',
    countrycode: 'DE',
  },
  {
    postalcode: '56295',
    city: 'Rüber',
    countrycode: 'DE',
  },
  {
    postalcode: '56299',
    city: 'Achterspannerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56299',
    city: 'Ochtendung',
    countrycode: 'DE',
  },
  {
    postalcode: '56299',
    city: 'Sackenheimerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56299',
    city: 'Sürzerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56305',
    city: 'Döttesfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '56305',
    city: 'Niederähren',
    countrycode: 'DE',
  },
  {
    postalcode: '56305',
    city: 'Puderbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56307',
    city: 'Dernbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56307',
    city: 'Dürrholz',
    countrycode: 'DE',
  },
  {
    postalcode: '56307',
    city: 'Harschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56316',
    city: 'Hanroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56316',
    city: 'Niederhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '56316',
    city: 'Raubach',
    countrycode: 'DE',
  },
  {
    postalcode: '56317',
    city: 'Linkenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56317',
    city: 'Urbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56321',
    city: 'Brey',
    countrycode: 'DE',
  },
  {
    postalcode: '56321',
    city: 'Rhens',
    countrycode: 'DE',
  },
  {
    postalcode: '56322',
    city: 'Spay',
    countrycode: 'DE',
  },
  {
    postalcode: '56323',
    city: 'Hünenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '56323',
    city: 'Waldesch',
    countrycode: 'DE',
  },
  {
    postalcode: '56329',
    city: 'St. Goar',
    countrycode: 'DE',
  },
  {
    postalcode: '56330',
    city: 'Fißmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56330',
    city: 'Kobern-Gondorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Alken',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Brodenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Burgen',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Dieblich',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Franzenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Gastemühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Grüne Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Hatzenport',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Lehmen',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Löf',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Mohrenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Niederfell',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Oberfell',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Rosenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Schwalberhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56332',
    city: 'Wolken',
    countrycode: 'DE',
  },
  {
    postalcode: '56333',
    city: 'Winningen',
    countrycode: 'DE',
  },
  {
    postalcode: '56335',
    city: 'Neuhäusel',
    countrycode: 'DE',
  },
  {
    postalcode: '56337',
    city: 'Arzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56337',
    city: 'Eitelborn',
    countrycode: 'DE',
  },
  {
    postalcode: '56337',
    city: 'Kadenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56337',
    city: 'Simmern',
    countrycode: 'DE',
  },
  {
    postalcode: '56338',
    city: 'Braubach',
    countrycode: 'DE',
  },
  {
    postalcode: '56340',
    city: 'Büchelborn',
    countrycode: 'DE',
  },
  {
    postalcode: '56340',
    city: 'Dachsborn',
    countrycode: 'DE',
  },
  {
    postalcode: '56340',
    city: 'Dachsenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56340',
    city: 'Erlenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '56340',
    city: 'Osterspai',
    countrycode: 'DE',
  },
  {
    postalcode: '56341',
    city: 'Filsen',
    countrycode: 'DE',
  },
  {
    postalcode: '56341',
    city: 'Kamp-Bornhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '56346',
    city: 'Bornsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56346',
    city: 'Gute Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56346',
    city: 'Immenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56346',
    city: 'Loreley',
    countrycode: 'DE',
  },
  {
    postalcode: '56346',
    city: 'Lykershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56346',
    city: 'Prath',
    countrycode: 'DE',
  },
  {
    postalcode: '56346',
    city: 'Rödelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56346',
    city: 'Saueressigs Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56346',
    city: 'St. Goarshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56346',
    city: 'Ströbels Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56346',
    city: 'Theismühlen',
    countrycode: 'DE',
  },
  {
    postalcode: '56348',
    city: 'Bornich',
    countrycode: 'DE',
  },
  {
    postalcode: '56348',
    city: 'Dahlheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56348',
    city: 'Dörscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56348',
    city: 'Kestert',
    countrycode: 'DE',
  },
  {
    postalcode: '56348',
    city: 'Patersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56348',
    city: 'Weisel',
    countrycode: 'DE',
  },
  {
    postalcode: '56349',
    city: 'Kaub',
    countrycode: 'DE',
  },
  {
    postalcode: '56349',
    city: 'Viktoriastollen',
    countrycode: 'DE',
  },
  {
    postalcode: '56355',
    city: 'Aftholderbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56355',
    city: 'Bettendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56355',
    city: 'Diethardt',
    countrycode: 'DE',
  },
  {
    postalcode: '56355',
    city: 'Endlichhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '56355',
    city: 'Hunzel',
    countrycode: 'DE',
  },
  {
    postalcode: '56355',
    city: 'Kehlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56355',
    city: 'Lautert',
    countrycode: 'DE',
  },
  {
    postalcode: '56355',
    city: 'Nastätten',
    countrycode: 'DE',
  },
  {
    postalcode: '56355',
    city: 'Oberbachheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56355',
    city: 'Schneidmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56355',
    city: 'Weidenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56355',
    city: 'Winterwerb',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Auel',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Bogel',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Buch',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Dessighofen',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Dornholzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Ehr',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Eschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Geisig',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Gemmerich',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Hainau',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Hasenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Himmighofen',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Hollermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Holzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Kasdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Lierschied',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Lipporn',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Lollschied',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Marienfels',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Miehlen',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Niederbachheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Niederwallmenach',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Nochern',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Obertiefenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Oberwallmenach',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Oelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Pohl',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Reichenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Reitzenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Rettershain',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Ruppertshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Strüth',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Uhusmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Welterod',
    countrycode: 'DE',
  },
  {
    postalcode: '56357',
    city: 'Weyer',
    countrycode: 'DE',
  },
  {
    postalcode: '56368',
    city: 'Berghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56368',
    city: 'Ergeshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56368',
    city: 'Herold',
    countrycode: 'DE',
  },
  {
    postalcode: '56368',
    city: 'Katzenelnbogen',
    countrycode: 'DE',
  },
  {
    postalcode: '56368',
    city: 'Klingelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56368',
    city: 'Niedertiefenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56368',
    city: 'Roth',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Allendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Attenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Berndroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Biebrich',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Bremberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Dörsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Ebertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Eisighofen',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Gutenacker',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Kördorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Mittelfischbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Oberfischbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Reckenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Rettert',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Schönborn',
    countrycode: 'DE',
  },
  {
    postalcode: '56370',
    city: 'Wasenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56377',
    city: 'Gieshübel',
    countrycode: 'DE',
  },
  {
    postalcode: '56377',
    city: 'Herbelsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56377',
    city: 'Kalkofen, Gem Dörnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56377',
    city: 'Misselberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56377',
    city: 'Nassau',
    countrycode: 'DE',
  },
  {
    postalcode: '56377',
    city: 'Schleuse',
    countrycode: 'DE',
  },
  {
    postalcode: '56377',
    city: 'Schweighausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56377',
    city: 'Seelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56377',
    city: 'Waldschmidtmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Arnstein',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Bergerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Charlottenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Dienethal',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Dörnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Gasteyers Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Geilnau',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Holzappel',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Horhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Hömberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Hütte, Gem Dörnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Laurenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Obernhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Oberwies',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Rupbach, Gem Bremberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Rupbach, Gem Gutenacker',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Rupbach, Gem Steinsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Salscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Scheidt',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Singhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Steckenbergermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Steinsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Sulzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Weinähr',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Winden',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Zechenhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '56379',
    city: 'Zimmerschied',
    countrycode: 'DE',
  },
  {
    postalcode: '56410',
    city: 'Montabaur',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Boden',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Daubach',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Gackenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Girod',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Großholbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Görgeshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Heilberscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Heiligenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Holler',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Horbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Hübingen',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Nentershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Niederelbert',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Niedererbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Nomborn',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Oberelbert',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Ruppach-Goldhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Stahlhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Untershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56412',
    city: 'Welschneudorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Berod',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Bilkheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Dreikirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Hundsangen',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Meudt',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Molsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Niederahr',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Oberahr',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Obererbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Salz',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Steinefrenz',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Wallmerod',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Weroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56414',
    city: 'Zehnhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56422',
    city: 'Wirges',
    countrycode: 'DE',
  },
  {
    postalcode: '56424',
    city: 'Bannberscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56424',
    city: 'Ebernhahn',
    countrycode: 'DE',
  },
  {
    postalcode: '56424',
    city: 'Mogendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56424',
    city: 'Moschheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56424',
    city: 'Staudt',
    countrycode: 'DE',
  },
  {
    postalcode: '56427',
    city: 'Siershahn',
    countrycode: 'DE',
  },
  {
    postalcode: '56428',
    city: 'Dernbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56457',
    city: 'Halbs',
    countrycode: 'DE',
  },
  {
    postalcode: '56457',
    city: 'Hergenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56457',
    city: 'Jeremiasmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56457',
    city: 'Westerburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Ailertchen',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Bellingen',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Berzhahn',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Brandscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Elbingen',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Gemünden',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Girkenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Guckheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Hammermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Harschbacherfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Härtlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Kaden',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Kölbingen',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Langenhahn',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Mähren',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Pottum',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Rotenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Rothenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Stahlhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Stockum-Püschen',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Weltersburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Westert',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Willmenrod',
    countrycode: 'DE',
  },
  {
    postalcode: '56459',
    city: 'Winnen',
    countrycode: 'DE',
  },
  {
    postalcode: '56462',
    city: 'Hilpischmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56462',
    city: 'Höhn',
    countrycode: 'DE',
  },
  {
    postalcode: '56470',
    city: 'Bad Marienberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56472',
    city: 'Dammühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56472',
    city: 'Dreisbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56472',
    city: 'Fehl-Ritzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56472',
    city: 'Großseifen',
    countrycode: 'DE',
  },
  {
    postalcode: '56472',
    city: 'Hahn',
    countrycode: 'DE',
  },
  {
    postalcode: '56472',
    city: 'Hardt',
    countrycode: 'DE',
  },
  {
    postalcode: '56472',
    city: 'Hof',
    countrycode: 'DE',
  },
  {
    postalcode: '56472',
    city: 'Lautzenbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '56472',
    city: 'Nisterau',
    countrycode: 'DE',
  },
  {
    postalcode: '56472',
    city: 'Nisterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56472',
    city: 'Stockhausen-Illfurth',
    countrycode: 'DE',
  },
  {
    postalcode: '56477',
    city: 'Nister-Möhrendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56477',
    city: 'Rennerod',
    countrycode: 'DE',
  },
  {
    postalcode: '56477',
    city: 'Waigandshain',
    countrycode: 'DE',
  },
  {
    postalcode: '56477',
    city: 'Zehnhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Bretthausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Elsoff',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Hellenhahn-Schellenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Homberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Hüblingen',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Irmtraut',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Liebenscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Neunkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Niederroßbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Oberrod',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Oberroßbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Rehe',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Salzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Seck',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Stein-Neukirch',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Waldmühlen',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Westernohe',
    countrycode: 'DE',
  },
  {
    postalcode: '56479',
    city: 'Willingen',
    countrycode: 'DE',
  },
  {
    postalcode: '56564',
    city: 'Neuwied',
    countrycode: 'DE',
  },
  {
    postalcode: '56566',
    city: 'Neuwied',
    countrycode: 'DE',
  },
  {
    postalcode: '56567',
    city: 'Neuwied',
    countrycode: 'DE',
  },
  {
    postalcode: '56575',
    city: 'Weißenthurm',
    countrycode: 'DE',
  },
  {
    postalcode: '56579',
    city: 'Bonefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '56579',
    city: 'Hardert',
    countrycode: 'DE',
  },
  {
    postalcode: '56579',
    city: 'Rengsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56581',
    city: 'Ehlscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56581',
    city: 'Hegerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56581',
    city: 'Kurtscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56581',
    city: 'Melsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56584',
    city: 'Anhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56584',
    city: 'Meinborn',
    countrycode: 'DE',
  },
  {
    postalcode: '56584',
    city: 'Rüscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56584',
    city: 'Thalhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56587',
    city: 'Oberhonnefeld-Gierend',
    countrycode: 'DE',
  },
  {
    postalcode: '56587',
    city: 'Oberraden',
    countrycode: 'DE',
  },
  {
    postalcode: '56587',
    city: 'Straßenhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '56588',
    city: 'Bremscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56588',
    city: 'Sohl',
    countrycode: 'DE',
  },
  {
    postalcode: '56588',
    city: 'Stopperich',
    countrycode: 'DE',
  },
  {
    postalcode: '56588',
    city: 'Waldbreitbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56589',
    city: 'Datzeroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56589',
    city: 'Niederbreitbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56593',
    city: 'Bürdenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56593',
    city: 'Güllesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56593',
    city: 'Horhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56593',
    city: 'Krunkel',
    countrycode: 'DE',
  },
  {
    postalcode: '56593',
    city: 'Niedersteinebach',
    countrycode: 'DE',
  },
  {
    postalcode: '56593',
    city: 'Obersteinebach',
    countrycode: 'DE',
  },
  {
    postalcode: '56593',
    city: 'Pleckhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56593',
    city: 'Pleckhausermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56594',
    city: 'Willroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56598',
    city: 'Hammerstein',
    countrycode: 'DE',
  },
  {
    postalcode: '56598',
    city: 'Rheinbrohl',
    countrycode: 'DE',
  },
  {
    postalcode: '56599',
    city: 'Leutesdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56626',
    city: 'Andernach',
    countrycode: 'DE',
  },
  {
    postalcode: '56630',
    city: 'Kretz',
    countrycode: 'DE',
  },
  {
    postalcode: '56637',
    city: 'Plaidt',
    countrycode: 'DE',
  },
  {
    postalcode: '56642',
    city: 'Kruft',
    countrycode: 'DE',
  },
  {
    postalcode: '56645',
    city: 'Nickenich',
    countrycode: 'DE',
  },
  {
    postalcode: '56648',
    city: 'Saffig',
    countrycode: 'DE',
  },
  {
    postalcode: '56651',
    city: 'Brenk',
    countrycode: 'DE',
  },
  {
    postalcode: '56651',
    city: 'Galenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56651',
    city: 'Niederdürenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56651',
    city: 'Niederzissen',
    countrycode: 'DE',
  },
  {
    postalcode: '56651',
    city: 'Oberdürenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56651',
    city: 'Oberzissen',
    countrycode: 'DE',
  },
  {
    postalcode: '56653',
    city: 'Glees',
    countrycode: 'DE',
  },
  {
    postalcode: '56653',
    city: 'Maria Laach',
    countrycode: 'DE',
  },
  {
    postalcode: '56653',
    city: 'Wassenach',
    countrycode: 'DE',
  },
  {
    postalcode: '56653',
    city: 'Wehr',
    countrycode: 'DE',
  },
  {
    postalcode: '56656',
    city: 'Brohl-Lützing',
    countrycode: 'DE',
  },
  {
    postalcode: '56659',
    city: 'Burgbrohl',
    countrycode: 'DE',
  },
  {
    postalcode: '56727',
    city: 'Brachems',
    countrycode: 'DE',
  },
  {
    postalcode: '56727',
    city: 'Mayen',
    countrycode: 'DE',
  },
  {
    postalcode: '56727',
    city: 'Reudelsterz',
    countrycode: 'DE',
  },
  {
    postalcode: '56727',
    city: 'Sankt Johann',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Acht',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Anschau',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Arft',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Baar',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Bermel',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Boos',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Ditscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Ettringen',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Geisbüschhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Herresbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Heunenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Hirten',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Kehrig',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Kirchwald',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Langenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Langscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Lind',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Luxem',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Mimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Monreal',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Münk',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Nachtsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Neu Virneburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Nitz',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Siebenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Virneburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Weiler',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Welschenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56729',
    city: 'Wüsteratherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56736',
    city: 'Kottenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56743',
    city: 'Mendig',
    countrycode: 'DE',
  },
  {
    postalcode: '56743',
    city: 'Thür',
    countrycode: 'DE',
  },
  {
    postalcode: '56745',
    city: 'Bell',
    countrycode: 'DE',
  },
  {
    postalcode: '56745',
    city: 'Hausten',
    countrycode: 'DE',
  },
  {
    postalcode: '56745',
    city: 'Rieden',
    countrycode: 'DE',
  },
  {
    postalcode: '56745',
    city: 'Volkesfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '56745',
    city: 'Weibern',
    countrycode: 'DE',
  },
  {
    postalcode: '56746',
    city: 'Hohenleimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56746',
    city: 'Kempenich',
    countrycode: 'DE',
  },
  {
    postalcode: '56746',
    city: 'Langhardt',
    countrycode: 'DE',
  },
  {
    postalcode: '56746',
    city: 'Spessart',
    countrycode: 'DE',
  },
  {
    postalcode: '56751',
    city: 'Einig',
    countrycode: 'DE',
  },
  {
    postalcode: '56751',
    city: 'Gering',
    countrycode: 'DE',
  },
  {
    postalcode: '56751',
    city: 'Kollig',
    countrycode: 'DE',
  },
  {
    postalcode: '56751',
    city: 'Polch',
    countrycode: 'DE',
  },
  {
    postalcode: '56753',
    city: 'Mertloch',
    countrycode: 'DE',
  },
  {
    postalcode: '56753',
    city: 'Naunheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56753',
    city: 'Nettemühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56753',
    city: 'Pillig',
    countrycode: 'DE',
  },
  {
    postalcode: '56753',
    city: 'Schloßhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56753',
    city: 'Trimbs',
    countrycode: 'DE',
  },
  {
    postalcode: '56753',
    city: 'Welling',
    countrycode: 'DE',
  },
  {
    postalcode: '56754',
    city: 'Binningen',
    countrycode: 'DE',
  },
  {
    postalcode: '56754',
    city: 'Brohl',
    countrycode: 'DE',
  },
  {
    postalcode: '56754',
    city: 'Brückenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56754',
    city: 'Dünfus',
    countrycode: 'DE',
  },
  {
    postalcode: '56754',
    city: 'Forst',
    countrycode: 'DE',
  },
  {
    postalcode: '56754',
    city: 'Möntenich',
    countrycode: 'DE',
  },
  {
    postalcode: '56754',
    city: 'Pilligerheck',
    countrycode: 'DE',
  },
  {
    postalcode: '56754',
    city: 'Roes',
    countrycode: 'DE',
  },
  {
    postalcode: '56754',
    city: 'Rotherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56754',
    city: 'Sauersmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56759',
    city: 'Eppenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56759',
    city: 'Kaisersesch',
    countrycode: 'DE',
  },
  {
    postalcode: '56759',
    city: 'Kalenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '56759',
    city: 'Laubach',
    countrycode: 'DE',
  },
  {
    postalcode: '56759',
    city: 'Leienkaul',
    countrycode: 'DE',
  },
  {
    postalcode: '56759',
    city: 'Maria Martental',
    countrycode: 'DE',
  },
  {
    postalcode: '56759',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56759',
    city: 'Präfekturhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56759',
    city: 'Schöne Aussicht, Gem Landkern',
    countrycode: 'DE',
  },
  {
    postalcode: '56759',
    city: 'Schöne Aussicht, Gem Masburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56761',
    city: 'Brachtendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56761',
    city: 'Düngenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56761',
    city: 'Eulgem',
    countrycode: 'DE',
  },
  {
    postalcode: '56761',
    city: 'Gamlen',
    countrycode: 'DE',
  },
  {
    postalcode: '56761',
    city: 'Hambuch',
    countrycode: 'DE',
  },
  {
    postalcode: '56761',
    city: 'Hauroth',
    countrycode: 'DE',
  },
  {
    postalcode: '56761',
    city: 'Kaifenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56761',
    city: 'Masburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56761',
    city: 'Müllenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56761',
    city: 'Polcherholz',
    countrycode: 'DE',
  },
  {
    postalcode: '56761',
    city: 'Urmersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56761',
    city: 'Zettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '56766',
    city: 'Auderath',
    countrycode: 'DE',
  },
  {
    postalcode: '56766',
    city: 'Berenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56766',
    city: 'Filz',
    countrycode: 'DE',
  },
  {
    postalcode: '56766',
    city: 'Horperath',
    countrycode: 'DE',
  },
  {
    postalcode: '56766',
    city: 'Ulmen',
    countrycode: 'DE',
  },
  {
    postalcode: '56767',
    city: 'Gunderath',
    countrycode: 'DE',
  },
  {
    postalcode: '56767',
    city: 'Höchstberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56767',
    city: 'Kaperich',
    countrycode: 'DE',
  },
  {
    postalcode: '56767',
    city: 'Kolverath',
    countrycode: 'DE',
  },
  {
    postalcode: '56767',
    city: 'Kölnische Höfe',
    countrycode: 'DE',
  },
  {
    postalcode: '56767',
    city: 'Kötterichen',
    countrycode: 'DE',
  },
  {
    postalcode: '56767',
    city: 'Lirstal',
    countrycode: 'DE',
  },
  {
    postalcode: '56767',
    city: 'Mosbruch',
    countrycode: 'DE',
  },
  {
    postalcode: '56767',
    city: 'Oberelz',
    countrycode: 'DE',
  },
  {
    postalcode: '56767',
    city: 'Sassen',
    countrycode: 'DE',
  },
  {
    postalcode: '56767',
    city: 'Uersfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '56767',
    city: 'Ueß',
    countrycode: 'DE',
  },
  {
    postalcode: '56769',
    city: 'Arbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56769',
    city: 'Bereborn',
    countrycode: 'DE',
  },
  {
    postalcode: '56769',
    city: 'Mannebach',
    countrycode: 'DE',
  },
  {
    postalcode: '56769',
    city: 'Retterath',
    countrycode: 'DE',
  },
  {
    postalcode: '56812',
    city: 'Cochem',
    countrycode: 'DE',
  },
  {
    postalcode: '56812',
    city: 'Dohr',
    countrycode: 'DE',
  },
  {
    postalcode: '56812',
    city: 'Valwig',
    countrycode: 'DE',
  },
  {
    postalcode: '56814',
    city: 'Beilstein',
    countrycode: 'DE',
  },
  {
    postalcode: '56814',
    city: 'Bremm',
    countrycode: 'DE',
  },
  {
    postalcode: '56814',
    city: 'Bruttig-Fankel',
    countrycode: 'DE',
  },
  {
    postalcode: '56814',
    city: 'Ediger-Eller',
    countrycode: 'DE',
  },
  {
    postalcode: '56814',
    city: 'Ernst',
    countrycode: 'DE',
  },
  {
    postalcode: '56814',
    city: 'Faid',
    countrycode: 'DE',
  },
  {
    postalcode: '56814',
    city: 'Greimersburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56814',
    city: 'Illerich',
    countrycode: 'DE',
  },
  {
    postalcode: '56814',
    city: 'Landkern',
    countrycode: 'DE',
  },
  {
    postalcode: '56814',
    city: 'Wirfus',
    countrycode: 'DE',
  },
  {
    postalcode: '56818',
    city: 'Klotten',
    countrycode: 'DE',
  },
  {
    postalcode: '56820',
    city: 'Briedern',
    countrycode: 'DE',
  },
  {
    postalcode: '56820',
    city: 'Mesenich',
    countrycode: 'DE',
  },
  {
    postalcode: '56820',
    city: 'Nehren',
    countrycode: 'DE',
  },
  {
    postalcode: '56820',
    city: 'Senheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56821',
    city: 'Ellenz-Poltersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '56823',
    city: 'Büchel',
    countrycode: 'DE',
  },
  {
    postalcode: '56825',
    city: 'Beuren',
    countrycode: 'DE',
  },
  {
    postalcode: '56825',
    city: 'Gevenich',
    countrycode: 'DE',
  },
  {
    postalcode: '56825',
    city: 'Gillenbeuren',
    countrycode: 'DE',
  },
  {
    postalcode: '56825',
    city: 'Kliding',
    countrycode: 'DE',
  },
  {
    postalcode: '56825',
    city: 'Schmitt',
    countrycode: 'DE',
  },
  {
    postalcode: '56825',
    city: 'Urschmitt',
    countrycode: 'DE',
  },
  {
    postalcode: '56825',
    city: 'Weiler',
    countrycode: 'DE',
  },
  {
    postalcode: '56826',
    city: 'Heckenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56826',
    city: 'Lutzerath',
    countrycode: 'DE',
  },
  {
    postalcode: '56826',
    city: 'Wagenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56826',
    city: 'Wollmerath',
    countrycode: 'DE',
  },
  {
    postalcode: '56828',
    city: 'Alflen',
    countrycode: 'DE',
  },
  {
    postalcode: '56829',
    city: 'Brieden',
    countrycode: 'DE',
  },
  {
    postalcode: '56829',
    city: 'Kail',
    countrycode: 'DE',
  },
  {
    postalcode: '56829',
    city: 'Pommern',
    countrycode: 'DE',
  },
  {
    postalcode: '56841',
    city: 'Hödeshof',
    countrycode: 'DE',
  },
  {
    postalcode: '56841',
    city: 'Traben-Trarbach',
    countrycode: 'DE',
  },
  {
    postalcode: '56843',
    city: 'Burg',
    countrycode: 'DE',
  },
  {
    postalcode: '56843',
    city: 'Irmenach',
    countrycode: 'DE',
  },
  {
    postalcode: '56843',
    city: 'Lötzbeuren',
    countrycode: 'DE',
  },
  {
    postalcode: '56843',
    city: 'Scholmunderhof',
    countrycode: 'DE',
  },
  {
    postalcode: '56843',
    city: 'Starkenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '56850',
    city: 'Briedeler Heck',
    countrycode: 'DE',
  },
  {
    postalcode: '56850',
    city: 'Enkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '56850',
    city: 'Hahn',
    countrycode: 'DE',
  },
  {
    postalcode: '56850',
    city: 'Hohestein',
    countrycode: 'DE',
  },
  {
    postalcode: '56850',
    city: 'Maiermund',
    countrycode: 'DE',
  },
  {
    postalcode: '56850',
    city: 'Raversbeuren',
    countrycode: 'DE',
  },
  {
    postalcode: '56856',
    city: 'Zell',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Altlay',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Altstrimmig',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Belg',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Forst',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Grenderich',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Haserich',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Liesenich',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Mittelstrimmig',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Neef',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Peterswald-Löffelscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Rödelhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Sankt Aldegund',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Sosberg',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Tellig',
    countrycode: 'DE',
  },
  {
    postalcode: '56858',
    city: 'Würrich',
    countrycode: 'DE',
  },
  {
    postalcode: '56859',
    city: 'Alf',
    countrycode: 'DE',
  },
  {
    postalcode: '56859',
    city: 'Bullay',
    countrycode: 'DE',
  },
  {
    postalcode: '56859',
    city: 'Weidenhell',
    countrycode: 'DE',
  },
  {
    postalcode: '56861',
    city: 'Reil',
    countrycode: 'DE',
  },
  {
    postalcode: '56862',
    city: 'Pünderich',
    countrycode: 'DE',
  },
  {
    postalcode: '56864',
    city: 'Bad Bertrich',
    countrycode: 'DE',
  },
  {
    postalcode: '56864',
    city: 'Beurenermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '56864',
    city: 'Bonsbeuern',
    countrycode: 'DE',
  },
  {
    postalcode: '56864',
    city: 'Pelzerhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '56865',
    city: 'Blankenrath',
    countrycode: 'DE',
  },
  {
    postalcode: '56865',
    city: 'Hesweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '56865',
    city: 'Moritzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '56865',
    city: 'Panzweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '56865',
    city: 'Reidenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56865',
    city: 'Schauren',
    countrycode: 'DE',
  },
  {
    postalcode: '56865',
    city: 'Vogthof',
    countrycode: 'DE',
  },
  {
    postalcode: '56865',
    city: 'Walhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '56867',
    city: 'Briedel',
    countrycode: 'DE',
  },
  {
    postalcode: '56869',
    city: 'Mastershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57072',
    city: 'Siegen',
    countrycode: 'DE',
  },
  {
    postalcode: '57074',
    city: 'Siegen',
    countrycode: 'DE',
  },
  {
    postalcode: '57076',
    city: 'Siegen',
    countrycode: 'DE',
  },
  {
    postalcode: '57078',
    city: 'Siegen',
    countrycode: 'DE',
  },
  {
    postalcode: '57080',
    city: 'Siegen',
    countrycode: 'DE',
  },
  {
    postalcode: '57223',
    city: 'Kreuztal',
    countrycode: 'DE',
  },
  {
    postalcode: '57234',
    city: 'Wilnsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '57250',
    city: 'Netphen',
    countrycode: 'DE',
  },
  {
    postalcode: '57258',
    city: 'Freudenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '57271',
    city: 'Hilchenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57290',
    city: 'Neunkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '57299',
    city: 'Burbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57319',
    city: 'Bad Berleburg',
    countrycode: 'DE',
  },
  {
    postalcode: '57334',
    city: 'Bad Laasphe',
    countrycode: 'DE',
  },
  {
    postalcode: '57339',
    city: 'Erndtebrück',
    countrycode: 'DE',
  },
  {
    postalcode: '57368',
    city: 'Lennestadt',
    countrycode: 'DE',
  },
  {
    postalcode: '57392',
    city: 'Schmallenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '57399',
    city: 'Kirchhundem',
    countrycode: 'DE',
  },
  {
    postalcode: '57413',
    city: 'Finnentrop',
    countrycode: 'DE',
  },
  {
    postalcode: '57439',
    city: 'Attendorn',
    countrycode: 'DE',
  },
  {
    postalcode: '57462',
    city: 'Olpe',
    countrycode: 'DE',
  },
  {
    postalcode: '57482',
    city: 'Wenden',
    countrycode: 'DE',
  },
  {
    postalcode: '57489',
    city: 'Drolshagen',
    countrycode: 'DE',
  },
  {
    postalcode: '57518',
    city: 'Alsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '57518',
    city: 'Betzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '57518',
    city: 'Steineroth',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Derschen',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Dickendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Emmerzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Friedewald',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Grünebach',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Kausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Langenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Mauden',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Molzhain',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Neunkhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Niederdreisbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Rosenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Rosenheimerlay',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Schutzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57520',
    city: 'Steinebach',
    countrycode: 'DE',
  },
  {
    postalcode: '57537',
    city: 'Dellingen',
    countrycode: 'DE',
  },
  {
    postalcode: '57537',
    city: 'Forst',
    countrycode: 'DE',
  },
  {
    postalcode: '57537',
    city: 'Hövels',
    countrycode: 'DE',
  },
  {
    postalcode: '57537',
    city: 'Kaltau',
    countrycode: 'DE',
  },
  {
    postalcode: '57537',
    city: 'Langenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57537',
    city: 'Lechenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57537',
    city: 'Mittelhof',
    countrycode: 'DE',
  },
  {
    postalcode: '57537',
    city: 'Selbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57537',
    city: 'Thal',
    countrycode: 'DE',
  },
  {
    postalcode: '57537',
    city: 'Wissen',
    countrycode: 'DE',
  },
  {
    postalcode: '57539',
    city: 'Bitzen',
    countrycode: 'DE',
  },
  {
    postalcode: '57539',
    city: 'Breitscheidt',
    countrycode: 'DE',
  },
  {
    postalcode: '57539',
    city: 'Bruchertseifen',
    countrycode: 'DE',
  },
  {
    postalcode: '57539',
    city: 'Etzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57539',
    city: 'Fürthen',
    countrycode: 'DE',
  },
  {
    postalcode: '57539',
    city: 'Heckenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '57539',
    city: 'Herrgottsau',
    countrycode: 'DE',
  },
  {
    postalcode: '57539',
    city: 'Neuschlade',
    countrycode: 'DE',
  },
  {
    postalcode: '57539',
    city: 'Roth',
    countrycode: 'DE',
  },
  {
    postalcode: '57548',
    city: 'Diedenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '57548',
    city: 'Kirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '57555',
    city: 'Brachbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57555',
    city: 'Euteneuen',
    countrycode: 'DE',
  },
  {
    postalcode: '57555',
    city: 'Mudersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57562',
    city: 'Herdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '57567',
    city: 'Daaden',
    countrycode: 'DE',
  },
  {
    postalcode: '57572',
    city: 'Harbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57572',
    city: 'Niederfischbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57577',
    city: 'Hamm',
    countrycode: 'DE',
  },
  {
    postalcode: '57577',
    city: 'Pfannenschoppen',
    countrycode: 'DE',
  },
  {
    postalcode: '57577',
    city: 'Seelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57577',
    city: 'Thalhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57577',
    city: 'Thalhausermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '57578',
    city: 'Elkenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '57580',
    city: 'Elben',
    countrycode: 'DE',
  },
  {
    postalcode: '57580',
    city: 'Fensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '57580',
    city: 'Gebhardshain',
    countrycode: 'DE',
  },
  {
    postalcode: '57581',
    city: 'Katzwinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '57583',
    city: 'Mörlen',
    countrycode: 'DE',
  },
  {
    postalcode: '57583',
    city: 'Nauroth',
    countrycode: 'DE',
  },
  {
    postalcode: '57584',
    city: 'Scheuerfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '57584',
    city: 'Wallmenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '57586',
    city: 'Weitefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '57587',
    city: 'Birken-Honigsessen',
    countrycode: 'DE',
  },
  {
    postalcode: '57587',
    city: 'Mühlenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '57589',
    city: 'Birkenbeul',
    countrycode: 'DE',
  },
  {
    postalcode: '57589',
    city: 'Friedenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '57589',
    city: 'Niederirsen',
    countrycode: 'DE',
  },
  {
    postalcode: '57589',
    city: 'Pracht',
    countrycode: 'DE',
  },
  {
    postalcode: '57610',
    city: 'Almersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57610',
    city: 'Altenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '57610',
    city: 'Bachenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '57610',
    city: 'Gieleroth',
    countrycode: 'DE',
  },
  {
    postalcode: '57610',
    city: 'Ingelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57610',
    city: 'Michelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Birnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Busenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Eichelhardt',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Giesenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Helmenzen',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Helmeroth',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Hemmelzen',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Heupelzen',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Hilgenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Idelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Ingelbach-Bahnhof',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Isert',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Kettenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Kroppach',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Marienthal',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Obererbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Racksen',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Volkerzen',
    countrycode: 'DE',
  },
  {
    postalcode: '57612',
    city: 'Ölsen',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Alberthofen',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Berod',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Borod',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Brubbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Fluterschen',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Mudenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Niederwambach',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Oberwambach',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Ratzert',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Steimel',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Stürzelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Wahlrod',
    countrycode: 'DE',
  },
  {
    postalcode: '57614',
    city: 'Woldert',
    countrycode: 'DE',
  },
  {
    postalcode: '57627',
    city: 'Astert',
    countrycode: 'DE',
  },
  {
    postalcode: '57627',
    city: 'Gehlert',
    countrycode: 'DE',
  },
  {
    postalcode: '57627',
    city: 'Hachenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '57627',
    city: 'Heuzert',
    countrycode: 'DE',
  },
  {
    postalcode: '57627',
    city: 'Marzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Atzelgift',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Dreifelden',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Heimborn',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Höchstenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Kirburg',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Kundert',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Limbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Linden',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Lochum',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Luckenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Malberg',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Marienstatt',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Merkelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Mörsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Müschenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Norken',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Stein-Wingert',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Steinebach',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Streithausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57629',
    city: 'Wied',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Berzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Burglahr',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Eichen',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Eulenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Flammersfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Giershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Kescheid',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Orfgen',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Peterslahr',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Reiferscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Rott',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Schürdt',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Seelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Seifen',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Walterschen',
    countrycode: 'DE',
  },
  {
    postalcode: '57632',
    city: 'Ziegenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Ersfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Fiersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Forstmehren',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Hasselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Heuberg',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Hirz-Maulsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Kircheib',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Kraam',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Mehren',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Oberirsen',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Rettersen',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Werkhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Weyerbusch',
    countrycode: 'DE',
  },
  {
    postalcode: '57635',
    city: 'Wölmersen',
    countrycode: 'DE',
  },
  {
    postalcode: '57636',
    city: 'Mammelzen',
    countrycode: 'DE',
  },
  {
    postalcode: '57636',
    city: 'Sörth',
    countrycode: 'DE',
  },
  {
    postalcode: '57638',
    city: 'Neitersen',
    countrycode: 'DE',
  },
  {
    postalcode: '57638',
    city: 'Obernau',
    countrycode: 'DE',
  },
  {
    postalcode: '57638',
    city: 'Schöneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '57639',
    city: 'Oberdreis',
    countrycode: 'DE',
  },
  {
    postalcode: '57639',
    city: 'Rodenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57641',
    city: 'Oberlahr',
    countrycode: 'DE',
  },
  {
    postalcode: '57642',
    city: 'Alpenrod',
    countrycode: 'DE',
  },
  {
    postalcode: '57644',
    city: 'Hattert',
    countrycode: 'DE',
  },
  {
    postalcode: '57644',
    city: 'Krambergsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '57644',
    city: 'Marzauermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '57644',
    city: 'Welkenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57644',
    city: 'Winkelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '57645',
    city: 'Kellershof',
    countrycode: 'DE',
  },
  {
    postalcode: '57645',
    city: 'Nister',
    countrycode: 'DE',
  },
  {
    postalcode: '57647',
    city: 'Enspel',
    countrycode: 'DE',
  },
  {
    postalcode: '57647',
    city: 'Hirtscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '57647',
    city: 'Nistertal',
    countrycode: 'DE',
  },
  {
    postalcode: '57647',
    city: 'Stöffel',
    countrycode: 'DE',
  },
  {
    postalcode: '57647',
    city: 'Talmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '57648',
    city: 'Bölsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '57648',
    city: 'Unnau',
    countrycode: 'DE',
  },
  {
    postalcode: '58089',
    city: 'Hagen',
    countrycode: 'DE',
  },
  {
    postalcode: '58091',
    city: 'Hagen',
    countrycode: 'DE',
  },
  {
    postalcode: '58093',
    city: 'Hagen',
    countrycode: 'DE',
  },
  {
    postalcode: '58095',
    city: 'Hagen',
    countrycode: 'DE',
  },
  {
    postalcode: '58097',
    city: 'Hagen',
    countrycode: 'DE',
  },
  {
    postalcode: '58099',
    city: 'Hagen',
    countrycode: 'DE',
  },
  {
    postalcode: '58119',
    city: 'Hagen',
    countrycode: 'DE',
  },
  {
    postalcode: '58135',
    city: 'Hagen',
    countrycode: 'DE',
  },
  {
    postalcode: '58239',
    city: 'Schwerte',
    countrycode: 'DE',
  },
  {
    postalcode: '58256',
    city: 'Ennepetal',
    countrycode: 'DE',
  },
  {
    postalcode: '58285',
    city: 'Gevelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '58300',
    city: 'Wetter',
    countrycode: 'DE',
  },
  {
    postalcode: '58313',
    city: 'Herdecke',
    countrycode: 'DE',
  },
  {
    postalcode: '58332',
    city: 'Schwelm',
    countrycode: 'DE',
  },
  {
    postalcode: '58339',
    city: 'Breckerfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '58452',
    city: 'Witten',
    countrycode: 'DE',
  },
  {
    postalcode: '58453',
    city: 'Witten',
    countrycode: 'DE',
  },
  {
    postalcode: '58454',
    city: 'Witten',
    countrycode: 'DE',
  },
  {
    postalcode: '58455',
    city: 'Witten',
    countrycode: 'DE',
  },
  {
    postalcode: '58456',
    city: 'Witten',
    countrycode: 'DE',
  },
  {
    postalcode: '58507',
    city: 'Lüdenscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '58509',
    city: 'Lüdenscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '58511',
    city: 'Lüdenscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '58513',
    city: 'Lüdenscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '58515',
    city: 'Lüdenscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '58540',
    city: 'Meinerzhagen',
    countrycode: 'DE',
  },
  {
    postalcode: '58553',
    city: 'Halver',
    countrycode: 'DE',
  },
  {
    postalcode: '58566',
    city: 'Kierspe',
    countrycode: 'DE',
  },
  {
    postalcode: '58579',
    city: 'Schalksmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '58636',
    city: 'Iserlohn',
    countrycode: 'DE',
  },
  {
    postalcode: '58638',
    city: 'Iserlohn',
    countrycode: 'DE',
  },
  {
    postalcode: '58640',
    city: 'Iserlohn',
    countrycode: 'DE',
  },
  {
    postalcode: '58642',
    city: 'Iserlohn',
    countrycode: 'DE',
  },
  {
    postalcode: '58644',
    city: 'Iserlohn',
    countrycode: 'DE',
  },
  {
    postalcode: '58675',
    city: 'Hemer',
    countrycode: 'DE',
  },
  {
    postalcode: '58706',
    city: 'Menden',
    countrycode: 'DE',
  },
  {
    postalcode: '58708',
    city: 'Menden',
    countrycode: 'DE',
  },
  {
    postalcode: '58710',
    city: 'Menden',
    countrycode: 'DE',
  },
  {
    postalcode: '58730',
    city: 'Fröndenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '58739',
    city: 'Wickede',
    countrycode: 'DE',
  },
  {
    postalcode: '58762',
    city: 'Altena',
    countrycode: 'DE',
  },
  {
    postalcode: '58769',
    city: 'Nachrodt-Wiblingwerde',
    countrycode: 'DE',
  },
  {
    postalcode: '58791',
    city: 'Werdohl',
    countrycode: 'DE',
  },
  {
    postalcode: '58802',
    city: 'Balve',
    countrycode: 'DE',
  },
  {
    postalcode: '58809',
    city: 'Neuenrade',
    countrycode: 'DE',
  },
  {
    postalcode: '58840',
    city: 'Plettenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '58849',
    city: 'Herscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '59063',
    city: 'Hamm',
    countrycode: 'DE',
  },
  {
    postalcode: '59065',
    city: 'Hamm',
    countrycode: 'DE',
  },
  {
    postalcode: '59067',
    city: 'Hamm',
    countrycode: 'DE',
  },
  {
    postalcode: '59069',
    city: 'Hamm',
    countrycode: 'DE',
  },
  {
    postalcode: '59071',
    city: 'Hamm',
    countrycode: 'DE',
  },
  {
    postalcode: '59073',
    city: 'Hamm',
    countrycode: 'DE',
  },
  {
    postalcode: '59075',
    city: 'Hamm',
    countrycode: 'DE',
  },
  {
    postalcode: '59077',
    city: 'Hamm',
    countrycode: 'DE',
  },
  {
    postalcode: '59174',
    city: 'Kamen',
    countrycode: 'DE',
  },
  {
    postalcode: '59192',
    city: 'Bergkamen',
    countrycode: 'DE',
  },
  {
    postalcode: '59199',
    city: 'Bönen',
    countrycode: 'DE',
  },
  {
    postalcode: '59227',
    city: 'Ahlen',
    countrycode: 'DE',
  },
  {
    postalcode: '59229',
    city: 'Ahlen',
    countrycode: 'DE',
  },
  {
    postalcode: '59269',
    city: 'Beckum',
    countrycode: 'DE',
  },
  {
    postalcode: '59302',
    city: 'Oelde',
    countrycode: 'DE',
  },
  {
    postalcode: '59320',
    city: 'Ennigerloh',
    countrycode: 'DE',
  },
  {
    postalcode: '59329',
    city: 'Wadersloh',
    countrycode: 'DE',
  },
  {
    postalcode: '59348',
    city: 'Lüdinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '59368',
    city: 'Werne',
    countrycode: 'DE',
  },
  {
    postalcode: '59379',
    city: 'Selm',
    countrycode: 'DE',
  },
  {
    postalcode: '59387',
    city: 'Ascheberg',
    countrycode: 'DE',
  },
  {
    postalcode: '59394',
    city: 'Nordkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '59399',
    city: 'Olfen',
    countrycode: 'DE',
  },
  {
    postalcode: '59423',
    city: 'Unna',
    countrycode: 'DE',
  },
  {
    postalcode: '59425',
    city: 'Unna',
    countrycode: 'DE',
  },
  {
    postalcode: '59427',
    city: 'Unna',
    countrycode: 'DE',
  },
  {
    postalcode: '59439',
    city: 'Holzwickede',
    countrycode: 'DE',
  },
  {
    postalcode: '59457',
    city: 'Werl',
    countrycode: 'DE',
  },
  {
    postalcode: '59469',
    city: 'Ense',
    countrycode: 'DE',
  },
  {
    postalcode: '59494',
    city: 'Soest',
    countrycode: 'DE',
  },
  {
    postalcode: '59505',
    city: 'Bad Sassendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '59510',
    city: 'Lippetal',
    countrycode: 'DE',
  },
  {
    postalcode: '59514',
    city: 'Welver',
    countrycode: 'DE',
  },
  {
    postalcode: '59519',
    city: 'Möhnesee',
    countrycode: 'DE',
  },
  {
    postalcode: '59555',
    city: 'Lippstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '59556',
    city: 'Lippstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '59557',
    city: 'Lippstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '59558',
    city: 'Lippstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '59581',
    city: 'Warstein',
    countrycode: 'DE',
  },
  {
    postalcode: '59590',
    city: 'Geseke',
    countrycode: 'DE',
  },
  {
    postalcode: '59597',
    city: 'Erwitte',
    countrycode: 'DE',
  },
  {
    postalcode: '59602',
    city: 'Rüthen',
    countrycode: 'DE',
  },
  {
    postalcode: '59609',
    city: 'Anröchte',
    countrycode: 'DE',
  },
  {
    postalcode: '59755',
    city: 'Arnsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '59757',
    city: 'Arnsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '59759',
    city: 'Arnsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '59821',
    city: 'Arnsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '59823',
    city: 'Arnsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '59846',
    city: 'Sundern',
    countrycode: 'DE',
  },
  {
    postalcode: '59872',
    city: 'Meschede',
    countrycode: 'DE',
  },
  {
    postalcode: '59889',
    city: 'Eslohe',
    countrycode: 'DE',
  },
  {
    postalcode: '59909',
    city: 'Bestwig',
    countrycode: 'DE',
  },
  {
    postalcode: '59929',
    city: 'Brilon',
    countrycode: 'DE',
  },
  {
    postalcode: '59939',
    city: 'Olsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '59955',
    city: 'Winterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '59964',
    city: 'Medebach',
    countrycode: 'DE',
  },
  {
    postalcode: '59969',
    city: 'Bromskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '59969',
    city: 'Hallenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '60308',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60311',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60313',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60314',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60316',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60318',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60320',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60322',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60323',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60325',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60326',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60327',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60329',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60385',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60386',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60388',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60389',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60431',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60433',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60435',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60437',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60438',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60439',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60486',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60487',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60488',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60489',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60528',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60529',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60549',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60594',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60596',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60598',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '60599',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '61118',
    city: 'Bad Vilbel',
    countrycode: 'DE',
  },
  {
    postalcode: '61130',
    city: 'Nidderau',
    countrycode: 'DE',
  },
  {
    postalcode: '61130',
    city: 'Winnerhöfe',
    countrycode: 'DE',
  },
  {
    postalcode: '61137',
    city: 'Schöneck',
    countrycode: 'DE',
  },
  {
    postalcode: '61138',
    city: 'Niederdorfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '61169',
    city: 'Friedberg',
    countrycode: 'DE',
  },
  {
    postalcode: '61184',
    city: 'Karben',
    countrycode: 'DE',
  },
  {
    postalcode: '61191',
    city: 'Rosbach',
    countrycode: 'DE',
  },
  {
    postalcode: '61194',
    city: 'Niddatal',
    countrycode: 'DE',
  },
  {
    postalcode: '61197',
    city: 'Florstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '61200',
    city: 'Am Römerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '61200',
    city: 'Am Römerschacht',
    countrycode: 'DE',
  },
  {
    postalcode: '61200',
    city: 'Wölfersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '61203',
    city: 'Bingenheimer Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '61203',
    city: 'Eiserhof',
    countrycode: 'DE',
  },
  {
    postalcode: '61203',
    city: 'Reichelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '61203',
    city: 'Winterhof',
    countrycode: 'DE',
  },
  {
    postalcode: '61206',
    city: 'Wöllstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '61209',
    city: 'Echzell',
    countrycode: 'DE',
  },
  {
    postalcode: '61209',
    city: 'Kreuzquelle',
    countrycode: 'DE',
  },
  {
    postalcode: '61231',
    city: 'Bad Nauheim',
    countrycode: 'DE',
  },
  {
    postalcode: '61239',
    city: 'Ober-Mörlen',
    countrycode: 'DE',
  },
  {
    postalcode: '61250',
    city: 'Erdfunkstelle Usingen',
    countrycode: 'DE',
  },
  {
    postalcode: '61250',
    city: 'Usingen',
    countrycode: 'DE',
  },
  {
    postalcode: '61267',
    city: 'Neu-Anspach',
    countrycode: 'DE',
  },
  {
    postalcode: '61273',
    city: 'Saalburg',
    countrycode: 'DE',
  },
  {
    postalcode: '61273',
    city: 'Wehrheim',
    countrycode: 'DE',
  },
  {
    postalcode: '61276',
    city: 'Weilrod',
    countrycode: 'DE',
  },
  {
    postalcode: '61279',
    city: 'Grävenwiesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '61348',
    city: 'Bad Homburg',
    countrycode: 'DE',
  },
  {
    postalcode: '61350',
    city: 'Bad Homburg',
    countrycode: 'DE',
  },
  {
    postalcode: '61352',
    city: 'Bad Homburg',
    countrycode: 'DE',
  },
  {
    postalcode: '61381',
    city: 'Friedrichsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '61389',
    city: 'Schmitten',
    countrycode: 'DE',
  },
  {
    postalcode: '61440',
    city: 'Oberursel',
    countrycode: 'DE',
  },
  {
    postalcode: '61449',
    city: 'Steinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '61462',
    city: 'Königstein',
    countrycode: 'DE',
  },
  {
    postalcode: '61476',
    city: 'Kronberg',
    countrycode: 'DE',
  },
  {
    postalcode: '61479',
    city: 'Glashütten',
    countrycode: 'DE',
  },
  {
    postalcode: '63065',
    city: 'Offenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63067',
    city: 'Offenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63069',
    city: 'Offenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63071',
    city: 'Offenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63073',
    city: 'Offenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63075',
    city: 'Offenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63110',
    city: 'Rodgau',
    countrycode: 'DE',
  },
  {
    postalcode: '63128',
    city: 'Dietzenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63150',
    city: 'Heusenstamm',
    countrycode: 'DE',
  },
  {
    postalcode: '63150',
    city: 'Wildhof',
    countrycode: 'DE',
  },
  {
    postalcode: '63165',
    city: 'Mühlheim',
    countrycode: 'DE',
  },
  {
    postalcode: '63179',
    city: 'Obertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '63225',
    city: 'Langen',
    countrycode: 'DE',
  },
  {
    postalcode: '63263',
    city: 'Neu-Isenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '63303',
    city: 'Dreieich',
    countrycode: 'DE',
  },
  {
    postalcode: '63322',
    city: 'Rödermark',
    countrycode: 'DE',
  },
  {
    postalcode: '63329',
    city: 'Egelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63450',
    city: 'Hanau',
    countrycode: 'DE',
  },
  {
    postalcode: '63452',
    city: 'Hanau',
    countrycode: 'DE',
  },
  {
    postalcode: '63454',
    city: 'Hanau',
    countrycode: 'DE',
  },
  {
    postalcode: '63456',
    city: 'Hanau',
    countrycode: 'DE',
  },
  {
    postalcode: '63457',
    city: 'Hanau',
    countrycode: 'DE',
  },
  {
    postalcode: '63477',
    city: 'Maintal',
    countrycode: 'DE',
  },
  {
    postalcode: '63486',
    city: 'Bruchköbel',
    countrycode: 'DE',
  },
  {
    postalcode: '63500',
    city: 'Seligenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '63505',
    city: 'Hof Eckeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '63505',
    city: 'Langenselbold',
    countrycode: 'DE',
  },
  {
    postalcode: '63512',
    city: 'Hainburg',
    countrycode: 'DE',
  },
  {
    postalcode: '63517',
    city: 'Rodenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63526',
    city: 'Erlensee',
    countrycode: 'DE',
  },
  {
    postalcode: '63533',
    city: 'Mainhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '63538',
    city: 'Großkrotzenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '63543',
    city: 'Bei den Tongruben',
    countrycode: 'DE',
  },
  {
    postalcode: '63543',
    city: 'Neuberg',
    countrycode: 'DE',
  },
  {
    postalcode: '63546',
    city: 'Hammersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63549',
    city: 'Ronneburg',
    countrycode: 'DE',
  },
  {
    postalcode: '63571',
    city: 'Gelnhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '63579',
    city: 'Freigericht',
    countrycode: 'DE',
  },
  {
    postalcode: '63584',
    city: 'Gründau',
    countrycode: 'DE',
  },
  {
    postalcode: '63589',
    city: 'Linsengericht',
    countrycode: 'DE',
  },
  {
    postalcode: '63589',
    city: 'Tannengrundhof',
    countrycode: 'DE',
  },
  {
    postalcode: '63589',
    city: 'Weißkirchhof',
    countrycode: 'DE',
  },
  {
    postalcode: '63594',
    city: 'Hasselroth',
    countrycode: 'DE',
  },
  {
    postalcode: '63599',
    city: 'Biebergemünd',
    countrycode: 'DE',
  },
  {
    postalcode: '63607',
    city: 'Wächtersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63619',
    city: 'Bad Orb',
    countrycode: 'DE',
  },
  {
    postalcode: '63628',
    city: 'Bad Soden-Salmünster',
    countrycode: 'DE',
  },
  {
    postalcode: '63633',
    city: 'Birstein',
    countrycode: 'DE',
  },
  {
    postalcode: '63636',
    city: 'Brachttal',
    countrycode: 'DE',
  },
  {
    postalcode: '63637',
    city: 'Jossgrund',
    countrycode: 'DE',
  },
  {
    postalcode: '63639',
    city: 'Flörsbachtal',
    countrycode: 'DE',
  },
  {
    postalcode: '63654',
    city: 'Büdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '63667',
    city: 'Nidda',
    countrycode: 'DE',
  },
  {
    postalcode: '63674',
    city: 'Altenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '63679',
    city: 'Schotten',
    countrycode: 'DE',
  },
  {
    postalcode: '63683',
    city: 'Ortenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '63688',
    city: 'Gedern',
    countrycode: 'DE',
  },
  {
    postalcode: '63691',
    city: 'Ranstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '63694',
    city: 'Limeshain',
    countrycode: 'DE',
  },
  {
    postalcode: '63695',
    city: 'Glauburg',
    countrycode: 'DE',
  },
  {
    postalcode: '63697',
    city: 'Hirzenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '63699',
    city: 'Birkenstöcke',
    countrycode: 'DE',
  },
  {
    postalcode: '63699',
    city: 'Hanchesmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '63699',
    city: 'Kefenrod',
    countrycode: 'DE',
  },
  {
    postalcode: '63699',
    city: 'Loosemühle',
    countrycode: 'DE',
  },
  {
    postalcode: '63739',
    city: 'Aschaffenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '63741',
    city: 'Aschaffenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '63743',
    city: 'Aschaffenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '63755',
    city: 'Alzenau',
    countrycode: 'DE',
  },
  {
    postalcode: '63762',
    city: 'Großostheim',
    countrycode: 'DE',
  },
  {
    postalcode: '63768',
    city: 'Hösbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63773',
    city: 'Goldbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63776',
    city: 'Hüttelngesäß',
    countrycode: 'DE',
  },
  {
    postalcode: '63776',
    city: 'Mömbris',
    countrycode: 'DE',
  },
  {
    postalcode: '63785',
    city: 'Obernburg',
    countrycode: 'DE',
  },
  {
    postalcode: '63791',
    city: 'Karlstein',
    countrycode: 'DE',
  },
  {
    postalcode: '63796',
    city: 'Kahl',
    countrycode: 'DE',
  },
  {
    postalcode: '63801',
    city: 'Kleinostheim',
    countrycode: 'DE',
  },
  {
    postalcode: '63808',
    city: 'Haibach',
    countrycode: 'DE',
  },
  {
    postalcode: '63811',
    city: 'Stockstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '63814',
    city: 'Mainaschaff',
    countrycode: 'DE',
  },
  {
    postalcode: '63820',
    city: 'Elsenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '63825',
    city: 'Blankenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63825',
    city: 'Schöllkrippen',
    countrycode: 'DE',
  },
  {
    postalcode: '63825',
    city: 'Sommerkahl',
    countrycode: 'DE',
  },
  {
    postalcode: '63825',
    city: 'Westerngrund',
    countrycode: 'DE',
  },
  {
    postalcode: '63826',
    city: 'Geiselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63828',
    city: 'Kleinkahl',
    countrycode: 'DE',
  },
  {
    postalcode: '63829',
    city: 'Krombach',
    countrycode: 'DE',
  },
  {
    postalcode: '63831',
    city: 'Wiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '63834',
    city: 'Sulzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63839',
    city: 'Kleinwallstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '63840',
    city: 'Hausen',
    countrycode: 'DE',
  },
  {
    postalcode: '63843',
    city: 'Niedernberg',
    countrycode: 'DE',
  },
  {
    postalcode: '63846',
    city: 'Laufach',
    countrycode: 'DE',
  },
  {
    postalcode: '63846',
    city: 'Weiberhof',
    countrycode: 'DE',
  },
  {
    postalcode: '63849',
    city: 'Leidersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63853',
    city: 'Mömlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '63856',
    city: 'Bessenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63857',
    city: 'Waldaschaff',
    countrycode: 'DE',
  },
  {
    postalcode: '63860',
    city: 'Rothenbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '63863',
    city: 'Eschau',
    countrycode: 'DE',
  },
  {
    postalcode: '63863',
    city: 'Hundsrück',
    countrycode: 'DE',
  },
  {
    postalcode: '63864',
    city: 'Glattbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63867',
    city: 'Johannesberg',
    countrycode: 'DE',
  },
  {
    postalcode: '63868',
    city: 'Großwallstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '63869',
    city: 'Heigenbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '63871',
    city: 'Heinrichsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '63872',
    city: 'Heimbuchenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '63874',
    city: 'Dammbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63874',
    city: 'Höllhammer',
    countrycode: 'DE',
  },
  {
    postalcode: '63875',
    city: 'Mespelbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '63877',
    city: 'Sailauf',
    countrycode: 'DE',
  },
  {
    postalcode: '63879',
    city: 'Weibersbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '63897',
    city: 'Miltenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '63906',
    city: 'Erlenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63911',
    city: 'Klingenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '63916',
    city: 'Amorbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63916',
    city: 'Sansenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '63920',
    city: 'Großheubach',
    countrycode: 'DE',
  },
  {
    postalcode: '63924',
    city: 'Kleinheubach',
    countrycode: 'DE',
  },
  {
    postalcode: '63924',
    city: 'Rüdenau',
    countrycode: 'DE',
  },
  {
    postalcode: '63925',
    city: 'Brunnthal',
    countrycode: 'DE',
  },
  {
    postalcode: '63925',
    city: 'Laudenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63927',
    city: 'Bürgstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '63928',
    city: 'Eichenbühl',
    countrycode: 'DE',
  },
  {
    postalcode: '63928',
    city: 'Spritzenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '63930',
    city: 'Neunkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '63931',
    city: 'Kirchzell',
    countrycode: 'DE',
  },
  {
    postalcode: '63933',
    city: 'Mönchberg',
    countrycode: 'DE',
  },
  {
    postalcode: '63934',
    city: 'Röllbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63936',
    city: 'Schneeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '63937',
    city: 'Weilbach',
    countrycode: 'DE',
  },
  {
    postalcode: '63939',
    city: 'Wörth',
    countrycode: 'DE',
  },
  {
    postalcode: '64283',
    city: 'Darmstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64285',
    city: 'Darmstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64287',
    city: 'Darmstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64289',
    city: 'Darmstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64291',
    city: 'Darmstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64293',
    city: 'Darmstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64295',
    city: 'Darmstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64297',
    city: 'Darmstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64319',
    city: 'Pfungstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64331',
    city: 'Weiterstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64342',
    city: 'Seeheim-Jugenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '64347',
    city: 'Griesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '64354',
    city: 'Reinheim',
    countrycode: 'DE',
  },
  {
    postalcode: '64367',
    city: 'Mühltal',
    countrycode: 'DE',
  },
  {
    postalcode: '64372',
    city: 'Ober-Ramstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64380',
    city: 'Roßdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '64385',
    city: 'Gumpener Kreuz',
    countrycode: 'DE',
  },
  {
    postalcode: '64385',
    city: 'Reichelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '64390',
    city: 'Erzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '64395',
    city: 'Brensbach',
    countrycode: 'DE',
  },
  {
    postalcode: '64395',
    city: 'Hippelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '64395',
    city: 'Hundertmorgen',
    countrycode: 'DE',
  },
  {
    postalcode: '64395',
    city: 'Kohlbacher Hof',
    countrycode: 'DE',
  },
  {
    postalcode: '64397',
    city: 'Modautal',
    countrycode: 'DE',
  },
  {
    postalcode: '64401',
    city: 'Groß-Bieberau',
    countrycode: 'DE',
  },
  {
    postalcode: '64404',
    city: 'Bickenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '64405',
    city: 'Fischbachtal',
    countrycode: 'DE',
  },
  {
    postalcode: '64407',
    city: 'Fränkisch-Crumbach',
    countrycode: 'DE',
  },
  {
    postalcode: '64409',
    city: 'Messel',
    countrycode: 'DE',
  },
  {
    postalcode: '64521',
    city: 'Groß-Gerau',
    countrycode: 'DE',
  },
  {
    postalcode: '64546',
    city: 'Mörfelden-Walldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '64560',
    city: 'Riedstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64569',
    city: 'Nauheim',
    countrycode: 'DE',
  },
  {
    postalcode: '64572',
    city: 'Büttelborn',
    countrycode: 'DE',
  },
  {
    postalcode: '64579',
    city: 'Gernsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '64584',
    city: 'Biebesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '64589',
    city: 'Stockstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64625',
    city: 'Bensheim',
    countrycode: 'DE',
  },
  {
    postalcode: '64646',
    city: 'Heppenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '64653',
    city: 'Lorsch',
    countrycode: 'DE',
  },
  {
    postalcode: '64658',
    city: 'Faustenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '64658',
    city: 'Fürth',
    countrycode: 'DE',
  },
  {
    postalcode: '64665',
    city: 'Alsbach-Hähnlein',
    countrycode: 'DE',
  },
  {
    postalcode: '64668',
    city: 'Rimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '64673',
    city: 'Zwingenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '64678',
    city: 'Lindenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '64683',
    city: 'Einhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '64686',
    city: 'Lautertal',
    countrycode: 'DE',
  },
  {
    postalcode: '64689',
    city: 'Grasellenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '64711',
    city: 'Erbach',
    countrycode: 'DE',
  },
  {
    postalcode: '64711',
    city: 'Gebhardshütte',
    countrycode: 'DE',
  },
  {
    postalcode: '64720',
    city: 'Michelstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64720',
    city: 'Schimmelshütte',
    countrycode: 'DE',
  },
  {
    postalcode: '64732',
    city: 'Bad König',
    countrycode: 'DE',
  },
  {
    postalcode: '64739',
    city: 'Höchst',
    countrycode: 'DE',
  },
  {
    postalcode: '64743',
    city: 'Beerfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '64743',
    city: 'Marbach',
    countrycode: 'DE',
  },
  {
    postalcode: '64747',
    city: 'Breuberg',
    countrycode: 'DE',
  },
  {
    postalcode: '64750',
    city: 'Lützelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '64753',
    city: 'Brombachtal',
    countrycode: 'DE',
  },
  {
    postalcode: '64753',
    city: 'Wünschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '64754',
    city: 'Badisch Schöllenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '64754',
    city: 'Hesseneck',
    countrycode: 'DE',
  },
  {
    postalcode: '64756',
    city: 'Mossautal',
    countrycode: 'DE',
  },
  {
    postalcode: '64757',
    city: 'Rothenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '64757',
    city: 'Unter-Hainbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '64759',
    city: 'Sensbachtal',
    countrycode: 'DE',
  },
  {
    postalcode: '64807',
    city: 'Dieburg',
    countrycode: 'DE',
  },
  {
    postalcode: '64823',
    city: 'Groß-Umstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '64832',
    city: 'Babenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '64839',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '64846',
    city: 'Groß-Zimmern',
    countrycode: 'DE',
  },
  {
    postalcode: '64850',
    city: 'Schaafheim',
    countrycode: 'DE',
  },
  {
    postalcode: '64853',
    city: 'Otzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '64859',
    city: 'Eppertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '65183',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65185',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65187',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65189',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65191',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65193',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65195',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65197',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65199',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65201',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65203',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65205',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65207',
    city: 'Wiesbaden',
    countrycode: 'DE',
  },
  {
    postalcode: '65232',
    city: 'Taunusstein',
    countrycode: 'DE',
  },
  {
    postalcode: '65239',
    city: 'Hochheim',
    countrycode: 'DE',
  },
  {
    postalcode: '65307',
    city: 'Bad Schwalbach',
    countrycode: 'DE',
  },
  {
    postalcode: '65321',
    city: 'Heidenrod',
    countrycode: 'DE',
  },
  {
    postalcode: '65326',
    city: 'Aarbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '65326',
    city: 'Sandersmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '65329',
    city: 'Hohenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '65343',
    city: 'Eltville',
    countrycode: 'DE',
  },
  {
    postalcode: '65344',
    city: 'Eltville',
    countrycode: 'DE',
  },
  {
    postalcode: '65345',
    city: 'Eltville',
    countrycode: 'DE',
  },
  {
    postalcode: '65346',
    city: 'Eltville',
    countrycode: 'DE',
  },
  {
    postalcode: '65347',
    city: 'Eltville',
    countrycode: 'DE',
  },
  {
    postalcode: '65366',
    city: 'Geisenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '65375',
    city: 'Oestrich-Winkel',
    countrycode: 'DE',
  },
  {
    postalcode: '65385',
    city: 'Am Rüdesheimer Hafen',
    countrycode: 'DE',
  },
  {
    postalcode: '65385',
    city: 'Rüdesheim am Rhein',
    countrycode: 'DE',
  },
  {
    postalcode: '65388',
    city: 'Schlangenbad',
    countrycode: 'DE',
  },
  {
    postalcode: '65391',
    city: 'Lorch',
    countrycode: 'DE',
  },
  {
    postalcode: '65391',
    city: 'Sauerthal',
    countrycode: 'DE',
  },
  {
    postalcode: '65396',
    city: 'Walluf',
    countrycode: 'DE',
  },
  {
    postalcode: '65399',
    city: 'Kiedrich',
    countrycode: 'DE',
  },
  {
    postalcode: '65428',
    city: 'Rüsselsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '65439',
    city: 'Flörsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '65451',
    city: 'Kelsterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '65462',
    city: 'Ginsheim-Gustavsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '65468',
    city: 'Trebur',
    countrycode: 'DE',
  },
  {
    postalcode: '65474',
    city: 'Bischofsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '65479',
    city: 'Raunheim',
    countrycode: 'DE',
  },
  {
    postalcode: '65510',
    city: 'Hasenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '65510',
    city: 'Hühnerkirche',
    countrycode: 'DE',
  },
  {
    postalcode: '65510',
    city: 'Hünstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '65510',
    city: 'Idstein',
    countrycode: 'DE',
  },
  {
    postalcode: '65520',
    city: 'Bad Camberg',
    countrycode: 'DE',
  },
  {
    postalcode: '65527',
    city: 'Niedernhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '65529',
    city: 'Waldems',
    countrycode: 'DE',
  },
  {
    postalcode: '65549',
    city: 'Limburg',
    countrycode: 'DE',
  },
  {
    postalcode: '65550',
    city: 'Limburg',
    countrycode: 'DE',
  },
  {
    postalcode: '65551',
    city: 'Limburg',
    countrycode: 'DE',
  },
  {
    postalcode: '65552',
    city: 'Limburg',
    countrycode: 'DE',
  },
  {
    postalcode: '65553',
    city: 'Limburg',
    countrycode: 'DE',
  },
  {
    postalcode: '65554',
    city: 'Limburg',
    countrycode: 'DE',
  },
  {
    postalcode: '65555',
    city: 'Limburg',
    countrycode: 'DE',
  },
  {
    postalcode: '65556',
    city: 'Limburg',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Balduinstein',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Burgschwalbach',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Cramberg',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Eppenrod',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Flacht',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Gückingen',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Heistenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Hirschberg',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Holzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Isselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Kaltenholzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Langenscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Lohrheim',
    countrycode: 'DE',
  },
  {
    postalcode: '65558',
    city: 'Oberneisen',
    countrycode: 'DE',
  },
  {
    postalcode: '65582',
    city: 'Aull',
    countrycode: 'DE',
  },
  {
    postalcode: '65582',
    city: 'Diez',
    countrycode: 'DE',
  },
  {
    postalcode: '65582',
    city: 'Hambach',
    countrycode: 'DE',
  },
  {
    postalcode: '65589',
    city: 'Hadamar',
    countrycode: 'DE',
  },
  {
    postalcode: '65594',
    city: 'Runkel',
    countrycode: 'DE',
  },
  {
    postalcode: '65597',
    city: 'Hünfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '65599',
    city: 'Dornburg',
    countrycode: 'DE',
  },
  {
    postalcode: '65604',
    city: 'Elz',
    countrycode: 'DE',
  },
  {
    postalcode: '65606',
    city: 'Villmar',
    countrycode: 'DE',
  },
  {
    postalcode: '65611',
    city: 'Brechen',
    countrycode: 'DE',
  },
  {
    postalcode: '65614',
    city: 'Beselich',
    countrycode: 'DE',
  },
  {
    postalcode: '65618',
    city: 'Selters',
    countrycode: 'DE',
  },
  {
    postalcode: '65620',
    city: 'Waldbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '65623',
    city: 'Hahnstätten',
    countrycode: 'DE',
  },
  {
    postalcode: '65623',
    city: 'Mudershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '65623',
    city: 'Netzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '65623',
    city: 'Schiesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '65624',
    city: 'Altendiez',
    countrycode: 'DE',
  },
  {
    postalcode: '65626',
    city: 'Birlenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '65626',
    city: 'Fachingen',
    countrycode: 'DE',
  },
  {
    postalcode: '65627',
    city: 'Elbtal',
    countrycode: 'DE',
  },
  {
    postalcode: '65629',
    city: 'Niederneisen',
    countrycode: 'DE',
  },
  {
    postalcode: '65719',
    city: 'Hofheim',
    countrycode: 'DE',
  },
  {
    postalcode: '65760',
    city: 'Eschborn',
    countrycode: 'DE',
  },
  {
    postalcode: '65779',
    city: 'Kelkheim',
    countrycode: 'DE',
  },
  {
    postalcode: '65795',
    city: 'Hattersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '65812',
    city: 'Bad Soden',
    countrycode: 'DE',
  },
  {
    postalcode: '65817',
    city: 'Eppstein',
    countrycode: 'DE',
  },
  {
    postalcode: '65824',
    city: 'Schwalbach',
    countrycode: 'DE',
  },
  {
    postalcode: '65830',
    city: 'Kriftel',
    countrycode: 'DE',
  },
  {
    postalcode: '65835',
    city: 'Liederbach',
    countrycode: 'DE',
  },
  {
    postalcode: '65843',
    city: 'Sulzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '65929',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '65931',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '65933',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '65934',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '65936',
    city: 'Frankfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '66111',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66113',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66115',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66117',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66119',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66121',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66123',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66125',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66126',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66127',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66128',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66129',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66130',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66131',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66132',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66133',
    city: 'Saarbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66265',
    city: 'Heusweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66271',
    city: 'Kleinblittersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '66280',
    city: 'Sulzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66287',
    city: 'Quierschied',
    countrycode: 'DE',
  },
  {
    postalcode: '66292',
    city: 'Riegelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66299',
    city: 'Friedrichsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '66333',
    city: 'Völklingen',
    countrycode: 'DE',
  },
  {
    postalcode: '66346',
    city: 'Püttlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '66352',
    city: 'Großrosseln',
    countrycode: 'DE',
  },
  {
    postalcode: '66359',
    city: 'Bous',
    countrycode: 'DE',
  },
  {
    postalcode: '66386',
    city: 'St. Ingbert',
    countrycode: 'DE',
  },
  {
    postalcode: '66399',
    city: 'Mandelbachtal',
    countrycode: 'DE',
  },
  {
    postalcode: '66424',
    city: 'Homburg',
    countrycode: 'DE',
  },
  {
    postalcode: '66440',
    city: 'Blieskastel',
    countrycode: 'DE',
  },
  {
    postalcode: '66450',
    city: 'Bexbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66453',
    city: 'Gersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '66459',
    city: 'Kirkel',
    countrycode: 'DE',
  },
  {
    postalcode: '66482',
    city: 'Faustermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66482',
    city: 'Hirschhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66482',
    city: 'Hitscherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66482',
    city: 'Kirschbacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66482',
    city: 'Kirschbachermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66482',
    city: 'Pirmannsteinerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66482',
    city: 'Riedelberg-Tal',
    countrycode: 'DE',
  },
  {
    postalcode: '66482',
    city: 'Stausteinerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66482',
    city: 'Zweibrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66484',
    city: 'Althornbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66484',
    city: 'Battweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66484',
    city: 'Dietrichingen',
    countrycode: 'DE',
  },
  {
    postalcode: '66484',
    city: 'Großsteinhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '66484',
    city: 'Kleinsteinhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '66484',
    city: 'Riedelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66484',
    city: 'Schmitshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '66484',
    city: 'Stockbornerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66484',
    city: 'Walshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '66484',
    city: 'Winterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66497',
    city: 'Contwig',
    countrycode: 'DE',
  },
  {
    postalcode: '66500',
    city: 'Buchholzbergerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66500',
    city: 'Bödingerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66500',
    city: 'Hornbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66500',
    city: 'Hübelhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66500',
    city: 'Mauschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66501',
    city: 'Großbundenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66501',
    city: 'Kleinbundenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66503',
    city: 'Dellfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '66503',
    city: 'Ziegelhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '66504',
    city: 'Bottenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66506',
    city: 'Maßweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66507',
    city: 'Reifenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66509',
    city: 'Rieschweiler-Mühlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66538',
    city: 'Neunkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '66539',
    city: 'Neunkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '66540',
    city: 'Neunkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '66557',
    city: 'Illingen',
    countrycode: 'DE',
  },
  {
    postalcode: '66564',
    city: 'Ottweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66571',
    city: 'Eppelborn',
    countrycode: 'DE',
  },
  {
    postalcode: '66578',
    city: 'Schiffweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66583',
    city: 'Spiesen-Elversberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66589',
    city: 'Merchweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66606',
    city: 'St. Wendel',
    countrycode: 'DE',
  },
  {
    postalcode: '66620',
    city: 'Nonnweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66625',
    city: 'Nohfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '66629',
    city: 'Freisen',
    countrycode: 'DE',
  },
  {
    postalcode: '66636',
    city: 'Tholey',
    countrycode: 'DE',
  },
  {
    postalcode: '66640',
    city: 'Namborn',
    countrycode: 'DE',
  },
  {
    postalcode: '66646',
    city: 'Marpingen',
    countrycode: 'DE',
  },
  {
    postalcode: '66649',
    city: 'Oberthal',
    countrycode: 'DE',
  },
  {
    postalcode: '66663',
    city: 'Merzig',
    countrycode: 'DE',
  },
  {
    postalcode: '66679',
    city: 'Losheim',
    countrycode: 'DE',
  },
  {
    postalcode: '66687',
    city: 'Wadern',
    countrycode: 'DE',
  },
  {
    postalcode: '66693',
    city: 'Mettlach',
    countrycode: 'DE',
  },
  {
    postalcode: '66701',
    city: 'Beckingen',
    countrycode: 'DE',
  },
  {
    postalcode: '66706',
    city: 'Perl',
    countrycode: 'DE',
  },
  {
    postalcode: '66709',
    city: 'Weiskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '66740',
    city: 'Saarlouis',
    countrycode: 'DE',
  },
  {
    postalcode: '66763',
    city: 'Dillingen',
    countrycode: 'DE',
  },
  {
    postalcode: '66773',
    city: 'Schwalbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66780',
    city: 'Rehlingen-Siersburg',
    countrycode: 'DE',
  },
  {
    postalcode: '66787',
    city: 'Wadgassen',
    countrycode: 'DE',
  },
  {
    postalcode: '66793',
    city: 'Saarwellingen',
    countrycode: 'DE',
  },
  {
    postalcode: '66798',
    city: 'Wallerfangen',
    countrycode: 'DE',
  },
  {
    postalcode: '66802',
    city: 'Überherrn',
    countrycode: 'DE',
  },
  {
    postalcode: '66806',
    city: 'Ensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '66809',
    city: 'Nalbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66822',
    city: 'Lebach',
    countrycode: 'DE',
  },
  {
    postalcode: '66839',
    city: 'Schmelz',
    countrycode: 'DE',
  },
  {
    postalcode: '66849',
    city: 'Am Sandweiher',
    countrycode: 'DE',
  },
  {
    postalcode: '66849',
    city: 'Artamhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66849',
    city: 'Landstuhl',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Bann',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Erkelshäuserhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Hauptstuhl',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Horbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Königreich',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Linden',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Mittelbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Oberarnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Olenkorb',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Queidersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Scharrhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Scharrmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Schwanenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66851',
    city: 'Steinalben',
    countrycode: 'DE',
  },
  {
    postalcode: '66862',
    city: 'Kindsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66869',
    city: 'Blaubach',
    countrycode: 'DE',
  },
  {
    postalcode: '66869',
    city: 'Kusel',
    countrycode: 'DE',
  },
  {
    postalcode: '66869',
    city: 'Ruthweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66869',
    city: 'Schellweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Albessen',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Dennweiler-Frohnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Ehweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Etschberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Haschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Herchweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Konken',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Körborn',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Oberalben',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Pfeffelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Reichweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Selchenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Thallichtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66871',
    city: 'Theisbergstegen',
    countrycode: 'DE',
  },
  {
    postalcode: '66877',
    city: 'Ramstein-Miesenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66879',
    city: 'Kollweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66879',
    city: 'Kottweiler-Schwanden',
    countrycode: 'DE',
  },
  {
    postalcode: '66879',
    city: 'Niedermohr',
    countrycode: 'DE',
  },
  {
    postalcode: '66879',
    city: 'Niederstaufenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66879',
    city: 'Oberstaufenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66879',
    city: 'Reichenbach-Steegen',
    countrycode: 'DE',
  },
  {
    postalcode: '66879',
    city: 'Steinwenden',
    countrycode: 'DE',
  },
  {
    postalcode: '66882',
    city: 'Hütschenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '66882',
    city: 'Schanzerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66882',
    city: 'Schanzermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66885',
    city: 'Altenglan',
    countrycode: 'DE',
  },
  {
    postalcode: '66885',
    city: 'Bedesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66885',
    city: 'Sulzbacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Bosenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Elzweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Erdesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Föckelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Glanbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Horschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Jettenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Neunkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Niederalben',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Rammelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Rathsweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Rathsweiler Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Rutsweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Sankt Julian',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Ulmet',
    countrycode: 'DE',
  },
  {
    postalcode: '66887',
    city: 'Welchweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66892',
    city: 'Bruchmühlbach-Miesau',
    countrycode: 'DE',
  },
  {
    postalcode: '66892',
    city: 'Elschbacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66894',
    city: 'Bechhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '66894',
    city: 'Etzenbachermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66894',
    city: 'Gerhardsbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '66894',
    city: 'Krähenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66894',
    city: 'Käshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '66894',
    city: 'Lambsborn',
    countrycode: 'DE',
  },
  {
    postalcode: '66894',
    city: 'Langwieden',
    countrycode: 'DE',
  },
  {
    postalcode: '66894',
    city: 'Martinshöhe',
    countrycode: 'DE',
  },
  {
    postalcode: '66894',
    city: 'Rosenkopf',
    countrycode: 'DE',
  },
  {
    postalcode: '66894',
    city: 'Wiesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66901',
    city: 'Schönenberg-Kübelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66903',
    city: 'Altenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '66903',
    city: 'Dittweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66903',
    city: 'Frohnhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '66903',
    city: 'Gries',
    countrycode: 'DE',
  },
  {
    postalcode: '66903',
    city: 'Lindenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66903',
    city: 'Ohmbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66903',
    city: 'Reismühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66904',
    city: 'Brücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66904',
    city: 'Börsborn',
    countrycode: 'DE',
  },
  {
    postalcode: '66904',
    city: 'Haseldell',
    countrycode: 'DE',
  },
  {
    postalcode: '66904',
    city: 'Lebecksmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66907',
    city: 'Glan-Münchweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66907',
    city: 'Rehweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66909',
    city: 'Henschtal',
    countrycode: 'DE',
  },
  {
    postalcode: '66909',
    city: 'Herschweiler-Pettersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '66909',
    city: 'Hüffler',
    countrycode: 'DE',
  },
  {
    postalcode: '66909',
    city: 'Krottelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66909',
    city: 'Langenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66909',
    city: 'Matzenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66909',
    city: 'Nanzdietschweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66909',
    city: 'Quirnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66909',
    city: 'Steinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66909',
    city: 'Wahnwegen',
    countrycode: 'DE',
  },
  {
    postalcode: '66914',
    city: 'Waldmohr',
    countrycode: 'DE',
  },
  {
    postalcode: '66916',
    city: 'Breitenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66916',
    city: 'Dunzweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66916',
    city: 'Hainhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66917',
    city: 'Biedershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '66917',
    city: 'Erlenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66917',
    city: 'Hellbornerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66917',
    city: 'Kneispermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66917',
    city: 'Knopp-Labach',
    countrycode: 'DE',
  },
  {
    postalcode: '66917',
    city: 'Knoppermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66917',
    city: 'Konradsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66917',
    city: 'Seitershof',
    countrycode: 'DE',
  },
  {
    postalcode: '66917',
    city: 'Wallhalben',
    countrycode: 'DE',
  },
  {
    postalcode: '66917',
    city: 'Würschhauserhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66917',
    city: 'Würschhausermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66919',
    city: 'Hermersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66919',
    city: 'Herschberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66919',
    city: 'Hettenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '66919',
    city: 'Obernheim-Kirchenarnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66919',
    city: 'Saalstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '66919',
    city: 'Schauerberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66919',
    city: 'Weselberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66953',
    city: 'Pirmasens',
    countrycode: 'DE',
  },
  {
    postalcode: '66954',
    city: 'Pirmasens',
    countrycode: 'DE',
  },
  {
    postalcode: '66954',
    city: 'Schelertal',
    countrycode: 'DE',
  },
  {
    postalcode: '66955',
    city: 'Beckenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66955',
    city: 'Pirmasens',
    countrycode: 'DE',
  },
  {
    postalcode: '66957',
    city: 'Eppenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '66957',
    city: 'Hilst',
    countrycode: 'DE',
  },
  {
    postalcode: '66957',
    city: 'Kröppen',
    countrycode: 'DE',
  },
  {
    postalcode: '66957',
    city: 'Obersimten',
    countrycode: 'DE',
  },
  {
    postalcode: '66957',
    city: 'Ruppertsweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66957',
    city: 'Schweix',
    countrycode: 'DE',
  },
  {
    postalcode: '66957',
    city: 'Trulben',
    countrycode: 'DE',
  },
  {
    postalcode: '66957',
    city: 'Vinningen',
    countrycode: 'DE',
  },
  {
    postalcode: '66969',
    city: 'Lemberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66976',
    city: 'Rodalben',
    countrycode: 'DE',
  },
  {
    postalcode: '66978',
    city: 'Clausen',
    countrycode: 'DE',
  },
  {
    postalcode: '66978',
    city: 'Donsieders',
    countrycode: 'DE',
  },
  {
    postalcode: '66978',
    city: 'Leimen',
    countrycode: 'DE',
  },
  {
    postalcode: '66978',
    city: 'Merzalben',
    countrycode: 'DE',
  },
  {
    postalcode: '66981',
    city: 'Münchweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66981',
    city: 'Ständehof',
    countrycode: 'DE',
  },
  {
    postalcode: '66987',
    city: 'Thaleischweiler-Fröschen',
    countrycode: 'DE',
  },
  {
    postalcode: '66987',
    city: 'Weihermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66989',
    city: 'Dusenbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '66989',
    city: 'Huberhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66989',
    city: 'Höheinöd',
    countrycode: 'DE',
  },
  {
    postalcode: '66989',
    city: 'Höheischweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66989',
    city: 'Höhfröschen',
    countrycode: 'DE',
  },
  {
    postalcode: '66989',
    city: 'Nünschweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66989',
    city: 'Petersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '66994',
    city: 'Dahn',
    countrycode: 'DE',
  },
  {
    postalcode: '66996',
    city: 'Biehlerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66996',
    city: 'Bärenbrunnerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '66996',
    city: 'Bärenbrunnermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '66996',
    city: 'Erfweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '66996',
    city: 'Fischbach',
    countrycode: 'DE',
  },
  {
    postalcode: '66996',
    city: 'Hirschthal',
    countrycode: 'DE',
  },
  {
    postalcode: '66996',
    city: 'Ludwigswinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '66996',
    city: 'Schindhard',
    countrycode: 'DE',
  },
  {
    postalcode: '66996',
    city: 'Schönau',
    countrycode: 'DE',
  },
  {
    postalcode: '66999',
    city: 'Hinterweidenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '66999',
    city: 'Wieslauterhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67059',
    city: 'Ludwigshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '67061',
    city: 'Ludwigshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '67063',
    city: 'Ludwigshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '67065',
    city: 'Ludwigshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '67067',
    city: 'Ludwigshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '67069',
    city: 'Ludwigshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '67071',
    city: 'Ludwigshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '67098',
    city: 'Annaberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67098',
    city: 'Bad Dürkheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67098',
    city: 'Lindemannsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '67098',
    city: 'Mundhardterhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67105',
    city: 'Schifferstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67112',
    city: 'Mutterstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67117',
    city: 'Limburgerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67122',
    city: 'Altrip',
    countrycode: 'DE',
  },
  {
    postalcode: '67125',
    city: 'Dannstadt-Schauernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67126',
    city: 'Hochdorf-Assenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67127',
    city: 'Rödersheim-Gronau',
    countrycode: 'DE',
  },
  {
    postalcode: '67133',
    city: 'Maxdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '67134',
    city: 'Birkenheide',
    countrycode: 'DE',
  },
  {
    postalcode: '67136',
    city: 'Fußgönheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67141',
    city: 'Neuhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '67146',
    city: 'Deidesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67147',
    city: 'Forst',
    countrycode: 'DE',
  },
  {
    postalcode: '67149',
    city: 'Meckenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67150',
    city: 'Niederkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '67152',
    city: 'Ruppertsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67157',
    city: 'Silbertal',
    countrycode: 'DE',
  },
  {
    postalcode: '67157',
    city: 'Wachenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67158',
    city: 'Ellerstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67159',
    city: 'Friedelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67161',
    city: 'Gönnheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67165',
    city: 'Waldsee',
    countrycode: 'DE',
  },
  {
    postalcode: '67166',
    city: 'Otterstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67166',
    city: 'Reffenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '67167',
    city: 'Erpolzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67169',
    city: 'Kallstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67227',
    city: 'Frankenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '67229',
    city: 'Gerolsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67229',
    city: 'Großkarlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67229',
    city: 'Laumersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67240',
    city: 'Bobenheim-Roxheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67245',
    city: 'Lambsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67246',
    city: 'Dirmstein',
    countrycode: 'DE',
  },
  {
    postalcode: '67251',
    city: 'Freinsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67256',
    city: 'Weisenheim am Sand',
    countrycode: 'DE',
  },
  {
    postalcode: '67258',
    city: 'Heßheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67259',
    city: 'Beindersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67259',
    city: 'Großniedesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67259',
    city: 'Heuchelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67259',
    city: 'Kleinniedesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67269',
    city: 'Grünstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67271',
    city: 'Battenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67271',
    city: 'Kindenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67271',
    city: 'Kleinkarlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67271',
    city: 'Mertesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67271',
    city: 'Neuleiningen',
    countrycode: 'DE',
  },
  {
    postalcode: '67271',
    city: 'Neuleiningen-Tal',
    countrycode: 'DE',
  },
  {
    postalcode: '67271',
    city: 'Obersülzen',
    countrycode: 'DE',
  },
  {
    postalcode: '67273',
    city: 'Bobenheim am Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '67273',
    city: 'Dackenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67273',
    city: 'Herxheim am Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '67273',
    city: 'Weisenheim am Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '67278',
    city: 'Bockenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67280',
    city: 'Ebertsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67280',
    city: 'Quirnheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67281',
    city: 'Bissersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67281',
    city: 'Bruchmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67281',
    city: 'Kirchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67283',
    city: 'Obrigheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67292',
    city: 'Bolanderhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67292',
    city: 'Heubergerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67292',
    city: 'Kirchheimbolanden',
    countrycode: 'DE',
  },
  {
    postalcode: '67292',
    city: 'Leithof',
    countrycode: 'DE',
  },
  {
    postalcode: '67292',
    city: 'Oberthierwasen',
    countrycode: 'DE',
  },
  {
    postalcode: '67292',
    city: 'Pulvermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67292',
    city: 'Unterthierwasen',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Bischheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Gauersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Hessenhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Ilbesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Josefsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Mauchenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Morschheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Oberwiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Orbis',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Pfalzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Rittersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Stetten',
    countrycode: 'DE',
  },
  {
    postalcode: '67294',
    city: 'Steuerwaldsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67295',
    city: 'Bolanden',
    countrycode: 'DE',
  },
  {
    postalcode: '67297',
    city: 'Heyerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67297',
    city: 'Marnheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67304',
    city: 'Eisenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67304',
    city: 'Kerzenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67305',
    city: 'Ochsenbusch',
    countrycode: 'DE',
  },
  {
    postalcode: '67305',
    city: 'Ramsen',
    countrycode: 'DE',
  },
  {
    postalcode: '67307',
    city: 'Göllheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67308',
    city: 'Albisheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67308',
    city: 'Biedesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67308',
    city: 'Bubenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67308',
    city: 'Einselthum',
    countrycode: 'DE',
  },
  {
    postalcode: '67308',
    city: 'Immesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67308',
    city: 'Kleinmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67308',
    city: 'Lautersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67308',
    city: 'Ottersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67308',
    city: 'Rüssingen',
    countrycode: 'DE',
  },
  {
    postalcode: '67308',
    city: 'Wiesenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67308',
    city: 'Zellertal',
    countrycode: 'DE',
  },
  {
    postalcode: '67310',
    city: 'Hettenleidelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67311',
    city: 'Nackterhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67311',
    city: 'Tiefenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '67316',
    city: 'Carlsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67316',
    city: 'Junghof',
    countrycode: 'DE',
  },
  {
    postalcode: '67316',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67316',
    city: 'Neuwoog',
    countrycode: 'DE',
  },
  {
    postalcode: '67317',
    city: 'Altleiningen',
    countrycode: 'DE',
  },
  {
    postalcode: '67317',
    city: 'Maihof',
    countrycode: 'DE',
  },
  {
    postalcode: '67317',
    city: 'Nikolaushof',
    countrycode: 'DE',
  },
  {
    postalcode: '67317',
    city: 'Süßenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67319',
    city: 'Lauberhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67319',
    city: 'Wattenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67346',
    city: 'Angelhof I u. II',
    countrycode: 'DE',
  },
  {
    postalcode: '67346',
    city: 'Speyer',
    countrycode: 'DE',
  },
  {
    postalcode: '67354',
    city: 'Römerberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67360',
    city: 'Lingenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '67361',
    city: 'Freisbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67363',
    city: 'Lustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67365',
    city: 'Schwegenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67366',
    city: 'Weingarten',
    countrycode: 'DE',
  },
  {
    postalcode: '67368',
    city: 'Westheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67373',
    city: 'Dudenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '67374',
    city: 'Hanhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '67376',
    city: 'Harthausen',
    countrycode: 'DE',
  },
  {
    postalcode: '67377',
    city: 'Gommersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67378',
    city: 'Zeiskam',
    countrycode: 'DE',
  },
  {
    postalcode: '67433',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67434',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67435',
    city: 'Benjental',
    countrycode: 'DE',
  },
  {
    postalcode: '67435',
    city: 'Looganlage',
    countrycode: 'DE',
  },
  {
    postalcode: '67435',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67454',
    city: 'Haßloch',
    countrycode: 'DE',
  },
  {
    postalcode: '67459',
    city: 'Böhl-Iggelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67466',
    city: 'Breitenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '67466',
    city: 'Erfenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '67466',
    city: 'Krankenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '67466',
    city: 'Lambrecht',
    countrycode: 'DE',
  },
  {
    postalcode: '67466',
    city: 'Luhrbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67466',
    city: 'Neu-Maschine',
    countrycode: 'DE',
  },
  {
    postalcode: '67466',
    city: 'Nonnental',
    countrycode: 'DE',
  },
  {
    postalcode: '67468',
    city: 'Erlenbacher Forsthaus',
    countrycode: 'DE',
  },
  {
    postalcode: '67468',
    city: 'Frankeneck',
    countrycode: 'DE',
  },
  {
    postalcode: '67468',
    city: 'Frankenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '67468',
    city: 'Lambertskreuz',
    countrycode: 'DE',
  },
  {
    postalcode: '67468',
    city: 'Neidenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '67468',
    city: 'Sattelmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67471',
    city: 'Elmstein',
    countrycode: 'DE',
  },
  {
    postalcode: '67472',
    city: 'Esthal',
    countrycode: 'DE',
  },
  {
    postalcode: '67473',
    city: 'Lindenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67475',
    city: 'Weidenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '67480',
    city: 'Edenkoben',
    countrycode: 'DE',
  },
  {
    postalcode: '67482',
    city: 'Altdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '67482',
    city: 'Böbingen',
    countrycode: 'DE',
  },
  {
    postalcode: '67482',
    city: 'Freimersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67482',
    city: 'Venningen',
    countrycode: 'DE',
  },
  {
    postalcode: '67483',
    city: 'Edesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67483',
    city: 'Großfischlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '67483',
    city: 'Kleinfischlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '67487',
    city: 'Maikammer',
    countrycode: 'DE',
  },
  {
    postalcode: '67487',
    city: 'St Martin',
    countrycode: 'DE',
  },
  {
    postalcode: '67489',
    city: 'Kirrweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67547',
    city: 'Worms',
    countrycode: 'DE',
  },
  {
    postalcode: '67549',
    city: 'Worms',
    countrycode: 'DE',
  },
  {
    postalcode: '67550',
    city: 'Worms',
    countrycode: 'DE',
  },
  {
    postalcode: '67551',
    city: 'Worms',
    countrycode: 'DE',
  },
  {
    postalcode: '67574',
    city: 'Osthofen',
    countrycode: 'DE',
  },
  {
    postalcode: '67575',
    city: 'Eich',
    countrycode: 'DE',
  },
  {
    postalcode: '67577',
    city: 'Alsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67578',
    city: 'Gimbsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67580',
    city: 'Hamm',
    countrycode: 'DE',
  },
  {
    postalcode: '67582',
    city: 'Mettenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67583',
    city: 'Guntersblum',
    countrycode: 'DE',
  },
  {
    postalcode: '67585',
    city: 'Dorn-Dürkheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67586',
    city: 'Hillesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67587',
    city: 'Wintersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67590',
    city: 'Monsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67591',
    city: 'Hohen-Sülzen',
    countrycode: 'DE',
  },
  {
    postalcode: '67591',
    city: 'Mölsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67591',
    city: 'Mörstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67591',
    city: 'Offstein',
    countrycode: 'DE',
  },
  {
    postalcode: '67591',
    city: 'Wachenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67592',
    city: 'Flörsheim-Dalsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67593',
    city: 'Bermersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67593',
    city: 'Westhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '67595',
    city: 'Bechtheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67596',
    city: 'Dittelsheim-Heßloch',
    countrycode: 'DE',
  },
  {
    postalcode: '67596',
    city: 'Frettenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67598',
    city: 'Gundersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67599',
    city: 'Gundheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67655',
    city: 'Kaiserslautern',
    countrycode: 'DE',
  },
  {
    postalcode: '67657',
    city: 'Kaiserslautern',
    countrycode: 'DE',
  },
  {
    postalcode: '67659',
    city: 'Kaiserslautern',
    countrycode: 'DE',
  },
  {
    postalcode: '67661',
    city: 'Breitenau',
    countrycode: 'DE',
  },
  {
    postalcode: '67661',
    city: 'Kaiserslautern',
    countrycode: 'DE',
  },
  {
    postalcode: '67661',
    city: 'Kindsbach, Forsthaus',
    countrycode: 'DE',
  },
  {
    postalcode: '67661',
    city: 'Stüterhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67663',
    city: 'Kaiserslautern',
    countrycode: 'DE',
  },
  {
    postalcode: '67677',
    city: 'Altenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67677',
    city: 'Enkenbach-Alsenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '67677',
    city: 'Schorlenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67678',
    city: 'Mehlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '67680',
    city: 'Eichenbachermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67680',
    city: 'Neuhemsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67681',
    city: 'Sembach',
    countrycode: 'DE',
  },
  {
    postalcode: '67681',
    city: 'Wartenberg-Rohrbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67685',
    city: 'Erzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '67685',
    city: 'Eulenbis',
    countrycode: 'DE',
  },
  {
    postalcode: '67685',
    city: 'Mückenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67685',
    city: 'Mückenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67685',
    city: 'Schwedelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67685',
    city: 'Weilerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67686',
    city: 'Mackenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67688',
    city: 'Rodenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67691',
    city: 'Hochspeyer',
    countrycode: 'DE',
  },
  {
    postalcode: '67691',
    city: 'Klaftertalerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67693',
    city: 'Fischbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67693',
    city: 'Waldleiningen',
    countrycode: 'DE',
  },
  {
    postalcode: '67697',
    city: 'Otterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67699',
    city: 'Heiligenmoschel',
    countrycode: 'DE',
  },
  {
    postalcode: '67699',
    city: 'Horterhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67699',
    city: 'Schneckenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '67699',
    city: 'Sickingerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67700',
    city: 'Niederkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '67701',
    city: 'Schallodenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67705',
    city: 'Eisenschmelz',
    countrycode: 'DE',
  },
  {
    postalcode: '67705',
    city: 'Finsterbrunnertal',
    countrycode: 'DE',
  },
  {
    postalcode: '67705',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67705',
    city: 'Stelzenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67705',
    city: 'Trippstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67706',
    city: 'Krickenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67706',
    city: 'Ländlerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67707',
    city: 'Karlsthal Bahnhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67707',
    city: 'Schopp',
    countrycode: 'DE',
  },
  {
    postalcode: '67714',
    city: 'Heidelsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '67714',
    city: 'Hollertal',
    countrycode: 'DE',
  },
  {
    postalcode: '67714',
    city: 'Hundsweihersägemühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67714',
    city: 'Waldfischbach-Burgalben',
    countrycode: 'DE',
  },
  {
    postalcode: '67715',
    city: 'Geiselberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67716',
    city: 'Heltersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67718',
    city: 'Schmalenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67722',
    city: 'Winnweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67724',
    city: 'Gehrweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67724',
    city: 'Gonbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67724',
    city: 'Gundersweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67724',
    city: 'Höringen',
    countrycode: 'DE',
  },
  {
    postalcode: '67724',
    city: 'Messersbacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67725',
    city: 'Breunigweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67725',
    city: 'Börrstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '67725',
    city: 'Röderhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67727',
    city: 'Lohnsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '67728',
    city: 'Münchweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67729',
    city: 'Sippersfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '67731',
    city: 'Dudenbacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67731',
    city: 'Otterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67731',
    city: 'Reichenbacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67732',
    city: 'Hirschhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '67734',
    city: 'Katzweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67734',
    city: 'Sulzbachtal',
    countrycode: 'DE',
  },
  {
    postalcode: '67735',
    city: 'Mehlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67737',
    city: 'Frankelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67737',
    city: 'Olsbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '67737',
    city: 'Winterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67742',
    city: 'Adenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67742',
    city: 'Buborn',
    countrycode: 'DE',
  },
  {
    postalcode: '67742',
    city: 'Bösodenbacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67742',
    city: 'Deimberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67742',
    city: 'Ginsweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67742',
    city: 'Hausweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67742',
    city: 'Heinzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '67742',
    city: 'Herren-Sulzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67742',
    city: 'Lauterecken',
    countrycode: 'DE',
  },
  {
    postalcode: '67742',
    city: 'Schönbornerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Cronenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Hohenöllen',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Homberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Hoppstädten',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Kappeln',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Kirrweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Lohnweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Löllbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Medard',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Rathskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Schweinschied',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Seelen',
    countrycode: 'DE',
  },
  {
    postalcode: '67744',
    city: 'Wiesweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67745',
    city: 'Grumbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67746',
    city: 'Langweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67746',
    city: 'Merzweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67746',
    city: 'Unterjeckenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67748',
    city: 'Odenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67749',
    city: 'Nerzweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67749',
    city: 'Offenbach-Hundheim',
    countrycode: 'DE',
  },
  {
    postalcode: '67752',
    city: 'Oberweiler-Tiefenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67752',
    city: 'Rutsweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67752',
    city: 'Wolfstein',
    countrycode: 'DE',
  },
  {
    postalcode: '67753',
    city: 'Aschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67753',
    city: 'Einöllen',
    countrycode: 'DE',
  },
  {
    postalcode: '67753',
    city: 'Hefersweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67753',
    city: 'Reipoltskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '67753',
    city: 'Relsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67753',
    city: 'Rothselberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67754',
    city: 'Eßweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67756',
    city: 'Hinzweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67756',
    city: 'Oberweiler im Tal',
    countrycode: 'DE',
  },
  {
    postalcode: '67757',
    city: 'Kreimbach-Kaulbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67759',
    city: 'Nußbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67759',
    city: 'Reichsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Bisterschied',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Dörrmoschel',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Felsbergerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Karlshöhe',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Katzenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Kolbenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Kreuzhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Obermittweilerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Rockenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Schacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Simonshof',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Spreiterhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Teschenmoschel',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Untermittweilerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67806',
    city: 'Wolfsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Bayerfeld-Steckweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Bennhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Falkenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Imsweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Jägerlust, Forsthaus',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Langheckerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Mörsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Neubau',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Ransweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Ruppertsecken',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Schweisweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Schönborn',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Stahlberg',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Steinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Sulzhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Wambacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Weitersweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67808',
    city: 'Würzweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67811',
    city: 'Dielkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '67813',
    city: 'Gerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67813',
    city: 'Schwarzengraben',
    countrycode: 'DE',
  },
  {
    postalcode: '67813',
    city: 'St Alban',
    countrycode: 'DE',
  },
  {
    postalcode: '67814',
    city: 'Dannenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '67814',
    city: 'Jakobsweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67816',
    city: 'Dreisen',
    countrycode: 'DE',
  },
  {
    postalcode: '67816',
    city: 'Herfingerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67816',
    city: 'Mühlbusch',
    countrycode: 'DE',
  },
  {
    postalcode: '67816',
    city: 'Standenbühl',
    countrycode: 'DE',
  },
  {
    postalcode: '67817',
    city: 'Imsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67819',
    city: 'Kriegsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '67821',
    city: 'Alsenz',
    countrycode: 'DE',
  },
  {
    postalcode: '67821',
    city: 'Oberndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Bremricherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Finkenbach-Gersweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Gaugrehweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Gutenbacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Hengstbacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Kalkofen',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Leiningerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Mannweiler-Cölln',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Münsterappel',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Niederhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Niedermoschel',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Schmalfelderhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Stolzenbergerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Waldgrehweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67822',
    city: 'Winterborn',
    countrycode: 'DE',
  },
  {
    postalcode: '67823',
    city: 'Bergmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '67823',
    city: 'Lettweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '67823',
    city: 'Obermoschel',
    countrycode: 'DE',
  },
  {
    postalcode: '67823',
    city: 'Schiersfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '67823',
    city: 'Sitters',
    countrycode: 'DE',
  },
  {
    postalcode: '67823',
    city: 'Unkenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67824',
    city: 'Feilbingert',
    countrycode: 'DE',
  },
  {
    postalcode: '67826',
    city: 'Hallgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '67826',
    city: 'Montforterhof',
    countrycode: 'DE',
  },
  {
    postalcode: '67827',
    city: 'Becherbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67829',
    city: 'Callbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67829',
    city: 'Reiffelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '67829',
    city: 'Schmittweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '68159',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68161',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68163',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68165',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68167',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68169',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68199',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68219',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68229',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68239',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68259',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68305',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68307',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68309',
    city: 'Mannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68519',
    city: 'Viernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68526',
    city: 'Ladenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '68535',
    city: 'Edingen-Neckarhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '68542',
    city: 'Heddesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68549',
    city: 'Ilvesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68623',
    city: 'Forsthaus Heide',
    countrycode: 'DE',
  },
  {
    postalcode: '68623',
    city: 'Lampertheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68642',
    city: 'Bürstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '68647',
    city: 'Biblis',
    countrycode: 'DE',
  },
  {
    postalcode: '68649',
    city: 'Groß-Rohrheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68723',
    city: 'Oftersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68723',
    city: 'Plankstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '68723',
    city: 'Schwetzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '68753',
    city: 'Waghäusel',
    countrycode: 'DE',
  },
  {
    postalcode: '68766',
    city: 'Hockenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68775',
    city: 'Ketsch',
    countrycode: 'DE',
  },
  {
    postalcode: '68782',
    city: 'Brühl',
    countrycode: 'DE',
  },
  {
    postalcode: '68789',
    city: 'St. Leon-Rot',
    countrycode: 'DE',
  },
  {
    postalcode: '68794',
    city: 'Oberhausen-Rheinhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '68799',
    city: 'Reilingen',
    countrycode: 'DE',
  },
  {
    postalcode: '68804',
    city: 'Altlußheim',
    countrycode: 'DE',
  },
  {
    postalcode: '68809',
    city: 'Neulußheim',
    countrycode: 'DE',
  },
  {
    postalcode: '69115',
    city: 'Heidelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '69117',
    city: 'Heidelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '69118',
    city: 'Heidelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '69120',
    city: 'Heidelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '69121',
    city: 'Heidelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '69123',
    city: 'Heidelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '69124',
    city: 'Heidelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '69126',
    city: 'Heidelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '69151',
    city: 'Neckargemünd',
    countrycode: 'DE',
  },
  {
    postalcode: '69168',
    city: 'Wiesloch',
    countrycode: 'DE',
  },
  {
    postalcode: '69181',
    city: 'Leimen',
    countrycode: 'DE',
  },
  {
    postalcode: '69190',
    city: 'Walldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '69198',
    city: 'Schriesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '69207',
    city: 'Sandhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '69214',
    city: 'Eppelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '69221',
    city: 'Dossenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '69226',
    city: 'Nußloch',
    countrycode: 'DE',
  },
  {
    postalcode: '69231',
    city: 'Rauenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '69234',
    city: 'Dielheim',
    countrycode: 'DE',
  },
  {
    postalcode: '69239',
    city: 'Neckarsteinach',
    countrycode: 'DE',
  },
  {
    postalcode: '69242',
    city: 'Mühlhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '69245',
    city: 'Bammental',
    countrycode: 'DE',
  },
  {
    postalcode: '69250',
    city: 'Schönau',
    countrycode: 'DE',
  },
  {
    postalcode: '69251',
    city: 'Gaiberg',
    countrycode: 'DE',
  },
  {
    postalcode: '69253',
    city: 'Heiligkreuzsteinach',
    countrycode: 'DE',
  },
  {
    postalcode: '69254',
    city: 'Malsch',
    countrycode: 'DE',
  },
  {
    postalcode: '69256',
    city: 'Mauer',
    countrycode: 'DE',
  },
  {
    postalcode: '69257',
    city: 'Wiesenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '69259',
    city: 'Wilhelmsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '69412',
    city: 'Eberbach',
    countrycode: 'DE',
  },
  {
    postalcode: '69412',
    city: 'Igelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '69427',
    city: 'Mudau',
    countrycode: 'DE',
  },
  {
    postalcode: '69429',
    city: 'Unterdielbach',
    countrycode: 'DE',
  },
  {
    postalcode: '69429',
    city: 'Waldbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '69434',
    city: 'Brombach',
    countrycode: 'DE',
  },
  {
    postalcode: '69434',
    city: 'Heddesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '69434',
    city: 'Hirschhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '69436',
    city: 'Schönbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '69437',
    city: 'Neckargerach',
    countrycode: 'DE',
  },
  {
    postalcode: '69439',
    city: 'Zwingenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '69469',
    city: 'Weinheim',
    countrycode: 'DE',
  },
  {
    postalcode: '69483',
    city: 'Wald-Michelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '69488',
    city: 'Birkenau',
    countrycode: 'DE',
  },
  {
    postalcode: '69493',
    city: 'Hirschberg',
    countrycode: 'DE',
  },
  {
    postalcode: '69502',
    city: 'Hemsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '69509',
    city: 'Mörlenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '69514',
    city: 'Laudenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '69517',
    city: 'Gorxheimertal',
    countrycode: 'DE',
  },
  {
    postalcode: '69518',
    city: 'Abtsteinach',
    countrycode: 'DE',
  },
  {
    postalcode: '70173',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70174',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70176',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70178',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70180',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70182',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70184',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70186',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70188',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70190',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70191',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70192',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70193',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70195',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70197',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70199',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70327',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70329',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70372',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70374',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70376',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70378',
    city: 'Sonnenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '70378',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70435',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70437',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70439',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70469',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70499',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70563',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70565',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70567',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70569',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70597',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70599',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70619',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70629',
    city: 'Stuttgart',
    countrycode: 'DE',
  },
  {
    postalcode: '70734',
    city: 'Fellbach',
    countrycode: 'DE',
  },
  {
    postalcode: '70736',
    city: 'Fellbach',
    countrycode: 'DE',
  },
  {
    postalcode: '70771',
    city: 'Leinfelden-Echterdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '70794',
    city: 'Filderstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '70806',
    city: 'Kornwestheim',
    countrycode: 'DE',
  },
  {
    postalcode: '70825',
    city: 'Korntal-Münchingen',
    countrycode: 'DE',
  },
  {
    postalcode: '70839',
    city: 'Gerlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71032',
    city: 'Böblingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71034',
    city: 'Böblingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71063',
    city: 'Sindelfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71065',
    city: 'Sindelfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71067',
    city: 'Sindelfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71069',
    city: 'Sindelfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71083',
    city: 'Herrenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '71088',
    city: 'Holzgerlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71093',
    city: 'Weil im Schönbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '71101',
    city: 'Schönaich',
    countrycode: 'DE',
  },
  {
    postalcode: '71106',
    city: 'Magstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '71111',
    city: 'Burkhardtsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '71111',
    city: 'Obere Kleinmichelesmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '71111',
    city: 'Obere Rauhmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '71111',
    city: 'Untere Kleinmichelesmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '71111',
    city: 'Untere Rauhmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '71111',
    city: 'Waldenbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '71116',
    city: 'Gärtringen',
    countrycode: 'DE',
  },
  {
    postalcode: '71120',
    city: 'Grafenau',
    countrycode: 'DE',
  },
  {
    postalcode: '71126',
    city: 'Gäufelden',
    countrycode: 'DE',
  },
  {
    postalcode: '71131',
    city: 'Jettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71134',
    city: 'Aidlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71139',
    city: 'Ehningen',
    countrycode: 'DE',
  },
  {
    postalcode: '71144',
    city: 'Schlechtenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '71144',
    city: 'Schlößlesmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '71144',
    city: 'Steinenbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '71144',
    city: 'Walzenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '71149',
    city: 'Bondorf',
    countrycode: 'DE',
  },
  {
    postalcode: '71154',
    city: 'Nufringen',
    countrycode: 'DE',
  },
  {
    postalcode: '71155',
    city: 'Altdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '71157',
    city: 'Hildrizhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '71159',
    city: 'Mötzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71229',
    city: 'Leonberg',
    countrycode: 'DE',
  },
  {
    postalcode: '71254',
    city: 'Ditzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71263',
    city: 'Weil der Stadt',
    countrycode: 'DE',
  },
  {
    postalcode: '71272',
    city: 'Grundhof',
    countrycode: 'DE',
  },
  {
    postalcode: '71272',
    city: 'Renningen',
    countrycode: 'DE',
  },
  {
    postalcode: '71277',
    city: 'Rutesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '71282',
    city: 'Hemmingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71287',
    city: 'Weissach',
    countrycode: 'DE',
  },
  {
    postalcode: '71292',
    city: 'Friolzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '71296',
    city: 'Heimsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '71297',
    city: 'Mönsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '71299',
    city: 'Wimsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '71332',
    city: 'Waiblingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71334',
    city: 'Waiblingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71336',
    city: 'Waiblingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71364',
    city: 'Birkachhof',
    countrycode: 'DE',
  },
  {
    postalcode: '71364',
    city: 'Steinächle',
    countrycode: 'DE',
  },
  {
    postalcode: '71364',
    city: 'Winnenden',
    countrycode: 'DE',
  },
  {
    postalcode: '71384',
    city: 'Weinstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '71394',
    city: 'Kernen',
    countrycode: 'DE',
  },
  {
    postalcode: '71397',
    city: 'Leutenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '71404',
    city: 'Korb',
    countrycode: 'DE',
  },
  {
    postalcode: '71409',
    city: 'Schwaikheim',
    countrycode: 'DE',
  },
  {
    postalcode: '71522',
    city: 'Backnang',
    countrycode: 'DE',
  },
  {
    postalcode: '71540',
    city: 'Glattenzainbach',
    countrycode: 'DE',
  },
  {
    postalcode: '71540',
    city: 'Hornberger Reute',
    countrycode: 'DE',
  },
  {
    postalcode: '71540',
    city: 'Murrhardt',
    countrycode: 'DE',
  },
  {
    postalcode: '71543',
    city: 'Stocksberg, Gem Beilstein',
    countrycode: 'DE',
  },
  {
    postalcode: '71543',
    city: 'Wüstenrot',
    countrycode: 'DE',
  },
  {
    postalcode: '71546',
    city: 'Aspach',
    countrycode: 'DE',
  },
  {
    postalcode: '71549',
    city: 'Auenwald',
    countrycode: 'DE',
  },
  {
    postalcode: '71549',
    city: 'Glaitenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '71554',
    city: 'Weissach',
    countrycode: 'DE',
  },
  {
    postalcode: '71560',
    city: 'Bernhalden',
    countrycode: 'DE',
  },
  {
    postalcode: '71560',
    city: 'Mittelfischbach',
    countrycode: 'DE',
  },
  {
    postalcode: '71560',
    city: 'Sulzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '71560',
    city: 'Unterfischbach',
    countrycode: 'DE',
  },
  {
    postalcode: '71563',
    city: 'Affalterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '71563',
    city: 'Siegelhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '71566',
    city: 'Althütte',
    countrycode: 'DE',
  },
  {
    postalcode: '71570',
    city: 'Katharinenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '71570',
    city: 'Oppenweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '71573',
    city: 'Allmersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '71576',
    city: 'Burgstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '71577',
    city: 'Großerlach',
    countrycode: 'DE',
  },
  {
    postalcode: '71577',
    city: 'Hals',
    countrycode: 'DE',
  },
  {
    postalcode: '71579',
    city: 'Spiegelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '71579',
    city: 'Warthof',
    countrycode: 'DE',
  },
  {
    postalcode: '71634',
    city: 'Ludwigsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '71636',
    city: 'Ludwigsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '71638',
    city: 'Ludwigsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '71640',
    city: 'Ludwigsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '71642',
    city: 'Ludwigsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '71665',
    city: 'Vaihingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71672',
    city: 'Makenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '71672',
    city: 'Marbach',
    countrycode: 'DE',
  },
  {
    postalcode: '71679',
    city: 'Asperg',
    countrycode: 'DE',
  },
  {
    postalcode: '71686',
    city: 'Remseck',
    countrycode: 'DE',
  },
  {
    postalcode: '71691',
    city: 'Freiberg',
    countrycode: 'DE',
  },
  {
    postalcode: '71696',
    city: 'Möglingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71701',
    city: 'Schwieberdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71706',
    city: 'Hardthof',
    countrycode: 'DE',
  },
  {
    postalcode: '71706',
    city: 'Markgröningen',
    countrycode: 'DE',
  },
  {
    postalcode: '71711',
    city: 'Hinterbirkenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '71711',
    city: 'Murr',
    countrycode: 'DE',
  },
  {
    postalcode: '71711',
    city: 'Steinheim',
    countrycode: 'DE',
  },
  {
    postalcode: '71717',
    city: 'Beilstein',
    countrycode: 'DE',
  },
  {
    postalcode: '71720',
    city: 'Obere Ölmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '71720',
    city: 'Oberstenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '71720',
    city: 'Untere Ölmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '71723',
    city: 'Großbottwar',
    countrycode: 'DE',
  },
  {
    postalcode: '71726',
    city: 'Benningen',
    countrycode: 'DE',
  },
  {
    postalcode: '71729',
    city: 'Erdmannhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '71729',
    city: 'Rundsmühlhof',
    countrycode: 'DE',
  },
  {
    postalcode: '71732',
    city: 'Lehenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '71732',
    city: 'Silberhälden',
    countrycode: 'DE',
  },
  {
    postalcode: '71732',
    city: 'Tamm',
    countrycode: 'DE',
  },
  {
    postalcode: '71735',
    city: 'Eberdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '71737',
    city: 'Kirchberg',
    countrycode: 'DE',
  },
  {
    postalcode: '71739',
    city: 'Oberriexingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72070',
    city: 'Hohenentringen',
    countrycode: 'DE',
  },
  {
    postalcode: '72070',
    city: 'Tübingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72072',
    city: 'Tübingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72074',
    city: 'Tübingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72076',
    city: 'Tübingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72108',
    city: 'Rottenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '72116',
    city: 'Mössingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72119',
    city: 'Ammerbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '72124',
    city: 'Pliezhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '72127',
    city: 'Kusterdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72131',
    city: 'Ofterdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72135',
    city: 'Dettenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '72138',
    city: 'Im Hengstrain',
    countrycode: 'DE',
  },
  {
    postalcode: '72138',
    city: 'Kirchentellinsfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '72141',
    city: 'Walddorfhäslach',
    countrycode: 'DE',
  },
  {
    postalcode: '72144',
    city: 'Dußlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72145',
    city: 'Hirrlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72147',
    city: 'Nehren',
    countrycode: 'DE',
  },
  {
    postalcode: '72147',
    city: 'Schlattwiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '72149',
    city: 'Neustetten',
    countrycode: 'DE',
  },
  {
    postalcode: '72160',
    city: 'Horb',
    countrycode: 'DE',
  },
  {
    postalcode: '72160',
    city: 'Oberer Eutinger Talhof',
    countrycode: 'DE',
  },
  {
    postalcode: '72160',
    city: 'Oberhof',
    countrycode: 'DE',
  },
  {
    postalcode: '72172',
    city: 'Sulz',
    countrycode: 'DE',
  },
  {
    postalcode: '72175',
    city: 'Dornhan',
    countrycode: 'DE',
  },
  {
    postalcode: '72178',
    city: 'Waldachtal',
    countrycode: 'DE',
  },
  {
    postalcode: '72181',
    city: 'Starzach',
    countrycode: 'DE',
  },
  {
    postalcode: '72184',
    city: 'Eutingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72186',
    city: 'Empfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72186',
    city: 'Weiherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '72189',
    city: 'Vöhringen',
    countrycode: 'DE',
  },
  {
    postalcode: '72202',
    city: 'Nagold',
    countrycode: 'DE',
  },
  {
    postalcode: '72213',
    city: 'Altensteig',
    countrycode: 'DE',
  },
  {
    postalcode: '72218',
    city: 'Wildberg',
    countrycode: 'DE',
  },
  {
    postalcode: '72221',
    city: 'Haiterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '72224',
    city: 'Ebhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '72226',
    city: 'Simmersfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '72227',
    city: 'Egenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '72229',
    city: 'Rohrdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '72250',
    city: 'Freudenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '72250',
    city: 'Zuflucht',
    countrycode: 'DE',
  },
  {
    postalcode: '72270',
    city: 'Baiersbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '72275',
    city: 'Alpirsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '72280',
    city: 'Dornstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '72285',
    city: 'Pfalzgrafenweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '72290',
    city: 'Loßburg',
    countrycode: 'DE',
  },
  {
    postalcode: '72290',
    city: 'Schwenkenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '72291',
    city: 'Betzweiler-Wälde',
    countrycode: 'DE',
  },
  {
    postalcode: '72291',
    city: 'Salzenweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '72293',
    city: 'Glatten',
    countrycode: 'DE',
  },
  {
    postalcode: '72294',
    city: 'Grömbach',
    countrycode: 'DE',
  },
  {
    postalcode: '72296',
    city: 'Schopfloch',
    countrycode: 'DE',
  },
  {
    postalcode: '72297',
    city: 'Pfaffenstube',
    countrycode: 'DE',
  },
  {
    postalcode: '72297',
    city: 'Seewald',
    countrycode: 'DE',
  },
  {
    postalcode: '72297',
    city: 'Völmlesmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '72299',
    city: 'Wörnersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '72299',
    city: 'Zinsbachmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '72336',
    city: 'Balingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72348',
    city: 'Rosenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '72351',
    city: 'Geislingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72355',
    city: 'Schömberg',
    countrycode: 'DE',
  },
  {
    postalcode: '72356',
    city: 'Dautmergen',
    countrycode: 'DE',
  },
  {
    postalcode: '72358',
    city: 'Dormettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72359',
    city: 'Dotternhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '72361',
    city: 'Hausen',
    countrycode: 'DE',
  },
  {
    postalcode: '72362',
    city: 'Nusplingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72364',
    city: 'Obernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '72365',
    city: 'Ratshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '72367',
    city: 'Weilen',
    countrycode: 'DE',
  },
  {
    postalcode: '72369',
    city: 'Zimmern',
    countrycode: 'DE',
  },
  {
    postalcode: '72379',
    city: 'Burg Hohenzollern',
    countrycode: 'DE',
  },
  {
    postalcode: '72379',
    city: 'Hechingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72393',
    city: 'Burladingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72401',
    city: 'Haigerloch',
    countrycode: 'DE',
  },
  {
    postalcode: '72406',
    city: 'Bisingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72411',
    city: 'Bodelshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '72414',
    city: 'Rangendingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72415',
    city: 'Grosselfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72417',
    city: 'Jungingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72419',
    city: 'Lieshöfe',
    countrycode: 'DE',
  },
  {
    postalcode: '72419',
    city: 'Neufra',
    countrycode: 'DE',
  },
  {
    postalcode: '72419',
    city: 'Stollbeck',
    countrycode: 'DE',
  },
  {
    postalcode: '72458',
    city: 'Albstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '72458',
    city: 'Linderhof',
    countrycode: 'DE',
  },
  {
    postalcode: '72459',
    city: 'Albstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '72461',
    city: 'Albstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '72469',
    city: 'Meßstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '72474',
    city: 'Winterlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72475',
    city: 'Bitz',
    countrycode: 'DE',
  },
  {
    postalcode: '72477',
    city: 'Schwenningen',
    countrycode: 'DE',
  },
  {
    postalcode: '72479',
    city: 'Straßberg',
    countrycode: 'DE',
  },
  {
    postalcode: '72488',
    city: 'Sigmaringen',
    countrycode: 'DE',
  },
  {
    postalcode: '72501',
    city: 'Gammertingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72501',
    city: 'Pistre',
    countrycode: 'DE',
  },
  {
    postalcode: '72505',
    city: 'Krauchenwies',
    countrycode: 'DE',
  },
  {
    postalcode: '72510',
    city: 'Stetten',
    countrycode: 'DE',
  },
  {
    postalcode: '72511',
    city: 'Bingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72513',
    city: 'Hettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72514',
    city: 'Inzigkofen',
    countrycode: 'DE',
  },
  {
    postalcode: '72516',
    city: 'Scheer',
    countrycode: 'DE',
  },
  {
    postalcode: '72517',
    city: 'Sigmaringendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '72519',
    city: 'Veringenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '72525',
    city: 'Gutsbezirk Münsingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72525',
    city: 'Münsingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72531',
    city: 'Hohenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '72532',
    city: 'Gomadingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72534',
    city: 'Hayingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72535',
    city: 'Heroldstatt',
    countrycode: 'DE',
  },
  {
    postalcode: '72537',
    city: 'Mehrstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '72539',
    city: 'Pfronstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '72555',
    city: 'Metzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72574',
    city: 'Bad Urach',
    countrycode: 'DE',
  },
  {
    postalcode: '72581',
    city: 'Dettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72582',
    city: 'Grabenstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '72584',
    city: 'Hülben',
    countrycode: 'DE',
  },
  {
    postalcode: '72585',
    city: 'Riederich',
    countrycode: 'DE',
  },
  {
    postalcode: '72587',
    city: 'Römerstein',
    countrycode: 'DE',
  },
  {
    postalcode: '72589',
    city: 'Westerheim',
    countrycode: 'DE',
  },
  {
    postalcode: '72622',
    city: 'Nürtingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72631',
    city: 'Aichtal',
    countrycode: 'DE',
  },
  {
    postalcode: '72636',
    city: 'Frickenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '72639',
    city: 'Neuffen',
    countrycode: 'DE',
  },
  {
    postalcode: '72644',
    city: 'Oberboihingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72649',
    city: 'Wolfschlugen',
    countrycode: 'DE',
  },
  {
    postalcode: '72654',
    city: 'Neckartenzlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72655',
    city: 'Altdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '72657',
    city: 'Altenriet',
    countrycode: 'DE',
  },
  {
    postalcode: '72658',
    city: 'Bempflingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72660',
    city: 'Beuren',
    countrycode: 'DE',
  },
  {
    postalcode: '72661',
    city: 'Grafenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '72663',
    city: 'Großbettlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72664',
    city: 'Kohlberg',
    countrycode: 'DE',
  },
  {
    postalcode: '72666',
    city: 'Neckartailfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72667',
    city: 'Schlaitdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '72669',
    city: 'Unterensingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72760',
    city: 'Reutlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72762',
    city: 'Reutlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72764',
    city: 'Reutlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72766',
    city: 'Reutlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72768',
    city: 'Reutlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72770',
    city: 'Reutlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72793',
    city: 'Pfullingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72800',
    city: 'Eningen',
    countrycode: 'DE',
  },
  {
    postalcode: '72805',
    city: 'Lichtenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '72810',
    city: 'Gomaringen',
    countrycode: 'DE',
  },
  {
    postalcode: '72813',
    city: 'Oberer Lindenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '72813',
    city: 'St. Johann',
    countrycode: 'DE',
  },
  {
    postalcode: '72818',
    city: 'Trochtelfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '72820',
    city: 'Sonnenbühl',
    countrycode: 'DE',
  },
  {
    postalcode: '72827',
    city: 'Wannweil',
    countrycode: 'DE',
  },
  {
    postalcode: '72829',
    city: 'Engstingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73033',
    city: 'Göppingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73035',
    city: 'Göppingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73037',
    city: 'Eitleshof',
    countrycode: 'DE',
  },
  {
    postalcode: '73037',
    city: 'Göppingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73037',
    city: 'Iltishof',
    countrycode: 'DE',
  },
  {
    postalcode: '73054',
    city: 'Eislingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73054',
    city: 'Näherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73061',
    city: 'Ebersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73066',
    city: 'Uhingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73072',
    city: 'Donzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '73072',
    city: 'Frauenholz',
    countrycode: 'DE',
  },
  {
    postalcode: '73072',
    city: 'Kratzerhöfle',
    countrycode: 'DE',
  },
  {
    postalcode: '73072',
    city: 'Schurrenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73079',
    city: 'Baierhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73079',
    city: 'Süßen',
    countrycode: 'DE',
  },
  {
    postalcode: '73084',
    city: 'Salach',
    countrycode: 'DE',
  },
  {
    postalcode: '73087',
    city: 'Boll',
    countrycode: 'DE',
  },
  {
    postalcode: '73087',
    city: 'Erlenwasen',
    countrycode: 'DE',
  },
  {
    postalcode: '73092',
    city: 'Heiningen',
    countrycode: 'DE',
  },
  {
    postalcode: '73095',
    city: 'Albershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '73098',
    city: 'Rechberghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '73099',
    city: 'Adelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '73101',
    city: 'Aichelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '73102',
    city: 'Birenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73104',
    city: 'Börtlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73105',
    city: 'Dürnau',
    countrycode: 'DE',
  },
  {
    postalcode: '73107',
    city: 'Eschenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73107',
    city: 'Haagwiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '73107',
    city: 'Lotenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '73108',
    city: 'Gammelshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '73110',
    city: 'Hattenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '73111',
    city: 'Lauterstein',
    countrycode: 'DE',
  },
  {
    postalcode: '73113',
    city: 'Oberer Etzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '73113',
    city: 'Ottenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73114',
    city: 'Schlat',
    countrycode: 'DE',
  },
  {
    postalcode: '73116',
    city: 'Krettenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73116',
    city: 'Schützenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73116',
    city: 'Wäschenbeuren',
    countrycode: 'DE',
  },
  {
    postalcode: '73117',
    city: 'Wangen',
    countrycode: 'DE',
  },
  {
    postalcode: '73119',
    city: 'Zell',
    countrycode: 'DE',
  },
  {
    postalcode: '73207',
    city: 'Plochingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73230',
    city: 'Kirchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '73235',
    city: 'Kaltenwanghof',
    countrycode: 'DE',
  },
  {
    postalcode: '73235',
    city: 'Weilheim',
    countrycode: 'DE',
  },
  {
    postalcode: '73240',
    city: 'Wendlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73249',
    city: 'Berghof',
    countrycode: 'DE',
  },
  {
    postalcode: '73249',
    city: 'Erlenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73249',
    city: 'Wernau',
    countrycode: 'DE',
  },
  {
    postalcode: '73252',
    city: 'Lenningen',
    countrycode: 'DE',
  },
  {
    postalcode: '73257',
    city: 'Köngen',
    countrycode: 'DE',
  },
  {
    postalcode: '73262',
    city: 'Reichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73265',
    city: 'Dettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73266',
    city: 'Bissingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73266',
    city: 'Diepoldsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '73266',
    city: 'Engelhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73266',
    city: 'Torfgrube',
    countrycode: 'DE',
  },
  {
    postalcode: '73268',
    city: 'Erkenbrechtsweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '73269',
    city: 'Hochdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '73271',
    city: 'Holzmaden',
    countrycode: 'DE',
  },
  {
    postalcode: '73272',
    city: 'Neidlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73274',
    city: 'Notzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73275',
    city: 'Ohmden',
    countrycode: 'DE',
  },
  {
    postalcode: '73277',
    city: 'Owen',
    countrycode: 'DE',
  },
  {
    postalcode: '73312',
    city: 'Berneck',
    countrycode: 'DE',
  },
  {
    postalcode: '73312',
    city: 'Geislingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73312',
    city: 'Obere Roggenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '73312',
    city: 'Schonterhöhe',
    countrycode: 'DE',
  },
  {
    postalcode: '73326',
    city: 'Deggingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73329',
    city: 'Kuchen',
    countrycode: 'DE',
  },
  {
    postalcode: '73333',
    city: 'Gingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73337',
    city: 'Bad Überkingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73340',
    city: 'Amstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '73342',
    city: 'Bad Ditzenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73344',
    city: 'Gruibingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73345',
    city: 'Drackenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '73345',
    city: 'Geislinger Weg',
    countrycode: 'DE',
  },
  {
    postalcode: '73345',
    city: 'Großmannshof',
    countrycode: 'DE',
  },
  {
    postalcode: '73345',
    city: 'Hohenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '73347',
    city: 'Mühlhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '73349',
    city: 'Eselhöfe',
    countrycode: 'DE',
  },
  {
    postalcode: '73349',
    city: 'Kölleshof',
    countrycode: 'DE',
  },
  {
    postalcode: '73349',
    city: 'Todsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '73349',
    city: 'Wiesensteig',
    countrycode: 'DE',
  },
  {
    postalcode: '73430',
    city: 'Aalen',
    countrycode: 'DE',
  },
  {
    postalcode: '73431',
    city: 'Aalen',
    countrycode: 'DE',
  },
  {
    postalcode: '73432',
    city: 'Aalen',
    countrycode: 'DE',
  },
  {
    postalcode: '73433',
    city: 'Aalen',
    countrycode: 'DE',
  },
  {
    postalcode: '73434',
    city: 'Aalen',
    countrycode: 'DE',
  },
  {
    postalcode: '73434',
    city: 'Untersiegenbühl',
    countrycode: 'DE',
  },
  {
    postalcode: '73441',
    city: 'Bopfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73441',
    city: 'Heerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73441',
    city: 'Hohenlohe',
    countrycode: 'DE',
  },
  {
    postalcode: '73441',
    city: 'Osterholz',
    countrycode: 'DE',
  },
  {
    postalcode: '73447',
    city: 'Oberkochen',
    countrycode: 'DE',
  },
  {
    postalcode: '73450',
    city: 'Hochstatter Hof',
    countrycode: 'DE',
  },
  {
    postalcode: '73450',
    city: 'Neresheim',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Abtsgmünd',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Algishofen',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Brandhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Fach',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Fuchshäusle',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Langenhalde',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Mühlholz',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Obersiegenbühl',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Rötenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Schlauchhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Schleifhäusle',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Suhhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Wahlenhalden',
    countrycode: 'DE',
  },
  {
    postalcode: '73453',
    city: 'Zanken',
    countrycode: 'DE',
  },
  {
    postalcode: '73457',
    city: 'Essingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73460',
    city: 'Hüttlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73463',
    city: 'Westhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '73466',
    city: 'Lauchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '73467',
    city: 'Kirchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '73469',
    city: 'Riesbürg',
    countrycode: 'DE',
  },
  {
    postalcode: '73479',
    city: 'Adlersteige',
    countrycode: 'DE',
  },
  {
    postalcode: '73479',
    city: 'Ellwangen',
    countrycode: 'DE',
  },
  {
    postalcode: '73485',
    city: 'Ellrichsbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '73485',
    city: 'Unterschneidheim',
    countrycode: 'DE',
  },
  {
    postalcode: '73486',
    city: 'Adelmannsfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '73488',
    city: 'Ellenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '73489',
    city: 'Grunbachsägmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '73489',
    city: 'Henkensägmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '73489',
    city: 'Jagstzell',
    countrycode: 'DE',
  },
  {
    postalcode: '73489',
    city: 'Reifenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73489',
    city: 'Reifensägmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '73489',
    city: 'Sperrhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73489',
    city: 'Ölmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '73491',
    city: 'Neuler',
    countrycode: 'DE',
  },
  {
    postalcode: '73492',
    city: 'Rainau',
    countrycode: 'DE',
  },
  {
    postalcode: '73494',
    city: 'Belzhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73494',
    city: 'Fleckenbacher Sägmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '73494',
    city: 'Gauchshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '73494',
    city: 'Rosenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '73495',
    city: 'Stödtlen',
    countrycode: 'DE',
  },
  {
    postalcode: '73497',
    city: 'Tannhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '73499',
    city: 'Wört',
    countrycode: 'DE',
  },
  {
    postalcode: '73525',
    city: 'Schwäbisch Gmünd',
    countrycode: 'DE',
  },
  {
    postalcode: '73527',
    city: 'Schwäbisch Gmünd',
    countrycode: 'DE',
  },
  {
    postalcode: '73527',
    city: 'Täferrot',
    countrycode: 'DE',
  },
  {
    postalcode: '73529',
    city: 'Bärenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73529',
    city: 'Pfeilhalde',
    countrycode: 'DE',
  },
  {
    postalcode: '73529',
    city: 'Saurenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73529',
    city: 'Schwäbisch Gmünd',
    countrycode: 'DE',
  },
  {
    postalcode: '73540',
    city: 'Heubach',
    countrycode: 'DE',
  },
  {
    postalcode: '73547',
    city: 'Beutenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '73547',
    city: 'Lorch',
    countrycode: 'DE',
  },
  {
    postalcode: '73550',
    city: 'Hummelshalden',
    countrycode: 'DE',
  },
  {
    postalcode: '73550',
    city: 'Waldstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '73553',
    city: 'Alfdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '73553',
    city: 'Birkhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73553',
    city: 'Haselbach-Söldhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '73553',
    city: 'Schillinghof',
    countrycode: 'DE',
  },
  {
    postalcode: '73553',
    city: 'Voggenmühlhöfle',
    countrycode: 'DE',
  },
  {
    postalcode: '73553',
    city: 'Ölmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '73557',
    city: 'Mutlangen',
    countrycode: 'DE',
  },
  {
    postalcode: '73560',
    city: 'Böbingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73563',
    city: 'Mögglingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73563',
    city: 'Sixenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73565',
    city: 'Mooswiese',
    countrycode: 'DE',
  },
  {
    postalcode: '73565',
    city: 'Spraitbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73565',
    city: 'Stutzenklinge',
    countrycode: 'DE',
  },
  {
    postalcode: '73566',
    city: 'Bartholomä',
    countrycode: 'DE',
  },
  {
    postalcode: '73566',
    city: 'Bibersohl',
    countrycode: 'DE',
  },
  {
    postalcode: '73566',
    city: 'Birkenteich',
    countrycode: 'DE',
  },
  {
    postalcode: '73566',
    city: 'Irmannsweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '73568',
    city: 'Durlangen',
    countrycode: 'DE',
  },
  {
    postalcode: '73568',
    city: 'Leinhäusle',
    countrycode: 'DE',
  },
  {
    postalcode: '73568',
    city: 'Weggen-Ziegelhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '73569',
    city: 'Bräunlesrain',
    countrycode: 'DE',
  },
  {
    postalcode: '73569',
    city: 'Buchhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73569',
    city: 'Eschach',
    countrycode: 'DE',
  },
  {
    postalcode: '73569',
    city: 'Obergröningen',
    countrycode: 'DE',
  },
  {
    postalcode: '73569',
    city: 'Reute',
    countrycode: 'DE',
  },
  {
    postalcode: '73571',
    city: 'Göggingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73572',
    city: 'Heuchlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73574',
    city: 'Iggingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73574',
    city: 'Mühlhölzle',
    countrycode: 'DE',
  },
  {
    postalcode: '73574',
    city: 'Pfaffenhäusle',
    countrycode: 'DE',
  },
  {
    postalcode: '73575',
    city: 'Horn',
    countrycode: 'DE',
  },
  {
    postalcode: '73575',
    city: 'Horner Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '73575',
    city: 'Kleemeisterei',
    countrycode: 'DE',
  },
  {
    postalcode: '73575',
    city: 'Leinzell',
    countrycode: 'DE',
  },
  {
    postalcode: '73575',
    city: 'Mulfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73577',
    city: 'Buchhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73577',
    city: 'Heiligenbruck',
    countrycode: 'DE',
  },
  {
    postalcode: '73577',
    city: 'Koppenkreut',
    countrycode: 'DE',
  },
  {
    postalcode: '73577',
    city: 'Ruppertshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '73577',
    city: 'Schilpenbühl',
    countrycode: 'DE',
  },
  {
    postalcode: '73577',
    city: 'Utzstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '73579',
    city: 'Schechingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73614',
    city: 'Schorndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '73614',
    city: 'Wellingshof',
    countrycode: 'DE',
  },
  {
    postalcode: '73630',
    city: 'Remshalden',
    countrycode: 'DE',
  },
  {
    postalcode: '73635',
    city: 'Obersteinenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '73635',
    city: 'Rudersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '73642',
    city: 'Eibenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73642',
    city: 'Klingenmühlhöfle',
    countrycode: 'DE',
  },
  {
    postalcode: '73642',
    city: 'Köshof',
    countrycode: 'DE',
  },
  {
    postalcode: '73642',
    city: 'Schautenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73642',
    city: 'Welzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '73650',
    city: 'Winterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73655',
    city: 'Bärenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73655',
    city: 'Eulenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73655',
    city: 'Haldenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73655',
    city: 'Haselhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73655',
    city: 'Ilgenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '73655',
    city: 'Pfahlbronner Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '73655',
    city: 'Plüderhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '73655',
    city: 'Schenkhöfle',
    countrycode: 'DE',
  },
  {
    postalcode: '73660',
    city: 'Urbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73663',
    city: 'Berglen',
    countrycode: 'DE',
  },
  {
    postalcode: '73666',
    city: 'Baltmannsweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '73667',
    city: 'Ebnisee',
    countrycode: 'DE',
  },
  {
    postalcode: '73667',
    city: 'Kaisersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73669',
    city: 'Lichtenwald',
    countrycode: 'DE',
  },
  {
    postalcode: '73728',
    city: 'Esslingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73730',
    city: 'Esslingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73732',
    city: 'Esslingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73733',
    city: 'Esslingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73734',
    city: 'Esslingen',
    countrycode: 'DE',
  },
  {
    postalcode: '73760',
    city: 'Ostfildern',
    countrycode: 'DE',
  },
  {
    postalcode: '73765',
    city: 'Neuhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '73770',
    city: 'Denkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '73773',
    city: 'Aichwald',
    countrycode: 'DE',
  },
  {
    postalcode: '73776',
    city: 'Altbach',
    countrycode: 'DE',
  },
  {
    postalcode: '73779',
    city: 'Deizisau',
    countrycode: 'DE',
  },
  {
    postalcode: '74072',
    city: 'Heilbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '74074',
    city: 'Heilbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '74076',
    city: 'Heilbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '74078',
    city: 'Heilbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '74080',
    city: 'Heilbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '74081',
    city: 'Heilbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '74172',
    city: 'Neckarsulm',
    countrycode: 'DE',
  },
  {
    postalcode: '74177',
    city: 'Bad Friedrichshall',
    countrycode: 'DE',
  },
  {
    postalcode: '74182',
    city: 'Obersulm',
    countrycode: 'DE',
  },
  {
    postalcode: '74189',
    city: 'Weinsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '74193',
    city: 'Schwaigern',
    countrycode: 'DE',
  },
  {
    postalcode: '74196',
    city: 'Grollenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '74196',
    city: 'Neuenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '74199',
    city: 'Untergruppenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74206',
    city: 'Bad Wimpfen',
    countrycode: 'DE',
  },
  {
    postalcode: '74211',
    city: 'Leingarten',
    countrycode: 'DE',
  },
  {
    postalcode: '74214',
    city: 'Schöntal',
    countrycode: 'DE',
  },
  {
    postalcode: '74219',
    city: 'Möckmühl',
    countrycode: 'DE',
  },
  {
    postalcode: '74223',
    city: 'Flein',
    countrycode: 'DE',
  },
  {
    postalcode: '74223',
    city: 'Talheimer Hof',
    countrycode: 'DE',
  },
  {
    postalcode: '74226',
    city: 'Nordheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74229',
    city: 'Oedheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74232',
    city: 'Abstatt',
    countrycode: 'DE',
  },
  {
    postalcode: '74235',
    city: 'Erlenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74238',
    city: 'Krautheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74239',
    city: 'Hardthausen',
    countrycode: 'DE',
  },
  {
    postalcode: '74243',
    city: 'Langenbrettach',
    countrycode: 'DE',
  },
  {
    postalcode: '74245',
    city: 'Löwenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '74246',
    city: 'Eberstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '74248',
    city: 'Ellhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '74249',
    city: 'Buchhof, Gem Hardthausen',
    countrycode: 'DE',
  },
  {
    postalcode: '74249',
    city: 'Buchhof, Gem Öhringen',
    countrycode: 'DE',
  },
  {
    postalcode: '74249',
    city: 'Jagsthausen',
    countrycode: 'DE',
  },
  {
    postalcode: '74249',
    city: 'Trautenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '74251',
    city: 'Lehrensteinsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '74252',
    city: 'Massenbachhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '74254',
    city: 'Offenau',
    countrycode: 'DE',
  },
  {
    postalcode: '74255',
    city: 'Roigheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74257',
    city: 'Untereisesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74259',
    city: 'Widdern',
    countrycode: 'DE',
  },
  {
    postalcode: '74321',
    city: 'Bietigheim-Bissingen',
    countrycode: 'DE',
  },
  {
    postalcode: '74336',
    city: 'Brackenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74343',
    city: 'Sachsenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74348',
    city: 'Lauffen',
    countrycode: 'DE',
  },
  {
    postalcode: '74354',
    city: 'Besigheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74357',
    city: 'Bellevue',
    countrycode: 'DE',
  },
  {
    postalcode: '74357',
    city: 'Bönnigheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74360',
    city: 'Ilsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '74360',
    city: 'Pfahlhof',
    countrycode: 'DE',
  },
  {
    postalcode: '74363',
    city: 'Güglingen',
    countrycode: 'DE',
  },
  {
    postalcode: '74366',
    city: 'Kirchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74369',
    city: 'Löchgau',
    countrycode: 'DE',
  },
  {
    postalcode: '74372',
    city: 'Sersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74374',
    city: 'Zaberfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '74376',
    city: 'Gemmrigheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74379',
    city: 'Ingersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74382',
    city: 'Neckarwestheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74385',
    city: 'Pleidelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74388',
    city: 'Talheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74389',
    city: 'Cleebronn',
    countrycode: 'DE',
  },
  {
    postalcode: '74391',
    city: 'Erligheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74392',
    city: 'Freudental',
    countrycode: 'DE',
  },
  {
    postalcode: '74394',
    city: 'Hessigheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74394',
    city: 'Schreyerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '74395',
    city: 'Mundelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74397',
    city: 'Pfaffenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '74399',
    city: 'Walheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74405',
    city: 'Gaildorf',
    countrycode: 'DE',
  },
  {
    postalcode: '74417',
    city: 'Gschwend',
    countrycode: 'DE',
  },
  {
    postalcode: '74417',
    city: 'Höllhof',
    countrycode: 'DE',
  },
  {
    postalcode: '74417',
    city: 'Jakobsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '74420',
    city: 'Oberrot',
    countrycode: 'DE',
  },
  {
    postalcode: '74423',
    city: 'Obersontheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74424',
    city: 'Bühlertann',
    countrycode: 'DE',
  },
  {
    postalcode: '74426',
    city: 'Bühlerzell',
    countrycode: 'DE',
  },
  {
    postalcode: '74427',
    city: 'Fichtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '74429',
    city: 'Sulzbach-Laufen',
    countrycode: 'DE',
  },
  {
    postalcode: '74523',
    city: 'Burgbretzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '74523',
    city: 'Bühlerzimmern',
    countrycode: 'DE',
  },
  {
    postalcode: '74523',
    city: 'Einkorn',
    countrycode: 'DE',
  },
  {
    postalcode: '74523',
    city: 'Scherbenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '74523',
    city: 'Schwäbisch Hall',
    countrycode: 'DE',
  },
  {
    postalcode: '74523',
    city: 'Traubenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '74532',
    city: 'Buch',
    countrycode: 'DE',
  },
  {
    postalcode: '74532',
    city: 'Ilshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '74532',
    city: 'Landturm',
    countrycode: 'DE',
  },
  {
    postalcode: '74535',
    city: 'Mainhardt',
    countrycode: 'DE',
  },
  {
    postalcode: '74538',
    city: 'Rosengarten',
    countrycode: 'DE',
  },
  {
    postalcode: '74541',
    city: 'Vellberg',
    countrycode: 'DE',
  },
  {
    postalcode: '74542',
    city: 'Braunsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74544',
    city: 'Michelbach an der Bilz',
    countrycode: 'DE',
  },
  {
    postalcode: '74545',
    city: 'Hinterziegelhalden',
    countrycode: 'DE',
  },
  {
    postalcode: '74545',
    city: 'Michelfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '74547',
    city: 'Untermünkheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74549',
    city: 'Wolpertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '74564',
    city: 'Auhof',
    countrycode: 'DE',
  },
  {
    postalcode: '74564',
    city: 'Crailsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74564',
    city: 'Heldenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '74564',
    city: 'Schummhof',
    countrycode: 'DE',
  },
  {
    postalcode: '74572',
    city: 'Blaufelden',
    countrycode: 'DE',
  },
  {
    postalcode: '74575',
    city: 'Schrozberg',
    countrycode: 'DE',
  },
  {
    postalcode: '74579',
    city: 'Buchmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '74579',
    city: 'Fichtenau',
    countrycode: 'DE',
  },
  {
    postalcode: '74579',
    city: 'Finkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '74579',
    city: 'Finkenhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '74579',
    city: 'Hahnenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '74579',
    city: 'Neumühle',
    countrycode: 'DE',
  },
  {
    postalcode: '74579',
    city: 'Ratzensägmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '74582',
    city: 'Gerabronn',
    countrycode: 'DE',
  },
  {
    postalcode: '74585',
    city: 'Horschhof',
    countrycode: 'DE',
  },
  {
    postalcode: '74585',
    city: 'Rot am See',
    countrycode: 'DE',
  },
  {
    postalcode: '74586',
    city: 'Frankenhardt',
    countrycode: 'DE',
  },
  {
    postalcode: '74589',
    city: 'Satteldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '74592',
    city: 'Kirchberg',
    countrycode: 'DE',
  },
  {
    postalcode: '74594',
    city: 'Gumpenweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '74594',
    city: 'Kreßberg',
    countrycode: 'DE',
  },
  {
    postalcode: '74595',
    city: 'Langenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '74597',
    city: 'Appensee',
    countrycode: 'DE',
  },
  {
    postalcode: '74597',
    city: 'Eckarrot',
    countrycode: 'DE',
  },
  {
    postalcode: '74597',
    city: 'Eulenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '74597',
    city: 'Mittelmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '74597',
    city: 'Riegersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74597',
    city: 'Stimpfach',
    countrycode: 'DE',
  },
  {
    postalcode: '74599',
    city: 'Wallhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '74613',
    city: 'Öhringen',
    countrycode: 'DE',
  },
  {
    postalcode: '74626',
    city: 'Bretzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '74629',
    city: 'Pfedelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74632',
    city: 'Haberhof',
    countrycode: 'DE',
  },
  {
    postalcode: '74632',
    city: 'Neuenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '74632',
    city: 'Orbachshof',
    countrycode: 'DE',
  },
  {
    postalcode: '74635',
    city: 'Kupferzell',
    countrycode: 'DE',
  },
  {
    postalcode: '74638',
    city: 'Waldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '74639',
    city: 'Schießhof',
    countrycode: 'DE',
  },
  {
    postalcode: '74639',
    city: 'Zweiflingen',
    countrycode: 'DE',
  },
  {
    postalcode: '74653',
    city: 'Ingelfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '74653',
    city: 'Künzelsau',
    countrycode: 'DE',
  },
  {
    postalcode: '74670',
    city: 'Forchtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '74673',
    city: 'Mulfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '74676',
    city: 'Niedernhall',
    countrycode: 'DE',
  },
  {
    postalcode: '74677',
    city: 'Dörzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74679',
    city: 'Weißbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74706',
    city: 'Osterburken',
    countrycode: 'DE',
  },
  {
    postalcode: '74722',
    city: 'Buchen',
    countrycode: 'DE',
  },
  {
    postalcode: '74731',
    city: 'Storchhof',
    countrycode: 'DE',
  },
  {
    postalcode: '74731',
    city: 'Walldürn',
    countrycode: 'DE',
  },
  {
    postalcode: '74736',
    city: 'Hardheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74740',
    city: 'Adelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74743',
    city: 'Seckach',
    countrycode: 'DE',
  },
  {
    postalcode: '74744',
    city: 'Ahorn',
    countrycode: 'DE',
  },
  {
    postalcode: '74746',
    city: 'Höpfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '74747',
    city: 'Ravenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '74749',
    city: 'Rosenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '74821',
    city: 'Mosbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74831',
    city: 'Gundelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74834',
    city: 'Elztal',
    countrycode: 'DE',
  },
  {
    postalcode: '74834',
    city: 'Heidersbacher Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '74838',
    city: 'Limbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74842',
    city: 'Billigheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74847',
    city: 'Obrigheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74850',
    city: 'Schefflenz',
    countrycode: 'DE',
  },
  {
    postalcode: '74855',
    city: 'Haßmersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74858',
    city: 'Aglasterhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '74861',
    city: 'Neudenau',
    countrycode: 'DE',
  },
  {
    postalcode: '74862',
    city: 'Binau',
    countrycode: 'DE',
  },
  {
    postalcode: '74864',
    city: 'Fahrenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74865',
    city: 'Neckarzimmern',
    countrycode: 'DE',
  },
  {
    postalcode: '74867',
    city: 'Neunkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '74869',
    city: 'Schwarzach',
    countrycode: 'DE',
  },
  {
    postalcode: '74889',
    city: 'Sinsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74906',
    city: 'Bad Rappenau',
    countrycode: 'DE',
  },
  {
    postalcode: '74909',
    city: 'Meckesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74912',
    city: 'Kirchardt',
    countrycode: 'DE',
  },
  {
    postalcode: '74915',
    city: 'Waibstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '74918',
    city: 'Angelbachtal',
    countrycode: 'DE',
  },
  {
    postalcode: '74921',
    city: 'Helmstadt-Bargen',
    countrycode: 'DE',
  },
  {
    postalcode: '74924',
    city: 'Neckarbischofsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '74925',
    city: 'Epfenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74927',
    city: 'Eschelbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '74928',
    city: 'Hüffenhardt',
    countrycode: 'DE',
  },
  {
    postalcode: '74930',
    city: 'Ittlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '74931',
    city: 'Lobbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74933',
    city: 'Neidenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '74934',
    city: 'Reichartshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '74936',
    city: 'Siegelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74937',
    city: 'Spechbach',
    countrycode: 'DE',
  },
  {
    postalcode: '74939',
    city: 'Zuzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '75015',
    city: 'Bretten',
    countrycode: 'DE',
  },
  {
    postalcode: '75031',
    city: 'Eppingen',
    countrycode: 'DE',
  },
  {
    postalcode: '75031',
    city: 'Gießhübelmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '75031',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '75038',
    city: 'Oberderdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '75045',
    city: 'Walzbachtal',
    countrycode: 'DE',
  },
  {
    postalcode: '75050',
    city: 'Gemmingen',
    countrycode: 'DE',
  },
  {
    postalcode: '75053',
    city: 'Gondelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '75056',
    city: 'Sulzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '75057',
    city: 'Kürnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '75059',
    city: 'Egonmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '75059',
    city: 'Zaisenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '75172',
    city: 'Pforzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '75173',
    city: 'Pforzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '75175',
    city: 'Pforzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '75177',
    city: 'Katharinenthalerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '75177',
    city: 'Pforzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '75179',
    city: 'Pforzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '75180',
    city: 'Pforzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '75181',
    city: 'Pforzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '75196',
    city: 'Remchingen',
    countrycode: 'DE',
  },
  {
    postalcode: '75203',
    city: 'Königsbach-Stein',
    countrycode: 'DE',
  },
  {
    postalcode: '75210',
    city: 'Keltern',
    countrycode: 'DE',
  },
  {
    postalcode: '75217',
    city: 'Birkenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '75223',
    city: 'Niefern-Öschelbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '75228',
    city: 'Ispringen',
    countrycode: 'DE',
  },
  {
    postalcode: '75233',
    city: 'Tiefenbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '75236',
    city: 'Kämpfelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '75239',
    city: 'Eisingen',
    countrycode: 'DE',
  },
  {
    postalcode: '75242',
    city: 'Neuhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '75245',
    city: 'Neulingen',
    countrycode: 'DE',
  },
  {
    postalcode: '75248',
    city: 'Ölbronn-Dürrn',
    countrycode: 'DE',
  },
  {
    postalcode: '75249',
    city: 'Kieselbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '75305',
    city: 'Neuenbürg',
    countrycode: 'DE',
  },
  {
    postalcode: '75323',
    city: 'Bad Wildbad',
    countrycode: 'DE',
  },
  {
    postalcode: '75328',
    city: 'Schömberg',
    countrycode: 'DE',
  },
  {
    postalcode: '75331',
    city: 'Engelsbrand',
    countrycode: 'DE',
  },
  {
    postalcode: '75334',
    city: 'Straubenhardt',
    countrycode: 'DE',
  },
  {
    postalcode: '75335',
    city: 'Dobel',
    countrycode: 'DE',
  },
  {
    postalcode: '75337',
    city: 'Enzklösterle',
    countrycode: 'DE',
  },
  {
    postalcode: '75337',
    city: 'Nonnenmiß',
    countrycode: 'DE',
  },
  {
    postalcode: '75339',
    city: 'Höfen',
    countrycode: 'DE',
  },
  {
    postalcode: '75365',
    city: 'Calw',
    countrycode: 'DE',
  },
  {
    postalcode: '75378',
    city: 'Bad Liebenzell',
    countrycode: 'DE',
  },
  {
    postalcode: '75382',
    city: 'Althengstett',
    countrycode: 'DE',
  },
  {
    postalcode: '75385',
    city: 'Bad Teinach-Zavelstein',
    countrycode: 'DE',
  },
  {
    postalcode: '75385',
    city: 'Dachshof',
    countrycode: 'DE',
  },
  {
    postalcode: '75385',
    city: 'Glasmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '75385',
    city: 'Lautenbachhof',
    countrycode: 'DE',
  },
  {
    postalcode: '75387',
    city: 'Neubulach',
    countrycode: 'DE',
  },
  {
    postalcode: '75389',
    city: 'Neuweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '75391',
    city: 'Gechingen',
    countrycode: 'DE',
  },
  {
    postalcode: '75392',
    city: 'Deckenpfronn',
    countrycode: 'DE',
  },
  {
    postalcode: '75394',
    city: 'Oberreichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '75395',
    city: 'Ostelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '75397',
    city: 'Simmozheim',
    countrycode: 'DE',
  },
  {
    postalcode: '75399',
    city: 'Nagoldtal',
    countrycode: 'DE',
  },
  {
    postalcode: '75399',
    city: 'Untere Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '75399',
    city: 'Unterreichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '75417',
    city: 'Mühlacker',
    countrycode: 'DE',
  },
  {
    postalcode: '75428',
    city: 'Illingen',
    countrycode: 'DE',
  },
  {
    postalcode: '75433',
    city: 'Maulbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '75438',
    city: 'Knittlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '75443',
    city: 'Ötisheim',
    countrycode: 'DE',
  },
  {
    postalcode: '75446',
    city: 'Wiernsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '75447',
    city: 'Sternenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '75449',
    city: 'Wurmberg',
    countrycode: 'DE',
  },
  {
    postalcode: '76131',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76133',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76135',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76137',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76139',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76149',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76185',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76187',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76189',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76199',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76227',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76228',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76229',
    city: 'Karlsruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '76275',
    city: 'Ettlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '76287',
    city: 'Rheinstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '76297',
    city: 'Stutensee',
    countrycode: 'DE',
  },
  {
    postalcode: '76307',
    city: 'Karlsbad',
    countrycode: 'DE',
  },
  {
    postalcode: '76316',
    city: 'Malsch',
    countrycode: 'DE',
  },
  {
    postalcode: '76327',
    city: 'Pfinztal',
    countrycode: 'DE',
  },
  {
    postalcode: '76332',
    city: 'Bad Herrenalb',
    countrycode: 'DE',
  },
  {
    postalcode: '76332',
    city: 'Plotzsägemühle',
    countrycode: 'DE',
  },
  {
    postalcode: '76337',
    city: 'Waldbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '76344',
    city: 'Eggenstein-Leopoldshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '76351',
    city: 'Linkenheim-Hochstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '76356',
    city: 'Weingarten',
    countrycode: 'DE',
  },
  {
    postalcode: '76356',
    city: 'Werrabronn',
    countrycode: 'DE',
  },
  {
    postalcode: '76359',
    city: 'Fischweier',
    countrycode: 'DE',
  },
  {
    postalcode: '76359',
    city: 'Marxzell',
    countrycode: 'DE',
  },
  {
    postalcode: '76359',
    city: 'Schöllbronner Mühle',
    countrycode: 'DE',
  },
  {
    postalcode: '76437',
    city: 'Rastatt',
    countrycode: 'DE',
  },
  {
    postalcode: '76448',
    city: 'Durmersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76456',
    city: 'Kuppenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76461',
    city: 'Muggensturm',
    countrycode: 'DE',
  },
  {
    postalcode: '76467',
    city: 'Bietigheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76470',
    city: 'Ötigheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76473',
    city: 'Iffezheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76474',
    city: 'Au',
    countrycode: 'DE',
  },
  {
    postalcode: '76476',
    city: 'Bischweier',
    countrycode: 'DE',
  },
  {
    postalcode: '76477',
    city: 'Elchesheim-Illingen',
    countrycode: 'DE',
  },
  {
    postalcode: '76479',
    city: 'Steinmauern',
    countrycode: 'DE',
  },
  {
    postalcode: '76530',
    city: 'Baden-Baden',
    countrycode: 'DE',
  },
  {
    postalcode: '76532',
    city: 'Baden-Baden',
    countrycode: 'DE',
  },
  {
    postalcode: '76534',
    city: 'Baden-Baden',
    countrycode: 'DE',
  },
  {
    postalcode: '76547',
    city: 'Sinzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76549',
    city: 'Hügelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76571',
    city: 'Gaggenau',
    countrycode: 'DE',
  },
  {
    postalcode: '76593',
    city: 'Gernsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76596',
    city: 'Forbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76597',
    city: 'Loffenau',
    countrycode: 'DE',
  },
  {
    postalcode: '76599',
    city: 'Weisenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76646',
    city: 'Bruchsal',
    countrycode: 'DE',
  },
  {
    postalcode: '76661',
    city: 'Philippsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '76669',
    city: 'Bad Schönborn',
    countrycode: 'DE',
  },
  {
    postalcode: '76676',
    city: 'Graben-Neudorf',
    countrycode: 'DE',
  },
  {
    postalcode: '76684',
    city: 'Östringen',
    countrycode: 'DE',
  },
  {
    postalcode: '76689',
    city: 'Karlsdorf-Neuthard',
    countrycode: 'DE',
  },
  {
    postalcode: '76694',
    city: 'Fasanenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '76694',
    city: 'Forst',
    countrycode: 'DE',
  },
  {
    postalcode: '76698',
    city: 'Ubstadt-Weiher',
    countrycode: 'DE',
  },
  {
    postalcode: '76703',
    city: 'Kraichtal',
    countrycode: 'DE',
  },
  {
    postalcode: '76706',
    city: 'Dettenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76707',
    city: 'Hambrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '76709',
    city: 'Kronau',
    countrycode: 'DE',
  },
  {
    postalcode: '76726',
    city: 'Altbrand',
    countrycode: 'DE',
  },
  {
    postalcode: '76726',
    city: 'Germersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76726',
    city: 'Vorwerk Friedrich',
    countrycode: 'DE',
  },
  {
    postalcode: '76744',
    city: 'Höllenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '76744',
    city: 'Langenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '76744',
    city: 'Vollmersweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76744',
    city: 'Wörth',
    countrycode: 'DE',
  },
  {
    postalcode: '76751',
    city: 'Jockgrim',
    countrycode: 'DE',
  },
  {
    postalcode: '76756',
    city: 'Bellheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76761',
    city: 'Rülzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76764',
    city: 'Rheinzabern',
    countrycode: 'DE',
  },
  {
    postalcode: '76767',
    city: 'Hagenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76767',
    city: 'Neufeldhof',
    countrycode: 'DE',
  },
  {
    postalcode: '76768',
    city: 'Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '76770',
    city: 'Hatzenbühl',
    countrycode: 'DE',
  },
  {
    postalcode: '76771',
    city: 'Hördt',
    countrycode: 'DE',
  },
  {
    postalcode: '76773',
    city: 'Kuhardt',
    countrycode: 'DE',
  },
  {
    postalcode: '76774',
    city: 'Leimersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76776',
    city: 'Neuburg',
    countrycode: 'DE',
  },
  {
    postalcode: '76777',
    city: 'Neupotz',
    countrycode: 'DE',
  },
  {
    postalcode: '76779',
    city: 'Salmbacher Passage',
    countrycode: 'DE',
  },
  {
    postalcode: '76779',
    city: 'Scheibenhardt',
    countrycode: 'DE',
  },
  {
    postalcode: '76829',
    city: 'Landau',
    countrycode: 'DE',
  },
  {
    postalcode: '76829',
    city: 'Leinsweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76829',
    city: 'Modenbacherhof',
    countrycode: 'DE',
  },
  {
    postalcode: '76829',
    city: 'Ranschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76831',
    city: 'Billigheim-Ingenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76831',
    city: 'Birkweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76831',
    city: 'Eschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76831',
    city: 'Göcklingen',
    countrycode: 'DE',
  },
  {
    postalcode: '76831',
    city: 'Heuchelheim-Klingen',
    countrycode: 'DE',
  },
  {
    postalcode: '76831',
    city: 'Ilbesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76831',
    city: 'Impflingen',
    countrycode: 'DE',
  },
  {
    postalcode: '76831',
    city: 'Ziegelhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '76833',
    city: 'Böchingen',
    countrycode: 'DE',
  },
  {
    postalcode: '76833',
    city: 'Frankweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76833',
    city: 'Knöringen',
    countrycode: 'DE',
  },
  {
    postalcode: '76833',
    city: 'Siebeldingen',
    countrycode: 'DE',
  },
  {
    postalcode: '76833',
    city: 'Walsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76835',
    city: 'Burrweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76835',
    city: 'Flemlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '76835',
    city: 'Gleisweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76835',
    city: 'Hainfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '76835',
    city: 'Rhodt',
    countrycode: 'DE',
  },
  {
    postalcode: '76835',
    city: 'Roschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76835',
    city: 'Weyher',
    countrycode: 'DE',
  },
  {
    postalcode: '76835',
    city: 'Wolfseck, Forsthaus',
    countrycode: 'DE',
  },
  {
    postalcode: '76846',
    city: 'Hauenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '76848',
    city: 'Darstein',
    countrycode: 'DE',
  },
  {
    postalcode: '76848',
    city: 'Dimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76848',
    city: 'Lug',
    countrycode: 'DE',
  },
  {
    postalcode: '76848',
    city: 'Schwanheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76848',
    city: 'Spirkelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76848',
    city: 'Wilgartswiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '76855',
    city: 'Annweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76855',
    city: 'Kaisermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '76855',
    city: 'Knochenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Albersweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Dernbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Eußerthal',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Gossersweiler-Stein',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Hoecker, Haus',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Münchweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Neumühle',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Ramberg',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Rinnthal',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Silz',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Taubensuhl',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Völkersweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Waldeck',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Waldhambach',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Waldrohrbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76857',
    city: 'Wernersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '76863',
    city: 'Herxheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76863',
    city: 'Herxheimweyher',
    countrycode: 'DE',
  },
  {
    postalcode: '76865',
    city: 'Insheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76865',
    city: 'Rohrbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76870',
    city: 'Kandel',
    countrycode: 'DE',
  },
  {
    postalcode: '76872',
    city: 'Bruchsiedlung',
    countrycode: 'DE',
  },
  {
    postalcode: '76872',
    city: 'Erlenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76872',
    city: 'Freckenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '76872',
    city: 'Hergersweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76872',
    city: 'Minfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '76872',
    city: 'Steinweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76872',
    city: 'Winden',
    countrycode: 'DE',
  },
  {
    postalcode: '76877',
    city: 'Fuchsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '76877',
    city: 'Offenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76879',
    city: 'Bornheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76879',
    city: 'Dreihof',
    countrycode: 'DE',
  },
  {
    postalcode: '76879',
    city: 'Essingen',
    countrycode: 'DE',
  },
  {
    postalcode: '76879',
    city: 'Hochstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '76879',
    city: 'Knittelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76879',
    city: 'Ottersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '76887',
    city: 'Bad Bergzabern',
    countrycode: 'DE',
  },
  {
    postalcode: '76887',
    city: 'Böllenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '76887',
    city: 'Gehlmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '76887',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '76887',
    city: 'Sandbühlerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Am Springberg',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Barbelroth',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Birkenhördt',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Dierbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Dörrenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Gleiszellen-Gleishorbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Haftelhof',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Kapellen-Drusweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Kapsweyer',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Kleinsteinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Klingenmünster',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Niederhorbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Niederotterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Oberotterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Oberschlettenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Pleisweiler-Oberhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Schweigen-Rechtenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Schweighofen',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Steinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Vorderweidenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '76889',
    city: 'Waldhof',
    countrycode: 'DE',
  },
  {
    postalcode: '76891',
    city: 'Bobenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '76891',
    city: 'Bruchweiler-Bärenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76891',
    city: 'Bundenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '76891',
    city: 'Busenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '76891',
    city: 'Erlenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76891',
    city: 'Falkenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '76891',
    city: 'Niederschlettenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '76891',
    city: 'Nothweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '76891',
    city: 'Rumbach',
    countrycode: 'DE',
  },
  {
    postalcode: '77652',
    city: 'Offenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '77654',
    city: 'Offenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '77656',
    city: 'Offenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '77694',
    city: 'Kehl',
    countrycode: 'DE',
  },
  {
    postalcode: '77704',
    city: 'Oberkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '77709',
    city: 'Oberwolfach',
    countrycode: 'DE',
  },
  {
    postalcode: '77709',
    city: 'Wolfach',
    countrycode: 'DE',
  },
  {
    postalcode: '77716',
    city: 'Fischerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '77716',
    city: 'Haslach',
    countrycode: 'DE',
  },
  {
    postalcode: '77716',
    city: 'Hofstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '77723',
    city: 'Gengenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '77728',
    city: 'Oppenau',
    countrycode: 'DE',
  },
  {
    postalcode: '77731',
    city: 'Willstätt',
    countrycode: 'DE',
  },
  {
    postalcode: '77736',
    city: 'Zell',
    countrycode: 'DE',
  },
  {
    postalcode: '77740',
    city: 'Bad Peterstal-Griesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '77743',
    city: 'Neuried',
    countrycode: 'DE',
  },
  {
    postalcode: '77746',
    city: 'Schutterwald',
    countrycode: 'DE',
  },
  {
    postalcode: '77749',
    city: 'Hohberg',
    countrycode: 'DE',
  },
  {
    postalcode: '77756',
    city: 'Hausach',
    countrycode: 'DE',
  },
  {
    postalcode: '77761',
    city: 'Schiltach',
    countrycode: 'DE',
  },
  {
    postalcode: '77767',
    city: 'Appenweier',
    countrycode: 'DE',
  },
  {
    postalcode: '77770',
    city: 'Durbach',
    countrycode: 'DE',
  },
  {
    postalcode: '77773',
    city: 'Schenkenzell',
    countrycode: 'DE',
  },
  {
    postalcode: '77776',
    city: 'Bad Rippoldsau-Schapbach',
    countrycode: 'DE',
  },
  {
    postalcode: '77781',
    city: 'Biberach',
    countrycode: 'DE',
  },
  {
    postalcode: '77784',
    city: 'Oberharmersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '77787',
    city: 'Nordrach',
    countrycode: 'DE',
  },
  {
    postalcode: '77790',
    city: 'Steinach',
    countrycode: 'DE',
  },
  {
    postalcode: '77791',
    city: 'Berghaupten',
    countrycode: 'DE',
  },
  {
    postalcode: '77793',
    city: 'Gutach',
    countrycode: 'DE',
  },
  {
    postalcode: '77794',
    city: 'Lautenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '77796',
    city: 'Mühlenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '77797',
    city: 'Ohlsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '77799',
    city: 'Ortenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '77815',
    city: 'Bühl',
    countrycode: 'DE',
  },
  {
    postalcode: '77830',
    city: 'Bühlertal',
    countrycode: 'DE',
  },
  {
    postalcode: '77833',
    city: 'Ottersweier',
    countrycode: 'DE',
  },
  {
    postalcode: '77836',
    city: 'Rheinmünster',
    countrycode: 'DE',
  },
  {
    postalcode: '77839',
    city: 'Lichtenau',
    countrycode: 'DE',
  },
  {
    postalcode: '77855',
    city: 'Achern',
    countrycode: 'DE',
  },
  {
    postalcode: '77866',
    city: 'Rheinau',
    countrycode: 'DE',
  },
  {
    postalcode: '77871',
    city: 'Renchen',
    countrycode: 'DE',
  },
  {
    postalcode: '77876',
    city: 'Kappelrodeck',
    countrycode: 'DE',
  },
  {
    postalcode: '77880',
    city: 'Sasbach',
    countrycode: 'DE',
  },
  {
    postalcode: '77883',
    city: 'Ottenhöfen',
    countrycode: 'DE',
  },
  {
    postalcode: '77886',
    city: 'Lauf',
    countrycode: 'DE',
  },
  {
    postalcode: '77887',
    city: 'Sasbachwalden',
    countrycode: 'DE',
  },
  {
    postalcode: '77889',
    city: 'Seebach',
    countrycode: 'DE',
  },
  {
    postalcode: '77933',
    city: 'Lahr',
    countrycode: 'DE',
  },
  {
    postalcode: '77948',
    city: 'Friesenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '77955',
    city: 'Ettenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '77960',
    city: 'Seelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '77963',
    city: 'Schwanau',
    countrycode: 'DE',
  },
  {
    postalcode: '77966',
    city: 'Kappel-Grafenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '77971',
    city: 'Kippenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '77972',
    city: 'Mahlberg',
    countrycode: 'DE',
  },
  {
    postalcode: '77974',
    city: 'Meißenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '77975',
    city: 'Ringsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '77977',
    city: 'Rust',
    countrycode: 'DE',
  },
  {
    postalcode: '77978',
    city: 'Schuttertal',
    countrycode: 'DE',
  },
  {
    postalcode: '78048',
    city: 'Villingen-Schwenningen',
    countrycode: 'DE',
  },
  {
    postalcode: '78050',
    city: 'Villingen-Schwenningen',
    countrycode: 'DE',
  },
  {
    postalcode: '78052',
    city: 'Villingen-Schwenningen',
    countrycode: 'DE',
  },
  {
    postalcode: '78054',
    city: 'Villingen-Schwenningen',
    countrycode: 'DE',
  },
  {
    postalcode: '78056',
    city: 'Villingen-Schwenningen',
    countrycode: 'DE',
  },
  {
    postalcode: '78073',
    city: 'Bad Dürrheim',
    countrycode: 'DE',
  },
  {
    postalcode: '78078',
    city: 'Niedereschach',
    countrycode: 'DE',
  },
  {
    postalcode: '78083',
    city: 'Dauchingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78083',
    city: 'Ebersteinerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '78086',
    city: 'Brigachtal',
    countrycode: 'DE',
  },
  {
    postalcode: '78087',
    city: 'Mönchweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '78089',
    city: 'Unterkirnach',
    countrycode: 'DE',
  },
  {
    postalcode: '78098',
    city: 'Triberg',
    countrycode: 'DE',
  },
  {
    postalcode: '78112',
    city: 'Schoren',
    countrycode: 'DE',
  },
  {
    postalcode: '78112',
    city: 'St. Georgen',
    countrycode: 'DE',
  },
  {
    postalcode: '78120',
    city: 'Furtwangen',
    countrycode: 'DE',
  },
  {
    postalcode: '78126',
    city: 'Königsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '78132',
    city: 'Hornberg',
    countrycode: 'DE',
  },
  {
    postalcode: '78136',
    city: 'Schonach',
    countrycode: 'DE',
  },
  {
    postalcode: '78141',
    city: 'Schönwald',
    countrycode: 'DE',
  },
  {
    postalcode: '78144',
    city: 'Tennenbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '78147',
    city: 'Vöhrenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '78148',
    city: 'Gütenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '78166',
    city: 'Donaueschingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78176',
    city: 'Blumberg',
    countrycode: 'DE',
  },
  {
    postalcode: '78183',
    city: 'Hüfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78187',
    city: 'Geisingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78194',
    city: 'Immendingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78199',
    city: 'Bräunlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78224',
    city: 'Singen',
    countrycode: 'DE',
  },
  {
    postalcode: '78234',
    city: 'Engen',
    countrycode: 'DE',
  },
  {
    postalcode: '78239',
    city: 'Rielasingen-Worblingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78244',
    city: 'Gottmadingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78247',
    city: 'Hilzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78250',
    city: 'Tengen',
    countrycode: 'DE',
  },
  {
    postalcode: '78253',
    city: 'Eigeltingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78256',
    city: 'Steißlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78259',
    city: 'Mühlhausen-Ehingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78262',
    city: 'Gailingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78266',
    city: 'Büsingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78267',
    city: 'Aach',
    countrycode: 'DE',
  },
  {
    postalcode: '78269',
    city: 'Volkertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '78315',
    city: 'Radolfzell',
    countrycode: 'DE',
  },
  {
    postalcode: '78333',
    city: 'Stockach',
    countrycode: 'DE',
  },
  {
    postalcode: '78337',
    city: 'Öhningen',
    countrycode: 'DE',
  },
  {
    postalcode: '78343',
    city: 'Gaienhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '78345',
    city: 'Moos',
    countrycode: 'DE',
  },
  {
    postalcode: '78351',
    city: 'Bodman-Ludwigshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '78354',
    city: 'Sipplingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78355',
    city: 'Hohenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '78357',
    city: 'Mühlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78359',
    city: 'Orsingen-Nenzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78462',
    city: 'Konstanz',
    countrycode: 'DE',
  },
  {
    postalcode: '78464',
    city: 'Konstanz',
    countrycode: 'DE',
  },
  {
    postalcode: '78465',
    city: 'Insel Mainau',
    countrycode: 'DE',
  },
  {
    postalcode: '78465',
    city: 'Konstanz',
    countrycode: 'DE',
  },
  {
    postalcode: '78467',
    city: 'Konstanz',
    countrycode: 'DE',
  },
  {
    postalcode: '78476',
    city: 'Allensbach',
    countrycode: 'DE',
  },
  {
    postalcode: '78479',
    city: 'Reichenau',
    countrycode: 'DE',
  },
  {
    postalcode: '78532',
    city: 'Tuttlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78549',
    city: 'Spaichingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78554',
    city: 'Aldingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78554',
    city: 'Erlenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '78554',
    city: 'Michelhölzle',
    countrycode: 'DE',
  },
  {
    postalcode: '78559',
    city: 'Gosheim',
    countrycode: 'DE',
  },
  {
    postalcode: '78564',
    city: 'Reichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '78564',
    city: 'Wehingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78567',
    city: 'Fridingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78567',
    city: 'Scheuerlehof',
    countrycode: 'DE',
  },
  {
    postalcode: '78570',
    city: 'Mühlheim',
    countrycode: 'DE',
  },
  {
    postalcode: '78573',
    city: 'Wurmlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78576',
    city: 'Emmingen-Liptingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78579',
    city: 'Neuhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '78580',
    city: 'Bärenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '78580',
    city: 'Hammer',
    countrycode: 'DE',
  },
  {
    postalcode: '78582',
    city: 'Balgheim',
    countrycode: 'DE',
  },
  {
    postalcode: '78583',
    city: 'Böttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78585',
    city: 'Bubsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '78586',
    city: 'Deilingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78588',
    city: 'Denkingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78589',
    city: 'Dürbheim',
    countrycode: 'DE',
  },
  {
    postalcode: '78591',
    city: 'Durchhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '78592',
    city: 'Egesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '78594',
    city: 'Gunningen',
    countrycode: 'DE',
  },
  {
    postalcode: '78595',
    city: 'Hausen',
    countrycode: 'DE',
  },
  {
    postalcode: '78597',
    city: 'Irndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '78598',
    city: 'Königsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '78600',
    city: 'Kolbingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78601',
    city: 'Mahlstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '78603',
    city: 'Renquishausen',
    countrycode: 'DE',
  },
  {
    postalcode: '78604',
    city: 'Rietheim-Weilheim',
    countrycode: 'DE',
  },
  {
    postalcode: '78606',
    city: 'Seitingen-Oberflacht',
    countrycode: 'DE',
  },
  {
    postalcode: '78607',
    city: 'Talheim',
    countrycode: 'DE',
  },
  {
    postalcode: '78609',
    city: 'Tuningen',
    countrycode: 'DE',
  },
  {
    postalcode: '78628',
    city: 'Hochhalden',
    countrycode: 'DE',
  },
  {
    postalcode: '78628',
    city: 'Rottweil',
    countrycode: 'DE',
  },
  {
    postalcode: '78647',
    city: 'Trossingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78652',
    city: 'Deißlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78652',
    city: 'Unterrotenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '78652',
    city: 'Wildenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '78655',
    city: 'Dunningen',
    countrycode: 'DE',
  },
  {
    postalcode: '78658',
    city: 'Zimmern',
    countrycode: 'DE',
  },
  {
    postalcode: '78661',
    city: 'Dietingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78662',
    city: 'Bösingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78664',
    city: 'Eschbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '78665',
    city: 'Frittlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78667',
    city: 'Hochwald',
    countrycode: 'DE',
  },
  {
    postalcode: '78667',
    city: 'Villingendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '78669',
    city: 'Wellendingen',
    countrycode: 'DE',
  },
  {
    postalcode: '78713',
    city: 'Gifizenmoos',
    countrycode: 'DE',
  },
  {
    postalcode: '78713',
    city: 'Schramberg',
    countrycode: 'DE',
  },
  {
    postalcode: '78727',
    city: 'Oberndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '78730',
    city: 'Lauterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '78730',
    city: 'Schloßhof',
    countrycode: 'DE',
  },
  {
    postalcode: '78730',
    city: 'Wolfsbühl',
    countrycode: 'DE',
  },
  {
    postalcode: '78733',
    city: 'Aichhalden',
    countrycode: 'DE',
  },
  {
    postalcode: '78733',
    city: 'Breitreute',
    countrycode: 'DE',
  },
  {
    postalcode: '78733',
    city: 'Neuhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '78736',
    city: 'Epfendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '78737',
    city: 'Fluorn-Winzeln',
    countrycode: 'DE',
  },
  {
    postalcode: '78739',
    city: 'Hardt',
    countrycode: 'DE',
  },
  {
    postalcode: '79098',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79100',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79102',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79104',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79106',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79108',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79110',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79111',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79112',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79114',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79115',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79117',
    city: 'Freiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79183',
    city: 'Waldkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '79189',
    city: 'Bad Krozingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79194',
    city: 'Gundelfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79194',
    city: 'Heuweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '79199',
    city: 'Kirchzarten',
    countrycode: 'DE',
  },
  {
    postalcode: '79206',
    city: 'Breisach',
    countrycode: 'DE',
  },
  {
    postalcode: '79211',
    city: 'Denzlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79215',
    city: 'Biederbach',
    countrycode: 'DE',
  },
  {
    postalcode: '79215',
    city: 'Elzach',
    countrycode: 'DE',
  },
  {
    postalcode: '79219',
    city: 'Staufen',
    countrycode: 'DE',
  },
  {
    postalcode: '79224',
    city: 'Umkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '79227',
    city: 'Schallstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '79232',
    city: 'March',
    countrycode: 'DE',
  },
  {
    postalcode: '79235',
    city: 'Vogtsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79238',
    city: 'Ehrenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '79241',
    city: 'Ihringen',
    countrycode: 'DE',
  },
  {
    postalcode: '79244',
    city: 'Münstertal',
    countrycode: 'DE',
  },
  {
    postalcode: '79249',
    city: 'Merzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '79252',
    city: 'Stegen',
    countrycode: 'DE',
  },
  {
    postalcode: '79254',
    city: 'Oberried',
    countrycode: 'DE',
  },
  {
    postalcode: '79256',
    city: 'Buchenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '79258',
    city: 'Hartheim',
    countrycode: 'DE',
  },
  {
    postalcode: '79261',
    city: 'Gutach',
    countrycode: 'DE',
  },
  {
    postalcode: '79263',
    city: 'Simonswald',
    countrycode: 'DE',
  },
  {
    postalcode: '79268',
    city: 'Bötzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79271',
    city: 'St. Peter',
    countrycode: 'DE',
  },
  {
    postalcode: '79274',
    city: 'St. Märgen',
    countrycode: 'DE',
  },
  {
    postalcode: '79276',
    city: 'Reute',
    countrycode: 'DE',
  },
  {
    postalcode: '79279',
    city: 'Vörstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '79280',
    city: 'Au',
    countrycode: 'DE',
  },
  {
    postalcode: '79282',
    city: 'Ballrechten-Dottingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79283',
    city: 'Bollschweil',
    countrycode: 'DE',
  },
  {
    postalcode: '79285',
    city: 'Ebringen',
    countrycode: 'DE',
  },
  {
    postalcode: '79286',
    city: 'Glottertal',
    countrycode: 'DE',
  },
  {
    postalcode: '79288',
    city: 'Gottenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '79289',
    city: 'Horben',
    countrycode: 'DE',
  },
  {
    postalcode: '79291',
    city: 'Merdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79292',
    city: 'Pfaffenweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '79294',
    city: 'Sölden',
    countrycode: 'DE',
  },
  {
    postalcode: '79295',
    city: 'Sulzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79297',
    city: 'Winden',
    countrycode: 'DE',
  },
  {
    postalcode: '79299',
    city: 'Wittnau',
    countrycode: 'DE',
  },
  {
    postalcode: '79312',
    city: 'Emmendingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79312',
    city: 'Landeck',
    countrycode: 'DE',
  },
  {
    postalcode: '79331',
    city: 'Teningen',
    countrycode: 'DE',
  },
  {
    postalcode: '79336',
    city: 'Herbolzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '79341',
    city: 'Kenzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79346',
    city: 'Endingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79348',
    city: 'Freiamt',
    countrycode: 'DE',
  },
  {
    postalcode: '79350',
    city: 'Sexau',
    countrycode: 'DE',
  },
  {
    postalcode: '79353',
    city: 'Bahlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79356',
    city: 'Eichstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '79359',
    city: 'Riegel',
    countrycode: 'DE',
  },
  {
    postalcode: '79361',
    city: 'Sasbach',
    countrycode: 'DE',
  },
  {
    postalcode: '79362',
    city: 'Forchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '79364',
    city: 'Malterdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79365',
    city: 'Rheinhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '79367',
    city: 'Weisweil',
    countrycode: 'DE',
  },
  {
    postalcode: '79369',
    city: 'Wyhl',
    countrycode: 'DE',
  },
  {
    postalcode: '79379',
    city: 'Müllheim',
    countrycode: 'DE',
  },
  {
    postalcode: '79395',
    city: 'Neuenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79400',
    city: 'Kandern',
    countrycode: 'DE',
  },
  {
    postalcode: '79410',
    city: 'Badenweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '79415',
    city: 'Bad Bellingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79418',
    city: 'Schliengen',
    countrycode: 'DE',
  },
  {
    postalcode: '79423',
    city: 'Heitersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '79424',
    city: 'Auggen',
    countrycode: 'DE',
  },
  {
    postalcode: '79426',
    city: 'Buggingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79427',
    city: 'Eschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '79429',
    city: 'Malsburg-Marzell',
    countrycode: 'DE',
  },
  {
    postalcode: '79539',
    city: 'Lörrach',
    countrycode: 'DE',
  },
  {
    postalcode: '79540',
    city: 'Lörrach',
    countrycode: 'DE',
  },
  {
    postalcode: '79541',
    city: 'Lörrach',
    countrycode: 'DE',
  },
  {
    postalcode: '79576',
    city: 'Weil am Rhein',
    countrycode: 'DE',
  },
  {
    postalcode: '79585',
    city: 'Steinen',
    countrycode: 'DE',
  },
  {
    postalcode: '79588',
    city: 'Efringen-Kirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '79589',
    city: 'Binzen',
    countrycode: 'DE',
  },
  {
    postalcode: '79591',
    city: 'Eimeldingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79592',
    city: 'Fischingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79594',
    city: 'Inzlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79595',
    city: 'Rümmingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79597',
    city: 'Schallbach',
    countrycode: 'DE',
  },
  {
    postalcode: '79599',
    city: 'Wittlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79618',
    city: 'Rheinfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '79639',
    city: 'Grenzach-Wyhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '79650',
    city: 'Schopfheim',
    countrycode: 'DE',
  },
  {
    postalcode: '79664',
    city: 'Wehr',
    countrycode: 'DE',
  },
  {
    postalcode: '79669',
    city: 'Zell',
    countrycode: 'DE',
  },
  {
    postalcode: '79674',
    city: 'Todtnau',
    countrycode: 'DE',
  },
  {
    postalcode: '79677',
    city: 'Aitern',
    countrycode: 'DE',
  },
  {
    postalcode: '79677',
    city: 'Böllen',
    countrycode: 'DE',
  },
  {
    postalcode: '79677',
    city: 'Fröhnd',
    countrycode: 'DE',
  },
  {
    postalcode: '79677',
    city: 'Schönau',
    countrycode: 'DE',
  },
  {
    postalcode: '79677',
    city: 'Schönenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '79677',
    city: 'Tunau',
    countrycode: 'DE',
  },
  {
    postalcode: '79677',
    city: 'Wembach',
    countrycode: 'DE',
  },
  {
    postalcode: '79682',
    city: 'Todtmoos',
    countrycode: 'DE',
  },
  {
    postalcode: '79683',
    city: 'Bürchau',
    countrycode: 'DE',
  },
  {
    postalcode: '79685',
    city: 'Häg-Ehrsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '79686',
    city: 'Hasel',
    countrycode: 'DE',
  },
  {
    postalcode: '79688',
    city: 'Hausen',
    countrycode: 'DE',
  },
  {
    postalcode: '79689',
    city: 'Maulburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79691',
    city: 'Neuenweg',
    countrycode: 'DE',
  },
  {
    postalcode: '79692',
    city: 'Elbenschwand',
    countrycode: 'DE',
  },
  {
    postalcode: '79692',
    city: 'Raich',
    countrycode: 'DE',
  },
  {
    postalcode: '79692',
    city: 'Sallneck',
    countrycode: 'DE',
  },
  {
    postalcode: '79692',
    city: 'Tegernau',
    countrycode: 'DE',
  },
  {
    postalcode: '79694',
    city: 'Utzenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '79695',
    city: 'Wieden',
    countrycode: 'DE',
  },
  {
    postalcode: '79697',
    city: 'Wies',
    countrycode: 'DE',
  },
  {
    postalcode: '79699',
    city: 'Wieslet',
    countrycode: 'DE',
  },
  {
    postalcode: '79713',
    city: 'Bad Säckingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79725',
    city: 'Laufenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '79730',
    city: 'Murg',
    countrycode: 'DE',
  },
  {
    postalcode: '79733',
    city: 'Görwihl',
    countrycode: 'DE',
  },
  {
    postalcode: '79736',
    city: 'Rickenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '79737',
    city: 'Herrischried',
    countrycode: 'DE',
  },
  {
    postalcode: '79739',
    city: 'Schwörstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '79761',
    city: 'Waldshut-Tiengen',
    countrycode: 'DE',
  },
  {
    postalcode: '79771',
    city: 'Klettgau',
    countrycode: 'DE',
  },
  {
    postalcode: '79774',
    city: 'Albbruck',
    countrycode: 'DE',
  },
  {
    postalcode: '79777',
    city: 'Ühlingen-Birkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '79780',
    city: 'Stühlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79787',
    city: 'Lauchringen',
    countrycode: 'DE',
  },
  {
    postalcode: '79790',
    city: 'Küssaberg',
    countrycode: 'DE',
  },
  {
    postalcode: '79793',
    city: 'Wutöschingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79798',
    city: 'Jestetten',
    countrycode: 'DE',
  },
  {
    postalcode: '79801',
    city: 'Hohentengen',
    countrycode: 'DE',
  },
  {
    postalcode: '79802',
    city: 'Dettighofen',
    countrycode: 'DE',
  },
  {
    postalcode: '79804',
    city: 'Dogern',
    countrycode: 'DE',
  },
  {
    postalcode: '79805',
    city: 'Eggingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79807',
    city: 'Lottstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '79809',
    city: 'Weilheim',
    countrycode: 'DE',
  },
  {
    postalcode: '79822',
    city: 'Titisee-Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '79837',
    city: 'Häusern',
    countrycode: 'DE',
  },
  {
    postalcode: '79837',
    city: 'Ibach',
    countrycode: 'DE',
  },
  {
    postalcode: '79837',
    city: 'Oberkutterau',
    countrycode: 'DE',
  },
  {
    postalcode: '79837',
    city: 'St. Blasien',
    countrycode: 'DE',
  },
  {
    postalcode: '79843',
    city: 'Löffingen',
    countrycode: 'DE',
  },
  {
    postalcode: '79848',
    city: 'Bonndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '79853',
    city: 'Lenzkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '79856',
    city: 'Hinterzarten',
    countrycode: 'DE',
  },
  {
    postalcode: '79859',
    city: 'Schluchsee',
    countrycode: 'DE',
  },
  {
    postalcode: '79862',
    city: 'Höchenschwand',
    countrycode: 'DE',
  },
  {
    postalcode: '79865',
    city: 'Grafenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '79868',
    city: 'Feldberg',
    countrycode: 'DE',
  },
  {
    postalcode: '79871',
    city: 'Eisenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '79872',
    city: 'Bernau',
    countrycode: 'DE',
  },
  {
    postalcode: '79874',
    city: 'Breitnau',
    countrycode: 'DE',
  },
  {
    postalcode: '79875',
    city: 'Dachsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '79875',
    city: 'Luchle',
    countrycode: 'DE',
  },
  {
    postalcode: '79877',
    city: 'Friedenweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '79879',
    city: 'Wutach',
    countrycode: 'DE',
  },
  {
    postalcode: '80331',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80333',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80335',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80336',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80337',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80339',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80469',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80538',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80539',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80634',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80636',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80637',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80638',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80639',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80686',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80687',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80689',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80796',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80797',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80798',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80799',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80801',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80802',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80803',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80804',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80805',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80807',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80809',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80933',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80935',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80937',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80939',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80992',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80993',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80995',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80997',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '80999',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81241',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81243',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81245',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81247',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81249',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81249',
    city: 'Streiflach',
    countrycode: 'DE',
  },
  {
    postalcode: '81369',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81371',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81373',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81375',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81377',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81379',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81475',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81476',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81477',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81479',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81539',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81541',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81543',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81545',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81547',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81549',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81667',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81669',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81671',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81673',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81675',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81677',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81679',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81735',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81737',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81739',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81825',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81827',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81829',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81925',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81927',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '81929',
    city: 'München',
    countrycode: 'DE',
  },
  {
    postalcode: '82008',
    city: 'Unterhaching',
    countrycode: 'DE',
  },
  {
    postalcode: '82024',
    city: 'Taufkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '82031',
    city: 'Grünwald',
    countrycode: 'DE',
  },
  {
    postalcode: '82041',
    city: 'Deisenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '82041',
    city: 'Furth',
    countrycode: 'DE',
  },
  {
    postalcode: '82041',
    city: 'Gerblinghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '82041',
    city: 'Kreuzpullach',
    countrycode: 'DE',
  },
  {
    postalcode: '82041',
    city: 'Laufzorn',
    countrycode: 'DE',
  },
  {
    postalcode: '82041',
    city: 'Oberbiberg',
    countrycode: 'DE',
  },
  {
    postalcode: '82041',
    city: 'Oberhaching',
    countrycode: 'DE',
  },
  {
    postalcode: '82041',
    city: 'Ödenpullach',
    countrycode: 'DE',
  },
  {
    postalcode: '82049',
    city: 'Großhesselohe',
    countrycode: 'DE',
  },
  {
    postalcode: '82049',
    city: 'Höllriegelskreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '82049',
    city: 'Pullach',
    countrycode: 'DE',
  },
  {
    postalcode: '82054',
    city: 'Sauerlach',
    countrycode: 'DE',
  },
  {
    postalcode: '82057',
    city: 'Icking',
    countrycode: 'DE',
  },
  {
    postalcode: '82061',
    city: 'Neuried',
    countrycode: 'DE',
  },
  {
    postalcode: '82064',
    city: 'Jettenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '82064',
    city: 'Oberdill',
    countrycode: 'DE',
  },
  {
    postalcode: '82064',
    city: 'Straßlach-Dingharting',
    countrycode: 'DE',
  },
  {
    postalcode: '82065',
    city: 'Baierbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '82067',
    city: 'Ebenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '82067',
    city: 'Kloster Schäftlarn',
    countrycode: 'DE',
  },
  {
    postalcode: '82067',
    city: 'Zell',
    countrycode: 'DE',
  },
  {
    postalcode: '82069',
    city: 'Hohenschäftlarn',
    countrycode: 'DE',
  },
  {
    postalcode: '82069',
    city: 'Schäftlarn',
    countrycode: 'DE',
  },
  {
    postalcode: '82110',
    city: 'Germering',
    countrycode: 'DE',
  },
  {
    postalcode: '82131',
    city: 'Gauting',
    countrycode: 'DE',
  },
  {
    postalcode: '82131',
    city: 'Kasten',
    countrycode: 'DE',
  },
  {
    postalcode: '82131',
    city: 'Stockdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '82140',
    city: 'Olching',
    countrycode: 'DE',
  },
  {
    postalcode: '82152',
    city: 'Krailling',
    countrycode: 'DE',
  },
  {
    postalcode: '82152',
    city: 'Planegg',
    countrycode: 'DE',
  },
  {
    postalcode: '82166',
    city: 'Gräfelfing',
    countrycode: 'DE',
  },
  {
    postalcode: '82178',
    city: 'Puchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '82194',
    city: 'Gröbenzell',
    countrycode: 'DE',
  },
  {
    postalcode: '82205',
    city: 'Gilching',
    countrycode: 'DE',
  },
  {
    postalcode: '82205',
    city: 'Hüll',
    countrycode: 'DE',
  },
  {
    postalcode: '82211',
    city: 'Herrsching',
    countrycode: 'DE',
  },
  {
    postalcode: '82216',
    city: 'Frauenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '82216',
    city: 'Germerswang',
    countrycode: 'DE',
  },
  {
    postalcode: '82216',
    city: 'Maisach',
    countrycode: 'DE',
  },
  {
    postalcode: '82216',
    city: 'Malching',
    countrycode: 'DE',
  },
  {
    postalcode: '82216',
    city: 'Stefansberg',
    countrycode: 'DE',
  },
  {
    postalcode: '82223',
    city: 'Eichenau',
    countrycode: 'DE',
  },
  {
    postalcode: '82223',
    city: 'Roggenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '82229',
    city: 'Seefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '82234',
    city: 'Weßling',
    countrycode: 'DE',
  },
  {
    postalcode: '82237',
    city: 'Wörthsee',
    countrycode: 'DE',
  },
  {
    postalcode: '82239',
    city: 'Alling',
    countrycode: 'DE',
  },
  {
    postalcode: '82256',
    city: 'Fürstenfeldbruck',
    countrycode: 'DE',
  },
  {
    postalcode: '82266',
    city: 'Inning',
    countrycode: 'DE',
  },
  {
    postalcode: '82269',
    city: 'Geltendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '82272',
    city: 'Moorenweis',
    countrycode: 'DE',
  },
  {
    postalcode: '82275',
    city: 'Emmering',
    countrycode: 'DE',
  },
  {
    postalcode: '82276',
    city: 'Adelshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '82278',
    city: 'Althegnenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '82279',
    city: 'Eching',
    countrycode: 'DE',
  },
  {
    postalcode: '82281',
    city: 'Egenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '82282',
    city: 'Aufkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '82282',
    city: 'Englertshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '82282',
    city: 'Eurastetten',
    countrycode: 'DE',
  },
  {
    postalcode: '82282',
    city: 'Geisenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '82282',
    city: 'Holzmühl',
    countrycode: 'DE',
  },
  {
    postalcode: '82282',
    city: 'Kumpfmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '82282',
    city: 'Oberweikertshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '82282',
    city: 'Pischertshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '82282',
    city: 'Rammertshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '82282',
    city: 'Rottenfuß',
    countrycode: 'DE',
  },
  {
    postalcode: '82282',
    city: 'Unterschweinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '82282',
    city: 'Waltershofen',
    countrycode: 'DE',
  },
  {
    postalcode: '82284',
    city: 'Grafrath',
    countrycode: 'DE',
  },
  {
    postalcode: '82285',
    city: 'Hattenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '82287',
    city: 'Jesenwang',
    countrycode: 'DE',
  },
  {
    postalcode: '82288',
    city: 'Kottgeisering',
    countrycode: 'DE',
  },
  {
    postalcode: '82290',
    city: 'Landsberied',
    countrycode: 'DE',
  },
  {
    postalcode: '82291',
    city: 'Mammendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '82293',
    city: 'Mittelstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '82294',
    city: 'Oberschweinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '82296',
    city: 'Schöngeising',
    countrycode: 'DE',
  },
  {
    postalcode: '82297',
    city: 'Steindorf',
    countrycode: 'DE',
  },
  {
    postalcode: '82299',
    city: 'Türkenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '82319',
    city: 'Seewiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '82319',
    city: 'Starnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '82327',
    city: 'Tutzing',
    countrycode: 'DE',
  },
  {
    postalcode: '82335',
    city: 'Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '82340',
    city: 'Feldafing',
    countrycode: 'DE',
  },
  {
    postalcode: '82343',
    city: 'Pöcking',
    countrycode: 'DE',
  },
  {
    postalcode: '82346',
    city: 'Andechs',
    countrycode: 'DE',
  },
  {
    postalcode: '82347',
    city: 'Bernried',
    countrycode: 'DE',
  },
  {
    postalcode: '82349',
    city: 'Frohnloh',
    countrycode: 'DE',
  },
  {
    postalcode: '82349',
    city: 'Pentenried',
    countrycode: 'DE',
  },
  {
    postalcode: '82362',
    city: 'Schörghof',
    countrycode: 'DE',
  },
  {
    postalcode: '82362',
    city: 'Weilheim',
    countrycode: 'DE',
  },
  {
    postalcode: '82377',
    city: 'Penzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '82380',
    city: 'Bruckerhof',
    countrycode: 'DE',
  },
  {
    postalcode: '82380',
    city: 'Osterwald',
    countrycode: 'DE',
  },
  {
    postalcode: '82380',
    city: 'Peißenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '82380',
    city: 'Roßlaich',
    countrycode: 'DE',
  },
  {
    postalcode: '82383',
    city: 'Hohenpeißenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '82386',
    city: 'Huglfing',
    countrycode: 'DE',
  },
  {
    postalcode: '82386',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '82387',
    city: 'Antdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '82389',
    city: 'Böbing',
    countrycode: 'DE',
  },
  {
    postalcode: '82390',
    city: 'Eberfing',
    countrycode: 'DE',
  },
  {
    postalcode: '82390',
    city: 'Moos',
    countrycode: 'DE',
  },
  {
    postalcode: '82390',
    city: 'Tradlenz',
    countrycode: 'DE',
  },
  {
    postalcode: '82392',
    city: 'Habach',
    countrycode: 'DE',
  },
  {
    postalcode: '82392',
    city: 'Habaching',
    countrycode: 'DE',
  },
  {
    postalcode: '82392',
    city: 'Hachtsee',
    countrycode: 'DE',
  },
  {
    postalcode: '82392',
    city: 'Reinthal',
    countrycode: 'DE',
  },
  {
    postalcode: '82393',
    city: 'Iffeldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '82393',
    city: 'Schechen',
    countrycode: 'DE',
  },
  {
    postalcode: '82395',
    city: 'Obersöchering',
    countrycode: 'DE',
  },
  {
    postalcode: '82396',
    city: 'Pähl',
    countrycode: 'DE',
  },
  {
    postalcode: '82398',
    city: 'Polling',
    countrycode: 'DE',
  },
  {
    postalcode: '82399',
    city: 'Raisting',
    countrycode: 'DE',
  },
  {
    postalcode: '82401',
    city: 'Rottenbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '82402',
    city: 'Seeshaupt',
    countrycode: 'DE',
  },
  {
    postalcode: '82404',
    city: 'Sindelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '82405',
    city: 'Wessobrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '82407',
    city: 'Wielenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '82409',
    city: 'Wildsteig',
    countrycode: 'DE',
  },
  {
    postalcode: '82418',
    city: 'Hofheim',
    countrycode: 'DE',
  },
  {
    postalcode: '82418',
    city: 'Murnau',
    countrycode: 'DE',
  },
  {
    postalcode: '82418',
    city: 'Riegsee',
    countrycode: 'DE',
  },
  {
    postalcode: '82418',
    city: 'Seehausen',
    countrycode: 'DE',
  },
  {
    postalcode: '82431',
    city: 'Kochel',
    countrycode: 'DE',
  },
  {
    postalcode: '82432',
    city: 'Herzogstand',
    countrycode: 'DE',
  },
  {
    postalcode: '82432',
    city: 'Obernach',
    countrycode: 'DE',
  },
  {
    postalcode: '82432',
    city: 'Urfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '82432',
    city: 'Walchensee',
    countrycode: 'DE',
  },
  {
    postalcode: '82432',
    city: 'Zwergern',
    countrycode: 'DE',
  },
  {
    postalcode: '82433',
    city: 'Bad Kohlgrub',
    countrycode: 'DE',
  },
  {
    postalcode: '82435',
    city: 'Bad Bayersoien',
    countrycode: 'DE',
  },
  {
    postalcode: '82436',
    city: 'Eglfing',
    countrycode: 'DE',
  },
  {
    postalcode: '82438',
    city: 'Eschenlohe',
    countrycode: 'DE',
  },
  {
    postalcode: '82439',
    city: 'Großweil',
    countrycode: 'DE',
  },
  {
    postalcode: '82439',
    city: 'Kreut',
    countrycode: 'DE',
  },
  {
    postalcode: '82441',
    city: 'Ohlstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '82442',
    city: 'Saulgrub',
    countrycode: 'DE',
  },
  {
    postalcode: '82444',
    city: 'Schlehdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '82445',
    city: 'Schwaigen',
    countrycode: 'DE',
  },
  {
    postalcode: '82447',
    city: 'Spatzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '82449',
    city: 'Heimgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '82449',
    city: 'Uffing',
    countrycode: 'DE',
  },
  {
    postalcode: '82467',
    city: 'Garmisch-Partenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '82467',
    city: 'Plattele',
    countrycode: 'DE',
  },
  {
    postalcode: '82475',
    city: 'Schneefernerhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '82481',
    city: 'Mittenwald',
    countrycode: 'DE',
  },
  {
    postalcode: '82487',
    city: 'Oberammergau',
    countrycode: 'DE',
  },
  {
    postalcode: '82488',
    city: 'Ettal',
    countrycode: 'DE',
  },
  {
    postalcode: '82490',
    city: 'Farchant',
    countrycode: 'DE',
  },
  {
    postalcode: '82491',
    city: 'Grainau',
    countrycode: 'DE',
  },
  {
    postalcode: '82493',
    city: 'Am Quicken',
    countrycode: 'DE',
  },
  {
    postalcode: '82493',
    city: 'Elmau',
    countrycode: 'DE',
  },
  {
    postalcode: '82493',
    city: 'Gerold',
    countrycode: 'DE',
  },
  {
    postalcode: '82493',
    city: 'Klais',
    countrycode: 'DE',
  },
  {
    postalcode: '82493',
    city: 'Kranzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '82494',
    city: 'Krün',
    countrycode: 'DE',
  },
  {
    postalcode: '82496',
    city: 'Oberau',
    countrycode: 'DE',
  },
  {
    postalcode: '82497',
    city: 'Unterammergau',
    countrycode: 'DE',
  },
  {
    postalcode: '82499',
    city: 'Wallgau',
    countrycode: 'DE',
  },
  {
    postalcode: '82515',
    city: 'Bruckmaier',
    countrycode: 'DE',
  },
  {
    postalcode: '82515',
    city: 'Wolfratshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '82538',
    city: 'Geretsried',
    countrycode: 'DE',
  },
  {
    postalcode: '82541',
    city: 'Münsing',
    countrycode: 'DE',
  },
  {
    postalcode: '82544',
    city: 'Egling',
    countrycode: 'DE',
  },
  {
    postalcode: '82547',
    city: 'Eurasburg',
    countrycode: 'DE',
  },
  {
    postalcode: '82549',
    city: 'Königsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83022',
    city: 'Rosenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '83024',
    city: 'Rosenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '83026',
    city: 'Rosenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '83043',
    city: 'Bad Aibling',
    countrycode: 'DE',
  },
  {
    postalcode: '83052',
    city: 'Bruckmühl',
    countrycode: 'DE',
  },
  {
    postalcode: '83059',
    city: 'Kolbermoor',
    countrycode: 'DE',
  },
  {
    postalcode: '83064',
    city: 'Raubling',
    countrycode: 'DE',
  },
  {
    postalcode: '83071',
    city: 'Stephanskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '83075',
    city: 'Bad Feilnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '83080',
    city: 'Oberaudorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83083',
    city: 'Riedering',
    countrycode: 'DE',
  },
  {
    postalcode: '83088',
    city: 'Kiefersfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '83093',
    city: 'Bad Endorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83098',
    city: 'Brannenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '83101',
    city: 'Rohrdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83104',
    city: 'Tuntenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '83109',
    city: 'Großkarolinenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '83112',
    city: 'Frasdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83115',
    city: 'Neubeuern',
    countrycode: 'DE',
  },
  {
    postalcode: '83119',
    city: 'Eggerdach',
    countrycode: 'DE',
  },
  {
    postalcode: '83119',
    city: 'Gröben',
    countrycode: 'DE',
  },
  {
    postalcode: '83119',
    city: 'Obing',
    countrycode: 'DE',
  },
  {
    postalcode: '83122',
    city: 'Samerberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83123',
    city: 'Amerang',
    countrycode: 'DE',
  },
  {
    postalcode: '83125',
    city: 'Eggstätt',
    countrycode: 'DE',
  },
  {
    postalcode: '83126',
    city: 'Flintsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '83128',
    city: 'Halfing',
    countrycode: 'DE',
  },
  {
    postalcode: '83129',
    city: 'Höslwang',
    countrycode: 'DE',
  },
  {
    postalcode: '83131',
    city: 'Nußdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83132',
    city: 'Pittenhart',
    countrycode: 'DE',
  },
  {
    postalcode: '83134',
    city: 'Prutting',
    countrycode: 'DE',
  },
  {
    postalcode: '83135',
    city: 'Schechen',
    countrycode: 'DE',
  },
  {
    postalcode: '83137',
    city: 'Schonstett',
    countrycode: 'DE',
  },
  {
    postalcode: '83139',
    city: 'Söchtenau',
    countrycode: 'DE',
  },
  {
    postalcode: '83209',
    city: 'Herrenchiemsee',
    countrycode: 'DE',
  },
  {
    postalcode: '83209',
    city: 'Prien',
    countrycode: 'DE',
  },
  {
    postalcode: '83224',
    city: 'Grassau',
    countrycode: 'DE',
  },
  {
    postalcode: '83224',
    city: 'Staudach-Egerndach',
    countrycode: 'DE',
  },
  {
    postalcode: '83229',
    city: 'Aschau',
    countrycode: 'DE',
  },
  {
    postalcode: '83233',
    city: 'Bernau',
    countrycode: 'DE',
  },
  {
    postalcode: '83236',
    city: 'Übersee',
    countrycode: 'DE',
  },
  {
    postalcode: '83242',
    city: 'Reit im Winkl',
    countrycode: 'DE',
  },
  {
    postalcode: '83246',
    city: 'Unterwössen',
    countrycode: 'DE',
  },
  {
    postalcode: '83250',
    city: 'Fahrnpoint',
    countrycode: 'DE',
  },
  {
    postalcode: '83250',
    city: 'Hochgernhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '83250',
    city: 'Marquartstein',
    countrycode: 'DE',
  },
  {
    postalcode: '83253',
    city: 'Rimsting',
    countrycode: 'DE',
  },
  {
    postalcode: '83254',
    city: 'Breitbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '83256',
    city: 'Chiemsee',
    countrycode: 'DE',
  },
  {
    postalcode: '83256',
    city: 'Frauenchiemsee',
    countrycode: 'DE',
  },
  {
    postalcode: '83257',
    city: 'Gstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '83259',
    city: 'Schleching',
    countrycode: 'DE',
  },
  {
    postalcode: '83278',
    city: 'Traunstein',
    countrycode: 'DE',
  },
  {
    postalcode: '83301',
    city: 'Traunreut',
    countrycode: 'DE',
  },
  {
    postalcode: '83308',
    city: 'Trostberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83313',
    city: 'Grub',
    countrycode: 'DE',
  },
  {
    postalcode: '83313',
    city: 'Siegsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83317',
    city: 'Teisendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83324',
    city: 'Ruhpolding',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Aichberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Aichwies',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Amselgraben',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Burgstall',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Dobelgraben',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Eglsee',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Enzersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Fürst',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Greinach',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Gänsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Heinrichsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Hellmannsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Hoggen',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Horn',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Kirchhalling',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Köpfelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Madreut',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Oberdoblmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Oberhalling',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Obermoosen',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Oberwendling',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Panolsreut',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Ramgraben',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Riendlhäusl',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Ruhsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Scharling',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'St Leonhard',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Sträubl',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Untermoosen',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Unterwendling',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Waging',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Weißbach',
    countrycode: 'DE',
  },
  {
    postalcode: '83329',
    city: 'Wonneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83334',
    city: 'Inzell',
    countrycode: 'DE',
  },
  {
    postalcode: '83339',
    city: 'Chieming',
    countrycode: 'DE',
  },
  {
    postalcode: '83342',
    city: 'Tacherting',
    countrycode: 'DE',
  },
  {
    postalcode: '83346',
    city: 'Bergen',
    countrycode: 'DE',
  },
  {
    postalcode: '83346',
    city: 'Brünndling-Alm',
    countrycode: 'DE',
  },
  {
    postalcode: '83346',
    city: 'Hochfellnhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '83349',
    city: 'Palling',
    countrycode: 'DE',
  },
  {
    postalcode: '83352',
    city: 'Altenmarkt',
    countrycode: 'DE',
  },
  {
    postalcode: '83355',
    city: 'Grabenstätt',
    countrycode: 'DE',
  },
  {
    postalcode: '83358',
    city: 'Grafenanger',
    countrycode: 'DE',
  },
  {
    postalcode: '83358',
    city: 'Seebruck',
    countrycode: 'DE',
  },
  {
    postalcode: '83359',
    city: 'Ettendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83359',
    city: 'Hallabruck',
    countrycode: 'DE',
  },
  {
    postalcode: '83359',
    city: 'Hierankl',
    countrycode: 'DE',
  },
  {
    postalcode: '83359',
    city: 'Hufschlag',
    countrycode: 'DE',
  },
  {
    postalcode: '83359',
    city: 'Jahn',
    countrycode: 'DE',
  },
  {
    postalcode: '83359',
    city: 'Trenkmoos',
    countrycode: 'DE',
  },
  {
    postalcode: '83361',
    city: 'Kienberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83362',
    city: 'Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '83362',
    city: 'Brunnstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '83362',
    city: 'Eder',
    countrycode: 'DE',
  },
  {
    postalcode: '83362',
    city: 'Hirschau',
    countrycode: 'DE',
  },
  {
    postalcode: '83362',
    city: 'Lauter',
    countrycode: 'DE',
  },
  {
    postalcode: '83362',
    city: 'Reut',
    countrycode: 'DE',
  },
  {
    postalcode: '83362',
    city: 'Schnatzlreut',
    countrycode: 'DE',
  },
  {
    postalcode: '83362',
    city: 'Surberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83362',
    city: 'Zell',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Adligstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Andrichstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Atzlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Au',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Bach',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Diepoltstatt',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Fuchssteig',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Gierstling',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Graben',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Grabenhäusl',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Grub',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Gschwend',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Hinterleiten',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Hinterloh',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Hochhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Hörbering',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Kendl',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Knappenfeld, Gem Surberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Krainwinkl',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Lehen in Surberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Lerchen',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Loch',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Mauerreuten',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Mitterleiten',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Mitterstatt',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Mühlpoint',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Oberachthal',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Oberhöhenwald',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Oberreut',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Point',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Rainer',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Roll',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Schnaidt',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Schwammgraben',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Schwarzenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Schütz',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Spittenreut',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Sprung',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Stadl',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Strußberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Surbergbichl',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Vorderleiten',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Vorderloh',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Wald',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Weitwies, Gem Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Wetzelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83364',
    city: 'Wildberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83365',
    city: 'Nußdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83367',
    city: 'Ebing',
    countrycode: 'DE',
  },
  {
    postalcode: '83367',
    city: 'Parschall',
    countrycode: 'DE',
  },
  {
    postalcode: '83367',
    city: 'Petting',
    countrycode: 'DE',
  },
  {
    postalcode: '83367',
    city: 'Schuhegg',
    countrycode: 'DE',
  },
  {
    postalcode: '83367',
    city: 'Unterholzen',
    countrycode: 'DE',
  },
  {
    postalcode: '83368',
    city: 'Anning',
    countrycode: 'DE',
  },
  {
    postalcode: '83368',
    city: 'Daxberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83368',
    city: 'Gigling',
    countrycode: 'DE',
  },
  {
    postalcode: '83368',
    city: 'Hochreit',
    countrycode: 'DE',
  },
  {
    postalcode: '83368',
    city: 'Mais',
    countrycode: 'DE',
  },
  {
    postalcode: '83368',
    city: 'Reit',
    countrycode: 'DE',
  },
  {
    postalcode: '83368',
    city: 'St Georgen',
    countrycode: 'DE',
  },
  {
    postalcode: '83368',
    city: 'Steineck',
    countrycode: 'DE',
  },
  {
    postalcode: '83368',
    city: 'Weisbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '83368',
    city: 'Weisham',
    countrycode: 'DE',
  },
  {
    postalcode: '83370',
    city: 'Seeon',
    countrycode: 'DE',
  },
  {
    postalcode: '83371',
    city: 'Buchberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83371',
    city: 'Fasanenjäger',
    countrycode: 'DE',
  },
  {
    postalcode: '83371',
    city: 'Hohenester',
    countrycode: 'DE',
  },
  {
    postalcode: '83371',
    city: 'Holzreit',
    countrycode: 'DE',
  },
  {
    postalcode: '83371',
    city: 'Höhenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83371',
    city: 'Oberhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '83371',
    city: 'Plattenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83371',
    city: 'Roitham',
    countrycode: 'DE',
  },
  {
    postalcode: '83371',
    city: 'Stein',
    countrycode: 'DE',
  },
  {
    postalcode: '83371',
    city: 'Zieglstadl',
    countrycode: 'DE',
  },
  {
    postalcode: '83373',
    city: 'Taching',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Arleting',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Frauenhurt',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Hurt',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Hölzl',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Hörzing',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Kirchstätt',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Niedling',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Oderberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Parzing',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Schmieding',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Traunwalchen',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Walchenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83374',
    city: 'Zweckham',
    countrycode: 'DE',
  },
  {
    postalcode: '83376',
    city: 'Apperting',
    countrycode: 'DE',
  },
  {
    postalcode: '83376',
    city: 'Höllthal',
    countrycode: 'DE',
  },
  {
    postalcode: '83376',
    city: 'Steinrab',
    countrycode: 'DE',
  },
  {
    postalcode: '83376',
    city: 'Truchtlaching',
    countrycode: 'DE',
  },
  {
    postalcode: '83377',
    city: 'Lug',
    countrycode: 'DE',
  },
  {
    postalcode: '83377',
    city: 'Vachendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Egerdach',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Fritzenweng',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Greinach',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Mooshäusl',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Moospoint',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Oberau',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Reichwimm',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Selberting',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Töfenreut',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Voitswinkl',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Wald',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Weberhäusl',
    countrycode: 'DE',
  },
  {
    postalcode: '83379',
    city: 'Weibhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '83395',
    city: 'Freilassing',
    countrycode: 'DE',
  },
  {
    postalcode: '83404',
    city: 'Ainring',
    countrycode: 'DE',
  },
  {
    postalcode: '83410',
    city: 'Laufen',
    countrycode: 'DE',
  },
  {
    postalcode: '83413',
    city: 'Fridolfing',
    countrycode: 'DE',
  },
  {
    postalcode: '83417',
    city: 'Kirchanschöring',
    countrycode: 'DE',
  },
  {
    postalcode: '83435',
    city: 'Bad Reichenhall',
    countrycode: 'DE',
  },
  {
    postalcode: '83451',
    city: 'Piding',
    countrycode: 'DE',
  },
  {
    postalcode: '83454',
    city: 'Anger',
    countrycode: 'DE',
  },
  {
    postalcode: '83457',
    city: 'Bayerisch Gmain',
    countrycode: 'DE',
  },
  {
    postalcode: '83458',
    city: 'Schneizlreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '83471',
    city: 'Berchtesgaden',
    countrycode: 'DE',
  },
  {
    postalcode: '83471',
    city: 'Engedey',
    countrycode: 'DE',
  },
  {
    postalcode: '83471',
    city: 'Kärlingerhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '83471',
    city: 'Purtschellerhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '83471',
    city: 'Schönau',
    countrycode: 'DE',
  },
  {
    postalcode: '83471',
    city: 'Stanggaß',
    countrycode: 'DE',
  },
  {
    postalcode: '83471',
    city: 'Stöhrhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '83483',
    city: 'Bischofswiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '83486',
    city: 'Blaueishütte',
    countrycode: 'DE',
  },
  {
    postalcode: '83486',
    city: 'Ramsau',
    countrycode: 'DE',
  },
  {
    postalcode: '83486',
    city: 'Watzmannhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '83486',
    city: 'Wimbachgrieshütte',
    countrycode: 'DE',
  },
  {
    postalcode: '83486',
    city: 'Wimbachschloß',
    countrycode: 'DE',
  },
  {
    postalcode: '83487',
    city: 'Marktschellenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83489',
    city: 'Strub',
    countrycode: 'DE',
  },
  {
    postalcode: '83512',
    city: 'Langwiederberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83512',
    city: 'Reitmehring',
    countrycode: 'DE',
  },
  {
    postalcode: '83512',
    city: 'Wasserburg',
    countrycode: 'DE',
  },
  {
    postalcode: '83527',
    city: 'Haag',
    countrycode: 'DE',
  },
  {
    postalcode: '83527',
    city: 'Kirchdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83530',
    city: 'Schnaitsee',
    countrycode: 'DE',
  },
  {
    postalcode: '83533',
    city: 'Edling',
    countrycode: 'DE',
  },
  {
    postalcode: '83536',
    city: 'Gars',
    countrycode: 'DE',
  },
  {
    postalcode: '83539',
    city: 'Pfaffing',
    countrycode: 'DE',
  },
  {
    postalcode: '83543',
    city: 'Rott',
    countrycode: 'DE',
  },
  {
    postalcode: '83544',
    city: 'Albaching',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Au',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Biburg',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Dornhecken',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Elbrechting',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Ensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Frimberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Gaisberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Gerer',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Hopfgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Höllthal',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Kronberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Obereinöd',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Reisleite',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Sattlthambach',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Schachen',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Stampfl',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Steinau',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Tiefenweg',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Trescherberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Untereinöd',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Untermödling',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Weingarten',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Winterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83546',
    city: 'Wörth',
    countrycode: 'DE',
  },
  {
    postalcode: '83547',
    city: 'Babensham',
    countrycode: 'DE',
  },
  {
    postalcode: '83549',
    city: 'Eiselfing',
    countrycode: 'DE',
  },
  {
    postalcode: '83550',
    city: 'Emmering',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Achen',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Durrhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Eck',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Englstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Evenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Eßbaum',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Feichten',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Freinberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Froitshub',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Furth',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Grub',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Halmannsöd',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Hatthal',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Hinterholzmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Hofstätt',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Hohenöd',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Hub',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Kirchensur',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Lacken',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Leiteneck',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Lindach',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Locking',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Mais',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Osendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Pfaffing',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Reit, Gem Kirchensur',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Ried',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Schachen',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Scherer',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Seeleiten',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Spittersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Stephanskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Streit',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Surau',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Unterstreit',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Unteröd',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Vorderholzmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Weitmoos',
    countrycode: 'DE',
  },
  {
    postalcode: '83552',
    city: 'Wimpasing',
    countrycode: 'DE',
  },
  {
    postalcode: '83553',
    city: 'Frauenneuharting',
    countrycode: 'DE',
  },
  {
    postalcode: '83555',
    city: 'Gars Bahnhof',
    countrycode: 'DE',
  },
  {
    postalcode: '83555',
    city: 'Haiden',
    countrycode: 'DE',
  },
  {
    postalcode: '83555',
    city: 'Hochstraß',
    countrycode: 'DE',
  },
  {
    postalcode: '83555',
    city: 'Mailham',
    countrycode: 'DE',
  },
  {
    postalcode: '83555',
    city: 'Thal',
    countrycode: 'DE',
  },
  {
    postalcode: '83556',
    city: 'Griesstätt',
    countrycode: 'DE',
  },
  {
    postalcode: '83558',
    city: 'Maitenbeth',
    countrycode: 'DE',
  },
  {
    postalcode: '83559',
    city: 'Heuwinkl',
    countrycode: 'DE',
  },
  {
    postalcode: '83559',
    city: 'Krücklham',
    countrycode: 'DE',
  },
  {
    postalcode: '83559',
    city: 'Lohen',
    countrycode: 'DE',
  },
  {
    postalcode: '83559',
    city: 'Mittergars',
    countrycode: 'DE',
  },
  {
    postalcode: '83559',
    city: 'Reiser',
    countrycode: 'DE',
  },
  {
    postalcode: '83561',
    city: 'Ramerberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83562',
    city: 'Rechtmehring',
    countrycode: 'DE',
  },
  {
    postalcode: '83564',
    city: 'Soyen',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Aichat',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Anger',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Ast',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Biebing',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Eichbichl',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Eschenlohe',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Gersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Hagenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Heimgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Kleinaschau',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Moosen',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Oed',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Ried',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Schaurach',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Tegernau',
    countrycode: 'DE',
  },
  {
    postalcode: '83565',
    city: 'Wimpersing',
    countrycode: 'DE',
  },
  {
    postalcode: '83567',
    city: 'Unterreit',
    countrycode: 'DE',
  },
  {
    postalcode: '83569',
    city: 'Vogtareuth',
    countrycode: 'DE',
  },
  {
    postalcode: '83607',
    city: 'Holzkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '83620',
    city: 'Feldkirchen-Westerham',
    countrycode: 'DE',
  },
  {
    postalcode: '83623',
    city: 'Dietramszell',
    countrycode: 'DE',
  },
  {
    postalcode: '83624',
    city: 'Otterfing',
    countrycode: 'DE',
  },
  {
    postalcode: '83626',
    city: 'Grabenstoffl',
    countrycode: 'DE',
  },
  {
    postalcode: '83626',
    city: 'Valley',
    countrycode: 'DE',
  },
  {
    postalcode: '83627',
    city: 'Warngau',
    countrycode: 'DE',
  },
  {
    postalcode: '83629',
    city: 'Weyarn',
    countrycode: 'DE',
  },
  {
    postalcode: '83646',
    city: 'Bad Tölz',
    countrycode: 'DE',
  },
  {
    postalcode: '83646',
    city: 'Wackersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83661',
    city: 'Lenggries',
    countrycode: 'DE',
  },
  {
    postalcode: '83666',
    city: 'Waakirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '83670',
    city: 'Bad Heilbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '83671',
    city: 'Benediktbeuern',
    countrycode: 'DE',
  },
  {
    postalcode: '83673',
    city: 'Bichl',
    countrycode: 'DE',
  },
  {
    postalcode: '83674',
    city: 'Gaißach',
    countrycode: 'DE',
  },
  {
    postalcode: '83676',
    city: 'Jachenau',
    countrycode: 'DE',
  },
  {
    postalcode: '83677',
    city: 'Greiling',
    countrycode: 'DE',
  },
  {
    postalcode: '83677',
    city: 'Reichersbeuern',
    countrycode: 'DE',
  },
  {
    postalcode: '83679',
    city: 'Sachsenkam',
    countrycode: 'DE',
  },
  {
    postalcode: '83684',
    city: 'Tegernsee',
    countrycode: 'DE',
  },
  {
    postalcode: '83700',
    city: 'Oberhof',
    countrycode: 'DE',
  },
  {
    postalcode: '83700',
    city: 'Reitrain',
    countrycode: 'DE',
  },
  {
    postalcode: '83700',
    city: 'Rottach-Egern',
    countrycode: 'DE',
  },
  {
    postalcode: '83700',
    city: 'Weißach',
    countrycode: 'DE',
  },
  {
    postalcode: '83703',
    city: 'Gmund',
    countrycode: 'DE',
  },
  {
    postalcode: '83707',
    city: 'Bad Wiessee',
    countrycode: 'DE',
  },
  {
    postalcode: '83707',
    city: 'Ringsee',
    countrycode: 'DE',
  },
  {
    postalcode: '83708',
    city: 'Kreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '83714',
    city: 'Fußstall',
    countrycode: 'DE',
  },
  {
    postalcode: '83714',
    city: 'Kogel',
    countrycode: 'DE',
  },
  {
    postalcode: '83714',
    city: 'Lehner',
    countrycode: 'DE',
  },
  {
    postalcode: '83714',
    city: 'Miesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '83714',
    city: 'Unterschönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83727',
    city: 'Rotwandhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '83727',
    city: 'Schliersee',
    countrycode: 'DE',
  },
  {
    postalcode: '83730',
    city: 'Fischbachau',
    countrycode: 'DE',
  },
  {
    postalcode: '83734',
    city: 'Hausham',
    countrycode: 'DE',
  },
  {
    postalcode: '83735',
    city: 'Bayrischzell',
    countrycode: 'DE',
  },
  {
    postalcode: '83737',
    city: 'Irschenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Ahrain',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Angl',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Aschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Bach',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Bichl',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Briefer',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Deining',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Ehgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Eyrain',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Furt',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Großhub',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Großschönau',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Grub',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Grund',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Grundbach',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Harraß',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Hatzl',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Heißkistler',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Hilgenrain',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Hinterholz',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Hofreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Kalten',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Kleinhub',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Kolmberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Köck',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Lehermann',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Loder',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Moos',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Moosweber',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Neuradthal',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Niklasreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Oberkretzach',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Offenstätter',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Radthal',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Riedl',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Schlosser',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Sonnenreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Starzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Streitau',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Wartbichl',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Wiedmann',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Wieser',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Windwart',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Wörnsmühl',
    countrycode: 'DE',
  },
  {
    postalcode: '83739',
    city: 'Zieglhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '84028',
    city: 'Landshut',
    countrycode: 'DE',
  },
  {
    postalcode: '84030',
    city: 'Ergolding',
    countrycode: 'DE',
  },
  {
    postalcode: '84030',
    city: 'Landshut',
    countrycode: 'DE',
  },
  {
    postalcode: '84032',
    city: 'Altdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '84032',
    city: 'Landshut',
    countrycode: 'DE',
  },
  {
    postalcode: '84032',
    city: 'Linden',
    countrycode: 'DE',
  },
  {
    postalcode: '84034',
    city: 'Landshut',
    countrycode: 'DE',
  },
  {
    postalcode: '84036',
    city: 'Kumhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '84036',
    city: 'Landshut',
    countrycode: 'DE',
  },
  {
    postalcode: '84036',
    city: 'Seepoint',
    countrycode: 'DE',
  },
  {
    postalcode: '84048',
    city: 'Mainburg',
    countrycode: 'DE',
  },
  {
    postalcode: '84051',
    city: 'Essenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84056',
    city: 'Rottenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '84061',
    city: 'Ergoldsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84066',
    city: 'Mallersdorf-Pfaffenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '84069',
    city: 'Schierling',
    countrycode: 'DE',
  },
  {
    postalcode: '84072',
    city: 'Au',
    countrycode: 'DE',
  },
  {
    postalcode: '84076',
    city: 'Pfeffenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '84076',
    city: 'Spitzau, Gem Attenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '84079',
    city: 'Bruckberg',
    countrycode: 'DE',
  },
  {
    postalcode: '84082',
    city: 'Laberweinting',
    countrycode: 'DE',
  },
  {
    postalcode: '84085',
    city: 'Langquaid',
    countrycode: 'DE',
  },
  {
    postalcode: '84088',
    city: 'Neufahrn',
    countrycode: 'DE',
  },
  {
    postalcode: '84088',
    city: 'Salzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '84089',
    city: 'Aiglsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84091',
    city: 'Attenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '84092',
    city: 'Bayerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84094',
    city: 'Elsendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '84095',
    city: 'Furth',
    countrycode: 'DE',
  },
  {
    postalcode: '84097',
    city: 'Herrngiersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '84098',
    city: 'Hohenthann',
    countrycode: 'DE',
  },
  {
    postalcode: '84100',
    city: 'Niederaichbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84101',
    city: 'Obersüßbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84103',
    city: 'Postau',
    countrycode: 'DE',
  },
  {
    postalcode: '84104',
    city: 'Rudelzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '84106',
    city: 'Volkenschwand',
    countrycode: 'DE',
  },
  {
    postalcode: '84107',
    city: 'Weihmichl',
    countrycode: 'DE',
  },
  {
    postalcode: '84109',
    city: 'Wörth',
    countrycode: 'DE',
  },
  {
    postalcode: '84130',
    city: 'Dingolfing',
    countrycode: 'DE',
  },
  {
    postalcode: '84137',
    city: 'Aiteröd',
    countrycode: 'DE',
  },
  {
    postalcode: '84137',
    city: 'Vilsbiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '84140',
    city: 'Gangkofen',
    countrycode: 'DE',
  },
  {
    postalcode: '84144',
    city: 'Geisenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '84149',
    city: 'Velden',
    countrycode: 'DE',
  },
  {
    postalcode: '84152',
    city: 'Mengkofen',
    countrycode: 'DE',
  },
  {
    postalcode: '84155',
    city: 'Bodenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84160',
    city: 'Frontenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '84163',
    city: 'Marklkofen',
    countrycode: 'DE',
  },
  {
    postalcode: '84163',
    city: 'Wunder',
    countrycode: 'DE',
  },
  {
    postalcode: '84164',
    city: 'Dreifaltigkeitsberg, Gem Weng',
    countrycode: 'DE',
  },
  {
    postalcode: '84164',
    city: 'Moosthenning',
    countrycode: 'DE',
  },
  {
    postalcode: '84166',
    city: 'Adlkofen',
    countrycode: 'DE',
  },
  {
    postalcode: '84168',
    city: 'Aham',
    countrycode: 'DE',
  },
  {
    postalcode: '84169',
    city: 'Altfraunhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '84171',
    city: 'Baierbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84172',
    city: 'Buch',
    countrycode: 'DE',
  },
  {
    postalcode: '84174',
    city: 'Eching',
    countrycode: 'DE',
  },
  {
    postalcode: '84175',
    city: 'Gerzen',
    countrycode: 'DE',
  },
  {
    postalcode: '84175',
    city: 'Schalkham',
    countrycode: 'DE',
  },
  {
    postalcode: '84177',
    city: 'Gottfrieding',
    countrycode: 'DE',
  },
  {
    postalcode: '84178',
    city: 'Kröning',
    countrycode: 'DE',
  },
  {
    postalcode: '84180',
    city: 'Loiching',
    countrycode: 'DE',
  },
  {
    postalcode: '84181',
    city: 'Neufraunhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '84183',
    city: 'Niederviehbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84184',
    city: 'Tiefenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84186',
    city: 'Vilsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '84187',
    city: 'Weng',
    countrycode: 'DE',
  },
  {
    postalcode: '84189',
    city: 'Wurmsham',
    countrycode: 'DE',
  },
  {
    postalcode: '84307',
    city: 'Eggenfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '84323',
    city: 'Massing',
    countrycode: 'DE',
  },
  {
    postalcode: '84326',
    city: 'Falkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '84326',
    city: 'Rimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84329',
    city: 'Wurmannsquick',
    countrycode: 'DE',
  },
  {
    postalcode: '84332',
    city: 'Hebertsfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '84333',
    city: 'Malgersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '84335',
    city: 'Mitterskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84337',
    city: 'Schönau',
    countrycode: 'DE',
  },
  {
    postalcode: '84339',
    city: 'Unterdietfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '84347',
    city: 'Pfarrkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84359',
    city: 'Simbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84364',
    city: 'Bad Birnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84367',
    city: 'Reut',
    countrycode: 'DE',
  },
  {
    postalcode: '84367',
    city: 'Tann',
    countrycode: 'DE',
  },
  {
    postalcode: '84367',
    city: 'Zeilarn',
    countrycode: 'DE',
  },
  {
    postalcode: '84371',
    city: 'Dachsbergau',
    countrycode: 'DE',
  },
  {
    postalcode: '84371',
    city: 'Triftern',
    countrycode: 'DE',
  },
  {
    postalcode: '84375',
    city: 'Kirchdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '84378',
    city: 'Dietersburg',
    countrycode: 'DE',
  },
  {
    postalcode: '84381',
    city: 'Johanniskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84384',
    city: 'Wittibreut',
    countrycode: 'DE',
  },
  {
    postalcode: '84385',
    city: 'Egglham',
    countrycode: 'DE',
  },
  {
    postalcode: '84385',
    city: 'Schachahof',
    countrycode: 'DE',
  },
  {
    postalcode: '84387',
    city: 'Julbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84389',
    city: 'Postmünster',
    countrycode: 'DE',
  },
  {
    postalcode: '84405',
    city: 'Brunn',
    countrycode: 'DE',
  },
  {
    postalcode: '84405',
    city: 'Dorfen',
    countrycode: 'DE',
  },
  {
    postalcode: '84416',
    city: 'Inning',
    countrycode: 'DE',
  },
  {
    postalcode: '84416',
    city: 'Taufkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84419',
    city: 'Birnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84419',
    city: 'Obertaufkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84419',
    city: 'Schwindegg',
    countrycode: 'DE',
  },
  {
    postalcode: '84424',
    city: 'Isen',
    countrycode: 'DE',
  },
  {
    postalcode: '84427',
    city: 'Sankt Wolfgang',
    countrycode: 'DE',
  },
  {
    postalcode: '84428',
    city: 'Bachzelten',
    countrycode: 'DE',
  },
  {
    postalcode: '84428',
    city: 'Buchbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84431',
    city: 'Heldenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '84431',
    city: 'Rattenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84432',
    city: 'Hohenpolding',
    countrycode: 'DE',
  },
  {
    postalcode: '84434',
    city: 'Kirchberg',
    countrycode: 'DE',
  },
  {
    postalcode: '84435',
    city: 'Lengdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '84437',
    city: 'Reichertsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '84439',
    city: 'Steinkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84453',
    city: 'Mühldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '84478',
    city: 'Waldkraiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '84489',
    city: 'Burghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '84494',
    city: 'Lohkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84494',
    city: 'Neumarkt-Sankt Veit',
    countrycode: 'DE',
  },
  {
    postalcode: '84494',
    city: 'Niederbergkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84494',
    city: 'Niedertaufkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84503',
    city: 'Altötting',
    countrycode: 'DE',
  },
  {
    postalcode: '84503',
    city: 'Holzhauser Eck',
    countrycode: 'DE',
  },
  {
    postalcode: '84508',
    city: 'Burgkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84513',
    city: 'Erharting',
    countrycode: 'DE',
  },
  {
    postalcode: '84513',
    city: 'Töging',
    countrycode: 'DE',
  },
  {
    postalcode: '84518',
    city: 'Garching',
    countrycode: 'DE',
  },
  {
    postalcode: '84524',
    city: 'Neuötting',
    countrycode: 'DE',
  },
  {
    postalcode: '84529',
    city: 'Tittmoning',
    countrycode: 'DE',
  },
  {
    postalcode: '84533',
    city: 'Haiming',
    countrycode: 'DE',
  },
  {
    postalcode: '84533',
    city: 'Marktl',
    countrycode: 'DE',
  },
  {
    postalcode: '84533',
    city: 'Stammham',
    countrycode: 'DE',
  },
  {
    postalcode: '84539',
    city: 'Ampfing',
    countrycode: 'DE',
  },
  {
    postalcode: '84539',
    city: 'Zangberg',
    countrycode: 'DE',
  },
  {
    postalcode: '84543',
    city: 'Winhöring',
    countrycode: 'DE',
  },
  {
    postalcode: '84544',
    city: 'Aschau',
    countrycode: 'DE',
  },
  {
    postalcode: '84546',
    city: 'Egglkofen',
    countrycode: 'DE',
  },
  {
    postalcode: '84547',
    city: 'Emmerting',
    countrycode: 'DE',
  },
  {
    postalcode: '84549',
    city: 'Engelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '84550',
    city: 'Feichten',
    countrycode: 'DE',
  },
  {
    postalcode: '84552',
    city: 'Geratskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84553',
    city: 'Halsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84555',
    city: 'Jettenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84556',
    city: 'Kastl',
    countrycode: 'DE',
  },
  {
    postalcode: '84558',
    city: 'Kirchweidach',
    countrycode: 'DE',
  },
  {
    postalcode: '84558',
    city: 'Tyrlaching',
    countrycode: 'DE',
  },
  {
    postalcode: '84559',
    city: 'Kraiburg',
    countrycode: 'DE',
  },
  {
    postalcode: '84561',
    city: 'Mehring',
    countrycode: 'DE',
  },
  {
    postalcode: '84562',
    city: 'Mettenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '84564',
    city: 'Oberbergkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84565',
    city: 'Oberneukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84567',
    city: 'Erlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '84567',
    city: 'Perach',
    countrycode: 'DE',
  },
  {
    postalcode: '84568',
    city: 'Pleiskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84570',
    city: 'Polling',
    countrycode: 'DE',
  },
  {
    postalcode: '84571',
    city: 'Reischach',
    countrycode: 'DE',
  },
  {
    postalcode: '84573',
    city: 'Schönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '84574',
    city: 'Taufkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '84576',
    city: 'Teising',
    countrycode: 'DE',
  },
  {
    postalcode: '84577',
    city: 'Tüßling',
    countrycode: 'DE',
  },
  {
    postalcode: '84579',
    city: 'Unterneukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '85049',
    city: 'Ingolstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '85051',
    city: 'Ingolstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '85053',
    city: 'Ingolstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '85055',
    city: 'Ingolstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '85057',
    city: 'Ingolstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '85072',
    city: 'Eichstätt',
    countrycode: 'DE',
  },
  {
    postalcode: '85072',
    city: 'Harthof',
    countrycode: 'DE',
  },
  {
    postalcode: '85077',
    city: 'Manching',
    countrycode: 'DE',
  },
  {
    postalcode: '85084',
    city: 'Reichertshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '85088',
    city: 'Vohburg',
    countrycode: 'DE',
  },
  {
    postalcode: '85092',
    city: 'Kösching',
    countrycode: 'DE',
  },
  {
    postalcode: '85092',
    city: 'Köschinger Waldhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '85095',
    city: 'Denkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '85098',
    city: 'Großmehring',
    countrycode: 'DE',
  },
  {
    postalcode: '85101',
    city: 'Lenting',
    countrycode: 'DE',
  },
  {
    postalcode: '85104',
    city: 'Pförring',
    countrycode: 'DE',
  },
  {
    postalcode: '85107',
    city: 'Baar-Ebenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85110',
    city: 'Kipfenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '85111',
    city: 'Adelschlag',
    countrycode: 'DE',
  },
  {
    postalcode: '85113',
    city: 'Böhmfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '85114',
    city: 'Buxheim',
    countrycode: 'DE',
  },
  {
    postalcode: '85116',
    city: 'Egweil',
    countrycode: 'DE',
  },
  {
    postalcode: '85117',
    city: 'Eitensheim',
    countrycode: 'DE',
  },
  {
    postalcode: '85119',
    city: 'Ernsgaden',
    countrycode: 'DE',
  },
  {
    postalcode: '85120',
    city: 'Hepberg',
    countrycode: 'DE',
  },
  {
    postalcode: '85122',
    city: 'Hitzhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '85123',
    city: 'Karlskron',
    countrycode: 'DE',
  },
  {
    postalcode: '85125',
    city: 'Kinding',
    countrycode: 'DE',
  },
  {
    postalcode: '85126',
    city: 'Münchsmünster',
    countrycode: 'DE',
  },
  {
    postalcode: '85128',
    city: 'Nassenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '85129',
    city: 'Oberdolling',
    countrycode: 'DE',
  },
  {
    postalcode: '85131',
    city: 'Pollenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '85132',
    city: 'Schernfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '85134',
    city: 'Stammham',
    countrycode: 'DE',
  },
  {
    postalcode: '85135',
    city: 'Titting',
    countrycode: 'DE',
  },
  {
    postalcode: '85137',
    city: 'Walting',
    countrycode: 'DE',
  },
  {
    postalcode: '85139',
    city: 'Wettstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '85221',
    city: 'Dachau',
    countrycode: 'DE',
  },
  {
    postalcode: '85229',
    city: 'Markt Indersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '85232',
    city: 'Bergkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '85235',
    city: 'Odelzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85235',
    city: 'Pfaffenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '85238',
    city: 'Petershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85241',
    city: 'Hebertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85244',
    city: 'Röhrmoos',
    countrycode: 'DE',
  },
  {
    postalcode: '85247',
    city: 'Schwabhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85250',
    city: 'Altomünster',
    countrycode: 'DE',
  },
  {
    postalcode: '85253',
    city: 'Erdweg',
    countrycode: 'DE',
  },
  {
    postalcode: '85254',
    city: 'Einsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '85254',
    city: 'Fuchsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '85254',
    city: 'Sulzemoos',
    countrycode: 'DE',
  },
  {
    postalcode: '85256',
    city: 'Vierkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '85258',
    city: 'Weichs',
    countrycode: 'DE',
  },
  {
    postalcode: '85259',
    city: 'Wiedenzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85276',
    city: 'Feldmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '85276',
    city: 'Hettenshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85276',
    city: 'Jahnhöhe',
    countrycode: 'DE',
  },
  {
    postalcode: '85276',
    city: 'Pfaffenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '85276',
    city: 'Posthof',
    countrycode: 'DE',
  },
  {
    postalcode: '85276',
    city: 'Reisgang',
    countrycode: 'DE',
  },
  {
    postalcode: '85276',
    city: 'Washof',
    countrycode: 'DE',
  },
  {
    postalcode: '85276',
    city: 'Webling',
    countrycode: 'DE',
  },
  {
    postalcode: '85283',
    city: 'Wolnzach',
    countrycode: 'DE',
  },
  {
    postalcode: '85290',
    city: 'Geisenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '85293',
    city: 'Reichertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85296',
    city: 'Rohrbach',
    countrycode: 'DE',
  },
  {
    postalcode: '85298',
    city: 'Scheyern',
    countrycode: 'DE',
  },
  {
    postalcode: '85301',
    city: 'Schweitenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '85302',
    city: 'Gerolsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '85304',
    city: 'Ehrensberg',
    countrycode: 'DE',
  },
  {
    postalcode: '85304',
    city: 'Harres',
    countrycode: 'DE',
  },
  {
    postalcode: '85304',
    city: 'Ilmmünster',
    countrycode: 'DE',
  },
  {
    postalcode: '85304',
    city: 'Leiten',
    countrycode: 'DE',
  },
  {
    postalcode: '85304',
    city: 'Prambach',
    countrycode: 'DE',
  },
  {
    postalcode: '85304',
    city: 'Schaibmaierhof',
    countrycode: 'DE',
  },
  {
    postalcode: '85304',
    city: 'Winden',
    countrycode: 'DE',
  },
  {
    postalcode: '85305',
    city: 'Jetzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '85307',
    city: 'Entrischenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '85307',
    city: 'Paunzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85307',
    city: 'Streitberg',
    countrycode: 'DE',
  },
  {
    postalcode: '85309',
    city: 'Pörnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '85354',
    city: 'Freising',
    countrycode: 'DE',
  },
  {
    postalcode: '85356',
    city: 'Freising',
    countrycode: 'DE',
  },
  {
    postalcode: '85356',
    city: 'München-Flughafen',
    countrycode: 'DE',
  },
  {
    postalcode: '85368',
    city: 'Moosburg',
    countrycode: 'DE',
  },
  {
    postalcode: '85368',
    city: 'Wang',
    countrycode: 'DE',
  },
  {
    postalcode: '85375',
    city: 'Neufahrn',
    countrycode: 'DE',
  },
  {
    postalcode: '85376',
    city: 'Giggenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85376',
    city: 'Hetzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85376',
    city: 'Massenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85376',
    city: 'Moosmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '85376',
    city: 'Schaidenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85386',
    city: 'Eching',
    countrycode: 'DE',
  },
  {
    postalcode: '85391',
    city: 'Allershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85395',
    city: 'Attenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '85395',
    city: 'Oberholzhäuseln',
    countrycode: 'DE',
  },
  {
    postalcode: '85395',
    city: 'Wolfersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '85399',
    city: 'Hallbergmoos',
    countrycode: 'DE',
  },
  {
    postalcode: '85402',
    city: 'Kranzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '85405',
    city: 'Nandlstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '85406',
    city: 'Wälschbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '85406',
    city: 'Zolling',
    countrycode: 'DE',
  },
  {
    postalcode: '85408',
    city: 'Gammelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '85410',
    city: 'Haag',
    countrycode: 'DE',
  },
  {
    postalcode: '85411',
    city: 'Hohenkammer',
    countrycode: 'DE',
  },
  {
    postalcode: '85413',
    city: 'Hörgertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85414',
    city: 'Kirchdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '85416',
    city: 'Langenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '85417',
    city: 'Marzling',
    countrycode: 'DE',
  },
  {
    postalcode: '85419',
    city: 'Mauern',
    countrycode: 'DE',
  },
  {
    postalcode: '85435',
    city: 'Erding',
    countrycode: 'DE',
  },
  {
    postalcode: '85445',
    city: 'Oberding',
    countrycode: 'DE',
  },
  {
    postalcode: '85447',
    city: 'Fraunberg',
    countrycode: 'DE',
  },
  {
    postalcode: '85447',
    city: 'Weg',
    countrycode: 'DE',
  },
  {
    postalcode: '85452',
    city: 'Moosinning',
    countrycode: 'DE',
  },
  {
    postalcode: '85456',
    city: 'Wartenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '85457',
    city: 'Wörth',
    countrycode: 'DE',
  },
  {
    postalcode: '85459',
    city: 'Berglern',
    countrycode: 'DE',
  },
  {
    postalcode: '85461',
    city: 'Bockhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '85462',
    city: 'Eitting',
    countrycode: 'DE',
  },
  {
    postalcode: '85464',
    city: 'Finsing',
    countrycode: 'DE',
  },
  {
    postalcode: '85465',
    city: 'Fürnsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '85465',
    city: 'Langenpreising',
    countrycode: 'DE',
  },
  {
    postalcode: '85467',
    city: 'Neuching',
    countrycode: 'DE',
  },
  {
    postalcode: '85469',
    city: 'Walpertskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '85521',
    city: 'Ottobrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '85521',
    city: 'Riemerling',
    countrycode: 'DE',
  },
  {
    postalcode: '85540',
    city: 'Haar',
    countrycode: 'DE',
  },
  {
    postalcode: '85551',
    city: 'Kirchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '85560',
    city: 'Ebersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '85567',
    city: 'Bruck',
    countrycode: 'DE',
  },
  {
    postalcode: '85567',
    city: 'Grafing',
    countrycode: 'DE',
  },
  {
    postalcode: '85570',
    city: 'Köppelmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '85570',
    city: 'Markt Schwaben',
    countrycode: 'DE',
  },
  {
    postalcode: '85570',
    city: 'Ottenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '85579',
    city: 'Neubiberg',
    countrycode: 'DE',
  },
  {
    postalcode: '85586',
    city: 'Poing',
    countrycode: 'DE',
  },
  {
    postalcode: '85591',
    city: 'Vaterstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '85598',
    city: 'Baldham',
    countrycode: 'DE',
  },
  {
    postalcode: '85599',
    city: 'Hergolding',
    countrycode: 'DE',
  },
  {
    postalcode: '85599',
    city: 'Parsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '85604',
    city: 'Zorneding',
    countrycode: 'DE',
  },
  {
    postalcode: '85609',
    city: 'Aschheim',
    countrycode: 'DE',
  },
  {
    postalcode: '85614',
    city: 'Kirchseeon',
    countrycode: 'DE',
  },
  {
    postalcode: '85617',
    city: 'Aßling',
    countrycode: 'DE',
  },
  {
    postalcode: '85622',
    city: 'Feldkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '85622',
    city: 'Weißenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '85625',
    city: 'Baiern',
    countrycode: 'DE',
  },
  {
    postalcode: '85625',
    city: 'Glonn',
    countrycode: 'DE',
  },
  {
    postalcode: '85630',
    city: 'Grasbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '85635',
    city: 'Höhenkirchen-Siegertsbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '85640',
    city: 'Putzbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '85643',
    city: 'Steinhöring',
    countrycode: 'DE',
  },
  {
    postalcode: '85646',
    city: 'Anzing',
    countrycode: 'DE',
  },
  {
    postalcode: '85646',
    city: 'Neufarn',
    countrycode: 'DE',
  },
  {
    postalcode: '85646',
    city: 'Purfing',
    countrycode: 'DE',
  },
  {
    postalcode: '85649',
    city: 'Brunnthal',
    countrycode: 'DE',
  },
  {
    postalcode: '85652',
    city: 'Pliening',
    countrycode: 'DE',
  },
  {
    postalcode: '85653',
    city: 'Aying',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Blindham',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Graß',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Griesstätt',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Großhelfendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Göggenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Heimatshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Helfendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Kaltenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Kaps',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Kleinhelfendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Kleinkarolinenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Loibersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Oberschops',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Rauchenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Spielberg',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Trautshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '85655',
    city: 'Unterschops',
    countrycode: 'DE',
  },
  {
    postalcode: '85656',
    city: 'Buch',
    countrycode: 'DE',
  },
  {
    postalcode: '85658',
    city: 'Egmating',
    countrycode: 'DE',
  },
  {
    postalcode: '85659',
    city: 'Forstern',
    countrycode: 'DE',
  },
  {
    postalcode: '85661',
    city: 'Forstinning',
    countrycode: 'DE',
  },
  {
    postalcode: '85662',
    city: 'Hohenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '85664',
    city: 'Hohenlinden',
    countrycode: 'DE',
  },
  {
    postalcode: '85665',
    city: 'Moosach',
    countrycode: 'DE',
  },
  {
    postalcode: '85667',
    city: 'Oberpframmern',
    countrycode: 'DE',
  },
  {
    postalcode: '85669',
    city: 'Pastetten',
    countrycode: 'DE',
  },
  {
    postalcode: '85669',
    city: 'Ödenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '85716',
    city: 'Unterschleißheim',
    countrycode: 'DE',
  },
  {
    postalcode: '85737',
    city: 'Ismaning',
    countrycode: 'DE',
  },
  {
    postalcode: '85737',
    city: 'Zwillingshof',
    countrycode: 'DE',
  },
  {
    postalcode: '85748',
    city: 'Garching',
    countrycode: 'DE',
  },
  {
    postalcode: '85757',
    city: 'Karlsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '85764',
    city: 'Hackermoos',
    countrycode: 'DE',
  },
  {
    postalcode: '85764',
    city: 'Oberschleißheim',
    countrycode: 'DE',
  },
  {
    postalcode: '85774',
    city: 'Unterföhring',
    countrycode: 'DE',
  },
  {
    postalcode: '85777',
    city: 'Fahrenzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '85778',
    city: 'Haimhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86150',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86152',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86153',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86154',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86156',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86157',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86159',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86161',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86163',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86165',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86167',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86169',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86179',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86199',
    city: 'Augsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86316',
    city: 'Friedberg',
    countrycode: 'DE',
  },
  {
    postalcode: '86343',
    city: 'Königsbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '86356',
    city: 'Neusäß',
    countrycode: 'DE',
  },
  {
    postalcode: '86368',
    city: 'Gersthofen',
    countrycode: 'DE',
  },
  {
    postalcode: '86381',
    city: 'Krumbach',
    countrycode: 'DE',
  },
  {
    postalcode: '86391',
    city: 'Stadtbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '86399',
    city: 'Bobingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86405',
    city: 'Meitingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86415',
    city: 'Mering',
    countrycode: 'DE',
  },
  {
    postalcode: '86420',
    city: 'Diedorf',
    countrycode: 'DE',
  },
  {
    postalcode: '86424',
    city: 'Dinkelscherben',
    countrycode: 'DE',
  },
  {
    postalcode: '86438',
    city: 'Kissing',
    countrycode: 'DE',
  },
  {
    postalcode: '86441',
    city: 'Zusmarshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86444',
    city: 'Affing',
    countrycode: 'DE',
  },
  {
    postalcode: '86447',
    city: 'Aindling',
    countrycode: 'DE',
  },
  {
    postalcode: '86447',
    city: 'Todtenweis',
    countrycode: 'DE',
  },
  {
    postalcode: '86450',
    city: 'Altenmünster',
    countrycode: 'DE',
  },
  {
    postalcode: '86453',
    city: 'Dasing',
    countrycode: 'DE',
  },
  {
    postalcode: '86456',
    city: 'Gablingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86456',
    city: 'Peterhof',
    countrycode: 'DE',
  },
  {
    postalcode: '86459',
    city: 'Gessertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86462',
    city: 'Langweid',
    countrycode: 'DE',
  },
  {
    postalcode: '86465',
    city: 'Heretsried',
    countrycode: 'DE',
  },
  {
    postalcode: '86465',
    city: 'Welden',
    countrycode: 'DE',
  },
  {
    postalcode: '86470',
    city: 'Thannhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86473',
    city: 'Ziemetshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86476',
    city: 'Neuburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86477',
    city: 'Adelsried',
    countrycode: 'DE',
  },
  {
    postalcode: '86479',
    city: 'Aichen',
    countrycode: 'DE',
  },
  {
    postalcode: '86480',
    city: 'Aletshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86480',
    city: 'Waltenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86482',
    city: 'Aystetten',
    countrycode: 'DE',
  },
  {
    postalcode: '86483',
    city: 'Balzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86485',
    city: 'Biberbach',
    countrycode: 'DE',
  },
  {
    postalcode: '86486',
    city: 'Bonstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '86488',
    city: 'Breitenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '86489',
    city: 'Deisenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86491',
    city: 'Ebershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86492',
    city: 'Egling',
    countrycode: 'DE',
  },
  {
    postalcode: '86494',
    city: 'Emersacker',
    countrycode: 'DE',
  },
  {
    postalcode: '86495',
    city: 'Eurasburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86497',
    city: 'Horgau',
    countrycode: 'DE',
  },
  {
    postalcode: '86498',
    city: 'Kettershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86500',
    city: 'Kutzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86502',
    city: 'Laugna',
    countrycode: 'DE',
  },
  {
    postalcode: '86504',
    city: 'Merching',
    countrycode: 'DE',
  },
  {
    postalcode: '86505',
    city: 'Münsterhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86507',
    city: 'Kleinaitingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86507',
    city: 'Oberottmarshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86508',
    city: 'Rehling',
    countrycode: 'DE',
  },
  {
    postalcode: '86510',
    city: 'Ried',
    countrycode: 'DE',
  },
  {
    postalcode: '86511',
    city: 'Schmiechen',
    countrycode: 'DE',
  },
  {
    postalcode: '86513',
    city: 'Ursberg',
    countrycode: 'DE',
  },
  {
    postalcode: '86514',
    city: 'Ustersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '86517',
    city: 'Wehringen',
    countrycode: 'DE',
  },
  {
    postalcode: '86519',
    city: 'Wiesenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '86529',
    city: 'Schrobenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86551',
    city: 'Aichach',
    countrycode: 'DE',
  },
  {
    postalcode: '86554',
    city: 'Pöttmes',
    countrycode: 'DE',
  },
  {
    postalcode: '86556',
    city: 'Kühbach',
    countrycode: 'DE',
  },
  {
    postalcode: '86558',
    city: 'Hohenwart',
    countrycode: 'DE',
  },
  {
    postalcode: '86559',
    city: 'Adelzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86561',
    city: 'Aresing',
    countrycode: 'DE',
  },
  {
    postalcode: '86562',
    city: 'Berg im Gau',
    countrycode: 'DE',
  },
  {
    postalcode: '86564',
    city: 'Brunnen',
    countrycode: 'DE',
  },
  {
    postalcode: '86565',
    city: 'Gachenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '86567',
    city: 'Hilgertshausen-Tandern',
    countrycode: 'DE',
  },
  {
    postalcode: '86568',
    city: 'Hollenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '86570',
    city: 'Inchenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '86571',
    city: 'Langenmosen',
    countrycode: 'DE',
  },
  {
    postalcode: '86573',
    city: 'Obergriesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '86574',
    city: 'Petersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '86576',
    city: 'Schiltberg',
    countrycode: 'DE',
  },
  {
    postalcode: '86577',
    city: 'Sielenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '86579',
    city: 'Waidhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '86609',
    city: 'Donauwörth',
    countrycode: 'DE',
  },
  {
    postalcode: '86633',
    city: 'Neuburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86637',
    city: 'Binswangen',
    countrycode: 'DE',
  },
  {
    postalcode: '86637',
    city: 'Villenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '86637',
    city: 'Wertingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86637',
    city: 'Zusamaltheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86641',
    city: 'Rain',
    countrycode: 'DE',
  },
  {
    postalcode: '86643',
    city: 'Rennertshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '86647',
    city: 'Buttenwiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '86650',
    city: 'Wemding',
    countrycode: 'DE',
  },
  {
    postalcode: '86653',
    city: 'Daiting',
    countrycode: 'DE',
  },
  {
    postalcode: '86653',
    city: 'Monheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86655',
    city: 'Harburg',
    countrycode: 'DE',
  },
  {
    postalcode: '86657',
    city: 'Bissingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86660',
    city: 'Tapfheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86663',
    city: 'Asbach-Bäumenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86666',
    city: 'Burgheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86668',
    city: 'Karlshuld',
    countrycode: 'DE',
  },
  {
    postalcode: '86669',
    city: 'Königsmoos',
    countrycode: 'DE',
  },
  {
    postalcode: '86672',
    city: 'Thierhaupten',
    countrycode: 'DE',
  },
  {
    postalcode: '86673',
    city: 'Bergheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86674',
    city: 'Baar',
    countrycode: 'DE',
  },
  {
    postalcode: '86675',
    city: 'Buchdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '86676',
    city: 'Ehekirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '86678',
    city: 'Ehingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86679',
    city: 'Ellgau',
    countrycode: 'DE',
  },
  {
    postalcode: '86681',
    city: 'Fünfstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '86682',
    city: 'Genderkingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86684',
    city: 'Holzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86685',
    city: 'Huisheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86687',
    city: 'Kaisheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86688',
    city: 'Marxheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86690',
    city: 'Mertingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86692',
    city: 'Münster',
    countrycode: 'DE',
  },
  {
    postalcode: '86694',
    city: 'Niederschönenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '86695',
    city: 'Allmannshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '86695',
    city: 'Nordendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '86697',
    city: 'Oberhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86698',
    city: 'Oberndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '86700',
    city: 'Otting',
    countrycode: 'DE',
  },
  {
    postalcode: '86701',
    city: 'Rohrenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '86703',
    city: 'Rögling',
    countrycode: 'DE',
  },
  {
    postalcode: '86704',
    city: 'Tagmersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86706',
    city: 'Weichering',
    countrycode: 'DE',
  },
  {
    postalcode: '86707',
    city: 'Kühlenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '86707',
    city: 'Westendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '86709',
    city: 'Wolferstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '86720',
    city: 'Nördlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86732',
    city: 'Oettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86733',
    city: 'Alerheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86735',
    city: 'Amerdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86735',
    city: 'Forheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86736',
    city: 'Auhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86738',
    city: 'Deiningen',
    countrycode: 'DE',
  },
  {
    postalcode: '86739',
    city: 'Ederheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86739',
    city: 'Karlshof',
    countrycode: 'DE',
  },
  {
    postalcode: '86741',
    city: 'Ehingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86742',
    city: 'Fremdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86744',
    city: 'Hainsfarth',
    countrycode: 'DE',
  },
  {
    postalcode: '86745',
    city: 'Hohenaltheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86747',
    city: 'Maihingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86748',
    city: 'Marktoffingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86750',
    city: 'Megesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86751',
    city: 'Mönchsdeggingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86753',
    city: 'Möttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86754',
    city: 'Munningen',
    countrycode: 'DE',
  },
  {
    postalcode: '86756',
    city: 'Reimlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86757',
    city: 'Wallerstein',
    countrycode: 'DE',
  },
  {
    postalcode: '86759',
    city: 'Wechingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86807',
    city: 'Buchloe',
    countrycode: 'DE',
  },
  {
    postalcode: '86825',
    city: 'Bad Wörishofen',
    countrycode: 'DE',
  },
  {
    postalcode: '86830',
    city: 'Schwabmünchen',
    countrycode: 'DE',
  },
  {
    postalcode: '86833',
    city: 'Ettringen',
    countrycode: 'DE',
  },
  {
    postalcode: '86836',
    city: 'Graben',
    countrycode: 'DE',
  },
  {
    postalcode: '86836',
    city: 'Gutshof Lechfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '86836',
    city: 'Klosterlechfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '86836',
    city: 'Obermeitingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86836',
    city: 'Untermeitingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86842',
    city: 'Türkheim',
    countrycode: 'DE',
  },
  {
    postalcode: '86845',
    city: 'Großaitingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86850',
    city: 'Fischach',
    countrycode: 'DE',
  },
  {
    postalcode: '86853',
    city: 'Langerringen',
    countrycode: 'DE',
  },
  {
    postalcode: '86854',
    city: 'Amberg',
    countrycode: 'DE',
  },
  {
    postalcode: '86856',
    city: 'Hiltenfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86857',
    city: 'Hurlach',
    countrycode: 'DE',
  },
  {
    postalcode: '86859',
    city: 'Igling',
    countrycode: 'DE',
  },
  {
    postalcode: '86860',
    city: 'Jengen',
    countrycode: 'DE',
  },
  {
    postalcode: '86862',
    city: 'Lamerdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86863',
    city: 'Langenneufnach',
    countrycode: 'DE',
  },
  {
    postalcode: '86865',
    city: 'Markt Wald',
    countrycode: 'DE',
  },
  {
    postalcode: '86866',
    city: 'Mickhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86868',
    city: 'Mittelneufnach',
    countrycode: 'DE',
  },
  {
    postalcode: '86869',
    city: 'Oberostendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '86871',
    city: 'Rammingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86872',
    city: 'Scherstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '86874',
    city: 'Tussenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86875',
    city: 'Waal',
    countrycode: 'DE',
  },
  {
    postalcode: '86877',
    city: 'Walkertshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '86879',
    city: 'Wiedergeltingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86899',
    city: 'Landsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '86911',
    city: 'Dießen',
    countrycode: 'DE',
  },
  {
    postalcode: '86916',
    city: 'Kaufering',
    countrycode: 'DE',
  },
  {
    postalcode: '86919',
    city: 'Utting',
    countrycode: 'DE',
  },
  {
    postalcode: '86920',
    city: 'Denklingen',
    countrycode: 'DE',
  },
  {
    postalcode: '86920',
    city: 'Epfach',
    countrycode: 'DE',
  },
  {
    postalcode: '86922',
    city: 'Eresing',
    countrycode: 'DE',
  },
  {
    postalcode: '86923',
    city: 'Finning',
    countrycode: 'DE',
  },
  {
    postalcode: '86925',
    city: 'Fuchstal',
    countrycode: 'DE',
  },
  {
    postalcode: '86926',
    city: 'Algertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '86926',
    city: 'Greifenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '86926',
    city: 'Pflaumdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '86928',
    city: 'Hofstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '86929',
    city: 'Penzing',
    countrycode: 'DE',
  },
  {
    postalcode: '86931',
    city: 'Prittriching',
    countrycode: 'DE',
  },
  {
    postalcode: '86932',
    city: 'Pürgen',
    countrycode: 'DE',
  },
  {
    postalcode: '86932',
    city: 'Thalhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '86934',
    city: 'Reichling',
    countrycode: 'DE',
  },
  {
    postalcode: '86935',
    city: 'Rott',
    countrycode: 'DE',
  },
  {
    postalcode: '86937',
    city: 'Scheuring',
    countrycode: 'DE',
  },
  {
    postalcode: '86938',
    city: 'Schondorf',
    countrycode: 'DE',
  },
  {
    postalcode: '86940',
    city: 'Schwifting',
    countrycode: 'DE',
  },
  {
    postalcode: '86941',
    city: 'St Ottilien',
    countrycode: 'DE',
  },
  {
    postalcode: '86943',
    city: 'Thaining',
    countrycode: 'DE',
  },
  {
    postalcode: '86944',
    city: 'Unterdießen',
    countrycode: 'DE',
  },
  {
    postalcode: '86946',
    city: 'Vilgertshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '86947',
    city: 'Weil',
    countrycode: 'DE',
  },
  {
    postalcode: '86949',
    city: 'Windach',
    countrycode: 'DE',
  },
  {
    postalcode: '86956',
    city: 'Schongau',
    countrycode: 'DE',
  },
  {
    postalcode: '86971',
    city: 'Peiting',
    countrycode: 'DE',
  },
  {
    postalcode: '86972',
    city: 'Altenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '86974',
    city: 'Apfeldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '86975',
    city: 'Bernbeuren',
    countrycode: 'DE',
  },
  {
    postalcode: '86977',
    city: 'Burggen',
    countrycode: 'DE',
  },
  {
    postalcode: '86978',
    city: 'Hohenfurch',
    countrycode: 'DE',
  },
  {
    postalcode: '86980',
    city: 'Ingenried',
    countrycode: 'DE',
  },
  {
    postalcode: '86981',
    city: 'Kinsau',
    countrycode: 'DE',
  },
  {
    postalcode: '86983',
    city: 'Lechbruck',
    countrycode: 'DE',
  },
  {
    postalcode: '86984',
    city: 'Prem',
    countrycode: 'DE',
  },
  {
    postalcode: '86986',
    city: 'Schwabbruck',
    countrycode: 'DE',
  },
  {
    postalcode: '86987',
    city: 'Schwabsoien',
    countrycode: 'DE',
  },
  {
    postalcode: '86989',
    city: 'Deutenried',
    countrycode: 'DE',
  },
  {
    postalcode: '86989',
    city: 'Steingaden',
    countrycode: 'DE',
  },
  {
    postalcode: '87435',
    city: 'Kempten',
    countrycode: 'DE',
  },
  {
    postalcode: '87437',
    city: 'Kempten',
    countrycode: 'DE',
  },
  {
    postalcode: '87439',
    city: 'Kempten',
    countrycode: 'DE',
  },
  {
    postalcode: '87448',
    city: 'Waltenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '87452',
    city: 'Altusried',
    countrycode: 'DE',
  },
  {
    postalcode: '87459',
    city: 'Pfronten',
    countrycode: 'DE',
  },
  {
    postalcode: '87463',
    city: 'Dietmannsried',
    countrycode: 'DE',
  },
  {
    postalcode: '87466',
    city: 'Oy-Mittelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '87471',
    city: 'Durach',
    countrycode: 'DE',
  },
  {
    postalcode: '87474',
    city: 'Buchenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '87477',
    city: 'Sulzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '87480',
    city: 'Weitnau',
    countrycode: 'DE',
  },
  {
    postalcode: '87484',
    city: 'Nesselwang',
    countrycode: 'DE',
  },
  {
    postalcode: '87487',
    city: 'Wiggensbach',
    countrycode: 'DE',
  },
  {
    postalcode: '87488',
    city: 'Betzigau',
    countrycode: 'DE',
  },
  {
    postalcode: '87490',
    city: 'Haldenwang',
    countrycode: 'DE',
  },
  {
    postalcode: '87493',
    city: 'Lauben',
    countrycode: 'DE',
  },
  {
    postalcode: '87494',
    city: 'Rückholz',
    countrycode: 'DE',
  },
  {
    postalcode: '87496',
    city: 'Untrasried',
    countrycode: 'DE',
  },
  {
    postalcode: '87497',
    city: 'Wertach',
    countrycode: 'DE',
  },
  {
    postalcode: '87499',
    city: 'Wildpoldsried',
    countrycode: 'DE',
  },
  {
    postalcode: '87509',
    city: 'Immenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '87527',
    city: 'Ofterschwang',
    countrycode: 'DE',
  },
  {
    postalcode: '87527',
    city: 'Sonthofen',
    countrycode: 'DE',
  },
  {
    postalcode: '87534',
    city: 'Oberstaufen',
    countrycode: 'DE',
  },
  {
    postalcode: '87538',
    city: 'Balderschwang',
    countrycode: 'DE',
  },
  {
    postalcode: '87538',
    city: 'Bolsterlang',
    countrycode: 'DE',
  },
  {
    postalcode: '87538',
    city: 'Fischen',
    countrycode: 'DE',
  },
  {
    postalcode: '87538',
    city: 'Obermaiselstein',
    countrycode: 'DE',
  },
  {
    postalcode: '87541',
    city: 'Hindelang',
    countrycode: 'DE',
  },
  {
    postalcode: '87544',
    city: 'Blaichach',
    countrycode: 'DE',
  },
  {
    postalcode: '87545',
    city: 'Burgberg',
    countrycode: 'DE',
  },
  {
    postalcode: '87547',
    city: 'Missen-Wilhams',
    countrycode: 'DE',
  },
  {
    postalcode: '87549',
    city: 'Rettenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '87561',
    city: 'Oberstdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '87600',
    city: 'Kaufbeuren',
    countrycode: 'DE',
  },
  {
    postalcode: '87616',
    city: 'Marktoberdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '87616',
    city: 'Wald',
    countrycode: 'DE',
  },
  {
    postalcode: '87629',
    city: 'Füssen',
    countrycode: 'DE',
  },
  {
    postalcode: '87634',
    city: 'Günzach',
    countrycode: 'DE',
  },
  {
    postalcode: '87634',
    city: 'Obergünzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '87637',
    city: 'Eisenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '87637',
    city: 'Seeg',
    countrycode: 'DE',
  },
  {
    postalcode: '87640',
    city: 'Biessenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '87642',
    city: 'Halblech',
    countrycode: 'DE',
  },
  {
    postalcode: '87645',
    city: 'Schwangau',
    countrycode: 'DE',
  },
  {
    postalcode: '87647',
    city: 'Kraftisried',
    countrycode: 'DE',
  },
  {
    postalcode: '87647',
    city: 'Unterthingau',
    countrycode: 'DE',
  },
  {
    postalcode: '87648',
    city: 'Aitrang',
    countrycode: 'DE',
  },
  {
    postalcode: '87650',
    city: 'Baisweil',
    countrycode: 'DE',
  },
  {
    postalcode: '87651',
    city: 'Bidingen',
    countrycode: 'DE',
  },
  {
    postalcode: '87653',
    city: 'Eggenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '87654',
    city: 'Friesenried',
    countrycode: 'DE',
  },
  {
    postalcode: '87656',
    city: 'Germaringen',
    countrycode: 'DE',
  },
  {
    postalcode: '87657',
    city: 'Görisried',
    countrycode: 'DE',
  },
  {
    postalcode: '87659',
    city: 'Hopferau',
    countrycode: 'DE',
  },
  {
    postalcode: '87660',
    city: 'Irsee',
    countrycode: 'DE',
  },
  {
    postalcode: '87662',
    city: 'Kaltental',
    countrycode: 'DE',
  },
  {
    postalcode: '87662',
    city: 'Osterzell',
    countrycode: 'DE',
  },
  {
    postalcode: '87663',
    city: 'Lengenwang',
    countrycode: 'DE',
  },
  {
    postalcode: '87665',
    city: 'Mauerstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '87666',
    city: 'Pforzen',
    countrycode: 'DE',
  },
  {
    postalcode: '87668',
    city: 'Rieden',
    countrycode: 'DE',
  },
  {
    postalcode: '87669',
    city: 'Rieden',
    countrycode: 'DE',
  },
  {
    postalcode: '87671',
    city: 'Flohkraut',
    countrycode: 'DE',
  },
  {
    postalcode: '87671',
    city: 'Ronsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '87672',
    city: 'Roßhaupten',
    countrycode: 'DE',
  },
  {
    postalcode: '87674',
    city: 'Ruderatshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '87675',
    city: 'Rettenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '87675',
    city: 'Stötten',
    countrycode: 'DE',
  },
  {
    postalcode: '87677',
    city: 'Stöttwang',
    countrycode: 'DE',
  },
  {
    postalcode: '87679',
    city: 'Westendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '87700',
    city: 'Memmingen',
    countrycode: 'DE',
  },
  {
    postalcode: '87719',
    city: 'Mindelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '87724',
    city: 'Krautenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '87724',
    city: 'Ottobeuren',
    countrycode: 'DE',
  },
  {
    postalcode: '87724',
    city: 'Schochen',
    countrycode: 'DE',
  },
  {
    postalcode: '87727',
    city: 'Babenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '87730',
    city: 'Bad Grönenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '87733',
    city: 'Markt Rettenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '87734',
    city: 'Benningen',
    countrycode: 'DE',
  },
  {
    postalcode: '87736',
    city: 'Böhen',
    countrycode: 'DE',
  },
  {
    postalcode: '87737',
    city: 'Boos',
    countrycode: 'DE',
  },
  {
    postalcode: '87739',
    city: 'Breitenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '87740',
    city: 'Buxheim',
    countrycode: 'DE',
  },
  {
    postalcode: '87742',
    city: 'Apfeltrach',
    countrycode: 'DE',
  },
  {
    postalcode: '87742',
    city: 'Dirlewang',
    countrycode: 'DE',
  },
  {
    postalcode: '87743',
    city: 'Betzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '87743',
    city: 'Egg',
    countrycode: 'DE',
  },
  {
    postalcode: '87745',
    city: 'Eppishausen',
    countrycode: 'DE',
  },
  {
    postalcode: '87746',
    city: 'Erkheim',
    countrycode: 'DE',
  },
  {
    postalcode: '87748',
    city: 'Fellheim',
    countrycode: 'DE',
  },
  {
    postalcode: '87749',
    city: 'Hawangen',
    countrycode: 'DE',
  },
  {
    postalcode: '87751',
    city: 'Heimertingen',
    countrycode: 'DE',
  },
  {
    postalcode: '87752',
    city: 'Holzgünz',
    countrycode: 'DE',
  },
  {
    postalcode: '87754',
    city: 'Kammlach',
    countrycode: 'DE',
  },
  {
    postalcode: '87755',
    city: 'Kirchhaslach',
    countrycode: 'DE',
  },
  {
    postalcode: '87757',
    city: 'Bronnerlehe',
    countrycode: 'DE',
  },
  {
    postalcode: '87757',
    city: 'Kirchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '87758',
    city: 'Kronburg',
    countrycode: 'DE',
  },
  {
    postalcode: '87760',
    city: 'Lachen',
    countrycode: 'DE',
  },
  {
    postalcode: '87761',
    city: 'Lauben',
    countrycode: 'DE',
  },
  {
    postalcode: '87763',
    city: 'Lautrach',
    countrycode: 'DE',
  },
  {
    postalcode: '87764',
    city: 'Legau',
    countrycode: 'DE',
  },
  {
    postalcode: '87766',
    city: 'Memmingerberg',
    countrycode: 'DE',
  },
  {
    postalcode: '87767',
    city: 'Niederrieden',
    countrycode: 'DE',
  },
  {
    postalcode: '87769',
    city: 'Oberrieden',
    countrycode: 'DE',
  },
  {
    postalcode: '87770',
    city: 'Beblinstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '87770',
    city: 'Oberschönegg',
    countrycode: 'DE',
  },
  {
    postalcode: '87772',
    city: 'Pfaffenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '87773',
    city: 'Pleß',
    countrycode: 'DE',
  },
  {
    postalcode: '87775',
    city: 'Salgen',
    countrycode: 'DE',
  },
  {
    postalcode: '87776',
    city: 'Sontheim',
    countrycode: 'DE',
  },
  {
    postalcode: '87778',
    city: 'Stetten',
    countrycode: 'DE',
  },
  {
    postalcode: '87779',
    city: 'Trunkelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '87781',
    city: 'Ungerhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '87782',
    city: 'Sonderhof',
    countrycode: 'DE',
  },
  {
    postalcode: '87782',
    city: 'Unteregg',
    countrycode: 'DE',
  },
  {
    postalcode: '87782',
    city: 'Wallenried',
    countrycode: 'DE',
  },
  {
    postalcode: '87784',
    city: 'Westerheim',
    countrycode: 'DE',
  },
  {
    postalcode: '87785',
    city: 'Winterrieden',
    countrycode: 'DE',
  },
  {
    postalcode: '87787',
    city: 'Wolfertschwenden',
    countrycode: 'DE',
  },
  {
    postalcode: '87789',
    city: 'Woringen',
    countrycode: 'DE',
  },
  {
    postalcode: '88045',
    city: 'Friedrichshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '88046',
    city: 'Friedrichshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '88048',
    city: 'Friedrichshafen',
    countrycode: 'DE',
  },
  {
    postalcode: '88069',
    city: 'Tettnang',
    countrycode: 'DE',
  },
  {
    postalcode: '88074',
    city: 'Meckenbeuren',
    countrycode: 'DE',
  },
  {
    postalcode: '88079',
    city: 'Kressbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '88079',
    city: 'Reichen',
    countrycode: 'DE',
  },
  {
    postalcode: '88079',
    city: 'Wiesach',
    countrycode: 'DE',
  },
  {
    postalcode: '88085',
    city: 'Langenargen',
    countrycode: 'DE',
  },
  {
    postalcode: '88090',
    city: 'Immenstaad',
    countrycode: 'DE',
  },
  {
    postalcode: '88094',
    city: 'Oberteuringen',
    countrycode: 'DE',
  },
  {
    postalcode: '88097',
    city: 'Eriskirch',
    countrycode: 'DE',
  },
  {
    postalcode: '88099',
    city: 'Neukirch',
    countrycode: 'DE',
  },
  {
    postalcode: '88131',
    city: 'Bodolz',
    countrycode: 'DE',
  },
  {
    postalcode: '88131',
    city: 'Lindau',
    countrycode: 'DE',
  },
  {
    postalcode: '88138',
    city: 'Hergensweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '88138',
    city: 'Sigmarszell',
    countrycode: 'DE',
  },
  {
    postalcode: '88138',
    city: 'Weißensberg',
    countrycode: 'DE',
  },
  {
    postalcode: '88142',
    city: 'Wasserburg',
    countrycode: 'DE',
  },
  {
    postalcode: '88145',
    city: 'Hergatz',
    countrycode: 'DE',
  },
  {
    postalcode: '88145',
    city: 'Opfenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '88147',
    city: 'Achberg',
    countrycode: 'DE',
  },
  {
    postalcode: '88147',
    city: 'Hugelitz',
    countrycode: 'DE',
  },
  {
    postalcode: '88149',
    city: 'Nonnenhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '88161',
    city: 'Lindenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '88167',
    city: 'Gestratz',
    countrycode: 'DE',
  },
  {
    postalcode: '88167',
    city: 'Grünenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '88167',
    city: 'Lengersau',
    countrycode: 'DE',
  },
  {
    postalcode: '88167',
    city: 'Maierhöfen',
    countrycode: 'DE',
  },
  {
    postalcode: '88167',
    city: 'Malleichen',
    countrycode: 'DE',
  },
  {
    postalcode: '88167',
    city: 'Röthenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '88167',
    city: 'Stiefenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '88171',
    city: 'Weiler-Simmerberg',
    countrycode: 'DE',
  },
  {
    postalcode: '88175',
    city: 'Scheidegg',
    countrycode: 'DE',
  },
  {
    postalcode: '88175',
    city: 'Westkinberg',
    countrycode: 'DE',
  },
  {
    postalcode: '88178',
    city: 'Heimenkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '88179',
    city: 'Oberreute',
    countrycode: 'DE',
  },
  {
    postalcode: '88212',
    city: 'Ravensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '88213',
    city: 'Ravensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '88214',
    city: 'Ravensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '88239',
    city: 'Wangen',
    countrycode: 'DE',
  },
  {
    postalcode: '88250',
    city: 'Weingarten',
    countrycode: 'DE',
  },
  {
    postalcode: '88255',
    city: 'Baienfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '88255',
    city: 'Baindt',
    countrycode: 'DE',
  },
  {
    postalcode: '88260',
    city: 'Argenbühl',
    countrycode: 'DE',
  },
  {
    postalcode: '88263',
    city: 'Horgenzell',
    countrycode: 'DE',
  },
  {
    postalcode: '88267',
    city: 'Vogt',
    countrycode: 'DE',
  },
  {
    postalcode: '88271',
    city: 'Wilhelmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '88273',
    city: 'Fronreute',
    countrycode: 'DE',
  },
  {
    postalcode: '88276',
    city: 'Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '88279',
    city: 'Amtzell',
    countrycode: 'DE',
  },
  {
    postalcode: '88281',
    city: 'Schlier',
    countrycode: 'DE',
  },
  {
    postalcode: '88284',
    city: 'Wolpertswende',
    countrycode: 'DE',
  },
  {
    postalcode: '88285',
    city: 'Bodnegg',
    countrycode: 'DE',
  },
  {
    postalcode: '88287',
    city: 'Grünkraut',
    countrycode: 'DE',
  },
  {
    postalcode: '88289',
    city: 'Waldburg',
    countrycode: 'DE',
  },
  {
    postalcode: '88299',
    city: 'Leutkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '88316',
    city: 'Isny',
    countrycode: 'DE',
  },
  {
    postalcode: '88317',
    city: 'Aichstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '88319',
    city: 'Aitrach',
    countrycode: 'DE',
  },
  {
    postalcode: '88326',
    city: 'Aulendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '88339',
    city: 'Bad Waldsee',
    countrycode: 'DE',
  },
  {
    postalcode: '88348',
    city: 'Allmannsweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '88348',
    city: 'Bad Saulgau',
    countrycode: 'DE',
  },
  {
    postalcode: '88353',
    city: 'Kißlegg',
    countrycode: 'DE',
  },
  {
    postalcode: '88356',
    city: 'Ostrach',
    countrycode: 'DE',
  },
  {
    postalcode: '88361',
    city: 'Altshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '88361',
    city: 'Boms',
    countrycode: 'DE',
  },
  {
    postalcode: '88361',
    city: 'Eichstegen',
    countrycode: 'DE',
  },
  {
    postalcode: '88364',
    city: 'Wolfegg',
    countrycode: 'DE',
  },
  {
    postalcode: '88367',
    city: 'Hohentengen',
    countrycode: 'DE',
  },
  {
    postalcode: '88368',
    city: 'Bergatreute',
    countrycode: 'DE',
  },
  {
    postalcode: '88370',
    city: 'Ebenweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '88371',
    city: 'Ebersbach-Musbach',
    countrycode: 'DE',
  },
  {
    postalcode: '88373',
    city: 'Fleischwangen',
    countrycode: 'DE',
  },
  {
    postalcode: '88373',
    city: 'Muttenhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '88374',
    city: 'Hoßkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '88376',
    city: 'Königseggwald',
    countrycode: 'DE',
  },
  {
    postalcode: '88377',
    city: 'Riedhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '88379',
    city: 'Guggenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '88379',
    city: 'Unterwaldhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '88400',
    city: 'Biberach',
    countrycode: 'DE',
  },
  {
    postalcode: '88400',
    city: 'Scholterhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '88410',
    city: 'Bad Wurzach',
    countrycode: 'DE',
  },
  {
    postalcode: '88416',
    city: 'Erlenmoos',
    countrycode: 'DE',
  },
  {
    postalcode: '88416',
    city: 'Ochsenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '88416',
    city: 'Steinhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '88422',
    city: 'Alleshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '88422',
    city: 'Bad Buchau',
    countrycode: 'DE',
  },
  {
    postalcode: '88422',
    city: 'Betzenweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '88422',
    city: 'Dürnau',
    countrycode: 'DE',
  },
  {
    postalcode: '88422',
    city: 'Kanzach',
    countrycode: 'DE',
  },
  {
    postalcode: '88422',
    city: 'Moosburg',
    countrycode: 'DE',
  },
  {
    postalcode: '88422',
    city: 'Oggelshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '88422',
    city: 'Seekirch',
    countrycode: 'DE',
  },
  {
    postalcode: '88422',
    city: 'Tiefenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '88427',
    city: 'Bad Schussenried',
    countrycode: 'DE',
  },
  {
    postalcode: '88430',
    city: 'Emishalden',
    countrycode: 'DE',
  },
  {
    postalcode: '88430',
    city: 'Rot',
    countrycode: 'DE',
  },
  {
    postalcode: '88433',
    city: 'Schemmerhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '88436',
    city: 'Eberhardzell',
    countrycode: 'DE',
  },
  {
    postalcode: '88436',
    city: 'Venusberg',
    countrycode: 'DE',
  },
  {
    postalcode: '88437',
    city: 'Maselheim',
    countrycode: 'DE',
  },
  {
    postalcode: '88441',
    city: 'Bahnstock',
    countrycode: 'DE',
  },
  {
    postalcode: '88441',
    city: 'Birkhof',
    countrycode: 'DE',
  },
  {
    postalcode: '88441',
    city: 'Maierhof',
    countrycode: 'DE',
  },
  {
    postalcode: '88441',
    city: 'Mittelbiberach',
    countrycode: 'DE',
  },
  {
    postalcode: '88441',
    city: 'Streitberg',
    countrycode: 'DE',
  },
  {
    postalcode: '88444',
    city: 'Ummendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '88447',
    city: 'Warthausen',
    countrycode: 'DE',
  },
  {
    postalcode: '88448',
    city: 'Attenweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '88450',
    city: 'Berkheim',
    countrycode: 'DE',
  },
  {
    postalcode: '88450',
    city: 'Binnrot',
    countrycode: 'DE',
  },
  {
    postalcode: '88451',
    city: 'Dettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88453',
    city: 'Erolzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '88453',
    city: 'Waldenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '88454',
    city: 'Hochdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '88454',
    city: 'Winkel',
    countrycode: 'DE',
  },
  {
    postalcode: '88456',
    city: 'Ingoldingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88457',
    city: 'Kirchdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '88459',
    city: 'Rudeshof',
    countrycode: 'DE',
  },
  {
    postalcode: '88459',
    city: 'Tannheim',
    countrycode: 'DE',
  },
  {
    postalcode: '88459',
    city: 'Wiesbauer',
    countrycode: 'DE',
  },
  {
    postalcode: '88471',
    city: 'Laupheim',
    countrycode: 'DE',
  },
  {
    postalcode: '88477',
    city: 'Schwendi',
    countrycode: 'DE',
  },
  {
    postalcode: '88480',
    city: 'Achstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '88481',
    city: 'Balzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '88483',
    city: 'Burgrieden',
    countrycode: 'DE',
  },
  {
    postalcode: '88484',
    city: 'Gutenzell-Hürbel',
    countrycode: 'DE',
  },
  {
    postalcode: '88486',
    city: 'Kirchberg',
    countrycode: 'DE',
  },
  {
    postalcode: '88487',
    city: 'Mietingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88489',
    city: 'Wain',
    countrycode: 'DE',
  },
  {
    postalcode: '88499',
    city: 'Altheim',
    countrycode: 'DE',
  },
  {
    postalcode: '88499',
    city: 'Emeringen',
    countrycode: 'DE',
  },
  {
    postalcode: '88499',
    city: 'Riedlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88512',
    city: 'Mengen',
    countrycode: 'DE',
  },
  {
    postalcode: '88515',
    city: 'Langenenslingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88518',
    city: 'Herbertingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88521',
    city: 'Ertingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88524',
    city: 'Uttenweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '88525',
    city: 'Dürmentingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88527',
    city: 'Unlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88529',
    city: 'Zwiefalten',
    countrycode: 'DE',
  },
  {
    postalcode: '88605',
    city: 'Meßkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '88605',
    city: 'Sauldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '88630',
    city: 'Pfullendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '88631',
    city: 'Beuron',
    countrycode: 'DE',
  },
  {
    postalcode: '88633',
    city: 'Heiligenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '88634',
    city: 'Herdwangen-Schönach',
    countrycode: 'DE',
  },
  {
    postalcode: '88636',
    city: 'Illmensee',
    countrycode: 'DE',
  },
  {
    postalcode: '88637',
    city: 'Buchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '88637',
    city: 'Leibertingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88639',
    city: 'Wald',
    countrycode: 'DE',
  },
  {
    postalcode: '88662',
    city: 'Überlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88677',
    city: 'Markdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '88682',
    city: 'Salem',
    countrycode: 'DE',
  },
  {
    postalcode: '88690',
    city: 'Uhldingen-Mühlhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '88693',
    city: 'Deggenhausertal',
    countrycode: 'DE',
  },
  {
    postalcode: '88696',
    city: 'Owingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88697',
    city: 'Bermatingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88699',
    city: 'Frickingen',
    countrycode: 'DE',
  },
  {
    postalcode: '88709',
    city: 'Hagnau',
    countrycode: 'DE',
  },
  {
    postalcode: '88709',
    city: 'Meersburg',
    countrycode: 'DE',
  },
  {
    postalcode: '88718',
    city: 'Daisendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '88719',
    city: 'Stetten',
    countrycode: 'DE',
  },
  {
    postalcode: '89073',
    city: 'Ulm',
    countrycode: 'DE',
  },
  {
    postalcode: '89075',
    city: 'Ulm',
    countrycode: 'DE',
  },
  {
    postalcode: '89077',
    city: 'Ulm',
    countrycode: 'DE',
  },
  {
    postalcode: '89079',
    city: 'Ulm',
    countrycode: 'DE',
  },
  {
    postalcode: '89081',
    city: 'Seligweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '89081',
    city: 'Ulm',
    countrycode: 'DE',
  },
  {
    postalcode: '89129',
    city: 'Langenau',
    countrycode: 'DE',
  },
  {
    postalcode: '89129',
    city: 'Nerenstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '89129',
    city: 'Setzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89129',
    city: 'Wettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89129',
    city: 'Öllingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89134',
    city: 'Blaustein',
    countrycode: 'DE',
  },
  {
    postalcode: '89143',
    city: 'Blaubeuren',
    countrycode: 'DE',
  },
  {
    postalcode: '89143',
    city: 'Hinterer Hessenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '89150',
    city: 'Laichingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89155',
    city: 'Erbach',
    countrycode: 'DE',
  },
  {
    postalcode: '89160',
    city: 'Dornstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '89160',
    city: 'Himmelweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '89165',
    city: 'Dietenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89165',
    city: 'Dürach',
    countrycode: 'DE',
  },
  {
    postalcode: '89165',
    city: 'Halbertshof',
    countrycode: 'DE',
  },
  {
    postalcode: '89165',
    city: 'Oberführbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '89165',
    city: 'Unterführbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '89168',
    city: 'Niederstotzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89171',
    city: 'Illerkirchberg',
    countrycode: 'DE',
  },
  {
    postalcode: '89173',
    city: 'Lonsee',
    countrycode: 'DE',
  },
  {
    postalcode: '89174',
    city: 'Altheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89176',
    city: 'Asselfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89177',
    city: 'Ballendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '89177',
    city: 'Börslingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89179',
    city: 'Beimerstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '89180',
    city: 'Berghülen',
    countrycode: 'DE',
  },
  {
    postalcode: '89182',
    city: 'Bernstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '89183',
    city: 'Breitingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89183',
    city: 'Holzkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '89185',
    city: 'Hüttisheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89186',
    city: 'Illerrieden',
    countrycode: 'DE',
  },
  {
    postalcode: '89188',
    city: 'Merklingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89189',
    city: 'Neenstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '89191',
    city: 'Nellingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89192',
    city: 'Rammingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89194',
    city: 'Schnürpflingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89195',
    city: 'Staig',
    countrycode: 'DE',
  },
  {
    postalcode: '89197',
    city: 'Weidenstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '89198',
    city: 'Westerstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '89231',
    city: 'Neu-Ulm',
    countrycode: 'DE',
  },
  {
    postalcode: '89233',
    city: 'Neu-Ulm',
    countrycode: 'DE',
  },
  {
    postalcode: '89250',
    city: 'Senden',
    countrycode: 'DE',
  },
  {
    postalcode: '89257',
    city: 'Illertissen',
    countrycode: 'DE',
  },
  {
    postalcode: '89264',
    city: 'Dirrfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '89264',
    city: 'Riedhof',
    countrycode: 'DE',
  },
  {
    postalcode: '89264',
    city: 'Weißenhorn',
    countrycode: 'DE',
  },
  {
    postalcode: '89269',
    city: 'Vöhringen',
    countrycode: 'DE',
  },
  {
    postalcode: '89275',
    city: 'Elchingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89278',
    city: 'Nersingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89281',
    city: 'Altenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '89284',
    city: 'Pfaffenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '89287',
    city: 'Bellenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '89290',
    city: 'Buch',
    countrycode: 'DE',
  },
  {
    postalcode: '89290',
    city: 'Hochbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '89291',
    city: 'Holzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89293',
    city: 'Kellmünz',
    countrycode: 'DE',
  },
  {
    postalcode: '89294',
    city: 'Oberroth',
    countrycode: 'DE',
  },
  {
    postalcode: '89296',
    city: 'Osterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '89297',
    city: 'Roggenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '89299',
    city: 'Unterroth',
    countrycode: 'DE',
  },
  {
    postalcode: '89312',
    city: 'Günzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '89331',
    city: 'Burgau',
    countrycode: 'DE',
  },
  {
    postalcode: '89335',
    city: 'Brandfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '89335',
    city: 'Heubelsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '89335',
    city: 'Ichenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '89335',
    city: 'Wiblishauserhof',
    countrycode: 'DE',
  },
  {
    postalcode: '89340',
    city: 'Leipheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89343',
    city: 'Jettingen-Scheppach',
    countrycode: 'DE',
  },
  {
    postalcode: '89344',
    city: 'Aislingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89346',
    city: 'Bibertal',
    countrycode: 'DE',
  },
  {
    postalcode: '89347',
    city: 'Bubesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89349',
    city: 'Burtenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '89350',
    city: 'Dürrlauingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89352',
    city: 'Ellzee',
    countrycode: 'DE',
  },
  {
    postalcode: '89353',
    city: 'Glött',
    countrycode: 'DE',
  },
  {
    postalcode: '89355',
    city: 'Gundremmingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89356',
    city: 'Haldenwang',
    countrycode: 'DE',
  },
  {
    postalcode: '89358',
    city: 'Kammeltal',
    countrycode: 'DE',
  },
  {
    postalcode: '89359',
    city: 'Kötz',
    countrycode: 'DE',
  },
  {
    postalcode: '89361',
    city: 'Landensberg',
    countrycode: 'DE',
  },
  {
    postalcode: '89362',
    city: 'Offingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89364',
    city: 'Rettenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '89365',
    city: 'Röfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89367',
    city: 'Waldstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '89368',
    city: 'Winterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '89407',
    city: 'Dillingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89415',
    city: 'Lauingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89420',
    city: 'Höchstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '89423',
    city: 'Gundelfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89426',
    city: 'Mödingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89426',
    city: 'Wittislingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89428',
    city: 'Syrgenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '89429',
    city: 'Bachhagel',
    countrycode: 'DE',
  },
  {
    postalcode: '89431',
    city: 'Bächingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89434',
    city: 'Blindheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89435',
    city: 'Finningen',
    countrycode: 'DE',
  },
  {
    postalcode: '89437',
    city: 'Haunsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89438',
    city: 'Holzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89440',
    city: 'Lutzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89441',
    city: 'Medlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89443',
    city: 'Schwenningen',
    countrycode: 'DE',
  },
  {
    postalcode: '89446',
    city: 'Ziertheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89447',
    city: 'Zöschingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89518',
    city: 'Heidenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89520',
    city: 'Heidenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89522',
    city: 'Asbach',
    countrycode: 'DE',
  },
  {
    postalcode: '89522',
    city: 'Buchhof',
    countrycode: 'DE',
  },
  {
    postalcode: '89522',
    city: 'Christophruhe',
    countrycode: 'DE',
  },
  {
    postalcode: '89522',
    city: 'Heidenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89522',
    city: 'Neuasbach',
    countrycode: 'DE',
  },
  {
    postalcode: '89537',
    city: 'Gerschweiler',
    countrycode: 'DE',
  },
  {
    postalcode: '89537',
    city: 'Giengen',
    countrycode: 'DE',
  },
  {
    postalcode: '89542',
    city: 'Herbrechtingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89547',
    city: 'Gerstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '89551',
    city: 'Königsbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '89555',
    city: 'Steinheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89558',
    city: 'Böhmenkirch',
    countrycode: 'DE',
  },
  {
    postalcode: '89561',
    city: 'Dischingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89564',
    city: 'Nattheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89567',
    city: 'Sontheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89568',
    city: 'Hermaringen',
    countrycode: 'DE',
  },
  {
    postalcode: '89584',
    city: 'Ehingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89584',
    city: 'Lauterach',
    countrycode: 'DE',
  },
  {
    postalcode: '89597',
    city: 'Hausen',
    countrycode: 'DE',
  },
  {
    postalcode: '89597',
    city: 'Munderkingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89597',
    city: 'Unterwachingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89601',
    city: 'Karlshof',
    countrycode: 'DE',
  },
  {
    postalcode: '89601',
    city: 'Schelklingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89604',
    city: 'Allmendingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89605',
    city: 'Altheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89607',
    city: 'Emerkingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89608',
    city: 'Griesingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89610',
    city: 'Oberdischingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89611',
    city: 'Brühlhof',
    countrycode: 'DE',
  },
  {
    postalcode: '89611',
    city: 'Obermarchtal',
    countrycode: 'DE',
  },
  {
    postalcode: '89611',
    city: 'Rechtenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '89613',
    city: 'Grundsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '89613',
    city: 'Oberstadion',
    countrycode: 'DE',
  },
  {
    postalcode: '89614',
    city: 'Öpfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '89616',
    city: 'Rottenacker',
    countrycode: 'DE',
  },
  {
    postalcode: '89617',
    city: 'Untermarchtal',
    countrycode: 'DE',
  },
  {
    postalcode: '89619',
    city: 'Unterstadion',
    countrycode: 'DE',
  },
  {
    postalcode: '90402',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90403',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90408',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90409',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90411',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90419',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90425',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90427',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90429',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90431',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90439',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90441',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90443',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90449',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90451',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90453',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90455',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90459',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90461',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90469',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90471',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90473',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90475',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90478',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90480',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90482',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90489',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90491',
    city: 'Nürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90513',
    city: 'Zirndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '90518',
    city: 'Altdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '90522',
    city: 'Oberasbach',
    countrycode: 'DE',
  },
  {
    postalcode: '90530',
    city: 'Wendelstein',
    countrycode: 'DE',
  },
  {
    postalcode: '90537',
    city: 'Feucht',
    countrycode: 'DE',
  },
  {
    postalcode: '90542',
    city: 'Eckental',
    countrycode: 'DE',
  },
  {
    postalcode: '90547',
    city: 'Stein',
    countrycode: 'DE',
  },
  {
    postalcode: '90552',
    city: 'Röthenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '90556',
    city: 'Cadolzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '90556',
    city: 'Seukendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '90559',
    city: 'Burgthann',
    countrycode: 'DE',
  },
  {
    postalcode: '90562',
    city: 'Heroldsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90562',
    city: 'Kalchreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '90571',
    city: 'Schwaig',
    countrycode: 'DE',
  },
  {
    postalcode: '90574',
    city: 'Roßtal',
    countrycode: 'DE',
  },
  {
    postalcode: '90579',
    city: 'Langenzenn',
    countrycode: 'DE',
  },
  {
    postalcode: '90584',
    city: 'Allersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '90587',
    city: 'Obermichelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '90587',
    city: 'Tuchenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '90587',
    city: 'Veitsbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '90592',
    city: 'Schwarzenbruck',
    countrycode: 'DE',
  },
  {
    postalcode: '90596',
    city: 'Schwanstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '90599',
    city: 'Dietenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '90602',
    city: 'Pyrbaum',
    countrycode: 'DE',
  },
  {
    postalcode: '90607',
    city: 'Rückersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '90610',
    city: 'Winkelhaid',
    countrycode: 'DE',
  },
  {
    postalcode: '90613',
    city: 'Großhabersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '90614',
    city: 'Ammerndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '90616',
    city: 'Neuhof',
    countrycode: 'DE',
  },
  {
    postalcode: '90617',
    city: 'Puschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '90619',
    city: 'Trautskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '90762',
    city: 'Fürth',
    countrycode: 'DE',
  },
  {
    postalcode: '90763',
    city: 'Fürth',
    countrycode: 'DE',
  },
  {
    postalcode: '90765',
    city: 'Fürth',
    countrycode: 'DE',
  },
  {
    postalcode: '90766',
    city: 'Fürth',
    countrycode: 'DE',
  },
  {
    postalcode: '90768',
    city: 'Fürth',
    countrycode: 'DE',
  },
  {
    postalcode: '91052',
    city: 'Erlangen',
    countrycode: 'DE',
  },
  {
    postalcode: '91054',
    city: 'Buckenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '91054',
    city: 'Erlangen',
    countrycode: 'DE',
  },
  {
    postalcode: '91056',
    city: 'Erlangen',
    countrycode: 'DE',
  },
  {
    postalcode: '91058',
    city: 'Erlangen',
    countrycode: 'DE',
  },
  {
    postalcode: '91074',
    city: 'Herzogenaurach',
    countrycode: 'DE',
  },
  {
    postalcode: '91077',
    city: 'Dormitz',
    countrycode: 'DE',
  },
  {
    postalcode: '91077',
    city: 'Gabermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '91077',
    city: 'Hetzles',
    countrycode: 'DE',
  },
  {
    postalcode: '91077',
    city: 'Kleinsendelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91077',
    city: 'Minderleinsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '91077',
    city: 'Neunkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '91080',
    city: 'Marloffstein',
    countrycode: 'DE',
  },
  {
    postalcode: '91080',
    city: 'Spardorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91080',
    city: 'Uttenreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '91083',
    city: 'Baiersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91085',
    city: 'Weisendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91086',
    city: 'Aurachtal',
    countrycode: 'DE',
  },
  {
    postalcode: '91088',
    city: 'Bubenreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '91090',
    city: 'Effeltrich',
    countrycode: 'DE',
  },
  {
    postalcode: '91091',
    city: 'Großenseebach',
    countrycode: 'DE',
  },
  {
    postalcode: '91093',
    city: 'Heßdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91094',
    city: 'Langensendelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91096',
    city: 'Möhrendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91097',
    city: 'Oberreichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91099',
    city: 'Poxdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91126',
    city: 'Kammerstein',
    countrycode: 'DE',
  },
  {
    postalcode: '91126',
    city: 'Rednitzhembach',
    countrycode: 'DE',
  },
  {
    postalcode: '91126',
    city: 'Schwabach',
    countrycode: 'DE',
  },
  {
    postalcode: '91154',
    city: 'Roth',
    countrycode: 'DE',
  },
  {
    postalcode: '91161',
    city: 'Hilpoltstein',
    countrycode: 'DE',
  },
  {
    postalcode: '91166',
    city: 'Georgensgmünd',
    countrycode: 'DE',
  },
  {
    postalcode: '91171',
    city: 'Greding',
    countrycode: 'DE',
  },
  {
    postalcode: '91174',
    city: 'Spalt',
    countrycode: 'DE',
  },
  {
    postalcode: '91177',
    city: 'Thalmässing',
    countrycode: 'DE',
  },
  {
    postalcode: '91180',
    city: 'Heideck',
    countrycode: 'DE',
  },
  {
    postalcode: '91183',
    city: 'Abenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '91186',
    city: 'Büchenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91187',
    city: 'Röttenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91189',
    city: 'Rohr',
    countrycode: 'DE',
  },
  {
    postalcode: '91207',
    city: 'Lauf',
    countrycode: 'DE',
  },
  {
    postalcode: '91217',
    city: 'Hersbruck',
    countrycode: 'DE',
  },
  {
    postalcode: '91220',
    city: 'Schnaittach',
    countrycode: 'DE',
  },
  {
    postalcode: '91224',
    city: 'Pommelsbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '91227',
    city: 'Leinburg',
    countrycode: 'DE',
  },
  {
    postalcode: '91230',
    city: 'Happurg',
    countrycode: 'DE',
  },
  {
    postalcode: '91233',
    city: 'Neunkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '91235',
    city: 'Hartenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '91235',
    city: 'Velden',
    countrycode: 'DE',
  },
  {
    postalcode: '91236',
    city: 'Alfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '91238',
    city: 'Engelthal',
    countrycode: 'DE',
  },
  {
    postalcode: '91238',
    city: 'Offenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '91239',
    city: 'Henfenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '91241',
    city: 'Kirchensittenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91242',
    city: 'Ottensoos',
    countrycode: 'DE',
  },
  {
    postalcode: '91244',
    city: 'Reichenschwand',
    countrycode: 'DE',
  },
  {
    postalcode: '91245',
    city: 'Simmelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91247',
    city: 'Vorra',
    countrycode: 'DE',
  },
  {
    postalcode: '91249',
    city: 'Weigendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91257',
    city: 'Pegnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '91275',
    city: 'Auerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91278',
    city: 'Pottenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '91281',
    city: 'Kirchenthumbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91282',
    city: 'Betzenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '91284',
    city: 'Neuhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '91286',
    city: 'Obertrubach',
    countrycode: 'DE',
  },
  {
    postalcode: '91287',
    city: 'Plech',
    countrycode: 'DE',
  },
  {
    postalcode: '91289',
    city: 'Schnabelwaid',
    countrycode: 'DE',
  },
  {
    postalcode: '91301',
    city: 'Forchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91315',
    city: 'Höchstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '91320',
    city: 'Ebermannstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '91322',
    city: 'Gräfenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '91325',
    city: 'Adelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91327',
    city: 'Gößweinstein',
    countrycode: 'DE',
  },
  {
    postalcode: '91330',
    city: 'Eggolsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91332',
    city: 'Heiligenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '91334',
    city: 'Hemhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '91336',
    city: 'Heroldsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91338',
    city: 'Igensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91341',
    city: 'Röttenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91344',
    city: 'Fuchshof',
    countrycode: 'DE',
  },
  {
    postalcode: '91344',
    city: 'Schottersmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '91344',
    city: 'Waischenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '91346',
    city: 'Wiesenttal',
    countrycode: 'DE',
  },
  {
    postalcode: '91347',
    city: 'Aufseß',
    countrycode: 'DE',
  },
  {
    postalcode: '91349',
    city: 'Egloffstein',
    countrycode: 'DE',
  },
  {
    postalcode: '91350',
    city: 'Gremsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91352',
    city: 'Hallerndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91353',
    city: 'Hausen',
    countrycode: 'DE',
  },
  {
    postalcode: '91355',
    city: 'Hiltpoltstein',
    countrycode: 'DE',
  },
  {
    postalcode: '91356',
    city: 'Kirchehrenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91358',
    city: 'Kunreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '91358',
    city: 'Steingraben',
    countrycode: 'DE',
  },
  {
    postalcode: '91359',
    city: 'Leutenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91361',
    city: 'Pinzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '91362',
    city: 'Pretzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '91364',
    city: 'Unterleinleiter',
    countrycode: 'DE',
  },
  {
    postalcode: '91365',
    city: 'Weilersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91367',
    city: 'Weißenohe',
    countrycode: 'DE',
  },
  {
    postalcode: '91369',
    city: 'Wiesenthau',
    countrycode: 'DE',
  },
  {
    postalcode: '91413',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '91438',
    city: 'Bad Windsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91438',
    city: 'Dutzenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '91443',
    city: 'Scheinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '91448',
    city: 'Emskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '91452',
    city: 'Wilhermsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91456',
    city: 'Diespeck',
    countrycode: 'DE',
  },
  {
    postalcode: '91459',
    city: 'Markt Erlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91460',
    city: 'Baudenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91462',
    city: 'Dachsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91463',
    city: 'Dietersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91465',
    city: 'Ergersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91466',
    city: 'Gerhardshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '91468',
    city: 'Gutenstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '91469',
    city: 'Erlachskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '91469',
    city: 'Hagenbüchach',
    countrycode: 'DE',
  },
  {
    postalcode: '91471',
    city: 'Illesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91472',
    city: 'Ipsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91474',
    city: 'Langenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '91475',
    city: 'Lonnerstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '91477',
    city: 'Markt Bibart',
    countrycode: 'DE',
  },
  {
    postalcode: '91478',
    city: 'Markt Nordheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91480',
    city: 'Markt Taschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91481',
    city: 'Münchsteinach',
    countrycode: 'DE',
  },
  {
    postalcode: '91483',
    city: 'Oberscheinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '91484',
    city: 'Sugenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91486',
    city: 'Uehlfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '91487',
    city: 'Vestenbergsgreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '91489',
    city: 'Tanzenhaid',
    countrycode: 'DE',
  },
  {
    postalcode: '91489',
    city: 'Wilhelmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91522',
    city: 'Ansbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91541',
    city: 'Rothenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '91550',
    city: 'Dinkelsbühl',
    countrycode: 'DE',
  },
  {
    postalcode: '91555',
    city: 'Feuchtwangen',
    countrycode: 'DE',
  },
  {
    postalcode: '91560',
    city: 'Heilsbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '91564',
    city: 'Neuendettelsau',
    countrycode: 'DE',
  },
  {
    postalcode: '91567',
    city: 'Herrieden',
    countrycode: 'DE',
  },
  {
    postalcode: '91572',
    city: 'Bechhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '91575',
    city: 'Windsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91578',
    city: 'Leutershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '91580',
    city: 'Petersaurach',
    countrycode: 'DE',
  },
  {
    postalcode: '91583',
    city: 'Diebach',
    countrycode: 'DE',
  },
  {
    postalcode: '91583',
    city: 'Schillingsfürst',
    countrycode: 'DE',
  },
  {
    postalcode: '91586',
    city: 'Lichtenau',
    countrycode: 'DE',
  },
  {
    postalcode: '91587',
    city: 'Adelshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '91589',
    city: 'Aurach',
    countrycode: 'DE',
  },
  {
    postalcode: '91590',
    city: 'Bruckberg',
    countrycode: 'DE',
  },
  {
    postalcode: '91592',
    city: 'Buch a. Wald',
    countrycode: 'DE',
  },
  {
    postalcode: '91593',
    city: 'Burgbernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91595',
    city: 'Burgoberbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91596',
    city: 'Burk',
    countrycode: 'DE',
  },
  {
    postalcode: '91598',
    city: 'Colmberg',
    countrycode: 'DE',
  },
  {
    postalcode: '91599',
    city: 'Dentlein',
    countrycode: 'DE',
  },
  {
    postalcode: '91601',
    city: 'Dombühl',
    countrycode: 'DE',
  },
  {
    postalcode: '91602',
    city: 'Dürrwangen',
    countrycode: 'DE',
  },
  {
    postalcode: '91604',
    city: 'Flachslanden',
    countrycode: 'DE',
  },
  {
    postalcode: '91605',
    city: 'Gallmersgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '91607',
    city: 'Gebsattel',
    countrycode: 'DE',
  },
  {
    postalcode: '91608',
    city: 'Geslau',
    countrycode: 'DE',
  },
  {
    postalcode: '91610',
    city: 'Insingen',
    countrycode: 'DE',
  },
  {
    postalcode: '91611',
    city: 'Lehrberg',
    countrycode: 'DE',
  },
  {
    postalcode: '91613',
    city: 'Marktbergel',
    countrycode: 'DE',
  },
  {
    postalcode: '91614',
    city: 'Mönchsroth',
    countrycode: 'DE',
  },
  {
    postalcode: '91616',
    city: 'Neusitz',
    countrycode: 'DE',
  },
  {
    postalcode: '91617',
    city: 'Oberdachstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '91619',
    city: 'Obernzenn',
    countrycode: 'DE',
  },
  {
    postalcode: '91620',
    city: 'Ohrenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91622',
    city: 'Rügland',
    countrycode: 'DE',
  },
  {
    postalcode: '91623',
    city: 'Sachsen',
    countrycode: 'DE',
  },
  {
    postalcode: '91625',
    city: 'Schnelldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91626',
    city: 'Schopfloch',
    countrycode: 'DE',
  },
  {
    postalcode: '91628',
    city: 'Steinsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '91629',
    city: 'Weihenzell',
    countrycode: 'DE',
  },
  {
    postalcode: '91631',
    city: 'Wettringen',
    countrycode: 'DE',
  },
  {
    postalcode: '91632',
    city: 'Wieseth',
    countrycode: 'DE',
  },
  {
    postalcode: '91634',
    city: 'Wilburgstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '91635',
    city: 'Windelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91637',
    city: 'Wörnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '91639',
    city: 'Wolframs-Eschenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91710',
    city: 'Gunzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '91717',
    city: 'Linkersbaindt',
    countrycode: 'DE',
  },
  {
    postalcode: '91717',
    city: 'Schmalzmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '91717',
    city: 'Wassertrüdingen',
    countrycode: 'DE',
  },
  {
    postalcode: '91719',
    city: 'Heidenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91720',
    city: 'Absberg',
    countrycode: 'DE',
  },
  {
    postalcode: '91722',
    city: 'Arberg',
    countrycode: 'DE',
  },
  {
    postalcode: '91723',
    city: 'Dittenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91725',
    city: 'Ehingen',
    countrycode: 'DE',
  },
  {
    postalcode: '91726',
    city: 'Gerolfingen',
    countrycode: 'DE',
  },
  {
    postalcode: '91728',
    city: 'Gnotzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91729',
    city: 'Haundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91731',
    city: 'Langfurth',
    countrycode: 'DE',
  },
  {
    postalcode: '91732',
    city: 'Merkendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '91734',
    city: 'Mitteleschenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91735',
    city: 'Muhr',
    countrycode: 'DE',
  },
  {
    postalcode: '91737',
    city: 'Ornbau',
    countrycode: 'DE',
  },
  {
    postalcode: '91738',
    city: 'Pfofeld',
    countrycode: 'DE',
  },
  {
    postalcode: '91740',
    city: 'Röckingen',
    countrycode: 'DE',
  },
  {
    postalcode: '91741',
    city: 'Theilenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '91743',
    city: 'Unterschwaningen',
    countrycode: 'DE',
  },
  {
    postalcode: '91744',
    city: 'Weiltingen',
    countrycode: 'DE',
  },
  {
    postalcode: '91746',
    city: 'Weidenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '91747',
    city: 'Westheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91749',
    city: 'Wittelshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '91757',
    city: 'Treuchtlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '91781',
    city: 'Weißenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '91785',
    city: 'Pleinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '91788',
    city: 'Altheimersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '91788',
    city: 'Pappenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91790',
    city: 'Bergen',
    countrycode: 'DE',
  },
  {
    postalcode: '91790',
    city: 'Burgsalach',
    countrycode: 'DE',
  },
  {
    postalcode: '91790',
    city: 'Nennslingen',
    countrycode: 'DE',
  },
  {
    postalcode: '91790',
    city: 'Raitenbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '91792',
    city: 'Ellingen',
    countrycode: 'DE',
  },
  {
    postalcode: '91793',
    city: 'Alesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91795',
    city: 'Dollnstein',
    countrycode: 'DE',
  },
  {
    postalcode: '91796',
    city: 'Ettenstatt',
    countrycode: 'DE',
  },
  {
    postalcode: '91798',
    city: 'Höttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '91799',
    city: 'Langenaltheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91801',
    city: 'Markt Berolzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91802',
    city: 'Meinheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91804',
    city: 'Mörnsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '91805',
    city: 'Polsingen',
    countrycode: 'DE',
  },
  {
    postalcode: '91807',
    city: 'Lichtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '91807',
    city: 'Maxberg',
    countrycode: 'DE',
  },
  {
    postalcode: '91807',
    city: 'Solnhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '91809',
    city: 'Wellheim',
    countrycode: 'DE',
  },
  {
    postalcode: '92224',
    city: 'Amberg',
    countrycode: 'DE',
  },
  {
    postalcode: '92237',
    city: 'Sulzbach-Rosenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '92242',
    city: 'Hirschau',
    countrycode: 'DE',
  },
  {
    postalcode: '92245',
    city: 'Kümmersbruck',
    countrycode: 'DE',
  },
  {
    postalcode: '92249',
    city: 'Vilseck',
    countrycode: 'DE',
  },
  {
    postalcode: '92253',
    city: 'Schnaittenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '92256',
    city: 'Hahnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '92259',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '92260',
    city: 'Ammerthal',
    countrycode: 'DE',
  },
  {
    postalcode: '92262',
    city: 'Birgland',
    countrycode: 'DE',
  },
  {
    postalcode: '92263',
    city: 'Ebermannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '92265',
    city: 'Edelsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '92266',
    city: 'Ensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '92268',
    city: 'Etzelwang',
    countrycode: 'DE',
  },
  {
    postalcode: '92269',
    city: 'Fensterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '92271',
    city: 'Freihung',
    countrycode: 'DE',
  },
  {
    postalcode: '92272',
    city: 'Freudenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '92274',
    city: 'Gebenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '92275',
    city: 'Hirschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '92277',
    city: 'Hohenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '92278',
    city: 'Illschwang',
    countrycode: 'DE',
  },
  {
    postalcode: '92280',
    city: 'Kastl',
    countrycode: 'DE',
  },
  {
    postalcode: '92281',
    city: 'Königstein',
    countrycode: 'DE',
  },
  {
    postalcode: '92283',
    city: 'Lauterhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '92284',
    city: 'Poppenricht',
    countrycode: 'DE',
  },
  {
    postalcode: '92286',
    city: 'Rieden',
    countrycode: 'DE',
  },
  {
    postalcode: '92287',
    city: 'Schmidmühlen',
    countrycode: 'DE',
  },
  {
    postalcode: '92289',
    city: 'Ursensollen',
    countrycode: 'DE',
  },
  {
    postalcode: '92318',
    city: 'Neumarkt',
    countrycode: 'DE',
  },
  {
    postalcode: '92331',
    city: 'Lupburg',
    countrycode: 'DE',
  },
  {
    postalcode: '92331',
    city: 'Parsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '92334',
    city: 'Berching',
    countrycode: 'DE',
  },
  {
    postalcode: '92339',
    city: 'Beilngries',
    countrycode: 'DE',
  },
  {
    postalcode: '92342',
    city: 'Freystadt',
    countrycode: 'DE',
  },
  {
    postalcode: '92342',
    city: 'Realsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '92345',
    city: 'Dietfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '92345',
    city: 'Erbmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '92345',
    city: 'Pfenninghof',
    countrycode: 'DE',
  },
  {
    postalcode: '92348',
    city: 'Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '92348',
    city: 'Großwiesenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '92353',
    city: 'Postbauer-Heng',
    countrycode: 'DE',
  },
  {
    postalcode: '92355',
    city: 'Velburg',
    countrycode: 'DE',
  },
  {
    postalcode: '92358',
    city: 'Seubersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '92360',
    city: 'Mühlhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '92361',
    city: 'Berngau',
    countrycode: 'DE',
  },
  {
    postalcode: '92363',
    city: 'Breitenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '92364',
    city: 'Deining',
    countrycode: 'DE',
  },
  {
    postalcode: '92366',
    city: 'Hohenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '92367',
    city: 'Pilsach',
    countrycode: 'DE',
  },
  {
    postalcode: '92369',
    city: 'Sengenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '92421',
    city: 'Schwandorf',
    countrycode: 'DE',
  },
  {
    postalcode: '92431',
    city: 'Neunburg',
    countrycode: 'DE',
  },
  {
    postalcode: '92436',
    city: 'Bruck',
    countrycode: 'DE',
  },
  {
    postalcode: '92439',
    city: 'Bodenwöhr',
    countrycode: 'DE',
  },
  {
    postalcode: '92442',
    city: 'Wackersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '92444',
    city: 'Rötz',
    countrycode: 'DE',
  },
  {
    postalcode: '92445',
    city: 'Neukirchen-Balbini',
    countrycode: 'DE',
  },
  {
    postalcode: '92447',
    city: 'Schwarzhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '92449',
    city: 'Steinberg',
    countrycode: 'DE',
  },
  {
    postalcode: '92507',
    city: 'Nabburg',
    countrycode: 'DE',
  },
  {
    postalcode: '92521',
    city: 'Schwarzenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '92526',
    city: 'Oberviechtach',
    countrycode: 'DE',
  },
  {
    postalcode: '92533',
    city: 'Wernberg-Köblitz',
    countrycode: 'DE',
  },
  {
    postalcode: '92536',
    city: 'Pfreimd',
    countrycode: 'DE',
  },
  {
    postalcode: '92539',
    city: 'Schönsee',
    countrycode: 'DE',
  },
  {
    postalcode: '92540',
    city: 'Altendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '92542',
    city: 'Dieterskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '92543',
    city: 'Guteneck',
    countrycode: 'DE',
  },
  {
    postalcode: '92545',
    city: 'Niedermurach',
    countrycode: 'DE',
  },
  {
    postalcode: '92546',
    city: 'Schmidgaden',
    countrycode: 'DE',
  },
  {
    postalcode: '92548',
    city: 'Schwarzach',
    countrycode: 'DE',
  },
  {
    postalcode: '92549',
    city: 'Stadlern',
    countrycode: 'DE',
  },
  {
    postalcode: '92551',
    city: 'Stulln',
    countrycode: 'DE',
  },
  {
    postalcode: '92552',
    city: 'Teunz',
    countrycode: 'DE',
  },
  {
    postalcode: '92554',
    city: 'Thanstein',
    countrycode: 'DE',
  },
  {
    postalcode: '92555',
    city: 'Trausnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '92557',
    city: 'Weiding',
    countrycode: 'DE',
  },
  {
    postalcode: '92559',
    city: 'Winklarn',
    countrycode: 'DE',
  },
  {
    postalcode: '92637',
    city: 'Theisseil',
    countrycode: 'DE',
  },
  {
    postalcode: '92637',
    city: 'Weiden',
    countrycode: 'DE',
  },
  {
    postalcode: '92648',
    city: 'Vohenstrauß',
    countrycode: 'DE',
  },
  {
    postalcode: '92655',
    city: 'Grafenwöhr',
    countrycode: 'DE',
  },
  {
    postalcode: '92660',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '92665',
    city: 'Altenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '92665',
    city: 'Kirchendemenreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '92670',
    city: 'Windischeschenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '92676',
    city: 'Eschenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '92676',
    city: 'Speinshart',
    countrycode: 'DE',
  },
  {
    postalcode: '92681',
    city: 'Erbendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '92681',
    city: 'Kammerermühle',
    countrycode: 'DE',
  },
  {
    postalcode: '92681',
    city: 'Sassenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '92685',
    city: 'Floß',
    countrycode: 'DE',
  },
  {
    postalcode: '92690',
    city: 'Glashütte',
    countrycode: 'DE',
  },
  {
    postalcode: '92690',
    city: 'Pressath',
    countrycode: 'DE',
  },
  {
    postalcode: '92693',
    city: 'Eslarn',
    countrycode: 'DE',
  },
  {
    postalcode: '92694',
    city: 'Etzenricht',
    countrycode: 'DE',
  },
  {
    postalcode: '92696',
    city: 'Flossenbürg',
    countrycode: 'DE',
  },
  {
    postalcode: '92697',
    city: 'Georgenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '92699',
    city: 'Bechtsrieth',
    countrycode: 'DE',
  },
  {
    postalcode: '92699',
    city: 'Irchenrieth',
    countrycode: 'DE',
  },
  {
    postalcode: '92700',
    city: 'Kaltenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '92702',
    city: 'Kohlberg',
    countrycode: 'DE',
  },
  {
    postalcode: '92703',
    city: 'Krummennaab',
    countrycode: 'DE',
  },
  {
    postalcode: '92705',
    city: 'Leuchtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '92706',
    city: 'Luhe-Wildenau',
    countrycode: 'DE',
  },
  {
    postalcode: '92708',
    city: 'Mantel',
    countrycode: 'DE',
  },
  {
    postalcode: '92709',
    city: 'Moosbach',
    countrycode: 'DE',
  },
  {
    postalcode: '92711',
    city: 'Parkstein',
    countrycode: 'DE',
  },
  {
    postalcode: '92712',
    city: 'Pirk',
    countrycode: 'DE',
  },
  {
    postalcode: '92714',
    city: 'Pleystein',
    countrycode: 'DE',
  },
  {
    postalcode: '92715',
    city: 'Püchersreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '92717',
    city: 'Reuth',
    countrycode: 'DE',
  },
  {
    postalcode: '92718',
    city: 'Schirmitz',
    countrycode: 'DE',
  },
  {
    postalcode: '92720',
    city: 'Schwarzenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '92721',
    city: 'Störnstein',
    countrycode: 'DE',
  },
  {
    postalcode: '92723',
    city: 'Gleiritsch',
    countrycode: 'DE',
  },
  {
    postalcode: '92723',
    city: 'Tännesberg',
    countrycode: 'DE',
  },
  {
    postalcode: '92724',
    city: 'Trabitz',
    countrycode: 'DE',
  },
  {
    postalcode: '92726',
    city: 'Waidhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '92727',
    city: 'Waldthurn',
    countrycode: 'DE',
  },
  {
    postalcode: '92729',
    city: 'Weiherhammer',
    countrycode: 'DE',
  },
  {
    postalcode: '93047',
    city: 'Regensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '93049',
    city: 'Regensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '93051',
    city: 'Regensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '93053',
    city: 'Regensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '93055',
    city: 'Regensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '93057',
    city: 'Regensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '93059',
    city: 'Regensburg',
    countrycode: 'DE',
  },
  {
    postalcode: '93073',
    city: 'Neutraubling',
    countrycode: 'DE',
  },
  {
    postalcode: '93077',
    city: 'Bad Abbach',
    countrycode: 'DE',
  },
  {
    postalcode: '93080',
    city: 'Pentling',
    countrycode: 'DE',
  },
  {
    postalcode: '93083',
    city: 'Obertraubling',
    countrycode: 'DE',
  },
  {
    postalcode: '93086',
    city: 'Wörth',
    countrycode: 'DE',
  },
  {
    postalcode: '93087',
    city: 'Alteglofsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '93089',
    city: 'Aufhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '93090',
    city: 'Bach',
    countrycode: 'DE',
  },
  {
    postalcode: '93092',
    city: 'Barbing',
    countrycode: 'DE',
  },
  {
    postalcode: '93093',
    city: 'Donaustauf',
    countrycode: 'DE',
  },
  {
    postalcode: '93093',
    city: 'Jagdschloß Thiergarten',
    countrycode: 'DE',
  },
  {
    postalcode: '93095',
    city: 'Hagelstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '93096',
    city: 'Köfering',
    countrycode: 'DE',
  },
  {
    postalcode: '93098',
    city: 'Mintraching',
    countrycode: 'DE',
  },
  {
    postalcode: '93099',
    city: 'Mötzing',
    countrycode: 'DE',
  },
  {
    postalcode: '93101',
    city: 'Pfakofen',
    countrycode: 'DE',
  },
  {
    postalcode: '93102',
    city: 'Pfatter',
    countrycode: 'DE',
  },
  {
    postalcode: '93104',
    city: 'Riekofen',
    countrycode: 'DE',
  },
  {
    postalcode: '93104',
    city: 'Sünching',
    countrycode: 'DE',
  },
  {
    postalcode: '93105',
    city: 'Tegernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '93107',
    city: 'Thalmassing',
    countrycode: 'DE',
  },
  {
    postalcode: '93109',
    city: 'Wiesent',
    countrycode: 'DE',
  },
  {
    postalcode: '93128',
    city: 'Regenstauf',
    countrycode: 'DE',
  },
  {
    postalcode: '93133',
    city: 'Burglengenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '93138',
    city: 'Lappersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '93142',
    city: 'Maxhütte-Haidhof',
    countrycode: 'DE',
  },
  {
    postalcode: '93149',
    city: 'Nittenau',
    countrycode: 'DE',
  },
  {
    postalcode: '93149',
    city: 'Spitalhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '93152',
    city: 'Deckelstein',
    countrycode: 'DE',
  },
  {
    postalcode: '93152',
    city: 'Ebenwies',
    countrycode: 'DE',
  },
  {
    postalcode: '93152',
    city: 'Hardt',
    countrycode: 'DE',
  },
  {
    postalcode: '93152',
    city: 'Nittendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '93152',
    city: 'Zeiler',
    countrycode: 'DE',
  },
  {
    postalcode: '93155',
    city: 'Hemau',
    countrycode: 'DE',
  },
  {
    postalcode: '93158',
    city: 'Teublitz',
    countrycode: 'DE',
  },
  {
    postalcode: '93161',
    city: 'Grafenried',
    countrycode: 'DE',
  },
  {
    postalcode: '93161',
    city: 'Sinzing',
    countrycode: 'DE',
  },
  {
    postalcode: '93164',
    city: 'Brunn',
    countrycode: 'DE',
  },
  {
    postalcode: '93164',
    city: 'Laaber',
    countrycode: 'DE',
  },
  {
    postalcode: '93167',
    city: 'Falkenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '93167',
    city: 'Holzmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '93170',
    city: 'Bernhardswald',
    countrycode: 'DE',
  },
  {
    postalcode: '93173',
    city: 'Wenzenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '93176',
    city: 'Beratzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '93177',
    city: 'Altenthann',
    countrycode: 'DE',
  },
  {
    postalcode: '93179',
    city: 'Brennberg',
    countrycode: 'DE',
  },
  {
    postalcode: '93180',
    city: 'Deuerling',
    countrycode: 'DE',
  },
  {
    postalcode: '93182',
    city: 'Duggendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '93183',
    city: 'Holzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '93183',
    city: 'Kallmünz',
    countrycode: 'DE',
  },
  {
    postalcode: '93185',
    city: 'Michelsneukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '93186',
    city: 'Pettendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '93188',
    city: 'Pielenhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '93189',
    city: 'Reichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '93191',
    city: 'Rettenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '93192',
    city: 'Wald',
    countrycode: 'DE',
  },
  {
    postalcode: '93194',
    city: 'Walderbach',
    countrycode: 'DE',
  },
  {
    postalcode: '93195',
    city: 'Biersackschlag',
    countrycode: 'DE',
  },
  {
    postalcode: '93195',
    city: 'Wolfsegg',
    countrycode: 'DE',
  },
  {
    postalcode: '93197',
    city: 'Zeitlarn',
    countrycode: 'DE',
  },
  {
    postalcode: '93199',
    city: 'Zell',
    countrycode: 'DE',
  },
  {
    postalcode: '93309',
    city: 'Kelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '93326',
    city: 'Abensberg',
    countrycode: 'DE',
  },
  {
    postalcode: '93333',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '93336',
    city: 'Altmannstein',
    countrycode: 'DE',
  },
  {
    postalcode: '93339',
    city: 'Riedenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '93342',
    city: 'Saal',
    countrycode: 'DE',
  },
  {
    postalcode: '93343',
    city: 'Essing',
    countrycode: 'DE',
  },
  {
    postalcode: '93345',
    city: 'Hausen',
    countrycode: 'DE',
  },
  {
    postalcode: '93346',
    city: 'Ihrlerstein',
    countrycode: 'DE',
  },
  {
    postalcode: '93348',
    city: 'Kirchdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '93349',
    city: 'Mindelstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '93351',
    city: 'Painten',
    countrycode: 'DE',
  },
  {
    postalcode: '93352',
    city: 'Rohr',
    countrycode: 'DE',
  },
  {
    postalcode: '93354',
    city: 'Biburg',
    countrycode: 'DE',
  },
  {
    postalcode: '93354',
    city: 'Siegenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '93356',
    city: 'Teugn',
    countrycode: 'DE',
  },
  {
    postalcode: '93358',
    city: 'Train',
    countrycode: 'DE',
  },
  {
    postalcode: '93359',
    city: 'Wildenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '93413',
    city: 'Cham',
    countrycode: 'DE',
  },
  {
    postalcode: '93426',
    city: 'Roding',
    countrycode: 'DE',
  },
  {
    postalcode: '93437',
    city: 'Dürnberg',
    countrycode: 'DE',
  },
  {
    postalcode: '93437',
    city: 'Enklarn',
    countrycode: 'DE',
  },
  {
    postalcode: '93437',
    city: 'Furth im Wald',
    countrycode: 'DE',
  },
  {
    postalcode: '93444',
    city: 'Kötzting',
    countrycode: 'DE',
  },
  {
    postalcode: '93449',
    city: 'Albernhof',
    countrycode: 'DE',
  },
  {
    postalcode: '93449',
    city: 'Almosmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '93449',
    city: 'Ast',
    countrycode: 'DE',
  },
  {
    postalcode: '93449',
    city: 'Eschlhof',
    countrycode: 'DE',
  },
  {
    postalcode: '93449',
    city: 'Geigant',
    countrycode: 'DE',
  },
  {
    postalcode: '93449',
    city: 'Haschaberg',
    countrycode: 'DE',
  },
  {
    postalcode: '93449',
    city: 'Hirschhöf',
    countrycode: 'DE',
  },
  {
    postalcode: '93449',
    city: 'Häuslarn',
    countrycode: 'DE',
  },
  {
    postalcode: '93449',
    city: 'Kritzenast',
    countrycode: 'DE',
  },
  {
    postalcode: '93449',
    city: 'Sinzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '93449',
    city: 'Waldmünchen',
    countrycode: 'DE',
  },
  {
    postalcode: '93453',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '93455',
    city: 'Traitsching',
    countrycode: 'DE',
  },
  {
    postalcode: '93458',
    city: 'Eschlkam',
    countrycode: 'DE',
  },
  {
    postalcode: '93462',
    city: 'Lam',
    countrycode: 'DE',
  },
  {
    postalcode: '93464',
    city: 'Tiefenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '93466',
    city: 'Chamerau',
    countrycode: 'DE',
  },
  {
    postalcode: '93468',
    city: 'Miltach',
    countrycode: 'DE',
  },
  {
    postalcode: '93470',
    city: 'Lohberg',
    countrycode: 'DE',
  },
  {
    postalcode: '93471',
    city: 'Arnbruck',
    countrycode: 'DE',
  },
  {
    postalcode: '93473',
    city: 'Arnschwang',
    countrycode: 'DE',
  },
  {
    postalcode: '93474',
    city: 'Arrach',
    countrycode: 'DE',
  },
  {
    postalcode: '93476',
    city: 'Blaibach',
    countrycode: 'DE',
  },
  {
    postalcode: '93477',
    city: 'Gleißenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '93479',
    city: 'Grafenwiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '93479',
    city: 'Watzlsteg',
    countrycode: 'DE',
  },
  {
    postalcode: '93480',
    city: 'Hohenwarth',
    countrycode: 'DE',
  },
  {
    postalcode: '93482',
    city: 'Pemfling',
    countrycode: 'DE',
  },
  {
    postalcode: '93483',
    city: 'Pösing',
    countrycode: 'DE',
  },
  {
    postalcode: '93485',
    city: 'Rimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '93486',
    city: 'Runding',
    countrycode: 'DE',
  },
  {
    postalcode: '93488',
    city: 'Schönthal',
    countrycode: 'DE',
  },
  {
    postalcode: '93489',
    city: 'Schorndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '93491',
    city: 'Stamsried',
    countrycode: 'DE',
  },
  {
    postalcode: '93492',
    city: 'Treffelstein',
    countrycode: 'DE',
  },
  {
    postalcode: '93494',
    city: 'Waffenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '93495',
    city: 'Weiding',
    countrycode: 'DE',
  },
  {
    postalcode: '93497',
    city: 'Willmering',
    countrycode: 'DE',
  },
  {
    postalcode: '93499',
    city: 'Zandt',
    countrycode: 'DE',
  },
  {
    postalcode: '94032',
    city: 'Passau',
    countrycode: 'DE',
  },
  {
    postalcode: '94034',
    city: 'Passau',
    countrycode: 'DE',
  },
  {
    postalcode: '94036',
    city: 'Abraham',
    countrycode: 'DE',
  },
  {
    postalcode: '94036',
    city: 'Passau',
    countrycode: 'DE',
  },
  {
    postalcode: '94051',
    city: 'Hauzenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94060',
    city: 'Breitwies',
    countrycode: 'DE',
  },
  {
    postalcode: '94060',
    city: 'Pocking',
    countrycode: 'DE',
  },
  {
    postalcode: '94065',
    city: 'Waldkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '94072',
    city: 'Bad Füssing',
    countrycode: 'DE',
  },
  {
    postalcode: '94078',
    city: 'Freyung',
    countrycode: 'DE',
  },
  {
    postalcode: '94081',
    city: 'Fürstenzell',
    countrycode: 'DE',
  },
  {
    postalcode: '94086',
    city: 'Bad Griesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94089',
    city: 'Neureichenau',
    countrycode: 'DE',
  },
  {
    postalcode: '94094',
    city: 'Malching',
    countrycode: 'DE',
  },
  {
    postalcode: '94094',
    city: 'Rotthalmünster',
    countrycode: 'DE',
  },
  {
    postalcode: '94099',
    city: 'Ruhstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94104',
    city: 'Tittling',
    countrycode: 'DE',
  },
  {
    postalcode: '94104',
    city: 'Witzmannsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94107',
    city: 'Rollhäusl',
    countrycode: 'DE',
  },
  {
    postalcode: '94107',
    city: 'Untergriesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94110',
    city: 'Wegscheid',
    countrycode: 'DE',
  },
  {
    postalcode: '94113',
    city: 'Tiefenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94116',
    city: 'Hutthurm',
    countrycode: 'DE',
  },
  {
    postalcode: '94118',
    city: 'Jandelsbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '94121',
    city: 'Salzweg',
    countrycode: 'DE',
  },
  {
    postalcode: '94124',
    city: 'Büchlberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94127',
    city: 'Neuburg',
    countrycode: 'DE',
  },
  {
    postalcode: '94130',
    city: 'Obernzell',
    countrycode: 'DE',
  },
  {
    postalcode: '94133',
    city: 'Röhrnbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94136',
    city: 'Thyrnau',
    countrycode: 'DE',
  },
  {
    postalcode: '94137',
    city: 'Bayerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94139',
    city: 'Breitenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94140',
    city: 'Ering',
    countrycode: 'DE',
  },
  {
    postalcode: '94142',
    city: 'Fürsteneck',
    countrycode: 'DE',
  },
  {
    postalcode: '94143',
    city: 'Grainet',
    countrycode: 'DE',
  },
  {
    postalcode: '94145',
    city: 'Haidmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '94146',
    city: 'Hinterschmiding',
    countrycode: 'DE',
  },
  {
    postalcode: '94148',
    city: 'Kirchham',
    countrycode: 'DE',
  },
  {
    postalcode: '94149',
    city: 'Kößlarn',
    countrycode: 'DE',
  },
  {
    postalcode: '94151',
    city: 'Mauth',
    countrycode: 'DE',
  },
  {
    postalcode: '94152',
    city: 'Neuhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '94154',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '94155',
    city: 'Otterskirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '94157',
    city: 'Perlesreut',
    countrycode: 'DE',
  },
  {
    postalcode: '94158',
    city: 'Philippsreut',
    countrycode: 'DE',
  },
  {
    postalcode: '94160',
    city: 'Ringelai',
    countrycode: 'DE',
  },
  {
    postalcode: '94161',
    city: 'Ruderting',
    countrycode: 'DE',
  },
  {
    postalcode: '94163',
    city: 'Saldenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '94164',
    city: 'Sonnen',
    countrycode: 'DE',
  },
  {
    postalcode: '94166',
    city: 'Stubenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94167',
    city: 'Tettenweis',
    countrycode: 'DE',
  },
  {
    postalcode: '94169',
    city: 'Thurmansbang',
    countrycode: 'DE',
  },
  {
    postalcode: '94209',
    city: 'Regen',
    countrycode: 'DE',
  },
  {
    postalcode: '94227',
    city: 'Lindberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94227',
    city: 'Schwellhäusl',
    countrycode: 'DE',
  },
  {
    postalcode: '94227',
    city: 'Zwiesel',
    countrycode: 'DE',
  },
  {
    postalcode: '94234',
    city: 'Viechtach',
    countrycode: 'DE',
  },
  {
    postalcode: '94239',
    city: 'Gotteszell',
    countrycode: 'DE',
  },
  {
    postalcode: '94239',
    city: 'Ruhmannsfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '94239',
    city: 'Zachenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94244',
    city: 'Geiersthal',
    countrycode: 'DE',
  },
  {
    postalcode: '94244',
    city: 'Teisnach',
    countrycode: 'DE',
  },
  {
    postalcode: '94249',
    city: 'Bodenmais',
    countrycode: 'DE',
  },
  {
    postalcode: '94250',
    city: 'Achslach',
    countrycode: 'DE',
  },
  {
    postalcode: '94252',
    city: 'Bayerisch Eisenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '94253',
    city: 'Bischofsmais',
    countrycode: 'DE',
  },
  {
    postalcode: '94255',
    city: 'Böbrach',
    countrycode: 'DE',
  },
  {
    postalcode: '94256',
    city: 'Drachselsried',
    countrycode: 'DE',
  },
  {
    postalcode: '94258',
    city: 'Frauenau',
    countrycode: 'DE',
  },
  {
    postalcode: '94259',
    city: 'Kirchberg i. Wald',
    countrycode: 'DE',
  },
  {
    postalcode: '94261',
    city: 'Kirchdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94262',
    city: 'Kollnburg',
    countrycode: 'DE',
  },
  {
    postalcode: '94264',
    city: 'Langdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94265',
    city: 'Patersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94267',
    city: 'Prackenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94269',
    city: 'Rinchnach',
    countrycode: 'DE',
  },
  {
    postalcode: '94315',
    city: 'Straubing',
    countrycode: 'DE',
  },
  {
    postalcode: '94327',
    city: 'Bogen',
    countrycode: 'DE',
  },
  {
    postalcode: '94330',
    city: 'Aiterhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '94330',
    city: 'Salching',
    countrycode: 'DE',
  },
  {
    postalcode: '94333',
    city: 'Geiselhöring',
    countrycode: 'DE',
  },
  {
    postalcode: '94336',
    city: 'Hunderdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94336',
    city: 'Windberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94339',
    city: 'Leiblfing',
    countrycode: 'DE',
  },
  {
    postalcode: '94342',
    city: 'Irlbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94342',
    city: 'Straßkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '94344',
    city: 'Wiesenfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '94345',
    city: 'Aholfing',
    countrycode: 'DE',
  },
  {
    postalcode: '94347',
    city: 'Ascha',
    countrycode: 'DE',
  },
  {
    postalcode: '94348',
    city: 'Atting',
    countrycode: 'DE',
  },
  {
    postalcode: '94350',
    city: 'Falkenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '94351',
    city: 'Feldkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '94353',
    city: 'Haibach',
    countrycode: 'DE',
  },
  {
    postalcode: '94354',
    city: 'Haselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94356',
    city: 'Kirchroth',
    countrycode: 'DE',
  },
  {
    postalcode: '94357',
    city: 'Höhenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '94357',
    city: 'Konzell',
    countrycode: 'DE',
  },
  {
    postalcode: '94359',
    city: 'Loitzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94360',
    city: 'Mitterfels',
    countrycode: 'DE',
  },
  {
    postalcode: '94362',
    city: 'Neukirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '94363',
    city: 'Oberschneiding',
    countrycode: 'DE',
  },
  {
    postalcode: '94365',
    city: 'Parkstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '94366',
    city: 'Perasdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94368',
    city: 'Perkam',
    countrycode: 'DE',
  },
  {
    postalcode: '94369',
    city: 'Rain',
    countrycode: 'DE',
  },
  {
    postalcode: '94371',
    city: 'Rattenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94372',
    city: 'Rattiszell',
    countrycode: 'DE',
  },
  {
    postalcode: '94374',
    city: 'Eckhütt',
    countrycode: 'DE',
  },
  {
    postalcode: '94374',
    city: 'Oberlindberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94374',
    city: 'Schwarzach',
    countrycode: 'DE',
  },
  {
    postalcode: '94375',
    city: 'Stallwang',
    countrycode: 'DE',
  },
  {
    postalcode: '94377',
    city: 'Steinach',
    countrycode: 'DE',
  },
  {
    postalcode: '94379',
    city: 'Sankt Englmar',
    countrycode: 'DE',
  },
  {
    postalcode: '94405',
    city: 'Landau',
    countrycode: 'DE',
  },
  {
    postalcode: '94419',
    city: 'Reisbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94424',
    city: 'Arnstorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94428',
    city: 'Eichendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94431',
    city: 'Pilsting',
    countrycode: 'DE',
  },
  {
    postalcode: '94436',
    city: 'Simbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94437',
    city: 'Mamming',
    countrycode: 'DE',
  },
  {
    postalcode: '94439',
    city: 'Roßbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94447',
    city: 'Plattling',
    countrycode: 'DE',
  },
  {
    postalcode: '94469',
    city: 'Deggendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94469',
    city: 'Freiberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94474',
    city: 'Vilshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '94481',
    city: 'Grafenau',
    countrycode: 'DE',
  },
  {
    postalcode: '94481',
    city: 'Rehbruck',
    countrycode: 'DE',
  },
  {
    postalcode: '94486',
    city: 'Osterhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '94491',
    city: 'Hengersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94496',
    city: 'Ortenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '94501',
    city: 'Aidenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94501',
    city: 'Aldersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94501',
    city: 'Beutelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94501',
    city: 'Freudenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '94501',
    city: 'Mairing',
    countrycode: 'DE',
  },
  {
    postalcode: '94505',
    city: 'Bernried',
    countrycode: 'DE',
  },
  {
    postalcode: '94508',
    city: 'Schöllnach',
    countrycode: 'DE',
  },
  {
    postalcode: '94513',
    city: 'Schönberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94518',
    city: 'Linden',
    countrycode: 'DE',
  },
  {
    postalcode: '94518',
    city: 'Spiegelau',
    countrycode: 'DE',
  },
  {
    postalcode: '94522',
    city: 'Wallersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94526',
    city: 'Dammersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94526',
    city: 'Laufmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '94526',
    city: 'Lehmberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94526',
    city: 'Metten',
    countrycode: 'DE',
  },
  {
    postalcode: '94527',
    city: 'Aholming',
    countrycode: 'DE',
  },
  {
    postalcode: '94529',
    city: 'Aicha',
    countrycode: 'DE',
  },
  {
    postalcode: '94529',
    city: 'Gottholling',
    countrycode: 'DE',
  },
  {
    postalcode: '94529',
    city: 'Kerschbaum',
    countrycode: 'DE',
  },
  {
    postalcode: '94529',
    city: 'Weiding',
    countrycode: 'DE',
  },
  {
    postalcode: '94530',
    city: 'Auerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94532',
    city: 'Außernzell',
    countrycode: 'DE',
  },
  {
    postalcode: '94533',
    city: 'Buchhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '94535',
    city: 'Eging',
    countrycode: 'DE',
  },
  {
    postalcode: '94536',
    city: 'Eppenschlag',
    countrycode: 'DE',
  },
  {
    postalcode: '94538',
    city: 'Fürstenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '94539',
    city: 'Grafling',
    countrycode: 'DE',
  },
  {
    postalcode: '94541',
    city: 'Grattersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94542',
    city: 'Haarbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94544',
    city: 'Hofkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '94545',
    city: 'Hohenau',
    countrycode: 'DE',
  },
  {
    postalcode: '94547',
    city: 'Iggensbach',
    countrycode: 'DE',
  },
  {
    postalcode: '94548',
    city: 'Innernzell',
    countrycode: 'DE',
  },
  {
    postalcode: '94550',
    city: 'Künzing',
    countrycode: 'DE',
  },
  {
    postalcode: '94551',
    city: 'Hunding',
    countrycode: 'DE',
  },
  {
    postalcode: '94551',
    city: 'Lalling',
    countrycode: 'DE',
  },
  {
    postalcode: '94551',
    city: 'Reigersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94553',
    city: 'Mariaposching',
    countrycode: 'DE',
  },
  {
    postalcode: '94554',
    city: 'Moos',
    countrycode: 'DE',
  },
  {
    postalcode: '94556',
    city: 'Neuschönau',
    countrycode: 'DE',
  },
  {
    postalcode: '94557',
    city: 'Niederalteich',
    countrycode: 'DE',
  },
  {
    postalcode: '94559',
    city: 'Niederwinkling',
    countrycode: 'DE',
  },
  {
    postalcode: '94560',
    city: 'Offenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '94562',
    city: 'Oberpöring',
    countrycode: 'DE',
  },
  {
    postalcode: '94563',
    city: 'Otzing',
    countrycode: 'DE',
  },
  {
    postalcode: '94565',
    city: 'Bertholling',
    countrycode: 'DE',
  },
  {
    postalcode: '94565',
    city: 'Erlhof',
    countrycode: 'DE',
  },
  {
    postalcode: '94565',
    city: 'Haseneck',
    countrycode: 'DE',
  },
  {
    postalcode: '94565',
    city: 'Naßkamping',
    countrycode: 'DE',
  },
  {
    postalcode: '94565',
    city: 'Punzing',
    countrycode: 'DE',
  },
  {
    postalcode: '94565',
    city: 'Rathsmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94565',
    city: 'Schwarzhöring',
    countrycode: 'DE',
  },
  {
    postalcode: '94565',
    city: 'Söldenham',
    countrycode: 'DE',
  },
  {
    postalcode: '94566',
    city: 'Reichenberg bei Riedlhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '94566',
    city: 'Riedlhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '94568',
    city: 'Auwies',
    countrycode: 'DE',
  },
  {
    postalcode: '94568',
    city: 'St Oswald',
    countrycode: 'DE',
  },
  {
    postalcode: '94569',
    city: 'Stephansposching',
    countrycode: 'DE',
  },
  {
    postalcode: '94571',
    city: 'Schaufling',
    countrycode: 'DE',
  },
  {
    postalcode: '94572',
    city: 'Schöfweg',
    countrycode: 'DE',
  },
  {
    postalcode: '94574',
    city: 'Wallerfing',
    countrycode: 'DE',
  },
  {
    postalcode: '94575',
    city: 'Windorf',
    countrycode: 'DE',
  },
  {
    postalcode: '94577',
    city: 'Winzer',
    countrycode: 'DE',
  },
  {
    postalcode: '94579',
    city: 'Zenting',
    countrycode: 'DE',
  },
  {
    postalcode: '95028',
    city: 'Hof',
    countrycode: 'DE',
  },
  {
    postalcode: '95030',
    city: 'Hof',
    countrycode: 'DE',
  },
  {
    postalcode: '95032',
    city: 'Hof',
    countrycode: 'DE',
  },
  {
    postalcode: '95100',
    city: 'Selb',
    countrycode: 'DE',
  },
  {
    postalcode: '95100',
    city: 'Vorsuchhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '95111',
    city: 'Rehau',
    countrycode: 'DE',
  },
  {
    postalcode: '95119',
    city: 'Naila',
    countrycode: 'DE',
  },
  {
    postalcode: '95126',
    city: 'Schwarzenbach an der Saale',
    countrycode: 'DE',
  },
  {
    postalcode: '95131',
    city: 'Fels',
    countrycode: 'DE',
  },
  {
    postalcode: '95131',
    city: 'Schwarzenbach am Wald',
    countrycode: 'DE',
  },
  {
    postalcode: '95138',
    city: 'Bad Steben',
    countrycode: 'DE',
  },
  {
    postalcode: '95145',
    city: 'Oberkotzau',
    countrycode: 'DE',
  },
  {
    postalcode: '95152',
    city: 'Selbitz',
    countrycode: 'DE',
  },
  {
    postalcode: '95158',
    city: 'Kirchenlamitz',
    countrycode: 'DE',
  },
  {
    postalcode: '95158',
    city: 'Mittelschieda',
    countrycode: 'DE',
  },
  {
    postalcode: '95163',
    city: 'Weißenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '95168',
    city: 'Marktleuthen',
    countrycode: 'DE',
  },
  {
    postalcode: '95173',
    city: 'Schönwald',
    countrycode: 'DE',
  },
  {
    postalcode: '95176',
    city: 'Konradsreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '95179',
    city: 'Geroldsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '95180',
    city: 'Berg',
    countrycode: 'DE',
  },
  {
    postalcode: '95182',
    city: 'Döhlau',
    countrycode: 'DE',
  },
  {
    postalcode: '95183',
    city: 'Feilitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '95183',
    city: 'Trogen',
    countrycode: 'DE',
  },
  {
    postalcode: '95183',
    city: 'Töpen',
    countrycode: 'DE',
  },
  {
    postalcode: '95185',
    city: 'Gattendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '95186',
    city: 'Höchstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '95188',
    city: 'Issigau',
    countrycode: 'DE',
  },
  {
    postalcode: '95189',
    city: 'Fattigsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '95189',
    city: 'Köditz',
    countrycode: 'DE',
  },
  {
    postalcode: '95191',
    city: 'Leupoldsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '95192',
    city: 'Lichtenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95194',
    city: 'Regnitzlosau',
    countrycode: 'DE',
  },
  {
    postalcode: '95195',
    city: 'Röslau',
    countrycode: 'DE',
  },
  {
    postalcode: '95197',
    city: 'Schauenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '95199',
    city: 'Thierstein',
    countrycode: 'DE',
  },
  {
    postalcode: '95213',
    city: 'Münchberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95233',
    city: 'Helmbrechts',
    countrycode: 'DE',
  },
  {
    postalcode: '95234',
    city: 'Sparneck',
    countrycode: 'DE',
  },
  {
    postalcode: '95236',
    city: 'Hinterbug',
    countrycode: 'DE',
  },
  {
    postalcode: '95236',
    city: 'Stammbach',
    countrycode: 'DE',
  },
  {
    postalcode: '95237',
    city: 'Weißdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '95239',
    city: 'Zell',
    countrycode: 'DE',
  },
  {
    postalcode: '95326',
    city: 'Kulmbach',
    countrycode: 'DE',
  },
  {
    postalcode: '95336',
    city: 'Mainleus',
    countrycode: 'DE',
  },
  {
    postalcode: '95339',
    city: 'Neuenmarkt',
    countrycode: 'DE',
  },
  {
    postalcode: '95339',
    city: 'Wirsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95346',
    city: 'Stadtsteinach',
    countrycode: 'DE',
  },
  {
    postalcode: '95349',
    city: 'Thurnau',
    countrycode: 'DE',
  },
  {
    postalcode: '95352',
    city: 'Marktleugast',
    countrycode: 'DE',
  },
  {
    postalcode: '95355',
    city: 'Presseck',
    countrycode: 'DE',
  },
  {
    postalcode: '95355',
    city: 'Schmölz',
    countrycode: 'DE',
  },
  {
    postalcode: '95356',
    city: 'Grafengehaig',
    countrycode: 'DE',
  },
  {
    postalcode: '95356',
    city: 'Großrehmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '95356',
    city: 'Kleinrehmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '95356',
    city: 'Zegastmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '95358',
    city: 'Guttenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95359',
    city: 'Kasendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '95361',
    city: 'Ködnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '95362',
    city: 'Kupferberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95364',
    city: 'Ludwigschorgast',
    countrycode: 'DE',
  },
  {
    postalcode: '95365',
    city: 'Rugendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '95367',
    city: 'Trebgast',
    countrycode: 'DE',
  },
  {
    postalcode: '95369',
    city: 'Untersteinach',
    countrycode: 'DE',
  },
  {
    postalcode: '95444',
    city: 'Bayreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '95445',
    city: 'Bayreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '95447',
    city: 'Bayreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '95448',
    city: 'Bayreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '95460',
    city: 'Bad Berneck',
    countrycode: 'DE',
  },
  {
    postalcode: '95460',
    city: 'Friedrichshof',
    countrycode: 'DE',
  },
  {
    postalcode: '95460',
    city: 'Lindenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '95463',
    city: 'Bindlach',
    countrycode: 'DE',
  },
  {
    postalcode: '95466',
    city: 'Kirchenpingarten',
    countrycode: 'DE',
  },
  {
    postalcode: '95466',
    city: 'Weidenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95469',
    city: 'Speichersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '95473',
    city: 'Creußen',
    countrycode: 'DE',
  },
  {
    postalcode: '95473',
    city: 'Haag',
    countrycode: 'DE',
  },
  {
    postalcode: '95473',
    city: 'Prebitz',
    countrycode: 'DE',
  },
  {
    postalcode: '95478',
    city: 'Kemnath',
    countrycode: 'DE',
  },
  {
    postalcode: '95482',
    city: 'Gefrees',
    countrycode: 'DE',
  },
  {
    postalcode: '95485',
    city: 'Warmensteinach',
    countrycode: 'DE',
  },
  {
    postalcode: '95488',
    city: 'Eckersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '95490',
    city: 'Mistelgau',
    countrycode: 'DE',
  },
  {
    postalcode: '95490',
    city: 'Schöchleinsmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '95491',
    city: 'Ahorntal',
    countrycode: 'DE',
  },
  {
    postalcode: '95493',
    city: 'Bischofsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '95494',
    city: 'Gesees',
    countrycode: 'DE',
  },
  {
    postalcode: '95496',
    city: 'Glashütten',
    countrycode: 'DE',
  },
  {
    postalcode: '95497',
    city: 'Goldkronach',
    countrycode: 'DE',
  },
  {
    postalcode: '95499',
    city: 'Harsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '95500',
    city: 'Heinersreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '95502',
    city: 'Himmelkron',
    countrycode: 'DE',
  },
  {
    postalcode: '95503',
    city: 'Hummeltal',
    countrycode: 'DE',
  },
  {
    postalcode: '95505',
    city: 'Immenreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '95506',
    city: 'Kastl',
    countrycode: 'DE',
  },
  {
    postalcode: '95508',
    city: 'Kulmain',
    countrycode: 'DE',
  },
  {
    postalcode: '95509',
    city: 'Marktschorgast',
    countrycode: 'DE',
  },
  {
    postalcode: '95511',
    city: 'Mistelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '95512',
    city: 'Neudrossenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '95514',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '95515',
    city: 'Plankenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '95515',
    city: 'Schnackenwöhr',
    countrycode: 'DE',
  },
  {
    postalcode: '95517',
    city: 'Emtmannsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95517',
    city: 'Seybothenreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '95519',
    city: 'Schlammersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '95519',
    city: 'Vorbach',
    countrycode: 'DE',
  },
  {
    postalcode: '95615',
    city: 'Marktredwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '95615',
    city: 'Silberhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '95632',
    city: 'Wunsiedel',
    countrycode: 'DE',
  },
  {
    postalcode: '95643',
    city: 'Tirschenreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '95652',
    city: 'Waldsassen',
    countrycode: 'DE',
  },
  {
    postalcode: '95659',
    city: 'Arzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95666',
    city: 'Leonberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95666',
    city: 'Mitterteich',
    countrycode: 'DE',
  },
  {
    postalcode: '95671',
    city: 'Bärnau',
    countrycode: 'DE',
  },
  {
    postalcode: '95676',
    city: 'Wiesau',
    countrycode: 'DE',
  },
  {
    postalcode: '95679',
    city: 'Waldershof',
    countrycode: 'DE',
  },
  {
    postalcode: '95680',
    city: 'Bad Alexandersbad',
    countrycode: 'DE',
  },
  {
    postalcode: '95682',
    city: 'Brand',
    countrycode: 'DE',
  },
  {
    postalcode: '95683',
    city: 'Ebnath',
    countrycode: 'DE',
  },
  {
    postalcode: '95685',
    city: 'Falkenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95686',
    city: 'Fichtelberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95686',
    city: 'Seehaus',
    countrycode: 'DE',
  },
  {
    postalcode: '95688',
    city: 'Friedenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '95689',
    city: 'Fuchsmühl',
    countrycode: 'DE',
  },
  {
    postalcode: '95689',
    city: 'Hurtingöd',
    countrycode: 'DE',
  },
  {
    postalcode: '95691',
    city: 'Hohenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95692',
    city: 'Konnersreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '95694',
    city: 'Mehlmeisel',
    countrycode: 'DE',
  },
  {
    postalcode: '95695',
    city: 'Mähring',
    countrycode: 'DE',
  },
  {
    postalcode: '95697',
    city: 'Nagel',
    countrycode: 'DE',
  },
  {
    postalcode: '95698',
    city: 'Neualbenreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '95700',
    city: 'Neusorg',
    countrycode: 'DE',
  },
  {
    postalcode: '95701',
    city: 'Pechbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '95703',
    city: 'Auerberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95703',
    city: 'Plößberg',
    countrycode: 'DE',
  },
  {
    postalcode: '95704',
    city: 'Pullenreuth',
    countrycode: 'DE',
  },
  {
    postalcode: '95706',
    city: 'Schirnding',
    countrycode: 'DE',
  },
  {
    postalcode: '95707',
    city: 'Thiersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '95709',
    city: 'Tröstau',
    countrycode: 'DE',
  },
  {
    postalcode: '96047',
    city: 'Bamberg',
    countrycode: 'DE',
  },
  {
    postalcode: '96049',
    city: 'Bamberg',
    countrycode: 'DE',
  },
  {
    postalcode: '96050',
    city: 'Bamberg',
    countrycode: 'DE',
  },
  {
    postalcode: '96052',
    city: 'Bamberg',
    countrycode: 'DE',
  },
  {
    postalcode: '96103',
    city: 'Hallstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '96106',
    city: 'Ebern',
    countrycode: 'DE',
  },
  {
    postalcode: '96110',
    city: 'Scheßlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '96114',
    city: 'Hirschaid',
    countrycode: 'DE',
  },
  {
    postalcode: '96117',
    city: 'Memmelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96120',
    city: 'Bischberg',
    countrycode: 'DE',
  },
  {
    postalcode: '96123',
    city: 'Litzendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96126',
    city: 'Ermershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '96126',
    city: 'Maroldsweisach',
    countrycode: 'DE',
  },
  {
    postalcode: '96129',
    city: 'Strullendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96132',
    city: 'Schlüsselfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '96135',
    city: 'Stegaurach',
    countrycode: 'DE',
  },
  {
    postalcode: '96138',
    city: 'Burgebrach',
    countrycode: 'DE',
  },
  {
    postalcode: '96142',
    city: 'Hollfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '96145',
    city: 'Seßlach',
    countrycode: 'DE',
  },
  {
    postalcode: '96146',
    city: 'Altendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96148',
    city: 'Baunach',
    countrycode: 'DE',
  },
  {
    postalcode: '96149',
    city: 'Breitengüßbach',
    countrycode: 'DE',
  },
  {
    postalcode: '96151',
    city: 'Breitbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '96152',
    city: 'Burghaslach',
    countrycode: 'DE',
  },
  {
    postalcode: '96154',
    city: 'Burgwindheim',
    countrycode: 'DE',
  },
  {
    postalcode: '96155',
    city: 'Buttenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '96157',
    city: 'Ebrach',
    countrycode: 'DE',
  },
  {
    postalcode: '96158',
    city: 'Frensdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96160',
    city: 'Geiselwind',
    countrycode: 'DE',
  },
  {
    postalcode: '96161',
    city: 'Gerach',
    countrycode: 'DE',
  },
  {
    postalcode: '96163',
    city: 'Gundelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '96164',
    city: 'Kemmern',
    countrycode: 'DE',
  },
  {
    postalcode: '96166',
    city: 'Kirchlauter',
    countrycode: 'DE',
  },
  {
    postalcode: '96167',
    city: 'Königsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '96169',
    city: 'Lauter',
    countrycode: 'DE',
  },
  {
    postalcode: '96170',
    city: 'Lisberg',
    countrycode: 'DE',
  },
  {
    postalcode: '96170',
    city: 'Priesendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96172',
    city: 'Mühlhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '96173',
    city: 'Oberhaid',
    countrycode: 'DE',
  },
  {
    postalcode: '96175',
    city: 'Pettstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '96176',
    city: 'Pfarrweisach',
    countrycode: 'DE',
  },
  {
    postalcode: '96178',
    city: 'Pommersfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '96179',
    city: 'Rattelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96181',
    city: 'Rauhenebrach',
    countrycode: 'DE',
  },
  {
    postalcode: '96182',
    city: 'Reckendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96184',
    city: 'Rentweinsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96185',
    city: 'Schönbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '96187',
    city: 'Stadelhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '96188',
    city: 'Stettfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '96190',
    city: 'Untermerzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '96191',
    city: 'Viereth-Trunstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '96193',
    city: 'Wachenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '96194',
    city: 'Walsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96196',
    city: 'Wattendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96197',
    city: 'Wonsees',
    countrycode: 'DE',
  },
  {
    postalcode: '96199',
    city: 'Zapfendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96215',
    city: 'Lichtenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '96224',
    city: 'Burgkunstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '96231',
    city: 'Bad Staffelstein',
    countrycode: 'DE',
  },
  {
    postalcode: '96237',
    city: 'Ebersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96242',
    city: 'Sonnefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '96247',
    city: 'Michelau',
    countrycode: 'DE',
  },
  {
    postalcode: '96250',
    city: 'Ebensfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '96253',
    city: 'Untersiemau',
    countrycode: 'DE',
  },
  {
    postalcode: '96257',
    city: 'Marktgraitz',
    countrycode: 'DE',
  },
  {
    postalcode: '96257',
    city: 'Redwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '96260',
    city: 'Weismain',
    countrycode: 'DE',
  },
  {
    postalcode: '96264',
    city: 'Altenkunstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '96268',
    city: 'Mitwitz',
    countrycode: 'DE',
  },
  {
    postalcode: '96269',
    city: 'Großheirath',
    countrycode: 'DE',
  },
  {
    postalcode: '96271',
    city: 'Grub',
    countrycode: 'DE',
  },
  {
    postalcode: '96272',
    city: 'Hochstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '96274',
    city: 'Itzgrund',
    countrycode: 'DE',
  },
  {
    postalcode: '96275',
    city: 'Marktzeuln',
    countrycode: 'DE',
  },
  {
    postalcode: '96277',
    city: 'Schneckenlohe',
    countrycode: 'DE',
  },
  {
    postalcode: '96279',
    city: 'Weidhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '96317',
    city: 'Kaltbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '96317',
    city: 'Kronach',
    countrycode: 'DE',
  },
  {
    postalcode: '96328',
    city: 'Küps',
    countrycode: 'DE',
  },
  {
    postalcode: '96332',
    city: 'Pressig',
    countrycode: 'DE',
  },
  {
    postalcode: '96332',
    city: 'Rittersmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '96337',
    city: 'Ludwigsstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '96342',
    city: 'Stockheim',
    countrycode: 'DE',
  },
  {
    postalcode: '96346',
    city: 'Wallenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '96349',
    city: 'Mauthaus',
    countrycode: 'DE',
  },
  {
    postalcode: '96349',
    city: 'Steinwiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '96352',
    city: 'Wilhelmsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '96355',
    city: 'Tettau',
    countrycode: 'DE',
  },
  {
    postalcode: '96358',
    city: 'Kohlmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '96358',
    city: 'Reichenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '96358',
    city: 'Teuschnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '96361',
    city: 'Steinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '96364',
    city: 'Marktrodach',
    countrycode: 'DE',
  },
  {
    postalcode: '96365',
    city: 'Nordhalben',
    countrycode: 'DE',
  },
  {
    postalcode: '96367',
    city: 'Tschirn',
    countrycode: 'DE',
  },
  {
    postalcode: '96369',
    city: 'Weißenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '96369',
    city: 'Welzmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '96450',
    city: 'Coburg',
    countrycode: 'DE',
  },
  {
    postalcode: '96465',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '96472',
    city: 'Rödental',
    countrycode: 'DE',
  },
  {
    postalcode: '96476',
    city: 'Bad Rodach',
    countrycode: 'DE',
  },
  {
    postalcode: '96479',
    city: 'Weitramsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96482',
    city: 'Ahorn',
    countrycode: 'DE',
  },
  {
    postalcode: '96484',
    city: 'Meeder',
    countrycode: 'DE',
  },
  {
    postalcode: '96486',
    city: 'Lautertal',
    countrycode: 'DE',
  },
  {
    postalcode: '96487',
    city: 'Dörfles-Esbach',
    countrycode: 'DE',
  },
  {
    postalcode: '96489',
    city: 'Niederfüllbach',
    countrycode: 'DE',
  },
  {
    postalcode: '96515',
    city: 'Heinersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96515',
    city: 'Hüttengrund',
    countrycode: 'DE',
  },
  {
    postalcode: '96515',
    city: 'Jagdshof',
    countrycode: 'DE',
  },
  {
    postalcode: '96515',
    city: 'Judenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '96515',
    city: 'Neuenbau',
    countrycode: 'DE',
  },
  {
    postalcode: '96515',
    city: 'Sonneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '96523',
    city: 'Eschenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '96523',
    city: 'Haselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '96523',
    city: 'Hasenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '96523',
    city: 'Steinach',
    countrycode: 'DE',
  },
  {
    postalcode: '96524',
    city: 'Föritz',
    countrycode: 'DE',
  },
  {
    postalcode: '96524',
    city: 'Gefell',
    countrycode: 'DE',
  },
  {
    postalcode: '96524',
    city: 'Heubisch',
    countrycode: 'DE',
  },
  {
    postalcode: '96524',
    city: 'Mupperg',
    countrycode: 'DE',
  },
  {
    postalcode: '96524',
    city: 'Neuhaus-Schierschnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '96528',
    city: 'Bachfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '96528',
    city: 'Effelder',
    countrycode: 'DE',
  },
  {
    postalcode: '96528',
    city: 'Grümpen',
    countrycode: 'DE',
  },
  {
    postalcode: '96528',
    city: 'Rabenäußig',
    countrycode: 'DE',
  },
  {
    postalcode: '96528',
    city: 'Rauenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '96528',
    city: 'Rückerswind',
    countrycode: 'DE',
  },
  {
    postalcode: '96528',
    city: 'Schalkau',
    countrycode: 'DE',
  },
  {
    postalcode: '96528',
    city: 'Seltendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '96529',
    city: 'Mengersgereuth-Hämmern',
    countrycode: 'DE',
  },
  {
    postalcode: '97070',
    city: 'Würzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '97072',
    city: 'Würzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '97074',
    city: 'Würzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '97076',
    city: 'Würzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '97078',
    city: 'Würzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '97080',
    city: 'Würzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '97082',
    city: 'Würzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '97084',
    city: 'Würzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '97199',
    city: 'Ochsenfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '97204',
    city: 'Höchberg',
    countrycode: 'DE',
  },
  {
    postalcode: '97209',
    city: 'Veitshöchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97215',
    city: 'Simmershofen',
    countrycode: 'DE',
  },
  {
    postalcode: '97215',
    city: 'Uffenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97215',
    city: 'Weigenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97218',
    city: 'Gerbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '97222',
    city: 'Rimpar',
    countrycode: 'DE',
  },
  {
    postalcode: '97225',
    city: 'Zellingen',
    countrycode: 'DE',
  },
  {
    postalcode: '97228',
    city: 'Rottendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '97230',
    city: 'Estenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97232',
    city: 'Giebelstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97234',
    city: 'Reichenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '97236',
    city: 'Randersacker',
    countrycode: 'DE',
  },
  {
    postalcode: '97237',
    city: 'Altertheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97239',
    city: 'Aub',
    countrycode: 'DE',
  },
  {
    postalcode: '97241',
    city: 'Bergtheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97241',
    city: 'Oberpleichfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97243',
    city: 'Bieberehren',
    countrycode: 'DE',
  },
  {
    postalcode: '97244',
    city: 'Bütthard',
    countrycode: 'DE',
  },
  {
    postalcode: '97246',
    city: 'Eibelstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97247',
    city: 'Eisenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97249',
    city: 'Eisingen',
    countrycode: 'DE',
  },
  {
    postalcode: '97250',
    city: 'Erlabrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '97252',
    city: 'Frickenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97253',
    city: 'Gaukönigshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '97255',
    city: 'Gelchsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97255',
    city: 'Sonderhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '97256',
    city: 'Geroldshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97258',
    city: 'Gollhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '97258',
    city: 'Hemmersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97258',
    city: 'Ippesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97258',
    city: 'Oberickelsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97259',
    city: 'Greußenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97261',
    city: 'Güntersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '97262',
    city: 'Hausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97264',
    city: 'Helmstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97265',
    city: 'Hettstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97267',
    city: 'Himmelstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97268',
    city: 'Kirchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97270',
    city: 'Kist',
    countrycode: 'DE',
  },
  {
    postalcode: '97271',
    city: 'Kleinrinderfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97273',
    city: 'Kürnach',
    countrycode: 'DE',
  },
  {
    postalcode: '97274',
    city: 'Leinach',
    countrycode: 'DE',
  },
  {
    postalcode: '97276',
    city: 'Margetshöchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97277',
    city: 'Neubrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '97279',
    city: 'Prosselsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97280',
    city: 'Remlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '97282',
    city: 'Retzstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97283',
    city: 'Riedenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97285',
    city: 'Röttingen',
    countrycode: 'DE',
  },
  {
    postalcode: '97285',
    city: 'Tauberrettersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97286',
    city: 'Sommerhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97286',
    city: 'Winterhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97288',
    city: 'Theilheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97289',
    city: 'Thüngen',
    countrycode: 'DE',
  },
  {
    postalcode: '97291',
    city: 'Thüngersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97292',
    city: 'Holzkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '97292',
    city: 'Holzmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '97292',
    city: 'Uettingen',
    countrycode: 'DE',
  },
  {
    postalcode: '97294',
    city: 'Unterpleichfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97295',
    city: 'Waldbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '97297',
    city: 'Waldbüttelbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '97299',
    city: 'Zell',
    countrycode: 'DE',
  },
  {
    postalcode: '97318',
    city: 'Biebelried',
    countrycode: 'DE',
  },
  {
    postalcode: '97318',
    city: 'Kitzingen',
    countrycode: 'DE',
  },
  {
    postalcode: '97320',
    city: 'Albertshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '97320',
    city: 'Buchbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '97320',
    city: 'Großlangheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97320',
    city: 'Mainstockheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97320',
    city: 'Sulzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97332',
    city: 'Volkach',
    countrycode: 'DE',
  },
  {
    postalcode: '97334',
    city: 'Nordheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97334',
    city: 'Sommerach',
    countrycode: 'DE',
  },
  {
    postalcode: '97337',
    city: 'Dettelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97340',
    city: 'Marktbreit',
    countrycode: 'DE',
  },
  {
    postalcode: '97340',
    city: 'Martinsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97340',
    city: 'Segnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '97342',
    city: 'Marktsteft',
    countrycode: 'DE',
  },
  {
    postalcode: '97342',
    city: 'Obernbreit',
    countrycode: 'DE',
  },
  {
    postalcode: '97342',
    city: 'Seinsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97346',
    city: 'Iphofen',
    countrycode: 'DE',
  },
  {
    postalcode: '97348',
    city: 'Markt Einersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97348',
    city: 'Rödelsee',
    countrycode: 'DE',
  },
  {
    postalcode: '97348',
    city: 'Willanzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97350',
    city: 'Mainbernheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97353',
    city: 'Wiesentheid',
    countrycode: 'DE',
  },
  {
    postalcode: '97355',
    city: 'Abtswind',
    countrycode: 'DE',
  },
  {
    postalcode: '97355',
    city: 'Castell',
    countrycode: 'DE',
  },
  {
    postalcode: '97355',
    city: 'Kleinlangheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97355',
    city: 'Rüdenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97355',
    city: 'Wiesenbronn',
    countrycode: 'DE',
  },
  {
    postalcode: '97357',
    city: 'Prichsenstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97359',
    city: 'Münsterschwarzach Abtei',
    countrycode: 'DE',
  },
  {
    postalcode: '97359',
    city: 'Schwarzach',
    countrycode: 'DE',
  },
  {
    postalcode: '97421',
    city: 'Schweinfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '97422',
    city: 'Schweinfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '97424',
    city: 'Schweinfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '97437',
    city: 'Haßfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '97440',
    city: 'Werneck',
    countrycode: 'DE',
  },
  {
    postalcode: '97447',
    city: 'Frankenwinheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97447',
    city: 'Gerolzhofen',
    countrycode: 'DE',
  },
  {
    postalcode: '97450',
    city: 'Arnstein',
    countrycode: 'DE',
  },
  {
    postalcode: '97453',
    city: 'Schonungen',
    countrycode: 'DE',
  },
  {
    postalcode: '97456',
    city: 'Dittelbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '97461',
    city: 'Hofheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97464',
    city: 'Niederwerrn',
    countrycode: 'DE',
  },
  {
    postalcode: '97469',
    city: 'Gochsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97475',
    city: 'Zeil',
    countrycode: 'DE',
  },
  {
    postalcode: '97478',
    city: 'Knetzgau',
    countrycode: 'DE',
  },
  {
    postalcode: '97478',
    city: 'Reinhardswinden',
    countrycode: 'DE',
  },
  {
    postalcode: '97483',
    city: 'Eltmann',
    countrycode: 'DE',
  },
  {
    postalcode: '97486',
    city: 'Klaubmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '97486',
    city: 'Königsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '97488',
    city: 'Stadtlauringen',
    countrycode: 'DE',
  },
  {
    postalcode: '97490',
    city: 'Poppenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97491',
    city: 'Aidhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97493',
    city: 'Bergrheinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97494',
    city: 'Bundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '97496',
    city: 'Burgpreppach',
    countrycode: 'DE',
  },
  {
    postalcode: '97497',
    city: 'Dingolshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97499',
    city: 'Donnersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '97500',
    city: 'Ebelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97502',
    city: 'Euerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97503',
    city: 'Gädheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97505',
    city: 'Geldersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97506',
    city: 'Grafenrheinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97508',
    city: 'Grettstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97509',
    city: 'Kloster St Ludwig',
    countrycode: 'DE',
  },
  {
    postalcode: '97509',
    city: 'Kolitzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97511',
    city: 'Lülsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97513',
    city: 'Michelau',
    countrycode: 'DE',
  },
  {
    postalcode: '97514',
    city: 'Markertsgrün',
    countrycode: 'DE',
  },
  {
    postalcode: '97514',
    city: 'Oberaurach',
    countrycode: 'DE',
  },
  {
    postalcode: '97516',
    city: 'Oberschwarzach',
    countrycode: 'DE',
  },
  {
    postalcode: '97517',
    city: 'Rannungen',
    countrycode: 'DE',
  },
  {
    postalcode: '97519',
    city: 'Riedbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97520',
    city: 'Röthlein',
    countrycode: 'DE',
  },
  {
    postalcode: '97522',
    city: 'Sand',
    countrycode: 'DE',
  },
  {
    postalcode: '97523',
    city: 'Schwanfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97525',
    city: 'Schwebheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97526',
    city: 'Reichelshof',
    countrycode: 'DE',
  },
  {
    postalcode: '97526',
    city: 'Sennfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97528',
    city: 'Sulzdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '97529',
    city: 'Sulzheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97531',
    city: 'Theres',
    countrycode: 'DE',
  },
  {
    postalcode: '97532',
    city: 'Üchtelhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97534',
    city: 'Waigolshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97535',
    city: 'Wasserlosen',
    countrycode: 'DE',
  },
  {
    postalcode: '97537',
    city: 'Wipfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97539',
    city: 'Wonfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '97616',
    city: 'Bad Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97616',
    city: 'Salz',
    countrycode: 'DE',
  },
  {
    postalcode: '97618',
    city: 'Heustreu',
    countrycode: 'DE',
  },
  {
    postalcode: '97618',
    city: 'Hohenroth',
    countrycode: 'DE',
  },
  {
    postalcode: '97618',
    city: 'Hollstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97618',
    city: 'Niederlauer',
    countrycode: 'DE',
  },
  {
    postalcode: '97618',
    city: 'Rödelmaier',
    countrycode: 'DE',
  },
  {
    postalcode: '97618',
    city: 'Strahlungen',
    countrycode: 'DE',
  },
  {
    postalcode: '97618',
    city: 'Unsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '97618',
    city: 'Wollbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97618',
    city: 'Wülfershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97631',
    city: 'Bad Königshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '97633',
    city: 'Aubstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97633',
    city: 'Großbardorf',
    countrycode: 'DE',
  },
  {
    postalcode: '97633',
    city: 'Großeibstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97633',
    city: 'Herbstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97633',
    city: 'Höchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97633',
    city: 'Saal',
    countrycode: 'DE',
  },
  {
    postalcode: '97633',
    city: 'Sulzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97633',
    city: 'Trappstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97638',
    city: 'Mellrichstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97640',
    city: 'Hendungen',
    countrycode: 'DE',
  },
  {
    postalcode: '97640',
    city: 'Oberstreu',
    countrycode: 'DE',
  },
  {
    postalcode: '97640',
    city: 'Stockheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97645',
    city: 'Ostheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97647',
    city: 'Hausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97647',
    city: 'Nordheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97647',
    city: 'Schweinfurter Haus',
    countrycode: 'DE',
  },
  {
    postalcode: '97647',
    city: 'Sondheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97647',
    city: 'Thüringer Hütte',
    countrycode: 'DE',
  },
  {
    postalcode: '97647',
    city: 'Willmars',
    countrycode: 'DE',
  },
  {
    postalcode: '97650',
    city: 'Fladungen',
    countrycode: 'DE',
  },
  {
    postalcode: '97653',
    city: 'Bischofsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97654',
    city: 'Bastheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97656',
    city: 'Oberelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97657',
    city: 'Sandberg',
    countrycode: 'DE',
  },
  {
    postalcode: '97659',
    city: 'Schönau',
    countrycode: 'DE',
  },
  {
    postalcode: '97688',
    city: 'Bad Kissingen',
    countrycode: 'DE',
  },
  {
    postalcode: '97702',
    city: 'Münnerstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97705',
    city: 'Burkardroth',
    countrycode: 'DE',
  },
  {
    postalcode: '97708',
    city: 'Bad Bocklet',
    countrycode: 'DE',
  },
  {
    postalcode: '97711',
    city: 'Maßbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97711',
    city: 'Thundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '97714',
    city: 'Oerlenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97717',
    city: 'Aura',
    countrycode: 'DE',
  },
  {
    postalcode: '97717',
    city: 'Euerdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '97717',
    city: 'Sulzthal',
    countrycode: 'DE',
  },
  {
    postalcode: '97720',
    city: 'Nüdlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '97723',
    city: 'Oberthulba',
    countrycode: 'DE',
  },
  {
    postalcode: '97724',
    city: 'Burglauer',
    countrycode: 'DE',
  },
  {
    postalcode: '97725',
    city: 'Elfershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97727',
    city: 'Fuchsstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97729',
    city: 'Ramsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '97737',
    city: 'Gemünden',
    countrycode: 'DE',
  },
  {
    postalcode: '97753',
    city: 'Karlstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97762',
    city: 'Hammelburg',
    countrycode: 'DE',
  },
  {
    postalcode: '97769',
    city: 'Bad Brückenau',
    countrycode: 'DE',
  },
  {
    postalcode: '97772',
    city: 'Wildflecken',
    countrycode: 'DE',
  },
  {
    postalcode: '97773',
    city: 'Aura',
    countrycode: 'DE',
  },
  {
    postalcode: '97775',
    city: 'Burgsinn',
    countrycode: 'DE',
  },
  {
    postalcode: '97776',
    city: 'Eußenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97778',
    city: 'Fellen',
    countrycode: 'DE',
  },
  {
    postalcode: '97779',
    city: 'Geroda',
    countrycode: 'DE',
  },
  {
    postalcode: '97780',
    city: 'Gössenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97782',
    city: 'Gräfendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '97783',
    city: 'Karsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97785',
    city: 'Mittelsinn',
    countrycode: 'DE',
  },
  {
    postalcode: '97786',
    city: 'Motten',
    countrycode: 'DE',
  },
  {
    postalcode: '97788',
    city: 'Neuendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '97789',
    city: 'Oberleichtersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97791',
    city: 'Obersinn',
    countrycode: 'DE',
  },
  {
    postalcode: '97792',
    city: 'Riedenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '97794',
    city: 'Rieneck',
    countrycode: 'DE',
  },
  {
    postalcode: '97795',
    city: 'Schondra',
    countrycode: 'DE',
  },
  {
    postalcode: '97797',
    city: 'Wartmannsroth',
    countrycode: 'DE',
  },
  {
    postalcode: '97799',
    city: 'Zeitlofs',
    countrycode: 'DE',
  },
  {
    postalcode: '97816',
    city: 'Lohr',
    countrycode: 'DE',
  },
  {
    postalcode: '97828',
    city: 'Fuchsenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '97828',
    city: 'Hessenmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '97828',
    city: 'Marktheidenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97833',
    city: 'Frammersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97834',
    city: 'Birkenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97836',
    city: 'Bischbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '97836',
    city: 'Karlshöhe',
    countrycode: 'DE',
  },
  {
    postalcode: '97836',
    city: 'Schleifthor',
    countrycode: 'DE',
  },
  {
    postalcode: '97837',
    city: 'Erlenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97839',
    city: 'Esselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97840',
    city: 'Erlenfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '97840',
    city: 'Hafenlohr',
    countrycode: 'DE',
  },
  {
    postalcode: '97840',
    city: 'Lichtenau',
    countrycode: 'DE',
  },
  {
    postalcode: '97842',
    city: 'Karbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97843',
    city: 'Neuhütten',
    countrycode: 'DE',
  },
  {
    postalcode: '97845',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97846',
    city: 'Partenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '97848',
    city: 'Rechtenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97849',
    city: 'Roden',
    countrycode: 'DE',
  },
  {
    postalcode: '97851',
    city: 'Rothenfels',
    countrycode: 'DE',
  },
  {
    postalcode: '97852',
    city: 'Schleifmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '97852',
    city: 'Schollbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '97854',
    city: 'Steinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97855',
    city: 'Triefenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '97857',
    city: 'Urspringen',
    countrycode: 'DE',
  },
  {
    postalcode: '97859',
    city: 'Wiesthal',
    countrycode: 'DE',
  },
  {
    postalcode: '97877',
    city: 'Wertheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97892',
    city: 'Kreuzwertheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97896',
    city: 'Ebenheiderhof',
    countrycode: 'DE',
  },
  {
    postalcode: '97896',
    city: 'Freudenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '97896',
    city: 'Kirschfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '97900',
    city: 'Külsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97901',
    city: 'Altenbuch',
    countrycode: 'DE',
  },
  {
    postalcode: '97903',
    city: 'Collenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '97904',
    city: 'Dorfprozelten',
    countrycode: 'DE',
  },
  {
    postalcode: '97906',
    city: 'Faulbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97907',
    city: 'Hasloch',
    countrycode: 'DE',
  },
  {
    postalcode: '97907',
    city: 'Karthause Grünau',
    countrycode: 'DE',
  },
  {
    postalcode: '97909',
    city: 'Stadtprozelten',
    countrycode: 'DE',
  },
  {
    postalcode: '97922',
    city: 'Lauda-Königshofen',
    countrycode: 'DE',
  },
  {
    postalcode: '97941',
    city: 'Tauberbischofsheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97944',
    city: 'Boxberg',
    countrycode: 'DE',
  },
  {
    postalcode: '97947',
    city: 'Grünsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97950',
    city: 'Großrinderfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '97953',
    city: 'Königheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97956',
    city: 'Werbach',
    countrycode: 'DE',
  },
  {
    postalcode: '97957',
    city: 'Bowiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '97957',
    city: 'Wittighausen',
    countrycode: 'DE',
  },
  {
    postalcode: '97959',
    city: 'Assamstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '97980',
    city: 'Bad Mergentheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97980',
    city: 'Mönchshof',
    countrycode: 'DE',
  },
  {
    postalcode: '97990',
    city: 'Standorf',
    countrycode: 'DE',
  },
  {
    postalcode: '97990',
    city: 'Weikersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '97993',
    city: 'Creglingen',
    countrycode: 'DE',
  },
  {
    postalcode: '97996',
    city: 'Niederstetten',
    countrycode: 'DE',
  },
  {
    postalcode: '97999',
    city: 'Igersheim',
    countrycode: 'DE',
  },
  {
    postalcode: '98527',
    city: 'Suhl',
    countrycode: 'DE',
  },
  {
    postalcode: '98528',
    city: 'Suhl',
    countrycode: 'DE',
  },
  {
    postalcode: '98529',
    city: 'Albrechts',
    countrycode: 'DE',
  },
  {
    postalcode: '98529',
    city: 'Suhl',
    countrycode: 'DE',
  },
  {
    postalcode: '98530',
    city: 'Dietzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98530',
    city: 'Dillstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '98530',
    city: 'Grub',
    countrycode: 'DE',
  },
  {
    postalcode: '98530',
    city: 'Marisfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '98530',
    city: 'Oberstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '98530',
    city: 'Rohr',
    countrycode: 'DE',
  },
  {
    postalcode: '98530',
    city: 'Schmeheim',
    countrycode: 'DE',
  },
  {
    postalcode: '98530',
    city: 'Wichtshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98544',
    city: 'Zella-Mehlis',
    countrycode: 'DE',
  },
  {
    postalcode: '98547',
    city: 'Christes',
    countrycode: 'DE',
  },
  {
    postalcode: '98547',
    city: 'Kühndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98547',
    city: 'Schwarza',
    countrycode: 'DE',
  },
  {
    postalcode: '98547',
    city: 'Viernau',
    countrycode: 'DE',
  },
  {
    postalcode: '98553',
    city: 'Ahlstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '98553',
    city: 'Altendambach',
    countrycode: 'DE',
  },
  {
    postalcode: '98553',
    city: 'Bischofrod',
    countrycode: 'DE',
  },
  {
    postalcode: '98553',
    city: 'Breitenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98553',
    city: 'Eichenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '98553',
    city: 'Erlau',
    countrycode: 'DE',
  },
  {
    postalcode: '98553',
    city: 'Hinternah',
    countrycode: 'DE',
  },
  {
    postalcode: '98553',
    city: 'Hirschbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98553',
    city: 'Schleusingen',
    countrycode: 'DE',
  },
  {
    postalcode: '98553',
    city: 'Schleusingerneundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98553',
    city: 'St. Kilian',
    countrycode: 'DE',
  },
  {
    postalcode: '98554',
    city: 'Benshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98559',
    city: 'Gehlberg',
    countrycode: 'DE',
  },
  {
    postalcode: '98559',
    city: 'Oberhof',
    countrycode: 'DE',
  },
  {
    postalcode: '98574',
    city: 'Asbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98574',
    city: 'Grumbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98574',
    city: 'Mittelstille',
    countrycode: 'DE',
  },
  {
    postalcode: '98574',
    city: 'Schmalkalden',
    countrycode: 'DE',
  },
  {
    postalcode: '98587',
    city: 'Altersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98587',
    city: 'Bermbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98587',
    city: 'Oberschönau',
    countrycode: 'DE',
  },
  {
    postalcode: '98587',
    city: 'Rotterode',
    countrycode: 'DE',
  },
  {
    postalcode: '98587',
    city: 'Springstille',
    countrycode: 'DE',
  },
  {
    postalcode: '98587',
    city: 'Steinbach-Hallenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '98587',
    city: 'Unterschönau',
    countrycode: 'DE',
  },
  {
    postalcode: '98590',
    city: 'Georgenzell',
    countrycode: 'DE',
  },
  {
    postalcode: '98590',
    city: 'Mittelschmalkalden',
    countrycode: 'DE',
  },
  {
    postalcode: '98590',
    city: 'Möckers',
    countrycode: 'DE',
  },
  {
    postalcode: '98590',
    city: 'Rosa',
    countrycode: 'DE',
  },
  {
    postalcode: '98590',
    city: 'Roßdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98590',
    city: 'Schwallungen',
    countrycode: 'DE',
  },
  {
    postalcode: '98590',
    city: 'Wernshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98593',
    city: 'Floh-Seligenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '98593',
    city: 'Kleinschmalkalden',
    countrycode: 'DE',
  },
  {
    postalcode: '98596',
    city: 'Trusetal',
    countrycode: 'DE',
  },
  {
    postalcode: '98597',
    city: 'Breitungen',
    countrycode: 'DE',
  },
  {
    postalcode: '98597',
    city: 'Fambach',
    countrycode: 'DE',
  },
  {
    postalcode: '98597',
    city: 'Heßles',
    countrycode: 'DE',
  },
  {
    postalcode: '98599',
    city: 'Brotterode',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Bauerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Belrieth',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Bettenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Einhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Ellingshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Gerthausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Helmershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Henneberg',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Hermannsfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Herpf',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Leutersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Meiningen',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Neubrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Obermaßfeld-Grimmenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Ritschenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Stedtlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Stepfershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Sülzfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Untermaßfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Utendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Vachdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Wohlmuthausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98617',
    city: 'Wölfershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Behrungen',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Berkach',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Bibra',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Exdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Haina',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Jüchsen',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Mendhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Milz',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Nordheim',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Queienfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Rentwertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Römhild',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Schwickershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Westenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '98631',
    city: 'Wolfmannshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Aschenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Birx',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Erbenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Frankenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Friedelshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Hümpfershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Kaltensundheim',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Kaltenwestheim',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Mehmels',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Melpers',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Oberkatz',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Oberweid',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Oepfershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Unterkatz',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Unterweid',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Wahns',
    countrycode: 'DE',
  },
  {
    postalcode: '98634',
    city: 'Wasungen',
    countrycode: 'DE',
  },
  {
    postalcode: '98639',
    city: 'Metzels',
    countrycode: 'DE',
  },
  {
    postalcode: '98639',
    city: 'Rippershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98639',
    city: 'Wallbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98639',
    city: 'Walldorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Adelhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Dingsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Eishausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Gleichamberg',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Heßberg',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Hildburghausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Linden',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Massenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Reurieth',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Seidingstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Sophienthal',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Steinfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Stressenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98646',
    city: 'Streufdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98660',
    city: 'Beinerstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '98660',
    city: 'Ehrenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '98660',
    city: 'Grimmelshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98660',
    city: 'Henfstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '98660',
    city: 'Kloster Veßra',
    countrycode: 'DE',
  },
  {
    postalcode: '98660',
    city: 'Lengfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '98660',
    city: 'St. Bernhard',
    countrycode: 'DE',
  },
  {
    postalcode: '98660',
    city: 'Themar',
    countrycode: 'DE',
  },
  {
    postalcode: '98663',
    city: 'Bad Colberg-Heldburg',
    countrycode: 'DE',
  },
  {
    postalcode: '98663',
    city: 'Gompertshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98663',
    city: 'Hellingen',
    countrycode: 'DE',
  },
  {
    postalcode: '98663',
    city: 'Rieth',
    countrycode: 'DE',
  },
  {
    postalcode: '98663',
    city: 'Schlechtsart',
    countrycode: 'DE',
  },
  {
    postalcode: '98663',
    city: 'Schweickershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98663',
    city: 'Ummerstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '98663',
    city: 'Westhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '98666',
    city: 'Biberau',
    countrycode: 'DE',
  },
  {
    postalcode: '98666',
    city: 'Masserberg',
    countrycode: 'DE',
  },
  {
    postalcode: '98666',
    city: 'Waffenrod',
    countrycode: 'DE',
  },
  {
    postalcode: '98667',
    city: 'Gießübel',
    countrycode: 'DE',
  },
  {
    postalcode: '98667',
    city: 'Langenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98667',
    city: 'Schönbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '98667',
    city: 'Steinbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98667',
    city: 'Waldau',
    countrycode: 'DE',
  },
  {
    postalcode: '98667',
    city: 'Wiedersbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98669',
    city: 'Veilsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98673',
    city: 'Bockstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '98673',
    city: 'Brattendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98673',
    city: 'Brünn',
    countrycode: 'DE',
  },
  {
    postalcode: '98673',
    city: 'Crock',
    countrycode: 'DE',
  },
  {
    postalcode: '98673',
    city: 'Eisfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '98673',
    city: 'Harras',
    countrycode: 'DE',
  },
  {
    postalcode: '98673',
    city: 'Merbelsrod',
    countrycode: 'DE',
  },
  {
    postalcode: '98673',
    city: 'Poppenwind',
    countrycode: 'DE',
  },
  {
    postalcode: '98673',
    city: 'Schwarzbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98678',
    city: 'Hirschendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98678',
    city: 'Saargrund',
    countrycode: 'DE',
  },
  {
    postalcode: '98678',
    city: 'Sachsenbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '98678',
    city: 'Schirnrod',
    countrycode: 'DE',
  },
  {
    postalcode: '98678',
    city: 'Stelzen',
    countrycode: 'DE',
  },
  {
    postalcode: '98693',
    city: 'Bücheloh',
    countrycode: 'DE',
  },
  {
    postalcode: '98693',
    city: 'Heyda',
    countrycode: 'DE',
  },
  {
    postalcode: '98693',
    city: 'Ilmenau',
    countrycode: 'DE',
  },
  {
    postalcode: '98693',
    city: 'Manebach',
    countrycode: 'DE',
  },
  {
    postalcode: '98693',
    city: 'Martinroda',
    countrycode: 'DE',
  },
  {
    postalcode: '98693',
    city: 'Oberpörlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '98693',
    city: 'Roda',
    countrycode: 'DE',
  },
  {
    postalcode: '98693',
    city: 'Unterpörlitz',
    countrycode: 'DE',
  },
  {
    postalcode: '98701',
    city: 'Allersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98701',
    city: 'Altenfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '98701',
    city: 'Böhlen',
    countrycode: 'DE',
  },
  {
    postalcode: '98701',
    city: 'Friedersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98701',
    city: 'Gillersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98701',
    city: 'Großbreitenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98701',
    city: 'Herschdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98701',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '98701',
    city: 'Wildenspring',
    countrycode: 'DE',
  },
  {
    postalcode: '98701',
    city: 'Willmersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98704',
    city: 'Gräfinau-Angstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '98704',
    city: 'Langewiesen',
    countrycode: 'DE',
  },
  {
    postalcode: '98704',
    city: 'Oehrenstock',
    countrycode: 'DE',
  },
  {
    postalcode: '98704',
    city: 'Wümbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98708',
    city: 'Gehren',
    countrycode: 'DE',
  },
  {
    postalcode: '98708',
    city: 'Möhrenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98708',
    city: 'Pennewitz',
    countrycode: 'DE',
  },
  {
    postalcode: '98711',
    city: 'Frauenwald',
    countrycode: 'DE',
  },
  {
    postalcode: '98711',
    city: 'Schmiedefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '98711',
    city: 'Vesser',
    countrycode: 'DE',
  },
  {
    postalcode: '98714',
    city: 'Stützerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98716',
    city: 'Elgersburg',
    countrycode: 'DE',
  },
  {
    postalcode: '98716',
    city: 'Geraberg',
    countrycode: 'DE',
  },
  {
    postalcode: '98716',
    city: 'Geschwenda',
    countrycode: 'DE',
  },
  {
    postalcode: '98724',
    city: 'Ernstthal',
    countrycode: 'DE',
  },
  {
    postalcode: '98724',
    city: 'Lauscha',
    countrycode: 'DE',
  },
  {
    postalcode: '98724',
    city: 'Neuhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '98739',
    city: 'Lichte',
    countrycode: 'DE',
  },
  {
    postalcode: '98739',
    city: 'Piesau',
    countrycode: 'DE',
  },
  {
    postalcode: '98739',
    city: 'Reichmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98739',
    city: 'Schmiedefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '98743',
    city: 'Buchbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98743',
    city: 'Gebersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98743',
    city: 'Großneundorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98743',
    city: 'Gräfenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '98743',
    city: 'Lichtenhain bei Gräfenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '98743',
    city: 'Lippelsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98743',
    city: 'Spechtsbrunn',
    countrycode: 'DE',
  },
  {
    postalcode: '98744',
    city: 'Cursdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '98744',
    city: 'Deesbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98744',
    city: 'Lichtenhain Bergbahn',
    countrycode: 'DE',
  },
  {
    postalcode: '98744',
    city: 'Meura',
    countrycode: 'DE',
  },
  {
    postalcode: '98744',
    city: 'Oberweißbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98744',
    city: 'Unterweißbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98746',
    city: 'Goldisthal',
    countrycode: 'DE',
  },
  {
    postalcode: '98746',
    city: 'Katzhütte',
    countrycode: 'DE',
  },
  {
    postalcode: '98746',
    city: 'Mellenbach-Glasbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98746',
    city: 'Meuselbach-Schwarzmühle',
    countrycode: 'DE',
  },
  {
    postalcode: '98749',
    city: 'Friedrichshöhe',
    countrycode: 'DE',
  },
  {
    postalcode: '98749',
    city: 'Scheibe-Alsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '98749',
    city: 'Siegmundsburg',
    countrycode: 'DE',
  },
  {
    postalcode: '98749',
    city: 'Steinheid',
    countrycode: 'DE',
  },
  {
    postalcode: '99084',
    city: 'Erfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99085',
    city: 'Erfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99086',
    city: 'Erfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99087',
    city: 'Erfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99089',
    city: 'Erfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99091',
    city: 'Erfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99092',
    city: 'Erfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99094',
    city: 'Erfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99096',
    city: 'Erfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99097',
    city: 'Erfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99098',
    city: 'Erfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99099',
    city: 'Erfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99100',
    city: 'Alach',
    countrycode: 'DE',
  },
  {
    postalcode: '99100',
    city: 'Bahnhof Zimmernsupra-Töttelstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99100',
    city: 'Bienstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99100',
    city: 'Dachwig',
    countrycode: 'DE',
  },
  {
    postalcode: '99100',
    city: 'Döllstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99100',
    city: 'Gierstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99100',
    city: 'Großfahner',
    countrycode: 'DE',
  },
  {
    postalcode: '99100',
    city: 'Salomonsborn',
    countrycode: 'DE',
  },
  {
    postalcode: '99100',
    city: 'Töttelstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99100',
    city: 'Zimmernsupra',
    countrycode: 'DE',
  },
  {
    postalcode: '99102',
    city: 'Egstedt am Steiger',
    countrycode: 'DE',
  },
  {
    postalcode: '99102',
    city: 'Haarberg',
    countrycode: 'DE',
  },
  {
    postalcode: '99102',
    city: 'Klettbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99102',
    city: 'Niedernissa',
    countrycode: 'DE',
  },
  {
    postalcode: '99102',
    city: 'Rockhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99102',
    city: 'Rohdam am Haarberg',
    countrycode: 'DE',
  },
  {
    postalcode: '99102',
    city: 'Suhlequelle',
    countrycode: 'DE',
  },
  {
    postalcode: '99102',
    city: 'Waltersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99102',
    city: 'Windischholzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99189',
    city: 'Andisleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99189',
    city: 'Elxleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99189',
    city: 'Gebesee',
    countrycode: 'DE',
  },
  {
    postalcode: '99189',
    city: 'Haßleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99189',
    city: 'Kühnhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99189',
    city: 'Ringleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99189',
    city: 'Tiefthal',
    countrycode: 'DE',
  },
  {
    postalcode: '99189',
    city: 'Walschleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99189',
    city: 'Witterda',
    countrycode: 'DE',
  },
  {
    postalcode: '99192',
    city: 'Apfelstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99192',
    city: 'Ermstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99192',
    city: 'Frienstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99192',
    city: 'Gamstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99192',
    city: 'Gottstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99192',
    city: 'Ingersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99192',
    city: 'Luisenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '99192',
    city: 'Marienthal',
    countrycode: 'DE',
  },
  {
    postalcode: '99192',
    city: 'Molsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99192',
    city: 'Neudietendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99192',
    city: 'Nottleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99192',
    city: 'Osterberg',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Alperstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Eckstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Großrudestedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Kleinrudestedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Kranichborn',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Markvippach',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Mittelhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Nöda',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Riethnordhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Schloßvippach',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Schwansee',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Schwerborn',
    countrycode: 'DE',
  },
  {
    postalcode: '99195',
    city: 'Stotternheim',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Azmannsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Büßleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Eichelborn',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Großmölsen',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Hayn',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Hochstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Kerspleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Kleinmölsen',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Linderbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Mönchenholzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Obernissa',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Ollendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Sohnstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Töttleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Udestedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Urbich',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Vieselbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99198',
    city: 'Wallichen',
    countrycode: 'DE',
  },
  {
    postalcode: '99310',
    city: 'Alkersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99310',
    city: 'Arnstadt',
    countrycode: 'DE',
  },
  {
    postalcode: '99310',
    city: 'Bösleben-Wüllersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99310',
    city: 'Dornheim',
    countrycode: 'DE',
  },
  {
    postalcode: '99310',
    city: 'Hohes Kreuz',
    countrycode: 'DE',
  },
  {
    postalcode: '99310',
    city: 'Osthausen-Wülfershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99310',
    city: 'Wachsenburggemeinde',
    countrycode: 'DE',
  },
  {
    postalcode: '99310',
    city: 'Wipfratal',
    countrycode: 'DE',
  },
  {
    postalcode: '99310',
    city: 'Witzleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Behringen',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Behringer Schenke',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Cottendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Dienstedt-Hettstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Dörnfeld an der Ilm',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Ehrenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Geilsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Griesheim',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Großliebringen',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Gösselborn',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Hammersfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Nahwinden',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Niederwillingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Oberwillingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Singen',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Stadtilm',
    countrycode: 'DE',
  },
  {
    postalcode: '99326',
    city: 'Traßdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99330',
    city: 'Crawinkel',
    countrycode: 'DE',
  },
  {
    postalcode: '99330',
    city: 'Frankenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '99330',
    city: 'Gräfenroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99330',
    city: 'Liebenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '99334',
    city: 'Elleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99334',
    city: 'Elxleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99334',
    city: 'Ichtershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99334',
    city: 'Kirchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '99338',
    city: 'Angelroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99338',
    city: 'Gossel',
    countrycode: 'DE',
  },
  {
    postalcode: '99338',
    city: 'Neusiß',
    countrycode: 'DE',
  },
  {
    postalcode: '99338',
    city: 'Plaue',
    countrycode: 'DE',
  },
  {
    postalcode: '99423',
    city: 'Weimar',
    countrycode: 'DE',
  },
  {
    postalcode: '99425',
    city: 'Weimar',
    countrycode: 'DE',
  },
  {
    postalcode: '99427',
    city: 'Weimar',
    countrycode: 'DE',
  },
  {
    postalcode: '99428',
    city: 'Bechstedtstraß',
    countrycode: 'DE',
  },
  {
    postalcode: '99428',
    city: 'Daasdorf am Berge',
    countrycode: 'DE',
  },
  {
    postalcode: '99428',
    city: 'Gaberndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99428',
    city: 'Gelmeroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99428',
    city: 'Hopfgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '99428',
    city: 'Isseroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99428',
    city: 'Niedergrunstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99428',
    city: 'Niederzimmern',
    countrycode: 'DE',
  },
  {
    postalcode: '99428',
    city: 'Nohra',
    countrycode: 'DE',
  },
  {
    postalcode: '99428',
    city: 'Ottstedt am Berge',
    countrycode: 'DE',
  },
  {
    postalcode: '99428',
    city: 'Tröbsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99428',
    city: 'Utzberg',
    countrycode: 'DE',
  },
  {
    postalcode: '99438',
    city: 'Bad Berka',
    countrycode: 'DE',
  },
  {
    postalcode: '99438',
    city: 'Buchfart',
    countrycode: 'DE',
  },
  {
    postalcode: '99438',
    city: 'Gutendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99438',
    city: 'Hetschburg',
    countrycode: 'DE',
  },
  {
    postalcode: '99438',
    city: 'Oettern',
    countrycode: 'DE',
  },
  {
    postalcode: '99438',
    city: 'Possendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99438',
    city: 'Troistedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99438',
    city: 'Vollersroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Ballstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Berlstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Buttelstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Daasdorf bei Buttelstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Ettersburg',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Großobringen',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Heichelheim',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Hottelstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Kleinobringen',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Krautheim',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Leutenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Nermsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Neumark',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Ottmannshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Ramsla',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Rohrbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Sachsenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Schwerstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Stedten am Ettersberg',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Vippachedelhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Weiden',
    countrycode: 'DE',
  },
  {
    postalcode: '99439',
    city: 'Wohlsborn',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Döbritschen',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Frankendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Großschwabhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Hammerstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Hohlstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Kiliansroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Kleinschwabhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Kromsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Kötschau',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Lehnstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Magdala',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Mechelroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Mellingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Süßenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '99441',
    city: 'Umpferstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99444',
    city: 'Blankenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '99448',
    city: 'Hohenfelden',
    countrycode: 'DE',
  },
  {
    postalcode: '99448',
    city: 'Kranichfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '99448',
    city: 'Nauendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99448',
    city: 'Rittersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Apolda',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Eckolstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Flurstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Gebstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Großromstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Hermstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Kapellendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Kleinromstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Kösnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Liebstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Mattstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Münchengosserstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Niederreißen',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Niederroßla',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Nirmsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Oberreißen',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Obertrebra',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Oßmannstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Pfiffelbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Pfuhlsborn',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Stobra',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Wickerstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Wiegendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Willerstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99510',
    city: 'Wormstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99518',
    city: 'Auerstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99518',
    city: 'Bad Sulza',
    countrycode: 'DE',
  },
  {
    postalcode: '99518',
    city: 'Eberstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99518',
    city: 'Großheringen',
    countrycode: 'DE',
  },
  {
    postalcode: '99518',
    city: 'Ködderitzsch',
    countrycode: 'DE',
  },
  {
    postalcode: '99518',
    city: 'Lachstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99518',
    city: 'Niedertrebra',
    countrycode: 'DE',
  },
  {
    postalcode: '99518',
    city: 'Rannstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99518',
    city: 'Reisdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99518',
    city: 'Schmiedehausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Frohndorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Großbrembach',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Kleinbrembach',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Leubingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Orlishausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Rohrborn',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Schallenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Sprötau',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Stödten',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Sömmerda',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Tunzenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Vogelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Weißenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Wenigensömmern',
    countrycode: 'DE',
  },
  {
    postalcode: '99610',
    city: 'Wundersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99625',
    city: 'Battgendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99625',
    city: 'Beichlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99625',
    city: 'Dermsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99625',
    city: 'Großmonra',
    countrycode: 'DE',
  },
  {
    postalcode: '99625',
    city: 'Großneuhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99625',
    city: 'Kleinneuhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99625',
    city: 'Kölleda',
    countrycode: 'DE',
  },
  {
    postalcode: '99625',
    city: 'Schillingstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99628',
    city: 'Buttstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99628',
    city: 'Ellersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99628',
    city: 'Eßleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99628',
    city: 'Guthmannshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99628',
    city: 'Hardisleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99628',
    city: 'Mannstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99628',
    city: 'Olbersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99628',
    city: 'Rudersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99628',
    city: 'Teutleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99631',
    city: 'Günstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99631',
    city: 'Herrnschwende',
    countrycode: 'DE',
  },
  {
    postalcode: '99631',
    city: 'Nausiß',
    countrycode: 'DE',
  },
  {
    postalcode: '99631',
    city: 'Weißensee',
    countrycode: 'DE',
  },
  {
    postalcode: '99634',
    city: 'Gangloffsömmern',
    countrycode: 'DE',
  },
  {
    postalcode: '99634',
    city: 'Henschleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99634',
    city: 'Schwerstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99634',
    city: 'Straußfurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99634',
    city: 'Werningshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99636',
    city: 'Ostramondra',
    countrycode: 'DE',
  },
  {
    postalcode: '99636',
    city: 'Rastenberg',
    countrycode: 'DE',
  },
  {
    postalcode: '99638',
    city: 'Büchel',
    countrycode: 'DE',
  },
  {
    postalcode: '99638',
    city: 'Frömmstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99638',
    city: 'Griefstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99638',
    city: 'Kindelbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '99638',
    city: 'Riethgen',
    countrycode: 'DE',
  },
  {
    postalcode: '99706',
    city: 'Badra',
    countrycode: 'DE',
  },
  {
    postalcode: '99706',
    city: 'Bendeleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99706',
    city: 'Hachelbich',
    countrycode: 'DE',
  },
  {
    postalcode: '99706',
    city: 'Sondershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Abtsbessingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Allmenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Bellstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Billeben',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Dietenborn',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Ebeleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Freienbessingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Friedrichsrode',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Großberndten',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Großbrüchter',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Gundersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Himmelsberg',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Hohenebra',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Holzsußra',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Holzthaleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Immenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Keula',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Kleinberndten',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Kleinbrüchter',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Niederspier',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Peukendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Rockensußra',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Rockstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Schernberg',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Straußberg',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Thalebra',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Thüringenhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Toba',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Wenigenehrich',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Wiedermuth',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Wolferschwenda',
    countrycode: 'DE',
  },
  {
    postalcode: '99713',
    city: 'Zaunröden',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Bliederstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Clingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Feldengel',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Greußen',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Großenehrich',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Holzengel',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Kirchengel',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Niederbösa',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Oberbösa',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Otterstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Rohnstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Topfstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Trebra',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Wasserthaleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Westerengel',
    countrycode: 'DE',
  },
  {
    postalcode: '99718',
    city: 'Westgreußen',
    countrycode: 'DE',
  },
  {
    postalcode: '99734',
    city: 'Nordhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99734',
    city: 'Rüdigsdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Bielen',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Etzelsrode',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Friedrichsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Fronderode',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Großwechsungen',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Günzerode',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Haferungen',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Hainrode',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Herreden',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Hesserode',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Himmelgarten',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Hochstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Hörningen',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Immenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Kleinfurra',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Kleinwechsungen',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Leimbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Mauderode',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Mörbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Nohra',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Petersdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Pützlingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Steinbrücken',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Sundhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Wernrode',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Werther',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Wolkramshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99735',
    city: 'Wollersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99752',
    city: 'Bleicherode',
    countrycode: 'DE',
  },
  {
    postalcode: '99752',
    city: 'Kehmstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99752',
    city: 'Kleinbodungen',
    countrycode: 'DE',
  },
  {
    postalcode: '99752',
    city: 'Kraja',
    countrycode: 'DE',
  },
  {
    postalcode: '99752',
    city: 'Lipprechterode',
    countrycode: 'DE',
  },
  {
    postalcode: '99752',
    city: 'Wipperdorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99755',
    city: 'Ellrich',
    countrycode: 'DE',
  },
  {
    postalcode: '99755',
    city: 'Gudersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99755',
    city: 'Hohenstein',
    countrycode: 'DE',
  },
  {
    postalcode: '99755',
    city: 'Sülzhayn',
    countrycode: 'DE',
  },
  {
    postalcode: '99759',
    city: 'Elende',
    countrycode: 'DE',
  },
  {
    postalcode: '99759',
    city: 'Großlohra',
    countrycode: 'DE',
  },
  {
    postalcode: '99759',
    city: 'Münchenlohra',
    countrycode: 'DE',
  },
  {
    postalcode: '99759',
    city: 'Niedergebra',
    countrycode: 'DE',
  },
  {
    postalcode: '99759',
    city: 'Obergebra',
    countrycode: 'DE',
  },
  {
    postalcode: '99759',
    city: 'Rehungen',
    countrycode: 'DE',
  },
  {
    postalcode: '99759',
    city: 'Sollstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99762',
    city: 'Buchholz',
    countrycode: 'DE',
  },
  {
    postalcode: '99762',
    city: 'Harzungen',
    countrycode: 'DE',
  },
  {
    postalcode: '99762',
    city: 'Herrmannsacker',
    countrycode: 'DE',
  },
  {
    postalcode: '99762',
    city: 'Neustadt',
    countrycode: 'DE',
  },
  {
    postalcode: '99762',
    city: 'Niedersachswerfen',
    countrycode: 'DE',
  },
  {
    postalcode: '99762',
    city: 'Rodishain',
    countrycode: 'DE',
  },
  {
    postalcode: '99762',
    city: 'Steigerthal',
    countrycode: 'DE',
  },
  {
    postalcode: '99762',
    city: 'Stempeda',
    countrycode: 'DE',
  },
  {
    postalcode: '99762',
    city: 'Woffleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99765',
    city: 'Auleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99765',
    city: 'Görsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99765',
    city: 'Hamma',
    countrycode: 'DE',
  },
  {
    postalcode: '99765',
    city: 'Heringen',
    countrycode: 'DE',
  },
  {
    postalcode: '99765',
    city: 'Urbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99765',
    city: 'Uthleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99765',
    city: 'Windehausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99768',
    city: 'Appenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '99768',
    city: 'Ilfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '99768',
    city: 'Rothesütte',
    countrycode: 'DE',
  },
  {
    postalcode: '99817',
    city: 'Eisenach',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Beuernfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Bolleroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Eckardtshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Ettenhausen an der Nesse',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Ettenhausen an der Suhl',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Förtha',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Großenlupnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Krauthausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Lauchröden',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Marksuhl',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Oberellen',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Unterellen',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Wenigenlupnitz',
    countrycode: 'DE',
  },
  {
    postalcode: '99819',
    city: 'Wolfsburg-Unkeroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99826',
    city: 'Berka vor dem Hainich',
    countrycode: 'DE',
  },
  {
    postalcode: '99826',
    city: 'Bischofroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99826',
    city: 'Ebenshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99826',
    city: 'Frankenroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99826',
    city: 'Hallungen',
    countrycode: 'DE',
  },
  {
    postalcode: '99826',
    city: 'Lauterbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99826',
    city: 'Mihla',
    countrycode: 'DE',
  },
  {
    postalcode: '99826',
    city: 'Nazza',
    countrycode: 'DE',
  },
  {
    postalcode: '99830',
    city: 'Falken',
    countrycode: 'DE',
  },
  {
    postalcode: '99830',
    city: 'Großburschla',
    countrycode: 'DE',
  },
  {
    postalcode: '99830',
    city: 'Treffurt',
    countrycode: 'DE',
  },
  {
    postalcode: '99831',
    city: 'Creuzburg',
    countrycode: 'DE',
  },
  {
    postalcode: '99831',
    city: 'Hattengehau',
    countrycode: 'DE',
  },
  {
    postalcode: '99831',
    city: 'Ifta',
    countrycode: 'DE',
  },
  {
    postalcode: '99831',
    city: 'Schnellmannshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99831',
    city: 'Schrapfendorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99834',
    city: 'Gerstungen',
    countrycode: 'DE',
  },
  {
    postalcode: '99834',
    city: 'Neustädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99834',
    city: 'Sallmannshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99837',
    city: 'Berka',
    countrycode: 'DE',
  },
  {
    postalcode: '99837',
    city: 'Dankmarshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99837',
    city: 'Dippach',
    countrycode: 'DE',
  },
  {
    postalcode: '99837',
    city: 'Großensee',
    countrycode: 'DE',
  },
  {
    postalcode: '99842',
    city: 'Ruhla',
    countrycode: 'DE',
  },
  {
    postalcode: '99843',
    city: 'Kittelsthal',
    countrycode: 'DE',
  },
  {
    postalcode: '99843',
    city: 'Thal',
    countrycode: 'DE',
  },
  {
    postalcode: '99846',
    city: 'Seebach',
    countrycode: 'DE',
  },
  {
    postalcode: '99848',
    city: 'Hastrungsfeld-Burla',
    countrycode: 'DE',
  },
  {
    postalcode: '99848',
    city: 'Kälberfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '99848',
    city: 'Sättelstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99848',
    city: 'Wutha-Farnroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99867',
    city: 'Gotha',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Ballstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Brüheim',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Bufleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Ebenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Emleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Eschenbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Friedrichswerth',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Friemar',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Goldbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Grabsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Günthersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Haina',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Hochheim',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Molschleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Mühlberg',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Pferdingsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Remstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Schwabhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Seebergen',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Sonneborn',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Tröchtelborn',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Tüttleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Wandersleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Wangenheim',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Warza',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Wechmar',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Weingarten',
    countrycode: 'DE',
  },
  {
    postalcode: '99869',
    city: 'Westhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99880',
    city: 'Aspach',
    countrycode: 'DE',
  },
  {
    postalcode: '99880',
    city: 'Fröttstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99880',
    city: 'Gospiteroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99880',
    city: 'Hörselgau',
    countrycode: 'DE',
  },
  {
    postalcode: '99880',
    city: 'Langenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '99880',
    city: 'Laucha',
    countrycode: 'DE',
  },
  {
    postalcode: '99880',
    city: 'Leina',
    countrycode: 'DE',
  },
  {
    postalcode: '99880',
    city: 'Mechterstädt',
    countrycode: 'DE',
  },
  {
    postalcode: '99880',
    city: 'Metebach',
    countrycode: 'DE',
  },
  {
    postalcode: '99880',
    city: 'Teutleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99880',
    city: 'Trügleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99880',
    city: 'Waltershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99885',
    city: 'Luisenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '99885',
    city: 'Ohrdruf',
    countrycode: 'DE',
  },
  {
    postalcode: '99885',
    city: 'Wölfis',
    countrycode: 'DE',
  },
  {
    postalcode: '99887',
    city: 'Catterfeld',
    countrycode: 'DE',
  },
  {
    postalcode: '99887',
    city: 'Georgenthal',
    countrycode: 'DE',
  },
  {
    postalcode: '99887',
    city: 'Gräfenhain',
    countrycode: 'DE',
  },
  {
    postalcode: '99887',
    city: 'Herrenhof',
    countrycode: 'DE',
  },
  {
    postalcode: '99887',
    city: 'Hohenkirchen',
    countrycode: 'DE',
  },
  {
    postalcode: '99887',
    city: 'Petriroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99891',
    city: 'Fischbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99891',
    city: 'Schmerbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99891',
    city: 'Schwarzhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99891',
    city: 'Tabarz',
    countrycode: 'DE',
  },
  {
    postalcode: '99891',
    city: 'Winterstein',
    countrycode: 'DE',
  },
  {
    postalcode: '99894',
    city: 'Ernstroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99894',
    city: 'Friedrichroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99894',
    city: 'Schönau von der Walde',
    countrycode: 'DE',
  },
  {
    postalcode: '99897',
    city: 'Tambach-Dietharz',
    countrycode: 'DE',
  },
  {
    postalcode: '99898',
    city: 'Engelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99898',
    city: 'Finsterbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Bad Langensalza',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Behringen',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Bothenheilingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Großwelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Harthhaus',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Issersheilingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Kirchheilingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Kleinwelsbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Merxleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Mülverstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Neunheilingen',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Schönstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Sundhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Thamsbrück',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Tottleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Weberstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99947',
    city: 'Zimmern',
    countrycode: 'DE',
  },
  {
    postalcode: '99955',
    city: 'Bad Tennstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99955',
    city: 'Ballhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99955',
    city: 'Blankenburg',
    countrycode: 'DE',
  },
  {
    postalcode: '99955',
    city: 'Bruchstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99955',
    city: 'Haussömmern',
    countrycode: 'DE',
  },
  {
    postalcode: '99955',
    city: 'Herbsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99955',
    city: 'Hornsömmern',
    countrycode: 'DE',
  },
  {
    postalcode: '99955',
    city: 'Klettstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99955',
    city: 'Kutzleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99955',
    city: 'Mittelsömmern',
    countrycode: 'DE',
  },
  {
    postalcode: '99955',
    city: 'Urleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99958',
    city: 'Aschara',
    countrycode: 'DE',
  },
  {
    postalcode: '99958',
    city: 'Eckardtsleben',
    countrycode: 'DE',
  },
  {
    postalcode: '99958',
    city: 'Großvargula',
    countrycode: 'DE',
  },
  {
    postalcode: '99958',
    city: 'Illeben',
    countrycode: 'DE',
  },
  {
    postalcode: '99958',
    city: 'Nägelstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99958',
    city: 'Tonna',
    countrycode: 'DE',
  },
  {
    postalcode: '99974',
    city: 'Ammern',
    countrycode: 'DE',
  },
  {
    postalcode: '99974',
    city: 'Dachrieden',
    countrycode: 'DE',
  },
  {
    postalcode: '99974',
    city: 'Kaisershagen',
    countrycode: 'DE',
  },
  {
    postalcode: '99974',
    city: 'Mühlhausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99974',
    city: 'Reiser',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Beberstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Bickenriede',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Dörna',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Eigenrode',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Faulungen',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Hildebrandshausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Hollenbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Horsmar',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Hüpstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Kleinkeula',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Lengefeld',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Lengenfeld unterm Stein',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Rodeberg',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Sollstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99976',
    city: 'Zella',
    countrycode: 'DE',
  },
  {
    postalcode: '99986',
    city: 'Flarchheim',
    countrycode: 'DE',
  },
  {
    postalcode: '99986',
    city: 'Kammerforst',
    countrycode: 'DE',
  },
  {
    postalcode: '99986',
    city: 'Langula',
    countrycode: 'DE',
  },
  {
    postalcode: '99986',
    city: 'Niederdorla',
    countrycode: 'DE',
  },
  {
    postalcode: '99986',
    city: 'Oberdorla',
    countrycode: 'DE',
  },
  {
    postalcode: '99986',
    city: 'Oppershausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99988',
    city: 'Diedorf',
    countrycode: 'DE',
  },
  {
    postalcode: '99988',
    city: 'Heyerode',
    countrycode: 'DE',
  },
  {
    postalcode: '99988',
    city: 'Schierschwende',
    countrycode: 'DE',
  },
  {
    postalcode: '99988',
    city: 'Wendehausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99991',
    city: 'Altengottern',
    countrycode: 'DE',
  },
  {
    postalcode: '99991',
    city: 'Großengottern',
    countrycode: 'DE',
  },
  {
    postalcode: '99991',
    city: 'Heroldishausen',
    countrycode: 'DE',
  },
  {
    postalcode: '99994',
    city: 'Hohenbergen',
    countrycode: 'DE',
  },
  {
    postalcode: '99994',
    city: 'Marolterode',
    countrycode: 'DE',
  },
  {
    postalcode: '99994',
    city: 'Mehrstedt',
    countrycode: 'DE',
  },
  {
    postalcode: '99994',
    city: 'Schlotheim',
    countrycode: 'DE',
  },
  {
    postalcode: '99996',
    city: 'Großmehlra',
    countrycode: 'DE',
  },
  {
    postalcode: '99996',
    city: 'Menteroda',
    countrycode: 'DE',
  },
  {
    postalcode: '99996',
    city: 'Obermehler',
    countrycode: 'DE',
  },
  {
    postalcode: '99996',
    city: 'Urbach',
    countrycode: 'DE',
  },
  {
    postalcode: '99998',
    city: 'Körner',
    countrycode: 'DE',
  },
  {
    postalcode: '99998',
    city: 'Weinbergen',
    countrycode: 'DE',
  },
]
