import { styled, ThemeProps } from '@epilot/components'

type ProductStyledProps = {
  isActive: boolean
  withDetails: boolean
  theme: ThemeProps
}

const ProductStyled = styled.div<ProductStyledProps>`
  border: 1px solid
    ${({ isActive, theme }: ProductStyledProps) =>
      isActive ? theme?.palette?.primary?.main : '#d2dee4'};
  border-radius: ${({ theme }: ProductStyledProps) =>
    theme?.shape?.borderRadius || 0};
  border-top: 4px solid
    ${({ theme }: ProductStyledProps) => theme?.palette?.primary?.main};
  cursor: pointer;
  display: flex;
  flex-basis: ${({ withDetails }: ProductStyledProps) =>
    withDetails ? 'calc(33% - 24px)' : 'calc(25% - 24px)'};
  flex-direction: column;
  ${({ withDetails }: ProductStyledProps) =>
    !withDetails && 'justify-content: space-between'};
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
  margin-right: 12px;
  min-height: 360px;
  padding: 0;
  position: relative;
  max-width: ${({ withDetails }: ProductStyledProps) =>
    withDetails ? 'calc(33% - 24px)' : 'calc(25% - 24px)'};

  .MuiTypography-body1 {
    font-size: 24px;
    line-height: 32px;
    word-wrap: break-word;
    min-height: 72px;
  }
  .MuiFormControlLabel-root {
    display: inherit;
  }
  @media (max-width: 751px) {
    flex-basis: 100%;
    max-width: 100%;
  }

  .img-container {
    position: relative;
    display: flex;
    align-items: flex-start;
  }

  .img-container > img {
    max-width: 100%;
  }
`

export default ProductStyled
