import { VariableModel } from 'lib/api/types'

import { PayloadResults } from '../types'

export const variableModelsAdd = (results: PayloadResults): VariableModel => {
  const variableModels: VariableModel = {}

  if (results.power_consumption) {
    variableModels.POWER = [
      {
        type: 'KILOWATT_HOUR_COMPONENT',
        value: results.power_consumption.amount,
      },
    ]
  }

  if (results.gas_consumption) {
    variableModels.GAS = [
      {
        type: 'KILOWATT_HOUR_COMPONENT',
        value: results.gas_consumption.amount,
      },
    ]
  }

  return variableModels
}
