import { LayoutProps, RankedTester, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

import OverviewElement from './overview-element'

// OVERVIEW-ELEMENT
const OverviewElementRenderer = (props: LayoutProps) => {
  return <OverviewElement {...props} />
}

export default withJsonFormsLayoutProps(OverviewElementRenderer)

export const overviewElementTester: RankedTester = rankWith(
  9,
  uiTypeIs('OverviewElement'),
)
