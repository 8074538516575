import { Grid } from '@epilot/base-elements'
import React from 'react'

import { LayoutItemProps } from './types'

const DefaultLayoutItem = ({ children }: LayoutItemProps) => {
  return (
    <Grid item xs>
      {children}
    </Grid>
  )
}

export default DefaultLayoutItem
