import { BlueprintStep } from 'types/blueprint-config'

import {
  buildAddressBlock,
  boxWrapper,
  buildTextBlock,
  buildNextButtonBlock,
  stepperWrapper,
} from '../../../building-blocks'
import { buildBuildingDetailsBlock } from '../custom-blocks/building-details'

const buildingAddress = buildAddressBlock()
const buildingDetails = buildBuildingDetailsBlock()

export const buildingDetailsStep: BlueprintStep = {
  id: 'building_details',
  title: 'Building Details',
  nextButtonLabel: 'Next',
  schema: {
    type: 'object',
    properties: {
      ...buildingAddress.schema,
      ...buildingDetails.schema,
    },
    required: [...(buildingAddress.required ? buildingAddress.required : [])],
  },
  uischema: {
    type: 'GridContainerLayout',
    options: {
      spacing: 0,
    },
    elements: [
      ...stepperWrapper(
        ['building_details', 'owner_details', 'overview'],
        [
          ...boxWrapper([
            ...buildTextBlock('Gebäudeangaben', 'h1'),
            ...buildTextBlock('*Benötigte Angaben', 'caption', 'flex-end'),
            ...buildTextBlock('This is a placeholder text.', 'body1'),
            ...buildTextBlock('Adresse des Grundstücks/Gebäudes', 'h5'),
            ...buildingAddress.uischema,
            ...buildTextBlock('Angaben zum Gebäude', 'h5'),
            ...buildingDetails.uischema,
          ]),
          ...buildNextButtonBlock(),
        ],
      ),
    ],
  },
  resultData: {
    housing_units: 0,
    business_units: 0,
  },
  logic: {
    nextStepId: 'owner_details',
  },
}
