import { BagIcon } from '@epilot/components'
import React from 'react'

import { AddonSellingOption } from 'lib/api.types'

import AddonHeaderStyled from './addon-header-styled'
import AddonInfoTextStyled from './addon-infotext-styled'
import AddonPrices from './addon-prices'

export type AddonHeaderProps = {
  prices: AddonSellingOption
  title: string
  withDetails?: boolean
  withIcon?: boolean
}

const AddonHeader = ({
  title,
  prices,
  withDetails = true,
  withIcon = true,
}: AddonHeaderProps): JSX.Element => {
  const durationNumber = prices.duration?.slice(0, -1)
  const durationInterval = prices.duration?.slice(-1)
  const durationText = `Zahlungslaufzeit: ${durationNumber} ${
    durationInterval === 'M' ? 'Monate' : 'Jahre'
  }.`

  return (
    <>
      <AddonHeaderStyled withDetails={withDetails}>
        {withDetails && withIcon && <BagIcon size={60} />}
        <p>{title}</p>
        <AddonPrices
          prices={prices}
          variant={withDetails ? 'default' : 'bigger'}
        />
      </AddonHeaderStyled>
      {prices.duration && prices.recurring_price && (
        <AddonInfoTextStyled withDetails={withDetails}>
          {durationText}
        </AddonInfoTextStyled>
      )}
    </>
  )
}

export default AddonHeader
