import { BlueprintElements } from 'types/blueprint-config'

import { BuildingBlock } from '../../../building-blocks/types'

export const buildBuildingDetailsBlock = (): BuildingBlock => {
  const schema = {
    housing_units: {
      type: 'number',
    },
    business_units: {
      type: 'number',
    },
  }

  const uischema: BlueprintElements[] = [
    {
      type: 'GridItemLayout',
      options: {
        xs: 4,
      },
      elements: [
        {
          type: 'Control',
          options: {
            stepper: true,
          },
          label: 'Anzahl der Wohneinheiten',
          scope: '#/properties/housing_units',
        },
      ],
    },
    {
      type: 'GridItemLayout',
      options: {
        xs: 4,
      },
      elements: [
        {
          type: 'Control',
          options: {
            stepper: true,
          },
          label: 'Anzahl der Geschäftseinheiten',
          scope: '#/properties/business_units',
        },
      ],
    },
  ]

  return {
    schema: schema,
    uischema: uischema,
  }
}
