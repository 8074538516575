import { BlueprintResults } from '../../../types/blueprint-config'

import { defaultAddressSchemaProperties } from './schemas/address.schema'
import { defaultConfirmationSchemaProperties } from './schemas/confirmation.schema'
import { defaultConsumptionCalculatorSchemaProperties } from './schemas/consumption-calculator.schema'
import { defaultContractCancellationSchemaProperties } from './schemas/contract-cancellation.schema'
import { defaultPaymentSchemaProperties } from './schemas/payment.schema'
import { defaultPersonalInformationSchemaProperties } from './schemas/personal-information.schema'

export const results: BlueprintResults = {
  originalDataKey: 'fields',
  payloadMapping: {
    widgetId: 'meta.widgetId',
    widgetVersion: 'meta.version',
    organizationId: 'meta.organizationId',
    custom_fields: [
      {
        type: 'Text',
        value: `data.${defaultConsumptionCalculatorSchemaProperties.power_consumption.name}.amount`,
        label: 'Jährlicher Verbrauch (Strom)',
      },
      {
        type: 'Text',
        value: `data.${defaultConsumptionCalculatorSchemaProperties.gas_consumption.name}.amount`,
        label: 'Jährlicher Verbrauch (Gas)',
      },
      {
        type: 'Text',
        value: `data.${defaultAddressSchemaProperties.invoice_recipient_first_name.name}`,
        label: 'Abweichender Rechnungsempfänger Vorname',
      },
      {
        type: 'Text',
        value: `data.${defaultAddressSchemaProperties.invoice_recipient_last_name.name}`,
        label: 'Abweichender Rechnungsempfänger Nachname',
      },
      {
        type: 'Dropdown',
        value: `data.${defaultContractCancellationSchemaProperties.situation_power.name}`,
        label: 'Welche Situation trifft auf Sie zu? (Strom)',
      },
      {
        type: 'Dropdown',
        value: `data.${defaultContractCancellationSchemaProperties.terminate_contract_power.name}`,
        label: 'Sollen wir Ihren bestehenden Vertrag für Sie kündigen? (Strom)',
      },
      {
        type: 'Dropdown',
        value: `data.${defaultContractCancellationSchemaProperties.first_time_occupancy_power.name}`,
        label: 'Handelt es sich um einen Erstbezug in einem Neubau? (Strom)',
      },
      {
        type: 'Text',
        value: `data.${defaultContractCancellationSchemaProperties.delivery_date_power.name}`,
        label: 'Gewünschter Lieferbeginn (Strom)',
      },
      {
        type: 'Text',
        value: `(data.${defaultContractCancellationSchemaProperties.delivery_date_asap_power.name})?(Ja):(Nein)`,
        label: 'Schnellstmöglich (Strom)',
      },
      {
        type: 'Text',
        value: `data.${defaultContractCancellationSchemaProperties.termination_date_power.name}`,
        label: 'Kündigungstermin* (Strom)',
      },
      {
        type: 'Text',
        value: `(data.${defaultContractCancellationSchemaProperties.termination_date_asap_power.name})?(Ja):(Nein)`,
        label: 'Schnellstmöglich (Strom)',
      },
      {
        type: 'Text',
        value: `data.${defaultContractCancellationSchemaProperties.previous_provider_power.name}.company_name`,
        label: 'Bisheriger Anbieter (Strom)',
      },
      {
        type: 'Text',
        value: `data.${defaultContractCancellationSchemaProperties.moving_date_power.name}`,
        label: 'Einzugdatum (Strom)',
      },
      {
        type: 'Dropdown',
        value: `data.${defaultContractCancellationSchemaProperties.situation_gas.name}`,
        label: 'Welche Situation trifft auf Sie zu? (Gas)',
      },
      {
        type: 'Dropdown',
        value: `data.${defaultContractCancellationSchemaProperties.terminate_contract_gas.name}`,
        label: 'Sollen wir Ihren bestehenden Vertrag für Sie kündigen? (Gas)',
      },
      {
        type: 'Dropdown',
        value: `data.${defaultContractCancellationSchemaProperties.first_time_occupancy_gas.name}`,
        label: 'Handelt es sich um einen Erstbezug in einem Neubau? (Gas)',
      },
      {
        type: 'Text',
        value: `data.${defaultContractCancellationSchemaProperties.delivery_date_gas.name}`,
        label: 'Gewünschter Lieferbeginn (Gas)',
      },
      {
        type: 'Text',
        value: `(data.${defaultContractCancellationSchemaProperties.delivery_date_asap_gas.name})?(Ja):(Nein)`,
        label: 'Schnellstmöglich (Gas)',
      },
      {
        type: 'Text',
        value: `data.${defaultContractCancellationSchemaProperties.termination_date_gas.name}`,
        label: 'Kündigungstermin* (Gas)',
      },
      {
        type: 'Text',
        value: `(data.${defaultContractCancellationSchemaProperties.termination_date_asap_gas.name})?(Ja):(Nein)`,
        label: 'Schnellstmöglich (Gas)',
      },
      {
        type: 'Text',
        value: `data.${defaultContractCancellationSchemaProperties.previous_provider_gas.name}.company_name`,
        label: 'Bisheriger Anbieter (Gas)',
      },
      {
        type: 'Text',
        value: `data.${defaultContractCancellationSchemaProperties.moving_date_gas.name}`,
        label: 'Einzugdatum (Gas)',
      },
      {
        type: 'Text',
        value: `data.${defaultPaymentSchemaProperties.payment_method.name}`,
        label: 'Zahlungsart',
      },
      {
        type: 'Text',
        value: `data.${defaultPaymentSchemaProperties.account_owner.name}`,
        label: 'Kontoinhaber',
      },
      {
        type: 'Text',
        value: `data.${defaultPaymentSchemaProperties.sepa.name}.iban`,
        label: 'IBAN',
      },
      {
        type: 'Text',
        value: `data.${defaultPaymentSchemaProperties.sepa.name}.bic_number`,
        label: 'BIC',
      },
      {
        type: 'Text',
        value: `data.${defaultPaymentSchemaProperties.sepa.name}.bank_name`,
        label: 'Bankname',
      },
      {
        type: 'Text',
        value: `(data.${defaultConfirmationSchemaProperties.accept_terms.name})?(Ja):(Nein)`,
        label: 'AGB und Datenschutzerklärung gelesen und akzeptiert.',
      },
      {
        type: 'Text',
        value: `(data.${defaultConfirmationSchemaProperties.withdrawal.name})?(Ja):(Nein)`,
        label: 'Die Widerrufsbelehrung habe ich zur Kenntnis genommen.',
      },
      {
        type: 'Text',
        value: `(data.${defaultConfirmationSchemaProperties.accept_promotions.name})?(Ja):(Nein)`,
        label: 'Verarbeitung und Nutzung personenbezogener Daten akzeptiert.',
      },
    ],
    questions: [],
    opportunity: {
      product_package_new_id: 'shop.cart.firstProductId',
      email_template_id: '0',
      additionalFields: {
        powerConsumption: `data.${defaultConsumptionCalculatorSchemaProperties.power_consumption.name}.amount`,
        gasConsumption: `data.${defaultConsumptionCalculatorSchemaProperties.gas_consumption.name}.amount`,
        deliveryData: `data.${defaultContractCancellationSchemaProperties.delivery_date_power.name}`,
        nextDeliveryDate: `data.${defaultContractCancellationSchemaProperties.delivery_date_asap_power.name}`,
        terminateOldContract: `data.${defaultContractCancellationSchemaProperties.terminate_contract_power.name}`,
        acceptTerms: `(data.${defaultConfirmationSchemaProperties.accept_terms.name})?(Ja):(Nein)`,
        paymentSepaIban: `data.${defaultPaymentSchemaProperties.sepa.name}.iban`,
        paymentSepaBankname: `data.${defaultPaymentSchemaProperties.sepa.name}.bank_name`,
        paymentType: `data.${defaultPaymentSchemaProperties.payment_method.name}`,
        signature: `data.${defaultConfirmationSchemaProperties.signature.name}`,
      },
      enable_send_email: false,
      selling_option_id: 'shop.cart.firstSellingOptionId',
      addOns: 'shop.cart.addons',
      product_id: 'shop.cart.firstProductCategoryId',
      description: 'Created by a Widget request.',
      title: 'Opportunity from Widget.',
      brand_id: '3154113',
    },
    customer: {
      salutation: `data.${defaultPersonalInformationSchemaProperties.salutation.name}`,
      title: `data.${defaultPersonalInformationSchemaProperties.title.name}`,
      firstName: `data.${defaultPersonalInformationSchemaProperties.first_name.name}`,
      lastName: `data.${defaultPersonalInformationSchemaProperties.last_name.name}`,
      birthdate: `data.${defaultPersonalInformationSchemaProperties.birth_date.name}`,
      email: `data.${defaultPersonalInformationSchemaProperties.email.name}`,
      phone: `data.${defaultPersonalInformationSchemaProperties.telephone.name}`,
      differentBillingAddress: `data.${defaultAddressSchemaProperties.different_billing_address.name}`,
      organizationId: 'meta.organizationId',
      customerType: 'data.customer_type',
      deliveryAddress: {
        streetName: `data.${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.streetName`,
        city: `data.${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.city`,
        streetNumber: `data.${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.houseNumber`,
        postalCode: `data.${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.zipCode`,
        address_suffix: `data.${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.extention`,
      },
      billingAddress: {
        streetName: `data.${defaultAddressSchemaProperties.billing_address.name}.streetName`,
        city: `data.${defaultAddressSchemaProperties.billing_address.name}.city}`,
        streetNumber: `data.${defaultAddressSchemaProperties.billing_address.name}.houseNumber`,
        postalCode: `data.${defaultAddressSchemaProperties.billing_address.name}.zipCode}`,
        address_suffix: `data.${defaultAddressSchemaProperties.billing_address.name}.extention`,
      },
    },
    cart: {
      summary: {
        pricingBusiness: 'shop.cart.pricingBusiness',
        gross: 'shop.cart.totalGross',
        vat: 'shop.cart.totalVat',
        currency: '€',
        interval: 'shop.cart.productPriceInterval',
        net: 'shop.cart.totalNet',
        vatPercent: '16',
      },
      items: 'shop.cart.items',
    },
  },
}
