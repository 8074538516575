import { GlobalResultData } from 'modules/step/types'

import tokenize from './tokenize'

export default (
  templateString: string,
  data: GlobalResultData,
  labels?: { [property: string]: { [value: string]: string } },
  formatNumber?: boolean,
) => {
  let result = ''

  result = tokenize(templateString)
    .map((element) => {
      if (element.type === 'tag') {
        let value
        const numberOfKeyInValue = element.value.split('.').length

        if (numberOfKeyInValue && numberOfKeyInValue === 2) {
          const separatedKeys = element.value.split('.')
          const key1 = separatedKeys[0]
          const key2 = separatedKeys[1]

          value = key1 in data && data[key1] ? data[key1][key2] : null
        } else {
          value = data[element.value]
        }

        if (labels && labels[element.value]) {
          // replace value with pretty label value
          value = labels[element.value][value]
        }

        return formatNumber
          ? new Intl.NumberFormat(navigator.language).format(parseInt(value))
          : value
      } else {
        return element.value
      }
    })
    .join('')
    .trim()

  // TODO: This is highly heuristic! Need to think a better way
  if (result.split(':').length > 1 && !result.split(':')[1]) {
    return null
  }

  return result
}
