import { ConfigurationIcon, CheckIcon } from '@epilot/components'
import {
  OwnPropsOfRenderer,
  RankedTester,
  rankWith,
  uiTypeIs,
  UISchemaElement,
} from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

import IconStyled from './icon-styled'

const icons: any = {
  default: ConfigurationIcon,
  ConfigurationIcon: ConfigurationIcon,
  CheckIcon: CheckIcon,
}

const Icon = (props: OwnPropsOfRenderer): React.ReactElement | null => {
  const { uischema } = props
  const { options } = uischema as UISchemaElement
  const label = options?.label as string

  const IconComponent = icons[label || 'default']

  return (
    <IconStyled>
      <IconComponent {...options} />
    </IconStyled>
  )
}

export const IconRenderer = withJsonFormsLayoutProps(Icon)

export const iconRendererTester: RankedTester = rankWith(1, uiTypeIs('Icon'))
