import { CustomField } from 'lib/api/types'

import { PayloadResults } from '../types'

export const customFieldsPower = (results: PayloadResults): CustomField[] => {
  const values: CustomField[] = []

  if (results.power_consumption?.amount) {
    values.push({
      value: results.power_consumption?.amount.toString(),
      label: 'Jährlicher Verbrauch (Strom)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.situation_power) {
    values.push({
      value: results.situation_power,
      label: 'Welche Situation trifft auf Sie zu? (Strom)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.terminate_contract_power) {
    values.push({
      value: results.terminate_contract_power,
      label: 'Sollen wir Ihren bestehenden Vertrag für Sie kündigen? (Strom)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.first_time_occupancy_power) {
    values.push({
      value: results.first_time_occupancy_power,
      label: 'Handelt es sich um einen Erstbezug in einem Neubau? (Strom)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.delivery_date_asap_power) {
    values.push({
      value: 'Ja',
      label: 'Gewünschter Lieferbeginn (Strom) schnellstmöglich',
      source: 'journey',
      readOnly: true,
    })
  } else if (results.delivery_date_power) {
    values.push({
      value: 'Nein',
      label: 'Gewünschter Lieferbeginn (Strom) schnellstmöglich',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.previous_provider_power?.company_name) {
    values.push({
      value: results.previous_provider_power?.company_name,
      label: 'Bisheriger Anbieter (Strom)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.previous_provider_power?.bdew_code) {
    values.push({
      value: results.previous_provider_power?.bdew_code,
      label: 'BDEW Code - MarktPartner ID (Strom)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.moving_date_power) {
    values.push({
      value: results.moving_date_power,
      label: 'Einzugdatum (Strom)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (
    results.power_readings &&
    results.power_readings.length &&
    results.power_readings.length > 0
  ) {
    results.power_readings.forEach((reading, index) => {
      values.push(
        ...[
          {
            value: reading.meterNumber,
            label: `Zählernummer Strom ${index + 1}`,
            source: 'journey' as const,
            readOnly: true,
          },
          {
            value: reading.meterReading,
            label: `Zählerwert Strom ${index + 1}`,
            source: 'journey' as const,
            readOnly: true,
          },
          {
            value: reading.marketLocationId,
            label: `MaLo-ID Strom ${index + 1}`,
            source: 'journey' as const,
            readOnly: true,
          },
        ],
      )
    })
  }

  return values
}
