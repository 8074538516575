import { H1 } from '@epilot/components'
import {
  OwnPropsOfRenderer,
  RankedTester,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

import LabelControl from './label-control'

/**
 * LabelH1 tester
 * @type {RankedTester}
 */
export const labelH1RendererTester: RankedTester = rankWith(
  1,
  uiTypeIs('LabelH1'),
)

/**
 * LabelH1 tester renderer
 */
export const LabelH1Renderer = (
  props: OwnPropsOfRenderer,
): React.ReactElement | null => {
  return <LabelControl component={H1} {...props} />
}

export default withJsonFormsLayoutProps(LabelH1Renderer)
