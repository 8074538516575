import { styled } from '@epilot/components'

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  height: 100%;
  justify-content: center;
  text-align: center;
  svg {
    border: 1px solid #d2dee4;
    border-radius: 50%;
    box-sizing: content-box;
    color: #849096;
    height: 45px;
    margin-bottom: 24px;
    overflow: visible;
    padding: 25px;
    width: 45px;
  }
  h1 {
    color: #222222;
    font-size: 24px;
    font-weight: normal;
    margin: 0 0 8px 0;
  }
  p {
    color: #849096;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }
  a {
    color: #039be5;
    outline: 0;
  }
  a:active,
  a:focus {
    outline: 0;
    border: none;
    outline-style: none;
    -moz-outline-style: none;
  }
  button {
    background: none;
    border: none;
    color: #039be5;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    margin-top: 20px;
  }
`

export default ErrorContainer
