import { BodyText, SmallText, CircleInfoIcon } from '@epilot/components'
import React from 'react'
import { useSelector } from 'react-redux'

import { formatCurrency } from 'lib/format-currency'
import {
  getCart,
  getCartTotalSummary,
  getCartWithDetails,
} from 'modules/shop/selectors'

import CartFooterStyled from './cart-footer-styled'

type CartFooterProps = {
  priceIntervalLabel: string
  pricingBusiness: boolean
}

const CartFooter = ({
  priceIntervalLabel,
  pricingBusiness,
}: CartFooterProps): JSX.Element => {
  const cartTotalValues = useSelector(getCartTotalSummary)
  const cart = useSelector(getCart)
  const cartWithDetails = useSelector(getCartWithDetails)

  const isEmpty = cart.length < 1

  const containsBroadband =
    cart.filter(
      (product) => product.productCategory.toLocaleUpperCase() === 'BROADBAND',
    ).length > 0

  return (
    <CartFooterStyled>
      <div>
        <div className="cart-summary">
          {isEmpty ? 'Warenkorb ist leer' : `${cart.length} Artikel`}
        </div>
        {!isEmpty && !pricingBusiness && (
          <div className="cart-price">
            <BodyText fontWeight={600}>
              {formatCurrency(cartTotalValues.gross)}
              <span>/{priceIntervalLabel}</span>
            </BodyText>
            {!isEmpty &&
              (containsBroadband || cartTotalValues.oneTimeGross > 0) && (
                <SmallText>
                  {`+${formatCurrency(
                    cartTotalValues.oneTimeGross || 0,
                  )} einmalig`}
                </SmallText>
              )}
            <div className="tooltip">
              <SmallText>
                Alle Preise inklusive{' '}
                {!containsBroadband
                  ? `${cartTotalValues.vatPct.toFixed(0)}%`
                  : 'der gesetzlichen'}{' '}
                MwSt
              </SmallText>
              {!containsBroadband && (
                <>
                  <CircleInfoIcon color="#849096" size={'smallest'} />
                  <div className="tooltip-text">
                    Preise inkl. {cartTotalValues.vatPct.toFixed(0)}% MwSt. Der
                    voraussichtliche monatliche Preis basiert auf Ihren
                    Verbrauchsangaben. Der tatsächliche Abschlag kann vom
                    angezeigten Betrag abweichen.
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {!isEmpty && pricingBusiness && (
          <div className="cart-price">
            <BodyText fontWeight={600}>
              {formatCurrency(cartTotalValues.net)}
              <span>/{priceIntervalLabel}</span>
            </BodyText>
            {!isEmpty &&
              (containsBroadband || cartTotalValues.oneTimeNet > 0) && (
                <SmallText>
                  {`+${formatCurrency(
                    cartTotalValues.oneTimeNet || 0,
                  )} einmalig`}
                </SmallText>
              )}
            <div className="tooltip">
              <SmallText>
                Alle Preise exklusive{' '}
                {!containsBroadband
                  ? `${cartTotalValues.vatPct.toFixed(0)}%`
                  : 'der gesetzlichen'}{' '}
                MwSt
              </SmallText>
              {!containsBroadband && (
                <>
                  <CircleInfoIcon color="#849096" size={'smallest'} />
                  <div className="tooltip-text">
                    Preise inkl. {cartTotalValues.vatPct.toFixed(0)}% MwSt. Der
                    voraussichtliche monatliche Preis basiert auf Ihren
                    Verbrauchsangaben. Der tatsächliche Abschlag kann vom
                    angezeigten Betrag abweichen.
                  </div>
                </>
              )}
            </div>
            <SmallText>
              {cartTotalValues.vatPct.toFixed(0)}% MwSt:{' '}
              {formatCurrency(cartTotalValues.vat)} / {priceIntervalLabel}
            </SmallText>
            <BodyText>
              Brutto: {formatCurrency(cartTotalValues.gross)} /{' '}
              {priceIntervalLabel}
            </BodyText>
          </div>
        )}
      </div>
      <div>
        {!isEmpty &&
          cartWithDetails.map((item) => (
            <div className="bonus-item-container" key={item.id}>
              {item?.custom_fields?.['Bonus Titel'] && (
                <div>{item?.custom_fields?.['Bonus Titel']}</div>
              )}

              {item?.custom_fields?.['Bonus Detail Info'] && (
                <div>{item?.custom_fields?.['Bonus Detail Info']}</div>
              )}
            </div>
          ))}
      </div>
    </CartFooterStyled>
  )
}

export default CartFooter
