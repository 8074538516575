import Analytics, { AnalyticsInstance } from 'analytics'

import config from 'config'

import customFacebookPixel from './plugins/customFacebookPixel'
import { FB_PLUGIN_NAME } from './plugins/utils'
import { AnalyticsInit } from './types'

/**
 * After initialized, offers some methods to track data to Facebook Pixel
 * @method getPageData collects host information + widgetId + given stepId and returns page path
 * @method page mimics analytics.page() function call, but calls own instance and if given clients instance
 * @method init initializes clients analytics if set in journey builder. Instance is then being used for other track methods
 * @returns methods to interact with analytics
 *
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const trackerInit = function () {
  // clients analytics tracker if initialized
  let clientsAnalytics: undefined | AnalyticsInstance
  // The facebook pixel id
  let fbTrackingId = ''
  // id of widget used to generate virtual page path
  let widgetId = ''
  // initialStepId to track pageview once connected and initialized
  let initialStepId = ''
  // Enable or disable tracker from loading until `analytics.plugins.enable` / `analytics.plugins.disable` called
  let initEnabled = false

  /**
   * Initializer for clientsAnalytics tracker
   */
  const init = () => {
    if (fbTrackingId && !clientsAnalytics) {
      clientsAnalytics = Analytics({
        app: 'e-pilot-widget',
        plugins: [
          customFacebookPixel(
            {
              trackingId: fbTrackingId,
            },
            FB_PLUGIN_NAME,
            initEnabled,
          ),
        ],
        debug: config.NODE_ENV.NODE_ENV === 'development',
      })
      // initial pageview once initialized
      clientsAnalytics.page({ path: getPageData(initialStepId) })
    } else {
      console.error('Called init while having missing values')
    }
  }

  const getPageData = (stepId: string): string => {
    // only slash if widgetId given
    return `epilot-widget/${widgetId ? widgetId + '/' : ''}${stepId}`
  }

  return {
    /**
     * Analytics functions
     */
    initialize: function ({
      trackingId: fb,
      widgetId: id,
      initialStepId: stepId,
      toEnable = false,
    }: AnalyticsInit) {
      fbTrackingId = fb || ''
      widgetId = id
      initialStepId = stepId
      initEnabled = toEnable

      if (fbTrackingId && widgetId && initialStepId && !clientsAnalytics) init()
    },
    page: function (stepId: string) {
      const path = getPageData(stepId)

      if (clientsAnalytics) clientsAnalytics.page({ path })
    },
    track: function (eventName: string, payload?: any, isCustom?: boolean) {
      if (clientsAnalytics) {
        clientsAnalytics.track(eventName, payload, { isCustom })
      }
    },
    /**
     * Enable/Disable a plugin
     * @param plugin the plugin identifier / name
     * @param enable used to enable or disable the plugin
     * @param currentStepId current step id (optional, only needed when enabling plugin)
     */
    enablePlugin: (
      plugin: string,
      toEnable = false,
      currentStepId?: string,
    ) => {
      if (clientsAnalytics) {
        if (toEnable) {
          const pluginState = clientsAnalytics.getState('plugins')
          const alreadyEnabled = pluginState[plugin].enabled || false // to be used as a protection to avoid multiple calls to page tracking

          clientsAnalytics.plugins.enable(plugin, () => {
            if (clientsAnalytics && !alreadyEnabled) {
              const stepToTrack = currentStepId || initialStepId

              clientsAnalytics.page({
                path: getPageData(stepToTrack),
              })
            }
          })
        } else {
          clientsAnalytics.plugins.disable(plugin, () => {
            // Do something after disabling if needed
          })
        }
      }
    },
  }
}

export const fbTracker = trackerInit()
