import { styled } from '@epilot/components'

const CartItemInfoTextStyled = styled.span`
  margin-top: 5px;
  font-size: 10px;
  line-height: 10px;
  color: #849096;
`

export default CartItemInfoTextStyled
