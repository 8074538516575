import { ObjectKeyString } from 'lib/types'
import { ShopState } from 'modules/shop/types'
import { GlobalResultData } from 'modules/step/types'

import { formatIsoDateString } from '../../../../../src/lib/format-date'

import { getDataFromMapping } from './getDataFromMapping'
// eslint-disable-next-line no-useless-escape
const logicRegExp = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/

export const getPayloadFromMapping = (
  mapping: ObjectKeyString<any>,
  data: GlobalResultData,
  meta: ObjectKeyString<string>,
  shop: ShopState,
): { [key: string]: any } | undefined => {
  const properties = Object.keys(mapping)
  const payload = {} as any

  properties.forEach((key) => {
    if (Array.isArray(mapping[key])) {
      payload[key] = mapping[key]
        .map((item: ObjectKeyString<any>) => {
          return getPayloadFromMapping(item, data, meta, shop)
        })
        .filter((item: ObjectKeyString<any>) => {
          if (
            item['type'] === 'Dropdown' &&
            (typeof item['value'] === 'undefined' || item['value'] === null)
          )
            return false
          if (
            typeof item['question'] !== 'undefined' &&
            (typeof item['answer'] === 'undefined' || item['answer'] === null)
          )
            return false
          if (
            typeof item['label'] !== 'undefined' &&
            (typeof item['value'] === 'undefined' || item['value'] === null)
          )
            return false
          if (
            item['type'] === 'Text' &&
            (typeof item['value'] === 'undefined' || item['value'] === null)
          )
            return false

          return true
        })
    } else if (typeof mapping[key] === 'object') {
      // check sub level when an object
      const value = getPayloadFromMapping(mapping[key], data, meta, shop)

      if (typeof value !== 'undefined') {
        payload[key] = value
      }
    } else if (typeof mapping[key] === 'string') {
      const value = getDataFromMapping(mapping[key] as string, data, meta, shop)

      if (typeof value !== 'undefined') {
        const dateArray = logicRegExp.exec(value)
        let newValue = ''

        if (dateArray && dateArray.length > 0) {
          newValue = formatIsoDateString(value, true)
        } else {
          newValue = value
        }
        payload[key] = newValue
      }
    } else if (
      typeof mapping[key] === 'boolean' ||
      typeof mapping[key] === 'number'
    ) {
      payload[key] = mapping[key]
    }
  })

  if (typeof payload === 'object' && Object.keys(payload).length === 0)
    return undefined

  return payload
}
