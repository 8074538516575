import { Blueprint } from '../../types/blueprint-config'

import { results } from './config/results'
import {
  buildingDetailsStep,
  ownerDetailsStep,
  overviewStep,
  thankYouStep,
} from './config/steps'
import { epilotStyling } from './config/styling'

export const NewGGNV: Blueprint = {
  widgetId: 'new-ggnv-blueprint',
  initialStepId: buildingDetailsStep.id,
  styling: epilotStyling,
  steps: [buildingDetailsStep, ownerDetailsStep, overviewStep, thankYouStep],
  results,
  builder: {
    useBaseElementsLibrary: true,
  },
}
