import { useSelector } from 'react-redux'

import { getActiveStep, getSteps } from '../../modules/step/selectors'
import { useDataDog } from '../hooks/useDataDog'
import { useIsDirty } from '../hooks/useIsDirty'

/**
 * Component is only used to hook up logic to a class component
 * @returns null
 */
export const UseIsDirty = () => {
  const steps = useSelector(getSteps)
  const activeStep = useSelector(getActiveStep)

  const isDirty = useIsDirty(
    Object.keys(steps).map((key) => steps[key].resultData),
  )

  useDataDog(isDirty && !activeStep?.resultData?.previewMode)

  return null
}
