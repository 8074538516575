import { Step } from 'modules/step/types'

export const isSkipStep = (
  builder: Record<string, any> | undefined,
  step: Step,
  skipSteps: string[],
  index: number,
): unknown => {
  return (
    (builder &&
      builder.steps &&
      builder.steps[step.id] &&
      builder?.steps[step?.id]?.toggle_has_visibility === false) ||
    skipSteps.includes((index + 1).toString())
  )
}

export const isSkipStepById = (
  builder: Record<string, any> | undefined,
  step: Step,
  skipSteps: Step[],
): unknown => {
  return (
    (builder &&
      builder.steps &&
      builder.steps[step.id] &&
      builder?.steps[step?.id]?.toggle_has_visibility === false) ||
    skipSteps.findIndex((s) => s.id === step.id) > -1
  )
}
