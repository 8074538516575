import { styled, ThemeProps } from '@epilot/components'

import { Theme } from '../../../modules/app/types'

type ProductsHeaderStyledProps = {
  theme: Theme
  tariffDetails: boolean
  withDetails: boolean
}

export const TariffDetailsBulletStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }: { theme: ThemeProps }) => theme.spacing(2)}px;

  p {
    margin: 0 ${({ theme }: { theme: ThemeProps }) => theme.spacing(1)}px;
    text-align: left;
    font-size: ${({ theme }: { theme: ThemeProps }) =>
      theme.typography.body1.fontSize};
    line-height: 1.2;
  }
  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-top: 2px;
  }
`

const ProductsHeaderStyled = styled.div<ProductsHeaderStyledProps>`
  align-items: ${({ tariffDetails }: ProductsHeaderStyledProps) =>
    tariffDetails ? 'left' : 'center'};
  display: flex;
  flex-direction: ${({
    tariffDetails,
    withDetails,
  }: ProductsHeaderStyledProps) =>
    tariffDetails || withDetails ? 'column' : 'column-reverse'};
  flex-grow: ${({ tariffDetails }: ProductsHeaderStyledProps) =>
    tariffDetails ? 1 : 'auto'};
  max-height: 100%;
  flex-grow: 1 !important;
  justify-content: center;
  padding: ${({ withDetails }: ProductsHeaderStyledProps) =>
    withDetails ? '24px 24px 16px 24px' : '40px 24px'};

  p {
    color: ${({ theme }: ProductsHeaderStyledProps) =>
      theme?.typography?.color} !important;
    font-size: ${({ withDetails }: ProductsHeaderStyledProps) =>
      withDetails ? '14px' : '18px'};
    font-weight: ${({ withDetails }: ProductsHeaderStyledProps) =>
      withDetails ? '600' : '400'};
    margin-bottom: 0px;
    margin-top: ${({ tariffDetails, withDetails }: ProductsHeaderStyledProps) =>
      withDetails ? '16px' : tariffDetails ? '0' : '32px'};
    text-align: ${({ tariffDetails }: ProductsHeaderStyledProps) =>
      tariffDetails ? 'left' : 'center'};
    min-height: ${({ withDetails }: ProductsHeaderStyledProps) =>
      withDetails ? '72px' : 'auto'};
  }

  p:not(${TariffDetailsBulletStyled} p) {
    line-height: 24px;
  }

  div:last-child:not(${TariffDetailsBulletStyled}) {
    align-self: ${({ tariffDetails }: ProductsHeaderStyledProps) =>
      tariffDetails ? 'flex-end' : 'auto'};
    ${({ tariffDetails }: ProductsHeaderStyledProps) =>
      tariffDetails && 'margin-top: auto;'};
  }
`

export const TariffDetailsStyled = styled.div`
  margin: ${({ theme }: { theme: ThemeProps }) => theme.spacing(3)}px 0;
`

export default ProductsHeaderStyled
