import { BlueprintElements } from 'types/blueprint-config'

export const stepperWrapper = (
  steps: string[],
  elements: BlueprintElements[],
): BlueprintElements[] => {
  const uischema: BlueprintElements[] = [
    {
      type: 'StepperNavigation',
      options: {
        steps: steps,
      },
      elements: [
        {
          type: 'GridContainerLayout',
          options: {
            spacing: 4,
          },
          elements: [...elements],
        },
      ],
    },
  ]

  return uischema
}
