import './overview-step.css'

import { OwnPropsOfRenderer, UISchemaElement } from '@jsonforms/core'
import React from 'react'
import { connect } from 'react-redux'

import { ReactComponent as PenIcon } from 'assets/icons/pen.svg'
import { ObjectKeyString } from 'lib/types'
import { goToStep } from 'modules/step/reducer'
import { getStepDataById } from 'modules/step/selectors'
import { StepData, StepId } from 'modules/step/types'
import { GlobalAppState } from 'modules/types'

type OverviewElementProps = OwnPropsOfRenderer & {
  type: 'step' | 'manual'
  goToStep?: typeof goToStep
  stepData?: StepData
  uischema?: UISchemaElement & {
    label?: string
    stepId?: StepId
    labels?: ObjectKeyString<{
      label?: string
      labels?: ObjectKeyString<string>
    }>
  }
}

const OverviewElement = (props: OverviewElementProps) => {
  const { type, stepData, uischema, goToStep } = props

  const stepId = uischema?.stepId as StepId
  const label = uischema?.label as string
  const labels = uischema?.labels as
    | ObjectKeyString<{
        label?: string
        labels?: ObjectKeyString<string>
      }>
    | undefined
  const options = uischema?.options
  const optionExclude = options?.exclude
  const isEditable = options?.editable

  // TODO: handle manual
  // read elements in overview uischema
  // get the data of the referenced scope
  if (type === 'manual') return null

  let elements: unknown[] = []

  if (stepData !== undefined) {
    // type === "step" continue
    elements = Object.keys(stepData).map((dataKey, index) => {
      if (
        optionExclude &&
        Array.isArray(optionExclude) &&
        optionExclude.includes(dataKey)
      ) {
        // Skip property
        return null
      }
      let value: unknown = ''
      const isBoolean = typeof stepData[dataKey] === 'boolean'
      const isDate = stepData[dataKey] instanceof Date

      if (isDate) {
        const date = stepData[dataKey] as Date
        const options = {
          year: 'numeric' as const,
          month: 'long' as const,
          day: 'numeric' as const,
        }

        value = date.toLocaleDateString('de-DE', options) //date.toDateString()
      } else if (isBoolean) {
        value = stepData[dataKey] === true ? 'Ja' : 'Nein'
      } else {
        value = stepData[dataKey]
      }

      let propertyLabel
      let propertyValueLabels

      if (labels && labels[dataKey]) {
        const valueString = value as string

        propertyLabel = labels[dataKey].label
        propertyValueLabels = labels[dataKey].labels

        if (propertyValueLabels && propertyValueLabels[valueString]) {
          value = propertyValueLabels[valueString]
        }
      }

      return (
        <div className="items" key={`overview-element-${dataKey}-${index}`}>
          {propertyLabel ? `${propertyLabel}: ` : null}
          {value}
        </div>
      )
    })
  }
  // create a clickable "card" with boarder and an edit icon
  // display the data in the card
  // if clicked link to the coresponting step

  if (elements.length === 0) return null

  return (
    <div
      className={
        'overview-element--container' + (isEditable ? ' editable' : '')
      }
      onClick={() => isEditable && goToStep && goToStep(stepId)}
    >
      <div className="overview-element">
        {label ? <div className="title">{label}</div> : null}
        {elements}
      </div>
      {isEditable && (
        <div className="icon">
          <PenIcon />
        </div>
      )}
    </div>
  )
}

export default connect(
  (state: GlobalAppState, props: OverviewElementProps) => {
    const stepId = props.uischema?.stepId

    return {
      stepData: stepId ? getStepDataById(state, stepId) : {},
    }
  },
  {
    goToStep: goToStep,
  },
)(OverviewElement)
