import { ProductCategory } from 'modules/shop/types'
import { contractTypeFind } from 'modules/step/getResultsFromMapping/dataOpportunitiesCreate/contractTypeFind'

import { GlobalResultData } from '../../types'
import { getConsumption } from '../utils/getDataFromMapping'
import {
  getSalutationFromLabel,
  getTitleFromLabel,
} from '../utils/getDataFromMapping/getMappedTitleAndSautation'

import { fixedCustomFields, fixedQuestions } from './fixMissingLabel'
//import { generateConsents } from './generateConsents'
import { generateListCustomFields } from './installations_mapper'
import { createAddonsSchema, createCartItemsSchema } from './product_mapper'
import { generateScreenshotPack, deleteMultiFiles } from './screenshot_mapper'
export const getResponseFromMapping = (
  data: GlobalResultData,
  journeySubmitId: string,
) => {
  const response = {
    fields: {
      ...data.fields,
      power_consumption: getConsumption(data.fields.power_consumption),
    },
    organization_id: data.customer.organizationId || '728',
    widget_id: data.widgetId,
    widget_version: data.widgetVersion,
    description: 'Created by a Widget request',
    title_in_opportunity: 'Opportunity from Widget',
    customer_type: data.customer.customerType || '',
    journey_type: data.journey_type || '',
    delivery_contact: {
      salutation: getSalutationFromLabel(data.customer.salutation),
      company_name: data.customer.companyName || '',
      first_name: data.customer.firstName,
      last_name: data.customer.lastName,
      birthday: data.customer.birthdate || '',
      street_name: data.customer.deliveryAddress?.streetName || '',
      street_number: data.customer.deliveryAddress?.streetNumber || '',
      address_suffix: data.customer.deliveryAddress?.address_suffix || '',
      city: data.customer.deliveryAddress.city,
      postal_code: data.customer.deliveryAddress.postalCode,
      email: data.customer.email,
      phone: data.customer.phone,
      title: getTitleFromLabel(data.customer.title),
      country_code:
        data.fields.address_delivery_postalcode_city?.countrycode || 'DE',
    },
    billing_contact: {
      salutation:
        (data.customer.differentInvoiceName &&
          getSalutationFromLabel(data.customer.billingAddress.salutation)) ||
        getSalutationFromLabel(data.customer.salutation),
      company_name: data.customer.companyName || '',
      first_name:
        (data.customer.differentInvoiceName &&
          data.customer.billingAddress.firstName) ||
        data.customer.firstName,
      last_name:
        (data.customer.differentInvoiceName &&
          data.customer.billingAddress.lastName) ||
        data.customer.lastName,
      title:
        (data.customer.differentInvoiceName &&
          getTitleFromLabel(data.customer.billingAddress.title)) ||
        getTitleFromLabel(data.customer.title),
      birthday: data.customer.birthdate || '',
      street_name: data.customer.billingAddress?.streetName || '',
      street_number: data.customer.billingAddress?.streetNumber || '',
      city: data.customer.billingAddress?.city,
      postal_code: data.customer.billingAddress?.postalCode,
      address_suffix: data.customer.billingAddress?.address_suffix || '',
      email: data.customer.email,
      phone: data.customer.phone,
      country_code:
        data.fields.address_billing_postalcode_city?.countrycode ||
        data.fields.address_delivery_postalcode_city?.countrycode ||
        'DE',
    },
    company_details: {
      register_court: data.customer.registerCourt || '',
      register_number: data.customer.registerNumber || '',
    },
    additional_contacts: data.customer.additional_contacts || {},
    ...(data.opportunity.addOns && {
      addons:
        // using empty string here as its not used in v3 of results empty which the v4 is requiring
        createAddonsSchema(data.opportunity.addOns, '' as ProductCategory) ||
        [],
    }),
    ...(data.opportunity.additionalFields?.paymentType && {
      payment_details: {
        payment_type: data.opportunity.additionalFields?.paymentType || 'sepa',
        iban:
          data.fields.checkout_payment_sepa_iban ||
          data.fields.sepa?.iban ||
          '',
        bic:
          data.fields.checkout_payment_sepa_bic ||
          data.fields.sepa?.bic_number ||
          '',
        bank_name:
          data.fields.checkout_payment_sepa_bankname ||
          data.fields.sepa?.bank_name ||
          '',
        bank_account_owner:
          data.fields.checkout_payment_sepa_account_owner ||
          data.fields.account_owner ||
          '',
      },
    }),
    additional_user_input: {
      ...data.opportunity.additionalFields,
      sale_identifier_id: data.opportunity.identifier_id || data?.name,
      delivery_date: data.opportunity.additionalFields?.deliveryData || '',
      contract_type:
        data.opportunity.additionalFields?.preExisitingContract ||
        contractTypeFind(
          data.opportunity.additionalFields?.contract_type_power,
        ),
      terminate_old_contract:
        data.opportunity.additionalFields?.terminateOldContract || 'none',
      accept_terms: data.opportunity.additionalFields?.acceptTerms || false,
      accept_promotions: data.fields.accept_promotions || false,
      consents: data.consents || [],
      custom_fields: fixedCustomFields(generateListCustomFields(data)),
      questions_in_opportunity: fixedQuestions(data.questions),
      email_template_id: data.opportunity?.email_template_id || '0',
      enable_send_email:
        typeof data.opportunity.enable_send_email === 'boolean'
          ? data.opportunity.enable_send_email
          : true,
      brand_id:
        typeof data.opportunity.brand_id === 'number'
          ? data.opportunity.brand_id.toString()
          : data.opportunity.brand_id,
      enet_call_info: (data.callEnetInfo && data.callEnetInfo[0]) || {},
    },
    cart: createCartItemsSchema(
      data.cart?.items,
      data.opportunity?.selling_option_id,
      data.cart?.summary?.interval,
      // using empty string here as its not used in v3 of results empty which the v4 is requiring
      '' as ProductCategory,
    ),
    user_uploaded_files: data.opportunity.additionalFields?.upload_files || [],
    user_screenshot: generateScreenshotPack(data),
    journeySubmitId,
  }

  const newPayload = deleteMultiFiles(response)

  return newPayload
}
