import { styled } from '@epilot/components'

type ProductsSelectionStyledProps = {
  productsCount: number
}

const ProductsSelectionStyled = styled.div<ProductsSelectionStyledProps>`
  display: flex;
  justify-content: ${(props: ProductsSelectionStyledProps) =>
    props.productsCount > 3 ? 'left' : 'center'};
  flex-flow: row wrap;
  transition: all 0.3s ease-in;
  margin: -12px;
  box-sizing: border-box;
`

export default ProductsSelectionStyled
