import { BlueprintStep } from 'types/blueprint-config'

import {
  buildAddressBlock,
  boxWrapper,
  buildTextBlock,
  buildNextButtonBlock,
  buildPersonalInfoBlock,
  buildChoiceBlock,
  stepperWrapper,
  hideableWrapper,
  buildEmptySpaceBlock,
} from '../../../building-blocks'

const ownerInfo = buildPersonalInfoBlock()
const isOwnerChoice = buildChoiceBlock({
  scope: 'ownerType',
  choices: ['Eigentümer', 'Vertreter'],
  required: true,
})
const contactAddress = buildAddressBlock({
  address: { name: 'contact_address', required: true },
})
const hideableContactAddress = hideableWrapper({
  scope: 'different_address',
  label: 'Abweichend von Gebäudeanschrift',
  elements: contactAddress.uischema,
  requiredFields: [...(contactAddress.required ? contactAddress.required : [])],
})
const additionalOwnerInfo = buildPersonalInfoBlock({
  salutation: { name: 'additional_salutation', required: true },
  title: { name: 'additional_title', required: false },
  first_name: { name: 'additional_first_name', required: true },
  last_name: { name: 'additional_last_name', required: true },
  user_email: { name: 'additional_user_email', required: true },
  telephone: { name: 'additional_telephone', required: true },
})
const additionalContactAddress = buildAddressBlock({
  address: { name: 'additional_address', required: true },
})
const hideableAdditionalOwner = hideableWrapper({
  scope: 'additional_owner',
  label: 'Weiterer Eigentümer/in vorhanden',
  elements: [
    ...buildTextBlock(
      'Eigentümer(in) oder rechtsgeschäftliche/r Vertreter(in)',
      'h5',
    ),
    ...additionalOwnerInfo.uischema,
    ...buildTextBlock('Kontakadresse weiterer Eigentümer', 'h5'),
    ...additionalContactAddress.uischema,
  ],
  requiredFields: [
    ...(additionalOwnerInfo.required ? additionalOwnerInfo.required : []),
    ...(additionalContactAddress.required
      ? additionalContactAddress.required
      : []),
  ],
})

export const ownerDetailsStep: BlueprintStep = {
  id: 'owner_details',
  title: 'Owner Details',
  nextButtonLabel: 'Next',
  schema: {
    type: 'object',
    properties: {
      ...ownerInfo.schema,
      ...isOwnerChoice.schema,
      ...hideableContactAddress.schema,
      ...contactAddress.schema,
      ...hideableAdditionalOwner.schema,
      ...additionalOwnerInfo.schema,
      ...additionalContactAddress.schema,
    },
    required: [
      ...(isOwnerChoice.required ? isOwnerChoice.required : []),
      ...(ownerInfo.required ? ownerInfo.required : []),
    ],
    allOf: [
      hideableContactAddress.conditional_required,
      hideableAdditionalOwner.conditional_required,
    ],
  },
  uischema: {
    type: 'GridContainerLayout',
    options: {
      spacing: 0,
    },
    elements: [
      ...stepperWrapper(
        ['building_details', 'owner_details', 'overview'],
        [
          ...boxWrapper([
            ...buildTextBlock('Eigentümerangaben', 'h1'),
            ...buildTextBlock('*Benötigte Angaben', 'caption', 'flex-end'),
            ...buildTextBlock('This is a placeholder text.', 'body1'),
            ...buildTextBlock(
              'Eigentümer(in) oder rechtsgeschäftliche/r Vertreter(in)',
              'h5',
            ),
            ...isOwnerChoice.uischema,
            ...buildEmptySpaceBlock(),
            ...ownerInfo.uischema,
            ...buildTextBlock('Kontakadresse', 'h5'),
            ...hideableContactAddress.uischema,
          ]),

          ...boxWrapper([
            ...buildTextBlock('Ggf. weitere Eigentümer/in', 'h1'),
            ...hideableAdditionalOwner.uischema,
          ]),

          ...buildNextButtonBlock(),
        ],
      ),
    ],
  },
  resultData: {
    different_address: false,
    additional_owner: false,
  },
  logic: {
    nextStepId: 'overview',
  },
}
