import { H1 } from '@epilot/components/dist'
import { LayoutProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'
import { useSelector } from 'react-redux'

import { getCart, getProductsById } from 'modules/shop/selectors'
import { getActiveStepData } from 'modules/step/selectors'

import ProductDetailsAdditionalInformation from './product-details-additional-information'
import ProductDetailsAddons from './product-details-addons'
import ProductDetailsAttachments from './product-details-attachments'
import ProductDetailsFootnote from './product-details-footnote'
import ProductDetailsPrices from './product-details-prices'
import ProductDetailsTitle from './product-details-title'

const ProductDetailsRenderer = (props: LayoutProps) => {
  const activeStepData = useSelector(getActiveStepData)
  const cart = useSelector(getCart)
  const products = useSelector(getProductsById)

  const productId = cart[0] ? cart[0].id : Object.keys(products)[0]

  if (!productId || productId === '0' || products[productId] === undefined)
    return (
      <H1>
        Bitte wählen Sie im Produktauswahl Schritt mindestens ein Produkt aus.
      </H1>
    )
  const {
    name,
    customFields,
    addons,
    description,
    price,
    image,
    attachments,
  } = products[productId]
  const {
    Claim: subtitle,
    'Beschreibung Konditionen und Zusatzinfos': conditions,
    'Titel Tarifmerkmal': tariffDetailsTitle,
    'Tarifmerkmal 01': tariffDetails1,
    'Tarifmerkmal 02': tariffDetails2,
    'Tarifmerkmal 03': tariffDetails3,
    'Tarifmerkmal 04': tariffDetails4,
    'Tarifmerkmal 05': tariffDetails5,
    'Tarifmerkmal 06': tariffDetails6,
    'Tarifmerkmal 07': tariffDetails7,
    'Titel Zusatzoptionen': addOnsTitle,
    Steuerinformationen: taxInformation,
    'Titel Konditionen und Zusatzinfos': additionalInformationTitle,
    Fußnote: footnote,
  } = customFields

  const productImage = image?.src

  const tariffDetails = [
    tariffDetails1,
    tariffDetails2,
    tariffDetails3,
    tariffDetails4,
    tariffDetails5,
    tariffDetails6,
    tariffDetails7,
  ]

  const { renderers, schema, path, enabled, uischema } = props
  const selectedAddonIds =
    activeStepData['addon_ids'] ?? activeStepData['addon_ids'].split(',')

  const showAnnualBasePrice = activeStepData['toggle_base_annual_price']
  const showTotalAnnualPrice = activeStepData['toggle_total_annual_price']
  const showShortDescription = activeStepData['toggle_short_description']

  return (
    <>
      <ProductDetailsTitle
        description={description}
        image={productImage}
        name={name}
        showShortDescription={
          showShortDescription || showShortDescription === undefined
        }
        subtitle={subtitle}
        tariffDetails={tariffDetails}
        tariffDetailsTitle={tariffDetailsTitle}
      />
      {addons.length > 0 && (
        <ProductDetailsAddons
          addons={addons}
          layoutProps={{ renderers, schema, path, enabled, uischema }}
          selectedAddonsIds={selectedAddonIds}
          title={addOnsTitle}
        />
      )}
      <ProductDetailsPrices
        price={price}
        showAnnualBasePrice={!!showAnnualBasePrice}
        showTotalAnnualPrice={!!showTotalAnnualPrice}
        taxInformation={taxInformation}
      />
      {attachments && attachments?.length > 0 && (
        <ProductDetailsAttachments attachments={attachments} />
      )}
      {(additionalInformationTitle || conditions) && (
        <ProductDetailsAdditionalInformation
          conditions={conditions}
          title={additionalInformationTitle}
        />
      )}
      {footnote && <ProductDetailsFootnote footnote={footnote} />}
    </>
  )
}

export const productDetailsRendererTester = rankWith(
  2,
  uiTypeIs('ProductDetails'),
)

export default withJsonFormsLayoutProps(ProductDetailsRenderer)
