import React from 'react'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'

import ErrorBoundary from 'ErrorBoundary'

import AppEpilot from './components/app-epilot'
import rootSaga from './store/sagas'
import createStore from './store/store'

/* ----------------------------------------------------------------------------
 * Initialize Bugsnag.com for automatically reporting unhandled error, handled
 * errors also can be reported. The ErrorBoundary catches unhandled errors and
 * renders a fallback component
 * ------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------
 * Create the saga middleware and store
 * ------------------------------------------------------------------------- */
const sagaMiddleware = createSagaMiddleware()
const store = createStore({}, {}, [sagaMiddleware])

// Initialize SAGA
sagaMiddleware.run(rootSaga)

/* ----------------------------------------------------------------------------
 * React App
 * ------------------------------------------------------------------------- */
const App = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <AppEpilot />
    </Provider>
  </ErrorBoundary>
)

// Testing: expose store when run in Cypress
declare global {
  interface Window {
    Cypress: unknown
    store: unknown
  }
}

if (window.Cypress) {
  window.store = store
}

export default App
