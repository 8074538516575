import { BlueprintStep } from 'types/blueprint-config'

import {
  blockHider,
  buildNextButtonBlock,
  buildPersonalInfoBlock,
  buildTextBlock,
} from './block-builders'

const personalInfo = buildPersonalInfoBlock()
const representativePersonalInfo = buildPersonalInfoBlock({
  salutation: { name: 'representative_salutation' },
  title: { name: 'representative_title' },
  first_name: { name: 'representative_first_name' },
  last_name: { name: 'representative_last_name' },
  user_email: { name: 'representative_user_email' },
  telephone: { name: 'representative_telephone' },
})

const hideableRepresentative = blockHider('different_representative', [
  ...buildTextBlock('Untertitel Kontaktangaben', 'LabelH3').uischema,
  ...representativePersonalInfo.uischema,
  ...buildTextBlock('Untertitel Kontaktadresse', 'LabelH3').uischema,
  // Address Here
])

const hideableBillingAddress = blockHider('different_billing_address', [
  ...buildTextBlock('Adresse', 'LabelH3').uischema,
  // Address Here
])

export const addressInformation: BlueprintStep = {
  id: 'address_information',
  title: 'A title',
  nextButtonLabel: 'Next',
  schema: {
    type: 'object',
    properties: {
      ...personalInfo.schema,
      ...hideableBillingAddress.schema,
      ...hideableRepresentative.schema,
      ...representativePersonalInfo.schema,
    },
    required: [...(personalInfo.required ? personalInfo.required : [])],
  },
  uischema: {
    type: 'VerticalLayout',
    elements: [
      ...buildTextBlock('Kontakt Formular Titel', 'LabelH1').uischema,
      // FIRST BLOCK
      ...buildTextBlock('First block text', 'LabelH2').uischema,
      ...buildTextBlock('KontaktAngaben', 'LabelH3').uischema,
      ...personalInfo.uischema,
      ...buildTextBlock('KontaktAdresse', 'LabelH3').uischema,

      // SECOND BLOCK
      ...buildTextBlock('Second block text', 'LabelH2').uischema,
      ...hideableBillingAddress.uischema,
      // Address Here

      // THIRD BLOCK
      ...buildTextBlock('Third block text', 'LabelH2').uischema,
      ...hideableRepresentative.uischema,

      // NEXT BUTTON
      ...buildNextButtonBlock().uischema,
    ],
  },
  resultData: {},
  logic: {
    nextStepId: 'thank_you',
  },
}
