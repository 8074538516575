import { styled } from '@epilot/components'

const CartItemStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4px 16px 8px 16px;
  width: 100%;

  .icon {
    align-items: center;
    border-radius: 50%;
    background-color: #eff2f3;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;
  }
  .icon.addon-icon {
    background-color: transparent;
  }
  .icon > svg {
    height: 16px;
    width: 16px;
  }
  .title {
    flex: 1;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
  }
  .title > p {
    line-height: 18px;
  }
  .price {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    text-align: right;
    min-width: 120px;
  }
  .price > p {
    line-height: 18px;
  }
  .price > p > span {
    font-weight: 400;
  }
  > img {
    max-width: 32px;
    align-self: center;
    border-radius: 2px;
  }
`

export default CartItemStyled
