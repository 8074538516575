import { StepConnector, Theme } from '@epilot/base-elements'
import { withStyles } from '@material-ui/styles'

const connectorStyles = (theme: Theme) => ({
  active: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 1,
  },
})

export const CustomConnector = withStyles(connectorStyles)(StepConnector)
