import { BlueprintElements } from 'types/blueprint-config'

export const buildEmptySpaceBlock = (): BlueprintElements[] => {
  const uischema: BlueprintElements[] = [
    {
      type: 'GridItemLayout',
      options: {
        xs: 12,
        sm: 6,
      },
      elements: [],
    },
  ]

  return uischema
}
