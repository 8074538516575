import {
  DisplayCartItem,
  Product,
  ShopState,
  ProductCategory,
  Addon,
} from 'modules/shop/types'
import { GlobalAppState } from 'modules/types'

const getState = (state: GlobalAppState): ShopState => state.shop

const cart = (state: GlobalAppState) => getState(state).cart
const products = (state: GlobalAppState) => getState(state).products
const productsById = (state: GlobalAppState) => getState(state).productsById
const productPriceInterval = (state: GlobalAppState) =>
  getState(state).productPriceInterval
const productSettings = (state: GlobalAppState) =>
  getState(state).productSettings
const productLoadWithoutCondition = (state: GlobalAppState) =>
  getState(state).loadProductWithoutCondition
const productPricingBusiness = (state: GlobalAppState) =>
  getState(state).productPricingBusiness
const productApi = (state: GlobalAppState) => getState(state).productApi
const productApiV2 = (state: GlobalAppState) => {
  return getState(state).productApiV2
}
const productAvailabilityApi = (state: GlobalAppState) =>
  getState(state).productAvailabilityApi
const showCart = (state: GlobalAppState) => getState(state).showCart
const showCartWithItems = (state: GlobalAppState) =>
  getState(state).showCartWithItems

const enetApi = (state: GlobalAppState) => getState(state).enetApi

const monitorFieldsLastValue = (state: GlobalAppState) =>
  getState(state).monitorFieldsLastValue

/**
 * Getters
 */

// Complete shop state
export const getShop = (state: GlobalAppState) => getState(state)

export const getMonitorFieldsLastValue = (state: GlobalAppState) =>
  monitorFieldsLastValue(state)

// Cart
export const getCart = (state: GlobalAppState) => cart(state)
export const getShowCart = (state: GlobalAppState) => showCart(state)
export const getShowCartWithItems = (state: GlobalAppState) =>
  showCartWithItems(state)

export const isCartVisible = (state: GlobalAppState) => {
  const showCart = getShowCart(state)
  const showCartWithItems = getShowCartWithItems(state)
  const cart = getCart(state)

  return showCart || (showCartWithItems && cart.length > 0)
}

export const getCartTotalValue = (state: GlobalAppState) => {
  const cart = getCartWithDetails(state)
  const pricingBusiness = getProductPricingBusiness(state)
  let totalValue = 0

  cart.forEach((item) => {
    const value = pricingBusiness
      ? item.price?.estimated_monthly_price?.amount_net || 0
      : item.price?.estimated_monthly_price?.amount_gross || 0

    if (value) {
      totalValue += value
    }
  })

  return totalValue
}

export const getCartOneTimeTotalValue = (state: GlobalAppState) => {
  const cart = getCartWithDetails(state)
  const pricingBusiness = getProductPricingBusiness(state)

  return cart.reduce(
    (previous, current) =>
      previous +
      (pricingBusiness
        ? current.price?.one_time_price?.amount_net || 0
        : current.price?.one_time_price?.amount_gross || 0),
    0,
  )
}

export const getCartTotalSummary = (state: GlobalAppState) => {
  const cart = getCartWithDetails(state)

  return cart.reduce(
    (summery, current) => {
      const addonPrices = current.addons?.reduce(
        (addonsSummery, currentAddon) => {
          return {
            addonsNet:
              addonsSummery.addonsNet +
              (currentAddon.price?.recurring_price?.monthly?.amount_net || 0) *
                (currentAddon.quantity || 1),
            addonsVat:
              addonsSummery.addonsVat +
              (currentAddon.price?.recurring_price?.monthly?.amount_vat || 0) *
                (currentAddon.quantity || 1),
            addonsGross:
              addonsSummery.addonsGross +
              (currentAddon.price?.recurring_price?.monthly?.amount_gross ||
                0) *
                (currentAddon.quantity || 1),
            addonsOneTimeNet:
              addonsSummery.addonsOneTimeNet +
              (currentAddon.price?.one_time_price?.amount_net || 0) *
                (currentAddon.quantity || 1),
            addonsOneTimeGross:
              addonsSummery.addonsOneTimeGross +
              (currentAddon.price?.one_time_price?.amount_gross || 0) *
                (currentAddon.quantity || 1),
          }
        },
        {
          addonsNet: 0,
          addonsVat: 0,
          addonsGross: 0,
          addonsOneTimeNet: 0,
          addonsOneTimeGross: 0,
        },
      )

      return {
        net:
          summery.net +
          (current.price?.estimated_monthly_price?.amount_net || 0) +
          (addonPrices?.addonsNet || 0),
        gross:
          summery.gross +
          (current.price?.estimated_monthly_price?.amount_gross || 0) +
          (addonPrices?.addonsGross || 0),
        vat:
          summery.vat +
          (current.price?.estimated_monthly_price?.amount_gross || 0) -
          (current.price?.estimated_monthly_price?.amount_net || 0) +
          (addonPrices?.addonsVat || 0),
        oneTimeNet:
          summery.oneTimeNet +
          (current.price?.one_time_price?.amount_net || 0) +
          (addonPrices?.addonsOneTimeNet || 0),
        oneTimeGross:
          summery.oneTimeGross +
          (current.price?.one_time_price?.amount_gross || 0) +
          (addonPrices?.addonsOneTimeGross || 0),
        vatPct: Math.max(
          summery.vatPct,
          current.price?.estimated_monthly_price?.vat_pct || 0,
          current.price?.one_time_price?.vat_pct || 0,
          current.price?.calculated_consumption_price?.vat_pct || 0,
        ),
      }
    },
    { net: 0, vat: 0, gross: 0, oneTimeNet: 0, oneTimeGross: 0, vatPct: 0 },
  )
}

const emptyCart = [] as []

export const getCartWithDetails = (state: GlobalAppState) => {
  if (getProducts(state).length === 0) {
    return emptyCart
  }

  const cart = getCart(state)
  const productsById = getProductsById(state)
  const productPriceInterval = getProductPriceInterval(state)

  // Add product details and informations
  const detailsCart = [] as DisplayCartItem[]

  cart.forEach((item) => {
    const product = productsById[item.id]

    if (!product)
      return console.error('Cart item id not found in products - id:', item.id)

    const cartDetails = {
      ...item,
      custom_fields: product.customFields,
      name: product.name,
      description: product.description,
      image_src: product.image?.thumbnail_src,
    } as DisplayCartItem

    const { price, productCategory } = product

    if (price) {
      let grossPrice =
        productCategory?.toLocaleUpperCase() === 'BROADBAND'
          ? price?.one_time_price?.amount_gross || 0
          : price?.estimated_yearly_price?.amount_gross
      let netPrice =
        productCategory?.toLocaleUpperCase() === 'BROADBAND'
          ? price?.one_time_price?.amount_net || 0
          : price?.estimated_yearly_price?.amount_net

      // Default calculated price is per YEAR
      if (
        productPriceInterval === 'MONTHLY' &&
        productCategory?.toUpperCase() !== 'BROADBAND'
      ) {
        grossPrice = price?.estimated_monthly_price?.amount_gross
        netPrice = price?.estimated_monthly_price?.amount_net
      }

      cartDetails.price = {
        ...price,
        gross: grossPrice || 0,
        net: netPrice || 0,
      }

      const productAddons = getAddonsFromProductsInCart(state)
      const currentProductAddon = productAddons.filter(
        (p) => p.productId === item.id,
      )[0]

      cartDetails.addons = cartDetails.addons?.map((addonItem) => {
        const addonIndex = currentProductAddon?.addons?.findIndex(
          (addon) => addon.id.toString() === addonItem.id,
        )

        const name = currentProductAddon?.addons[addonIndex]?.name
        const price = currentProductAddon?.addons[addonIndex]?.options[0]
        const image =
          currentProductAddon?.addons[addonIndex]?.images?.[0]?.thumbnail_src

        return { ...addonItem, name, price, image_src: image }
      })
    }

    detailsCart.push(cartDetails)
  })

  return detailsCart
}

// Product
export const getProducts = (state: GlobalAppState) => products(state)
export const getProductsById = (state: GlobalAppState) => productsById(state)
export const getProductSettings = (state: GlobalAppState) =>
  productSettings(state)
export const getProductPricingBusiness = (state: GlobalAppState) =>
  productPricingBusiness(state)
export const getProductApi = (state: GlobalAppState) => productApi(state)
export const getProductApiV2 = (state: GlobalAppState) => productApiV2(state)
export const getProductAvailabilityApi = (state: GlobalAppState) =>
  productAvailabilityApi(state)
export const getProductPriceInterval = (state: GlobalAppState) =>
  productPriceInterval(state)
export const getProductLoadWithoutCondition = (state: GlobalAppState) =>
  productLoadWithoutCondition(state)
export const getProductPriceIntervalLabel = (state: GlobalAppState) => {
  const interval = productPriceInterval(state)

  return interval === 'MONTHLY' ? 'Monat' : 'Jahr'
}
export const getEnetApi = (state: GlobalAppState) => enetApi(state)

export const getProductsByCategory = (
  state: GlobalAppState,
  category: ProductCategory,
): Product[] => {
  const products = getProducts(state)
  const categoryProducts: Product[] = []

  products.forEach((product) => {
    if (product.productCategory === category.toUpperCase())
      categoryProducts.push(product)
  })

  return categoryProducts
}

export const getAddonsFromProductsInCart = (
  state: GlobalAppState,
): { productId: string; addons: Addon[] }[] => {
  const products = getProducts(state)
  const cart = getCart(state)
  const cartProductIds = cart.map((product) => product.id)
  const productsInCart = products.filter(({ id }) =>
    cartProductIds.includes(id),
  )

  return productsInCart.map(({ id, addons }) => ({ productId: id, addons }))
}

export const getProductById = (state: GlobalAppState, id: string): Product => {
  return getProductsById(state)[id]
}
