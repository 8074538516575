// import { PricingComponent } from 'lib/api.types'
import { ObjectKeyString } from 'lib/types'
import { GlobalResultData } from 'modules/step/types'

// import { PriceTimeUnit, Product } from './types'

export const getScopedProductData = (
  productsScope: string[],
  data: GlobalResultData,
) => {
  const values = {} as ObjectKeyString<any>

  const match = productsScope.reduce((previousValue, propertyName) => {
    const value = getValueByPropertyName(propertyName, data)

    if (previousValue && value) {
      values[propertyName] = value

      return true
    }

    return false
  }, true)

  if (match) {
    return values
  }
}

export const hasChangedMonitorFields = (
  monitorValues: ObjectKeyString<unknown>,
  data: GlobalResultData,
) => {
  const monitorFields = Object.keys(monitorValues)

  if (monitorFields.length === 0) return true

  return monitorFields.reduce(
    (previousValue, fieldName) =>
      previousValue || monitorValues[fieldName] !== data[fieldName],
    false,
  )
}

export const getValueByPropertyName = (
  propertyName: string,
  data: GlobalResultData,
) => {
  const propertyNameParts = propertyName.split('.')
  let value = null

  if (propertyNameParts.length === 2) {
    const firstLevel = data[propertyNameParts[0]]

    value = firstLevel ? firstLevel[propertyNameParts[1]] : null
  } else {
    value = data[propertyName]
  }

  return value
}
export const justUppercaseAtFirstCharacter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
