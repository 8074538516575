import { BlueprintElements } from 'types/blueprint-config'

import { BuildingBlock } from './types'

type ChoiceBlockValues = {
  scope: string
  choices: string[]
  required: boolean
}

const defaultChoiceValues: ChoiceBlockValues = {
  scope: 'A choice',
  choices: ['option1', 'option2', 'option3'],
  required: false,
}

export const buildChoiceBlock = (
  choiceValues?: ChoiceBlockValues,
): BuildingBlock => {
  const values = choiceValues || defaultChoiceValues
  const schema = {
    [values.scope]: {
      type: 'string',
      enum: values.choices,
      errorMessage: 'Please select one of the options',
    },
  }

  const uischema: BlueprintElements[] = [
    {
      type: 'GridItemLayout',
      options: {
        xs: 12,
        sm: 6,
      },
      elements: [
        {
          type: 'Control',
          scope: `#/properties/${values.scope}`,
          options: {
            toggle: true,
          },
        },
      ],
    },
  ]

  const required = values.required ? [values.scope] : []

  return {
    schema,
    uischema,
    required,
  }
}
