import { JsonFormsRendererRegistryEntry } from '@jsonforms/core'

import {
  ButtonLayoutRenderer,
  buttonLayoutRendererTester,
  ButtonRenderer,
  buttonRendererTester,
} from './button'
import { CartRenderer, cartRendererTester } from './cart'
import {
  ConfirmationMessageRenderer,
  confirmationMessageTester,
} from './confirmation-message'
import {
  DataLabelRenderer,
  dataLabelRendererTester,
  DynamicDataLabelRenderer,
  dynamicDataLabelRendererTester,
} from './data-label'
import { dataLoggerRendererTester, DataLoggerRenderer } from './data-logger'
import {
  ForwardSummaryTileRenderer,
  forwardSummaryTileTester,
} from './forward-summary-tile'
import { MeterReadingRenderer, meterReadingTester } from './meter-reading'
import {
  OverviewScreenshotRendererTester,
  OverviewScreenshotRenderer,
} from './overview-screenshot'
import { ProductDetailRenderer, productDetailTester } from './product-detail'
import {
  ProductSelectionRenderer,
  productSelectionTester,
} from './product-selection'
import { SpinnerRenderer, spinnerRendererTester } from './spinner'
import { StepContentRenderer, stepContentTester } from './step-content'
import {
  stepperNavigationTester,
  StepperNavigationRenderer,
} from './stepper-navigation'
import { SummaryTileRenderer, summaryTileTester } from './summary-tile'
export const createRenderer = (): JsonFormsRendererRegistryEntry[] => {
  const renderer: JsonFormsRendererRegistryEntry[] = []

  // Layout Renderers
  // Stepper Navigation
  renderer.push({
    tester: stepperNavigationTester,
    renderer: StepperNavigationRenderer,
  })
  // Step Content
  renderer.push({
    tester: stepContentTester,
    renderer: StepContentRenderer,
  })
  // Forward Summary
  renderer.push({
    tester: forwardSummaryTileTester,
    renderer: ForwardSummaryTileRenderer,
  })
  // Product Detail
  renderer.push({
    tester: productDetailTester,
    renderer: ProductDetailRenderer,
  })
  // Summary
  renderer.push({
    tester: summaryTileTester,
    renderer: SummaryTileRenderer,
  })
  // Thank You
  renderer.push({
    tester: confirmationMessageTester,
    renderer: ConfirmationMessageRenderer,
  })
  // Cart
  renderer.push({
    tester: cartRendererTester,
    renderer: CartRenderer,
  })
  // Data Label
  renderer.push({
    tester: dataLabelRendererTester,
    renderer: DataLabelRenderer,
  })
  renderer.push({
    tester: dynamicDataLabelRendererTester,
    renderer: DynamicDataLabelRenderer,
  })

  // Control Renderers
  // Product Selection
  renderer.push({
    tester: productSelectionTester,
    renderer: ProductSelectionRenderer,
  })
  // Meter Reading
  renderer.push({
    tester: meterReadingTester,
    renderer: MeterReadingRenderer,
  })
  // Button
  renderer.push({
    tester: buttonRendererTester,
    renderer: ButtonRenderer,
  })
  // Button Layout
  // Button was moved from Layout renderer to Control renderer with enet changes
  // This could generate backward incompatibilities and errors when rendering JSON Forms
  // For that reason, same component was created with as it was before moving to Control Renderer
  // To use Button as a control, use { control: true } in the options of the uischema
  renderer.push({
    tester: buttonLayoutRendererTester,
    renderer: ButtonLayoutRenderer,
  })
  // Spinner
  renderer.push({
    tester: spinnerRendererTester,
    renderer: SpinnerRenderer,
  })
  renderer.push({
    tester: OverviewScreenshotRendererTester,
    renderer: OverviewScreenshotRenderer,
  })

  renderer.push({
    tester: dataLoggerRendererTester,
    renderer: DataLoggerRenderer,
  })

  return renderer
}
