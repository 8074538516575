import { GlobalResultData } from 'modules/step/types'

import {
  getSalutationFromLabel,
  getTitleFromLabel,
} from './getMappedTitleAndSautation'
export const getInputDataFromMapping = (
  mapping: string,
  data: GlobalResultData,
) => {
  // split string with object notation to array
  const dataParams = mapping.split('.')
  const param1 = dataParams[1]
  const param2 = dataParams[2]

  // intercept when CONTACT_FORM - needs billing AND delivery address
  if (data['journey_type'] && data['journey_type'] === 'CONTACT_FORM') {
    const value = getContactFormInputData(dataParams, data)

    // return only if field has been intercepted, otherwise continiue going through default mapping
    if (typeof value !== 'undefined') return value
  }

  switch (param1) {
    case 'address_billing_street_name':
      if (data['different_billing_address']) {
        return data['address_billing_street_name']
      } else {
        return data['address_delivery_street_name']
          ? data['address_delivery_street_name']
          : data['address_installation_street_name']
      }
    case 'address_billing_house_number':
      if (data['different_billing_address']) {
        return data['address_billing_house_number']
      } else {
        return data['address_delivery_house_number']
          ? data['address_delivery_house_number']
          : data['address_installation_house_number']
      }
    case 'address_billing_postalcode':
      if (data['different_billing_address']) {
        return data['address_billing_postalcode']
      } else {
        return data['address_delivery_postalcode_city']
          ? data['address_delivery_postalcode_city']['postalcode']
          : data['address_delivery_postalcode']
          ? data['address_delivery_postalcode']
          : data['address_installation_postalcode']
      }
    case 'address_billing_city':
      if (data['different_billing_address']) {
        return data['address_billing_city']
      } else {
        return data['address_delivery_postalcode_city']
          ? data['address_delivery_postalcode_city']['city']
          : data['address_delivery_city']
          ? data['address_delivery_city']
          : data['address_installation_city']
      }
    case 'address_delivery_postalcode_city':
      return param2
        ? data['address_delivery_postalcode_city'][param2]
        : data['address_delivery_postalcode_city']
    case 'match_address_plz':
      if (data['different_billing_address']) {
        return param2
          ? data['match_address_plz'][param2]
          : data['match_address_plz']
      } else {
        return param2
          ? data['address_delivery_postalcode_city'][param2]
          : data['address_delivery_postalcode_city']
      }
    case 'number_portability_type':
      return data['number_portability_type'] === 'terminateContract'
        ? 'Mit Kündigung des Anschlusses'
        : data['number_portability_type'] === 'terminated'
        ? 'Den Anschluss habe ich bereits selber gekündigt.'
        : typeof data['number_portability_type'] === 'undefined'
        ? null
        : 'Ohne Kündigung des Anschlusses'
    case 'using_phone_number_type':
      return data['using_phone_number_type'] === 'all_connection_phone_number'
        ? 'Alle Rufnummern des Anschlusses übernehmen.'
        : data['using_phone_number_type'] === 'main_phone_number'
        ? 'Nur die Haupt- und folgende Rufnummer(n) übernehmen.'
        : typeof data['using_phone_number_type'] === 'undefined'
        ? null
        : 'Einen Rufnummerblock übernehmen.'
    case 'next_delivery_date':
      return typeof data['next_delivery_date'] === 'undefined' ? 'Nein' : 'Ja'
    case 'installations_performance':
      return data['installations_performance'][param2]
    case 'type_of_feed_in':
      return data['type_of_feed_in'] === 'FullFeedIn'
        ? 'Volleinspeisung'
        : data['type_of_feed_in'] === 'SurplusFeedIn'
        ? 'Überschusseinspeisung'
        : typeof data['type_of_feed_in'] === 'undefined'
        ? null
        : 'Keine Einspeisung'
    // GGNV mapping
    case 'contact_address':
      return data[param1] && data[param1][param2]
        ? data[param1][param2]
        : data['address'][param2]
    // PowerV2
    case 'billing_address':
      if (data['different_billing_address']) {
        return data[param1][param2]
      } else {
        return data['postalcode_city'][param2]
      }
  }

  return data[param1] && param2 ? data[param1][param2] : data[param1]
}

const getContactFormInputData = (split: string[], data: GlobalResultData) => {
  switch (split[1]) {
    // BILLING DETAILS
    case 'address_billing_postalcode_city':
      if (split[2] === 'city') {
        return data['address_billing_postalcode_city'] &&
          data['address_billing_postalcode_city']['city']
          ? data['address_billing_postalcode_city']['city']
          : ''
      } else if (split[2] === 'postalcode') {
        return data['address_billing_postalcode_city'] &&
          data['address_billing_postalcode_city']['postalcode']
          ? data['address_billing_postalcode_city']['postalcode']
          : ''
      }

      return ''

    case 'address_billing_street_name':
      return data['address_billing_street_name'] || ''

    case 'address_billing_house_number':
      return data['address_billing_house_number'] || ''

    case 'address_billing_addition':
      return data['address_billing_addition'] || ''

    case 'title':
      return data['title'] || ''

    case 'salutation':
      return data['salutation'] || ''

    // DELIVERY DETAILS - choose delivery address if exists, otherwise get billing address. Else, empty
    case 'address_delivery_postalcode_city':
      if (split[2] === 'city') {
        if (data['different_billing_address']) {
          return data['address_delivery_postalcode_city'] &&
            data['address_delivery_postalcode_city']['city']
            ? data['address_delivery_postalcode_city']['city']
            : ''
        } else {
          return data['address_billing_postalcode_city'] &&
            data['address_billing_postalcode_city']['city']
            ? data['address_billing_postalcode_city']['city']
            : ''
        }
      } else if (split[2] === 'postalcode') {
        if (data['different_billing_address']) {
          return data['address_delivery_postalcode_city'] &&
            data['address_delivery_postalcode_city']['postalcode']
            ? data['address_delivery_postalcode_city']['postalcode']
            : ''
        } else {
          return data['address_billing_postalcode_city'] &&
            data['address_billing_postalcode_city']['postalcode']
            ? data['address_billing_postalcode_city']['postalcode']
            : ''
        }
      }

      return ''

    case 'address_delivery_street_name':
      if (data['different_billing_address']) {
        return data['address_delivery_street_name'] || ''
      } else {
        return data['address_billing_street_name'] || ''
      }

    case 'address_delivery_house_number':
      if (data['different_billing_address']) {
        return data['address_delivery_house_number'] || ''
      } else {
        return data['address_billing_house_number'] || ''
      }

    case 'address_delivery_addition':
      if (data['different_billing_address']) {
        return data['address_delivery_addition'] || ''
      } else {
        return data['address_billing_addition'] || ''
      }

    // Representative - additional address
    case 'representative_contact_street_name':
      if (data['different_representative']) {
        return data['representative_contact_street_name'] || ''
      } else {
        return data['address_billing_street_name'] || ''
      }

    case 'representative_contact_street_number':
      if (data['different_representative']) {
        return data['representative_contact_street_number'] || ''
      } else {
        return data['address_billing_house_number'] || ''
      }

    case 'address_representative_addition':
      if (data['different_representative']) {
        return data['address_representative_addition'] || ''
      } else {
        return data['address_billing_addition'] || ''
      }

    // choose delivery address if exists, otherwise get billing address. Else, empty
    case 'representative_contact_postalcode_city':
      if (split[2] === 'city') {
        if (data['different_representative']) {
          return data['representative_contact_postalcode_city'] &&
            data['representative_contact_postalcode_city']['city']
            ? data['representative_contact_postalcode_city']['city']
            : ''
        } else {
          return data['address_billing_postalcode_city'] &&
            data['address_billing_postalcode_city']['city']
            ? data['address_billing_postalcode_city']['city']
            : ''
        }
      } else if (split[2] === 'postalcode') {
        if (data['different_representative']) {
          return data['representative_contact_postalcode_city'] &&
            data['representative_contact_postalcode_city']['postalcode']
            ? data['representative_contact_postalcode_city']['postalcode']
            : ''
        } else {
          return data['address_billing_postalcode_city'] &&
            data['address_billing_postalcode_city']['postalcode']
            ? data['address_billing_postalcode_city']['postalcode']
            : ''
        }
      }

      return ''

    case 'representative_contact_salutation':
      if (data['different_representative']) {
        return getSalutationFromLabel(
          data['representative_contact_salutation'] || '',
        )
      } else {
        return getSalutationFromLabel(data['salutation'] || '')
      }

    case 'representative_contact_title':
      if (data['different_representative']) {
        return getTitleFromLabel(data['representative_contact_title'] || '')
      } else {
        return getTitleFromLabel(data['title'] || '')
      }

    case 'representative_contact_first_name':
      if (data['different_representative']) {
        return data['representative_contact_first_name'] || ''
      } else {
        return data['first_name'] || ''
      }

    case 'representative_contact_last_name':
      if (data['different_representative']) {
        return data['representative_contact_last_name'] || ''
      } else {
        return data['last_name'] || ''
      }

    case 'representative_contact_email':
      if (data['different_representative']) {
        return data['representative_contact_email'] || ''
      } else {
        return data['email'] || ''
      }

    case 'representative_contact_phone':
      if (data['different_representative']) {
        return data['representative_contact_phone'] || ''
      } else {
        return data['phone_number'] || ''
      }

    default:
      return undefined
  }
}
