import React from 'react'

import { InstallationsStyled } from '../installation-module-control/installations-performance-module-styled'
import { Installation, InstallationType } from '../types'

import { generateInstallations } from './buildInstallationPerformance'

type InstallationsPerformanceType = {
  showErrors?: boolean
  type: InstallationType
  handleChangeInstallation: (index: number, newValue: Installation) => void
  handleRemoveInstallation: (index: number) => void
  installations: Installation[]
  commissioningInstallationsLabel?: string
  commissioningInstallationsOptions?: string[]
}

const InstallationsPerformance = ({
  showErrors,
  handleChangeInstallation,
  handleRemoveInstallation,
  installations,
}: InstallationsPerformanceType): JSX.Element => {
  const showRemoveIcon = installations && installations.length > 1

  return (
    <InstallationsStyled>
      {generateInstallations({
        handleChangeInstallation,
        handleRemoveInstallation,
        installations,
        showRemoveIcon,
        showErrors,
      })}
    </InstallationsStyled>
  )
}

export default InstallationsPerformance
