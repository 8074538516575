import config from 'config'
import { ShopState } from 'modules/shop/types'

import { Blueprint } from '../../types/blueprint-config'

import { results } from './config/results'
import { shop } from './config/shop'
import {
  consumptionCalculator,
  thankYou,
  productOverviewPower,
  productDetailPower,
  productOverviewGas,
  productDetailGas,
  personalInformation,
  contractCancellation,
  contractCancellationPower,
  contractCancellationGas,
  paymentInformation,
  summary,
  productUnavailabilityPower,
  productUnavailabilityGas,
  error,
} from './config/steps'
// Alternative styling for testing purposes
// import { dew21Styling } from './config/styling'
import { epilotStyling } from './config/styling'

export const PowerAndGas: Blueprint = {
  widgetId: 'power-and-gas-blueprint',
  initialStepId: consumptionCalculator.id,
  // epilot staging organization ID
  // organizationId: '728',
  // epilot internal organization ID
  // organizationId: '3718238',
  organizationId: config.PREVIEW_ORGANIZATION,
  shop: shop as Partial<ShopState>,
  // Alternative stylings just for testing purposes
  // styling: dew21Styling,
  styling: epilotStyling,
  steps: [
    consumptionCalculator,
    productUnavailabilityPower,
    productOverviewPower,
    productDetailPower,
    productUnavailabilityGas,
    productOverviewGas,
    productDetailGas,
    personalInformation,
    contractCancellation,
    contractCancellationPower,
    contractCancellationGas,
    paymentInformation,
    summary,
    thankYou,
    error,
  ],
  results,
  builder: {
    useBaseElementsLibrary: true,
    brand_id: 7066103,
  },
}
