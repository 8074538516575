import { BodyText } from '@epilot/components'
import React from 'react'

type RadioProps = {
  label: string
  subLabel?: string
}

const Radio = ({ label, subLabel }: RadioProps): React.ReactElement => {
  const subLabelWrapper =
    typeof subLabel === 'object' ? subLabel : <BodyText>{subLabel}</BodyText>

  return (
    <>
      <BodyText>{label}</BodyText>
      {subLabel ? subLabelWrapper : null}
    </>
  )
}

export default Radio
