import { Box, CircularProgress } from '@epilot/base-elements'
import { renderers } from '@epilot/json-renderers'
import { JsonFormsCore } from '@jsonforms/core'
import { JsonForms } from '@jsonforms/react'
import { vanillaRenderers, vanillaCells } from '@jsonforms/vanilla-renderers'
import { ErrorObject } from 'ajv'
import { deepEqual } from 'fast-equals'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Error } from 'components/error'
import { createRenderer } from 'components/renderer-v2'
import { setActiveStepErrors, setInputValue } from 'modules/step/reducer'
import {
  getActiveStep,
  getActiveStepErrors,
  getError,
  getIsFirstStep,
  getIsLoading,
  getShowErrors,
  getSteps,
} from 'modules/step/selectors'
import { Step } from 'modules/step/types'

import config from '../../config'
import { useDataDog } from '../hooks/useDataDog'
import { useIsDirty } from '../hooks/useIsDirty'

import { initAjv } from './ajv-validation'

const epilotRendererV2 = [
  ...vanillaRenderers,
  ...createRenderer(),
  ...renderers,
]
const epilotCellsV2 = [...vanillaCells]
const ajv = initAjv('de')

const StepHandlerV2 = ({
  postalCodeCityValue,
  setPostalCodeCityValue,
}: {
  postalCodeCityValue: Record<string, any> | null | undefined
  setPostalCodeCityValue: React.Dispatch<
    React.SetStateAction<Record<string, any> | null | undefined>
  >
}): JSX.Element => {
  const activeStep = useSelector(getActiveStep)
  const isInitialStep = useSelector(getIsFirstStep)
  const steps = useSelector(getSteps)
  const activeStepErrors = useSelector(getActiveStepErrors)
  const isLoadingStep = useSelector(getIsLoading)
  const error = useSelector(getError)
  const showErrors = useSelector(getShowErrors)
  const [stepErrors, setStepErrors] = useState<ErrorObject[] | undefined>(
    activeStepErrors,
  )
  const [step, setStep] = useState<Step | null>(null)
  const [stepData, setStepData] = useState({ ...step?.resultData })
  const dispatch = useDispatch()

  useEffect(() => {
    // Re-render step if active step change
    if (
      activeStep.id !== step?.id ||
      !deepEqual(step?.resultData, activeStep?.resultData)
    ) {
      setStep(activeStep)

      // Scroll to the top on loading new step
      const reactRootElement = document.getElementById('react-root')

      if (reactRootElement) {
        if (config.IS_IN_IFRAME && config.AUTO_RESIZE && !isInitialStep) {
          reactRootElement.scrollIntoView()
        } else {
          reactRootElement.scrollTop = 0
        }
      }
    }
  }, [activeStep.id, step])

  // If global data change set data
  useEffect(() => {
    if (!deepEqual(activeStep.resultData, stepData)) {
      if (config.PREVIEW_MODE) {
        setStepData((prevStepData) => ({
          ...prevStepData,
          ...activeStep.resultData,
        }))

        return
      }
      if (step == null) {
        //first page
        setStepData(activeStep.resultData)
      } else if (activeStep.id !== step?.id) {
        //change steps
        const activeStepIndex = Object.keys(steps).indexOf(activeStep.id)
        const nextStepIndex = Object.keys(steps).indexOf(step.id)

        // when go next step, use the data includes all the newest status and merge the previous data to next step
        // when go previous next, use the active-step's result data
        if (nextStepIndex < activeStepIndex) {
          // if going to PI screen on P&G, check if value from
          // consumption calculator screen should be injected
          // ref: https://e-pilot.atlassian.net/browse/ER-2444?focusedCommentId=46641
          if (activeStep.id === 'personal_information') {
            setStepData((prevStepData) => {
              const data = {
                ...activeStep.resultData,
                ...prevStepData,
              }

              // merge only if it does not exist
              if (!data.postalcode_city) {
                return {
                  ...data,
                  postalcode_city: postalCodeCityValue,
                }
              }

              return data
            })
          } else {
            setStepData((prevStepData) => ({
              ...activeStep.resultData,
              ...prevStepData,
            }))
          }
        } else if (nextStepIndex > activeStepIndex) {
          setStepData(activeStep.resultData)
        }
      } else {
        //current step data change
        setStepData((prevStepData) => ({
          ...prevStepData,
          ...activeStep.resultData,
        }))

        // save postalcode city value from consumption calculator screen for later injection
        // ref: https://e-pilot.atlassian.net/browse/ER-2444?focusedCommentId=46641
        if (activeStep.id === 'consumption_calculator') {
          setPostalCodeCityValue(
            activeStep.resultData['postalcode_city'] || undefined,
          )
        }
      }
    }
  }, [activeStep.resultData])
  useEffect(() => {
    dispatch(setActiveStepErrors(stepErrors))
  }, [dispatch, activeStep.id, stepErrors])

  useEffect(() => {
    if (
      step &&
      !deepEqual(step.schema, { ...step.schema, customErrorMessages: true })
    )
      setStep((currentStep) => {
        if (currentStep)
          return {
            ...currentStep,
            schema: { ...currentStep.schema, customErrorMessages: true },
          }

        return currentStep
      })
  }, [step?.schema])

  const handleChange = ({
    data,
    errors,
  }: Pick<JsonFormsCore, 'data' | 'errors'>): void => {
    const dataReduced = { ...data }

    setStepErrors(errors)
    dispatch(setInputValue(dataReduced, errors))
  }

  const isDirty = useIsDirty(
    Object.keys(steps).map((key) => steps[key].resultData),
  )

  useDataDog(isDirty && !activeStep?.resultData?.previewMode)

  if (error) return <Error />

  if (isLoadingStep || !step)
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    )

  return (
    <JsonForms
      ajv={ajv}
      cells={epilotCellsV2}
      data={stepData}
      key={step.id}
      onChange={handleChange}
      renderers={epilotRendererV2}
      schema={step.schema}
      uischema={step.uischema}
      validationMode={showErrors ? 'ValidateAndShow' : 'ValidateAndHide'}
    />
  )
}

export default StepHandlerV2
