import {
  BodyText,
  Checkbox,
  CheckboxV1,
  MuiFormHelperText,
  Row,
} from '@epilot/components'
import {
  and,
  ControlProps,
  ControlState,
  or,
  OwnPropsOfControl,
  RankedTester,
  rankWith,
  schemaTypeIs,
  uiTypeIs,
} from '@jsonforms/core'
import { Control, withJsonFormsControlProps } from '@jsonforms/react'
import React, { createRef } from 'react'
import { connect } from 'react-redux'

import { getShowErrors } from 'modules/step/selectors'
import { ControlElementEpilot } from 'modules/step/types'
import { GlobalAppState } from 'modules/types'

import { parseTemplate } from '../../../lib'

type CheckboxControlState = {
  selected: { [key: string]: boolean }
  selection?: boolean
}

class CheckboxControl extends Control<
  ControlProps & OwnPropsOfControl & { showErrors?: typeof getShowErrors },
  ControlState & CheckboxControlState
> {
  checkboxRef = createRef() as React.RefObject<HTMLDivElement>

  handleChangeLocal(key: string, selection: boolean): void {
    const { selected } = this.state
    const { path, handleChange } = this.props

    if (key === 'single') {
      this.setState({ selection })

      return handleChange(path, selection)
    }

    const newState: CheckboxControlState = { selected: { ...selected } }

    newState.selected[key] = selection

    this.setState(newState, () => {
      handleChange(path, this.state.selected)
    })
  }

  render(): React.ReactElement {
    const {
      data,
      schema,
      uischema,
      path,
      handleChange,
      errors,
      showErrors,
    } = this.props
    const style = uischema?.options?.style
    const boxed = uischema?.options?.boxed
    const resetOnDeselect = uischema?.options?.resetOnDeselect

    if (showErrors && errors.length > 0 && this.checkboxRef.current) {
      this.checkboxRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }

    if (schema.type === 'boolean') {
      let checkbox: React.ReactElement

      if (uischema?.options?.border === true) {
        return (
          <CheckboxV1
            boxed={true}
            error={showErrors && errors !== '' ? errors : undefined}
            handleClick={(selected: boolean) =>
              handleChange(
                path,
                resetOnDeselect === true && !selected ? undefined : selected,
              )
            }
            selected={data || false}
          >
            <div>
              <div> {uischema.label as React.ReactElement}</div>
              {uischema?.options?.subLabel && (
                <div style={uischema?.options?.subLabel?.style}>
                  {uischema?.options?.subLabel?.text}
                </div>
              )}
            </div>
          </CheckboxV1>
        )
      }
      if (uischema?.options?.template) {
        const text = parseTemplate(
          uischema.label as string,
          uischema.options.template,
        )

        checkbox = (
          <Checkbox
            boxed={typeof boxed === 'boolean' ? boxed : false}
            checked={data || false}
            error={showErrors && errors !== '' ? errors : undefined}
            label={
              <div
                dangerouslySetInnerHTML={{ __html: text }}
                style={{ display: 'contents' }}
              ></div>
            }
            onChange={(e: unknown, selected: boolean) => {
              handleChange(
                path,
                resetOnDeselect === true && !selected ? undefined : selected,
              )
            }}
          />
        )
      } else {
        checkbox = (
          <Checkbox
            boxed={typeof boxed === 'boolean' ? boxed : false}
            checked={data || false}
            error={showErrors && errors !== '' ? errors : undefined}
            label={uischema.label as React.ReactElement}
            onChange={(e: unknown, selected: boolean) => {
              handleChange(
                path,
                resetOnDeselect === true && !selected ? undefined : selected,
              )
            }}
          />
        )
      }

      return (
        <div ref={this.checkboxRef} style={style}>
          {checkbox}
        </div>
      )
    }
    // TODO handle initial Data
    const properties = schema.properties

    if (!properties) throw Error('CheckboxControl is expecting properties')

    const checkboxElement = uischema as ControlElementEpilot
    const checkboxLabels = checkboxElement?.labels

    const checkboxElements = Object.keys(properties).map((key) => {
      let label: unknown = key

      if (checkboxLabels && typeof checkboxLabels[key] === 'string') {
        label = checkboxLabels[key]
      }

      return (
        <CheckboxV1
          boxed={typeof boxed === 'boolean' ? boxed : false}
          direction="column-reverse"
          error={showErrors && errors !== '' ? errors : undefined}
          handleClick={(selected: boolean) =>
            this.handleChangeLocal(key, selected)
          }
          key={key}
          selected={data && data[key] ? data[key] : false}
        >
          <BodyText style={styles.body}>{label as string}</BodyText>
        </CheckboxV1>
      )
    })

    return (
      <div>
        <Row>{checkboxElements}</Row>
        {showErrors && errors !== '' && (
          <MuiFormHelperText error id={`${path}--helper-text`}>
            {errors}
          </MuiFormHelperText>
        )}
      </div>
    )
  }
}

const styles = {
  body: { minWidth: '50px', margin: '15px' },
}

export default connect((state: GlobalAppState) => ({
  showErrors: getShowErrors(state),
}))(withJsonFormsControlProps(CheckboxControl))

export const checkboxControlTester: RankedTester = rankWith(
  4,
  and(uiTypeIs('Control'), or(schemaTypeIs('object'), schemaTypeIs('boolean'))),
)
