import React from 'react'

import { CollapsibleContainerStyled } from '../installation-module-control/installations-performance-module-styled'
import {
  CommissioningInstallation,
  GeneratorInstallation,
  InstallationPerformanceDetailsType,
  InverterInstallation,
  SolarModuleInstallation,
  StorageInstallation,
} from '../types'
import { INSTALLATIONTYPE, INSTALLATIONUNIT } from '../unitConstant'

const InstallationPerformanceDetails = ({
  type,
  commissioningInstallationsLabel,
  installations,
  expanded = false,
}: InstallationPerformanceDetailsType): JSX.Element => {
  switch (type) {
    case 'solar':
      return (
        <CollapsibleContainerStyled expanded={expanded}>
          <div className="installation-details-title">
            <p>Hersteller</p>
            <p>Typ</p>
            <p>Leistung ({INSTALLATIONUNIT[INSTALLATIONTYPE.SOLAR]})</p>
            <p>Anzahl</p>
          </div>
          {(installations as SolarModuleInstallation[]).map(
            (installation, index) => (
              <div className="installation-details" key={index}>
                <p>{installation.manufacturer}</p>
                <p>{installation.type}</p>
                <p>{installation.solarPerformance}</p>
                <p>{installation.quantity}</p>
              </div>
            ),
          )}
        </CollapsibleContainerStyled>
      )
    case 'storage':
      return (
        <CollapsibleContainerStyled expanded={expanded}>
          <div className="installation-details-title">
            <p>Hersteller</p>
            <p>Typ</p>
            <p>Leistungsaufnahme (kVA)</p>
            <p>Leistungsabgabe (kVA)</p>
            <p>Speicherkapazität (kWh)</p>
            <p>Anzahl</p>
          </div>
          {(installations as StorageInstallation[]).map(
            (installation, index) => (
              <div className="installation-details" key={index}>
                <p>{installation.manufacturer}</p>
                <p>{installation.type}</p>
                <p>{installation.powerConsumption}</p>
                <p>{installation.powerOutput}</p>
                <p>{installation.storageCapacity}</p>
                <p>{installation.quantity}</p>
              </div>
            ),
          )}
        </CollapsibleContainerStyled>
      )
    case 'inverter':
      return (
        <CollapsibleContainerStyled expanded={expanded}>
          <div className="installation-details-title">
            <p>Hersteller</p>
            <p>Typ</p>
            <p>Leistung ({INSTALLATIONUNIT[INSTALLATIONTYPE.INVERTER]})</p>
            <p>Anzahl</p>
          </div>
          {(installations as InverterInstallation[]).map(
            (installation, index) => (
              <div className="installation-details" key={index}>
                <p>{installation.manufacturer}</p>
                <p>{installation.type}</p>
                <p>{installation.inverterPerformance}</p>
                <p>{installation.quantity}</p>
              </div>
            ),
          )}
        </CollapsibleContainerStyled>
      )
    case 'generator':
      return (
        <CollapsibleContainerStyled expanded={expanded}>
          <div className="installation-details-title">
            <p>Hersteller</p>
            <p>Typ</p>
            <p>Scheinleistung (kVA)</p>
            <p>Wirkleistung (kW)</p>
            <p>Bauart</p>
            <p>Anzahl</p>
          </div>
          {(installations as GeneratorInstallation[]).map(
            (installation, index) => (
              <div className="installation-details" key={index}>
                <p>{installation.manufacturer}</p>
                <p>{installation.type}</p>
                <p>{installation.apparentPerformance}</p>
                <p>{installation.activePerformance}</p>
                <p>{installation.constructionType}</p>
                <p>{installation.quantity}</p>
              </div>
            ),
          )}
        </CollapsibleContainerStyled>
      )
    case 'commissioning':
    default:
      return (
        <CollapsibleContainerStyled expanded={expanded}>
          <div className="installation-details-title">
            <p>{commissioningInstallationsLabel}</p>
            <p>Status</p>
            <p>Nennleistung (kW)</p>
            <p>Jährlicher Verbrauch (kWh)</p>
          </div>
          {(installations as CommissioningInstallation[]).map(
            (installation, index) => (
              <div className="installation-details" key={index}>
                <p>{installation.name}</p>
                <p>{installation.status}</p>
                <p>{installation.ratedCapacity}</p>
                <p>{installation.annualConsumption}</p>
              </div>
            ),
          )}
        </CollapsibleContainerStyled>
      )
  }
}

export default InstallationPerformanceDetails
