import {
  MuiHidden,
  BirthdateControl as BirthdateControlComponent,
} from '@epilot/components'
import {
  and,
  ControlProps,
  or,
  RankedTester,
  rankWith,
  schemaTypeIs,
  optionIs,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { isRequiredScope } from 'lib'
import { generateStringLabel } from 'lib/is-required-scope'
import { validateDate } from 'lib/validate-date'
import { getShowErrors } from 'modules/step/selectors'

const BirthdateInputControl = ({
  id,
  data,
  enabled,
  label,
  visible,
  errors,
  handleChange,
  path,
  uischema,
  rootSchema,
}: ControlProps) => {
  const [localError, setLocalError] = useState('')
  const showErrors = useSelector(getShowErrors)
  const dateLabel = uischema?.options?.label
  const description = uischema?.options?.description

  const isRequired = isRequiredScope(rootSchema, uischema.scope)
  const stringLabel = generateStringLabel(label as string, isRequired)

  const handleLocalChange = ({
    day,
    month,
    year,
  }: {
    day: string
    month: string
    year: string
  }) => {
    const error = validateDate(day, month, year)

    if (error) {
      if (!localError) {
        handleChange(path, undefined)
      }

      setLocalError(error)
    } else if (year && month && day) {
      const formattedDate = `${year}-${month}-${day}`

      setLocalError('')

      handleChange(path, formattedDate)
    }
  }

  return (
    <MuiHidden xsUp={!visible}>
      <BirthdateControlComponent
        dateLabels={dateLabel}
        description={description}
        disabled={!enabled}
        error={localError || (showErrors ? errors : '')}
        fullWidth
        id={id}
        label={stringLabel}
        onChange={handleLocalChange}
        value={data || ''}
      />
    </MuiHidden>
  )
}

export default withJsonFormsControlProps(BirthdateInputControl)

export const birthdateInputControlTester: RankedTester = rankWith(
  10,
  and(
    uiTypeIs('Control'),
    or(schemaTypeIs('string')),
    and(optionIs('type', 'birthdate')),
  ),
)
