import { all } from 'redux-saga/effects'

import saga from '../modules/saga'

export default function* rootSaga(): Generator<any, any, any> {
  yield all([
    saga(),
    // more sagas...
  ])
}
