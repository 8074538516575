import { CustomField } from 'lib/api/types'

import { PayloadResults } from '../types'

export const customFieldsCommon = (results: PayloadResults): CustomField[] => {
  const values: CustomField[] = []

  if (results.invoice_recipient_first_name) {
    values.push({
      value: results.invoice_recipient_first_name,
      label: 'Abweichender Rechnungsempfänger Vorname',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.invoice_recipient_last_name) {
    values.push({
      value: results.invoice_recipient_last_name,
      label: 'Abweichender Rechnungsempfänger Nachname',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.payment_method) {
    values.push({
      value: results.payment_method,
      label: 'Zahlungsart',
      source: 'journey',
      readOnly: true,
    })
  }
  if (results.account_owner) {
    values.push({
      value: results.account_owner,
      label: 'Kontoinhaber',
      source: 'journey',
      readOnly: true,
    })
  }
  if (results.sepa?.iban) {
    values.push({
      value: results.sepa.iban,
      label: 'IBAN',
      source: 'journey',
      readOnly: true,
    })
  }
  if (results.sepa?.bic_number) {
    values.push({
      value: results.sepa.bic_number,
      label: 'BIC',
      source: 'journey',
      readOnly: true,
    })
  }
  if (results.sepa?.bank_name) {
    values.push({
      value: results.sepa.bank_name,
      label: 'Bankname',
      source: 'journey',
      readOnly: true,
    })
  }

  // leave if statement here as user has the choice to use 'invoice' instead of 'sepa'
  if (results.payment_agreement) {
    values.push({
      value: 'Ja',
      label: 'Einzugsermächtigung',
      source: 'journey',
      readOnly: true,
    })
  }

  values.push({
    value: results.accept_terms ? 'Ja' : 'Nein',
    label: 'AGB und Datenschutzerklärung gelesen und akzeptiert.',
    source: 'journey',
    readOnly: true,
  })

  values.push({
    value: results.withdrawal ? 'Ja' : 'Nein',
    label: 'Die Widerrufsbelehrung habe ich zur Kenntnis genommen.',
    source: 'journey',
    readOnly: true,
  })

  values.push({
    value: results.accept_promotions ? 'Ja' : 'Nein',
    label: 'Marketing-Einwilligung',
    source: 'journey',
    readOnly: true,
  })

  values.push({
    value: results.accept_assignment ? 'Ja' : 'Nein',
    label: 'Beauftragung',
    source: 'journey',
    readOnly: true,
  })

  return values
}
