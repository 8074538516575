import { H2 } from '@epilot/components/dist'
import {
  JsonFormsRendererRegistryEntry,
  JsonSchema,
  UISchemaElement,
  VerticalLayout,
} from '@jsonforms/core'
import { JsonFormsDispatch } from '@jsonforms/react'
import React from 'react'

import { Addon } from 'modules/shop/types'

import { SecondaryTitleDescriptionStyled } from './product-details-styled'

type ProductDetailsAddonsProps = {
  addons: Addon[]
  selectedAddonsIds: string[]
  title?: string
  layoutProps: {
    renderers: JsonFormsRendererRegistryEntry[] | undefined
    schema: JsonSchema
    path: string
    enabled: boolean
    uischema: UISchemaElement
  }
}

const ProductDetailsAddons = ({
  addons,
  selectedAddonsIds,
  title,
  layoutProps,
}: ProductDetailsAddonsProps): JSX.Element => {
  let filteredAddons = addons
  const { renderers, schema, path, enabled, uischema } = layoutProps

  if (selectedAddonsIds) {
    filteredAddons = addons.filter((addon) =>
      selectedAddonsIds.includes(addon.id.toString()),
    )
  }

  return filteredAddons.length > 0 ? (
    <SecondaryTitleDescriptionStyled>
      <H2>{title || 'Zusatzoptionen'}</H2>
      {(uischema as VerticalLayout).elements.map((child, index) => (
        <JsonFormsDispatch
          enabled={enabled}
          key={index}
          path={path}
          renderers={renderers}
          schema={schema}
          uischema={child}
        />
      ))}
    </SecondaryTitleDescriptionStyled>
  ) : (
    <></>
  )
}

export default ProductDetailsAddons
