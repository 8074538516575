// @flow

import { combineReducers } from 'redux'

import * as reducer from '../modules/reducer'

export default function buildRootReducer() {
  return combineReducers({
    ...reducer,
  })
}
