import { BlueprintElements } from 'types/blueprint-config'

import { BuildingBlock, BlockValueProps } from './types'

// Address
type AddressBlockValues = {
  address: BlockValueProps
}

const defaultAddresslValues: AddressBlockValues = {
  address: { name: 'address', required: true },
}

export const buildAddressBlock = (
  addressValues?: AddressBlockValues,
): BuildingBlock => {
  const values = addressValues || defaultAddresslValues

  const schema = {
    [values.address.name]: {
      type: 'object',
    },
  }

  const uischema: BlueprintElements[] = [
    {
      type: 'GridItemLayout',
      options: {
        xs: 12,
      },
      elements: [
        {
          type: 'AddressControl',
          label: 'Land',
          scope: `#/properties/${values.address.name}`,
          options: {
            labels: {
              streetLabel: `Straße ${values.address.required ? '*' : ''}`,
              housenumberLabel: `Hausnummer ${
                values.address.required ? '*' : ''
              }`,
              zipCityLabel: `PLZ / Ort ${values.address.required ? '*' : ''}`,
              extentionLabel: 'Adresszusatz',
            },
            streetAddressAPI:
              'https://jdk4pkdou2.execute-api.eu-central-1.amazonaws.com/dev/v1/integration/location/%country%/streets?postal_code=%zip%&city=%city%&street=%street%',
            cityZipAPI:
              'https://jdk4pkdou2.execute-api.eu-central-1.amazonaws.com/dev/v1/integration/location/%country%/postalcode-cities?postalcode_city=%postalcode_city%',
            fieldsOrder: [
              {
                name: 'ZIP_CITY',
              },
              {
                name: 'STREET',
              },
              {
                name: 'HOUSE',
              },
              {
                name: 'EXTENTION',
              },
            ],
          },
        },
      ],
    },
  ]

  const required = values.address.required ? [values.address.name] : []

  return {
    schema: schema,
    uischema: uischema,
    required: required,
  }
}
