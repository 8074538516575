export const epilotStyling = {
  theming_options: {
    palette: {
      primary: '#01579B',
    },
    background: {
      default: '#E0EDEF',
    },
    typography: {
      title_size: 32,
      subtitle_size: 24,
      main_size: 18,
      caption_size: 14,
    },
    spacing: {
      default: 10,
    },
    shape: {
      border_radius: 0,
    },
    props: {
      client_logo_url: '',
      content_max_width: 1128,
    },
  },
  useBaseElementsLibrary: true,
}
