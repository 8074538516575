import {
  EpilotTheme,
  ThemeProvider as ThemeProviderV2,
} from '@epilot/base-elements'
import { ThemeProvider } from '@epilot/components'
import { instance18n as instance18nRenderers } from '@epilot/json-renderers'
import { createMuiTheme, ThemeOptions } from '@material-ui/core'
import React, { useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { connect } from 'react-redux'

import {
  getIsInitialized,
  getIsLoading,
  getTheme,
  getError,
  getInitialConfig,
} from 'modules/app/selectors'
import { Theme } from 'modules/app/types'
import { GlobalAppState } from 'modules/types'
import { WidgetConfig } from 'types/widget-config'

import config from '../config'

import Debug from './debug/debug'
import { Error } from './error'
import Loader from './loader'
import { StepHandler, StepHandlerV2 } from './step-handler'
import { UIWrapper, UIWrapperV2 } from './ui-wrapper'

type AppEpilotProps = {
  isInitialized: boolean
  isLoading: boolean
  error: boolean
  theme: Theme | EpilotTheme | undefined
  initialConfig: WidgetConfig | null
}

// Type guard to check if theme is of type Theme (old UI library) or EpilotTheme (new UI library)
const isEpilotTheme = (initialConfig: WidgetConfig | null) => {
  const { builder = {} } = initialConfig || {}
  const { useBaseElementsLibrary = false } = builder

  return useBaseElementsLibrary
}

const AppEpilot = ({
  isInitialized,
  isLoading,
  error,
  theme,
  initialConfig,
}: AppEpilotProps): React.ReactElement => {
  // ref: https://e-pilot.atlassian.net/browse/ER-2444?focusedCommentId=46641
  const [postalCodeCityValue, setPostalCodeCityValue] = useState<
    null | undefined | Record<string, any>
  >(null)

  // Check if the theme is coming in the URL
  const themeToUse = config.THEME_PREVIEW
    ? (JSON.parse(config.THEME_PREVIEW) as EpilotTheme)
    : theme

  // If an error is found, redirects to Error component
  if (error) return <Error />

  // Show spinner on loading
  if (!isInitialized || isLoading) return <Loader />

  // If the configuration use the new UI, use the ThemeProvider of the new elements library
  // and the StepHandlerV2 with JSON Forms renderers for modules library
  if (isEpilotTheme(initialConfig)) {
    const newTheme = themeToUse as EpilotTheme

    return (
      <ThemeProviderV2 theme={newTheme}>
        <I18nextProvider i18n={instance18nRenderers}>
          <UIWrapperV2>
            <StepHandlerV2
              postalCodeCityValue={postalCodeCityValue}
              setPostalCodeCityValue={setPostalCodeCityValue}
            />
          </UIWrapperV2>
        </I18nextProvider>
        {config.DEBUG ? <Debug /> : null}
      </ThemeProviderV2>
    )
  } else {
    const oldTheme = themeToUse as EpilotTheme | Theme

    if (oldTheme && oldTheme?.typography) {
      const sheet = window.document.styleSheets[0] as CSSStyleSheet

      const fontUrls =
        'fontUrls' in oldTheme.typography
          ? oldTheme.typography.fontUrls
          : undefined

      const fontSource =
        'fontSource' in oldTheme.typography
          ? oldTheme.typography.fontSource
          : undefined

      fontUrls?.forEach(({ url }: Record<string, string>) => {
        sheet.insertRule(
          `@font-face {font-family: '${oldTheme?.typography?.fontFamily?.replace(
            ' !important',
            '',
          )}';font-style: normal;font-weight: normal;src:url('${url}');}`,
          sheet.cssRules.length,
        )
      })

      fontSource?.forEach(({ src, fontFamily, fontStyle, fontWeight }) => {
        sheet.insertRule(
          `@font-face {font-family: '${fontFamily}'; font-style: ${fontStyle}; font-weight: ${fontWeight}; src:${src};}`,
          sheet.cssRules.length,
        )
      })
    }

    let themeToApply

    try {
      themeToApply = createMuiTheme(oldTheme as ThemeOptions)
    } catch {
      themeToApply = oldTheme
    }

    return (
      <>
        <div
          className="app app-epilot-widget"
          data-component="app-epilot"
          style={{
            backgroundColor: oldTheme?.palette?.background?.default || 'white',
          }}
        >
          <ThemeProvider theme={themeToApply as Theme}>
            <UIWrapper>
              <StepHandler />
            </UIWrapper>
          </ThemeProvider>
        </div>
        {config.DEBUG ? <Debug /> : null}
      </>
    )
  }
}

export default connect((state: GlobalAppState) => ({
  isInitialized: getIsInitialized(state),
  isLoading: getIsLoading(state),
  error: getError(state),
  theme: getTheme(state),
  initialConfig: getInitialConfig(state),
}))(AppEpilot)
