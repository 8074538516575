import { Checkbox, CircleCheckIcon, SmallCheckIcon } from '@epilot/components'
import React from 'react'
import { useSelector } from 'react-redux'

import { getTheme } from 'modules/app/selectors'

import AddonButtonStyled from './addon-button-styled'
import AddonDetailsStyled from './addon-details-styled'

export type AddonDetailsProps = {
  description: string | undefined
  isActive: boolean
  open: boolean
  selectButtonLabel: string
  handleChangeQuantity: (
    e: React.MouseEvent<HTMLButtonElement>,
    quantity: number,
  ) => void
  quantity: number
  singleSelect?: boolean
  withQuantity?: boolean
}

const AddonDetails = ({
  description,
  isActive,
  open,
  selectButtonLabel,
  singleSelect,
  handleChangeQuantity,
  quantity = 1,
  withQuantity = false,
}: AddonDetailsProps): JSX.Element => {
  const theme = useSelector(getTheme)

  return (
    <AddonDetailsStyled isActive={isActive}>
      <div className="button-container">
        <AddonButtonStyled className={isActive ? 'active' : ''}>
          {!singleSelect ? (
            <Checkbox
              checked={isActive}
              label={<span>{selectButtonLabel}</span>}
            />
          ) : (
            <Checkbox
              checked={isActive}
              checkedIcon={
                <div className={'checkedIcon-background'}>
                  <div className={'checkedIcon-icon'}>
                    <SmallCheckIcon
                      color={theme?.palette?.primary?.main}
                      size={'smallest'}
                    />
                  </div>
                </div>
              }
              icon={<div className={'button-icon'}></div>}
              label={<span>{selectButtonLabel}</span>}
            />
          )}
        </AddonButtonStyled>
        {isActive && withQuantity && (
          <div className="quantity-selector">
            <button onClick={(e) => handleChangeQuantity(e, -1)}>-</button>
            <span>{quantity}</span>
            <button onClick={(e) => handleChangeQuantity(e, 1)}>+</button>
          </div>
        )}
      </div>
      {open &&
        description?.split(/\r?\n/).map((text, i) => {
          return (
            <div className="text-container" key={i}>
              {text.startsWith('•') ? (
                <div className="circle-text-container">
                  <CircleCheckIcon
                    color={theme?.palette?.primary?.main}
                  ></CircleCheckIcon>{' '}
                  <p>{text.split('•')[1]}</p>
                </div>
              ) : (
                <p key={i}>{text}</p>
              )}
            </div>
          )
        })}
    </AddonDetailsStyled>
  )
}

export default AddonDetails
