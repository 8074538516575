import { styled } from '@epilot/components'

import { Theme } from '../../../modules/app/types'

type Price = {
  highlighted: boolean
  small: boolean
  theme: Theme
  variant: 'bigger' | 'default'
}

const PriceStyled = styled.div<Price>`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 12px;

  .prices-container {
    display: flex;
    color: ${({ theme }: Price) => theme?.typography?.color} !important;
  }
  .big {
    font-size: ${({ highlighted, small, variant }: Price) =>
      highlighted ? (variant === 'bigger' ? 60 : 40) : small ? 14 : 24}px;
    line-height: ${({ highlighted, small, variant }: Price) =>
      highlighted ? (variant === 'bigger' ? 50 : 32) : small ? 16 : 20}px;
    margin: 0 1px;
  }

  .small {
    font-size: ${({ variant }: Price) =>
      variant === 'bigger' ? '22px' : '14px'};
    line-height: ${({ variant }: Price) =>
      variant === 'bigger' ? '32px' : '16px'};
    margin: 0px 1px;
    font-style: normal;
    font-weight: normal;
  }
  .small-container {
    display: flex;
    flex-direction: column;
  }
  .price-description {
    font-weight: normal;
    font-size: ${({ variant }: Price) =>
      variant === 'bigger' ? '14px' : '12px'};
    font-style: normal;
    position: absolute;
    left: 0px;
    top: 18px;
  }
  .small-price-text {
    font-weight: normal;
    font-size: 12px;
    font-style: normal;
    margin-top: 2px;
  }
`

export default PriceStyled
