import { BlueprintResults } from '../../../types/blueprint-config'

export const results: BlueprintResults = {
  originalDataKey: 'fields',
  payloadMapping: {
    journey_type: 'CONTACT_FORM',
    widgetId: 'meta.widgetId',
    widgetVersion: 'meta.version',
    organizationId: 'meta.organizationId',
    custom_fields: [
      {
        type: 'Text',
        value: 'data.housing_units',
        label: 'Wohneinheit(en)',
      },
      {
        type: 'Text',
        value: 'data.business_units',
        label: 'Geschäftseinheit(en)',
      },
      {
        type: 'Text',
        value: 'data.ownerType',
        label: 'Owner Type',
      },
      {
        type: 'Text',
        value: '(data.checkbox1)?(Ja):(Nein)',
        label: 'Consent 1',
      },
      {
        type: 'Text',
        value: '(data.checkbox2)?(Ja):(Nein)',
        label: 'Consent 2',
      },
    ],
    questions: [],
    opportunity: {
      enable_send_email: false,
      description: 'Created by a Widget request.',
      title: 'Opportunity from Widget.',
    },
    customer: {
      organizationId: 'meta.organizationId',
      title: 'data.title',
      salutation: 'data.salutation',
      firstName: 'data.first_name',
      lastName: 'data.last_name',
      phone: 'data.telephone',
      email: 'data.user_email',
      customerType: 'PRIVATE',
      deliveryAddress: {
        streetName: 'data.address.streetName',
        streetNumber: 'data.address.houseNumber',
        city: 'data.address.city',
        postalCode: 'data.address.zipCode',
        address_suffix: 'data.address.addressExtention',
      },
      differentBillingAddress: 'data.different_address',
      billingAddress: {
        streetName: 'data.contact_address.streetName',
        streetNumber: 'data.contact_address.houseNumber',
        city: 'data.contact_address.city',
        postalCode: 'data.contact_address.zipCode',
        address_suffix: 'data.contact_address.addressExtention',
      },
      additional_contacts: {
        additional_owner: {
          salutation: 'data.additional_salutation',
          title: 'data.additional_title',
          first_name: 'data.additional_first_name',
          last_name: 'data.additional_last_name',
          email: 'data.additional_user_email',
          phone: 'data.additional_telephone',
          street_name: 'data.additional_address.streetName',
          street_number: 'data.additional_address.houseNumber',
          city: 'data.additional_address.city',
          postal_code: 'data.additional_address.zipCode',
          address_suffix: 'data.additional_address.addressExtention',
        },
      },
    },
  },
}
