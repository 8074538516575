import {
  InputControl as InputControlComponent,
  MuiHidden,
  ProductPowerIcon,
  ProductGasIcon,
} from '@epilot/components'
import {
  and,
  ControlProps,
  or,
  RankedTester,
  rankWith,
  schemaTypeIs,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'
import { useSelector } from 'react-redux'

import { isRequiredScope } from 'lib'
import { generateStringLabel } from 'lib/is-required-scope'
import { getShowErrors } from 'modules/step/selectors'

const icons: any = {
  default: ProductPowerIcon,
  ProductPowerIcon: ProductPowerIcon,
  ProductGasIcon: ProductGasIcon,
}

const NumberInputControl = ({
  id,
  data,
  enabled,
  label,
  visible,
  errors,
  handleChange,
  path,
  schema,
  uischema,
  rootSchema,
}: ControlProps) => {
  const showErrors = useSelector(getShowErrors)
  const type = schema.type === 'integer' ? 'numeric' : 'decimal'
  const unit = uischema?.options?.unit
  const iconName = uischema?.options?.icon
  const IconComponent = icons[iconName] || icons['default']

  const isRequired = isRequiredScope(rootSchema, uischema.scope)
  const stringLabel = generateStringLabel(label as string, isRequired)

  const handleChangeLocal = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (type === 'numeric') {
        const value = parseInt(event.target.value, 10)

        handleChange(path, value)
      } else {
        const value = parseFloat(event.target.value)

        handleChange(path, value)
      }
    } catch {
      handleChange(path, event.target.value)
    }
  }

  return (
    <MuiHidden xsUp={!visible}>
      <InputControlComponent
        disabled={!enabled}
        error={showErrors ? errors : ''}
        fullWidth
        icon={iconName ? <IconComponent color={'#849096'} /> : null}
        id={id}
        label={stringLabel}
        onChange={handleChangeLocal}
        type={type}
        unit={unit}
        value={data ?? ''}
      />
    </MuiHidden>
  )
}

export default withJsonFormsControlProps(NumberInputControl)

export const numberInputControlTester: RankedTester = rankWith(
  9,
  and(uiTypeIs('Control'), or(schemaTypeIs('number'), schemaTypeIs('integer'))),
)
