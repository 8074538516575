import { ProductCategory } from 'modules/shop/types'

import { API_PRODUCTS } from '../../../config'

import { defaultConsumptionCalculatorSchemaProperties } from './schemas/consumption-calculator.schema'

export const shop = {
  productSettings: {
    categories: [
      {
        id: 'POWER' as ProductCategory,
        icon: {
          alt: 'POWER',
          href: '',
        },
        loadProductsScope: ['postalcode_city', 'power_consumption'],
      },
      {
        id: 'GAS' as ProductCategory,
        icon: {
          alt: 'GAS',
          href: '',
        },
        loadProductsScope: ['postalcode_city', 'gas_consumption'],
      },
    ],
  },
  loadProductWithoutCondition: true,
  productApi: {
    queryStatic: {
      category: ['POWER', 'GAS'],
    },
    queryTemplate: {
      postal_code: `${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.zipCode`,
      street_name: `${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.streetName`,
      street_number: `${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.houseNumber`,
      city: `${defaultConsumptionCalculatorSchemaProperties.postalcode_city.name}.city`,
      power_consumption: `${defaultConsumptionCalculatorSchemaProperties.power_consumption.name}.amount`,
      gas_consumption: `${defaultConsumptionCalculatorSchemaProperties.gas_consumption.name}.amount`,
    },
    endpoint: `${API_PRODUCTS}/v2/product`,
  },
  enetApi: {
    queryTemplate: {
      postal_code: `postalcode_city.zipCode`,
      consumption: `{product}_consumption.amount`,
    },
    gridOperatorAmount: '/enet-pricing/grid-providers',
    endPoint: 'https://svc-enet-integration-api.staging.sls.epilot.io/v1',
  },
  productPriceInterval: 'MONTHLY',
}
