import React from 'react'

import { Product as ProductType } from 'modules/shop/types'

import Product from './product'
import ProductsSelectionStyled from './products-selection-styled'

type ProductsSelectionProps = {
  selectedIds: string
  products: ProductType[]
  onSelectedChange: (value: string) => void
  open: boolean
  withDetails?: boolean
  tariffDetails?: boolean
  showBonus?: boolean
  showFootnote?: boolean
}

const ProductsSelection = ({
  selectedIds,
  products,
  onSelectedChange,
  open,
  withDetails = true,
  tariffDetails = false,
  showBonus = false,
  showFootnote = false,
}: ProductsSelectionProps): JSX.Element => {
  const productsCount = products.length

  return (
    <ProductsSelectionStyled productsCount={productsCount}>
      {products.map((product) => (
        <Product
          key={product.id}
          onSelectedChange={onSelectedChange}
          open={open}
          product={product}
          selectedIds={selectedIds}
          showBonus={showBonus}
          showFootnote={showFootnote}
          tariffDetails={tariffDetails}
          withDetails={withDetails}
        />
      ))}
    </ProductsSelectionStyled>
  )
}

export default ProductsSelection
