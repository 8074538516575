import { H2 } from '@epilot/components'
import {
  OwnPropsOfRenderer,
  RankedTester,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import React from 'react'

import LabelControl from './label-control'

/**
 * LabelH2 tester
 * @type {RankedTester}
 */
export const labelH2RendererTester: RankedTester = rankWith(
  1,
  uiTypeIs('LabelH2'),
)

/**
 * LabelH2 renderer
 */
export const LabelH2Renderer = (
  props: OwnPropsOfRenderer,
): React.ReactElement | null => {
  return <LabelControl component={H2} {...props} />
}

export default withJsonFormsLayoutProps(LabelH2Renderer)
