/* eslint-disable @typescript-eslint/no-unused-vars */
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import buildRootReducer from './buildRootReducer'

export default (
  parameters: any = {},
  __INITIAL_STATE: any = {},
  middlewares: any[] = [],
): any => {
  const rootReducer = buildRootReducer()
  const initialState = {}

  // TODO: separate dev from prod for removing tools
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  )

  return store
}
