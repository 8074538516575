import { Spinner } from '@epilot/components'
import React from 'react'

import LoaderContainer from './loader-container-styled'

const Loader = () => (
  <LoaderContainer>
    <Spinner />
  </LoaderContainer>
)

export default Loader
