import { CustomField } from 'lib/api/types'

import { PayloadResults } from '../types'

export const customFieldsGas = (results: PayloadResults): CustomField[] => {
  const values: CustomField[] = []

  if (results.gas_consumption?.amount) {
    values.push({
      value: results.gas_consumption?.amount.toString(),
      label: 'Jährlicher Verbrauch (Gas)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.situation_gas) {
    values.push({
      value: results.situation_gas,
      label: 'Welche Situation trifft auf Sie zu? (Gas)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.terminate_contract_gas) {
    values.push({
      value: results.terminate_contract_gas,
      label: 'Sollen wir Ihren bestehenden Vertrag für Sie kündigen? (Gas)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.first_time_occupancy_gas) {
    values.push({
      value: results.first_time_occupancy_gas,
      label: 'Handelt es sich um einen Erstbezug in einem Neubau? (Gas)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.delivery_date_asap_gas) {
    values.push({
      value: 'Ja',
      label: 'Gewünschter Lieferbeginn (Gas) schnellstmöglich',
      source: 'journey',
      readOnly: true,
    })
  } else if (results.delivery_date_gas) {
    values.push({
      value: 'Nein',
      label: 'Gewünschter Lieferbeginn (Gas) schnellstmöglich',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.previous_provider_gas?.company_name) {
    values.push({
      value: results.previous_provider_gas?.company_name,
      label: 'Bisheriger Anbieter (Gas)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.previous_provider_gas?.bdew_code) {
    values.push({
      value: results.previous_provider_gas?.bdew_code,
      label: 'BDEW Code - MarktPartner ID (Gas)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (results.moving_date_gas) {
    values.push({
      value: results.moving_date_gas,
      label: 'Einzugdatum (Gas)',
      source: 'journey',
      readOnly: true,
    })
  }

  if (
    results.gas_readings &&
    results.gas_readings.length &&
    results.gas_readings.length > 0
  ) {
    results.gas_readings.forEach((reading, index) => {
      values.push(
        ...[
          {
            value: reading.meterNumber,
            label: `Zählernummer Gas ${index + 1}`,
            source: 'journey' as const,
            readOnly: true,
          },
          {
            value: reading.meterReading,
            label: `Zählerwert Gas ${index + 1}`,
            source: 'journey' as const,
            readOnly: true,
          },
          {
            value: reading.marketLocationId,
            label: `MaLo-ID Gas ${index + 1}`,
            source: 'journey' as const,
            readOnly: true,
          },
        ],
      )
    })
  }

  return values
}
