import { LayoutProps, UISchemaElement } from '@jsonforms/core'
import React, { ReactElement } from 'react'
import { connect, useSelector } from 'react-redux'

import { ReactComponent as PenIcon } from 'assets/icons/pen.svg'
import { formatIsoDateString } from 'lib/format-date'
import replaceTemplate from 'lib/replace-template'
import { goToStep } from 'modules/step/reducer'
import { getAllResultData } from 'modules/step/selectors'
import { StepId } from 'modules/step/types'

import { cleanBroadbandData } from '../../../modules/step/helper/cleanBroadbandData'
import { FilesPack } from '../upload/upload'

type OverviewElementProps = {
  // goToStep?: typeof goToStep
  label?: string

  elements: {
    template: string
    labels?: { [property: string]: { [value: string]: string } }
    rule?: {
      effect: 'HIDE' | 'SHOW'
      condition: {
        schema: { const: unknown; otherConst?: Array<unknown> }
        scope: string
        otherScope?: Array<unknown>
      }
    }
    file?: string
    formatDate?: string[]
    options?: {
      style: React.CSSProperties
    }
  }[]
  options: {
    editLinkStepId?: StepId
    style: React.CSSProperties
  }
}
type Rule = {
  effect: 'HIDE' | 'SHOW'
  condition: {
    schema: { const: unknown }
    scope: string
  }
}

const OverviewElement = (
  props: LayoutProps & {
    goToStep?: typeof goToStep
    uischema: UISchemaElement & {
      label?: string

      elements?: OverviewElementProps['elements']
    }
  },
) => {
  const { uischema } = props

  const goToStep = props.goToStep
  const stepDataInitial = useSelector(getAllResultData)
  const stepData = cleanBroadbandData(stepDataInitial)
  const label = uischema.label as string
  const stepDisplayRule = uischema?.rule as Rule
  // OPTIONS
  const options = uischema.options
  const editLinkStepId = options?.editLinkStepId as StepId
  const elements = uischema.elements as OverviewElementProps['elements']

  const rowContent = [] as ReactElement[]

  if (stepDisplayRule) {
    if (stepDisplayRule.effect === 'HIDE') {
      const expectedValue = stepDisplayRule?.condition?.schema.const
      const propertyName = stepDisplayRule?.condition?.scope

      if (stepData[propertyName] === expectedValue) {
        return <></>
      }
    }
  }

  elements.forEach((element, index) => {
    const formatDate = element.formatDate
    const { style } = element.options || {}

    // replace all date values with a pretty formated string
    if (formatDate && formatDate.length > 0) {
      formatDate.forEach((datePropertyName) => {
        if (stepData[datePropertyName])
          stepData[datePropertyName] = formatIsoDateString(
            stepData[datePropertyName],
            true,
          )
      })
    }

    // Rule based display
    const rule = element.rule

    if (rule) {
      if (rule.effect === 'SHOW') {
        const expectedValue = rule.condition.schema.const
        const propertyName = rule.condition.scope

        if (stepData[propertyName] !== expectedValue) return
      } else if (rule.effect === 'HIDE') {
        const expectedValue = rule.condition.schema.const
        const propertyName = rule.condition.scope

        // if data and the expected value are the some do not add element
        if (stepData[propertyName] === expectedValue) return
        // if there are more than one different hide condition, if any one condition is satisfied, element will be hide
        const otherConst = rule.condition.schema.otherConst
        const otherScope = rule.condition.otherScope

        if (
          Array.isArray(otherScope) &&
          Array.isArray(otherConst) &&
          otherScope.length === otherConst.length
        ) {
          for (let i = 0; i < otherScope.length; i++) {
            if (stepData[otherScope[i] as string] === otherConst[i]) return
          }
        }
      }
    }

    rowContent.push(
      <div className="items" key={`overview-element-${index}`} style={style}>
        {replaceTemplate(element.template, stepData, element.labels)}
      </div>,
    )
    //display upload files in overview step
    const propertyOfUploadElement = element.file

    if (propertyOfUploadElement) {
      const files = stepData[propertyOfUploadElement]
      const handleOpenFile = (file: FilesPack) => {
        const win = window.open()

        if (win)
          win.document.write(
            '<iframe src="' +
              file.base64 +
              '" frameborder="0" style=" width:100%; height:100%;" allowfullscreen></iframe>',
          )
      }

      files &&
        rowContent.push(
          files.map((file: FilesPack, index: number) => {
            return (
              <div key={`overview-files-${index}`}>
                <a
                  href={file.base64}
                  onClick={() => handleOpenFile(file)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {file.file_name}
                </a>
              </div>
            )
          }),
        )
    }
  })

  if (rowContent.length === 0) return null

  return (
    <div
      className={
        'overview-element--container' + (editLinkStepId ? ' editable' : '')
      }
    >
      <div className="overview-element">
        {label ? <div className="title">{label}</div> : null}
        {rowContent}
      </div>
      {typeof editLinkStepId === 'string' && (
        <div
          className="icon"
          onClick={() => {
            if (editLinkStepId && goToStep) {
              goToStep(editLinkStepId)
            }
          }}
        >
          <PenIcon />
        </div>
      )}
    </div>
  )
}

export default connect(null, { goToStep })(OverviewElement)
