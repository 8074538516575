import { Price } from 'lib/api.types'

const formatOptions = {
  useGrouping: true,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  minimumIntegerDigits: 1,
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'symbol',
}
const numberFormats = new Intl.NumberFormat(navigator.language, formatOptions)

export const formatCurrency = (currencyValue: number): string => {
  return numberFormats.format(currencyValue)
}

const formatCentOptions = {
  useGrouping: true,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  minimumIntegerDigits: 1,
}

const formatter = new Intl.NumberFormat(navigator.language, formatCentOptions)

export const formatAsCent = (currencyValue: number): string => {
  return formatter.format(currencyValue * 100) + ' Cent'
}

export function formatPrice(
  price: Price,
  pricingBusiness: boolean,
  asCent?: boolean,
): string {
  return pricingBusiness
    ? asCent
      ? formatAsCent(price.amount_net)
      : formatCurrency(price.amount_net)
    : asCent
    ? formatAsCent(price.amount_gross)
    : formatCurrency(price.amount_gross)
}
