import { styled, ThemeProps } from '@epilot/components'

type AddonStyledProps = {
  isActive: boolean
  theme: ThemeProps
  isOpened: boolean
  withDetails: boolean
}

const AddonStyled = styled.div<AddonStyledProps>`
  border: 1px solid
    ${({ isActive, theme }: AddonStyledProps) =>
      isActive ? theme?.palette?.primary?.main : '#d2dee4'};
  border-radius: ${({ theme }: AddonStyledProps) =>
    theme?.shape?.borderRadius || 0};
  border-top: 4px solid
    ${({ theme }: AddonStyledProps) => theme?.palette?.primary?.main};
  cursor: pointer;
  display: flex;
  flex-basis: ${({ withDetails }: AddonStyledProps) =>
    withDetails ? 'calc(33% - 24px)' : 'calc(25% - 24px)'};
  flex-direction: column;
  ${({ withDetails }: AddonStyledProps) =>
    !withDetails && 'justify-content: space-between'};
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 0;
  position: relative;
  max-width: ${({ withDetails }: AddonStyledProps) =>
    withDetails ? 'calc(33% - 24px)' : 'calc(25% - 24px)'};

  .MuiTypography-body1 {
    font-size: 24px;
    line-height: 32px;
    word-wrap: break-word;
    min-height: 72px;
  }
  .MuiFormControlLabel-root {
    display: inherit;
  }
  .circle-icon {
    position: absolute;
    top: 24.75px;
    right: 24.75px;
  }
  .circle-icon svg {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 751px) {
    flex-basis: 100%;
    max-width: 100%;
  }
  > img {
    max-width: 100%;
  }
`

export default AddonStyled
