import { BlueprintElements } from 'types/blueprint-config'

import { BuildingBlock } from './types'

type HideableWrapperValues = {
  scope: string
  label: string
  elements: BlueprintElements[]
  requiredFields?: string[]
}

export const hideableWrapper = (
  values: HideableWrapperValues,
): BuildingBlock => {
  const schema = {
    [values.scope]: {
      type: 'boolean',
    },
  }

  const uischema: BlueprintElements[] = [
    {
      type: 'GridItemLayout',
      options: {
        xs: 12,
      },
      elements: [
        {
          type: 'Control',
          label: values.label,
          scope: `#/properties/${values.scope}`,
        },
        {
          type: 'GridContainerLayout',
          options: {
            spacing: 2,
          },
          rule: {
            condition: {
              schema: {
                const: true,
              },
              scope: `#/properties/${values.scope}`,
            },
            effect: 'SHOW',
          },
          elements: [...values.elements],
        },
      ],
    },
  ]

  const conditional_required = {
    if: {
      properties: {
        [values.scope]: {
          const: true,
        },
      },
    },
    then: {
      required: values.requiredFields,
    },
  }

  return {
    schema: schema,
    uischema: uischema,
    conditional_required: conditional_required,
  }
}
