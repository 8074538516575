import { BlueprintElements } from 'types/blueprint-config'

export const buildNextButtonBlock = (): BlueprintElements[] => {
  const uischema: BlueprintElements[] = [
    {
      type: 'GridItemLayout',
      options: {
        xs: 12,
      },
      elements: [
        {
          type: 'ForwardSummaryTile',
          options: {
            style: {
              padding: '30px 60px',
            },
          },
        },
      ],
    },
  ]

  return uischema
}
