import { BodyText, H1, H2 } from '@epilot/components/dist'
import React from 'react'

import config from 'config'

import {
  BulletTextStyled,
  CircleCheckIconStyled,
  ProductDetailsTitleStyled,
} from './product-details-styled'

type ProductDetailsTitleProps = {
  image?: string
  name: string
  subtitle?: string
  showShortDescription: boolean
  description?: string
  tariffDetailsTitle?: string
  tariffDetails: string[]
}

const ProductDetailsTitle = ({
  image,
  name,
  subtitle,
  showShortDescription,
  description,
  tariffDetailsTitle,
  tariffDetails,
}: ProductDetailsTitleProps): JSX.Element => {
  const mapBulletText = (text: string, index: number) => {
    return text ? (
      <BulletTextStyled key={index}>
        <CircleCheckIconStyled></CircleCheckIconStyled>
        <BodyText>{text}</BodyText>
      </BulletTextStyled>
    ) : null
  }

  const tariffDetailsWithBulletsFirstPart = tariffDetails
    .filter((_, i) => i <= Math.floor(tariffDetails.length / 2))
    .map(mapBulletText)
    .filter((element) => element !== null)
  const tariffDetailsWithBulletsSecondPart = tariffDetails
    .filter((_, i) => i > Math.floor(tariffDetails.length / 2))
    .map(mapBulletText)
    .filter((element) => element !== null)

  return (
    <ProductDetailsTitleStyled withImage={!!image}>
      {image && (
        <div>
          <img alt={name} src={config.IMAGE_HOST + image} />
        </div>
      )}
      <div>
        <H1>{name}</H1>
        {subtitle && <H2>{subtitle}</H2>}
        {(showShortDescription || showShortDescription === undefined) &&
          description && <div className="short-description">{description}</div>}
        {tariffDetailsTitle && <H2>{tariffDetailsTitle}</H2>}
        {tariffDetails.length > 0 && (
          <div className="tariff-details">
            <div>{tariffDetailsWithBulletsFirstPart}</div>
            <div>{tariffDetailsWithBulletsSecondPart}</div>
          </div>
        )}
      </div>
    </ProductDetailsTitleStyled>
  )
}

export default ProductDetailsTitle
