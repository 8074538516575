const generateScreenshotPack = (data: any) => {
  const firstName = data.fields?.first_name
  const lastName = data.fields?.last_name
  const signatureName = `${firstName}_${lastName}.png`

  const screenshot: any = {}
  const screenshotPack = data.fields?.signature
  let screenshotSizeInBytes = 0

  if (typeof screenshotPack === 'string') {
    const screenshotStringLength =
      screenshotPack?.length - 'data:image/png;base64,'.length

    screenshotSizeInBytes =
      4 * Math.ceil(screenshotStringLength / 3) * 0.5624896334383812
  }

  if (screenshotPack) {
    screenshot['file_type'] = screenshotPack.file_type || 'png'
    screenshot['file_size'] = screenshotPack.file_size || screenshotSizeInBytes
    screenshot['original_name'] = signatureName
    screenshot['base64'] = screenshotPack.base64 || screenshotPack
  }

  return screenshot
}

const deleteMultiFiles = (data: any) => {
  delete data.fields?.signature
  delete data.additional_user_input?.signature
  delete data.fields?.upload_file
  delete data.additional_user_input?.upload_files

  return data
}

export { generateScreenshotPack, deleteMultiFiles }
