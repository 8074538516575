import { styled } from '@epilot/components'

import { Theme } from 'modules/app/types'

type ProductPricesStyledProps = {
  theme: Theme
  variant: 'bigger' | 'default'
}

const ProductPricesStyled = styled.div`
  display: flex;
  flex-direction: ${({ variant }: ProductPricesStyledProps) =>
    variant === 'bigger' ? 'column' : 'row'};
  margin-top: ${({ variant }: ProductPricesStyledProps) =>
    variant === 'bigger' ? 0 : '16px'};

  > div:not(:first-child) {
    margin-top: ${({ variant, theme }: ProductPricesStyledProps) =>
      variant === 'bigger' ? `${theme.spacing(3)}px` : '0'};
  }
`

export default ProductPricesStyled
