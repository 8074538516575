import { AddressModuleProps } from '@epilot/base-modules'

import config from 'config'
import { ProductCategory } from 'modules/shop/types'
import { BlueprintElements, BlueprintGridElement } from 'types/blueprint-config'

import {
  buildAddressControl,
  buildCardBlock,
  buildGridContainerBlock,
  buildGridItemBlock,
  buildH2Block,
  buildLabelBlock,
  buildTabBlock,
  includeShowEffect,
  buildConsumptionCalculatorControl,
  buildNextButton,
} from '../block-builders'
import { defaultConsumptionCalculatorSchemaProperties } from '../schemas/consumption-calculator.schema'

const consumptionCalculatorInputLabels = {
  POSTALCODE_CITY_LABEL: 'Postleitzahl',
  STREET_LABEL: 'Straße',
  HOUSE_NUMBER_LABEL: 'Hausnr.',
  POSTALCODE_CITY_PLACEHOLDER: 'z.B. 44135 Dortmund',
  STREET_PLACEHOLDER: 'z.B. Gustav-Stresemann-Allee',
  HOUSE_NUMBER_PLACEHOLDER: 'z.B. 49a',
  ICON_LABEL: 'Iconed input',
}

export const buildConsumptionCalculatorBlock = (
  productCategory: ProductCategory | undefined,
  showIconPower?: boolean,
  showIconGas?: boolean,
  subheadlineText?: string,
): BlueprintGridElement => {
  const gridContainerElements: BlueprintElements[] = []

  // Build Tab
  const tabValues =
    productCategory?.toUpperCase() === 'POWER'
      ? [{ label: 'Strom', icons: ['power'] }]
      : productCategory?.toUpperCase() === 'GAS'
      ? [{ label: 'Gas', icons: ['gas'] }]
      : [
          { label: 'Strom', icons: ['power'] },
          { label: 'Gas', icons: ['gas'] },
          { label: 'Strom & Gas', icons: ['power', 'gas'] },
        ]
  const tabControl = buildTabBlock(
    tabValues,
    defaultConsumptionCalculatorSchemaProperties.selected_tab.name,
  )

  gridContainerElements.push(tabControl)

  const cardContainerElements: BlueprintElements[] = []

  // Build Headline Message
  const headlineContainerElements: BlueprintElements[] = []
  const headlineLabelControl = buildH2Block(
    'In wenigen Schritten zu Ihrem Tarif',
    {
      color: 'textPrimary',
      style: { fontWeigth: 600 },
    },
  )
  const headlineGridItem = buildGridItemBlock([headlineLabelControl], {
    xs: 12,
    style: { marginBottom: '32px' },
  })

  headlineContainerElements.push(headlineGridItem)

  if (subheadlineText) {
    const subheadlineLabelControl = buildLabelBlock(subheadlineText, {
      variant: 'body1',
      color: 'textPrimary',
    })
    const subheadlineGridItem = buildGridItemBlock([subheadlineLabelControl], {
      xs: 12,
    })

    headlineContainerElements.push(subheadlineGridItem)
  }

  const headlineContainer = buildGridContainerBlock(headlineContainerElements, {
    spacing: 2,
  })

  cardContainerElements.push(headlineContainer)

  // Build Single Product Tab
  const singleProductTabContainer = buildTabContainerBlock({
    bundle: false,
    showIconPower,
    showIconGas,
  })

  cardContainerElements.push(singleProductTabContainer)

  // Build Bundle Product Tab
  const bundleProductTabContainer = buildTabContainerBlock({
    bundle: true,
    showIconPower,
    showIconGas,
  })

  cardContainerElements.push(bundleProductTabContainer)

  const cardContainer = buildCardBlock(cardContainerElements, {
    style: {
      borderTopLeftRadius: 0,
      flexDirection: 'column',
      padding: '8px 0 44px',
      width: '100%',
    },
  })

  gridContainerElements.push(cardContainer)

  const gridContainer = buildGridContainerBlock(gridContainerElements, {})

  return gridContainer
}

const buildTabContainerBlock = ({
  bundle,
  showIconPower,
  showIconGas,
}: {
  bundle: boolean
  showIconPower?: boolean
  showIconGas?: boolean
}) => {
  const layoutContainerElements: BlueprintElements[] = []

  // Build Address Control (with Address)
  const showWithAddressAddressControlGridContainer = buildAddressControlBlock({
    withAddress: true,
    bundle,
    showIcon: showIconPower || showIconGas,
  })

  layoutContainerElements.push(showWithAddressAddressControlGridContainer)

  // Build Consumption Calculator (with Address)
  const calculatorWithAddressContainer = buildCalculatorBlock({
    withAddress: true,
    bundle,
    showIconPower,
    showIconGas,
  })

  layoutContainerElements.push(calculatorWithAddressContainer)

  // Build Address Control (without Address)
  const showAddressControlGridContainer = buildAddressControlBlock({
    withAddress: false,
    bundle,
    showIcon: showIconPower || showIconGas ? true : false,
  })

  layoutContainerElements.push(showAddressControlGridContainer)

  // Build Consumption Calculator (without Address)
  const calculatorContainer = buildCalculatorBlock({
    withAddress: false,
    bundle,
    showIconPower,
    showIconGas,
  })

  layoutContainerElements.push(calculatorContainer)

  const layoutContainer = buildGridContainerBlock(layoutContainerElements, {
    spacing: 4,
    style: {
      justifyContent: 'flex-end',
    },
  })

  return includeShowEffect(
    layoutContainer,
    defaultConsumptionCalculatorSchemaProperties.selected_tab.name,
    undefined,
    bundle ? ['Strom & Gas'] : ['Strom', 'Gas'],
  )
}

const buildAddressControlBlock = ({
  withAddress,
  bundle,
  showIcon,
}: {
  withAddress: boolean
  bundle: boolean
  showIcon?: boolean
}) => {
  const addressControlGridContainerElements: BlueprintElements[] = []

  const addressControlOptions: AddressModuleProps = {
    hideRequiredAsterisk: true,
    labels: {
      zipCityLabel: consumptionCalculatorInputLabels.POSTALCODE_CITY_LABEL,
      streetLabel: consumptionCalculatorInputLabels.STREET_LABEL,
      housenumberLabel: consumptionCalculatorInputLabels.HOUSE_NUMBER_LABEL,
    },
    placeholders: {
      zipCityPlaceholder:
        consumptionCalculatorInputLabels.POSTALCODE_CITY_PLACEHOLDER,
      streetPlaceholder: consumptionCalculatorInputLabels.STREET_PLACEHOLDER,
      housenumberPlaceholder:
        consumptionCalculatorInputLabels.HOUSE_NUMBER_PLACEHOLDER,
    },
    fieldsOrder: [
      {
        name: 'ZIP_CITY',
        gridItemProps: { xs: 12, sm: 12, md: 12, lg: withAddress ? 4 : 12 },
      },
    ],
    streetAddressAPI:
      config.API_POSTALCODE_ADDRESS +
      '/v1/integration/location/%country%/streets?postal_code=%zip%&city=%city%&street=%street%',
    cityZipAPI:
      config.API_POSTALCODE_ADDRESS +
      '/v1/integration/location/%country%/postalcode-cities?postalcode_city=%postalcode_city%',
    zipCitySplitString: ' ',
    containerProps: {
      spacing: 4,
    },
    requiredFields: {
      zipCity: true,
    },
    acceptOnlySuggestedAddresses: true,
  }

  if (withAddress) {
    addressControlOptions.fieldsOrder.push({
      name: 'STREET',
      gridItemProps: { xs: 8, sm: 9, md: 9, lg: 6 },
    })
    addressControlOptions.fieldsOrder.push({
      name: 'HOUSE',
      gridItemProps: { xs: 4, sm: 3, md: 3, lg: 2 },
    })
    if (!addressControlOptions.requiredFields)
      addressControlOptions.requiredFields = {}
    addressControlOptions.requiredFields.street = true
    addressControlOptions.requiredFields.housenumber = true
  }

  const addressControl = buildAddressControl(
    defaultConsumptionCalculatorSchemaProperties.postalcode_city.name,
    addressControlOptions,
  )

  addressControlGridContainerElements.push(addressControl)

  const addressControlGridContainer = buildGridItemBlock(
    addressControlGridContainerElements,
    { xs: 12, md: 6, lg: bundle ? (showIcon ? 2 : 6) : !withAddress ? 6 : 8 },
  )

  return includeShowEffect(
    addressControlGridContainer,
    `power/properties/showAdditionalFields`,
    withAddress,
  )
}

const buildCalculatorBlock = ({
  withAddress,
  bundle,
  showIconPower,
  showIconGas,
}: {
  withAddress: boolean
  bundle: boolean
  showIconPower?: boolean
  showIconGas?: boolean
}) => {
  const calculatorGridContainerElements: BlueprintElements[] = []
  const calculatorControlGridContainerElements: BlueprintElements[] = []

  // Build Power Consumption Calculator
  const powerCalculatorControl = buildConsumptionCalculatorControl(
    defaultConsumptionCalculatorSchemaProperties.power_consumption.name,
    {
      variant: showIconPower ? 'icons' : 'strings',
      optionsTresholds: [1500, 2500, 3500, 4500, 5500],
      optionsLabels: [
        '1.500 kWh (1 Person)',
        '2.500 kWh (2 Personen)',
        '3.500 kWh (3 Personen)',
        '4.500 kWh (4 Personen)',
        '5.500 kWh (5 Personen)',
      ],
      placeholder: 'kWh/Jahr',
      label: bundle ? 'Jährl. Verbrauch (Strom)' : 'Jährl. Verbrauch',
      showIcons: showIconPower,
      icon: 'user',
      iconLabel: 'Personen',
      iconsPlacement: 'start',
      textInputLabel: bundle ? 'Jährl. Verbrauch (Strom)' : 'Jährl. Verbrauch',
      inputPlaceholder: 'kWh',
      iconSpacing: '8px',
    },
  )

  const powerCalculatorControlGridItem = buildGridItemBlock(
    [powerCalculatorControl],
    {
      xs: 12,
      sm: 12,
      md: bundle ? 6 : 12,
      lg: bundle ? (showIconPower ? 5 : 4) : withAddress ? 6 : 8,
    },
  )

  const showPowerCalculatorControlGridItem = includeShowEffect(
    powerCalculatorControlGridItem,
    defaultConsumptionCalculatorSchemaProperties.selected_tab.name,
    undefined,
    bundle ? ['Strom & Gas'] : ['Strom'],
  )

  calculatorControlGridContainerElements.push(
    showPowerCalculatorControlGridItem,
  )

  // Build Gas Consumption Calculator
  const gasCalculatorControl = buildConsumptionCalculatorControl(
    defaultConsumptionCalculatorSchemaProperties.gas_consumption.name,
    {
      variant: showIconGas ? 'icons' : 'strings',
      optionsTresholds: [7500, 15000, 22500, 27000],
      optionsLabels: [
        '7.500 kWh (50 m²)',
        '15.000 kWh (100 m²)',
        '22.500 kWh (150 m²)',
        '27.000 kWh (200 m²)',
      ],
      placeholder: 'kWh/Jahr',
      label: bundle ? 'Jährl. Verbrauch (Gas)' : 'Jährl. Verbrauch',
      showIcons: showIconGas,
      icon: 'home',
      iconLabel: 'Wohnfläche in m2',
      iconsPlacement: 'start',
      textInputLabel: bundle ? 'Jährl. Verbrauch (Gas)' : 'Jährl. Verbrauch',
      inputPlaceholder: 'kWh',
      iconLabels: ['50 m²', '100 m²', '150 m²', '200 m²'],
      iconSpacing: '8px',
    },
  )

  const gasCalculatorControlGridItem = buildGridItemBlock(
    [gasCalculatorControl],
    {
      xs: 12,
      sm: 12,
      md: bundle ? 6 : 12,
      lg: bundle ? (showIconGas ? 5 : 4) : withAddress ? 6 : 8,
    },
  )

  const showGasCalculatorControlGridItem = includeShowEffect(
    gasCalculatorControlGridItem,
    defaultConsumptionCalculatorSchemaProperties.selected_tab.name,
    undefined,
    bundle ? ['Strom & Gas'] : ['Gas'],
  )

  calculatorControlGridContainerElements.push(showGasCalculatorControlGridItem)

  // Build Next Button
  const nextButton = buildNextButton(
    'Berechnen',
    {
      variant: 'contained',
      action: 'loadAmountGridOperator',
      fullWidth: true,
      color: 'primary',
      control: true,
    },
    defaultConsumptionCalculatorSchemaProperties.grid_operator_button.name,
  )

  const nextButtonGridItem = buildGridItemBlock([nextButton], {
    xs: 12,
    sm: 12,
    lg: bundle ? (showIconGas && showIconPower ? 2 : 4) : !withAddress ? 4 : 6,
  })

  calculatorControlGridContainerElements.push(nextButtonGridItem)

  const calculatorControlGridContainer = buildGridContainerBlock(
    calculatorControlGridContainerElements,
    { spacing: 4 },
  )

  calculatorGridContainerElements.push(calculatorControlGridContainer)

  const calculatorGridContainer = buildGridItemBlock(
    calculatorGridContainerElements,
    {
      xs: 12,
      md: 6,
      lg: bundle
        ? showIconGas || showIconPower
          ? 10
          : 6
        : !withAddress
        ? 6
        : 4,
    },
  )

  return includeShowEffect(
    calculatorGridContainer,
    `power/properties/showAdditionalFields`,
    withAddress,
  )
}
