import { Box } from '@epilot/base-elements'
import { Autocomplete, MuiHidden } from '@epilot/components'
import {
  and,
  ControlProps,
  isObjectControl,
  optionIs,
  RankedTester,
  rankWith,
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import React from 'react'
import { useSelector } from 'react-redux'

import { generateStringLabel } from 'lib/is-required-scope'
import { getShowErrors } from 'modules/step/selectors'

import { getHtmlId, isRequiredScope } from '../../../lib'
import postalCodes from '../../../lib/postalcode-mapping/postalcode-city-mappings'
import { useOverwritePlaceholderStyles } from '../styles'

const PostalcodeCityControl = ({
  id,
  data,
  label,
  visible,
  handleChange,
  path,
  uischema,
  errors,
  rootSchema,
}: ControlProps) => {
  const classes = useOverwritePlaceholderStyles()
  const htmlId = getHtmlId(id)
  const showErrors = useSelector(getShowErrors)
  const isRequired = isRequiredScope(rootSchema, uischema.scope)
  const stringLabel = generateStringLabel(label as string, isRequired)

  return (
    <MuiHidden xsUp={!visible}>
      <Box className={classes.inputOverwrite}>
        <Autocomplete
          disableOpenOnFocus
          error={showErrors && errors.length > 0}
          filterOption={(postalCode, value) => {
            if (!value) return false
            const splitedValue = value.split(' ')

            if (splitedValue.length > 1) {
              return (
                postalCode.postalcode.startsWith(splitedValue[0]) &&
                postalCode.city.startsWith(splitedValue[1])
              )
            } else {
              return (
                postalCode.postalcode.startsWith(value) ||
                postalCode.city
                  .toLocaleLowerCase()
                  .startsWith(value?.toLocaleLowerCase())
              )
            }
          }}
          getOptionLabel={(postalCode) =>
            postalCode.postalcode
              ? `${postalCode.postalcode} ${postalCode.city}`
              : postalCode.city
          }
          helperText={showErrors ? errors : ''}
          id={htmlId}
          label={stringLabel}
          name={htmlId}
          noOptionsText={uischema?.options?.noOptionsText}
          onChange={(_, value) => handleChange(path, value)}
          options={postalCodes}
          placeholder={uischema?.options?.placeholder}
          value={data ?? { postalcode: '', city: '' }}
        />
      </Box>
    </MuiHidden>
  )
}

export default withJsonFormsControlProps(PostalcodeCityControl)
export const postalcodeCityTester: RankedTester = rankWith(
  99,
  and(isObjectControl, optionIs('format', 'postalcode-city')),
)
