import config from 'config'
import { ProductCategory } from 'modules/shop/types'
import { BlueprintElements } from 'types/blueprint-config'

import {
  buildGridContainerBlock,
  buildGridItemBlock,
  buildRadioControlBlock,
  buildBooleanControlBlock,
  buildInputControlBlock,
  buildMeterReadingControlBlock,
  buildStackSpacingBlock,
  buildLabelBlock,
  includeShowEffect,
  includeEffectMultipleConditions,
  buildPreviousProviderControlBlock,
  includeHideEffect,
} from '../block-builders'
import {
  defaultContractCancellationSchemaProperties,
  SITUATION_POWER_ENUM_VALUES,
  SITUATION_GAS_ENUM_VALUES,
} from '../schemas/contract-cancellation.schema'

const contractCancellationInputLabels = {
  SITUATION_POWER: 'Welche Situation trifft auf Sie zu?',
  TERMINATE_CONTRACT_POWER:
    'Sollen wir Ihren bestehenden Vertrag für Sie kündigen?',
  FIRST_TIME_OCCUPANCY_POWER:
    'Handelt es sich um einen Erstbezug in einem Neubau?',
  TERMINATION_DATE_POWER: 'Kündigungstermin',
  DELIVERY_DATE_POWER: 'Gewünschter Lieferbeginn',
  DELIVERY_DATE_ASAP_POWER: 'Schnellstmöglich',
  PREVIOUS_PROVIDER_POWER: 'Bisheriger Anbieter',
  MOVING_DATE_POWER: 'Einzugdatum',
  METER_READING_POWER: 'Ihr Zähler',
  TERMINATION_DATE_GAS: 'Kündigungstermin',
  SITUATION_GAS: 'Welche Situation trifft auf Sie zu?',
  TERMINATE_CONTRACT_GAS:
    'Sollen wir Ihren bestehenden Vertrag für Sie kündigen?',
  FIRST_TIME_OCCUPANCY_GAS:
    'Handelt es sich um einen Erstbezug in einem Neubau?',
  DELIVERY_DATE_GAS: 'Gewünschter Lieferbeginn',
  DELIVERY_DATE_ASAP_GAS: 'Schnellstmöglich',
  PREVIOUS_PROVIDER_GAS: 'Bisheriger Anbieter',
  MOVING_DATE_GAS: 'Einzugdatum',
  METER_READING_GAS: 'Ihr Zähler',
  DESIRED_DATE_LABEL: 'Wunschtermin',
}
const generateContractCancellationInputLabels = (isRequireDates?: boolean) => {
  const contractCancellationInputLabels = {
    SITUATION_POWER: 'Welche Situation trifft auf Sie zu?',
    TERMINATE_CONTRACT_POWER:
      'Sollen wir Ihren bestehenden Vertrag für Sie kündigen?',
    FIRST_TIME_OCCUPANCY_POWER:
      'Handelt es sich um einen Erstbezug in einem Neubau?',
    TERMINATION_DATE_POWER: 'Kündigungstermin',

    DELIVERY_DATE_ASAP_POWER: 'Schnellstmöglich',
    PREVIOUS_PROVIDER_POWER: 'Bisheriger Anbieter',
    MOVING_DATE_POWER: 'Einzugdatum',
    METER_READING_POWER: 'Ihr Zähler',
    TERMINATION_DATE_GAS: 'Kündigungstermin',
    SITUATION_GAS: 'Welche Situation trifft auf Sie zu?',
    TERMINATE_CONTRACT_GAS:
      'Sollen wir Ihren bestehenden Vertrag für Sie kündigen?',
    FIRST_TIME_OCCUPANCY_GAS:
      'Handelt es sich um einen Erstbezug in einem Neubau?',
    DELIVERY_DATE_ASAP_GAS: 'Schnellstmöglich',
    PREVIOUS_PROVIDER_GAS: 'Bisheriger Anbieter',
    MOVING_DATE_GAS: 'Einzugdatum',
    METER_READING_GAS: 'Ihr Zähler',
    DESIRED_DATE_LABEL: 'Wunschtermin',
    DELIVERY_DATE_POWER: isRequireDates
      ? 'Gewünschter Lieferbeginn *'
      : 'Gewünschter Lieferbeginn',
    DELIVERY_DATE_GAS: isRequireDates
      ? 'Gewünschter Lieferbeginn *'
      : 'Gewünschter Lieferbeginn',
  }

  return contractCancellationInputLabels
}

type ContractCancellationBuildProps = {
  category: ProductCategory
  showPreviousProvider?: boolean
  previousProviderApiEndpoint?: string
  situationText?: string
  terminateContractText?: string
  showMeterNumber?: boolean
  showMarketLocationId?: boolean
  showMeterReading?: boolean
  showAddButton?: boolean
  showAsSummary?: boolean
  isRequireDates?: boolean
}

// Build Contract Cancellation
export const buildContractCancellationBlock = ({
  category,
  showPreviousProvider = true,
  previousProviderApiEndpoint = config.API_PREVIOUS_PROVIDER,
  showMeterNumber = true,
  showMarketLocationId = true,
  showMeterReading = true,
  showAddButton = true,
  isRequireDates = true,
}: ContractCancellationBuildProps): BlueprintElements => {
  const gridContainerElements = []
  const contractCancellationInputLabels = generateContractCancellationInputLabels(
    isRequireDates,
  )

  if (category?.toLocaleLowerCase() === 'power') {
    // Build Situation Label (Power)
    const situationLabel = buildLabelBlock(
      contractCancellationInputLabels.SITUATION_POWER,
    )

    // Build Situation (Power)
    const situationControl = buildRadioControlBlock(
      defaultContractCancellationSchemaProperties.situation_power.name,
      '',
      {
        clearPathOnChange: [
          defaultContractCancellationSchemaProperties.terminate_contract_power
            .name,
          defaultContractCancellationSchemaProperties.first_time_occupancy_power
            .name,
          defaultContractCancellationSchemaProperties.delivery_date_power.name,
          defaultContractCancellationSchemaProperties.termination_date_power
            .name,
          defaultContractCancellationSchemaProperties.previous_provider_power
            .name,
          defaultContractCancellationSchemaProperties.moving_date_power.name,
        ],
      },
    )
    const situationControlStackSpacing = buildStackSpacingBlock(
      [situationLabel, situationControl],
      { scale: 1 },
    )
    const situationControlGridItem = buildGridItemBlock(
      [situationControlStackSpacing],
      {
        xs: 12,
      },
    )

    gridContainerElements.push(situationControlGridItem)

    // Build Terminate Contract Label (Power)
    const terminateContractLabel = buildLabelBlock(
      contractCancellationInputLabels.TERMINATE_CONTRACT_POWER,
    )

    // Build Terminate Contract (Power)
    const terminateContractControl = buildRadioControlBlock(
      defaultContractCancellationSchemaProperties.terminate_contract_power.name,
      '',
      {
        clearPathOnChange: [
          defaultContractCancellationSchemaProperties.delivery_date_power.name,
          defaultContractCancellationSchemaProperties.termination_date_power
            .name,
          defaultContractCancellationSchemaProperties.previous_provider_power
            .name,
        ],
      },
    )
    const terminateContractControlStackSpacing = buildStackSpacingBlock(
      [terminateContractLabel, terminateContractControl],
      { scale: 1 },
    )

    const terminateContractControlGridItem = buildGridItemBlock(
      [terminateContractControlStackSpacing],
      {
        xs: 12,
      },
    )
    const showTerminateContractControlGridItem = includeShowEffect(
      terminateContractControlGridItem,
      defaultContractCancellationSchemaProperties.situation_power.name,
      SITUATION_POWER_ENUM_VALUES[0],
    )

    gridContainerElements.push(showTerminateContractControlGridItem)

    // Build First Time Occupancy Label (Power)
    const firstTimeOccupancyLabel = buildLabelBlock(
      contractCancellationInputLabels.FIRST_TIME_OCCUPANCY_POWER,
    )

    // Build First Time Occupancy (Power)
    const firstTimeOccupancyControl = buildRadioControlBlock(
      defaultContractCancellationSchemaProperties.first_time_occupancy_power
        .name,
      '',
    )
    const firstTimeOccupancyControlStackSpacing = buildStackSpacingBlock(
      [firstTimeOccupancyLabel, firstTimeOccupancyControl],
      { scale: 1 },
    )

    const firstTimeOccupancyControlGridItem = buildGridItemBlock(
      [firstTimeOccupancyControlStackSpacing],
      {
        xs: 12,
      },
    )
    const showFirstTimeOccupancyControlGridItem = includeShowEffect(
      firstTimeOccupancyControlGridItem,
      defaultContractCancellationSchemaProperties.situation_power.name,
      SITUATION_POWER_ENUM_VALUES[1],
    )

    gridContainerElements.push(showFirstTimeOccupancyControlGridItem)

    // Build Delivery Date Label (Power)
    const deliveryDateLabel = buildLabelBlock(
      contractCancellationInputLabels.DELIVERY_DATE_POWER,
    )

    // Build Delivery Date Asap (Power)
    const deliveryDateAsapControl = buildBooleanControlBlock(
      defaultContractCancellationSchemaProperties.delivery_date_asap_power.name,
      contractCancellationInputLabels.DELIVERY_DATE_ASAP_POWER,
      {
        clearPathOnChange: [
          defaultContractCancellationSchemaProperties.delivery_date_power.name,
        ],
      },
    )

    // Build Delivery Date (Power)
    const deliveryDateControl = buildInputControlBlock(
      defaultContractCancellationSchemaProperties.delivery_date_power.name,
      contractCancellationInputLabels.DESIRED_DATE_LABEL,
    )
    const hideDeliveryDateControl = includeHideEffect(
      deliveryDateControl,
      defaultContractCancellationSchemaProperties.delivery_date_asap_power.name,
      true,
    )
    const deliveryDateControlStackSpacing = buildStackSpacingBlock(
      [deliveryDateLabel, deliveryDateAsapControl, hideDeliveryDateControl],
      { scale: 1 },
    )
    const deliveryDateControlGridItem = buildGridItemBlock(
      [deliveryDateControlStackSpacing],
      {
        xs: 12,
      },
    )

    const showDeliveryDateControlGridItem = includeEffectMultipleConditions(
      deliveryDateControlGridItem,
      {
        [defaultContractCancellationSchemaProperties.situation_power.name]:
          SITUATION_POWER_ENUM_VALUES[0],
        [defaultContractCancellationSchemaProperties.terminate_contract_power
          .name]: 'Ja',
      },
      'SHOW',
    )

    gridContainerElements.push(showDeliveryDateControlGridItem)

    // Build Terminate Date (Power)
    const terminationDateControl = buildInputControlBlock(
      defaultContractCancellationSchemaProperties.termination_date_power.name,
      contractCancellationInputLabels.TERMINATION_DATE_GAS,
    )
    const terminationDateControlStackSpacing = buildStackSpacingBlock(
      [terminationDateControl],
      { scale: 1 },
    )
    const terminationDateControlGridItem = buildGridItemBlock(
      [terminationDateControlStackSpacing],
      {
        xs: 12,
      },
    )

    const showTerminationDateControlGridItem = includeEffectMultipleConditions(
      terminationDateControlGridItem,
      {
        [defaultContractCancellationSchemaProperties.situation_power.name]:
          SITUATION_POWER_ENUM_VALUES[0],
        [defaultContractCancellationSchemaProperties.terminate_contract_power
          .name]: 'Nein',
      },
      'SHOW',
    )

    gridContainerElements.push(showTerminationDateControlGridItem)

    // Build Previous Provider (Power)
    if (showPreviousProvider) {
      const previousProviderControl = buildPreviousProviderControlBlock(
        defaultContractCancellationSchemaProperties.previous_provider_power
          .name,
        contractCancellationInputLabels.PREVIOUS_PROVIDER_POWER,
        previousProviderApiEndpoint,
      )
      const previousProviderControlGridItem = buildGridItemBlock(
        [previousProviderControl],
        {
          xs: 12,
        },
      )

      const showPreviousProviderControlGridItem = includeEffectMultipleConditions(
        previousProviderControlGridItem,
        {
          [defaultContractCancellationSchemaProperties.situation_power.name]:
            SITUATION_POWER_ENUM_VALUES[0],
          [defaultContractCancellationSchemaProperties.terminate_contract_power
            .name]: 'Ja',
        },
        'SHOW',
      )

      gridContainerElements.push(showPreviousProviderControlGridItem)
    }

    // Build Moving Date (Power)
    const movingDateControl = buildBooleanControlBlock(
      defaultContractCancellationSchemaProperties.moving_date_power.name,
      contractCancellationInputLabels.MOVING_DATE_POWER,
    )
    const movingDateControlGridItem = buildGridItemBlock([movingDateControl], {
      xs: 12,
    })

    const showMovingDateControlGridItem = includeShowEffect(
      movingDateControlGridItem,
      defaultContractCancellationSchemaProperties.situation_power.name,
      SITUATION_POWER_ENUM_VALUES[1],
    )

    gridContainerElements.push(showMovingDateControlGridItem)

    // Build Meter Reading (Power - Change supplier)
    const meterReadingChangeSupplierControl = buildMeterReadingControlBlock(
      defaultContractCancellationSchemaProperties.power_readings.name,
      {
        title: contractCancellationInputLabels.METER_READING_POWER,
        showMeterNumber,
        showMarketLocationId,
        showMeterReading,
        showAddButton,
      },
    )
    const showMeterReadingChangeSupplierControl = includeShowEffect(
      meterReadingChangeSupplierControl,
      defaultContractCancellationSchemaProperties.situation_power.name,
      SITUATION_POWER_ENUM_VALUES[0],
    )
    const meterReadingChangeSupplierControlGridItem = buildGridItemBlock(
      [showMeterReadingChangeSupplierControl],
      {
        xs: 12,
        style: {
          marginBottom: '12px',
        },
      },
    )

    gridContainerElements.push(meterReadingChangeSupplierControlGridItem)

    // Build Meter Reading (Power - Moving)
    const meterReadingMovingControl = buildMeterReadingControlBlock(
      defaultContractCancellationSchemaProperties.power_readings.name,
      {
        title: contractCancellationInputLabels.METER_READING_POWER,
        showMeterNumber,
        showMarketLocationId: false,
        showMeterReading,
        showAddButton,
      },
    )
    const showMeterReadingMovingControl = includeShowEffect(
      meterReadingMovingControl,
      defaultContractCancellationSchemaProperties.situation_power.name,
      SITUATION_POWER_ENUM_VALUES[1],
    )
    const meterReadingMovingControlGridItem = buildGridItemBlock(
      [showMeterReadingMovingControl],
      {
        xs: 12,
        style: {
          marginBottom: '12px',
        },
      },
    )

    gridContainerElements.push(meterReadingMovingControlGridItem)
  } else if (category?.toLocaleLowerCase() === 'gas') {
    // Build Situation Label (Gas)
    const situationLabel = buildLabelBlock(
      contractCancellationInputLabels.SITUATION_GAS,
    )

    // Build Situation (Gas)
    const situationControl = buildRadioControlBlock(
      defaultContractCancellationSchemaProperties.situation_gas.name,
      '',
      {
        clearPathOnChange: [
          defaultContractCancellationSchemaProperties.terminate_contract_gas
            .name,
          defaultContractCancellationSchemaProperties.first_time_occupancy_gas
            .name,
          defaultContractCancellationSchemaProperties.delivery_date_gas.name,
          defaultContractCancellationSchemaProperties.termination_date_gas.name,
          defaultContractCancellationSchemaProperties.previous_provider_gas
            .name,
          defaultContractCancellationSchemaProperties.moving_date_gas.name,
        ],
      },
    )
    const situationControlStackSpacing = buildStackSpacingBlock(
      [situationLabel, situationControl],
      { scale: 1 },
    )
    const situationControlGridItem = buildGridItemBlock(
      [situationControlStackSpacing],
      {
        xs: 12,
      },
    )

    gridContainerElements.push(situationControlGridItem)

    // Build Terminate Contract Label (Gas)
    const terminateContractLabel = buildLabelBlock(
      contractCancellationInputLabels.TERMINATE_CONTRACT_GAS,
    )

    // Build Terminate Contract (Gas)
    const terminateContractControl = buildRadioControlBlock(
      defaultContractCancellationSchemaProperties.terminate_contract_gas.name,
      '',
      {
        clearPathOnChange: [
          defaultContractCancellationSchemaProperties.delivery_date_gas.name,
          defaultContractCancellationSchemaProperties.termination_date_gas.name,
          defaultContractCancellationSchemaProperties.previous_provider_gas
            .name,
        ],
      },
    )
    const terminateContractControlStackSpacing = buildStackSpacingBlock(
      [terminateContractLabel, terminateContractControl],
      { scale: 1 },
    )

    const terminateContractControlGridItem = buildGridItemBlock(
      [terminateContractControlStackSpacing],
      {
        xs: 12,
      },
    )

    const showTerminateContractControlGridItem = includeShowEffect(
      terminateContractControlGridItem,
      defaultContractCancellationSchemaProperties.situation_gas.name,
      SITUATION_GAS_ENUM_VALUES[0],
    )

    gridContainerElements.push(showTerminateContractControlGridItem)

    // Build First Time Occupancy Label (Gas)
    const firstTimeOccupancyLabel = buildLabelBlock(
      contractCancellationInputLabels.FIRST_TIME_OCCUPANCY_GAS,
    )

    // Build First Time Occupancy (Gas)
    const firstTimeOccupancyControl = buildRadioControlBlock(
      defaultContractCancellationSchemaProperties.first_time_occupancy_gas.name,
      '',
    )
    const firstTimeOccupancyControlStackSpacing = buildStackSpacingBlock(
      [firstTimeOccupancyLabel, firstTimeOccupancyControl],
      { scale: 1 },
    )

    const firstTimeOccupancyControlGridItem = buildGridItemBlock(
      [firstTimeOccupancyControlStackSpacing],
      {
        xs: 12,
      },
    )
    const showFirstTimeOccupancyControlGridItem = includeShowEffect(
      firstTimeOccupancyControlGridItem,
      defaultContractCancellationSchemaProperties.situation_gas.name,
      SITUATION_GAS_ENUM_VALUES[1],
    )

    gridContainerElements.push(showFirstTimeOccupancyControlGridItem)

    // Build Delivery Date Label (Gas)
    const deliveryDateLabel = buildLabelBlock(
      contractCancellationInputLabels.DELIVERY_DATE_GAS,
    )

    // Build Delivery Date Asap (Gas)
    const deliveryDateAsapControl = buildBooleanControlBlock(
      defaultContractCancellationSchemaProperties.delivery_date_asap_gas.name,
      contractCancellationInputLabels.DELIVERY_DATE_ASAP_GAS,
      {
        clearPathOnChange: [
          defaultContractCancellationSchemaProperties.delivery_date_gas.name,
        ],
      },
    )

    // Build Delivery Date (Power)
    const deliveryDateControl = buildInputControlBlock(
      defaultContractCancellationSchemaProperties.delivery_date_gas.name,
      contractCancellationInputLabels.DESIRED_DATE_LABEL,
    )
    const hideDeliveryDateControl = includeHideEffect(
      deliveryDateControl,
      defaultContractCancellationSchemaProperties.delivery_date_asap_gas.name,
      true,
    )
    const deliveryDateControlStackSpacing = buildStackSpacingBlock(
      [deliveryDateLabel, deliveryDateAsapControl, hideDeliveryDateControl],
      { scale: 1 },
    )
    const deliveryDateControlGridItem = buildGridItemBlock(
      [deliveryDateControlStackSpacing],
      {
        xs: 12,
      },
    )

    const showDeliveryDateControlGridItem = includeEffectMultipleConditions(
      deliveryDateControlGridItem,
      {
        [defaultContractCancellationSchemaProperties.situation_gas.name]:
          SITUATION_GAS_ENUM_VALUES[0],
        [defaultContractCancellationSchemaProperties.terminate_contract_gas
          .name]: 'Ja',
      },
      'SHOW',
    )

    gridContainerElements.push(showDeliveryDateControlGridItem)

    // Build Terminate Date (Gas)
    const terminationDateControl = buildInputControlBlock(
      defaultContractCancellationSchemaProperties.termination_date_gas.name,
      contractCancellationInputLabels.TERMINATION_DATE_POWER,
    )
    const terminationDateControlStackSpacing = buildStackSpacingBlock(
      [terminationDateControl],
      { scale: 1 },
    )
    const terminationDateControlGridItem = buildGridItemBlock(
      [terminationDateControlStackSpacing],
      {
        xs: 12,
      },
    )

    const showTerminationDateControlGridItem = includeEffectMultipleConditions(
      terminationDateControlGridItem,
      {
        [defaultContractCancellationSchemaProperties.situation_gas.name]:
          SITUATION_GAS_ENUM_VALUES[0],
        [defaultContractCancellationSchemaProperties.terminate_contract_gas
          .name]: 'Nein',
      },
      'SHOW',
    )

    gridContainerElements.push(showTerminationDateControlGridItem)

    // Build Previous Provider (Gas)
    if (showPreviousProvider) {
      const previousProviderControl = buildPreviousProviderControlBlock(
        defaultContractCancellationSchemaProperties.previous_provider_gas.name,
        contractCancellationInputLabels.PREVIOUS_PROVIDER_GAS,
        previousProviderApiEndpoint,
      )
      const previousProviderControlGridItem = buildGridItemBlock(
        [previousProviderControl],
        {
          xs: 12,
        },
      )

      const showPreviousProviderControlGridItem = includeEffectMultipleConditions(
        previousProviderControlGridItem,
        {
          [defaultContractCancellationSchemaProperties.situation_gas.name]:
            SITUATION_POWER_ENUM_VALUES[0],
          [defaultContractCancellationSchemaProperties.terminate_contract_gas
            .name]: 'Ja',
        },
        'SHOW',
      )

      gridContainerElements.push(showPreviousProviderControlGridItem)
    }

    // Build Moving Date (Gas)
    const movingDateControl = buildBooleanControlBlock(
      defaultContractCancellationSchemaProperties.moving_date_gas.name,
      contractCancellationInputLabels.MOVING_DATE_GAS,
    )
    const movingDateControlGridItem = buildGridItemBlock([movingDateControl], {
      xs: 12,
    })

    const showMovingDateControlGridItem = includeShowEffect(
      movingDateControlGridItem,
      defaultContractCancellationSchemaProperties.situation_gas.name,
      SITUATION_POWER_ENUM_VALUES[1],
    )

    gridContainerElements.push(showMovingDateControlGridItem)

    // Build Meter Reading (Gas - Change Supplier)
    const meterReadingChangeSupplierControl = buildMeterReadingControlBlock(
      defaultContractCancellationSchemaProperties.gas_readings.name,
      {
        title: contractCancellationInputLabels.METER_READING_GAS,
        showMeterNumber,
        showMarketLocationId,
        showMeterReading,
        showAddButton,
      },
    )
    const showMeterReadingChangeSupplierControl = includeShowEffect(
      meterReadingChangeSupplierControl,
      defaultContractCancellationSchemaProperties.situation_gas.name,
      SITUATION_GAS_ENUM_VALUES[0],
    )
    const meterReadingChangeSupplierControlGridItem = buildGridItemBlock(
      [showMeterReadingChangeSupplierControl],
      {
        xs: 12,
        style: {
          marginBottom: '12px',
        },
      },
    )

    gridContainerElements.push(meterReadingChangeSupplierControlGridItem)

    // Build Meter Reading (Gas - Moving)
    const meterReadingMovingControl = buildMeterReadingControlBlock(
      defaultContractCancellationSchemaProperties.gas_readings.name,
      {
        title: contractCancellationInputLabels.METER_READING_GAS,
        showMeterNumber,
        showMarketLocationId,
        showMeterReading,
        showAddButton,
      },
    )
    const showMeterReadingMovingControl = includeShowEffect(
      meterReadingMovingControl,
      defaultContractCancellationSchemaProperties.situation_gas.name,
      SITUATION_GAS_ENUM_VALUES[1],
    )
    const meterReadingMovingControlGridItem = buildGridItemBlock(
      [showMeterReadingMovingControl],
      {
        xs: 12,
        style: {
          marginBottom: '12px',
        },
      },
    )

    gridContainerElements.push(meterReadingMovingControlGridItem)
  }

  // Build Container
  const gridContainer = buildGridContainerBlock(gridContainerElements, {
    spacing: 3,
    alignItems: 'flex-end',
  })

  return gridContainer
}

// Build Contract Cancellation Summary
export const buildContractCancellationSummaryBlock = ({
  category,
  showMeterNumber = true,
  showMarketLocationId = true,
  showMeterReading = true,
  showAddButton = false,
  showAsSummary = true,
}: ContractCancellationBuildProps): BlueprintElements => {
  const gridContainerElements = []

  if (category?.toLocaleLowerCase() === 'power') {
    // Build Meter Reading (Power - Change supplier)
    const meterReadingChangeSupplierControl = buildMeterReadingControlBlock(
      defaultContractCancellationSchemaProperties.power_readings.name,
      {
        title: contractCancellationInputLabels.METER_READING_POWER,
        showMeterNumber,
        showMarketLocationId,
        showMeterReading,
        showAddButton,
        showAsSummary,
      },
    )
    const showMeterReadingChangeSupplierControl = includeShowEffect(
      meterReadingChangeSupplierControl,
      defaultContractCancellationSchemaProperties.situation_power.name,
      SITUATION_POWER_ENUM_VALUES[0],
    )
    const meterReadingChangeSupplierControlGridItem = buildGridItemBlock(
      [showMeterReadingChangeSupplierControl],
      {
        xs: 12,
        style: {
          padding: '0px',
        },
      },
    )

    gridContainerElements.push(meterReadingChangeSupplierControlGridItem)

    // Build Meter Reading (Power - Moving)
    const meterReadingMovingControl = buildMeterReadingControlBlock(
      defaultContractCancellationSchemaProperties.power_readings.name,
      {
        title: contractCancellationInputLabels.METER_READING_POWER,
        showMeterNumber,
        showMarketLocationId: false,
        showMeterReading,
        showAddButton,
        showAsSummary,
      },
    )
    const showMeterReadingMovingControl = includeShowEffect(
      meterReadingMovingControl,
      defaultContractCancellationSchemaProperties.situation_power.name,
      SITUATION_POWER_ENUM_VALUES[1],
    )
    const meterReadingMovingControlGridItem = buildGridItemBlock(
      [showMeterReadingMovingControl],
      {
        xs: 12,
        style: {
          padding: '0px',
        },
      },
    )

    gridContainerElements.push(meterReadingMovingControlGridItem)
  } else if (category?.toLocaleLowerCase() === 'gas') {
    // Build Meter Reading (Gas - Change Supplier)
    const meterReadingChangeSupplierControl = buildMeterReadingControlBlock(
      defaultContractCancellationSchemaProperties.gas_readings.name,
      {
        title: contractCancellationInputLabels.METER_READING_GAS,
        showMeterNumber,
        showMarketLocationId,
        showMeterReading,
        showAddButton,
        showAsSummary,
      },
    )
    const showMeterReadingChangeSupplierControl = includeShowEffect(
      meterReadingChangeSupplierControl,
      defaultContractCancellationSchemaProperties.situation_gas.name,
      SITUATION_GAS_ENUM_VALUES[0],
    )
    const meterReadingChangeSupplierControlGridItem = buildGridItemBlock(
      [showMeterReadingChangeSupplierControl],
      {
        xs: 12,
        style: {
          padding: '0px',
        },
      },
    )

    gridContainerElements.push(meterReadingChangeSupplierControlGridItem)

    // Build Meter Reading (Gas - Moving)
    const meterReadingMovingControl = buildMeterReadingControlBlock(
      defaultContractCancellationSchemaProperties.gas_readings.name,
      {
        title: contractCancellationInputLabels.METER_READING_GAS,
        showMeterNumber,
        showMarketLocationId,
        showMeterReading,
        showAddButton,
        showAsSummary,
      },
    )
    const showMeterReadingMovingControl = includeShowEffect(
      meterReadingMovingControl,
      defaultContractCancellationSchemaProperties.situation_gas.name,
      SITUATION_GAS_ENUM_VALUES[1],
    )
    const meterReadingMovingControlGridItem = buildGridItemBlock(
      [showMeterReadingMovingControl],
      {
        xs: 12,
        style: {
          padding: '0px',
        },
      },
    )

    gridContainerElements.push(meterReadingMovingControlGridItem)
  }

  // Build Container
  const gridContainer = buildGridContainerBlock(gridContainerElements, {
    spacing: 3,
    alignItems: 'flex-end',
  })

  return gridContainer
}
