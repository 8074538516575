export default (input: string) => {
  const regex = /{{\s?([^{}\s]*)\s?}}/g
  let match
  let index = 0
  const result = []

  while ((match = regex.exec(input))) {
    if (index < match.index)
      result.push({
        type: 'text',
        value: input.substr(index, match.index - index),
      })
    result.push({ type: 'tag', value: match[1] })
    index = match.index + match[0].length
  }
  if (index < input.length)
    result.push({ type: 'text', value: input.substr(index) })

  return result
}
