import { BlueprintElements } from 'types/blueprint-config'

import {
  buildGridContainerBlock,
  buildGridItemBlock,
  buildBooleanControlBlock,
  buildOverviewScreenshotControlBlock,
} from '../block-builders'
import { defaultConfirmationSchemaProperties } from '../schemas/confirmation.schema'

const confirmationInputLabels = {
  TERMS_AGREEMENT:
    'Mit dieser Bestellung erkläre ich mich mit den AGBs und der Datenschutzerklärung einverstanden.',
  CANCELLATION_POLICY_AGREEMENT:
    'Die Widerrufsbelehrung habe ich zur Kenntnis genommen.',
  PROMOTIONS_AGREEMENT:
    'Bitte senden Sie mir zukünftig Informationen über neue Produktangebote per E-Mail.',
  SIGNATURE: 'Unterschrift',
  REQUIRED_FIELDS: '* Benötigte Angaben',
}

type ConfirmationBuilderProps = {
  termsText?: string
  cancellationPolicyText?: string
  promotionsText?: string
  showSignature?: boolean
}

// Build Confirmation
export const buildConfirmationBlock = ({
  termsText,
  cancellationPolicyText,
  promotionsText,
  showSignature = false,
}: ConfirmationBuilderProps): BlueprintElements => {
  const gridContainerElements = []

  // Build Terms Agreement
  const termsAgreementControl = buildBooleanControlBlock(
    defaultConfirmationSchemaProperties.accept_terms.name,
    termsText || confirmationInputLabels.TERMS_AGREEMENT,
    { typographyVariant: 'body2' },
  )
  const termsAgreementControlGridItem = buildGridItemBlock(
    [termsAgreementControl],
    {
      xs: 12,
    },
  )

  gridContainerElements.push(termsAgreementControlGridItem)

  // Build Cancellation Policy Agreement
  const cancellationPolicyAgreementControl = buildBooleanControlBlock(
    defaultConfirmationSchemaProperties.withdrawal.name,
    cancellationPolicyText ||
      confirmationInputLabels.CANCELLATION_POLICY_AGREEMENT,
    { typographyVariant: 'body2' },
  )
  const cancellationPolicyAgreementControlGridItem = buildGridItemBlock(
    [cancellationPolicyAgreementControl],
    {
      xs: 12,
    },
  )

  gridContainerElements.push(cancellationPolicyAgreementControlGridItem)

  // Build Promotions Agreement
  const promotionsAgreementControl = buildBooleanControlBlock(
    defaultConfirmationSchemaProperties.accept_promotions.name,
    promotionsText || confirmationInputLabels.PROMOTIONS_AGREEMENT,
    { typographyVariant: 'body2' },
  )
  const promotionsAgreementControlGridItem = buildGridItemBlock(
    [promotionsAgreementControl],
    {
      xs: 12,
    },
  )

  gridContainerElements.push(promotionsAgreementControlGridItem)

  // Build Signature
  if (showSignature) {
    const layersForOverviewScreenshot = 8
    const signatureControl = buildOverviewScreenshotControlBlock(
      defaultConfirmationSchemaProperties.signature.name,
      confirmationInputLabels.SIGNATURE,
      layersForOverviewScreenshot,
    )
    const signatureControlGridItem = buildGridItemBlock([signatureControl], {
      xs: 12,
    })

    gridContainerElements.push(signatureControlGridItem)
  }

  // Build Container
  const firstGridContainer = buildGridContainerBlock(gridContainerElements, {
    spacing: 3,
  })

  return firstGridContainer
}
