import { styled } from '@epilot/components'

const LoaderContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
`

export default LoaderContainer
