import {
  ControlProps,
  OwnPropsOfEnum,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core'
import { withJsonFormsEnumProps } from '@jsonforms/react'
import React from 'react'
import { useSelector } from 'react-redux'

import { getShowErrors } from 'modules/step/selectors'

import { Installation } from '../types'

import InstallationsPerformanceModule from './installations-performance-module'

const InstallationsPerformanceControl = ({
  uischema,
  handleChange,
  path,
  data,
}: ControlProps & OwnPropsOfEnum) => {
  const showErrors = useSelector(getShowErrors)
  const installationsOptions = uischema.options?.installations?.enum || 'Gerät*'
  const installationsLabel = uischema.options?.installations?.label || []
  const installationsType =
    uischema.options?.installations?.type || 'commissioning'
  const selectedInstallations = data ? data['selected_installations'] : []
  const buttonText =
    uischema.options?.installations?.buttonText ||
    ' + Weiteres Gerät hinzufügen'

  const handleConsumptionChange = (
    consumption: number,
    installations: Installation[],
  ) => {
    handleChange(path, {
      annual_consumption: Number(consumption),
      selected_installations: [...installations],
    })
  }

  return (
    <InstallationsPerformanceModule
      buttonText={buttonText}
      commissioningInstallationsLabel={installationsLabel}
      commissioningInstallationsOptions={installationsOptions}
      handleConsumptionChange={handleConsumptionChange}
      selectedInstallations={selectedInstallations}
      showErrors={showErrors}
      type={installationsType}
    />
  )
}

export const installationsPerformanceControlTester = rankWith(
  1,
  uiTypeIs('InstallationsPerformance'),
)

export default withJsonFormsEnumProps(InstallationsPerformanceControl)
