import { EnetCallInfo } from 'components/renderer-v2/button/type'

export const generateEnetFlag = (
  category: string | undefined,
  enetCallInfo: EnetCallInfo[] | undefined,
) => {
  const defaultValue = [{ category: '', enetCall: false }]

  if (!category || typeof category !== 'string') return defaultValue
  let currentEnetCallInfo: EnetCallInfo[] = []

  if (enetCallInfo && enetCallInfo.length > 0) {
    currentEnetCallInfo = enetCallInfo.filter((eachEnetInfo: EnetCallInfo) => {
      return eachEnetInfo.category.toLowerCase() === category.toLowerCase()
    })
  }

  if (currentEnetCallInfo.length === 0) return defaultValue

  return currentEnetCallInfo
}
