import React from 'react'

import { DisplayCartItem } from 'modules/shop/types'

import CartItem from './cart-item'

type CartContentProps = {
  cart: DisplayCartItem[]
  priceIntervalLabel: string
  pricingBusiness: boolean
}

const CartContent = ({
  cart,
  priceIntervalLabel,
  pricingBusiness,
}: CartContentProps) => (
  <>
    {cart.map((item) => (
      <CartItem
        cartItem={item}
        key={item.id}
        priceIntervalLabel={priceIntervalLabel}
        pricingBusiness={pricingBusiness}
      ></CartItem>
    ))}
  </>
)

export default CartContent
