export const displayLogics = (
  options:
    | {
        [key: string]: any
      }
    | undefined,
  data: {
    [key: string]: any
  },
): boolean => {
  if (options?.displayOption === 'HideOnDefault') {
    if (data[options.propertyName] === undefined) {
      return false
    }
  }

  if (options?.displayOption === 'ShowOnPropertyEqualTo') {
    if (data[options.propertyName] !== options.propertyValue) {
      return false
    }
  }

  if (options?.displayOption === 'ShowOnPropertyNOTEqualTo') {
    if (data[options.propertyName] === options.propertyValue) {
      return false
    }
  }

  return true
}
