import { CustomDate } from 'lib/api/types'
import { getAsapDate } from 'lib/dateUtils'
import { ProductType } from 'modules/shop/types'

import { PayloadResults } from '../types'

export const customDatesAdd = (
  results: PayloadResults,
  identifier: ProductType,
  builder: Record<string, any> | undefined,
): CustomDate[] => {
  let customDates: CustomDate[] = []

  const asapPowerValue =
    builder?.steps?.contract_cancellation
      ?.range_step_delivery_date_min_max_range_power?.[0] ||
    builder?.steps?.contract_cancellation_power
      ?.range_step_delivery_date_min_max_range?.[0] ||
    0

  const asapGasValue =
    builder?.steps?.contract_cancellation
      ?.range_step_delivery_date_min_max_range_gas?.[0] ||
    builder?.steps?.contract_cancellation_gas
      ?.range_step_delivery_date_min_max_range?.[0] ||
    0

  switch (identifier) {
    case 'power':
      customDates = customDatesPower(results, asapPowerValue)
      break
    case 'gas':
      customDates = customDatesGas(results, asapGasValue)
      break
    default:
      customDates = []
      break
  }

  return [...customDates]
}
const customDatesPower = (
  results: PayloadResults,
  asapValue: number,
): CustomDate[] => {
  const values: CustomDate[] = []

  if (results.delivery_date_asap_power) {
    values.push({
      value: getAsapDate(asapValue, 'dd.MM.yyyy'),
      label: 'Gewünschter Lieferbeginn (Strom)',
    })
  } else if (results.delivery_date_power) {
    values.push({
      value: results.delivery_date_power,
      label: 'Gewünschter Lieferbeginn (Strom)',
    })
  }
  if (results.termination_date_power) {
    values.push({
      value: results.termination_date_power,
      label: 'Kündigungstermin (Strom)',
    })
  }
  if (results.moving_date_power) {
    values.push({
      value: results.moving_date_power,
      label: 'Einzugdatum (Strom)',
    })
  }

  return values
}
const customDatesGas = (
  results: PayloadResults,
  asapValue: number,
): CustomDate[] => {
  const values: CustomDate[] = []

  if (results.delivery_date_asap_gas) {
    values.push({
      value: getAsapDate(asapValue, 'dd.MM.yyyy'),
      label: 'Gewünschter Lieferbeginn (Gas)',
    })
  } else if (results.delivery_date_gas) {
    values.push({
      value: results.delivery_date_gas,
      label: 'Gewünschter Lieferbeginn (Gas)',
    })
  }
  if (results.termination_date_gas) {
    values.push({
      value: results.termination_date_gas,
      label: 'Kündigungstermin (Gas)',
    })
  }
  if (results.moving_date_gas) {
    values.push({
      value: results.moving_date_gas,
      label: 'Einzugdatum (Gas)',
    })
  }

  return values
}
