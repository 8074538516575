import { PricingItem } from '@epilot/base-modules'
import { useSelector } from 'react-redux'

import { SellingOption } from 'lib/api.types'
import { formatPrice } from 'lib/format-currency'
import {
  getProductPriceInterval,
  getProductPricingBusiness,
} from 'modules/shop/selectors'

export const priceTitles = {
  ESTIMATED_PRICE_PER_MONTH: 'Voraussichtlicher Abschlag pro Monat',
  VARIABLE_PRICE: 'Arbeitspreis je kWh',
  BASE_MONTH_PRICE: 'Grundpreis pro Monat',
  BASE_YEAR_PRICE: 'Grundpreis pro Jahr',
  ESTIMATED_PRICE_PER_YEAR: 'Gesamtpreis pro Jahr',
  BASE_PRICE_PER_YEAR: 'Gesamtgrundpreis pro Jahr',
}

type usePriceProps = {
  price: SellingOption
  showAnnualBasePrice?: boolean
  showTotalAnnualPrice?: boolean
}

type usePriceReturn = {
  priceTitles?: { [key: string]: string }
  estimatedPricePerMonth?: string
  estimatedPricePerYear?: string
  variablePrice?: string
  basePricePerMonth?: string
  basePricePerYear?: string
  oneTimePrice?: string
  variablePrices?: PricingItem[]
  isMonthlyPrice?: boolean
  baseMonthPrice?: string
}

export const usePrice = ({
  price,
  showAnnualBasePrice = true,
  showTotalAnnualPrice = true,
}: usePriceProps): usePriceReturn => {
  const pricingBusiness = useSelector(getProductPricingBusiness)
  const priceInterval = useSelector(getProductPriceInterval)

  // Destructure price
  const {
    estimated_monthly_price: estimatedPricePerMonth,
    estimated_yearly_price: estimatedPricePerYear,
    calculated_consumption_price: variablePrice,
    recurring_price: basePrice,
    one_time_price: oneTimePrice,
  } = price
  const {
    monthly: basePricePerMonth,
    yearly: basePricePerYear,
    base_month_price: baseMonthPrice,
    base_yearly_price: baseYearlyPrice,
  } = basePrice || {}

  const variablePrices: PricingItem[] = []

  if (estimatedPricePerMonth)
    variablePrices.push({
      title: priceTitles.ESTIMATED_PRICE_PER_MONTH,
      price: pricingBusiness
        ? estimatedPricePerMonth.amount_net
        : estimatedPricePerMonth.amount_gross,
      priceCurrency: '€',
    })
  if (variablePrice)
    variablePrices.push({
      title: priceTitles.VARIABLE_PRICE,
      price: pricingBusiness
        ? variablePrice.amount_net * 100
        : variablePrice.amount_gross * 100,
      priceCurrency: 'ct',
    })
  if (baseMonthPrice)
    variablePrices.push({
      title: priceTitles.BASE_MONTH_PRICE,
      price: pricingBusiness
        ? baseMonthPrice.amount_net
        : baseMonthPrice.amount_gross,
      priceCurrency: '€',
    })
  if (showTotalAnnualPrice && estimatedPricePerYear)
    variablePrices.push({
      title: priceTitles.ESTIMATED_PRICE_PER_YEAR,
      price: pricingBusiness
        ? estimatedPricePerYear.amount_net
        : estimatedPricePerYear.amount_gross,
      priceCurrency: '€',
    })
  if (
    showAnnualBasePrice &&
    baseYearlyPrice &&
    (pricingBusiness
      ? baseYearlyPrice?.amount_net
      : baseYearlyPrice?.amount_gross)
  ) {
    variablePrices.push({
      title: priceTitles.BASE_PRICE_PER_YEAR,
      price: pricingBusiness
        ? baseYearlyPrice?.amount_net
          ? baseYearlyPrice.amount_net
          : 0
        : baseYearlyPrice?.amount_gross
        ? baseYearlyPrice.amount_gross
        : 0,

      priceCurrency: '€',
    })
  } else if (showAnnualBasePrice && baseMonthPrice) {
    variablePrices.push({
      title: priceTitles.BASE_PRICE_PER_YEAR,
      price: pricingBusiness
        ? baseMonthPrice?.amount_net
          ? baseMonthPrice.amount_net * 12
          : 0
        : baseMonthPrice?.amount_gross
        ? baseMonthPrice.amount_gross * 12
        : 0,

      priceCurrency: '€',
    })
  }

  return {
    estimatedPricePerMonth:
      estimatedPricePerMonth &&
      formatPrice(estimatedPricePerMonth, pricingBusiness),
    estimatedPricePerYear:
      estimatedPricePerYear &&
      formatPrice(estimatedPricePerYear, pricingBusiness),
    variablePrice:
      variablePrice && formatPrice(variablePrice, pricingBusiness, true),
    basePricePerMonth:
      basePricePerMonth && formatPrice(basePricePerMonth, pricingBusiness),
    basePricePerYear:
      basePricePerYear && formatPrice(basePricePerYear, pricingBusiness),
    oneTimePrice: oneTimePrice && formatPrice(oneTimePrice, pricingBusiness),
    baseMonthPrice:
      baseMonthPrice && formatPrice(baseMonthPrice, pricingBusiness),
    variablePrices,
    isMonthlyPrice: priceInterval === 'MONTHLY',
  }
}
