export const SITUATION_POWER_ENUM_VALUES = [
  'Versorgerwechsel: Ich möchte unter meiner aktuellen Adresse meinen Versorger wechseln.',
  'Umzug: Ich ziehe neu ein/bin neu eingezogen und möchte an meiner neuen Adresse beliefert werden.',
]

export const SITUATION_GAS_ENUM_VALUES = [
  'Versorgerwechsel: Ich möchte unter meiner aktuellen Adresse meinen Versorger wechseln.',
  'Umzug: Ich ziehe neu ein/bin neu eingezogen und möchte an meiner neuen Adresse beliefert werden.',
]

export const defaultContractCancellationSchemaProperties = {
  situation_power: { name: 'situation_power' },
  terminate_contract_power: {
    name: 'terminate_contract_power',
  },
  first_time_occupancy_power: {
    name: 'first_time_occupancy_power',
  },
  delivery_date_power: { name: 'delivery_date_power' },
  delivery_date_asap_power: { name: 'delivery_date_asap_power' },
  termination_date_power: { name: 'termination_date_power' },
  termination_date_asap_power: { name: 'termination_date_asap_power' },
  previous_provider_power: { name: 'previous_provider_power' },
  moving_date_power: { name: 'moving_date_power' },
  situation_gas: { name: 'situation_gas' },
  terminate_contract_gas: { name: 'terminate_contract_gas' },
  first_time_occupancy_gas: {
    name: 'first_time_occupancy_gas',
  },
  delivery_date_gas: { name: 'delivery_date_gas' },
  delivery_date_asap_gas: { name: 'delivery_date_asap_gas' },
  termination_date_gas: { name: 'termination_date_gas' },
  termination_date_asap_gas: { name: 'termination_date_asap_gas' },
  previous_provider_gas: { name: 'previous_provider_gas' },
  moving_date_gas: { name: 'moving_date_gas' },
  power_readings: { name: 'power_readings' },
  gas_readings: { name: 'gas_readings' },
}

export const contractCancellationSchemaPowerProperties = {
  [defaultContractCancellationSchemaProperties.situation_power.name]: {
    type: 'string',
    enum: SITUATION_POWER_ENUM_VALUES,
  },
  [defaultContractCancellationSchemaProperties.terminate_contract_power.name]: {
    type: 'string',
    enum: ['Ja', 'Nein'],
  },
  [defaultContractCancellationSchemaProperties.first_time_occupancy_power
    .name]: {
    type: 'string',
    enum: ['Ja', 'Nein'],
  },
  [defaultContractCancellationSchemaProperties.delivery_date_power.name]: {
    type: 'string',
    dateDE: true,
    futureDateDE: 14,
    examples: ['TT.MM.JJJJ'],
  },
  [defaultContractCancellationSchemaProperties.delivery_date_asap_power.name]: {
    type: 'boolean',
  },
  [defaultContractCancellationSchemaProperties.termination_date_power.name]: {
    type: 'string',
    dateDE: true,
    futureDateDE: 14,
    examples: ['TT.MM.JJJJ'],
  },
  [defaultContractCancellationSchemaProperties.termination_date_asap_power
    .name]: {
    type: 'boolean',
  },
  [defaultContractCancellationSchemaProperties.previous_provider_power.name]: {
    type: 'object',
  },
  [defaultContractCancellationSchemaProperties.moving_date_power.name]: {
    type: 'string',
    dateDE: true,
    examples: ['TT.MM.JJJJ'],
  },
  [defaultContractCancellationSchemaProperties.power_readings.name]: {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        meterNumber: {
          type: 'string',
        },
        marketLocationId: {
          type: 'string',
        },
        meterReading: {
          type: 'string',
        },
      },
    },
  },
}

export const contractCancellationSchemaGasProperties = {
  [defaultContractCancellationSchemaProperties.situation_gas.name]: {
    type: 'string',
    enum: SITUATION_GAS_ENUM_VALUES,
  },
  [defaultContractCancellationSchemaProperties.terminate_contract_gas.name]: {
    type: 'string',
    enum: ['Ja', 'Nein'],
  },
  [defaultContractCancellationSchemaProperties.first_time_occupancy_gas.name]: {
    type: 'string',
    enum: ['Ja', 'Nein'],
  },
  [defaultContractCancellationSchemaProperties.delivery_date_gas.name]: {
    type: 'string',
    dateDE: true,
    futureDateDE: 14,
    examples: ['TT.MM.JJJJ'],
  },
  [defaultContractCancellationSchemaProperties.delivery_date_asap_gas.name]: {
    type: 'boolean',
  },
  [defaultContractCancellationSchemaProperties.termination_date_gas.name]: {
    type: 'string',
    dateDE: true,
    futureDateDE: 14,
    examples: ['TT.MM.JJJJ'],
  },
  [defaultContractCancellationSchemaProperties.termination_date_asap_gas
    .name]: {
    type: 'boolean',
  },
  [defaultContractCancellationSchemaProperties.previous_provider_gas.name]: {
    type: 'object',
  },
  [defaultContractCancellationSchemaProperties.moving_date_gas.name]: {
    type: 'string',
    dateDE: true,
    examples: ['TT.MM.JJJJ'],
  },
  [defaultContractCancellationSchemaProperties.gas_readings.name]: {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        meterNumber: {
          type: 'string',
        },
        marketLocationId: {
          type: 'string',
        },
        meterReading: {
          type: 'string',
        },
      },
    },
  },
}

export const contractCancellationSchemaRequiredPower = (
  power_date_required?: boolean,
  power_contract_termination?: boolean,
) => {
  return {
    required: [
      defaultContractCancellationSchemaProperties.situation_power.name,
      defaultContractCancellationSchemaProperties.power_readings.name,
    ],
    allOf: [
      {
        if: {
          properties: {
            [defaultContractCancellationSchemaProperties.situation_power
              .name]: {
              const: SITUATION_POWER_ENUM_VALUES[0],
            },
          },
        },
        then: {
          ...(power_contract_termination && {
            required: [
              defaultContractCancellationSchemaProperties
                .terminate_contract_power.name,
            ],
          }),
          if: {
            properties: {
              [defaultContractCancellationSchemaProperties
                .terminate_contract_power.name]: {
                const: 'Ja',
              },
            },
          },
          ...(power_date_required && {
            then: {
              if: {
                properties: {
                  [defaultContractCancellationSchemaProperties
                    .delivery_date_asap_power.name]: {
                    const: true,
                  },
                },
              },
              then: {
                required: [
                  defaultContractCancellationSchemaProperties
                    .delivery_date_asap_power.name,
                ],
              },
              else: {
                required: [
                  defaultContractCancellationSchemaProperties
                    .delivery_date_power.name,
                ],
              },
            },
            else: {
              required: [
                defaultContractCancellationSchemaProperties
                  .termination_date_power.name,
              ],
            },
          }),
        },
        else: {
          ...(power_date_required && {
            required: [
              defaultContractCancellationSchemaProperties.moving_date_power
                .name,
            ],
          }),
        },
      },
    ],
  }
}

export const contractCancellationSchemaRequiredGas = (
  gas_date_required?: boolean,
  gas_contract_termination?: boolean,
) => {
  return {
    required: [
      defaultContractCancellationSchemaProperties.situation_gas.name,
      defaultContractCancellationSchemaProperties.gas_readings.name,
    ],
    allOf: [
      {
        if: {
          properties: {
            [defaultContractCancellationSchemaProperties.situation_gas.name]: {
              const: SITUATION_GAS_ENUM_VALUES[0],
            },
          },
        },
        then: {
          ...(gas_contract_termination && {
            required: [
              defaultContractCancellationSchemaProperties.terminate_contract_gas
                .name,
            ],
          }),
          if: {
            properties: {
              [defaultContractCancellationSchemaProperties
                .terminate_contract_gas.name]: {
                const: 'Ja',
              },
            },
          },
          ...(gas_date_required && {
            then: {
              if: {
                properties: {
                  [defaultContractCancellationSchemaProperties
                    .delivery_date_asap_gas.name]: {
                    const: true,
                  },
                },
              },
              then: {
                required: [
                  defaultContractCancellationSchemaProperties
                    .delivery_date_asap_gas.name,
                ],
              },
              else: {
                required: [
                  defaultContractCancellationSchemaProperties.delivery_date_gas
                    .name,
                ],
              },
            },
            else: {
              required: [
                defaultContractCancellationSchemaProperties.termination_date_gas
                  .name,
              ],
            },
          }),
        },
        else: {
          ...(gas_date_required && {
            required: [
              defaultContractCancellationSchemaProperties.moving_date_gas.name,
            ],
          }),
        },
      },
    ],
  }
}

export const contractCancellationSchemaRequired = (
  power_date_required = true,
  power_contract_termination = true,
  gas_date_required = true,
  gas_contract_termination = true,
) => {
  const test = {
    required: [
      defaultContractCancellationSchemaProperties.situation_power.name,
      defaultContractCancellationSchemaProperties.power_readings.name,
      defaultContractCancellationSchemaProperties.situation_gas.name,
      defaultContractCancellationSchemaProperties.gas_readings.name,
    ],
    allOf: [
      {
        ...contractCancellationSchemaRequiredPower(
          power_date_required,
          power_contract_termination,
        ),
      },
      {
        ...contractCancellationSchemaRequiredGas(
          gas_date_required,
          gas_contract_termination,
        ),
      },
    ],
  }

  return test
}
