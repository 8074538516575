import './debug.css'

import React from 'react'
import { connect } from 'react-redux'

import {
  getActiveStep,
  getActiveStepData,
  getAllResultData,
} from 'modules/step/selectors'
import { Step } from 'modules/step/types'
import { GlobalAppState } from 'modules/types'

type Props = {
  activeStep: undefined | Step
  data: any
}

const Debug = ({ activeStep, data }: Props): any => {
  if (!activeStep) return null

  return (
    <div className="epilot-widget-debug">
      Global result data
      <pre
        style={{
          maxWidth: '100vw',
          backgroundColor: '#fff',
          padding: '10px',
          fontSize: '12px',
        }}
      >
        <code className="language-json">{JSON.stringify(data, null, 2)}</code>
      </pre>
      Active step schema
      <pre
        style={{
          maxWidth: '100vw',
          backgroundColor: '#fff',
          padding: '10px',
          fontSize: '12px',
        }}
      >
        <code className="language-json">
          {JSON.stringify(activeStep.schema, null, 2)}
        </code>
      </pre>
      Active step uischema
      <pre
        style={{
          maxWidth: '100vw',
          backgroundColor: '#fff',
          padding: '10px',
          fontSize: '12px',
        }}
      >
        <code className="language-json">
          {JSON.stringify(activeStep.uischema, null, 2)}
        </code>
      </pre>
    </div>
  )
}

export default connect((state: GlobalAppState) => ({
  activeStep: getActiveStep(state),
  activeStepData: getActiveStepData(state),
  data: getAllResultData(state),
}))(Debug)
