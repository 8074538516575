export const epilotStyling = {
  theming_options: {
    palette: {
      primary: '#01579B',
    },
    background: {
      default: '#E0EDEF',
    },
    typography: {
      title_size: 32,
      subtitle_size: 24,
      main_size: 18,
      caption_size: 14,
    },
    spacing: {
      default: 8,
    },
    shape: {
      border_radius: 0,
    },
    props: {
      client_logo_url:
        'https://staging.epilot.cloud/styles/728/7066103/logo/95538690-02d9-11eb-bf7b-7b35b10c0000_epilot.png',
      content_max_width: 1128,
    },
  },
  useBaseElementsLibrary: true,
}

export const enercityStyling = {
  theming_options: {
    palette: {
      primary: '#B22869',
      secondary: '#EB3223',
    },
    background: {
      default: '#F1F1F1',
    },
    typography: {
      title_size: 32,
      subtitle_size: 24,
      main_size: 18,
    },
    spacing: {
      default: 10,
    },
    shape: {
      border_radius: 10,
    },
    props: {
      client_logo_url:
        'https://theme.zdassets.com/theme_assets/1603073/1a1416ffab041ba8de9677cac5a5dc2068a4d5c4.png',
      content_max_width: 1128,
    },
  },
  useBaseElementsLibrary: true,
}
