import { styled } from '@epilot/components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`

export default Wrapper
