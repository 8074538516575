import { Addon, CartItemReq } from '../../../../lib/api/types'
import { ProductCategory } from '../../../../modules/shop/types'

const createAddonsSchema = (
  addons: any,
  product_type: ProductCategory,
): Addon[] => {
  const addOnResponse: Addon[] = []

  if (addons) {
    Object.keys(addons).forEach((key: string) => {
      addons[key].forEach((addon: string) => {
        addOnResponse.push({
          id: key,
          product_id: key,
          selling_option_id: JSON.stringify(addon),
          product_type,
        })
      })
    })
  }

  return addOnResponse
}

const createCartItemsSchema = (
  items: any = [],
  sellingOptionId: string,
  interval: string,
  product_type: ProductCategory,
): CartItemReq[] => {
  const cartItemResponse: CartItemReq[] = []

  items.forEach((item: any) => {
    // if no product id, product id = 0, or product id = NaN -> it should be a generic OP
    if (item && item.productId) {
      let consumption = 0

      if (item.consumption) {
        if (item.consumption.amount) {
          consumption = item.consumption.amount
        } else {
          consumption = item.consumption
        }
      }

      cartItemResponse.push({
        product_id: JSON.stringify(item.productId),
        selling_option_id: JSON.stringify(sellingOptionId),
        consumption,
        interval: interval,
        product_type,
      })
    } else if (item.product_id && item.selling_option_id) {
      cartItemResponse.push(item)
    }
  })

  return cartItemResponse
}

export { createCartItemsSchema, createAddonsSchema }
