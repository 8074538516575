import ReactPixel from 'react-facebook-pixel'

type PixelData = {
  payload?: any
  config?: any
}

const config = {
  assumesPageview: true,
}

const fb = ReactPixel
let fbLoaded = false

/* Export the integration */
export default function customFacebookPixel(
  userConfig: any,
  pluginName: string,
  initEnabled: boolean,
) {
  return {
    name: pluginName || 'facebook-pixel',
    config: {
      ...config,
      ...userConfig,
    },
    enabled: initEnabled,
    initialize: ({ config }: PixelData) => {
      const { trackingId } = config

      if (!fbLoaded) {
        const options = {
          autoConfig: true,
          debug: false,
        }

        ReactPixel.init(trackingId, undefined, options)
        fbLoaded = true
      }
    },
    /* page view */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    page: ({ payload, config }: PixelData) => {
      fb.pageView()
    },
    /* track event */
    track: ({ payload }: PixelData) => {
      const options = payload.options
      const event = payload.event

      if (!options.isCustom) {
        fb.track(event, payload.properties)
      } else {
        fb.trackCustom(event)
      }
    },
    /* Verify script loaded */
    loaded: function () {
      return fbLoaded
    },
  }
}
