import {
  Autocomplete,
  BinIcon,
  InputControl,
  MuiGrid,
  styled,
} from '@epilot/components'
import React from 'react'

import {
  Installation,
  InstallationConstructionType,
  InstallationName,
  InstallationStatus,
} from '../types'
import {
  ERRORMESSAGE,
  INSTALLATIONTYPE,
  INSTALLATIONUNIT,
} from '../unitConstant'

export type ModuleInstallationPerformanceType = {
  handleChangeInstallation: (index: number, newValue: Installation) => void
  handleRemoveInstallation: (index: number) => void
  installations: Installation[]
  showRemoveIcon: boolean | undefined
  showErrors?: boolean
}
const AutocompleteHelper = styled.div`
  min-width: 150px;
  margin: -12px 0px 8px 0px;
  color: #f44336;
  font-size: 0.75rem;
`

export const generateInstallations = ({
  handleChangeInstallation,
  handleRemoveInstallation,
  installations,
  showErrors,
  showRemoveIcon,
}: ModuleInstallationPerformanceType): JSX.Element => {
  const installationsElements = (installations as any).map(
    (installation: any, index: number) => {
      const installationType = installation.installationType

      const isEmpty = (field: string | undefined) => {
        return typeof field === 'undefined' || field === '' || field === null
      }

      const performanceTable = {
        [INSTALLATIONTYPE.GENERATOR]: {
          inputField: installation.apparentPerformance,
          label: 'Scheinleistung',
          changeParameter: 'apparentPerformance',
          unit: 'kVA',
          type: 'numeric',
          isMandatory: true,
        },
        [INSTALLATIONTYPE.COMMISSIONING]: {
          inputField: installation.ratedCapacity,
          label: 'Nennleistung',
          changeParameter: 'ratedCapacity',
          unit: 'kW',
          type: 'numeric',
          isMandatory: true,
        },
        [INSTALLATIONTYPE.STORAGE]: {
          inputField: installation.powerConsumption,
          label: 'Leistungsaufnahme',
          changeParameter: 'powerConsumption',
          unit: 'kVA',
          type: 'numeric',
          isMandatory: true,
        },
      }
      const manufacturerTable = {
        [INSTALLATIONTYPE.GENERATOR]: {
          inputField: installation.manufacturer,
          label: 'Hersteller',
          changeParameter: 'manufacturer',
          unit: '',
          type: 'text',
          showRemoveIcon: showRemoveIcon,
          isMandatory: true,
        },
        [INSTALLATIONTYPE.SOLAR]: {
          inputField: installation.manufacturer,
          label: 'Hersteller',
          changeParameter: 'manufacturer',
          unit: '',
          type: 'text',
          showRemoveIcon: showRemoveIcon,
          isMandatory: true,
        },
        [INSTALLATIONTYPE.STORAGE]: {
          inputField: installation.manufacturer,
          label: 'Hersteller',
          changeParameter: 'manufacturer',
          unit: '',
          type: 'text',
          showRemoveIcon: showRemoveIcon,
          isMandatory: true,
        },
        [INSTALLATIONTYPE.INVERTER]: {
          inputField: installation.manufacturer,
          label: 'Hersteller',
          changeParameter: 'manufacturer',
          unit: '',
          type: 'text',
          showRemoveIcon: showRemoveIcon,
          isMandatory: true,
        },
      }
      const typeTable = {
        [INSTALLATIONTYPE.GENERATOR]: {
          inputField: installation.type,
          label: 'Type',
          changeParameter: 'type',
          unit: '',
          type: 'text',
          isMandatory: true,
        },
        [INSTALLATIONTYPE.SOLAR]: {
          inputField: installation.type,
          label: 'Type',
          changeParameter: 'type',
          unit: '',
          type: 'text',
          isMandatory: true,
        },
        [INSTALLATIONTYPE.STORAGE]: {
          inputField: installation.type,
          label: 'Type',
          changeParameter: 'type',
          unit: '',
          type: 'text',
          isMandatory: true,
        },
        [INSTALLATIONTYPE.INVERTER]: {
          inputField: installation.type,
          label: 'Type',
          changeParameter: 'type',
          unit: '',
          type: '',
          isMandatory: true,
        },
      }
      const performanceOutputTable = {
        [INSTALLATIONTYPE.GENERATOR]: {
          inputField: installation.activePerformance,
          label: 'Wirkleistung',
          changeParameter: 'activePerformance',
          unit: 'kW',
          type: 'numeric',
          isMandatory: true,
        },
        [INSTALLATIONTYPE.COMMISSIONING]: {
          inputField: installation.annualConsumption,
          label: 'Jährlicher Verbrauch',
          changeParameter: 'annualConsumption',
          unit: 'kWh',
          type: 'numeric',
          isMandatory: true,
        },
        [INSTALLATIONTYPE.STORAGE]: {
          inputField: installation.powerOutput,
          label: 'Leistungsabgabe',
          changeParameter: 'powerOutput',
          unit: 'kVA',
          type: 'numeric',
          isMandatory: true,
        },
      }
      const autocompleteTable = {
        name: {
          inputField: installation.name,
          label: 'Gerät',
          changeParameter: 'name',
          showRemoveIcon: showRemoveIcon,
          isMandatory: true,
        },
        status: {
          inputField: installation.status,
          label: 'Status',
          changeParameter: 'status',
          isMandatory: true,
        },
        constructionType: {
          inputField: installation.constructionType,
          label: 'Bauart',
          changeParameter: 'constructionType',
          isMandatory: true,
        },
      }

      const renderInput = (params: Record<string, any>) => {
        if (params) {
          const {
            inputField,
            label,
            changeParameter,
            unit,
            type,
            showRemoveIcon,
            isMandatory,
          } = params

          return (
            <MuiGrid style={{ display: 'flex', alignItems: 'center' }} xs>
              <InputControl
                error={showErrors && isEmpty(inputField) ? ERRORMESSAGE : ''}
                fullWidth
                id={`${installationType}-${changeParameter}-${index}`}
                label={label + (isMandatory ? '*' : '')}
                onChange={({ target: { value: performance } }) =>
                  handleChangeInstallation(index, {
                    [changeParameter]: performance,
                  })
                }
                type={type}
                unit={unit}
                value={installation[changeParameter]?.toString()}
              />
              {showRemoveIcon && (
                <BinIcon
                  onClick={() => handleRemoveInstallation(index)}
                  style={{ cursor: 'pointer', marginLeft: '8px' }}
                />
              )}
            </MuiGrid>
          )
        }
      }
      const renderAutocomplete = (params: Record<string, any>) => {
        if (params) {
          const {
            inputField,
            label,
            changeParameter,
            showRemoveIcon,
            isMandatory,
          } = params

          const options =
            changeParameter === 'name'
              ? InstallationName
              : changeParameter === 'status'
              ? InstallationStatus
              : changeParameter === 'constructionType'
              ? InstallationConstructionType
              : ''

          return (
            <MuiGrid item xs>
              <MuiGrid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ flexGrow: 1 }}>
                  <Autocomplete
                    error={showErrors && isEmpty(inputField)}
                    getOptionLabel={(option) => option}
                    id={`${changeParameter}-${index}`}
                    label={label + (isMandatory ? '*' : '')}
                    name={`${changeParameter}-${index}`}
                    onChange={(_, value) => {
                      return handleChangeInstallation(index, {
                        [changeParameter]: value,
                      })
                    }}
                    options={Object.values(options)}
                    placeholder={'Bitte auswählen'}
                    value={installation[changeParameter]}
                  />
                </div>
                {showRemoveIcon && (
                  <BinIcon
                    onClick={() => handleRemoveInstallation(index)}
                    style={{ cursor: 'pointer', marginLeft: '8px' }}
                  />
                )}
              </MuiGrid>
              {showErrors && isEmpty(inputField) && (
                <AutocompleteHelper>{ERRORMESSAGE}</AutocompleteHelper>
              )}
            </MuiGrid>
          )
        }
      }

      return (
        <div key={index}>
          {installationType === INSTALLATIONTYPE.COMMISSIONING && (
            <MuiGrid container direction="row" spacing={2}>
              {renderAutocomplete(autocompleteTable.name)}
              {renderAutocomplete(autocompleteTable.status)}
            </MuiGrid>
          )}
          {installationType !== INSTALLATIONTYPE.COMMISSIONING && (
            <MuiGrid container direction="row" spacing={2}>
              <MuiGrid item xs>
                {renderInput(manufacturerTable[installationType])}
              </MuiGrid>
              <MuiGrid item xs>
                {renderInput(typeTable[installationType])}
              </MuiGrid>
            </MuiGrid>
          )}
          <MuiGrid container direction="row" spacing={2}>
            <MuiGrid item xs>
              {renderInput(performanceTable[installationType])}
            </MuiGrid>
            <MuiGrid item xs>
              {renderInput(performanceOutputTable[installationType])}
            </MuiGrid>
          </MuiGrid>

          {installationType !== INSTALLATIONTYPE.COMMISSIONING && (
            <MuiGrid container direction="row" spacing={2}>
              <MuiGrid item xs>
                {installationType === INSTALLATIONTYPE.GENERATOR &&
                  renderAutocomplete(autocompleteTable.constructionType)}
                {installation.installationType !== INSTALLATIONTYPE.GENERATOR &&
                  renderInput({
                    inputField:
                      installation.installationType === INSTALLATIONTYPE.STORAGE
                        ? installation.storageCapacity
                        : installation[installationType.concat('Performance')],
                    label:
                      installation.installationType === INSTALLATIONTYPE.STORAGE
                        ? 'Speicherkapazität'
                        : 'Leistung',
                    changeParameter:
                      installation.installationType === INSTALLATIONTYPE.STORAGE
                        ? 'storageCapacity'
                        : installationType.concat('Performance'),
                    unit: INSTALLATIONUNIT[installation.installationType],
                    type: 'numeric',
                    isMandatory: true,
                  })}
              </MuiGrid>
              <MuiGrid item xs>
                {renderInput({
                  inputField: installation.quantity,
                  label: 'Anzahl',
                  changeParameter: 'quantity',
                  unit: '',
                  type: 'numeric',
                  isMandatory: true,
                })}
              </MuiGrid>
            </MuiGrid>
          )}
        </div>
      )
    },
  )

  return installationsElements
}
