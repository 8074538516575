import { Checkbox } from '@epilot/components'
import {
  ControlProps,
  OwnPropsOfEnum,
  RankedTester,
  rankWith,
  and,
  uiTypeIs,
  optionIs,
  or,
  schemaTypeIs,
} from '@jsonforms/core'
import { withJsonFormsEnumProps } from '@jsonforms/react'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { cartAddAddon, cartRemoveAddon } from 'modules/shop/reducer'
import { getAddonsFromProductsInCart } from 'modules/shop/selectors'
import { getShowErrors } from 'modules/step/selectors'

const CheckboxCartControl = ({
  data,
  handleChange,
  path,
  uischema,
  errors,
  showErrors,
}: ControlProps & OwnPropsOfEnum & { showErrors?: typeof getShowErrors }) => {
  const dispatch = useDispatch()
  const productAddons = useSelector(getAddonsFromProductsInCart)
  const addOnID = uischema?.options?.addOnId?.toString() || ''

  const onChangeLocal = (selection: boolean) => {
    if (productAddons[0]) {
      const { productId } = productAddons[0]

      if (selection) {
        dispatch(cartAddAddon(addOnID, productId))
      } else {
        dispatch(cartRemoveAddon(addOnID, productId))
      }
    }
    handleChange(path, selection)
  }

  return (
    <Checkbox
      checked={data || false}
      error={showErrors && errors !== '' ? errors : undefined}
      label={uischema.label as React.ReactElement}
      onChange={(_, selection: boolean) => {
        onChangeLocal(selection)
      }}
    />
  )
}

export default withJsonFormsEnumProps(CheckboxCartControl)

export const checkboxCartControlTester: RankedTester = rankWith(
  5,
  and(
    uiTypeIs('Control'),
    or(schemaTypeIs('object'), schemaTypeIs('boolean')),
    optionIs('addOnToCart', true),
  ),
)
