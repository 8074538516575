import { BlueprintStep } from 'types/blueprint-config'

import {
  boxWrapper,
  buildTextBlock,
  buildNextButtonBlock,
  stepperWrapper,
  buildCheckboxBlock,
  buildSummaryTileBlock,
} from '../../../building-blocks'

const checkbox1 = buildCheckboxBlock({
  scope: 'checkbox1',
  label:
    'Mit dieser Bestellung erkläre ich mich mit den AGBs und der Datenschutzerklärung einverstanden.',
  required: true,
})

const step1Labels = [
  `{{address.zipCode}} {{address.city}}`,
  `{{address.streetName}} {{address.houseNumber}}`,
  `{{address.addressExtention}}`,
  `{{housing_units}} Wohneinheit(en)`,
  `{{business_units}} Geschäftseinheit(en)`,
]

const step2OwnerLabels = [
  `{{salutation}} {{title}} {{first_name}} {{last_name}}`,
  `{{user_email}}`,
  `{{telephone}}`,
  `{{contact_address.zipCode}} {{contact_address.city}}`,
  `{{contact_address.streetName}} {{contact_address.houseNumber}}`,
  `{{contact_address.addressExtention}}`,
]

const step2ExtraLabels = [
  `{{additional_salutation}} {{additional_title}} {{additional_first_name}} {{additional_last_name}}`,
  `{{additional_user_email}}`,
  `{{additional_telephone}}`,
  `{{additional_address.zipCode}} {{additional_address.city}}`,
  `{{additional_address.streetName}} {{additional_address.houseNumber}}`,
  `{{additional_address.addressExtention}}`,
]

export const overviewStep: BlueprintStep = {
  id: 'overview',
  title: 'Overview',
  nextButtonLabel: 'Jetzt anfordern',
  schema: {
    type: 'object',
    properties: {
      ...checkbox1.schema,
    },
    required: [...(checkbox1.required ? checkbox1.required : [])],
  },
  uischema: {
    type: 'GridContainerLayout',
    options: {
      spacing: 0,
    },
    elements: [
      ...stepperWrapper(
        ['building_details', 'owner_details', 'overview'],
        [
          ...buildSummaryTileBlock({
            stepLink: 'building_details',
            title: 'Gebäude',
            labels: step1Labels,
          }),
          ...buildSummaryTileBlock({
            stepLink: 'owner_details',
            title: 'Eigentümer/Vertreter',
            labels: step2OwnerLabels,
          }),
          ...buildSummaryTileBlock({
            stepLink: 'owner_details',
            title: 'Weiterer Eigentümer',
            labels: step2ExtraLabels,
            scope: 'additional_owner',
          }),
          ...buildTextBlock('Übersicht', 'h1'),
          ...boxWrapper([...checkbox1.uischema]),
          ...buildNextButtonBlock(),
        ],
      ),
    ],
  },
  resultData: {},
  logic: {
    nextStepId: 'thank_you',
    sendResults: true,
  },
}
