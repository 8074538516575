import { styled } from '@epilot/components'

import { Theme } from '../../../modules/app/types'

type AddonDetailsStyledProps = {
  isActive: boolean
  theme: Theme
}

const AddonsDetailsStyled = styled.div<AddonDetailsStyledProps>`
  align-items: center;
  border-top: 1px solid #d2dee4;
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px 24px;
  color: ${({ theme }: AddonDetailsStyledProps) =>
    theme?.typography?.color} !important;

  .button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .quantity-selector {
    align-items: center;
    display: flex;
    margin-left: 16px;
    flex-direction: row;
  }
  .quantity-selector > span {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    justify-content: center;
    margin: 0 8px;
    padding: 0 8px;
    text-align: center;
  }
  .quantity-selector > input::-webkit-outer-spin-button,
  .quantity-selector > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .quantity-selector > button {
    background: white;
    border: 1px solid
      ${({ theme }: AddonDetailsStyledProps) => theme?.palette?.disabled?.light};
    border-radius: 11px;
    color: ${({ theme }: AddonDetailsStyledProps) =>
      theme?.palette?.primary.main};
    font-weight: bold;
    height: 23px;
    justify-content: center;
    line-height: 0px;
    width: 23px;
  }
  .quantity-selector > button:hover {
    border: 1px solid
      ${({ theme }: AddonDetailsStyledProps) => theme?.palette?.primary.main};
  }
  .quantity-selector > button:active {
    background: ${({ theme }: AddonDetailsStyledProps) =>
      theme?.palette?.primary.main};
    color: white;
    outline: none !important;
  }
  .quantity-selector > button:focus {
    outline: none !important;
  }
  .select-addon-button {
    color: ${({ theme }: AddonDetailsStyledProps) =>
      theme?.palette?.primary?.main};
    border: 1px solid
      ${({ theme }: AddonDetailsStyledProps) => theme?.palette?.primary?.main} !important;
    padding: 6px;
    width: 100%;
  }
  .select-addon-button .MuiButton-label span {
    color: ${({ isActive, theme }: AddonDetailsStyledProps) =>
      isActive
        ? theme?.typography?.primaryColor
        : theme?.palette?.primary?.main};
  }
  .select-addon-button:hover .MuiButton-label span {
    color: ${({ theme }: AddonDetailsStyledProps) =>
      theme?.typography?.primaryColor};
  }
  .select-addon-button .MuiButton-label {
    height: 16px;
  }
  .MuiFormControlLabel-root .MuiTypography-root {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }
  .select-addon-button:hover .MuiSvgIcon-root {
    fill: white;
  }
  .MuiSvgIcon-root {
    fill: ${({ isActive, theme }: AddonDetailsStyledProps) =>
      isActive ? 'white' : theme?.palette?.disabled?.light};
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: white;
  }
  p {
    align-self: flex-start;
    margin-bottom: 0px;
    margin-top: 8px;
  }
  .circle-text-container {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
  }
  .circle-text-container p {
    margin: 8px 8px 0px;
  }

  .circle-text-container svg {
    width: 16px;
    height: 16px;
    margin-top: 8px;
    min-width: 16px;
  }
  .text-container {
    width: 100%;
  }
  .text-container > p {
    margin-top: 16px;
  }
  .button-icon {
    border: 1px solid
      ${({ isActive, theme }: AddonDetailsStyledProps) =>
        isActive ? 'white' : theme?.palette?.disabled?.light};
    border-radius: 50%;
    height: 16px;
    width: 16px;
  }
  .checkedIcon-background {
    height: 16px;
    width: 16px;
    background: white;
    border-radius: 50%;
    position: relative;
  }
  .checkedIcon-icon {
    position: absolute;
    top: -1px;
  }
`

export default AddonsDetailsStyled
