import { clone } from 'modules/step/helper/utils'
import { GlobalResultData } from 'modules/step/types'

// now data clean for P&G bundle and new power journey
export const dataCleanBaseCondition = (data: GlobalResultData) => {
  let dataCopy = clone(data)

  if (dataCopy) {
    // personal information step
    dataCopy = cleanPersonalInformationStep(dataCopy)
    // contract termination step for power
    dataCopy = cleanContractStepForDifferentCategory(dataCopy, 'power')
    // contract termination step for gas
    dataCopy = cleanContractStepForDifferentCategory(dataCopy, 'gas')
    // payment step
    dataCopy = cleanPaymentStep(dataCopy)
  }

  return dataCopy
}

const cleanContractStepForDifferentCategory = (
  data: GlobalResultData,
  category: string,
) => {
  const dataClone = clone(data)

  if (
    dataClone[`situation_${category}`] ===
    'Versorgerwechsel: Ich möchte unter meiner aktuellen Adresse meinen Versorger wechseln.'
  ) {
    delete dataClone[`moving_date_${category}`]
    delete dataClone[`first_time_occupancy_${category}`]
    if (dataClone[`terminate_contract_${category}`] === 'Ja') {
      delete dataClone[`termination_date_${category}`]
      dataClone[`termination_date_${category}`]
      if (dataClone[`delivery_date_asap_${category}`]) {
        delete dataClone[`delivery_date_${category}`]
      } else {
        delete dataClone[`delivery_date_asap_${category}`]
      }
    } else {
      delete dataClone[`previous_provider_${category}`]
      delete dataClone[`delivery_date_${category}`]
      delete dataClone[`delivery_date_asap_${category}`]
    }
  } else {
    delete dataClone[`previous_provider_${category}`]
    delete dataClone[`terminate_contract_${category}`]
    delete dataClone[`delivery_date_${category}`]
    delete dataClone[`termination_date_${category}`]
    delete dataClone[`delivery_date_asap_${category}`]
  }

  return dataClone
}
const cleanPersonalInformationStep = (data: GlobalResultData) => {
  const dataClone = clone(data)

  if (!dataClone?.different_billing_address) {
    delete dataClone?.billing_address
  }
  if (
    !dataClone?.different_billing_address ||
    !dataClone?.different_invoice_recipient
  ) {
    delete dataClone?.invoice_recipient_first_name
    delete dataClone?.invoice_recipient_last_name
    delete dataClone?.invoice_recipient_salutation
    delete dataClone?.invoice_recipient_title
  }

  return dataClone
}
const cleanPaymentStep = (data: GlobalResultData) => {
  const dataClone = clone(data)

  if (dataClone?.payment_method === 'invoice') {
    delete dataClone?.account_owner
    delete dataClone?.sepa
    delete dataClone?.payment_agreement
  }

  return dataClone
}
