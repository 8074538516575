import {
  AppBar,
  Box,
  Grid,
  Icon,
  IconButton,
  InlineSpacing,
  Toolbar,
  useTheme,
} from '@epilot/base-elements'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { exitFullscreen } from 'modules/app/reducer'
import { getContentMaxWidth } from 'modules/app/selectors'
import { goPrevious, goToStep } from 'modules/step/reducer'
import {
  getHideHeader,
  getIsEmbedded,
  getPreviousSteps,
} from 'modules/step/selectors'

import config from '../../config'

import Logo from './ui-wrapper-header-logo'

type UIWrapperV2Props = {
  children: React.ReactNode
}

const UIWrapperV2 = ({ children }: UIWrapperV2Props): JSX.Element => {
  const theme = useTheme()
  const contentMaxWidth = useSelector(getContentMaxWidth)
  const hideHeader = useSelector(getHideHeader)
  const isEmbedded = useSelector(getIsEmbedded)
  const previousSteps = useSelector(getPreviousSteps)
  const dispatch = useDispatch()

  const handleGoPrevious = () => {
    dispatch(goPrevious())
  }

  const handleClose = () => {
    // Return to first step when closing full screen
    if (previousSteps && previousSteps.length > 0)
      dispatch(goToStep(previousSteps[0]))
    dispatch(exitFullscreen())
  }

  const showHeader = (): boolean => {
    return !(hideHeader || isEmbedded)
  }

  return showHeader() ? (
    <Box
      bgcolor={theme.palette.background.default}
      display={'block'}
      width="100vw"
    >
      {!config.HIDE_ITEMS.includes('topBar') && (
        <AppBar>
          <Toolbar>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              {previousSteps &&
              previousSteps.length > 0 &&
              !config.HIDE_ITEMS.includes('backButton') ? (
                <IconButton onClick={handleGoPrevious}>
                  <Icon
                    customColor={theme.palette.primary.main || 'white'}
                    name="arrow_back_ios"
                  />
                </IconButton>
              ) : (
                <div />
              )}

              <Logo />
              <InlineSpacing alignItems="center" scale={1}>
                {!config.HIDE_ITEMS.includes('closeButton') ? (
                  <IconButton onClick={handleClose}>
                    <Icon
                      customColor={theme.palette.primary.main || 'white'}
                      fontSize="large"
                      name="close"
                    />
                  </IconButton>
                ) : (
                  <div></div>
                )}
              </InlineSpacing>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <div
        id="content-wrapper"
        style={{
          display: 'flex',
          background: theme.palette.background.default,
        }}
      >
        <Box
          margin={`${theme.spacing(4)}px auto`}
          maxWidth={contentMaxWidth || '1200px'}
          width="100%"
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Box>
      </div>
    </Box>
  ) : (
    <Box
      display={'flex'}
      height={config.AUTO_RESIZE ? '100%' : '100vh'}
      width="100vw"
    >
      <Box margin={'auto'} maxWidth={contentMaxWidth || '1200px'} width="100%">
        {children}
      </Box>
    </Box>
  )
}

export default UIWrapperV2
