import { defaultConsumptionCalculatorSchemaProperties } from './consumption-calculator.schema'

export const defaultAddressSchemaProperties = {
  disable_postalcode: { name: 'disable_postalcode' },
  different_billing_address: { name: 'different_billing_address' },
  billing_address: { name: 'billing_address' },
  different_invoice_recipient: { name: 'different_invoice_recipient' },
  invoice_recipient_salutation: { name: 'invoice_recipient_salutation' },
  invoice_recipient_title: { name: 'invoice_recipient_title' },
  invoice_recipient_first_name: { name: 'invoice_recipient_first_name' },
  invoice_recipient_last_name: { name: 'invoice_recipient_last_name' },
}

export const addressSchemaProperties = {
  [defaultConsumptionCalculatorSchemaProperties.postalcode_city.name]: {
    type: 'object',
    properties: {
      zipCode: {
        type: 'string',
      },
      streetName: {
        type: 'string',
      },
      houseNumber: {
        type: 'string',
      },
      extention: {
        type: 'string',
      },
    },
  },
  [defaultAddressSchemaProperties.disable_postalcode.name]: {
    type: 'boolean',
  },
  [defaultAddressSchemaProperties.different_billing_address.name]: {
    type: 'boolean',
  },
  [defaultAddressSchemaProperties.billing_address.name]: {
    type: 'object',
    properties: {
      zipCode: {
        type: 'string',
      },
      city: {
        type: 'string',
      },
      streetName: {
        type: 'string',
      },
      houseNumber: {
        type: 'string',
      },
      extention: {
        type: 'string',
      },
    },
  },
  [defaultAddressSchemaProperties.different_invoice_recipient.name]: {
    type: 'boolean',
  },
  [defaultAddressSchemaProperties.invoice_recipient_salutation.name]: {
    type: 'string',
    enum: ['Herr', 'Frau', 'Divers'],
  },
  [defaultAddressSchemaProperties.invoice_recipient_title.name]: {
    type: 'string',
    enum: ['Dr.', 'Prof.', 'Prof. Dr.'],
  },
  [defaultAddressSchemaProperties.invoice_recipient_first_name.name]: {
    type: 'string',
  },
  [defaultAddressSchemaProperties.invoice_recipient_last_name.name]: {
    type: 'string',
  },
}
