import './step-handler.css'

import { JsonFormsCore } from '@jsonforms/core'
import { deepEqual } from 'fast-equals'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Error } from 'components/error'
import Loader from 'components/loader/'
import { setInputValue } from 'modules/step/reducer'
import { getActiveStep, getIsLoading, getError } from 'modules/step/selectors'
import { Step } from 'modules/step/types'
import { GlobalAppState } from 'modules/types'

import localizeError from './localize-error'
import StepHandlerJson from './step-handler-json'
import { UseIsDirty } from './UseIsDirty'

type StepHandlerProps = {
  activeStep: undefined | Step
  isLoading?: boolean
  error: boolean
  setInputValue: typeof setInputValue
}

type StepHandlerState = {
  step: null | Step
}

class StepHandler extends Component<StepHandlerProps, StepHandlerState> {
  state = {
    step: null,
  }

  static getDerivedStateFromProps(
    props: StepHandlerProps,
    state: StepHandlerState,
  ): any {
    const step = props.activeStep

    if (
      step?.id !== state.step?.id ||
      !deepEqual(step?.resultData, state.step?.resultData)
    ) {
      // If 'id' or 'resultData' changed return new step data
      return {
        step,
      }
    }

    return null
  }

  handleChange = ({
    data,
    errors,
  }: Pick<JsonFormsCore, 'data' | 'errors'>): void => {
    const { setInputValue } = this.props
    const dataReduced = { ...data }

    if (errors) localizeError(errors)
    setInputValue(dataReduced, errors)
  }
  // Notes: resultData is available per step and, per selector as globalResultData
  // Maybe a the globalResultData separated into the resultData of each step might
  // be a useful option which should be enabled by config setting

  // step config might need to have a separate 'presetData' prop to separate from
  // user 'data' coming from the UI inputs

  render(): JSX.Element {
    const step = this.state.step as StepHandlerState['step']
    const { isLoading, error } = this.props

    if (error) return <Error />

    if (isLoading || !step) return <Loader />

    return (
      <div
        className={`step-handler step-id-${step.id}`}
        data-component="step-handler"
        data-key={step.id}
      >
        <div className="step-handler-inner">
          <UseIsDirty />
          <StepHandlerJson handleChange={this.handleChange} step={step} />
        </div>
      </div>
    )
  }
}

export default connect(
  (state: GlobalAppState) => ({
    activeStep: getActiveStep(state),
    isLoading: getIsLoading(state),
    error: getError(state),
  }),
  {
    setInputValue,
  },
)(StepHandler)
