import { ControlElement, JsonSchema, Labels } from '@jsonforms/core'
import { useSelector } from 'react-redux'

import { isRequiredScope } from 'lib'
import { generateStringLabel } from 'lib/is-required-scope'
import { getShowErrors } from 'modules/step/selectors'
import { BlueprintControlElement } from 'types/blueprint-config'

import { isBlueprintControlElement } from './type-guards'

export type UseSelectBoxProps = {
  uischema: ControlElement
  rootSchema: JsonSchema
  label: string | Labels
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enumOptions: any[]
}

export type UseSelectBoxReturnType = {
  uischema: BlueprintControlElement
  showErrors: boolean
  label: string
  options: { value: string; label: string }[]
}

export const useSelectBox = (
  props: UseSelectBoxProps,
): UseSelectBoxReturnType => {
  const { rootSchema, uischema, label, enumOptions, data } = props

  const isRequired = isRequiredScope(rootSchema, uischema.scope)
  const showErrors = useSelector(getShowErrors)

  // check if uischema matches BlueprintControlElement type
  if (!isBlueprintControlElement(uischema as BlueprintControlElement)) {
    throw new Error(
      'Incompatible Type of props.uischema detected. The uischema must be of type BlueprintControlElement',
    )
  }

  // get label and validate it's of correct type
  const stringLabel =
    typeof label === 'string' && generateStringLabel(label, isRequired)

  if (stringLabel === false) {
    throw new Error(
      'Incompatible Type of props.label detected. Must be a string',
    )
  }

  const { labels } = uischema as BlueprintControlElement

  // get combined options
  const options = enumOptions.map((value) => {
    return {
      value,
      label: (labels && (labels as any)[value]) || value,
    }
  })

  // validate options props is in the correct format. filter function returns invalid elements.
  // if filter function returns a length > 1, the format is incorrect.
  const incorrectOptionsFormat = !!(
    options &&
    options.length &&
    options.filter(
      (option) =>
        !(typeof option.value === 'string' && typeof option.label === 'string'),
    ).length
  )

  if (incorrectOptionsFormat) {
    throw new TypeError(
      'SelectBox options should be in format {label: string, value: string}[]',
    )
  }

  if (!(typeof data === 'undefined' || typeof data === 'string')) {
    throw new TypeError('The data can only be of type string | undefined.')
  }

  return {
    showErrors,
    options,
    uischema: uischema as BlueprintControlElement,
    label: stringLabel,
  }
}
