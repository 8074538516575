import { BlueprintStep } from 'types/blueprint-config'

export const thankYou: BlueprintStep = {
  id: 'thank_you',
  title: 'Last step',
  nextButtonLabel: 'Next',
  schema: {
    type: 'object',
    properties: {},
  },
  uischema: {
    type: 'GridContainerLayout',
    options: {},
    elements: [
      {
        type: 'GridItemLayout',
        options: {
          xs: 12,
        },
        elements: [
          {
            type: 'CardLayout',
            elements: [
              {
                type: 'ConfirmationMessage',
                options: {
                  title: 'Vielen Dank für Ihre Bestellung.',
                  text:
                    'Sie werden in Kürze eine Bestätigungs-Email von uns erhalten. Bitte prüfen Sie Ihr E-Mail Postfach auf den Erhalt der E-Mail.',
                  icon: 'check',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  resultData: {},
  logic: {},
  isEmbedded: true,
}
