import React, { useState } from 'react'

type RadioProps = {
  summary: string | React.ReactElement
  description?: string | React.ReactElement
}

const RadioDescription = ({
  summary,
  description,
}: RadioProps): React.ReactElement => {
  const [open, setOpen] = useState(false)

  let divSummary = <div>{summary}</div>

  if (summary && typeof summary === 'string') {
    divSummary = <div dangerouslySetInnerHTML={{ __html: summary }} />
  }

  let divDescription = <div>{description}</div>

  if (description && typeof description === 'string') {
    divDescription = <div dangerouslySetInnerHTML={{ __html: description }} />
  }

  return (
    <div style={styles.container}>
      <div style={styles.summary}>
        {summary ? divSummary : null}
        {description ? (
          <div onClick={() => setOpen(!open)} style={{ marginRight: '5px' }}>
            \/
          </div>
        ) : null}
      </div>

      {description && open ? (
        <div style={styles.description}>{divDescription}</div>
      ) : null}
    </div>
  )
}

const styles = {
  container: {
    marginTop: '5px',
  },
  summary: {
    marginBottom: '5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {},
}

export default RadioDescription
